const initialState = {
    tenancy_contract: {},
  };
  const Tenancy_Contract_Reducer = (state = initialState, action) => {

    
    
    switch (action.type) {
      case "SET_TENANCY_CONTRACT":
        return {
          ...state,
          tenancy_contract: action?.payload ? action?.payload?.tenancy_contract : {},
       
        };
  
  
      default:
        return state;
    }
  };
  
  export default Tenancy_Contract_Reducer;
  

  