import { Component } from 'react'
import { Col, Row, Card, Button, Space, Layout, Menu, Typography, Table, Tag, Checkbox, Drawer, Segmented } from 'antd';
import { Link } from 'react-router-dom';
import {TeamOutlined, MobileOutlined, DesktopOutlined, EditOutlined, StopOutlined, DeleteOutlined, PlusOutlined, InfoCircleFilled,} from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import "../Cms/Website/cms.css"
import Moment from 'react-moment';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import EditUser from './EditUser';

const { Title } = Typography;
const {Sider } = Layout;
const CheckboxGroup = Checkbox.Group;


function getItem(label, key,id, icon, children) {
    return { key, icon,  children, label,id };
  }

  function sortUsers (key) {
    const sortedUsers = [...this.state.users].sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  
    this.setState({ users: sortedUsers });
  };

  async function fetchAllUsers() {
    let allUsers = [];
    let hasMore = true;
    let nextPageUrl = `/all_users`;
  
    while (hasMore) {
      // Fetch the current page of users
      const response = await API.post(nextPageUrl);
  
      // Extract users and pagination details from the response
      const users = response.data.users || [];
      const nextPage = response.data.next_page_url; 

      // Add the users to the allUsers array
      allUsers = [...allUsers, ...users];
  
      // Check if there's another page to fetch
      if (nextPage) {
        nextPageUrl = nextPage;  // Update the URL to fetch the next page
      } else {
        hasMore = false;  // Stop the loop when no more pages
      }
    }
    return allUsers;
  }
  
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:false,
            users:[],
            openDetails:false,
            record_id:'',
            record_name:'',
            searchUser:'',
            searchRole:'',
            total:''
        }
    }

    componentDidMount() {
        const page = 1;
        const perPage = 15;
       
        this.fetchData(page, perPage);
    }

    fetchData = async (page, perPage) => {
        this.setState({ isLoading: true });
        try {
            // Optional: Simulate a delay for testing
            await new Promise(resolve => setTimeout(resolve, 300));
    
            const response = await API.post(`/all_users?page=${page}&perPage=${perPage}`);
            console.log("Fetched data:", response.data); // Log the data structure
            const data = response.data; // Ensure this is in the expected format
    
            this.setState({
                users: data,
                isLoading: false,
            });
        } catch (error) {
            console.error("Error fetching users:", error);
            this.setState({ isLoading: false });
        }
    };
    
    getCurrentTime = (date) => {
        if (date != null && date !== "" && date !== undefined) {
            let newDate = new Date(date);
            if (isNaN(newDate.getTime())) {
                console.error("Invalid date format:", date);
                return null;
            }
            newDate.setTime(newDate.getTime() - (60 * 60 * 1000));
            // console.log("New date:", newDate);

            return newDate.toISOString();
        } else {
            return null;
        }
    }

    // Function to handle user search
    handleSearchUsers = async (searchUser) => {
        try {
          this.setState({ isLoading: true });
      
          const allUsers = await fetchAllUsers();
      console.log(allUsers)
          // Ensure allUsers is an array before filtering
          const searchQuery = searchUser ? searchUser.toString().toLowerCase() : '';
          const filteredUsers = Array.isArray(allUsers)
            ? allUsers.filter(user => 
                user.display_name.toLowerCase().includes(searchQuery) || 
                user.email.toLowerCase().includes(searchQuery) || 
                user.username.toLowerCase().includes(searchQuery)
              )
            : []; // Fallback to an empty array if allUsers is not iterable
      console.log('**Filtered**' + filteredUsers)
          this.setState({
            searchUser, // Save the search term
            filteredUsers: searchQuery ? filteredUsers : [], // Store filtered users
            users: { ...this.state.users, users: allUsers }, // Update users list
            isLoading: false,
          });
        } catch (error) {
          console.error("Error fetching users:", error);
          this.setState({ isLoading: false });
        }
      };
      
      
    handleRoleFilter = async (searchRole) => {
        console.log(searchRole);
        try {
            this.setState({ isLoading: true });
            // Fetch all users
            const allUsers = await fetchAllUsers();
            
            // Check if searchRole has values
            const filteredUsers = searchRole.length > 0
                ? allUsers.users.filter(user => searchRole.includes(user.role_id))
                : allUsers; // Return all users if no searchRole is provided
            
            this.setState({
                searchRole, // Save the search term
                filteredUsers, // Store filtered users
                users: { ...this.state.users, users: allUsers }, // Update users list
                isLoading: false,
            });
        } catch (error) {
            console.error("Error fetching users:", error);
            this.setState({ isLoading: false });
        }
    };

    handleRoleChange = (checkedValues) => { 
        this.setState({ status_filter: checkedValues }, () => {
            this.handleRoleFilter(checkedValues);
        });
    };

    getImage = (image) => {
        if (image.includes('https:')) {
            return image
        } else {
            return 'https://www1.luxuryproperty.com/uploads/team/' + image
        }
    }


    render() {  console.log(this.state.users)
        const columns = [
            {
                title: 'photo', width:180,
                dataIndex: 'Profile',
                render: (text, record) => (
                    record.profile_picture ?
                    <div className='agentimage' style={{width:180, height:150}}><img src={this.getImage(record.profile_picture)} className='defimg mx-auto' style={{ height:'100%', width:'100%', objectFit:'cover' }} /></div>
                    :<div className='noimageyet' style={{width:180, height:150}}><img src={"../../icons/agent.png"} className='defimg mx-auto' width="80px" style={{ opacity: '0.6' }} /></div>
                )
            },
            {
              title: 'Name', width:300,
              dataIndex: 'display_name',
              sorter: (a, b) => a.display_name.length - b.display_name.length,
              render: (text, record) => {
                let color; let value
                switch (record.active) {
                    case 'Y':
                    color = "green"; value="Active"
                    break;
                    default:
                    color = "red";value="Inactive"
                }
                return (
                    <Row className={nameStyle} onClick={() => onRowClick(record)} style={{cursor:'pointer'}}>
                        <Col span={24} style={{marginBottom:20}}><Tag color={color} key={record.active} style={tagRole} icon={<InfoCircleFilled style={{fontSize:6}}/>}>{value}</Tag></Col>
                        <Col span={24} fontSize={24} className='font-bold capitalize'>{record.display_name}</Col>
                        <Col span={24}><span className='font-12'>{record.email.toLowerCase()}</span></Col>
                        <Col span={24}><span className='font-12'>{record.mobile}</span></Col>
                    </Row>
                )
              }
            },{
                title: 'Role',
                render: (text, record) => {
                  let color;
                  switch (record.role_id) {
                    case 1: //Super Admin
                      color = "geekblue";
                      break;
                    case 2: //Sales Agent
                      color = "cyan";
                      break;
                    case 9: //Rental Agent
                      color = "gold";
                      break;
                    default:
                      color = "purple";
                  }
                  
                  return (
                    <Row><Col span={24} className='d-flex flex-column justify-content-between gap-2'>
                        <Row>
                            <Col span={24}><span className='font-bold font-10'>Team:</span></Col>
                            <Col span={24}><span className='font-12'>Sales </span></Col>
                        </Row>
                        <Row>
                            <Col span={24}><span className='font-bold font-10'>Access:</span></Col>
                            <Col span={24}>
                                <Tag color={color} key={record.role_id} style={tagRole}>
                                {record.role}
                                </Tag>
                            </Col>
                        </Row>
                    </Col></Row>
                );
                }
            },{
                
                title: 'Last seen',
                dataIndex: 'lastseencol',
                render: (text, record) => (
                    <Row>
                        <Col span={24}><span className='font-bold font-10'>Last Seen:</span></Col>
                        <Col span={24}><Space className='d-flex flex-column' align='left'>
                        <span className='d-flex flex-row font-12 align-items-start' style={{ gap: '0.3rem' }}><DesktopOutlined style={{marginTop:'3px'}}/>Web:
                            {
                                record.web_login != null && record.web_login != "" && record.web_login != undefined ? <div className='d-flex flex-column font-12 align-items-start gap-1'>
                                <Moment fromNow>{this.getCurrentTime(record.web_login)}</Moment>
                                <Moment format='hh:mm:ss | DD MMM YYYY'>{(record.web_login) ? this.getCurrentTime(record.web_login) : ""}</Moment> 
                                </div>: " Never"
                            }</span>
                        <span className='d-flex flex-row font-12 align-items-start' style={{ gap: '0.3rem' }}><MobileOutlined style={{marginTop:'3px'}}/>Mobile:    {
                            record.mobile_login != null && record.mobile_login != "" && record.mobile_login != undefined ? 
                            <div className='d-flex flex-column font-12 align-items-start'>
                                <Moment format='hh:mm:ss | DD MMM YYYY'>{(record.mobile_login) ? this.getCurrentTime(record.mobile_login) : ""}</Moment>
                            </div>: " Never"
                        }</span>

                    </Space></Col>
                    </Row>
                )
            },{
                title:'Telephone Directory',width:100,
                dataIndex:'teldirectory',
                render:(text, record)=> (
                    <Row>
                        <Col span={24}><span className='font-bold font-10'>Telephone Directory:</span></Col>
                        <Col span={24}>{record.telephone_directory}</Col>
                    </Row>
                )
            },{
                title: 'Sort', width:100,
                render: (text, record) => (
                    <Row>
                        <Col span={24}><span className='font-bold font-10'>Sort:</span></Col>
                        <Col span={24}>
                            <input placeholder='0' style={inputStyle} value={record.sort}/>
                        </Col>
                    </Row>
                  ),
              }, {
                title: 'Action', width:150, className:'actionwrap',
                render: (text, record) => (
                  <Row className=''>
                    <Col span={24}><p className='info-title mb-0'><i class="bi bi-three-dots"></i></p></Col>
                    <Col span={24}><Button type='link' onClick={() => onRowClick(record)} style={actionStyle}>
                        <span style={{width:15}}><EditOutlined style={{fontSize:10, width:14}} /></span><span className='font-12'>Edit details</span></Button></Col>
                    <Col span={24} className='justify-content-between'><Button type='link' style={actionStyle}><span className='gap-1'><span style={{width:15}}><StopOutlined style={{fontSize:10, width:14}} /></span><span className='font-12'>Deactivate</span></span></Button></Col>
                    <Col span={24} className='justify-content-between'><Button type='link' style={actionStyle}><span className='gap-1'><span style={{width:15}}><DeleteOutlined style={{fontSize:10, width:14}} /></span><span className='font-12'>Delete user</span></span></Button></Col>
                  </Row>
                  ),
              }, 
         
          ]
          const refineRoles = (
            <CheckboxGroup className="refineRole"
              value={this.state.status_filter} placeholder="Status" onChange={this.handleRoleChange}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={[
                { value: '1', label: 'Super Admin' },
                { value: '2', label: 'Rental Agents' },
                { value: '9', label: 'Sales Agents' }
              ]} />
          )
          const onRowClick = (record) => {
            this.setState({openDetails:true, record_id:record.id, record_name:record.display_name});
          };
          const closeDetails=()=>{ this.setState({openDetails:false}) }
          const handleTableChange = (pagination) => { this.fetchData(pagination.current, pagination.pageSize); };
        return (
            <Card className='usermgnt-wrap' title={
                <Space className='headercard d-flex flex-row align-items-start py-2'>
                    <Space className='d-flex'><TeamOutlined style={{fontSize:21}} /><Title style={{fontSize:21}} className='font-bold mb-0 pb-0'>User Management</Title></Space>
                </Space>}>
                <Layout className="wrap d-flex flex-column w-100" style={{background:'transparent', }}>
                    <ToastContainer />
                    <Row className="container pb-2 justify-content-between" gutter={21} id="2" style={{maxWidth:'100%', paddingRight:0}}>
                        <Col span={24}>
                            <Row>
                                <Col span={12}>
                                    <Title level={4} className='font-bold'>All Users <span style={{color:'rgba(21,21,21,0.6)'}} className='font-bold'>{this.state.users.total}</span></Title>
                                </Col>
                            </Row>
                            <Row className="container p-0 mx-0 justify-content-between" style={{maxWidth:'100%'}}>
                                <Col span={12} style={{display:'flex', alignItems:'flex-end'}}>
                                    {refineRoles}
                                </Col>
                                <Col span={12}>
                                    <Row gutter={8} className='w-100' style={{justifyContent:'flex-end', display:'flex'}}>
                                        <Col span={12}>
                                            <div className='form-group mb-0'>
                                                <input placeholder="Search" onChange={(e) => this.handleSearchUsers(e.target.value)} allowClear  />
                                            </div>
                                        </Col>
                                        <Col style={{pr:0}}><Button type="primary" className='mt-0'><PlusOutlined style={{mr:1}} />Add User</Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col></Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Table loading={this.state.isLoading} showHeader={false} onChange={handleTableChange} className='userTable'
                                pagination={{
                                    pageSizeOptions: ['5','10', '20','50','100'],
                                    total:
                                    this.state?.users?.total > 0 &&
                                    this.state.users.total,
                                    pageSize: this.state.users.per_page,
                                    current: this.state.users.current_page,
                                    showTotal: (total, range) =>
                                    window.isTabView ? `` :`Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                }}
                                style={tableStyle}
                                columns={columns}
                                dataSource={this.state.searchUser ? this.state.filteredUsers : this.state?.users?.users ?? []}
                            />
                        </Col>
                    </Row>
                    <Drawer title={<span className='font-bold'>Edit User: {this.state.record_name}</span>} onClose={closeDetails} open={this.state.openDetails} width={'80vw'}>
                        <EditUser record={this.state.record_id} />
                    </Drawer>
                </Layout>
            </Card>
        );
    }
}

export default Users;

const tableStyle = { width:'100%',
    'table': {borderSpacing:'0 1rem', borderRight:'0 !important'}
}
const tagRole = { fontSize:12, display:'flex', alignItems:'center', width:'fit-content',textTransform:'capitalize',
    '.stat-Y': {background:'green'},
}
const nameStyle ={}
const inputStyle = {minWidth:20, width:'100%', border:'1px solid rgba(21,21,21,0.16)', textAlign:'center', height:46, marginTop:5}
const actionStyle = { cursor:'pointer', padding:'0 8px', margin:0, color:'#151515', width:'100%'}

