import React, { Component } from 'react'
import { Row, Col, Avatar, Space, Button, Progress, Card, Table, Modal, Select, Calendar, Radio, Input, Upload, message, Empty, Segmented, Checkbox  } from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {UserOutlined, CalendarOutlined, CaretRightOutlined, CaretLeftOutlined, EyeFilled, InboxOutlined,AppstoreOutlined } from '@ant-design/icons';
import './hr_platform.css'
import dayjs from 'dayjs';
import { TabList, TabPanel } from 'react-tabs';
import {baseurlImg, liveUrl} from '../../components/BaseUrl';
import Moment from 'react-moment';
import {Link} from 'react-router-dom'
const { TextArea } = Input;
const { Dragger } = Upload;
const CheckboxGroup = Checkbox.Group;

const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };




  function getListData(value) {
    let listData;
    switch (value.date()) {
      case 8:
        listData = [
          { type: 'warning', content: 'This is warning event.' },
          { type: 'normal', content: 'This is usual event.' },
        ]; break;
      case 10:
        listData = [
          { type: 'warning', content: 'This is warning event.' },
          { type: 'normal', content: 'This is usual event.' },
          { type: 'error', content: 'This is error event.' },
        ]; break;
      case 15:
        listData = [
          { type: 'warning', content: 'This is warning event' },
          { type: 'normal', content: 'This is very long usual event。。....' },
          { type: 'error', content: 'This is error event 1.' },
          { type: 'error', content: 'This is error event 2.' },
          { type: 'error', content: 'This is error event 3.' },
          { type: 'error', content: 'This is error event 4.' },
        ]; break;
      default:
    }
    return listData || [];
  }
  
  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <ul className="events">
        {
          listData.map(item => (
            <li key={item.content}>
              <span className={`event-${item.type}`}>●</span>
              {item.content}
            </li>
          ))
        }
      </ul>
    );
  }
  
  function getMonthData(value) {
    if (value.month() === 8) {
      return 1394;
    }
  }
  
  function monthCellRender(value) {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }







class HR_Dashboard extends Component {
    constructor(props){
    super(props)
        this.state = { 
            leaverequesetform: false,
            certificaterequestform: false,
            documentsform:false,
            calendar:false,
            pendingrequest:false,
        }
    }

    handleShowLeaveRequestForm = () => {
        this.setState({leaverequesetform: true})
    }
    handleCloseLeaveRequestForm = () => {
        this.setState({leaverequesetform: false})
    }
    handleShowCertificateRequestForm = () => {
        this.setState({certificaterequestform: true})
    }
    handleCloseCertificateRequestForm = () => {
        this.setState({certificaterequestform: false})
    }
    handleShowDocumentsForm = () => {
        this.setState({documentsform: true})
    }
    handleCloseDocumentsForm = () => {
        this.setState({documentsform: false})
    }
    handleShowCalendar = () => {
        this.setState({calendar: true})
    }
    handleCloseCalendar = () => {
        this.setState({calendar: false})
    }
    handleShowPendingRequest = () => {
        this.setState({pendingrequest: true})
    }
    handleClosePendingRequest = () => {
        this.setState({pendingrequest: false})
    }

    render() {

        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let user = userdata.auth.user;
        let role_name = userdata.auth.user.role_name;


        // LEAVE TABLE
        const columns = [
            { title: '', dataIndex: 'icon', width:30,
                render: (text, record) => (
                    record.request=='Leave' ? <span className="iconrequest leave"><i class="bi bi-suitcase-lg"></i></span>
                    : record.request=="Certificate" ? <span className="iconrequest certificate"><i class="bi bi-suitcase-lg"></i></span>
                    :null
                ),
            }, { title: 'Request', dataIndex: 'request',
                render: (text, record) => (
                    <>
                        <p className="mb-0">{record.request}</p>
                        <span>Holiday</span>
                    </>
                ),
            }, { title: 'From', dataIndex: 'from',
                render: (text, record) => (
                    <Moment format="DD MMM YYYY ">2023-05-30 12:54:44</Moment>
                ),
            }, { title: 'Till', dataIndex: 'till',
                render: (text, record) => (
                    <Moment format="DD MMM YYYY ">2023-05-30 12:54:44</Moment>
                ),
            }, { title: 'Status', dataIndex: 'status',
                render:(text,record) => (
                    <p className="mb-0"><span className="circle"></span><span className="font-12">{record.status}</span></p>
                )
            },
        ]

        const upcomingleave = [
            {request: 'Leave', from:"", till:"", status:'Approve', reason:'Holiday'},
        ]
        const pendingrequest = [
            {request: 'Certificate', from:"22-08-2023", till:"", status:'Pending', reason:'Salary Certificate'},
        ]

        // DEPENDENT
        const dependent = [
            { title: 'Next of Kin', dataIndex: 'kin',
            }, { title: 'Relationship', dataIndex: 'relationship',
            }, { title: 'Contact Number', dataIndex: 'number',}
        ]
        const dependantdata = [
            {kin: 'Elena Michaels', relationship:"Aunt", number:"+971123456789"},
            {kin: 'Elena Michaels', relationship:"Aunt", number:"+971123456789"},
        ]
        const leavehistory = [
            {request: 'Sick', from:"2023-05-30 12:54:44", till:"2023-05-30 12:54:44", status:'Approve', reason:'Fever and headache'},
            {request: 'Leave', from:"2023-05-30 12:54:44", till:"2023-05-30 12:54:44", status:'Approve', reason:'Holiday'},
            {request: 'Sick', from:"2023-05-30 12:54:44", till:"2023-05-30 12:54:44", status:'Approve', reason:'Fever and headache'},
        ]

        return (<>
            {/* =========== MODALS =============*/}
            {/* =========== MODALS =============*/}
            {/* Leave Request Form */}
            <Modal title="Leave Request Form" open={this.state.leaverequesetform} onOk={this.handleShowLeaveRequestForm} onCancel={this.handleCloseLeaveRequestForm} width={'75vw'} centered={true} closeIcon={<i class="bi bi-x fs-5 mt-1"></i>} className="leaverequestmodal" bodyStyle={{maxHeight:'75vh', overflowY:'scroll'}}
            footer={<>
                <Space className='d-flex flex-row justify-content-between w-100 align-items-center' align="start">
                    <p className="text-left mb-0">DISCLAIMER: Avoid finalizing travel plans before leaves are approved.</p>
                    <Space>
                        <Button type="primary">Submit Request</Button>
                        <Button type="text">Cancel</Button>
                    </Space>
                </Space>
            </>}>
                <Row gutter={16}>
                    <Col span={18} className="border-right pr-4">
                        <Row gutter={24}>
                            <Col span={12}>
                                <Space className="space-form" align="start">
                                    <label>Leave Type <em>*</em></label>
                                    <Select defaultValue="Annual Leave" allowClear showSearch
                                    style={{minWidth:'200px'}}
                                    options={[
                                        {value: 'annualleave', label: 'Annual Leave',},
                                        {value: 'annualleave-halfday', label: 'Annual Leave (Half Day)',},
                                        {value: 'paternityleave', label: 'Paternity Leave',},
                                        {value: 'maternityleave', label: 'Maternity Leave',},
                                        {value: 'sickleave', label: 'Sick Leave',},
                                        {value: 'emergencyleave', label: 'Emergency Leave',},
                                        {value: 'bereavementleave', label: 'Bereavement Leave (only for immediate family',},
                                        {value: 'unpaidleave', label: 'Unpaid Leave',},
                                        {value: 'otherleave', label: 'Other Leave',},
                                    ]}/>
                                </Space>
                                <Space align='start' className="space-form">
                                    <label>Select Days <em>*</em></label>
                                    <div style={{width:'100%'}}><Calendar fullscreen={false} className="leavecalendar" /></div>
                                </Space>
                            </Col>
                            <Col span={12} className="d-flex flex-column justify-content-between">
                                <Space className="d-flex flex-column ml-0" align="start">
                                    <Space className="space-form" align="start">
                                        <label>Who should your listings/leads be reassigned to? <em>*</em></label>
                                        <Select allowClear showSearch placeholder="Choose an agent"
                                        style={{minWidth:'200px'}}
                                        options={[
                                            {value: 'annualleave', label: 'Darren Jarvis',},
                                            {value: 'annualleave-halfday', label: 'Reece Wallace',},
                                            {value: 'paternityleave', label: 'Kyle Giusti',},
                                            {value: 'maternityleave', label: 'Craig Scrim',},
                                            {value: 'sickleave', label: 'Michaela Holesinska',},
                                            {value: 'emergencyleave', label: 'Anna Arxe',},
                                            {value: 'bereavementleave', label: 'Keisha Brass',},
                                            {value: 'unpaidleave', label: 'Iga Sikorska',},
                                            {value: 'otherleave', label: 'Mary Borghosizian',},
                                        ]}/>
                                    </Space>
                                    <Space className="space-form" align="start">
                                        <label>Where will you be? <em>*</em></label>
                                        <Radio.Group name="radiogroup" defaultValue={1} className="d-flex flex-column">
                                            <Radio value={1}>Inside UAE</Radio>
                                            <Radio value={2}>Outside UAE</Radio>
                                        </Radio.Group>
                                    </Space>
                                </Space>
                                {/* Notifications for when they click on the calendar. */}
                                <Space className="d-flex flex-column notileaves" align="start">
                                    <div className="border d-flex flex-row">
                                        <p className="border-right"><span className="font-bold">10</span> out of <span className="font-bold">30</span> used</p>
                                        <p><span className="font-bold">20</span> days remaining</p>
                                    </div>
                                    <Space className="d-flex flex-column notisentence">
                                        <p>You're selected <span className="font-bold">24 - 31 August 2023.</span> That's a total of <span className="font-bold">5 working days</span> spanning over <span className="font-bold">7 days.</span></p>
                                        <p>You'll resume work on <span>1 September 2023</span></p>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col span={24}>
                                <Space className="space-form w-100" align="start" >
                                    <label>Note <em>*</em></label>
                                    <TextArea row={3} />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}  className="d-flex flex-column px-3">
                            <Space  className="d-flex flex-column" >
                                <p>This form needs to be filled out at least <span>15 days in advance</span> for all leaves with all the requested details.</p>
                                <p>For sick leaves not exceeding 1 day, an email needs to be sent to HR.</p>
                            </Space>
                            {user.role == 1 ? <>
                                <Space className="d-flex flex-column">
                                    <p className="font-bold ml-0">Operations Team:</p>
                                    <p>Should you be on leave for more than <span>3 consecutive days</span>, a handover is mandatory.</p>
                                    <p>All types of leave are subject for approval from the Management.</p>
                                </Space>
                            </>:
                                <Space className="d-flex flex-column">
                                    <p className="font-bold">Brokerage Team:</p>
                                    <p>For leaves 2 days or more:</p>
                                    <ul>
                                        <li>Listings/Leads reallocation is mandatory. </li>
                                        <li>Any information for reallocation not mentioned will be decided by your direct manager.</li>
                                    </ul>
                                    <p>All types of leave are subject for approval from the Management.</p>
                                </Space>
                            }
                    </Col>
                </Row>
            </Modal>


            {/* CERTIFICATE REQUEST FORM */}
            <Modal title="Certificate Request Form" open={this.state.certificaterequestform} onOk={this.handleCloseCertificateRequestForm} onCancel={this.handleCloseCertificateRequestForm} width={'65vw'} centered={true} closeIcon={<i class="bi bi-x fs-5 mt-1"></i>} className="certificaterequestmodal" bodyStyle={{maxHeight:'75vh', overflowY:'scroll'}}
            footer={<>
                <Space className='d-flex flex-row justify-content-between w-100 align-items-center' align="start">
                    <Space>
                        <Button type="primary">Submit Request</Button>
                        <Button type="text">Cancel</Button>
                    </Space>
                </Space>
            </>}>
                <Row gutter={16} className="w-100">
                    <Col span={6}>
                        <Space className="space-form">
                            <label>Type of Certificate:<em></em></label>
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={1}>NOC - Driving License</Radio>
                                    <Radio value={2}>NOC - Working Permit</Radio>
                                    <Radio value={3}>Salary Certificate</Radio>
                                    <Radio value={4}>Al Ansari - Payslip Request</Radio>
                                    <Radio value={4}>Company Payslip</Radio>
                                    <Radio value={4}>NOC - Travel</Radio>
                                </Space>
                            </Radio.Group>
                        </Space>
                    </Col>
                    <Col span={18} className="d-flex flex-row flex-wrap certforms">
                        <Space className="space-form">
                            <label>Institute Name<em>*</em></label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Attention</label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Institute Address<em>*</em></label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Account No</label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Branch</label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Amount</label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Country</label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Last Working Date</label>
                            <Input placeholder="Enter name" />
                        </Space>
                        <Space className="space-form">
                            <label>Comments / Notes</label>
                            <Input placeholder="Enter name" />
                        </Space>
                    </Col>
                </Row>
            </Modal>

            {/* DOCUMENT REQUEST FORM */}
            <Modal title="Documents Request Form" open={this.state.documentsform} onCancel={this.handleCloseDocumentsForm} width={'65vw'} centered={true} closeIcon={<i class="bi bi-x fs-5 mt-1"></i>} className="documentrequestmodal" scrollableBody={true} bodyStyle={{maxHeight:'75vh', overflowY:'scroll'}}>
                <Row gutter={16} className="w-100">
                    <Col span={24}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                            </p>
                        </Dragger>
                    </Col>
                </Row>
            </Modal>

            {/* PENDING REQUEST APPROVAL */}
            <Modal title="Pending Request" open={this.state.pendingrequest} onCancel={this.handleClosePendingRequest} width={'25vw'} centered={true} closeIcon={<i class="bi bi-x fs-5 mt-1"></i>} className="pendingrequestmodal" footer={null}>
                <Row className="d-flex flex-row w-100">
                    <Col span={6}>
                        <span className="leaveicon annual"><i class="bi bi-suitcase-lg fs-3"></i></span>
                    </Col>
                    <Col span={18}>
                        <Space direction="vertical" >
                            <p className='leavestat annual'>Annual Leave</p>
                            <p className='font-bold fs-5'>Darren Jarvis</p>
                            <p className="d-flex flex-row">
                                <span className="opacity-5">Start:</span>
                                <span>September 08, 2023</span>
                            </p>
                            <p className="d-flex flex-row">
                                <span className="opacity-5">End:</span>
                                <span>September 30, 2023</span>
                            </p>
                            <p className="d-flex flex-row">
                                <span className="opacity-5">Reliever:</span>
                                <span>Jamie Holland</span>
                            </p>
                            <p className="d-flex flex-column">
                                <span className="opacity-5">Comment:</span>
                                <span>Tentative dates on his return to office</span>
                            </p>
                            <Space className="justify-content-center">
                                <Button type="primary" style={{width:'120px'}} className="justify-content-center d-flex">Approve</Button>
                                <Button type="secondary"  style={{width:'120px'}} className="justify-content-center">Reject</Button>
                            </Space>  
                        </Space>
                              
                    </Col>
                </Row>

            </Modal>

            {/* CALENDAR FULL SCREEN */}
            <Modal title="Calendar" open={this.state.calendar} onCancel={this.handleCloseCalendar} width={'100vw'} centered={true} closeIcon={<i class="bi bi-x fs-5 mt-1"></i>} className="calendarrequestmodal" bodyStyle={{width:'100%', height:'85vh'}}>
                <Row>
                    <Col span={24}>
                        <Row className="d-flex flex-row w-100 justify-content-between">
                            <Col>
                                <span className="font-bold font-12 mr-1">View as: </span>
                                <Segmented options={[
                                    { value: 'calendar',icon: <CalendarOutlined />,},
                                    { value: 'Kanban', icon: <AppstoreOutlined />},
                                ]}/>
                            </Col>
                            <Col>
                                <Button type="primary" onClick={this.handleShowLeaveRequestForm}>Create Leave Application</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row className="mb-2">
                            <Col className="space-form d-flex flex-row">
                                <span className="font-bold font-12 mr-2">Filter by Type:</span>
                                <Checkbox.Group options={[
                                    { label: 'Annual Leave', value: 'annual' },
                                    { label: 'Sick Leave', value: 'sick' },
                                    { label: 'Birthday', value: 'birthday' },
                                    { label: 'Emirates ID', value: 'eid' },
                                    { label: 'Visa', value: 'visa' },
                                    { label: 'Labour Card', value: 'labour' },
                                    { label: 'WFH', value: 'wfh' },
                                ]} />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Calendar fullscreen={false} style={{height:'100%'}}/>
                    </Col>
                </Row>
                
                
            </Modal>



<div className="d-none">{user.role=1}</div>



    
            {/* Agent / Ops Dashboard */}
            <Row className="hr-wrap" gutter={24}>
            {user.role==1 || user.role==2 ?<>
                <Col span={6} className="infowrap">
                    <Space className="d-flex flex-column justify-content-center border p-4 w-100">
                        <Space className="align-items-center d-flex flex-column maininfo ml-0 w-100">
                            <Avatar size={160} />
                            {user.role==1 ?<>
                                <h6 className="mb-0">Anastasiia Kurochkina</h6>
                                <p>Private Client Advisor</p>
                                <p>Sales Team</p>
                                <p>Joined as of 01 June, 2023</p>
                            </>:user.role==2 ?<>
                                <h6 className="mb-0">Mark Richards</h6>
                                <p>Managing Director</p>
                                <p>Joined as of 01 June, 2023</p>
                            </>:null}
                        </Space>
                        {user.role==1 ?<>
                            <Space className="p-2 d-flex flex-column align-items-start w-100">
                                <p><i class="bi bi-info-circle"></i> <span>Empoyee No: 01</span></p>
                                <p><i class="bi bi-phone"></i> <span>+971 50 123 4567</span></p>
                                <p><i class="bi bi-envelope"></i> <span>anastasiia@luxuryproperty.com</span></p>
                                <p><i class="bi bi-person"></i> <span>Line Manager: </span> <span>Mark Richards</span></p>
                            </Space>
                        </>:user.role==2 ?<>
                            <Space className="p-2 d-flex flex-column align-items-start w-100">
                                <p><i class="bi bi-info-circle"></i> <span>Empoyee No: 01</span></p>
                                <p><i class="bi bi-phone"></i> <span>+971 50 123 4567</span></p>
                                <p><i class="bi bi-envelope"></i> <span>mark@luxuryproperty.com</span></p>
                            </Space>
                        </>:null}
                        
                        <Space className='btngrp w-100 d-flex flex-column'>
                            <Button type={'secondary'} onClick={this.handleShowLeaveRequestForm}>Request For A Leave</Button>
                            <Button type={'secondary'} onClick={this.handleShowCertificateRequestForm}>Request For A Certificate</Button>
                            <Button type={'secondary'} onClick={this.handleShowDocumentsForm}>View My Documents</Button>
                        </Space>
                        
                    </Space>
                    {user.role==2 ?<>
                        <Space className="d-flex flex-column justify-content-center border p-4 w-100">
                            <Space className="mx-auto d-flex flex-column justify-items-center align-self-center">
                                <Progress type="circle" percent={27}  strokeColor="#f26f14" trailColor="#151515" strokeLinecap="square" size={170} className="mx-auto" strokeWidth={7} 
                                    format={(percent) => (<><h2>{percent}</h2><span className="font-12">Leave Balance</span> </>)} />
                                <Space className="dayswrap">
                                    <p className="d-flex flex-column border-right pr-4">
                                        <span className="font-10" style={{whiteSpace:'nowrap'}}>Days Remaining</span>
                                        <span className="font-bold">27 Days</span>
                                    </p>
                                    <p className="d-flex flex-column">
                                        <span className='font-10'>Days used</span>
                                        <span className="font-bold">3 Days</span>
                                    </p>
                                </Space>
                            </Space>
                        </Space>
                        <Card title={<h6>Dependent Details/ Next of Kin / Emergency Contact</h6>} className="border" style={{ boxShadow:'none', borderRadius:0 }}>
                            <Table bordered={false} columns={dependent} dataSource={dependantdata} className="w-100" pagination={false} scroll={false} />
                        </Card>
                    </>:null}
                </Col>
                </>:null}

                {/* =================================================================================
                // =================================================================================
                // ================================================================================= */}
                {user.role==1 ?
                    // Agent/OPS Dashboard
                    <Col span={18} className="d-flex flex-column justify-content-between">
                        <Space className="leaveinfo d-flex flex-column justify-content-center border w-100">
                            <Row className="w-100">
                                <Col span={17} className="border-right" >
                                    <Row className="border-bottom">
                                        <Col span={24}>
                                            <h6 className="mb-2">Upcoming Leave</h6>
                                        </Col>
                                        <Col span={24}>
                                            <Table bordered={false} columns={columns} dataSource={upcomingleave} className="w-100" pagination={false} scroll={false} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <h6>Pending Request</h6>
                                        </Col>
                                        <Col span={24}>
                                            <Table bordered={false} columns={columns} dataSource={pendingrequest} className="w-100" pagination={false} scroll={false} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={7} className="d-flex">
                                    <Space className="mx-auto d-flex flex-column justify-items-center align-self-center">
                                        <Progress type="circle" percent={27}  strokeColor="#f26f14" trailColor="#151515" strokeLinecap="square" size={170} className="mx-auto" strokeWidth={7} 
                                        format={(percent) => (
                                            <><h2>{percent}</h2><span className="font-12">Leave Balance</span> </>
                                        )}
                                        />
                                        <Space className="dayswrap">
                                            <p className="d-flex flex-column border-right pr-4">
                                                <span className="font-10" style={{whiteSpace:'nowrap'}}>Days Remaining</span>
                                                <span className="font-bold">27 Days</span>
                                            </p>
                                            <p className="d-flex flex-column">
                                                <span className='font-10'>Days used</span>
                                                <span className="font-bold">3 Days</span>
                                            </p>
                                        </Space>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                        <Space className="pt-3 w-100 infohistory" >
                            <Row gutter={16} className="w-100"> 
                                <Col span={12}>
                                    <Card title={<h6>Dependent Details/ Next of Kin / Emergency Contact</h6>} className="border" style={{ boxShadow:'none', borderRadius:0 }}>
                                        <Table bordered={false} columns={dependent} dataSource={dependantdata} className="w-100" pagination={false} scroll={false} />
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card title={<h6>Leave History</h6>} className="border" style={{ boxShadow:'none', borderRadius:0 }}>
                                        <Table bordered={false} columns={columns} dataSource={pendingrequest} className="w-100" pagination={false} scroll={false} />
                                    </Card>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                // =================================================================================
                // =================================================================================
                // =================================================================================
                :user.role==2 ?
                    // Direct Line Managers
                    <Col span={18} className="d-flex flex-column justify-content-between">
                        <Space className="leaveinfo d-flex flex-column justify-content-center border w-100">
                            <Row className="w-100">
                                <Col span={17} className="border-right" >
                                    <Space className="space-form p-4" align="start">
                                        <Space align="start" className="ml-0 w-100 justify-content-between calendar-title">
                                            <h6 onClick={this.handleShowCalendar} style={{cursor:'pointer'}}>Calendar<i class="bi bi-arrows-fullscreen ml-2 fs-6"></i></h6>
                                            <Button type="text" className="ml-auto mt-0 d-flex"><i class="bi bi-funnel mr-1"></i>Filter</Button>
                                        </Space>
                                        <Calendar fullscreen={false} />
                                    </Space>
                                    
                                </Col>
                                <Col span={7} className="d-flex flex-column pendingrequest">
                                    <h6>Pending Requests</h6>
                                    <Space className='requestswrap' direction="vertical">
                                        <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Darren Jarvis</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-bandaid"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Georgia Peacock</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat sick'>Sick Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Rachel Edwards</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Shane Jewell</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Craig Scrim</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-house"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Reece Wallace</p>
                                                    <p className='font-10 opacity-5'>30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat wfh'>WFH</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-bandaid"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Kristina Scalicka</p>
                                                    <p className='font-10 opacity-5'>30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat sick'>Sick Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Darren Jarvis</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>WFH</p></Col>
                                            </Row>  
                                        </Space>
                                    </Space>
                                    <Space align="start" className='ml-0 my-1'>
                                        <Button type="link" style={{color:'#151515'}} className='mt-0'>See All Requests (10) <i class="bi bi-arrow-right ml-1"></i></Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                        <Space className="pt-3 w-100 infohistory" >
                            <Row gutter={16} className="w-100"> 
                                <Col span={12} className="leavehistory">
                                    <Card title={
                                        <Space align='start' className="d-flex flex-row justify-content-between w-100" >
                                            <h6>Leave History</h6>
                                        <Radio.Group defaultValue="a" buttonStyle="solid" className="ant-leave-filter">
                                            <Radio.Button value="a">All</Radio.Button>
                                            <Radio.Button value="b">Leave</Radio.Button>
                                            <Radio.Button value="c">Sick</Radio.Button>
                                        </Radio.Group>
                                        </Space>
                                    } className="border" style={{ boxShadow:'none', borderRadius:0 }}>
                                        {pendingrequest && pendingrequest.length> 0 ?
                                            <Table bordered={false} columns={columns} dataSource={pendingrequest} className="w-100" pagination={false} scroll={false} />
                                        :<Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{ height: 60 }}
                                        description={ <span> You don't have any leaves yet </span> } className="mt-4">
                                        <Button type="secondary" className="mx-auto">Request Now</Button>
                                      </Empty>
                                        }
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Row className="h-100">
                                        <Col span={24} className="border myupcomingleave">
                                            <Card title={<h6>Your Upcoming Leave</h6>}  style={{ boxShadow:'none', borderRadius:0 }} bordered={false}>
                                                <Table bordered={false} columns={columns} dataSource={upcomingleave} className="w-100" pagination={false} scroll={false} />
                                            </Card>
                                        </Col>
                                        <Col span={24} className="border mypendingrequest" style={{borderTop:0}}>
                                        <Card title={<h6>Your Pending Request</h6>} style={{ boxShadow:'none', borderRadius:0 }} bordered={false}>
                                                <Table bordered={false} columns={columns} dataSource={pendingrequest} className="w-100" pagination={false} scroll={false} />
                                            </Card>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                
                            </Row>
                        </Space>
                    </Col>
                    
                // =================================================================================
                // =================================================================================
                // =================================================================================
                :user.role==3 ?<Col span={24} className="superadminwrap"><Row gutter={16} >
                    <Col span={18} >
                        <h4 onClick={this.handleShowCalendar} style={{cursor:'pointer', width:'fit-content'}} className="mb-3">Calendar<i class="bi bi-arrows-fullscreen ml-2 fs-6"></i></h4>
                        <Calendar fullscreen={false} className="calwrap" />
                    </Col>
                    <Col span={6} className="d-flex flex-column pendingrequest p-0">
                        <Row>
                            <Col span={24} className="border">
                                <h6>Pending Requests</h6>
                                <Space className='requestswrap' direction="vertical">
                                    <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                        <Row className='d-flex flex-row w-100'>
                                            <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                            <Col span={12}>
                                                <p className='font-bold'>Darren Jarvis</p>
                                                <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                            </Col>
                                            <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                        </Row>  
                                    </Space>
                                    <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                        <Row className='d-flex flex-row w-100'>
                                            <Col span={4}><i class="bi bi-bandaid"></i></Col>
                                            <Col span={12}>
                                                <p className='font-bold'>Georgia Peacock</p>
                                                <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                            </Col>
                                            <Col><p className='leavestat sick'>Sick Leave</p></Col>
                                        </Row>  
                                    </Space>
                                    <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                        <Row className='d-flex flex-row w-100'>
                                            <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                            <Col span={12}>
                                                <p className='font-bold'>Rachel Edwards</p>
                                                <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                            </Col>
                                            <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                        </Row>  
                                    </Space>
                                    <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Shane Jewell</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Craig Scrim</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-house"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Reece Wallace</p>
                                                    <p className='font-10 opacity-5'>30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat wfh'>WFH</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-bandaid"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Kristina Scalicka</p>
                                                    <p className='font-10 opacity-5'>30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat sick'>Sick Leave</p></Col>
                                            </Row>  
                                        </Space>
                                        <Space className='requestitem' align="start">
                                            <Row className='d-flex flex-row w-100'>
                                                <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                                <Col span={12}>
                                                    <p className='font-bold'>Darren Jarvis</p>
                                                    <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                                </Col>
                                                <Col><p className='leavestat annual'>WFH</p></Col>
                                            </Row>  
                                        </Space>
                                </Space>
                                <Space align="start" className='ml-0 my-1'>
                                    <Button type="link" style={{color:'#151515'}} className='mt-0'>See All Requests (10) <i class="bi bi-arrow-right ml-1"></i></Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                
                <Row className="w-100 d-flex flex-row mt-4 mx-0 bottomeventswrap justify-content-between" gutter={16}>
                    <Col span={6} className="border">
                        <h6>Today Events</h6>
                        <Space direction="vertical" align="start" className="px-3">
                            <Row className="btmevents birthday" gutter={16}>
                                <Col><p className="font-bold border-right">17 Aug</p></Col>
                                <Col className="d-flex flex-column">    
                                    <p className="holidaytype"><i class="bi bi-cake2"></i> Birthday</p>
                                    <p>Natalie White</p>
                                </Col>
                            </Row>
                            <Row className="btmevents joining" gutter={16}>
                                <Col><p className="font-bold  border-right">17 Aug</p></Col>
                                <Col className="d-flex flex-column">    
                                    <p className="holidaytype"><i class="bi bi-cake2"></i> Joining Anniversary</p>
                                    <p>Liam Ure</p>
                                </Col>
                            </Row>
                            <Row className="btmevents labour" gutter={16}>
                                <Col><p className="font-bold border-right">17 Aug</p></Col>
                                <Col className="d-flex flex-column">    
                                    <p className="holidaytype"><i class="bi bi-credit-card"></i> Labour Card</p>
                                    <p>Liam Ure</p>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                    <Col span={6} className="border">
                        <h6>Upcoming Events</h6>
                        <Space direction="vertical" align="start" className="px-3">
                            <Row className="btmevents birthday" gutter={16}>
                                <Col><p className="font-bold border-right">19 Aug</p></Col>
                                <Col className="d-flex flex-column">    
                                    <p className="holidaytype"><i class="bi bi-cake2"></i> Birthday</p>
                                    <p>Natalie White</p>
                                </Col>
                            </Row>
                            <Row className="btmevents joining" gutter={16}>
                                <Col><p className="font-bold  border-right">21 Aug</p></Col>
                                <Col className="d-flex flex-column">    
                                    <p className="holidaytype"><i class="bi bi-person-vcard"></i> Emirates ID</p>
                                    <p>Rabab Gulamhussein</p>
                                </Col>
                            </Row>
                            <Row className="btmevents labour" gutter={16}>
                                <Col><p className="font-bold border-right">21 Aug</p></Col>
                                <Col className="d-flex flex-column">    
                                    <p className="holidaytype"><i class="bi bi-card-text"></i> Visa</p>
                                    <p>Rabab Gulamhussein</p>
                                </Col>
                            </Row>
                            <Row className="btmevents birthday" gutter={16}>
                                <Col><p className="font-bold border-right">22 Aug</p></Col>
                                <Col className="d-flex flex-column">    
                                    <p className="holidaytype"><i class="bi bi-cake2"></i> Birthday</p>
                                    <p>Mark Andrew Richards</p>
                                    <p>Joanette Bautista</p>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                    <Col span={11} className="border">
                        <h6>People who are on leave today</h6>
                        <Space className='leavetodaywrap' direction="vertical">
                            <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                <Row className='d-flex flex-row w-100'>
                                    <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                    <Col span={12}>
                                        <p className='font-bold'>Darren Jarvis</p>
                                        <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                    </Col>
                                    <Col><p className='leavestat annual'>Annual Leave</p></Col>
                                </Row>  
                            </Space>
                            <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                <Row className='d-flex flex-row w-100'>
                                    <Col span={4}><i class="bi bi-suitcase-lg"></i></Col>
                                    <Col span={12}>
                                        <p className='font-bold'>Naz Acar</p>
                                        <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                    </Col>
                                    <Col><p className='leavestat wfh'>Work from home</p></Col>
                                </Row>  
                            </Space>
                            <Space className='requestitem' align="start" onClick={this.handleShowPendingRequest}>
                                <Row className='d-flex flex-row w-100'>
                                    <Col span={4}><i class="bi bi-bandaid"></i></Col>
                                    <Col span={12}>
                                        <p className='font-bold'>Georgia Peacock</p>
                                        <p className='font-10 opacity-5'>03 Sept 2023 - 30 Sept 2023</p>
                                    </Col>
                                    <Col><p className='leavestat sick'>Sick Leave</p></Col>
                                </Row>  
                            </Space>
                        </Space>
                    </Col>
                </Row>
                
                </Col>:null}
            </Row>



        </>)
    }

}

export default (HR_Dashboard)