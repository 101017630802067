import React, { Component } from 'react'
import { Breadcrumb,Tabs,Select,Button,Input} from 'antd';
import {PhoneOutlined,WhatsAppOutlined,MailOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { TextArea } = Input;

 class EditListing extends Component {
  render() {

    const items = [
        {
          key: '1',
          label: `log call`,
          
        },
        {
          key: '2',
          label: `Create notes`,
        },
        {
          key: '3',
          label: `Send whatsapp`,
        },
        {
          key: '4',
          label: `Send email`,
        },
   
      ];

      const logsitems = [
        {
          key: '1',
          label: `all (10)`,
          
        },
        {
          key: '2',
          label: <PhoneOutlined />,
        },
        {
          key: '3',
          label: <WhatsAppOutlined />,
        },
        {
          key: '4',
          label: <MailOutlined />,
        },
   
      ];
    return (
        <div>
        <Breadcrumb
         style={{
           margin: '16px 0',
         }}
       >
          <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/all_listings">Listings</Link></Breadcrumb.Item>
         <Breadcrumb.Item>Listing Details </Breadcrumb.Item>
         
       </Breadcrumb>
       <div style={{
           padding: 24,
           minHeight: 380,
          // background: "#fff",
         }}>
       
         <div className='row'>
            <div className='col-md-3'>
            <div class="card">
                
                <div class="card-body">
                    <h5>Owner's details</h5>
                    <hr />

                    <div className='form-group'>
                        <label>Name</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className='form-group'>
                        <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>

                    <div className='form-group'>
                        <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>

                    <div className='mt-3'>
                      <p>Owner's Other Properties</p>
                      <hr />
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Ref no</th>
                          <th scope="col">Unit no</th>
                          <th scope="col">Community</th>
                          <th scope="col">Sub community</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td>Otto</td>
                          <td>@mdo</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Jacob</td>
                          <td>Thornton</td>
                          <td>@fat</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td colspan="2">Larry the Bird</td>
                          <td>@twitter</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>

                    <div className='mt-5'>
                      <h5>Property details</h5>
                     
                      <hr />

                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <label>Agent name</label>
                            <Select
                      defaultValue="lucy"
                      style={{
                        width: 380,
                      }}
                      onChange="{handleChange}"
                      options={[
                        {
                          value: 'jack',
                          label: 'Jack',
                        },
                        {
                          value: 'lucy',
                          label: 'Lucy',
                        },
                        {
                          value: 'Yiminghe',
                          label: 'yiminghe',
                        },
                        {
                          value: 'disabled',
                          label: 'Disabled',
                          disabled: true,
                        },
                      ]}
                    />
                          </div>

                          <div className='form-group mt-3'>
                            <label>Unit no</label>
                            <input type="text" className="form-control" />
                          </div>

                          <div className='form-group mt-3'>
                            <label>Community</label>
                            <Select
                              defaultValue="lucy"
                              style={{
                                width: 380,
                              }}
                              onChange="{handleChange}"
                              options={[
                                {
                                  value: 'jack',
                                  label: 'Jack',
                                },
                                {
                                  value: 'lucy',
                                  label: 'Lucy',
                                },
                                {
                                  value: 'Yiminghe',
                                  label: 'yiminghe',
                                },
                                {
                                  value: 'disabled',
                                  label: 'Disabled',
                                  disabled: true,
                                },
                              ]}
                            />
                          </div>

                          <div className='form-group mt-3'>
                            <label>Sub community</label>
                            <Select
                              defaultValue="lucy"
                              style={{
                                width: 380,
                              }}
                              onChange="{handleChange}"
                              options={[
                                {
                                  value: 'jack',
                                  label: 'Jack',
                                },
                                {
                                  value: 'lucy',
                                  label: 'Lucy',
                                },
                                {
                                  value: 'Yiminghe',
                                  label: 'yiminghe',
                                },
                                {
                                  value: 'disabled',
                                  label: 'Disabled',
                                  disabled: true,
                                },
                              ]}
                            />
                          </div>

                          <div className='form-group mt-3'>
                            <label>Property</label>
                            <Select
                              defaultValue="lucy"
                              style={{
                                width: 380,
                              }}
                              onChange="{handleChange}"
                              options={[
                                {
                                  value: 'jack',
                                  label: 'Jack',
                                },
                                {
                                  value: 'lucy',
                                  label: 'Lucy',
                                },
                                {
                                  value: 'Yiminghe',
                                  label: 'yiminghe',
                                },
                                {
                                  value: 'disabled',
                                  label: 'Disabled',
                                  disabled: true,
                                },
                              ]}
                            />
                          </div>

                          <div className='form-group mt-3'>
                            <label>Beds</label>
                            <Select
                              defaultValue="lucy"
                              style={{
                                width: 380,
                              }}
                              onChange="{handleChange}"
                              options={[
                                {
                                  value: 'jack',
                                  label: 'Jack',
                                },
                                {
                                  value: 'lucy',
                                  label: 'Lucy',
                                },
                                {
                                  value: 'Yiminghe',
                                  label: 'yiminghe',
                                },
                                {
                                  value: 'disabled',
                                  label: 'Disabled',
                                  disabled: true,
                                },
                              ]}
                            />
                          </div>

                          <div className='form-group mt-3'>
                            <label>Price</label>
                             <input type="text" className="form-control" />
                          </div>

                          <div className='form-group mt-3'>
                            <label>Tags</label>
                            <TextArea rows={3}   />
                          </div>


                        </div>
                      </div>
                    </div>
                   
                </div>
                </div>    


              </div>
            <div className='col-md-6'>
            <div class="card">
               
                <div class="card-body" >
                <h4 style={{margin:'15px'}}>Timeline</h4>
                <Tabs style={{color:'grey'}} defaultActiveKey="1" items={items} onChange={this.handleTabs}>

               </Tabs>

               
               <div className='row mt-2'>
                  <div className='col-md-3'>
                <Button style={{backgroundColor:'#151515'}} type="primary">Add call log</Button>
                </div>
                </div>
                <div className='mt-3'>
                <Tabs style={{color:'grey'}} defaultActiveKey="1" items={logsitems} onChange={this.handleTabs}>
               </Tabs>
               </div>
                </div>
               
                </div>  
            </div>
            <div className='col-md-3'>
            <div class="card">
                
                <div class="card-body">
                <h5>Match details</h5>
                <hr />
                <div className='row'>
                  <div className='col-md-6'>
                  <div className='form-group'>
                    <label>Community</label>
                    <Select
                      showSearch
                      style={{
                        width: 190,
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={[
                        {
                          value: '1',
                          label: 'Not Identified',
                        },
                        {
                          value: '2',
                          label: 'Closed',
                        },
                        {
                          value: '3',
                          label: 'Communicated',
                        },
                        {
                          value: '4',
                          label: 'Identified',
                        },
                        {
                          value: '5',
                          label: 'Resolved',
                        },
                        {
                          value: '6',
                          label: 'Cancelled',
                        },
                      ]}
                    />
                  </div>
                  </div>
                  <div className='col-md-6'>
                  <div className='form-group'>
                    <label>Sub community</label>
                    <Select
                        showSearch
                        style={{
                          width: 188,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={[
                          {
                            value: '1',
                            label: 'Not Identified',
                          },
                          {
                            value: '2',
                            label: 'Closed',
                          },
                          {
                            value: '3',
                            label: 'Communicated',
                          },
                          {
                            value: '4',
                            label: 'Identified',
                          },
                          {
                            value: '5',
                            label: 'Resolved',
                          },
                          {
                            value: '6',
                            label: 'Cancelled',
                          },
                        ]}
                      />
                  </div>
                    </div>
                </div>

                <div className='row mt-3'>
                <label>Budget</label>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      
                      <input type="text" className="form-control" placeholder='Min'/>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='form-group'>
                      
                      <input type="text" className="form-control" placeholder='Max'/>
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-12'>
                  <label>Beds</label>
                    <div className='form-group'>
                    
                      <Select
                      defaultValue="lucy"
                      style={{
                        width: 380,
                      }}
                      onChange="{handleChange}"
                      options={[
                        {
                          value: 'jack',
                          label: 'Jack',
                        },
                        {
                          value: 'lucy',
                          label: 'Lucy',
                        },
                        {
                          value: 'Yiminghe',
                          label: 'yiminghe',
                        },
                        {
                          value: 'disabled',
                          label: 'Disabled',
                          disabled: true,
                        },
                      ]}
                    />
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-6'>
                  <div className='form-group'>
                    <label>Property for</label>
                    <Select
                      showSearch
                      style={{
                        width: 190,
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={[
                        {
                          value: '1',
                          label: 'Not Identified',
                        },
                        {
                          value: '2',
                          label: 'Closed',
                        },
                        {
                          value: '3',
                          label: 'Communicated',
                        },
                        {
                          value: '4',
                          label: 'Identified',
                        },
                        {
                          value: '5',
                          label: 'Resolved',
                        },
                        {
                          value: '6',
                          label: 'Cancelled',
                        },
                      ]}
                    />
                  </div>
                  </div>
                  <div className='col-md-6'>
                  <div className='form-group'>
                    <label>Type</label>
                    <Select
                        showSearch
                        style={{
                          width: 188,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={[
                          {
                            value: '1',
                            label: 'Not Identified',
                          },
                          {
                            value: '2',
                            label: 'Closed',
                          },
                          {
                            value: '3',
                            label: 'Communicated',
                          },
                          {
                            value: '4',
                            label: 'Identified',
                          },
                          {
                            value: '5',
                            label: 'Resolved',
                          },
                          {
                            value: '6',
                            label: 'Cancelled',
                          },
                        ]}
                      />
                  </div>
                    </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-12'>
                  <label>Addes date</label>
                    <div className='form-group'>
                    
                      <Select
                      defaultValue="lucy"
                      style={{
                        width: 380,
                      }}
                      onChange="{handleChange}"
                      options={[
                        {
                          value: 'jack',
                          label: 'Jack',
                        },
                        {
                          value: 'lucy',
                          label: 'Lucy',
                        },
                        {
                          value: 'Yiminghe',
                          label: 'yiminghe',
                        },
                        {
                          value: 'disabled',
                          label: 'Disabled',
                          disabled: true,
                        },
                      ]}
                    />
                    </div>
                  </div>
                </div>
                      <div className='row mt-5'>

                        
                        <Button style={{backgroundColor:'#151515'}} type="primary">Match</Button>
                       
                        
                      </div>

                      <div className='row mt-3'>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}> 
                        <div>
                        Match with access
                        </div>
                          <div>
                            <Link >
                            View all (0)
                            </Link>
                            
                             </div>
                         
                        </div>
                        <hr />

                        <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Ref no</th>
                          <th scope="col">Unit no</th>
                          <th scope="col">Community</th>
                          <th scope="col">Sub community</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td>Otto</td>
                          <td>@mdo</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Jacob</td>
                          <td>Thornton</td>
                          <td>@fat</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td colspan="2">Larry the Bird</td>
                          <td>@twitter</td>
                        </tr>
                      </tbody>
                    </table>

                      

                      </div>
                   
                </div>
                </div>  
            </div>
         </div>

       </div>
       </div>
    )
  }
}

export default EditListing
