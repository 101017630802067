const initialState = {
    leads: "",
    listings:"",
    deals:"",
    community_id:"",
    subcommunity_id:"",
    property_id:"",
    property_for:[],
    agent_value:[],
    listing_array:[],
    deals_listing_array:[],
    publish_date_morethan_option:false,
    publish_date_lessthan_option:false,
    publish_date_value_id:1,
    publish_date_value:"",
    added_date_morethan_option:false,
    added_date_lessthan_option:false,
    added_date_value_id:1,
    added_date_value:"",
    available_date_morethan_option:false,
    available_date_lessthan_option:false,
    available_date_value_id:1,
    available_date_value:"",
    provider:[]
  };
  const Stock_Area_Reducer = (state = initialState, action) => {
    
    switch (action.type) {
      case "SET_LEADS":
        return {
          ...state,
          listing_array: action?.payload ? action?.payload.listing_array:[],
          provider :action?.payload ? action?.payload.provider:[],
          agent_value:action?.payload ? action?.payload.agent_value:[],
        };
        case "REMOVE_LEADS_STOCK_AREA_REDUCER":
            return {
              ...state,
              listing_array:[],
              provider:[],
              agent_value:[]
            };
        
        case "SET_DEALS":
            return {
              ...state,
              deals_listing_array: action?.payload ? action?.payload.listing_array:[],
              deals_listing_id: action?.payload ? action?.payload.listing_id:[],
              provider :action?.payload ? action?.payload.provider:[],
            };
            case "REMOVE_DEALS_STOCK_AREA_REDUCER":
                return {
                  ...state,
                  deals_listing_array:[],
                  provider:[]
                };

                

              
      default:
        return state;
    }
  };
  
  export default Stock_Area_Reducer;
  

  