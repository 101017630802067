import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'antd/dist/reset.css';
import {
    Table,
    // Tabs,
    Space,
    Button,
    Modal,
    Select,
    Drawer,
    Input,
    Avatar,
    Divider,
    DatePicker,
    Tooltip,
    Mentions,
    Spin,
    Radio, Row, Col, Upload
} from 'antd'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { FilterOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined, CheckOutlined, CloseOutlined, EditOutlined, SettingFilled, DeleteFilled, SearchOutlined, ClearOutlined, PaperClipOutlined, UploadOutlined, FileSearchOutlined,DeleteOutlined, ShareAltOutlined } from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import { baseurlImg } from '../../components/BaseUrl';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import EdiText from "react-editext";
import validator from 'validator'
import parse from 'html-react-parser';
import BeatLoader from "react-spinners/BeatLoader";
import CurrencyInput from 'react-currency-input-field';
import EasyEdit, { Types } from 'react-easy-edit';
import CalenderEvent from "../../components/CalenderEvent";
// import './index.css';
// import './listing.css'
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import {baseLpUrlImg, liveUrl} from '../../components/BaseUrl';
import { store } from '../../store';
import ApiCalendar from "react-google-calendar-api";
import { end } from '@popperjs/core';


const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const width = window.screen.width;
// let columns = []
let property_type_array = ["Residential", "Commercial"]
let property_for_array = ["sale", "rent", "tenanted", "short-term"]

const config = {
    "clientId": '8974307049-e0ug8edud9gp4ahcnah13i5kk8opivnh.apps.googleusercontent.com',
    "apiKey": 'AIzaSyAbmWx3GOaQ_HU-lD4wViBmV4kKNeWdk0Y',
    "scope": "https://www.googleapis.com/auth/calendar",
    "discoveryDocs": [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ]
}

const apiCalendar = new ApiCalendar(config)

class MatchedListLeads extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 2,
            vendor: "",
            isViewing: false,
            isLoading: false,
            decline_note: "",
            isComparableLoading: false,
            isNextLoading: false,
            isPreviousLoading: false,
            remainingTime: "00:00:00",
            contact_type_call_note_status : "",
            isRequestviewingOpen: false,
            isdecline:false,
            role: "",
            agent_id: "",
            isWhatsappLoading: false,
            rows: [],
            selectedLeads: [],
            comparable_leads_count:0,
            isCallLogOpen:false,
            sSharetoWhatsApp:false,
            leads_count:0,
            isViewAsGallery: true,
            sendEvent: false,
            mylistings: [],
            propertytypes: [],
            validationerrors: {},
            followup_data: [],
            property_viewings: [],
            property_offers: [],
            collaborators: [],
            followups: [],
            offers: [],
            followups_details: [],
            ref_nums: [],
            unit_nums: [],
            userClients: [],
            selectedClients: [],
            selectedCallUsers: [],
            selectedNoteUsers: [],
            selectedWhatsappUsers: [],
            selectedEmailUsers: [],
            curr: "",
            requested_viewings: [],
            comment: "",
            titleHasError:false,
            ismourequestOpen:"",
            sellers:[],
            sellersdata:[],

            selectedViewingUsers: [],
            selectedOfferUsers: [],
            selectedFollowupUsers: [],

            newArrSelectedAgents: [],
            selected_agent: "",
            followup_for: "",
            reminder: "",

            ref_def_nums: [],
            old_unit_num: [],
            viewings_listings: [],
            viewing_ref_no: "",
            viewing_unit_no: "",
            viewing_agent: "",
            viewing_date: "",
            viewing_note: "",
            viewing_status: "",
            viewing_price: "",
            showPrice: false,
            showDate: false,
            community_from_requirement: "",
            sub_community_from_requirement: "",
            min_from_requirement: "",
            max_from_requirement: "",
            bedroom_from_requirement: "",
            property_for_from_requirement: "",
            property_type_from_requirement: "",
            added_date_from_requirement: "",
            contact_type_requirement:"",
            price_requirement: "",

            lead_agent: "",
            lead_community: "",
            lead_sub_community: "",
            //followup
            followup_note: "",
            followup_date: "",
            leads: [],
            comparable_leads: [],
            lead_details: {},
            viewings: {},
            all: [],
            notes: [],
            agents: [],
            call_notes: [],
            whatsapp_notes: [],
            email_notes: [],
            viewing_notes: [],
            offer_notes: [],
            followup_notes: [],
            communities: [],
            sub_communities: [],
            properties: [],
            all_sub_communities: [],
            requirement_sub_communities: [],
            filters: [],
            greaterthanprice: false,
            leassthanprice: false,
            inbetweenprice: false,
            lessValue: 0,
            greaterValue: 0,

            owner_id: "",
            owner_name: "",
            owner_email: "",
            owner_mobile: "",

            community_match: "",
            sub_community_match: "",
            min_budget: 0,
            max_budget: 0,
            bedroom_match: "",
            property_for_match: "",
            property_type_match: "",
            added_date_match: "",
            date_morethan_option_match: true,
            date_lessthan_option_match: false,
            date_value_match: 0,
            date_value_id_match: 1,
            matchs: [],
            not_matchs: [],
            owner_other_properties: [],


            // requirements

            requirement_min_budget: 0,
            requirement_max_budget: 0,
            requirement_lead_stage: "",
            requirement_community: "",
            requirement_sub_community: "",
            requirement_verifyedby: "",
            requirement_agent_id: "",
            requirement_move_in_date: "",
            requirement_bedroom: "",


            // filters option

            // price

            price_first_value_id: 1,
            price_second_value_id: 1,
            price_active_second_option: false,
            price_and_or_option: true,
            price_first_value: "",
            price_second_value: "",
            inrange: false,
            price_min: 0,
            price_max: 0,

            viewing_value:"",
            viewing_morethan_value:true,
            viewing_lessthan_value:false,
            viewing_option_value:1,
            inrange_viewing:false,
            viewing_min_value:"",
            viewing_max_value:"",

            offer_value:"",
            offer_morthan_value:true,
            offer_lessthan_value:false,
            offer_option_value:1,
            inrange_offer :false,
            offer_min_value:"",
            offer_max_value:"",
            // date

            date_first_value_id: 1,
            date_second_value_id: 1,
            date_active_second_option: false,
            date_and_or_option: true,
            date_first_value: "",
            date_second_value: "",
            date_morethan_option: true,
            date_lessthan_option: false,
            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            date_value: "",
            date_value_id: 1,

            last_call_morethan_option: true,
            last_call_lessthan_option: false,
            last_call_value: 0,
            last_call_value_id: 1,
            last_call_option_value:1,

            last_match_morethan_option: true,
            last_match_lessthan_option: false,
            last_match_value: 0,
            last_match_value_id: 1,
            last_match_option_value:1,

            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            move_in_date_value: "",
            move_in_date_value_id: 1,
            move_in_date_option_value:1,
            inrange_move_in_date:false,
            move_in_date_date_range:[],
            move_in_date_is_empty:false,

            // add new filter
            isFilterOpen: false,
            isFilterEditOpen: false,
            filter_id: 0,
            filter_title: "",
            contact_type_id: 0,
            community_value_id: 0,
            sub_community_value_id: 0,
            price_value_id: 0,
            price_value: "",
            bed_rooms_value_id: 1,
            bed_rooms_value: "",
            created_value_id: 0,
            created_value: "",
            stage_value_id: 0,
            stage_value: [],
            contact_types_value: [],
            furnished_types_value: [],
            property_type_filter: [],

            default_stage_value: [],
            default_contact_types_value: [],
            default_furnished_types_value: [],

            name_value_id: 1,
            name_value: "",

            refno_value_id: 1,
            refno_value: "",

            phone_value_id: 1,
            phone_value: "",

            agent_value_id: 1,
            agent_value: [],

            // drawer for details
            open: false,

            record_id: '',
            view_id: 0,
            isviewingOpen: false,
            iseditviewingOpen: false,
            isOfferOpen: false,
            isfollowupOpen: false,
            isfollowupViewOpen: false,

            lead_stage_filter:[
                { label: 'None', value: '0' },
                { label: 'New Inquiry', value: '1' },
                { label: 'Working', value: '2' },
                { label: 'Hot', value: '3' },
                { label: 'Follow Up', value: '4' },
                { label: 'Dead', value: '5' },
                { label: 'Recruitment', value: '9' },
                { label: 'No Answer', value: '10' },
                { label: 'Expired', value: '11' },
                { label: 'Pond', value: '12' },
                ,
                {
                    value: '13',
                    label: 'Offer accepted',
                },
                {
                    value: '14',
                    label: 'Offer declined',
                },
            ],
            furnished_types: [
                {
                    value: '1',
                    label: 'Furnished'
                },
                {
                    value: '2',
                    label: 'Unfurnished'
                }
            ],

            contact_types: [
                {
                    value: '1',
                    label: 'Tenant'
                },
                {
                    value: '2',
                    label: 'Buyer'
                },
                {
                    value: '3',
                    label: 'Landlord'
                },
                {
                    value: '4',
                    label: 'Seller'
                },
                {
                    value: '5',
                    label: 'Landlord+Seller'
                },
                {
                    value: '6',
                    label: 'Agent'
                },
                {
                    value: '7',
                    label: 'Other'
                },
                {
                    value: '8',
                    label: 'Portal'
                },
                {
                    value: '9',
                    label: 'Buyer/Tenant'
                },
                {
                    value: '10',
                    label: 'Unrecognized'
                }
            ],

            stages: [
                {
                    value: '1',
                    label: 'New Inquiry',
                },
                {
                    value: '2',
                    label: 'Working',
                },
                {
                    value: '3',
                    label: 'Hot',
                },
                {
                    value: '4',
                    label: 'Follow Up',
                },
                // {
                //     value: '5',
                //     label: 'Dead',
                // },
                // {
                //     value: '6',
                //     label: 'Offer',
                // },
                // {
                //     value: '7',
                //     label: 'Deal signed',
                // },
                {
                    value: '8',
                    label: 'Viewings',
                },
                {
                    value: '9',
                    label: 'Recruitment',
                },
                {
                    value: '10',
                    label: 'No Answer',
                },
                {
                    value: '11',
                    label: 'Expired',
                },
                {
                    value: '12',
                    label: 'Pond',
                },
                {
                    value: '13',
                    label: 'Offer accepted',
                },
                {
                    value: '14',
                    label: 'Offer declined',
                },
            ],

            filter_options: [


                {
                    value: '1',
                    label: 'Contact type',
                },

                {
                    value: '2',
                    label: 'Community / Sub community',
                },
                {
                    value: '3',
                    label: 'Price',
                },
                {
                    value: '4',
                    label: 'Bedrooms',
                },
                {
                    value: '5',
                    label: 'Created',
                },
                {
                    value: '6',
                    label: 'Stage',
                },
                {
                    value: '7',
                    label: 'Furnished  / unfurnished',
                },
                {
                    value: '8',
                    label: 'Agents',
                },
                {
                    value: '9',
                    label: 'Viewings',
                },
                {
                    value: '10',
                    label: 'Offers',
                },
                {
                    value: '11',
                    label: 'Type',
                },
                {
                    value: '12',
                    label: 'Last call',
                },
                {
                    value: '13',
                    label: 'Last match',
                },
                {
                    value: '14',
                    label: 'Move in date',
                },



            ],
            price_options: [


                {
                    value: '1',
                    label: 'Equals',
                },
                {
                    value: '2',
                    label: 'Not equal',
                },
                {
                    value: '3',
                    label: 'Less than',
                },
                {
                    value: '4',
                    label: 'Less than or equals',
                },
                {
                    value: '5',
                    label: 'Greater than',
                },
                {
                    value: '6',
                    label: 'Greater than or equals',
                },

                {
                    value: '7',
                    label: 'In range',
                },

            ],

            name_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            phone_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            bedroom_filter_options: [
                {
                    value: '1',
                    label: 'Equals',
                },
                {
                    value: '2',
                    label: 'Not equal',
                },
                {
                    value: '3',
                    label: 'Less than',
                },
                {
                    value: '4',
                    label: 'Greater than',
                },


            ],
            agent_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            // add notes
            call_log: "",
            note_log: "",
            whatsapp_log: "",
            email_log: "",
            followup_log: '',
            emailSubject:"",

            // Loaders
            call_loader: false,
            note_loader: false,
            whatsapp_loader: false,
            email_loader: false,
            offer_loader: false,
            viewing_loader: false,
            followUp_loader: false,

            // Error
            checkErr: false,
            // IDs
            nextId: '',
            prevId: '',
            currId: '',

            // states for disabled
            disabledNext: false,
            disabledPrev: false,

            // Farooq
            currUrl: "",
            loader: false
        }
        this.textInput = React.createRef();

    }

    componentDidMount() {
     

        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        let id = this.props.match.params.id;
        const page = 1;
        const perPage = 10;
        const data = {
             id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }

        this.fetchAllAgents();
        this.fetchFilter();
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);

        // setTimeout(() => {
            this.fetchAllRequest();
        // }, 2000);


    }

    fetchAllAgents = () => {
        API.get("/all_agents")
            .then((response) => {
                    if (response.data.success) {
                        this.setState({ agents: response.data.agents })
                    }
                }
            )
    }

    fetchAllRequest = () => {
        API.get("/community")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ communities: response.data.communities })
                }
            })

        API.get("/all_sub_community")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ sub_communities: response.data.sub_communities })
                }
            })
        API.get("/all_properties")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ properties: response.data.properties })
                }
            })
        API.get("/propertytypes")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ propertytypes: response.data.propertytypes })
                }
            })



        API.get('/all_client_users').then((response) => {
            if (response.data.success) {
                const data = response.data.users
                for (let i = 0; i < data.length; i++) {
                    const num = data[i].id
                    data[i].key = num.toString()

                }
                this.setState({ userClients: data })
            }
        })

    }





    fetchFilter = () => {
        API.get('/filters')
            .then((response) => {
                if (response.data.success) {
                    this.setState({ filters: response.data.filters })
                }
            })
    }
    handleAddRow = (value) => {
        const item = {
            name: "",
            mobile: "",
            id: value

        };

        let filter_options = this.state.filter_options.filter(item => item.value !== value)
        this.setState({
            rows: [...this.state.rows, item],
            filter_options: filter_options
        });
    };
    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };
    handleRemoveSpecificRow = (idx, id) => () => {
        const rows = [...this.state.rows]
        if (id == 1) {
            const item = {
                value: '1',
                label: 'Contact type'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        } else
        if (id == 2) {
            const item = {
                value: '2',
                label: 'Community / Sub community'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        } else
        if (id == 3) {
            const item = {
                value: '3',
                label: 'Price'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item],
                price_first_value_id: 0,
                price_first_value: 0,
                price_min: 0,
                price_max: 0
            })
        } else
        if (id == 4) {
            const item = {
                value: '4',
                label: 'Bedrooms'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        } else
        if (id == 5) {
            const item = {
                value: '5',
                label: 'Created'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        } else
        if (id == 6) {
            const item = {
                value: '',
                label: 'Stages'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        } else
        if (id == 7) {
            const item = {
                value: '7',
                label: 'Furnished / Unfurnished'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        }
        else
        if (id == 8) {
            const item = {
                value: '8',
                label: 'Agents'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        }
        else
        if (id == 9) {
            const item = {
                value: '9',
                label: 'Viewings'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        }
        else
        if (id == 10) {
            const item = {
                value: '10',
                label: 'Offers'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        }  else
        if (id == 12) {
            const item = {
                value: '12',
                label: 'Last call'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        }  else
        if (id == 13) {
            const item = {
                value: '13',
                label: 'Last match'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        } else
        if (id == 14) {
            const item = {
                value: '14',
                label: 'Move in date'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        }

        rows.splice(idx, 1)

        this.setState({ rows })
    }

    handleNewFilter = () => {
        this.setState({ isFilterOpen: true })

    }

    onClickRequestViewing = () => {
        if (this.state.selectedLeads.length <= 0){
            toast.error("Nothing is selected");
        }else{
            this.setState({ isLoading: true })
            const data = {
                "selected_leads" : this.state.selectedLeads,
                "listing_id" : this.props.match.params.id
            }
            API.post("/request_viewing", data)
                .then((response)=>{
                    if(response.data.success){
                        this.setState({ isLoading: false })
                        toast.success("Viewing is successfully requested")
                        API.post("/request_viewing_email", data)
                            .then((response)=>{
                                if(response.data.success){
                                    console.log(response)
                                }
                            }).catch((err) => {
                            console.log(err)
                        })
                    }
                }).catch((err) => {
                console.log(err)
                this.setState({ isLoading: false })
                toast.error('Something went wrong');
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        }
    }
    handlemourequests =(e) =>{
        this.setState({ismourequestOpen:true})
    }
    handlemourequestsok =(e) =>{
        this.setState({ismourequestOpen:false})
    }
    handlemourequestscancel =(e) =>{
        this.setState({ismourequestOpen:false})
    }

    handleEditFilter = (id, e) => {

        let filter_option = []

        const newrows = [
            {
                id: 1
            },
            { id: 2 },
            {
                id: 3,
            },
            { id: 4 },
            { id: 5 },
            {
                id: 6
            },
            { id: 7 }
        ]
        this.setState({ rows: newrows })

        this.setState({ isFilterEditOpen: true, filter_id: id })

        API.get("/get_saved_filter/" + id)
            .then((response) => {
                if (response.data.success) {
                    const response_filter = response.data.filter
                    // API.get("/sub_community/" + response_filter.community_value_id)
                    //   .then((response) => {
                    //     if (response.data.success)
                    //       this.setState({ sub_communities: response.data.sub_communities })
                    //   })


                    const stage = response.data.filter_stages
                    const contact_type = response.data.filters_contact_types
                    const furnished_unfurnished = response.data.filters_furnished_unfurnisheds


                    this.setState({
                        stage_value: stage, contact_types_value: contact_type, furnished_types_value: furnished_unfurnished,
                        filter_title: response_filter.title,
                        community_value_id: response_filter.community_value_id,
                        sub_community_value_id: response_filter.sub_community_value_id,

                        price_first_value_id: response_filter.price_first_value_id,
                        price_first_value: response_filter.price_first_value,
                        price_min: response_filter.price_min,
                        price_max: response_filter.price_max,
                        inrange: response_filter.price_first_value_id == 7 ? true : false,

                        date_morethan_option: response_filter.date_morethan_option,
                        date_lessthan_option: response_filter.date_lessthan_option,

                        move_in_date_morethan_option: response_filter.move_in_date_morethan_option,
                        move_in_date_lessthan_option: response_filter.move_in_date_lessthan_option,
                        date_value_id: response_filter.date_value_id,
                        date_value: response_filter.date_value,
                        move_in_date_date_range:this.state.move_in_date_date_range,

                        bed_rooms_value_id: response_filter.bed_rooms_value_id,
                        bed_rooms_value: response_filter.bed_rooms_value,
                        default_stage_value: response.data.filter_stages,
                        default_contact_types_value: response.data.filters_contact_types,
                        default_furnished_types_value: response.data.filters_furnished_unfurnisheds

                    })
                }
            })
    }

    handleEditOk = () => {
        const id = this.state.filter_id
        const data = {
            filter_title: this.state.filter_title,
            community_value_id: this.state.community_value_id,
            sub_community_value_id: this.state.sub_community_value_id,

            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            stage_value: this.state.stage_value,

            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,

        }

        API.put("/update_filter/" + id, data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ isFilterEditOpen: false, filter_title: "" })
                    this.fetchFilter();

                }
            })

    }

    handleOk = () => {

        if(this.state.filter_title==""){

            this.setState({titleHasError:true})
            return
        }

        const data = {


            filter_title: this.state.filter_title,
            community_value_id: this.state.community_value_id,
            sub_community_value_id: this.state.sub_community_value_id,

            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            stage_value: this.state.stage_value,

            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            agent_value:this.state.agent_value,
            property_type_filter:this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        API.post("/add_filter", data)
            .then((response) => {


                //return
                if (response.data.success) {
                    this.setState({ isFilterOpen: false, filter_title: "" })
                    this.fetchFilter();

                }
            })
    };
    handleCancel = () => {
        this.setState({ isFilterOpen: false, filter_title: '' })
    };

    handleEditCancel = () => {
        this.setState({ isFilterEditOpen: false, filter_title: '' })
    };

    handleFilterTitle = (e) => {
        this.setState({ filter_title: e.target.value,titleHasError:false })
    }

    handleContactType = (e) => {



        this.setState({ contact_types_value: e })


        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: e,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }




        this.fetchData(page, perPage, data)
        this.fetchComparable(page, perPage, data);
    }

    handleCommunity = (value) => {
        this.setState({ community_value_id: value })
        if(value !== null || value !== ''){
            API.get("/sub_community/" + value)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ sub_communities: response.data.sub_communities })
                })
        }
    }


    handleSubCommunity = (value) => {
        this.setState({ sub_community_value_id: value })
    }

    handleBedRooms_id = (e) => {

        this.setState({ bed_rooms_value_id: e })

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: e,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }




        if (this.state.bed_rooms_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);

    }

    handleBedRoomsValue = (e) => {

        this.setState({ bed_rooms_value: e.target.value })
        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: e.target.value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,


            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }


        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handleStages = (e) => {


        this.setState({ stage_value: e, default_stage_value: e })

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: e,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range

        }

        this.fetchData(page, perPage, data)
        this.fetchComparable(page, perPage, data);
    }
    handleFurnishedTypes = (e) => {
        this.setState({ furnished_types_value: e })
        let id = this.props.match.params.id;
        const page = 1;
        const perPage = 10;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: e,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        this.fetchData(page, perPage, data)
        this.fetchComparable(page, perPage, data);
    }

    handleDeleteFilter = (targetKey, action) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.delete("/delete_filter/" + targetKey)
                    .then((response) => {
                        if (response.data.success) {
                            toast.success("Successfully deleted")
                            this.fetchFilter()
                        }
                    })
            }
        })
    };

    // filter options

    handleNameValue_id = (e) => {
        this.setState({ name_value_id: e })

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: e,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }
        if (this.state.name_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleName = (e) => {

        this.setState({ name_value: e.target.value })
        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: e.target.value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handlePhoneValue_id = (e) => {
        this.setState({ phone_value_id: e })
        let id = this.props.match.params.id;
        const page = 1;
        const perPage = 10;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: e.target.value,
            phone_value_id: e,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }
        if (this.state.phone_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handlePhone = (e) => {

        this.setState({ phone_value: e.target.value })

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: e.target.value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }


        if (this.state.phone_value_id > 0)
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);

    }

    handleAgentValue_id = (e) => {

        this.setState({ agent_value_id: e })

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value:this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: e,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }
        if (this.state.agent_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }
    handleAgent = (e) => {
        this.setState({ agent_value: e })
        let id = this.props.match.params.id;
        const page = 1;
        const perPage = 10;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: e,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }
        this.fetchData(page, perPage, data)
        this.fetchComparable(page, perPage, data);
    }


    handlePropertyForFirstValue = (e) => {

        const property_for_first_value = e.target.value;
        if (property_for_first_value != '') {
            this.setState({ property_for_second_option: true })
        } else {
            this.setState({ property_for_second_option: false })
        }
    }

    handleRefnoFirst = (e) => {

        const refno_first_value = e.target.value;
        this.setState({ refno_first_value: refno_first_value })

        if (refno_first_value != '') {
            this.setState({ refno_active_second_option: true })
        } else {
            this.setState({ refno_active_second_option: false })
        }
        let id = this.props.match.params.id;
        const data = {
            id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);


    }

    handleRefnoSecond = (e) => {

        this.setState({ refno_second_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
            id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: e.target.value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);

    }

    handleRefnoFirstValue_id = (e) => {
        this.setState({ refno_first_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: e.target.value,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,


            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.refno_first_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleRefRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ refno_and_or_option: !this.state.refno_and_or_option })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: !this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.refno_second_value != '')

            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleRefnoSecondValue_id = (e) => {
        this.setState({ refno_second_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: e.target.value,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.refno_second_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    // property for
    handleSales = (e) => {
        if (this.state.sale) {
            property_for_array = property_for_array.filter(e => e !== 'sale');
        } else {

            property_for_array.push('sale')
        }

        this.setState({ sale: !this.state.sale })
        const data = {
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }
    handleRent = (e) => {
        if (this.state.rent) {
            property_for_array = property_for_array.filter(e => e !== 'rent');
        } else {

            property_for_array.push('rent')
        }
        this.setState({ rent: !this.state.rent })
        const data = {
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }
    handleTenant = (e) => {
        if (this.state.tenant) {
            property_for_array = property_for_array.filter(e => e !== 'tenanted');
        } else {

            property_for_array.push('tenanted')
        }
        this.setState({ tenant: !this.state.tenant })
        const data = {
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }
    handleShortterm = (e) => {
        if (this.state.short_term) {
            property_for_array = property_for_array.filter(e => e !== 'short-term');
        } else {

            property_for_array.push('short-term')
        }
        this.setState({ short_term: !this.state.short_term })
        const data = {
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    // property type
    handleResidential = (e) => {



        if (this.state.residential) {

            property_type_array = property_type_array.filter(e => e !== 'Residential');


        } else {

            property_type_array.push('Residential')


        }

        this.setState({ residential: !this.state.residential })
        const data = {
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);

    }
    handleCommercial = (e) => {
        if (this.state.commercial) {
            property_type_array = property_type_array.filter(e => e !== 'Commercial');


        } else {
            property_type_array.push('Commercial')

        }
        this.setState({ commercial: !this.state.commercial })

        const data = {
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,
            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);

    }

    // unit no options
    handleUnitnoFirst = (e) => {


        const unitno_first_value = e.target.value;
        this.setState({ unitno_first_value: unitno_first_value })

        if (unitno_first_value != '') {
            this.setState({ unitno_active_second_option: true })
        } else {
            this.setState({ unitno_active_second_option: false })
        }
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage, data);

        this.fetchComparable(page, perPage, data);
    }

    handleUnitnoSecond = (e) => {

        this.setState({ unitno_second_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: e.target.value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handleUnitnoFirstValue_id = (e) => {
        this.setState({ unitno_first_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: e.target.value,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.unitno_first_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleUnitRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ unitno_and_or_option: !this.state.unitno_and_or_option })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.unitno_second_value != '')

            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleUnitnoSecondValue_id = (e) => {
        this.setState({ unitno_second_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,


            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: e.target.value,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.unitno_second_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }


    // price options

    handlePriceFirst = (e) => {
        const price_first_value = e.target.value;

        this.setState((prevState) => (
            e.target.validity.valid ? { price_first_value: e.target.value } : prevState)
        )

        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value > 0) {
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
        } else {
            return
        }




    }

    // price in range
    handlePriceMin = (e) => {

        this.setState({ price_min: e.target.value })

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: e.target.value,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }
        if (this.state.price_max > 0)
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handlePriceMax = (e) => {

        this.setState({ price_max: e.target.value })

        const max = e.target.value
        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: e.target.value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }
        if (this.state.price_min > 0 && max > this.state.price_min)
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handlePriceSecond = (e) => {

        this.setState({ price_second_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: e.target.value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handlePriceFirstValue_id = (e) => {



        this.setState({ inrange: false })
        if (e == 7) {
            this.setState({ inrange: true })
        }
        let id = this.props.match.params.id;
        this.setState({ price_first_value_id: e, price_value_id: e })
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: e,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.price_first_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handlePriceRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ price_and_or_option: !this.state.price_and_or_option })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.price_second_value != '')

            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handlePriceSecondValue_id = (e) => {
        this.setState({ price_second_value_id: e.target.value })

        let id = this.props.match.params.id;

        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,


            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: e.target.value,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.price_second_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }


    // date option

    handleDateMorethan = (e) => {
        this.setState({ date_morethan_option: true, date_lessthan_option: false })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: true,
            date_lessthan_option: false,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleMoveInDateMorethan = (e) => {
        this.setState({ move_in_date_morethan_option: true, move_in_date_lessthan_option: false })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: true,
            date_lessthan_option: false,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            move_in_date_value: e,
            move_in_date_value_id: this.state.move_in_date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleDateLessthan = (e) => {
        this.setState({ date_morethan_option: false, date_lessthan_option: true })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: false,
            date_lessthan_option: true,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleMoveInDateLessthan = (e) => {
        this.setState({ move_in_date_morethan_option: false, move_in_date_lessthan_option: true })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            move_in_date_filter: this.state.move_in_date_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: false,
            date_lessthan_option: true,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            move_in_date_morethan_option: false,
            move_in_date_lessthan_option: true,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleDateValue_id = (e) => {
        this.setState({ date_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: e.target.value,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    getPrice = (price) => {
        if (price != null && price != "" && price != undefined){
            if(!isNaN(parseInt(price))){
                return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
            }
        }
    }

    getCurrentTime = (date) => {
        if (date != null && date != "" && date != undefined && date != "0000-00-00 00:00:00"){
            let newDate = new Date(date);
            newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
            return newDate.toISOString();
        }
    }

    getUnixTime = (time) => {
        const getRegionalTime = this.getCurrentTime(time);
        const originalTimestamp = new Date(getRegionalTime).getTime() / 1000;
        return originalTimestamp;
    }

    getTime = (date) => {
        const added_date = new Date(date);
        const formattedTime = added_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return formattedTime;
    }
    getDate = (date) => {
        const added_date = new Date(date);
        const formattedDate = added_date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
        return formattedDate;
    }

    getCurrentTimeStamp = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const unixTime = new Date(timestamp).getTime() / 1000;
        return unixTime;
    }

    getRemainingTime = (added_date) => {
        const dateObj = new Date(added_date);
        const unixTime = dateObj.getTime() / 1000;
        const addedDateUnixTime = unixTime + 28 * 60 * 60;
        let currentDateUnixTime = this.getCurrentTimeStamp();
        let timeDifference = addedDateUnixTime - currentDateUnixTime;
        if (timeDifference <= 0){
            this.setState({remainingTime: "00:00:00"})
            // return "00:00:00";
            // const postData = {
            //     "current_request": data,
            // }
            // API.post("/request_time_expire", postData)
            //     .then((response)=>{
            //         if(response.data.success){
            //             this.setState({all: response.data.all})
            //             this.setState({requested_viewings: response.data.requested_viewings})
            //         }
            //     }).catch((err) => {
            //     console.log(err)
            //     toast.error('Something went wrong');
            // })
        }else{
            const hours = Math.floor(timeDifference / 3600);
            const minutes = Math.floor((timeDifference % 3600) / 60);
            const seconds = timeDifference % 60;
            const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            this.setState({remainingTime: formattedDuration})
        }
    }

    getEndTime = (start_time) => {
        const originalDate = new Date(start_time);
        const updatedDate = new Date(originalDate.getTime() + (24 * 60 * 60 * 1000));

        const year = updatedDate.getFullYear();
        const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
        const day = String(updatedDate.getDate()).padStart(2, '0');
        const hours = String(updatedDate.getHours()).padStart(2, '0');
        const minutes = String(updatedDate.getMinutes()).padStart(2, '0');
        const seconds = String(updatedDate.getSeconds()).padStart(2, '0');

        const updatedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return updatedTime;
    }

    handleDateValue = (e) => {
        this.setState({ date_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: e.target.value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    // last call
    handleLastCallOptionValue = (e) =>{

        this.setState({last_call_option_value:e.target.value})
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            move_in_date_filter: this.state.move_in_date_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:e.target.value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_call_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleLastCallValue_id = (e) => {
        this.setState({ last_call_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: e.target.value,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range

        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_call_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleLastCallValue = (e) => {
        this.setState({ last_call_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: e.target.value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    // last match
    handleLastMatchOptionValue = (e) =>{

        this.setState({last_match_option_value:e.target.value})
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:e.target.value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_match_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleLastMatchValue_id = (e) => {
        this.setState({ last_match_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: e.target.value,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range

        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_match_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleLastMatchValue = (e) => {
        this.setState({ last_match_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: e.target.value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    // move in date
    handleMoveInDateOptionValue = (e) =>{

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:e.target.value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        if(e.target.value == 7 ){
            this.setState({inrange_move_in_date:true,move_in_date_is_empty:false})
            if (this.state.move_in_date_date_range.length>0)
                this.fetchData(page, perPage, data);
                this.fetchComparable(page, perPage, data);
        }else
        if(e.target.value == 8 ){
            this.setState({move_in_date_is_empty:true})

            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
        }
        else
        {
            this.setState({inrange_move_in_date:false,move_in_date_is_empty:false})
            if (this.state.move_in_date_value != '')
                this.fetchData(page, perPage, data);
                this.fetchComparable(page, perPage, data);
        }



        this.setState({move_in_date_option_value:e.target.value})





    }

    handleMoveInDateValue_id = (e) => {
        this.setState({ move_in_date_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: e.target.value,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range

        }

        const page = 1;
        const perPage = 10;
        if (this.state.move_in_date_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleMoveInDateValue = (e) => {
        this.setState({ move_in_date_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,

            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: e.target.value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }


    handleDateFirst = (e) => {

        const date_first_value = e.target.value;
        this.setState({ date_first_value: date_first_value })

        if (date_first_value != '') {
            this.setState({ date_active_second_option: true })
        } else {
            this.setState({ date_active_second_option: false })
        }
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);

    }

    handleDateSecond = (e) => {

        this.setState({ date_second_value: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: e.target.value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handleDateFirstValue_id = (e) => {
        this.setState({ date_first_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: e.target.value,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_first_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleDateRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ date_and_or_option: !this.state.date_and_or_option })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_second_value != '')

            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleDateSecondValue_id = (e) => {
        this.setState({ date_second_value_id: e.target.value })
        let id = this.props.match.params.id;
        const data = {
          id:id,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,


            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: e.target.value,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_second_value != '')
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }





    onInputChange = (e) => {
        console.log("onchange", e.target.value)
    }

    onChangeFollowupFor = (e) => {
        this.setState({ followup_for: e.target.value })
    }
    onChangeReminder = (e) => {
        this.setState({ reminder: e.target.value })
    }
    handleAgentDetails = (e) => {
        this.setState({ selected_agent: e.target.value })
    }


    handlePriceRange = (e) => {
        const radio = e.target.value
        if (radio == 1) {
            this.setState({ greaterthanprice: true, leassthanprice: false, inbetweenprice: false })
        } else
        if (radio == 2) {
            this.setState({ greaterthanprice: false, leassthanprice: true, inbetweenprice: false })
        } else
        if (radio == 3) {
            this.setState({ greaterthanprice: false, leassthanprice: false, inbetweenprice: true })
        }
    }

    handleGreaterValue = (e) => {
        this.setState({ greaterValue: e.target.value })
    }

    handleLessValue = (e) => {
        this.setState({ lessValue: e.target.value })
    }

    onTypeChange = (e) => {
        this.setState({type: e.target.value})
    }

    handleisDecline=(data)=>{
        this.setState({isdecline:true})
        this.setState({currentRequest:data})
    }
    handleisDeclineClose=()=>{
        this.setState({isdecline:false})
        this.setState({currentRequest:null})
    }
    onClickDecline = () => {
        if (this.state.decline_note == ""){
            toast.error("Decline Note is Required");
        }else{
            const data = {
                "decline_note": this.state.decline_note,
                "current_request": this.state.currentRequest
            }
            API.post("/decline_note", data)
                .then((response)=>{
                    if(response.data.success){
                        this.setState({all: response.data.all})
                        this.setState({requested_viewings: response.data.requested_viewings})
                        this.setState({isdecline: false})
                        this.setState({decline_note: ""})
                        this.setState({currentRequest: null})
                    }
                }).catch((err) => {
                console.log(err)
                this.setState({isdecline: false})
                this.setState({decline_note: ""})
                this.setState({currentRequest: null})
                toast.error('Something went wrong');
            }).finally(() => {
                this.setState({isdecline: false})
                this.setState({decline_note: ""})
                this.setState({currentRequest: null})
            })
        }
    }
    changeDeclineNote = (e) => {
        this.setState({decline_note: e.target.value})
    }
    handleisShareWhatsApp=(data)=>{
        this.setState({isWhatsappLoading:true})
        this.setState({currentRequest:data})
        const postData = {
            "current_request": data
        }
        API.post("/share_request_viewing", postData)
            .then((response)=>{
                if(response.data.success){
                    this.setState({all: response.data.all})
                    this.setState({requested_viewings: response.data.requested_viewings})
                    this.setState({isWhatsappLoading:false})
                    this.setState({currentRequest:null})
                }
            }).catch((err) => {
            console.log(err)
            this.setState({isWhatsappLoading:false})
            this.setState({currentRequest:null})
            toast.error('Something went wrong');
        }).finally(() => {
            this.setState({isWhatsappLoading:false})
            this.setState({currentRequest:null})
        })
    }
    handleisShareWhatsAppClose=()=>{
        this.setState({isSharetoWhatsApp:false})
    }

    handleCallLogModal=()=>{
        this.setState({isCallLogOpen:true})
    }
    handleCallLogModalCancel=()=>{
        this.setState({isCallLogOpen:false})
    }

    onChangeVendor = (e) => {
        this.setState({vendor: e})
    }

    onSearchClient = (e) => {

        API.get("/get_clients/" + e)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ clientList: response.data.contacts })
                }
            })
    }

    handleMinKeyDown = (event) => {
        const maxValue = this.state.greaterValue
        const data = {
            greaterValue: this.state.greaterValue,
            lessValue: this.state.lessValue
        }
        if (event.key === 'Enter') {
            if (maxValue > 0) {
            } else {
                this.textInput.current.focus();
            }
        }
    }



    fetchData = (page, perPage, data, xChk) => {
        this.setState({ isLoading: true })
        API.post(`/matchedlistleads?page=${page}&perPage=${perPage}`, data)
            .then(async (response) => {
            if(response.data.success){
                await response.data.leads;
                this.setState({
                    leads: response.data.leads,
                    leads_account:response.data.leads.total,
                    isLoading: false
                })
            }else{
               this.setState({isLoading:false}) 
            }

            })

        
    }

    fetchComparable = (page, perPage, data) => {
      this.setState({ isComparableLoading: true })
      API.post(`/comparable_matchedlistleads?page=${page}&perPage=${perPage}`, data)
          .then(async (response) => {

 if(response.data.success){
              await response.data.leads;
              this.setState({
                comparable_leads: response.data.leads,
                comparable_leads_count:response.data.leads.total,
                isComparableLoading: false
              })}else{
                this.setState({isComparableLoading:false})
              }

          })
  }


    onDeleteCollab = (user_id, index, id, e) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.post("/delete-leads-collabs/" + id, {lead_id: this.state.lead_details.id, user_id: user_id})
                    .then((response) =>{
                        if(response.data.success){
                            this.state.collaborators.splice(index, 1);
                            this.setState({collaborators: this.state.collaborators})
                            toast.success("Successfully deleted")
                        }
                    })
            }
        })
    }
    handleTabs = (e,event) => {




        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;
        let filter_options = []
        this.setState({isLoading:true})

        API.get("/get_saved_filter/" + e)
            .then((response) => {


                const response_filter = response.data.filter

                const stage = response.data.filter_stages?response.data.filter_stages:[]
                const contact_type = response.data.filters_contact_types?response.data.filters_contact_types:[]
                const furnished_unfurnished = response.data.filters_furnished_unfurnisheds?response.data.filters_furnished_unfurnisheds:[]
                const agent_value_filter = response.data.agent_value_filter?response.data.agent_value_filter:[]

                const property_type_filter = response.data.property_type_filter ?response.data.property_type_filter:[]

                const move_in_date_date_range = response.data.move_in_date_date_range ?response.data.move_in_date_date_range:[]

                this.setState({

                    property_type_filter: property_type_filter,
                    community_filter: response_filter.community_value_id,
                    sub_community_filter: response_filter.sub_community_value_id,
                    property_filter: this.state.property_filter,
                    name_value_id: this.state.name_value_id,
                    name_value: this.state.name_value,
                    refno_value: this.state.refno_value,
                    refno_value_id: this.state.refno_value_id,
                    phone_value_id: this.state.phone_value_id,
                    phone_value: this.state.phone_value,
                    agent_value_id: this.state.agent_value_id,
                    agent_value: agent_value_filter,

                    bed_rooms_value_id: response_filter.bed_rooms_value_id,
                    bed_rooms_value: response_filter.bed_rooms_value?response_filter.bed_rooms_value:"",
                    stage_value: stage,
                    contact_types_value: contact_type,
                    furnished_types_value: furnished_unfurnished,
                    price_first_value_id: response_filter.price_first_value_id,
                    price_first_value: response_filter.price_first_value?response_filter.price_first_value:"",
                    price_min: response_filter.price_min,
                    price_max: response_filter.price_max,
                    date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
                    date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
                    move_in_date_morethan_option: response_filter.move_in_date_morethan_option == 1 ? true : false,
                    move_in_date_lessthan_option: response_filter.move_in_date_lessthan_option == 1 ? true : false,
                    date_value: response_filter.date_value,
                    date_value_id: response_filter.date_value_id,

                    viewing_value:response_filter.viewing_value !=null?response_filter.viewing_value:"",
                    viewing_option_value:response_filter.viewing_option_value?response_filter.viewing_option_value:1,
                    viewing_min_value:response_filter.viewing_min_value !=null?response_filter.viewing_min_value:"",
                    viewing_max_value:response_filter.viewing_max_value !=null?response_filter.viewing_max_value:"",


                    offer_value:response_filter.offer_value !=null?response_filter.offer_value:"",
                    offer_option_value:response_filter.offer_option_value?response_filter.offer_option_value:1,
                    offer_min_value:response_filter.offer_min_value!=null?response_filter.offer_min_value:"",
                    offer_max_value:response_filter.offer_max_value !=null?response_filter.offer_max_value:"",



                    last_call_value: response_filter.last_call_value?response_filter.last_call_value:0,
                    last_call_value_id: response_filter.last_call_value_id?response_filter.last_call_value_id:1,
                    last_call_option_value:response_filter.last_call_option_value?response_filter.last_call_option_value:1,



                    last_match_value: response_filter.last_match_value?response_filter.last_match_value:0,
                    last_match_value_id: response_filter.last_match_value_id?response_filter.last_match_value_id:1,
                    last_match_option_value:response_filter.last_match_option_value?response_filter.last_match_option_value:1,


                    move_in_date_value: response_filter.move_in_date_value?response_filter.move_in_date_value:"",
                    move_in_date_value_id: response_filter.move_in_date_value_id?response_filter.move_in_date_value_id:1,
                    move_in_date_option_value:response_filter.move_in_date_option_value?response_filter.move_in_date_option_value:1,
                    move_in_date_date_range:move_in_date_date_range

                })

                const data = {

                    id:id,
                    property_type_filter: property_type_filter,
                    community_filter: response_filter.community_value_id,
                    sub_community_filter: response_filter.sub_community_value_id,
                    property_filter: this.state.property_filter,
                    name_value_id: this.state.name_value_id,
                    name_value: this.state.name_value,
                    refno_value: this.state.refno_value,
                    refno_value_id: this.state.refno_value_id,
                    phone_value_id: this.state.phone_value_id,
                    phone_value: this.state.phone_value,
                    agent_value_id: this.state.agent_value_id,
                    agent_value: agent_value_filter,

                    bed_rooms_value_id: response_filter.bed_rooms_value_id,
                    bed_rooms_value: response_filter.bed_rooms_value?response_filter.bed_rooms_value:"",
                    stage_value: stage,
                    contact_types_value: contact_type,
                    furnished_types_value: furnished_unfurnished,
                    price_first_value_id: response_filter.price_first_value_id,
                    price_first_value: response_filter.price_first_value?response_filter.price_first_value:"",
                    price_min: response_filter.price_min,
                    price_max: response_filter.price_max,
                    date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
                    date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
                    move_in_date_morethan_option: response_filter.move_in_date_morethan_option == 1 ? true : false,
                    move_in_date_lessthan_option: response_filter.move_in_date_lessthan_option == 1 ? true : false,
                    date_value: response_filter.date_value,
                    date_value_id: response_filter.date_value_id,

                    viewing_value:response_filter.viewing_value !=null?response_filter.viewing_value:"",
                    viewing_option_value:response_filter.viewing_option_value?response_filter.viewing_option_value:1,
                    viewing_min_value:response_filter.viewing_min_value !=null?response_filter.viewing_min_value:"",
                    viewing_max_value:response_filter.viewing_max_value !=null?response_filter.viewing_max_value:"",


                    offer_value:response_filter.offer_value !=null?response_filter.offer_value:"",
                    offer_option_value:response_filter.offer_option_value?response_filter.offer_option_value:1,
                    offer_min_value:response_filter.offer_min_value!=null?response_filter.offer_min_value:"",
                    offer_max_value:response_filter.offer_max_value !=null?response_filter.offer_max_value:"",



                    last_call_value: response_filter.last_call_value?response_filter.last_call_value:0,
                    last_call_value_id: response_filter.last_call_value_id?response_filter.last_call_value_id:1,
                    last_call_option_value:response_filter.last_call_option_value?response_filter.last_call_option_value:1,



                    last_match_value: response_filter.last_match_value?response_filter.last_match_value:0,
                    last_match_value_id: response_filter.last_match_value_id?response_filter.last_match_value_id:1,
                    last_match_option_value:response_filter.last_match_option_value?response_filter.last_match_option_value:1,


                    move_in_date_value: response_filter.move_in_date_value?response_filter.move_in_date_value:"",
                    move_in_date_value_id: response_filter.move_in_date_value_id?response_filter.move_in_date_value_id:1,
                    move_in_date_option_value:response_filter.move_in_date_option_value?response_filter.move_in_date_option_value:1,
                    move_in_date_date_range:move_in_date_date_range

                }


                this.fetchData(page, perPage, data);
                this.fetchComparable(page, perPage, data);
            })





    }

    // By Farooq


    // fTablePageUpdate = async (xPageNumber, xChk) => {
    //   this.setState({
    //     open: false,
    //   })
    //   const data = {
    //     property_type_filter:this.state.property_type_filter,
    //     community_filter:this.state.community_filter,
    //     sub_community_filter:this.state.sub_community_filter,
    //     property_filter:this.state.property_filter,
    //     name_value_id: this.state.name_value_id,
    //     name_value: this.state.name_value,

    //     phone_value_id: this.state.phone_value_id,
    //     phone_value: this.state.phone_value,

    //     bed_rooms_value_id: this.state.bed_rooms_value_id,
    //     bed_rooms_value: this.state.bed_rooms_value,
    //     agent_value_id: this.state.agent_value_id,
    //     agent_value: this.state.agent_value,

    //     stage_value: this.state.stage_value,
    //     contact_types_value: this.state.contact_types_value,
    //     furnished_types_value: this.state.furnished_types_value,


    //     price_first_value_id: this.state.price_first_value_id,
    //     price_first_value: this.state.price_first_value,
    //     price_min: this.state.price_min,
    //     price_max: this.state.price_max,

    //     date_first_value_id: this.state.date_first_value_id,
    //     date_and_or_option: this.state.date_and_or_option,
    //     date_second_value_id: this.state.date_second_value_id,
    //     date_first_value: this.state.date_first_value,
    //     date_second_value: this.state.date_second_value,

    //     date_morethan_option: this.state.date_morethan_option,
    //     date_lessthan_option: this.state.date_lessthan_option,
    //     date_value: this.state.date_value,
    //     date_value_id: this.state.date_value_id,
    //   }

    //   await this.fetchData(xPageNumber, 10, data, xChk);
    // }

    findNextOrder = (xId) => {
        let index = this.state.leads.data.findIndex(({ original_id }) => original_id === xId)
        return index > -1 && index < this.state.leads.data.length - 1 ? this.state.leads.data[index + 1] : undefined
    }

    findPrevOrder = (xId) => {
        // console.log("***xID *** ", xId);
        // console.log("***leads Data *** ", this.state.leads.data);
        let index = this.state.leads.data.findIndex(({ original_id }) => original_id === xId)
        // console.log("*** Index Prev Value **** ", index)
        // return index > -1 && index < this.state.leads.data.length - 1 ? this.state.leads.data[index - 1] : undefined
        return index > -1 && index < this.state.leads.data.length ? this.state.leads.data[index - 1] : undefined
    }

    handleOpenDrawer = (id, xPrev_Next) => {
        console.log("**** ID *** ", id)
        if(xPrev_Next){
            this.setState({
                loader: true
            });
        }

        this.setState({matchs:[], not_matchs: []})
        // By Farooq
        const nextId = this.findNextOrder(id);
        const prevId = this.findPrevOrder(id);
        console.log("**Now Id***", id);
        console.log("**Prev Id***", prevId);
        console.log("**Next Id***", nextId);

        this.setState({
            // record_id: xPrev_Next ? this.state.record_id : id,
            nextId: nextId == undefined ? this.state.nextId : nextId.original_id,
            prevId: prevId == undefined ? this.state.prevId : prevId.original_id,
            currId: id
        })
        ///////////////////
        this.setState({record_id: id})

        API.get("/lead_details/" + id)
            .then((response) => {

                if (response.data.success){
                    this.setState({requested_viewings: response.data.requested_viewings})
                    this.setState({currentRequest: null})
                    this.setState({
                        lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
                        all: response.data.all,
                        role: response.data.role.role,
                        requested_viewings: response.data.requested_viewings,
                        agent_id: response.data.role.id,
                        notes: response.data.notes, requirement_community: response.data.lead_details.community,
                        community_match:response.data.lead_details.community,
                        sub_community_match:response.data.lead_details.subcommunity,
                        min_budget: response.data.lead_details.budget_from,
                        max_budget: response.data.lead_details.budget_to,
                        bedroom_match: response.data.lead_details.bedroom,
                        comment: response.data.lead_details.comment,

                        call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                        //viewing_notes: response.data.viewing_notes,
                        email_notes: response.data.email_notes,
                        followup_notes: response.data.followup_notes,
                        offer_notes: response.data.offer_notes,
                        viewing_notes: response.data.viewing_notes,
                        owner_id: response.data.lead_details.owner_id,
                        owner_name: response.data.lead_details.owner,
                        owner_email: response.data.lead_details.owner_email,
                        owner_mobile: response.data.lead_details.mobile,
                        requirement_bedroom: response.data.lead_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                        requirement_max_budget: response.data.lead_details.budget_to,requirement_verifyedby:response.data.lead_details.verified_by,
                        requirement_lead_stage: response.data.lead_details.lead_stage,
                        requirement_community: response.data.lead_details.community, requirement_sub_community: response.data.lead_details.subcommunity,
                        requirement_agent_id: response.data.lead_details.lead_agent, lead_community: response.data.lead_details.community, lead_sub_community: response.data.lead_details.subcommunity,
                        contact_type_requirement:response.data.lead_details.contact_type,price_requirement:response.data.lead_details.lead_price,
                        collaborators: response.data.collaborators,requirement_move_in_date:response.data.lead_details.move_in_date,
                        property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                        move_in_date : response.data.lead_details.move_in_date,
                        // Farooq
                        loader: false
                    })

                    const lead_stage = response.data.lead_details.lead_stage
                    if (lead_stage == 6) {
                        const item = {
                            value: '6',
                            label: 'Offer'
                        }

                        this.setState({
                            lead_stage_filter: [...this.state.lead_stage_filter, item]
                        })
                    }else
                    if (lead_stage == 7) {
                        const item = {
                            value: '7',
                            label: 'Deal signed'
                        }

                        this.setState({
                            lead_stage_filter: [...this.state.lead_stage_filter, item]
                        })
                    }else
                    if (lead_stage == 8) {
                        const item = {
                            value: '8',
                            label: 'Viewings'
                        }

                        this.setState({
                            lead_stage_filter: [...this.state.lead_stage_filter, item]
                        })
                    }

                    API.get("/sub_community/" + response.data.lead_details.community,)
                        .then((response) => {
                            if (response.data.success)
                                this.setState({ sub_communities: response.data.sub_communities })
                        })

                    if (this.state.requested_viewings != []){
                        setInterval(() => {
                            this.getRemainingTime(this.state.requested_viewings && this.state.requested_viewings[0] && this.state.requested_viewings[0].added_date)
                        }, 1000)
                    }
                }
                else{
                    this.setState({
                        loader: false
                    })
                }
                this.fetchFollowUpDetails(this.state.record_id)
                // By Farooq
                if (!xPrev_Next) {
                    this.setState({
                        open: true,
                        disabledNext: false,
                        disabledPrev: false
                    })
                }

                ///// chnages By Farooq ////
                // if(!this.props.match.params.id){
                //   this.setState({currUrl: window.location.href })
                //   window.history.replaceState(null, "Leads Id", window.location.href+'/'+id)
                // }

                this.setState({ currUrl: window.location.href })
                window.history.replaceState(null, "Leads Id", window.location.href + '/' + id)
                /////////////////////////


                API.get("/all_agents")
                    .then((response) => {
                            if (response.data.success) {
                                this.setState({ agents: response.data.agents })
                            }
                        }
                    )

                this.fetchFollowUp(id);
                const community = response.data.lead_details.community
                if(community == '' || community == null){
                    console.log("community empty ", true)
                }
                else{
                    API.get("/sub_community/" + community)
                        .then((response) => {
                            if (response.data.success)
                                this.setState({ requirement_sub_communities: response.data.sub_communities })
                        })

                }

            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loader: false
                })
            })



    }

    handleNextRecord = (e) => {
        let newUrl;

        const arr = this.state.leads.data.slice(-1);
        if (this.state.currId == arr[0].original_id) {
            // this.fTablePageUpdate(this.state.leads.current_page+1, 'next');
            this.setState({ disabledNext: true });

        }
        else {
            this.handleOpenDrawer(this.state.nextId, true);
            this.setState({ disabledPrev: false });

            // Farooq
            if (this.props.match.params.id) {
                newUrl = window.location.href.replace(/leads.*/, `leads`);
            }
            else {
                newUrl = window.location.href.replace(/leads.*/, `leads`);
            }
            // const newUrl = window.location.href.replace(/leads.*/, `leads/${this.state.nextId}`);
            this.setState({ currUrl: newUrl })
            window.history.replaceState(null, "Leads Id", newUrl);
            /////////////////////////
        }

        {/**

         const newArray = this.state.leads[Symbol.iterator]();
         const nextArray  = newArray.next();



         const id = this.state.record_id;
         this.setState({isNextLoading:true})

         API.get("/next_lead/" + id)
         .then((response) => {

            if (response.data.success) {
              this.setState({
                 isNextLoading:false,
                 record_id: response.data.lead_details.parent_lead,
                 lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
                 all:response.data.all,
                 notes: response.data.notes,requirement_community:response.data.lead_details.community,
                 call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                 email_notes: response.data.email_notes, property_offers: response.data.property_offers,
                 owner_id:response.data.lead_details.owner_id,
                 owner_name:response.data.lead_details.owner,
                 owner_email:response.data.lead_details.owner_email,
                 owner_mobile:response.data.lead_details.mobile,
                 requirement_bedroom:response.data.lead_details.bedroom,requirement_min_budget:response.data.lead_details.budget_from,
                 requirement_max_budget:response.data.lead_details.budget_to,
                 requirement_community:response.data.lead_details.community,requirement_sub_community:response.data.lead_details.subcommunity,
                 requirement_agent_id:response.data.lead_details.lead_agent,lead_community:response.data.lead_details.community,lead_sub_community:response.data.lead_details.subcommunity,
                 collaborators:response.data.collaborators
              })

              // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
            } else {
              this.setState({isNextLoading:false})
              toast.warning("last record")
            }
          })

         */}
    }

    handlePreviousRecord = (e) => {
        const arr = this.state.leads.data.slice(0, 1);
        if (this.state.currId == arr[0].original_id) {
            // if(this.state.leads.current_page !== 1){
            //   this.fTablePageUpdate( this.state.leads.current_page-1, 'prev');
            // }
            this.setState({ disabledPrev: true })
        }
        else {
            this.handleOpenDrawer(this.state.prevId, true)
            this.setState({ disabledNext: false })

            // Farooq
            const newUrl = window.location.href.replace(/leads.*/, `leads`);
            this.setState({ currUrl: newUrl })
            window.history.replaceState(null, "Leads Id", newUrl);
            /////////////////////////
        }


        {/***
         const id = this.state.record_id;
         this.setState({isPreviousLoading:true})
         API.get("/previous_lead/" + id)
         .then((response) => {

        if (response.data.success) {
          this.setState({
            isPreviousLoading:false,
             record_id: response.data.lead_details.parent_lead,
             lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
             all:response.data.all,
             notes: response.data.notes,requirement_community:response.data.lead_details.community,
             call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
             email_notes: response.data.email_notes, property_offers: response.data.property_offers,
             owner_id:response.data.lead_details.owner_id,
             owner_name:response.data.lead_details.owner,
             owner_email:response.data.lead_details.owner_email,
             owner_mobile:response.data.lead_details.mobile,
             requirement_bedroom:response.data.lead_details.bedroom,requirement_min_budget:response.data.lead_details.budget_from,
             requirement_max_budget:response.data.lead_details.budget_to,
             requirement_community:response.data.lead_details.community,requirement_sub_community:response.data.lead_details.subcommunity,
             requirement_agent_id:response.data.lead_details.lead_agent,lead_community:response.data.lead_details.community,lead_sub_community:response.data.lead_details.subcommunity,
             collaborators:response.data.collaborators
          })

          // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
        } else {
          this.setState({isPreviousLoading:false})
          toast.warning("last record")
        }
      })
         */}
    }

    saveNavigation = (id) => {
        const data = this.state.leads.data;
        this.props.setNavigation(data)
    }


    handleCloseDrawer = () => {
        this.setState({ open: false })


        // Farooq

        const newUrl = window.location.href.replace(/leads-list.*/, 'leadslist');
        // window.history.replaceState(null, "Leads Id", this.state.currUrl)
        window.history.replaceState(null, "Leads Id", newUrl);
        this.setState({ currUrl: "" })

        //////////////////

        /// Empty all log values...
        this.setState({
            call_log:'',
            note_log:'',
            whatsapp_log:'',
            email_log:'',
            offer_log:'',
            viewing_log:'',
            followup_log:'',
        })
    }

    handleSaveData = () => {
        toast.success("Successfully addedd")
        this.setState({ open: false })
    }

    handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {


                toast.success("Successfully deleted")



            }
        })
    }

    handleCommunityMatch = (e) => {
        this.setState({ community_match: e })
        if(e !== null || e !== ''){
            API.get("/sub_community/" + e)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ sub_communities: response.data.sub_communities })
                })
        }
    }



    handleSubCommunityMatch = (e) => {
        this.setState({ sub_community_match: e })

    }

    handleRequirementCommunity = (e) => {

        this.setState({ requirement_community: e })
        if(e !== null || e !== ''){
            API.get("/sub_community/" + e)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ requirement_sub_communities: response.data.sub_communities })
                })
        }
    }


    handleRequirementSubCommunity = (e) => {
        this.setState({ requirement_sub_community: e })

    }

    handleRequirementBeds = (e) => {
        this.setState({ requirement_bedroom: e.target.value })
    }

    handleRequirementMin = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ requirement_min_budget: new_value })
    }

    handleRequirementMax = (e) => {

        const value = e.target.value

        const new_value = value.replace(/,/g, "")

        this.setState({ requirement_max_budget: new_value })
    }

    handleRequirementMoveInDate = (e) => {
        this.setState({ requirement_move_in_date: e.target.value })
    }

    handleRequirementStage = (e) => {
        this.setState({ requirement_lead_stage: e.target.value })
    }

    handleRequirementVerifyby = (e) => {
        this.setState({ requirement_verifyedby: e.target.value })
    }

    handleRequirementAgent = (e) => {
        this.setState({ requirement_agent_id: e })
    }

    handleRequirementSubmit = () => {
        const data = {
            lead_id: this.state.lead_details.id,
            requirement_min_budget: this.state.requirement_min_budget,
            requirement_max_budget: this.state.requirement_max_budget,
            requirement_lead_stage: this.state.requirement_lead_stage,
            requirement_community: this.state.requirement_community,
            requirement_sub_community: this.state.requirement_sub_community,
            requirement_verifyedby: this.state.requirement_verifyedby,
            requirement_agent_id: this.state.requirement_agent_id,
            requirement_move_in_date: this.state.requirement_move_in_date,
            requirement_bedroom: this.state.requirement_bedroom
        }

        API.post("/requirements_save_update", data)
            .then((response) => {
                let requirement_note = ""
                if (response.data.success) {

                    this.setState({
                        min_from_requirement: this.state.requirement_min_budget,
                        max_from_requirement: this.state.requirement_max_budget,
                        community_from_requirement: this.state.requirement_community,
                        sub_community_from_requirement: this.state.requirement_sub_community,
                        added_date_from_requirement: this.state.requirement_move_in_date,
                        bedroom_from_requirement: this.state.requirement_bedroom
                    })

                    const old_community = this.state.lead_details.community != null ? this.state.lead_details.community : 0
                    const new_community = this.state.requirement_community != null ? this.state.requirement_community : 0
                    const old_sub_community = this.state.lead_details.subcommunity
                    const new_sub_community = this.state.requirement_sub_community

                    const old_bedroom = this.state.lead_details.bedroom
                    const new_bedroom = this.state.requirement_bedroom
                    const old_lead_stage = this.state.lead_details.lead_stage
                    const new_lead_stage = this.state.requirement_lead_stage
                    if (old_community != new_community) {
                        const old_community_obj = this.state.communities.find(obj => {
                            return obj.value == old_community
                        })

                        const new_community_obj = this.state.communities.find(obj => {
                            return obj.value == new_community
                        })
                        requirement_note += "Community changed from " + old_community_obj.label + " to " + new_community_obj.label + "\n"

                    }

                    if (old_sub_community != new_sub_community) {

                        const old_sub_community_obj = this.state.all_sub_communities.find(obj => {
                            return obj.value == old_sub_community
                        })

                        const new_sub_community_obj = this.state.all_sub_communities.find(obj => {
                            return obj.value == new_sub_community
                        })
                        requirement_note += "\n Sub Community changed from " + old_sub_community_obj.label + " to " + new_sub_community_obj.label + "\n"
                    }

                    if (old_bedroom != new_bedroom) {

                        requirement_note += "Bedroom from " + old_bedroom + " to " + new_bedroom + "\n"

                    }

                    if (old_lead_stage != new_lead_stage) {

                        requirement_note += "Lead stage from " + old_lead_stage + " to " + new_lead_stage + "\n"

                    }











                    const data = {
                        leads_id: this.state.record_id,
                        note: requirement_note,
                        note_type: "note"
                    }

                    API.post("/add_lead_note", data)
                        .then((response) => {
                            if (response.data.success) {
                                this.setState({

                                    notes: response.data.notes,
                                    call_notes: response.data.call_notes,
                                    whatsapp_notes: response.data.whatsapp_notes,
                                    email_notes: response.data.email_notes
                                })

                            }
                        })
                    // }
                }
            })
    }

    handleMinBudget = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ min_budget: new_value })
    }

    handleMaxBudget = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ max_budget: new_value })
    }

    handleBeds = (e) => {
        this.setState({ bedroom_match: e.target.value })
    }

    handlePropertForMatch = (e) => {
        this.setState({ property_for_match: e.target.value })
    }

    handlePropertTypeMatch = (e) => {
        this.setState({ property_type_match: e.target.value })
    }

    handleAddedDateMatch = (e) => {
        this.setState({ added_date_match: e.target.value })
    }

    handleMatch = () => {

        const data = {
            lead_id: this.state.record_id,
            community_match: this.state.community_match,
            sub_community_match: this.state.sub_community_match,
            min_budget: this.state.min_budget, max_budget: this.state.max_budget,
            beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
            property_type_match: this.state.property_type_match,
            added_date_match: this.state.added_date_match
        }



        API.post("/match_leads", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ matchs: response.data.match_leads, not_matchs: response.data.not_match_leads })
                }
            })
    }

    // note logs
    handleCallLog = (e) => {
        this.setState({ call_log: e.target.value })
    }

    handleCallSubmit = () => {
        if (this.state.call_log == '') {
            toast.warning('Call log cannot be empty');
        }
        else {
            this.setState({ call_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.call_log,
                note_type: "phone",
                users: this.state.selectedCallUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        console.log("*** Check Call Response *** ", response.data)
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            call_log: "",
                            call_loader: false,
                            selectedCallUsers: [],
                        })

                        Swal.fire({
                            title: 'Do you want to add follow up?',
                            icon: 'questions',
                            showCancelButton: true,
                            confirmButtonColor: '#151515',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes',
                            cancelButtonText:'No'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.setState({isfollowupOpen:true})
                                this.setState({isCallLogOpen: false})
                            }else{
                                this.setState({isCallLogOpen: false})
                            }
                        })
                        let status = (this.state.contact_type_call_note_status != "") ? this.state.contact_type_call_note_status : 2

                        const data = { leads_id: this.state.record_id, lead_stage: status }
                        API.post("/update_lead_stage", data)
                            .then((response) => {
                                if (response.data.success) {
                                    this.setState({
                                        all: response.data.all,
                                        notes: response.data.notes
                                    })
                                    this.setState({contact_type_call_note_status: ""})
                                    this.setState({requirement_lead_stage: status})
                                }
                            }).catch((err) => console.log(err));
                    }
                    else {
                        this.setState({ call_loader: false })
                    }
                }).catch(() => {
                this.setState({ callloader: false })
            })
        }
    }

    handleNoteLog = (e) => {
        this.setState({ note_log: e.target.value })
    }

    handleNoteSubmit = () => {
        if (this.state.note_log == '') {
            toast.warning('Note log cannot be empty');
        }
        else {
            this.setState({ note_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.note_log,
                note_type: "note",
                users: this.state.selectedNoteUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {

                    if (response.data.success) {
                        console.log("*****Is Successs ", response.data.success)
                        this.setState({
                            selectedCallUsers: [],
                            note_log: "",
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            note_loader: false,
                            selectedNoteUsers:[]
                        })

                    }
                    else {
                        this.setState({ note_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ note_loader: false })
                })
        }
    }

    handleWhatsappLog = (e) => {
        this.setState({ whatsapp_log: e.target.value })
    }

    handleWhatsappSubmit = () => {
        if (this.state.whatsapp_log == '') {
            toast.warning('whatsApp log cannot be empty')
        }
        else {
            this.setState({ whatsapp_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.whatsapp_log,
                note_type: "whatsapp",
                users: this.state.selectedWhatsappUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            whatsapp_log: "",
                            selectedCallUsers: [],
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            whatsapp_loader: false,
                            selectedWhatsappUsers:[]
                        })

                    }
                    else {
                        this.setState({ whatsapp_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ whatsapp_loader: false })
                })
        }
    }

    handleEmailLog = (e) => {
        this.setState({ email_log: e.target.value })
    }

    handleEmailSubmit = () => {
        if (this.state.email_log == '') {
            toast.warning('Email log cannot be empty')
        }
        else {
            this.setState({ email_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.email_log,
                note_type: "email",
                users: this.state.selectedEmailUsers
            }



            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            email_log: "",
                            emailSubject:"",
                            selectedCallUsers: [],
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            email_loader: false,
                            selectedEmailUsers:[]
                        })
                    }
                    else {
                        this.setState({ email_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ email_loader: false })
                })
        }
    }

    handleViewingSubmit = () => {
        if (this.state.viewing_log == '') {
            toast.warning('Viewing log cannot be empty')
        }
        else {
            this.setState({ viewing_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.viewing_log,
                note_type: "viewing",
                users: this.state.selectedViewingUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            viewing_log:"",
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            viewing_loader: false,
                            selectedViewingUsers:[]
                        })

                    }
                    else {
                        this.setState({ viewing_loader: false })
                    }
                })
                .then((err) => {
                    this.setState({ viewing_loader: false })
                })
        }
    }

    handleOfferSubmit = () => {
        if (this.state.offer_log == '') {
            toast.warning('toast log icannot be empty')
        }
        else {
            this.setState({ offer_loader: true })
            this.setState({})
            const data = {
                leads_id: this.state.record_id,
                note: this.state.offer_log,
                note_type: "offer",
                users: this.state.selectedOfferUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            offer_loader: false,
                            offer_log:'',
                            selectedOfferUsers:[]
                        })

                    }
                    else {
                        this.setState({ offer_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ offer_loader: false })
                })
        }
    }

    handleFollowupSubmit = () => {
        if (this.state.followup_log == '') {
            toast.warning('Followup cannot be empty')
        }
        else {
            this.setState({ followUp_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.followup_log,
                note_type: "followup",
                users: this.state.selectedFollowupUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            followup_log:'',
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            followUp_loader: false,
                            selectedFollowupUsers:[]
                        })

                    }
                    else {
                        this.setState({ followUp_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ followUp_loader: false })
                })
        }
    }

    handleNewViewing = () => {
        this.setState({ isviewingOpen: true })
        if (this.state.sendEvent == false) {
            setTimeout(this.signIn(), 5000)
        }
    }



    onSearchRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ ref_nums: [] })
            return
        }
        API.get("/get_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ ref_nums: response.data.ref_nums })
                }
            })
    }

    handleRequestNewViewing = (data = null) => {
        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let role_name = userdata.auth.user.role_name;
        const user_id = userdata.auth.user.id

        this.setState({ isRequestviewingOpen: true })
        this.setState({currentRequest:data})
        this.setState({ref_def_nums:data.refno})
        this.setState({viewing_ref_no:[data.refno]})
        this.setState({viewing_agent:user_id})

        if (this.state.sendEvent == false) {
            setTimeout(this.signIn(), 5000)
        }
    }

    handleRefsno = (e) => {
        this.setState({ viewing_ref_no: e, ref_def_nums: e })
        const data = {
            ref_nums: e
        }


        API.post("/get_all_listing_by_refnos", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ viewings_listings: response.data.listings })
                }
            })

    }

    isValidHttpUrl = (string) =>{
        try {
          const url = new URL(string);
          return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
          return false;
        }
      }

    handleUnitno = (e) => {



        if (!e || e == '' || e == null) {
            API.get("/get_old_unit/" + this.state.old_unit_num)
                .then((response) => {

                    const refno = response.data.listing.value

                    this.setState({
                        ref_def_nums: this.state.ref_def_nums.filter(function (value) {
                            return value.refno !== refno
                        })
                    });

                    // this.setState({viewings_listings: this.state.viewings_listings.filter(function(viewing_listing) {
                    //   return viewing_listing.refno !== refno
                    //  })});



                })
            // this.setState({ref_def_nums:[]})
            return
        }
        this.setState({ viewing_unit_no: e })
        const data = {
            unit_num: e
        }
        API.post("/get_all_listing_by_unitno", data)
            .then((response) => {
                if (response.data.success) {
                    const listing = response.data.all_listings
                    const unit = listing.unitno
                    let data = response.data.all_listings
                    const isFound = this.state.viewings_listings.some(element => {
                        if (element.unitno === e) {
                            return true;
                        }

                        return false;
                    });
                    if (!isFound) {
                        this.setState({ viewings_listings: [...this.state.viewings_listings, response.data.listing], ref_def_nums: [...this.state.ref_def_nums, data.value], old_unit_num: e, viewing_ref_no: [...this.state.viewing_ref_no, data.value] })
                    } else {
                        return
                    }
                }
            })
    }

    handleSearchUintNo = (e) => {


        if (!e || e == '' || e == null) {
            // this.setState({ref_nums:[]})
            return
        }

        API.get("/get_listing_by_unitno/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ unit_nums: response.data.unit_nums })
                }
            })
    }

    handleViewingNote = (e) => {
        this.setState({ viewing_note: e.target.value })
    }

    handleViewingDate = (date, dateString) => {
        console.log("***** Date to String ***** ", dateString)
        this.setState({ viewing_date: dateString })
    };

    getFollowUpType = (type) => {
        if (type == 1){
            return "Follow Up";
        }else if(type == 2){
            return "Call"
        }else if(type == 3){
            return "Email"
        }else if(type == 4){
            return "Viewing"
        }else if(type == 5){
            return "Offer"
        }else{
            return "Whatsapp"
        }
    };

    handleViewingAgent = (e) => {
        this.setState({ viewing_agent: e })
    }

    generateEventDescription = (viewing_listings) => {
        let htmlDesc = "<div>"
        viewing_listings.map((viewings_listing) => {
            htmlDesc += '<p>Ref No:' + viewings_listing.refno + '</p>'
            htmlDesc += '<p>Unit No:' + viewings_listing.unitno + '</p>'
            htmlDesc += '<p>Community:' + viewings_listing.community + '</p>'
            htmlDesc += '<p>Sub Community:' + viewings_listing.subcommunity + '</p>'
            htmlDesc += '<p>Property:' + viewings_listing.property + '</p><br>'

        });
        htmlDesc += "</div>"
        return htmlDesc;
    }

    generateFollowUpsEventDescription = (viewing_listings) => {
        let htmlDesc = "<div>"
        htmlDesc += '<p>Notes:' + viewing_listings.followup_note + '</p>'
        htmlDesc += '<p>Type:' + (viewing_listings.type == 1) ? "Vendor" : "Client" + '</p><br>'
        htmlDesc += '<p>Type:' + "Client" + '</p><br>'
        htmlDesc += "</div>"
        return htmlDesc;
    }

    agentEmails = (viewing_listings) => {
        let agentEmailsData = []
        viewing_listings.map((viewings_listing) => {
            agentEmailsData.push({ email: viewings_listing.agent_email })

        });
        return agentEmailsData;
    }

    createEvent = (viewing_state_data, startingDate, type) => {
        const [date, time] = startingDate.split(' ');
        const [year, month, day] = date.split('-');
        const [hours, minutes] = time.split(':');
        const isoString = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
        const dateObject = new Date(isoString);
        const startDateInIsoFormat = dateObject.toISOString();

        const starting_date = new Date(startDateInIsoFormat);
        const newStartingDate = new Date(starting_date.getTime() - (4 * 60 * 60 * 1000));
        const startingDateInUtc = newStartingDate.toISOString();
        const endDate = new Date(startingDateInUtc);
        endDate.setHours(endDate.getHours() + 1);
        const endDateInIsoFormat = endDate.toISOString();
        let eventData = {};
        if (type == "viewing"){
            eventData = {
                summary: "Leads Viewing Event",
                description: this.generateEventDescription(viewing_state_data),
                start: {
                    dateTime: startingDateInUtc,
                    timeZone: "Asia/Dubai",
                },
                end: {
                    dateTime: endDateInIsoFormat,
                    timeZone: "Asia/Dubai",
                },
                attendees: this.agentEmails(viewing_state_data),
                sendUpdates: "all"
            };
        }else{
            eventData = {
                summary: "Leads Followups Event",
                description: this.generateFollowUpsEventDescription(viewing_state_data),
                start: {
                    dateTime: startingDateInUtc,
                    timeZone: "Asia/Dubai",
                },
                end: {
                    dateTime: endDateInIsoFormat,
                    timeZone: "Asia/Dubai",
                },
                // attendees: this.agentEmails(viewing_state_data),
                // sendUpdates: "all"
            };
        }

        try {
            apiCalendar
                .createEvent(eventData)
                .then((response) => {
                    this.setState({ sendEvent: true })
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    signIn = () => {
        apiCalendar.handleAuthClick();
    }

    handleRequestViewingOk = () => {
        if (this.state.viewing_ref_no == '' ||
            this.state.viewing_agent == '' ||
            this.state.viewing_date == ''
        ) {
            this.setState({ checkErr: true });
            // toast.error( "fields cannot be empty");
        }
        else {
            this.setState({ isViewing: true });
            this.setState({ checkErr: false });
            const data = {
                lead_id: this.state.record_id,
                lead_ref_no: this.state.lead_details.refno,
                viewing_ref_no: this.state.viewing_ref_no,
                viewing_agent: this.state.viewing_agent,
                viewing_date: this.state.viewing_date,
                viewing_note: this.state.viewing_note,
                "current_request": this.state.currentRequest
            }
            API.post("/add_request_viewing", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({ isRequestviewingOpen: false, property_viewings: response.data.property_viewings, viewing_notes: response.data.viewing_notes, isViewing: false })
                        this.clearViewingForm();
                        this.setState({ checkErr: false });
                        API.get("/lead_details/" + this.state.record_id)
                            .then((response) => {
                                if (response.data.success){
                                    this.setState({all: response.data.all})
                                this.setState({requested_viewings: response.data.requested_viewings})
                                this.setState({currentRequest: null})
                                this.setState({
                                    lead_details: response.data.lead_details, lead_list_details: response.data.lead_list_details, owner_other_properties: response.data.owner_other_properties,
                                    all: response.data.all,
                                    requested_viewings: response.data.requested_viewings,
                                    role: response.data.role.role,
                                    agent_id: response.data.role.id,
                                    contact_type_requirement:response.data.lead_list_details.contact_type,
                                    notes: response.data.notes, requirement_community: response.data.lead_list_details.community,
                                    call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                                    viewing_notes: response.data.viewing_notes,
                                    email_notes: response.data.email_notes,
                                    followup_notes: response.data.followup_notes,
                                    offer_notes: response.data.offer_notes,
                                    owner_id: response.data.lead_list_details.owner_id,
                                    comment: response.data.lead_details.comment,
                                    owner_name: response.data.lead_list_details.owner,
                                    owner_email: response.data.lead_list_details.owner_email,
                                    owner_mobile: response.data.lead_list_details.mobile,
                                    requirement_bedroom: response.data.lead_list_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                                    requirement_max_budget: response.data.lead_details.budget_to,
                                    requirement_lead_stage: response.data.lead_list_details.lead_stage,requirement_verifyedby:response.data.lead_details.verified_by,
                                    requirement_community: response.data.lead_list_details.community, requirement_sub_community: response.data.lead_list_details.subcommunity,
                                    requirement_agent_id: response.data.lead_list_details.lead_agent, lead_community: response.data.lead_list_details.community, lead_sub_community: response.data.lead_list_details.subcommunity,
                                    collaborators: response.data.collaborators,
                                    property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                                })}
                                else{
                                    this.setState({ isRequestviewingOpen: false, property_viewings: response.data.property_viewings, viewing_notes: response.data.viewing_notes, isViewing: false })
                                    this.clearViewingForm();
                                    this.setState({ checkErr: false });
                                }
                            }).catch((err) => {
                            console.log(err)
                            this.setState({currentRequest: null})
                            toast.error('Something went wrong');
                        }).finally(() => {
                            this.setState({currentRequest: null})
                        })
                        const email_data = {
                            lead_id: this.state.record_id,
                            lead_ref_no: this.state.lead_details.refno,
                            viewing_ref_no: this.state.viewing_ref_no,
                            viewing_agent: this.state.viewing_agent,
                            viewing_date: this.state.viewing_date,
                            viewing_note: this.state.viewing_note,
                            views_id: response.data.propertyviewing_ids
                        }
                        API.post("/add_viewing_emails", email_data)
                            .then((res) => {

                                if (res.data.success) {
                                    console.log("success")
                                }
                            })
                        this.createEvent(this.state.viewings_listings, this.state.viewing_date, "viewing");
                    }
                    else {
                        this.setState({ isViewing: false });
                        this.setState({ checkErr: false });
                    }

                })
                .catch((err) => {
                    this.setState({ isViewing: false });
                    this.setState({ checkErr: false });
                })
        }

    }

    handleRequestViewingCancel = () => {
        this.setState({ isRequestviewingOpen: false })
        this.setState({ checkErr: false });
        this.setState({ currentRequest: null });
        this.setState({ viewing_agent: "" });
        this.setState({ ref_def_nums: [] });
    }

    handleViewingOk = () => {
        if (this.state.viewing_ref_no == '' ||
            this.state.viewing_agent == '' ||
            this.state.viewing_date == ''
            // ||
            // this.state.viewing_note == ''
        ) {
            this.setState({ checkErr: true });
            // toast.error( "fields cannot be empty");
        }
        else {
            this.setState({ isViewing: true });
            this.setState({ checkErr: false });
            const data = {
                lead_id: this.state.record_id,
                lead_ref_no: this.state.lead_details.refno,
                viewing_ref_no: this.state.viewing_ref_no,
                viewing_agent: this.state.viewing_agent,
                viewing_date: this.state.viewing_date,
                viewing_note: this.state.viewing_note
            }

            API.post("/add_viewing", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({ isviewingOpen: false, property_viewings: response.data.property_viewings, viewing_notes: response.data.viewing_notes, isViewing: false })
                        this.clearViewingForm();
                        this.setState({ checkErr: false });
                        API.get("/lead_details/" + this.state.record_id)
                            .then((response) => {
                                if (response.data.success)
                                    this.setState({all: response.data.all})
                                this.setState({requested_viewings: response.data.requested_viewings})
                                this.setState({currentRequest: null})
                                    this.setState({
                                        lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
                                        all: response.data.all,
                                        role: response.data.role.role,
                                        requested_viewings: response.data.requested_viewings,
                                        agent_id: response.data.role.id,
                                        contact_type_requirement:response.data.lead_details.contact_type,
                                        notes: response.data.notes, requirement_community: response.data.lead_details.community,
                                        call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                                        viewing_notes: response.data.viewing_notes,
                                        email_notes: response.data.email_notes,
                                        followup_notes: response.data.followup_notes,
                                        offer_notes: response.data.offer_notes,
                                        owner_id: response.data.lead_details.owner_id,
                                        comment: response.data.lead_details.comment,
                                        owner_name: response.data.lead_details.owner,
                                        owner_email: response.data.lead_details.owner_email,
                                        owner_mobile: response.data.lead_details.mobile,
                                        requirement_bedroom: response.data.lead_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                                        requirement_max_budget: response.data.lead_details.budget_to,
                                        requirement_lead_stage: response.data.lead_details.lead_stage,requirement_verifyedby:response.data.lead_details.verified_by,
                                        requirement_community: response.data.lead_details.community, requirement_sub_community: response.data.lead_details.subcommunity,
                                        requirement_agent_id: response.data.lead_details.lead_agent, lead_community: response.data.lead_details.community, lead_sub_community: response.data.lead_details.subcommunity,
                                        collaborators: response.data.collaborators,
                                        property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                                    })
                            })
                        const email_data = {
                            lead_id: this.state.record_id,
                            lead_ref_no: this.state.lead_details.refno,
                            viewing_ref_no: this.state.viewing_ref_no,
                            viewing_agent: this.state.viewing_agent,
                            viewing_date: this.state.viewing_date,
                            viewing_note: this.state.viewing_note,
                            views_id: response.data.propertyviewing_ids
                        }
                        API.post("/add_viewing_emails", email_data)
                            .then((res) => {

                                if (res.data.success) {
                                    console.log("success")
                                }
                            })
                        this.createEvent(this.state.viewings_listings, this.state.viewing_date, "viewing");
                    }
                    else {
                        this.setState({ isViewing: false });
                        this.setState({ checkErr: false });
                    }

                })
                .catch((err) => {
                    this.setState({ isViewing: false });
                    this.setState({ checkErr: false });
                })
        }

    }

    clearViewingForm = () => {
        this.setState({
            viewing_ref_no: '',
            viewing_unit_no: '',
            viewing_agent: '',
            // viewing_date:'',
            ref_def_nums: [],
            viewings_listings: []
        })
    }

    handleViewingCancel = () => {
        this.setState({ isviewingOpen: false })
        this.setState({ checkErr: false });
    }

    handleEditViewing(view_id, e) {
        this.setState({ view_id: view_id })
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Scheduled" ? true : false,
                        viewing_price: response.data.viewings.amount, iseditviewingOpen: true
                    })
                }

            })
    }

    handleOfferView = (view_id, e) => {
        this.setState({ view_id: view_id })
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {

                    {
                        /**
                         viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                         viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                         showPrice: response.data.viewings.status == "Offer" ? true : false,
                         showDate: response.data.viewings.status == "Scheduled" ? true : false,
                         viewing_price: response.data.viewings.amount, iseditviewingOpen: true
                         */
                    }

                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Scheduled" ? true : false,
                        viewing_price: response.data.viewings.amount, isOfferOpen: true
                    })
                }

            })
    }

    handleViewStatus = (e) => {
        const status = e
        this.setState({ viewing_status: e })
        if (status == "Offer") {
            this.setState({ showPrice: true })
        } else {
            this.setState({ showPrice: false })
        }

        if (status == "Scheduled") {
            this.setState({ showDate: true })
        } else {
            this.setState({ showDate: false })
        }
    }

    handleViewStatusChange = () => {
        const data = { lead_id: this.state.record_id, view_id: this.state.view_id, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price, note: this.state.viewing_note }
        API.post("/change_viewing_status", data)
            .then((response) => {

                this.setState({
                    property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                    all:response.data.all,notes:response.data.notes,
                    call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                    //viewing_notes: response.data.viewing_notes,
                    email_notes: response.data.email_notes,
                    followup_notes: response.data.followup_notes,
                    offer_notes: response.data.offer_notes,
                    viewing_notes: response.data.viewing_notes,
                })
            })
        this.setState({ iseditviewingOpen: false })
    }
    handleViewStatusCancel = () => {
        this.setState({ iseditviewingOpen: false })
    }

    handleViewingPrice = (e) => {
        this.setState({ viewing_price: e.target.value })
    }


    fetchFollowUp(leads_id) {
        API.get("/followups/" + leads_id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ followups: response.data.followups })
                }

            })
    }

    fetchFollowUpDetails(leads_id) {
        API.post("/followup_details/" + leads_id, {type: "client"})
            .then((response) => {
                if (response.data.success) {
                    this.setState({ followups_details: response.data.followups })
                }

            })
    }

    handleNewFollowUp = () => {
        this.setState({
            followup_date: "",
            followup_note: "",
            selected_agent: "",
            followup_for: "",
            reminder: "",
            isfollowupOpen: true
        })
        if (this.state.sendEvent == false) {
            setTimeout(this.signIn(), 5000)
        }
        if (this.state.role != 1 && this.state.role != 2){
            this.setState({
                selected_agent: this.state.agent_id
            })
        }
    }
    handleFollowupDate = (data, dateString) => {
        this.setState({ followup_date: dateString })
    }

    handleFollowupNote = (e) => {
        this.setState({ followup_note: e.target.value })
    }

    handleFollowupOk = () => {
        if (this.validate()){
            const lead_id = this.state.record_id
            const data = {
                leads_id: this.state.record_id,
                listing_id: 0,
                contacts_id: this.state.owner_id,
                followup_date: this.state.followup_date,
                followup_note: this.state.followup_note,
                type: this.state.type,
                selected_agent: this.state.selected_agent,
                followup_for: this.state.followup_for,
                reminder_time: this.state.reminder
            }

            API.post("/add_followup_details", data)
                .then((response) => {
                    if (response.data.success) {
                        this.fetchFollowUpDetails(this.state.record_id)
                        this.createEvent(data, this.state.followup_date, "followups");
                        this.setState({ isfollowupOpen: false })
                        API.get("/lead_details/" + this.state.record_id)
                            .then((response) => {
                                if (response.data.success)
                                    this.setState({requested_viewings: response.data.requested_viewings})
                                this.setState({currentRequest: null})
                                    this.setState({
                                        lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
                                        all: response.data.all,
                                        role: response.data.role.role,
                                        agent_id: response.data.role.id,
                                        requested_viewings: response.data.requested_viewings,
                                        comment: response.data.lead_details.comment,
                                        contact_type_requirement:response.data.lead_details.contact_type,
                                        notes: response.data.notes, requirement_community: response.data.lead_details.community,
                                        call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                                        viewing_notes: response.data.viewing_notes,
                                        email_notes: response.data.email_notes,
                                        followup_notes: response.data.followup_notes,
                                        offer_notes: response.data.offer_notes,
                                        owner_id: response.data.lead_details.owner_id,
                                        owner_name: response.data.lead_details.owner,
                                        owner_email: response.data.lead_details.owner_email,
                                        owner_mobile: response.data.lead_details.mobile,
                                        requirement_bedroom: response.data.lead_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                                        requirement_max_budget: response.data.lead_details.budget_to,
                                        requirement_lead_stage: response.data.lead_details.lead_stage,requirement_verifyedby:response.data.lead_details.verified_by,
                                        requirement_community: response.data.lead_details.community, requirement_sub_community: response.data.lead_details.subcommunity,
                                        requirement_agent_id: response.data.lead_details.lead_agent, lead_community: response.data.lead_details.community, lead_sub_community: response.data.lead_details.subcommunity,
                                        collaborators: response.data.collaborators,
                                        property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                                    })
                            })
                    }


                })
        }
    }
    handleFollowupCancel = () => {
        this.setState({ isfollowupOpen: false })
    }

    validate() {
        let followup_for = this.state.followup_for;
        let selected_agent = this.state.selected_agent;
        let followup_date = this.state.followup_date;
        let followup_note = this.state.followup_note;
        let validationerrors = {};
        let isValid = true;


        if (followup_for == null || followup_for == "" || followup_for == 0) {
            isValid = false;
            validationerrors["followup_for"] = "Follow up for is required.";
        }
        if (selected_agent == null || selected_agent == "" || selected_agent == 0) {
            isValid = false;
            validationerrors["selected_agent"] = "Agent is required.";
        }
        if (followup_date == null || followup_date == "" || followup_date == 0) {
            isValid = false;
            validationerrors["followup_date"] = "Date is required.";
        }
        if (followup_note == null || followup_note == "" || followup_note == 0) {
            isValid = false;
            validationerrors["followup_note"] = "Note is required.";
        }

        this.setState({
            validationerrors: validationerrors
        });

        return isValid;
    }

    handleViewFollowup = (data, e) => {
        this.setState({ isfollowupViewOpen: true, followup_data: data })
    }

    handleFollowupViewOk = () => {
        this.setState({ isfollowupViewOpen: false })

    }

    handleFollowupViewCancel = () => {
        this.setState({ isfollowupViewOpen: false })
    }

    handleOkOffer = () => {
        this.setState({ isOfferOpen: false })
    }
    handleCancelOffer = () => {
        this.setState({ isOfferOpen: false })
    }

    handleAllMatch = () => {

        const data = {
            lead_id: this.state.record_id,
            community_match: this.state.community_match,
            sub_community_match: this.state.sub_community_match,
            min_budget: this.state.min_budget, max_budget: this.state.max_budget,
            beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
            property_type_match: this.state.property_type_match,
            added_date_match: this.state.added_date_match
        }
        this.props.setNoMatch(data)
        window.open("/matching_listings", "_blank");
        //this.props.history.push("/matching_listings")
    }

    handleRemoveList = (refno, e) => {


        this.setState({
            viewings_listings: this.state.viewings_listings.filter(function (viewing_listing) {
                return viewing_listing.refno !== refno
            })
        });

        this.setState({
            ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
                return ref_num.value !== refno
            })
        });

        this.setState({
            ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
                return ref_num !== refno
            })
        });
    }

    // New Code By Farooq


    handleCommunityFilter = (e) => {

        this.setState({ community_filter: e })
        if(e != null || e !==''){
            API.get("/sub_community/" + e)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ sub_communities: response.data.sub_communities })
                })
        }

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {

            id:id,
            property_type_filter: this.state.property_type_filter,
            community_filter: e,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,

            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,


            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }


        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handleSubCommunityFilter = (e) => {


        API.get("/property/" + e)
            .then((response) => {
                if (response.data.success)
                    this.setState({ properties: response.data.properties })
            })


        this.setState({ sub_community_filter: e })



        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {

            id:id,
            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: e,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,


            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }


        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }




    handlePropertyFilter = (e) => {


        this.setState({ property_filter: e })



        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {

           id:id,
            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: e,
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,


            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }


        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handlePropertyTypeFilter = (e) => {


        this.setState({ property_type_filter: e })

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {

            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: e,


            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }


        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    postAddCollaborator = () => {
        const data = {
            colls: this.state.newArrSelectedAgents,
            lead_id: this.state.record_id
        }
        API.post("/add_collaborate ", data)
            .then((response) => {
                if (response.data) {
                    if (response.data.success) {
                        this.setState({ collaborators: response.data.collaborators, newArrSelectedAgents: [] })
                    }
                }
                else {
                    console.log("***Check Add Collaborator Not Success *** ", response);
                }
            }).catch((err) => console.log("**CHeck Error *** ", err))
    }

    handleChangeSelect = (value) => {
        this.setState({ newArrSelectedAgents: value })
    };
    onSelectAgents = (value) => {
        const arr = this.state.agents.filter((data) => {
            if (data.value == value) {
                return data;
            }
        })
        // this.setState({newArrSelectedAgents: [...this.state.newArrSelectedAgents, arr]});
        this.setState((state, props) => ({
            newArrSelectedAgents: state.newArrSelectedAgents, arr
        }));
    }

    onChangeCallNote = (value) => {
        this.setState({ call_log: value })
    }

    onSelectCallMentions = (option) => {
        this.setState({ selectedCallUsers: [...this.state.selectedCallUsers, option] })

    }

    onChangeNoteNote = (value) => {
        this.setState({ note_log: value })
    }

    onSelectNoteMentions = (option) => {
        this.setState({ selectedNoteUsers: [...this.state.selectedNoteUsers, option] })

    }

    onChangeWhatsappNote = (value) => {
        this.setState({ whatsapp_log: value })
    }

    onSelectWhatsappMentions = (option) => {
        this.setState({ selectedWhatsappUsers: [...this.state.selectedWhatsappUsers, option] })

    }

    onChangeEmailNote = (value) => {
        this.setState({ email_log: value })
    }

    onSelectEmailMentions = (option) => {
        this.setState({ selectedEmailUsers: [...this.state.selectedEmailUsers, option] })

    }

    onChangeViewingNote = (value) => {
        this.setState({ viewing_log: value })
    }

    onSelectViewingMentions = (option) => {
        this.setState({ selectedViewingUsers: [...this.state.selectedViewingUsers, option] })

    }
    onChangeOfferNote = (value) => {
        this.setState({ offer_log: value })
    }

    onSelectOfferMentions = (option) => {
        this.setState({ selectedOfferUsers: [...this.state.selectedOfferUsers, option] })

    }
    onChangeFollowupNote = (value) => {
        this.setState({ followup_log: value })
    }

    onSelectFollowupMentions = (option) => {
        this.setState({ selectedFollowupUsers: [...this.state.selectedFollowupUsers, option] })

    }

    handleSaveOwnerName = (value) => {

        const data = {
            owner_id: this.state.owner_id,
            owner_name: value
        }
        API.post("/update_owner_name", data)
            .then((response) => {
                console.log("updated")
            })

    }

    handleSaveOwnerEmail = (value) => {
        const data = {
            owner_id: this.state.owner_id,
            owner_email: value
        }
        API.post("/update_owner_email", data)
            .then((response) => {
                console.log("updated")
            })
    }

    handleClearFilter = () => {
      let id = this.props.match.params.id;

        this.setState({

            property_type_filter: [],
            community_filter: "",
            sub_community_filter: "",
            property_filter: "",
            name_value_id: 1,
            name_value: "",
            refno_value: "",
            refno_value_id: 1,
            phone_value_id: 1,
            phone_value: "",
            bed_rooms_value_id: 1,
            bed_rooms_value: "",
            agent_value_id: 1,
            agent_value: [],
            stage_value: [],
            contact_types_value: [],
            furnished_types_value: [],
            price_first_value_id: 1,
            price_first_value: "",
            price_min: 0,
            price_max: 0,
            date_morethan_option: true,
            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            date_lessthan_option: false,
            date_value: "",
            date_value_id: 1,

            viewing_value:"",
            viewing_option_value:1,
            inrange_viewing:false,
            viewing_min_value:"",
            viewing_max_value:"",


            offer_value:"",
            offer_option_value:1,
            inrange_offer:false,
            offer_min_value:"",
            offer_max_value:"",



            last_call_value: "",
            last_call_value_id: 1,
            last_call_option_value:1,



            last_match_value: "",
            last_match_value_id: 1,
            last_match_option_value:1,


            move_in_date_value: "",
            move_in_date_value_id: 1,
            move_in_date_option_value:1,
            move_in_date_date_range:[],

            inrange: false
        })
        const page = 1;
        const perPage = 10;
        const data = {
            id:id,
            property_type_filter: [],
            community_filter: "",
            sub_community_filter: "",
            property_filter: "",
            name_value_id: 1,
            name_value: "",
            refno_value: "",
            refno_value_id: 1,
            phone_value_id: 1,
            phone_value: "",
            bed_rooms_value_id: 1,
            bed_rooms_value: "",
            agent_value_id: 1,
            agent_value: [],
            stage_value: [],
            contact_types_value: [],
            furnished_types_value: [],
            price_first_value_id: 1,
            price_first_value: "",
            price_min: 0,
            price_max: 0,
            date_morethan_option: true,
            date_lessthan_option: false,

            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            date_value: "",
            date_value_id: 1,

            viewing_value:"",
            viewing_option_value:1,
            inrange_viewing:false,
            viewing_min_value:"",
            viewing_max_value:"",


            offer_value:"",
            offer_option_value:1,
            inrange_offer:false,
            offer_min_value:"",
            offer_max_value:"",



            last_call_value: "",
            last_call_value_id: 1,
            last_call_option_value:1,



            last_match_value: "",
            last_match_value_id: 1,
            last_match_option_value:1,


            move_in_date_value: "",
            move_in_date_value_id: 1,
            move_in_date_option_value:1,
            move_in_date_date_range:[],
            inrange: false


        }

        this.fetchData(page, perPage, data)
        this.fetchComparable(page, perPage, data);

    }


    saveLeadStage = (value) => {

        // done


        const data = { leads_id: this.state.record_id, lead_stage: value }
        if(value==5){
            Swal.fire({
                title: 'Lead Stage Status Update',
                text: 'Please provide a note about the status being changed to Dead.',
                input: 'text',
                className: 'deadstage-wrap',
                inputAttributes: {autocapitalize: 'off'},
                showCancelButton: false,
                confirmButtonText: 'Add note',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire( "<p class='confirmedstat'>Status changed to Dead lead.</p>")
                  API.post("/update_lead_stage", data)
                    .then((response) => {
                        if (response.data.success) {
                            this.setState({
                                all: response.data.all,
                                notes: response.data.notes
                            })
                        }
                    }).catch((err) => console.log(err));
                    const confirm_data = {leads_id:this.state.record_id, confirm_message:result.value}
                    console.log(confirm_data);
                    API.post("/add_confirm_note", confirm_data)
                    .then((response) => {
                        if (response.data.success) {
                            this.setState({
                                all: response.data.all,
                                notes: response.data.notes
                            })
                        }
                    }).catch((err) => console.log(err));
                }
              })
        }else if(value == 2 || value == 3 || value == 4){
            this.setState({contact_type_call_note_status: value})
            this.setState({isCallLogOpen: true})
        }else{
            API.post("/update_lead_stage", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            }).catch((err) => console.log(err));
        }
    }

    saveMoveInDate = (value) => {
        // const data = { leads_id: this.state.record_id, move_in_date: value }
        // edit by Farooq, the above one is wrong date state...
        this.setState({
            leads_id: this.state.record_id,
            requirement_move_in_date: value
        })
        API.post("/update_move_in_date", {leads_id: this.state.record_id, move_in_date: value})
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }

    saveVerifier = (value) => {
        // return
        // const data = { leads_id: this.state.record_id, verifier_by: value }
        this.setState({
            leads_id: this.state.record_id, verifier_by: value
        })
        API.post("/update_verifier_by", { leads_id: this.state.record_id, verifier_by: value })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }

    saveContactType = (value) => {
        // return
        // const data = { leads_id: this.state.record_id, verifier_by: value }
        this.setState({
            leads_id: this.state.record_id,
        })
        API.post("/update_contact_type", { leads_id: this.state.record_id, contact_type: value })
            .then((response) => {

                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }




    saveAgent = (value) => {
        const data = { leads_id: this.state.record_id, lead_agent: value }
        API.post("/update_agent", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }

    saveCommunity = (value) => {
        this.setState({ requirement_community: value })
        const data = { leads_id: this.state.record_id, community: value }
        API.post("/update_community", data)
            .then((response) => {



                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
        if( value !== null || value !== ''){
            API.get("/sub_community/" + value)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ requirement_sub_communities: response.data.sub_communities })
                })
        }
    }

    saveSubCommunity = (value) => {
        this.setState({ requirement_sub_community: value })
        const data = { leads_id: this.state.record_id, subcommunity: value }
        API.post("/update_subcommunity", data)
            .then((response) => {



                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }


    saveBedroom = (value) => {
        const data = { leads_id: this.state.record_id, bedroom: value }
        API.post("/update_bedroom", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes,
                        bedroom_match:value
                    })
                }
            })
    }

    // By Farooq

    addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

    fAmountSaperator = (value) => {
        return this.addCommas(this.removeNonNumeric(value));
    }

    savePrice = (value) => {

        const value_ = value
        const new_value = value_.replace(/,/g, "")

        this.setState({
            price_requirement: value,min_budget:new_value
        })
        if (new_value > 0) {
            const data = { leads_id: this.state.record_id, price_requirement: new_value }
            API.post("/update_price", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes
                        })
                    }
                })
        }

    }

    saveMax = (value) => {
        const value_ = value
        const new_value = value_.replace(/,/g, "")


        this.setState({
            requirement_max_budget: value,max_budget:new_value
        })
        if (new_value > 0) {
            const data = { leads_id: this.state.record_id, max_budget: new_value }
            API.post("/update_max_budget", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes
                        })
                    }
                })
        }

    }



    handleViewingValue_id = (e) =>{
        this.setState({viewing_option_value:e.target.value})
        if(e.target.value == 5){
            this.setState({inrange_viewing:true})
        }else{
            this.setState({inrange_viewing:false})

        }
        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {
            id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:e.target.value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }

        if(this.state.viewing_value !="")
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);


    }
    handleViewingValue = (e) =>{
        this.setState({ viewing_value: e.target.value })
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:e.target.value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }

        const page = 1;
        const perPage = 10;
        if(e.target.value !="")
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleViewingMinValue = (e) =>{
        this.setState({viewing_min_value:e.target.value})
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:e.target.value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }

        const page =1
        const perPage = 10
        if(this.state.viewing_max_value!="" && e.target.value !="")
            this.fetchData(page,perPage,data)
    }

    handleViewingMaxValue = (e) =>{
        this.setState({viewing_max_value:e.target.value})
        const page=1
        const perPage =10
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:e.target.value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }
        if(this.state.viewing_min_value!="" && e.target.value !="")
            this.fetchData(page,perPage,data)
    }
    // offer

    handleOfferValue_id = (e) =>{
        this.setState({offer_option_value:e.target.value})
        if(e.target.value == 5){
            this.setState({inrange_offer:true})
        }else{
            this.setState({inrange_offer:false})
        }
        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:e.target.value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range


        }

        if(this.state.offer_value!="" )
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);


    }
    handleOfferValue = (e) =>{
        this.setState({ offer_value: e.target.value })
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:e.target.value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if(e.target.value !="")
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleOfferMinValue = (e) =>{
        this.setState({offer_min_value:e.target.value})
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:e.target.value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }

        const page =1
        const perPage = 10
        if(this.state.offer_max_value!="" && e.target.value !="")
            this.fetchData(page,perPage,data)
    }

    handleOfferMaxValue = (e) =>{
        this.setState({offer_max_value:e.target.value})
        const page=1
        const perPage =10
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:e.target.value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range
        }
        if(this.state.offer_min_value!="" && e.target.value !="")
            this.fetchData(page,perPage,data)
    }
    handleRefnoValue_id = (e) =>{
        this.setState({refno_value_id:e})
        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: e,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range

        }

        if(this.state.refno_value !="")
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleRefnoValue = (e) =>{
        this.setState({refno_value:e.target.value})
        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: e.target.value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:this.state.move_in_date_date_range

        }

        if(e.target.value !="")
            this.fetchData(page, perPage, data);
            this.fetchComparable(page, perPage, data);
    }

    handleRangeDate = (value, dateString) =>{



        this.setState({move_in_date_date_range:value !=null?dateString:[]})

        const page = 1;
        const perPage = 10;
        let id = this.props.match.params.id;

        const data = {
          id:id,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value:this.state.viewing_value,
            viewing_morethan_value:this.state.viewing_morethan_value,
            viewing_lessthan_value:this.state.viewing_lessthan_value,
            viewing_option_value:this.state.viewing_option_value,
            inrange_viewing:this.state.inrange_viewing,
            viewing_min_value:this.state.viewing_min_value,
            viewing_max_value:this.state.viewing_max_value,


            offer_value:this.state.offer_value,
            offer_morthan_value:this.state.offer_morthan_value,
            offer_lessthan_value:this.state.offer_lessthan_value,
            offer_option_value:this.state.offer_option_value,
            inrange_offer:this.state.inrange_offer,
            offer_min_value:this.state.offer_min_value,
            offer_max_value:this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value:this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value:this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value:this.state.move_in_date_option_value,
            move_in_date_date_range:value !=null?dateString:[]


        }


        this.fetchData(page, perPage, data);
        this.fetchComparable(page, perPage, data);
    }

    handleToggleViewGallery = () => { this.setState({ isViewAsGallery: true }) }
    handleToggleViewTable = () => { this.setState({ isViewAsGallery: false }) }

    render() {

        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let role_name = userdata.auth.user.role_name;
        const user_id = userdata.auth.user.id

        var currentTime = new Date();
        currentTime.setDate(currentTime.getDate()-7);

        var twoDays = new Date();
        twoDays.setDate(twoDays.getDate()-1);

        console.log("14 day from now is",currentTime);
       // const dt = new Date();
const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);


const full_two_days = padL(twoDays.getFullYear())+"-"+padL(twoDays.getMonth()+1)+"-"+padL(twoDays.getDate())+" "+padL(twoDays.getHours())+":"+padL(twoDays.getMinutes())+":"+padL(twoDays.getSeconds())

const full_date = padL(currentTime.getFullYear())+"-"+padL(currentTime.getMonth()+1)+"-"+padL(currentTime.getDate())+" "+padL(currentTime.getHours())+":"+padL(currentTime.getMinutes())+":"+padL(currentTime.getSeconds())


// console.log(`${
//     padL(currentTime.getMonth()+1)}-${
//     padL(currentTime.getDate())}-${
//         currentTime.getFullYear()} ${
//     padL(currentTime.getHours())}:${
//     padL(currentTime.getMinutes())}:${
//     padL(currentTime.getSeconds())}`
// );
   
        const isUserFound = this.state.collaborators.some(element => {
            if (element.user_id === user_id) {
                return true
            } else {
                return false
            }
        });



        const columnsCollaborator = [
            {
                title: 'User',
                dataIndex: 'User',
                key: 'user',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Added By',
                dataIndex: 'Added By',
                key: 'addedBy',
            },
            {
                title: 'Added Date',
                dataIndex: 'Added Date',
                key: 'addedDate',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'Action',
                render: () => (
                    <>
                        <DeleteFilled style={{ fontSize: '1rem' }} />
                    </>
                ),
            },
        ];
        const dataCollaborator = [
            {
                key: '1',
                name: 'lead Pool',
                age: 32,
                address: '12/12/2022',
            },
            // {
            //   key: '2',
            //   name: 'Jim Green',
            //   age: 42,
            //   address: '12/12/2022',
            // },
            // {
            //   key: '3',
            //   name: 'Joe Black',
            //   age: 32,
            //   address: '12/12/2022',
            // },
        ];




        const logsitems = [
            {
                key: '1',
                label: `all (10)`,

            },
            {
                key: '2',
                label: <PhoneOutlined />,
            },
            {
                key: '3',
                label: <WhatsAppOutlined />,
            },
            {
                key: '4',
                label: <MailOutlined />,
            },

        ];

        const onSelectChange = (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
            this.setState({selectedLeads: newSelectedRowKeys})
            // setSelectedRowKeys(newSelectedRowKeys);
        };

        const rowSelection = {
            // selectedRowKeys,
            onChange: onSelectChange,

        };

        const columns = [



            {
                title: 'Name',
                width: 180,fixed: 'left', style:{zIndex:'9'},
                //dataIndex: 'fullname',
                render: (text, record) => (
                    <>
                        {record.fullname == " " || record.fullname == "" || record.fullname == null ? 
                        <Link to={`/leads/${record.original_id}`} target="_blank" onClick={this.saveNavigation.bind(this, record.original_id)}>
                           <SearchOutlined /> 
                        </Link>
                        // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.original_id, false)}>
                        //     <SearchOutlined />
                        //     </Link> 
                            : 
                            <Link to={`/leads/${record.original_id}`} target="_blank" onClick={this.saveNavigation.bind(this, record.original_id)}>
                           {record.fullname} 
                        </Link>
                            // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.original_id, false)}>
                            //     {record.fullname}
                            //     </Link>
                                }
                                </>
                    // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
                ),
                sorter: (a, b) => a.fullname.length - b.fullname.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Name
                        <hr />
                        <Select
                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"

                            onChange={this.handleNameValue_id}
                            options={this.state.name_filter_options}
                        />
                        <div className='mt-2'>
                            <input type="text" className="form-control" value={this.state.name_value} onChange={this.handleName} placeholder="filter..." />
                        </div>




                    </div>
                ),
            },
            {
                title: 'Lead Stage',width: 160,
                // dataIndex: 'lead_stage',
                render: (text, record) => (
                    <>
                        {record.lead_stage == 1 ? <span className="uncontacted pstat">New Inquiry</span> : null}
                        {record.lead_stage == 2 ? <span className="working pstat">Working</span> : null}
                        {record.lead_stage == 3 ? <span className="hot pstat">Hot</span> : null}
                        {record.lead_stage == 4 ? <span className="followup pstat">Follow Up</span> : null}
                        {record.lead_stage == 5 ? <span className="dead pstat">Dead</span> : null}
                        {/* {record.lead_stage == 6 ? <span className="working pstat">Offer</span> : null}
                        {record.lead_stage == 7 ? <span className="followup pstat">Deal signed</span> : null} */}
                        {record.lead_stage == 8 ? <span className="followup pstat">Viewings</span> : null}
                        {record.lead_stage == 9 ? <span className="recruitment pstat">Recruitment</span> : null}
                    </>
                ),
                sorter: (a, b) => {
                    if (a && a.lead_stage && a.lead_stage.length && b && b.lead_stage && b.lead_stage.length) {
                        return a.lead_stage.length - b.lead_stage.length;
                    } else if (a && a.lead_stage && a.lead_stage.length) {
                        return -1;
                    } else if (b && b.lead_stage && b.lead_stage.length) {
                        return 1;
                    }
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Stages
                        <hr />

                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            value={this.state.stage_value}
                            placeholder="Please select"
                            onChange={this.handleStages}
                            options={this.state.stages}
                        />


                    </div>
                ),

            },

            {
                title: 'Agent',width: 200,minWidth:'200px',
                dataIndex: 'agents',
                render: (text, record) => {
                    let agent =  (this.state.agents) ? this.state.agents.find(items => items.value === record.agents) : record.agents;
                    return (agent && agent.label) ? agent.label : agent;
                },

                sorter: (a, b) => a.agents.length - b.agents.length,

                filterDropdown: (
                    <div className='form-group'
                         style={{
                             borderRadius: '0.3rem',
                             background: '#fff',
                             boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                         }}>
                        <label>Agent</label>
                        {/* <Select
              style={{
                width: '100%',
              }}
              showSearch
              allowClear
              placeholder="Please select"
              onChange={this.handleAgent}
              options={this.state.agent_filter_options}
            /> */}
                        <div className='mt-2'>
                            {/* <input type="text" value={this.state.agent_value} className="form-control" onChange={this.handleAgent} placeholder="filter..." /> */}

                            <Select
                                showSearch
                                mode={'multiple'}
                                value={this.state.agent_value}
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Search"
                                onChange={this.handleAgent}

                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.agents}
                            />

                        </div>
                    </div>
                ),
            },

            // {
            //     title: 'Phone',width: 140,
            //     dataIndex: 'mobile',
            //
            //     sorter: (a, b) => a.mobile.length - b.mobile.length,
            //
            //     filterDropdown: (
            //         <div style={{
            //             padding: '8px',
            //             borderRadius: '6px',
            //             background: '#fff',
            //             boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            //         }}>
            //             Phone number
            //             <hr />
            //             <Select
            //                 style={{
            //                     width: '100%',
            //                 }}
            //                 placeholder="Please select"
            //                 onChange={this.handlePhoneValue_id}
            //                 options={this.state.phone_filter_options}
            //             />
            //
            //             <div className='mt-2'>
            //                 <input type="text" value={this.state.phone_value} className="form-control" onChange={this.handlePhone} placeholder="filter..." />
            //             </div>
            //
            //
            //
            //
            //         </div>
            //     ),
            //     hidden:role==1?false: true
            // },
            {
                title: 'Last call',width: 150,
                dataIndex: 'last_call',
                render: (text, record) => (
                    <>
                        {
                            record.last_call == null || record.last_call =='' || record.last_call == "0000-00-00 00:00:00"?
                                ""
                                :
                                <Moment fromNow>
                                    {record.last_call}
                                </Moment>
                        }

                    </>


                ),
                sorter: (a, b) => {
                    if (a && a.last_call && a.last_call.length && b && b.last_call && b.last_call.length) {
                        return a.last_call.length - b.last_call.length;
                    } else if (a && a.last_call && a.last_call.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.last_call && b.last_call.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Last call
                        <hr />
                        <div className='mb-1'>
                            <select value={this.state.last_call_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastCallOptionValue} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>

                            </select>
                        </div>

                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleLastCallValue} />
                            <select className='form-select ml-1' onChange={this.handleLastCallValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },

            // last call here
            {
                title: 'Contact type',width: 180,
                dataIndex: 'contact_type',
                render: (text, record) => {

                    const contact_type = record.contact_type
                    if (contact_type == 1){
                        return "Tenant";
                    }else if(contact_type == 2){
                        return "Buyer";
                    } else if(contact_type == 3){
                        return "Landlord";
                    }else if(contact_type == 4){
                        return "Seller";
                    }else if(contact_type == 6){
                        return "Agent";
                    }else if(contact_type == 7){
                        return "Other";
                    }else if(contact_type == 8){
                        return "Portal";
                    }else if(contact_type == 9){
                        return "Buyer/Tenant";
                    }else{
                        return "Unrecognized";
                    }
                },
                sorter: (a, b) => {
                    if (a && a.contact_type && a.contact_type.length && b && b.contact_type && b.contact_type.length) {
                        return a.contact_type.length - b.contact_type.length;
                    } else if (a && a.contact_type && a.contact_type.length) {
                        return -1;
                    } else if (b && b.contact_type && b.contact_type.length) {
                        return 1;
                    }
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Contact type
                        <hr />

                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            value={this.state.contact_types_value}
                            placeholder="Please select"
                            onChange={this.handleContactType}
                            options={this.state.contact_types}
                        />


                    </div>
                ),

            },
            {
                title: ' Community',width: 200,
                dataIndex: 'community',
                sorter: (a, b) => a.community.length - b.community.length,
                render: (text, record) => {
                    if (record.community != null && record.community != "" && record.community != undefined){
                        let community =  (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                        return (community && community.label) ? community.label : community;
                    }else{
                        return "";
                    }
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Community
                        <Select
                            allowClear
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            value={this.state.community_filter}
                            placeholder="Search"
                            onChange={this.handleCommunityFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.communities}
                        />


                    </div>
                ),
            },
            {
                title: ' Sub Community',width: 300,
                dataIndex: 'subcommunity',
                sorter: (a, b) => a.subcommunity.length - b.subcommunity.length,
                render: (text, record) => {
                    if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined){
                        let subcommunity =  (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                        return (subcommunity && subcommunity.label) ? subcommunity.label : subcommunity;
                    }else{
                        return "";
                    }
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Sub Community
                        <Select
                            showSearch
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            value={this.state.sub_community_filter}

                            placeholder="Search"
                            onChange={this.handleSubCommunityFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.sub_communities}
                        />


                    </div>
                ),
            },
            {
                title: ' Property',width: 250,
                dataIndex: 'property',
                sorter: (a, b) => a.property.length - b.property.length,
                render: (text, record) => {
                    if (record.property != null && record.property != "" && record.property != undefined){
                        let property =  (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                        return (property && property.label) ? property.label : property;
                    }else{
                        return "";
                    }
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Property
                        <Select
                            showSearch
                            allowClear
                            style={{
                                width: '100%',
                            }}

                            value={this.state.property_filter}

                            placeholder="Search"
                            onChange={this.handlePropertyFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.properties}
                        />


                    </div>
                ),
            },
            {
                title: 'Price',width: 150,
                dataIndex: 'lead_price',
                sorter: (a, b) => {
                    if (a && a.lead_price && a.lead_price.length && b && b.lead_price && b.lead_price.length) {
                        return a.lead_price.length - b.lead_price.length;
                    } else if (a && a.lead_price && a.lead_price.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.lead_price && b.lead_price.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                render: (text, record) => {

                    const price = record.lead_price
                    if (!isNaN(parseInt(price))) {
                        if(price > 0 && price !=null){
                            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'

                        }else{
                            return null
                        }
                    } else {
                        return null;
                    }
                },
                //a.price.length > 0 - b.price.length > 0,
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Price
                        <hr />


                        <Select

                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"
                            onChange={this.handlePriceFirstValue_id}
                            options={this.state.price_options}
                        />

                        {/* <select className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select> */}
                        <div className='mt-2'>
                            {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input type="text" value={this.state.price_min} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                                <input type="text" value={this.state.price_max} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
                            </div> : <input type="text" value={this.state.price_first_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
                        </div>




                    </div>
                ),
            },

            {
                title: 'Beds',width: 100,
                dataIndex: 'bedroom',

                sorter: (a, b) => a.bedroom.length - b.bedroom.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Bedrooms
                        <hr />

                        <Select

                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"
                            onChange={this.handleBedRooms_id}
                            options={this.state.bedroom_filter_options}
                        />
                        {/* <select className="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleBedRooms_id}>

              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3"> Less than</option>
              <option value="4">Greater than</option>
            </select> */}
                        <div className='mt-2'>
                            <input type="text" value={this.state.bed_rooms_value} className="form-control" onChange={this.handleBedRoomsValue} placeholder="filter..." />
                        </div>




                    </div>
                ),
            },

            {
                title: 'FF / UF',width: 150,
                // dataIndex: 'furnished',
                render: (text, record) => (
                    <>
                        {record.furnished == 1 ? "Furnished" : "Unfurnished"}
                    </>


                ),

                sorter: (a, b) => {
                    if (a && a.furnished && a.furnished.length && b && b.furnished && b.furnished.length) {
                        return a.furnished.length - b.furnished.length;
                    } else if (a && a.furnished && a.furnished.length) {
                        return -1;
                    } else if (b && b.furnished && b.furnished.length) {
                        return 1;
                    }
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Furnished / Unfurnished
                        <hr />
                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            value={this.state.furnished_types_value}
                            placeholder="Please select"
                            onChange={this.handleFurnishedTypes}
                            options={this.state.furnished_types}
                        />


                    </div>
                ),

            },

            {
                title: 'Move in date',width: 180,
                dataIndex: 'move_in_date',
                render: (text, record) => (

                    <>

                        {
                            record.move_in_date == null || record.move_in_date =='' || record.move_in_date == "0000-00-00 00:00:00"?
                                ""
                                :
                                <Moment format="YYYY/MM/DD">{record.move_in_date}</Moment>
                        }


                    </>

                ),
                sorter: (a, b) => {
                    if (a && a.move_in_date && a.move_in_date.length && b && b.move_in_date && b.move_in_date.length) {
                        return a.move_in_date.length - b.move_in_date.length;
                    } else if (a && a.move_in_date && a.move_in_date.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.move_in_date && b.move_in_date.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Move in date
                        <hr />
                        <div className='mb-1'>
                            <select value={this.state.move_in_date_option_value} className="form-select" aria-label="Default select example" name='move_in_date' onChange={this.handleMoveInDateOptionValue} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Greater than</option>
                                <option value="4">Less than</option>
                                <option value="5">In Next</option>
                                <option value="6">In Previous</option>
                                <option value="7">Date from - to</option>
                                <option value="8">Is empty</option>

                            </select>
                        </div>

                        {this.state.move_in_date_is_empty?null:<>
                            {this.state.inrange_move_in_date? <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />:<div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input value={this.state.move_in_date_value} type="text" className="form-control" onChange={this.handleMoveInDateValue} />
                                <select className='form-select ml-1' onChange={this.handleMoveInDateValue_id}>
                                    <option value="1">days</option>
                                    <option value="2">weeks</option>
                                    <option value="3">months</option>
                                    <option value="4">years</option>
                                </select>
                            </div>}
                        </>}

                    </div>
                ),
            },

            {
                title: 'Type',width: 150,
                dataIndex: 'prop_type',
                render: (text, record) => {
                    if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined){
                        let prop_type =  (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                        return (prop_type && prop_type.label) ? prop_type.label : prop_type;
                    }else{
                        return "";
                    }
                },
                sorter: (a, b) => a.prop_type.length - b.prop_type.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Property type
                        <hr />


                        <Select
                            showSearch
                            allowClear

                            style={{
                                width: '100%',
                            }}
                            mode="multiple"
                            value={this.state.property_type_filter}

                            placeholder="Search"
                            onChange={this.handlePropertyTypeFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.propertytypes}
                        />



                    </div>
                ),
            },
            {
                title: 'Viewings',width: 150,
                dataIndex: 'viewings',
                render: (text, record) => (
                    <>
                        {(record.viewings != null) ? record.viewings : 0}
                    </>

                ),
                sorter: (a, b) => {

                },
                filterDropdown: (
                    <div style={{
                        padding: '15px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        <div >
                            <label>Viewings</label>
                            <select value={this.state.viewing_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleViewingValue_id} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>
                                <option value="5">In range</option>

                            </select>
                            <div className='mt-2'>
                                {this.state.inrange_viewing ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input type="text" value={this.state.viewing_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMinValue} placeholder="min value" />
                                    <input type="text" value={this.state.viewing_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMaxValue} placeholder="max value" />
                                </div> : <input type="text" value={this.state.viewing_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleViewingValue} placeholder="filter..." />}
                            </div>
                        </div>


                    </div>
                ),
            },
            {
                title: 'Offers',width: 130,
                dataIndex: 'offers',
                render: (text, record) => (
                    <>
                        {(record.offers != null) ? record.offers : 0}
                    </>

                ),
                sorter: (a, b) => {

                },
                filterDropdown: (
                    <div style={{
                        padding: '15px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>

                        <div >
                            <label>Offers</label>
                            <select value={this.state.offer_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleOfferValue_id} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>
                                <option value="5">In range</option>

                            </select>
                            <div className='mt-2'>
                                {this.state.inrange_offer ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input type="text" value={this.state.offer_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMinValue} placeholder="min value" />
                                    <input type="text" value={this.state.offer_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMaxValue} placeholder="max value" />
                                </div> : <input type="text" value={this.state.offer_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleOfferValue} placeholder="filter..." />}
                            </div>
                        </div>


                        {/* <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.offer_morthan_value} id="flexRadioDefault1" onChange={this.handleOfferMorethan} />
              <label className="form-check-label" for="flexRadioDefault1">More than</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" checked={this.state.offer_lessthan_value} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleOfferLessthan} />
              <label className="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>
            <div className='form-group mt-2 mb-0'>
              <input type="text" value={this.state.offer_value} className="form-control" onChange={this.handleOfferValue} />
            </div> */}
                    </div>
                ),
            },
            {
                title: 'Created',width: 160,
                dataIndex: 'added_date',
                render: (text, record) => (
                    <Moment fromNow>
                        {(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}
                    </Moment>
                    // <Moment fromNow>
                    // <>
                    // {record.parent_lead_date}
                    //     {validator.isDate(record.parent_lead_date) ?
                    //     <Moment fromNow>
                    //       {record.parent_lead_date}
                    //     </Moment> : ""}
                    // </>
                    //   {record.parent_lead_date}
                    // </Moment>

                ),
                sorter: (a, b) => {
                    if (a && a.added_date && a.added_date.length && b && b.added_date && b.added_date.length) {
                        return a.added_date.length - b.added_date.length;
                    } else if (a && a.added_date && a.added_date.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.added_date && b.added_date.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Date
                        <hr />


                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Less than
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                More than
                            </label>
                        </div>

                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleDateValue} />
                            <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },
           
            {
                title: 'Last match',width: 170,
                dataIndex: 'last_match',
                render: (text, record) => (
                    <>
                        {/* { validator.isDate(record.added_date)?
            <Moment fromNow>
              {record.added_date}
            </Moment>:""} */}

                        {
                            record.last_match == null || record.last_match =='' || record.last_match == "0000-00-00 00:00:00"?
                                ""
                                :
                                <Moment fromNow>
                                    {record.last_match}
                                </Moment>
                        }

                    </>
                ),
                sorter: (a, b) => {
                    if (a && a.last_match && a.last_match.length && b && b.last_match && b.last_match.length) {
                        return a.last_match.length - b.last_match.length;
                    } else if (a && a.last_match && a.last_match.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.last_match && b.last_match.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Last match
                        <hr />

                        <div className='mb-1'>
                            <select value={this.state.last_match_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastMatchOptionValue} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>

                            </select>
                        </div>
                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleLastMatchValue} />
                            <select className='form-select ml-1' onChange={this.handleLastMatchValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },


            {
                title: 'Ref no',width: 150,
                //dataIndex: 'fullname',
                render: (text, record) => (
                    <>
                        {record.refno == " " || record.refno == "" || record.refno == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.original_id, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.original_id, false)}>{record.refno}</Link>}</>
                    // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
                ),
                sorter: (a, b) => a.refno.length - b.refno.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Ref no
                        <hr />
                        <Select
                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"

                            onChange={this.handleRefnoValue_id}
                            options={this.state.name_filter_options}
                        />
                        <div className='mt-2'>
                            <input type="text" className="form-control" value={this.state.refno_value} onChange={this.handleRefnoValue} placeholder="filter..." />
                        </div>




                    </div>
                ),
            },




        ].filter(item => !item.hidden);

        const new_columns = [
            { title:'Pin', className:'namecolwrap', width:15,
            render: (text, record) => {
                return (<>
                    {record.pinned==1 || record.pinned ==2 ?<i class="bi bi-pin-angle-fill mr-1" style={{bottom: 'unset', left: 0}}></i>:null}
                </>)
            }
        },
            { title: 'Name', className:'namecolwrap',
                width: 250,fixed: 'left', style:{zIndex:'9'},
                //dataIndex: 'fullname',
                render: (text, record) => (
                    <>
                        {/* {record.pinned==1 || record.pinned ==2 ?<i class="bi bi-pin-angle-fill mr-1" style={{bottom: 'unset', left: 0}}></i>:null} */}
                        {/* <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}> */}
                        <Link to={`/leads/${record.original_id}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}>
                        <div className='contactinfo'>
                                <div className="d-flex flex-column">
                                <Avatar style={{borderRadius: '0.75rem', borderRadius: '0.75rem', boxShadow: '0px 0px 5px lightgrey'}} size={80} icon={ record.picture== null || record.picture == ""?<i class="bi bi-person-bounding-box"></i>:<img style={{borderRadius: 0}} src={ this.isValidHttpUrl(record.picture)? record.picture:  `data:image/png;base64,${record.picture}` } />}/>
                                    
                                </div>
                                
                                <div className='cgroup'>
                                    <p className='cname mb-0'>{record.fullname == " " || record.fullname == "" || record.fullname == null ?   <SearchOutlined /> : record.fullname}</p>
                                    <p className='ctype mb-0 font-12'>
                                        {record.contact_type==1?"Tenant"
                                        :record.contact_type==2?"Buyer"
                                        :record.contact_type==3?"Landlord"
                                        :record.contact_type==4?"Seller"
                                        :record.contact_type==6?"Agent"
                                        :record.contact_type==7?"Other"
                                        :record.contact_type==8?"Portal"
                                        :record.contact_type==9?"Buyer/Tenant"
                                        :"Unrecognized"}
                                    </p>  
                                    <p className='font-12'>{record.mobile?record.mobile:null}</p>
                                </div>
                            </div>
                        </Link> 
                    </>
                ),
            }, 
            
        { title:'Lead Stage', className:'lead_stage', width:180,
                render: (text, record) => {
                    let agent =  (this.state.agents) ? this.state.agents.find(items => items.value === record.agents) : record.agents;
                    const agentName = (agent && agent.label) ? agent.label : agent
                    return (<>
                        <div className='listinginfowrap listingagentwrap' style={{height: 0}}>
                        <p className='cstatus' style={{minWidth: '85px', width:'fit-content'}}>
                                        {record.agents == 430 && record.lead_stage == 12 ? <span style={{width: '100%'}} className="recruitment pstat status-styling">Pond</span> :<>
                                        {record.lead_stage == 1 ? <span style={{width: '100%'}} className="uncontacted pstat status-styling">New Inquiry</span> : null}
                                        {record.lead_stage == 2 ? <span style={{width: '100%'}} className="working pstat status-styling">Working</span> : null}
                                        {record.lead_stage == 10 ? <span style={{width: '100%'}} className="noanswer pstat status-styling">No Answer</span> : null}
                                        {record.lead_stage == 11 ? <span style={{width: '100%'}} className="expiry pstat status-styling">Expired</span> : null}
                                        {record.lead_stage == 3 ? <span style={{width: '100%'}} className="hot pstat status-styling">Hot</span> : null}
                                        {record.lead_stage == 4 ? <span style={{width: '100%'}} className="followup pstat status-styling">Follow Up</span> : null}
                                        {record.lead_stage == 5 ? <span style={{width: '100%'}} className="dead pstat status-styling">Dead</span> : null}
                                        {record.lead_stage == 6 ? <span style={{width: '100%'}} className="offer pstat status-styling">Offer</span> : null}
                                        {record.lead_stage == 7 ? <span style={{width: '100%'}} className="dealsigned pstat status-styling">Deal signed</span> : null}
                                        {record.lead_stage == 8 ? <span style={{width: '100%'}} className="viewings pstat status-styling">Viewings</span> : null}
                                        {record.lead_stage == 9 ? <span style={{width: '100%'}} className="recruitment pstat status-styling">Recruitment</span> : null}
                                        {record.lead_stage == 13 ? <span style={{width: '100%'}} className="recruitment pstat status-styling">Offer accepted</span> : null}
                                        {record.lead_stage == 14 ? <span style={{width: '100%'}} className="recruitment pstat status-styling">Offer declined</span> : null}
                                        {record.lead_stage == 28 ? <span style={{width: '100%'}} className="recruitment pstat status-styling">Past Client</span> : null}
                                        </>}
                                    </p>
                                    <div className='cgroup'>
                                    {record.wa_confirmed==1?
                                    <p className='vstat whatsapp d-flex flex-row mr-2' style={{width: '85px'}}><i style={{width: '100%'}} class="bi bi-check-all"></i>WhatsApp</p>:null}
                                    {record.verified==1?
                                    <p className='vstat verified d-flex flex-row' style={{width: '85px'}}><i style={{width: '100%'}} class="bi bi-check-all"></i>Verified</p>:null}
                                </div>
                        </div>
                    </>)
                }
            },
            { title:'Agent', className:'agent', width:230,
                render: (text, record) => {
                    let agent =  (this.state.agents) ? this.state.agents.find(items => items.value === record.agents) : record.agents;
                    const agentName = (agent && agent.label) ? agent.label : agent
                    return (<>
                        <div className='listinginfowrap listingagentwrap' style={{height: 0}}>
                            <div className='info-group'>
                                <p className='info-title'>Agent:</p>
                                <div className='agentwrap d-flex flex-row align-items-center'>
                                    <Avatar style={{borderRadius: '10px !important'}} size={40} icon={<img style={{borderRadius: '10px', boxShadow: '1px 1px 10px lightgray',width: "30px",height: "30px", borderRadius: '10px !important'}} src={ record.profile_photo== null || record.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(record.profile_photo)? record.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo } />} />
                                    <p className='infovalue'>
                                        {agentName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>)
                }
            },{ title:'Created Date', className:'created', width:230,
                render: (text, record) => (
                    // table data
                    <>
                        <div className='listinginfowrap' style={{height: 0}}>
                            <div className='info-group'>
                                <p className='info-title'>Created Date:</p>
                                <Moment className='infovalue' fromNow>{(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}</Moment>
                            </div>
                            <div className='info-group'>
                                <p className='info-title'>Last Activity:</p>
                                {record.last_activity && record.last_activity != null?<Moment className='infovalue' fromNow>{(record.last_activity) ? this.getCurrentTime(record.last_activity) : ""}</Moment>:null}
                            </div>
                            {/* <div className='info-group'>
                                <p className='info-title'>Match:</p>
                                <Link target="_blank" to={"all_match_listings/"+record.original_id}>
                                    {record.matched} (<span className={record.matched_new>0?'newmatchcolor infovalue':'infovalue'}>{record.matched_new}</span>)
                                </Link>
                            </div> */}
                            
                        </div>
                    </>
                )
            },
            { title:'Match', className:'match', width:230,
                render: (text, record) => (
                    <>
                        <div className='listinginfowrap' style={{height: 0}}>
                        <div className='info-group'>
                                <p className='info-title'>Last Call:</p>
                                {record.last_call == null || record.last_call =='' || record.last_call == "0000-00-00 00:00:00"?"":<Moment className='infovalue' fromNow>{record.last_call}</Moment>}
                            </div>
                            <div className='info-group' style={{display: 'flex'}}>
                               <div style={{marginRight: 15}}>
                               <p className='info-title'>Viewings:</p>
                                <p className='infovalue'>
                                    {(record.viewings != null) ? record.viewings : 0}
                                </p>
                               </div>

                               <div>
                               <p className='info-title'>Offers:</p>
                                <p className='infovalue'>
                                    {(record.offers != null) ? record.offers : 0}
                                </p>
                               </div>
                            </div>
                        </div>
                    </>
                )
            },
            { title:'Requirements', className:'', width:230,
            render: (text, record) => {
                let com="", subcom="", prop=""
                if (record.community != null && record.community != "" && record.community != undefined){ 
                    let community =  (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                    (community && community.label) ? com = community.label : com = community}
                if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined){
                    let subcommunity =  (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                    (subcommunity && subcommunity.label) ? subcom = subcommunity.label : subcom = subcommunity;}
                if (record.property != null && record.property != "" && record.property != undefined){
                    let property =  (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                    (property && property.label) ? prop=property.label : prop=property;}
                const price = record.lead_price
                let ptype=""
                if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined){
                    let prop_type =  (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                    (prop_type && prop_type.label) ? ptype=prop_type.label : ptype=prop_type;}
                return (<>
                    <div className='listinginfowrap' style={{height: 0}}>
                    <div className='info-group'>
                        <p className='info-title'>Requirements:</p>
                        {/* <p className='infovalue' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '195px' }}> */}
                        <p className='infovalue' >
                        {/* {prop ? prop + ", " : ""}{subcom ? subcom + ", " : ""}{com ? com : ""} */}
                        {subcom ? subcom + ", " : ""}{com ? com : ""}
                        </p>
                        </div>
                        </div>
                </>)
            }
        },
        { title:'Requirements', className:'', width:230,
            render: (text, record) => {
                let com="", subcom="", prop=""
                if (record.community != null && record.community != "" && record.community != undefined){ 
                    let community =  (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                    (community && community.label) ? com = community.label : com = community}
                if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined){
                    let subcommunity =  (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                    (subcommunity && subcommunity.label) ? subcom = subcommunity.label : subcom = subcommunity;}
                if (record.property != null && record.property != "" && record.property != undefined){
                    let property =  (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                    (property && property.label) ? prop=property.label : prop=property;}
                const price = record.lead_price
                let ptype=""
                if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined){
                    let prop_type =  (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                    (prop_type && prop_type.label) ? ptype=prop_type.label : ptype=prop_type;}
                return (<>
                    <div className='' style={{height: 0}}>
        
                        <div className='d-flex flex-row w-100' style={{gap:'3rem'}}>
                            <div className='info-group' style={{width:'50%'}}>
                                <p className='info-title'>Price:</p>
                                <p className='infovalue'>
                                    {!isNaN(parseInt(price)) ?
                                        price > 0 && price !=null ?price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                        :"-"
                                    :"-"}
                                </p>
                            </div>
                            <div className='info-group'>
                                <p className='info-title'>Type:</p>
                                <p className='infovalue'>
                                    { ptype?ptype:"-"}
                                </p>
                            </div>
                            </div>
                            <div>
                            <div className='' style={{height: 0}}>
                            <div className='info-group'>
                                <p className='info-title'>Bed:</p>
                                <p className='infovalue'>{record.bedroom}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </>)
            }
        },
        {
            title: 'Ref no',width: 130,
            render: (text, record) => (
                <>
                <div className='' style={{height: 0}}>
                            <div className='info-group'>
                                <p className='info-title'>Refno:</p>
                                <p className='infovalue'>{record.refno == " " || record.refno == "" || record.refno == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.original_id, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.original_id, false)}>{record.refno}</Link>}</p>
                            </div>
                        </div>
                   
                    </>
                // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
            ),
            sorter: (a, b) => a.refno.length - b.refno.length,

            filterDropdown: (
                <div style={{
                    padding: '8px',
                    borderRadius: '6px',
                    background: '#fff',
                    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                }}>
                    Ref no
                    <hr />
                    <Select
                        style={{
                            width: '100%',
                        }}

                        placeholder="Please select"

                        onChange={this.handleRefnoValue_id}
                        options={this.state.name_filter_options}
                    />
                    <div className='mt-2'>
                        <input type="text" className="form-control" value={this.state.refno_value} onChange={this.handleRefnoValue} placeholder="filter..." />
                    </div>




                </div>
            ),
        },
        {
            title: 'Move in date',width: 130,
            dataIndex: 'move_in_date',
            render: (text, record) => (

                <>
                <div className='' style={{height: 0}}>
                            <div className='info-group'>
                                <p className='info-title'>Move in Date:</p>
                                <p className='infovalue'>
                                    {
                                    record.move_in_date == null || record.move_in_date =='' || record.move_in_date == "0000-00-00 00:00:00"?
                                    ""
                                    :
                                    <Moment format="YYYY/MM/DD">{record.move_in_date}</Moment>
                                    }
                                    </p>
                            </div>
                        </div>


                </>

            ),
            sorter: (a, b) => {
                if (a && a.move_in_date && a.move_in_date.length && b && b.move_in_date && b.move_in_date.length) {
                    return a.move_in_date.length - b.move_in_date.length;
                } else if (a && a.move_in_date && a.move_in_date.length) {
                    // That means be has null rechargeType, so a will come first.
                    return -1;
                } else if (b && b.move_in_date && b.move_in_date.length) {
                    // That means a has null rechargeType so b will come first.
                    return 1;
                }

                // Both rechargeType has null value so there will be no order change.
                return 0;
            },
            filterDropdown: (
                <div style={{
                    padding: '8px',
                    borderRadius: '6px',
                    background: '#fff',
                    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                }}>
                    Move in date
                    <hr />
                    <div className='mb-1'>
                        <select value={this.state.move_in_date_option_value} className="form-select" aria-label="Default select example" name='move_in_date' onChange={this.handleMoveInDateOptionValue} >
                            <option value="1">Equals</option>
                            <option value="2">Not equal</option>
                            <option value="3">Greater than</option>
                            <option value="4">Less than</option>
                            <option value="5">In Next</option>
                            <option value="6">In Previous</option>
                            <option value="7">Date from - to</option>
                            <option value="8">Is empty</option>

                        </select>
                    </div>

                    {this.state.move_in_date_is_empty?null:<>
                        {this.state.inrange_move_in_date? <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />:<div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input value={this.state.move_in_date_value} type="text" className="form-control" onChange={this.handleMoveInDateValue} />
                            <select className='form-select ml-1' onChange={this.handleMoveInDateValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>}
                    </>}

                </div>
            ),
        },
        {
            title: 'Last call',width: 130,
            dataIndex: 'last_call',
            render: (text, record) => (
                <>
                           <div className='' style={{height: 0}}>
                             <div className='info-group'>
                                 <p className='info-title'>Last Call:</p>
                                 <p className='infovalue'>
                                 {
                        record.last_call == null || record.last_call =='' || record.last_call == "0000-00-00 00:00:00"?
                            ""
                            :
                            <Moment fromNow>
                                {record.last_call}
                            </Moment>
                    }
                                    </p>
                            </div>
                        </div>

                </>


            ),
            sorter: (a, b) => {
                if (a && a.last_call && a.last_call.length && b && b.last_call && b.last_call.length) {
                    return a.last_call.length - b.last_call.length;
                } else if (a && a.last_call && a.last_call.length) {
                    // That means be has null rechargeType, so a will come first.
                    return -1;
                } else if (b && b.last_call && b.last_call.length) {
                    // That means a has null rechargeType so b will come first.
                    return 1;
                }

                // Both rechargeType has null value so there will be no order change.
                return 0;
            },
            filterDropdown: (
                <div style={{
                    padding: '8px',
                    borderRadius: '6px',
                    background: '#fff',
                    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                }}>
                    Last call
                    <hr />
                    <div className='mb-1'>
                        <select value={this.state.last_call_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastCallOptionValue} >
                            <option value="1">Equals</option>
                            <option value="2">Not equal</option>
                            <option value="3">Less than</option>
                            <option value="4">Greater than</option>

                        </select>
                    </div>

                    <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <input type="text" className="form-control" onChange={this.handleLastCallValue} />
                        <select className='form-select ml-1' onChange={this.handleLastCallValue_id}>
                            <option value="1">days</option>
                            <option value="2">weeks</option>
                            <option value="3">months</option>
                            <option value="4">years</option>
                        </select>
                    </div>


                </div>
            ),
        },

        {
            title: 'FF / UF',width: 130,
            // dataIndex: 'furnished',
            render: (text, record) => (
                <>
                 <div className='' style={{height: 0}}>
                              <div className='info-group'>
                                  <p className='info-title'>Last Call:</p>
                                  <p className='infovalue'>
                                  {record.furnished == 1 ? "Furnished" : "Unfurnished"}
                                    </p>
                            </div>
                        </div>
                </>


            ),

            sorter: (a, b) => {
                if (a && a.furnished && a.furnished.length && b && b.furnished && b.furnished.length) {
                    return a.furnished.length - b.furnished.length;
                } else if (a && a.furnished && a.furnished.length) {
                    return -1;
                } else if (b && b.furnished && b.furnished.length) {
                    return 1;
                }
                return 0;
            },
            filterDropdown: (
                <div style={{
                    padding: '8px',
                    borderRadius: '6px',
                    background: '#fff',
                    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                }}>
                    Furnished / Unfurnished
                    <hr />
                    <Select
                        mode="multiple"
                        style={{
                            width: '100%',
                        }}
                        value={this.state.furnished_types_value}
                        placeholder="Please select"
                        onChange={this.handleFurnishedTypes}
                        options={this.state.furnished_types}
                    />


                </div>
            ),

        },
            
        ];




        const handleTableChange = (pagination, filters) => {

          let id = this.props.match.params.id;


            const data = {
              id:id,
                community_filter: this.state.community_filter,
                sub_community_filter: this.state.sub_community_filter,
                property_filter: this.state.property_filter,
                name_value_id: this.state.name_value_id,
                name_value: this.state.name_value,
                phone_value_id: this.state.phone_value_id,
                phone_value: this.state.phone_value,
                refno_value: this.state.refno_value,
                refno_value_id: this.state.refno_value_id,
                bed_rooms_value_id: this.state.bed_rooms_value_id,
                bed_rooms_value: this.state.bed_rooms_value,
                agent_value_id: this.state.agent_value_id,
                agent_value: this.state.agent_value,
                stage_value: this.state.stage_value,
                contact_types_value: this.state.contact_types_value,
                furnished_types_value: this.state.furnished_types_value,
                price_first_value_id: this.state.price_first_value_id,
                price_first_value: this.state.price_first_value,
                price_min: this.state.price_min,
                price_max: this.state.price_max,
                date_morethan_option: this.state.date_morethan_option,
                date_lessthan_option: this.state.date_lessthan_option,
                move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                date_value: this.state.date_value,
                date_value_id: this.state.date_value_id,
                property_type_filter: this.state.property_type_filter,

                viewing_value:this.state.viewing_value,
                viewing_morethan_value:this.state.viewing_morethan_value,
                viewing_lessthan_value:this.state.viewing_lessthan_value,
                viewing_option_value:this.state.viewing_option_value,
                inrange_viewing:this.state.inrange_viewing,
                viewing_min_value:this.state.viewing_min_value,
                viewing_max_value:this.state.viewing_max_value,


                offer_value:this.state.offer_value,
                offer_morthan_value:this.state.offer_morthan_value,
                offer_lessthan_value:this.state.offer_lessthan_value,
                offer_option_value:this.state.offer_option_value,
                inrange_offer:this.state.inrange_offer,
                offer_min_value:this.state.offer_min_value,
                offer_max_value:this.state.offer_max_value,


                last_call_morethan_option: this.state.last_call_morethan_option,
                last_call_lessthan_option: this.state.last_call_lessthan_option,
                last_call_value: this.state.last_call_value,
                last_call_value_id: this.state.last_call_value_id,
                last_call_option_value:this.state.last_call_option_value,


                last_match_morethan_option: this.state.last_match_morethan_option,
                last_match_lessthan_option: this.state.last_match_lessthan_option,
                last_match_value: this.state.last_match_value,
                last_match_value_id: this.state.last_match_value_id,
                last_match_option_value:this.state.last_match_option_value,

                move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                move_in_date_value: this.state.move_in_date_value,
                move_in_date_value_id: this.state.move_in_date_value_id,
                move_in_date_option_value:this.state.move_in_date_option_value,
                move_in_date_date_range:this.state.move_in_date_date_range

            }




            this.fetchData(pagination.current, pagination.pageSize, data);

        };
        const handleComparableTableChange = (pagination, filters) => {

          let id = this.props.match.params.id;


            const data = {
              id:id,
                community_filter: this.state.community_filter,
                sub_community_filter: this.state.sub_community_filter,
                property_filter: this.state.property_filter,
                name_value_id: this.state.name_value_id,
                name_value: this.state.name_value,
                phone_value_id: this.state.phone_value_id,
                phone_value: this.state.phone_value,
                refno_value: this.state.refno_value,
                refno_value_id: this.state.refno_value_id,
                bed_rooms_value_id: this.state.bed_rooms_value_id,
                bed_rooms_value: this.state.bed_rooms_value,
                agent_value_id: this.state.agent_value_id,
                agent_value: this.state.agent_value,
                stage_value: this.state.stage_value,
                contact_types_value: this.state.contact_types_value,
                furnished_types_value: this.state.furnished_types_value,
                price_first_value_id: this.state.price_first_value_id,
                price_first_value: this.state.price_first_value,
                price_min: this.state.price_min,
                price_max: this.state.price_max,
                date_morethan_option: this.state.date_morethan_option,
                date_lessthan_option: this.state.date_lessthan_option,
                move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                date_value: this.state.date_value,
                date_value_id: this.state.date_value_id,
                property_type_filter: this.state.property_type_filter,

                viewing_value:this.state.viewing_value,
                viewing_morethan_value:this.state.viewing_morethan_value,
                viewing_lessthan_value:this.state.viewing_lessthan_value,
                viewing_option_value:this.state.viewing_option_value,
                inrange_viewing:this.state.inrange_viewing,
                viewing_min_value:this.state.viewing_min_value,
                viewing_max_value:this.state.viewing_max_value,


                offer_value:this.state.offer_value,
                offer_morthan_value:this.state.offer_morthan_value,
                offer_lessthan_value:this.state.offer_lessthan_value,
                offer_option_value:this.state.offer_option_value,
                inrange_offer:this.state.inrange_offer,
                offer_min_value:this.state.offer_min_value,
                offer_max_value:this.state.offer_max_value,


                last_call_morethan_option: this.state.last_call_morethan_option,
                last_call_lessthan_option: this.state.last_call_lessthan_option,
                last_call_value: this.state.last_call_value,
                last_call_value_id: this.state.last_call_value_id,
                last_call_option_value:this.state.last_call_option_value,


                last_match_morethan_option: this.state.last_match_morethan_option,
                last_match_lessthan_option: this.state.last_match_lessthan_option,
                last_match_value: this.state.last_match_value,
                last_match_value_id: this.state.last_match_value_id,
                last_match_option_value:this.state.last_match_option_value,

                move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                move_in_date_value: this.state.move_in_date_value,
                move_in_date_value_id: this.state.move_in_date_value_id,
                move_in_date_option_value:this.state.move_in_date_option_value,
                move_in_date_date_range:this.state.move_in_date_date_range

            }




            this.fetchComparable(pagination.current, pagination.pageSize, data);

        };

        

        const sellers =[
            {title:'Name', dataIndex:'name'},
            {title:'Mobile', dataIndex:'mobile'},
            {title:'Email', dataIndex:'email'},
            {title:'Agent', dataIndex:'agent'},
        ]

        const sellersdata =[
            {name:'Rohit Batra', mobile:'0505588463', email:'', agent:'Claire Towle'},
            {name:'Asha', mobile:'447739408250', email:'', agent:'George Hughes'},
        ]



        return (
            <div className='leadswrap'>
                <ToastContainer />

                {/* Decline Popup */}
                <Modal title="Decline to Share the Property" open={this.state.isdecline} width={600}
                    // onOk={this.handleCallSubmit}
                       onCancel={this.handleisDeclineClose}
                       footer={<>
                           <Button type="primary" className="d-flex flex-row" onClick={this.onClickDecline}>Submit</Button>
                           <Button type="text" className="d-flex flex-row" style={{height:'36px'}} onClick={this.handleisDeclineClose}>Cancel</Button>
                       </>}
                >
                    <Row className='w-100'>
                        <Col span={24}>
                            <p>Why do you want to decline this property?</p>
                            <TextArea rows={3} value={this.state.decline_note} onChange={this.changeDeclineNote}/>
                        </Col>
                    </Row>
                </Modal>

                {/*Request Viewing Viewing*/}

                <Modal width={1000} title="Add Viewings" open={this.state.isRequestviewingOpen}
                       onOk={this.handleRequestViewingOk} onCancel={this.handleRequestViewingCancel}
                       confirmLoading={this.state.isViewing}
                >
                    <Divider />

                    <div className='row w-100'>
                        <div className='col-md-12 px-0 pt-1'>
                            <div className="form-group">
                                <label>Ref No</label>

                                <Select
                                    style={{
                                        width: "100%",
                                    }}
                                    mode={"multiple"}
                                    value={this.state.ref_def_nums}
                                    showSearch
                                    allowClear
                                    disabled
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleRefsno}
                                    onSearch={this.onSearchRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.ref_nums}
                                />
                                {
                                    this.state.ref_nums == '' || this.state.ref_def_nums.length == 0 && this.state.checkErr &&
                                    <label style={{ color: 'red' }} >empty</label>
                                }
                            </div>
                            <div className="row orgroup">
                                <div className="col-12 d-flex flex-row">
                                    <hr />
                                    <p className="mb-0">Or</p>
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Unit no:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            value={this.state.viewing_unit_no}
                                            showSearch
                                            allowClear
                                            disabled
                                            placeholder="Select Unit no"
                                            optionFilterProp="children"
                                            onChange={this.handleUnitno}
                                            onSearch={this.handleSearchUintNo}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.unit_nums}
                                        />

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Agent:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            value={this.state.viewing_agent}
                                            showSearch
                                            disabled
                                            placeholder="Select Agent"
                                            optionFilterProp="children"
                                            onChange={this.handleViewingAgent}

                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.agents}
                                        />
                                        {
                                            this.state.viewing_agent == '' && this.state.checkErr &&
                                            <label style={{ color: 'red' }} >empty</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Community:</label>
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            showSearch
                                            disabled
                                            placeholder="Search"
                                            value={this.state.currentRequest && this.state.currentRequest.community}
                                            optionFilterProp="children"
                                            // onChange={this.handleCommunityMatch}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.communities}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Sub Community:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            // mode={"multiple"}

                                            showSearch
                                            maxTagCount={"responsive"}
                                            disabled
                                            placeholder="Search"
                                            value={this.state.currentRequest && this.state.currentRequest.subcommunity}
                                            optionFilterProp="children"
                                            //onChange={this.handleSubCommunityMatch}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.sub_communities}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Property:</label>
                                        <select name="property" id="property" className="form-control" disabled>
                                            <option value=""></option>
                                            <option selected="" value="386">Burj Views A</option>
                                            <option value="237">Burj Views B</option>
                                            <option value="283">Burj Views C</option>
                                            <option value="274">Burj Views Podium</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Date / Time:</label>
                                        <DatePicker
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            showTime
                                            onChange={this.handleViewingDate} />
                                        {
                                            this.state.viewing_date == '' && this.state.checkErr &&
                                            <label style={{ color: 'red' }} >empty</label>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <table className="table">

                                        <thead>
                                        <tr>
                                            <th scope="col">Ref no</th>
                                            <th scope="col">Unit no</th>
                                            <th scope="col">Community</th>
                                            <th scope="col">Subcommunity</th>
                                            <th scope="col">Property</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.viewings_listings.map((viewing_listing) => (
                                            <tr>
                                                <th >{viewing_listing.refno}</th>
                                                <th >{viewing_listing.unitno}</th>
                                                <td>{viewing_listing.community}</td>
                                                <td>{viewing_listing.subcommunity}</td>
                                                <td>{viewing_listing.property}</td>
                                                <td>
                                                    <CloseOutlined className='text-danger' onClick={this.handleRemoveList.bind(this, viewing_listing.refno)} />
                                                </td>
                                            </tr>
                                        ))}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                {/* Call Log Pop up */}
                <Modal title="Add Call" open={this.state.isCallLogOpen} width={800}
                    // onOk={this.handleCallSubmit}
                       onCancel={this.handleCallLogModalCancel}
                       footer={<>{
                           this.state.call_loader ?
                               <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                   <Spin style={{ alignSelf: 'center' }} />
                               </div>
                               :
                               <Button id="calllogbtn" onClick={this.handleCallSubmit} type="primary" className="d-flex flex-row"><i className="bi bi-plus me-1 fs-5" /> Add call log</Button>
                       }
                       </>}
                >
                    <Row span={24} className='w-100 addcallpopup-wrap' gutter={8}>
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Name:</label>
                                <EdiText value={this.state.lead_details.owner} type="text" onSave={this.handleSaveOwnerName} className='leadetailsowner' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Contact Type<em>*</em>:</label>
                                <div className='form-text-wrapper'><EasyEdit type={Types.SELECT} value={this.state.contact_type_requirement == undefined || this.state.contact_type_requirement == null  || this.state.contact_type_requirement ==""? 'selected':
                                    this.state.contact_type_requirement.toString()
                                }
                                    // options={this.state.agents}
                                                                             options={this.state.contact_types.map((e)=> {
                                                                                 return {
                                                                                     label:e.label, value: e.value.toString()
                                                                                 }
                                                                             })}
                                                                             onSave={this.saveContactType}
                                                                             onChange={this.onChangeContactType}
                                                                             saveButtonLabel={<CheckOutlined />}
                                                                             cancelButtonLabel={<CloseOutlined />}
                                                                             placeholder="Please select"
                                                                             onValidate={value => {return value != null}}
                                                                             validationMessage="Add Contact Type"
                                /></div>
                            </div>
                        </Col>
                        {this.state.contact_type_requirement.toString()=="1"?
                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Move In Date: <em>*</em></label>
                                    <div className='form-text-wrapper'><EasyEdit type={Types.DATE}
                                                                                 value={
                                                                                     this.state.requirement_move_in_date == "0000-00-00 00:00:00"?
                                                                                         "Select date"
                                                                                         :
                                                                                         <Moment format='DD MMM YYYY'>{this.state.requirement_move_in_date}</Moment>
                                                                                 }
                                                                                 onSave={this.saveMoveInDate}
                                                                                 saveButtonLabel={<CheckOutlined />}
                                                                                 cancelButtonLabel={<CloseOutlined />}
                                                                                 onValidate={value => {return value != null}}
                                                                                 validationMessage="Move In Date is required"
                                    /></div>
                                </div>
                            </Col>
                            :null}
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Budget<em>*</em>:</label>
                                <div className='form-text-wrapper'><EasyEdit type={Types.TEXT} value={this.state.price_requirement == null || this.state.price_requirement == '' ? 'Price' : this.fAmountSaperator(this.state.price_requirement) } onSave={this.savePrice} saveButtonLabel={<CheckOutlined />} cancelButtonLabel={<CloseOutlined />}  attributes={{ name: "awesome-input", id: 1 }}
                                                                             onValidate={value => {return value != null}}
                                                                             validationMessage="Budget is required" /></div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Community<em>*</em>:</label>
                                <div className='form-text-wrapper'><EasyEdit type={Types.SELECT}
                                                                             value={
                                                                                 this.state.requirement_community == undefined  || this.state.requirement_community == null?
                                                                                     "Select"
                                                                                     :
                                                                                     this.state.requirement_community
                                                                             }
                                                                             options={this.state.communities.map((e)=> {
                                                                                 return {
                                                                                     label: e.label,
                                                                                     value: e.value.toString()
                                                                                 }
                                                                             })}
                                    // onSave={this.saveAgent}
                                                                             onSave={this.saveCommunity}
                                                                             saveButtonLabel={<CheckOutlined />}
                                                                             cancelButtonLabel={<CloseOutlined />}
                                                                             onValidate={value => {return value != null}}
                                                                             validationMessage="Community field is required"
                                /></div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Sub Community:</label>
                                <div className='form-text-wrapper'><EasyEdit
                                    type={Types.SELECT}
                                    value={
                                        this.state.requirement_sub_community == undefined  || this.state.requirement_sub_community == null?
                                            "Select"
                                            :
                                            this.state.requirement_sub_community.toString()
                                    }
                                    options={this.state.requirement_sub_communities.map((e) => {
                                        return {
                                            label: e.label,
                                            value: e.value.toString()
                                        }
                                    })}
                                    onSave={this.saveSubCommunity}
                                    saveButtonLabel={<CheckOutlined />}
                                    cancelButtonLabel={<CloseOutlined />}


                                /></div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Bedrooms:</label>
                                <div className='form-text-wrapper'><EasyEdit type={Types.SELECT} value={this.state.requirement_bedroom}
                                                                             options={[
                                                                                 { label: 'Studio', value: 'Studio' },
                                                                                 { label: '1', value: '1' },
                                                                                 { label: '2', value: '2' },
                                                                                 { label: '3', value: '3' },
                                                                                 { label: '4', value: '4' },
                                                                                 { label: '5', value: '5' },
                                                                                 { label: '6', value: '6' },
                                                                                 { label: '7', value: '7' },
                                                                                 { label: '8', value: '8' },
                                                                                 { label: '9', value: '9' },
                                                                                 { label: '10', value: '10' },
                                                                                 { label: '11', value: '11' },
                                                                                 { label: '12', value: '12' },
                                                                                 { label: '13', value: '13' },
                                                                                 { label: '14', value: '14' },
                                                                                 { label: '15', value: '15' }
                                                                             ]}
                                                                             onSave={this.saveBedroom}
                                                                             saveButtonLabel={<CheckOutlined />}
                                                                             cancelButtonLabel={<CloseOutlined />}
                                /></div>
                            </div>
                        </Col>
                        {/*<Col span={12}>*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label for="matchingPfor" className=''>Property For:</label>*/}
                        {/*        <select onChange={this.handlePropertyFor} id="matchingPfor" className="form-control" title tabIndex={-1} value={this.state.property_type_from_requirement}>*/}
                        {/*            <option value>Select</option>*/}
                        {/*            <option value="rent">Rent</option>*/}
                        {/*            <option value="sale">Sale</option>*/}
                        {/*            <option value="short-term">Short Term</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row span={24}  className='w-100'  gutter={8}>
                        <Col span={24} className='w-100 form-group'>
                            <label className="font-10">Call Note:</label>
                            <Mentions style={{fontSize:10, width: '100%',fontFamily:"Poppins-Regular"}}
                                      value={this.state.call_log}
                                      onChange={this.onChangeCallNote}
                                      onSelect={this.onSelectCallMentions}
                                      rows={3}
                                      options={this.state.userClients}
                            />
                        </Col>
                    </Row>
                </Modal>

                <Modal width={600} title="Offers" open={this.state.isOfferOpen}
                       onOk={this.handleOkOffer} onCancel={this.handleCancelOffer}
                >
                    <div className="container">
                        <div className='row w-100 d-flex flex-row'>
                            <div className='px-0 pt-1 form-group'>
                                <label>Date</label>
                                <p className="form-control-disabled">{this.state.viewings.view_date}</p>
                            </div>


                            <div className='px-0 pt-1 form-group'>
                                <label>Note</label>
                                <p className="form-control-disabled">{this.state.viewings.note}</p>
                            </div>
                            <div className='px-0 pt-1 form-group'>
                                <label>Price</label>
                                <p className="form-control-disabled">{this.state.viewings.amount}</p>
                            </div>
                        </div>
                    </div>
                </Modal>



                <Modal open={this.state.isfollowupOpen} title="Add Follow Up" onOk={this.handleFollowupOk} onCancel={this.handleFollowupCancel}>
                    <div className="d-flex flex-column w-100 mt-4">
                        <div className="form-group">
                            <Radio.Group className='d-flex flex-row' onChange={this.onTypeChange} value={this.state.type} disabled>
                                <Radio value={1}>Vendor</Radio>
                                <Radio value={2}>Client</Radio>
                            </Radio.Group>
                        </div>
                        {this.state.type == 1 && <div className="form-group">
                            <label className="form-label">Search vendor</label> {/* if client switch to Search Vendor*/}
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                value={this.state.vendor}
                                showSearch
                                allowClear
                                placeholder="Select Vendor"
                                maxTagCount= 'responsive'
                                optionFilterProp="children"
                                onChange={this.onChangeVendor}
                                onSearch={this.onSearchClient}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.clientList}
                            />
                        </div>}
                        {this.state.type == 2 && <div className="form-group">
                            <label className="form-label">Client:</label>
                            <span className='form-control font-14 disabled'>{this.state.owner_name}</span>
                        </div>}
                        <div className="d-flex flex-row">
                            <div class="form-group col-6 pl-0 mb-1">
                                <label class="form-label">Follow up for</label>
                                <select onChange={this.onChangeFollowupFor} value={this.state.followup_for} name="followup_for" id="followup_for" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                    <option value="">Select Follow up For</option>
                                    <option value={1}>Follow Up</option>
                                    <option value={2}>Call</option>
                                    <option value={3}>Email</option>
                                    <option value={4}>Viewing</option>
                                    <option value={5}>Offer</option>
                                    <option value={6}>Whatsapp</option>
                                </select>
                                <div
                                    className="text-danger">{this.state.validationerrors.followup_for}</div>
                            </div>
                            {(role_name == "Admin" || role_name == "Super Admin") && <div className="form-group col-6 pr-0 mb-1">
                                <label className="form-label">Agent</label>
                                <select onChange={this.handleAgentDetails}
                                        value={this.state.selected_agent}
                                        name="selected_agent" id="selected_agent"
                                        className="form-control select2 required selectsearch select2-offscreen"
                                        title
                                        tabIndex={-1}>
                                    <option value="">Select Agent</option>
                                    {this.state.agents.map((agent) => {
                                        return (
                                            <option value={agent.value}>{agent.label}</option>
                                        )
                                    })}
                                </select>
                                <div
                                    className="text-danger">{this.state.validationerrors.selected_agent}</div>
                            </div>}
                            {(role_name != "Admin" && role_name != "Super Admin") && <div className="form-group col-6 pr-0 mb-1">
                                <label className="form-label">Agent</label>
                                <select onChange={this.handleAgentDetails}
                                        value={this.state.selected_agent}
                                        name="selected_agent" id="selected_agent"
                                        className="form-control select2 required selectsearch select2-offscreen"
                                        title
                                        tabIndex={-1}>
                                    <option value="">Select Agent</option>
                                    {this.state.agents.map((agent) => {
                                        return (
                                            <option value={agent.value}>{agent.label}</option>
                                        )
                                    })}
                                </select>
                                <div
                                    className="text-danger">{this.state.validationerrors.selected_agent}</div>
                            </div>}
                        </div>

                        <div className="form-group mb-0">
                            <div className='d-flex flex-row'>
                                <div className='form-group col-6 pl-0 mb-0'>
                                    <label className="form-label">Date and Time</label>
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        style={{ width: '100%', height: '36px', 'overflow': 'hidden' }}
                                        showTime={{ minuteStep:5}}
                                        onChange={this.handleFollowupDate} />
                                    <div
                                        className="text-danger">{this.state.validationerrors.followup_date}</div>
                                </div>
                                <div className='form-group col-6 pl-0 mb-0'>
                                    <label className="form-label">Reminder</label>
                                    <select onChange={this.onChangeReminder} value={this.state.reminder} name="reminder" id="reminder" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                        <option value="">Select Reminder</option>
                                        <option value="600">10 minutes before</option>
                                        <option value="1800">30 minutes before</option>
                                        <option value="3600">1 hour before</option>
                                        <option value="86400">1 day before</option>
                                        <option value="604800">1 week before</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="form-group">
                            <label className="form-label">Note</label>
                            <TextArea rows={4} onChange={this.handleFollowupNote}/>
                            <div
                                className="text-danger">{this.state.validationerrors.followup_note}</div>
                        </div>
                    </div>
                </Modal>

                <Modal width={600} title=" Followup Details" open={this.state.isfollowupViewOpen}
                       onOk={this.handleFollowupViewOk} onCancel={this.handleFollowupViewCancel}
                >
                    <Divider />


                    <div className="d-flex flex-column w-100 ">
                        <div className="form-group">
                            <label>Date & Time</label>
                            <p>{this.state.followup_data.date}</p>
                        </div>
                        <div className="form-group">
                            <label >Followup by</label>
                            <p>{this.state.followup_data.agent_name}</p>
                        </div>
                        <div className="form-group">
                            <label >Status</label>
                            <p>{(this.state.followup_data.status == 0) ? "Inactive" : "Active"}</p>
                        </div>
                        <div className="form-group">
                            <label >Type</label>
                            <p>{(this.state.followup_data.type == 1) ? "Vendor" : "Client"}</p>
                        </div>
                        <div className="form-group">
                            <label >Followup for</label>
                            <p>{this.getFollowUpType(this.state.followup_data.followup_type)}</p>
                        </div>
                        <div className="form-group">
                            <label >Followup note</label>
                            <p>{this.state.followup_data.notes}</p>
                        </div>
                    </div>


                </Modal>
                <Modal width={600} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
                    <div className="container">
                        <div className='row w-100 d-flex flex-row'>
                            <div className='px-0 pt-1 form-group'>
                                <label>Ref No</label>
                                <p className="form-control-disabled">{this.state.viewings.listing_refno}</p>
                            </div>
                            <div className='px-0 pt-1 form-group'>
                                <label>Agent Name</label>
                                <p className="form-control-disabled">{this.state.viewings.fullname}</p>
                            </div>
                        </div>
                        <div className='row w-100 mt-3 d-flex flex-row'>

                            <div className='px-0 pt-1 form-group'>
                                <label>Date</label>
                                {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p className="form-group">{this.state.viewings.view_date}</p>}

                            </div>
                            <div className='px-0 pt-1 mt-3 form-group'>
                                <label>Status</label>
                                <Select
                                    style={{
                                        width: 160,
                                    }}
                                    showSearch
                                    maxTagCount={"responsive"}
                                    placeholder="Select beds"
                                    optionFilterProp="children"
                                    onChange={this.handleViewStatus}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        { value: 'Offer', label: 'Offer' },
                                        { value: "Successful", label: 'Successful' },
                                        { value: "Cancelled", label: 'Cancelled' },
                                        { value: "Unsuccessful", label: 'Unsuccessful' },
                                        { value: "Scheduled", label: 'Scheduled' }
                                    ]}
                                />
                                {/* <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
              <option value="Offer">Offer</option>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select> */}
                            </div>
                            {this.state.showPrice ? <div className='form-group'>
                                <label>Price</label>
                                <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
                            </div> : null}
                        </div>
                        <div className='row w-100 mt-3'>
                            <div className='w-100 px-0 pt-1'>
                                <label>Note</label>
                                <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
                            </div>


                        </div>
                    </div>
                </Modal>

                {/* viewings modal */}
                {/* <Modal width={1000} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
          <Divider />
          <div className='row'>
            <div className='col-md-4'>
              <label>REf NO</label>
              <p>{this.state.viewings.listing_refno}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <label>Agent Name</label>
              <p>{this.state.viewings.fullname}</p>
            </div>
            <div className='col-md-4'>
              <label>Date</label>
              {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p>{this.state.viewings.view_date}</p>}

            </div>
            <div className='col-md-4'>
              <label>Status</label>
              <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-8'>
              <label>Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
            {this.state.showPrice ? <div className='col-md-4'>
              <label>Price</label>
              <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
            </div> : null}

          </div>
        </Modal> */}
                <Modal width={1000} title="Add Viewings" open={this.state.isviewingOpen}
                       onOk={this.handleViewingOk} onCancel={this.handleViewingCancel}
                       confirmLoading={this.state.isViewing}
                >
                    <Divider />

                    <div className='row w-100'>
                        <div className='col-md-12 px-0 pt-1'>
                            <div className="form-group">
                                <label>Ref No</label>

                                <Select
                                    style={{
                                        width: "100%",
                                    }}
                                    mode={"multiple"}
                                    value={this.state.ref_def_nums}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleRefsno}
                                    onSearch={this.onSearchRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.ref_nums}
                                />
                                {
                                    this.state.ref_nums == '' || this.state.ref_def_nums.length == 0 && this.state.checkErr &&
                                    <label style={{ color: 'red' }} >empty</label>
                                }
                            </div>
                            <div className="row orgroup">
                                <div className="col-12 d-flex flex-row">
                                    <hr />
                                    <p className="mb-0">Or</p>
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Unit no:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            value={this.state.viewing_unit_no}
                                            showSearch
                                            allowClear
                                            placeholder="Select Unit no"
                                            optionFilterProp="children"
                                            onChange={this.handleUnitno}
                                            onSearch={this.handleSearchUintNo}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.unit_nums}
                                        />

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Agent:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            value={this.state.viewing_agent}
                                            showSearch
                                            placeholder="Select Agent"
                                            optionFilterProp="children"
                                            onChange={this.handleViewingAgent}

                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.agents}
                                        />
                                        {
                                            this.state.viewing_agent == '' && this.state.checkErr &&
                                            <label style={{ color: 'red' }} >empty</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Community:</label>
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            showSearch
                                            placeholder="Search"
                                            optionFilterProp="children"
                                            // onChange={this.handleCommunityMatch}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.communities}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Sub Community:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            // mode={"multiple"}

                                            showSearch
                                            maxTagCount={"responsive"}
                                            placeholder="Search"
                                            optionFilterProp="children"
                                            //onChange={this.handleSubCommunityMatch}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.sub_communities}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Property:</label>
                                        <select name="property" id="property" className="form-control">
                                            <option value=""></option>
                                            <option selected="" value="386">Burj Views A</option>
                                            <option value="237">Burj Views B</option>
                                            <option value="283">Burj Views C</option>
                                            <option value="274">Burj Views Podium</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Date / Time:</label>
                                        <DatePicker
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            showTime
                                            onChange={this.handleViewingDate} />
                                        {
                                            this.state.viewing_date == '' && this.state.checkErr &&
                                            <label style={{ color: 'red' }} >empty</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
        <div className="col-12">
            <label className="">Notes:</label>
            <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            {
                this.state.viewing_note == '' && this.state.checkErr &&
                <label style={{color:'red'}} >empty</label>
               }
        </div>
    </div> */}


                            <div className="row">
                                <div className="col-12">
                                    <table className="table">

                                        <thead>
                                        <tr>
                                            <th scope="col">Ref no</th>
                                            <th scope="col">Unit no</th>
                                            <th scope="col">Community</th>
                                            <th scope="col">Subcommunity</th>
                                            <th scope="col">Property</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.viewings_listings.map((viewing_listing) => (
                                            <tr>
                                                <th >{viewing_listing.refno}</th>
                                                <th >{viewing_listing.unitno}</th>
                                                <td>{viewing_listing.community}</td>
                                                <td>{viewing_listing.subcommunity}</td>
                                                <td>{viewing_listing.property}</td>
                                                <td>
                                                    <CloseOutlined className='text-danger' onClick={this.handleRemoveList.bind(this, viewing_listing.refno)} />
                                                </td>
                                            </tr>
                                        ))}


                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <div className='row'>
            <div className='col-md-8'>
              <label>Ref No:</label>
              <Select
                style={{
                  width: "100%",
                }}
                mode={"multiple"}
                showSearch
                placeholder="Select Refs no"
                optionFilterProp="children"
                onChange={this.handleRefsno}
                onSearch={this.onSearchRefNo}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.ref_nums}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-4'>
              <label>Agent</label>
              <Select
                style={{
                  width: "100%",
                }}

                showSearch
                placeholder="Select Agent"
                optionFilterProp="children"
                onChange={this.handleViewingAgent}

                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.agents}
              />
            </div>
            <div className='col-md-4'>
              <label>Date / Time</label>
              <DatePicker style={{ width: '100%' }} showTime onChange={this.handleViewingDate} />
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-10'>
              <label>Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
          </div>
 */}

                    {/*<CalenderEvent/>*/}

                </Modal>
                <Drawer
                    title={`Lead details`}
                    placement="left"
                    // size={"large"}
                    width={"98%"}
                    onClose={this.handleCloseDrawer}
                    open={this.state.open}
                    // extra={
                    //   <Space>
                    //     <Button type='primary' danger ghost onClick={this.handleCloseDrawer} icon={<CloseOutlined /> }>Cancel</Button>
                    //     <Button type="primary" onClick={this.handleSaveData}>
                    //       Save changes
                    //     </Button>
                    //   </Space>
                    // }
                >

                    <div className="cont-wrap container">
                        {
                            this.state.loader ?
                                <div style={{ position:'absolute', alignSelf:"center", top:'50%' }} >
                                    <Spin size='large' />
                                </div>
                                :
                                <>
                                    <div className="row">
                                        <div className="col-12 d-flex flex-row toptitle">
                                            <button className="btn btn-primary" disabled={this.state.isPreviousLoading || this.state.disabledPrev ? true : false} onClick={ this.state.loader? () =>  console.log('change') : this.handlePreviousRecord} >{this.state.isPreviousLoading ? <BeatLoader color={"blue"} loading={true} size={30} /> : "Previous"}</button>
                                            <div className="d-flex w-100 ps-3"><h2 className="page-title fs-5 font-bold"><i className="bi bi-pencil-fill me-2" />Leads Details - {this.state.lead_details.refno}</h2></div>
                                            <button className="btn btn-primary" disabled={this.state.isNextLoading || this.state.disabledNext ? true : false} onClick={ this.state.loader? () =>  console.log('change') : this.handleNextRecord}  >{this.state.isNextLoading ? <BeatLoader color={"blue"} loading={true} size={30} /> : "Next"}</button>
                                        </div>
                                    </div>
                                    <div className="row column-wrap mt-3">
                                        <div className="col-12 col-md-3" >


                                            {/** Contact Details */}

                                            <div className="bg-white border-top ownersdetails">
                                                {
                                                    // role == 1 || isUserFound || user_id.toString() == this.state.requirement_agent_id.toString() ?
                                                    role == 1 || isUserFound || this.state.collaborators.some((data)=> data.user_id == user_id) || user_id.toString() == this.state.requirement_agent_id.toString() || (this.state.lead_details.last_call && this.state.lead_details.last_call < full_date)  || (!this.state.lead_details.last_call && this.state.lead_details.parent_lead_date && this.state.lead_details.parent_lead_date < full_two_days) ?
                                                        <>

                                                            <div className="d-flex flex-row justify-content-between"  >
                                                                <h3>Contact Details</h3>
                                                                {/* <button type="button" className="btn btn-primary requestchange">Change Details</button> */}
                                                            </div>
                                                            <div className="d-flex flex-column formfieldset mt-3">
                                                                <div className="d-flex flex-column form-group">
                                                                    <label className="control-labels mr-4">Name</label>
                                                                    <EdiText

                                                                        value={this.state.lead_details.owner}
                                                                        type="text"
                                                                        onSave={this.handleSaveOwnerName}

                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column form-group">
                                                                    <label className="control-labels mr-4">Phone </label>
                                                                    <p className="form-control-disabled">{this.state.owner_mobile}</p>
                                                                </div>
                                                                <div className="d-flex flex-column form-group">
                                                                    <label className="control-labels mr-4"> Email </label>
                                                                    <EdiText

                                                                        value={this.state.lead_details.email}
                                                                        type="text"
                                                                        onSave={this.handleSaveOwnerEmail}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="bg-white  ownersdetails mt-3">
                                                                <div className="d-flex flex-column form-group mb-0">
                                                                    <label className="control-labels mr-4">Other Enquires</label>
                                                                    <div className="col-12 d-flex flex-column border-wrap listtable-wrap">

                                                                        {this.state.owner_other_properties.map((owner_other_property) => {
                                                                            var pStatus = 'Draft'
                                                                            switch (owner_other_property.status) {
                                                                                case 'D':
                                                                                    pStatus = 'Draft'
                                                                                    break;
                                                                                case 'UC':
                                                                                    pStatus = 'Confirmed Owner'
                                                                                    break;
                                                                                case 'N':
                                                                                    pStatus = 'Unpublish'
                                                                                    break;
                                                                                case 'OM':
                                                                                    pStatus = 'Off-Market'
                                                                                    break;
                                                                                case 'CTM':
                                                                                    pStatus = 'Coming to market'
                                                                                    break;
                                                                                case 'Y':
                                                                                    pStatus = 'Published'
                                                                                    break;
                                                                                case 'U':
                                                                                    pStatus = 'Action'
                                                                                    break;

                                                                                case 'SEO':
                                                                                    pStatus = 'SEO'
                                                                                    break;
                                                                                case 'L':
                                                                                    pStatus = 'Unlisted'
                                                                                    break;
                                                                                case 'S':
                                                                                    pStatus = 'Sold'
                                                                                    break;
                                                                                case 'T':
                                                                                    pStatus = 'Let'
                                                                                    break;
                                                                                case 'P':
                                                                                    pStatus = 'Review'
                                                                                    break;
                                                                                case 'F':
                                                                                    pStatus = 'Feeds'
                                                                                    break;
                                                                                default:
                                                                                    pStatus = 'Draft'
                                                                                    break;
                                                                            }
                                                                            return (
                                                                                <a
                                                                                    target="_blank"
                                                                                    // href={"https://www.luxuryproperty.com/property/preview/" + owner_other_property.ref_no+"-"+owner_other_property.listing_id}
                                                                                    href={liveUrl + "/all_listings/" + owner_other_property.listing_id}
                                                                                    className="d-flex flex-row p-2 w-100"
                                                                                    style={{ textDecorationLine:'none' }}
                                                                                >
                                                                                    <div className="col-8 listtable">
                                                                                        <p className="propfor">{owner_other_property.property_for}</p>
                                                                                        <p className="refno">{owner_other_property.refno}</p>
                                                                                        <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                                                                        <p className="community">{owner_other_property.community}</p>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <div className="d-flex flex-row stat publishstat">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span>{pStatus}</span>
                                                                                        </div>
                                                                                        <div className="agentprop">
                                                                                            <p className="name">{owner_other_property.agent}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            )
                                                                        })}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null

                                                }

                                                





                                            </div>
                                            {/*** END */}


                                            {/* FORMS */}
                                            <div className="bg-white border-top offers p-4 mt-3">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4 mb-3">Requirements </h3>
                                                </div>
                                                <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
                                                    <div className="form-group"> {/* MULTISELECT */}
                                                        <label className="control-labels ">Lead Stage</label>
                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            //value={this.state.requirement_lead_stage.toString()}

                                                            value={this.state.requirement_lead_stage == undefined || this.state.requirement_lead_stage == null ||  this.state.requirement_lead_stage == "" ?
                                                                ''
                                                                :
                                                                this.state.requirement_lead_stage.toString()
                                                            }

                                                            options={this.state.lead_stage_filter}
                                                            onSave={this.saveLeadStage}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            placeholder={'please select'}
                                                            instructions="Custom instructions"
                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Verifier</label>
                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            // value={this.state.requirement_verifyedby.toString()}
                                                            value={this.state.requirement_verifyedby == undefined || this.state.requirement_verifyedby == null ||  this.state.requirement_verifyedby == "" ?
                                                                ''
                                                                :
                                                                this.state.requirement_verifyedby.toString()
                                                            }
                                                            options={[
                                                                { label: 'None', value: '0' },
                                                                { label: 'Danielle Robinson', value: '375' },
                                                                { label: 'Christalline Ybanez', value: '427' },
                                                            ]}

                                                            onSave={this.saveVerifier}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Custom instructions"
                                                            placeholder="Please select"
                                                        />

                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-labels ">Contact Type</label>

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={this.state.contact_type_requirement == undefined || this.state.contact_type_requirement == null  || this.state.contact_type_requirement ==""?
                                                                'selected'
                                                                :
                                                                this.state.contact_type_requirement.toString()
                                                            }
                                                            // options={this.state.agents}
                                                            options={this.state.contact_types.map((e)=> {
                                                                return {
                                                                    label:e.label, value: e.value.toString()
                                                                }
                                                            })}
                                                            onSave={this.saveContactType}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Custom instructions"
                                                            placeholder="Please select"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-labels ">Agent Name</label>

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={this.state.requirement_agent_id == undefined || this.state.requirement_agent_id == null  || this.state.requirement_agent_id ==""?
                                                                ''
                                                                :
                                                                this.state.requirement_agent_id.toString()
                                                            }
                                                            // options={this.state.agents}
                                                            options={this.state.agents.map((e)=> {
                                                                return {
                                                                    label:e.label, value: e.value.toString()
                                                                }
                                                            })}
                                                            onSave={this.saveAgent}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Custom instructions"
                                                            placeholder="Please select"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Move in Date</label>
                                                        <EasyEdit
                                                            type={Types.DATETIME_LOCAL}
                                                            value={
                                                                this.state.requirement_move_in_date == "0000-00-00 00:00:00"?
                                                                    "select date"
                                                                    :
                                                                    this.state.requirement_move_in_date
                                                            }
                                                            onSave={this.saveMoveInDate}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Select your date and time"
                                                        />
                                                        {/* <input value={this.state.lead_details.mov_in} onChange={this.handleRequirementMoveInDate} type="datetime-local" id="move_in_date" className="form-control" defaultValue /> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Price</label>
                                                        <div className="d-flex flex-row" style={{ gap: '0.6rem' }}>

                                                            <EasyEdit
                                                                type={Types.TEXT}
                                                                value={this.state.price_requirement == null || this.state.price_requirement == '' ?
                                                                    'Price'
                                                                    :
                                                                    this.fAmountSaperator(this.state.price_requirement)
                                                                }
                                                                onSave={this.savePrice}
                                                                saveButtonLabel={<CheckOutlined />}
                                                                cancelButtonLabel={<CloseOutlined />}
                                                                attributes={{ name: "awesome-input", id: 1 }}
                                                                instructions="Star this repo!"

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Bedrooms</label>
                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={this.state.requirement_bedroom}

                                                            options={[
                                                                { label: 'Studio', value: 'Studio' },
                                                                { label: '1', value: '1' },
                                                                { label: '2', value: '2' },
                                                                { label: '3', value: '3' },
                                                                { label: '4', value: '4' },
                                                                { label: '5', value: '5' },
                                                                { label: '6', value: '6' },
                                                                { label: '7', value: '7' },
                                                                { label: '8', value: '8' },
                                                                { label: '9', value: '9' },
                                                                { label: '10', value: '10' },
                                                                { label: '11', value: '11' },
                                                                { label: '12', value: '12' },
                                                                { label: '13', value: '13' },
                                                                { label: '14', value: '14' },
                                                                { label: '15', value: '15' }
                                                            ]}
                                                            onSave={this.saveBedroom}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}


                                                        />


                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Community</label>
                                                        {/* <Select
                              style={{
                                width: '100%',
                              }}
                              defaultValue={this.state.requirement_community}
                              showSearch
                              placeholder="Search"
                              onChange={this.handleRequirementCommunity}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            /> */}

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={
                                                                this.state.requirement_community == undefined  || this.state.requirement_community == null?
                                                                    "select"
                                                                    :
                                                                    this.state.requirement_community.toString()
                                                            }
                                                            options={this.state.communities.map((e)=> {
                                                                return {
                                                                    label: e.label,
                                                                    value: e.value.toString()
                                                                }
                                                            })}
                                                            // onSave={this.saveAgent}
                                                            onSave={this.saveCommunity}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}


                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Sub Community</label>

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={
                                                                this.state.requirement_sub_community == undefined  || this.state.requirement_sub_community == null?
                                                                    "select"
                                                                    :
                                                                    this.state.requirement_sub_community.toString()
                                                            }
                                                            options={this.state.requirement_sub_communities.map((e) => {
                                                                return {
                                                                    label: e.label,
                                                                    value: e.value.toString()
                                                                }
                                                            })}
                                                            onSave={this.saveSubCommunity}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}


                                                        />
                                                        {/* <Select
                              style={{
                                width: '100%',
                              }}
                              // mode={"multiple"}
                              value={this.state.requirement_sub_community}
                              showSearch
                              maxTagCount={"responsive"}
                              placeholder="Search"
                              optionFilterProp="children"
                              onChange={this.handleRequirementSubCommunity}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.requirement_sub_communities}
                            /> */}

                                                    </div>
                                                </div>
                                                {/**  HIDE SAVE BUTTON FOR NOW  */}
                                                {/* <button onClick={this.handleRequirementSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button> */}
                                            </div>{/* END FORMS*/}
                                        </div>
                                        <div className="col-12 col-md-6 timeline-wrap">

                                            <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                                                <h3>Timeline</h3>

                                                <div className='tabtimeline'>
                                                    <Mentions
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        value={this.state.note_log}
                                                        onChange={this.onChangeNoteNote}
                                                        onSelect={this.onSelectNoteMentions}
                                                        options={this.state.userClients} rows={2}
                                                    />
                                                    {/* <textarea id="logsText" onChange={this.handleNoteLog} rows={4} placeholder="Add note.." className="font-14 mentionnote" defaultValue={""} /> */}
                                                    {
                                                        this.state.note_loader ?
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                <Spin style={{ alignSelf: 'center' }} />
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row pt-2 mb-2 mx-auto justify-content-center'>
                                                                <Button id="logsbtn" onClick={this.handleNoteSubmit} type="primary" className="d-flex flex-row"><i className="bi bi-plus me-1 fs-5" />Add note</Button>
                                                                <Button id="calllogbtn" type="primary" onClick={this.handleCallLogModal} className="d-flex flex-row align-items-center mb-3 ml-2"><i className="bi bi-plus me-1 fs-5" /> <span className='font-12'>Add Call</span></Button>
                                                            </div>
                                                    }

                                                </div>

                                                {/* TIMELINE */}
                                                <div className="timeline border-wrap">
                                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                                        <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">All</button>
                                                        <button className="nav-link" id="nav-notewrap-tab" data-bs-toggle="tab" data-bs-target="#nav-notewrap" type="button" role="tab" aria-controls="nav-notewrap" aria-selected="false">Notes</button>
                                                        <button className="nav-link" id="nav-calllist-tab" data-bs-toggle="tab" data-bs-target="#nav-calllist" type="button" role="tab" aria-controls="nav-calllist" aria-selected="false">Calls</button>
                                                        {/*<button className="nav-link" id="nav-wa-tab" data-bs-toggle="tab" data-bs-target="#nav-wa" type="button" role="tab" aria-controls="nav-wa" aria-selected="false">WhatsApp</button>*/}
                                                        {/*<button className="nav-link" id="nav-mail-tab" data-bs-toggle="tab" data-bs-target="#nav-mail" type="button" role="tab" aria-controls="nav-mail" aria-selected="false">Email</button>*/}
                                                        {/*<button className="nav-link" id="nav-offers-tab" data-bs-toggle="tab" data-bs-target="#nav-offers" type="button" role="tab" aria-controls="nav-offers" aria-selected="false">Offers</button>*/}
                                                        {/*<button className="nav-link" id="nav-view-tab" data-bs-toggle="tab" data-bs-target="#nav-view" type="button" role="tab" aria-controls="nav-view" aria-selected="false">Viewings</button>*/}
                                                        {/*<button className="nav-link" id="nav-followups-tab" data-bs-toggle="tab" data-bs-target="#nav-followups" type="button" role="tab" aria-controls="nav-followups" aria-selected="false">Follow Up</button>*/}
                                                    </div>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        {/* ALL */}
                                                        <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabIndex={0}>
                                                            <div className="timeline-list">

                                                                {/* Sticky Agent Shared Listing */}
                                                                {this.state.requested_viewings && this.state.requested_viewings.map((requested_viewing) => (
                                                                    <div className='showproperty timegroup'>
                                                                        <div className='col-1'>
                                                                            <div className='iconwrap'>
                                                                                <i className="bi bi-house-up"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-11 px-0">
                                                                            <div className="col-12 d-flex flex-column">
                                                                                <div className="agentdetails">
                                                                                    <div
                                                                                        className="d-flex flex-row justify-content-between">
                                                                                        <p className="awriter">
                                                                                            <span
                                                                                                className="font-bold mr-1">{requested_viewing.by_user_name}</span>
                                                                                            <span>wants you to share property to your client.</span>
                                                                                        </p>
                                                                                        <p className="atime justify-content-end">
                                                                                            <Moment fromNow>
                                                                                                {(requested_viewing.added_date) ? this.getCurrentTime(requested_viewing.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="adesc p-0">
                                                                                        <div
                                                                                            className='showpropertycontent'>
                                                                                            <a href=""
                                                                                               className='font-bold'>{requested_viewing.note}</a>
                                                                                            <p className="font-semibold">{requested_viewing.unitno}, {requested_viewing.community}, {requested_viewing.subcommunity} <i
                                                                                                className="bi bi-dot"></i><span>{(requested_viewing.price) ? this.getPrice(requested_viewing.price) + " AED" : "-"}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <Row className='showpropbtm'>
                                                                                            <Col span={6}
                                                                                                 className='showprop-timer'>
                                                                                                <i className="bi bi-hourglass-split fs-6"></i>
                                                                                                {this.state.remainingTime}
                                                                                            </Col>
                                                                                            <Col span={17}
                                                                                                 className='showprop-actions'>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        onClick={this.handleRequestNewViewing.bind(this, requested_viewing)}>Book
                                                                                                    a viewing</Button>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        disabled={this.state.isWhatsappLoading}
                                                                                                        onClick={this.handleisShareWhatsApp.bind(this, requested_viewing)}>Share
                                                                                                    via
                                                                                                    WhatsApp  {this.state.isWhatsappLoading && <Spin style={{ alignSelf: 'center' }} />}</Button>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        onClick={this.handleisDecline.bind(this, requested_viewing)}>Decline</Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>

                                                                                </div>
                                                                                {/*<div className='childrenwrap mt-2'>*/}
                                                                                {/*    /!* Book a viewing *!/*/}
                                                                                {/*    <div className='childcontent'>*/}
                                                                                {/*        <div*/}
                                                                                {/*            className="d-flex flex-row justify-content-between">*/}
                                                                                {/*            <p className="awriter">*/}
                                                                                {/*                <span*/}
                                                                                {/*                    className="font-bold mr-1">Mark Castley</span>*/}
                                                                                {/*                <span>scheduled a viewing with his client.</span>*/}
                                                                                {/*                <span*/}
                                                                                {/*                    className='stat scheduled'>Scheduled</span>*/}
                                                                                {/*            </p>*/}
                                                                                {/*            <p className="atime justify-content-end">*/}
                                                                                {/*                <Moment*/}
                                                                                {/*                    fromNow></Moment>*/}
                                                                                {/*            </p>*/}
                                                                                {/*        </div>*/}
                                                                                {/*        <div className="adesc">*/}
                                                                                {/*            <Row>*/}
                                                                                {/*                <Col span={24}><span*/}
                                                                                {/*                    className='font-10'>Date / Time:</span></Col>*/}
                                                                                {/*                <Col span={8}>*/}
                                                                                {/*                    <p className='font-bold fs-5 mb-0'>6:45*/}
                                                                                {/*                        PM</p>*/}
                                                                                {/*                    <p className="font-12">22*/}
                                                                                {/*                        June 2023</p>*/}
                                                                                {/*                </Col>*/}
                                                                                {/*                <Col span={16}>*/}
                                                                                {/*                    <p>Viewing booked*/}
                                                                                {/*                        for <span*/}
                                                                                {/*                            className='font-bold'>LP32747</span>*/}
                                                                                {/*                    </p>*/}
                                                                                {/*                    <p className="font-12 mt-2">319,*/}
                                                                                {/*                        Sun, Arabian*/}
                                                                                {/*                        Ranches III <i*/}
                                                                                {/*                            className="bi bi-dot"></i><span>185,000 AED</span>*/}
                                                                                {/*                    </p>*/}
                                                                                {/*                </Col>*/}
                                                                                {/*            </Row>*/}
                                                                                {/*        </div>*/}
                                                                                {/*    </div>*/}
                                                                                {/*    /!* Share via WhatsApp - Replied *!/*/}
                                                                                {/*    <div className='childcontent'>*/}
                                                                                {/*        <div*/}
                                                                                {/*            className="d-flex flex-row justify-content-between">*/}
                                                                                {/*            <p className="awriter">*/}
                                                                                {/*                <span*/}
                                                                                {/*                    className="font-bold mr-1">Owner's name</span>*/}
                                                                                {/*                <span>replied</span>*/}
                                                                                {/*            </p>*/}
                                                                                {/*            <p className="atime justify-content-end">*/}
                                                                                {/*                <Moment*/}
                                                                                {/*                    fromNow></Moment>*/}
                                                                                {/*            </p>*/}
                                                                                {/*        </div>*/}
                                                                                {/*        <div className="adesc">*/}
                                                                                {/*            <Row*/}
                                                                                {/*                className='align-content-center'>*/}
                                                                                {/*                <Col span={16}*/}
                                                                                {/*                     className='d-flex flex-column'>*/}
                                                                                {/*                    <span*/}
                                                                                {/*                        className='font-10'>Best time to view:</span>*/}
                                                                                {/*                    <div*/}
                                                                                {/*                        className='d-flex flex-row'>*/}
                                                                                {/*                        <p className='font-bold mb-0'>Morning</p>*/}
                                                                                {/*                        <i className="bi bi-dot"></i>*/}
                                                                                {/*                        <p className='font-bold'>Weekdays</p>*/}
                                                                                {/*                    </div>*/}
                                                                                {/*                </Col>*/}
                                                                                {/*                <Col span={8}>*/}
                                                                                {/*                    <Button*/}
                                                                                {/*                        type='primary'*/}
                                                                                {/*                        onClick={this.handleNewViewing}>Book*/}
                                                                                {/*                        a*/}
                                                                                {/*                        viewing</Button>*/}
                                                                                {/*                </Col>*/}
                                                                                {/*            </Row>*/}
                                                                                {/*        </div>*/}
                                                                                {/*    </div>*/}
                                                                                {/*    /!* Share via WhatsApp - Shared via WhatsApp *!/*/}
                                                                                {/*    <div className='childcontent'>*/}
                                                                                {/*        <div*/}
                                                                                {/*            className="d-flex flex-row justify-content-between">*/}
                                                                                {/*            <p className="awriter">*/}
                                                                                {/*                <span*/}
                                                                                {/*                    className="font-bold mr-1">Mark Castley </span>*/}
                                                                                {/*                <span>shared your property through WhatsApp</span>*/}
                                                                                {/*            </p>*/}
                                                                                {/*            <p className="atime justify-content-end">*/}
                                                                                {/*                <Moment*/}
                                                                                {/*                    fromNow></Moment>*/}
                                                                                {/*            </p>*/}
                                                                                {/*        </div>*/}
                                                                                {/*    </div>*/}
                                                                                {/*    /!* Share via WhatsApp - Shared via WhatsApp *!/*/}
                                                                                {/*    <div className='childcontent'>*/}
                                                                                {/*        <div*/}
                                                                                {/*            className="d-flex flex-row justify-content-between">*/}
                                                                                {/*            <p className="awriter">*/}
                                                                                {/*                <span*/}
                                                                                {/*                    className="font-bold mr-1">Mark Castley </span>*/}
                                                                                {/*                <span>declined your request</span>*/}
                                                                                {/*            </p>*/}
                                                                                {/*            <p className="atime justify-content-end">*/}
                                                                                {/*                <Moment*/}
                                                                                {/*                    fromNow></Moment>*/}
                                                                                {/*            </p>*/}
                                                                                {/*        </div>*/}
                                                                                {/*        <div className="adesc">*/}
                                                                                {/*            <span*/}
                                                                                {/*                className='mentioned mr-1'>@Mark Richards</span>*/}
                                                                                {/*            <span>property doesn't meet the requirements</span>*/}
                                                                                {/*        </div>*/}
                                                                                {/*    </div>*/}
                                                                                {/*</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                {/* Requested Viewings */}
                                                                {/*{*/}
                                                                {/*    this.state.requested_viewings && this.state.requested_viewings.map((requested_viewing)=>(*/}
                                                                {/*        <div className='showproperty timegroup note'>*/}
                                                                {/*            <div className='col-1'>*/}
                                                                {/*                <div className='iconwrap'>*/}
                                                                {/*                    <i className="bi bi-house-up"></i>*/}
                                                                {/*                </div>*/}
                                                                {/*            </div>*/}
                                                                {/*            <div className="col-11 px-0">*/}
                                                                {/*                <div className="col-12 d-flex flex-row">*/}
                                                                {/*                    <div className="agentdetails">*/}
                                                                {/*                        <div*/}
                                                                {/*                            className="d-flex flex-row justify-content-between">*/}
                                                                {/*                            <p className="awriter">*/}
                                                                {/*                                <span*/}
                                                                {/*                                    className="font-bold mr-1">{requested_viewing.by_user_name}</span>*/}
                                                                {/*                                <span>wants you to share his property to your client.</span>*/}
                                                                {/*                            </p>*/}
                                                                {/*                            <p className="atime justify-content-end">*/}
                                                                {/*                                <Moment fromNow>*/}
                                                                {/*                                    {(requested_viewing.added_date) ? this.getCurrentTime(requested_viewing.added_date) : "-"}*/}
                                                                {/*                                </Moment>*/}
                                                                {/*                            </p>*/}
                                                                {/*                        </div>*/}
                                                                {/*                        <div className="adesc p-0">*/}
                                                                {/*                            <div*/}
                                                                {/*                                className='showpropertycontent'>*/}
                                                                {/*                                <a href=""*/}
                                                                {/*                                   className='font-bold'>{requested_viewing.note}</a>*/}
                                                                {/*                                <p className="font-semibold">{requested_viewing.unitno}, {requested_viewing.community}, {requested_viewing.subcommunity} <i*/}
                                                                {/*                                        className="bi bi-dot"></i><span>{(requested_viewing.price) ? this.getPrice(requested_viewing.price) + " AED" : "-"}</span>*/}
                                                                {/*                                </p>*/}
                                                                {/*                            </div>*/}
                                                                {/*                            <Row className='showpropbtm'>*/}
                                                                {/*                                <Col span={6}*/}
                                                                {/*                                     className='showprop-timer'>*/}
                                                                {/*                                    <i className="bi bi-hourglass-split fs-6"></i>*/}
                                                                {/*                                    {this.state.remainingTime}*/}
                                                                {/*                                </Col>*/}
                                                                {/*                                <Col span={17}*/}
                                                                {/*                                     className='showprop-actions'>*/}
                                                                {/*                                    <Button type='primary'*/}
                                                                {/*                                            size='small'*/}
                                                                {/*                                            onClick={this.handleRequestNewViewing.bind(this, requested_viewing)}>Book*/}
                                                                {/*                                        a viewing</Button>*/}
                                                                {/*                                    <Button type='primary'*/}
                                                                {/*                                            size='small'*/}
                                                                {/*                                            disabled={this.state.isWhatsappLoading}*/}
                                                                {/*                                            onClick={this.handleisShareWhatsApp.bind(this, requested_viewing)}>Share*/}
                                                                {/*                                        via*/}
                                                                {/*                                        WhatsApp  {this.state.isWhatsappLoading && <Spin style={{ alignSelf: 'center' }} />}</Button>*/}
                                                                {/*                                    <Button type='primary'*/}
                                                                {/*                                            size='small'*/}
                                                                {/*                                            onClick={this.handleisDecline.bind(this, requested_viewing)}>Decline</Button>*/}
                                                                {/*                                </Col>*/}
                                                                {/*                            </Row>*/}
                                                                {/*                        </div>*/}

                                                                {/*                    </div>*/}
                                                                {/*                </div>*/}
                                                                {/*            </div>*/}
                                                                {/*        </div>*/}
                                                                {/*    ))*/}
                                                                {/*}*/}

                                                                {/*comment section*/}
                                                                {/*{this.state.comment &&*/}
                                                                {/*    <div className={"timegroup note"}>*/}
                                                                {/*        <div className="col-1">*/}
                                                                {/*            <div className="iconwrap">*/}
                                                                {/*                <i className="bi bi-chat-fill"></i>*/}
                                                                {/*            </div>*/}
                                                                {/*        </div>*/}
                                                                {/*        <div className="col-11 px-0">*/}
                                                                {/*            <div className="col-12 d-flex flex-row">*/}
                                                                {/*                <div className="agentdetails">*/}
                                                                {/*                    <div className="d-flex flex-row justify-content-between">*/}
                                                                {/*                        <p className="awriter"><span className="font-bold">{"Comment: "}</span>*/}
                                                                {/*                        </p>*/}
                                                                {/*                    </div>*/}
                                                                {/*                    <div className="adesc">*/}
                                                                {/*                        {parse('<div>' + this.state.comment + '</div>')}*/}
                                                                {/*                    </div>*/}
                                                                {/*                </div>*/}
                                                                {/*            </div>*/}
                                                                {/*        </div>*/}
                                                                {/*    </div>*/}
                                                                {/*}*/}
                                                                {/*comment section*/}

                                                                {this.state.all.map((al) => {
                                                                    return (
                                                                        <div className={"timegroup " + al.note_type}>
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    {al.note_type == "phone" ? <i className="bi bi-telephone-fill"></i> : null}
                                                                                    {al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : null}
                                                                                    {al.note_type == "whatsapp" ? <i className="bi bi-whatsapp" /> : null}
                                                                                    {al.note_type == "note" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                                                                    {al.note_type == "viewing" ? <i className="bi bi-eye-fill"></i> : null}
                                                                                    {al.note_type == "offer" ? <i className="bi bi-tag-fill"></i> : null}
                                                                                    {al.note_type == "followup" ? <i className="bi bi-calendar"></i> : null}
                                                                                    {al.note_type == "req_decline" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                                                                    {al.note_type == "req_expire" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                                                                    {al.note_type == "req_accept" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                                                                    {al.note_type == "req_t_up" ? <i className="bi bi-house-up"></i> : null}
                                                                                    {al.note_type == "req_w" ? <i className="bi bi-house-up"></i> : null}


                                                                                </div>
                                                                            </div>
                                                                            <div className="col-11 px-0">
                                                                                <div className="col-12 d-flex flex-row">
                                                                                    <div className="agentdetails">
                                                                                        <div className="d-flex flex-row justify-content-between">
                                                                                            <p className="awriter"><span className="font-bold">{(al.by_user_name) ? al.by_user_name : "System"}</span>
                                                                                                {al.note_type == "phone" ? " logged a call" : null}
                                                                                                {al.note_type == "email" ? " sent an email" : null}
                                                                                                {al.note_type == "whatsapp" ? "" : null}
                                                                                                {al.note_type == "note" ? " added a note" : null}
                                                                                                {al.note_type == "viewing" ? " added a viewing" : null}
                                                                                                {al.note_type == "offer" ? " added an offer" : null}
                                                                                                {al.note_type == "followup" ? " scheduled a follow up" : null}
                                                                                                {al.note_type == "req_decline" ? " declined your request" : null}
                                                                                                {al.note_type == "req_expire" && <span>
                                                                                                    <span> share property to your client.</span> <div
                                                                                                    className='showpropertycontent'>
                                                                                                    <a href=""
                                                                                                       className='font-bold'>{al.note}</a>
                                                                                                    <p className="font-semibold">{(al.unitno) ? al.unitno: ""} {(al.community) ? "," + al.community : ""} {(al.subcommunity) ? "," + al.subcommunity : ""} {(al.price) && <i
                                                                                                        className="bi bi-dot"></i>}<span>{(al.price) ? this.getPrice(al.price) : ""}</span>
                                                                                                    </p>
                                                                                                    </div>
                                                                                                </span>
                                                                                                }
                                                                                                {al.note_type == "req_accept" ? " accept the viewing request" : null}
                                                                                                {al.note_type == "req_t_up" ? " do not respond on viewing request" : null}
                                                                                                {al.note_type == "req_w" ? " share your property through whatsapp" : null}
                                                                                            </p>
                                                                                            <p className="atime">
                                                                                                <Moment fromNow>
                                                                                                    {(al.added_date) ? this.getCurrentTime(al.added_date) : ""}
                                                                                                </Moment></p>
                                                                                        </div>
                                                                                        <div className="adesc">
                                                                                            {al.note_type && al.note_type == 'req_expire' && al.children != [] &&
                                                                                                al.children.map((child) => (
                                                                                                    <div className='childrenwrap mt-2'>
                                                                                                        {/* Book a viewing */}
                                                                                                        {child && child.note_type == "req_accept" && <div className='childcontent'>
                                                                                                            <div
                                                                                                                className="d-flex flex-row justify-content-between">
                                                                                                                <p className="awriter">
                                                                                                <span
                                                                                                    className="font-bold mr-1">{child.by_user_name}</span>
                                                                                                                    <span>scheduled a viewing with client.</span>
                                                                                                                    <span
                                                                                                                        className='stat scheduled'>Scheduled</span>
                                                                                                                </p>
                                                                                                                <p className="atime justify-content-end">
                                                                                                                    <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                    </Moment>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="adesc">
                                                                                                                <Row>
                                                                                                                    <Col span={24}><span
                                                                                                                        className='font-10'>Date / Time:</span></Col>
                                                                                                                    <Col span={8}>
                                                                                                                        <p className='font-bold fs-5 mb-0'>{this.getTime(child.added_date)}</p>
                                                                                                                        <p className="font-12">{this.getDate(child.added_date)}</p>
                                                                                                                    </Col>
                                                                                                                    <Col span={16}>
                                                                                                                        <p>Viewing booked
                                                                                                                            for <span
                                                                                                                                className='font-bold'>{al.note}</span>
                                                                                                                        </p>
                                                                                                                        <p className="font-12 mt-2">{al.unitno},
                                                                                                                            {al.community}, {al.subcommunity} <i
                                                                                                                                className="bi bi-dot"></i><span>{al.price} AED</span>
                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                        {/* Share via WhatsApp - Replied */}
                                                                                                        {child && child.note_type == "req_wait" &&  <div className='childcontent'>
                                                                                                            <div
                                                                                                                className="d-flex flex-row justify-content-between">
                                                                                                                <p className="awriter">
                                                                                                <span
                                                                                                    className="font-bold mr-1">{this.state.lead_list_details.owner}</span>
                                                                                                                    <span>replied</span>
                                                                                                                </p>
                                                                                                                <p className="atime justify-content-end">
                                                                                                                    <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                    </Moment>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="adesc">
                                                                                                                <Row
                                                                                                                    className='align-content-center'>
                                                                                                                    <Col span={16}
                                                                                                                         className='d-flex flex-column'>
                                                                                                    <span
                                                                                                        className='font-10'>Best time to view:</span>
                                                                                                                        <div
                                                                                                                            className='d-flex flex-row'>
                                                                                                                            <p className='font-bold mb-0'>{child.note}</p>
                                                                                                                            {/*<i className="bi bi-dot"></i>*/}
                                                                                                                            {/*<p className='font-bold'>Weekdays</p>*/}
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                    <Col span={8}>
                                                                                                                        <Button
                                                                                                                            type='primary'
                                                                                                                            onClick={this.handleRequestNewViewing.bind(this, al)}>Book
                                                                                                                            a
                                                                                                                            viewing</Button>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                        {/* Share via WhatsApp - Shared via WhatsApp */}
                                                                                                        {child && child.note_type == "req_w" &&  <div className='childcontent'>
                                                                                                            <div
                                                                                                                className="d-flex flex-row justify-content-between">
                                                                                                                <p className="awriter">
                                                                                                <span
                                                                                                    className="font-bold mr-1">{child.by_user_name}</span>
                                                                                                                    <span>{child.note}</span>
                                                                                                                </p>
                                                                                                                <p className="atime justify-content-end">
                                                                                                                    <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                    </Moment>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                        {/* Share via WhatsApp - Shared via WhatsApp */}
                                                                                                        {child && child.note_type == "req_decline" && <div className='childcontent'>
                                                                                                            <div
                                                                                                                className="d-flex flex-row justify-content-between">
                                                                                                                <p className="awriter">
                                                                                                <span
                                                                                                    className="font-bold mr-1">{child.by_user_name}</span>
                                                                                                                    <span>declined your request</span>
                                                                                                                </p>
                                                                                                                <p className="atime justify-content-end">
                                                                                                                    <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                    </Moment>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="adesc">
                                                                                                                {/*<span*/}
                                                                                                                {/*    // className='mentioned mr-1'>@Mark Richards</span>*/}
                                                                                                                <span>{child.note}</span>
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            {
                                                                                                al.note_type != "req_expire" && (al.note_type != "req_accept" && parse('<div>' + al.note + '</div>'))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* NOTES */}
                                                        <div className="tab-pane fade" id="nav-notewrap" role="tabpanel" aria-labelledby="nav-notewrap-tab" tabIndex={0}>
                                                            <div className="timeline-list">

                                                                {this.state.notes.map((al) => {
                                                                    return (
                                                                        <div className={"timegroup " + al.note_type}>
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    {al.note_type == "phone" ? <i className="bi bi-telephone-fill"></i> : null}
                                                                                    {al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : null}
                                                                                    {al.note_type == "whatsapp" ? <i className="bi bi-whatsapp" /> : null}
                                                                                    {al.note_type == "note" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                                                                    {al.note_type == "viewing" ? <i className="bi bi-eye-fill"></i> : null}
                                                                                    {al.note_type == "offer" ? <i className="bi bi-tag-fill"></i> : null}
                                                                                    {al.note_type == "followup" ? <i className="bi bi-calendar"></i> : null}


                                                                                </div>
                                                                            </div>
                                                                            <div className="col-11 px-0">
                                                                                <div className="col-12 d-flex flex-row">
                                                                                    <div className="agentdetails">
                                                                                        <div className="d-flex flex-row justify-content-between">
                                                                                            <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                                                                {al.note_type == "phone" ? " logged a call" : null}
                                                                                                {al.note_type == "email" ? " sent an email" : null}
                                                                                                {al.note_type == "whatsapp" ? "" : null}
                                                                                                {al.note_type == "note" ? " added a note" : null}
                                                                                                {al.note_type == "viewing" ? " added a viewing" : null}
                                                                                                {al.note_type == "offer" ? " added an offer" : null}
                                                                                                {al.note_type == "followup" ? " scheduled a follow up" : null}
                                                                                            </p>
                                                                                            <p className="atime">
                                                                                                <Moment fromNow>
                                                                                                    {(al.added_date) ? this.getCurrentTime(al.added_date) : ""}
                                                                                                </Moment>
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="adesc">
                                                                                            {al.note && al.note.replace(/<[^>]+>/g, '')}
                                                                                            {/* {al.note !=null && al.note !=""? parse('<div>' + al.note + '</div>'):""} */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* CALLS */}
                                                        <div className="tab-pane fade" id="nav-calllist" role="tabpanel" aria-labelledby="nav-calllist-tab" tabIndex={0}>
                                                            <div className="timeline-list">

                                                                {this.state.call_notes.map((call_note) => {
                                                                    return (
                                                                        <div className="timegroup phone">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-telephone-fill" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-11 px-0">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter">
                                                                                                <span className="font-bold">{call_note.by_user_name}</span>
                                                                                                <span> logged a call.</span>
                                                                                            </p>
                                                                                            <p className="atime">
                                                                                                <Moment fromNow>
                                                                                                    {this.getCurrentTime(call_note.added_date)}
                                                                                                </Moment></p>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        <div className="adesc" dangerouslySetInnerHTML={{ __html: call_note.note }}></div>
                                                                                        {/*{call_note.note.replace(/<[^>]+>/g, '')}*/}
                                                                                        {/* {parse('<div className="font-12">' + call_note.note + '</div>')} */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                                                <h3>Match Details</h3>
                                                <div className="d-flex flex-column pt-3">
                                                    <div className="d-flex flex-column matchfilter">
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="community" className="mb-0">Community:</label>
                                                                <Select id="community"
                                                                        style={{
                                                                            width: 160,
                                                                        }}

                                                                        showSearch
                                                                    // allowClear
                                                                    // defaultValue={this.state.community_match.toString()}
                                                                        value={
                                                                            this.state.community_match == undefined  || this.state.community_match == null ||this.state.community_match=="" ?
                                                                                "Select"
                                                                                :
                                                                                this.state.community_match.toString()
                                                                        }

                                                                        placeholder="Select a person"
                                                                        optionFilterProp="children"
                                                                        onChange={this.handleCommunityMatch}
                                                                    // onSearch={onSearch}
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                    // options={this.state.communities}

                                                                        options={this.state.communities.map((e)=> {
                                                                            return {
                                                                                label:e.label, value: e.value.toString()
                                                                            }
                                                                        })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub-Community:</label>
                                                                <Select id="propsubcommunity"
                                                                        style={{
                                                                            width: 160,
                                                                        }}
                                                                    // mode={"multiple"}

                                                                        showSearch
                                                                    // value={this.state.sub_community_match.toString()}
                                                                        value={
                                                                            this.state.sub_community_match == undefined  || this.state.sub_community_match == null ||this.state.sub_community_match=="" ?
                                                                                "Select"
                                                                                :
                                                                                this.state.sub_community_match.toString()
                                                                        }
                                                                        maxTagCount={"responsive"}
                                                                        placeholder="Select a person"
                                                                        optionFilterProp="children"
                                                                        onChange={this.handleSubCommunityMatch}
                                                                    // onSearch={onSearch}
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                    //options={this.state.sub_communities}
                                                                        options={this.state.sub_communities.map((e)=> {
                                                                            return {
                                                                                label:e.label, value: e.value.toString()
                                                                            }
                                                                        })}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row budget">
                                                                <label for="matchingBudgetMin">Budget:</label>

                                                                <div className="d-flex flex-row">
                                                                    <CurrencyInput
                                                                        id="input-min"
                                                                        name="input-min"
                                                                        placeholder="Min"
                                                                        value={this.state.min_budget}
                                                                        decimalsLimit={2}
                                                                        onChange={this.handleMinBudget}
                                                                    />
                                                                    {/* <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" /> */}
                                                                    <span className="dash" style={{}}>-</span>
                                                                    <CurrencyInput
                                                                        id="input-max"
                                                                        name="input-max"
                                                                        placeholder="Max"
                                                                        value={this.state.max_budget}

                                                                        decimalsLimit={2}
                                                                        onChange={this.handleMaxBudget}
                                                                    />
                                                                    {/* <input type="text" onChange={this.handleMaxBudget}  className="currencyInput" id="matchingBudgetMax" placeholder="Max" /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="matchingBedrooms">Beds:</label>
                                                                <select value={this.state.bedroom_match} onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value="Studio">Studio</option>
                                                                    <option value={1}>1</option>
                                                                    <option value={2}>2</option>
                                                                    <option value={3} >3</option>
                                                                    <option value={4}>4</option>
                                                                    <option value={5}>5</option>
                                                                    <option value={6}>6</option>
                                                                    <option value={7}>7</option>
                                                                    <option value={8}>8</option>
                                                                    <option value={9}>9</option>
                                                                    <option value={10}>10</option>
                                                                    <option value={11}>11</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={13}>13</option>
                                                                    <option value={14}>14</option>
                                                                    <option value={15}>15</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row w-100">
                                                                <label for="addedDateM">Added Date:</label>
                                                                <select onChange={this.handleAddedDateMatch} id="addedDateM" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value={1}>Last 24 Hours</option>
                                                                    <option value={3}>Last 3 Days</option>
                                                                    <option value={7}>Last 7 Days</option>
                                                                    <option value={14}>Last 14 Days</option>
                                                                    <option value={30}>Last 1 Month</option>
                                                                    <option value={90}>Last 3 Month</option>
                                                                    <option value={180}>Last 6 Month</option>
                                                                    <option value={365}>Last 1 Year</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row">
                                                                <label for="matchingPfor">Property For:</label>
                                                                <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value="rent">Rent</option>
                                                                    <option value="sale">Sale</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row">
                                                                <label for="matchingType">Type:</label>
                                                                <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                                                                    <option>select</option>
                                                                    {this.state.propertytypes.map((propertytype) => {
                                                                        return <option value={propertytype.value}>{propertytype.label}</option>
                                                                    })}


                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                                                        </div>

                                                    </div>

                                                    <div className="matchresults">
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <label className="control-labels mr-4">Match with access</label>
                                                        </div>
                                                        <div className="matchresult">
                                                            {this.state.matchs.map((match) => {
                                                                const match_price = match.price


                                                                return (
                                                                    <a href className="matchitem">
                                                                        <div className="matchinfo">
                                                                            <div className="col-12 col-md-9 px-0">
                                                                                <p className="address">{match.unitno}</p>
                                                                                <p className="address">{match.address}</p>
                                                                                <p className="address"> {match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'}</p>

                                                                            </div>
                                                                            <div className="col-3">
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="matchhistory">
                                                                            <p className="addeddate">
                                                                                <Moment fromNow>
                                                                                    {match.added_date}
                                                                                </Moment>
                                                                            </p>
                                                                            <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                                                        </div>
                                                                    </a>
                                                                )
                                                            })}

                                                            {this.state.matchs.length > 0 ? <Link onClick={this.handleAllMatch} className="matchitem">

                                                                View All

                                                            </Link> : null}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* END Match*/}
                                            {/* OFFERS */}
                                            <div className="bg-white border-top offers p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4">Offers</h3>
                                                    <a href className="btn btn-link viewall">View All</a>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">

                                                    {this.state.property_offers.map((property_offer) => {

                                                        const offer_price = property_offer.price
                                                        return (
                                                            <a onClick={this.handleOfferView.bind(this, property_offer.views_id)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        <p className="address">{property_offer.address}</p>
                                                                        <p className="price">Offer:  {offer_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'}</p>
                                                                        <p className="price">{property_offer.view_date}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button type="button" className="btn btn-link addfeedback"></button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_offers.length > 0 ? <a className="matchitem viewall">
                                                        View All
                                                    </a> : null}
                                                </div>
                                            </div> {/* END OFFERS */}
                                            {/* VIEWINGS */}
                                            <div className="bg-white border-top viewings p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4">Viewings</h3>
                                                    <a onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</a>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">
                                                    {this.state.property_viewings.map((property_viewing) => {

                                                        return (
                                                            <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        <p className="address">{property_viewing.address}</p>
                                                                        <p className="price">
                                                                            {property_viewing.price != null && property_viewing.price != "" ? <>Offer: AED {property_viewing.price}</> : null}

                                                                        </p>
                                                                        <p className="price">{property_viewing.view_date}</p>
                                                                        <p className="price">{property_viewing.note}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button type="button" className="btn btn-link addfeedback"></button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_viewings.length > 0 ? <Link to={"/all_lead_viewings/" + this.state.record_id} target="_blank" className="btn btn-link viewall">View All</Link>
                                                        : null}

                                                </div>
                                            </div> {/* END VIEWINGS*/}

                                            {/* Followup */}
                                            <div className="bg-white border-top viewings p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4">Follow Up</h3>
                                                    <a onClick={this.handleNewFollowUp} className="btn btn-link viewall">Add Followup</a>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">
                                                    {this.state.followups_details.map((followup) => {
                                                        return (
                                                            <a onClick={this.handleViewFollowup.bind(this, followup)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        {/*<p className="price">{followups.first_name}</p>*/}
                                                                        <p className="price">{(followup.type === 1) ? "Vendor" : "Client"}</p>
                                                                        <p className="price">{(followup.status === 1) ? "Active" : "Inactive"}</p>
                                                                        <p className="address">
                                                                            {(followup.notes) ? (followup.notes.length > 40 ?
                                                                                `${followup.notes.substring(0, 40)}...` : followup.notes) : null
                                                                            }
                                                                        </p>
                                                                        <p className="price">{followup.date}</p>
                                                                        <p className="price">{(followup.agent_name) ?  "Follow up by " + followup.agent_name : ""}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button type="button" className="btn btn-link addfeedback"></button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                                                        View All
                                                    </a> : null}

                                                </div>
                                            </div> {/* END Followup*/}

                                            <div className="bg-white border-top viewings p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-start" }} >
                                                        <SettingFilled style={{ fontSize: '1rem', marginRight: '0.35rem' }} />
                                                        <h3 className="mr-4">Collaborators</h3>
                                                    </div>
                                                </div>

                                                <Select
                                                    // defaultValue="lucy"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '1rem'
                                                    }}
                                                    showSearch
                                                    mode={"multiple"}
                                                    value={this.state.newArrSelectedAgents}
                                                    placeholder="Select a person"
                                                    maxTagCount={"responsive"}
                                                    onChange={this.handleChangeSelect}
                                                    // onSelect={(itm)=> {
                                                    //   this.onSelectAgents(itm)
                                                    //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents)
                                                    // }}
                                                    // onDeselect={(itm)=> {
                                                    //   this.onDeSelectAgents(itm)
                                                    //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents)
                                                    // }}
                                                    options={this.state.agents}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                />
                                                {/* <button type="button" className="btn btn-dark w-40 mb-2"  >
                  <p style={{ fontSize:'1rem', color:'white' }} >
                    Add Collaborator
                  </p>
                </button> */}

                                                <Button onClick={this.postAddCollaborator} type="primary" style={{ backgroundColor: 'black', color: 'white' }} >Add Collaborator</Button>

                                                <div style={{ width: '100%', marginTop: '1rem', paddingLeft: '0.25rem' }} >
                                                    {this.state.collaborators.map((coll, index) => {
                                                        return (
                                                            <div className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        <p className="name">{coll.display_name}</p>
                                                                        <p className="price">{coll.added_date}</p>
                                                                    </div>
                                                                    {
                                                                        role == 1 ?
                                                                            <div className="col-3">
                                                                                <button type="button" className="btn btn-link addfeedback" onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)} > Delete</button>
                                                                            </div>
                                                                            :
                                                                            user_id == coll.user_id &&
                                                                            <div className="col-3">
                                                                                <button type="button" className="btn btn-link addfeedback" onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)} > Delete </button>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {this.state.collaborators.length > 0 ?
                                                        <Link to={"/all_lead_viewings/" + this.state.record_id} target="_blank" className="btn btn-link viewall w-100 text-right">View All</Link>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>




                </Drawer>

                <div className="contentwrap" style={{
                    minHeight: 380,
                    background: "#fff",
                }}>

                    {/* <Tabs >
                        <TabList>
                            {this.state.filters.length > 0? <Tab onClick={this.handleClearFilter}>All</Tab>:null}

                            {this.state.filters.map((filter)=>(
                                <>

                                    <Tab><span onClick={this.handleTabs.bind(this,filter.key)} className="mr-3">{filter.label}</span><CloseOutlined onClick={this.handleDeleteFilter.bind(this,filter.key)} style={{color:'rgba(0, 0, 0, 0.45)', fontSize: '12px', verticalAlign: 'middle'}} /></Tab>

                                </>
                            ))}

                        </TabList>


                    </Tabs> */}

                    {/* <Tabs style={{ color: '#8D949C', width: "100%" }} hideAdd type="editable-card" items={this.state.filters} onChange={this.handleTabs} onEdit={this.handleDeleteFilter} /> */}

                    {/* <Tabs style={{ color: '#8D949C', width: "100%" }} hideAdd type="editable-card" defaultActiveKey="1" onChange={this.handleTabs} onEdit={this.handleDeleteFilter}>
            {this.state.filters
              .map((key) => (

                <TabPane
                  tab={
                    <span>
                      <EditOutlined onClick={this.handleEditFilter.bind(this, key.key)} />
                      {key.label}


                    </span>
                  }
                  key={key.key}
                >

                </TabPane>
              ))
            }
          </Tabs> */}
                    <div className='d-flex px-3'>
                        <div className='row w-100'>
                            <Space direction="vertical">


                                <Space wrap>

                                    <div className="d-flex flex-row justify-content-between my-2 w-100 pt-3 pl-3">
                                        
                                        <Col className='viewas-wrap d-flex flex-column' >
                            <Space direction="horizontal">
                            <label className='lblfilter'>View as:</label>
                            <div className='viewswitcher-btn d-flex align-items-center'>
                                <Button type="text" onClick={this.handleToggleViewGallery} className={this.state.isViewAsGallery ?"activeview mt-0" : "mt-0"}><i class="bi bi-view-list"></i></Button>
                                <Button type='text' onClick={this.handleToggleViewTable} className={this.state.isViewAsGallery ? "mt-0" : "activeview mt-0"}><i class="bi bi-table"></i></Button>
                            </div>
                            </Space>
                        </Col>
                                        <div className="d-flex flex-row justify-content-between">
                                            <Button type='primary' className="addfilter ml-2" onClick={this.onClickRequestViewing} icon={<ShareAltOutlined />}>
                                                Request Viewing
                                            </Button>
                                            <Button type='primary' className="addfilter ml-2" onClick={this.handleNewFilter} icon={<FilterOutlined />}>
                                                Add new filter
                                            </Button>
                                            <Button className='ml-2 addnewlisting' type='primary' onClick={this.handleClearFilter} icon={<ClearOutlined />}>
                                                Reset filter
                                            </Button>
                                        </div>
                                    </div>




                                    <Modal width={600} title="Filter" open={this.state.isFilterOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
                                        <div className='row w-100 '>
                                            <div className='col-md-12 px-0'>
                                                <div className='form-group'>
                                                    <label>Title</label>

                                                    <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                                                    {this.state.titleHasError?<div className='text-danger'>Title required</div>:null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row w-100' />


                                        <Select

                                            showSearch
                                            style={{
                                                width: 300,height:46
                                            }}
                                            placeholder="add filter"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.filter_options}
                                            onChange={this.handleAddRow}
                                            //   onChange={add()}
                                        />








                                        {this.state.rows.map((item, idx) => (
                                            <div className='d-flex flex-row w-100' >
                                                {item.id == 1 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 px-0 mb-0'>
                                                            <label>Contact type</label>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%', minWidth: "7rem"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleContactType}
                                                                options={this.state.contact_types}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 2 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-6 d-flex flex-row mt-3 align-content-end pl-0'>
                                                        <div className='form-group w-100 px-0 mb-0'>
                                                            <label>Community</label>
                                                            <Select
                                                                style={{
                                                                    width: 300,
                                                                }}
                                                                showSearch
                                                                placeholder="Select a person"
                                                                optionFilterProp="children"
                                                                onChange={this.handleCommunity}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.communities}
                                                            />

                                                        </div>
                                                    </div>      <div className='col-5 pr-0 d-flex flex-row mt-3 align-content-end'>
                                                    <div className='form-group w-100 px-0 mb-0'>
                                                        <label>Sub community</label>
                                                        <Select
                                                            style={{
                                                                width: 300,
                                                            }}
                                                            showSearch
                                                            placeholder="Select a person"
                                                            optionFilterProp="children"
                                                            onChange={this.handleSubCommunity}
                                                            // onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={this.state.sub_communities}
                                                        />
                                                    </div>
                                                </div><div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                    className="btn btn-outline-danger btn-sm"
                                                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                ><CloseOutlined style={{display: "flex", }}/>
                                                </button></div></div> : null}
                                                {item.id == 3 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group col-6 px-0 mb-0'>
                                                            <label className='font-bold'>Price</label>
                                                            <Select
                                                                className='pricedrop'
                                                                style={{
                                                                    width: '100%', minWidth: "7rem", height:"46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handlePriceFirstValue_id}
                                                                options={this.state.price_options}
                                                            />
                                                        </div>

                                                        <div className='d-flex flex-row col-6 pr-0' style={{alignItems: end}}>
                                                            {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text" style={{ width: '50%', height:"46px",fontSize: "14px" }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                                                                <input type="text" style={{ width: '50%', height:"46px",fontSize: "14px" }} className="form-control ml-1 " onChange={this.handlePriceMax} placeholder="max" />
                                                            </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." style={{ height:"46px",fontSize: "14px"}} />}
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 4 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>

                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group col-6 px-0 mb-0'>
                                                            <label className='font-bold'>Bedrooms</label>
                                                            <Select
                                                                className='pricedrop'
                                                                style={{
                                                                    width: '100%',minWidth: "7rem", height:"46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleBedRooms_id}
                                                                options={this.state.bedroom_filter_options}
                                                            />
                                                        </div>
                                                        <div className='d-flex flex-row col-6 pr-0 align-items-end'>
                                                            <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleBedRoomsValue} placeholder="filter..."  style={{ height:"46px",fontSize: "14px"}}/>
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div> </div> : null}

                                                {item.id == 5 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Created</label>
                                                                <div className='d-flex flex-row' style={{gap: "0.6rem"}}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                                                        <label className="form-check-label" for="flexRadioDefault1">
                                                                            More than
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                                                        <label className="form-check-label" for="flexRadioDefault2">
                                                                            Less than
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <input type="text" className="form-control" onChange={this.handleDateValue} />
                                                                    <select className='form-select ml-1' onChange={this.handleDateValue_id} style={{fontSize: "14px"}}>
                                                                        <option value="1">days</option>
                                                                        <option value="2">weeks</option>
                                                                        <option value="3">months</option>
                                                                        <option value="4">years</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{display: "flex", }}/>
                                                        </button></div></div> : null}
                                                {item.id == 6 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 mb-0'>
                                                            <label>Stage</label>

                                                            <Select
                                                                mode="multiple"

                                                                style={{
                                                                    width: '100%',minWidth: "7rem", height:"46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleStages}
                                                                options={this.state.stages}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 7 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 mb-0'>
                                                            <label>Furnished / Unfurnished</label>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',minWidth: "7rem", height:"46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleFurnishedTypes}
                                                                options={this.state.furnished_types}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 8 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 mb-0'>
                                                            <label>Agents</label>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',minWidth: "7rem", height:"46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleAgent}
                                                                options={this.state.agents}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 9 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div style={{
                                                        padding: '15px',
                                                        // borderRadius: '6px',
                                                        background: '#fff',
                                                        //  boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                                                    }}>
                                                        <label>Viewings</label>

                                                        <select value={this.state.viewing_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleViewingValue_id} >
                                                            <option value="1">Equals</option>
                                                            <option value="2">Not equal</option>
                                                            <option value="3">Less than</option>
                                                            <option value="4">Greater than</option>
                                                            <option value="5">In range</option>

                                                        </select>
                                                        <div className='mt-2'>
                                                            {this.state.inrange_viewing ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text" value={this.state.viewing_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMinValue} placeholder="min value" />
                                                                <input type="text" value={this.state.viewing_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMaxValue} placeholder="max value" />
                                                            </div> : <input type="text" value={this.state.viewing_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleViewingValue} placeholder="filter..." />}
                                                        </div>

                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 10 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div style={{
                                                        padding: '15px',
                                                        // borderRadius: '6px',
                                                        background: '#fff',
                                                        // boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                                                    }}>
                                                        <label>Offers</label>

                                                        <select value={this.state.offer_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleOfferValue_id} >
                                                            <option value="1">Equals</option>
                                                            <option value="2">Not equal</option>
                                                            <option value="3">Less than</option>
                                                            <option value="4">Greater than</option>
                                                            <option value="5">In range</option>

                                                        </select>
                                                        <div className='mt-2'>
                                                            {this.state.inrange_offer ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text" value={this.state.offer_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMinValue} placeholder="min value" />
                                                                <input type="text" value={this.state.offer_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMaxValue} placeholder="max value" />
                                                            </div> : <input type="text" value={this.state.offer_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleOfferValue} placeholder="filter..." />}
                                                        </div>

                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 11 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 px-0 mb-0'>
                                                            <label> Type</label>
                                                            <Select
                                                                showSearch
                                                                allowClear

                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                mode="multiple"
                                                                value={this.state.property_type_filter}

                                                                placeholder="Search"
                                                                onChange={this.handlePropertyTypeFilter}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.propertytypes}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{display: "flex", }}/>
                                                    </button></div>
                                                </div> : null}

                                                {item.id == 12 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Last call</label>
                                                                <div className='mb-1'>
                                                                    <select value={this.state.last_call_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastCallOptionValue} >
                                                                        <option value="1">Equals</option>
                                                                        <option value="2">Not equal</option>
                                                                        <option value="3">Less than</option>
                                                                        <option value="4">Greater than</option>

                                                                    </select>
                                                                </div>

                                                                <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <input type="text" className="form-control" onChange={this.handleLastCallValue} />
                                                                    <select className='form-select ml-1' onChange={this.handleLastCallValue_id}>
                                                                        <option value="1">days</option>
                                                                        <option value="2">weeks</option>
                                                                        <option value="3">months</option>
                                                                        <option value="4">years</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{display: "flex", }}/>
                                                        </button></div></div> : null}

                                                {item.id == 13 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Last match</label>
                                                                <div className='mb-1'>
                                                                    <select value={this.state.last_match_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastMatchOptionValue} >
                                                                        <option value="1">Equals</option>
                                                                        <option value="2">Not equal</option>
                                                                        <option value="3">Less than</option>
                                                                        <option value="4">Greater than</option>

                                                                    </select>
                                                                </div>
                                                                <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <input type="text" className="form-control" onChange={this.handleLastMatchValue} />
                                                                    <select className='form-select ml-1' onChange={this.handleLastMatchValue_id}>
                                                                        <option value="1">days</option>
                                                                        <option value="2">weeks</option>
                                                                        <option value="3">months</option>
                                                                        <option value="4">years</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{display: "flex", }}/>
                                                        </button></div></div> : null}

                                                {item.id == 14 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Move in date</label>
                                                                <div className='mb-1'>
                                                                    <select value={this.state.move_in_date_option_value} className="form-select" aria-label="Default select example" name='move_in_date' onChange={this.handleMoveInDateOptionValue} >
                                                                        <option value="1">Equals</option>
                                                                        <option value="2">Not equal</option>
                                                                        <option value="3">Greater than</option>
                                                                        <option value="4">Less than</option>
                                                                        <option value="5">In Next</option>
                                                                        <option value="6">In Previous</option>
                                                                        <option value="7">Date from - to</option>
                                                                        <option value="8">Is empty</option>

                                                                    </select>
                                                                </div>

                                                                {this.state.move_in_date_is_empty?null:<>
                                                                    {this.state.inrange_move_in_date? <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />:<div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                        <input value={this.state.move_in_date_value} type="text" className="form-control" onChange={this.handleMoveInDateValue} />
                                                                        <select className='form-select ml-1' onChange={this.handleMoveInDateValue_id}>
                                                                            <option value="1">days</option>
                                                                            <option value="2">weeks</option>
                                                                            <option value="3">months</option>
                                                                            <option value="4">years</option>
                                                                        </select>
                                                                    </div>}
                                                                </>}


                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{display: "flex", }}/>
                                                        </button></div></div> : null}


                                            </div>
                                        ))}




                                    </Modal>

                                    {/* edit filter */}

                                    <Modal width={1000} title="Filter" open={this.state.isFilterEditOpen} onOk={this.handleEditOk} onCancel={this.handleEditCancel}>
                                        {this.state.filter_id}
                                        <hr />
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='form-group'>
                                                    <label>Title</label>
                                                    <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-4' />


                                        <Select

                                            showSearch
                                            style={{
                                                width: 300,
                                            }}
                                            placeholder="add filter"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.filter_options}
                                            onChange={this.handleAddRow}
                                            //   onChange={add()}
                                        />

                                        {this.state.rows.map((item, idx) => (
                                            <div >
                                                {item.id == 1 && this.state.default_contact_types_value.length > 0 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Contact type</label>
                                                        <Select
                                                            mode="multiple"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            defaultValue={this.state.default_contact_types_value}

                                                            placeholder="Please select"
                                                            onChange={this.handleContactType}
                                                            options={this.state.contact_types}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 2 && this.state.community_value_id > 0 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Community</label>
                                                        <Select
                                                            style={{
                                                                width: 300,
                                                            }}
                                                            showSearch
                                                            defaultValue={this.state.community_value_id}
                                                            placeholder="Select a person"
                                                            optionFilterProp="children"
                                                            onChange={this.handleCommunity}
                                                            // onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={this.state.communities}
                                                        />

                                                    </div>
                                                </div>      <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Sub community</label>

                                                        <select value={this.state.sub_community_value_id} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                                            {this.state.sub_communities.map((sub_comm) => (
                                                                <option value={sub_comm.value}>{sub_comm.label}</option>
                                                            ))}


                                                        </select>

                                                        {/* <Select
                       style={{
                        width: 300,
                      }}
                    showSearch
                    value={this.state.sub_community_value_id}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleSubCommunity}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.sub_communities}
                /> */}
                                                    </div>
                                                </div><div className='col-md-4 mt-4'>  <button
                                                    className="btn btn-outline-danger btn-sm"
                                                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                >
                                                    Remove
                                                </button></div></div> : null}
                                                {item.id == 3 && this.state.price_first_value_id > 0 ? <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label>Price</label>

                                                            <select value={this.state.price_first_value_id} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                                                <option value="1">Equals</option>
                                                                <option value="2">Not equal</option>
                                                                <option value="3">Less than</option>
                                                                <option value="4">Less than or equals</option>
                                                                <option value="5">Greater than</option>
                                                                <option value="6">Greater than or equals</option>
                                                                <option value="7">In range</option>
                                                            </select>

                                                        </div>

                                                        <div className='mt-2'>
                                                            {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text" style={{ width: '150px' }} className="form-control" value={this.state.price_min} onChange={this.handlePriceMin} placeholder="min" />
                                                                <input type="text" style={{ width: '150px' }} className="form-control ml-1" value={this.state.price_max} onChange={this.handlePriceMax} placeholder="max" />
                                                            </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.price_first_value} onChange={this.handlePriceFirst} placeholder="filter..." />}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 4 && this.state.bed_rooms_value > 0 ? <div className='row'>

                                                    <div className='col-md-4'>
                                                        <label>Bedrooms</label>
                                                        <select value={this.state.bed_rooms_value_id} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleBedRooms_id} >
                                                            <option value="1">Equals</option>
                                                            <option value="2">Not equal</option>
                                                            <option value="3">Less than</option>
                                                            <option value="4">Greater than</option>

                                                        </select>
                                                        <div className='mt-2'>
                                                            <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.bed_rooms_value} onChange={this.handleBedRoomsValue} placeholder="filter..." />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div> </div> : null}

                                                {item.id == 5 && this.state.date_value_id > 0 && this.state.date_value > 0 ?
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Created</label>


                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                                                <label className="form-check-label" for="flexRadioDefault1">
                                                                    More than
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                                                <label className="form-check-label" for="flexRadioDefault2">
                                                                    Less than
                                                                </label>
                                                            </div>

                                                            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <input type="text" className="form-control" onChange={this.handleDateValue} />
                                                                <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                                                    <option value="1">days</option>
                                                                    <option value="2">weeks</option>
                                                                    <option value="3">months</option>
                                                                    <option value="4">years</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div></div> : null}
                                                {item.id == 6 && this.state.default_stage_value.length > 0 ? <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label>Stage </label>

                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                defaultValue={this.state.default_stage_value}

                                                                placeholder="Please select"
                                                                onChange={this.handleStages}
                                                                options={this.state.stages}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'> <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 7 && this.state.default_furnished_types_value.length > 0 ? <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label>Furnished / Unfurnished </label>

                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                defaultValue={this.state.default_furnished_types_value}

                                                                placeholder="Please select"
                                                                onChange={this.handleFurnishedTypes}
                                                                options={this.state.furnished_types}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'> <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>

                                                </div> : null}


                                            </div>
                                        ))}




                                    </Modal>




                                </Space>
                            </Space>
                        </div>
                    </div>

                    <div className="table-responsive tablelistwrap px-3" >
                    <Tabs className={'blacktabs'}>
                      <TabList>
                        <Tab>Exact leads  ( {this.state.leads_account} )</Tab>
                        <Tab>Comparable leads  ( {this.state.comparable_leads_count} )</Tab>
                      </TabList>

                      <TabPanel> 

                         
                      {this.state.isViewAsGallery ? <>

                       {/* New Table for Leads Start */}

 <div className='blacktabs-content px-3'>
                        
                        <Table onChange={handleTableChange}
                               rowSelection={rowSelection}
                               loading={this.state.isLoading}
                               pagination={{
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   total:
                                       this.state?.leads?.total > 0 &&
                                       this.state.leads.total,
                                   pageSize: this.state.leads.per_page,
                                   current: this.state.leads.current_page,
                                   showTotal: (total, range) =>
                                       `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                   showSizeChanger: true,
                                   onShowSizeChange: onShowSizeChange,
                                   itemRender: itemRender,
                               }}
                               columns={new_columns}
                               scroll={{ x: '1300'}}
                               dataSource={
                                   this.state?.leads?.data
                                       ? this.state?.leads?.data
                                       : []
                               }
                               rowKey={(record) => record.original_id}
                               showHeader={false}
                        />
                        </div>

                         {/* New Table for Leads End */}

                    </> : <>
                    <div className='blacktabs-content px-3'>
                        
                        <Table onChange={handleTableChange}
                               rowSelection={rowSelection}
                               loading={this.state.isLoading}
                               pagination={{
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   total:
                                       this.state?.leads?.total > 0 &&
                                       this.state.leads.total,
                                   pageSize: this.state.leads.per_page,
                                   current: this.state.leads.current_page,
                                   showTotal: (total, range) =>
                                       `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                   showSizeChanger: true,
                                   onShowSizeChange: onShowSizeChange,
                                   itemRender: itemRender,
                               }}
                               columns={columns}
                               scroll={{ x: '1300'}}
                               dataSource={
                                   this.state?.leads?.data
                                       ? this.state?.leads?.data
                                       : []
                               }
                               rowKey={(record) => record.original_id}
                        />
                        </div>
                    </>}
                      

                      </TabPanel>
                      <TabPanel> 
                      {this.state.isViewAsGallery ? <>

{/* New Table for Leads Start compare */}

<div className='blacktabs-content px-3'>
                     
                     <Table onChange={handleComparableTableChange}
                                                    rowSelection={rowSelection}
                                                    loading={this.state.isComparableLoading}
                                                    pagination={{
                                                        pageSizeOptions: ['10', '20', '50', '100'],
                                                        total:
                                                            this.state?.comparable_leads?.total > 0 &&
                                                            this.state.comparable_leads.total,
                                                        pageSize: this.state.comparable_leads.per_page,
                                                        current: this.state.comparable_leads.current_page,
                                                        showTotal: (total, range) =>
                                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                        showSizeChanger: true,
                                                        onShowSizeChange: onShowSizeChange,
                                                        itemRender: itemRender,
                                                    }}
                                                    columns={new_columns}
                                                    scroll={{ x: '1300'}}
                                                    dataSource={
                                                        this.state?.comparable_leads?.data
                                                            ? this.state?.comparable_leads?.data
                                                            : []
                                                    }
                                                    rowKey={(record) => record.original_id}
                                                    showHeader={false}
                                             /></div>

  {/* New Table for Leads End */}

</> : <>
<div className='blacktabs-content px-3'>
                     
                     <Table onChange={handleComparableTableChange}
                                                    rowSelection={rowSelection}
                                                    loading={this.state.isComparableLoading}
                                                    pagination={{
                                                        pageSizeOptions: ['10', '20', '50', '100'],
                                                        total:
                                                            this.state?.comparable_leads?.total > 0 &&
                                                            this.state.comparable_leads.total,
                                                        pageSize: this.state.comparable_leads.per_page,
                                                        current: this.state.comparable_leads.current_page,
                                                        showTotal: (total, range) =>
                                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                        showSizeChanger: true,
                                                        onShowSizeChange: onShowSizeChange,
                                                        itemRender: itemRender,
                                                    }}
                                                    columns={columns}
                                                    scroll={{ x: '1300'}}
                                                    dataSource={
                                                        this.state?.comparable_leads?.data
                                                            ? this.state?.comparable_leads?.data
                                                            : []
                                                    }
                                                    rowKey={(record) => record.original_id}
                                             /></div>
</>}
                      </TabPanel>
                   </Tabs>
                     

                        

                        
                        

                    </div>
                </div>

            </div>




        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNoMatch: (data) => dispatch({ type: "SET_Not_Match", payload: data }),
        setNavigation: (data) => dispatch({type: "SET_NAVIGATION",payload:data})
    }
}

export default connect(null, mapDispatchToProps)(MatchedListLeads)