import React, { Component } from "react";
import { connect } from "react-redux";
import "./leads.css";
import "react-tabs/style/react-tabs.css";
import EditText from "react-editext";
import { baseurlImg, liveUrl, baseurl } from "../../components/BaseUrl";
import { store } from "../../store";
import Moment from "react-moment";
import parse from "html-react-parser";
import EasyEdit, { Types } from "react-easy-edit";
import API from "../../utils/API";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import EdiText from "react-editext";
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CurrencyInput from "react-currency-input-field";
import ApiCalendar from "react-google-calendar-api";
import styled from 'styled-components';
import axios from "axios";
import {
  CheckOutlined, ClockCircleOutlined,
  CloseOutlined,
  SendOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CaretRightOutlined,
  CaretLeftOutlined, CalendarOutlined, WhatsAppOutlined,
  DesktopOutlined,
  MobileOutlined, AntDesignOutlined, UserOutlined
} from "@ant-design/icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Table,
  // Tabs,
  Space,
  Button,
  Modal,
  Select,
  Drawer,
  Input,
  Avatar,
  Divider,
  DatePicker,
  Mentions,
  Checkbox,
  Spin,
  // Carousel,
  Radio,
  Row,
  Col,
  Upload,
  Image,
  Popover,
  Collapse,
  Empty,
  Timeline,
  Tag,
  Badge,
  Switch,
  Card, Tooltip
} from "antd";
const { Option } = Select;
const current_array = []
const props = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CaretRightOutlined />,
  prevArrow: <CaretLeftOutlined />,
};

const config = {
  "clientId": '8974307049-e0ug8edud9gp4ahcnah13i5kk8opivnh.apps.googleusercontent.com',
  "apiKey": 'AIzaSyAbmWx3GOaQ_HU-lD4wViBmV4kKNeWdk0Y',
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config)

const handleListingTableChange = (pagination, filters) => {
  const page = 1;
  const perPage = 5;
  const data_fetch = {
    leads_id: this.state.record_id,
    bedroom: this.new_bed,
    price: this.state.price_first,
    community: this.state.new_community,
    subcommunity: this.state.new_sub_community,
  };
  this.fetchMatchedVerificationCallLeadList(
    pagination.current,
    pagination.pageSize,
    data_fetch
  );
};

const rowMatchSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
  },
};

const { TextArea } = Input;

const rowSelectionForClient = {
  onChange: (selectedRowKeys, selectedRows) => {
    let fullname = selectedRows[0]["agent_name"];
    let contact_type = selectedRows[0]["contact_type"];
    // this.setState({ client_name: (selectedRows && selectedRows[0] && selectedRows[0]["fullname"]) ? selectedRows[0]["fullname"] : ""});
    // this.setState({ client_phone: (selectedRows && selectedRows[0] && selectedRows[0]["mobile"]) ? selectedRows[0]["mobile"] : ""});
    // this.setState({ client_contact_type: (selectedRows && selectedRows[0] && selectedRows[0]["contact_type"]) ? selectedRows[0]["contact_type"] : ""});
  },
};

const onSelectChange = (newSelectedRowKeys) => {
  this.setState({ SelectedRows: newSelectedRowKeys });
  const selectedLeadsData = this.state.leads.data.filter((item) =>
    newSelectedRowKeys.includes(item.original_id)
  );

  let oldParentLead = null;
  let maxParentLeadDate = null;
  let maxLastCallDate = null;
  let maxLastActivityDate = null;

  for (const item of selectedLeadsData) {
    const parentLeadDate = new Date(item.parent_lead_date);
    const lastCallDate = new Date(item.last_call);
    const lastActivityDate = new Date(item.last_activity);
    const lastParentLead = item.parent_lead;

    if (!oldParentLead || lastParentLead < oldParentLead) {
      oldParentLead = lastParentLead;
    }

    if (!maxParentLeadDate || parentLeadDate > maxParentLeadDate) {
      maxParentLeadDate = parentLeadDate;
    }

    if (!maxLastCallDate || lastCallDate > maxLastCallDate) {
      maxLastCallDate = lastCallDate;
    }
    if (!maxLastActivityDate || lastActivityDate > maxLastActivityDate) {
      maxLastActivityDate = lastActivityDate;
    }
  }

  // If you want to format the maxDate back to a string
  const formattedMaxParentLeadDate = maxParentLeadDate
    ? maxParentLeadDate.toISOString().slice(0, 19).replace("T", " ")
    : null;
  const formattedMaxLastCallDate = maxLastCallDate
    ? maxLastCallDate.toISOString().slice(0, 19).replace("T", " ")
    : null;
  const formattedMaxLastActivityDate = maxLastActivityDate
    ? maxLastActivityDate.toISOString().slice(0, 19).replace("T", " ")
    : null;

  // return selectedLeadsData
  const selectOptions = selectedLeadsData.map((item) => ({
    label: item.fullname,
    value: item.original_id,
  }));

  const selectedStages = selectedLeadsData.map((item) => {
    const foundStage = this.state.stages.find(
      (stage) => stage.value == item.lead_stage
    );
    return foundStage;
  });

  const allAgents = selectedLeadsData.map((record) => {
    const foundAgent = this.state.agents.find(
      (agent) => agent.value == record.agents
    );
    return foundAgent;
  });

  this.setState({
    selectedLeads: selectOptions,
    selectedStages: selectedStages,
    allAgents: allAgents,
    oldParentLead: oldParentLead,
    maxParentLeadDate: formattedMaxParentLeadDate,
    maxLastCallDate: formattedMaxLastCallDate,
    maxLastActivityDate: formattedMaxLastActivityDate,
  });
};

const rowSelection = {
  // selectedRowKeys,
  onChange: onSelectChange,
};

class AllLeadsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {

      utm_campaign : "",
      utm_source : "",
      keyword : "",
      campaignid : "",
      adgroupid : "",
      device : "",
      referral : "",

      email_subject : "",
      email_message : "",

      followup_subject: "",
      isTimer: false,
      timeRemaining: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      activePanel: null,
      selectedRowKeysTest: [],
      selectedRowKeysTestShare: [],
      isListingsLoading: false,
      isAddAnotherPhoneNumber: false,
      listings: [],
      number_of_cheque: "",
      new_to_dubai: "",
      current_live: "",
      availability_for_viewing: "",
      pre_approved: "",
      agent_data: "",
      assigned_agent: "",
      payment: "",
      buyer_type: "",
      new_bed: "",
      new_community: "",
      new_sub_community: "",
      price_first: "",
      price_second: "",
      new_move_in_date: "",
      SelectedRows: [],
      SelectedRows2: [],
      selected_data: [],
      refno_data: [],
      unitno_data: [],
      share_unitno_data: [],
      refno_search: false,
      unitno_search: false,
      exact_match_listings: [],
      comparable_match_listings: [],
      exact_match_listings_count: 0,
      filtered_lead_stage: [],
      comparable_match_listings_count: 0,
      type: 2,
      next_id: "",
      previous_id: "",
      reply: "",
      activity_agent_id: "",
      isViewViewingOpen: false,
      isViewOffersOpen: false,
      isViewMatchesOpen: false,
      isShareLinksOpen: false,
      isSharingOpen: false,
      decline_note: "",
      activityFeeds: [],
      isListingLoading: false,
      gmail: [],
      next_record: [],
      previous_record: [],
      whatsapp: [],
      skype: [],
      instagram: 0,
      twitter: 0,
      telegram: 0,
      isMatchProperties: true,
      sendTagDataAll: [],
      sendTagDataReplied: [],
      sendTagDataPending: [],
      sendTagData: [],
      agent_profile_picture: [],
      recieveTagDataAll: [],
      isViewLogsOpen: false,
      sendEvent: false,
      recieveTagDataReplied: [],
      sources: [{ label: "Direct Call", value: 22 }],
      client_name: "",
      visit_logs: [],
      offplan: false,
      referred_by: "",
      referred_to: "",
      client_phone: "",
      client_refno: "",
      client_source: "",
      client_agent: "",
      client_note: "",
      recieveTagDataPending: [],
      recieveTagData: [],
      isActivityLoading: false,
      isTagLoading: false,
      requested_viewings: [],
      currentRequest: null,
      isWhatsappLoading: false,
      remainingTime: "00:00:00",
      vendor: "",
      isViewing: false,
      isLoading: false,
      isNextLoading: false,
      isPreviousLoading: false,
      isRequestviewingOpen: false,
      role: "",
      role_profile: "",
      agent_id: "",
      addNewFilterSelect: "",
      contact_type_call_note_status: "",
      rows: [],
      sendEvent: false,
      mylistings: [],
      propertytypes: [],
      validationerrors: {},
      addleadvalidationerrors: {},
      followup_data: [],
      property_viewings: [],
      shared_links: [],
      property_offers: [],
      client_contact_type: "",
      client_data: [],
      collaborators: [],
      followups: [],
      offers: [],
      is_referred: false,
      followups_details: [],
      ref_nums: [],
      suggestions: [],
      share_links_suggestions: [],
      suggestion_value: "",
      unit_nums: [],
      userClients: [],
      selectedClients: [],
      selectedCallUsers: [],
      selectedNoteUsers: [],
      selectedWhatsappUsers: [],
      selectedEmailUsers: [],
      curr: "",
      comment: "",
      titleHasError: false,
      ismourequestOpen: "",
      sellers: [],
      sellersdata: [],

      selectedViewingUsers: [],
      selectedOfferUsers: [],
      selectedFollowupUsers: [],

      newArrSelectedAgents: [],
      selected_agent: "",
      followup_for: "",
      reminder: "",

      ref_def_nums: [],
      old_unit_num: [],
      viewings_listings: [],
      viewing_ref_no: "",
      viewing_unit_no: "",
      viewing_unit_no_for_share_link: "",
      viewing_agent: [],

      viewing_date: "",
      viewing_note: "",
      viewing_status: "",
      viewing_price: "",
      showPrice: false,
      showDate: false,
      community_from_requirement: "",
      sub_community_from_requirement: "",
      min_from_requirement: "",
      max_from_requirement: "",
      bedroom_from_requirement: "",
      property_for_from_requirement: "",
      added_date_from_requirement: "",
      contact_type_requirement: "",
      price_requirement: "",
      lead_agent: "",
      lead_community: "",
      lead_sub_community: "",
      followup_note: "",
      followup_date: "",
      leads: [],
      lead_details: {},
      lead_list_details: {},
      viewings: {},
      all: [],
      notes: [],
      agents: [],
      call_notes: [],
      whatsapp_notes: [],
      email_notes: [],
      viewing_notes: [],
      offer_notes: [],
      followup_notes: [],
      communities: [],
      sub_communities: [],
      properties: [],
      all_sub_communities: [],
      requirement_sub_communities: [],
      filters: [],
      greaterthanprice: false,
      leassthanprice: false,
      inbetweenprice: false,
      lessValue: 0,
      greaterValue: 0,
      owner_id: "",
      owner_name: "",
      owner_email: "",
      owner_mobile: "",
      community_match: "",
      community_match_add: "",
      sub_community_match: "",
      sub_community_match_add: "",
      min_budget: 0,
      max_budget: 0,
      min_budget_add: 0,
      max_budget_add: 0,
      bedroom_match: "",
      property_for_match: "",
      property_type_match: "",
      added_date_match: "",
      date_morethan_option_match: true,
      date_lessthan_option_match: false,
      date_value_match: 0,
      date_value_id_match: 1,
      bedroom_match_add: "",
      property_for_match_add: "",
      property_type_match_add: "",
      added_date_match_add: "",
      date_morethan_option_match_add: true,
      date_lessthan_option_match_add: false,
      date_value_match_add: 0,
      date_value_id_match_add: 1,
      matchs: [],
      not_matchs: [],
      owner_other_properties: [],
      requirement_min_budget: 0,
      requirement_max_budget: 0,
      requirement_lead_stage: "",
      requirement_community: "",
      requirement_sub_community: "",
      requirement_verifyedby: "",
      requirement_agent_id: "",
      requirement_move_in_date: "0000-00-00 00:00:00",
      requirement_bedroom: "",
      pin: false,
      search_owner: "",
      price_first_value_id: 1,
      price_second_value_id: 1,
      price_active_second_option: false,
      price_and_or_option: true,
      price_first_value: "",
      price_second_value: "",
      inrange: false,
      price_min: 0,
      price_max: 0,
      viewing_value: "",
      viewing_morethan_value: true,
      viewing_lessthan_value: false,
      viewing_option_value: 1,
      inrange_viewing: false,
      viewing_min_value: "",
      viewing_max_value: "",
      offer_value: "",
      offer_morthan_value: true,
      offer_lessthan_value: false,
      offer_option_value: 1,
      inrange_offer: false,
      offer_min_value: "",
      offer_max_value: "",
      date_first_value_id: 1,
      date_second_value_id: 1,
      date_active_second_option: false,
      date_and_or_option: true,
      date_first_value: "",
      date_second_value: "",
      date_morethan_option: true,
      date_lessthan_option: false,
      move_in_date_morethan_option: true,
      move_in_date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,
      last_activity_date_morethan_option: true,
      last_activity_date_lessthan_option: false,
      last_activity_date_value: "",
      last_activity_date_value_id: 1,

      last_whatsapp_date_morethan_option: true,
      last_whatsapp_date_lessthan_option: false,
      last_whatsapp_date_value: "",
      last_whatsapp_date_value_id: 1,

      last_whatsapp_received_date_morethan_option: true,
      last_whatsapp_received_date_lessthan_option: false,
      last_whatsapp_received_date_value: "",
      last_whatsapp_received_date_value_id: 1,

      last_call_morethan_option: true,
      last_call_lessthan_option: false,
      last_call_value: 0,
      last_call_value_id: 1,
      last_call_option_value: 1,

      last_match_morethan_option: true,
      last_match_lessthan_option: false,
      last_match_value: 0,
      last_match_value_id: 1,
      last_match_option_value: 1,

      move_in_date_morethan_option: true,
      move_in_date_lessthan_option: false,
      move_in_date_value: "",
      move_in_date_value_id: 1,
      move_in_date_option_value: 1,
      inrange_move_in_date: false,
      move_in_date_date_range: [],
      move_in_date_is_empty: false,

      // file filter
      documents:[],
      view_documentsArray : [], 
      document_title:"",
      document_file:null,
      documentImageShow:"",
      document_notes:[],
      // add new filter
      isFilterOpen: false,
      isFilterEditOpen: false,
      filter_id: 0,
      filter_title: "",
      contact_type_id: 0,
      community_value_id: 0,
      sub_community_value_id: 0,
      price_value_id: 0,
      price_value: "",
      bed_rooms_value_id: 1,
      bed_rooms_value: "",
      created_value_id: 0,
      created_value: "",
      stage_value_id: 0,
      stage_value: [],
      nested_stage_value: [],
      contact_types_value: [],
      furnished_types_value: [],
      property_type_filter: [],

      default_stage_value: [],
      default_contact_types_value: [],
      default_furnished_types_value: [],
      name_value_id: 1,
      name_value: "",

      refno_value_id: 1,
      refno_value: "",

      phone_value_id: 1,
      phone_value: "",

      agent_value_id: 1,
      agent_value: [],
      replied_to_whatsapp: false,
      clicked_book_viewing: [],
      viewing_booked_since_requested: false,
      viewing_date_range: [],
      viewing_outcome: [],
      number_confirmed_on_whatsapp: false,
      offer_status: [],
      deal_signed: false,
      open: false,
      record_id: "",
      view_id: 0,
      isviewingOpen: false,
      iseditviewingOpen: false,
      isOfferOpen: false,
      isfollowupOpen: false,
      isfollowupViewOpen: false,



     
      ifFinanceYes: false,

      lead_stage_filter: [
        { label: "None", value: "0" },
        { label: "New Inquiry", value: "1" },
        { label: "Working", value: "2" },
        // { label: "Hot", value: "3" },
        { label: "Follow Up", value: "4" },
        { label: "Dead", value: "5" },
        {
          value: "6",
          label: "Offer",
        },
        {
          value: "7",
          label: "Deal signed",
        },
        // { label: "Recruitment", value: "9" },
        { label: "Viewing", value: "8" },
        // { label: "No Answer", value: "10" },
        // { label: "Expired", value: "11" },
        { label: "Pond", value: "12" },
        ,
        {
          value: "13",
          label: "Offer accepted",
        },
        {
          value: "14",
          label: "Offer declined",
        },
        ,
        // {
        //   value: "22",
        //   label: "3rd party",
        // },
        {
          value: "28",
          label: "Past Client",
        },
        { label: "Spam", value: "20" }
      ],
      lead_stage_filter_agent: [
        { label: "Working", value: "2" },
        { label: "Offer", value: "6", },
        { label: "Follow Up", value: "4" },
        { label: "Dead", value: "5" },
        { label: "Spam", value: "20" },
        { label: "Pond", value: "12" }
      ],
      furnished_types: [
        {
          value: "1",
          label: "Furnished",
        },
        {
          value: "2",
          label: "Unfurnished",
        },
        {
          value: "22",
          label: "3rd party",
        },
      ],

      contact_types: [
        {
          value: "1",
          label: "Tenant",
        },
        {
          value: "2",
          label: "Buyer",
        },
        {
          value: "3",
          label: "Landlord",
        },
        {
          value: "4",
          label: "Seller",
        },
        {
          value: "5",
          label: "Landlord+Seller",
        },
        {
          value: "6",
          label: "Agent",
        },
        {
            value: "13",
            label: "Verified Agent",
          },
        {
          value: "7",
          label: "Other",
        },
        {
          value: "8",
          label: "Portal",
        },
        {
          value: "9",
          label: "Buyer/Tenant",
        },
        {
          value: "10",
          label: "Unrecognized",
        },
        {
            value: "12",
            label: "Candidate",
          },
      ],

      stages: [
        {
          value: "1",
          label: "New Inquiry",
        },
        {
          value: "2",
          label: "Working",
        },
        // {
        //   value: "3",
        //   label: "Hot",
        // },
        {
          value: "4",
          label: "Follow Up",
        },
        {
          value: "5",
          label: "Dead",
        },
        {
          value: "6",
          label: "Offer",
        },
        {
          value: "7",
          label: "Deal signed",
        },
        {
          value: "8",
          label: "Viewings",
        },
        {
          value: "9",
          label: "Recruitment",
        },
        // {
        //   value: "10",
        //   label: "No Answer",
        // },
        // {
        //   value: "11",
        //   label: "Expired",
        // },
        {
          value: "12",
          label: "Pond",
        },
        {
          value: "13",
          label: "Offer accepted",
        },
        {
          value: "14",
          label: "Offer declined",
        },
        {
          value: "15",
          label: "whatsapp reply",
        },
        {
          value: "16",
          label: "Second Viewing",
        },
        {
          value: "17",
          label: "Not Interested",
        },
        {
          value: "18",
          label: "Considering",
        },
        {
          value: "19",
          label: "Whatsapp automation",
        },
        {
          value: "20",
          label: "Whatsapp Agent",
        },
        // {
        //   value: "21",
        //   label: "Pledge",
        // },
        {
          value: "22",
          label: "3rd party",
        },

        {
          value: "25",
          label: "Verified",
        },

        {
          value: "26",
          label: "Verification call",
        },
        {
          value: "27",
          label: "Whatsapp Verified",
        },
        {
          value: "28",
          label: "Past Client",
        },
      ],

      filter_options: [
        {
          value: "1",
          label: "Contact type",
        },

        {
          value: "2",
          label: "Community / Sub community",
        },
        {
          value: "3",
          label: "Price",
        },
        {
          value: "4",
          label: "Bedrooms",
        },
        {
          value: "5",
          label: "Created",
        },
        {
          value: "6",
          label: "Stage",
        },
        {
          value: "7",
          label: "Furnished  / unfurnished",
        },
        {
          value: "8",
          label: "Agents",
        },
        {
          value: "9",
          label: "Viewings",
        },
        {
          value: "10",
          label: "Offers",
        },
        {
          value: "11",
          label: "Type",
        },
        {
          value: "12",
          label: "Last call",
        },
        {
          value: "13",
          label: "Last match",
        },
        {
          value: "14",
          label: "Move in date",
        },
        {
          value: "15",
          label: "Replied to whatsapp",
        },
        {
          value: "16",
          label: "Clicked - Book Viewing/ More Info",
        },
        {
          value: "17",
          label: "Viewing booked Since Requested",
        },
        {
          value: "18",
          label: "Viewing date",
        },
        {
          value: "19",
          label: "Viewing Outcome",
        },
        {
          value: "20",
          label: "Number confirmed on whatsapp",
        },
        {
          value: "21",
          label: "Offer Status",
        },
        {
          value: "22",
          label: "Deal Signed",
        },
      ],
      price_options: [
        {
          value: "1",
          label: "Equals",
        },
        {
          value: "2",
          label: "Not equal",
        },
        {
          value: "3",
          label: "Less than",
        },
        {
          value: "4",
          label: "Less than or equals",
        },
        {
          value: "5",
          label: "Greater than",
        },
        {
          value: "6",
          label: "Greater than or equals",
        },

        {
          value: "7",
          label: "In range",
        },
      ],

      name_filter_options: [
        {
          value: "1",
          label: "Contain",
        },
        {
          value: "2",
          label: "Not contain",
        },
        {
          value: "3",
          label: "Equals",
        },
        {
          value: "4",
          label: "Not equal",
        },
        {
          value: "5",
          label: "Start with",
        },
        {
          value: "6",
          label: "Ends with",
        },
      ],
      phone_filter_options: [
        {
          value: "1",
          label: "Contain",
        },
        {
          value: "2",
          label: "Not contain",
        },
        {
          value: "3",
          label: "Equals",
        },
        {
          value: "4",
          label: "Not equal",
        },
        {
          value: "5",
          label: "Start with",
        },
        {
          value: "6",
          label: "Ends with",
        },
      ],
      bedroom_filter_options: [
        {
          value: "1",
          label: "Equals",
        },
        {
          value: "2",
          label: "Not equal",
        },
        {
          value: "3",
          label: "Less than",
        },
        {
          value: "4",
          label: "Greater than",
        },
      ],
      agent_filter_options: [
        {
          value: "1",
          label: "Contain",
        },
        {
          value: "2",
          label: "Not contain",
        },
        {
          value: "3",
          label: "Equals",
        },
        {
          value: "4",
          label: "Not equal",
        },
        {
          value: "5",
          label: "Start with",
        },
        {
          value: "6",
          label: "Ends with",
        },
      ],
      // add notes
      call_log: "",
      note_log: "",
      whatsapp_log: "",
      email_log: "",
      followup_log: "",
      emailSubject: "",

      // Loaders
      call_loader: false,
      note_loader: false,
      whatsapp_loader: false,
      email_loader: false,
      offer_loader: false,
      viewing_loader: false,
      followUp_loader: false,

      // Error
      checkErr: false,
      // IDs
      nextId: "",
      prevId: "",
      currId: "",

      // states for disabled
      disabledNext: false,
      disabledPrev: false,

      // Farooq
      currUrl: "",
      loader: false,
      isCallLogOpen: false,
      isVerificationOpen: false,
      calltype: '',
      

      isEmailmodal : false,
      
      documentstype : '',


      // 24hours
      isdecline: false,
      isSharetoWhatsApp: false,
      // status count
      add_documents : false,
      view_documents : false,
      new_inquire: 0,
      no_answer: 0,
      offer: 0,
      viewing: 0,
      working: 0,
      expired: 0,
      pond: 0,
      second_viewing: 0,
      not_interest: 0,
      consider: 0,
      offer_accepted: 0,
      offer_rejected: 0,
      teams: [
        { value: 6, label: "Sales Team" },
        { value: 9, label: "Off Plan Team", value: 10, label: "Leasing Team" },
      ],
      selectedTeam: "",

      // Match listing drawer
      showMatchListings: false,
      splitarrays: [],
      wa_parentchildarr: [],

      isOpenTagged: false,
      isOpenMyActivityFeed: false,

      isAllActive: true,
      isRepliedActive: false,
      isPendingActive: false,
      isAllRecieveTags: true,
      isRepliedRecieveTags: false,
      isPendingRecieveTags: false,

      // Add New Lead
      isAddNewLead: false,
      filtershow: false,
      matched_leads_viewing_value_show: false,

      selectedTime: '06:00',
      selectedDate: new Date(),

      isFollowUpLoading: false,
      isViewingLoading: false,
    };
    this.textInput = React.createRef();
  }


  componentDidMount() {
    var loadScript = function (src) {
      var tag = document.createElement("script");
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName("body")[0].appendChild(tag);
    };
    loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js"
    );
    // this.updateTimeRemaining();
    // this.timerInterval = setInterval(this.updateTimeRemaining, 1000);

    const { id } = this.props.match.params;
    this.getLeadsDetail();
    this.fetchAllRequest();
    this.fetchAllAgents();
    this.fetchExactListings();
    this.fetchFollowUpDetails(id)
    this.fetchCoparableListings();

    this.addLog();
  }



  addSelectedData = (data) => {
    this.setState({ selected_data: data })
  }

  handleSuggestionsTableChange = (pagination) => {

    if (this.state.refno_search == true) {
      this.callToRef(this.state.ref_def_nums, pagination.current);
    } else {
      this.callToUnit(this.state.viewing_unit_no, pagination.current);
    }
  };

  handleVisitLogsTableChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
      columnKey: sorter.order != false ? sorter.columnKey : "",
      order: sorter.order != false ? sorter.order : "",
    });
    this.setState({ isLogLoading: true });
    const { id } = this.props.match.params;
    const data = {
      lead_id: id,
      column_key: sorter.order != false ? sorter.columnKey : "",
      order: sorter.order != false ? sorter.order : "",
      agent_filter: this.state.agent_filter
    };
    API.post(`/get-visit-logs?page=${pagination.current}&perPage=${pagination.pageSize}`, data).then(
      (response) => {
        if (response.data.success) {
          this.setState({ isLogLoading: false });
          this.setState({ visit_logs: response.data.visit_logs });
        }
      }
    );
    // this.getVisitLogs(pagination.current, pagination.pageSize);
  };
  openViewLogs = () => {
    const page = 1;
    const perPage = 10;
    this.getVisitLogs(page, perPage);
    this.setState({ isViewLogsOpen: true });
  };
  cancelViewLogs = () => {
    this.setState({ isViewLogsOpen: false });
  };

  getVisitLogs = (page, perPage) => {
    this.setState({ isLogLoading: true });
    const { id } = this.props.match.params;
    const data = {
      lead_id: id,
      column_key: this.state.columnKey,
      order: this.state.order,
      agent_filter: this.state.agent_filter
    };
    API.post(`/get-visit-logs?page=${page}&perPage=${perPage}`, data).then(
      (response) => {
        if (response.data.success) {
          this.setState({ isLogLoading: false });
          this.setState({ visit_logs: response.data.visit_logs });
        }
      }
    );
  };

  addLog = () => {
    const data = {
      lead_id: this.props.match.params
    }
    API.post(
      `/lead-detail-visit-log`,
      data
    ).then(async (response) => {

    });
  }

  fetchMatchedVerificationCallLeadList = (page, perPage, data) => {
    this.setState({ isListingsLoading: true });
    API.post(
      `/matchedVerificationCallLead?page=${page}&perPage=${perPage}`,
      data
    ).then(async (response) => {
      await response.data.listings;
      this.setState({
        listings: response.data.listings,
        isListingsLoading: false,
      });
    });
  };


  // getNavigationRecord = () => {

  // }

  saveNewCommunity = (value) => {
    this.setState({ requirement_community: value, new_community: value });
    const data = { leads_id: this.state.record_id, community: value };
    API.post("/update_community", data).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
        this.fetchExactListings();
        this.fetchCoparableListings();
      }
    });
    API.get("/sub_community/" + value).then((response) => {
      if (response.data.success)
        this.setState({
          requirement_sub_communities: response.data.sub_communities,
        });
    });
    const page = 1;
    const perPage = 5;
    const data_fetch = {
      leads_id: this.state.record_id,
      bedroom: this.state.new_bed,
      price: this.state.price_first,
      community: value,
      subcommunity: this.state.new_sub_community,
    };
    this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);
  };

  saveNewMoveInDate = (value) => {
    console.log(value)
    this.setState({
      requirement_move_in_date: value,
      new_move_in_date: value,
    });
    API.post("/update_move_in_date", {
      leads_id: this.state.record_id,
      move_in_date: value,
    }).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
      }
    });
  };

  handlePreApproved = (e) => {
    this.setState({ pre_approved: e.target.value });
  };

  handleNumberOfCheques = (e) => {
    this.setState({ number_of_cheque: e });
  };

  saveCurrentLive = (value) => {
    this.setState({ current_live: value });
  };

  handlePayment = (e) => {
    this.setState({ payment: e.target.value });
    if (e.target.value == "Finance") {
      this.setState({ ifFinanceYes: true });
    } else {
      this.setState({ ifFinanceYes: false });
    }
  };
  handleBuyerType = (e) => {
    this.setState({ buyer_type: e.target.value });
  };
  saveNewSubCommunity = (value) => {
    this.setState({
      requirement_sub_community: value,
      new_sub_community: value,
    });
    const data = { leads_id: this.state.record_id, subcommunity: value };
    API.post("/update_subcommunity", data).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
      }
    });
    const page = 1;
    const perPage = 5;
    const data_fetch = {
      leads_id: this.state.record_id,
      bedroom: this.state.new_bed,
      price: this.state.price_first,
      community: this.state.new_community,
      subcommunity: value,
    };
    this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);
  };

  saveNewBedroom = (value) => {
    this.setState({ new_bed: value, requirement_bedroom: value });
    const data = { leads_id: this.state.record_id, bedroom: value };
    API.post("/update_bedroom", data).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
          bedroom_match: value,
        });
        this.fetchExactListings();
        this.fetchCoparableListings();
      }
    });
    const page = 1;
    const perPage = 5;
    const data_fetch = {
      leads_id: this.state.record_id,
      bedroom: value,
      price: this.state.price_first,
      community: this.state.new_community,
      subcommunity: this.state.new_sub_community,
    };
    this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);
  };

  handleSplitViewing = (string, props) => {
    if (string.includes('Viewing booked')) {
      var splitarr = string.split("booked for");
      var splitarr2 = splitarr[1].split("on")
      var splitarr3 = splitarr2[1].split(" ")
      return (
        <div className='adesc-sched w-100' style={{ display: "flex", alignItems: "center" }}>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col span={24}>
              {props ? <div className="d-flex flex-row mb-2" style={{ gap: '1rem' }}>

                <p><Moment format='hh:mm A' className='font-bold font-14' style={{ whiteSpace: 'nowrap' }}>{splitarr2[1]}</Moment></p>
                <span>|</span>
                <p><Moment format='DD MMMM YYYY' style={{ whiteSpace: 'nowrap' }}>{splitarr3[2]}</Moment></p>
                <span>|</span>
                {props.status == "D" ? <p className='stat publishstat'>Draft</p>
                  : props.status == "UC" ? <p className='stat confirmedowner ml-0'>Confirmed Owner</p>
                    : props.status == "OM" ? <p className='stat offmarket ml-0'>Off-Market</p>
                      : props.status == "CTM" ? <p className='stat comingtomarket ml-0'>Coming to Market</p>
                        : props.status == "Y" ? <p className='stat published ml-0'>Published</p>
                          : props.status == "F" ? <p className='stat feeds ml-0'>Feeds</p>
                            : null
                }
              </div> : null}

            </Col>
            <Col span={12} className="mr-0 mb-0">
              <Carousel showThumbs={false} arrows style={{ width: '130px', height: "68px", overflow: 'hidden' }} effect="fade" {...props}>
                {props && props.property_images && this.getImageArray(props.property_images).map((image) => {
                  return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                })}
              </Carousel>
            </Col>



            <Col span={12}>
              {props ? <div className='lpdetails_text w-100'>

                <div className='mr-0 font-bold'>{splitarr2[0]}</div><div>{props.price && props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</div>
                <p>{props.unitno ? props.unitno + "," : null}{props.property_project ? props.property_project + "," : null}</p>
              </div> : null}
            </Col>
          </Row>



          {/* {props ?
                        <div className='lp_details w-100 m-0 pr-0' style={{border:0}}> */}
          {/* <Carousel arrows style={{width:'130px',height:"68px",  overflow:'hidden'}} effect="fade" {...props}>
                                {this.getImageArray(props.property_images).map((image) => {
                                    return <Image src={baseurlImg + "/" + image} style={{width:'100%'}} />
                                })}
                            </Carousel> */}
          {/* <div className='lpdetails_text w-100'>
                                {props.status=="D"? <p className='stat publishstat'>Draft</p>
                                :props.status=="UC"? <p className='stat confirmedowner'>Confirmed Owner</p>
                                :props.status=="OM"? <p className='stat offmarket'>Off-Market</p>
                                :props.status=="CTM"? <p className='stat comingtomarket'>Coming to Market</p>
                                :props.status=="Y"? <p className='stat published'>Published</p>
                                :props.status=="F"? <p className='stat feeds'>Feeds</p>
                                :null
                                }
                                <span className='mr-0 font-bold'>{splitarr2[0]}</span><i className="bi bi-dot"></i><span>{props.price && props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</span>
                                <p>{props.unitno?props.unitno+",":null}{props.property_project?props.property_project+",":null}</p>
                            </div> */}

          {/* </div>
                    :null} */}
        </div>
      )
    } else {
      return false
    }

  }

  onEnterReply = (e, index) => {

    const { value } = e.target;

    this.setState(prevState => {

      const reply = [...prevState.reply];

      reply[index] = value;
      this.setState({ reply: reply })
      return { reply };

    });

  }

  onClickReply = (parent_data, index, e) => {
    if (parent_data !== "" && parent_data != null) {
      const data = {
        parent_data: parent_data,
        note: this.state.reply[index] || ''
      };
      API.post("/add_lead_note_reply ", data)
        .then((response) => {
          if (response.data) {
            if (response.data.success) {
              this.setState({
                reply: "",
                // notes: response.data.notes,
                all: response.data.all,
                // call_notes: response.data.call_notes,
                // whatsapp_notes: response.data.whatsapp_notes,
                // email_notes: response.data.email_notes
              })
              API.post("/add_lead_note_reply_email ", data)
                .then((response) => {

                }).catch((err) => {
                  console.log("error", err)
                })
            }
          }
          else {
            toast.error("Something Went Wrong")
          }
        }).catch((err) => {
          toast.error("Something Went Wrong")
        })
    }
  }

  openViewViewings = () => {
    this.setState({ isViewViewingOpen: true });
  };

  openViewOffers = () => {
    this.setState({ isViewOffersOpen: true });
  };

  openViewMatches = () => {
    this.resetMatches();
    this.setState({ isViewMatchesOpen: true });
  };

  openShareLinks = () => {
    this.setState({ isShareLinksOpen: true })
  }

  resetMatches = () => {
    this.setState({
      community_match_add: "",
      sub_community_match_add: "",
      min_budget_add: "",
      max_budget_add: "",
      bedroom_match_add: "",
      property_for_match_add: "",
      property_type_match_add: "",
      added_date_match_add: "",
      date_morethan_option_match_add: true,
      date_lessthan_option_match_add: false,
      date_value_match_add: 0,
      date_value_id_match_add: 1,
      matchs: []
    });
  }

  viewOffersCancel = () => {
    this.setState({ isViewOffersOpen: false });
  };

  viewMatchesCancel = () => {
    this.setState({ isViewMatchesOpen: false });
  };

  viewViewingCancel = () => {
    this.setState({ isViewViewingOpen: false });
  };

  shareLinksCancel = () => {
    this.setState({ isShareLinksOpen: false })
  }

  getImageArray = (imageString) => {
    if (imageString == "" || imageString == null) {
      return [];
    } else {
      let images = imageString.split(",");
      return images;
    }
  }

  viewAllViewing = () => {
    window.open("/all_lead_viewings/" + this.state.record_id, "_blank");
  };

  viewAllExactMatch = () => {
    window.open("/matched_lead_lists/" + this.state.record_id, "_blank");
  };

  viewAllCompareMatch = () => {
    window.open("/all_c_match_listings/" + this.state.record_id, "_blank");
  };

  viewAllOffers = () => {
    window.open("/all_lead_viewings/" + this.state.record_id, "_blank");
  };

  handleOkOffer = () => {
    this.setState({ isOfferOpen: false })
  }
  handleCancelOffer = () => {
    this.setState({ isOfferOpen: false })
  }




  handleOkLead = () => {
    this.setState({ add_documents: false })
  }
  handleCancelLead = () => {
    this.setState({ add_documents: false })
  }



  handleCancelViewDocument = () => {
    this.setState({ view_documents: false })
  }

  handleOkViewDocument = () => {
    this.setState({ view_documents: false })
  }





  handleOfferView = (view_id, e) => {
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, isOfferOpen: true
          })
        }

      })
  }

  getPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED";
  };

  handleEditViewing(view_id, e) {
    this.setState({ isViewViewingOpen: false });
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, iseditviewingOpen: true
          })
        }

      })
  }

  handleNewViewing = (data = null) => {
    this.setState({ isviewingOpen: true, isViewViewingOpen: false });

  };

  handleNewShareLink = (data = null) => {
    this.setState({ isSharingOpen: true, isShareLinksOpen: false });

  };


  clearViewingForm = () => {
    this.setState({
      viewing_ref_no: "",
      viewing_unit_no: "",
      viewing_agent: [],
      viewing_date: '',
      ref_def_nums: [],
      agent_profile_picture: [],
      viewings_listings: [],
      refno_search: false,
      unitno_search: false,
      refno_data: [],
      unitno_data: [],
      suggestions: []
    });
  };

  handleShareLinkOk = () => {
    const unitnoData = this.state.share_unitno_data;
    const share_link_ref_no = unitnoData.map(item => item.refno);
    const data = { lead_id: this.props.match.params.id, share_link_ref_no: share_link_ref_no }
    API.post("/addsharelink", data)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isSharingOpen: false, share_unitno_data: [], share_links_suggestions: [], viewing_unit_no_for_share_link: "", shared_links: res.data.shared_links })

        }
      })

  }

  handleViewingOk = () => {

    const refnoData = this.state.refno_data;
    const unitnoData = this.state.unitno_data;
    const viewing_ref_no = refnoData.map(item => item.refno).concat(unitnoData.map(item => item.refno));
    if (
      this.state.viewing_agent.length == 0 ||
      this.state.viewing_date == ""
    ) {
      this.setState({ checkErr: true });
    } else {
      this.setState({ isViewing: true });
      this.setState({ checkErr: false });
      const data = {
        lead_id: this.state.record_id,
        lead_ref_no: this.state.lead_details.refno,
        viewing_ref_no: viewing_ref_no,
        // viewing_ref_no: this.state.viewing_ref_no,
        viewing_agent: this.state.viewing_agent,
        viewing_date: this.state.viewing_date,
        viewing_note: this.state.viewing_note,
      };
      //    console.log("data for server is",data);
      // return

      this.setState({ isViewingLoading: true })


      API.post("/add_viewing", data)
        .then((response) => {
          if (response.data.success) {
            toast.success("Viewings added successfully");
            this.setState({
              isviewingOpen: false,
              property_viewings: response.data.property_viewings,
              viewing_notes: response.data.viewing_notes,
              isViewing: false,
            });
            this.clearViewingForm();
            this.setState({ checkErr: false });
            API.get("/lead_details/" + this.state.record_id).then(
              (response) => {
                if (response.data.success)
                  this.setState({
                    requested_viewings: response.data.requested_viewings,
                  });
                this.setState({ currentRequest: null });
                this.setState({
                  lead_details: response.data.lead_details,
                  lead_list_details: response.data.lead_list_details,
                  owner_other_properties: response.data.owner_other_properties,
                  all: response.data.all,
                  requested_viewings: response.data.requested_viewings,
                  role: response.data.role.role,
                  agent_id: response.data.role.id,
                  contact_type_requirement:
                    response.data.lead_list_details.contact_type,
                  notes: response.data.notes,
                  requirement_community:
                    response.data.lead_list_details.community,
                  call_notes: response.data.call_notes,
                  whatsapp_notes: response.data.whatsapp_notes,
                  viewing_notes: response.data.viewing_notes,
                  email_notes: response.data.email_notes,
                  followup_notes: response.data.followup_notes,
                  offer_notes: response.data.offer_notes,
                  owner_id: response.data.lead_list_details.owner_id,
                  next_record: response.data.next_record ? response.data.next_record : [],
                  previous_record: response.data.previous_record ? response.data.previous_record : [],
                  gmail:
                    response.data.lead_list_details.gmail &&
                      response.data.lead_list_details.gmail != false
                      ? response.data.lead_list_details.gmail
                      : [],
                  skype:
                    response.data.lead_list_details.skype &&
                      response.data.lead_list_details.skype != false
                      ? response.data.lead_list_details.skype
                      : [],
                  whatsapp:
                    response.data.lead_list_details.whatsapp &&
                      response.data.lead_list_details.whatsapp != false
                      ? response.data.lead_list_details.whatsapp
                      : [],
                  instagram:
                    response.data.lead_list_details.instagram &&
                      response.data.lead_list_details.instagram != null &&
                      response.data.lead_list_details.instagram != ""
                      ? response.data.lead_list_details.instagram
                      : 0,
                  twitter:
                    response.data.lead_list_details.twitter &&
                      response.data.lead_list_details.twitter != null &&
                      response.data.lead_list_details.twitter != ""
                      ? response.data.lead_list_details.twitter
                      : 0,
                  telegram:
                    response.data.lead_list_details.telegram &&
                      response.data.lead_list_details.telegram != null &&
                      response.data.lead_list_details.telegram != ""
                      ? response.data.lead_list_details.telegram
                      : 0,
                  comment: response.data.lead_details.comment,
                  owner_name: response.data.lead_list_details.owner,
                  owner_email: response.data.lead_list_details.owner_email,
                  owner_mobile: response.data.lead_list_details.mobile,
                  requirement_bedroom: response.data.lead_list_details.bedroom,
                  requirement_min_budget:
                    response.data.lead_details.budget_from,
                  requirement_max_budget: response.data.lead_details.budget_to,
                  requirement_lead_stage:
                    response.data.lead_list_details.lead_stage,
                  requirement_verifyedby:
                    response.data.lead_details.verified_by,
                  requirement_community:
                    response.data.lead_list_details.community,
                  requirement_sub_community:
                    response.data.lead_list_details.subcommunity,
                  requirement_agent_id:
                    response.data.lead_list_details.lead_agent,
                  lead_community: response.data.lead_list_details.community,
                  lead_sub_community:
                    response.data.lead_list_details.subcommunity,
                  collaborators: response.data.collaborators,
                  property_viewings: response.data.property_viewings,
                  property_offers: response.data.property_offers,
                });
              }
            );
            const email_data = {
              lead_id: this.state.record_id,
              lead_ref_no: this.state.lead_details.refno,
              viewing_ref_no: this.state.viewing_ref_no,
              viewing_agent: this.state.viewing_agent,
              viewing_date: this.state.viewing_date,
              viewing_note: this.state.viewing_note,
              views_id: response.data.propertyviewing_ids,
            };
            API.post("/add_viewing_emails", email_data).then((res) => {

            });
            const postData = {
              agent_id: this.state.viewing_agent
            };
            API.post(`/get_oauth_access_token`, postData).then(
              (response) => {
                if (response.data.success) {
                  let agent_data = response.data.agent_data;
                  this.setState({ agent_data: response.data.agent_data });
                  agent_data.forEach(agent => {
                    this.createEvent(this.state.viewings_listings, this.state.viewing_date, "viewing", agent.access_token, agent.agent_name);
                  });
                }
              }
            );
            this.setState({ isViewingLoading: false })
          } else {
            toast.error("Something went wrong");
            this.setState({ isViewing: false });
            this.setState({ checkErr: false });
            this.setState({ isViewingLoading: false })
          }
        })
        .catch((err) => {
          this.setState({ isViewing: false });
          this.setState({ checkErr: false });
          this.setState({ isViewingLoading: false })
        });


    }
  };

  handleViewingCancel = () => {
    this.setState({ isviewingOpen: false });
    this.setState({ checkErr: false });
    this.setState({ currentRequest: null });
    this.clearViewingForm();
    this.setState({ isViewingLoading: false })
  };

  handleShareCancel = () => {
    this.setState({ isSharingOpen: false, share_links_suggestions: [], viewing_unit_no_for_share_link: "" });

  };



  callToRef = (typed_data, currentPage) => {
    this.setState({ isListingLoading: true });
    this.setState({ unit_nums: [] })
    if (!typed_data || typed_data == "" || typed_data == null) {
      this.setState({ ref_nums: [] });
      this.setState({ suggestions: [] });
      this.setState({ ref_def_nums: typed_data })
      return;
    }
    this.setState({ ref_def_nums: typed_data })
    const data = {
      id: typed_data,
      page: currentPage,
      perPage: 5
    }
    API.post("/get_ref_no", data).then((response) => {
      this.setState({ isListingLoading: false });
      if (response.data.success) {



        this.setState({ ref_nums: response.data.ref_nums });
        this.setState({ suggestions: response.data.ref_nums });
      }
    });
  }

  callToUnit = (typed_data, currentPage) => {
    this.setState({ isListingLoading: true });
    this.setState({ ref_nums: [] })
    if (!typed_data || typed_data == "" || typed_data == null) {
      this.setState({ unit_nums: [] });
      this.setState({ suggestions: [], viewing_unit_no: typed_data });
      return;
    }
    this.setState({ viewing_unit_no: typed_data })
    const data = {
      id: typed_data,
      page: currentPage,
      perPage: 5
    }
    API.post("/get_listing_by_unitno", data).then((response) => {
      this.setState({ isListingLoading: false });
      if (response.data.success) {





        this.setState({ unit_nums: response.data.unit_nums });
        this.setState({ suggestions: response.data.unit_nums });
      }
    });
  }

  onSearchRefNo = (e) => {
    this.callToRef(e.target.value, 1)
    this.setState({ refno_search: true });
    this.setState({ unitno_search: false });
  };

  handleSearchUintNo = (e) => {
    this.callToUnit(e.target.value, 1)
    this.setState({ refno_search: false });
    this.setState({ unitno_search: true });
  };

  handleSearchUintNoForShareLink = (e) => {
    this.setState({ isListingLoading: true });
    const data = {
      id: e.target.value,
      page: 1,
      perPage: 5
    }
    this.setState({ viewing_unit_no_for_share_link: e.target.value })
    API.post("/get_listing_by_unitno_for_share_link", data).then((response) => {

      this.setState({ isListingLoading: false });
      if (response.data.success) {





        // this.setState({ unit_nums: response.data.unit_nums });
        this.setState({ share_links_suggestions: response.data.unit_nums });
      }
    });

  }

  handleViewingAgent = (e) => {




    const agents = this.state.agents;
    const viewingAgent = e;
    const data = this.state.suggestions.data




    const unitno_data = this.state.unitno_data && this.state.unitno_data.length > 0 ? this.state.unitno_data : this.state.refno_data




    this.setState({ viewing_agent: e });

    const newselectedRows = unitno_data
      .filter(record => e.includes(record.agent_id))
      .map(record => record);


    const selectedRows = data.filter(record => e.includes(record.agent_id)).map(record => record);

    const matchingProfilePictures = agents
      .filter(agent => viewingAgent.includes(agent.value))
      .map(agent => agent.profile_picture);
    const selectedRowKeys = data
      .filter(record => e.includes(record.agent_id))
      .map(record => record.refno);

    if (this.state.refno_search == true) {

      this.setState({ refno_data: this.state.unitno_data.length > 0 || this.state.refno_data.length > 0 ? newselectedRows : selectedRows });
    } else {

      this.setState({ unitno_data: this.state.unitno_data.length > 0 || this.state.refno_data.length > 0 ? newselectedRows : selectedRows });
    }

    this.setState({ selectedRowKeysTest: selectedRowKeys });

    this.setState({ agent_profile_picture: matchingProfilePictures });
  };

  handleRemoveList = (refno, e) => {
    this.setState({
      viewings_listings: this.state.viewings_listings.filter(function (
        viewing_listing
      ) {
        return viewing_listing.refno !== refno;
      }),
    });

    this.setState({
      ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
        return ref_num.value !== refno;
      }),
    });

    this.setState({
      ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
        return ref_num !== refno;
      }),
    });
  };

  handleViewStatusCancel = () => {
    this.setState({ iseditviewingOpen: false })
  }

  handleViewStatusChange = () => {
    const data = { lead_id: this.state.record_id, view_id: this.state.view_id, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price, note: this.state.viewing_note }
    API.post("/change_viewing_status", data)
      .then((response) => {

        this.setState({
          property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
          all: response.data.all, notes: response.data.notes,
          call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
          email_notes: response.data.email_notes,
          followup_notes: response.data.followup_notes,
          offer_notes: response.data.offer_notes,
          viewing_notes: response.data.viewing_notes,
        })
      })
    toast.success("Viewing status has been successfully changed");
    this.setState({ iseditviewingOpen: false })
  }

  handleViewingPrice = (e) => {
    this.setState({ viewing_price: e.target.value })
  }

  handleViewingNote = (e) => {
    this.setState({ viewing_note: e.target.value })
  }

  handleViewStatus = (e) => {
    const status = e
    this.setState({ viewing_status: e })
    if (status == "Offer") {
      this.setState({ showPrice: true })
    } else {
      this.setState({ showPrice: false })
    }

    if (status == "Scheduled") {
      this.setState({ showDate: true })
    } else {
      this.setState({ showDate: false })
    }
  }

  handleViewingDate = (date, dateString) => {
    const formattedDate = dayjs(dateString).format('YYYY-MM-DD HH:mm:ss'); //console.log(formattedDate)
    this.setState({ viewing_date: formattedDate });
  };

  handleUnitno = (e) => {
    if (!e || e == "" || e == null) {
      API.get("/get_old_unit/" + this.state.old_unit_num).then((response) => {
        const refno = response.data.listing.value;

        this.setState({
          ref_def_nums: this.state.ref_def_nums.filter(function (value) {
            return value.refno !== refno;
          }),
        });

        // this.setState({viewings_listings: this.state.viewings_listings.filter(function(viewing_listing) {
        //   return viewing_listing.refno !== refno
        //  })});
      });
      // this.setState({ref_def_nums:[]})
      return;
    }
    this.setState({ viewing_unit_no: e });
    const data = {
      unit_num: e,
    };
    API.post("/get_all_listing_by_unitno", data).then((response) => {
      if (response.data.success) {
        const listing = response.data.all_listings;
        const unit = listing.unitno;
        let data = response.data.all_listings;
        const isFound = this.state.viewings_listings.some((element) => {
          if (element.unitno === e) {
            return true;
          }

          return false;
        });
        if (!isFound) {
          this.setState({
            viewings_listings: [
              ...this.state.viewings_listings,
              response.data.listing,
            ],
            ref_def_nums: [...this.state.ref_def_nums, data.value],
            old_unit_num: e,
            viewing_ref_no: [...this.state.viewing_ref_no, data.value],
          });
        } else {
          return;
        }
      }
    });
  };

  handleCommunityMatch = (e) => {
    this.setState({ community_match_add: e })
    if (e !== null || e !== '') {
      API.get("/sub_community/" + e)
        .then((response) => {
          if (response.data.success)
            this.setState({ sub_communities: response.data.sub_communities })
        })
    }
  }

  handleSubCommunityMatch = (e) => {
    this.setState({ sub_community_match_add: e })

  }

  handleMinBudget = (e) => {
    const value = e.target.value
    const new_value = value.replace(/,/g, "")
    this.setState({ min_budget_add: new_value })
  }

  handleMaxBudget = (e) => {
    const value = e.target.value
    const new_value = value.replace(/,/g, "")
    this.setState({ max_budget_add: new_value })
  }

  handleBeds = (e) => {
    this.setState({ bedroom_match_add: e.target.value })
  }

  handleAddedDateMatch = (e) => {
    this.setState({ added_date_match_add: e.target.value })
  }

  handlePropertForMatch = (e) => {
    this.setState({ property_for_match_add: e.target.value })
  }

  handlePropertTypeMatch = (e) => {
    this.setState({ property_type_match_add: e.target.value })
  }

  handleAllMatch = () => {
    const data = {
      lead_id: this.state.record_id,
      community_match: this.state.community_match_add,
      sub_community_match: this.state.sub_community_match_add,
      min_budget: this.state.min_budget_add, max_budget: this.state.max_budget_add,
      beds: this.state.bedroom_match_add, property_for_match: this.state.property_for_match_add,
      property_type_match: this.state.property_type_match_add,
      added_date_match: this.state.added_date_match_add
    }
    this.props.setNoMatch(data)
    window.open("/matching_listings", "_blank");
  }

  handleMatch = () => {
    console.log(this.state)
    const data = {
      //   lead_mobile: this.state.mobilenumber,
      lead_id: this.state.record_id,
      community_match: this.state.community_match_add,
      sub_community_match: this.state.sub_community_match_add,
      min_budget: this.state.min_budget_add, max_budget: this.state.max_budget_add,
      beds: this.state.bedroom_match_add, property_for_match: this.state.property_for_match_add,
      property_type_match: this.state.property_type_match_add,
      added_date_match: this.state.added_date_match_add
    }

    API.post("/match_leads", data)
      .then((response) => {

        if (response.data.success) {
          this.setState({
            matchs: response.data.match_leads,
          })
        }
      })
  }

  handleRefsno = (e) => {
    this.setState({ viewing_ref_no: e.target.value, ref_def_nums: e.target.value });
    const data = {
      ref_nums: e.target.value,
    };

    API.post("/get_all_listing_by_refnos", data).then((response) => {
      if (response.data.success) {
        this.setState({ viewings_listings: response.data.listings });
      }
    });
  };

  handleVerificationCallSubmit = () => {
    if (
      !this.state.contact_type_requirement ||
      !this.state.requirement_move_in_date ||
      !this.state.price_requirement ||
      !this.state.requirement_community
    ) {
      toast.warning("Please enter all required");
      return;
    }

    const userdata = store.getState((state) => state);
    const user_id = userdata.auth.user.id;

    if (this.state.call_log == "") {
      toast.warning("Call log cannot be empty");
      return;
    } else {
      this.setState({ call_loader: true });
      const data = {
        leads_id: this.state.record_id,
        note: this.state.call_log,
        note_type: "verify",
        users: this.state.selectedCallUsers,
        number_of_cheque: this.state.number_of_cheque,
        new_to_dubai: this.state.new_to_dubai,
        current_live: this.state.current_live,
        availability_for_viewing: this.state.availability_for_viewing,
        pre_approved: this.state.pre_approved,
        payment: this.state.payment,
        buyer_type: this.state.buyer_type,
        price_requirement: this.state.price_requirement,
        requirement_community: this.state.requirement_community,
        requirement_sub_community: this.state.requirement_sub_community,
        new_bed: this.state.new_bed,
        new_community: this.state.new_community,
        new_sub_community: this.state.new_sub_community,
        price_first: this.state.price_first,
        price_second: this.state.price_second,
        new_move_in_date: this.state.new_move_in_date,
        contact_type_requirement: this.state.contact_type_requirement,
      };

      API.post("/add_lead_note", data)
        .then((response) => {
          if (response.data.success) {
            if (this.state.lead_details.lead_stage == 10) {
              this.setState({
                requirement_lead_stage: 2,
                requirement_agent_id: user_id,
              });
            }

            this.setState({
              isVerificationOpen: false,
              all: response.data.all,
              // notes: response.data.notes,
              // call_notes: response.data.call_notes,
              // whatsapp_notes: response.data.whatsapp_notes,
              // email_notes: response.data.email_notes,
              // followup_notes: response.data.followup_notes,
              // offer_notes: response.data.offer_notes,
              // viewing_notes: response.data.viewing_notes,
              call_loader: false,
            });

            if ((this.state.selectedCallUsers && this.state.selectedCallUsers.length > 0) || (this.state.selectedNoteUsers && this.state.selectedNoteUsers.length > 0)) {
              const email_info = {
                leads_id: this.state.record_id,
                users: this.state.selectedCallUsers,
                note: this.state.call_log,
              };
              API.post("/send_lead_note_email", email_info).then((res) => {
                this.setState({
                  selectedCallUsers: [],
                  call_log: "",

                  selectedNoteUsers: [],
                });
                if (res.data.success) {
                  this.setState({
                    selectedCallUsers: [],
                    call_log: "",

                    selectedNoteUsers: [],
                  });
                }
              });
            } else {
              this.setState({
                selectedCallUsers: [],
                call_log: "",

                selectedNoteUsers: [],
              });
            }
          } else {
            this.setState({ call_loader: false });
          }
        })
        .catch(() => {
          this.setState({ callloader: false });
        });
    }
  };

  handleVerificationCallLogOpen = () => {
    this.setState({ isVerificationOpen: true });
  };
  handleVerificationCallLogClose = () => {
    this.setState({ isVerificationOpen: false });
  };

  fetchAllAgents = () => {
    API.get("/all_agents").then((response) => {
      if (response.data.success) {
        this.setState({ agents: response.data.agents });
      }
    });
  };

  handleNoteLog = (e) => {
    this.setState({ note_log: e.target.value });
  };

  handleChangeSelect = (value) => {
    this.setState({ newArrSelectedAgents: value })
  };

  postAddCollaborator = () => {
    const data = {
      colls: this.state.newArrSelectedAgents,
      lead_id: this.state.record_id
    }
    API.post("/add_collaborate ", data)
      .then((response) => {
        if (response.data) {
          if (response.data.success) {
            this.setState({ collaborators: response.data.collaborators, newArrSelectedAgents: [] })
          }
        }

      }).catch((err) => console.log("**CHeck Error *** ", err))
  }

  onDeleteCollab = (user_id, index, id, e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.post("/delete-leads-collabs/" + id, { lead_id: this.state.lead_details.parent_lead, user_id: user_id })
          .then((response) => {
            if (response.data.success) {
              this.state.collaborators.splice(index, 1);
              this.setState({ collaborators: this.state.collaborators })
              toast.success("Successfully deleted")
            }
          })
      }
    })
  }

  handleNoteSubmit = () => {
    if (this.state.note_log == "") {
      toast.warning("Note log cannot be empty");
    } else {
      this.setState({ note_loader: true });
      const data = {
        leads_id: this.state.record_id,
        note: this.state.note_log,
        note_type: "note",
        users: this.state.selectedNoteUsers,
      };


      API.post("/add_lead_note", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              all: response.data.all,
              // notes: response.data.notes,
              // call_notes: response.data.call_notes,
              // whatsapp_notes: response.data.whatsapp_notes,
              // email_notes: response.data.email_notes,
              // followup_notes: response.data.followup_notes,
              // offer_notes: response.data.offer_notes,
              // viewing_notes: response.data.viewing_notes,
              note_loader: false,
              note_log: "",
            });
            if (this.state.selectedNoteUsers && this.state.selectedNoteUsers.length > 0) {
              const email_info = {
                leads_id: this.state.record_id,
                users: this.state.selectedNoteUsers,
                note: this.state.note_log,
              };
              API.post("/send_lead_note_email", email_info).then((res) => {
                if (res.data.success) {
                  this.setState({
                    selectedCallUsers: [],
                    note_log: "",

                    selectedNoteUsers: [],
                  });
                }
              });
            }
          } else {
            this.setState({ note_loader: false });
          }
        })
        .catch((err) => {
          this.setState({ note_loader: false });
        });
    }
  };

  handleWhatsAppSubmit = () => {
    if (this.state.note_log == "") {
      toast.warning("Note log cannot be empty");
    } else {
      this.setState({ note_loader: true });
      const data = {
        leads_id: this.state.record_id,
        note: this.state.note_log,
        note_type: "whatsapp",
        users: this.state.selectedNoteUsers,
      };


      API.post("/add_lead_note", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              all: response.data.all,
              // notes: response.data.notes,
              // call_notes: response.data.call_notes,
              // whatsapp_notes: response.data.whatsapp_notes,
              // email_notes: response.data.email_notes,
              // followup_notes: response.data.followup_notes,
              // offer_notes: response.data.offer_notes,
              // viewing_notes: response.data.viewing_notes,
              note_loader: false,
              note_log: "",
            });
            if (this.state.selectedNoteUsers && this.state.selectedNoteUsers.length > 0) {
              const email_info = {
                leads_id: this.state.record_id,
                users: this.state.selectedNoteUsers,
                note: this.state.note_log,
              };
              API.post("/send_lead_note_email", email_info).then((res) => {
                if (res.data.success) {
                  this.setState({
                    selectedCallUsers: [],
                    note_log: "",

                    selectedNoteUsers: [],
                  });
                }
              });
            }
          } else {
            this.setState({ note_loader: false });
          }
        })
        .catch((err) => {
          this.setState({ note_loader: false });
        });
    }
  };


  fetchAllRequest = () => {
    API.get("/community").then((response) => {
      if (response.data.success) {
        this.setState({ communities: response.data.communities });
      }
    });

    API.get("/all_sub_community").then((response) => {
      if (response.data.success) {
        this.setState({ sub_communities: response.data.sub_communities });
      }
    });

    API.get("/all_sub_community").then((response) => {
      if (response.data.success) {
        this.setState({ requirement_sub_communities: response.data.sub_communities });
      }
    });
    API.get("/all_properties").then((response) => {
      if (response.data.success) {
        this.setState({ properties: response.data.properties });
      }
    });
    API.get("/propertytypes").then((response) => {
      if (response.data.success) {
        this.setState({ propertytypes: response.data.propertytypes });
      }
    });

    API.get("/all_client_users").then((response) => {
      if (response.data.success) {
        const data = response.data.users;
        for (let i = 0; i < data.length; i++) {
          const num = data[i].id;
          data[i].key = num.toString();
        }
        this.setState({ userClients: data });
      }
    });
  };

  saveMoveInDate = (value) => {
    // if(value=null) { this.setState({requirement_move_in_date : "0000-00-00 00:00:00"}) }

    this.setState({
      leads_id: this.state.record_id,
      requirement_move_in_date: value,
    });
    API.post("/update_move_in_date", {
      leads_id: this.state.record_id,
      move_in_date: value,
    }).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
      }
    });
  };

  fetchExactListings = () => {
    // let id = this.state.record_id;
    const { id } = this.props.match.params;
    API.get("/matchedleadlists/" + id).then((res) => {
      this.setState({ isMatchProperties: false });
      if (res.data.success) {
        this.setState({
          exact_match_listings: res.data.listings,
          exact_match_listings_count: res.data.listings_count,
        });
      }
    });
  };

  fetchCoparableListings = () => {
    // let id = this.state.record_id;
    const { id } = this.props.match.params;
    API.get("/comparable_matchedleadlists/" + id).then((res) => {
      if (res.data.success) {
        this.setState({
          comparable_match_listings: res.data.listings,
          comparable_match_listings_count: res.data.listings_count,
        });
      }
    });
  };

  addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  fAmountSaperator = (value) => {
    return this.addCommas(this.removeNonNumeric(value));
  };

  saveBedroom = (value) => {
    const data = { leads_id: this.state.record_id, bedroom: value };
    API.post("/update_bedroom", data).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
          bedroom_match: value,
        });
        this.fetchExactListings();
        this.fetchCoparableListings();
      }
    });
  };

  saveSubCommunity = (value) => {
    this.setState({ requirement_sub_community: value });
    const data = { leads_id: this.state.record_id, subcommunity: value };
    API.post("/update_subcommunity", data).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
      }
    });
  };

  onChangeCallNote = (value) => {

    this.setState({ call_log: value });
  };

  onSelectCallMentions = (option) => {
    this.setState({
      selectedCallUsers: [...this.state.selectedCallUsers, option],
    });
  };

  handleNoAnswer = () => {
    const data = { leads_id: this.state.record_id };
    API.post("/lead_add_call_no_answer", data).then((response) => {
      if (response.data.success) {
        this.setState({ requirement_lead_stage: 10 });
        Swal.fire({
          // title: "Create MOU Request!",

          text: "Would you like to make Follow up?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({ isfollowupOpen: true });
          }
        });
        this.setState({
          all: response.data.all,
          call_notes: response.data.call_notes,
          notes: response.data.notes,
          requirement_lead_stage: 10,
        });
      }
    });
  };


 


  handleCallLogModal = (val) => {
    return () => {
      this.setState({ isCallLogOpen: true, calltype: val });
    };
  };



  EmailMessage = (value) => {
    this.setState({ email_message: value });
  }


  EmailSubject = (e) => {
    this.setState({ email_subject: e.target.value });
  }


  handleEmail = (val) => {
    return () => {
      this.setState({ isEmailmodal: true, });
    };
  }


  handleDocumentsModal = (val) => {
    return () => {
      this.setState({ add_documents: true, documentstype: val });
    };
  };

   handleViewDocumentsModal = (val) => {
    return () => {

      const { id } = this.props.match.params;
       this.setState({ loader: true });
       API.get(`get_leads_documents/${id}`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            view_documents: true,
            viewdocumentstype: val,
            view_documentsArray: response.data.documents, 
            loader: true 
            
          });
        } else {
          toast.error("Failed to fetch documents");
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
        toast.error("Error fetching documents");
        this.setState({ loader: false }); 
      });
      // this.setState({ view_documents: true, viewdocumentstype: val });
    };
  };



  handleCallLogModalCancel = () => {
    this.setState({ isCallLogOpen: false });
  };

  handleOffplan = (e) => {
    this.setState({ offplan: !this.state.offplan });
  };

  handleCallSubmit = () => {
    if (!this.state.offplan) {
      if (
        !this.state.contact_type_requirement ||
        !this.state.requirement_move_in_date ||
        !this.state.price_requirement ||
        !this.state.requirement_community
      ) {
        toast.warning("Please enter all required");
        return;
      }
    }

    const userdata = store.getState((state) => state);
    const user_id = userdata.auth.user.id;

    if (this.state.call_log == "") {
      toast.warning("Call log cannot be empty");
      return;
    } else {
      this.setState({ call_loader: true });
      const data = {
        leads_id: this.state.record_id,
        note: this.state.call_log,
        note_type: "phone",
        users: this.state.selectedCallUsers,
      };

      API.post("/add_lead_note", data)
        .then((response) => {
          if (response.data.success) {
            if (this.state.lead_details.lead_stage == 10) {
              this.setState({
                requirement_lead_stage: 2,
                requirement_agent_id: user_id,
              });
            }

            this.setState({
              all: response.data.all,
              // notes: response.data.notes,
              // call_notes: response.data.call_notes,
              // whatsapp_notes: response.data.whatsapp_notes,
              // email_notes: response.data.email_notes,
              // followup_notes: response.data.followup_notes,
              // offer_notes: response.data.offer_notes,
              // viewing_notes: response.data.viewing_notes,
              // call_log: "",
              call_loader: false,
              // selectedCallUsers: [],
            });

            Swal.fire({
              title: "Do you want to add follow up?",
              icon: "questions",
              width: "400px",
              className: "followuconfirm",
              showCancelButton: true,
              confirmButtonColor: "#151515",
              cancelButtonColor: "#FFF",
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ isfollowupOpen: true });
                this.setState({ isCallLogOpen: false });
              } else {
                this.setState({ isCallLogOpen: false });
              }
            });

            let status =
              this.state.contact_type_call_note_status != ""
                ? this.state.contact_type_call_note_status
                : 2;

            const data = { leads_id: this.state.record_id, lead_stage: status };
            API.post("/update_lead_stage", data)
              .then((response) => {
                if (response.data.success) {
                  this.setState({
                    all: response.data.all,
                    notes: response.data.notes,
                  });
                  this.setState({ contact_type_call_note_status: "" });
                  this.setState({ requirement_lead_stage: status });
                }
              })
              .catch((err) => console.log(err));
            if (this.state.selectedNoteUsers && this.state.selectedNoteUsers.length > 0) {
              const email_info = {
                leads_id: this.state.record_id,
                users: this.state.selectedNoteUsers,
                note: this.state.call_log,
              };
              API.post("/send_lead_note_email", email_info).then((res) => {
                this.setState({
                  selectedCallUsers: [],
                  call_log: "",

                  selectedNoteUsers: [],
                });
                if (res.data.success) {
                  this.setState({
                    selectedCallUsers: [],
                    call_log: "",

                    selectedNoteUsers: [],
                  });
                }
              });
            }
          } else {
            this.setState({ call_loader: false });
          }
        })
        .catch(() => {
          this.setState({ callloader: false });
        });
    }
  };


   






  onChangeNoteNote = (value) => {
    this.setState({ note_log: value });
  };

  onSelectNoteMentions = (option) => {
    this.setState({
      selectedNoteUsers: [...this.state.selectedNoteUsers, option],
    });
  };

  onChangeWhatsappNote = (value) => {
    this.setState({ whatsapp_log: value });
  };

  onSelectWhatsappMentions = (option) => {
    this.setState({
      selectedWhatsappUsers: [...this.state.selectedWhatsappUsers, option],
    });
  };

  onChangeEmailNote = (value) => {
    this.setState({ email_log: value });
  };

  onSelectEmailMentions = (option) => {
    this.setState({
      selectedEmailUsers: [...this.state.selectedEmailUsers, option],
    });
  };

  onChangeViewingNote = (value) => {
    this.setState({ viewing_log: value });
  };

  onSelectViewingMentions = (option) => {
    this.setState({
      selectedViewingUsers: [...this.state.selectedViewingUsers, option],
    });
  };
  onChangeOfferNote = (value) => {
    this.setState({ offer_log: value });
  };

  onSelectOfferMentions = (option) => {
    this.setState({
      selectedOfferUsers: [...this.state.selectedOfferUsers, option],
    });
  };
  onChangeFollowupNote = (value) => {
    this.setState({ followup_log: value });
  };

  onSelectFollowupMentions = (option) => {
    this.setState({
      selectedFollowupUsers: [...this.state.selectedFollowupUsers, option],
    });
  };

  saveCommunity = (value) => {
    this.setState({ requirement_community: value });
    const data = { leads_id: this.state.record_id, community: value };
    API.post("/update_community", data).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
        this.fetchExactListings();
        this.fetchCoparableListings();
      }
    });
    if (value !== null || value !== "") {
      API.get("/sub_community/" + value).then((response) => {
        if (response.data.success)
          this.setState({
            requirement_sub_communities: response.data.sub_communities,
          });
      });
    }
  };

  savePrice = (value) => {
    const value_ = value;
    const new_value = value_.replace(/,/g, "");

    this.setState({
      price_requirement: value,
      min_budget: new_value,
    });
    if (new_value > 0) {
      const data = {
        leads_id: this.state.record_id,
        price_requirement: new_value,
      };
      API.post("/update_price", data).then((response) => {
        if (response.data.success) {
          this.setState({
            all: response.data.all,
            notes: response.data.notes,
          });
          this.fetchExactListings();
          this.fetchCoparableListings();
        }
      });
    }
  };

  saveAgent = (value) => {
    const data = { leads_id: this.state.record_id, lead_agent: value };
    API.post("/update_agent", data).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
      }
    });
  };



  handleEmailHideModal = () => {
    this.setState({
      isEmailmodal : false, 
    })
  }



  SendEmail = (value) => {

    const { email_subject, email_message,record_id, owner_email } = this.state;
      if (!email_subject || !email_message) {
        toast.error('Subject and message cannot be empty!');
        return; 
      }
    //  Get login user data no mater its agents and its a admin
     const userdata = store.getState((state) => state);
      const user_id  = userdata.auth.user.id;
      const username = userdata.auth.user.name;
      const jobtitle = userdata.auth.user.jobtitle;
      const data = {
        to          : owner_email,
        subject     : email_subject,
        message     : email_message,
        leads_id    : record_id,
        username    : username,
        designation : jobtitle,
        user_id     : user_id,
      };
      
     API.post("/send_google_email", data).then((response) => {
         if(response.data.status === "success"){
          toast.success('Email Send Successfully');
          this.setState({ isEmailmodal : false});
         }
         if(response.data.status === "error"){
          toast.error("You don't have permission to send emails.");
            this.setState({ isEmailmodal : false});
         }
     });
  } 



  saveContactType = (value) => {
    this.setState({ contact_type_requirement: value });
    this.setState({
      leads_id: this.state.record_id,
    });
    API.post("/update_contact_type", {
      leads_id: this.state.record_id,
      contact_type: value,
    }).then((response) => {
      if (response.data.success) {
        if (value == 1) {
          this.setState({ contact_type_requirement: 1 });
        } else {
          this.setState({ contact_type_requirement: value });
        }
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
      }
    });
  };

  saveVerifier = (value) => {
    this.setState({
      leads_id: this.state.record_id,
      verifier_by: value,
    });
    API.post("/update_verifier_by", {
      leads_id: this.state.record_id,
      verifier_by: value,
    }).then((response) => {
      if (response.data.success) {
        this.setState({
          all: response.data.all,
          notes: response.data.notes,
        });
      }
    });
  };

  handleAgentFilter = (e) => {
    this.setState({ agent_filter: e })
    const page = 1;
    const perPage = 10;
    this.setState({ isLogLoading: true });
    const { id } = this.props.match.params;
    const data = {
      lead_id: id,
      column_key: this.state.columnKey,
      order: this.state.order,
      agent_filter: e
    };
    API.post(`/get-visit-logs?page=${page}&perPage=${perPage}`, data).then(
      (response) => {
        if (response.data.success) {
          this.setState({ isLogLoading: false });
          this.setState({ visit_logs: response.data.visit_logs });
        }
      }
    );
    // this.getVisitLogs(page, perPage);
  }

  saveLeadStage = (value) => {
    const data = { leads_id: this.state.record_id, lead_stage: value };
    if (value == 5) {
      Swal.fire({
        title: "Lead Stage Status Update",
        text: "Please provide a note about the status being changed to Dead.",
        input: "text",
        className: "deadstage-wrap",
        inputAttributes: { autocapitalize: "off" },
        showCancelButton: false,
        confirmButtonText: "Add note",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "<p className='confirmedstat'>Status changed to Dead lead.</p>"
          );
          API.post("/update_lead_stage", data)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  all: response.data.all,
                  notes: response.data.notes,
                });
              }
            })
            .catch((err) => console.log(err));
          const confirm_data = {
            leads_id: this.state.record_id,
            confirm_message: result.value,
          };

          API.post("/add_confirm_note", confirm_data)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  all: response.data.all,
                  notes: response.data.notes,
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    } else if (value == 2 || value == 3 || value == 4) {
      this.setState({ contact_type_call_note_status: value });
      this.setState({ isCallLogOpen: true });
    } else {
      API.post("/update_lead_stage", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              all: response.data.all,
              notes: response.data.notes,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  goToPrev = () => {
    const stateData = store.getState((state) => state);
    const leads = stateData.navigation.navigation;
    const currentIndex = leads.findIndex((lead) => lead.parent_lead === this.state.lead_details.id);
    const previousIndex = currentIndex - 1;
    const previousLead = previousIndex >= 0 ? leads[previousIndex] : null;
    this.getNewRecord((previousLead && previousLead.parent_lead) ? previousLead.parent_lead : null);
  }

  goToNext = () => {
    const stateData = store.getState((state) => state);
    const leads = stateData.navigation.navigation;
    const currentIndex = leads.findIndex((lead) => lead.parent_lead === this.state.lead_details.parent_lead);
    const nextIndex = currentIndex + 1;
    const nextLead = nextIndex < leads.length ? leads[nextIndex] : null;
    this.getNewRecord((nextLead && nextLead.parent_lead) ? nextLead.parent_lead : null);
  }

  getNewRecord = (id) => {
    let newUrl;
    if (this.props.match.params.id) {
      newUrl = window.location.href.replace(/leads.*/, `leads`);
    }
    else {
      newUrl = window.location.href.replace(/leads.*/, `leads`);
    }
    this.setState({ currUrl: newUrl })
    window.history.replaceState(null, "Leads Id", newUrl);
    this.setState({ isLoading: true });
    this.setState({ record_id: id });
    API.get("/lead_details/" + id)
      .then((response) => {
        if (response.data.success) {
          // return item ? item.title : 'Title not found';
          this.setState({ isLoading: false });
          this.setState({ currentRequest: null });
          this.setState({
            lead_details: response.data.lead_details,
            record_id: response.data.lead_details.id,
            nextId: response.data.lead_details.id,
            lead_list_details: response.data.lead_list_details,
            owner_other_properties: response.data.owner_other_properties,
            all: response.data.all,
            role: response.data.role.role,
            agent_id: response.data.role.id,
            requested_viewings: response.data.requested_viewings,
            notes: response.data.notes,
            requirement_community: response.data.lead_list_details.community,
            community_match: response.data.lead_list_details.community,
            sub_community_match: response.data.lead_list_details.subcommunity,
            min_budget: response.data.lead_details.budget_from,
            max_budget: response.data.lead_details.budget_to,
            bedroom_match: response.data.lead_list_details.bedroom,
            comment: response.data.lead_details.comment,
            referred_by:
              response.data.lead_list_details.referred_by != "" &&
                response.data.lead_list_details.referred_by != null &&
                response.data.lead_list_details.referred_by != undefined
                ? response.data.lead_list_details.referred_by
                : response.data.lead_list_details.lead_agent,
            referred_to: response.data.lead_list_details.referred_to,
            is_referred:
              response.data.lead_list_details.referred_to == null ||
                response.data.lead_list_details.referred_to == ""
                ? false
                : true,
            call_notes: response.data.call_notes,
            whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes,
            followup_notes: response.data.followup_notes,
            offer_notes: response.data.offer_notes,
            viewing_notes: response.data.viewing_notes,
            owner_id: response.data.lead_list_details.owner_id,
            next_record: response.data.next_record ? response.data.next_record : [],
            previous_record: response.data.previous_record ? response.data.previous_record : [],
            gmail:
              response.data.lead_list_details.gmail &&
                response.data.lead_list_details.gmail != false
                ? response.data.lead_list_details.gmail
                : [],
            skype:
              response.data.lead_list_details.skype &&
                response.data.lead_list_details.skype != false
                ? response.data.lead_list_details.skype
                : [],
            whatsapp:
              response.data.lead_list_details.whatsapp &&
                response.data.lead_list_details.whatsapp != false
                ? response.data.lead_list_details.whatsapp
                : [],
            instagram:
              response.data.lead_list_details.instagram &&
                response.data.lead_list_details.instagram != null &&
                response.data.lead_list_details.instagram != ""
                ? response.data.lead_list_details.instagram
                : 0,
            twitter:
              response.data.lead_list_details.twitter &&
                response.data.lead_list_details.twitter != null &&
                response.data.lead_list_details.twitter != ""
                ? response.data.lead_list_details.twitter
                : 0,
            telegram:
              response.data.lead_list_details.telegram &&
                response.data.lead_list_details.telegram != null &&
                response.data.lead_list_details.telegram != ""
                ? response.data.lead_list_details.telegram
                : 0,
            owner_name: response.data.lead_list_details.owner,
            owner_email:
              response.data.lead_list_details &&
                response.data.lead_list_details.owner_email
                ? response.data.lead_list_details.owner_email
                : "",
            owner_mobile:
              response.data.lead_list_details &&
                response.data.lead_list_details.mobile
                ? response.data.lead_list_details.mobile
                : "",
            requirement_bedroom: response.data.lead_list_details.bedroom,
            requirement_min_budget: response.data.lead_details.budget_from,
            requirement_max_budget: response.data.lead_details.budget_to,
            requirement_verifyedby: response.data.lead_details.verified_by,
            requirement_lead_stage: response.data.lead_list_details.lead_stage,
            pin:
              response.data.lead_details.pinned == 1 ||
                response.data.lead_details.pinned == 2
                ? true
                : false,
            requirement_community: response.data.lead_list_details.community,
            requirement_sub_community:
              response.data.lead_list_details.subcommunity,
            requirement_agent_id: response.data.lead_list_details.lead_agent,
            lead_community: response.data.lead_list_details.community,
            lead_sub_community: response.data.lead_list_details.subcommunity,
            contact_type_requirement:
              response.data.lead_list_details.contact_type,
            price_requirement: response.data.lead_list_details.lead_price,
            price_first: response.data.lead_list_details.lead_price,
            collaborators: response.data.collaborators,
            requirement_move_in_date:
              response.data.lead_list_details.move_in_date,
            property_viewings: response.data.property_viewings,
            property_offers: response.data.property_offers,
            move_in_date: response.data.lead_list_details.move_in_date,
            new_move_in_date: response.data.lead_list_details.move_in_date,
            new_bed: response.data.lead_list_details.bedroom,
            new_community: response.data.lead_list_details.community,
            new_sub_community: response.data.lead_list_details.subcommunity,
            shared_links: response.data.shared_links,
            loader: false,
          });

          // this.fetchExactListings();
          // this.fetchCoparableListings();
          const page = 1;
          const perPage = 5;
          const data = {
            leads_id: this.state.record_id,
            bedroom: response.data.lead_list_details.bedroom,
            price: response.data.lead_list_details.lead_price,
            community: response.data.lead_list_details.community,
            subcommunity: response.data.lead_list_details.subcommunity,
          };
          this.fetchMatchedVerificationCallLeadList(page, perPage, data);
          // id added
          this.setState({ currUrl: window.location.href })
          window.history.replaceState(null, "Leads Id", window.location.href + '/' + response.data.lead_details.id)

          const lead_stage = response.data.lead_list_details.lead_stage;
          if (lead_stage == 1) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "1") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "1") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "New Inquiry",
              value: "1",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });

            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 2) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "2") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "2") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Working",
              value: "2",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 3) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "3") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "3") {
                  return true;
                }

                return false;
              }
            );
            const item = {
              label: "Hot",
              value: "3",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 4) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "4") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "4") {
                  return true;
                }

                return false;
              }
            );
            const item = {
              label: "Follow Up",
              value: "4",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 5) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "5") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "5") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Dead",
              value: "5",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 6) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "6") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "6") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Offer",
              value: "6",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 7) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "7") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "7") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Deal signed",
              value: "7",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 8) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "8") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "8") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Viewings",
              value: "8",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 9) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "9") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "9") {
                  return true;
                }

                return false;
              }
            );
            const item = {
              label: "Recruitment",
              value: "9",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 10) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "10") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "10") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "No Answer",
              value: "10",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 11) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "11") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "11") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Expired",
              value: "11",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 12) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "12") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "12") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Pond",
              value: "12",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 13) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "13") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "13") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Offer accepted",
              value: "13",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 14) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "14") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "14") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Offer declined",
              value: "14",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          }

          if (this.state.requested_viewings != []) {
            setInterval(() => {
              this.getRemainingTime(
                this.state.requested_viewings &&
                this.state.requested_viewings[0] &&
                this.state.requested_viewings[0].added_date
              );
            }, 1000);
          }
        } else {
          toast.error("No Record Found");
          this.setState({
            loader: false,
          });
        }
        // this.setState({ currUrl: window.location.href });
        // window.history.replaceState(
        //   null,
        //   "Leads Id",
        //   window.location.href + "/" + id
        // );

        API.get("/all_agents").then((response) => {
          if (response.data.success) {
            this.setState({ agents: response.data.agents });
          }
        });

        this.fetchFollowUp(id);
        const community = response.data.lead_list_details.community;
        if (community == "" || community == null) {

        } else {
          API.get("/sub_community/" + community).then((response) => {
            if (response.data.success)
              this.setState({
                requirement_sub_communities: response.data.sub_communities,
              });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
          isLoading: false,
        });
      });
  };

  handleSaveOwnerName = (value) => {
    const data = {
      owner_id: this.state.owner_id,
      owner_name: value,
    };
    API.post("/update_owner_name", data).then((response) => {

    });
  };




  // handelDocuments = (val) => {

  //    this.setState({ add_documents: true })
  // };



  changeReferred_by = (e) => {
    this.setState({ referred_by: e });
  };
  changeReferred_to = (e) => {
    this.setState({ referred_to: e });
  };
  onSaveRef = () => {
    if (
      this.state.referred_by == "" ||
      this.state.referred_by == null ||
      this.state.referred_by == undefined
    ) {
      toast.error("Referred by is required");
    } else if (
      this.state.referred_to == "" ||
      this.state.referred_to == null ||
      this.state.referred_to == undefined
    ) {
      toast.error("Referred to is required");
    } else {
      const data = {
        referred_to: this.state.referred_to,
        referred_by: this.state.referred_by,
        lead_id: this.state.record_id,
      };
      API.post("/lead_referred", data)
        .then((response) => {
          if (response.data.success) {
            let new_agent = this.state.referred_to;
            this.setState({ requirement_agent_id: new_agent.toString() });
            toast.success("Lead is successfully referred");
          }
        })
        .catch((e) => {
          toast.error("Something Went Wrong");
        });
    }
  };

  onChangePin = (e) => {
    const data = {
      leads_id: this.state.record_id,
      pin: e.target.checked == true ? 1 : 0,
    };
    API.post("/update_pin", data).then((res) => {
      if (res.data.success) {
        this.setState({ pin: !this.state.pin });
      } else {
        toast.error("More than 5");
      }
    });
  };

  handleSaveOwnerEmail = (value) => {
    const data = {
      owner_id: this.state.owner_id,
      owner_email: value,
    };
    API.post("/update_owner_email", data).then((response) => {

    });
  };

  getCurrentTimeStamp = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const unixTime = new Date(timestamp).getTime() / 1000;
    return unixTime;
  };

  handleAddVerification = () => {
    const data = { leads_id: this.state.record_id };
    API.post("/add_verification_by_lead_id", data).then((res) => {
      if (res.data.success) {
        toast.success("Verification call added successfully");
        const lead_details = this.state.lead_details;
        lead_details.verifier = "verifier";
        this.setState({ lead_details: lead_details });
      }
    });
  };

  handleRemoveVerification = () => {
    const data = { leads_id: this.state.record_id };
    API.post("/remove_verification_by_lead_id", data).then((res) => {
      if (res.data.success) {
        toast.success("Verification call removed successfully");
        const lead_details = this.state.lead_details;
        lead_details.verifier = null;
        this.setState({ lead_details: lead_details });
      }
    });
  };

  getRemainingTime = (added_date) => {
    const dateObj = new Date(added_date);
    const unixTime = dateObj.getTime() / 1000;
    const addedDateUnixTime = unixTime + 28 * 60 * 60;
    let currentDateUnixTime = this.getCurrentTimeStamp();
    let timeDifference = addedDateUnixTime - currentDateUnixTime;
    if (timeDifference <= 0) {
      this.setState({ remainingTime: "00:00:00" });
    } else {
      const hours = Math.floor(timeDifference / 3600);
      const minutes = Math.floor((timeDifference % 3600) / 60);
      const seconds = timeDifference % 60;
      const formattedDuration = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
      this.setState({ remainingTime: formattedDuration });
    }
  };

  getLeadsDetail = () => {
    const userdata = store.getState((state) => state);
    const user_id = userdata.auth.user.id;
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    this.setState({ record_id: id, selected_agent: user_id });
    API.get("/lead_details/" + id)
      .then((response) => {
        
          // console.log(response);
        if (response.data.success) {
          this.setState({ isLoading: false });
          this.setState({ currentRequest: null });
          this.setState({
            lead_details: response.data.lead_details,
            lead_list_details: response.data.lead_list_details,
            owner_other_properties: response.data.owner_other_properties,
            all: response.data.all,
            role: response.data.role.role,
            agent_id: response.data.role.id,
            requested_viewings: response.data.requested_viewings,
            notes: response.data.notes,
            requirement_community: response.data.lead_list_details.community,
            community_match: response.data.lead_list_details.community,
            sub_community_match: response.data.lead_list_details.subcommunity,
            min_budget: response.data.lead_details.budget_from,
            max_budget: response.data.lead_details.budget_to,
            bedroom_match: response.data.lead_list_details.bedroom,
            comment: response.data.lead_details.comment,
            //add more leads add new colum in database also

            utm_campaign : response.data.lead_details.utm_campaign,
            utm_source : response.data.lead_details.utm_source,
            keyword : response.data.lead_details.keyword,
            campaignid : response.data.lead_details.campaignid,
            adgroupid : response.data.lead_details.adgroupid,
            device : response.data.lead_details.device,
            referral : response.data.lead_details.referral,

            referred_by:
              response.data.lead_list_details.referred_by != "" &&
                response.data.lead_list_details.referred_by != null &&
                response.data.lead_list_details.referred_by != undefined
                ? response.data.lead_list_details.referred_by
                : response.data.lead_list_details.lead_agent,
            referred_to: response.data.lead_list_details.referred_to,
            is_referred:
              response.data.lead_list_details.referred_to == null ||
                response.data.lead_list_details.referred_to == ""
                ? false
                : true,
            call_notes: response.data.call_notes,
            whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes,
            followup_notes: response.data.followup_notes,
            offer_notes: response.data.offer_notes,
            viewing_notes: response.data.viewing_notes,
            owner_id: response.data.lead_list_details.owner_id,
            next_record: response.data.next_record ? response.data.next_record : [],
            previous_record: response.data.previous_record ? response.data.previous_record : [],
            gmail:
              response.data.lead_list_details.gmail &&
                response.data.lead_list_details.gmail != false
                ? response.data.lead_list_details.gmail
                : [],
            skype:
              response.data.lead_list_details.skype &&
                response.data.lead_list_details.skype != false
                ? response.data.lead_list_details.skype
                : [],
            whatsapp:
              response.data.lead_list_details.whatsapp &&
                response.data.lead_list_details.whatsapp != false
                ? response.data.lead_list_details.whatsapp
                : [],
            instagram:
              response.data.lead_list_details.instagram &&
                response.data.lead_list_details.instagram != null &&
                response.data.lead_list_details.instagram != ""
                ? response.data.lead_list_details.instagram
                : 0,
            twitter:
              response.data.lead_list_details.twitter &&
                response.data.lead_list_details.twitter != null &&
                response.data.lead_list_details.twitter != ""
                ? response.data.lead_list_details.twitter
                : 0,
            owner_name: response.data.lead_list_details.owner,
            owner_email:
              response.data.lead_list_details &&
                response.data.lead_list_details.owner_email
                ? response.data.lead_list_details.owner_email
                : "",
            owner_mobile:
              response.data.lead_list_details &&
                response.data.lead_list_details.mobile
                ? response.data.lead_list_details.mobile
                : "",
            requirement_bedroom: response.data.lead_list_details.bedroom,
            requirement_min_budget: response.data.lead_details.budget_from,
            requirement_max_budget: response.data.lead_details.budget_to,
            requirement_verifyedby: response.data.lead_details.verified_by,
            requirement_lead_stage: response.data.lead_list_details.lead_stage,
            pin:
              response.data.lead_details.pinned == 1 ||
                response.data.lead_details.pinned == 2
                ? true
                : false,
            requirement_community: response.data.lead_list_details.community,
            requirement_sub_community:
              response.data.lead_list_details.subcommunity,
            requirement_agent_id: response.data.lead_list_details.lead_agent,
            lead_community: response.data.lead_list_details.community,
            lead_sub_community: response.data.lead_list_details.subcommunity,
            contact_type_requirement:
              response.data.lead_list_details.contact_type,
            price_requirement: response.data.lead_list_details.lead_price,
            price_first: response.data.lead_list_details.lead_price,
            collaborators: response.data.collaborators,
            requirement_move_in_date:
              response.data.lead_list_details.move_in_date,
            property_viewings: response.data.property_viewings,
            shared_links: response.data.shared_links,
            property_offers: response.data.property_offers,
            move_in_date: response.data.lead_list_details.move_in_date,
            new_move_in_date: response.data.lead_list_details.move_in_date,
            new_bed: response.data.lead_list_details.bedroom,
            new_community: response.data.lead_list_details.community,
            new_sub_community: response.data.lead_list_details.subcommunity,
            loader: false,
          });

          // this.fetchExactListings();
          // this.fetchCoparableListings();
          const page = 1;
          const perPage = 5;
          const data = {
            leads_id: this.state.record_id,
            bedroom: response.data.lead_list_details.bedroom,
            price: response.data.lead_list_details.lead_price,
            community: response.data.lead_list_details.community,
            subcommunity: response.data.lead_list_details.subcommunity,
          };
          this.fetchMatchedVerificationCallLeadList(page, perPage, data);
          // this.getNavigationRecord();

          const lead_stage = response.data.lead_list_details.lead_stage;
          if (lead_stage == 1) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "1") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "1") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "New Inquiry",
              value: "1",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });

            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 2) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "2") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "2") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Working",
              value: "2",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 3) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "3") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "3") {
                  return true;
                }

                return false;
              }
            );
            const item = {
              label: "Hot",
              value: "3",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 4) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "4") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "4") {
                  return true;
                }

                return false;
              }
            );
            const item = {
              label: "Follow Up",
              value: "4",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 5) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "5") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "5") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Dead",
              value: "5",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 6) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "6") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "6") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Offer",
              value: "6",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 7) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "7") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "7") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Deal signed",
              value: "7",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 8) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "8") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "8") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Viewings",
              value: "8",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 9) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "9") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "9") {
                  return true;
                }

                return false;
              }
            );
            const item = {
              label: "Recruitment",
              value: "9",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 10) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "10") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "10") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "No Answer",
              value: "10",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 11) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "11") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "11") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Expired",
              value: "11",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 12) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "12") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "12") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Pond",
              value: "12",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 13) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "13") {
                  return true;
                }

                return false;
              }
            );
            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "13") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Offer accepted",
              value: "13",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          } else if (lead_stage == 14) {
            const isViewingFound = this.state.lead_stage_filter.some(
              (element) => {
                if (element.value === "14") {
                  return true;
                }

                return false;
              }
            );

            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(
              (element) => {
                if (element.value === "14") {
                  return true;
                }

                return false;
              }
            );

            const item = {
              label: "Offer declined",
              value: "14",
            };
            if (!isViewingFound)
              this.setState({
                lead_stage_filter: [...this.state.lead_stage_filter, item],
              });
            if (!isViewingFoundAgent)
              this.setState({
                lead_stage_filter_agent: [
                  ...this.state.lead_stage_filter_agent,
                  item,
                ],
              });
          }

          if (this.state.requested_viewings != []) {
            setInterval(() => {
              this.getRemainingTime(
                this.state.requested_viewings &&
                this.state.requested_viewings[0] &&
                this.state.requested_viewings[0].added_date
              );
            }, 1000);
          }
        } else {
          this.setState({
            loader: false,
          });
        }
        // id added on fetch
        this.setState({ currUrl: window.location.href });
        const param_data = this.props.match.params;
        const url = "";
        if (param_data && param_data.id) {
          url = window.location.search;
        } else {
          url = window.location.search + "/" + id;
        }

        window.history.replaceState(
          null,
          "Leads Id",
          url
        );

        API.get("/all_agents").then((response) => {
          if (response.data.success) {
            this.setState({ agents: response.data.agents });
          }
        });

        this.fetchFollowUp(id);
        const community = response.data.lead_list_details.community;
        if (community == "" || community == null) {

        } else {
          API.get("/sub_community/" + community).then((response) => {
            if (response.data.success)
              this.setState({
                requirement_sub_communities: response.data.sub_communities,
              });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
          isLoading: false,
        });
      });
  };

  onChangeAssignAgent = (e) => {
    const { id } = this.props.match.params;
    this.setState({ assigned_agent: e })
    const postData = {
      status: 3,
      lead_id: id,
      assigned_agent: e
    }
    this.callForAcceptDecline(postData);
  }

  onClickAccept = () => {
    const { id } = this.props.match.params;
    const postData = {
      status: 1,
      lead_id: id
    }

    this.callForAcceptDecline(postData);
  }

  onClickReject = () => {
    const { id } = this.props.match.params;
    const postData = {
      status: 2,
      lead_id: id
    }

    this.callForAcceptDecline(postData);
  }

  callForAcceptDecline = (postData) => {
    API.post("/accept_decline", postData)
      .then((response) => {
        if (response.data.success) {
          this.getLeadsDetail();
        }
      })
  }

  handleAddAnotherPhoneNumber = () => {
    this.setState({ isAddAnotherPhoneNumber: true });
  };

  formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-AE", {
      style: "currency",
      currency: "AED",
    }).format(amount);
  };

  handleAddAnotherPhoneNumberClose = () => {
    this.setState({ isAddAnotherPhoneNumber: false });
  };

  fetchFollowUp(leads_id) {
    API.get("/followups/" + leads_id).then((response) => {
      if (response.data.success) {
        this.setState({ followups: response.data.followups });
      }
    });
  }

  handleRequestNewViewing = (data = null) => {
    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let role_name = userdata.auth.user.role_name;
    const user_id = userdata.auth.user.id

    this.setState({ isRequestviewingOpen: true })
    this.setState({ currentRequest: data })
    this.setState({ ref_def_nums: data.refno })
    this.setState({ viewing_ref_no: [data.refno] })
    this.setState({ viewing_agent: user_id })

    if (this.state.sendEvent == false) {
      setTimeout(this.signIn(), 5000)
    }
  }

  isValidHttpUrl = (string) => {
    try {
      const url = new URL(string);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (err) {
      return false;
    }
  };
  isHTML = (content) => {
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    return htmlRegex.test(content);
  }


  getCurrentTime = (date) => {
    if (
      date != null &&
      date != "" &&
      date != undefined &&
      date != "0000-00-00 00:00:00"
    ) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateParts = date.split(" ");
      const iso8601DateString = dateParts[0] + "T" + dateParts[1] + "Z";
      const utcDate = new Date(iso8601DateString);
      const regionalDate = new Date(
        utcDate.toLocaleString("en-US", { timeZone: userTimeZone })
      );
      return regionalDate;
    }
  };

  handleSplitReq = (string) => {
    if (string && string.includes("from")) {
      var splitarr = string.split("from");
      var splitarrtwo = splitarr[1].split("To");
      return (
        <>
          <div className="d-flex" style={{ marginTop: "10px" }}>
            <p className="font-12">
              <span className="mr-2 font-bold">{splitarr[0]}</span>
              <Tag>{splitarrtwo[0]}</Tag>
              <i className="bi bi-arrow-right mr-2"></i>
              <Tag className="updated">
                {splitarr[0].includes("Move in date") ? (
                  <Moment format="DD MMMM YYYY">{splitarrtwo[1]}</Moment>
                ) : (
                  splitarrtwo[1]
                )}
              </Tag>
            </p>
          </div>
        </>
      );
    }
    if (string.includes("changed To")) {
      var splitarr = string.split("changed To");
      return (
        <div className="d-flex adesc">
          <p className="font-12">
            <span className="mr-0 font-bold">{splitarr[0]}</span>
            <Tag>{splitarr[0]}</Tag>
            <i className="bi bi-arrow-right mr-2 "></i>
            <Tag>{splitarr[1]}</Tag>
          </p>
        </div>
      );
    }
    if (string.includes("Expired")) {
      var fromStatus = string.match(/(?<=from\s+).*?(?=\s+to)/gs);
      var toStatus = string.match(/(?<=to\s+).*?(?=\s+because)/gs);
      var splitarr = string.split("following:");
      if (splitarr && splitarr[1]) {
        return (
          <>
            <div className="d-flex flex-column w-100">
              <div className="adesc">
                <span
                  className="font-bold"
                  style={{ marginBottom: "-15px", display: "flex" }}
                >
                  Reason:
                </span>
                <p className="font-12 mt-0">{parse(splitarr[1])}</p>
              </div>
              <div className="d-flex adesc-stat mt-1">
                <Tag className="pstat">{fromStatus}</Tag>
                <i className="bi bi-arrow-right mr-2 "></i>
                <Tag className="pstat expired">{toStatus}</Tag>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="d-flex flex-column w-100">
              <div className="adesc">
                <p className="font-12 mt-0">{string}</p>
              </div>
              <div className="d-flex adesc-stat mt-1">
                <Tag className="pstat">New Inquiry</Tag>
                <i className="bi bi-arrow-right mr-2 "></i>
                <Tag className="pstat expired">Expired</Tag>
              </div>
            </div>
          </>
        );
      }
    }
  };
  savePriceFirst = (value) => {
    const value_ = value
    const new_value = value_.replace(/,/g, "")


    this.setState({
      price_requirement: value, min_budget: new_value, price_first: value
    })
    if (new_value > 0) {
      const data = { leads_id: this.state.record_id, price_requirement: new_value }
      API.post("/update_price", data)
        .then((response) => {

          if (response.data.success) {
            this.setState({
              all: response.data.all,
              notes: response.data.notes
            })
            this.fetchExactListings()
            this.fetchCoparableListings()
          }
        })
    }
    const page = 1;
    const perPage = 5;
    const data_fetch = {
      leads_id: this.state.record_id,
      bedroom: this.state.new_bed,
      price: new_value,
      community: this.state.new_community,
      subcommunity: this.state.new_sub_community
    }
    this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);


  }
  handleisShareWhatsApp = (data) => {
    this.setState({ isWhatsappLoading: true })
    this.setState({ currentRequest: data })
    const postData = {
      "current_request": data
    }
    API.post("/share_request_viewing", postData)
      .then((response) => {
        if (response.data.success) {
          this.setState({ all: response.data.all })
          this.setState({ requested_viewings: response.data.requested_viewings })
          this.setState({ isWhatsappLoading: false })
          this.setState({ currentRequest: null })
        }
      }).catch((err) => {
        console.log(err)
        this.setState({ isWhatsappLoading: false })
        this.setState({ currentRequest: null })
        // toast.error('Something went wrong');
      }).finally(() => {
        this.setState({ isWhatsappLoading: false })
        this.setState({ currentRequest: null })
      })
    // this.setState({isSharetoWhatsApp:true})
  }
  handleisShareWhatsAppClose = () => {
    this.setState({ isSharetoWhatsApp: false })
  }
  handleisDecline = (data) => {
    this.setState({ isdecline: true })
    this.setState({ currentRequest: data })
  }
  handleisDeclineClose = () => {
    this.setState({ isdecline: false })
    this.setState({ currentRequest: null })
  }
  onClickDecline = () => {
    const data = {
      "decline_note": this.state.decline_note,
      "current_request": this.state.currentRequest
    }
    API.post("/decline_note", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ all: response.data.all })
          this.setState({ requested_viewings: response.data.requested_viewings })
          this.setState({ isdecline: false })
          this.setState({ decline_note: "" })
          this.setState({ currentRequest: null })
        }
      }).catch((err) => {
        console.log(err)
        this.setState({ isdecline: false })
        this.setState({ decline_note: "" })
        this.setState({ currentRequest: null })
        // toast.error('Something went wrong');
      }).finally(() => {
        this.setState({ isdecline: false })
        this.setState({ decline_note: "" })
        this.setState({ currentRequest: null })
      })
  }
  changeDeclineNote = (e) => {
    this.setState({ decline_note: e.target.value })
  }

  fetchFollowUp(leads_id) {
    API.get("/followups/" + leads_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({ followups: response.data.followups })
        }

      })
  }

  fetchFollowUpDetails(leads_id) {
    API.post("/followup_details/" + leads_id, { type: "client" })
      .then((response) => {
        if (response.data.success) {
          this.setState({ followups_details: response.data.followups })
        }

      })
  }

  handleNewFollowUp = () => {
    this.setState({
      followup_date: "",
      followup_note: "",
      selected_agent: this.state.selected_agent,
      followup_for: "",
      reminder: "",
      isfollowupOpen: true
    })
    // if (this.state.sendEvent == false) {
    //     setTimeout(this.signIn(), 5000)
    // }
    if (this.state.role != 1 && this.state.role != 2) {
      this.setState({
        selected_agent: this.state.agent_id
      })
    }
  }

  generateEventDescription = (viewing_listings) => {
    let htmlDesc = "<div>"
    viewing_listings.map((viewings_listing) => {
      htmlDesc += '<p>Ref No:' + viewings_listing.refno + '</p>'
      htmlDesc += '<p>Unit No:' + viewings_listing.unitno + '</p>'
      htmlDesc += '<p>Community:' + viewings_listing.community + '</p>'
      htmlDesc += '<p>Sub Community:' + viewings_listing.subcommunity + '</p>'
      htmlDesc += '<p>Property:' + viewings_listing.property + '</p><br>'

    });
    htmlDesc += "</div>"
    return htmlDesc;
  }

  generateFollowUpsEventDescription = (viewing_listings) => {
    const comm = (this.state.requirement_community) ? this.state.requirement_community : ""
    const sub_comm = (this.state.requirement_sub_community) ? this.state.requirement_sub_community : ""
    const prop = (this.state.lead_list_details.property) ? this.state.lead_list_details.property : ""
    const community = this.state.communities.find(item => item.value == comm);
    const sub_community = this.state.sub_communities.find(item => item.value == sub_comm);
    const property = this.state.properties.find(item => item.value == prop);
    const price = this.state.price_requirement

    let htmlDesc = "<div>"

    htmlDesc += '<p>Name:' + this.state.lead_list_details.owner + '</p><br>'
    htmlDesc += '<p>Community:' + community.label + '</p><br>'
    htmlDesc += '<p>Sub Community:' + sub_community.label + '</p><br>'
    // htmlDesc += '<p>Property:' + property.label + '</p><br>'
    htmlDesc += '<p>Price:' + price + '</p><br>'
    htmlDesc += '<p>Notes:' + viewing_listings.followup_note + '</p><br>'
    // htmlDesc += '<p>Type:' + "Client" + '</p><br>'
    htmlDesc += '<a href="https://dev.luxuryproperty.com/leads/' + viewing_listings.leads_id + '">View details</a><br>';
    htmlDesc += "</div>"
    return htmlDesc;
  }

  agentEmails = (viewing_listings) => {
    let agentEmailsData = []
    viewing_listings.map((viewings_listing) => {
      agentEmailsData.push({ email: viewings_listing.agent_email })

    });
    return agentEmailsData;
  }

  createEvent = (viewing_state_data, startingDate, type, access_token, agent_name) => {
    const [date, time] = startingDate.split(' ');
    const [year, month, day] = date.split('-');
    const [hours, minutes] = time.split(':');
    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
    const dateObject = new Date(isoString);
    const startDateInIsoFormat = dateObject.toISOString();

    const starting_date = new Date(startDateInIsoFormat);
    const newStartingDate = new Date(starting_date.getTime() - (4 * 60 * 60 * 1000));
    const startingDateInUtc = newStartingDate.toISOString();
    const endDate = new Date(startingDateInUtc);
    endDate.setHours(endDate.getHours() + 1);
    const endDateInIsoFormat = endDate.toISOString();

    let eventData = {};
    if (type == "viewing") {
      eventData = {
        source: {
          url: `https://dev.luxuryproperty.com/leads/${this.props.match.params.id}`,
          title: agent_name,
        },
        summary: "Leads Viewing Event",
        description: this.generateEventDescription(viewing_state_data),
        start: {
          dateTime: startingDateInUtc,
          timeZone: "Asia/Dubai",
        },
        end: {
          dateTime: endDateInIsoFormat,
          timeZone: "Asia/Dubai",
        },
        // attendees: this.agentEmails(viewing_state_data),
        // sendUpdates: "all"
      };
    } else {
      eventData = {
        source: {
          url: `https://dev.luxuryproperty.com/leads/${this.state.agent_id}`,
          title: agent_name,
        },
        summary: this.state.followup_subject ? "Followups - " + this.state.followup_subject : "Followups",
        description: this.generateFollowUpsEventDescription(viewing_state_data),
        start: {
          dateTime: startingDateInUtc,
          timeZone: "Asia/Dubai",
        },
        end: {
          dateTime: endDateInIsoFormat,
          timeZone: "Asia/Dubai",
        },
        // attendees: this.agentEmails(viewing_state_data),
        // sendUpdates: "all"
      };
    }

    // try {
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    };

    axios.post('https://www.googleapis.com/calendar/v3/calendars/primary/events', eventData, { headers })
      .then((response) => {

      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  signIn = () => {

    apiCalendar.handleAuthClick();
  }

  generateTimeOptions = () => {
    const options = [];
    const startTime = 6 * 60; // 6:00 AM
    const endTime = 22 * 60; // 10:00 PM

    for (let time = startTime; time <= endTime; time += 15) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      const formattedHours24 = hours.toString().padStart(2, '0');
      const formattedHours12 = ((hours % 12 === 0 ? 12 : hours % 12)).toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';

      const formattedTime = `${formattedHours24}:${formattedMinutes}`;
      const labelTime = `${formattedHours12}:${formattedMinutes} ${period}`;

      options.push({
        value: formattedTime,
        label: labelTime,
      });
    }

    return options;
  };
  handleFollowupDate = (momentObject, dateString) => {
    const combinedDateTime = dateString + ' ' + this.state.selectedTime;
    this.setState({
      selectedDate: dateString,
      followup_date: combinedDateTime,
    });

  }

  handleTimeChange = (value) => {
    const combinedDateTime = this.state.selectedDate + ' ' + value;
    this.setState({
      selectedTime: value,
      followup_date: combinedDateTime, // Set the date again to ensure it's up to date
    });
  }

  handleFollowupNote = (e) => {
    this.setState({ followup_note: e.target.value })
  }

  handleFollowupTitle = (e) => {
    this.setState({ followup_subject: e.target.value })
  }

  handleFollowupOk = () => {

    if (this.validate()) {

      const lead_id = this.state.record_id

      const data = {
        leads_id: this.props.match.params.id,
        listing_id: 0,
        contacts_id: this.state.owner_id,
        followup_date: this.state.followup_date,
        followup_note: this.state.followup_note,
        type: this.state.type,
        selected_agent: this.state.selected_agent,
        followup_for: this.state.followup_for,
        reminder_time: this.state.reminder
      }


      this.setState({ isFollowUpLoading: true });


      API.post("/add_followup_details", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({ isFollowUpLoading: false, isfollowupOpen: false });

            this.setState({ isfollowupOpen: false, requirement_lead_stage: 4 })
            this.fetchFollowUpDetails(this.state.record_id)

            const id = this.props.match.params.id
            API.get("/lead_details/" + id)
              .then((response) => {
                if (response.data.success) {

                  // this.setState({currentRequest: null})
                  this.setState({
                    all: response.data.all,
                  })


                }
              })

            const postData = {
              agent_id: [this.state.selected_agent]
            };
            API.post(`/get_oauth_access_token`, postData).then(
              (response) => {
                if (response.data.success) {
                  let agent_data = response.data.agent_data;
                  this.setState({ agent_data: response.data.agent_data });
                  agent_data.forEach(agent => {
                    this.createEvent(data, this.state.followup_date, "followups", agent.access_token, agent.agent_name);
                  });
                }
              }
            );
            // setTimeout(() => {
            //   this.createEvent(data, this.state.followup_date, "followups");
            // }, 2000);



          }


        })
    }
  }
  handleFollowupCancel = () => {
    this.setState({ isfollowupOpen: false })
  }
  getFollowUpType = (type) => {
    if (type == 1) {
      return "Follow Up";
    } else if (type == 2) {
      return "Call"
    } else if (type == 3) {
      return "Email"
    } else if (type == 4) {
      return "Viewing"
    } else if (type == 5) {
      return "Offer"
    } else {
      return "Whatsapp"
    }
  };
  validate() {
    let followup_for = this.state.followup_for;
    let selected_agent = this.state.selected_agent;
    let followup_date = this.state.followup_date;
    let followup_note = this.state.followup_note;
    let validationerrors = {};
    let isValid = true;


    if (followup_for == null || followup_for == "" || followup_for == 0) {
      isValid = false;
      validationerrors["followup_for"] = "Follow up for is required.";
    }
    if (selected_agent == null || selected_agent == "" || selected_agent == 0) {
      isValid = false;
      validationerrors["selected_agent"] = "Agent is required.";
    }
    if (followup_date == null || followup_date == "" || followup_date == 0) {
      isValid = false;
      validationerrors["followup_date"] = "Date is required.";
    }
    if (followup_note == null || followup_note == "" || followup_note == 0) {
      isValid = false;
      validationerrors["followup_note"] = "Note is required.";
    }

    this.setState({
      validationerrors: validationerrors
    });

    return isValid;
  }

  handleViewFollowup = (data, e) => {
    this.setState({ isfollowupViewOpen: true, followup_data: data })
  }
  
  handlePanelChange = (key) => {
    this.setState({activePanel: key});
  };


  handleFollowupViewOk = () => {
    this.setState({ isfollowupViewOpen: false })

  }

  handleFollowupViewCancel = () => {
    this.setState({ isfollowupViewOpen: false })
  }
  onChangeFollowupFor = (e) => {
    this.setState({ followup_for: e.target.value })
  }
  onChangeReminder = (e) => {
    this.setState({ reminder: e.target.value })
  }
  handleAgentDetails = (e) => {
    this.setState({ selected_agent: e })
  }
  onChangeVendor = (e) => {
    this.setState({ vendor: e })
  }
  onSearchClient = (e) => {

    API.get("/get_clients/" + e)
      .then((response) => {

        if (response.data.success) {
          this.setState({ clientList: response.data.contacts })
        }
      })
  }

  handleShareRowSelectChange = (rowKey, selectedRowKeysTestShare) => {
    const refno_unitno = this.state.share_unitno_data
    const refno_data_mergedArray = [...new Set([...refno_unitno, ...selectedRowKeysTestShare])];

    this.setState({ share_unitno_data: refno_data_mergedArray });

    this.setState({ selectedRowKeysTestShare: rowKey });
  };

  handleRowSelectChange = (rowKey, selectedRowKeysTest) => {

    const uniqueAgentIds = [...new Set(selectedRowKeysTest.map(item => item.agent_id).filter(id => id !== 430))];
    const refno_unitno = this.state.refno_search ? this.state.refno_data : this.state.unitno_data
    const refno_data_mergedArray = [...new Set([...refno_unitno, ...selectedRowKeysTest])];
    const mergedArray = [...new Set([...this.state.viewing_agent, ...uniqueAgentIds])];
    this.setState({ viewing_agent: mergedArray });
    const agents = this.state.agents;
    const matchingProfilePictures = agents
      .filter(agent => mergedArray.includes(agent.value))
      .map(agent => agent.profile_picture);
    this.setState({ agent_profile_picture: matchingProfilePictures });
    if (this.state.refno_search == true) {

      this.setState({ refno_data: refno_data_mergedArray });
    } else {

      this.setState({ unitno_data: refno_data_mergedArray });
    }
    this.setState({ selectedRowKeysTest: rowKey });
  };

  handleSelectByAgentIds = () => {
    const { data } = this.state;
    const selectedAgentIds = [220, 430]; // Array of agent IDs to select

    // Filter the data to get the row keys (agent_ids) that match the selectedAgentIds
    const selectedRowKeys = data
      .filter(record => selectedAgentIds.includes(record.agent_id))
      .map(record => record.agent_id);

    // Update the selectedRowKeys state
    this.setState({ selectedRowKeys });
  };



   handleDocumentTitle = (e) =>{
      this.setState({document_title:e.target.value})
    }

    handleDocumentFile  =(e) =>{
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
      this.setState({ documentImageShow: URL.createObjectURL(e.target.files[0]) });
    };
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          document_file: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }

    handleDocumentsSubmit  = () => {
      const { id } = this.props.match.params;
       if (!this.state.document_title) {
        toast.error("Document Title Is Required");
        return; 
      }
      if (!this.state.document_file) {
        toast.error("Please select a document before submitting.");
        return; 
      }
      const data = {
        leads_id : id,
        document_file : this.state.document_file, document_title : this.state.document_title
      }
      API.post("/add_lead_documents",data)
      .then((response) =>{
        if(response.data.success){
          this.setState({documents:response.data.documents,document_title:""})
            toast.success("Document has been sucessfully added")
            this.setState({ add_documents: false })
        }
      })
    }


  rowSelectionForViewing = {
    onChange: (selectedRowKeys, selectedRows) => {
      const uniqueAgentIds = [...new Set(selectedRows.map(item => item.agent_id))];
      this.setState({ viewing_agent: uniqueAgentIds });
      const agents = this.state.agents;
      const matchingProfilePictures = agents
        .filter(agent => uniqueAgentIds.includes(agent.value))
        .map(agent => agent.profile_picture);
      this.setState({ agent_profile_picture: matchingProfilePictures });



      if (this.state.refno_search == true) {

        this.setState({ refno_data: selectedRows });
      } else {

        this.setState({ unitno_data: selectedRows });
      }
    },
  };
  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  updateTimeRemaining = () => {
    const lead_details = this.state.lead_details

    if (lead_details.lead_stage == 1) {
      const parentLeadDate = this.state.lead_details.parent_lead_date
      var myDate = new Date(parentLeadDate)


      myDate.setTime(myDate.getTime() + (4 * 60 * 60 * 1000));


      myDate.setHours(myDate.getHours() + 24)

      const now = new Date();

      now.setTime(now.getTime() + (4 * 60 * 60 * 1000));
      const timeDifference = myDate - now;

      if (timeDifference <= 0) {
        clearInterval(this.timerInterval);
        this.setState({
          isTimer: false,
          timeRemaining: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          },
        });
      } else {
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        this.setState({
          isTimer: true,
          timeRemaining: {
            days: days,
            hours: hours % 24,
            minutes: minutes % 60,
            seconds: seconds % 60,
          },
        });
      }



    } else
      if (lead_details.lead_stage == 2) {
        const parentLeadDate = new Date(this.state.lead_details.last_call)

        const currentDate = new Date();
        const utcCurrentDate = Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        const utcOtherDate = Date.UTC(
          parentLeadDate.getFullYear(),
          parentLeadDate.getMonth(),
          parentLeadDate.getDate()
        );

        const timeNewDifference = utcOtherDate - utcCurrentDate;

        const daysDifference = Math.floor(timeNewDifference / (1000 * 60 * 60 * 24));



        if (daysDifference > 7) {
          const viewings = this.state.property_viewings
          if (viewings && viewings.length > 0) {
            const viewing_date = new Date(this.state.property_viewings[0].view_date)
            const current_time = new Date();
            if (viewing_date > current_time) {
              const parentLeadDate = new Date(this.state.lead_details.last_call)

              var myDate = new Date(viewing_date)
              myDate.setTime(myDate.getTime() + (4 * 60 * 60 * 1000));
              myDate.setHours(myDate.getHours() + 24)
              const now = new Date();
              now.setTime(now.getTime() + (4 * 60 * 60 * 1000));
              const timeDifference = myDate - now;

              if (timeDifference <= 0) {
                clearInterval(this.timerInterval);
                this.setState({
                  isTimer: false,
                  timeRemaining: {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  },
                });
              } else {
                const seconds = Math.floor(timeDifference / 1000);
                const minutes = Math.floor(seconds / 60);
                const hours = Math.floor(minutes / 60);
                const days = Math.floor(hours / 24);

                this.setState({
                  isTimer: true,
                  timeRemaining: {
                    days: days,
                    hours: hours % 24,
                    minutes: minutes % 60,
                    seconds: seconds % 60,
                  },
                });
              }
            } else {
              this.setState({
                isTimer: false,
                timeRemaining: {
                  days: 0,
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                },
              });

            }


          } else {
            this.setState({
              isTimer: false,
              timeRemaining: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
              },
            });
          }
        } else {

          const originalDate = new Date(this.state.lead_details.last_call);
          const newDate = new Date(originalDate.getTime() + 7 * 24 * 60 * 60 * 1000);




          const now = new Date();
          const timeDifference = newDate - now;

          if (timeDifference <= 0) {
            clearInterval(this.timerInterval);
            this.setState({
              isTimer: false,
              timeRemaining: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
              },
            });
          } else {
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            this.setState({
              isTimer: true,
              timeRemaining: {
                days: days,
                hours: hours % 24,
                minutes: minutes % 60,
                seconds: seconds % 60,
              },
            });
          }

        }




      }

  };


  callTimer = (parent_lead_date) => {
    var myDate = new Date(parent_lead_date)
    myDate.setHours(myDate.getHours() + 24)

    return <h3>Timer here</h3>
  }

  // Method to convert the date
  getValidDate = (dateString) => {
    return dayjs(dateString, 'YYYY-MM-DD HH:mm:ss').isValid()
      ? dayjs(dateString, 'YYYY-MM-DD HH:mm:ss')
      : null;
  };


  //   shouldComponentUpdate(nextProps, nextState) {
  //     // You can add your logic here to decide whether to re-render or not
  //     // For example, return false if you don't want to re-render
  //     if (this.state.isMatchPropertiesLoaded) {
  //         return false;
  //       }
  //       return true;
  //   }

  render() {   
    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let role_name = userdata.auth.user.role_name;
    let role_profile = userdata.auth.user.profile_photo;
    const user_id = userdata.auth.user.id;
    const agent_name = userdata.auth.user.display_name;

    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const selectedRowKeysTest = this.state.selectedRowKeysTest;
    const selectedRowKeysTestShare = this.state.selectedRowKeysTestShare;


    const isUserFound = this.state.collaborators.some(element => {
      if (element.user_id === user_id) {
        return true
      } else {
        return false
      }
    });
    //   const { timeRemaining } = this.state;


    const rowSelectionTest = {

      selectedRowKeys: selectedRowKeysTest,
      onChange: this.handleRowSelectChange
    };

    const rowSelectionTestShare = {
      selectedRowKeys: selectedRowKeysTestShare,
      onChange: this.handleShareRowSelectChange
    }

    const logsColumn = [
      {
        title: "Agent",
        dataIndex: "agent_name",
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Agent
            <Select
              showSearch
              allowClear
              style={{
                width: '100%',
              }}

              placeholder="Search"
              onChange={this.handleAgentFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={this.state.agents}
            />


          </div>
        ),
      },
      {
        title: "Time",
        dataIndex: "time",
        sorter: (a, b) => a.time.length - b.time.length,
        key: "time",
        sortOrder: sortedInfo.columnKey === 'time' && sortedInfo.order,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => (
          <Moment format="DD MMM YYYY HH:mm:ss">
            {record.time ? this.getCurrentTime(record.time) : "-"}
          </Moment>
        ),
      },

      {
        title: "OS",
        dataIndex: "mobile",
        render: (text, record) => {
          return record.mobile == 0 ? <DesktopOutlined /> : <MobileOutlined />
        }

      },
    ];

    const addViewingStructure = [
      {
        key: (record) => record.refno,
        title: "Property",
        dataIndex: "propertyinfo",
        render: (text, record) => (
          <Link target="_blank" to={"/all_listings/" + record.id}>
            <div className="info-group w-100 propertyinfo">
              <div className="d-flex flex-row justify-content-between">
                <p className="info-title">
                  {(record.unitno) && <span className="font-bold font-12">{record.unitno}</span>} {(record.unitno) && "|"} {record.refno}
                </p>
              </div>
              <p>
                {record.property ? record.property + "," : ""}
                {record.sub_community}, {record.community}
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: (record) => record.property_for,
        title: "Property for",
        dataIndex: "property_for",
        render: (text, record) => (
          <div className="info-group w-100 propertyinfo">
            <div className="d-flex flex-row justify-content-between">
              <p className="info-title">
                Property for
              </p>
            </div>
            <p>
              {record.property_for}
            </p>
          </div>
        ),
      },
      {
        key: (record) => record.display_name,
        title: "Agent",
        dataIndex: "display_name",
        render: (text, record) => (
          <div className="info-group w-100 propertyinfo">
            <div className="d-flex flex-row justify-content-between">
              <p className="info-title">
                Agent
              </p>
            </div>
            <p>
              {record.display_name}
            </p>
          </div>
        ),
      },
    ];


    const matchverifycol = [
      {
        title: "Property",
        dataIndex: "propertyinfo",
        render: (text, record) => (
          <Link target="_blank" to={"/all_listings/" + record.id}>
            <div className="info-group w-100 propertyinfo">
              <div className="d-flex flex-row justify-content-between">
                <p className="info-title">
                  {record.property_for} | {record.refno}
                </p>

                {record.status == "Y" ? (
                  <p className="published stat">Published</p>
                ) : null}
                {record.status == "CTM" ? (
                  <p className="comingtomarket stat">Coming to market</p>
                ) : null}
                {record.status == "F" ? (
                  <p className="feeds stat">Feeds</p>
                ) : null}
                {record.status == "D" ? (
                  <p className="draft stat">Draft</p>
                ) : null}
                {record.status == "UC" ? (
                  <p className="confirmedowner stat">Confirmed Owner</p>
                ) : null}
                {record.status == "N" ? (
                  <p className="unpublished stat">Unpublished</p>
                ) : null}
                {record.status == "OM" ? (
                  <p className="offmarket stat">Off-Market</p>
                ) : null}
                {record.status == "U" ? (
                  <p className="action stat">Action</p>
                ) : null}
                {record.status == "SEO" ? (
                  <p className="seo stat">SEO</p>
                ) : null}
              </div>
              <p className="font-bold font-12">{record.unitno}</p>
              <p>
                {record.subcommunity}, {record.community}
              </p>
              <div className="d-flex flex-row justify-content-between">
                <p>
                  {record.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED"}
                </p>
                <div className="d-flex flex-row">
                  {/* <Avatar size={18} className='mr-1'/> */}
                  <Avatar
                    className="d-flex"
                    size={18}
                    style={{ backgroundColor: "#FFF", marginRight: "0" }}
                    icon={
                      <img
                        src={
                          record.profile_photo == null ||
                            record.profile_photo == ""
                            ? baseurlImg +
                            "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg"
                            : this.isValidHttpUrl(record.profile_photo)
                              ? record.profile_photo
                              : baseurlImg +
                              "lpcrm/public/uploads/clients/2036/users/" +
                              record.profile_photo
                        }
                      />
                    }
                  />
                  <span>{record.agent_name}</span>
                  {/* <span
                              className="infovalue atime"
                              style={{ fontSize: "13px", display: "flex" }}
                            >
                              <Moment fromNow>
                              {(record.added_date) ? this.getCurrentTime(record.added_date) : "-"}
                              </Moment>
                            </span> */}
                </div>
              </div>
            </div>
          </Link>
        ),
      },
    ];


    const otherEquires = (
      <>
        <div className="bg-white  ownersdetails">
          <div className="d-flex flex-column form-group mb-0">
            <div className="col-12 d-flex flex-column listtable-wrap">
              {this.state.owner_other_properties.map(
                (owner_other_property) => {
                  var pStatus = "Draft";
                  switch (owner_other_property.status) {
                    case "D":
                      pStatus = "Draft";
                      break;
                    case "UC":
                      pStatus = "Confirmed Owner";
                      break;
                    case "N":
                      pStatus = "Unpublish";
                      break;
                    case "OM":
                      pStatus = "Off-Market";
                      break;
                    case "CTM":
                      pStatus = "Coming to market";
                      break;
                    case "Y":
                      pStatus = "Published";
                      break;
                    case "U":
                      pStatus = "Action";
                      break;

                    case "SEO":
                      pStatus = "SEO";
                      break;
                    case "L":
                      pStatus = "Unlisted";
                      break;
                    case "S":
                      pStatus = "Sold";
                      break;
                    case "T":
                      pStatus = "Let";
                      break;
                    case "P":
                      pStatus = "Review";
                      break;
                    case "F":
                      pStatus = "Feeds";
                      break;
                    default:
                      pStatus = "Draft";
                      break;
                  }
                  return (
                    <div
                      style={{
                        border: "rgba(21,21,21,0.20)",
                        boxShadow: "none",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                    >
                      <a
                        target="_blank"
                        href={
                          liveUrl +
                          "/all_listings/" +
                          owner_other_property.listing_id
                        }
                        className="d-flex flex-row p-2 w-100"
                        style={{ textDecorationLine: "none" }}
                      >
                        <div className="col-8 listtable">
                          <p className="propfor">
                            {owner_other_property.property_for}
                          </p>
                          <p className="refno">
                            {owner_other_property.unitno}
                          </p>
                          <p className="refno">
                            {owner_other_property.refno}
                          </p>
                          <p className="propadd">
                            {owner_other_property.unitno},{" "}
                            {owner_other_property.subcommunity}
                          </p>
                          <p className="community">
                            {owner_other_property.community}
                          </p>
                        </div>
                        <div className="col-4">
                          <div className="d-flex flex-row stat publishstat">
                            <i className="bi bi-circle-fill" />
                            <span>{pStatus}</span>
                          </div>
                          <div className="agentprop">
                            <p className="name">
                              {owner_other_property.agent}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </>

    )

    const referral = (
      <>
        <div className="editleadwrap referral">
          <Row>
            <Col span={24}>
              <div className="form-group">
                <label className="mb-0">Referred by:</label>
                <Select
                  showSearch
                  placeholder="Add Agent"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  name="agents_assign"
                  disabled={
                    role_name != "Admin" &&
                    role_name != "Super Admin" &&
                    this.state.is_referred
                  }
                  defaultValue={this.state.referred_by}
                  onChange={this.changeReferred_by}
                >
                  <Option value="">Refer agent</Option>
                  {this.state.agents.map((agent) => {
                    return (
                      <>
                        <Option value={agent.value}>
                          {agent.label}
                        </Option>
                      </>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={24}>
              <div className="form-group">
                <label className="mb-0">Referred to:</label>
                <Select
                  showSearch
                  placeholder="Add Agent"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  name="agents_assign"
                  disabled={
                    role_name != "Admin" &&
                    role_name != "Super Admin" &&
                    this.state.is_referred
                  }
                  defaultValue={this.state.referred_to}
                  onChange={this.changeReferred_to}
                >
                  <Option value="">Refer agent</Option>
                  {this.state.agents.map((agent) => {
                    return (
                      <>
                        <Option value={agent.value}>
                          {agent.label}
                        </Option>
                      </>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={24}>
              <div className="form-group mt-2">
                <Button
                  type="primary"
                  size="large"
                  disabled={
                    role_name != "Admin" &&
                    role_name != "Super Admin" &&
                    this.state.is_referred
                  }
                  className="mt-0 font-bold pl-0"
                  style={{
                    padding: "18px",
                    width: "fit-content",
                  }}
                  onClick={this.onSaveRef}
                >
                  <i className="bi bi-plus me-1 fs-5" />
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )

    const followUp = (
      <div className="editleadwrap referral">
        <Row>
          <Col span={24}>
            <div className="bg-white  viewings ">
              <div className="d-flex flex-row justify-content-between">

                <a onClick={this.handleNewFollowUp} className="btn btn-primary mb-2 viewall" style={{ fontSize: 14, color: "#151515" }}>Add Followup</a>
              </div>
              <div className="d-flex flex-column border-wrap">
                {this.state.followups_details.map((followup) => {
                  return (
                    <a onClick={this.handleViewFollowup.bind(this, followup)} className="matchitem">
                      <div className="matchinfo">
                        <div className="col-9">
                          {/*<p className="price">{followups.first_name}</p>*/}
                          <p className="price">{(followup.type === 1) ? "Vendor" : "Client"}</p>
                          {/* <p className="price">{(followup.status === 1) ? "Active" : "Inactive"}</p> */}
                          <p className="address">
                            {(followup.notes) ? (followup.notes.length > 40 ?
                              `${followup.notes.substring(0, 40)}...` : followup.notes) : null
                            }
                          </p>
                          <p className="price">{followup.date}</p>
                          <p className="price">{(followup.agent_name) ? "Follow up by " + followup.agent_name : ""}</p>
                        </div>
                        <div className="col-3">
                          <button type="button" className="btn btn-link addfeedback"></button>
                        </div>
                      </div>
                    </a>
                  )
                })}
                {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                  View All
                </a> : null}

              </div>
            </div> {/* END Followup*/}

          </Col>


        </Row>
      </div>
    )


    const collaborators = (
      <div className="editleadwrap referral">
        <Row>
          {role == 1 || isUserFound || this.state.collaborators.some((data) => data.user_id == user_id) || user_id.toString() == this.state.requirement_agent_id.toString() || this.state.lead_details.lead_stage == 10 || this.state.lead_details.lead_agent == 430 ?
            <>
              <Col span={24}>
                <div className="form-group">
                  <label className="mb-0">Select Collaborator:</label>
                  <div className="flex flex-row">
                    <Select
                      style={{
                        width: '100%',
                        marginBottom: '1rem'
                      }}
                      showSearch
                      mode={"multiple"}
                      value={this.state.newArrSelectedAgents}
                      placeholder="Select a Collaborator"
                      maxTagCount={"responsive"}
                      onChange={this.handleChangeSelect}
                      options={this.state.agents}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    />

                    <Button
                      type="primary"
                      size="large"
                      className="mt-2 font-bold pl-0"
                      style={{ width: "fit-content", textAlign: 'center', padding: '0 10px !important', marginLeft: '5px', height: '36px' }}
                      onClick={this.postAddCollaborator}
                    >Add
                    </Button> </div>
                </div>
              </Col>
              {/* <Col span={24} style={{display: "flex", justifyContent: "center"}}>
<div className="form-group">
<Button
  type="primary"
  size="large"
  className="mt-0 font-bold pl-0"
  style={{ padding: "18px", width: "fit-content" }}
  onClick={this.postAddCollaborator}
>
  <i className="bi bi-people mr-2 ml-2"></i>
  Add Collaborator
</Button>

</div>
</Col> */}
            </>
            : null}
          <Col span={24}>
            <div className="form-group mt-2">
              <div style={{ width: '100%', paddingLeft: '0.25rem' }} >
                {this.state.collaborators.map((coll, index) => {
                  return (
                    <div className="matchitem">
                      <div className="matchinfo" style={{ alignItems: "center" }}>
                        {/* <div className="col-2"> */}
                        {/* <Avatar style={{borderRadius: '10% !important'}} 
                                    size={40} 
                                    icon={<img style={{borderRadius: '10px', boxShadow: '1px 1px 10px lightgray',width: "40px",height: "40px", borderRadius: '10% !important'}} 
                                    src={ coll.profile_photo== null || coll.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(coll.profile_photo)? coll.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + coll.profile_photo } />} /> */}
                        {/* </div> */}
                        <div className="col-9">
                          <p className="name">{coll.display_name}</p>
                          <p className="price">{coll.added_date}</p>
                        </div>
                        {
                          role == 1 ?
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"
                                onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)}
                              > Delete</button>
                            </div>
                            :
                            user_id == coll.user_id &&
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"
                                onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)}
                              > Delete </button>
                            </div>
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
    return (
      <>
        <ToastContainer />

        <Modal width={600} title="Offers" open={this.state.add_documents} onOk={this.handleOkLead} onCancel={this.handleCancelLead}>
          <div className="container">
               <div className="row">
                <div className="form-group col-12">
                  <label className="control-labels">Documents</label>
                  <table className="table border-wrap">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>File</th>
                        <th>Action</th>
                       </tr>
                      </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="TitleInput">
                            <input onChange={this.handleDocumentTitle} type="text" tabIndex={2} name="doctitle"  placeholder="Title" className="form-control input-sm" id="doctitle" />
                            </div>
                          </td>
                        <td>
                           <div className="custom-file-input-wrapper">
                              <input onChange={this.handleDocumentFile} type="file" tabIndex={2} name="documents" className="form-control custom-file-input" accept="image/gif, image/jpeg,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                              <label htmlFor="fileInput" className="custom-file-label"> Choose File </label>
                           </div>
                           </td>
                           <td>
                            <button onClick={this.handleDocumentsSubmit} className="btn btn-success" type="submit" href="javascript:void(0)" data-href><i className="bi bi-check" /></button>
                            </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </Modal>


        <Modal width={600} title="Offers" open={this.state.view_documents} onOk={this.handleOkViewDocument} onCancel={this.handleCancelViewDocument}>
          <div className="container">
               <div className="row">
                <div className="form-group col-12">
                  <label className="control-labels">Documents</label>
                  <table className="table border-wrap">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>File</th>
                        {/* <th>Action</th> */}
                       </tr>
                      </thead>
                    <tbody>
                       {this.state.view_documentsArray.map((document) => {
                          return (
                              <tr>
                                  <td> {document.title}</td>
                                  <td><a href={document.document} target="_blank"><i class="bi bi-eye-fill"></i></a> </td>
                              </tr>
                          )
                      })} 
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </Modal>









        <Modal title="Decline to Share the Property" open={this.state.isdecline} width={600}
          // onOk={this.handleCallSubmit}
          onCancel={this.handleisDeclineClose}
          footer={<>
            <Button type="primary" className="d-flex flex-row" onClick={this.onClickDecline}>Submit</Button>
            <Button type="text" className="d-flex flex-row" style={{ height: '36px' }} onClick={this.handleisDeclineClose}>Cancel</Button>
          </>}
        >
          <Row className='w-100'>
            <Col span={24}>
              <p>Why do you want to decline this property?</p>
              <TextArea rows={3} value={this.state.decline_note} onChange={this.changeDeclineNote} />
            </Col>
          </Row>
        </Modal>

        <Modal
          width={800}
          title="View Logs"
          open={this.state.isViewLogsOpen}
          onCancel={this.cancelViewLogs}
          className="ModelHeight"
          onOk={this.cancelViewLogs}
        >
          <div className="row w-100">
            <div className="row">
              <div className="col-12">
                <Table
                  onChange={this.handleVisitLogsTableChange}
                  className="visit-logs"
                  loading={this.state.isLogLoading}
                  rowSelection={rowSelection}
                  pagination={{
                    total:
                      this.state?.visit_logs?.total > 0 &&
                      this.state.visit_logs.total,
                    pageSize: this.state.visit_logs.per_page,
                    current: this.state.visit_logs.current_page,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  style={{ overflowX: "auto" }}
                  columns={logsColumn}
                  dataSource={
                    this.state?.visit_logs?.data
                      ? this.state?.visit_logs?.data
                      : []
                  }
                />

                {/* <Table onChange={handleListingTableChange} 
                                  loading={this.state.isLogLoading}
                                  pagination={{hideOnSinglePage: true, defaultPageSize:5, pageSize:5,   
                                    total:
                                      this.state?.visit_logs?.total > 0 &&
                                      this.state.visit_logs.total,
                                    current: this.state.visit_logs.current_page,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                   
                                    itemRender: itemRender,
                                }} showHeader={false}
                              
                                  columns={logsColumn}
                                  
                                  dataSource={
                                    this.state?.visit_logs?.data
                                      ? this.state?.visit_logs?.data
                                      : []
                                  }
                                  /> */}
                {/* {
                  this.state.isLogLoading ? <div className="logs-loader"><Spin style={{ alignSelf: "center" }} /></div> : 
<table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Agent Name</th>
                      <th scope="col">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.visit_logs.map((visit_log) => (
                    
                       <tr>
                        <td>{visit_log.agent_name}</td>
                        <td>
                    
                        <Moment format="DD MMM YYYY HH:mm:ss">
                        {visit_log.time}
                          </Moment>
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                } */}
              </div>
            </div>
          </div>
        </Modal>

        {/* View Viewing Modal */}

        {/* View Offers Modal */}

        <Modal width={600} title="Offers" open={this.state.isOfferOpen}
          onOk={this.handleOkOffer} onCancel={this.handleCancelOffer}
        >
          <div className="container">
            <div className='row w-100 d-flex flex-row'>
              <div className='px-0 pt-1 form-group'>
                <label>Date</label>
                <p className="form-control-disabled">{this.state.viewings.view_date}</p>
              </div>


              <div className='px-0 pt-1 form-group'>
                <label>Note</label>
                <p className="form-control-disabled">{this.state.viewings.note}</p>
              </div>
              <div className='px-0 pt-1 form-group'>
                <label>Price</label>
                <p className="form-control-disabled">{this.state.viewings.amount}</p>
              </div>
            </div>
          </div>
        </Modal>

        {/* Change Viewing Modal */}

        <Modal open={this.state.isfollowupOpen} title={<p className="mb-0 font-bold">Schedule a Follow Up</p>} centered //onOk={this.handleFollowupOk} onCancel={this.handleFollowupCancel}
          onClose={this.handleFollowupCancel}
          footer={<>
            <Button variant={'contained'} onClick={this.handleFollowupOk} style={{ background: '#151515', color: '#FFF', opacity: 1, }} disabled={this.state.isFollowUpLoading}>
              {this.state.isFollowUpLoading == false ? "Add Follow Up"
                : <><Spin />Adding Follow Up...</>
              }
            </Button>
            <Button onClick={this.handleFollowupCancel}>Cancel</Button>
          </>}
        >
          <div className="d-flex flex-column w-100 mt-2">

            <div className="form-group">
              <Radio.Group className='d-flex flex-row' onChange={this.onTypeChange} value={this.state.type} disabled>
                <Radio value={1}>Vendor</Radio>
                <Radio value={2}>Client</Radio>
              </Radio.Group>
            </div>
            <div className="form-group">
              <label className="form-label">Subject</label>
              <Input onChange={this.handleFollowupTitle} defaultValue={"Follow Up"} />

            </div>
            {this.state.type == 1 && <div className="form-group">
              <label className="form-label">Search vendor</label> {/* if client switch to Search Vendor*/}
              <Select
                style={{
                  width: "100%",
                }}
                value={this.state.vendor}
                showSearch
                allowClear
                placeholder="Select Vendor"
                maxTagCount='responsive'
                optionFilterProp="children"
                onChange={this.onChangeVendor}
                onSearch={this.onSearchClient}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.clientList}
              />
            </div>}
            {this.state.type == 2 && <div className="form-group">
              <label className="form-label">Client:</label>
              <span className='form-control font-14 disabled'>{this.state.owner_name}</span>
            </div>}
            <div className="d-flex flex-column  mb-2">
              <div className="form-group col-12 pl-0 mb-2">
                <label className="form-label">Follow up for</label>
                {/* <select onChange={this.onChangeFollowupFor} value={this.state.followup_for} name="followup_for" id="followup_for" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                    <option value="">Select Follow up For</option>
                                    <option value={1}>Follow Up</option>
                                    <option value={2}>Call</option>
                                    <option value={3}>Email</option>
                                    <option value={4}>Viewing</option>
                                    <option value={5}>Offer</option>
                                    <option value={6}>Whatsapp</option>
                                </select> */}


                <Radio.Group className="followupforgrp"
                  onChange={this.onChangeFollowupFor}
                  value={this.state.followup_for}
                  buttonStyle="solid" // This makes the segmented control look like buttons
                >
                  {/* <Radio.Button value="">Select Follow up For</Radio.Button> */}
                  <Radio.Button value={1}>Follow Up</Radio.Button>
                  <Radio.Button value={2}>Call</Radio.Button>
                  <Radio.Button value={3}>Email</Radio.Button>
                  <Radio.Button value={4}>Viewing</Radio.Button>
                  <Radio.Button value={5}>Offer</Radio.Button>
                  <Radio.Button value={6}>Whatsapp</Radio.Button>
                </Radio.Group>


                <div
                  className="text-danger">{this.state.validationerrors.followup_for}</div>
              </div>
              {(role_name == "Admin" || role_name == "Super Admin") && <div className="form-group col-12 px-0 mb-2 ">
                <label className="form-label">Agent</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={this.state.selected_agent}
                  showSearch
                  allowClear
                  placeholder="Select Vendor"
                  maxTagCount='responsive'
                  optionFilterProp="children"
                  onChange={this.handleAgentDetails}
                  // onSearch={this.onSearchClient}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={this.state.agents}
                />
                {/* <select onChange={this.handleAgentDetails}
                                        value={this.state.selected_agent}
                                        name="selected_agent" id="selected_agent"
                                        className="form-control select2 required selectsearch select2-offscreen"
                                        title
                                        tabIndex={-1}>
                                    <option value="">Select Agent</option>
                                    {this.state.agents.map((agent) => {
                                        return (
                                            <option value={agent.value}>{agent.label}</option>
                                        )
                                    })}
                                </select> */}
                <div
                  className="text-danger">{this.state.validationerrors.selected_agent}</div>
              </div>}
              {(role_name != "Admin" && role_name != "Super Admin") && <div className="form-group col-6 pr-0 mb-1">
                <label className="form-label">Agent</label>
                <select onChange={this.handleAgentDetails}
                  value={this.state.selected_agent}
                  name="selected_agent" id="selected_agent"
                  className="form-control select2 required selectsearch select2-offscreen"
                  title
                  tabIndex={-1}>
                  <option value="">Select Agent</option>
                  {this.state.agents.map((agent) => {
                    return (
                      <option value={agent.value}>{agent.label}</option>
                    )
                  })}
                </select>
                <div
                  className="text-danger">{this.state.validationerrors.selected_agent}</div>
              </div>}
            </div>

            <div className="form-group mb-2">
              <div className='d-flex flex-row'>
                <div className='form-group col-6 pl-0 mb-0'>
                  <label className="form-label">Date</label>
                  <DatePicker changeOnBlur={true}
                    // format="DD MMMM YYYY"
                    style={{ width: '100%', height: '36px', 'overflow': 'hidden' }}
                    // showTime={{ minuteStep:15}}
                    onChange={this.handleFollowupDate} />
                  <div
                    className="text-danger">{this.state.validationerrors.followup_date}</div>
                </div>
                <div className='form-group col-6 pl-0 mb-0'>
                  <label className="form-label">Time</label>
                  <Select style={{ width: '100%', height: '36px' }}
                    showSearch
                    placeholder="Select a time"
                    options={this.generateTimeOptions()}
                    onChange={this.handleTimeChange}
                    value={this.state.selectedTime}
                  />
                </div>



                {/* <div className='form-group col-6 pl-0 mb-0'>
                                    <label className="form-label">Reminder</label>
                                    <select onChange={this.onChangeReminder} value={this.state.reminder} name="reminder" id="reminder" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                        <option value="">Select Reminder</option>
                                        <option value="600">10 minutes before</option>
                                        <option value="1800">30 minutes before</option>
                                        <option value="3600">1 hour before</option>
                                        <option value="86400">1 day before</option>
                                        <option value="604800">1 week before</option>
                                    </select>
                                </div> */}
              </div>

            </div>
            <div className="form-group">
              <label className="form-label">Note</label>
              <TextArea rows={4} onChange={this.handleFollowupNote} />
              <div
                className="text-danger">{this.state.validationerrors.followup_note}</div>
            </div>
          </div>
        </Modal>

        <Modal width={600} title=" Followup Details" open={this.state.isfollowupViewOpen}
          onOk={this.handleFollowupViewOk} onCancel={this.handleFollowupViewCancel}
        >
          <Divider />


          <div className="d-flex flex-column w-100 ">
            <div className="form-group">
              <label>Date & Time</label>
              <p>{this.state.followup_data.date}</p>
            </div>
            <div className="form-group">
              <label >Followup by</label>
              <p>{this.state.followup_data.agent_name}</p>
            </div>
            <div className="form-group">
              <label >Status</label>
              <p>{(this.state.followup_data.status == 0) ? "Inactive" : "Active"}</p>
            </div>
            <div className="form-group">
              <label >Type</label>
              <p>{(this.state.followup_data.type == 1) ? "Vendor" : "Client"}</p>
            </div>
            <div className="form-group">
              <label >Followup for</label>
              <p>{this.getFollowUpType(this.state.followup_data.followup_type)}</p>
            </div>
            <div className="form-group">
              <label >Followup note</label>
              <p>{this.state.followup_data.notes}</p>
            </div>
          </div>


        </Modal>

        <Modal width={600} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
          <div className="container">
            <div className='row w-100 d-flex flex-row'>
              <div className='px-0 pt-1 form-group'>
                <label>Ref No</label>
                <p className="form-control-disabled">{this.state.viewings.listing_refno}</p>
              </div>
              <div className='px-0 pt-1 form-group'>
                <label>Agent Name</label>
                <p className="form-control-disabled">{this.state.viewings.fullname}</p>
              </div>
            </div>
            <div className='row w-100 mt-3 d-flex flex-row'>

              <div className='px-0 pt-1 form-group'>
                <label>Date</label>
                {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p className="form-group">{this.state.viewings.view_date}</p>}

              </div>
              <div className='px-0 pt-1 mt-3 form-group'>
                <label>Status</label>
                <Select
                  style={{
                    width: 160,
                  }}
                  showSearch
                  maxTagCount={"responsive"}
                  placeholder="Select beds"
                  optionFilterProp="children"
                  onChange={this.handleViewStatus}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    { value: 'Offer', label: 'Offer' },
                    { value: "Successful", label: 'Successful' },
                    { value: "Cancelled", label: 'Cancelled' },
                    { value: "Unsuccessful", label: 'Unsuccessful' },
                    { value: "Scheduled", label: 'Scheduled' }
                  ]}
                />
              </div>
              {this.state.showPrice ? <div className='form-group'>
                <label>Price</label>
                <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
              </div> : null}
            </div>
            <div className='row w-100 mt-3'>
              <div className='w-100 px-0 pt-1'>
                <label>Note</label>
                <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
              </div>


            </div>
          </div>
        </Modal>

        {/* View Match Modal */}

        <Modal
          width={600}
          title="Match Details"
          open={this.state.isViewMatchesOpen}
          onCancel={this.viewMatchesCancel}
          onOk={this.viewMatchesCancel}
        >
          <div style={{ width: '100%' }}>
            <div className="d-flex flex-column pt-3">
              <div className="d-flex flex-column matchfilter">
                <div className="col-12 px-0">
                  <div className="form-group">
                    <label for="community" className="mb-0">
                      Community:
                    </label>
                    <Select allowClear={true}
                      id="community"
                      style={{
                        width: 160,
                      }}
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={this.handleCommunityMatch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.communities.map((e) => {
                        return {
                          label: e.label,
                          value: e.value.toString(),
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="form-group">
                    <label
                      for="propsubcommunity"
                      className="control-labels "
                      htmlFor="propsubcommunity"
                    >
                      Sub-Community:
                    </label>
                    <Select allowClear={true}
                      id="propsubcommunity"
                      style={{
                        width: 160,
                      }}
                      showSearch
                      maxTagCount={"responsive"}
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={this.handleSubCommunityMatch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.sub_communities.map((e) => {
                        return {
                          label: e.label,
                          value: e.value.toString(),
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="form-group d-flex flex-row budget">
                    <label for="matchingBudgetMin">Budget:</label>

                    <div className="d-flex flex-row">
                      <CurrencyInput
                        id="input-min"
                        name="input-min"
                        placeholder="Min"
                        value={this.state.min_budget_add}
                        decimalsLimit={2}
                        onChange={this.handleMinBudget}
                      />

                      <span className="dash" style={{}}>
                        -
                      </span>
                      <CurrencyInput
                        id="input-max"
                        name="input-max"
                        placeholder="Max"
                        value={this.state.max_budget_add}
                        decimalsLimit={2}
                        onChange={this.handleMaxBudget}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="form-group">
                    <label for="matchingBedrooms">Beds:</label>
                    <select
                      value={this.state.bedroom_match_add}
                      onChange={this.handleBeds}
                      id="matchingBedrooms"
                      name="bedrooms"
                      className="form-control"
                      title
                      tabIndex={-1}
                    >
                      <option value>Select</option>
                      <option value="Studio">Studio</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                      <option value={11}>11</option>
                      <option value={12}>12</option>
                      <option value={13}>13</option>
                      <option value={14}>14</option>
                      <option value={15}>15</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-12 px-0">
                  <div className="form-group d-flex flex-row w-100">
                    <label for="addedDateM">Added Date:</label>
                    <select
                      onChange={this.handleAddedDateMatch}
                      id="addedDateM"
                      className="form-control"
                      title
                      tabIndex={-1}
                    >
                      <option value>Select</option>
                      <option value={1}>Last 24 Hours</option>
                      <option value={3}>Last 3 Days</option>
                      <option value={7}>Last 7 Days</option>
                      <option value={14}>Last 14 Days</option>
                      <option value={30}>Last 1 Month</option>
                      <option value={90}>Last 3 Month</option>
                      <option value={180}>Last 6 Month</option>
                      <option value={365}>Last 1 Year</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-12 px-0">
                  <div className="form-group d-flex flex-row">
                    <label for="matchingPfor">Property For:</label>
                    <select
                      onChange={this.handlePropertForMatch}
                      id="matchingPfor"
                      className="form-control"
                      title
                      tabIndex={-1}
                    >
                      <option value>Select</option>
                      <option value="rent">Rent</option>
                      <option value="sale">Sale</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="form-group d-flex flex-row">
                    <label for="matchingType">Type:</label>
                    <select
                      onChange={this.handlePropertTypeMatch}
                      id="matchingType"
                      className="select2 select2-offscreen"
                      title
                      tabIndex={-1}
                    >
                      <option>select</option>
                      {this.state.propertytypes.map((propertytype) => {
                        return (
                          <option value={propertytype.value}>
                            {propertytype.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <button
                    type="button"
                    onClick={this.handleAllMatch}
                    className="btn btn-dark w-100 mb-2"
                  >
                    Match
                  </button>
                </div>
              </div>

              {this.state.matchs.length > 0 &&
                <div className="matchresults">
                  <div className="d-flex flex-row justify-content-between">
                    <label className="control-labels mr-4">
                      Match with access
                    </label>
                  </div>
                  <div className="matchresult">
                    {this.state.matchs.map((match) => {
                      const match_price = match.price;

                      return (
                        <a href className="matchitem">
                          <div className="matchinfo">
                            <div className="col-12 col-md-9 px-0">
                              <p className="address">{match.unitno}</p>
                              <p className="address">{match.address}</p>
                              <p className="address">
                                {" "}
                                {match_price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "AED"}
                              </p>
                            </div>
                            <div className="col-3">
                              <p></p>
                            </div>
                          </div>
                          <div className="matchhistory">
                            <p className="addeddate">
                              <Moment fromNow>{match.added_date}</Moment>
                            </p>
                            <p className="linkarrow">
                              <i className="bi bi-chevron-right" />
                            </p>
                          </div>
                        </a>
                      );
                    })}

                    {this.state.matchs.length > 0 ? (
                      <Link onClick={this.handleAllMatch} className="matchitem">
                        View All
                      </Link>
                    ) : null}
                  </div>
                </div>
              }
            </div>
          </div>
        </Modal>

        {/* View Offers Modal */}

        <Modal
          width={1000}
          title="View Offers"
          open={this.state.isViewOffersOpen}
          onCancel={this.viewOffersCancel}
          onOk={this.viewOffersCancel}
        >
          <Divider />
          <div className="row w-100">
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Date</th>
                      <th scope="col">Price</th>
                      <th scope="col">Address</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.property_offers.map((property_offer) => (
                      <tr>
                        <th>{property_offer.views_id}</th>
                        <th>{property_offer.view_date}</th>
                        <td>
                          {property_offer && property_offer.price
                            ? this.getPrice(property_offer.price)
                            : "-"}
                        </td>
                        <td>{property_offer.address}</td>
                        <td>{property_offer.status}</td>
                        <td style={{ cursor: "pointer" }}
                          onClick={this.handleOfferView.bind(this, property_offer.views_id)}
                        ><i className="bi bi-eye-fill me-2" /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {this.state.property_offers.length > 0 ? (
                  <Button
                    id="calllogbtn"
                    onClick={this.viewAllOffers}
                    type="primary"
                    className="d-flex flex-row"
                  >
                    <i className="bi bi-eye mr-1"></i>View All
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Modal>

        {/* Share links Modal */}
        <Modal
          width={1000}
          title="Share links"
          open={this.state.isShareLinksOpen}

          footer={false}
          onCancel={this.shareLinksCancel}
        // onOk={this.shareLinksOk}
        >

          <div className="row w-100">
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Link</th>
                      <th scope="col">Date</th>
                      <th scope="col">Ref no</th>
                      <th scope="col">Share link with client</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.shared_links.map((shared_link) => (
                      <tr>
                        <th>
                          <a style={{ cursor: 'pointer' }} target="_blank" href={"https://dubai.luxuryproperty.com/" + shared_link.hash_id}>Link to website</a>

                        </th>
                        <th> <Moment fromNow>
                          {shared_link.added_date}
                        </Moment></th>
                        <td>{shared_link.listings_refno}</td>
                        <td>{"https://dubai.luxuryproperty.com/" + shared_link.hash_id}
                          {/* <a style={{cursor:'pointer'}} target="_blank" href="https://wa.me/00971529931288?text=hello world">share to whatsapp</a>                */}


                        </td>
                        {/* <td style={{cursor: "pointer"}} onClick={this.handleEditViewing.bind(this, shared_link.id)}><i className="bi bi-pencil-fill me-2" /></td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* {this.state.property_viewings.length > 0 ? (
                  <Button
                    id="calllogbtn"
                    onClick={this.viewAllViewing}
                    type="primary"
                    className="d-flex flex-row"
                  >
                    <i className="bi bi-eye mr-1"></i>View All
                  </Button>
                ) : null} */}

                <Button
                  id="calllogbtn"
                  onClick={this.handleNewShareLink}
                  type="primary"
                  className="d-flex flex-row"
                >
                  <i className="bi bi-add-lg mr-1"></i>Add New Link
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        {/* View Viewing Modal */}

        <Modal
          width={800}
          title={<p className="font-bold d-flex flex-row mb-0"><i className="bi bi-eye-fill mr-1 ml-1"></i>Viewings</p>}
          open={this.state.isViewViewingOpen}
          //   onCancel={this.viewViewingCancel}
          //   onOk={this.viewViewingCancel}
          footer={<div className="d-flex w-100 flex-row">
            {this.state.property_viewings.length > 0 ? (
              <Button
                id="calllogbtn" type="text"
                onClick={this.viewAllViewing}
                className="d-flex flex-row mt-0 align-items-center p-0" style={{ width: 'fit-content' }}
              >
                <i className="bi bi-eye mr-1"></i>Show All
              </Button>
            ) : null}
            <div className="d-flex flex-row align-items-center ml-auto">
              <Button
                id="calllogbtn"
                onClick={this.handleNewViewing}
                type="primary"
                className="d-flex flex-row mt-0 mr-1" style={{ width: 'fit-content' }}
              >
                <i className="bi bi-add-lg mr-1"></i>Add Viewing
              </Button>
              <Button
                onClick={this.viewViewingCancel}
                className="d-flex flex-row mt-0" style={{ width: 'fit-content' }}
              >
                <i className="bi bi-add-lg mr-1 mt-0"></i>Cancel
              </Button>
            </div>
          </div>}
        >
          <div className="row w-100 no-gutter">
            <div className="row">
              <div className="col-12 px-0">
                {window.isMobileView ? <>
                  {this.state.property_viewings.map((property_viewing) => (
                    <Card>
                      <Row style={{ width: "100%" }}>
                        <Col span={12} sm={12} xs={12}>
                          <p className="refno">
                            Id
                          </p>







                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="propadd">
                            {property_viewing.views_id}
                          </p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="refno">
                            Date
                          </p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="propadd">
                            {property_viewing.view_date ? <div className="d-flex flex-column">
                              <Moment format="DD MMMM YYYY">{property_viewing.view_date}</Moment>
                              <Moment format="hh:mm A">{property_viewing.view_date}</Moment>
                            </div> : ''}
                          </p></Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="refno">
                            Price
                          </p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="propadd">
                            {property_viewing && property_viewing.price
                              ? this.getPrice(property_viewing.price)
                              : "-"}</p></Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="refno">
                            Address
                          </p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="propadd">
                            {property_viewing.address}</p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="refno">
                            Status
                          </p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="propadd">
                            {property_viewing.status}</p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p className="refno">
                            Action
                          </p>
                        </Col>
                        <Col span={12} sm={12} xs={12}>
                          <p style={{ cursor: "pointer" }} onClick={this.handleEditViewing.bind(this, property_viewing.views_id)}><i className="bi bi-pencil-fill me-2 propadd" />
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  ))} </> :
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Date</th>
                        <th scope="col">Price</th>
                        <th scope="col">Address</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.property_viewings.map((property_viewing) => (
                        <tr>
                          <th>{property_viewing.views_id}</th>
                          <th>{property_viewing.view_date ? <div className="d-flex flex-column">
                            <Moment format="DD MMMM YYYY">{property_viewing.view_date}</Moment>
                            <Moment format="hh:mm A">{property_viewing.view_date}</Moment>
                          </div> : ''}</th>
                          <td>
                            {property_viewing && property_viewing.price
                              ? this.getPrice(property_viewing.price)
                              : "-"}
                          </td>
                          <td>{property_viewing.address}</td>
                          <td>{property_viewing.status}</td>
                          <td style={{ cursor: "pointer" }} onClick={this.handleEditViewing.bind(this, property_viewing.views_id)}><i className="bi bi-pencil-fill me-2" /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }




              </div>
            </div>
          </div>
        </Modal>

        {/* Add share link Modal popUp */}
        <Modal
          width={'70vw'}
          title={<><i className="bi bi-calendar-plus"></i> Add Refno for share link</>}
          open={this.state.isSharingOpen}
          onOk={this.handleShareLinkOk}
          onCancel={this.handleShareCancel}
        >

          <div className="row w-100">
            <div className="col-md-5 pl-0 pr-3 border-right ">
              {/* <div className="col-md-12 d-flex flex-row w-100 px-0">
                    <div className="col-12 px-0">
                        <div className="form-group">
                            <label className=""><ClockCircleOutlined className="pr-1" /> Date & Time:</label>
                            <DatePicker size="small"
                            format="YYYY-MM-DD HH:mm"
                            style={{ width: "100%" }}
                            changeOnBlur={true}
                            value={(this.state.viewing_date != "") ? dayjs(this.state.viewing_date) : ""}
                            showTime ={{  
                                use12Hours: true,
                                minuteStep: 15,
                                changeOnBlur: true
                            }}
                            onChange={this.handleViewingDate}
                            showNow={false}
                            />
                            {this.state.viewing_date == "" && this.state.checkErr && (
                            <label style={{ color: "red" }}>Date is required</label>
                            )}
                        </div>
                    </div>
                </div> */}
              {/* <div className="form-group">
                <label>Ref No</label>
                <Input 
               style={{
                width: "100%",
              }}
                onChange={this.onSearchRefNo}
                name="refno" 
                value={this.state.ref_def_nums}
                placeholder="Select Refs no"
                />

                {this.state.ref_nums == "" ||
                  (this.state.ref_def_nums.length == 0 &&
                    this.state.checkErr && (
                      <label style={{ color: "red" }}>empty</label>
                    ))}
              </div> */}
              {/* <div className="row orgroup">
                <div className="col-12 d-flex flex-row">
                  <hr />
                  <p className="mb-0">Or</p>
                  <hr />
                </div>
              </div> */}
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="form-group">
                    <label className="">Unit no:</label>
                    <Input
                      style={{
                        width: "100%",
                      }}
                      onChange={this.handleSearchUintNoForShareLink}
                      name="unitno"
                      value={this.state.viewing_unit_no_for_share_link}
                      placeholder="Select Unit no"
                    />


                  </div>
                </div>
                {/* <div className="col-12 mb-2">
                    <div className="row">
                        <div className="col-12 col-md-12 mt-1">
                  <div className="form-group">
                    <label className="">Add Agent:</label>
                    <div className="d-flex flex-row" style={{gap:'0.4rem'}}>
                    <Select size="small"
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      value={this.state.viewing_agent}
                      showSearch
                      placeholder="Select Agent"
                      optionFilterProp="children"
                      onChange={this.handleViewingAgent}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.agents}
                    />
                    </div>
                    {this.state.viewing_agent.length == 0 && this.state.checkErr && (
                      <label style={{ color: "red" }}>Agent is required</label>
                    )}
                    <div className="row mt-2">
                    <Avatar.Group gap="0.7rem">
                    {this.state.agent_profile_picture && this.state.agent_profile_picture.map((s_data, index) => (
                        <Tooltip title={index} placement="top">
                          <Avatar
                           icon={
                            <img
                              src={
                                s_data == null ||
                                s_data == ""
                                  ? baseurlImg +
                                    "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg"
                                  : this.isValidHttpUrl(s_data)
                                  ? s_data
                                  : baseurlImg +
                                    "lpcrm/public/uploads/clients/2036/users/" +
                                    s_data
                              }
                            />
                          }
                          />
                      
                    </Tooltip>
                      ))}
                    </Avatar.Group>
                    </div>
                    
                  </div>
                    </div>
                    
                  </div>
                </div> */}
              </div>

            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-12">

                  <Table
                    onChange={this.handleSuggestionsTableChange}
                    loading={this.state.isListingLoading}
                    rowKey={(record) => record.refno}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultPageSize: 5,
                      pageSize: 5,
                      total:
                        this.state?.share_links_suggestions?.total > 0 &&
                        this.state.share_links_suggestions.total,
                      current: this.state.share_links_suggestions.current_page,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,

                      itemRender: itemRender,
                    }}
                    showHeader={false}
                    columns={addViewingStructure}
                    dataSource={
                      this.state?.share_links_suggestions?.data ? this.state?.share_links_suggestions?.data : []
                    }
                    rowSelection={rowSelectionTestShare}
                  />

                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Ref no</th>
                        <th scope="col">Unit no</th>
                        <th scope="col">Community</th>
                        <th scope="col">Subcommunity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.share_unitno_data.map((s_data) => (
                        <tr>
                          <th>{s_data.refno}</th>
                          <th>{s_data.unitno}</th>
                          <td>{s_data.community}</td>
                          <td>{s_data.sub_community}</td>
                        </tr>
                      ))}

                      {this.state.unitno_data && this.state.unitno_data.map((s_data) => (
                        <tr>
                          <th>{s_data.refno}</th>
                          <th>{s_data.unitno}</th>
                          <td>{s_data.community}</td>
                          <td>{s_data.sub_community}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </Modal>

        {/* Add View Modal */}

        <Modal
          width={window.isIpadView ? '100%' : '70vw'}
          title={<><i className="bi bi-calendar-plus"></i> Add a Viewing</>}
          open={this.state.isviewingOpen}
          //   onOk={this.handleViewingOk}
          onCancel={this.handleViewingCancel}
          confirmLoading={this.state.isViewing}
          style={{ height: window.isIpadView ? '700px' : 'unset', overflow: window.isIpadView ? 'auto' : 'unset', borderRadius: window.isIpadView ? '10px' : '' }}
          footer={<div className="d-flex w-100 flex-row">

            <div className="d-flex flex-row align-items-center ml-auto">

              <Button
                id="calllogbtn"
                onClick={this.handleViewingOk}
                confirmLoading={this.state.isViewing}
                type="primary" disabled={this.state.isViewingLoading}
                className="d-flex flex-row mt-0 mr-1" style={{ width: 'fit-content' }}
              >
                <i className="bi bi-add-lg mr-1"></i>{this.state.isViewingLoading ? <><Spin />Adding...</> : 'Add Viewing'}
              </Button>
              <Button
                onClick={this.handleViewingCancel}
                className="d-flex flex-row mt-0" style={{ width: 'fit-content' }}
              >
                <i className="bi bi-add-lg mr-1 mt-0"></i>Cancel
              </Button>
            </div>
          </div>}
        >

          <div className="row w-100">
            <div className="col-md-5 pl-0 pr-3 border-right ">
              <div className="col-md-12 d-flex flex-row w-100 px-0">
                <div className="col-12 px-0">
                  <div className="form-group">
                    <label className=""><ClockCircleOutlined className="pr-1" /> Date & Time:</label>
                    <DatePicker size="small"
                      // format="HH:mm DD MMMM, YYYY"
                      style={{ width: "100%" }}
                      value={(this.state.viewing_date != "") ? dayjs(this.state.viewing_date) : ""}
                      showTime={{
                        minuteStep: 15,
                      }}
                      onChange={this.handleViewingDate}
                      showNow={false}
                    />
                    {this.state.viewing_date == "" && this.state.checkErr && (
                      <label style={{ color: "red" }}>Date is required</label>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Ref No</label>
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={this.onSearchRefNo}
                  name="refno"
                  value={this.state.ref_def_nums}
                  placeholder="Select Refs no"
                />

                {/* <Select size="small"
                  style={{
                    width: "100%",
                  }}
                  mode={"multiple"}
                  value={this.state.ref_def_nums}
                  showSearch
                  allowClear
                  placeholder="Select Refs no"
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  onChange={this.handleRefsno}
                  onSearch={this.onSearchRefNo}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={this.state.ref_nums}
                /> */}
                {this.state.ref_nums == "" ||
                  (this.state.ref_def_nums.length == 0 &&
                    this.state.checkErr && (
                      <label style={{ color: "red" }}>empty</label>
                    ))}
              </div>
              <div className="row orgroup">
                <div className="col-12 d-flex flex-row">
                  <hr />
                  <p className="mb-0">Or</p>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="form-group">
                    <label className="">Unit no:</label>
                    <Input
                      style={{
                        width: "100%",
                      }}
                      onChange={this.handleSearchUintNo}
                      name="unitno"
                      value={this.state.viewing_unit_no}
                      placeholder="Select Unit no"
                    />

                    {/* <Select size="small"
                      style={{
                        width: "100%",
                      }}
                      value={this.state.viewing_unit_no}
                      showSearch
                      allowClear
                      placeholder="Select Unit no"
                      optionFilterProp="children"
                      onChange={this.handleUnitno}
                      onSearch={this.handleSearchUintNo}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.unit_nums}
                    /> */}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col-12 col-md-12 mt-1">
                      <div className="form-group">
                        <label className="">Add Agent:</label>
                        <div className="d-flex flex-row" style={{ gap: '0.4rem' }}>
                          <Select size="small"
                            style={{
                              width: "100%",
                            }}
                            mode="multiple"
                            value={this.state.viewing_agent}
                            showSearch
                            placeholder="Select Agent"
                            optionFilterProp="children"
                            onChange={this.handleViewingAgent}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={this.state.agents}
                          />
                        </div>
                        {this.state.viewing_agent.length == 0 && this.state.checkErr && (
                          <label style={{ color: "red" }}>Agent is required</label>
                        )}
                        <div className="row mt-2">
                          <Avatar.Group gap="0.7rem">
                            {this.state.agent_profile_picture && this.state.agent_profile_picture.map((s_data, index) => (
                              <Tooltip title={index} placement="top">
                                <Avatar
                                  icon={
                                    <img
                                      src={
                                        s_data == null ||
                                          s_data == ""
                                          ? baseurlImg +
                                          "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg"
                                          : this.isValidHttpUrl(s_data)
                                            ? s_data
                                            : baseurlImg +
                                            "lpcrm/public/uploads/clients/2036/users/" +
                                            s_data
                                      }
                                    />
                                  }
                                />
                                {/* <Avatar src={s_data} /> */}
                              </Tooltip>
                            ))}
                          </Avatar.Group>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              {/* <div className="row mb-2">
                <div className="col-6">
                  <div className="form-group">
                    <label className="">Community:</label>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      showSearch
                      placeholder="Search"
                      optionFilterProp="children"
                      // onChange={this.handleCommunityMatch}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.communities}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="">Sub Community:</label>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      // mode={"multiple"}

                      showSearch
                      maxTagCount={"responsive"}
                      placeholder="Search"
                      optionFilterProp="children"
                      //onChange={this.handleSubCommunityMatch}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.sub_communities}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="row mb-2">
                <div className="col-6">
                  <div className="form-group">
                    <label className="">Property:</label>
                    <select
                      name="property"
                      id="property"
                      className="form-control"
                    >
                      <option value=""></option>
                      <option selected="" value="386">
                        Burj Views A
                      </option>
                      <option value="237">Burj Views B</option>
                      <option value="283">Burj Views C</option>
                      <option value="274">Burj Views Podium</option>
                    </select>
                  </div>
                </div>
                
              </div>               */}
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-12">

                  <Table style={{ borderTop: '1px solid #151515' }}
                    onChange={this.handleSuggestionsTableChange}
                    loading={this.state.isListingLoading}
                    rowKey={(record) => record.refno}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultPageSize: 5,
                      pageSize: 5,
                      total:
                        this.state?.suggestions?.total > 0 &&
                        this.state.suggestions.total,
                      current: this.state.suggestions.current_page,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,

                      itemRender: itemRender,
                    }}
                    showHeader={false}
                    columns={addViewingStructure}
                    dataSource={
                      this.state?.suggestions?.data ? this.state?.suggestions?.data : []
                    }
                    rowSelection={rowSelectionTest}
                  />

                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Ref no</th>
                        <th scope="col">Unit no</th>
                        <th scope="col">Community</th>
                        <th scope="col">Subcommunity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.refno_data && this.state.refno_data.map((s_data) => (
                        <tr>
                          <th>{s_data.refno}</th>
                          <th>{s_data.unitno}</th>
                          <td>{s_data.community}</td>
                          <td>{s_data.sub_community}</td>
                        </tr>
                      ))}

                      {this.state.unitno_data && this.state.unitno_data.map((s_data) => (
                        <tr>
                          <th>{s_data.refno}</th>
                          <th>{s_data.unitno}</th>
                          <td>{s_data.community}</td>
                          <td>{s_data.sub_community}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </Modal>

        {/* Verification Call Modal */}

        <Modal
          title="Add Verification Call"
          open={this.state.isVerificationOpen}
          width={1000}
          centered
          // style={{ height: window.isIpadView ? '600px' : '', overflow: window.isIpadView ? 'auto' : '', borderRadius: window.isIpadView ? '10px' : '' }}
          onCancel={this.handleVerificationCallLogClose}
          footer={
            <>
              {this.state.call_loader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Spin style={{ alignSelf: "center" }} />
                </div>
              ) : (
                <Button
                  id="calllogbtn"
                  onClick={this.handleVerificationCallSubmit}
                  type="primary"
                  className="d-flex flex-row"
                >
                  <i className="bi bi-person-fill-add mr-1"></i>Add Verification
                  Details
                </Button>
              )}
            </>
          }
        >
          <Row span={24} className="verify-wrap" gutter={8}>
            <Col span={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Row span={24} className="w-100 addcallpopup-wrap" gutter={8}>
                <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="form-group w-100">
                    <label>Name:</label>
                    <EdiText
                      value={
                        this.state.lead_details && this.state.lead_details.owner
                          ? this.state.lead_details.owner
                          : ""
                      }
                      type="text"
                      onSave={this.handleSaveOwnerName}
                      className="leadetailsowner"
                    />
                  </div>
                </Col>
                <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="form-group w-100">
                    <label>
                      Contact Type<em>*</em>:
                    </label>
                    <div className="form-text-wrapper">
                      <EasyEdit
                        type={Types.SELECT}
                        value={
                          this.state.contact_type_requirement == undefined ||
                            this.state.contact_type_requirement == null ||
                            this.state.contact_type_requirement == ""
                            ? "selected"
                            : this.state.contact_type_requirement.toString()
                        }
                        // options={this.state.agents}
                        options={this.state.contact_types.map((e) => {
                          return {
                            label: e.label,
                            value: e.value.toString(),
                          };
                        })}
                        onSave={this.saveContactType}
                        saveButtonLabel={<CheckOutlined />}
                        cancelButtonLabel={<CloseOutlined />}
                        placeholder="Please select"
                        onValidate={(value) => {
                          return value != null;
                        }}
                        validationMessage="Add Contact Type"
                      />
                    </div>
                  </div>
                </Col>
                <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="form-group w-100">
                    <label>
                      Budget<em>*</em>:
                    </label>
                    <div className="form-text-wrapper">
                      <EasyEdit
                        type={Types.TEXT}
                        onSave={this.savePriceFirst}
                        saveButtonLabel={<CheckOutlined />}
                        cancelButtonLabel={<CloseOutlined />}
                        value={
                          this.state.price_first == null ||
                            this.state.price_first == ""
                            ? "Price"
                            : this.fAmountSaperator(this.state.price_first)
                        }
                        attributes={{ name: "awesome-input", id: 1 }}
                        onValidate={(value) => {
                          return value != null;
                        }}
                        validationMessage="Budget is required"
                      />
                    </div>
                  </div>
                </Col>
                <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="form-group w-100">
                    <label>Bedrooms:</label>
                    <div className="form-text-wrapper">
                      <EasyEdit
                        type={Types.SELECT}
                        value={this.state.requirement_bedroom}
                        options={[
                          { label: "Studio", value: "Studio" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                          { label: "3", value: "3" },
                          { label: "4", value: "4" },
                          { label: "5", value: "5" },
                          { label: "6", value: "6" },
                          { label: "7", value: "7" },
                          { label: "8", value: "8" },
                          { label: "9", value: "9" },
                          { label: "10", value: "10" },
                          { label: "11", value: "11" },
                          { label: "12", value: "12" },
                          { label: "13", value: "13" },
                          { label: "14", value: "14" },
                          { label: "15", value: "15" },
                        ]}
                        onSave={this.saveNewBedroom}
                        saveButtonLabel={<CheckOutlined />}
                        cancelButtonLabel={<CloseOutlined />}
                      />
                    </div>
                  </div>
                </Col>

                <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="form-group w-100">
                    <label>
                      Community<em>*</em>:
                    </label>
                    <div className="form-text-wrapper">
                      <EasyEdit
                        type={Types.SELECT}
                        value={
                          this.state.requirement_community == undefined ||
                            this.state.requirement_community == null
                            ? "Select"
                            : this.state.requirement_community
                        }
                        options={this.state.communities.map((e) => {
                          return {
                            label: e.label,
                            value: e.value.toString(),
                          };
                        })}
                        // onSave={this.saveAgent}
                        onSave={this.saveNewCommunity}
                        saveButtonLabel={<CheckOutlined />}
                        cancelButtonLabel={<CloseOutlined />}
                        onValidate={(value) => {
                          return value != null;
                        }}
                        validationMessage="Community field is required"
                      />
                    </div>
                  </div>
                </Col>
                <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="form-group w-100">
                    <label>Sub Community:</label>
                    <div className="form-text-wrapper">
                      <EasyEdit
                        type={Types.SELECT}
                        value={
                          this.state.requirement_sub_community == undefined ||
                            this.state.requirement_sub_community == null
                            ? "Select"
                            : this.state.requirement_sub_community.toString()
                        }
                        options={this.state.requirement_sub_communities.map(
                          (e) => {
                            return {
                              label: e.label,
                              value: e.value.toString(),
                            };
                          }
                        )}
                        onSave={this.saveNewSubCommunity}
                        saveButtonLabel={<CheckOutlined />}
                        cancelButtonLabel={<CloseOutlined />}
                      />
                    </div>
                  </div>
                </Col>
                {/* {this.state.contact_type_requirement.toString()=="1"? */}
                <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="form-group w-100">
                    <label>
                      Move In Date: <em>*</em>
                    </label>
                    <div className="form-text-wrapper">
                      <EasyEdit
                        type={Types.DATE}
                        value={
                          <Moment format='DD MMM YYYY'>{this.state.new_move_in_date}</Moment>
                          // (this.state.new_move_in_date == "0000-00-00 00:00:00" || this.state.new_move_in_date == null)?
                          // "Select date"
                          // :
                          // <Moment format='DD MMM YYYY'>{this.state.new_move_in_date}</Moment>
                        }
                        onSave={this.saveNewMoveInDate}
                        saveButtonLabel={<CheckOutlined />}
                        cancelButtonLabel={<CloseOutlined />}
                        onValidate={(value) => {
                          return value != null;
                        }}
                        validationMessage="Move In Date is required"
                      />
                    </div>
                  </div>
                </Col>
                {/* // :null} */}
                {this.state.contact_type_requirement.toString() == "2" ? (
                  <>
                    <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="form-group w-100 justify-content-start">
                        <label>Buyer Type: </label>
                        <div className="">
                          <Radio.Group
                            buttonStyle="solid"
                            className="flex-row"
                            onChange={this.handleBuyerType}
                          >
                            <Radio.Button value="End User">
                              End User
                            </Radio.Button>
                            <Radio.Button value="Investor">
                              Investor
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                      </div>
                    </Col>
                    <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24} className="flex-row">
                      <div className="form-group w-100">
                        <label>Payment Type:</label>
                        <div className="">
                          <Radio.Group
                            buttonStyle="solid"
                            className="flex-row"
                            onChange={this.handlePayment}
                          >
                            <Radio.Button value="Cash">Cash</Radio.Button>
                            <Radio.Button value="Finance">Finance</Radio.Button>
                          </Radio.Group>
                        </div>
                      </div>
                      {/* If Finance */}
                    </Col>

                    <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="form-group w-100 justify-content-start">
                        <label>Availability for viewings: </label>
                        <Input onChange={this.handleAvailabilityForViewings} />
                      </div>
                    </Col>
                    {this.state.ifFinanceYes ? (
                      <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <div className="form-group w-100">
                          <label>Pre-Approved?</label>
                          <div className="">
                            <Radio.Group
                              buttonStyle="solid"
                              className="flex-row"
                              onChange={this.handlePreApproved}
                            >
                              <Radio.Button value="Yes">Yes</Radio.Button>
                              <Radio.Button value="No">No</Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </>
                ) : this.state.contact_type_requirement.toString() == "1" ? (
                  <>
                    <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="form-group w-100 justify-content-start">
                        <label>Number of cheques:</label>
                        <div className="">
                          <Select
                            onChange={this.handleNumberOfCheques}
                            options={[
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                              { value: "4", label: "4" },
                              { value: "5", label: "5" },
                              { value: "6", label: "6" },
                              { value: "7", label: "7" },
                              { value: "8", label: "8" },
                              { value: "9", label: "9" },
                              { value: "10", label: "10" },
                              { value: "11", label: "11" },
                              { value: "12", label: "12" },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col span={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="form-group w-100">
                        <label>Currently living:</label>
                        <div className="form-text-wrapper">
                          <EasyEdit
                            type={Types.SELECT}
                            value={
                              this.state.requirement_community == undefined ||
                                this.state.requirement_community == null
                                ? "Select"
                                : this.state.requirement_community
                            }
                            options={this.state.communities.map((e) => {
                              return {
                                label: e.label,
                                value: e.value.toString(),
                              };
                            })}
                            // onSave={this.saveAgent}
                            onSave={this.saveCurrentLive}
                            saveButtonLabel={<CheckOutlined />}
                            cancelButtonLabel={<CloseOutlined />}
                            onValidate={(value) => {
                              return value != null;
                            }}
                            validationMessage="Community field is required"
                          />
                        </div>
                      </div>
                    </Col>
                  </>
                ) : null}
              </Row>

              <Row span={24} className="w-100" gutter={8}>
                <Col span={24} className="w-100 form-group">
                  <label className="font-10">Call Note:</label>
                  <Mentions
                    style={{
                      fontSize: 10,
                      width: "100%",
                      fontFamily: "Poppins-Regular",
                    }}
                    value={this.state.call_log}
                    onChange={this.onChangeCallNote}
                    onSelect={this.onSelectCallMentions}
                    rows={3}
                    options={this.state.userClients}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Table
                onChange={handleListingTableChange}
                loading={this.state.isListingsLoading}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: window.isMobileView ? 12 : 5,
                  pageSize: window.isMobileView ? 12 : 5,
                  total:
                    this.state?.listings?.total > 0 &&
                    this.state.listings.total,
                  current: this.state.listings.current_page,
                  showTotal: (total, range) =>
                    window.isMobileView ? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,

                  itemRender: itemRender,
                }}
                showHeader={false}
                columns={matchverifycol}
                dataSource={
                  this.state?.listings?.data ? this.state?.listings?.data : []
                }
                rowSelection={{ type: "radio", ...rowSelectionForClient }}
              />
            </Col>
          </Row>
        </Modal>
          {/* onCancel={this.handleCallLogModalCancel} */}
          <Modal title={"Send Email"} open={this.state.isEmailmodal} width={650} className="ModelHeight" onCancel={this.handleEmailHideModal} onOk={this.handleEmailHideModal}> 
            <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <input type="text" placeholder="Subject" className="form-control input-sm"  onChange={this.EmailSubject}  value={this.state.email_subject}/>
                </div>
                <div className="form-group mt-2">
                  <Mentions rows={3} type="text" placeholder="Message" className="form-control input-sm"  onChange={this.EmailMessage}  value={this.state.email_message}/>
                </div>
              </div>
              <div class="col-lg-12 col-12 d-grid">
                  <button onClick={this.SendEmail} className="btn btn-dark" type="submit" href="javascript:void(0)" data-href>
                  {" "}
                  Send Email {" "}
                </button>
              </div>
            </div>
          </div>
        </Modal> 


        <Modal
          title={"Add " + this.state.calltype}
          open={this.state.isCallLogOpen}
          width={800}
          className="ModelHeight"
          onCancel={this.handleCallLogModalCancel}
          footer={
            <>
              {this.state.call_loader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Spin style={{ alignSelf: "center" }} />
                </div>
              ) : (
                <Button style={{width:'100%', height:46}}
                  id="calllogbtn"
                  onClick={this.handleCallSubmit}
                  type="primary"
                  className="d-flex flex-row mb-1"
                >
                  <i className="bi bi-plus me-1 fs-5" /> {"Add " + this.state.calltype + ' log'}
                </Button>
              )}
            </>
          }
        >
          {user_id == 388 || role == 1 ? (
            <Row span={24} className="w-100 addcallpopup-wrap px-3 pt-2 pb-1" gutter={8}>
              <Checkbox
                checked={this.state.offplan}
                onChange={this.handleOffplan}
              >
                Offplan
              </Checkbox>
            </Row>
          ) : null}

          <Row span={24} className="w-100 addcallpopup-wrap px-3" gutter={8}>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <div className="form-group w-100">
                <label>Name:</label>
                <EdiText
                  value={
                    this.state.lead_details && this.state.lead_details.owner
                      ? this.state.lead_details.owner
                      : ""
                  }
                  type="text"
                  onSave={this.handleSaveOwnerName}
                  className="leadetailsowner"
                />
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <div className="form-group w-100">
                <label>
                  Contact Type<em>*</em>:
                </label>
                <Select
  value={
    this.state.contact_type_requirement === undefined ||
    this.state.contact_type_requirement === null ||
    this.state.contact_type_requirement === ""
      ? "selected"
      : this.state.contact_type_requirement.toString()
  }
  onChange={this.saveContactType}
  placeholder="Please select"
  onBlur={() => {
    if (
      this.state.contact_type_requirement === undefined ||
      this.state.contact_type_requirement === null ||
      this.state.contact_type_requirement === ""
    ) {
      // Handle validation error
      console.error("Add Contact Type");
    }
  }}
  popupClassName="contact-type-select-dropdown"
>
  {this.state.contact_types.map((e) => (
    <Option key={e.value.toString()} value={e.value.toString()}>
      {e.label}
    </Option>
  ))}
</Select>
              </div>
            </Col>
            {this.state.contact_type_requirement.toString() == "1" ? (
              <Col span={12} lg={12} md={12} sm={24} xs={24}>
                <div className="form-group w-100">
                  <label>
                    Move In Date: <em>*</em>
                  </label>
                  <div className="form-text-wrapper">
                    <EasyEdit
                      type={Types.DATE}
                      value={
                        this.state.requirement_move_in_date ==
                          "0000-00-00 00:00:00" ||
                          this.state.requirement_move_in_date == null ? (
                          "Select date"
                        ) : (
                          <Moment format="DD MMM YYYY">
                            {this.state.requirement_move_in_date}
                          </Moment>
                        )
                      }
                      onSave={this.saveMoveInDate}
                      saveButtonLabel={<CheckOutlined />}
                      cancelButtonLabel={<CloseOutlined />}
                      onValidate={(value) => {
                        return value != null;
                      }}
                      validationMessage="Move In Date is required"
                    />
                  </div>
                </div>
              </Col>
            ) : null}
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <div className="form-group w-100">
                <label>
                  Budget<em>*</em>:
                </label>
                <div className="form-text-wrapper">
                  <EasyEdit
                    type={Types.TEXT}
                    value={
                      this.state.price_requirement == null ||
                        this.state.price_requirement == ""
                        ? "Price"
                        : this.fAmountSaperator(this.state.price_requirement)
                    }
                    onSave={this.savePrice}
                    saveButtonLabel={<CheckOutlined />}
                    cancelButtonLabel={<CloseOutlined />}
                    attributes={{ name: "awesome-input", id: 1 }}
                    onValidate={(value) => {
                      return value != null;
                    }}
                    validationMessage="Budget is required"
                  />
                </div>
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <div className="form-group w-100">
                <label>
                  Community<em>*</em>:
                </label>
  <Select
    showSearch
    value={
      this.state.requirement_community === undefined || this.state.requirement_community === null
        ? "Select"
        : this.state.requirement_community
    }
    onChange={this.saveCommunity}
    onBlur={() => {
      if (this.state.requirement_community === undefined || this.state.requirement_community === null) {
        // Handle validation error
        console.error("Community field is required");
      }
    }}
    filterOption={(input, option) =>
      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    optionFilterProp="label"
    dropdownClassName="community-select-dropdown"
  >
    {this.state.communities.map((e) => (
      <Option key={e.value.toString()} value={e.value.toString()} label={e.label}>
        {e.label}
      </Option>
    ))}
  </Select>
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <div className="form-group w-100">
                <label>Sub Community:</label>
                <Select
  showSearch
  value={
    this.state.requirement_sub_community === undefined ||
    this.state.requirement_sub_community === null
      ? "Select"
      : this.state.requirement_sub_community.toString()
  }
  onChange={this.saveSubCommunity}
  placeholder="Select Sub Community"
  onBlur={() => {
    if (
      this.state.requirement_sub_community === undefined ||
      this.state.requirement_sub_community === null
    ) {
      // Handle validation if needed
      console.error("Sub Community selection is required");
    }
  }}
  filterOption={(input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  optionFilterProp="label"
  popupClassName="sub-community-select-popup"
>
  {this.state.requirement_sub_communities.map((e) => (
    <Option key={e.value.toString()} value={e.value.toString()} label={e.label}>
      {e.label}
    </Option>
  ))}</Select>
              </div>
            </Col>
            <Col span={12} >
              <div className="form-group w-100">
                <label>Bedrooms:</label>
                <div className="form-text-wrapper">
                  <EasyEdit
                    type={Types.SELECT}
                    value={this.state.requirement_bedroom}
                    options={[
                      { label: "Studio", value: "Studio" },
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                      { label: "8", value: "8" },
                      { label: "9", value: "9" },
                      { label: "10", value: "10" },
                      { label: "11", value: "11" },
                      { label: "12", value: "12" },
                      { label: "13", value: "13" },
                      { label: "14", value: "14" },
                      { label: "15", value: "15" },
                    ]}
                    onSave={this.saveBedroom}
                    saveButtonLabel={<CheckOutlined />}
                    cancelButtonLabel={<CloseOutlined />}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row span={24} className="w-100 px-3">
            <Col span={24} className="w-100 form-group">
              <label className="font-10">Call Note:</label>
              <Mentions
                style={{
                  fontSize: 10,
                  width: "100%",
                  fontFamily: "Poppins-Regular",
                }}
                value={this.state.call_log}
                onChange={this.onChangeCallNote}
                onSelect={this.onSelectCallMentions}
                rows={3}
                options={this.state.userClients}
              />
            </Col>
          </Row>
        </Modal>
        {this.state.isLoading ? (
          <div className="w-100 d-flex my-5 py-5">
            <Spin
              className="spinnerOp mx-auto"
              size="large"
              indicator={<Spin size="large" />}
            />
          </div>
        ) : (
          <>
            {/* Add another phone number modal */}

            <Modal
              title="Add secondary phone number"
              className="newleadwrap"
              centered
              open={this.state.isAddAnotherPhoneNumber}
              onOk={this.handleAddNewLeadClose}
              onCancel={this.handleAddAnotherPhoneNumberClose}
              footer={
                <>
                  <Button
                    type="text"
                    className="d-flex flex-row"
                    style={{ height: "36px" }}
                    onClick={this.handleAddAnotherPhoneNumberClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="d-flex flex-row"
                    onClick={this.handleAddLead}
                  >
                    Add Number
                  </Button>
                </>
              }
            >
              <Row gutter={16}>
                <Col span={24}>
                  <div className="d-flex flex-row align-items-center">
                    <p
                      className="mr-2 mb-0 font-12 font-bold"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Search Phone No:
                    </p>
                    <Input
                      placeholder=""
                      allowClear
                      // onChange={this.handleSearchOwner}
                      style={{
                        height: "32px",
                        borderRadius: "0.3rem",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Modal>
            <Space direction="vertical" size={16} style={{ background: "#f5f5f5" }}>
              <Row className="navigation-container">
                <Col span={4}>
                  <Button id="prev" style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={this.goToPrev}
                    type="primary"
                    className="d-flex flex-row"
                  >
                    <i className="bi bi-arrow-left mr-1 ml-1"></i>
                    Previous
                  </Button>
                </Col>
                {/* <Col  span={16} style={{
                               
                               display: "flex",
                               justifyContent: "center",
                             }}>
                {this.state.isTimer?
                                
                                <div style={{ display:'flex',alignItems:'center',justifyContent:'center'}}>
     <span style={{fontSize:20}}>This lead will expire in:  </span>  <span style={{color:'red',fontSize:'20px',marginLeft:'8px'}}>{timeRemaining.days} day(s) {timeRemaining.hours}  hour(s) {timeRemaining.minutes} minute(s) {timeRemaining.seconds} second(s)</span>
    </div>:null}
                </Col> */}
                <Col span={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    id="next"
                    style={{

                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={this.goToNext}
                    type="primary"
                    className="d-flex flex-row"
                  >

                    Next
                    <i className="bi bi-arrow-right mr-1 ml-1"></i>
                  </Button>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={6} xl={6} lg={6} md={24} sm={24} xs={24} className="lead-section-wrapper position-relative ">
                  <Row>
                    {/* Contact Details */}
                    {role == 1 || isUserFound || this.state.collaborators.some((data) => data.user_id == user_id) || user_id.toString() == this.state.requirement_agent_id.toString() || this.state.lead_details.lead_stage == 10 || this.state.lead_details.lead_stage == 11 || this.state.lead_details.lead_status == 1 || this.state.lead_details.lead_agent == 430 ? <>


                      <Col span={24} xl={24} lg={24} md={12} sm={12} xs={24} >

                        <Card
                          className={`${this.state.lead_details.lead_status === 0 ? "leads-details-section blur-row" : "leads-details-section"} ${window.isIpadView ? "mt-3" : ""}`}

                          title="Client Details" style={{ height: window.isIpadView ? '95%' : '' }}
                          // style={{ height: "525px", overflow: "auto" }}
                          extra={
                            this.state.lead_list_details &&
                            this.state.lead_list_details.is_verified &&
                            this.state.lead_list_details.is_verified != null && (
                              <span
                                style={{ width: "100%" }}
                                className="working pstat"
                              >
                                Verified
                              </span>
                            )
                          }
                        >
                          <>

                            {this.state.lead_details.lead_status == 0 ?
                              <div className="blurContact">
                                <div className="d-flex flex-row">
                                  <Button className='btnAccept' onClick={this.onClickAccept}>Accept</Button>
                                  <Button className='btnDecline' onClick={this.onClickReject}>Reject</Button>
                                </div>
                                {role_name == "Admin" || role_name == "Super Admin" &&
                                  <Select style={{ minWidth: '200px', height: 36, borderRadius: 0 }}
                                    showSearch className='selectAgentwrap'
                                    value={this.state.assigned_agent ? this.state.assigned_agent : 'Reassign to'}
                                    allowClear
                                    placeholder="Select Assigned Agent"
                                    maxTagCount='responsive'
                                    onChange={this.onChangeAssignAgent}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={
                                      this.state.allAgents && this.state.allAgents.length > 0 ? this.state.allAgents.map(item => ({
                                        label: item.label,
                                        value: item.value,
                                      })) :
                                        this.state.agents
                                    }
                                  />
                                }
                              </div>
                              : null}
                            <div className="cdets">
                              <Row gutter={16}>
                                {/* <Col xs={24} sm={12} md={10}>
                          <div
                            className="d-flex flex-column"
                            style={{ alignItems: "center" }}
                          >
                            <Avatar
                              style={{
                                boxShadow: "0px 0px 10px lightgrey",
                                borderRadius: "10%",
                              }}
                              className="client-profile"
                              size={80}
                              src={
                                this.state.lead_list_details &&
                                this.state.lead_list_details.owner_profile
                                  ? this.isValidHttpUrl(
                                      this.state.lead_list_details &&
                                        this.state.lead_list_details
                                          .owner_profile
                                    )
                                    ? this.state.lead_list_details.owner_profile
                                    : `data:image/png;base64,${this.state.lead_list_details.owner_profile}`
                                  : ""
                              }
                              icon={<i className="bi bi-person-bounding-box" />}
                            />
                          </div>
                        </Col> */}
                                <Col span={24}>
                                  <div style={{ marginBottom: "10px" }}>
                                    <EasyEdit
                                      type={Types.TEXT}
                                      value={
                                        this.state.lead_list_details &&
                                          this.state.lead_list_details.owner
                                          ? this.state.lead_list_details.owner
                                          : null
                                      }
                                      onSave={this.handleSaveOwnerName}
                                      saveButtonLabel={<CheckOutlined />}
                                      cancelButtonLabel={<CloseOutlined />}
                                      placeholder="Please Enter Name"
                                    />
                                  </div>

                                  <div style={{ marginBottom: "10px" }}>
                                    <EasyEdit
                                      type={Types.TEXT}
                                      value={
                                        this.state.lead_list_details &&
                                          this.state.lead_list_details.owner_email
                                          ? this.state.lead_list_details.owner_email
                                          : null
                                      }
                                      onSave={this.handleSaveOwnerEmail}
                                      saveButtonLabel={<CheckOutlined />}
                                      cancelButtonLabel={<CloseOutlined />}
                                      placeholder="Please Enter Email"
                                    />
                                  </div>
                                <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                  <label style={{ marginBottom: "10px" }}>
                                    {this.state.lead_list_details &&
                                      this.state.lead_list_details.mobile
                                      ? this.state.lead_list_details.mobile
                                      : "-"}
                                  </label>
                                  
                                  {this.state.lead_list_details && this.state.lead_list_details.mobile && <StyledGreenButton href={`https://api.whatsapp.com/send/?phone=${this.state.lead_list_details.mobile.replace(/\+/g, '')}`} target="_blank" rel="noopener noreferrer"><WhatsAppOutlined />Send message</StyledGreenButton>
                                  }
                                  </div>

                                  {/* <Button
                            type="primary"
                            className="mt-0 font-bold pl-0"
                            style={{
                              width: "fit-content",
                              paddingRight: "4px",
                            }}
                            onClick={this.handleAddAnotherPhoneNumber}
                          >
                            <i className="bi bi-telephone-plus mr-2 ml-2"></i>
                          </Button> */}
                                </Col>
                              </Row>
                              {/* <Row className="icon-container-styling">
                        <Col span={24}>
                          <div className="icon-container">
                            <img
                              src={baseurl + "/icons/gmail.png"}
                              className="mr-2"
                              style={{ height: "20px" }}
                            ></img>
                             {this.state.gmail != [] &&
                            (this.state.gmail.registered == 1 ||
                              this.state.gmail.registered == true) ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <Link to="#" className="social-media-link">
                                    <Avatar
                                      style={{
                                        boxShadow: "0px 0px 10px lightgrey",
                                      }}
                                      size={35}
                                      // src={
                                      //   this.state.lead_list_details &&
                                      //   this.state.lead_list_details.owner_profile
                                      //     ? this.isValidHttpUrl(
                                      //         this.state.lead_list_details &&
                                      //           this.state.lead_list_details
                                      //             .owner_profile
                                      //       )
                                      //       ? this.state.lead_list_details.owner_profile
                                      //       : `data:image/png;base64,${this.state.lead_list_details.owner_profile}`
                                      //     : ""
                                      // }
                                      icon={
                                        <i className="bi bi-person-bounding-box" />
                                      }
                                    />
                                  </Link>
                                </div>
                                <label className="ml-2">
                                <Link to="#" className="social-media-link">
                                  - Click Here
                                  </Link>
                                  {/* <Link to="#" className="social-media-link">
                                    {this.state.lead_list_details &&
                                    this.state.lead_list_details.owner
                                      ? this.state.lead_list_details.owner
                                      : null}
                                  </Link> 
                                </label>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </Col>
                        {/* <Col span={24}>
                          <div className="icon-container">
                            <img
                              src={baseurl + "/icons/linkedin.png"}
                              className="mr-2"
                              style={{ height: "25px" }}
                            ></img>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="d-flex flex-column">
                                <Avatar
                                  style={{
                                    boxShadow: "0px 0px 10px lightgrey",
                                  }}
                                  size={40}
                                  icon={<i className="bi bi-person-bounding-box" />}
                                />
                              </div>
                              <label className="ml-2">Josh Ebden</label>
                            </div>
                            <label
                              style={{
                                border: "1px solid lightgray",
                                boxShadow: "1px 1px 7px lightgray",
                                borderRadius: "8px",
                                width: "120px",
                                padding: "5px",
                                fontSize: "10px",
                                color: "grey",
                              }}
                              className="ml-2"
                            >
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                10 days ago
                              </span>
                            </label>
                          </div>
                        </Col> 
                        <Col span={24}>
                          <div className="icon-container">
                            <img
                              src={baseurl + "/icons/skype.png"}
                              style={{ height: "27px", marginRight: "7px" }}
                            ></img>
                           {this.state.skype != [] &&
                            (this.state.skype.registered == 1 ||
                              this.state.skype.registered == true) ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <Link to="#" className="social-media-link">
                                    <Avatar
                                      style={{
                                        boxShadow: "0px 0px 10px lightgrey",
                                      }}
                                      className="skype-image"
                                      size={35}
                                      src={
                                        this.state.skype &&
                                        this.state.skype.photo
                                      }
                                      icon={
                                        <i className="bi bi-person-bounding-box" />
                                      }
                                    />
                                  </Link>
                                </div>
                                <label className="ml-2">
                                  <Link to="#" className="social-media-link">
                                    {this.state.skype && this.state.skype.name
                                      ? this.state.skype.name
                                      : this.state.lead_list_details.owner}
                                  </Link>
                                </label>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </Col>
                       {/* instagram

                       <Col span={24}>
                          <div className="icon-container">
                            <img
                              src={baseurl + "/icons/instagram.webp"}
                              style={{ height: "27px", marginRight: "7px" }}
                            ></img>
                            {this.state.instagram ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <Link to="#" className="social-media-link">
                                    <Avatar
                                      style={{
                                        boxShadow: "0px 0px 10px lightgrey",
                                      }}
                                      size={35}
                                      //  src={
                                      //   this.state.lead_list_details &&
                                      //   this.state.lead_list_details.owner_profile
                                      //     ? this.isValidHttpUrl(
                                      //         this.state.lead_list_details &&
                                      //           this.state.lead_list_details
                                      //             .owner_profile
                                      //       )
                                      //       ? this.state.lead_list_details.owner_profile
                                      //       : `data:image/png;base64,${this.state.lead_list_details.owner_profile}`
                                      //     : ""
                                      // }
                                      icon={
                                        <i className="bi bi-person-bounding-box" />
                                      }
                                    />
                                  </Link>
                                </div>
                                <label className="ml-2">
                                <Link to="#" className="social-media-link">
                                - Click Here
                                  </Link>
                                  {/* <Link to="#" className="social-media-link">
                                    {this.state.lead_list_details &&
                                    this.state.lead_list_details.owner
                                      ? this.state.lead_list_details.owner
                                      : null}
                                  </Link> 
                                </label>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </Col>

                        {/* twitter

                        <Col span={24}>
                          <div className="icon-container">
                            <img
                              src={baseurl + "/icons/twitterx.png"}
                              style={{ height: "27px", marginRight: "7px" }}
                            ></img>
                            {this.state.twitter ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <Link to="#" className="social-media-link">
                                    <Avatar
                                      style={{
                                        boxShadow: "0px 0px 10px lightgrey",
                                      }}
                                      size={35}
                                      //  src={
                                      //   this.state.lead_list_details &&
                                      //   this.state.lead_list_details.owner_profile
                                      //     ? this.isValidHttpUrl(
                                      //         this.state.lead_list_details &&
                                      //           this.state.lead_list_details
                                      //             .owner_profile
                                      //       )
                                      //       ? this.state.lead_list_details.owner_profile
                                      //       : `data:image/png;base64,${this.state.lead_list_details.owner_profile}`
                                      //     : ""
                                      // }
                                      icon={
                                        <i className="bi bi-person-bounding-box" />
                                      }
                                    />
                                  </Link>
                                </div>
                                <label className="ml-2">
                                  {/* <Link to="#" className="social-media-link">
                                    {this.state.lead_list_details &&
                                    this.state.lead_list_details.owner
                                      ? this.state.lead_list_details.owner
                                      : null}
                                  </Link>
                                  <Link to="#" className="social-media-link">
                                  - Click Here
                                  </Link>
                                </label>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                          <Col span={24}>
                          <div className="icon-container">
                            <img
                              src={baseurl + "/icons/whatsapp.webp"}
                              className="mr-2"
                              style={{ height: "27px" }}
                            ></img>
                            {this.state.whatsapp != [] &&
                            (this.state.whatsapp.registered == 1 ||
                              this.state.whatsapp.registered == true) ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <a href={`https://wa.me/${this.state.owner_mobile}`} className="social-media-link" target="_blank">
                                    <Avatar
                                      style={{
                                        boxShadow: "0px 0px 10px lightgrey",
                                      }}
                                      size={35}
                                      src={
                                        this.state.whatsapp &&
                                        this.state.whatsapp.photo
                                          ? this.isValidHttpUrl(
                                              this.state.whatsapp &&
                                                this.state.whatsapp.photo
                                            )
                                            ? this.state.whatsapp.photo
                                            : `data:image/png;base64,${this.state.whatsapp.photo}`
                                          : ""
                                      }
                                      icon={
                                        <i className="bi bi-person-bounding-box" />
                                      }
                                    />
                                  </a>
                                </div>
                                <label className="ml-2">
                                <a href={`https://wa.me/${this.state.owner_mobile}`} className="social-media-link" target="_blank">
                               - Click Here
                                    {/* {this.state.lead_list_details &&
                                    this.state.lead_list_details.owner
                                      ? this.state.lead_list_details.owner
                                      : null}
                                  </a>
                                </label>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </Col>

                        <Col span={24}>

                        <div className="icon-container">
                            <img
                               src={baseurl + "/icons/telegram.png"}
                               style={{ height: "27px", marginRight: "7px" }}
                            ></img>
                            {this.state.telegram ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="d-flex flex-column">
                                  <Link to="#" className="social-media-link">
                                    <Avatar
                                      style={{
                                        boxShadow: "0px 0px 10px lightgrey",
                                      }}
                                      size={35}
                                      icon={
                                        <i className="bi bi-person-bounding-box" />
                                      }
                                    />
                                  </Link>
                                </div>
                                <label className="ml-2">
                                <Link to="#" className="social-media-link">
                                - Click Here
                                  </Link>
                                  {/* <Link to="#" className="social-media-link">
                                    {this.state.lead_list_details &&
                                    this.state.lead_list_details.owner
                                      ? this.state.lead_list_details.owner
                                      : null}
                                  </Link>
                                </label>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </Col>
                        </Col>
                      </Row> */}
                            </div>
                          </>
                        </Card>
                      </Col>



                    </> : null}
                    <Col span={24} xl={24} lg={24} md={12} sm={12} xs={24} >
                      <Row className="mt-3"> 
                        <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24} >

                          <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24} >
                            {/* Other Enquires */}

                            {window.isIpadView ? <>
                              <Collapse accordion activeKey={this.state.activePanel} onChange={this.handlePanelChange.bind(1)} className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                                <Collapse.Panel header={<p className='mr-1 mb-0 font-16 medium font-bold' style={{ whiteSpace: "nowrap" }}>Other Enquires</p>} key="1">
                                  {otherEquires}
                                </Collapse.Panel>
                              </Collapse>
                            </>
                              : <Card
                                className={window.isIpadView ? window.isMobileView ? 'mt-3 leads-details-section otherinquiries' : "mr-3 mt-3 leads-details-section otherinquiries" : "leads-details-section mt-3 otherinquiries"}
                                title="Other Enquires " style={{ height: window.isIpadView ? '95%' : '' }}
                              >{otherEquires} 
                              </Card>
                              }

                            {/* Add to Verification Call Log 
                  <Card className="leads-details-section mt-3">
                    <>
                      {this.state.lead_details.verified == 1 ? null : (
                        <div
                          className="editleadwrap addtoverification"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {!this.state.lead_details.verifier ||
                          this.state.lead_details.verifier == null ||
                          this.state.lead_details.verifier == "" ? (
                            <Button
                              type="primary"
                              size="large"
                              className="mt-0 font-bold pl-0"
                              style={{ padding: "18px" }}
                              onClick={this.handleAddVerification}
                            >
                              <i className="bi bi-telephone-plus mr-2 ml-2"></i>
                              Add to Verification Call Log
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              size="large"
                              className="mt-0 font-bold pl-0"
                              style={{ padding: "18px" }}
                              onClick={this.handleRemoveVerification}
                            >
                              <i className="bi bi-telephone-plus mr-2 ml-2"></i>
                              Remove Verification Call Log
                            </Button>
                          )}
                        </div>
                      )}
                    </>
                  </Card>*/}
                      </Col>

                           {/* <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                              <Card className="">
                                <Button style={{ margin: 0 }} type="primary" className="d-flex flex-row align-items-center m-0" onClick={this.handelDocuments('add_documents') }> <i className="bi bi-plus me-1 fs-5" />{" "} <span className="font-12">Add Documents</span>
                            </Button>
                              </Card>
                          </Col>  */}
                            <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                              <Card className={window.isIpadView ? "mt-3 leads-details-section" : "leads-details-section mt-3"} title="Leads Details" style={{ height: window.isIpadView ? '95%' : '' }}>
                                <p className="badge badge-info" style={{width:'100%' }}>
                                  {this.state.utm_campaign ? `Utm-Campaign: ${this.state.utm_campaign}` : ""}
                                </p>
                                <p className="badge badge-info" style={{width:'100%' }}>
                                   {this.state.utm_source ? `Utm-Source: ${this.state.utm_source}` : ""}
                                </p>
                                 <p className="badge badge-info" style={{width:'100%' }}>
                                   {this.state.keyword ? `KeyWord: ${this.state.keyword}` : ""}
                                </p>
                                 <p className="badge badge-info" style={{width:'100%' }}>
                                   {this.state.campaignid ? `Campaign Name: ${this.state.campaignid}` : ""}
                                </p>
                                 <p className="badge badge-info" style={{width:'100%' }}>
                                   {this.state.adgroupid ? `Ads-Group Name: ${this.state.adgroupid}` : ""}
                                </p>
                                 <p className="badge badge-info" style={{width:'100%' }}>
                                   {this.state.device ? `Device Name: ${this.state.device}` : ""}
                                </p>
                                <p className="badge badge-info" style={{width:'100%' }}>
                                   {this.state.referral ? `Referral-URL: ${this.state.referral}` : ""}
                                </p>
                              </Card>
                             </Col>

                            <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                              <Card className={window.isIpadView ? "mt-3 leads-details-section" : "leads-details-section mt-3"} title="Leads Documents" style={{ height: window.isIpadView ? '95%' : '' }}>
                                <Button style={{ width: '100%', margin: 0 }} id="documentsmodal" type="primary" onClick={this.handleDocumentsModal('documents')} className="d-flex flex-row align-items-center m-0"> <i className="bi bi-plus me-1 fs-5" />{" "}
                                <span className="font-12">Add  Lead Documents</span>
                               </Button>
                                 <br/>
                               <Button style={{ width: '100%', marginTop: '4x', }} id="viewDocumentsModel" type="primary" onClick={this.handleViewDocumentsModal('viewdocuments')} className="d-flex flex-row align-items-center m-0"> <i className="bi bi-eye me-1 fs-5" />{" "}
                                   <span className="font-12">View Documents</span>
                               </Button>
                              </Card>
                             </Col>



                             

                          <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24} >
                            {window.isIpadView ? <>
                              <Collapse accordion activeKey={this.state.activePanel} onChange={this.handlePanelChange.bind(2)} className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                                <Collapse.Panel header={<p className='mr-1 mb-0 font-16 medium font-bold' style={{ whiteSpace: "nowrap" }}>Referral</p>} key="2">
                                  {referral}
                                </Collapse.Panel>
                              </Collapse>
                            </>
                              :
                              <Card className={window.isIpadView ? "mt-3 leads-details-section" : "leads-details-section mt-3"} title="Referral" style={{ height: window.isIpadView ? '95%' : '' }}>
                                {referral}
                              </Card>
                              }
                          </Col>


                          <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24} >
                            {/* follow up */}
                            {window.isIpadView ? <>
                              <Collapse accordion activeKey={this.state.activePanel} onChange={this.handlePanelChange.bind(3)} className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                                <Collapse.Panel header={<p className='mr-1 mb-0 font-16 medium font-bold' style={{ whiteSpace: "nowrap" }}>Follow up</p>} key="3">
                                  {followUp}
                                </Collapse.Panel>
                              </Collapse>
                            </>
                              :
                              <Card className={window.isIpadView ? window.isMobileView ? 'mt-3 leads-details-section' : "mr-3 mt-3 leads-details-section" : "leads-details-section mt-3"} title="Follow up" style={{ height: window.isIpadView ? '95%' : '' }}>
                                {followUp}
                              </Card>
                            }
                          </Col>

                          <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24} >
                            {/* Collaborators */}
                            {window.isIpadView ? <>
                              <Collapse accordion activeKey={this.state.activePanel} onChange={this.handlePanelChange.bind(4)} className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                                <Collapse.Panel header={<p className='mr-1 mb-0 font-16 medium font-bold' style={{ whiteSpace: "nowrap" }}>Collaborators</p>} key="4">
                                  {collaborators}
                                </Collapse.Panel>
                              </Collapse>
                            </>
                              :
                              <Card className={window.isIpadView ? "mt-3 leads-details-section" : "leads-details-section mt-3"} title="Collaborators" style={{ height: window.isIpadView ? '95%' : '' }}>
                                {collaborators}
                              </Card>
                            }
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>









                </Col>

                <Col span={18} xl={18} lg={18} md={24} sm={24} xs={24} className="lead-section-wrapper">
                  {/* Timeline */}
                  <Row gutter={[0, "5%"]} justify="center">
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <Card
                        className="leads-details-section"
                        style={{
                          // height: 525,
                          marginRight: window.isIpadView ? '' : "20px",
                          marginBottom: window.isIpadView ? '20px' : "",
                          // overflow: "auto",
                        }}
                      >
                        <div>
                          <Row>
                            <Col span={8} md={8} sm={8} xs={24}>
                              <div
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  fontFamily: "Poppins-Bold, sans-serif",
                                  marginBottom: "10px",
                                }}
                              >
                                Timeline
                              </div>
                            </Col>
                            <Col span={8} md={8} sm={8} xs={24}>



                            </Col>
                            {/* view logs */}
                            <Col span={8} md={8} sm={8} xs={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                              <div
                                style={{
                                  display: 'flex', gap: '0.3rem',
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  fontFamily: "Poppins-Regular, sans-serif",
                                  width: "fit-content"
                                }}
                              >
                                {role == 1 ||
                                  user_id == 488 ||
                                  user_id == 497 ||
                                  user_id == 498 ? (
                                  <Button style={{ width: 'fit-content' }}
                                    type="primary"
                                    onClick={this.handleVerificationCallLogOpen}
                                    className="mb-3 ml-2"
                                  >
                                    <i className="bi bi-check-all me-1 fs-5"></i>
                                    <span style={{ fontSize: 12 }}>
                                      Verify a Call
                                    </span>
                                  </Button>
                                ) : null}

                                <Button
                                  id="view-logs"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  onClick={this.openViewLogs}
                                  type="primary"
                                  className="d-flex flex-row"
                                >
                                  <i className="bi bi-eye-fill mr-1 ml-1"></i>
                                  <span style={{ fontSize: 12 }}>Logs</span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row
                            style={{ display: "flex", }} gutter={16} className="justify-content-between"
                          >
                            <Col span={5} xl={5} lg={5} md={5} sm={5} xs={5}>
                              <div className="d-flex flex-column ">
                                <Avatar
                                  className="user_profile_image"
                                  style={{
                                    boxShadow: "0px 0px 10px lightgrey",
                                    borderRadius: "5px",
                                  }}
                                  size={window.isMobileView ? 65:105}
                                  icon={<i className="bi bi-person-bounding-box" />}
                                  src={
                                    this.isValidHttpUrl(role_profile)
                                      ? role_profile
                                      : baseurlImg +
                                      "lpcrm/public/uploads/clients/2036/users/" +
                                      role_profile
                                  }
                                />
                              </div>
                            </Col>
                            <Col span={18} xl={18} lg={18} md={18} sm={18} xs={19} style={{ mb: 3, maxWidth:'71%', display: 'flex', flexDirection: 'column', gap: '0.6rem', alignItems: 'flex-end' }}>
                              <Mentions
                                style={{
                                  width: "100%",
                                }}
                                value={this.state.note_log}
                                onChange={this.onChangeNoteNote}
                                placeholder="Write Something..."
                                onSelect={this.onSelectNoteMentions}
                                options={this.state.userClients}
                                rows={3}
                              />

                              <Button style={{ width: '50%', justifyContent: 'center', ml: 'auto' }}
                                id="logsbtn"
                                onClick={this.handleNoteSubmit}
                                type="primary"
                                className="d-flex flex-row m-0"
                              >
                                {this.state.note_loader ? (<div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Spin style={{ alignSelf: "center" }} />
                                </div>
                                ) : (<>
                                  <i className="bi bi-plus me-1 fs-6" />
                                  <span style={{ fontSize: 12 }}>Add note</span> </>)}
                              </Button>
                            </Col>
                          </Row>
                          {/* {this.state.note_loader ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Spin style={{ alignSelf: "center" }} />
                            </div>
                          ) : ( */}
                          <div
                            className="d-flex flex-row mx-auto timelineaddbtns timeline-btn-wrapper"
                            style={{
                              display: "flex",
                              justifyContent: "space-between", paddingTop: '0.6rem', paddingBottom: '1.5rem'
                            }}
                          >

                            <Button style={{ width: '48%', justifyContent: 'center' }} type="primary" onClick={this.handleCallLogModal('whatsapp')} className="m-0 align-items-center">
                              <i className="fa fa-whatsapp mr-2 fs-6" aria-hidden="true"></i>
                              <span style={{ fontSize: 12 }}>Add WhatsApp</span>
                            </Button>
                            &nbsp;

                           

                            {this.state.lead_list_details.owner_email && (
                              <Button style={{ width: '48%', justifyContent: 'center' }} type="primary" onClick={this.handleEmail('email')} className="m-0 align-items-center">
                                <i className="fa fa-envelope mr-2 fs-6" aria-hidden="true"></i>
                                <span style={{ fontSize: 12 }}>Add Email</span>
                              </Button>
                            )}


                             {/* <Button style={{ width: '48%', justifyContent: 'center' }} type="primary" onClick={this.handleEmail('email')} className="m-0 align-items-center">
                              <i className="fa fa-envelope mr-2 fs-6" aria-hidden="true"></i>
                              <span style={{ fontSize: 12 }}>Add Email</span>
                            </Button> */}
                            &nbsp;
                            <Button style={{ width: '48%', margin: 0 }} id="calllogbtn" type="primary" onClick={this.handleCallLogModal('call')} className="d-flex flex-row align-items-center m-0"
                            > <i className="bi bi-plus me-1 fs-5" />{" "} <span className="font-12">Add Call</span>
                            </Button>

                            {/* <Button style={{ width: '48%', margin: 0 }} id="documentsmodal" type="primary" onClick={this.handleDocumentsModal('documents')} className="d-flex flex-row align-items-center m-0"
                            > <i className="bi bi-plus me-1 fs-5" />{" "} <span className="font-12">Add Documents</span>
                            </Button> */}

                          </div>
                          <Row>
                            <Col span={24}>
                              <div className="font-bold"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  fontFamily: "Poppins-Regular, sans-serif",
                                  marginBottom: "10px",
                                }}
                              >
                                Matching Properties
                              </div>
                            </Col>
                          </Row>
                          {this.state.isMatchProperties ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "1rem",
                              }}
                            >
                              <Spin style={{ alignSelf: "center" }} />
                            </div>
                          ) : (
                            <Row span={24} gutter={16}>
                              <div className="my-carousel">
                                <Carousel
                                  showThumbs={false}
                                  showStatus={false}
                                  showArrows={true}
                                  infiniteLoop={true}
                                  centerMode={true}
                                  centerSlidePercentage={window.isIpadView ? window.isMobileView ? 100 : 75 : 33.33}
                                >
                                  {this.state.exact_match_listings.map(
                                    (exact_match) => (
                                      <>
                                        <a style={{ marginRight: "15px", color: '#151515', textDecoration: 'none', padding: '5px', overflow: 'hidden' }} href={'https://www.luxuryproperty.com/' + exact_match.refno}>
                                          <img
                                            style={{ height: "100px", objectFit: 'cover' }}
                                            src={
                                              exact_match.property_image ==
                                                null ||
                                                exact_match.property_image == ""
                                                ? "/images/unavailable_image.jpg"
                                                : this.isValidHttpUrl(
                                                  exact_match.property_image
                                                )
                                                  ? exact_match.property_image
                                                  : baseurlImg +
                                                  exact_match.property_image
                                            }
                                          />
                                          <div style={{ marginTop: "15px", fontSize: "10px", justifyContent: 'flex-start' }}>
                                            <span className='font-bold' style={{ fontSize: '12px' }}>{exact_match.refno}</span><br />
                                            <div><i className="fa fa-home mr-1" aria-hidden="true"></i>
                                              {exact_match && exact_match.subcommunity ? exact_match.subcommunity + ", " : "-"} {exact_match && exact_match.community ? exact_match.community : "-"}
                                            </div>
                                          </div>
                                          <div style={{ fontSize: "10px" }}>
                                            <i
                                              className="fa fa-bed mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {exact_match && exact_match.bedrooms
                                              ? exact_match.bedrooms + " Beds"
                                              : "-"}
                                          </div>
                                          <div style={{ fontSize: "10px" }}>
                                            <i
                                              className="fa fa-money mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {exact_match && exact_match.price
                                              ? this.formatCurrency(
                                                exact_match.price
                                              )
                                              : "-"}
                                          </div>
                                        </a>
                                      </>
                                    )
                                  )}
                                </Carousel>
                              </div>
                            </Row>
                          )}
                        </div>
                      </Card>
                    </Col>
                    {/* Requirements */}
                    <Col xl={12} lg={12} md={24} sm={24} xs={24} className="reqwrap">
                      <Card title="Client Requirements" className="leads-details-section" style={{ height: window.isIpadView ? 'auto' : 525 }}>
                        <Row className="requirementswrap">
                          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            {/* <Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div className="form-group d-flex flex-row">
                                  <Row style={{width: "100%", display: "flex", alignContent: "space-around"}}>
                                      <Col span={12}>
                                      <label className="requirements-styling" style={{width: "100%"}}> 
                                    <i className="bi bi-pin-angle-fill mr-1" style={{color:'#ff0000'}}></i>
                                    Pin 
                                    </label>
                                      </Col>
                                    </Row>
                                    
                                    <Col span={12}>
                                    <Checkbox
                                    style={{width:"100%"}}
                                      checked={this.state.pin}
                                      onChange={this.onChangePin}
                                    >
                                    </Checkbox>
                                    </Col>
                                  </div>
                                </div>
                              </Col>
                            </Row> */}
                            <Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}
                                  >
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                      <Col span={12} >
                                        <label className="requirements-styling font-semibold" style={{ width: "100%" }}>
                                          Bedrooms
                                        </label>
                                      </Col>
                                      <Col span={12}>
                                        <EasyEdit
                                          style={{ width: "100%" }}
                                          type={Types.SELECT}
                                          value={this.state.requirement_bedroom}
                                          options={[
                                            { label: "Studio", value: "Studio" },
                                            { label: "1", value: "1" },
                                            { label: "2", value: "2" },
                                            { label: "3", value: "3" },
                                            { label: "4", value: "4" },
                                            { label: "5", value: "5" },
                                            { label: "6", value: "6" },
                                            { label: "7", value: "7" },
                                            { label: "8", value: "8" },
                                            { label: "9", value: "9" },
                                            { label: "10", value: "10" },
                                            { label: "11", value: "11" },
                                            { label: "12", value: "12" },
                                            { label: "13", value: "13" },
                                            { label: "14", value: "14" },
                                            { label: "15", value: "15" },
                                          ]}
                                          onSave={this.saveBedroom}
                                          saveButtonLabel={<CheckOutlined />}
                                          cancelButtonLabel={<CloseOutlined />}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>




                          </Col>
                          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}
                                  >
                                    {" "}
                                    {/* MULTISELECT */}
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>

                                      <Col span={12}>
                                        <label className="requirements-styling font-semibold" style={{ width: "100%" }}>
                                          Lead Stage
                                        </label>
                                      </Col>
                                      <Col span={12}>
                                        <EasyEdit
                                          style={{ width: "100%" }}
                                          type={Types.SELECT}
                                          value={
                                            this.state.requirement_lead_stage ==
                                              undefined ||
                                              this.state.requirement_lead_stage ==
                                              null ||
                                              this.state.requirement_lead_stage == ""
                                              ? ""
                                              : this.state.requirement_lead_stage.toString()
                                          }
                                          options={
                                            role == 1
                                              ? this.state.lead_stage_filter
                                              : this.state.lead_stage_filter_agent
                                          }
                                          onSave={this.saveLeadStage}
                                          saveButtonLabel={<CheckOutlined />}
                                          cancelButtonLabel={<CloseOutlined />}
                                          placeholder={"-"}
                                        />
                                      </Col>
                                    </Row>


                                  </div>
                                </div>

                              </Col>
                            </Row>

                            <Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}
                                  >
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                      <Col span={12}>
                                        <label className="requirements-styling font-semibold" style={{ width: "100px" }}>
                                          Price (AED)
                                        </label>
                                      </Col>
                                      <Col span={12}>
                                        <div
                                          className="d-flex flex-row"
                                          style={{ gap: "0.6rem", width: "100%" }}
                                        >
                                          <EasyEdit
                                            type={Types.TEXT}
                                            value={
                                              this.state.price_requirement ==
                                                null ||
                                                this.state.price_requirement == ""
                                                ? "0"
                                                : this.fAmountSaperator(
                                                  this.state.price_requirement
                                                )
                                            }
                                            onSave={this.savePrice}
                                            saveButtonLabel={<CheckOutlined />}
                                            cancelButtonLabel={<CloseOutlined />}
                                            attributes={{
                                              name: "awesome-input",
                                              id: 1,
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>


                          </Col>
                          <Col span={24}>
                            <Row>
                              <Col xs={24} sm={24} md={12}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}
                                  >
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                      <Col span={12}>
                                        <label className="requirements-styling font-semibold" style={{ width: "100px" }}>
                                          Contact Type
                                        </label>
                                      </Col>
                                      <Col span={12}>
                                        <EasyEdit
                                          style={{ width: "100px" }}
                                          type={Types.SELECT}
                                          value={
                                            this.state.contact_type_requirement ==
                                              undefined ||
                                              this.state.contact_type_requirement ==
                                              null ||
                                              this.state.contact_type_requirement ==
                                              "-"
                                              ? "selected"
                                              : this.state.contact_type_requirement.toString()
                                          }
                                          options={this.state.contact_types.map(
                                            (e) => {
                                              return {
                                                label: e.label,
                                                value: e.value.toString(),
                                              };
                                            }
                                          )}
                                          onSave={this.saveContactType}
                                          saveButtonLabel={<CheckOutlined />}
                                          cancelButtonLabel={<CloseOutlined />}
                                          placeholder="-"
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <div
                                  className="form-group"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                    <Col span={12}>
                                      <label className="requirements-styling font-semibold" style={{ width: "100px" }}>
                                        Move in Date
                                      </label>
                                    </Col>
                                    <Col span={12}>
                                      {/* <DatePicker onChange={this.saveMoveInDate} allowClear type={Types.DATETIME_LOCAL}
                                          value={
                                            this.state.requirement_move_in_date ==
                                            "0000-00-00 00:00:00"
                                            ? "-"
                                            : 
                                            <Moment className='infovalue' format="DD MMM YYYY" >{(this.state.requirement_move_in_date) ? this.getCurrentTime(this.state.requirement_move_in_date) : ""}</Moment>
                                        } */}

                                      <DatePicker value={this.getValidDate(this.state.requirement_move_in_date)}
                                        format="DD MMMM YYYY"
                                        style={{ width: '100%', height: '36px', 'overflow': 'hidden' }}
                                        // showTime={{ minuteStep:15}}
                                        onChange={this.saveMoveInDate}
                                      />


                                      {/* <EasyEdit
                                          style={{width: "100%", minWidth:0}}
                                        type={Types.DATETIME_LOCAL}
                                        value={
                                            this.state.requirement_move_in_date ==
                                            "0000-00-00 00:00:00"
                                            ? "-"
                                            : 
                                            <Moment className='infovalue' format="DD MMM YYYY" >{(this.state.requirement_move_in_date) ? this.getCurrentTime(this.state.requirement_move_in_date) : ""}</Moment>
                                        }
                                        onSave={this.saveMoveInDate}
                                        saveButtonLabel={<CheckOutlined />}
                                        cancelButtonLabel={<CloseOutlined />}
                                        /> */}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                      <Col span={6} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <label className="requirements-styling font-semibold" style={{ width: '100%' }}>
                                          Community
                                        </label>
                                      </Col>
                                      <Col span={18} xs={12} sm={12} md={12} lg={18} xl={18}>
                                        <EasyEdit
                                          style={{ width: "100%" }}
                                          type={Types.SELECT}
                                          value={
                                            this.state.requirement_community ==
                                              undefined ||
                                              this.state.requirement_community == null
                                              ? "-"
                                              : this.state.requirement_community.toString()
                                          }
                                          options={this.state.communities.map(
                                            (e) => {
                                              return {
                                                label: e.label,
                                                value: e.value.toString(),
                                              };
                                            }
                                          )}
                                          onSave={this.saveCommunity}
                                          saveButtonLabel={<CheckOutlined />}
                                          cancelButtonLabel={<CloseOutlined />}
                                        />
                                      </Col>
                                    </Row>

                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                      <Col span={6} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <label className="requirements-styling font-semibold" style={{ width: '100%' }}>
                                          Sub Community
                                        </label>
                                      </Col>
                                      <Col span={18} xs={12} sm={12} md={12} lg={18} xl={18}>
                                        <EasyEdit
                                          type={Types.SELECT}
                                          style={{ width: "100%" }}
                                          value={
                                            this.state.requirement_sub_community ==
                                              undefined ||
                                              this.state.requirement_sub_community ==
                                              null
                                              ? "-"
                                              : this.state.requirement_sub_community.toString()

                                          }
                                          options={this.state.requirement_sub_communities.map(
                                            (e) => {
                                              return {
                                                label: e.label,
                                                value: e.value.toString(),
                                              };
                                            }
                                          )}
                                          onSave={this.saveSubCommunity}
                                          saveButtonLabel={<CheckOutlined />}
                                          cancelButtonLabel={<CloseOutlined />}
                                        />
                                      </Col>
                                    </Row>



                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                      <Col span={6} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <label className="requirements-styling font-semibold" style={{ width: '100%' }}>
                                          Verifier
                                        </label>
                                      </Col>
                                      <Col span={18} xs={12} sm={12} md={12} lg={18} xl={18}>
                                        <EasyEdit style={{ width: '100%' }}
                                          type={Types.SELECT}
                                          value={
                                            this.state.requirement_verifyedby ==
                                              undefined ||
                                              this.state.requirement_verifyedby ==
                                              null ||
                                              this.state.requirement_verifyedby == ""
                                              ? ""
                                              : this.state.requirement_verifyedby.toString()
                                          }
                                          options={[
                                            { label: "None", value: "0" },
                                            {
                                              label: "Danielle Robinson",
                                              value: "375",
                                            },
                                            {
                                              label: "Christalline Ybanez",
                                              value: "427",
                                            },
                                          ]}
                                          onSave={this.saveVerifier}
                                          saveButtonLabel={<CheckOutlined />}
                                          cancelButtonLabel={<CloseOutlined />}
                                          placeholder="-"
                                        />
                                      </Col>
                                    </Row>

                                  </div>
                                </div>
                              </Col>
                            </Row><Row>
                              <Col xs={24} sm={24} md={24}>
                                <div>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Row style={{ width: "100%", display: "flex", alignContent: "space-around" }}>
                                      <Col span={6} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <label className="requirements-styling font-semibold" style={{ width: '100%' }}>
                                          Agent Name
                                        </label>

                                      </Col>
                                      <Col span={18} xs={12} sm={12} md={12} lg={18} xl={18}>
                                        {role == 1 || isUserFound || this.state.collaborators.some((data) => data.user_id == user_id) || user_id.toString() == this.state.requirement_agent_id.toString() || this.state.lead_details.lead_stage == 10 || this.state.lead_details.lead_agent == 430 ?


                                          <EasyEdit
                                            type={Types.SELECT}
                                            style={{ width: "100%" }}
                                            value={
                                              this.state.requirement_agent_id ==
                                                undefined ||
                                                this.state.requirement_agent_id ==
                                                null ||
                                                this.state.requirement_agent_id == ""
                                                ? ""
                                                : this.state.requirement_agent_id.toString()
                                            }
                                            options={this.state.agents.map((e) => {
                                              return {
                                                label: e.label,
                                                value: e.value.toString(),
                                              };
                                            })}
                                            editMode={false}
                                            onSave={this.saveAgent}
                                            saveButtonLabel={<CheckOutlined />}
                                            cancelButtonLabel={<CloseOutlined />}
                                            placeholder="-"
                                          /> : <>
                                            {this.state.agents.map((agent) => (
                                              agent.value === this.state.requirement_agent_id ? (
                                                <div key={agent.value}>{agent.label}</div>
                                              ) : null
                                            ))}</>}
                                      </Col>
                                    </Row>

                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row >
                          <Col span={24}>
                            <h3 className="ant-card-head px-0">Match | Viewings | Offers</h3>
                          </Col>
                          <Col span={24} className="activitybtngrp">
                            <Button
                              id="calllogbtn"
                              onClick={this.openViewMatches}
                              type="primary"
                              className="d-flex flex-row"
                            >
                              <i className="bi bi-bezier2 mr-1"></i>Match
                            </Button>
                            <Button
                              id="calllogbtn"
                              onClick={this.openViewViewings}
                              type="primary"
                              className="d-flex flex-row"
                            >
                              <i className="bi bi-eye-fill mr-1 ml-1"></i>
                              Viewings
                            </Button>

                            <Button
                              id="calllogbtn"
                              onClick={this.openViewOffers}
                              type="primary"
                              className="d-flex flex-row"
                            >
                              <i className="bi bi-tag-fill mr-1"></i>Offers
                            </Button>

                            {/*                             
                            <Button
                              id="calllogbtn"
                              onClick={this.openShareLinks}
                              type="primary"
                              className="d-flex flex-row"
                            >
                             <i className="bi bi-share mr-1"></i>
                              Share link
                            </Button> */}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    {/* Requirements */}
                  </Row>

                  {/* Activity */}
                  <Row gutter={[0, "5%"]}
                    justify="start"
                    style={{ marginTop: "15px" }} className="timeline-wrap justify-content-start">
                    {/*                       
{this.state.requested_viewings && this.state.requested_viewings.map((requested_viewing,index) => (
  <Card  className={index % 2 !== 0 ? "leads-details-section mt-3" : "leads-details-section"}
  style={{ marginTop: index % 2 === 0 ? '10px' : '', marginRight: index % 2 === 0 ? '20px' : '', marginLeft: index % 2 !== 0 ? '20px' : '', width:"100%", height: "fit-content"}}>
                                                                <Timeline.Item dot={<i className="bi bi-house-fill"></i>} className={"timerequested_viewing"}>
                                                                    <div className='d-flex flex-column'>
                                                                       
                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                            <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                            <Avatar
                              style={{
                                boxShadow: "0px 0px 10px lightgrey",
                                borderRadius: "10%",
                              }}
                              size={50}
                              icon={ <img src={
                                requested_viewing.note && requested_viewing.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                :requested_viewing.profile_photo== null || requested_viewing.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(requested_viewing.profile_photo)? requested_viewing.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + requested_viewing.profile_photo }
                                />} 
                            />

                                                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ requested_viewing.profile_photo== null || requested_viewing.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(requested_viewing.profile_photo)? requested_viewing.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + requested_viewing.profile_photo }/>} />
                                                                                <p className="awriter">
                                                                                    <span className="font-bold">{requested_viewing.by_user_name}</span>
                                                                                    <span> wants you to share property to your client.</span>
                                                                            </p>
                                                                            </div>
                                                                            <div className='align-content-center d-flex'>
                                                                                <p className="atime align-self-center">
                                                                                    <Moment fromNow>
                                                                                    {(requested_viewing.added_date) ? this.getCurrentTime(requested_viewing.added_date) : "-"}
                                                                                    </Moment>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='adesc p-0'>
                                                                            <div className='showpropertycontent'>
                                                                                            <a href=""
                                                                                               className='font-bold'>{requested_viewing.note}</a>
                                                                                            <p className="font-semibold">{requested_viewing.unitno}, {requested_viewing.community}, {requested_viewing.subcommunity} <i
                                                                                                className="bi bi-dot"></i><span>{(requested_viewing.price) ? this.getPrice(requested_viewing.price) : "-"}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <Row className='showpropbtm'>
                                                                                            <Col span={6} className='showprop-timer'>
                                                                                                <i className="bi bi-hourglass-split fs-6"></i>
                                                                                                <span className='font-bold'>{this.state.remainingTime}</span>
                                                                                            </Col>
                                                                                            <Col span={17}
                                                                                                 className='showprop-actions'>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        onClick={this.handleRequestNewViewing.bind(this, requested_viewing)}>Book
                                                                                                    a viewing</Button>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        disabled={this.state.isWhatsappLoading}
                                                                                                        onClick={this.handleisShareWhatsApp.bind(this, requested_viewing)}
                                                                                                        >Share
                                                                                                    via
                                                                                                    WhatsApp  {this.state.isWhatsappLoading && <Spin style={{ alignSelf: 'center' }} />}</Button>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        onClick={this.handleisDecline.bind(this, requested_viewing)}>Decline</Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                            </div>
                                                                        </div>
                                                                </Timeline.Item>
                                                                </Card>
                                                            ))} */}
                  </Row>
                  <Row
                    gutter={[0, "5%"]}
                    justify="start"
                    style={{ marginTop: "15px" }} className="timeline-wrap justify-content-start"
                  >



                    {/* dynamic timeline */}
                    {this.state.all.map((al, index) => {
                      return (
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12} className={index % 2 === 0 ? window.isTabView ? '' : "divider" : ""} style={{ display: index % 2 === 0 ? 'flex' : '', flexDirection: 'row' }}>
                          {window.isTabView ? '' : <div className="circle" style={{ marginTop: index % 2 === 0 ? '10px' : '5%', marginBottom: index % 2 === 0 ? '0' : '-25px', marginLeft: index % 2 === 0 ? '0' : '-6px', marginRight: index % 2 === 0 ? '-6px' : '0' }}></div>}
                          <Card
                            className={index % 2 !== 0 ? window.isTabView ? "leads-details-section" : "leads-details-section mt-3" : "leads-details-section"}
                            style={{ marginTop: index % 2 === 0 ? window.isTabView ? '' : '10px' : '', marginRight: index % 2 === 0 ? window.isTabView ? '' : '20px' : '', marginLeft: index % 2 !== 0 ? window.isTabView ? '' : '20px' : '', width: "100%", height: "fit-content", marginBottom: window.isTabView ? '10px' : '' }}

                          >
                            <div className="tabtimeline">
                              <Row>
                                {/* <Col span={2} className="mr-2">
                          <div className="d-flex flex-column">
                            <Avatar
                              style={{
                                boxShadow: "0px 0px 10px lightgrey",
                                borderRadius: "10%",
                              }}
                              size={50}
                              icon={ <img src={
                                al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                :al.profile_photo== null || al.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }
                                />} 
                            />
                          </div>
                        </Col> */}
                                <Col span={24}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>
                                      {/* <span
                                className="font-bold"
                                style={{ fontSize: "15px" }}
                              >
                                {al.by_user_name?al.by_user_name:al.note && al.note.includes('archived the chat') ?'':al.note.includes('Expired') ?'Automation':'CRM'}
                              </span>{" "}
                              <span
                              className="infovalue atime"
                              style={{ fontSize: "13px", display: "flex" }}
                            >
                              <Moment fromNow>
                              {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                              </Moment>
                            </span> */}
                                      {al.note_type == "note" || al.note_type == "email" ?
                                        <span className={"time" + al.note_type}>
                                          <div className='d-flex flex-column'>
                                            <div className='d-flex flex-row justify-content-between'>
                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                <Avatar
                                                  style={{
                                                    boxShadow: "0px 0px 10px lightgrey",
                                                    borderRadius: "10%",
                                                  }}
                                                  size={50}
                                                  icon={<img src={
                                                    al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                      : al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo}
                                                  />}
                                                />



                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={
                                                                                            al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                                                                            :al.profile_photo== null || al.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }
                                                                                            />} 
                                                                                            /> */}


                                                <p className="awriter">
                                                  <span className="font-bold">{al.by_user_name ? al.by_user_name : al.note && al.note.includes('archived the chat') ? '' : al.note.includes('Expired') ? 'Automation' : 'CRM'}</span>
                                                  <span>
                                                    {al.note && al.note.includes('archived the chat') ? 'A client sent a message in the chat'
                                                      // :al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                      : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                        ' updated a requirement'
                                                        : al.note && al.note.includes('Expired') ? ' changed the status'
                                                          : ' added a note'}
                                                  </span>

                                                  <span
                                                    className="infovalue atime"
                                                    style={{ fontSize: "13px", display: "flex" }}
                                                  >
                                                    {
                                                      al.added_date ? (
                                                        <>
                                                          <div className="tooltip">
                                                            <Moment fromNow>
                                                              {al.added_date
                                                                ? this.getCurrentTime(al.added_date)
                                                                : "-"}
                                                            </Moment>
                                                            <span className="tooltiptext">
                                                              <Moment format="DD MMM YYYY HH:mm:ss">
                                                                {al.added_date
                                                                  ? this.getCurrentTime(al.added_date)
                                                                  : "-"}
                                                              </Moment>
                                                            </span>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        "-"
                                                      )
                                                    }
                                                  </span>
                                                </p>

                                              </div>



                                              {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}



                                            </div>

                                            {al.note && al.note.includes("Call start :") ?
                                              <div className='d-flex adesc'><p className='font-12'>{al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}</p></div>
                                              : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                <>{al.note && this.handleSplitReq(al.note)}</>
                                                // :al.note.includes('property on Bayut') ?<span className="">{al.note}</span>
                                                : al.note && al.note.includes('archived the chat') ? <div className='d-flex adesc'>{al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}</div>
                                                  : al.note.includes('Expired') ? <>
                                                    <div className='d-flex flex-column w-100'>
                                                      {/* {(al.note) && this.handleSplitReq(al.note)} */}
                                                      <div className="adesc">
                                                        <span className="font-bold" style={{ display: "flex" }} >
                                                          Reason:
                                                        </span>
                                                        <p className="font-12 mt-0">{parse(al.note.substr(al.note.indexOf(":") + 1))}</p>
                                                      </div>
                                                      <div className="d-flex adesc-stat mt-1">
                                                        <Tag className="pstat">{al.note.match(/(?<=from\s+).*?(?=\s+to)/gs)}</Tag>
                                                        <i className="bi bi-arrow-right mr-2 "></i>
                                                        <Tag className="pstat expired">Expired</Tag>
                                                      </div>
                                                    </div>
                                                  </>
                                                    : <div className='d-flex adesc'>
                                                      <div className='d-flex flex-column'>
                                                        <p className='font-12 mb-1'>
                                                          {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}

                                                        </p>
                                                        {al.lp_details ?
                                                          <div className='lp_details'>

                                                            <Carousel showThumbs={false} arrows style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}>
                                                              {al && al.lp_details && al.lp_details.property_images && this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                              })}
                                                            </Carousel>
                                                            <div className='lpdetails_text w-100'>
                                                              {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                                : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                  : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                    : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                      : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                        : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                          : null
                                                              }
                                                              <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                              <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                              <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                            </div>
                                                          </div>
                                                          : null}
                                                      </div>
                                                    </div>}


                                            {al.note && al.note.includes("@") && !al.note.includes('archived the chat') ?
                                              <>
                                                <div className='d-flex flex-row w-100 taggedreply'>
                                                  <Avatar
                                                    style={{
                                                      boxShadow: "0px 0px 10px lightgrey",
                                                      borderRadius: "10%",
                                                    }}
                                                    size={50}
                                                    icon={<img src={role_profile == null || role_profile == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(role_profile) ? role_profile : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + role_profile} />}
                                                  />
                                                  {/* <Avatar className='d-flex mt-1 mr-1' size={25} icon={ <img src={role_profile == null || role_profile == ""? "/images/defaultLogo.png": this.isValidHttpUrl(role_profile)? role_profile:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + role_profile }/>} /> */}
                                                  <div className='d-flex flex-row w-100'>
                                                    <TextArea placeholder='Reply' className='adesctarea' rows={1} onChange={event => this.onEnterReply(event, index)} value={this.state.reply[index] || ''} />
                                                    <Button type='primary' onClick={this.onClickReply.bind(this, al, index)} style={{ marginTop: 0, height: "47px", marginLeft: "8px" }}>{<SendOutlined />}</Button>
                                                  </div>
                                                </div>
                                                <Timeline className='d-flex flex-column mt-2'>
                                                  {al.children && al.children.length > 0 && al.children != [] && al.children.map((child, index) => (
                                                    /* Request Accepted : Book a viewing */
                                                    child && child.note_type == "reply" ?
                                                      <Timeline.Item className={"mt-2 time" + al.note_type}>
                                                        <div className='d-flex flex-column'>
                                                          <div className='d-flex flex-row justify-content-between'>
                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                              {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ child.profile_photo== null || child.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(child.profile_photo)? child.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + child.profile_photo }/>} /> */}
                                                              <Avatar
                                                                style={{
                                                                  boxShadow: "0px 0px 10px lightgrey",
                                                                  borderRadius: "10%",
                                                                }}
                                                                size={50}
                                                                icon={<img src={child.profile_photo == null || child.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(child.profile_photo) ? child.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + child.profile_photo} />}
                                                              />
                                                              <p className="awriter">
                                                                <span className="font-bold">{child.by_user_name}</span>
                                                                <span> replied</span>
                                                                <span
                                                                  className="infovalue atime"
                                                                  style={{ fontSize: "13px", display: "flex" }}
                                                                >
                                                                  {
                                                                    al.added_date ? (
                                                                      <>
                                                                        <div className="tooltip">
                                                                          <Moment fromNow>
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                          <span className="tooltiptext">
                                                                            <Moment format="DD MMM YYYY HH:mm:ss">
                                                                              {al.added_date
                                                                                ? this.getCurrentTime(al.added_date)
                                                                                : "-"}
                                                                            </Moment>
                                                                          </span>
                                                                        </div>
                                                                      </>
                                                                    ) : (
                                                                      "-"
                                                                    )
                                                                  }
                                                                </span>
                                                              </p>
                                                            </div>
                                                            {/* <div className='align-content-center d-flex'>
                                                                                                                   <p className="atime align-self-center">
                                                                                                                       <Moment fromNow>
                                                                                                                           {(child.added_date) ? this.getCurrentTime(child.added_date) : "-"}
                                                                                                                       </Moment>
                                                                                                                   </p>
                                                                                                               </div> */}
                                                          </div>
                                                          <div className="adesc">
                                                            <span>{child.note}</span>
                                                          </div>
                                                        </div>
                                                      </Timeline.Item>
                                                      : null))}
                                                </Timeline>
                                              </>
                                              : null}
                                          </div>
                                        </span>


                                        : al.note_type == "phone" ?
                                          <span className={"time" + al.note_type}>
                                            <div className='d-flex flex-column'>
                                              <div className='d-flex flex-row justify-content-between'>
                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                  {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                  <Avatar
                                                    style={{
                                                      boxShadow: "0px 0px 10px lightgrey",
                                                      borderRadius: "10%",
                                                    }}
                                                    size={50}
                                                    icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                  />
                                                  <p className="awriter">
                                                    <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                    <span> logged a call</span>
                                                    <span
                                                      className="infovalue atime"
                                                      style={{ fontSize: "13px", display: "flex" }}
                                                    >
                                                      {
                                                        al.added_date ? (
                                                          <>
                                                            <div className="tooltip">
                                                              <Moment fromNow>
                                                                {al.added_date
                                                                  ? this.getCurrentTime(al.added_date)
                                                                  : "-"}
                                                              </Moment>
                                                              <span className="tooltiptext">
                                                                <Moment format="DD MMM YYYY HH:mm:ss">
                                                                  {al.added_date
                                                                    ? this.getCurrentTime(al.added_date)
                                                                    : "-"}
                                                                </Moment>
                                                              </span>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          "-"
                                                        )
                                                      }
                                                    </span>
                                                  </p>
                                                </div>
                                                {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                              </div>
                                              <div className='d-flex adesc'>
                                                <p className='font-12 note-styling'>
                                                  {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}
                                                </p>
                                              </div>
                                            </div>
                                          </span>
                                          : al.note_type == "verify" ?
                                            <span className={"time" + al.note_type}>
                                              <div className='d-flex flex-column'>
                                                <div className='d-flex flex-row justify-content-between'>
                                                  <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                    {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                    <Avatar
                                                      style={{
                                                        boxShadow: "0px 0px 10px lightgrey",
                                                        borderRadius: "10%",
                                                      }}
                                                      size={50}
                                                      icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                    />
                                                    <p className="awriter">
                                                      <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                      <span> verified this lead</span>
                                                      <span
                                                        className="infovalue atime"
                                                        style={{ fontSize: "13px", display: "flex" }}
                                                      >
                                                        {
                                                          al.added_date ? (
                                                            <>
                                                              <div className="tooltip">
                                                                <Moment fromNow>
                                                                  {al.added_date
                                                                    ? this.getCurrentTime(al.added_date)
                                                                    : "-"}
                                                                </Moment>
                                                                <span className="tooltiptext">
                                                                  <Moment format="DD MMM YYYY HH:mm:ss">
                                                                    {al.added_date
                                                                      ? this.getCurrentTime(al.added_date)
                                                                      : "-"}
                                                                  </Moment>
                                                                </span>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            "-"
                                                          )
                                                        }
                                                      </span>
                                                    </p>
                                                  </div>
                                                  {/* <div className='align-content-center d-flex'>
                                                                                    <p className="atime align-self-center">
                                                                                        <Moment fromNow>
                                                                                            {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                        </Moment>
                                                                                    </p>
                                                                                </div> */}
                                                </div>
                                                {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}
                                              </div>
                                            </span>

                                            : al.note_type == "whatsapp" ?
                                              <span className={this.state.whatsapp_from == 1 ? 'timewhatsapp wa_child' : 'timewhatsapp wa_parent'}>
                                                <div className='d-flex flex-column'>
                                                  <div className='d-flex flex-row justify-content-between'>
                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                      <Avatar
                                                        style={{
                                                          boxShadow: "0px 0px 10px lightgrey",
                                                          borderRadius: "10%",
                                                        }}
                                                        size={50}
                                                        icon={
                                                          <img src={
                                                            al.note && al.note.includes("Whatsapp - Propertyfinder") ? '/images/PropertyFinder.png'
                                                              : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                                : al.note && al.note.includes("Automation sent whatsapp") || al.note && al.note.includes('Client Replied:') ? '/images/defaultLogo.png'
                                                                  : al.note && al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo
                                                          } style={{ transform: 'unset' }} />}
                                                      />
                                                      {/* <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                <img src={
                                                                                                    al.note && al.note.includes("Whatsapp - Propertyfinder") ?'/images/PropertyFinder.png'
                                                                                                    :al.note && al.note.includes("Whatsapp - Bayut")|| al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                                                                                    : al.note && al.note.includes("Automation sent whatsapp") || al.note && al.note.includes('Client Replied:') ?'/images/defaultLogo.png'
                                                                                                    : al.note && al.profile_photo== null || al.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo
                                                                                                    } style={{transform:'unset'}}/>} /> */}
                                                      <p className="awriter">
                                                        {al.note && al.note.includes("Whatsapp - Propertyfinder") ? <span className="">You got a new inquiry from <span className='font-bold'>PropertyFinder</span></span>
                                                          : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                            : al.note && al.note.includes('Client Replied:') ? <span> Client replied to the message</span>
                                                              : <span> CRM sent a message</span>}
                                                        <span
                                                          className="infovalue atime"
                                                          style={{ fontSize: "13px", display: "flex" }}
                                                        >
                                                          {
                                                            al.added_date ? (
                                                              <>
                                                                <div className="tooltip">
                                                                  <Moment fromNow>
                                                                    {al.added_date
                                                                      ? this.getCurrentTime(al.added_date)
                                                                      : "-"}
                                                                  </Moment>
                                                                  <span className="tooltiptext">
                                                                    <Moment format="DD MMM YYYY HH:mm:ss">
                                                                      {al.added_date
                                                                        ? this.getCurrentTime(al.added_date)
                                                                        : "-"}
                                                                    </Moment>
                                                                  </span>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              "-"
                                                            )
                                                          }
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                  </div>
                                                </div>
                                                <div className='d-flex adesc'>
                                                  <div className='d-flex flex-column'>
                                                    <p className='font-12 mb-1'>
                                                      {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}
                                                    </p>
                                                    {al.lp_details ?
                                                      <div className='lp_details note-crousal'>
                                                        <Carousel arrows showThumbs={false} style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}>
                                                          {al && al.lp_details && al.lp_details.property_images && this.getImageArray(al.lp_details.property_images).map((image) => {
                                                            return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                          })}
                                                        </Carousel>
                                                        <div className='lpdetails_text w-100'>
                                                          {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                            : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                              : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                  : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                    : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                      : null
                                                          }
                                                          <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                          <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                          <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                        </div>
                                                      </div>
                                                      : null}
                                                  </div>
                                                </div>
                                              </span>

                                              : al.note_type == "viewing" ?
                                                <span className={"time" + al.note_type}>
                                                  <div className='d-flex flex-column'>
                                                    <div className='d-flex flex-row justify-content-between'>
                                                      <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                        <Avatar
                                                          style={{
                                                            boxShadow: "0px 0px 10px lightgrey",
                                                            borderRadius: "10%",
                                                          }}
                                                          size={50}
                                                          icon={
                                                            <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                        />
                                                        {/* <Avatar className='d-flex mt-1' size={25} icon={ */}
                                                        {/* <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                        <p className="awriter">
                                                          <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                          <span> scheduled a viewing</span>
                                                          {/* <span className='pstat scheduled'>Scheduled</span> */}
                                                          <span
                                                            className="infovalue atime"
                                                            style={{ fontSize: "13px", display: "flex" }}
                                                          >
                                                            {
                                                              al.added_date ? (
                                                                <>
                                                                  <div className="tooltip">
                                                                    <Moment fromNow>
                                                                      {al.added_date
                                                                        ? this.getCurrentTime(al.added_date)
                                                                        : "-"}
                                                                    </Moment>
                                                                    <span className="tooltiptext">
                                                                      <Moment format="DD MMM YYYY HH:mm:ss">
                                                                        {al.added_date
                                                                          ? this.getCurrentTime(al.added_date)
                                                                          : "-"}
                                                                      </Moment>
                                                                    </span>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                "-"
                                                              )
                                                            }
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}

                                                    </div>
                                                  </div>
                                                  <div className='d-flex adesc w-100'>
                                                    {this.handleSplitViewing(al.note, al.lp_details ? al.lp_details : null)}
                                                  </div>

                                                  {al.property_viewings_feedback && al.property_viewings_feedback.map((vfeedback) => (
                                                    <>
                                                      {al.note.includes(vfeedback.refno) ?
                                                        <div className='d-flex flex-row mt-2'>
                                                          <Avatar
                                                            style={{
                                                              boxShadow: "0px 0px 10px lightgrey",
                                                              borderRadius: "10%",
                                                            }}
                                                            size={50}
                                                            icon={
                                                              <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                          />
                                                          {/* <Avatar className='d-flex mt-1' size={25} icon={
                                                                                            <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                          <div className='messagewrap'>
                                                            <p>{vfeedback.note}</p>
                                                            <div className='d-flex w-100 align-items-center'>
                                                              <span className='mr-1 font-bold'>Status: </span>
                                                              <Tag>Scheduled</Tag>
                                                              <i className="bi bi-arrow-right mr-2 "></i>
                                                              <Tag className={vfeedback.status.replace(/ /g, '').toLowerCase() + ' pstat px-1'}>{vfeedback.status}</Tag>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        : null}
                                                    </>
                                                  ))}
                                                </span>


                                                : al.note_type == "offer" ?
                                                  <span className={"time" + al.note_type}>
                                                    <div className='d-flex flex-column'>
                                                      <div className='d-flex flex-row justify-content-between'>
                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                          <Avatar
                                                            style={{
                                                              boxShadow: "0px 0px 10px lightgrey",
                                                              borderRadius: "10%",
                                                            }}
                                                            size={50}
                                                            icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                          />
                                                          {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?  "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } />} /> */}
                                                          <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                            {al.note && al.note.includes("Viewing status") ? <span> updated the viewing status</span>
                                                              : al.note && al.note.includes("Offer status") ? <span> updated the offer status</span>
                                                                : null}
                                                            <span
                                                              className="infovalue atime"
                                                              style={{ fontSize: "13px", display: "flex" }}
                                                            >
                                                              {
                                                                al.added_date ? (
                                                                  <>
                                                                    <div className="tooltip">
                                                                      <Moment fromNow>
                                                                        {al.added_date
                                                                          ? this.getCurrentTime(al.added_date)
                                                                          : "-"}
                                                                      </Moment>
                                                                      <span className="tooltiptext">
                                                                        <Moment format="DD MMM YYYY HH:mm:ss">
                                                                          {al.added_date
                                                                            ? this.getCurrentTime(al.added_date)
                                                                            : "-"}
                                                                        </Moment>
                                                                      </span>
                                                                    </div>
                                                                  </>
                                                                ) : (
                                                                  "-"
                                                                )
                                                              }
                                                            </span>
                                                          </p>
                                                        </div>
                                                        {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>{this.getCurrentTime(al.added_date)}</Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                      </div>
                                                      <div className='d-flex adesc'>
                                                        <p className='font-12'>
                                                          {al.note}
                                                        </p>
                                                      </div>
                                                      <div className="d-flex adesc-stat mt-1">
                                                        {al.note && al.note.includes("Viewing status changed to Offer") ? <>
                                                          <Tag className='pstat viewing'>Viewing</Tag>
                                                          <i className="bi bi-arrow-right"></i>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                        </> : al.note && al.note.includes("Not Interested") ? <>
                                                          <Tag className='pstat viewing'>Viewing</Tag>
                                                          <i className="bi bi-arrow-right"></i>
                                                          <Tag className='pstat cancelled'>Not Interested</Tag>
                                                        </> : al.note && al.note.includes("Offer Sent") ? <>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                          <i className="bi bi-arrow-right"></i>
                                                          <Tag className='pstat offer'>Offer Sent</Tag>
                                                        </> : al.note && al.note.includes("Offer Accepted") ? <>
                                                          <Tag className='pstat offer'>Offer Sent</Tag>
                                                          <i className="bi bi-arrow-right"></i>
                                                          <Tag className='pstat secondviewing'>Offer Accepted</Tag>
                                                        </> : al.note && al.note.includes("Offer Rejected") ? <>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                          <i className="bi bi-arrow-right"></i>
                                                          <Tag className='pstat scheduled'>Offer Rejected</Tag>
                                                        </> : al.note && al.note.includes("Deal Signed") ? <>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                          <i className="bi bi-arrow-right"></i>
                                                          <Tag className='pstat deal'>Deal Signed</Tag>
                                                        </> : null}
                                                      </div>
                                                    </div>
                                                  </span>

                                                  : al.note_type == "noanswer" ?
                                                    <span className={"time" + al.note_type}>
                                                      <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-row justify-content-between'>
                                                          <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                            <Avatar
                                                              style={{
                                                                boxShadow: "0px 0px 10px lightgrey",
                                                                borderRadius: "10%",
                                                              }}
                                                              size={50}
                                                              icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                            />
                                                            {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                            <p className="awriter">
                                                              <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                              <span> called but no answer</span>
                                                              <span
                                                                className="infovalue atime"
                                                                style={{ fontSize: "13px", display: "flex" }}
                                                              >
                                                                {
                                                                  al.added_date ? (
                                                                    <>
                                                                      <div className="tooltip">
                                                                        <Moment fromNow>
                                                                          {al.added_date
                                                                            ? this.getCurrentTime(al.added_date)
                                                                            : "-"}
                                                                        </Moment>
                                                                        <span className="tooltiptext">
                                                                          <Moment format="DD MMM YYYY HH:mm:ss">
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                        </span>
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    "-"
                                                                  )
                                                                }
                                                              </span>
                                                            </p>
                                                          </div>
                                                          {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                        </div>

                                                      </div>
                                                    </span>

                                                    : al.note_type == "req_expire" ?
                                                      <span className={"time" + al.note_type}>
                                                        <div className='d-flex flex-column'>
                                                          <div className='d-flex flex-row justify-content-between'>
                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                              <Avatar
                                                                style={{
                                                                  boxShadow: "0px 0px 10px lightgrey",
                                                                  borderRadius: "10%",
                                                                }}
                                                                size={50}
                                                                icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                              />
                                                              {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                              <p className="awriter">
                                                                <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                <span> requested to share his property to your client.</span>
                                                                <span
                                                                  className="infovalue atime"
                                                                  style={{ fontSize: "13px", display: "flex" }}
                                                                >
                                                                  {
                                                                    al.added_date ? (
                                                                      <>
                                                                        <div className="tooltip">
                                                                          <Moment fromNow>
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                          <span className="tooltiptext">
                                                                            <Moment format="DD MMM YYYY HH:mm:ss">
                                                                              {al.added_date
                                                                                ? this.getCurrentTime(al.added_date)
                                                                                : "-"}
                                                                            </Moment>
                                                                          </span>
                                                                        </div>
                                                                      </>
                                                                    ) : (
                                                                      "-"
                                                                    )
                                                                  }
                                                                </span>
                                                              </p>
                                                            </div>
                                                            {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                          </div>
                                                          <div className='adesc'>
                                                            <a href="" className='font-bold color-text-black'>{al.note}</a>
                                                            <p className="font-semibold">{(al.unitno) ? al.unitno : ""} {(al.community) ? "," + al.community : ""} {(al.subcommunity) ? "," + al.subcommunity : ""} {(al.price) && <i className="bi bi-dot"></i>}<span>{(al.price) ? this.getPrice(al.price) : ""}</span></p>
                                                          </div>
                                                          {al.note_type && al.note_type == 'req_expire' ?
                                                            <Timeline className='d-flex flex-column mt-2'>
                                                              {al.children && al.children.length > 0 && al.children != [] && al.children.map((child) => (
                                                                /* Request Accepted : Book a viewing */
                                                                child && child.note_type == "req_accept" ?
                                                                  <Timeline.Item className={"time" + al.note_type}>
                                                                    <div className='d-flex flex-column'>
                                                                      <div className='d-flex flex-row justify-content-between'>
                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                          {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                          <Avatar
                                                                            style={{
                                                                              boxShadow: "0px 0px 10px lightgrey",
                                                                              borderRadius: "10%",
                                                                            }}
                                                                            size={50}
                                                                            icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                          />
                                                                          <p className="awriter">
                                                                            <span className="font-bold">{child.by_user_name}</span>
                                                                            <span> scheduled a viewing with client.</span>
                                                                            <span
                                                                              className="infovalue atime"
                                                                              style={{ fontSize: "13px", display: "flex" }}
                                                                            >
                                                                              {
                                                                                al.added_date ? (
                                                                                  <>
                                                                                    <div className="tooltip">
                                                                                      <Moment fromNow>
                                                                                        {al.added_date
                                                                                          ? this.getCurrentTime(al.added_date)
                                                                                          : "-"}
                                                                                      </Moment>
                                                                                      <span className="tooltiptext">
                                                                                        <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                          {al.added_date
                                                                                            ? this.getCurrentTime(al.added_date)
                                                                                            : "-"}
                                                                                        </Moment>
                                                                                      </span>
                                                                                    </div>
                                                                                  </>
                                                                                ) : (
                                                                                  "-"
                                                                                )
                                                                              }
                                                                            </span>
                                                                          </p>
                                                                        </div>
                                                                        {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow>{(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                      </div>
                                                                      <div className="adesc">
                                                                        <Row>
                                                                          <Col span={24}><span className='font-10'>Date / Time:</span></Col>
                                                                          <Col span={8}>
                                                                            <p className='font-bold fs-6 mb-0'>{this.getTime(child.viewing_date)}</p>
                                                                            <p className="font-12">{this.getDate(child.added_date)}</p>
                                                                          </Col>
                                                                          <Col span={16}>
                                                                            <p>Viewing booked for <span className='font-bold'>{al.note}</span></p>
                                                                            <p className="font-12 mt-2">{al.unitno}, {al.community}, {al.subcommunity} <i className="bi bi-dot"></i><span>{al.price} AED</span></p>
                                                                          </Col>
                                                                        </Row>
                                                                      </div>
                                                                    </div>
                                                                  </Timeline.Item>
                                                                  :/* Share via WhatsApp - Replied */
                                                                  child && child.note_type == "req_wait" ?
                                                                    <Timeline.Item className={" time" + al.note_type}>
                                                                      <div className='d-flex flex-column'>
                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                          <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                            <Avatar
                                                                              style={{
                                                                                boxShadow: "0px 0px 10px lightgrey",
                                                                                borderRadius: "10%",
                                                                              }}
                                                                              size={50}
                                                                              icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                            />
                                                                            {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                            <p className="awriter">
                                                                              <span className="font-bold">{this.state.lead_list_details.owner}</span>
                                                                              <span> replied to the message.</span>
                                                                              <span
                                                                                className="infovalue atime"
                                                                                style={{ fontSize: "13px", display: "flex" }}
                                                                              >
                                                                                {
                                                                                  al.added_date ? (
                                                                                    <>
                                                                                      <div className="tooltip">
                                                                                        <Moment fromNow>
                                                                                          {al.added_date
                                                                                            ? this.getCurrentTime(al.added_date)
                                                                                            : "-"}
                                                                                        </Moment>
                                                                                        <span className="tooltiptext">
                                                                                          <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                            {al.added_date
                                                                                              ? this.getCurrentTime(al.added_date)
                                                                                              : "-"}
                                                                                          </Moment>
                                                                                        </span>
                                                                                      </div>
                                                                                    </>
                                                                                  ) : (
                                                                                    "-"
                                                                                  )
                                                                                }
                                                                              </span>
                                                                            </p>
                                                                          </div>
                                                                          {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                        </div>
                                                                        <div className="adesc">
                                                                          <Row className='align-content-center'>
                                                                            <Col span={16} className='d-flex flex-column'>
                                                                              <span className='font-10'>Best time to view:</span>
                                                                              <div className='d-flex flex-row'>
                                                                                <p className='font-bold mb-0'>{child.note}</p>
                                                                              </div>
                                                                            </Col>
                                                                            <Col span={8}>
                                                                              <Button type='primary' onClick={this.handleRequestNewViewing.bind(this, al)}>Book a viewing</Button>
                                                                            </Col>
                                                                          </Row>
                                                                        </div>
                                                                      </div>
                                                                    </Timeline.Item>
                                                                    :/* Share via WhatsApp - Shared via WhatsApp */
                                                                    child && child.note_type == "req_w" ?
                                                                      <Timeline.Item className={"mt-2 time" + al.note_type}>
                                                                        <div className='d-flex flex-column'>
                                                                          <div className='d-flex flex-row justify-content-between'>
                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                              <Avatar
                                                                                style={{
                                                                                  boxShadow: "0px 0px 10px lightgrey",
                                                                                  borderRadius: "10%",
                                                                                }}
                                                                                size={50}
                                                                                icon={<img src={child.note.includes("System has shared") ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                              />
                                                                              {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ child.note.includes("System has shared")  ?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                              <p className="awriter">
                                                                                <span>
                                                                                  {child.note.includes("System has shared") ?
                                                                                    <><span>CRM shared {child.by_user_name} property to the client</span>
                                                                                    </>
                                                                                    : child.note}
                                                                                </span>
                                                                                <span
                                                                                  className="infovalue atime"
                                                                                  style={{ fontSize: "13px", display: "flex" }}
                                                                                >
                                                                                  {
                                                                                    al.added_date ? (
                                                                                      <>
                                                                                        <div className="tooltip">
                                                                                          <Moment fromNow>
                                                                                            {al.added_date
                                                                                              ? this.getCurrentTime(al.added_date)
                                                                                              : "-"}
                                                                                          </Moment>
                                                                                          <span className="tooltiptext">
                                                                                            <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                              {al.added_date
                                                                                                ? this.getCurrentTime(al.added_date)
                                                                                                : "-"}
                                                                                            </Moment>
                                                                                          </span>
                                                                                        </div>
                                                                                      </>
                                                                                    ) : (
                                                                                      "-"
                                                                                    )
                                                                                  }
                                                                                </span>
                                                                              </p>
                                                                            </div>
                                                                            {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                            <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                    </Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                          </div>
                                                                        </div>
                                                                      </Timeline.Item>
                                                                      :/* Share via WhatsApp - Shared via WhatsApp */
                                                                      child && child.note_type == "req_decline" ?
                                                                        <Timeline.Item className={"mt-2 time" + al.note_type}>
                                                                          <div className='d-flex flex-column'>
                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                <Avatar
                                                                                  style={{
                                                                                    boxShadow: "0px 0px 10px lightgrey",
                                                                                    borderRadius: "10%",
                                                                                  }}
                                                                                  size={50}
                                                                                  icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                                />
                                                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                                <p className="awriter">
                                                                                  <span className="font-bold">{child.by_user_name}</span>
                                                                                  <span> declined your request</span>
                                                                                  <span
                                                                                    className="infovalue atime"
                                                                                    style={{ fontSize: "13px", display: "flex" }}
                                                                                  >
                                                                                    {
                                                                                      al.added_date ? (
                                                                                        <>
                                                                                          <div className="tooltip">
                                                                                            <Moment fromNow>
                                                                                              {al.added_date
                                                                                                ? this.getCurrentTime(al.added_date)
                                                                                                : "-"}
                                                                                            </Moment>
                                                                                            <span className="tooltiptext">
                                                                                              <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                                {al.added_date
                                                                                                  ? this.getCurrentTime(al.added_date)
                                                                                                  : "-"}
                                                                                              </Moment>
                                                                                            </span>
                                                                                          </div>
                                                                                        </>
                                                                                      ) : (
                                                                                        "-"
                                                                                      )
                                                                                    }
                                                                                  </span>
                                                                                </p>
                                                                              </div>
                                                                              {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                            </div>
                                                                            <div className="adesc">
                                                                              <span>{child.note}</span>
                                                                            </div>
                                                                          </div>
                                                                        </Timeline.Item>
                                                                        : null))}
                                                            </Timeline>
                                                            : null}
                                                        </div>
                                                      </span>

                                                      : al.note_type = "followup" ?
                                                        <span className={"timefollowup"}>
                                                          <div className='d-flex flex-column'>
                                                            <div className='d-flex flex-row justify-content-between'>
                                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                <Avatar
                                                                  style={{
                                                                    boxShadow: "0px 0px 10px lightgrey",
                                                                    borderRadius: "10%",
                                                                  }}
                                                                  size={50}
                                                                  icon={
                                                                    <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                                />
                                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ */}
                                                                {/* <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                                <p className="awriter mb-0">
                                                                  <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'} </span><span> {al.by_status == 2 ? "added a followup" : "added a feedback for viewing"} </span>
                                                                </p>
                                                              </div>
                                                              <div className='align-content-center d-flex'>
                                                                <p className="atime align-self-center">
                                                                  <Moment fromNow>
                                                                    {al.added_date
                                                                      ?
                                                                      this.getCurrentTime(al.added_date)
                                                                      // al.added_date
                                                                      // this.getCurrentTime(al.added_date) 
                                                                      : "-"}
                                                                  </Moment>
                                                                </p>
                                                              </div>








                                                            </div>
                                                          </div>
                                                          <div className='d-flex adesc py-2 px-0'>
                                                            <div className="d-flex" style={{ background: 'rgba(21,21,21,0.06)', borderRadius: '12px', width: '60px', height: '60px', alignItems: 'center', justifyContent: 'center', marginRight: ' 0.5rem' }}>
                                                              <i className="bi bi-calendar2-check" style={{ fontSize: '1.5rem' }} ></i>
                                                            </div>
                                                            <div className="d-flex flex-column border-right pr-2">
                                                              <Moment format="DD MMMM YYYY">{al.added_date}</Moment>
                                                              <Moment format="hh:mm A" style={{ fontSize: 26 }} className="font-bold">{al.added_date}</Moment>
                                                            </div>
                                                            <div className="d-flex flex-column pl-2">
                                                              {/* Subject */} <p className="font-12">Subject: Follow up </p>
                                                              {/* Follow up for */} <p className="font-12">Follow up for : Call</p>
                                                              {/* Note */} <p className="font-12">Note: {al.note}</p>
                                                            </div>
                                                          </div>
                                                        </span>

                                                        :
                                                        <span className={"time" + al.note_type}>
                                                          <div className='d-flex flex-column'>
                                                            <div className='d-flex flex-row justify-content-between'>
                                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                <Avatar
                                                                  style={{
                                                                    boxShadow: "0px 0px 10px lightgrey",
                                                                    borderRadius: "10%",
                                                                  }}
                                                                  size={50}
                                                                  icon={
                                                                    <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                                />
                                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ */}
                                                                {/* <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/defaultLogo.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                                <p className="awriter">
                                                                  <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                  <span
                                                                    className="infovalue atime"
                                                                    style={{ fontSize: "13px", display: "flex" }}
                                                                  >
                                                                    {
                                                                      al.added_date ? (
                                                                        <>
                                                                          <div className="tooltip">
                                                                            <Moment fromNow>
                                                                              {al.added_date
                                                                                ? this.getCurrentTime(al.added_date)
                                                                                : "-"}
                                                                            </Moment>
                                                                            <span className="tooltiptext">
                                                                              <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                {al.added_date
                                                                                  ? this.getCurrentTime(al.added_date)
                                                                                  : "-"}
                                                                              </Moment>
                                                                            </span>
                                                                          </div>
                                                                        </>
                                                                      ) : (
                                                                        "-"
                                                                      )
                                                                    }
                                                                  </span>
                                                                </p>
                                                              </div>
                                                              {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}

                                                            </div>
                                                          </div>
                                                          <div className='d-flex adesc'>
                                                            <p className='font-12'>
                                                              {al.note}
                                                              <span
                                                                className="infovalue atime"
                                                                style={{ fontSize: "13px", display: "flex" }}
                                                              >
                                                                {
                                                                  al.added_date ? (
                                                                    <>
                                                                      <div className="tooltip">
                                                                        <Moment fromNow>
                                                                          {al.added_date
                                                                            ? this.getCurrentTime(al.added_date)
                                                                            : "-"}
                                                                        </Moment>
                                                                        <span className="tooltiptext">
                                                                          <Moment format="DD MMM YYYY HH:mm:ss">
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                        </span>
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    "-"
                                                                  )
                                                                }
                                                              </span>
                                                            </p>
                                                          </div>
                                                        </span>}

                                      {/* <span>
                                updated a requirement
                                </span> */}
                                    </span>
                                    {/* <span
                              className="infovalue atime"
                              style={{ fontSize: "13px", display: "flex" }}
                            >
                              <Moment fromNow>
                              {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                              </Moment>
                            </span> */}
                                  </div>
                                  {/* <div className='d-flex adesc'>
                                                                    <p className='font-12'>
                                                                        { (al.note && al.note != null) && parse(al.note)}
                                                                    </p>
                                                                </div> */}
                                  {/* <p>
                            {this.handleSplitReq(
                              "Move in date has been changed from 0000-00-00 00:00:00 To 2023-09-01"
                            )}
                          </p> */}
                                </Col>
                              </Row>
                            </div>
                          </Card>

                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              </Row>

            </Space>


          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNoMatch: (data) => dispatch({ type: "SET_Not_Match", payload: data }),
    removeLeadReducer: () =>
      dispatch({ type: "REMOVE_LEAD_STATISTICS", payload: false }),
  };
};

export default connect(null, mapDispatchToProps)(AllLeadsDetails);

const StyledGreenButton = styled(Button)`
    border:1px solid #25d366!important;background:#25d366; color:#FFF; width:fit-content; border-radius:5px; margin-top:0; height:36px;
    text-decoration:none;
    & span {font-size:12px;} 
    & :hover span {color:#25d366}

    `;