const BrochurePdf = (data) => {

    const listing = data? data.data : []
    const listingimage = listing.property_images.split(',');

    const SuperAdmin = ['Christian', 'Kamela'];
    let agentname, agentpic, agentemail, agentmobile
    if (SuperAdmin.includes(listing.agent_name.split(' ')[0])) {
        agentname = listing.listing_agent_name
        agentpic = listing.listing_agent_picture
        agentemail = listing.listing_agent_email
        agentmobile = listing.listing_agent_mobile
    } else {
        agentname = listing.agent_name
        agentpic = listing.profile_photo
        agentemail = listing.email
        agentmobile = listing.mobile
    }

    return (
        <>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Poppins:wght@300;400;600;700&display=swap" rel="stylesheet"/>
        <style>{`
            @page { size: 11.69in 8.27in;margin: 0in;padding:0}
            @media print{
                @page {size: landscape}; div {print-color-adjust: exact;}
                img {max-width: 100%; max-height: 100%; width: auto; height: auto; display: block; margin: auto; page-break-inside: avoid;}
            
            }
            .flyerbrochure { width:100%; height:100% }
            .flyerbrochure .pagecontainer { background:#FFF; z-index:2; width: 297mm; height: 210mm; position:relative; overflow:hidden; margin-bottom:2rem; display:flex; align-items:center; justify-content:center}
            .flyerbrochure img {object-fit:cover; height:100%; width:100%}
            .flyerbrochure * {font-family: 'Poppins', sans-serif; font-size: 16px; margin:0; line-height:1.8}            
            .flyerbrochure .bgimage { position: absolute; z-index: 1; height:100%; width:100%; overflow:hidden; max-height: 580pt;}
            .flyerbrochure .bcontainer {  z-index:2; position:relative; overflow:hidden; display:flex; flex-direction:row; height:100%; width:100%}
            .flyerbrochure .poppins-semibold {font-family: "Poppins", sans-serif; font-weight: 600;font-style: normal; font-size:16px;}
            .flyerbrochure .poppins-bold {font-family: "Poppins", sans-serif;font-weight: 700;font-style: normal; font-size:18px}
            .flyerbrochure .cormorant-semibold { font-family: "Cormorant Garamond", serif; font-optical-sizing: auto; font-weight: 600; font-style: normal;}
            .flyerbrochure .cormorant-bold { font-family: "Cormorant Garamond", serif; font-optical-sizing: auto; font-weight: 700; font-style: normal; font-size:21px}
            .flyerbrochure .header {display:flex; width: 100%; justify-content:space-between; align-items: center; margin:0.6rem 0}
            .flyerbrochure .brochuredesc p{ font-size:9pt; line-height:2}

            .flyerbrochure .col-2 {display:flex; max-width: 20%; flex: 0 0 20%; padding:0;}
            .flyerbrochure .col-3 {display:flex; max-width:25%; flex: 0 0 25%; padding:0;}
            .flyerbrochure .col-4 {display:flex; max-width: 32.33%; flex: 0 0 32.33%; padding:0;}        
            .flyerbrochure .col-5 {display:flex; max-width: 41.66%; flex: 0 0 41.66%; padding:0;}     
            .flyerbrochure .col-6 {display: flex; max-width: 50%; flex: 0 0 50%;;padding:0;}
            .flyerbrochure .col-7 {display: flex; max-width: 58.33%; flex: 0 0 58.33%; padding:0;}
            .flyerbrochure .col-8 {max-width: 66.66%; flex:66.66%; padding:0;}
            .flyerbrochure .col-9 {max-width: 75%; flex:75%; padding:0;}
            .flyerbrochure .col-10 {max-width:83.33%; flex:83.33%; padding:0;}
            .flyerbrochure .col-12 {max-width:100%; flex:100%; padding:0;}
            .flyerbrochure .flex-column {flex-direction: column;}
            .flyerbrochure .flex-row {flex-direction: row;}
        `}</style>

        <div className="flyerbrochure">
            {/* First Page */}
            <div className="pagecontainer">
                <div style={{backgroundColor:'black', position:'absolute', height:'30px', width:'100px', zIndex:'3', top:0, right:0}}></div>
                <div style={{background: 'rgba(21,21,21,0.26)', position:'absolute', height:'130px', width:'30px', zIndex:3, bottom:0, right:0}}></div>
                <div style={{background: 'rgba(21,21,21,0.96)', position:'absolute', height:170, width:20, zIndex:3, bottom:0, left:5}}></div>
                <div style={{background: 'rgba(21,21,21,0.96)', position:'absolute', height:180, width:2, zIndex:3, bottom:0, left:2}}></div>
                <div className="bcontainer">
                    <div className="col-5 flex-column" style={{height:'60%',justifyContent:'space-between', textAlign: 'center', display:'flex'}}>
                        <div className="header" style={{justifyContent: 'center',paddingTop:'5rem'}}>
                            <img src="brochure_assets/Lp-Logo-2021-Black-Web-300.png" width={300} style={{position:'absolute', height: 'fit-content', width:'300px'}}  />
                        </div>
                        <div className="title flex-column">
                            <h1 style={{fontSize:'24pt', letterSpacing: '3px', textTransform: 'uppercase', margin:0, lineHeight:1, color:'#151515'}} className="cormorant-bold">
                                {listing.subcommunity}
                            </h1>
                            <h2 style={{fontSize:'10pt', margin:'5px 0 0', letterSpacing: '5px', fontWeight:'300 !important', textTransform: 'uppercase', color:'#151515'}}>{listing.community}</h2>
                        </div>
                    </div>
                    <div className="col-7" style={{overflow:'hidden', maxHeight: '1000px'}}>
                        <img src={'https://www1.luxuryproperty.com/' + listingimage[0] + '?nowm'} width="auto" height="90%" style={{objectFit: 'cover', position:'relative', left:'50%', transform: 'translateX(-50%)', height:'100%'}}/>
                    </div>
                </div>
            </div>
            {/* <!-- Second Page --> */}
            <div className="pagecontainer">
                <div className="bcontainer">
                    <div className="col-5 flex-column" style={{height:'100%',justifyContent:'space-between'}}>
                        <div className="title flex-column" style={{paddingTop:'3rem', paddingLeft:'2rem'}}>
                            {/* <!-- [Sub Community], [Community] Brief --> */}
                            <h1 style={{fontSize:'32px', letterSpacing: '3px', textTransform: 'uppercase', margin:'0 0 4rem', lineHeight:'1.4'}} className="cormorant-bold">{listing.subcommunity + ", " + listing.community} Brief</h1>

                            {listing.property_for && (<p style={{display:'flex', marginBottom:'2rem', flexDirection: 'column'}}>
                                <span className="poppins-bold" style={{lineHeight: '1.8', fontSize:"18pt", textTransform:'uppercase' }}>{'For ' +listing.property_for}</span>
                            </p>)}

                            {listing.price && (<p style={{display:'flex', marginBottom:'2rem', flexDirection: 'column'}}>
                                <span className="poppins-bold" style={{lineHeight: 1, fontSize:"14pt"}}>{'AED ' + listing.price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                <span style={{letterSpacing: '3px', textTransform: 'uppercase', fontSize:'8pt'}}>Price</span>
                            </p>)}
                            <div style={{display:'flex', flexDirection: 'row', gap:'4rem'}}>
                                {listing.bedrooms && (<p style={{display:'flex', marginBottom:'2rem', flexDirection: 'column'}}>
                                    <span className="poppins-bold" style={{lineHeight: 1, fontSize:'14pt'}}>{listing.bedrooms}</span>
                                    <span style={{letterSpacing: '3px', textTransform: 'uppercase',fontSize:'8pt'}}>Bedrooms</span>
                                </p>)}
                                {listing.bathrooms && (<p style={{display:'flex', marginBottom:'2rem', flexDirection: 'column'}}>
                                    <span className="poppins-bold" style={{lineHeight: 1, fontSize:'14pt'}}>{listing.bathrooms}</span>
                                    <span style={{letterSpacing: '3px', texTransform: 'uppercase', fontSize:'8pt'}}>Bathrooms</span>
                                </p>)}
                            </div>
                            {listing.bua && ( <p style={{display:'flex', marginBottom:'2rem', flexDirection: 'column'}}>
                                <span className="poppins-bold" style={{lineHeight: 1.2, fontSize:'14pt'}}>{listing.bua.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} sq. ft.</span>
                                <span style={{letterSpacing: '3px',textTransform: 'uppercase',fontSize:'8pt'}}>Built-Up Area</span>
                            </p> )}
                            {listing.desc && <div style={{display: 'flex', flexDirection: 'column', gap:'0.6rem', lineHeight:'2', position:'absolute', bottom:'2rem', paddingRight:'3rem'}} className="brochuredesc" dangerouslySetInnerHTML={{ __html: listing.desc.match(/<p>.*?<\/p>/i)?.[0] || '' }}></div>}
                        </div>
                    </div>
                    { listingimage[1] && <div className="col-7" style={{overflow:'hidden'}}>
                        <img src={'https://www1.luxuryproperty.com/' + listingimage[1] + '?nowm'} width="auto" height="100%" style={{objectFit: 'cover', position:'relative', left:'50%', transform: 'translateX(-50%)'}}/>
                    </div> }
                </div>
            </div>
            {/* <!-- Third Page --> */}
            { listingimage[2] && <div className="pagecontainer">
                <div className="bcontainer"><img src={'https://www1.luxuryproperty.com/' + listingimage[2] + '?nowm'} width="auto" height="100%" style={{objectFit: 'cover',position:'relative',left:'50%', transform: 'translateX(-50%)'}} /></div>
            </div> }

            {/* <!-- Fourth Page --> */}
            { listingimage[3] && listingimage[4] && listingimage[5] && <div className="pagecontainer">
                <div className="bcontainer" style={{gap:'0.3rem'}}>
                    <div className="col-8" style={{overflow: 'hidden'}}><img src={'https://www1.luxuryproperty.com/' + listingimage[3] + '?nowm'} width="auto" height="792.5pt" style={{objectFit: 'cover', position:'relative',left:'50%',transform: 'translateX(-50%)'}} /></div>
                    <div className="col-4 flex-column" style={{gap:'0.3rem'}}>
                        <div className="col-12" style={{overflow: 'hidden'}}>
                            <img src={'https://www1.luxuryproperty.com/' + listingimage[4] + '?nowm'} width="auto" height="50%" style={{objectFit: 'cover', position:'relative',left:'50%', transform: 'translateX(-50%)'}} />
                        </div>
                        <div className="col-12" style={{overflow: 'hidden'}}>
                            <img src={'https://www1.luxuryproperty.com/' + listingimage[5] + '?nowm'} width="auto" height="50%" style={{objectFit: 'cover', position:'relative',left:'50%', transform:' translateX(-50%)'}} />
                        </div>
                    </div>
                </div>
            </div> }
            {/* <!-- Fifth Page --> */}
            { listingimage[6] && <div className="pagecontainer">
                <div className="bcontainer"><img src={'https://www1.luxuryproperty.com/' + listingimage[6] + '?nowm'} width="auto" height="790pt" style={{objectFit: 'cover', position:'relative',left:'50%', transform:' translateX(-50%)'}} /></div>
            </div> }

            {/* <!-- Seventh Page --> */}
            { listingimage[7] && listingimage[8] && listingimage[9] && <div className="pagecontainer">
                <div className="bcontainer" style={{gap:'0.3rem'}}>
                    <div className="col-4 flex-column" style={{gap:'0.3rem'}}>
                        <div className="col-12" style={{overflow: 'hidden'}}>
                            <img src={'https://www1.luxuryproperty.com/' + listingimage[7] + '?nowm'} width="auto" height="400pt" style={{objectFit: 'cover', position:'relative',left:'50%', transform:' translateX(-50%)'}} />
                        </div>
                        <div className="col-12" style={{overflow: 'hidden'}}>
                            <img src={'https://www1.luxuryproperty.com/' + listingimage[8] + '?nowm'} width="auto" height="400pt" style={{objectFit: 'cover', position:'relative',left:'50%', transform:' translateX(-50%)'}} />
                        </div>
                    </div>
                    <div className="col-8" style={{overflow: 'hidden'}}><img src={'https://www1.luxuryproperty.com/' + listingimage[9] + '?nowm'} width="auto" height="792.5pt" style={{objectFit: 'cover', position:'relative',left:'50%', transform:' translateX(-50%)'}} /></div>
                </div>
            </div> }

            {/* <!-- Eigth Page --> */}
            { listingimage[10] && <div className="pagecontainer">
                <div className="bcontainer"><img src={'https://www1.luxuryproperty.com/' + listingimage[10] + '?nowm'} width="auto" height="792.5pt" style={{objectFit: 'cover', position:'relative',left:'50%', transform:' translateX(-50%)'}} /></div>
            </div> }

            {/* <!-- Info Page --> */}
            {listing.desc && ( <div className="pagecontainer">
                <div className="bcontainer" style={{alignSelf: 'center'}}>
                    <div className="col-3" style={{justifyContent: 'center',paddingTop:'3.5rem'}}>
                        <img src={'https://www1.luxuryproperty.com/' + listing.qr_code} height="200px" width="200px" style={{ height:150, width:150, objectFit:'unset'}}/>
                    </div>
                    <div className="col-9 flex-column" style={{padding:'3rem 3rem 3rem 0'}}>
                        {/* <!-- Title --> */}
                        <p className="poppins-bold" style={{fontSize:'18pt', marginBottom:'1rem'}}>{listing.title ? listing.title:'' }</p>
                        {/* <!-- Description: 2nd paragraph to end --> */}
                        {listing.desc && <div style={{display: 'flex', flexDirection: 'column', gap:'0.6rem', lineHeight:'1.8'}} className="brochuredesc" dangerouslySetInnerHTML={{ __html: listing.desc.replace(/<p>.*?<\/p>/i, '') }}></div>}
                    </div>
                </div>
            </div>)}
            {/* <!-- Last Page --> */}
            <div className="pagecontainer" style={{display:'flex', flexDirection:'row'}}>
                <div className="col-5 flex-column" style={{height:'90%',justifyContent:'space-between', textAlign: 'center', alignSelf: 'center'}}>
                    <div className="header" style={{justifyContent: 'flex-start', paddingTop:'5rem', paddingLeft: '3rem'}}>
                        <img src="brochure_assets/Lp-Logo-2021-Black-Web-300.png" width={300} style={{position:'absolute', height: 'fit-content', width:'300px'}}  />
                    </div>
                    <div className="title flex-column" style={{textAlign: 'left', paddingLeft:'3rem', marginTop:'5rem'}}>
                        <h1 style={{fontSize:'18pt', margin:'0 0 8px', lineHeight:1}} className="poppins-bold">{agentname}</h1>
                        <h2 style={{fontSize:'10pt', margin:0, fontWeight:300, gap:'0.3rem', display:'flex', alignItems: 'center'}}><img src="brochure_assets/whatsapp-green.svg" height="18px" width="16px" style={{width:16, height:16}} />{agentmobile}</h2>
                    </div>
                    <p style={{display:'flex', flexDirection: 'column', textAlign: 'left',paddingLeft:'3rem'}}>
                        <span style={{fontSize:'8pt'}}>Luxury Property LLC</span>
                        <span style={{fontSize:'8pt'}}>204, Bay Square 2, Business Bay,</span>
                        <span style={{fontSize:'8pt'}}>Dubai, United Arab Emirates</span>
                        <span style={{fontSize:'8pt'}}>PO Box 125597</span>
                        <span style={{fontSize:'8pt'}}>ORN: 12328</span>
                        <span style={{fontSize:'8pt'}}>+971 4 563 5900</span>
                        <span style={{fontSize:'8pt'}}>sales@luxuryproperty.com</span>
                    </p>
                </div>
                <div className="col-7" style={{overflow:'hidden', maxHeight: '1000px', height:'210mm'}}>
                        <img src={'https://www1.luxuryproperty.com/' + listingimage[listingimage.length - 1] + '?nowm'} width="auto" height="90%" style={{objectFit: 'cover', position:'relative', left:'50%', transform: 'translateX(-50%)', height:'100%', width:'auto'}}/>
                    </div>
            </div>

        </div>
        </> 
    )
}

export default BrochurePdf