import React,{Component} from 'react';
import { Page, Text, View, Document, StyleSheet,PDFViewer,Font } from '@react-pdf/renderer';
import { Image} from 'antd';

import { store } from '../../store';
import Html from 'react-pdf-html';
import jsPDF from 'jspdf';
import ReactToPrint from 'react-to-print';
 
import DataComponent from './DataComponent';

const doc = new jsPDF();

const html =` <div>
<meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="x-apple-disable-message-reformatting" />
{/*[if !mso]><!*/}<meta httpEquiv="X-UA-Compatible" content="IE=edge" />{/*<![endif]*/}
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;700&display=swap" rel="stylesheet" />
<title>Tenancy Contract</title>
<style type="text/css" dangerouslySetInnerHTML={{__html: "\n        body {margin: 0;padding: 0;}\n        table,tr,td { vertical-align: top; border-collapse: collapse;}\n        .ie-container table,.mso-container table {table-layout: fixed;}\n        * {line-height: inherit; font-family: Arial, Helvetica, sans-serif; font-size:14px;color:#2d4253}\n        p{margin:5px 0;}\n        a[x-apple-data-detectors='true'] { color: inherit !important; text-decoration: none !important;}\n        table, td { color: #2d4253; } \n        .u-row-container{\n    height:297mm;\n    width:210mm; border:1px solid #151515; margin:1rem auto; background-color: #FFF;\n    padding:1.5rem\n}\n        @media print {\n            body{\n                width: 21cm;\n                height: 29.7cm;\n                margin: 30mm 45mm 30mm 45mm; \n                /* change the margins as you want them to be. */\n        } \n        }\n    " }} />
{/* First Page */}
<div className="u-row-container">
  <div className="u-row" style={{}}>
    <table width="100%">
      <tbody><tr style={{backgroundColor: '#FFF', paddingBottom: 5}}>
          <td text-align="left">
            <img src="Government_of_Dubai_logo.png" height="38px" />
            <img src="Dubai-Land-LOGO.png" height="45px" valign="center" style={{float: 'right'}} />
          </td>
        </tr>
        <tr style={{border: '1px solid #4f647e', backgroundColor: '#FFF'}}>
          <td style={{verticalAlign: 'bottom', padding: 5, border: '1px solid #4f647e', width: '100%'}}>
            <div style={{width: '33%', float: 'left', paddingTop: 32, paddingLeft: 10}}>
              <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1}}>Date</p>
              <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 150, margin: '0 0 0 2px', lineHeight: 1}}>&nbsp;</p>
              <p style={{ float: 'left', margin: 0, fontSize: 12, lineHeight: 1}}>التاريخ</p>
            </div>
            <div style={{width: '62%', float: 'right'}}><img src="tenancycontract-logo.png" height="45px" /></div>
          </td>
        </tr>
      </tbody></table>
    <table style={{marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Owner / Lessor Information</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>معلومات المالك/ المؤجر</th>
        </tr></thead>
      <tbody><tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8'}}>Owner's Name</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>اسم المالك</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', letterSpacing: '-0.6px'}}>Lessor's Name</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>اسم المؤجر</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Lessor's Emirates ID</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>الهوية اللإمارتية للمؤجر</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '18%', float: 'left', lineHeight: '1.8'}}>License No.</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '65%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم الرخصة</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '23%', float: 'left', lineHeight: '1.8'}}>License Authority</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '57%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '17%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>سلطة الترخيص</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '100%'}}><p style={{fontSize: 8, width: '50%', margin: '0 0 0 -3%', float: 'left', textAlign: 'center'}}>Incase of a Company في حال كانت شركة</p></div>
            <div style={{width: '100%'}}><p style={{fontSize: 8, width: '48%', margin: '0 0 0 5%', float: 'left', textAlign: 'center'}}>Incase of a Company في حال كانت شركة</p></div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8'}}>Lessor's Email</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '75%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>البريد اللإلكتروني للمؤجر</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '11%', float: 'left', lineHeight: '1.8'}}>Lessor's Phone</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '78%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '11%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم هاتف المؤجر</p>
          </td>
        </tr>
      </tbody></table>
    <table style={{marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Tenant Information</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>معلومات المالك/ المؤجر</th>
        </tr></thead>
      <tbody><tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8'}}>Tenant's Name</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>اسم المستأجر</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', letterSpacing: '-0.6px'}}>Tenant's Emirates ID</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>الهوية الإماراتية للمستأجر</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>License No.</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '70%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم الرخصة</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>License Authority</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>سلطة الترخيص</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '100%'}}><p style={{fontSize: 8, width: '50%', margin: '0 0 0 -3%', float: 'left', textAlign: 'center'}}>Incase of a Company في حال كانت شركة</p></div>
            <div style={{width: '100%'}}><p style={{fontSize: 8, width: '48%', margin: '0 0 0 5%', float: 'left', textAlign: 'center'}}>Incase of a Company في حال كانت شركة</p></div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Tenant's Email</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>البريد الإلكتروني للمستأجر</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Tenant's Phone</p>
            <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1}}>&nbsp;</p>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم هاتف المستأجر</p>
          </td>
        </tr>
      </tbody></table>
    <table style={{marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Property Information</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>معلومات العقار</th>
        </tr></thead>
      <tbody><tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '19%', float: 'left', lineHeight: '1.8'}}>Property Usage</p>
            <div className="form-group" style={{float: 'left', margin: '6px 2%', lineHeight: '1.8'}}>
              <input type="radio" id="industrial" name="property_usage" defaultValue="Industrial" style={{float: 'left'}} />
              <label htmlFor="industrial" style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Industrial صناعي</label>
            </div>
            <div className="form-group" style={{float: 'left', margin: '6px 2%', lineHeight: '1.8'}}>
              <input type="radio" id="commercial" name="property_usage" defaultValue="Commercial" style={{float: 'left'}} />
              <label htmlFor="industrial" style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Commercial تجاري</label>
            </div>
            <div className="form-group" style={{float: 'left', margin: '6px 2%', lineHeight: '1.8'}}>
              <input type="radio" id="residential" name="property_usage" defaultValue="Residential" style={{float: 'left'}} />
              <label htmlFor="industrial" style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Residential سكني</label>
            </div>
            <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>استخدام العقار</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Plot No.</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '70%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم الأرض</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Makani No.</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم مكاني</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '21%', float: 'left', lineHeight: '1.8'}}>Building Name</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '62%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>اسم المبنى</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '16%', float: 'left', lineHeight: '1.8'}}>Property No.</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '69%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم العقار</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '21%', float: 'left', lineHeight: '1.8'}}>Property Type</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '62%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>نوع الوحدة</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '27%', float: 'left', lineHeight: '1.8'}}>Property Area (s.m)</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '44%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '29%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>مساحة العقار (متر.مربع)</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8'}}>Location</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '72%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '13%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>الموقع</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '27%', float: 'left', lineHeight: '1.8'}}>Premises No. (DEWA)</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '52%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '20%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>رقم المبنى (ديوا)</p>
            </div>
          </td>
        </tr>
      </tbody></table>
    <table style={{marginTop: 5, border: '1px solid #eff2f5'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Contract Information</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>معلومات العقار</th>
        </tr></thead>
      <tbody>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '22%', float: 'left', lineHeight: '1.8'}}>Contract Period</p>
              <div style={{width: '68%', float: 'left'}}>
                <p style={{borderBottom: '1px dashed #4f647e', width: '45%', float: 'left', lineHeight: 1}}>&nbsp;</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '45%', float: 'right', lineHeight: 1}}>&nbsp;</p>
              </div>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '10%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>الموقع</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '20%', float: 'left', lineHeight: '1.8'}}>Contract Value</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '65%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>قيمة العقد</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={6} style={{padding: '0 15px'}}>
            <div style={{width: '100%'}}><p style={{fontSize: 8, width: '48%', margin: '-5px 0 0 4%', float: 'left', textAlign: 'center'}}>From من</p></div>
            <div style={{width: '100%'}}><p style={{fontSize: 8, width: '10%', margin: '-5px 0 0 5%', float: 'left', textAlign: 'center'}}>To إلى</p></div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '45%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '22%', float: 'left', lineHeight: '1.8'}}>Annual Rent</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '59%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '19%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>الايجار السنوي</p>
            </div>
            <div style={{width: '50%', float: 'right'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '20%', float: 'left', lineHeight: '1.8'}}>Security Deposit Amount</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '65%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>مبلغ التأمين</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12} style={{padding: '0 15px'}}>
            <div style={{width: '100%', float: 'left'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '11%', float: 'left', lineHeight: '1.8'}}>Mode of Payment</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '81%', float: 'left', lineHeight: 1}}>&nbsp;</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap',  width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8'}}>طريقة الدفع</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{marginTop: 5, border: '1px solid #FFF', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 0.8rem'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Terms and Conditions</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>الأحكام و الشروط</th>
        </tr></thead>
      <tbody>
        <tr>
          <td colSpan={6}>
            <ol style={{paddingLeft: 15, marginTop: 2, marginBottom: 0}}>
              <li style={{fontSize: 8, lineHeight: '1.8'}}>The tenant has inspected the premises and agreed to lease the unit on its current condition.</li>
              <li style={{fontSize: 8, lineHeight: '1.8'}}>Tenant undertakes to use the premises for designated purpose, tenant has no rights to transfer or relinquish the tenancy contract either with or to without counterpart to any without landlord written to third party in whole or in part unless it is legally permitted.</li>
              <li style={{fontSize: 8, lineHeight: '1.8'}}>The tenant undertakes not to make any amendments, modifications or addendums to the premises subject of the contract without obtaining the landlord written approval. Tenant shall be liable for any damages or failure due to that.</li>
            </ol>
          </td>
          <td colSpan={6}>
            <ol style={{paddingRight: 15, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0}}>
              <li style={{fontSize: 8}}>عاين المستأجر الوحدة موضوع الايجار ووافق على إستئجار العقار على حالته الحالية.</li>
              <li style={{fontSize: 8}}>يتعهد المستأجر باستعمال المأجور للغرض المخصص له، و لا يجوز للمستأجر تحويل أو التنازل عن عقد الايجار للغير بمقابل أو دون مقابل دون موافقة المالك خطيا، كما لا يجوز للمستأجر تأجير المأجور أو أي جزء منه من الباطن مالم يسمح بذلك قانونا</li>
              <li style={{fontSize: 8}}>يتعهد المستأجر بعدم إجراء أي تعديلات أو إضافات على العقار موضوع العقد دون موافقة المالك الخطية، و يكون المستأجر مسؤولا عن أي أضرار أو نقص أو تلف يلحق بالعقار</li>
            </ol>
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{marginTop: 10, border: '1px solid #FFF'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', fontSize: 13, paddingLeft: 10, whiteSpace: 'nowrap'}}>Signatures</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>التوقيعات</th>
        </tr></thead>
      <tbody>
        <tr>
          <td colSpan={12}>
            <div style={{marginTop: 5, border: '1px solid #374f6c', width: '50%', float: 'left'}}>
              <p style={{width: '50%', float: 'left', fontSize: 12, marginTop: 60, marginLeft: 5}}>Tenant's Signature توقيع المستأجر</p>
              <div style={{width: '45%', float: 'right', marginTop: 60}}>
                <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1}}>Date</p>
                <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 120, margin: '0 0 0 2px', lineHeight: 1}}>&nbsp;</p>
                <p style={{ float: 'left', margin: 0, fontSize: 12, lineHeight: 1}}>التاريخ</p>
              </div>
            </div>
            <div style={{marginTop: 5, border: '1px solid #374f6c', width: '49%', float: 'right'}}>
              <p style={{width: '48%', float: 'left', fontSize: 12, marginTop: 60, marginLeft: 5}}>Lessor's Signature توقيع المؤجر</p>
              <div style={{width: '49%', float: 'right', marginTop: 60}}>
                <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1}}>Date</p>
                <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 120, margin: '0 0 0 2px', lineHeight: 1}}>&nbsp;</p>
                <p style={{ float: 'left', margin: 0, fontSize: 12, lineHeight: 1}}>التاريخ</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
{/* 2nd Page */}
<div className="u-row-container">
  <div className="u-row">
    <table width="100%" style={{background: 'url("ejari-poa-background-1.png") repeat-y', backgroundSize: '30%', backgroundPosition: '50% 3rem'}}>
      <tbody><tr style={{backgroundColor: '#FFF', paddingBottom: 5}}>
          <td text-align="left">
            <img src="Government_of_Dubai_logo.png" height="38px" />
            <img src="Dubai-Land-LOGO.png" height="45px" valign="center" style={{float: 'right'}} />
          </td>
        </tr>
        <tr>
          <td colSpan={12}>
            <ol style={{paddingLeft: 15, marginTop: 2, marginBottom: 0, width: '46%', float: 'left'}} start={4}>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>The tenant shall be responsible for payment of all electricity, water, cooling and gas charges resulting of occupying leased unit unless other condition agreed in written.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>The tenant must pay the rent amount in the manner and dates agreed with the landlord.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>The tenant fully undertakes to comply with all the regulations and instructions related to the management of the property and the use of the premises and of common areas such (parking, swimming pools, gymnasium, etc…).</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Tenancy contract parties declare all mentioned emails addresses and phone numbers are correct, all formal and legal notifications will be sent to those addresses in case of dispute between parties.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>The landlord undertakes to enable the tenant of the full use of the premises including its facilities (swimming pool, gym, parking lot, etc) and do the regular maintenance as intended unless other condition agreed in written, and not to do any act that would detract from the premises benefit.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>By signing this agreement from the first party, the “Landlord” hereby confirms and undertakes that he is the current owner of the property or his legal representative under legal power of attorney duly entitled by the competent authorities.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Any disagreement or dispute may arise from execution or interpretation of this contract shall be settled by the Rental Dispute Center.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>This contract is subject to all provisions of Law No (26) of 2007 regulating the relation between landlords and tenants in the emirate of Dubai as amended, and as it will be changed or amended from time to time, as long with any related legislations and regulations applied in the emirate of Dubai.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Any additional condition will not be considered in case it conflicts with law.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>In case of discrepancy occurs between Arabic and non Arabic texts with regards to the interpretation of this agreement or the scope of its application, the Arabic text shall prevail.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>The landlord undertakes to register this tenancy contract on EJARI affiliated to Dubai Land Department and provide with all required documents.</li>
            </ol>
            <ol style={{paddingRight: 15, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '46%', float: 'right'}} start={4}>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>يكون المستأجر مسؤولا عن سداد كافة فواتير الكهرباء و المياه و التبريد و الغاز المترتبة عن اشغاله المأجور مالم يتم الاتفاق على غير ذلك كتابيا</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>يتعهد المستأجر بسداد مبلغ الايجار المتفق عليه في هذا العقد في التواريخ و الطريقة المتفق عليها.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>يلتزم المستأجر التقيد التام بالانظمة و التعليمات المتعلقة باستخدام المأجور و المنافع المشتركة
                (كمواقف السيارات، أحواض السباحة، النادي الصحي، الخ).</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>يقر أطراف التعاقد بصحة العناوين و أرقام الهواتف المذكورة أعلاه، و تكون تلك العناوين هي المعتمدة رسميا للإخطارات و الأعلانات القضائية في حال نشوء أي نزاع بين أطراف العقد</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>يتعهد المؤجر بتمكين المستأجر من الانتفاع التام بالعقار للغرض المؤجر لأجله و المرافق الخاصة به (حوض سباحة، نادي صحي، مواقف سيارات.... إلخ) كما يكون مسؤولا عن أعمال الصيانة مالم يتم الاتفاق على غير ذلك، و عدم التعرض له في منفعة العقار.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>يعتبر توقيع المؤجر على هذا العقد إقرار منه بأنه المالك الحالي للعقار أو الوكيل القانوني للمالك بموجب وكالة قانونية موثقة وفق الأصول لدى الجهات المختصة.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>أي خلاف أو نزاع قد ينشأ عن تنفيذ أو تفسير هذا العقد يعود البت فيه لمركز فض المنازعات الإيجارية</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>يخضع هذا العقد لكافة أحكام القانون رقم ( 26 ) لسنة 2007 بشأن تنظيم العلاقة بين مؤجري و مستأجري العقارات في إماراة دبي، و تعديلاته و أي تغيير أو تعديل يطرأ عليه من وقت لآخر، كما يخضع للتشريعات و اللوائح الأخرى ذات العلاقة النافذة في دبي.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 10}}>لا يعتد بأي شرط تم إضافته إلى هذا العقد في حال تعارضه مع القانون.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 15}}>في حال حدوث أي تعارض أو اختلاف في التفسير بين النص العربيو النص الأجنبي يعتمد النص العربي.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>يتعهد المؤجر بتسجيل عقد الايجار في نظام إيجاري التابع لدائرة الأراضي و الأملاك و توفير كافة المستندات اللازمة لذلك.</li>
            </ol>
          </td>
        </tr>
      </tbody></table>
    <table style={{marginTop: 5, border: '1px solid #FFF'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Know your Rights</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>لمعرفة حقوق الأطراف</th>
        </tr></thead>
      <tbody>
        <tr>
          <td colSpan={12}>
            <ul style={{paddingLeft: 15, marginTop: 2, marginBottom: 0, width: '46%', float: 'left'}}>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>You may visit Rental Dispute Center website through www.dubailand.gov.ae in case of any rental dispute between parties.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Law No 26 of 2007 regulating relationship between landlords and tenants.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Law No 33 of 2008 amending law 26 of year 2007.</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Law No 43 of 2013 determining rent increases for properties.</li>
            </ul>
            <ul style={{paddingRight: 15, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '46%', float: 'right'}}>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>يمكنكم زيارة موقع مركز فض المنازعات الإيجارية من خلال www.dubailand.gov.ae في حال نشوء أي نزاع إيجاري بين الأطراف.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>الإطلاع على قانون رقم 26 لسنة 2007 بشأن تنظيم العلاقة بين المؤجرين والمستأجرين.</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>. الإطلاع على قانون رقم 33 لسنة 2008 الخاص بتعديل بعض أحكام قانون 26 لعام 2007</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>الإطلاع على قانون رقم 43 لسنة 2013 بشأن تحديد زيادة بدل الإيجار.</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{marginTop: 5, border: '1px solid #FFF'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Attachments for Ejari Registration</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>مرفقات التسجيل في إيجاري</th>
        </tr></thead>
      <tbody>
        <tr>
          <td colSpan={12}>
            <ul style={{paddingLeft: 15, marginTop: 2, marginBottom: 0, width: '46%', float: 'left'}}>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Original unified tenancy contract</li>
              <li style={{fontSize: 8, lineHeight: '1.8', marginBottom: 5}}>Original emirates ID of applicant</li>
            </ul>
            <ul style={{paddingRight: 15, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '46%', float: 'right'}}>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>نسخة أصلية عن عقد الايجار الموحد</li>
              <li style={{fontSize: 8,  lineHeight: '1.8', marginBottom: 5}}>الهوية الإماراتية الأصلية لمقدم الطلب</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{marginTop: 5, border: '1px solid #FFF'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Additional Terms</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>شروط إضافية</th>
        </tr></thead>
      <tbody style={{background: 'url("ejari-poa-background-1.png") repeat-y', backgroundSize: '30%', backgroundPosition: '50% 0'}}>
        <tr>
          <td colSpan={12}>
            <div style={{marginTop: 10}}>
              <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>1</p>
              <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1}}>&nbsp;</p>
              <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>1</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12}>
            <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>2</p>
            <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1}}>&nbsp;</p>
            <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>2</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12}>
            <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>3</p>
            <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1}}>&nbsp;</p>
            <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>3</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12}>
            <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>4</p>
            <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1}}>&nbsp;</p>
            <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0'}}>4</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12}>
            <p style={{float: 'left', fontSize: 12, lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0', margin: '7px 0'}}>5</p>
            <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1}}>&nbsp;</p>
            <p style={{float: 'left', fontSize: 12, lineHeight: 1, borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 15, textAlign: 'center', padding: '3px 0 0', margin: '7px 0'}}>5</p>
          </td>
        </tr>
        <tr>
          <td colSpan={12}>
            <p style={{fontSize: 12, lineHeight: '1.8', marginBottom: 5, width: '48%', float: 'left'}}>Note : You may add addendum to this tenancy contract in case you have additional terms                              while it needs to be signed by all parties.</p>
            <p style={{fontSize: 12,  lineHeight: '1.8', marginBottom: 5, width: '48%', float: 'right', direction: 'rtl'}}>ملاحظة: يمكن إضافة ملحق إلى هذا العقد في حال وجود أي شروط إضافية، على أن يوقع من أطراف التعاقد.</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{marginTop: 10, border: '1px solid #FFF'}} width="100%">
      <thead style={{backgroundColor: '#374f6c'}}>
        <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'left', fontSize: 13, paddingLeft: 10, whiteSpace: 'nowrap'}}>Signatures</th>
          <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap'}}>التوقيعات</th>
        </tr></thead>
      <tbody>
        <tr>
          <td colSpan={12}>
            <div style={{marginTop: 5, border: '1px solid #374f6c', width: '50%', float: 'left'}}>
              <p style={{width: '50%', float: 'left', fontSize: 12, marginTop: 60, marginLeft: 5}}>Tenant's Signature توقيع المستأجر</p>
              <div style={{width: '45%', float: 'right', marginTop: 60}}>
                <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1}}>Date</p>
                <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 120, margin: '0 0 0 2px', lineHeight: 1}}>&nbsp;</p>
                <p style={{ float: 'left', margin: 0, fontSize: 12, lineHeight: 1}}>التاريخ</p>
              </div>
            </div>
            <div style={{marginTop: 5, border: '1px solid #374f6c', width: '49%', float: 'right'}}>
              <p style={{width: '48%', float: 'left', fontSize: 12, marginTop: 60, marginLeft: 5}}>Lessor's Signature توقيع المؤجر</p>
              <div style={{width: '49%', float: 'right', marginTop: 60}}>
                <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1}}>Date</p>
                <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 100, margin: '0 0 0 2px', lineHeight: 1}}>&nbsp;</p>
                <p style={{ float: 'left', margin: 0, fontSize: 12, lineHeight: 1}}>التاريخ</p>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={12}>
            <div style={{marginTop: 5, border: '1px solid #374f6c', width: '100%', float: 'left', textAlign: 'center'}}>
              <p style={{float: 'left', fontSize: 11, textAlign: 'center', width: '100%'}}>Tel: 8004488 Fax: +971 4 222 2251 P.O.Box 1166, Dabai, U.A.E. هاتف: ۸۰۰٤٤۸۸ فاكس: ٩٧١٤٢٢٢٢٢٥١+ ص.ب ۱۱٦٦ ، دبي ، الإمارات العربية المتحدة</p>
              <p style={{fontSize: 11}}>Website: www.dubailand.gov.ae :الموقع الإلكتروني  E-mail: support@dubailand.gov.ae    :بريد إلكتروني </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
`;
Font.register({
    family: 'CAIRO',
    src: './CAIRO-REGULAR.TTF'
  });
// Create styles
const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'CAIRO'

    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
      fontFamily: 'CAIRO'
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

// Create Document Component
class tenancyContractPdf extends Component {

    componentDidMount(){
        const tenancy_contract = store.getState((state) => state);
        const tenancy_contract_data = tenancy_contract
        console.log("pdf",tenancy_contract_data.tenancy_contract_reducer.tenancy_contract);
    }

    render(){
        const tenancy_contract = store.getState((state) => state);
        const tenancy_contract_data = tenancy_contract
        const data_object = tenancy_contract_data.tenancy_contract_reducer.tenancy_contract;
        return(
            <div>
          <ReactToPrint
            content={() => this.componentRef}
            trigger={() => <button className="btn btn-primary">Print to PDF</button>}
          />
          <DataComponent ref={(response) => (this.componentRef = response)} />
        </div>
        )
    }
// const tenancyContractPdf = () => (
   
}

export default tenancyContractPdf