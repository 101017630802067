import React, { Component } from 'react'
import { Table, Select,DatePicker,Col, Row, Button,Spin, Drawer, Radio, Upload, Input,Dropdown, Modal, Card } from 'antd';
import { UploadOutlined, FileSearchOutlined, DeleteOutlined,PaperClipOutlined, CheckOutlined, CloseOutlined, LineOutlined,  PlusOutlined, MoreOutlined, EditOutlined, DownloadOutlined,FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "../../Pages/antdstyle.css"
import "react-toastify/dist/ReactToastify.css";
import './request.css'
import API from '../../utils/API';
import { store } from '../../store';
import Moment from 'react-moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Document, Page, pdfjs } from 'react-pdf';

dayjs.extend(customParseFormat);

const {TextArea} = Input
const setNumPages=null
const pageNumber=null

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

const { RangePicker } = DatePicker;
const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });
  const disabledRangeTime = (_, type) => {
    if (type === 'start') {
      return {
        disabledHours: () => range(0, 60).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => range(0, 60).splice(20, 4),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  };

const items = [
    {key: '1', label: 'Edit',},
    {key: '2', label: 'Download',},
    {key: '3', label: 'Change Status',},
    {key: '4', label: 'Delete', },
  ];
class Mou extends Component {

    constructor(props){
        super(props);
        this.state ={
            approved_button:false,
            edit_contract_button:false,
            download_contract_button:false,
            dismiss_contract_button:false,
            status_to:"",
            record_id:"",
            mou_price:"",
            mou_transfer_date:"",
            mou_annual_charge:"",
            mou_is_vacant:"no",
            mou_tenancy_exp_date:"",
            //seller
            seller_title_deed:"",
            seller_title_file:null,
            seller_title_imageShow:null,


            seller_passport:null,
            seller_passport_imageShow:null,

            seller_emirate_id:null,
            seller_emirate_id_imageShow:null,

            seller_visa:null,
            seller_visa_imageShow:null,

            seller_commission:"",
            seller_conveyancing_fee:"",
            // buyer
            buyer_title_deed:"",
            buyer_title_file:null,
            buyer_title_file_imageShow:null,

            buyer_passport:null,
            buyer_passport_imageShow:null,

            buyer_emirate_id:null,
            buyer_emirate_id_imageShow:null,

            buyer_visa:null,
            buyer_visa_imageShow:null,

            buyer_commission:"",
            buyer_conveyancing_fee:"",
            //other details
            furnished_unfurnished:"",
            valuation_variance:"",
            special_conditions:"",
            ismourequestOpen:false,
            isLoading:false,
            mou_requests:[],
           isMouActionOpen:false,
           isMouPreviewOpen:false,
           status:"",
           dateRange:[],

           setNumPages:null,
           pageNumber:null,




           mou_status:[{
            key:1,
            labe:"Pending"
        },
        {
            key:2,
            labe:"Require Action"
        },
        {
            key:3,
            labe:"Signed"
        },
        {
            key:4,
            labe:"Dismissed"
        }
    ]
        }
    }

    componentDidMount(){
        const page = 1;
        const perPage = 10
        const data = {status:this.state.status,dateRange:this.state.dateRange}

        this.fetchData(page, perPage,data);
    }

    fetchData = (page, perPage,data) => {
        this.setState({isLoading:true})
  
        
        API.post(`/mou_requests?page=${page}&perPage=${perPage}`,data)
             .then(async (response)  =>{
                await response.data;
                this.setState({
                    mou_requests: response.data.mou_requests,
                    isLoading: false
                })

             })
          

    }
    handlemoupreview =(e) =>{
        this.setState({isMouPreviewOpen:true})
      }
      handlemoupreviewok =(e) =>{
        this.setState({isMouPreviewOpen:false})
      }
      handlemoupreviewcancel =(e) =>{
        this.setState({isMouPreviewOpen:false})
      }

    handlemourequests =(e) =>{
        this.setState({ismourequestOpen:true})
      }
      handlemourequestsok =(e) =>{
        this.setState({ismourequestOpen:false})
      }
      handlemourequestscancel =(e) =>{
        this.setState({ismourequestOpen:false})
      }
      handleMouAction =(record,e) =>{
        this.setState({isMouActionOpen:true,record_id:record.id})
      }
      handleMouActionok =(e) =>{
        
        const id=this.state.record_id
        if(this.state.edit_contract_button){
            API.get("/get_mou/"+id)
            .then((res) =>{
                if(res.data.success){
                    const mou = res.data.mou;
                this.setState({
                    mou_price:mou.price,
                    mou_transfer_date:"",
                    mou_annual_charge:mou.annual_service_amount,
                    mou_is_vacant:mou.is_vacant,
                    mou_tenancy_exp_date:"",
                    //seller
                    seller_title_deed:"",
                    seller_title_file:null,
                    
                    
                    seller_passport:null,
                    
                    seller_emirate_id:null,
                    
                    seller_visa:null,
                    
                    seller_commission:"",
                    seller_conveyancing_fee:"",
                    // buyer
                    buyer_title_deed:mou.buyer_title_deed,
                    buyer_title_file:mou.buyer_title_file,
                    
                    buyer_passport:mou.buyer_passport,
                    
                    buyer_emirate_id:mou.buyer_emirate_id,
                    
                    buyer_visa:mou.buyer_visa,
                    
                    buyer_commission:mou.buyer_commission,
                    buyer_conveyancing_fee:mou.buyer_conveyancing_fee,
                    //other details
                    furnished_unfurnished:mou.furnished_unfurnished,
                    valuation_variance:"",
                    special_conditions:"",
                })
            }
            })
            
            this.setState({ismourequestOpen:true})
            return
        }else{
            this.setState({ismourequestOpen:false})
        }
        const data = {id:id,status_to:this.state.status_to}
        const fetch_data = {status:this.state.status,dateRange:this.state.dateRange}
        const page = 1;
        const perPage = 10
          API.post("change_mou_status",data)
          .then((response) =>{
            if(response.data.success){
                this.setState({isMouActionOpen:false})
                this.fetchData(page, perPage,fetch_data);

            }
          })
       
      }
      handleMouActioncancel =(e) =>{
        this.setState({isMouActionOpen:false})
      }

      handleStatusTabs = (e,event) => { 
        this.setState({status:e})
        const data = {status:e,dateRange:this.state.dateRange}
        const page =1
        const perPage=10
        this.fetchData(page,perPage,data)
      }
      handleClearStatus = () =>{
        this.setState({status:""})
        const data = {status:"",dateRange:this.state.dateRange}
        const page =1
        const perPage=10
        this.fetchData(page,perPage,data)
      }

      handleDateRange = (value,dateString) =>{
         this.setState({dateRange:value !=null?dateString:[]})
      
         const data = {status:this.state.status,dateRange:value !=null?dateString:[]}
         const page =1
         const perPage=10
         this.fetchData(page,perPage,data)
      }

      handleApproveContract = () =>{
        this.setState({approved_button:true,edit_contract_button:false,download_contract_button:false,dismiss_contract_button:false,status_to:"Signed"})
        // const data = {id:id,status_to:"Signed"}

      }
      handleEditContract = () =>{
        this.setState({approved_button:false,edit_contract_button:true,download_contract_button:false,dismiss_contract_button:false,status_to:""})
      }
      handleDownloadContract = () =>{
        this.setState({approved_button:false,edit_contract_button:false,download_contract_button:true,dismiss_contract_button:false,status_to:""})
      }
      handleDismissContract = () =>{
        this.setState({approved_button:false,edit_contract_button:false,download_contract_button:false,dismiss_contract_button:true,status_to:"Dismissed"})
      }

    render() {
        const sellers =[
            {title:'Name', dataIndex:'name'},
            {title:'Mobile', dataIndex:'mobile'},
            {title:'Email', dataIndex:'email'},
            {title:'Agent', dataIndex:'agent'},
          ]
    
          const sellersdata =[
            {name:'Rohit Batra', mobile:'0505588463', email:'', agent:'Claire Towle'},
            {name:'Asha', mobile:'447739408250', email:'', agent:'George Hughes'},
          ]



        const columns =[
            {title: 'Edit', dataIndex:'contractedit',width:50,
                render: (text, record) => (
                    <>
                        {record.status == "Signed" ?
                            <a className='info-icon'><FilePdfOutlined /></a>
                            : <a onClick={this.handleMouAction.bind(this,record)} className='info-icon'><FileSearchOutlined /></a>
                        }
                    </>
                )
            },
            {title:'Property', dataIndex:'refno', fixed: 'left',width:200 , className:"propertywrap", 
                render: (text, record) => (
     
                    <><div className='info-group'>
                        <a onClick={this.handleMouAction.bind(this,record)} className='font-bold mb-0'>{record.refno}</a>
                        <p className='font-12'>{record.subcommunity}, {record.community}</p>
                    </div>
                    <div className='info-group'>
                        <p className='info-title'>Sales Price:</p>
                        <p className=''>{record.price? record.price + " AED": null}</p>
                    </div>
                    </>
                )
            },
            {title:'Status', dataIndex:'status',width:120 ,
            render: (text, record) => {
                var pStatus = ''
                var color = ''

                switch (record.status) {
                    case 'Pending':
                        pStatus = 'Pending'
                        color='pending'
                        break;
                    case 'Signed':
                        pStatus = 'Signed'
                        color='signed'
                        break;
                    case 'Require Action':
                        pStatus = 'Require Action'
                        color='action'
                        break;
                    case 'Requested Changes':
                        pStatus = 'Requested Changes'
                        color='changes'
                        break;
                    case 'Dismissed':
                        pStatus = 'Dismissed'
                        color='dismissed'
                        break;
                    default:
                        pStatus = 'Pending'
                        color='pending'
                        break;
                }
                return (
                    <>
                        <span className={color + " c-icon"} key={pStatus}>{pStatus}</span>
                        <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span>
                        {pStatus=='Signed' ?
                            <Button type="text" onClick={this.handlecontract}><DownloadOutlined />Download contract</Button>
                        :null}

                    </>
                );
            },},
            {title:'Created/Agent', dataIndex:'createdagent', width:150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Created at:</p>
                            <p><Moment format="MMMM DD, YYYY">{record.created_at}</Moment></p>
                        </div>
                    </>
                )
            },
            { title:'infoproperty', dataIndex:'infoproperty', width:300,
            render: (text, record) => (
                <>
                    
                    <div className='info-group'>
                        <p className='info-title'>Proposed Transfer Date:</p>
                        <p className=''>{record.transfer_date?record.transfer_date:null}</p>
                    </div>
                    <div className='info-group'>
                        <p className='info-title'>Annual Service Charge Amount:</p>
                        <p className=''>{record.annual_service_amount? record.annual_service_amount:null}</p>
                    </div>
                    <div className='info-group'>
                        <p className='info-title'>Is the property vacant:</p>
                        <p className=''>
                            {record.is_vacant? record.is_vacant==1?"Yes":"N":null}
                        </p>
                    </div>
                    {record.is_vacant ? record.is_vacant==0?
                        <div className='info-group'>
                            <p className='info-title'>Tenancy expiry date:</p>
                            <p className=''>{record.tenancy_expire_date? record.tenancy_expire_date:null}</p>
                         </div>
                    :null:null}
                </>
            )},
            {title: 'Seller', dataIndex:'seller',width:250,
            render: (text, record) => (
                <>
                <div className='info-group'>
                    <p className='info-title'>Seller:</p>
                    <p>{record.seller?record.seller.fullname:""}</p>
                    <p>{record.seller?record.seller.mobile:""}{record.seller?" | " + record.seller.email:""}</p>
                    <p></p>
                </div>
                <div className='info-group'>
                    <p className='info-title'>Agent:</p>
                    <p className=''>{record.seller?record.seller.display_name:""}</p>
                </div>
                </>
            )
            },
            {title: 'Seller Documents', dataIndex:'sellerdocuments',width:150,
                render: (text, record) => (
                <>
                    <div className='info-group'>
                        <p className='info-title'>Seller documents:</p>
                        {/* <div className='info-check'>
                            <CheckOutlined />
                            <p>Title Deed <em>*</em></p>
                        </div>
                        <div className='info-check'>
                            <CheckOutlined />
                            <p>Passport <em>*</em></p>
                        </div>
                        <div className='info-check'>
                            <LineOutlined />
                            <p>Emirates ID</p>
                        </div>
                        <div className='info-check'>
                            <LineOutlined />
                            <p>Visa</p>
                        </div> */}
                    </div>
                </>
                )
            },
            {title: 'Seller Documents', dataIndex:'sellerdocumentsinfo',width:180,
                render: (text, record) => (
                <>
                    <div className='info-group'>
                        <p className='info-title'>Seller Commission:</p>
                        <p className=''>{record.seller_commission? record.seller_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED":null }</p>
                    </div>
                    <div className='info-group'>
                        <p className='info-title'>Seller Conveyancing Fee:</p>
                        <p className=''>{ record.seller_conveyancing_fee?record.seller_conveyancing_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" :null}</p>
                    </div>
                </>
                )
            },
            {title: 'Buyer', dataIndex:'buyer',width:250,
            render: (text, record) => (
                <>
                 <div className=''>
                    <p className='info-title'>Buyer:</p>
                    <p className='mb-0'>{record.buyer?record.buyer.fullname:""}</p>
                    <p>{record.buyer?record.buyer.mobile:""}{record.buyer?" | " + record.buyer.email:""}</p>
                    <div className='info-group'>
                        <p className='info-title'>Agent:</p>
                        <p className=''>{record.buyer?record.buyer.display_name:""}</p>
                    </div>
                 </div>
                </>
            )
            },
            {title: 'Buyer Documents', dataIndex:'buyerdocuments',width:150,
                render: (text, record) => (
                <>
                    <div className='info-group'>
                        <p className='info-title'>Buyer documents:</p>
                        {/* <div className='info-check'>
                            <CheckOutlined />
                            <p>Title Deed <em>*</em></p>
                        </div>
                        <div className='info-check'>
                            <CheckOutlined />
                            <p>Passport <em>*</em></p>
                        </div>
                        <div className='info-check'>
                            <LineOutlined />
                            <p>Emirates ID</p>
                        </div>
                        <div className='info-check'>
                            <LineOutlined />
                            <p>Visa</p>
                        </div> */}
                    </div>
                </>
                )
            },
            {title: 'Buyer Documents Info', dataIndex:'buyerdocumentsinfo',width:180,
                render: (text, record) => (
                <>
                    <div className='info-group'>
                        <p className='info-title'>Buyer Commission:</p>
                        <p className=''>{record.buyer_commission?record.buyer_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):""}</p>
                    </div>
                    <div className='info-group'>
                        <p className='info-title'>Buyer Conveyancing Fee:</p>
                        <p className=''>{record.buyer_conveyancing_fee?record.buyer_conveyancing_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED":""}</p>
                    </div>
                </>
                )
            },
            {title: 'Furnished/Unfurnished', dataIndex:'furnished_unfurnished',width:180,
                render: (text, record) => (
                <>
                    <div className='info-group'>
                        <p className='info-title'>Furnished/Unfurnished:</p>
                        <p className='text-capitalize'>{record.furnished_unfurnished? record.furnished_unfurnished:null}</p>
                    </div>
                </>
                )
            },
            {title: 'Valuation Variance', dataIndex:'valuationvariance',width:150,
            render: (text, record) => (
                <>
                    <div className='info-group'>
                        <p className='info-title'>Valuation Variance:</p>
                        <p>{record.valuation_variance} %</p>
                    </div>
                </>
            )},
            {title: 'Special Conditions', dataIndex:'special_conditions', width:200,  
            render: (text, record) => (
                <>
                    <div className='info-group'>
                        <p className='info-title'>Special Conditions:</p>
                        <p>{record.special_conditions}</p>
                    </div>
                </>
            )},
            {title:'Action', dataIndex:'operation', width:50,
                render: (text, record) => (
                    <>
                        <Dropdown menu={{items}}>
                            <a><MoreOutlined className='fs-4'/></a>
                        </Dropdown>
                    </>
                ),
            }
        ]


        const handleTableChange = (pagination,filters) => {
            const data = {status:this.state.status,dateRange:this.state.dateRange}

            this.fetchData(pagination.current, pagination.pageSize,data)
        }
        
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows);
            },
          };

        return (
            <>

                <Modal  width={800} title="" open={this.state.isMouPreviewOpen} onCancel={this.handlemoupreviewcancel} className='p-4' footer="">
                    <Row span={24} className='w-100'>
                        <Col span={24}> 
                            <p className='fs-5 font-bold'>Preview Contract</p>
                            <div className='docchecklist d-flex flex-row'>
                                {/* Link to the files */}
                                <a href="" className='info-check  d-flex flex-row align-items-center'>
                                    <CheckOutlined /><p className='font-12 mb-0'>Title Deed <em>*</em></p>
                                </a>
                                <a href="" className='info-check d-flex flex-row align-items-center'>
                                    <CheckOutlined /><p className='font-12 mb-0'>Passport <em>*</em></p>
                                </a>
                                <a href="" className='info-check d-flex flex-row align-items-center'>
                                    <LineOutlined /><p className='font-12 mb-0'>Emirates ID</p>
                                </a>
                                <a href="" className='info-check d-flex flex-row align-items-center'>
                                    <LineOutlined /> <p className='font-12 mb-0'>Visa</p>
                                </a>
                            </div>
                            <Document file="./Ejari_unified_tenancy_contract_Template.pdf" onLoadSuccess={onDocumentLoadSuccess}> <Page pageNumber={pageNumber} /> </Document>
                        </Col>
                    </Row>
                    <Row className='w-100'>
                        <Col span={24} className='previewpdfactions'>
                            <Button type='secondary'>Approve</Button>
                            <Button type='primary'>Request Changes</Button>
                            <Button type='primary'>Dismiss</Button>
                        </Col>
                    </Row>
                </Modal>

                {/* MOU Actions */}
                <Modal width={800} title="" onCancel={this.handleMouActioncancel} onOk={this.handleMouActionok}  open={this.state.isMouActionOpen} className='p-4' footer="">
                    <Row span={24} className='pt-5'>
                        <Col span={24} >
                            <p className='fs-5 font-bold'>How do you want to proceed with this contract?</p>
                        </Col>
                    </Row>
                    <Row gutter={16} span={24} className='contractactions mb-5'>
                        {/* Only for admin/managers */}
                        <Col span={8}>
                            <Card style={{backgroundColor:this.state.approved_button?'#f0f0f0':'white'}} //disabled 
                            // onClick={this.handleApproveContract}
                            className=''>
                                <Button type="text" onClick={this.handlemoupreview} onClose={this.handlemoupreviewcancel}>
                                    <FilePdfOutlined className='mx-auto' /> 
                                    <p className="font-bold mb-1 mx-auto">Preview contract</p>
                                    <p className='font-12 px-1 mx-auto'>View the contract's details before you approve, seek revisions, or dismiss it.</p>
                                </Button>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card style={{backgroundColor:this.state.edit_contract_button?'#f0f0f0':'white'}} onClick={this.handleEditContract}>
                                <Button type="text" onClick={this.handlecontract}><EditOutlined className='mx-auto' />
                                    <p className="font-bold mb-1 mx-auto">Edit contract</p>
                                    <p className='font-12 px-1 mx-auto'>Make changes to the contract.</p>
                                </Button>
                            </Card>
                        </Col>
                        {/* Download - will show if the status is Signed/Approve */}
                        {/* <Col span={8}>
                            <Card style={{backgroundColor:this.state.download_contract_button?'#f0f0f0':'white'}} onClick={this.handleDownloadContract}>
                                <Button type="text"><DownloadOutlined />Download contract</Button>
                            </Card>
                        </Col> */}
                        <Col span={8}   >
                            <Card style={{backgroundColor:this.state.dismiss_contract_button?'#f0f0f0':'white'}} onClick={this.handleDismissContract}>
                                <Button type="text"><FileExcelOutlined className='mx-auto' />
                                    <p className="font-bold mb-1 mx-auto">Dismiss contract</p>
                                    <p className='font-12 px-1 mx-auto'>Close the contract without making any changes</p>
                                </Button>
                            </Card>
                        </Col>
                    </Row>
                </Modal>
                <Drawer width={1200} title="MOU Request" placement="left" onClose={this.handlemourequestscancel} open={this.state.ismourequestOpen}>
                    <div className="">
                        <Row className='border-bottom pb-2' gutter={8}>
                            <Col>
                                <h5 className='font-bold mb-0'>1212</h5>
                                <p className="mb-0 font-12">comm, sub comm</p>
                                <p className='mb-0 font-12'>1200 AED</p>
                            
                            
                            </Col>
                        
                        </Row>
                        <Row span={24} gutter={24} className='mt-3'>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label className='font-16'>Sale Price</label>
                                    <input placeholder='Enter amount'  value={this.state.mou_price} onChange={this.handleMouPrice}  />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label className='font-16'>Proposed Transfer Date</label>
                                    <DatePicker value={(this.state.mou_transfer_date) ? dayjs(this.state.mou_transfer_date) : ""}  style={{height: '36px', overflow: 'hidden'}} onChange={this.handleMouTransferDate} />
                                </div>
                            </Col>
                        </Row>
                        <Row span={24} gutter={24} className='mt-2 pt-2 border-bottom'>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label className='font-16'>Annual Service Charges Amount</label>
                                    <input placeholder='Enter amount' onChange={this.handleMouAnnualAmount} />
                                </div>
                            </Col>
                            <Col span={4}>
                                <div className='form-group'>
                                    <label  className='font-16'>Is the property vacant?</label>
                                    <Radio.Group defaultValue={this.state.mou_is_vacant} buttonStyle="solid" className='d-flex flex-row' style={{height: '36px'}} onChange={this.handleIdVacant}>
                                        <Radio.Button value="yes">Yes</Radio.Button>
                                        <Radio.Button value="no">No</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </Col>
                            {/* If it's NO show Tenance expiry date */}
                            <Col span={8}>
                                <div className='form-group'>
                                    <label className='font-16'>Tenancy expiry date</label>
                                    <DatePicker style={{height: '36px', overflow: 'hidden'}} onChange={this.handleMouExpireDate}/>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row span={24} gutter={24} className="py-3">
                            {/* SELLER - Function to add multiple sellers
                                Seller Name: *
                                Seller Mobile: *
                                Seller Email: *
                                Seller’s Agent: * */}
                            <Col span={12}>
                                <p className='font-bold text-uppercase'>Seller</p>
                                <div className='form-group mb-0'>
                                    <label className='font-16'>Search for seller</label>
                                    <input placeholder='Search' />
                                </div>
                                <Button type="link" className='font-12 p-0 text-black float-right'>Add New Seller</Button>
                            </Col>
                            <Col span={12}>
                                {/* BUYER – Function to add multiple Buyers
                                    Buyer Name:*
                                    Buyer Mobile: *
                                    Buyer Email: *
                                    Buyer’s Agent: * */}
                                <p className='font-bold text-uppercase'>Buyer</p>
                                <div className='form-group mb-0'>
                                    <label className='font-16'>Search for Buyer</label>
                                    <input placeholder='Search' />
                                </div>
                                <Button type="link" className='font-12 p-0 text-black float-right'>Add New Buyer</Button>
                            </Col>
                        </Row>
                        <Row span={24} gutter={24} className='my-2'>
                            <Col span={12}>
                            <table class="table">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Mobile</th>
      <th scope="col">Email</th>
      <th scope="col">Agent</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      {/* <td>{this.state.seller_data.fullname}</td>
      <td>{this.state.seller_data.mobile}</td>
      <td>{this.state.seller_data.email}</td>
      <td>{this.state.seller_data.display_name}</td> */}
    </tr>
  
  </tbody>
</table>
                                {/* <Table columns={sellers} dataSource={sellersdata} className='w-100 font-12 border' pagination={false}/> */}
                            </Col>
                            <Col span={12}>
                            <table class="table">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Mobile</th>
      <th scope="col">Email</th>
      <th scope="col">Agent</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      
      {/* <td>{this.state.buyer_data.fullname}</td>
      <td>{this.state.buyer_data.mobile}</td>
      <td>{this.state.buyer_data.email}</td>
      <td>{this.state.buyer_data.display_name}</td> */}
    </tr>
  
  </tbody>
</table>
                                {/* <Table columns={sellers} dataSource={sellersdata} className='w-100 font-12 border' pagination={false}/> */}
                            </Col>
                        </Row>
                        <Row span={24} gutter={24} className='my-3'>
                            <Col span={24}><p className='font-bold mb-0'>Documents needed</p></Col>
                            {/* If there is already upload documents under the listing show in here if not they can upload. */}
                            {/* Seller documents 
                                Title Deed: *
                                Passport – attachment – Minimum requirement *
                                EID – attachment
                                Visa – Attachment
                            */}
                            <Col span={12}>
                            <div className='form-group'>
                                    <label>Title Deed <em>*</em></label>
                                    <input type="text" className="form-control" onChange={this.handleSellerTitleDeed} />
                                    <div className="text-danger">
                                    {/* {this.state.errors.seller_title_deed} */}
                                  </div>
                                </div>
                                <div className='form-group'>
                                {/* <label class="input-group-text" for="inputGroupFile01">Upload</label> */}
                                 <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerTitleFile} />

                                   
                                    {/* <Upload onChange={this.handleSellerTitleFile}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>
                                <div className='form-group'>
                                    <label>Passport <em>*</em><br/><span className='font-10'>Minimum Requirement</span></label>
                                 <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerPassport} />
                                 <div className="text-danger">
                                      {/* {this.state.errors.seller_passport} */}
                                    </div>
                                    {/* <Upload onChange={this.handleSellerPassport}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>
                                <div className='form-group'>
                                    <label>Emirates ID</label>
                                    <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerEmirateID} />
                                    {/* <Upload onChange={this.handleSellerEmirateID}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>
                                <div className='form-group'>
                                    <label>Visa</label>
                                    <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerVisa} />
                                    {/* <Upload onChange={this.handleSellerVisa}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>



                               
                            </Col>
                            {/* BUYER REquirements
                            Passport – attachment – Minimum requirement*
                            EID – Attachment */}
                            <Col span={12}>
                            <div className='form-group'>
                                    <label>Title Deed <em>*</em></label>
                                    <input type="text" className="form-control" onChange={this.handleBuyerTitleDeed} />
                                    <div className="text-danger">
                                    {/* {this.state.errors.buyer_title_deed} */}
                                  </div>
                                </div>
                            <div className='form-group'>
                            <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerTitleFile} />
                                    {/* <Upload onChange={this.handleBuyerTitleFile}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>
                                <div className='form-group'>
                                    <label>Passport <em>*</em><br/><span className='font-10'>Minimum Requirement</span></label>
                                    <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerPassport} />
                                    <div className="text-danger">
                                      {/* {this.state.errors.buyer_passport} */}
                                    </div>
                                    {/* <Upload onChange={this.handleBuyerPassport}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>
                                <div className='form-group'>
                                    <label>Emirates ID</label>
                                    <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerEmirateID} />
                                    {/* <Upload onChange={this.handleBuyerEmirateID}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>
                                <div className='form-group'>
                                    <label>Visa</label>
                                    <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerVisa} />
                                    {/* <Upload onChange={this.handleBuyerVisa}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                                </div>

                            </Col>
                        </Row>
                        <Row span={24} gutter={24} className='my-2'>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label className='font-16'>Seller Commission: <em>*</em></label>
                                    <input placeholder="Enter seller's commision" onChange={this.handleSellerCommission} />
                                    <div className="text-danger">
                                      {/* {this.state.errors.seller_commission} */}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='font-16'>Seller Conveyancing Fee: <em>*</em></label>
                                    <input placeholder="Enter Seller Conveyancing Fee" onChange={this.handleSellerConveyancingFee} />
                                    <div className="text-danger">
                                      {/* {this.state.errors.seller_conveyancing_fee} */}
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label className='font-16'>Buyer Commission: <em>*</em></label>
                                    <input placeholder="Enter Buyer's commision" onChange={this.handleBuyerCommission} />
                                    <div className="text-danger">
                                      {/* {this.state.errors.buyer_commission} */}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='font-16'>Buyer Conveyancing Fee: <em>*</em></label>
                                    <input placeholder="Enter Buyer Conveyancing Fee" onChange={this.handleBuyerConveyancingFee} />
                                    <div className="text-danger">
                                      {/* {this.state.errors.buyer_conveyancing_fee} */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row span={24} gutter={24} className='my-2'>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label className='font-16'>Furnished/Unfurnished: <em>*</em></label>
                                    <Select   style={{ width: 120 }}
                                    onChange={this.handleFurnished}
                                        options={[
                                            { value: 'furnished', label: 'Furnished' },
                                            { value: 'unfurnished', label: 'Unfurnished' },
                                        ]}
                                        />
                                         <div className="text-danger">
                                      {/* {this.state.errors.furnished_unfurnished} */}
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label className='font-16'>Valuation Variance: <em>*</em></label>
                                    <Select   style={{ width: 120 }}
                                    onChange={this.handleValuationVariance}
                                        options={[
                                            { value: '0', label: '0%' },
                                            { value: '5', label: '5%' },
                                            { value: '7', label: '7%' },
                                            { value: '10', label: '10%' },
                                        ]}
                                        />
                                         <div className="text-danger">
                                      {/* {this.state.errors.valuation_variance} */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label>Special Conditions</label>
                                    <TextArea
                                        placeholder="Enter special conditions"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                        onChange={this.handleSpecialConditions}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" className='ml-auto' onClick={this.handleSubmit}>Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Drawer>



                <Row gutter={16} className="contentwrap p-4">
                    <Col span={12}>
                        <h1 className='font-bold text-uppercase my-2 fs-3'>MOU Requests</h1>
                    </Col>
                    <Col className='d-flex flex-row justify-content-between align-items-center ml-auto' span={10} style={{gap:'0.8rem'}}>
                            <RangePicker format="YYYY-MM-DD" style={{height: '36px'}} onChange={this.handleDateRange}/>
                            {/* <Button type="primary" onClick={this.handlecontract}><PlusOutlined /> Create New Contract</Button> */}
                        </Col>
                    <Col span={12}>
                        {/* <Button type='primary' className="ml-auto " onClick={this.handlemourequests}   icon={<PaperClipOutlined />}> Request for MOU</Button> */}
                    </Col>
                </Row>
                <Row className="contentwrap p-4 pt-0 mouwrap">
                    <Col span={24}>
                        <div className='tcontractwrap'>
                        <Tabs >
                        <TabList>
                        {this.state.mou_status.length > 0? <Tab onClick={this.handleClearStatus}>All</Tab>:null}
                        {this.state.mou_status.map((mou_st)=>(
                            <>
                                <Tab onClick={this.handleStatusTabs.bind(this,mou_st.labe)}><span>{mou_st.labe}</span></Tab>
                            </>
                        ))}
                        
                       </TabList>
                  </Tabs>
                      


                            <Table className='border mourequest-wrap tcontractwrap' onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                  //className="table-striped table-bordered"
                                  pagination={{
                                    pageSizeOptions: ['10', '20','50','100'],
                                    total:
                                      this.state?.mou_requests?.total > 0 &&
                                      this.state.mou_requests.total,
                                    pageSize: this.state.mou_requests.per_page,
                                    current: this.state.mou_requests.current_page,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                //   style={{ overflowX: "auto" }}
                                  columns={columns}
                                  scroll={{ x: 1500, y: 1500 }}
                                  dataSource={
                                    this.state?.mou_requests?.data
                                      ? this.state?.mou_requests?.data
                                      : []
                                  }
                                  
                                  // rowKey={(record) => record}
                                  rowKey={(record) => record.id}
                            />
                          
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

}
export default  Mou;