import React, { Component } from 'react'
import { Table, Select, DatePicker, Col, Row, Button, Spin, Drawer, Radio, Upload, Input, Menu, Dropdown, Space, Modal, Card, Divider } from 'antd';
import { UploadOutlined, FileSearchOutlined, DeleteOutlined, PaperClipOutlined, CheckOutlined, CloseOutlined, MoreOutlined, PlusOutlined, EditOutlined, CheckCircleFilled, FilePdfOutlined, FileExcelOutlined, PullRequestOutlined, FileExclamationOutlined, StopOutlined, PrinterOutlined, FileSyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "../../Pages/antdstyle.css"
import "react-toastify/dist/ReactToastify.css";
import './request.css'
import dayjs from 'dayjs';
import Moment from 'react-moment';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import API from '../../utils/API';
import { connect } from 'react-redux'
import Swal from 'sweetalert2';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from 'react-to-print';
import { store } from '../../store';


dayjs.extend(customParseFormat);

// function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

const { TextArea } = Input
const { RangePicker } = DatePicker;
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;

};
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};
const disabledDateTime = () => ({
  disabledHours: () => range(0, 24).splice(4, 20),
  disabledMinutes: () => range(30, 60),
  disabledSeconds: () => [55, 56],
});
const disabledRangeTime = (_, type) => {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, 60).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  return {
    disabledHours: () => range(0, 60).splice(20, 4),
    disabledMinutes: () => range(0, 31),
    disabledSeconds: () => [55, 56],
  };
};

const items = [
  { key: '1', label: 'Edit', },
  { key: '2', label: 'Download', },
  { key: '3', label: 'Change Status', },
  { key: '4', label: 'Delete', },
];

class Tenancy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openApprove: false,
      tenancy_contract: {},
      propertytypes: [],
      tenancy_contracts: [],
      iscontractOpen: false,
      iscontractstatusOpen: false,
      status: "",
      dateRange: [],
      record_id: "",
      record_status: "",
      current_status: 1,
      refno: "",
      preview_contract_button: false,
      edit_contract_button: false,
      dismiss_contract_button: false,
      signed_contract_button: false,
      require_action_contract_button: false,
      approved_contract_button: false,
      status_to: "",
      selectButton: true,
      tenancy_contract_status: [{
        key: 1,
        labe: "Pending"
      },
      {
        key: 2,
        labe: "Require Action"
      },

      {
        key: 5,
        labe: "Approved"
      },
      {
        key: 3,
        labe: "Signed"
      },
      {
        key: 4,
        labe: "Dismissed"
      }
      ],
      address: "",
      dewa: "",
      location: "",
      plot: "",
      property_number: "",
      property_type: "",
      building_name: "",
      bedrooms: "",
      area_size: "",
      landloard_emirate_id_number: "",
      landloard_name: "",
      landloard_nationality: "",
      landloard_mobile: "",
      landloard_email: "",
      lms_link: "",
      tenant_emirate_id_number: "",
      tenant_name: "",
      tenant_mobile: "",
      tenant_email: "",
      contract_start_date: "",
      contract_end_date: "",
      rental_price: "",
      security_deposit: "",
      contract_value: "",
      payment_type: "",
      month_number: "",
      commission: "",
      condition_terms_one: "",
      condition_terms_two: "",
      condition_terms_three: "",
      condition_terms_four: "",
      condition_terms_five: "",
      // additional_clauses_contract:"",
      additional_clauses_addendum: "",
      title_deed_image: null,
      title_deed_imageShow: null,

      landloard_passport_image: null,
      landloard_passport_imageShow: null,

      landloard_emirate_id_image: null,
      landloard_emirate_id_imageShow: null,

      tenant_passport_image: null,
      tenant_passport_imageShow: null,

      tenant_emirate_id_image: null,
      tenant_emirate_id_imageShow: null,

      tenant_visa_image: null,
      tenant_visa_imageShow: null,

      poa_image: null,
      poa_imageShow: null,

      cheque_image: null,
      cheque_imageShow: null,

      // old images
      old_title_deed_image: null,

      old_landloard_passport_image: null,

      old_landloard_emirate_id_image: null,

      old_tenant_passport_image: null,

      old_tenant_emirate_id_image: null,

      old_tenant_visa_image: null,

      old_poa_image: null,

      old_cheque_image: null,


    }
  }

  componentDidMount() {
    const page = 1
    const perPage = 10
    const data = { status: "Pending", dateRange: this.state.dateRange }
    this.fetchData(page, perPage, data)

    API.get("/propertytypes")
      .then((response) => {
        if (response.data.success) {
          this.setState({ propertytypes: response.data.propertytypes })
        }
      })
  }

  fetchData = (page, perPage, data) => {
    this.setState({ isLoading: true })
    API.post(`/tenancy_contracts?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {

        await response.data;
        this.setState({
          tenancy_contracts: response.data.tenancy_contracts,
          isLoading: false
        })

      })
  }

  handlecontract = (e) => {
    this.setState({ iscontractOpen: true })
  }
  handlecontractok = (e) => {
    this.setState({ iscontractOpen: false })
  }
  handlecontractcancel = (e) => {
    this.setState({ iscontractOpen: false })
  }

  handlecontractstatus = (record, e) => {
    this.setState({ record_id: record.id, record_status: record.status, refno: record.refno, iscontractstatusOpen: true, preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: false })
  }

  downloadContract = (record, e) => {
    const data = { id: record.id }
    API.post("download_contract", data)
      .then((response) => {
        if (response.data.success) {
          this.props.setContract(response.data)
          // this.setState({iscontractstatusOpen:false})
          window.open("/tenancyContractPdf", "_blank");
        }


      })
  }
  // handlecontractstatusok =(e) =>{
  //     const data = {id:this.state.record_id}
  //     if(this.state.preview_contract_button){


  //         API.post("download_contract",data)
  //         .then((response) =>{
  //             if(response.data.success){
  //                 this.props.setContract(response.data)
  //                 this.setState({iscontractstatusOpen:false})
  //                  window.open("/tenancyContractPdf", "_blank");
  //             }

  //         //    window.open(response.data.file_path, '_blank');
  //         })


  //     }else

  //     if(this.state.dismiss_contract_button){

  //     }else

  //     if(this.state.edit_contract_button){

  //     }else{
  //         this.setState({selectButton:false})
  //     }

  // }
  handlecontractstatuscancel = (e) => {
    this.setState({ iscontractstatusOpen: false })
  }

  handleAddress = (e) => {
    this.setState({ address: e.target.value })
  }

  handleDewa = (e) => {
    this.setState({ dewa: e.target.value })
  }
  handleLocation = (e) => {
    this.setState({ location: e.target.value })
  }

  handlePlotNumber = (e) => {
    this.setState({ plot: e.target.value })
  }
  handleBuildingName = (e) => {
    this.setState({ building_name: e.target.value })
  }
  handlePropertyNumber = (e) => {
    this.setState({ property_number: e.target.value })
  }
  handlePropertyType = (e) => {
    this.setState({ property_type: e.target.value })
  }
  handleBedroom = (e) => {
    this.setState({ bedrooms: e.target.value })
  }

  handleAreaSize = (e) => {
    this.setState({ area_size: e.target.value })
  }

  handleLandLoardEmirateIDNumber = (e) => {
    this.setState({ landloard_emirate_id_number: e.target.value })
  }
  handleLandLoardName = (e) => {
    this.setState({ landloard_name: e.target.value })
  }

  handleLandLoardMobile = (e) => {
    this.setState({ landloard_mobile: e.target.value })
  }

  handleLandLoardEmail = (e) => {
    this.setState({ landloard_email: e.target.value })
  }
  handleLMSLink = (e) => {
    this.setState({ lms_link: e.target.value })
  }
  handleTenantEmirateIDNumber = (e) => {
    this.setState({ tenant_emirate_id_number: e.target.value })
  }
  handleTenantName = (e) => {
    this.setState({ tenant_name: e.target.value })
  }
  handleTenantMobile = (e) => {
    this.setState({ tenant_mobile: e.target.value })
  }
  handleTenantEmail = (e) => {
    this.setState({ tenant_email: e.target.value })
  }
  handleStartDate = (value, dateString) => {
    this.setState({ contract_start_date: value != null ? dateString : "" })
  }

  handleEndDate = (value, dateString) => {
    this.setState({ contract_end_date: value != null ? dateString : "" })
  }

  handleRentalPrice = (e) => {
    this.setState({ rental_price: e.target.value })
  }

  handleSecurityDeposit = (e) => {
    this.setState({ security_deposit: e.target.value })
  }

  handleCommission = (e) => {
    this.setState({ commission: e.target.value })
  }
  handleContractValue = (e) => {
    this.setState({ contract_value: e.target.value })
  }
  handlePaymentType = (e) => {
    this.setState({ payment_type: e.target.value })
  }

  handleNumberOfPayment = (e) => {
    this.setState({ month_number: e.target.value })
  }


  handleConditionTermsOne = (e) => {
    this.setState({ condition_terms_one: e.target.value })
  }

  handleConditionTermsTwo = (e) => {
    this.setState({ condition_terms_two: e.target.value })
  }
  handleConditionTermsThree = (e) => {
    this.setState({ condition_terms_three: e.target.value })
  }
  handleConditionTermsFour = (e) => {
    this.setState({ condition_terms_four: e.target.value })
  }
  handleConditionTermsFive = (e) => {
    this.setState({ condition_terms_five: e.target.value })
  }

  //  handleAdditionalContract = (e) =>{
  //     this.setState({additional_clauses_contract:e.target.value})
  //  }

  handleAdditionalAddendum = (e) => {
    this.setState({ additional_clauses_addendum: e.target.value })
  }
  // title deed
  handleTitleDeed = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTitleDeedImage(files[0]);
    this.setState({ title_deed_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTitleDeedImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        title_deed_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // landloard passport
  handleLandLoardPassportFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createLandloardPassportImage(files[0]);
    this.setState({ landloard_passport_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createLandloardPassportImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        landloard_passport_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // landloard emirate id
  handleLandLoardEmirateIDFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createLandloardEmirateIdImage(files[0]);
    this.setState({ landloard_emirate_id_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createLandloardEmirateIdImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        landloard_emirate_id_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // tenant passport
  handleTenantPassportFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTenantPassportImage(files[0]);
    this.setState({ tenant_passport_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTenantPassportImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        tenant_passport_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // tenant emirate id
  handleTenantEmirateIDFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTenantEmirateIDImage(files[0]);
    this.setState({ tenant_emirate_id_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTenantEmirateIDImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        tenant_emirate_id_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // tenant visa
  handleTenantVisa = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTenantVisaImage(files[0]);
    this.setState({ tenant_visa_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTenantVisaImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        tenant_visa_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // cheque doc
  handleCheque = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createChequeImage(files[0]);
    this.setState({ cheque_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createChequeImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        cheque_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  // pow doc
  handlePOA = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createPOAImage(files[0]);
    this.setState({ poa_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createPOAImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        poa_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  handleSubmitTenancyContract = () => {
    const data = {
      lp_number: this.state.refno,
      address: this.state.address,
      dewa: this.state.dewa,
      location: this.state.location,
      plot: this.state.plot,
      property_number: this.state.property_number,
      property_type: this.state.property_type,
      building_name: this.state.building_name,
      bedrooms: this.state.bedrooms,
      area_size: this.state.area_size,
      landloard_emirate_id_number: this.state.landloard_emirate_id_number,
      landloard_name: this.state.landloard_name,
      landloard_nationality: this.state.landloard_nationality,
      landloard_mobile: this.state.landloard_mobile,
      landloard_email: this.state.landloard_email,
      lms_link: this.state.lms_link,
      tenant_emirate_id_number: this.state.tenant_emirate_id_number,
      tenant_name: this.state.tenant_name,
      tenant_mobile: this.state.tenant_mobile,
      tenant_email: this.state.tenant_email,
      contract_start_date: this.state.contract_start_date,
      contract_end_date: this.state.contract_end_date,
      rental_price: this.state.rental_price,
      security_deposit: this.state.security_deposit,
      commission: this.state.commission,
      contract_value: this.state.contract_value,
      payment_type: this.state.payment_type,
      month_number: this.state.month_number,
      condition_terms_one: this.state.condition_terms_one,
      condition_terms_two: this.state.condition_terms_two,
      condition_terms_three: this.state.condition_terms_three,
      condition_terms_four: this.state.condition_terms_four,
      condition_terms_five: this.state.condition_terms_five,
      //  additional_clauses_contract:this.state.additional_clauses_contract,
      additional_clauses_addendum: this.state.additional_clauses_addendum,
      title_deed_image: this.state.title_deed_image,

      landloard_passport_image: this.state.landloard_passport_image,

      landloard_emirate_id_image: this.state.landloard_emirate_id_image,

      tenant_passport_image: this.state.tenant_passport_image,

      tenant_emirate_id_image: this.state.tenant_emirate_id_image,

      tenant_visa_image: this.state.tenant_visa_image,

      poa_image: this.state.poa_image,

      cheque_image: this.state.cheque_image,

      old_title_deed_image: this.state.old_title_deed_image,

      old_landloard_passport_image: this.state.old_landloard_passport_image,

      old_landloard_emirate_id_image: this.state.old_landloard_emirate_id_image,

      old_tenant_passport_image: this.state.old_tenant_passport_image,

      old_tenant_emirate_id_image: this.state.old_tenant_emirate_id_image,

      old_tenant_visa_image: this.state.old_tenant_visa_image,

      old_poa_image: this.state.old_poa_image,

      old_cheque_image: this.state.old_cheque_image
    }

    const id = this.state.record_id

    const fetch_data = { status: this.state.status, dateRange: this.state.dateRange }
    const page = 1
    const perPage = 10

    API.put("/tenancy_contract/" + id, data)
      .then((res) => {

        if (res.data.success) {
          toast.success("Successfully Updated")
          this.setState({ iscontractstatusOpen: false, iscontractOpen: false })

          this.fetchData(page, perPage, fetch_data)

          const email_data = { tenancy_contract_id: res.data.tenancy_contract_id }
          API.post("/tenancy_contract_edited_email", email_data)
            .then((res) => {
              console.log("success");
            })
        }
      })
  }

  handleStatusTabs = (e, event) => {

    this.setState({ status: e })
    const data = { status: e, dateRange: this.state.dateRange }
    const page = 1
    const perPage = 10
    this.fetchData(page, perPage, data)
  }

  handleClearStatus = () => {
    this.setState({ status: "" })
    const data = { status: "", dateRange: this.state.dateRange }
    const page = 1
    const perPage = 10
    this.fetchData(page, perPage, data)
  }

  handleDateRange = (value, dateString) => {

    this.setState({ dateRange: value != null ? dateString : [] })

    const data = { status: this.state.status, dateRange: value != null ? dateString : [] }
    const page = 1
    const perPage = 10
    this.fetchData(page, perPage, data)
  }

  handlePreviewContract = () => {
    const data = { id: this.state.record_id }
    this.setState({ preview_contract_button: true, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: false, status_to: "" })
    API.post("download_contract", data)
      .then((response) => {
        if (response.data.success) {
          this.props.setContract(response.data)
          this.setState({ iscontractstatusOpen: false })
          window.open("/tenancyContractPdf", "_blank");
        }


      })

  }
  handleEditContract = () => {
    this.setState({ preview_contract_button: false, edit_contract_button: true, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: false, status_to: "" })
    const id = this.state.record_id
    API.get("/get_tenancy_contract/" + id)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            address: res.data.tenancy_contract.address,
            dewa: res.data.tenancy_contract.dewa,
            location: res.data.tenancy_contract.location,
            // payment_type:res.data.tenancy_contract.payment_type,
            plot: res.data.tenancy_contract.plot,
            property_number: res.data.tenancy_contract.property_number,
            building_name: res.data.tenancy_contract.building_name,
            property_type: res.data.tenancy_contract.property_category_id,
            bedrooms: res.data.tenancy_contract.bedrooms,
            area_size: res.data.tenancy_contract.area_size,
            landloard_emirate_id_number: res.data.tenancy_contract.landloard_emirate_id_number,
            landloard_name: res.data.tenancy_contract.landloard_name,
            landloard_nationality: res.data.tenancy_contract.landloard_nationality,
            landloard_mobile: res.data.tenancy_contract.landloard_mobile,
            landloard_email: res.data.tenancy_contract.landloard_email,
            lms_link: res.data.tenancy_contract.lms_link,
            tenant_emirate_id_number: res.data.tenancy_contract.tenant_emirate_id_number,
            tenant_name: res.data.tenancy_contract.tenant_name,
            tenant_mobile: res.data.tenancy_contract.tenant_mobile,
            tenant_email: res.data.tenancy_contract.tenant_email,
            contract_start_date: res.data.tenancy_contract.start_date,
            contract_end_date: res.data.tenancy_contract.end_date,
            rental_price: res.data.tenancy_contract.rental_price,
            security_deposit: res.data.tenancy_contract.security_deposit,
            commission: res.data.tenancy_contract.commission,
            payment_type: res.data.tenancy_contract.payment_type,
            month_number: res.data.tenancy_contract.month_no,
            condition_terms_one: res.data.tenancy_contract.condition_terms_one,
            condition_terms_two: res.data.tenancy_contract.condition_terms_two,
            condition_terms_three: res.data.tenancy_contract.condition_terms_three,
            condition_terms_four: res.data.tenancy_contract.condition_terms_four,
            condition_terms_five: res.data.tenancy_contract.condition_terms_five,
            // additional_clauses_contract:res.data.tenancy_contract.additional_clauses_contract,
            additional_clauses_addendum: res.data.tenancy_contract.additional_clauses_addendum,

            old_title_deed_image: res.data.tenancy_contract.title_deed,

            old_landloard_passport_image: res.data.tenancy_contract.landlord_passport,

            old_landloard_emirate_id_image: res.data.tenancy_contract.landlord_emirate_id,

            old_tenant_passport_image: res.data.tenancy_contract.tenant_passport,

            old_tenant_emirate_id_image: res.data.tenancy_contract.tenant_emirate_id,

            old_tenant_visa_image: res.data.tenancy_contract.tenant_visa,

            old_poa_image: res.data.tenancy_contract.poa_document,

            old_cheque_image: res.data.tenancy_contract.cheque_document,


            iscontractstatusOpen: false, iscontractOpen: true
          })
        }



      })
  }
  handleDismissContract = () => {
    this.setState({ preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: true, signed_contract_button: false, require_action_contract_button: false, approved_contract_button: false, status_to: "Dismissed" })
    const data = { id: this.state.record_id, status_to: "Dismissed" }
    const page = 1
    const perPage = 10
    const fetch_data = { status: this.state.status, dateRange: this.state.dateRange }

    Swal.fire({
      title: "Are you sure you want dismiss the contract?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.post("change_tenancy_contract_status", data)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully Dismissed")
              this.setState({ iscontractstatusOpen: false, preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: false, status_to: "" })
              this.fetchData(page, perPage, fetch_data);

              const email_data = { tenancy_contract_id: response.data.tenancy_contract_id }

              API.post("/tenancy_contract_status_email", email_data)
                .then((res) => {
                  console.log("success");
                })

            }
          })
      }
    })



  }

  handleSignedContract = () => {
    this.setState({ preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: true, require_action_contract_button: false, approved_contract_button: false, status_to: "Signed" })
    const data = { id: this.state.record_id, status_to: "Signed" }
    const page = 1
    const perPage = 10
    const fetch_data = { status: this.state.status, dateRange: this.state.dateRange }

    Swal.fire({
      title: "Are you sure you want Signed the contract?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.post("change_tenancy_contract_status", data)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully Signed")
              this.setState({ iscontractstatusOpen: false, preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: false, approved_contract_button: false, status_to: "" })
              this.fetchData(page, perPage, fetch_data);
              const email_data = { tenancy_contract_id: response.data.tenancy_contract_id }

              API.post("/tenancy_contract_status_email", email_data)
                .then((res) => {
                  console.log("success");
                })

            }
          })
      }
    })



  }

  handleRequireActionContract = () => {
    this.setState({ preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: true, approved_contract_button: false, status_to: "Require Action" })
    const data = { id: this.state.record_id, status_to: "Require Action" }
    const page = 1
    const perPage = 10
    const fetch_data = { status: this.state.status, dateRange: this.state.dateRange }

    Swal.fire({
      title: "Are you sure you want change the contract?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.post("change_tenancy_contract_status", data)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully changed to Require Action")
              this.setState({ iscontractstatusOpen: false, openApprove: false, preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: false, status_to: "" })
              this.fetchData(page, perPage, fetch_data);
              const email_data = { tenancy_contract_id: response.data.tenancy_contract_id }

              API.post("/tenancy_contract_status_email", email_data)
                .then((res) => {
                  console.log("success");
                })

            }
          })
      }
    })



  }

  handleToApprovedContract = () => {

    this.setState({ preview_contract_button: true, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: false, approved_contract_button: true, status_to: "Approved", openApprove: true })

    const data = { id: this.state.record_id }
    API.post("download_contract", data)
      .then((response) => {

        if (response.data.success) {
          this.setState({ tenancy_contract: response.data.tenancy_contract })
        }


      })

  }

  handleApproved = () => {
    const data = { id: this.state.record_id, status_to: "Approved" }
    const page = 1
    const perPage = 10
    const fetch_data = { status: this.state.status, dateRange: this.state.dateRange }

    Swal.fire({
      title: "Are you sure you want approve?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.post("change_tenancy_contract_status", data)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully Approved")
              this.setState({ iscontractstatusOpen: false, openApprove: false, preview_contract_button: false, edit_contract_button: false, dismiss_contract_button: false, signed_contract_button: false, require_action_contract_button: false, approved_contract_button: false, status_to: "" })
              this.fetchData(page, perPage, fetch_data);
              const email_data = { tenancy_contract_id: response.data.tenancy_contract_id }

              API.post("/tenancy_contract_status_email", email_data)
                .then((res) => {
                  console.log("success");
                })
            }
          })
      }
    })



  }
  handleCancelApprove = () => {
    this.setState({ openApprove: false })
  }

  render() {
    const authentication = store.getState((state) => state);
    const user = authentication.auth.user
    const role = user.role

    const columns = [
      {
        title: 'Edit', dataIndex: 'contractedit', width: 50,
        render: (text, record) => (
          <>
            <a onClick={this.handlecontractstatus.bind(this, record)} className='info-icon'><FileSearchOutlined /></a>
          </>
        )
      },
      {
        title: 'Property', dataIndex: 'property', className: "propertywrap",
        render: (text, record) => (
          <><div className='info-group'>
            <a onClick={this.handlecontractstatus.bind(this, record)} className='font-bold mb-0'>{record.refno}</a>
            <p className='font-12'>{record.community},{record.subcommunity}</p>
          </div></>
        )
      },
      {
        title: 'Status', dataIndex: 'contractstatus',
        render: (text, record) => (
          <>
            {record.status === 'Signed' ?
              <><span className='c-icon signed'>Signed</span>
                <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
              : record.status === 'Approved' ?
                <><span className='c-icon signed'>Approved</span>
                  <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                : record.status === 'Require Action' ?
                  <><span className='c-icon action'>Request Changes</span>
                    <span className='datestat'> <Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                  : record.status === 'Pending' ?
                    <><span className='c-icon pending'>Pending</span>
                      <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                    : record.status === 'Requested Changes' ?
                      <><span className='c-icon changes'>Request Changes</span>
                        <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                      : record.status === 'Dismissed' ?
                        <><span className='c-icon dismissed'>Dismissed</span>
                          <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                        : /*default */
                        <><span className='c-icon pending'>Pending</span>
                          <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
            }
          </>
        )
      },
      {
        title: 'Created/Agent', dataIndex: 'createdagent',
        render: (text, record) => (
          <>
            <div className='info-group'>
              <p className='info-title'>Created at:</p>
              <p className=''><Moment format="MMMM DD, YYYY">{record.created_at}</Moment></p>
            </div>
            <div className='info-group'>
              <p className='info-title'>Agent:</p>
              <p className=''>{record.display_name}</p>
            </div>
          </>
        )
      },
      {
        title: 'Landlord', dataIndex: 'landlord',
        render: (text, record) => (
          <>
            <div className='info-group'>
              <p className='info-title'>Landlord:</p>
              <p>{record.landloard?.fullname}</p>
              <p>{record.landloard?.mobile}</p>
              <p>{record.landloard?.email}</p>
            </div>
          </>
        )
      },
      {
        title: 'Tenant', dataIndex: 'tenant',
        render: (text, record) => (
          <>
            <div className='info-group'>
              <p className='info-title'>Tenant:</p>
              <p>{record.tenant?.fullname}</p>
              <p>{record.tenant?.mobile}</p>
              <p>{record.tenant?.email}</p>
            </div>
          </>
        )
      },
      {
        title: 'Contract Details', dataIndex: 'contractdetails',
        render: (text, record) => (
          <>
            <div className='info-group'>
              <p className='info-title'>Contract Details:</p>
              <p>
                {
                  record.start_date == null || record.start_date == '' || record.start_date == "0000-00-00 00:00:00" ?
                    ''
                    :
                    <>
                      <Moment format="MMMM DD, YYYY">{record.start_date}</Moment>

                    </>
                } -
                {
                  record.end_date == null || record.end_date == '' || record.end_date == "0000-00-00 00:00:00" ?
                    ''
                    :
                    <>
                      <Moment format="MMMM DD, YYYY">{record.end_date}</Moment>

                    </>
                }
              </p>
              {record.rental_price != null ?
                <p>{record.rental_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                : null}
              <p>{record.month_no} - {record.payment_type == 1 ? "Cheque" : "Bank Transfer"} </p>
            </div>
          </>
        )
      },
      {
        title: 'Document Checklist', dataIndex: 'docchecklist',
        render: (text, record) => (
          <>
            <p className='info-title'>Document Checklist:</p>
            <div className='info-check'>
              {record.title_deed != null ? <CheckOutlined /> : <CloseOutlined />}

              <p>Title Deed</p>
            </div>
            <div className='info-check'>
              {record.landlord_passport != null || record.landlord_emirate_id != null ? <CheckOutlined /> : <CloseOutlined />}
              <p>Landlord Passport/ID</p>
            </div>
            <div className='info-check'>
              {record.tenant_passport != null || record.tenant_emirate_id != null ? <CheckOutlined /> : <CloseOutlined />}
              <p>Tenant Passport/ID</p>
            </div>
            <div className='info-check'>
              {record.tenant_visa != null ? <CheckOutlined /> : <CloseOutlined />}
              <p>Tenant Visa</p>
            </div>
          </>
        )
      },

      {
        title: 'Contract',
        render: (text, record) => (
          <div className='info-group'>
            <p className='info-title'>Contract PDF</p>
            <a className='btn btn p-0' target="_blank" onClick={this.downloadContract.bind(this, record)} ><FilePdfOutlined /> </a>
          </div>
        ),
        hidden: this.state.status == "Signed" ? false : true
      }
    ].filter(item => !item.hidden);

    const handleTableChange = (pagination, filters) => {


      const data = { status: this.state.status, dateRange: this.state.dateRange }

      this.fetchData(pagination.current, pagination.pageSize, data);


    };



    const allListingColumns = [
      {
        title: 'cardColumn', className: 'namecolwrap',
        // style: { zIndex: '9' },
        sorter: (a, b) => a.refno.length - b.refno.length,
        render: (text, record) => {

          return (<>

            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                    <div className='info-group'>
                      <div className='d-flex gap-1  HeaderMainDivRight'>
                        <a onClick={this.handlecontractstatus.bind(this, record)} className='info-icon'><FileSearchOutlined /></a>
                        <p className='font-bold fs-6  mb-0'><a onClick={this.handlecontractstatus.bind(this, record)} className='font-bold mb-0'>{record.refno}</a></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    <p>{record.status === 'Signed' ?
                      <><span className='c-icon signed'>Signed</span>
                        <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                      : record.status === 'Approved' ?
                        <><span className='c-icon signed'>Approved</span>
                          <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                        : record.status === 'Require Action' ?
                          <><span className='c-icon action'>Request Changes</span>
                            <span className='datestat'> <Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                          : record.status === 'Pending' ?
                            <><span className='c-icon pending'>Pending</span>
                              <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                            : record.status === 'Requested Changes' ?
                              <><span className='c-icon changes'>Request Changes</span>
                                <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                              : record.status === 'Dismissed' ?
                                <><span className='c-icon dismissed'>Dismissed</span>
                                  <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                                : /*default */
                                <><span className='c-icon pending'>Pending</span>
                                  <span className='datestat'><Moment format="MMMM DD, YYYY">{record.updated_at}</Moment></span></>
                    }</p>
                  </div>

                </div>
              </div>


              <div className='row alignCenter borderGray pt-2 pb-2'>
                <div className='col-12 col-sm-6'>
                  <div className='BodyFirstRowLeft'>
                    <div className='d-flex gap-1 BodyFirstRowRight'>
                      <p>{record.community ? record.community : '---' },{record.subcommunity}</p>
                    </div>

                  </div>
                </div>
                <div className='col-12 col-sm-6'>

                  <div className='d-flex gap-1 BodyFirstRowRight'>
                    <p className='info-title'>Created At:</p>
                    <p><Moment format="MMMM DD, YYYY">{record.created_at}</Moment></p>
                  </div>

                  <div className='d-flex gap-1 BodyFirstRowRight'>
                    <p className='info-title'>Agent:</p>
                    <p>{record.display_name}</p>
                  </div>

                </div>
              </div>




              <div className='row alignCenter borderGray pt-2 pb-2'>
                <div className='col-12 col-sm-6'>
                  <div className='BodyFirstRowRight'>
                    <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                      <p className='info-title'>Landlord:</p>
                      <p className='px-2'>

                        <div className='row'>
                          <div className='col-12'>
                            <div className='BodyFirstRowRight'>
                              <div className='d-flex gap-1 BodyFirstRowRight'>
                                <p className='font-bold' style={{ fontSize: '14px' }}>
                                  <p>{record.landloard.fullname ? record.landloard.fullname : '---'}</p>
                                  <p>{record.landloard.mobile ? record.landloard.mobile : '---'}</p>
                                  <p>{record.landloard.email ? record.landloard.email : '---'}</p>
                                </p>


                              </div>
                            </div>

                          </div>
                        </div>

                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-12 col-sm-6'>
                  <div className='BodyFirstRowRight'>
                    <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                      <p className='info-title'>Tenant:</p>
                      <p className='px-2'>

                        <div className='row'>
                          <div className='col-12'>
                            <div className='BodyFirstRowRight'>
                              <div className='d-flex gap-1 BodyFirstRowRight'>
                                <p className='font-bold' style={{ fontSize: '14px' }}>
                                  <p>{record.tenant?.fullname}</p>
                                  <p>{record.tenant?.mobile}</p>
                                  <p>{record.tenant?.email}</p>
                                </p>


                              </div>
                            </div>

                          </div>
                        </div>

                      </p>
                    </div>
                  </div>
                </div>




                <div className='col-12 col-sm-6'>
                  <div className='BodyFirstRowRight'>
                    <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                      <p className='info-title'>Contract Details:</p>
                      <p className='px-2'>

                        <div className='row'>
                          <div className='col-12'>
                            <div className='BodyFirstRowRight'>
                              <div className='d-flex gap-1 BodyFirstRowRight'>
                                <p className='font-bold' style={{ fontSize: '14px' }}>
                                  <p>
                                    {
                                      record.start_date == null || record.start_date == '' || record.start_date == "0000-00-00 00:00:00" ?
                                        ''
                                        :
                                        <>
                                          <Moment format="MMMM DD, YYYY">{record.start_date}</Moment>

                                        </>
                                    } -
                                    {
                                      record.end_date == null || record.end_date == '' || record.end_date == "0000-00-00 00:00:00" ?
                                        ''
                                        :
                                        <>
                                          <Moment format="MMMM DD, YYYY">{record.end_date}</Moment>

                                        </>
                                    }
                                  </p>
                                  {record.rental_price != null ?
                                    <p>{record.rental_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                    : null}
                                  <p>{record.month_no} - {record.payment_type == 1 ? "Cheque" : "Bank Transfer"} </p>
                                </p>


                              </div>
                            </div>

                          </div>
                        </div>

                      </p>
                    </div>
                  </div>
                </div>








                <div className='col-12 col-sm-6'>
                  <div className='BodyFirstRowRight'>
                    <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                      <p className='info-title'>Document Checklist:</p>
                      <p className='px-2'>

                        <div className='row'>
                          <div className='col-12'>
                            <div className='BodyFirstRowRight'>
                              <div className='d-flex gap-1 BodyFirstRowRight'>
                                <p className='font-bold' style={{ fontSize: '14px' }}>
                                  <div className='info-check'>
                                    {record.title_deed != null ? <CheckOutlined /> : <CloseOutlined />}

                                    <p>Title Deed</p>
                                  </div>
                                  <div className='info-check'>
                                    {record.landlord_passport != null || record.landlord_emirate_id != null ? <CheckOutlined /> : <CloseOutlined />}
                                    <p>Landlord Passport/ID</p>
                                  </div>
                                  <div className='info-check'>
                                    {record.tenant_passport != null || record.tenant_emirate_id != null ? <CheckOutlined /> : <CloseOutlined />}
                                    <p>Tenant Passport/ID</p>
                                  </div>
                                  <div className='info-check'>
                                    {record.tenant_visa != null ? <CheckOutlined /> : <CloseOutlined />}
                                    <p>Tenant Visa</p>
                                  </div>
                                </p>


                              </div>
                            </div>

                          </div>
                        </div>

                      </p>
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </>)
        }

      }
    ].filter(item => !item.hidden);


    return (
      <>
        <Modal width={ window.isiPadView? '100%':'70%'} title={""} className={'modaltenancypdf'}
          open={this.state.openApprove}
          //   onOk={handleOk} 
          onCancel={this.handleCancelApprove}
          footer={null}
          maskClosable={false}
        >
          <Row span={24} className='w-100'>
            <Col span={6} lg={6} md={24} sm={24} xs={24}>
              <div className='p-4 tenancypdf-buttons' style={{ display: 'flex', flexDirection: window.isIpadView ? 'row':'column', justifyContent: window.isIpadView ?'left':'space-between', flexWrap: window.isIpadView ? 'wrap' : '', alignItems:'baseline', gap:window.isIpadView? '10px' : ''}}>
                <p className={`${window.isIpadView ? 'w-100' : ''} font-bold fs-5`}>Preview Tenancy Contract</p>
                {role == 1 ? <><a className={`${window.isIpadView ? '' : 'w-100'} btn btn-text btngreen ${window.isMobileView ? '' : 'font-14'} `} onClick={this.handleApproved}><CheckOutlined />Approved</a>
                  <a className={`${window.isMobileView ? '' : 'font-14'} btn btn-text`} onClick={this.handleRequireActionContract}><FileExclamationOutlined />Require Action</a></> : null}

                <div className="" />
                <ReactToPrint
                  content={() => this.componentRef}
                  trigger={() => <button className= {`${window.isMobileView ? '' : 'font-14'} btn btn-text`}><PrinterOutlined />Print to PDF</button>}

                />
                <a className={`${window.isMobileView ? '' : 'font-14'} btn btn-text`} onClick={this.handleCancelApprove}><CloseCircleOutlined />Close</a>
              </div>

            </Col>
            <Col span={18} lg={18} md={24} sm={24} xs={24} className='pdfscroll'>
              <div ref={(response) => (this.componentRef = response)} className='w-100'>



                <div className="u-row-container" style={{ height: '293mm', width: "210mm", border: "1px solid #151515", margin: "0 auto", backgroundColor: "#FFF", padding: "2px 1.5rem" }}>
                  <div className="u-row" style={{}}>
                    <table width="100%">
                      <tbody><tr style={{ backgroundColor: '#FFF', paddingBottom: 5 }}>
                        <td text-align="left">
                          <img src="Government_of_Dubai_logo.png" height="32px" />
                          <img src="Dubai-Land-LOGO.png" height="40px" valign="center" style={{ float: 'right' }} />
                        </td>
                      </tr>
                        <tr style={{ border: '1px solid #4f647e', backgroundColor: '#FFF' }}>
                          <td style={{ verticalAlign: 'bottom', padding: 5, border: '1px solid #4f647e', width: '100%' }}>
                            <div style={{ width: '33%', float: 'left', paddingTop: 25, paddingLeft: 10 }}>
                              <p style={{ float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Date</p>
                              <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 120, margin: '0 0 0 2px', lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif' }}><Moment format="MMMM DD, YYYY" style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12, paddingLeft: "10px" }}>{this.state.tenancy_contract.created_at}</Moment></p>
                              <p style={{ fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 12, lineHeight: 1, color: '#2d4253' }}>التاريخ</p>
                            </div>
                            <div style={{ width: '62%', float: 'right' }}><img src="tenancycontract-logo.png" height="40px" /></div>
                          </td>
                        </tr>
                      </tbody></table>
                    <table style={{ marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Owner / Lessor Information</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>معلومات المالك/ المؤجر</th>
                        </tr></thead>
                      <tbody><tr>
                        <td colSpan={12} style={{ padding: '10px 15px 0' }}>
                          <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Owner's Name</p>
                          <p style={{ borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.landloard_name}</p>
                          <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>اسم المالك</p>
                        </td>
                      </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', letterSpacing: '-0.6px', color: '#2d4253' }}>Lessor's Name</p>
                            <p style={{ borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.landloard_name}</p>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>اسم المؤجر</p>
                          </td>

                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Lessor's Emirates ID</p>
                            <p style={{ borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1, textIndent: '1rem', fontFamily: 'Arial, Helvetica, sans-serif', color: '#000', height: '16px' }}>{this.state.tenancy_contract.landloard_emirate_id_number}</p>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>الهوية اللإمارتية للمؤجر</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '45%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '18%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>License No.</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '65%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif !important', color: '#000' }}>&nbsp;</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم الرخصة</p>
                            </div>
                            <div style={{ width: '50%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '23%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>License Authority</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '57%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>&nbsp;</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '17%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>سلطة الترخيص</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '100%', marginTop: '-15px' }}><p style={{ fontSize: 8, width: '50%', margin: '0 0 0 -3%', float: 'left', textAlign: 'center' }}><span style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', marginRight: '10px' }}>Incase of a Company</span><span style={{ fontFamily: 'Cairo, sans-serif' }}> في حال كانت شركة</span></p></div>
                            <div style={{ width: '100%', marginTop: '-15px' }}><p style={{ fontSize: 8, width: '48%', margin: '0 0 0 5%', float: 'left', textAlign: 'center' }}><span style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', marginRight: '15px' }}>Incase of a Company</span><span style={{ fontFamily: 'Cairo, sans-serif', color: '#2d4253' }}> في حال كانت شركة</span></p></div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '5px 15px 0' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Lessor's Email</p>
                            <p style={{ borderBottom: '1px dashed #4f647e', width: '75%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.landloard_email}</p>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>البريد اللإلكتروني للمؤجر</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '11%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Lessor's Phone</p>
                            <p style={{ borderBottom: '1px dashed #4f647e', width: '78%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.landloard_mobile}</p>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '11%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم هاتف المؤجر</p>
                          </td>
                        </tr>
                      </tbody></table>
                    <table style={{ marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Tenant Information</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>معلومات المالك/ المؤجر</th>
                        </tr></thead>
                      <tbody><tr>
                        <td colSpan={12} style={{ padding: '10px 15px 0' }}>
                          <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Tenant's Name</p>
                          <p style={{ borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.tenant_name}</p>
                          <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>اسم المستأجر</p>
                        </td>
                      </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '12%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', letterSpacing: '-0.6px', color: '#2d4253' }}>Tenant's Emirates ID</p>
                            <p style={{ borderBottom: '1px dashed #4f647e', width: '73%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.tenant_emirate_id_number}</p>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>الهوية الإماراتية للمستأجر</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '45%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '20%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>License No.</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '65%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>&nbsp;</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم الرخصة</p>
                            </div>
                            <div style={{ width: '50%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '25%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>License Authority</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '60%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>&nbsp;</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>سلطة الترخيص</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '100%', marginTop: '-15px' }}><p style={{ fontSize: 8, width: '50%', margin: '0 0 0 -3%', float: 'left', textAlign: 'center' }}><span style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Incase of a Company</span><span style={{ fontFamily: 'Cairo, sans-serif', color: '#2d4253' }}> في حال كانت شركة</span></p></div>
                            <div style={{ width: '100%', marginTop: '-15px' }}><p style={{ fontSize: 8, width: '48%', margin: '0 0 0 5%', float: 'left', textAlign: 'center' }}><span style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Incase of a Company</span><span style={{ fontFamily: 'Cairo, sans-serif', color: '#2d4253' }}> في حال كانت شركة</span></p></div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '5px 15px 0' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Tenant's Email</p>
                            <p style={{ borderBottom: '1px dashed #4f647e', width: '74%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.tenant_email}</p>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>البريد الإلكتروني للمستأجر</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '12%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Tenant's Phone</p>
                            <p style={{ borderBottom: '1px dashed #4f647e', width: '72%', float: 'left', lineHeight: 1, textIndent: '1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color: '#000' }}>{this.state.tenancy_contract.tenant_mobile}</p>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم هاتف المستأجر</p>
                          </td>
                        </tr>
                      </tbody></table>
                    <table style={{ marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Property Information</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>معلومات العقار</th>
                        </tr></thead>
                      <tbody><tr>
                        <td colSpan={24} style={{ padding: '0 15px 0' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '19%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', height: '16px', margin: 0, color: '#2d4253' }}>Property Usage</p>
                            <div className="form-group" style={{ float: 'left', margin: '3px 2%', lineHeight: '1.8', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <input type="radio" id="industrial" disabled name="property_usage" defaultValue="Industrial" style={{ float: 'left', width: "10px", height: "16px", marginRight: "9px" }} />
                              <label htmlFor="industrial" style={{ fontSize: 11, whiteSpace: 'nowrap', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', margin: 0 }}>Industrial <span style={{ fontFamily: 'Cairo, sans-serif', marginLeft: '5px' }}>صناعي</span></label>
                            </div>
                            <div className="form-group" style={{ float: 'left', margin: '3px 2%', lineHeight: '1.8', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <input type="radio" id="commercial" name="property_usage" defaultValue="Commercial" style={{ float: 'left', width: "10px", height: "17px", marginRight: "9px" }} disabled />
                              <label htmlFor="industrial" style={{ fontSize: 11, whiteSpace: 'nowrap', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', margin: '0' }}>Commercial<span style={{ fontFamily: 'Cairo, sans-serif', marginLeft: '5px' }}> تجاري</span></label>
                            </div>
                            <div className="form-group" style={{ float: 'left', margin: '3px 2%', lineHeight: '1.8', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <input type="radio" id="residential" name="property_usage" defaultValue="Residential" style={{ float: 'left', width: "10px", height: "17px", marginRight: "9px", borderColor: '#2d4253' }} disabled defaultChecked />
                              <label htmlFor="industrial" style={{ fontSize: 11, whiteSpace: 'nowrap', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', margin: 0 }}>Residential<span style={{ fontFamily: 'Cairo, sans-serif', marginLeft: '5px' }}> سكني</span></label>
                            </div>
                            <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253', margin: 0 }}>استخدام العقار</p>
                          </div>
                        </td>


                      </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '45%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Plot No.</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '70%', float: 'left', lineHeight: 1, textIndent: '1rem', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', height: '16px' }}>{this.state.tenancy_contract.plot}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم الأرض</p>
                            </div>
                            <div style={{ width: '50%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Makani No.</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1, textIndent: '1rem', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', height: '16px' }}>&nbsp;</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم مكاني</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '45%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '21%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Building Name</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '62%', float: 'left', lineHeight: 1, height: '16px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253', textIndent: 10 }}>{this.state.tenancy_contract.building_name}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>اسم المبنى</p>
                            </div>
                            <div style={{ width: '50%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '16%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Property No.</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '69%', float: 'left', lineHeight: 1, textIndent: '1rem', height: '16px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>{this.state.tenancy_contract.property_number}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم العقار</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '45%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '21%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Property Type</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '62%', float: 'left', lineHeight: 1, textIndent: '1rem', height: '16px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>{this.state.tenancy_contract.property_type}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>نوع الوحدة</p>
                            </div>
                            <div style={{ width: '50%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '27%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Property Area (s.m)</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '44%', float: 'left', lineHeight: 1, textIndent: '1rem', height: '16px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>{this.state.tenancy_contract.area_size}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '29%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>مساحة العقار (متر.مربع)</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '45%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Location</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '72%', float: 'left', lineHeight: 1, textIndent: '1rem', height: '16px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>{this.state.tenancy_contract.location}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '13%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>الموقع</p>
                            </div>
                            <div style={{ width: '50%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '27%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Premises No. (DEWA)</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '52%', float: 'left', lineHeight: 1, textIndent: '1rem', height: '16px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>{this.state.tenancy_contract.dewa}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '20%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>رقم المبنى (ديوا)</p>
                            </div>
                          </td>
                        </tr>
                      </tbody></table>
                    <table style={{ marginTop: 5, border: '1px solid #eff2f5' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Contract Information</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}> معلومات العقد</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td colSpan={12} style={{ padding: '10px 15px 0' }}>
                            <div style={{ width: '60%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '24%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Contract Period</p>
                              <div style={{ width: '65%', float: 'left' }}>
                                <p style={{ borderBottom: '1px dashed #4f647e', width: '45%', float: 'left', lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>
                                  {
                                    this.state.tenancy_contract.start_date == null || this.state.tenancy_contract.start_date == '' || this.state.tenancy_contract.start_date == "0000-00-00" || this.state.tenancy_contract.start_date == "0000-00-00 00:00:00" ?
                                      ''
                                      :
                                      <>
                                        <Moment format="MMMM DD, YYYY" style={{ fontSize: '12px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>{this.state.tenancy_contract.start_date}</Moment>
                                      </>
                                  }
                                </p>
                                <p style={{ borderBottom: '1px dashed #4f647e', width: '45%', float: 'right', lineHeight: 1, }}>    {
                                  this.state.tenancy_contract.end_date == null || this.state.tenancy_contract.end_date == '' || this.state.tenancy_contract.end_date == "0000-00-00" || this.state.tenancy_contract.end_date == "0000-00-00 00:00:00" ?
                                    ''
                                    :
                                    <>
                                      <Moment format="MMMM DD, YYYY" style={{ fontSize: '12px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>{this.state.tenancy_contract.end_date}</Moment>
                                    </>
                                }</p>
                              </div>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '10%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>الموقع</p>
                            </div>
                            <div style={{ width: '40%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '24%', float: 'left', lineHeight: '1.8', color: '#2d4253', fontFamily: 'Arial, Helvetica, sans-serif', height: '16px' }}>Contract Value</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '60%', float: 'left', lineHeight: 1, height: '16px' }}>{this.state.tenancy_contract.contract_value ? this.state.tenancy_contract.contract_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ""}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>قيمة العقد</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6} style={{ padding: '0 15px' }}>
                            <div style={{ width: '100%', marginTop: '-10px' }}><p style={{ fontSize: 8, width: '48%', margin: '-5px 0 0 4%', float: 'left', textAlign: 'center', color: '#2d4253' }}>From من</p></div>
                            <div style={{ width: '100%', marginTop: '-10px' }}><p style={{ fontSize: 8, width: '10%', margin: '-5px 0 0 5%', float: 'left', textAlign: 'center', color: '#2d4253' }}>To إلى</p></div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '45%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '22%', float: 'left', lineHeight: '1.8', color: '#2d4253', fontFamily: 'Arial, Helvetica, sans-serif' }}>Annual Rent</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '59%', float: 'left', lineHeight: 1, textIndent: '1rem', color: '#2d4253' }}>{this.state.tenancy_contract.rental_price ? this.state.tenancy_contract.rental_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ""}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '19%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>الايجار السنوي</p>
                            </div>
                            <div style={{ width: '50%', float: 'right' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '40%', float: 'left', lineHeight: '1.8', color: '#2d4253', fontFamily: 'Arial, Helvetica, sans-serif' }}>Security Deposit Amount</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '44%', float: 'left', lineHeight: 1, textIndent: '1rem', color: '#2d4253', fontFamily: 'Arial, Helvetica, sans-serif' }}>{this.state.tenancy_contract.security_deposit ? this.state.tenancy_contract.security_deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ""}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>مبلغ التأمين</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12} style={{ padding: '0 15px' }}>
                            <div style={{ width: '100%', float: 'left' }}>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', color: '#2d4253', fontFamily: 'Arial, Helvetica, sans-serif' }}>Mode of Payment</p>
                              <p style={{ borderBottom: '1px dashed #4f647e', width: '78%', float: 'left', lineHeight: 1, textIndent: '1rem', color: '#2d4253', fontFamily: 'Arial, Helvetica, sans-serif' }}>{this.state.tenancy_contract.month_no}-{this.state.tenancy_contract.payment_type == 1 ? "Cheque" : "Bank Transfer"}</p>
                              <p style={{ fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color: '#2d4253' }}>طريقة الدفع</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ marginTop: 5, border: '1px solid #FFF', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 0.8rem' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Terms and Conditions</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>الأحكام و الشروط</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td colSpan={6}>
                            <ol style={{ paddingLeft: 15, marginTop: 2, marginBottom: 0 }}>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', listStyle: 'inside number' }}>The tenant has inspected the premises and agreed to lease the unit on its current condition.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', listStyle: 'inside number' }}>Tenant undertakes to use the premises for designated purpose, tenant has no rights to transfer or relinquish the tenancy contract either with or to without counterpart to any without landlord written to third party in whole or in part unless it is legally permitted.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', listStyle: 'inside number' }}>The tenant undertakes not to make any amendments, modifications or addendums to the premises subject of the contract without obtaining the landlord written approval. Tenant shall be liable for any damages or failure due to that.</li>
                            </ol>
                          </td>
                          <td colSpan={6}>
                            <ol style={{ paddingRight: 15, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0 }}>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', listStyle: 'inside number', color: '#2d4253' }}>عاين المستأجر الوحدة موضوع الايجار ووافق على إستئجار العقار على حالته الحالية.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', listStyle: 'inside number', color: '#2d4253' }}>يتعهد المستأجر باستعمال المأجور للغرض المخصص له، و لا يجوز للمستأجر تحويل أو التنازل عن عقد الايجار للغير بمقابل أو دون مقابل دون موافقة المالك خطيا، كما لا يجوز للمستأجر تأجير المأجور أو أي جزء منه من الباطن مالم يسمح بذلك قانونا</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', listStyle: 'inside number', color: '#2d4253' }}>يتعهد المستأجر بعدم إجراء أي تعديلات أو إضافات على العقار موضوع العقد دون موافقة المالك الخطية، و يكون المستأجر مسؤولا عن أي أضرار أو نقص أو تلف يلحق بالعقار</li>
                            </ol>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ marginTop: 10, border: '1px solid #FFF' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: 13, paddingLeft: 10, whiteSpace: 'nowrap' }}>Signatures</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>التوقيعات</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td colSpan={12}>
                            <div style={{ marginTop: 5, border: '1px solid #374f6c', width: '50%', float: 'left', display: "flex", alignItems: "center" }}>
                              <p style={{ width: '50%', float: 'left', fontSize: 11, marginTop: 40, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Tenant's Signature<span style={{ fontFamily: 'Cairo, sans-serif', marginLeft: '5px' }}> توقيع المستأجر</span></p>
                              <div style={{ width: '45%', float: 'right', marginTop: 40, display: "flex", alignItems: "center", marginBottom: '1em', marginLeft: 'auto' }}>
                                <p style={{ float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Date</p>
                                <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 100, margin: '0 0 0 2px', lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', height: '16px', color: '#000' }}>&nbsp;</p>
                                <p style={{ fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 11, lineHeight: 1, fontFamily: 'Cairo, sans-serif', color: '#2d4253' }}>التاريخ</p>
                              </div>
                            </div>
                            <div style={{ marginTop: 5, border: '1px solid #374f6c', width: '49%', float: 'right', display: "flex", alignItems: "center" }}>
                              <p style={{ width: '48%', float: 'left', fontSize: 11, marginTop: 40, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Lessor's Signature<span style={{ fontFamily: 'Cairo, sans-serif', marginLeft: '5px', color: '#2d4253' }}> توقيع المؤجر</span></p>
                              <div style={{ width: '45%', float: 'right', marginTop: 40, display: "flex", alignItems: "center", marginBottom: '1em', marginLeft: 'auto' }}>
                                <p style={{ float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', color: '#2d4253' }}>Date</p>
                                <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 100, margin: '0 0 0 2px', lineHeight: 1, color: '#000' }}>&nbsp;</p>
                                <p style={{ fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 11, lineHeight: 1, color: '#2d4253' }}>التاريخ</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* 2nd Page */}
                <div className="u-row-container" style={{  height:  window.isIpadView ? '360mm' :'293mm', width: "210mm", border: "1px solid #151515", margin: "1rem auto", backgroundColor: "#FFF", padding: "2px 1.5rem" }}>
                  <div className="u-row" style={{}}>
                    <table width="100%" style={{ background: 'url("ejari-poa-background-1.png") repeat-y', backgroundSize: '30%', backgroundPosition: '50% 3rem' }}>
                      <tbody><tr style={{ backgroundColor: '#FFF', paddingBottom: 5 }}>
                        <td text-align="left">
                          <img src="Government_of_Dubai_logo.png" height="38px" />
                          <img src="Dubai-Land-LOGO.png" height="45px" valign="center" style={{ float: 'right' }} />
                        </td>
                      </tr>
                        <tr>
                          <td colSpan={12}>
                            <ol style={{ paddingLeft: 5, marginTop: 2, marginBottom: 0, width: '50%', float: 'left' }} start={4}>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>The tenant shall be responsible for payment of all electricity, water, cooling and gas charges resulting of occupying leased unit unless other condition agreed in written.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>The tenant must pay the rent amount in the manner and dates agreed with the landlord.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>The tenant fully undertakes to comply with all the regulations and instructions related to the management of the property and the use of the premises and of common areas such (parking, swimming pools, gymnasium, etc…).</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>Tenancy contract parties declare all mentioned emails addresses and phone numbers are correct, all formal and legal notifications will be sent to those addresses in case of dispute between parties.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>The landlord undertakes to enable the tenant of the full use of the premises including its facilities (swimming pool, gym, parking lot, etc) and do the regular maintenance as intended unless other condition agreed in written, and not to do any act that would detract from the premises benefit.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>By signing this agreement from the first party, the “Landlord” hereby confirms and undertakes that he is the current owner of the property or his legal representative under legal power of attorney duly entitled by the competent authorities.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>Any disagreement or dispute may arise from execution or interpretation of this contract shall be settled by the Rental Dispute Center.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>This contract is subject to all provisions of Law No (26) of 2007 regulating the relation between landlords and tenants in the emirate of Dubai as amended, and as it will be changed or amended from time to time, as long with any related legislations and regulations applied in the emirate of Dubai.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>Any additional condition will not be considered in case it conflicts with law.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>In case of discrepancy occurs between Arabic and non Arabic texts with regards to the interpretation of this agreement or the scope of its application, the Arabic text shall prevail.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>The landlord undertakes to register this tenancy contract on EJARI affiliated to Dubai Land Department and provide with all required documents.</li>
                            </ol>
                            <ol style={{ paddingRight: 5, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '50%', float: 'right' }} start={4}>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>يكون المستأجر مسؤولا عن سداد كافة فواتير الكهرباء و المياه و التبريد و الغاز المترتبة عن اشغاله المأجور مالم يتم الاتفاق على غير ذلك كتابيا</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>يتعهد المستأجر بسداد مبلغ الايجار المتفق عليه في هذا العقد في التواريخ و الطريقة المتفق عليها.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>يلتزم المستأجر التقيد التام بالانظمة و التعليمات المتعلقة باستخدام المأجور و المنافع المشتركة
                                (كمواقف السيارات، أحواض السباحة، النادي الصحي، الخ).</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>يقر أطراف التعاقد بصحة العناوين و أرقام الهواتف المذكورة أعلاه، و تكون تلك العناوين هي المعتمدة رسميا للإخطارات و الأعلانات القضائية في حال نشوء أي نزاع بين أطراف العقد</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>يتعهد المؤجر بتمكين المستأجر من الانتفاع التام بالعقار للغرض المؤجر لأجله و المرافق الخاصة به (حوض سباحة، نادي صحي، مواقف سيارات.... إلخ) كما يكون مسؤولا عن أعمال الصيانة مالم يتم الاتفاق على غير ذلك، و عدم التعرض له في منفعة العقار.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>يعتبر توقيع المؤجر على هذا العقد إقرار منه بأنه المالك الحالي للعقار أو الوكيل القانوني للمالك بموجب وكالة قانونية موثقة وفق الأصول لدى الجهات المختصة.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>أي خلاف أو نزاع قد ينشأ عن تنفيذ أو تفسير هذا العقد يعود البت فيه لمركز فض المنازعات الإيجارية</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>يخضع هذا العقد لكافة أحكام القانون رقم ( 26 ) لسنة 2007 بشأن تنظيم العلاقة بين مؤجري و مستأجري العقارات في إماراة دبي، و تعديلاته و أي تغيير أو تعديل يطرأ عليه من وقت لآخر، كما يخضع للتشريعات و اللوائح الأخرى ذات العلاقة النافذة في دبي.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle: 'inside number', color: '#2d4253' }}>لا يعتد بأي شرط تم إضافته إلى هذا العقد في حال تعارضه مع القانون.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 15, listStyle: 'inside number', color: '#2d4253' }}>في حال حدوث أي تعارض أو اختلاف في التفسير بين النص العربيو النص الأجنبي يعتمد النص العربي.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number', color: '#2d4253' }}>يتعهد المؤجر بتسجيل عقد الايجار في نظام إيجاري التابع لدائرة الأراضي و الأملاك و توفير كافة المستندات اللازمة لذلك.</li>
                            </ol>
                          </td>
                        </tr>
                      </tbody></table>
                    <table style={{ marginTop: 5, border: '1px solid #FFF' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Know your Rights</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>لمعرفة حقوق الأطراف</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td colSpan={12}>
                            <ul style={{ paddingLeft: 5, marginTop: 2, marginBottom: 0, width: '46%', float: 'left' }}>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>You may visit Rental Dispute Center website through www.dubailand.gov.ae in case of any rental dispute between parties.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>Law No 26 of 2007 regulating relationship between landlords and tenants.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>Law No 33 of 2008 amending law 26 of year 2007.</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>Law No 43 of 2013 determining rent increases for properties.</li>
                            </ul>
                            <ul style={{ paddingRight: 5, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '46%', float: 'right' }}>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>يمكنكم زيارة موقع مركز فض المنازعات الإيجارية من خلال www.dubailand.gov.ae في حال نشوء أي نزاع إيجاري بين الأطراف.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>الإطلاع على قانون رقم 26 لسنة 2007 بشأن تنظيم العلاقة بين المؤجرين والمستأجرين.</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>. الإطلاع على قانون رقم 33 لسنة 2008 الخاص بتعديل بعض أحكام قانون 26 لعام 2007</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside disc' }}>الإطلاع على قانون رقم 43 لسنة 2013 بشأن تحديد زيادة بدل الإيجار.</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ marginTop: 5, border: '1px solid #FFF' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Attachments for Ejari Registration</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>مرفقات التسجيل في إيجاري</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td colSpan={12}>
                            <ul style={{ paddingLeft: 5, marginTop: 2, marginBottom: 0, width: '46%', float: 'left' }}>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number' }}>Original unified tenancy contract</li>
                              <li style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number' }}>Original emirates ID of applicant</li>
                            </ul>
                            <ul style={{ paddingRight: 5, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '46%', float: 'right' }}>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number' }}>نسخة أصلية عن عقد الايجار الموحد</li>
                              <li style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle: 'inside number' }}>الهوية الإماراتية الأصلية لمقدم الطلب</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ marginTop: 5, border: '1px solid #FFF' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap' }}>Additional Terms</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>شروط إضافية</th>
                        </tr></thead>
                      <tbody style={{ background: 'url("ejari-poa-background-1.png") repeat-y', backgroundSize: '30%', backgroundPosition: '50% 0' }}>
                        <tr>
                          <td colSpan={12}>
                            <div style={{ marginTop: 10 }}>
                              <p style={{ float: 'left', fontSize: 11, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>1</p>
                              <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height: '16px', fontSize: 12, fontFamily: 'Arial, Helvetica, sans-serif', textIndent: 3 }}>
                                {"Tenant agrees to pay Luxury Property LLC " + (this.state.tenancy_contract.commission ? this.state.tenancy_contract.commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null) + " AED + VAT (" +
                                  parseInt((this.state.tenancy_contract.commission * 0.05)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED"
                                  + ") upon signing this agreement"}
                              </p>
                              <p style={{ float: 'left', fontSize: 11, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>1</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12}>
                            <p style={{ float: 'left', fontSize: 11, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>2</p>
                            <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height: '16px', fontSize: 12, fontFamily: 'Arial, Helvetica, sans-serif', textIndent: 3 }}>The attached addendum forms an integral part of this contract</p>
                            <p style={{ float: 'left', fontSize: 11, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>2</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12}>
                            <p style={{ float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>3</p>
                            <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height: '16px', textIndent: 3 }}>{this.state.tenancy_contract.condition_terms_three}</p>
                            <p style={{ float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>3</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12}>
                            <p style={{ float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>4</p>
                            <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height: '16px', textIndent: 3 }}>{this.state.tenancy_contract.condition_terms_four}</p>
                            <p style={{ float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0' }}>4</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12}>
                            <p style={{ float: 'left', fontSize: 12, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0', margin: '7px 0' }}>5</p>
                            <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height: '16px', textIndent: 3 }}>{this.state.tenancy_contract.condition_terms_five}</p>
                            <p style={{ float: 'left', fontSize: 12, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0', margin: '7px 0' }}>5</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12}>
                            <p style={{ fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, width: '48%', float: 'left' }}>Note : You may add addendum to this tenancy contract in case you have additional terms while it needs to be signed by all parties.</p>
                            <p style={{ fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, width: '48%', float: 'right', direction: 'rtl' }}>ملاحظة: يمكن إضافة ملحق إلى هذا العقد في حال وجود أي شروط إضافية، على أن يوقع من أطراف التعاقد.</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ marginTop: 10, border: '1px solid #FFF' }} width="100%">
                      <thead style={{ backgroundColor: '#374f6c' }}>
                        <tr><th colSpan={6} style={{ color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: 13, paddingLeft: 10, whiteSpace: 'nowrap' }}>Signatures</th>
                          <th colSpan={6} style={{ color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif' }}>التوقيعات</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td colSpan={12}>
                            <div style={{ marginTop: 5, border: '1px solid #374f6c', width: '50%', float: 'left', display: "flex", alignItems: "center" }}>
                              <p style={{ width: '50%', float: 'left', fontSize: 9, marginTop: 50, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif' }}>Tenant's Signature<span style={{ fontFamily: '"Cairo", sans-serif' }}> توقيع المستأجر</span></p>
                              <div style={{ width: '45%', float: 'right', marginTop: 50, marginBottom: '1em', display: "flex", alignItems: "center" }}>
                                <p style={{ float: 'left', fontSize: 9, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif' }}>Date</p>
                                <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 120, margin: '0 0 0 2px', lineHeight: 1 }}>&nbsp;</p>
                                <p style={{ fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 9, lineHeight: 1 }}>التاريخ</p>
                              </div>
                            </div>
                            <div style={{ marginTop: 5, border: '1px solid #374f6c', width: '49%', float: 'right', display: "flex", alignItems: "center" }}>
                              <p style={{ width: '48%', float: 'left', fontSize: 9, marginTop: 50, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif' }}>Lessor's Signature<span style={{ fontFamily: '"Cairo", sans-serif' }}> توقيع المؤجر</span></p>
                              <div style={{ width: '49%', float: 'right', marginTop: 50, marginBottom: '1em', display: "flex", alignItems: "center" }}>
                                <p style={{ float: 'left', fontSize: 9, margin: 0, lineHeight: 1, fontFamily: 'Aial, Helvetica, sans-serif' }}>Date</p>
                                <p style={{ float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 100, margin: '0 0 0 2px', lineHeight: 1 }}>&nbsp;</p>
                                <p style={{ fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 9, lineHeight: 1 }}>التاريخ</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={12}>
                            <div style={{ marginTop: 5, border: '1px solid #374f6c', width: '100%', float: 'left', textAlign: 'center', padding: "5px 0px" }}>
                              <p style={{ float: 'left', fontSize: 9, textAlign: 'center', width: '100%', fontFamily: 'Aial, Helvetica, sans-serif', marginBottom: 0 }}>Tel: 8004488 Fax: +971 4 222 2251 P.O.Box 1166, Dabai, U.A.E. هاتف: ۸۰۰٤٤۸۸ فاكس: ٩٧١٤٢٢٢٢٢٥١+ ص.ب ۱۱٦٦ ، دبي ، الإمارات العربية المتحدة</p>
                              <p style={{ fontSize: 9, fontFamily: 'Aial, Helvetica, sans-serif', marginBottom: 0 }}>Website: www.dubailand.gov.ae :الموقع الإلكتروني  E-mail: support@dubailand.gov.ae    :بريد إلكتروني </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
        <ToastContainer />
        <Modal width={window.isMobileView ? '100%' : 600} title="Tenancy contract" open={this.state.iscontractstatusOpen} onOk={this.handlecontractstatusok} onCancel={this.handlecontractstatuscancel} footer={null}>

          <Row span={24} className='pt-5'>
            <Col span={24}>
              <p className='fs-5 font-bold'>How do you want to proceed with this contract?</p>
            </Col>
          </Row>
          <Row gutter={16} span={24} className='contractactions pb-5'>
            {/* Only for admin/managers */}
            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <Card>
                <Button type="text" style={{ backgroundColor: this.state.preview_contract_button ? '#f0f0f0' : 'white' }} onClick={this.handleToApprovedContract}>
                  <FilePdfOutlined className='mx-auto' />
                  <p className="font-bold mb-1 mx-auto">
                    {role == 1 ? "Preview contract" : <>{this.state.record_status == "Approved" ? "Download contract" : "Preview contract"}</>}

                  </p>
                  <p className='font-12 px-1 mx-auto px-1'>View the contract's details before you approve, seek revisions, or dismiss it.</p>
                </Button>
              </Card>
            </Col>
            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <Card>
                <Button type="text" style={{ backgroundColor: this.state.edit_contract_button ? '#f0f0f0' : 'white' }} onClick={this.handleEditContract}><EditOutlined className='mx-auto' />
                  <p className="font-bold mb-1 mx-auto">Edit contract</p>
                  <p className='font-12 px-1 mx-auto'>Make changes to the contract.</p></Button>
              </Card>
            </Col>
            {role == 1 ? <>
              <Col span={8} lg={8} md={8} sm={24} xs={24}  >
                <Card>
                  <Button type="text" style={{ backgroundColor: this.state.dismiss_contract_button ? '#f0f0f0' : 'white' }} onClick={this.handleDismissContract}><FileExcelOutlined className='mx-auto' />
                    <p className="font-bold mb-1 mx-auto">Dismiss contract</p>
                    <p className='font-12 mx-auto px-1'>Close the contract without making any changes</p>
                  </Button>
                </Card>
              </Col>
            </> : null}


          </Row>

        </Modal>

        <Drawer width={'95vw'} title="Tenancy Contract Request" placement="left" onClose={this.handlecontractcancel} open={this.state.iscontractOpen}

        >
          <div className="requestwrap">

            <Row span={24} gutter={24}>
              <Col span={12} lg={12} md={24} sm={24} xs={24}>
                <Row className='border-top border' gutter={16}>
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Property Details</p>
                  </Col>
                  <Col span={24} className='bg-gray'>
                    <div className= {`${window.isMobileView ? '' : 'w-50'} form-group  pr-1`}>
                      <label>Search property</label>
                      <input placeholder='Enter Ref no' />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Address <em>*</em></label>
                      {/* unit no, property, sub community, community */}
                      <input value={this.state.address} placeholder='Enter address' onChange={this.handleAddress} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>DEWA/Premise Number</label>
                      <input value={this.state.dewa} placeholder='Enter DEWA number' onChange={this.handleDewa} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Location (as per Title Deed) <em>*</em></label>
                      <input value={this.state.location} placeholder='Enter location' onChange={this.handleLocation} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Plot Number (as per Title Deed)  <em>*</em></label>
                      <input value={this.state.plot} placeholder='Enter number' onChange={this.handlePlotNumber} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Building Name  <em>*</em></label>
                      <input value={this.state.building_name} placeholder='Enter number' onChange={this.handleBuildingName} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Property Number <em>*</em></label>
                      <input value={this.state.property_number} placeholder='Enter number' onChange={this.handlePropertyNumber} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Property Type <em>*</em></label>
                      {/* pull up from database but editable*/}
                      <select value={this.state.property_type} onChange={this.handlePropertyType} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                        <option>select</option>
                        {this.state.propertytypes.map((propertytype) => {
                          return <option value={propertytype.value}>{propertytype.label}</option>
                        })}


                      </select>
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>No of Bedrooms <em>*</em></label>
                      {/* pull up from database but editable*/}
                      <input value={this.state.bedrooms} placeholder='Enter number' onChange={this.handleBedroom} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Size in sqm <em>*</em></label>
                      <input value={this.state.area_size} placeholder='Enter size' onChange={this.handleAreaSize} />
                    </div>
                  </Col>
                </Row>
                <Row className='border border-top mt-3' gutter={16}>
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Contract Details</p>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Start date <em>*</em></label>
                      <DatePicker value={(this.state.contract_start_date) ? dayjs(this.state.contract_start_date) : ""} format="YYYY-MM-DD" onChange={this.handleStartDate} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24} >
                    <div className='form-group'>
                      <label>End date <em>*</em></label>
                      <DatePicker value={(this.state.contract_end_date) ? dayjs(this.state.contract_end_date) : ""} format="YYYY-MM-DD" onChange={this.handleEndDate} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Rental Price <em>*</em></label>
                      <input value={this.state.rental_price} placeholder='Enter price' onChange={this.handleRentalPrice} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Contract Value</label>
                      <input placeholder='Enter amount' onChange={this.handleContractValue} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Security Deposit Amount <em>*</em></label>
                      <input value={this.state.security_deposit} placeholder='Enter amount' onChange={this.handleSecurityDeposit} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Commission</label>
                      <input value={this.state.commission} placeholder='Enter amount' onChange={this.handleCommission} />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className='form-group'>
                      <label>Mode of Payment <em>*</em></label>
                      <Row>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                          <Radio.Group className='d-flex flex-row' value={this.state.payment_type} onChange={this.handlePaymentType}>
                            <Radio value={1}>Cheque</Radio>
                            <Radio value={2}>Bank Transfer</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                          <Radio.Group className='d-flex flex-row flex-wrap  align-self-end' onChange={this.handleNumberOfPayment} value={this.state.month_number}>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                            <Radio value={6}>6</Radio>
                            <Radio value={12}>12</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Addditional terms to the contract<em>*</em></label>
                      {/* <textarea value={this.state.additional_clauses_contract} border rows={4} maxLength={6} onChange={this.handleAdditionalContract}   /> */}
                      <div className='addterms d-flex flex-column'>
                        <Input value={this.state.condition_terms_one ? this.state.condition_terms_one : "Tenant agrees to pay Luxury Property LLC " + (this.state.commission ? this.state.commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null) + " AED + VAT (" + parseInt((this.state.commission * 0.05)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED"
                          + ") upon signing this agreement"}
                          showCount className='form-input' onChange={this.handleConditionTermsOne} />
                        <Input value={this.state.condition_terms_two ? this.state.condition_terms_two : "The attached addendum forms an integral part of this contract"} showCount className='form-input' onChange={this.handleConditionTermsTwo} />
                        <Input value={this.state.condition_terms_three} showCount className='form-input' onChange={this.handleConditionTermsThree} />
                        <Input value={this.state.condition_terms_four} showCount className='form-input' onChange={this.handleConditionTermsFour} />
                        <Input value={this.state.condition_terms_five} showCount className='form-input' onChange={this.handleConditionTermsFive} />
                      </div>
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Addditional clauses to the addendum<em>*</em></label>
                      <textarea value={this.state.additional_clauses_addendum} border className='border' rows={4} maxLength={6} onChange={this.handleAdditionalAddendum} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12} lg={12} md={24} sm={24} xs={24}>
                <Row className='border border-top' gutter={16}>
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Landlord Details</p>
                  </Col>
                  <Col span={24} className='bg-gray'>
                    <div className={`${window.isMobileView ? '' : 'w-50'} form-group  pr-1`}>
                      <label>Search owner</label>
                      <input placeholder='Search owner by name' />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Emirates ID/Passport Number <em>*</em></label>
                      <input value={this.state.landloard_emirate_id_number} placeholder='Enter number' onChange={this.handleLandLoardEmirateIDNumber} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Name (as per passport) <em>*</em></label>
                      <input value={this.state.landloard_name} placeholder='Enter name' onChange={this.handleLandLoardName} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Nationanality <em>*</em></label>
                      <input value={this.state.landloard_nationality} placeholder='Enter nationality' onChange={this.handleLandLoardNationality} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Mobile number <em>*</em></label>
                      <input value={this.state.landloard_mobile} placeholder='Enter number' onChange={this.handleLandLoardMobile} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Email address <em>*</em></label>
                      <input value={this.state.landloard_email} placeholder='Enter email adress' onChange={this.handleLandLoardEmail} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>LMS Link </label>
                      <input value={this.state.lms_link} placeholder='Enter link' onChange={this.handleLMSLink} />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16} className="border-top border mt-3">
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Tenant Details</p>
                  </Col>
                  <Col span={24} className='bg-gray'>
                    <div className={`${window.isMobileView ? '' : 'w-50'} form-group  pr-1`}>
                      <label>Search Tenant</label>
                      <input placeholder='Search tenant by name' />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Emirates ID/Passport Number <em>*</em></label>
                      <input value={this.state.tenant_emirate_id_number} placeholder='Enter number' onChange={this.handleTenantEmirateIDNumber} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Name (as per passport) <em>*</em></label>
                      <input value={this.state.tenant_name} placeholder='Enter name' onChange={this.handleTenantName} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Mobile Number <em>*</em></label>
                      <input value={this.state.tenant_mobile} placeholder='Enter mobile number' onChange={this.handleTenantMobile} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Email Address <em>*</em></label>
                      <input value={this.state.tenant_email} placeholder='Enter email address' onChange={this.handleTenantEmail} />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16} className="border-top border mt-3">
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Documents</p>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Title Deed <em>*</em></label>
                      <input type="file" placeholder='Enter email address' onChange={this.handleTitleDeed} />
                      {/* <Upload>
                                            <label>Title Deed <em>*</em></label>
                                            <Button>Browse</Button>
                                        </Upload> */}
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Landlord Passport<em>*</em></label>
                      <input type="file" placeholder='Enter email address' onChange={this.handleLandLoardPassportFile} />
                      {this.state.landloard_passport_image}

                      {/* <Upload>
                                            <label>Landlord Passport/ID Copy<em>*</em></label>
                                            <Button>Browse</Button>
                                        </Upload> */}
                    </div>
                  </Col>

                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Landlord Emirate ID<em>*</em></label>
                      <input type="file" placeholder='Enter email address' onChange={this.handleLandLoardEmirateIDFile} />

                      {/* <Upload>
                                            <label>Landlord Passport/ID Copy<em>*</em></label>
                                            <Button>Browse</Button>
                                        </Upload> */}
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Tenant Passport<em>*</em></label>
                      <input type="file" placeholder='Enter email address' onChange={this.handleTenantPassportFile} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Tenant's Emirates ID <em>*</em></label>
                      <input type="file" placeholder='Enter email address' onChange={this.handleTenantEmirateIDFile} />

                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Tenant Visa  <em>*</em></label>
                      <input type="file" placeholder='Enter email address' onChange={this.handleTenantVisa} />

                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>POA Documents (Passport, POA)</label>
                      <input type="file" placeholder='Enter email address' onChange={this.handlePOA} />
                    </div>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <div className='form-group'>
                      <label>Cheque Copy <em>*</em></label>
                      <input type="file" placeholder='Enter email address' onChange={this.handleCheque} />
                    </div>
                  </Col>

                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='ml-auto d-flex flex-row mt-5 mb-3'>
                <Button type='primary' className='mr-3' onClick={this.handleSubmitTenancyContract}>Submit Contract</Button>
                <Button type='primary' onClick={this.handlecontractcancel}>Cancel</Button>
              </Col>
            </Row>
          </div>
        </Drawer>




        <Row className="contentwrap p-4 pt-0">
          <Col span={24}>
            <Row className='mt-3'>
              <Col span={12} lg={12} md={12} sm={24} xs={24}><h1 className="font-bold text-uppercase my-4 fs-3">Tenancy Contracts</h1></Col>
              <Col className='d-flex flex-row justify-content-between align-items-center ml-auto' span={12}  lg={12} md={12} sm={24} xs={24} style={{ gap: '0.8rem' }}>
                <RangePicker format="YYYY-MM-DD" style={{ height: '36px' }} onChange={this.handleDateRange} className='ml-auto' />
                {/* <Button type="primary" onClick={this.handlecontract}><PlusOutlined /> Create New Contract</Button> */}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div className='tcontractwrap'>
              <Tabs defaultIndex={this.state.current_status}>
                <TabList >
                  {this.state.tenancy_contract_status.length > 0 ? <Tab onClick={this.handleClearStatus}>All</Tab> : null}
                  {this.state.tenancy_contract_status.map((t_st) => (
                    <>
                      <Tab onClick={this.handleStatusTabs.bind(this, t_st.labe)}><span>{t_st.labe}</span></Tab>
                    </>
                  ))}

                </TabList>
              </Tabs>
              <Table onChange={handleTableChange}
                loading={this.state.isLoading}
                //    rowSelection={rowSelection}
                className={`${window.isTabView? 'TableborderNoneMain' : 'border '} tenancy-wrap`}
                pagination={{
                  pageSizeOptions: ['10', '20', '50', '100'],
                  total:
                    this.state?.tenancy_contracts?.total > 0 &&
                    this.state.tenancy_contracts.total,
                  pageSize: this.state.tenancy_contracts.per_page,
                  current: this.state.tenancy_contracts.current_page,
                  showTotal: (total, range) =>
                    window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                  hideOnSinglePage: true,
                }}
                //   style={{ overflowX: "auto" }}
                columns={window.isIpadView ? allListingColumns : columns}
                //   scroll={{ x: 1500, y: 2000 }}
                dataSource={
                  this.state?.tenancy_contracts?.data
                    ? this.state?.tenancy_contracts?.data
                    : []
                }

                // rowKey={(record) => record}
                rowKey={(record) => record.id}
              />
            </div>
          </Col>
        </Row>
      </>
    )
  }

}


const mapDispatchToProps = dispatch => {
  return {
    setContract: (data) => dispatch({ type: "SET_TENANCY_CONTRACT", payload: data }),


  }
}

export default connect(null, mapDispatchToProps)(Tenancy)

