import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumb,Button, Checkbox, Form, Input,InputNumber,Select,Switch,Table } from 'antd';
import { SaveOutlined} from '@ant-design/icons';

import API from '../../utils/API';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

 class AddRole extends Component {

    constructor(props){
        super(props);
        this.state ={
            role_name:"",
            permissions:[],
            user_status:false
        }
    }

    componentDidMount(){
        API.get("/permissions")
        .then((response) =>{
            var data = response.data.permissions
            for (let i = 0; i < data.length; i++) {
                data[i].ischecked = false;
            }
            this.setState({permissions:data})


        })
    }

     validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };

      handleUserStatus = (value) =>{
       this.setState({user_status:value})
      }

      onChangePermission = (permission_id,e)=>{
       
        let index = this.state.permissions.findIndex(obj => obj.id ===permission_id);
        var mydata = this.state.permissions;

      

        var i;
        for (i = 0; i < mydata.length; i++) {
           
          if (i == index) {

           
           
            mydata[i].ischecked = !mydata[i].ischecked;
            
          }
        }

        this.setState({permissions:mydata})


      }


      handleRoleName = (e) =>{
        this.setState({role_name:e.target.value})
    }

    handleSubmit = ()=>{
       const data = {
        role_name:this.state.role_name,
        permissions:this.state.permissions
       }
       API.post("/addRole",data)
       .then((response) =>{
        console.log("res",response.data)
       })
    }
    //    onFinish = (values) => {
      
    //     const data = {
    //         form_values:values,
    //         permissions:this.state.permissions,
    //     }

      

    //     API.post("/createUser",data)
    //     .then((response) =>{
    //        if(response.data.success){
    //              this.props.history.push("/users");
    //        }
    //     })
    //   };



  render() {

   

    const dataSource = [
        {
          key: '1',
          name: 'Mike',
          age: 32,
          address: '10 Downing Street',
        },
        {
          key: '2',
          name: 'John',
          age: 42,
          address: '10 Downing Street',
        },
      ];
      
      const columns = [
        {
          title: 'Name',
          dataIndex: 'module_name',
          key: 'module_name',
        },
        
      ];

    return (
        <div>
        <Breadcrumb
         style={{
           margin: '16px 0',
         }}
       >
        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/roles">All Roles</Link></Breadcrumb.Item>
         <Breadcrumb.Item>Add New Role</Breadcrumb.Item>
         
       </Breadcrumb>
       <div style={{
           padding: 24,
           minHeight: 380,
           background: "#fff",
         }}>
    
<div className='row'>
    <div className='col-md-4'>
        <div className='form-group'>
            <label>Role Name</label>
            <input type="text" className="form-control" onChange={this.handleRoleName} />
        </div>
    </div>
    <div className='col-md-4'></div>
    <div className='col-md-4 mt-4'>
        <label></label>
        <button className='btn btn-primary' onClick={this.handleSubmit}> <i class="fa fa-save"></i>  Save Data</button>
        <button className='btn btn-danger ml-2 '><i class="fa fa-remove"></i>   Cancel</button>
    </div>
</div>

<div className='row mt-5'>

<table class="table table-striped table-bordered">
  <thead style={{backgroundColor:'#151515',color:'white'}}>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Permission</th>
      <th>Status</th>
     
    </tr>
  </thead>
  <tbody>
  {this.state.permissions.map((permission,index) =>{
    return(
    <tr>
      <th scope="row">{permission.id}</th>
      <td>{permission.name}</td>
      <td>
      <Checkbox  checked={permission.ischecked} onChange={this.onChangePermission.bind(this,permission.id)} >
          
          </Checkbox> 
      </td>
    
    </tr>

)})}

  </tbody>
</table>

{/* {this.state.permissions.map((permission,index) =>{
     
     return  (<div className='col-md-3'>
          <Checkbox checked={permission.ischecked} onChange={this.onChangePermission.bind(this,permission.id)}  >
          {permission.name}
         </Checkbox>
        
         
         </div>)

  
         
       })} */}
</div>

       </div>
       </div>
    )
  }
}

export default AddRole;
