import React, { Component } from 'react'
import { Row, Col, Table, Input, Checkbox,Select, Button, Spin, Card, Popover } from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import { } from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import Moment from 'react-moment';
import {Link} from 'react-router-dom'

const { Meta } = Card;

class Stock extends Component {
    constructor(props){
    super(props)
        this.state = { 
            isLoading:false,
            global_search:"",
            publish_date_morethan_option:false,
            publish_date_lessthan_option:false,
            publish_date_value_id:1,
            publish_date_value:"",
            property_for:[],
            bedroom:"",
            listings:[],
            agents: [],
            propertytypes:[],
            property_type_filter:[],
            added_date_morethan_option:false,
            added_date_lessthan_option:false,
            added_date_value_id:1,
            added_date_value:"",
            available_date_morethan_option:false,
            available_date_lessthan_option:false,
            available_date_value_id:1,
            available_date_value:"",
            agent_value:[]
        }
    }

    componentDidMount(){
        const page=1;
        const perPage = 10
        const data = {
            global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,
            agent_value:this.state.agent_value
        
        }
        this.fetchData(page,perPage,data);

        API.get("/propertytypes")
        .then((response) =>{
          if(response.data.success){
              this.setState({propertytypes:response.data.propertytypes})
          }
        })

        API.get("/all_agents")
        .then((response) => {
                if (response.data.success) {
                    this.setState({ agents: response.data.agents })
                }
            }
        )
    }

    fetchData = (page, perPage,data) => {
        this.setState({ isLoading: true })
        API.post(`/stock_listings?page=${page}&perPage=${perPage}`,data)
            .then(async (response) => {
                await response.data.listings;
                const alllist = response.data.listings.data;

                

                this.setState({
                    listings: response.data.listings,
                    isLoading: false
                })
                let total_confirmedowner = 0
                let total_comingtomarket = 0
                let total_published = 0
                let total_feeds = 0
                let total_averageprice = 0
                let alltotal = 0

                for (let i = 0; i < alllist.length; i++) {
                    total_confirmedowner +=alllist[i].confirmed
                    total_comingtomarket += alllist[i].coming
                    total_published += alllist[i].published
                    total_feeds += alllist[i].feeds
                    total_averageprice += parseInt(alllist[i].price_avg)

                }
                this.setState({
                    total_confirmedowner:total_confirmedowner,
                    total_comingtomarket:total_comingtomarket,
                    total_published:total_published,
                    total_feeds:total_feeds,
                    total_averageprice: total_averageprice/alllist.length,
                    alltotal: total_confirmedowner + total_comingtomarket + total_published + total_feeds
                })
                
            })


    }

    handleGlobalSearch = (e) =>{
        this.setState({global_search:e.target.value})
        const page=1
        const perPage = 10
        const data = {global_search:e.target.value,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
        this.fetchData(page,perPage,data)
    }

    handlePublishDateMorethan =() =>{
        this.setState({publish_date_morethan_option:true,publish_date_lessthan_option:false})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:true,
            publish_date_lessthan_option:false,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}

            if(this.state.publish_date_value !="")
            this.fetchData(page,perPage,data)
    }
    handlePublishDateLessthan =() =>{
        this.setState({publish_date_morethan_option:false,publish_date_lessthan_option:true})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:false,
            publish_date_lessthan_option:true,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
            if(this.state.publish_date_value !="")
             this.fetchData(page,perPage,data)
    }

    handlePublishDateValue_id = (e) =>{
        this.setState({publish_date_value_id:e.target.value})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:e.target.value,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
            if(this.state.publish_date_value !="")
            this.fetchData(page,perPage,data)
    }

    handlePublishDateValue = (e)=>{
        this.setState({publish_date_value:e.target.value})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:e.target.value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
              this.fetchData(page,perPage,data)
    }

    // added date
    handleAddedDateMorethan =() =>{
        this.setState({added_date_morethan_option:true,added_date_lessthan_option:false})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:true,
            added_date_lessthan_option:false,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}

            if(this.state.added_date_value !="")
            this.fetchData(page,perPage,data)
    }
    handleAddedDateLessthan =() =>{
        this.setState({added_date_morethan_option:false,added_date_lessthan_option:true})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:false,
            added_date_lessthan_option:true,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
            if(this.state.added_date_value !="")
             this.fetchData(page,perPage,data)
    }

    handleAddedDateValue_id = (e) =>{
        this.setState({added_date_value_id:e.target.value})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:e.target.value,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
            if(this.state.added_date_value !="")
            this.fetchData(page,perPage,data)
    }

    handleAddedDateValue = (e)=>{
        this.setState({added_date_value:e.target.value})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:e.target.value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
              this.fetchData(page,perPage,data)
    }

    // available date
    handleAvailableDateMorethan =() =>{
        this.setState({available_date_morethan_option:true,available_date_lessthan_option:false})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:true,
            available_date_lessthan_option:false,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}

            if(this.state.available_date_value !="")
            this.fetchData(page,perPage,data)
    }
    handleAvailableDateLessthan =() =>{
        this.setState({available_date_morethan_option:false,available_date_lessthan_option:true})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
            if(this.state.available_date_value !="")
             this.fetchData(page,perPage,data)
    }

    handleAvailableDateValue_id = (e) =>{
        this.setState({available_date_value_id:e.target.value})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:e.target.value,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
            if(this.state.available_date_value !="")
            this.fetchData(page,perPage,data)
    }

    handleAvailableDateValue = (e)=>{
        this.setState({available_date_value:e.target.value})
        const page=1
        const perPage = 10
        const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:e.target.value,agent_value:this.state.agent_value}
              this.fetchData(page,perPage,data)
    }


    handlePublished = (record,e)=>{
        const data = {status:"Y",community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,publish_date_morethan_option:this.state.publish_date_morethan_option,
        publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
        publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
        property_type_filter:this.state.property_type_filter,
        added_date_morethan_option:this.state.added_date_morethan_option,
        added_date_lessthan_option:this.state.added_date_lessthan_option,
        added_date_value_id:this.state.added_date_value_id,
        added_date_value:this.state.added_date_value,
        available_date_morethan_option:this.state.available_date_morethan_option,
        available_date_lessthan_option:this.state.available_date_lessthan_option,
        available_date_value_id:this.state.available_date_value_id,
        available_date_value:e.target.value,agent_value:this.state.agent_value}
        this.props.setStatus(data)
        window.open("/all_listings", "_blank");
    }

    handleFeed = (record,e)=>{
        const data = {status:"F",community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,publish_date_morethan_option:this.state.publish_date_morethan_option,
        publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
        publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
        property_type_filter:this.state.property_type_filter,
        added_date_morethan_option:this.state.added_date_morethan_option,
        added_date_lessthan_option:this.state.added_date_lessthan_option,
        added_date_value_id:this.state.added_date_value_id,
        added_date_value:this.state.added_date_value,
        available_date_morethan_option:this.state.available_date_morethan_option,
        available_date_lessthan_option:this.state.available_date_lessthan_option,
        available_date_value_id:this.state.available_date_value_id,
        available_date_value:e.target.value,agent_value:this.state.agent_value}
        this.props.setStatus(data)
        window.open("/all_listings", "_blank");
    }

    handleConfirmed = (record,e)=>{
        const data = {status:"UC",community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,publish_date_morethan_option:this.state.publish_date_morethan_option,
        publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
        publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
        property_type_filter:this.state.property_type_filter,
        added_date_morethan_option:this.state.added_date_morethan_option,
        added_date_lessthan_option:this.state.added_date_lessthan_option,
        added_date_value_id:this.state.added_date_value_id,
        added_date_value:this.state.added_date_value,
        available_date_morethan_option:this.state.available_date_morethan_option,
        available_date_lessthan_option:this.state.available_date_lessthan_option,
        available_date_value_id:this.state.available_date_value_id,
        available_date_value:e.target.value,agent_value:this.state.agent_value}
        this.props.setStatus(data)
        window.open("/all_listings", "_blank");
    }
    handleComingToMarket = (record,e)=>{
        const data = {status:"CTM",community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,publish_date_morethan_option:this.state.publish_date_morethan_option,
        publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
        publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
        property_type_filter:this.state.property_type_filter,
        added_date_morethan_option:this.state.added_date_morethan_option,
        added_date_lessthan_option:this.state.added_date_lessthan_option,
        added_date_value_id:this.state.added_date_value_id,
        added_date_value:this.state.added_date_value,
        available_date_morethan_option:this.state.available_date_morethan_option,
        available_date_lessthan_option:this.state.available_date_lessthan_option,
        available_date_value_id:this.state.available_date_value_id,
        available_date_value:e.target.value,agent_value:this.state.agent_value}
        this.props.setStatus(data)
        window.open("/all_listings", "_blank");
    }

    // handleBed = (record,bed,e)=>{
    //     const data = {bedroom:bed,community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id}
    //     this.props.setBedroom(data)
    //     window.open("/all_listings", "_blank");
    // }

    handlePropertyFor = (e) =>{

        // const redux_data = {property_for:e}
        // this.props.setPropertyFor(redux_data)
             
        this.setState({property_for:e})
        const page=1;
        const perPage = 10
        const data = {
            global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:e,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value
        
        }
        this.fetchData(page,perPage,data);
    }

    handleBedroom = (e) =>{
        this.setState({bedroom:e})
        const page=1;
        const perPage = 10
        const data = {
            global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:e,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value
        
        }
        this.fetchData(page,perPage,data);
    }

    roundTotal =(value, precision) =>{
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    handleClearFilter = () =>{
        this.setState({
                isLoading:false,
                global_search:"",
                publish_date_morethan_option:false,
                publish_date_lessthan_option:false,
                publish_date_value_id:1,
                publish_date_value:"",
                property_for:[],
                bedroom:"",
                property_type_filter:[],
                added_date_morethan_option:false,
                added_date_lessthan_option:false,
                added_date_value_id:1,
                added_date_value:"",
                available_date_morethan_option:false,
                available_date_lessthan_option:false,
                available_date_value_id:1,
                available_date_value:"",
                agent_value:[]
        })
        const page=1;
        const perPage = 10
        const data = {
            sLoading:false,
            global_search:"",
            publish_date_morethan_option:false,
            publish_date_lessthan_option:false,
            publish_date_value_id:1,
            publish_date_value:"",
            property_for:[],
            bedroom:"",
            property_type_filter:[],
            added_date_morethan_option:false,
            added_date_lessthan_option:false,
            added_date_value_id:1,
            added_date_value:"",
            available_date_morethan_option:false,
            available_date_lessthan_option:false,
            available_date_value_id:1,
            available_date_value:"",
            agent_value:[]
        }
        this.fetchData(page,perPage,data);
    }

    handlePropertyTypeFilter = (e) =>{

        this.setState({property_type_filter:e})

        const page=1;
        const perPage = 10
        const data = {
            global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:e,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value
        
        }
        this.fetchData(page,perPage,data);

    }
    handleAgent = (e) =>{
        this.setState({agent_value:e})
        // const redux_data = {agent_value:e}
        // this.props.setAgent(redux_data)
        const page=1;
        const perPage = 10
        const data = {
            global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
            property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,
            agent_value:e
        
        }
        this.fetchData(page,perPage,data);
    }

    render() {
        const columns = [
            { title: 'Community', dataIndex: 'community',
                render:(text, record)=>(
                    <>
                        <div className='info-group d-flex flex-column'>
                            {/* <label>Community:</label> */}
                            <p>{record.community}</p>
                        </div>
                    </>
                )
            },{ title: 'Sub Community', dataIndex: 'subcommunity',
                render:(text, record)=>(
                    <>
                        <div className='info-group d-flex flex-column'>
                            {/* <label>Sub Community:</label> */}
                            <p>{record.subcommunity}</p>
                        </div>
                    </>
                )
            },{ title: 'Property', dataIndex: 'property',
                render:(text, record)=>(
                    <>
                        <div className='info-group d-flex flex-column'>
                            {/* <label>Property:</label> */}
                            <p>{record.property?record.property:"-"}</p>
                        </div>
                    </>
                )
            },
            // { title: 'Bedrooms', dataIndex: 'beds',
            //     render:(text, record)=>{
            //         const bedroomArray = record.bedroom?record.bedroom.split(",").filter(Boolean):[]; 
                    
            //        return( <div className='info-group d-flex flex-column statstock'>
            //             <label>Bedrooms:</label>
            //             {bedroomArray.map((bed)=>(
            //             <Link >{bed}</Link>

            //             ))}
            //             {/* Bedrooms - can we make it array then they will click on the number it will filter? */}
            //             {/* <div className='flex-row '>
            //             {record.beds && record.beds.map((bed)=>(
            //                 <>{bed.bedrooms?  <a href>{bed.bedrooms},</a>:null}</>
                          
            //             ))}
            //            </div> */}
            //         </div>
            //        )
            //     }
            // }
            { title: 'Confirmed Owner', dataIndex: 'confirmedowner',
                render:(text, record)=>{
                    const total = record.confirmed + record.coming + record.published + record.feeds
                    
                  return(  <div className='d-flex flex-column statstock'>
                    <div className='info-group d-flex flex-column confirmedowner'>
                        {/* <label>Confirmed Owner:</label> */}
                        <Link onClick={this.handleConfirmed.bind(this,record)}>{record.confirmed?record.confirmed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"0"}</Link>
                        </div>
                    </div>
                  )
                }
            },
            { title: 'Coming to Market', dataIndex: 'comingtomarket',
                render:(text, record)=>{
                    const total = record.confirmed + record.coming + record.published + record.feeds
                  return(  <div className='d-flex flex-column statstock'>
                        <div className='info-group d-flex flex-column comingtomarket'>
                            {/* <label>Coming to Market:</label> */}
                            <Link onClick={this.handleComingToMarket.bind(this,record)} className="infostat">{record.coming?<>
                                <span>{record.coming.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                <span className='percentage'>{this.roundTotal((record.coming/total)*100,2)}%</span>
                            </>:"0"}</Link>
                        </div>
                    </div>
                  )
                }
            },{ title: 'Published', dataIndex: 'published',
                render:(text, record)=>{
                    const total = record.confirmed + record.coming + record.published + record.feeds
                   return( <div className='d-flex flex-column statstock' style={{gap:'0.6rem'}}>
                        <div className='info-group d-flex flex-column published'>
                            {/* <label>Published:</label> */}
                            <Link  onClick={this.handlePublished.bind(this,record)} className="infostat">
                            {record.published?<>
                                <span>{record.published.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                <span className='percentage'>{this.roundTotal((record.published/total)*100,2)} %</span>
                            </>:"0"}</Link>
                        </div>
                    </div>
                   )
                }
            },{ title: 'Feeds', dataIndex: 'feeds', width:100,
            render:(text, record)=>{
                const total = record.confirmed + record.coming + record.published + record.feeds
               return( <div className='d-flex flex-column statstock'>
                    <div className='info-group d-flex flex-column feeds'>
                        {/* <label>Feeds:</label> */}
                        <Link onClick={this.handleFeed.bind(this,record)} className="infostat">
                        {record.feeds?<>
                            <span>{record.feeds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                             <span className='percentage'>{this.roundTotal((record.feeds/total)*100,2)}%</span>
                        </>:"0"}</Link>
                    </div>
                </div>
               )
            }
            },{ title: 'Average Price', dataIndex: 'averageprice',
       
            render:(text, record)=>{
                const price_avg = record.price_avg
            
                return (
                <div className='d-flex flex-column statstock'>
                    <div className='info-group d-flex flex-column'>
                        {/* <label>Average Price:</label> */}
                        <span>
                        {!isNaN(parseInt(price_avg))?<>
                            {price_avg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}   
                        </>:null}</span>
                    </div>
                </div>
                )
            }
        }
        ]

        const handleTableChange = (pagination,filters) =>{
           const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,   
             property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value}
            this.fetchData(pagination.current, pagination.pageSize,data);
        }

        return (
            <>
                <Row className='contentwrap mt-4 stockwrap'>
                    <Col span={24} className='px-5'>
                        <h1 className='font-bold fs-3'>Stock</h1>
                    </Col>
                    <Col span={24} className='mb-3 px-5'>
                        {/* TOTALS */}
                        <Row gutter={16} className='w-100 totalcards-wrap'>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className='confirmedowner'>
                                            <Col span={20}>
                                                <span className='totalname'>Total Confirmed Owner</span>
                                                <p>
                                                    <span className="nototal">{this.state.total_confirmedowner? this.state.total_confirmedowner:'0'}</span>
                                                    <span className="nototalpercent">{this.roundTotal((this.state.total_confirmedowner/this.state.alltotal)*100,0) + "%"}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <i class="bi bi-person-check"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className='comingtomarket'>
                                            <Col span={20}>
                                                <span className='totalname'>Total Coming to Market</span>
                                                <p>
                                                    <span className="nototal">{this.state.total_comingtomarket? this.state.total_comingtomarket:'0'}</span>
                                                    <span className="nototalpercent">{this.roundTotal((this.state.total_comingtomarket/this.state.alltotal)*100,0)+ "%"}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <i class="bi bi-house-check"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className='published'>
                                            <Col span={20}>
                                                <span className='totalname'>Total Published</span>
                                                <p>
                                                    <span className="nototal">{this.state.total_published? this.state.total_published:'0'}</span>
                                                    <span className="nototalpercent">{this.roundTotal((this.state.total_published/this.state.alltotal)*100,0)+ "%"}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <i class="bi bi-house-check"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className='feeds'>
                                            <Col span={20}>
                                                <span className='totalname'>Total Feeds</span>
                                                <p>
                                                    <span className="nototal">{this.state.total_feeds? this.state.total_feeds:'0'}</span>
                                                    <span className="nototalpercent">{this.roundTotal((this.state.total_feeds/this.state.alltotal)*100,0)+ "%"}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <i class="bi bi-rss"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className='average'>
                                            <Col span={20}>
                                                <span className='totalname'>Average Price</span>
                                                <p>
                                                    <span className="nototal">{this.state.total_averageprice? this.state.total_averageprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",",2) + ' AED':'0'}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <i class="bi bi-tag"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col><hr/>
                    <Col span={24} className='px-5 filterrow'>
                        {/* Filter */}
                        <Row className='filterwrap border-bottom' gutter={12}>
                            <Col span={24}>
                                <Row gutter={8}  className='w-100 justify-content-between mb-2'>
                                    <Col span={10}>
                                        <div className='searchbar w-100 form-group d-flex flex-row align-items-center'>
                                            <Input type="text" value={this.state.global_search} onChange={this.handleGlobalSearch} placeholder='Search community, sub community or property' allowClear style={{height:'36px', maxWidth:'99%', borderRadius:'0.3rem'}} prefix={<i class="bi bi-search"></i>}/>
                                        </div>
                                    </Col>
                                    
                                    
                                    <Col span={2}>
                                        <Button onClick={this.handleClearFilter} className="resetbtn" type="text"><i class="bi bi-arrow-counterclockwise mr-1"></i><span>Reset All Filter</span></Button>
                                    </Col>
                                </Row>
                                
                            </Col>
                            <Col span={24} className='d-flex flex-row  align-items-center formcheckboxgroup'>
                                <Row gutter={24} className='w-100 filtersrow'>
                                    <Col className='d-flex flex-row align-items-center justify-content-end'>
                                        {/* Note: It will hide the sub community and property */}
                                        {/* <Checkbox className='commfilter'>Show Communities only</Checkbox> */}
                                        <label className='font-bold mr-2 mb-0'>Filter by: </label>
                                    </Col>
                                    <Col>
                                        <div className='form-check mb-0 mr-0 pl-0'>
                                            <Checkbox.Group allowClear onChange={this.handlePropertyFor} value={this.state.property_for}
                                                options={[
                                                    { value: 'sale', label: 'Sale'},
                                                    { value: 'rent', label: 'Rent'},
                                                ]} />
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                        <div className='form-check mb-0 mr-0 flex-row d-flex pl-0'>
                                            <label className='font-bold mr-2 mb-0'>Beds: </label>
                                            
                                            <Select allowClear className='bedswrap' bordered={false} value={this.state.bedroom?this.state.bedroom:'-'}
                                                options={[
                                                    {value:'Studio', label:'Studio'},
                                                    {value:'1', label:'1'},
                                                    {value:'2', label:'2'},
                                                    {value:'3', label:'3'},
                                                    {value:'4', label:'4'},
                                                    {value:'5', label:'5'},
                                                    {value:'6', label:'6'},
                                                    {value:'7', label:'7'},
                                                    {value:'8', label:'8'},
                                                    {value:'9', label:'9'},
                                                    {value:'10', label:'10'},
                                                ]}
                                                onChange={this.handleBedroom}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className='form-check mb-0 mr-0 flex-row d-flex pl-0'>
                                            <label className='font-bold mr-2 mb-0'>Type: </label>
                                            
                                            <Select
                                            showSearch
                                            allowClear

                                            style={{
                                            width: '100%',
                                            }}
                                            mode="multiple"
                                            value={this.state.property_type_filter}
                                            maxTagCount ='responsive'
                                            placeholder="Search"
                                            onChange={this.handlePropertyTypeFilter}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                            options={this.state.propertytypes}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <Popover placement="bottomRight" content={
                                            <div className='d-flex flex-column popfilter'>
                                                <p className='titledate'>Published Date</p>
                                                <div className='d-flex flex-row p-2 w-100 pb-0'>
                                                    <div className="form-check mb-0 mr-3">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.publish_date_morethan_option} id="publisheddateMorethanoption" onChange={this.handlePublishDateMorethan}/>
                                                        <label className="form-check-label font-12 pl-0" for="publisheddateMorethanoption">More than</label>
                                                    </div>
                                                    <div className="form-check mb-0">
                                                        <input className="form-check-input" type="radio" checked={this.state.publish_date_lessthan_option}  name="flexRadioDefault" id="publisheddateLessthanoption" onChange={this.handlePublishDateLessthan}  />
                                                        <label className="form-check-label font-12 pl-0" for="publisheddateLessthanoption">Less than</label>
                                                    </div>
                                                </div>
                                                <div className='form-check mb-0 mr-0 p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between', gap:'0.3rem'}}>
                                                    <input type="text" value={this.state.publish_date_value} className="form-control font-12"  onChange={this.handlePublishDateValue}/>
                                                    <select value={this.state.publish_date_value_id} className='form-select font-12'  onChange={this.handlePublishDateValue_id}>
                                                    <option value="1">days</option>
                                                    <option value="2">weeks</option>
                                                    <option value="3">months</option>
                                                    <option value="4">years</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }>
                                            <Button type="text" className='filterbtn'>
                                                {this.state.publish_date_value ? <>
                                                    <span>Published: </span>
                                                    <span>{this.state.publish_date_morethan_option?"More than "
                                                    :this.state.publish_date_lessthan_option?"Less than ":null}</span>
                                                    <span>{this.state.publish_date_value}</span>
                                                    <span>{this.state.publish_date_value_id?
                                                        this.state.publish_date_value_id==1? " days"
                                                        :this.state.publish_date_value_id==2? " weeks"
                                                        :this.state.publish_date_value_id==3? " months"
                                                        :this.state.publish_date_value_id==4? " years"
                                                        :null
                                                    :null}</span>
                                                </>: <span>Published Date</span>}
                                            
                                            <i class="bi bi-caret-down-fill ml-3"></i></Button>
                                        </Popover>
                                    </Col>
                                    <Col>
                                        <Popover placement="bottomRight" content={
                                                <div className='d-flex flex-column popfilter'>
                                                    <p className='titledate'>Added Date</p>
                                                    <div className='d-flex flex-row p-2 w-100 pb-0'>
                                                        <div className="form-check mb-0 mr-3">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.added_date_morethan_option} id="publisheddateMorethanoption" onChange={this.handleAddedDateMorethan}/>
                                                            <label className="form-check-label font-12 pl-0" for="publisheddateMorethanoption">More than</label>
                                                        </div>
                                                        <div className="form-check mb-0">
                                                            <input className="form-check-input" type="radio" checked={this.state.added_date_lessthan_option}  name="flexRadioDefault" id="publisheddateLessthanoption" onChange={this.handleAddedDateLessthan}  />
                                                            <label className="form-check-label font-12 pl-0" for="publisheddateLessthanoption">Less than</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-check mb-0 mr-0 p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between', gap:'0.3rem'}}>
                                                        <input type="text" value={this.state.added_date_value} className="form-control font-12"  onChange={this.handleAddedDateValue}/>
                                                        <select value={this.state.added_date_value_id} className='form-select font-12'  onChange={this.handleAddedDateValue_id}>
                                                        <option value="1">days</option>
                                                        <option value="2">weeks</option>
                                                        <option value="3">months</option>
                                                        <option value="4">years</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            }>
                                                <Button type="text" className='filterbtn'>
                                                    {this.state.added_date_value ? <>
                                                        <span>Added: </span>
                                                        <span>{this.state.added_date_morethan_option?"More than "
                                                        :this.state.added_date_lessthan_option?"Less than ":null}</span>
                                                        <span>{this.state.added_date_value}</span>
                                                        <span>{this.state.added_date_value_id?
                                                            this.state.added_date_value_id==1? " days"
                                                            :this.state.added_date_value_id==2? " weeks"
                                                            :this.state.added_date_value_id==3? " months"
                                                            :this.state.added_date_value_id==4? " years"
                                                            :null
                                                        :null}</span>
                                                    </>: <span>Added Date</span>}
                                                
                                                <i class="bi bi-caret-down-fill ml-3"></i></Button>
                                            </Popover>
                                    </Col>
                                    <Col>
                                        <Popover placement="bottomRight" content={
                                            <div className='d-flex flex-column popfilter'>
                                                <p className='titledate'>Available Date</p>
                                                <div className='d-flex flex-row p-2 w-100 pb-0'>
                                                    <div className="form-check mb-0 mr-3">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.available_date_morethan_option} id="publisheddateMorethanoption" onChange={this.handleAvailableDateMorethan}/>
                                                        <label className="form-check-label font-12 pl-0" for="publisheddateMorethanoption">More than</label>
                                                    </div>
                                                    <div className="form-check mb-0">
                                                        <input className="form-check-input" type="radio" checked={this.state.available_date_lessthan_option}  name="flexRadioDefault" id="publisheddateLessthanoption" onChange={this.handleAvailableDateLessthan}  />
                                                        <label className="form-check-label font-12 pl-0" for="publisheddateLessthanoption">Less than</label>
                                                    </div>
                                                </div>
                                                <div className='form-check mb-0 mr-0 p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between', gap:'0.3rem'}}>
                                                    <input type="text" value={this.state.available_date_value} className="form-control font-12"  onChange={this.handleAvailableDateValue}/>
                                                    <select value={this.state.available_date_value_id} className='form-select font-12'  onChange={this.handleAvailableDateValue_id}>
                                                    <option value="1">days</option>
                                                    <option value="2">weeks</option>
                                                    <option value="3">months</option>
                                                    <option value="4">years</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }>
                                            <Button type="text" className='filterbtn'>
                                                {this.state.available_date_value ? <>
                                                    <span>Available: </span>
                                                    <span>{this.state.available_date_morethan_option?"More than "
                                                    :this.state.available_date_lessthan_option?"Less than ":null}</span>
                                                    <span>{this.state.available_date_value}</span>
                                                    <span>{this.state.available_date_value_id?
                                                        this.state.available_date_value_id==1? " days"
                                                        :this.state.available_date_value_id==2? " weeks"
                                                        :this.state.available_date_value_id==3? " months"
                                                        :this.state.available_date_value_id==4? " years"
                                                        :null
                                                    :null}</span>
                                                </>: <span>Available Date</span>}
                                            
                                            <i class="bi bi-caret-down-fill ml-3"></i></Button>
                                        </Popover>
                                    </Col>
                                    <Col>
                                        <div className='form-check mb-0 mr-0 flex-row d-flex pl-0'>
                                            <label className='font-bold mr-2 mb-0'>Agent: </label>
                                            
                                            <Select
                                                showSearch
                                                mode={'multiple'}
                                                value={this.state.agent_value}
                                                allowClear
                                                style={{
                                                    minWidth: '155px',width:'fit-content'
                                                }}
                                                placeholder="Search"
                                                onChange={this.handleAgent}

                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={this.state.agents}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>                            
                        </Row>
                    </Col>
                    <Col span={24} className='mb-2 tablerow px-5'>
                        <div className=''>
                        {this.state.isLoading ?
                            <div className='w-100 d-flex my-5 py-5'>
                                    <Spin className='spinnerOp mx-auto' size='large' indicator={<img src='images/opportunityloading.gif' />} />
                                </div>
                        :
                            <Table onChange={handleTableChange} 
                                   loading={this.state.isLoading}
                                  pagination={{
                                     pageSizeOptions: ['10', '20','50','100'],
                                  total:
                                    this.state?.listings?.total > 0 &&
                                    this.state.listings.total,
                                  pageSize: this.state.listings.per_page,
                                  current: this.state.listings.current_page,
                                  showTotal: (total, range) =>
                                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                  showSizeChanger: true,
                                  onShowSizeChange: onShowSizeChange,
                                  itemRender: itemRender,
                                }}
                                style={{ overflowX: "auto" }}
                                columns={columns}
                                // showHeader={false}
                                className='tbstockwrap'
                                dataSource={
                                  this.state?.listings?.data
                                    ? this.state?.listings?.data
                                    : []
                                }

                                // rowKey={(record) => record}
                                // Farooq
                                rowKey={(record) => record.id}
                                summary={() => (
                                    <Table.Summary fixed={'bottom'}>
                                      <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={3}>
                                          &nbsp;
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column confirmedowner text-center'>
                                                {/* <label>Total Confirmed Owner:</label> */}
                                                <p className='font-bold d-flex flex-column'>
                                                    <span>{this.state.total_confirmedowner? this.state.total_confirmedowner:'0'}</span>
                                                    <span>{this.roundTotal((this.state.total_confirmedowner/this.state.alltotal)*100,0)+"%"}</span>
                                                </p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column comingtomarket text-center'>
                                                {/* <label>Total Coming to Market:</label> */}
                                                <p className='font-bold d-flex flex-column'>
                                                    <span>{this.state.total_comingtomarket?this.state.total_comingtomarket:'0'}</span>
                                                    <span>{this.roundTotal((this.state.total_comingtomarket/this.state.alltotal)*100,0)+"%"}</span></p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column published text-center'>
                                                {/* <label>Total Published:</label> */}
                                                <p className='font-bold d-flex flex-column'><span>{this.state.total_published?this.state.total_published:'0'}</span>
                                                <span>{this.roundTotal((this.state.total_published/this.state.alltotal)*100,2)+"%"}</span></p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column feeds  text-center'>
                                                {/* <label>Total Feeds:</label>  */}
                                                <p className='font-bold d-flex flex-column'><span>{this.state.total_feeds?this.state.total_feeds:'0'}</span>
                                                <span>{this.roundTotal((this.state.total_feeds/this.state.alltotal)*100,0)+"%"}</span></p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column average text-center'>
                                                {/* <label>Total Average Price:</label> */}
                                                <p className='font-bold'>{parseInt(this.state.total_averageprice).toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") + ' AED'}</p>
                                            </div>
                                        </Table.Summary.Cell>
                                      </Table.Summary.Row>
                                    </Table.Summary>
                                  )}
                                />
                            }
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapDispatchToProps = dispatch =>{
    return {
      setStatus: (data) => dispatch({type: "SET_STATUS",payload:data}),
    //   setBedroom: (data) => dispatch({type: "SET_BED",payload:data}),
    //   setPropertyFor: (data) => dispatch({type: "SET_PROPERTY_FOR",payload:data}),
    //   setAgent: (data) => dispatch({type: "SET_AGENT",payload:data})
      
    }
  }
  
  export default connect(null,mapDispatchToProps)(Stock)
