import React, { Component } from 'react'
import { Table, Space, Select,DatePicker,Col, Row  } from 'antd';
import "../../Pages/antdstyle.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './notifications.css'
import moment, { now } from 'moment';
import API from '../../utils/API';
import { Link } from 'react-router-dom';
import {RiseOutlined} from '@ant-design/icons';


const Option = Select.Option;


class Notifications extends Component {
   
    constructor(props){
        super(props)
        this.state={
            pledges:[],
            isLoading:false
        }
    }
    componentDidMount(){
        var loadScript = function(src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')

        const page = 1;
        const perPage = 10;
        //this.fetchData(page,perPage)

        // window.addEventListener('scroll', () => {
        //     let activeClass = 'noti-fixed';
        //     if(window.scrollY === 0){
        //         activeClass = '';
        //     }
        //     this.setState({ activeClass });
        //  });
    }

    fetchData = (page, perPage) => {
        this.setState({ isLoading: true })
        API.post(`/pledges?page=${page}&perPage=${perPage}`)
          .then(async (response) => {

            console.log("all data",response.data);
    
         
            await response.data.pledges;
            this.setState({
              pledges: response.data.pledges,
              isLoading: false
            })
    
          })
      
    
      }

  render() {

    const columns = [
        {
        title: 'Activity', dataIndex: 'activity',  key: 'activity', width:'15%',
        render: (text, record) => {
            var pStatus = ''
            var color = ''
             switch (record.activity) {
            case 'Viewing Feedback':
              pStatus = 'Viewing Feedback'
              color = 'orange'
              break;
            case 'New Lead':
              pStatus = 'New Lead'
              color = 'green'
              break;
            case 'Mention':
                pStatus = '@mention'
                color = 'purple'
                break;
            case 'Added a note':
                pStatus = 'Added a note'
                color = 'green'
                break;
            case 'Collaborator':
                pStatus = 'Collaborator'
                color = 'navy'
                break;
            case 'Lead Pond':
                    pStatus = 'Lead Pond'
                    color = 'blue'
                    break;
            default:                  
              pStatus = 'Scheduled Viewing'
              color = 'darkorange';
              break;
          }
          return (
            <div className={color + " pstat"} key={pStatus}>
              {pStatus}
            </div>
          )
        },},
       { title: 'Summary', dataIndex: 'summary',key: 'summary', width: '70%'
      }, 
      { title: 'Date', dataIndex: 'date',key: 'date', width:'10%'
      },
      { title: 'Action', dataIndex: 'action',key: 'action', width:'5%',
      render: (text, record) => (
        <div className="ms-auto">
            <a className="btn btn-link btn-sm m-2" style={{fontSize:'14px'}}><i className="fa fa-times" style={{fontSize:"14px"}}></i></a>
        </div>
        ),
      }];

    const handleTableChange = (pagination,filters) => {

        this.fetchData(pagination.current, pagination.pageSize);

    };
    // Sample Data
    const data =[
        {activity:'Viewing Feedback', summary: 'Your viewing for LP17687 (Sun, Arabian Ranches III) needs feedback', date: 'April 11, 2023 | 12:08 PM'},
        {activity:'New Lead', summary: 'A lead has been assigned to you. LP123456', date: 'April 10, 2023 | 08:11 PM'},
        {activity:'Added a note', summary: 'You added a note on Lead LP-L155478. Lead stage changed from Uncontacted To Working', date: 'April 9, 2023 | 9:09 PM'},
        {activity:'Scheduled Viewing', summary: 'You scheduled a viewing for 1234, Signature Villa, Palm Jumeirah', date: 'April 9, 2023 | 12:08 PM'},
        {activity:'Viewing feedback', summary: 'Your viewing for LP17687 (Sun, Arabian Ranches III) needs feedback', date: 'April 9, 2023 | 10:08 PM'},
        {activity:'Mention', summary: 'You scheduled a viewing for 1234, Signature Villa, Palm Jumeirah', date: 'April 8, 2023 | 12:08 PM'},
        {activity:'Collaborator', summary: 'You are added as a collaborator LP-L-L123455', date: 'April 8, 2023 | 08:08 PM'},
        {activity:'Lead Pond', summary: 'A new lead has been added to the Lead Pond. LP-L-L123456', date: 'April 8, 2023 | 07:08 PM'},

    ]

    return (
        <div className='notifications'>
           <ToastContainer />
                <div className='contentwrap'>
                    <div className='ml-auto d-flex px-0'>
                        <div className=' mt-3 w-100'>
                            <Space direction="vertical">
                                <Space wrap>
                                    <div className="d-flex flex-row justify-content-between my-3 w-100 align-items-end">
                                        <h3 className="mb-0">Notifications</h3>
                                        <DatePicker defaultValue={moment()} format={'dddd, MMMM DD, YYYY'} disabled className='todaysdate pr-0'/>
                                    </div>
                                </Space>
                            </Space>
                        </div>
                    </div>
                </div>

                <Row gutter={12} className='recentnoti contentwrap pb-5'>
                    <Col className="gutter-row bg-white py-4 px-2 border" span={15}>
                        <p className='font-bold mb-0 fs-6 px-4'>Activities</p>
                        <p className='px-4'>8 recent activities</p>
                        <div className='recentwrap d-flex flex-column position-relative'>
                            {/* Fixed notifications until they have done it */}
                            {/* <div className={`noti-group viewingfeedback ${this.state.activeClass} `}> */}
                            <div className={`noti-group viewingfeedback noti-fixed`}>
                                <div className='icon'><i class="fa fa-exclamation-triangle"></i></div>
                                <div className='noti-title'>
                                    <p className='title'>Viewing Feedback</p>
                                    <p>Your viewing for LP-17687 (Sun, Arabian Ranches) needs feedback.</p>
                                    <Link to={''} className="btn btn-link">Add Feedback <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>10 mins ago</p>
                                </div>
                            </div>
                            <div className='noti-group newviewing'>
                                <div className='icon'><i class="fa fa-eye"></i></div>
                                <div className='noti-title'>
                                    <p className='title'>New Viewing</p>
                                    <p>You scheduled a viewing</p>
                                    <Link to={''} className="btn btn-link">See details <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>2 mins ago</p>
                                </div>
                            </div>
                            <div className='noti-group scheduledviewing'>
                                <div className='icon'><i class="fa fa-eye"></i></div>
                                <div className='noti-title'>
                                    <p className='title'>Upcoming scheduled viewing</p>
                                    <p>You have a scheduled viewing at 1234, Signature Villa, Palm Jumeirah</p>
                                    <Link to={''} className="btn btn-link">See details <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>2 mins ago</p>
                                </div>
                            </div>
                            <div className='noti-group newlead'>
                                <div className='icon'><RiseOutlined /></div>
                                <div className='noti-title'>
                                    <p className='title'>New Lead</p>
                                    <p>A lead has been assigned to you. <Link to={''}>LP123456</Link> </p>
                                    <Link to={''} className="btn btn-link">View lead <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>2 mins ago</p>
                                </div>
                            </div>
                            <div className='noti-group newviewing'>
                                <div className='icon'><i class="fa fa-eye"></i></div>
                                <div className='noti-title'>
                                    <p className='title'>New Viewing</p>
                                    <p>You scheduled a viewing</p>
                                    <Link to={''} className="btn btn-link">See details <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>2 mins ago</p>
                                </div>
                            </div>
                            <div className='noti-group newlead'>
                                <div className='icon'><RiseOutlined /></div>
                                <div className='noti-title'>
                                    <p className='title'>New Lead</p>
                                    <p>A lead has been assigned to you. <Link to={''}>LP123456</Link> </p>
                                    <Link to={''} className="btn btn-link">View lead <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>2 mins ago</p>
                                </div>
                            </div>
                            <div className='noti-group newviewing'>
                                <div className='icon'><i class="fa fa-eye"></i></div>
                                <div className='noti-title'>
                                    <p className='title'>New Viewing</p>
                                    <p>You scheduled a viewing</p>
                                    <Link to={''} className="btn btn-link">See details <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>2 mins ago</p>
                                </div>
                            </div>
                            <div className='noti-group scheduledviewing'>
                                <div className='icon'><i class="fa fa-eye"></i></div>
                                <div className='noti-title'>
                                    <p className='title'>Upcoming scheduled viewing</p>
                                    <p>You have a scheduled viewing at 1234, Signature Villa, Palm Jumeirah</p>
                                    <Link to={''} className="btn btn-link">See details <i class="bi bi-arrow-right"></i></Link>
                                </div>
                                <div className='datestamp'>
                                    <p>2 mins ago</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="gutter-row bg-white p-4 border ml-auto" span={8}>
                        <p className='font-bold mb-0 fs-6'>Upcoming events</p>
                        <p>3 events for today</p>
                        <div className='d-flex flex-column border-top-3 '>
                            <Row className='upcomingevent'>
                                <Col className='d-flex flex-column' span={16}>
                                    <p className='fs-5 font-bold mb-0'>10:00 AM</p>
                                    <p className='mb-0 font-14'>1234 Signature Villa, Palm Jumeirah</p> 
                                </Col>
                                <Col span={8} className='text-right'>
                                    <p className='mb-0 font-12'>Scheduled Viewing</p>
                                </Col>
                            </Row>
                            <Row className='upcomingevent'>
                                <Col className='d-flex flex-column' span={16}>
                                    <p className='fs-5 font-bold mb-0'>10:00 AM</p>
                                    <p className='mb-0 font-14'>1234 Signature Villa, Palm Jumeirah</p> 
                                </Col>
                                <Col span={8} className='text-right'>
                                    <p className='mb-0 font-12'>Follow Up</p>
                                </Col>
                            </Row>
                            <Row className='upcomingevent'>
                                <Col className='d-flex flex-column' span={16}>
                                    <p className='fs-5 font-bold mb-0'>10:00 AM</p>
                                    <p className='mb-0 font-14'>1234 Signature Villa, Palm Jumeirah</p> 
                                </Col>
                                <Col span={8} className='text-right'>
                                    <p className='mb-0 font-12'>Follow Up</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={12} className='recentnoti contentwrap pb-5'>
                    <Col className="gutter-row bg-white border w-100 px-0">
                    <div class="" >
                        <Table columns={columns} dataSource={data} />
                    </div>
                    </Col>
                </Row>
        </div>
    )
  }
}

export default  Notifications;
