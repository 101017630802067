import React, { Component } from 'react'
import { Select, Row, Col, Avatar, Table, Statistic, Input, DatePicker, Checkbox, Collapse } from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import { UserOutlined } from '@ant-design/icons';
import './listing.css';
import '../Listings/tableResponsive.css';
import Moment from 'react-moment';
import { baseLpUrlImg, baseurlImg, liveUrl } from "../../components/BaseUrl";
import { itemRender, onShowSizeChange } from "../paginationfunction";
const Panel = Collapse.Panel;
const { Countdown } = Statistic;
const deadline = Date.now() + 1000 * 60 * 60 * 24 + 1000; // Dayjs is also OK
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const onFinish = () => {
    console.log('finished!');
};
const onChange = (val) => {
    if (typeof val === 'number' && 4.95 * 1000 < val && val < 5 * 1000) {
        console.log('changed!');
    }
};


class SharePropertyRequests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            request_viewing: [],
            status_filter: [],
        }
    }

    isValidHttpUrl = (string) => {
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    getCurrentTime = (date) => {
        if (date != null && date != "" && date != undefined && date != "0000-00-00 00:00:00") {
            let newDate = new Date(date);
            newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
            return newDate.toISOString();
        }
    }

    getCurrentTimeStamp = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const unixTime = new Date(timestamp).getTime() / 1000;
        return unixTime;
    }

    getRemainingTime = (added_date) => {
        const dateTime = added_date;
        const milliseconds = new Date(dateTime).getTime();
        const timestamp = milliseconds; // Given timestamp
        const date = new Date(timestamp); // Convert timestamp to Date object
        date.setTime(date.getTime() + (24 * 60 * 60 * 1000)); // Add 24 hours
        const updatedTimestamp = date.getTime(); // Get updated timestamp
        return updatedTimestamp;
    }

    handleStatusChange = (e) => {
        this.setState({ status_filter: e });
        const page = 1
        const perPage = 10
        const data = {
            status_filter: e,
        }
        this.fetchData(page, perPage, data);
    }

    componentDidMount() {
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        const page = 1;
        const perPage = 10;
        const data = {
            status_filter: this.state.status_filter,
        }
        this.fetchData(page, perPage, data);
    }

    getPrice = (price) => {
        if (price != null && price != "" && price != undefined) {
            if (!isNaN(parseInt(price))) {
                return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
            }
        }
    }

    fetchData = (page, perPage, data, xChk) => {
        this.setState({ isLoading: true })
        API.post(`/get_request_viewing?page=${page}&perPage=${perPage}`, data)
            .then(async (response) => {
                this.setState({
                    request_viewing: response.data.request_viewing,
                    isLoading: false
                })
            })


    }


    render() {


        const Filters = (
            <>
                <Row className='filterwrap border p-2' gutter={8}>
                    <Col span={5} lg={5} md={8} sm={12} xs={24}>
                        <div className='form-group mb-2'>
                            {/* <Select placeholder="Filter by Status"
                                      options={[
                                        { value: '1', label: 'New Inquiry' },
                                        { value: '2', label: 'Working' },
                                        { value: '3', label: 'Hot' },
                                        { value: '4', label: 'Follow Up' },
                                        { value: '5', label: 'Dead' },
                                        { value: '6', label: 'Offer' },
                                        { value: '7', label: 'Deal Signed' },
                                        { value: '8', label: 'Viewings' },
                                        { value: '9', label: 'Recruiter' },
                                        { value: '10', label: 'No Answer' },
                                        { value: '11', label: 'Expired' },
                                      ]}
                                    /> */}
                            <Select showSearch allowClear placeholder="Agent" key="1" defaultValue={"Requested by"}
                                className='font-12'
                                options={[
                                    { value: 'markcastley', label: 'Mark Castley' },
                                    { value: 'sean', label: 'Sean Magann' },
                                    { value: 'paulgrossi', label: 'Paul Grossi' },
                                    { value: 'Keisha Brass', label: 'Keisha Brass' },
                                    { value: 'Suleyman Ahmed', label: 'Suleyman Ahmed' }
                                ]}
                            />
                        </div>
                    </Col>
                    <Col span={5} lg={5} md={8} sm={12} xs={24}>
                        <div className='form-group  mb-2'>
                            <Input placeholder="Client name" />
                        </div>
                    </Col>
                    <Col span={5} lg={5} md={8} sm={12} xs={24}>
                        <div className='form-group  mb-2'>
                            <Select showSearch allowClear placeholder="Agent" key="1" defaultValue={"Requested to"}
                                className='font-12'
                                options={[
                                    { value: 'markcastley', label: 'Mark Castley' },
                                    { value: 'sean', label: 'Sean Magann' },
                                    { value: 'paulgrossi', label: 'Paul Grossi' },
                                    { value: 'Keisha Brass', label: 'Keisha Brass' },
                                    { value: 'Suleyman Ahmed', label: 'Suleyman Ahmed' }
                                ]}
                            />
                        </div>
                    </Col>
                    <Col span={5} lg={5} md={8} sm={12} xs={24}>
                        <div className='form-group  mb-2'>
                            <Input placeholder="Ref No" />
                        </div>
                    </Col>
                    <Col span={4} lg={4} md={8} sm={12} xs={24}>
                        <div className='form-group  mb-2'>
                            <Input placeholder="Unit no" />
                        </div>
                    </Col>
                    <Col span={24}>
                        <Row gutter={8} className='filterwrap-share'>
                            <Col className={`align-items-center d-flex ${window.isTabView ? 'flex-column' : 'flex-row'}`}>
                                <p className='font-bold mb-0' style={{ fontSize: '0.73rem' }}>Filter by:</p>
                                <CheckboxGroup
                                    onChange={this.handleStatusChange}
                                    // value={this.state.status_filter} onChange={this.handleStatusChange} placeholder="Status"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={[
                                        { value: 'viewingbooked', label: 'Requests that has viewing booked', },
                                        { value: 'yes', label: 'Client that said Yes', },
                                        { value: 'no', label: 'Client that said No', },
                                        { value: 'yesnoviewing', label: 'Client that said Yes but no viewing booked', },
                                        { value: 'declined', label: 'Declined requests' }
                                    ]} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )

        const columns = [
            {
                title: 'Client name', width: 180,
                dataIndex: 'leadclient',
                render: (text, record) => (
                    <div className='listinginfowrap'>
                        <div className='info-group justify-content-between d-flex flex-column h-100'>
                            <p>
                                {record.lead_status == 1 ?
                                    <span className="uncontacted pstat">New Inquiry</span> : null}
                                {record.lead_status == 2 ? <span className="working pstat">Working</span> : null}
                                {record.lead_status == 10 ? <span className="noanswer pstat">No Answer</span> : null}
                                {record.lead_status == 11 ? <span className="expiry pstat">Expired</span> : null}
                                {record.lead_status == 12 ? <span className="expiry pstat">Pond</span> : null}
                                {record.lead_status == 13 ? <span className="recruitment pstat">Offer Accepted</span> : null}
                                {record.lead_status == 14 ? <span className="recruitment pstat">Offer Declined</span> : null}
                                {record.lead_status == 3 ? <span className="hot pstat">Hot</span> : null}
                                {record.lead_status == 4 ? <span className="followup pstat">Follow Up</span> : null}
                                {record.lead_status == 5 ? <span className="dead pstat">Dead</span> : null}
                                {record.lead_status == 6 ? <span className="offer pstat">Offer</span> : null}
                                {record.lead_status == 7 ? <span className="dealsigned pstat">Deal signed</span> : null}
                                {record.lead_status == 8 ? <span className="viewings pstat">Viewings</span> : null}
                                {record.lead_status == 9 ?
                                    <span className="recruitment pstat">Recruitment</span> : null}
                            </p>
                            {/*<p className='font-16 pstat working'>{record.lead_status}</p>*/}
                            <div className=''>
                                <p className='info-title'>Client Name:</p>
                                <p>{record.leads_id ? <a href={"/leads/" + record.leads_id} target="_blank"><p
                                    className='font-16 font-bold'>{record.owner_firstname} {record.owner_lastname}</p>
                                </a> : record.owner_name}</p>
                                <p>
                                    {record.contact_type == 1 ? <span>Tenant</span> : null}
                                    {record.contact_type == 2 ? <span>Buyer</span> : null}
                                    {record.contact_type == 3 ? <span>Landlord</span> : null}
                                    {record.contact_type == 4 ? <span>Seller</span> : null}
                                    {record.contact_type == 5 ? <span>Landlord+Seller</span> : null}
                                    {record.contact_type == 6 ? <span>Agent</span> : null}
                                    {record.contact_type == 7 ? <span>Other</span> : null}
                                    {record.contact_type == 8 ? <span>Portal</span> : null}
                                    {record.contact_type == 9 ? <span>Buyer/Tenant</span> : null}
                                    {record.contact_type == 10 ? <span>Unrecognized</span> : null}
                                    {record.contact_type == 11 ? <span>Recruitment</span> : null}
                                </p>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                title: 'Time Remaining', dataIndex: 'hoursremaining',
                render: (text, record) => (
                    <div className='listinginfowrap'>
                        <div className='info-group'>
                            <p className='info-title'>Time Remaining:</p>
                            {record.note_type == 'req_view' && <Countdown prefix={<i class="bi bi-hourglass-split font-14"></i>}
                                value={this.getRemainingTime(record.added_date)} onFinish={onFinish} />}
                            {record.note_type != 'req_view' && (<><i className="bi bi-hourglass-split font-14"></i><b>00:00:00</b></>)}
                        </div>
                        <div className='info-group'>
                            <p className='info-title'>Last Call:</p>
                            <p className='font-16'>
                                {(record.last_call && record.last_call != "0000-00-00 00:00:00") ? <Moment fromNow>
                                    {(record.last_call)}
                                </Moment> : "-"}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                title: 'Client Agent', dataIndex: 'clientagent',
                render: (text, record) => (
                    <div className='listinginfowrap'>
                        <div className='info-group'>
                            <p className='info-title'>Requested by:</p>
                            <div className='d-flex flex-row align-items-center'>
                                {(record.listing_agent_image) ?
                                    <Avatar
                                        src={this.isValidHttpUrl(record.listing_agent_image) ? record.listing_agent_image : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.listing_agent_image}
                                        alt="avatar" /> :
                                    <Avatar icon={<UserOutlined />} />}
                                <p>{record.listing_agent_name}</p>
                            </div>
                        </div>
                        <div className='info-group'>
                            <p className='info-title'>Requested to:</p>
                            <div className='d-flex flex-row align-items-center'>
                                {(record.lead_agent_image) ?
                                    <Avatar
                                        src={this.isValidHttpUrl(record.lead_agent_image) ? record.lead_agent_image : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.lead_agent_image}
                                        alt="avatar" /> :
                                    <Avatar icon={<UserOutlined />} />}
                                <p>{record.lead_agent_name}</p>
                            </div>
                        </div>

                    </div>
                ),
            },
            {
                title: 'Property Details', dataIndex: 'propertydetails',
                render: (text, record) => (
                    <div className='listinginfowrap'>
                        <div className='info-group'>
                            <p className='info-title'>Property Details:</p>
                            <a href={liveUrl + "/all_listings/" + record.listing_id} target="_blank">
                                <p>{record.listing_id ? record.refno : record.refno}</p>
                                <p className='font-bold' style={{ fontSize: '14px' }}>{record.unitno}</p>
                                <p>{record.subcommunity}, {record.community}</p>
                                <p>{(record.price) ? this.getPrice(record.price) : ""}</p>
                            </a>
                        </div>
                    </div>
                ),
            },

            {
                title: 'Viewing Date', dataIndex: 'viewingdate',
                render: (text, record) => (
                    <div className='listinginfowrap' style={{ gap: 0 }}>
                        <div className='info-group'>
                            <p className='info-title'>Viewing date:</p>
                            <p>{record.viewing_date && record.viewing_date != null ? record.viewing_date : 'No viewing date booked'}</p>
                            {/*<p>{record.viewing_date && record.viewing_date != null ? record.viewing_date : 'No viewing date booked'}</p>*/}
                        </div>
                        <div className='info-group flex-row' style={{ gap: "0.3rem" }}>
                            <p className='info-title'>Share on WhatsApp:</p>
                            {record.whatsapp_status && record.whatsapp_status == 1 ?
                                <i className="bi bi-check-lg font-bold font-16"></i> :
                                <i class="bi bi-x-circle-fill font-bold font-16"></i>}
                        </div>
                        <div className='info-group'>
                            <p className='info-title'>Decline:</p>
                            <p>{record.decline_note && record.decline_note != null ? record.decline_note : '-'}</p>
                        </div>
                    </div>
                ),
            },
            {
                title: 'Date Added', dataIndex: 'sentdate', width: 100,
                render: (text, record) => (
                    <div className='listinginfowrap'>
                        <div className='info-group'>
                            <p className='info-title'>Added Date:</p>
                            {(record.added_date) ? <Moment fromNow className='font-bold'>
                                {record.added_date}
                            </Moment> : "-"}
                        </div>
                    </div>
                ),
            },
        ]

        const columnsResponsive = [
            {
                title: "Status", key: "status",
                render: (text, record) => {

                    return (<>
                        <div className="TableCard">

                            <div className='row alignCenter headerGrayBgColor borderBottom'>
                                <div className='col-6'>
                                    <div className='HeaderMainLeft'>
                                        <p>
                                            {record.lead_status == 1 ?
                                                <span className="uncontacted pstat">New Inquiry</span> : null}
                                            {record.lead_status == 2 ? <span className="working pstat">Working</span> : null}
                                            {record.lead_status == 10 ? <span className="noanswer pstat">No Answer</span> : null}
                                            {record.lead_status == 11 ? <span className="expiry pstat">Expired</span> : null}
                                            {record.lead_status == 12 ? <span className="expiry pstat">Pond</span> : null}
                                            {record.lead_status == 13 ? <span className="recruitment pstat">Offer Accepted</span> : null}
                                            {record.lead_status == 14 ? <span className="recruitment pstat">Offer Declined</span> : null}
                                            {record.lead_status == 3 ? <span className="hot pstat">Hot</span> : null}
                                            {record.lead_status == 4 ? <span className="followup pstat">Follow Up</span> : null}
                                            {record.lead_status == 5 ? <span className="dead pstat">Dead</span> : null}
                                            {record.lead_status == 6 ? <span className="offer pstat">Offer</span> : null}
                                            {record.lead_status == 7 ? <span className="dealsigned pstat">Deal signed</span> : null}
                                            {record.lead_status == 8 ? <span className="viewings pstat">Viewings</span> : null}
                                            {record.lead_status == 9 ?
                                                <span className="recruitment pstat">Recruitment</span> : null}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex gap-1 justify-end align-items-baseline HeaderMainDivRight'>
                                        <p className='info-title'>Time Remaining:</p>
                                        {record.note_type == 'req_view' && <Countdown prefix={<i class="bi bi-hourglass-split font-14"></i>}
                                            value={this.getRemainingTime(record.added_date)} onFinish={onFinish} />}
                                        {record.note_type != 'req_view' && (<><i className="bi bi-hourglass-split font-14"></i><b>00:00:00</b></>)}
                                    </div>
                                </div>
                            </div>

                              <div className='row alignCenter borderGray pt-2 pb-2'>
                                <div className='col-12 col-sm-6'>
                                    <div className='d-flex gap-1 BodyFirstRowRight align-items-baseline'>
                                        <p className='info-title'>Name:</p>
                                        <p>{record.leads_id ? <a href={"/leads/" + record.leads_id} target="_blank"><p
                                            className='font-12 font-bold'>{record.owner_firstname} {record.owner_lastname}</p>
                                        </a> : record.owner_name}</p>

                                    </div>

                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Type:</p>
                                            <p>
                                                {record.contact_type == 1 ? <span>Tenant</span> : null}
                                                {record.contact_type == 2 ? <span>Buyer</span> : null}
                                                {record.contact_type == 3 ? <span>Landlord</span> : null}
                                                {record.contact_type == 4 ? <span>Seller</span> : null}
                                                {record.contact_type == 5 ? <span>Landlord+Seller</span> : null}
                                                {record.contact_type == 6 ? <span>Agent</span> : null}
                                                {record.contact_type == 7 ? <span>Other</span> : null}
                                                {record.contact_type == 8 ? <span>Portal</span> : null}
                                                {record.contact_type == 9 ? <span>Buyer/Tenant</span> : null}
                                                {record.contact_type == 10 ? <span>Unrecognized</span> : null}
                                                {record.contact_type == 11 ? <span>Recruitment</span> : null}
                                            </p>
                                        </div>


                                    </div>

                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Date:</p>
                                            <p>
                                                {
                                                    record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                                                        '---'
                                                        :
                                                        <>
                                                            <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                                                            {/*<span className="px-2">|</span>*/}
                                                            {/*<Moment format="hh:mm A">{record.view_date}</Moment>*/}
                                                        </>
                                                }

                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Last Call:</p>
                                            <p className='font-12'>
                                                {(record.last_call && record.last_call != "0000-00-00 00:00:00") ? <Moment fromNow>
                                                    {(record.last_call)}
                                                </Moment> : "-"}
                                            </p>
                                        </div>
                                    </div>

                                </div>



                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowLeft d-flex flex-column'>
                                        <div className='d-flex gap-1 BodyFirstRowRight align-items-center'>
                                            <p className='info-title'>Requested by:</p>
                                            <div className='d-flex flex-row align-items-center'>
                                                {(record.listing_agent_image) ?
                                                    <Avatar
                                                        src={this.isValidHttpUrl(record.listing_agent_image) ? record.listing_agent_image : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.listing_agent_image}
                                                        alt="avatar" /> :
                                                    <Avatar icon={<UserOutlined />} />}
                                                <p>{record.listing_agent_name}</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight align-items-center'>
                                            <p className='info-title'>Requested to:</p>
                                            <div className='d-flex flex-row align-items-center'>
                                                {(record.lead_agent_image) ?
                                                    <Avatar
                                                        src={this.isValidHttpUrl(record.lead_agent_image) ? record.lead_agent_image : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.lead_agent_image}
                                                        alt="avatar" /> :
                                                    <Avatar icon={<UserOutlined />} />}
                                                <p>{record.lead_agent_name}</p>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className='col-12 col-sm-12'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Property Details:</p>
                                            <p className='px-2'>
                                                <a href={liveUrl + "/all_listings/" + record.listing_id} target="_blank">
                                                    <div className='row'>
                                                        <div className='col-12 col-sm-6'>
                                                            <div className='BodyFirstRowRight'>
                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                    <p className='info-title'>Ref No:</p>
                                                                    <p>{record.listing_id ? record.refno : record.refno}</p>


                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <div className='BodyFirstRowRight'>
                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                    <p className='info-title'>Unit No:</p>
                                                                    <p className='font-bold' style={{ fontSize: '14px' }}>{record.unitno}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <div className='BodyFirstRowRight'>
                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                    <p className='info-title'>Address:</p>
                                                                    <p>{record.subcommunity}, {record.community}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <div className='BodyFirstRowRight'>
                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                    <p className='info-title'>Price:</p>
                                                                    <p>{(record.price) ? this.getPrice(record.price) : ""}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Viewing date:</p>
                                            <p>{record.viewing_date && record.viewing_date != null ? record.viewing_date : 'No viewing date booked'}</p>
                                        </div>
                                    </div>

                                </div>



                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Share on WhatsApp:</p>
                                            {record.whatsapp_status && record.whatsapp_status == 1 ?
                                                <i className="bi bi-check-lg font-bold font-16"></i> :
                                                <i class="bi bi-x-circle-fill font-bold font-16"></i>}
                                        </div>
                                    </div>

                                </div>



                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Decline:</p>
                                            <p>{record.decline_note && record.decline_note != null ? record.decline_note : '-'}</p>
                                        </div>
                                    </div>

                                </div>



                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Added Date:</p>
                                            <p>{(record.added_date) ? <Moment fromNow className='font-bold'>
                                                {record.added_date}
                                            </Moment> : "-"}</p>

                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </>)
                }
            }
        ]


        const onTableChange = (pagination, filters) => {

            const all_data = store.getState((state) => state);
            const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
            const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead

            const data = {
                status_filter: this.state.status_filter,
            }

            this.fetchData(pagination.current, pagination.pageSize, data);

        };

        return (
            <div className='contentwrap shareproperty'>
                <Row>
                    <Col span={24}>
                        <h3>Share Property Requests</h3>
                    </Col>
                </Row>

                {/* Filters object Created by Hassan 18/08/24 */}
                {window.isTabView ? <>
                    <Collapse className={window.isIpadView ? 'moreoptions w-100' : 'moreoptions w-100'}>
                        <Panel header={<p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Filters:</p>} key="1">
                            {/* {!this.state.isViewingTabShow && !this.state.isOfferTabShow?<> */}

                            {Filters}
                        </Panel>
                    </Collapse> </> : [Filters]}

                {/* <Row className='datesfilter-wrap px-2' gutter={8}>
                    <Col span={8}>
                        <div class="addeddate-wrap d-flex flex-row">
                            <p class="titledate">Last Call</p>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" name="addeddateoption" id="addeddateLessthanoption" checked="" />
                                <label class="form-check-label" for="addeddateLessthanoption">Less than</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" name="addeddateoption" id="addeddateMorethanoption" />
                                <label class="form-check-label" for="addeddateMorethanoption">More than</label>
                            </div>
                            <div class="form-check mb-0 d-flex flex-row" bordered={false}> 
                                <input type="text" class="form-control font-12" value="0" />
                                <Select bordered={false}
                                    options={[
                                        { value: 1, label: 'Days' },
                                        { value: 2, label: 'weeks' },
                                        { value: 3, label: 'months' },
                                        { value: 4, label: 'years' },
                                    ]}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='form-group d-flex flex-row mb-0'>
                            <label className='titledate'>Viewing Date:</label>
                            <RangePicker />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='form-group d-flex flex-row mb-0'>
                            <label className='titledate'>Added date:</label>
                            <RangePicker /> 
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col span={24} className='sharepropwrap'>
                        <Table columns={window.isTabView ? columnsResponsive : columns}
                        className="TableborderNoneMain"
                            onChange={onTableChange}
                            dataSource={
                                this.state?.request_viewing?.data
                                    ? this.state?.request_viewing?.data
                                    : []
                            }
                            showHeader={false} loading={this.state.isLoading}
                            pagination={{
                                pageSizeOptions: ['10', '20', '50', '100'],
                                total:
                                    this.state?.request_viewing?.total > 0 &&
                                    this.state.request_viewing.total,
                                pageSize: this.state.request_viewing.per_page,
                                current: this.state.request_viewing.current_page,
                                showTotal: (total, range) =>
                                    window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                            }}
                        />
                    </Col>
                </Row>

            </div>
        )
    }
}

export default SharePropertyRequests