import React, { Component } from 'react'
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import {baseurlImg} from "./BaseUrl";
import API from "../utils/API";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import { Button, Checkbox,Modal } from 'antd';
class SortableContainer extends React.Component {
    // update this
    sortableContainerRef = React.createRef();
    oldIndex = null;

    constructor(props) {
        super(props);
        this.state = {
            property_images: this.props.property_images,
            listing_id:this.props.listing_id,
            previewOpen:false,
            image:null
        }
    }

    componentDidUpdate(prevProps) {
        // is this file uploaded? upload again
       
       
      
        if (prevProps.property_images.length != 0 && this.props.property_images.length >0 && prevProps.property_images.length !== this.props.property_images.length ) {
         
            console.log("tetete");

          this.setState({
            property_images: this.props.property_images,
          });
    
        }
      }

    componentDidMount() {
      
        const sortableContainer = $(this.sortableContainerRef.current);
        sortableContainer.sortable({
            start: this.handleSortStart,
            update: this.handleSortUpdate,
        });
    }

    handleButtonClicked = (images) => {
        this.props.updateImages(images)
    }

    handleFeature = (id,e) =>{

        const data = {id:id,listing_id:this.props.listing_id}
        var mydata =  this.state.property_images
        var i;
        var j;
        for (i = 0; i < mydata.length; i++) {
          
            mydata[i].default = "N";
        
        }

        for (j = 0; j < mydata.length; j++) {
            if(mydata[j].id == id){
            mydata[j].default = "Y";
            }
        
        }

        // var myArray =  this.state.property_images
        // var i
        // for ( i = 0; i < myArray.length; i++) {
        //      myArray[i].default == "N" 
             
        //  }
        // for (var j = 0; j < myArray.length; j++) {
        //    if(myArray[j] == id){
        //     myArray[j].default == "Y"
        //    }
            
        // }
        this.setState({property_images:mydata})
        

        API.post("/update_image_feature",data)
            .then((response) =>{
                if(response.data.success){
                    console.log("updated");
                   // this.setState({property_images:response.data.images})
                }
            })
    }

    handleDelete = (image, image_id,e) =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    image: image,
                    image_id: image_id,
                    listing_id:this.state.listing_id
                }
                API.post("/delete_listing_images", data)
                    .then((response) =>{
                        if(response.data.success){
                            toast.success("Successfully deleted")
                            this.setState({property_images: this.state.property_images.filter(function(ob) { 
                                return ob.id !== image_id 
                            })});
                            //this.setState({property_images:response.data.property_images})
                            this.handleButtonClicked(response.data.property_images);
                        }else{
                            toast.error('something went wrong');
                        }
                    }).catch((error) => {
                    console.log(error);
                    toast.error('something went wrong');
                });
            }
        })
    }

    handleSortStart = (event, ui) => {
        const { onStart } = this.props;
        this.oldIndex = ui.item.index();
        // Perform any actions you want when sorting starts
        if (onStart) {
            onStart(ui.item.index());
        }
    };

    handleSortUpdate = (event, ui) => {
        const { onUpdate, property_images } = this.props;
        const newIndex = ui.item.index();
        const totalItems = ui.item.parent().children().length;
        const fileName = property_images[newIndex];
        if (onUpdate) {
            onUpdate(this.oldIndex, newIndex, totalItems);
        }
    };

    componentWillUnmount() {
        $(this.sortableContainerRef.current).sortable('destroy');
    }

    handleImageSelect = (index,e)=>{
        const images_array = this.state.property_images

        for (let i = 0; i < images_array.length; i++) {
           if(i == index){
                images_array[i].check_status = !images_array[i].check_status  
           }
            
        }

        this.setState({ property_images: images_array });
    }

    handleAllImagesSelect = (e) =>{
       
        // e.target.checked ?
        const images_array = this.state.property_images



        for (let i = 0; i < images_array.length; i++) {
          
                images_array[i].check_status = e.target.checked ?true:false
          
            
        }

        this.setState({ property_images: images_array });
    }

    handleDeleteAll = () =>{

       const all_images = this.state.property_images
       const filteredImages = all_images.filter((image) => image.check_status === true);
       const otherImages = all_images.filter((image) => image.check_status != true);

      
      
       if(filteredImages.length > 0){
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    listing_id:this.state.listing_id,
                    filteredImages:filteredImages
                }
                API.post("/delete_all_images", data)
                    .then((response) =>{
                        
                        if(response.data.success){
                            toast.success("Successfully deleted")
                           this.setState({property_images:otherImages})
                         
                        }else{
                            toast.error('something went wrong');
                        }
                    }).catch((error) => {
                    console.log(error);
                    toast.error('something went wrong');
                });
            }
        })
       }
    }
    handlePreviewImage = (image_path,e) =>{
        
       
        this.setState({previewOpen:true,image:image_path.image})
    }
    handleCancel = () =>{
        this.setState({previewOpen:false})
    }
    render() {
        const all_images = this.state.property_images
        const filteredImages = all_images.filter((image) => image.check_status === true);
        // this.setState({property_images:this.props.property_images})

        //console.log("imgs state",this.props.property_images);

            return  (
                <>

<Modal   width={1000} open={this.state.previewOpen} 
// title={previewTitle} 
footer={null} 
onCancel={this.handleCancel}
>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={baseurlImg+ "/" + this.state.image}
        />
       
      </Modal>
                <div className=''>
                 <Checkbox 
                    onChange={this.handleAllImagesSelect}
                    >Select all</Checkbox>
                      {filteredImages.length > 0? <Button className='primary' onClick={this.handleDeleteAll}>Delete all</Button>:null}
                   
                 </div>
                    {/*{this.state.items.map((item, index) => (*/}
                        <div ref={this.sortableContainerRef} className="sortable-container">
                      
                            {this.state.property_images.map((image, index) => (
                                <div className="" key={index} >
                                    <Checkbox 
                                    checked={image.check_status?true:false}
                                    onChange={this.handleImageSelect.bind(this,index)}
                                    ></Checkbox>
                                    <img src={image.image.includes("https://") ? image.image : baseurlImg + "/" + image.image} className="w-100" onClick={this.handlePreviewImage.bind(this,image)} />
                                    <div className="">
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <input className="form-check-input mt-0" type="radio" onChange={this.handleFeature.bind(this,image.id)} name={image.id} checked={image.default=="Y"?true:false} aria-label="Checkbox for following text input" id="featured" />
                                                <label className="control-labels" htmlFor="leadgen">Featured</label>
                                            </div>
                                        </div>
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="floorplan" />
                                                <label className="control-labels" htmlFor="leadgen">Floor Plan</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-1">
                                        <select name="tags">
                                            <option>Tag</option>
                                            <option>Exterior</option>
                                            <option>Interior</option>
                                            <option>Litchen</option>
                                        </select>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        { (this.props.role == 1 || this.props.status != 'Y') && <button onClick={this.handleDelete.bind(this, image.image, image.id)}
                                                 className="btn btn-text p-0"><i className="bi bi-trash-fill"/>
                                        </button>}
                                        <a href={(image.image) ? (image.image.includes("https://") ? image.image : baseurlImg + "/" + image.image) : "#"} target="_blank">
                                            <button className="btn btn-text p-0">
                                                <i className="bi bi-eye-fill" />
                                            </button>
                                        </a>
                                        <button className="btn btn-text p-0"><i className="bi bi-magic" /></button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    {/*))}*/}
                </>
            );
            // <ul className="sortable-container" ref={this.sortableContainerRef} style={{ display: 'flex', width: "200px" }}>
            //     {this.state.items.map((item, index) => (
            //         <img className="w-100" src="https://www.luxuryproperty.com//uploads/images/5-bedroom-apartment-for-rent--LP20441-1292a9c73a886d00.jpg"
            //              />
            //         // <li key={index}>{item}</li>
            //     ))}
            // </ul>
    }
}
export default SortableContainer;