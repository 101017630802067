import React, { Component } from 'react'
import { Breadcrumb, Select } from 'antd'
import { Link } from 'react-router-dom'

 class AddListing extends Component {
    constructor(props){
        super(props)
        this.state = {
            communities:[],
            property_for_is:false
        }
    }

    handlePropertFor =(e) =>{
      const selected_property_for = e.target.value
      if(selected_property_for == 1){ this.setState({property_for_is:true})}
      else{ this.setState({property_for_is:false})}
     
    }

  render() {
    return (
        <div>
        <Breadcrumb
         style={{
           margin: '16px 0',
         }}
       >
         <Breadcrumb.Item>
         <Link to="/dashboard" >
         Dashboard
         </Link>
         </Breadcrumb.Item>
         <Breadcrumb.Item>
         <Link to="/all_listings" >
         All listings
         </Link>
         </Breadcrumb.Item>
         <Breadcrumb.Item>Add listing</Breadcrumb.Item>
         
       </Breadcrumb>
       <div  className='container' style={{
           padding: 24,
           minHeight: 380,
           background: "#fff",
         }}>

            <div className='row'>
                <div className='col-md-4'>
                    <div className='form-group'>
                        <label>Unit no</label>
                        <input type="text" className="form-control" />
                    </div>
                </div>
            </div>

            <div className='row'>
            <div className='col-md-4'>
            <label>Community</label>
                    <div className='form-group'>
                        
                        <Select
                       style={{
                        width: '100%',
                      }}
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    // onChange={this.handleCommunity}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.communities}
                />
                    </div>

                </div>

                <div className='col-md-4'>
                <label>Sub community</label>
                    <div className='form-group'>
                        
                        <Select
                       style={{
                        width: '100%',
                      }}
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    // onChange={this.handleCommunity}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.communities}
                />
                    </div>
                </div>
                <div className='col-md-4'>
            <label>Property</label>
                    <div className='form-group'>
                        
                        <Select
                       style={{
                        width: '100%',
                      }}
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    // onChange={this.handleCommunity}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.communities}
                />
                    </div>

                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label>Form A</label>
                    <input type="file" className='form-control form-control-lg' />
                </div>

                <div className='col-md-6'>
                    <label>Passport</label>
                    <input type="file" className='form-control form-control-lg' />
                </div>

            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label>Emirates ID</label>
                    <input type="file" className='form-control form-control-lg' />
                </div>

                <div className='col-md-6'>
                    <label>Title deed</label>
                    <input type="file" className='form-control form-control-lg' />
                </div>

            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label>Owner's Name</label>
                    <input type="text" className="form-control" />
                </div>

                <div className='col-md-6'>
                    <label>Owner's Phone Number</label>
                    <input type="text" className="form-control" />
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label>Owner's Email Address </label>
                    <input type="text" className="form-control" />
                </div>

                <div className='col-md-6'>
                    <label>Property Type</label>
                    <Select
                       style={{
                        width: '100%',
                      }}
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    // onChange={this.handleCommunity}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.communities}
                />
                </div>

            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label>Stage </label>
                    <select className='form-select'>
                      <option>Comming to market</option>
                      <option>Confirmed owner</option>
                   </select>
                </div>

                <div className='col-md-6'>
                    <label>Bedrooms</label>
                   <select className='form-select'>
                      <option>Studio</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                   </select>
                </div>

            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label>Price </label>
                  <input type="text" className='form-control' />
                </div>

                <div className='col-md-6'>
                    <label>Property for</label>
                   <select className='form-select' onChange={this.handlePropertFor}>
                      <option>select property for</option>
                      <option value="1">Rent</option>
                      <option value="2">Sale</option>
                      <option value="3">Short term</option>
                   </select>
                </div>

            </div>
   {this.state.property_for_is?
            <div className='row'>
                <div className='col-md-4'>
                    <label>Available date</label>
                </div>
            </div>:null
  }
       
       
       </div>
       </div>
    )
  }
}

export default AddListing
