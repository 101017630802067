import React, {Component} from 'react'
import API from "../../utils/API";
import {toast, ToastContainer} from "react-toastify";

class ForgetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            text_message: "Enter your email address",
            btn_message: "Send password reset email",
            email_checked: true,
            phone_checked: false,
            disable: false,
            validationerrors: {},
            fetch_message: ""
        }
    }

    toPhone = () => {
        this.setState({
            text_message: "Enter your phone number",
            btn_message: "Send code",
            phone_checked: true,
            email_checked: false
        })
        this.setState({disable: false});
        this.setState({validationerrors: {}});
        this.setState({fetch_message: ""});
    }

    toEmail = () => {
        this.setState({
            text_message: "Enter your email address",
            btn_message: "Send password reset email",
            phone_checked: false,
            email_checked: true
        })
        this.setState({disable: false});
        this.setState({validationerrors: {}});
        this.setState({fetch_message: ""});
    }

    getTextMessage = (e) => {
        this.setState({fetch_message: e.target.value})
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.resetPassword();
    }

    validate() {
        let input = this.state.fetch_message;
        let validationerrors = {};
        let isValid = true;


        if (input == null || input == "") {
            isValid = false;
            if (this.state.email_checked) {
                validationerrors["fetch_message"] = "Email is required.";
            } else {
                validationerrors["fetch_message"] = "Phone number is required.";
            }
        }else if (input !== "" || input != null) {
            if (this.state.email_checked) {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(input)) {
                    isValid = false;
                    validationerrors["email"] = "Please enter valid email address.";
                }
            }
        }

        this.setState({
            validationerrors: validationerrors
        });

        return isValid;
    }

    resetPassword = () => {
        if (this.validate()) {
            const data = {
                resetOptionViaEmail: this.state.email_checked,
                message: this.state.fetch_message
            }
            this.setState({disable: true});
            API.post("/reset_password", data)
                .then((response) => {
                    this.setState({disable: false});
                    let success = response.data.success;
                    if (success){
                        toast.success("Reset link is sent to your email")
                    }else{
                        toast.warning("Email do not exist")
                    }
                })
        }
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <div className='container' style={{marginTop: '50px'}}>
                    <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>

                            <div className="card ">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Reset password</h3>
                                    <div className="card-text">
                                        <form>

                                            {/*<div className="form-check">*/}
                                            {/*    <input className="form-check-input" type="radio" name="flexRadioDefault"*/}
                                            {/*           id="flexRadioDefault1" onClick={this.toPhone}*/}
                                            {/*           checked={this.state.phone_checked}/>*/}
                                            {/*    <label className="form-check-label" htmlFor="flexRadioDefault1">*/}
                                            {/*        SMS*/}
                                            {/*    </label>*/}
                                            {/*</div>*/}
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                       id="flexRadioDefault2" onClick={this.toEmail}
                                                       checked={this.state.email_checked}/>
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    Email
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control form-control-sm"
                                                       placeholder={this.state.text_message} name="fetch_message"
                                                       id="fetch_message" onChange={this.getTextMessage}
                                                       value={this.state.fetch_message}/>
                                                <div
                                                    className="text-danger">{this.state.validationerrors.fetch_message}</div>
                                                <div
                                                    className="text-danger">{this.state.validationerrors.email}</div>
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-block"
                                                    onClick={this.onSubmit}
                                                    disabled={this.state.disable}>{this.state.btn_message}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ForgetPassword