import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumb,Button, Checkbox, Form, Input,InputNumber,Select,Switch,Table } from 'antd';
import { SaveOutlined} from '@ant-design/icons';


import API from '../../utils/API';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

 class EditUser extends Component {

    constructor(props){
        super(props);
        this.state ={
            modules:[],
            permissions:[],
            teams:[],
            user:{},
            name:"",
            email:"",
            team_id:0,
            active:false

        }
    }

    componentDidMount(){

        let id = this.props.match.params.id;

        API.get("/get_roles")
        .then((response) =>{
         
            this.setState({teams:response.data.roles})
        })


        API.get("/user/"+id)
        .then((response) =>{


            const mydata = response.data.permissions
            const userpermissions = response.data.userpermissions
            const userdata = response.data.user
            
            for (let i = 0; i < mydata.length; i++) {
              
                for (let j = 0; j < userpermissions.length; j++) {
               
                   if(mydata[i].id == userpermissions[j].permission_id){

                    mydata[i].ischecked = true;

                   }else{
                    //mydata[i].ischecked = false;
                   }
                    
                }
                
            }

            
            this.setState({
              permissions:mydata,
              user:userdata,
              name:userdata.display_name,
              email:userdata.email,
              active:(userdata.status == "Y") ? true : false,
              team_id:userdata.role
            })

           
        })




    }

     validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };

     

      onChangePermission = (permission_id,e)=>{
       
        let index = this.state.permissions.findIndex(obj => obj.id ===permission_id);
        var mydata = this.state.permissions;

        var i;
        for (i = 0; i < mydata.length; i++) {
           
          if (i == index) {

           
           
            mydata[i].ischecked = mydata[i].ischecked? !mydata[i].ischecked:false;
            
          }
        }

        this.setState({permissions:mydata})


      }
      handleName= (e)=>{
        this.setState({name:e.target.value})
     }
      handleTeam = (e)=>{
        e.preventDefault()
         this.setState({team_id:e.target.value})
      }

      handleEmail = (e)=>{
        this.setState({email:e.target.value})
     }
     handleUserStatus = (value) =>{
      this.setState({active:value})
     }

     handleSubmit = () =>{
      let id = this.props.match.params.id;
      const data = {
        name:this.state.name,
        email:this.state.email,
        team_id:this.state.team_id,
        active:(this.state.active == true) ? "Y" : "N",
      }

    

      API.put("EditUser/"+id,data)
      .then((response) =>{
         if(response.data.success){
               this.props.history.push("/users");
         }
      })
     }


       onFinish = (values) => {
      
        const data = {
            form_values:values,
            permissions:this.state.permissions,
        }

       

        API.post("/createUser",data)
        .then((response) =>{
           if(response.data.success){
                 this.props.history.push("/users");
           }
        })
      };



  render() {
    

    return (
        <div>
        <Breadcrumb
         style={{
           margin: '16px 0',
         }}
       >
        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/users">All Uses</Link></Breadcrumb.Item>
         <Breadcrumb.Item>Edit User</Breadcrumb.Item>
         
       </Breadcrumb>
       <div style={{
           padding: 24,
           minHeight: 380,
           background: "#fff",
         }}>
          <div className='container'>

   <div className='row'>
    <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-10'>
                <div className='form-group'>
                  <label>Name</label>
                  <input type="text" className="form-control" defaultValue={this.state.name} onChange={this.handleName} />
                </div>
              </div>

              <div className='col-md-10 mt-3'>
                <div className='form-group'>
                  <label>Email</label>
                  <input type="text" className="form-control" defaultValue={this.state.email} onChange={this.handleEmail} />
                </div>
              </div>
             

              <div className='col-md-10 mt-3'>
                <div className='form-group'>
                  <label>Team</label>
                 <select className='form-select' onChange={this.handleTeam} >
                 <option value={this.state.team_id}>{this.state.user.name}</option>

                 {this.state.teams.map((team)=>(
                 <option value={team.roles_id}>{team.role}</option>
                    
                 ))}
                 

                 </select>
                </div>
              </div>

              <div className='col-md-3 mt-3'>
                <div className='form-group'>
                  <label>Active / Inactive</label>
                  <Switch  checked={this.state.active} onChange={this.handleUserStatus} />                </div>
              </div>


            </div>
    </div>

    <div className='col-md-7'>

     </div>


   </div>
   </div>

      <button className='btn btn-primary mt-5' onClick={this.handleSubmit}>
      <SaveOutlined /> Update
      </button>
            </div>
            </div>
    )
  }
}

export default EditUser;
