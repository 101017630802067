const initialState = {
    navigation: null
  };
  const NavigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NAVIGATION':
          return {
            ...state,
            navigation: action.payload,
          };
        default:
          return state;
      }
  };
  
  export default NavigationReducer;
  