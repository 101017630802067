const initialState = {
    matched_data: {},
  };
  const Not_Match_Listing_Reducer = (state = initialState, action) => {
   
    switch (action.type) {
      case "SET_Not_Match":
        return {
          ...state,
          matched_data: action?.payload ? action?.payload : {},
       
        };
  
  
      default:
        return state;
    }
  };
  
  export default Not_Match_Listing_Reducer;
  

  