import React, { Component } from 'react'
import { UploadOutlined, FileSearchOutlined, DeleteOutlined,PaperClipOutlined,CheckOutlined,PlusCircleOutlined, CloseOutlined, MoreOutlined, PlusOutlined, EditOutlined, CheckCircleFilled, FilePdfOutlined, FileExcelOutlined, PullRequestOutlined, FileExclamationOutlined, StopOutlined, PrinterOutlined, FileSyncOutlined, CloseCircleOutlined  } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import API from '../../utils/API';
import { baseurlImg } from '../../components/BaseUrl';
import parse from 'html-react-parser';
import { Skeleton,Button } from 'antd';



 class DownloadPDF extends Component {

    constructor(props){
        super(props)
        this.state ={
            listing:{},
            isLoading:false

        }
    }

    componentDidMount(){
        let id = this.props.match.params.id
        console.log("id",id);
        this.setState({isLoading:true})
        API.get("/listing_toDownload/"+id)
        .then((res) =>{
           if(res.data.success){
            this.setState({isLoading:false,listing:res.data.listing})
           }
        })
    }
    isValidHttpUrl = (string) =>{
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }
  
  render() {

    const images = this.state.listing.property_images?this.state.listing.property_images.split(",").filter(Boolean):[]; 
    const price = this.state.listing.price

    return (
      <div >
        {this.state.isLoading?<Skeleton />:<>
           <ReactToPrint
          
                    content={() => this.componentRef}
                    trigger={() => <Button className='ml-2 addnewlisting' type='primary' onClick={this.handleOpenDrawer} icon={<PrinterOutlined /> }>Save & download</Button>}
                    
                />
      <div className='printprevbrochure mt-3 d-flex position-relative' ref={(response) => (this.componentRef = response)}>
  <img style={{position: 'absolute', top: '0.8in', left: '0.00in', width: '9.02in', height: '4.79in'}} src={baseurlImg+"/"+images[0]} />
  <img style={{position: 'absolute', top: '3.9in', left: '3.52in', width: '5.5in', height: '1.7in'}} src="/brochure/ri_1.png" />
  {/* <div style={{position: 'absolute', top: '1.95in', left: '5.13in', width: '3.8in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '25pt', color: '#ffffff'}}>{this.state.listing.community} </span><br /></div>
  <div style={{position: 'absolute', top: '2.15in', left: '5.13in', width: '2.40in', lineHeight: '1.05in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '25pt', color: '#ffffff'}}>{this.state.listing.subcommunity} </span><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '50pt', color: '#ffffff'}}> </span><br /></div> */}
  <div style={{position: 'absolute', top: '4.15in', left: '3.9in', lineHeight: '1.05in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '12pt', color: '#ffffff'}}>{this.state.listing.community},&nbsp; {this.state.listing.subcommunity},&nbsp; {this.state.listing.property}  </span><br /></div>
  <div style={{position: 'absolute', top: '4.55in', left: '3.9in', width: '3.25in', lineHeight: '1.05in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '20pt', color: '#ffffff'}}>{price?price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':''}  </span><br /></div>
  <div style={{position: 'absolute', top: '11.4in', width: '100%', lineHeight: '0.18in'}}><div style={{background: '#151515', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <div style={{display: 'flex', flexDirection: 'row', padding: '1rem', width: '100%', alignItems: 'center'}}>
        <div style={{display: 'flex', borderRadius: '50%', overflow: 'hidden', width: 60, height: 60, alignContent: 'center', marginRight: '1rem'}}><img src= {this.isValidHttpUrl(this.state.listing.profile_photo)?this.state.listing.profile_photo:baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + this.state.listing.profile_photo} style={{height: '100%'}} /></div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p style={{color: '#FFF', fontSize: 14, margin: '0 0 5px'}}>{this.state.listing.agent_name}</p>
          <p style={{color: '#FFF', fontSize: 11, margin: 0}}>Senior Private Client Advisor</p>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', textAlign: 'right'}}>
          <p style={{color: '#FFF', fontSize: 12, margin: '0 0 5px'}}>{this.state.listing.mobile}</p> {/* the number of the agent*/}
          <p style={{color: '#FFF', fontSize: 12, margin: 0}}>{this.state.listing.email}</p>
        </div>
      </div>
    </div><br /></div>
  <img style={{position: 'absolute', top: '6.2in', left: '0.55in', width: '2.5inin', height: '1.35in'}} src={baseurlImg+"/"+images[1]} />
  <img style={{position: 'absolute', top: '6.2in', left: '3.29in', width: '2.5in', height: '1.35in'}} src={baseurlImg+"/"+images[2]}  />
  <img style={{position: 'absolute', top: '6.2in', left: '6.04in', width: '2.5in', height: '1.35in'}} src={baseurlImg+"/"+images[3]}  />
  <div style={{position: 'absolute', top: '5.80in', left: '0.55in', width: '1.18in', lineHeight: '0.24in'}}><span style={{fontStyle: 'normal', fontWeight: 'bold', fontSize: '12pt', color: '#151515'}}>OVERVIEW</span></div>
  <img style={{position: 'absolute', top: '5.95in', left: '2.25in', width: '6.23in', height: '0.01in'}} src="/brochure/vi_6.png" />
  <img style={{position: 'absolute', top: '0.25in', left: '0.56in', width: '5.36in', height: '0.36in'}} src="/brochure/vi_7.png" />
  <div style={{position: 'absolute', top: '0.30in', left: '0.85in', width: '4.84in', lineHeight: '0.24in'}}><span style={{fontSize: '10pt', color: '#151515', letterSpacing: 8}}>LUXURY PRIVATE VILLA </span></div><br />
  <div style={{position: 'absolute', top: '0.30in', left: '0.65in', width: '4.84in', lineHeight: '0.24in'}}><div style={{position: 'relative', left: '3.41in'}}><span style={{fontWeight: 'bold', fontSize: '11pt', color: '#151515', letterSpacing: 8}}>{this.state.listing.property_for =="rent"?"FOR RENT":"FOR SALE"} </span></div><br /></div>
  <img style={{position: 'absolute', top: '0.25in', left: '0.00in', width: '0.56in', height: '0.36in'}} src="/brochure/vi_8.png" />
  <img style={{position: 'absolute', top: '0.30in', left: '6.5in', width: '2.3in'}} src="/brochure/Lp-Logo-2021-Black-Web.png" />
  {/* <div style={{position: 'absolute', top: '4.96in', left: '5.13in', width: '1.82in', lineHeight: '0.83in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '42pt', color: '#ffffff'}}>$ 2 </span><br /></div>
  <div style={{position: 'absolute', top: '4.96in', left: '5.13in', width: '1.82in', lineHeight: '0.83in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '42pt', color: '#ffffff'}}>$ 200</span><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '42pt', color: '#ffffff'}}> </span><br /></div>
  <div style={{position: 'absolute', top: '5.75in', left: '5.13in', width: '1.85in', lineHeight: '0.28in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '14pt', fontFamily: 'Montserrat', color: '#ffffff'}}>PER </span><br /></div>
  <div style={{position: 'absolute', top: '5.75in', left: '5.13in', width: '1.85in', lineHeight: '0.29in'}}><div style={{position: 'relative', left: '0.75in'}}><span style={{fontStyle: 'normal', fontWeight: 'bold', fontSize: '14pt', fontFamily: 'Montserrat', color: '#ffffff'}}> DAY</span><span style={{fontStyle: 'normal', fontWeight: 'bold', fontSize: '14pt', fontFamily: 'Montserrat', color: '#ffffff'}}> </span><br /></div></div>
  <div style={{position: 'absolute', top: '4.75in', left: '5.13in', width: '1.81in', lineHeight: '0.29in'}}><span style={{fontStyle: 'normal', fontWeight: 'bold', fontSize: '14pt', fontFamily: 'Montserrat', color: '#ffffff'}}>ONLY</span><br /></div>
  <div style={{position: 'absolute', top: '4.76in', left: '5.13in', width: '1.81in', lineHeight: '0.28in'}}><div style={{position: 'relative', left: '1.17in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '14pt', fontFamily: 'Montserrat', color: '#ffffff'}}>FOR</span><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '14pt', fontFamily: 'Montserrat', color: '#ffffff'}}> </span><br /></div></div> */}

  
  <div style={{position: 'absolute', top: '7.75in', left: '0.55in', width: '7.56in', lineHeight: '0.24in'}}><span style={{fontStyle: 'normal', fontWeight: 'bold', fontSize: '12pt', color: '#151515'}}>ABOUT THE PROPERTY </span><br /></div>
  <div style={{position: 'absolute', top: '8.3in', left: '0.55in', width: '8in', lineHeight: '0.14in'}}><div style={{position: 'relative', left: '0.02in'}}><span style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '5pt', color: '#52555b'}}>{this.state.listing.desc && parse(this.state.listing.desc.substring(0, 949)) }</span></div><br /></div>
  <img style={{position: 'absolute', top: '7.9in', left: '3.38in', width: '5.10in', height: '0.01in'}} src="/brochure/vi_32.png" />
</div>
</>}
      </div>
    )
  }
}

export default DownloadPDF
