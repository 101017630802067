import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumb} from 'antd';
import { SaveOutlined} from '@ant-design/icons';
import './profile.css'
import API from '../../utils/API';
import { baseurlImg } from '../../components/BaseUrl';
import { connect } from 'react-redux';


class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: '',
      email:'',
      name:'',
      phone:'',
      address:'',
      profile_image:''

    };
  }


  componentDidMount(){

    API.get("/me")
    .then((response) =>{

     

      const user = response.data.user
      this.setState({name:user.username,email:user.email,phone:user.mobile,address:user.address,profile_image:user.profile_photo})
    })
  }


  handleName = (e) =>{
    e.preventDefault()
    this.setState({name:e.target.value})
  }

  handleEmail = (e) =>{
    e.preventDefault()
    this.setState({email:e.target.value})
  }
  handlePhone = (e) =>{
    e.preventDefault()
    this.setState({phone:e.target.value})
  }
  handleAddress = (e) =>{
    e.preventDefault()
    this.setState({address:e.target.value})
  }



  _handleImageChange = (e) => {

    e.preventDefault();
    this.setState({profile_image:''})
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
    this.setState({ imagePreviewUrl: URL.createObjectURL(e.target.files[0]) });
  };
  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        file: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }


  handleSubmit = (e) =>{
    const data = {
      name:this.state.name,
      email:this.state.email,
      phone:this.state.phone,
      address:this.state.address,
      image:this.state.file
    }
    
    API.post("/profile",data)
    .then((response)=>{

      if(response.data)
      this.props.setProfile(response.data.user)
    })
  }

    isValidHttpUrl = (string) =>{
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  render() {

    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img className='img_profile' src={imagePreviewUrl} />);
    } else {
      $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    }


    return (
      <div>

<Breadcrumb
         style={{
           margin: '16px 0',
         }}
       >
        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
         <Breadcrumb.Item>Profile</Breadcrumb.Item>
         
       </Breadcrumb>
       <div style={{
           padding: 24,
           minHeight: 380,
           background: "#fff",
         }}>

<div className='container'>

<div className='row'>
 <div className='col-md-5'>
         <div className='row'>
           <div className='col-md-10'>
             <div className='form-group'>
               <label>Name</label>
               <input type="text" className="form-control"  defaultValue={this.state.name} onChange={this.handleName} />
             </div>
           </div>

           <div className='col-md-10 mt-2'>
             <div className='form-group'>
               <label>Email</label>
               <input type="text" className="form-control" defaultValue={this.state.email} onChange={this.handleEmail} />
             </div>
           </div>
          
           <div className='col-md-10 mt-2'>
             <div className='form-group'>
               <label>Phone</label>
               <input type="text" className="form-control" defaultValue={this.state.phone} onChange={this.handlePhone} />
             </div>
           </div>


           <div className='col-md-10 mt-2'>
             <div className='form-group'>
               <label>Address</label>
               <input type="text" className="form-control" defaultValue={this.state.address} onChange={this.handleAddress} />
             </div>
           </div>

         
         

         


         </div>
 </div>

 <div className='col-md-7'>
 
         

<div className="imgPreview">
<input className="form-control" 
            type="file" 
            onChange={(e)=>this._handleImageChange(e)} />
            {this.state.profile_image !=''?<img className='img_profile' src={
               this.isValidHttpUrl(this.state.profile_image)? this.state.profile_image:
              baseurlImg+"lpcrm/public/uploads/clients/2036/users/"+this.state.profile_image

              } />:<>{$imagePreview}</>}
          
        </div>
  </div>


</div>

<div className='mt-3'>
  
  <button className='btn btn-primary' onClick={this.handleSubmit}><SaveOutlined /> Update</button>
 
</div>
</div>
      </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch =>{
  return {
      setProfile: (data) => dispatch({type: "SET_PROFILE",payload:data})
  }
}

export default connect(null,mapDispatchToProps)(Profile)
