import React, { Component } from 'react'
import { Select, DatePicker, Col, Row, Spin } from 'antd';
import "../../Pages/antdstyle.css"
import "react-toastify/dist/ReactToastify.css";
import './notifications.css'
import API from '../../utils/API';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { store } from '../../store';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker

let newDate = new Date()
let current_month = newDate.getMonth() + 1;


class Kpi extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            kpi_users: [],
            agents: [],
            teams: [],
            nameAsc: false,
            isAsc: false,
            nameAsc: false,
            listingAsc: false,
            lead_by_listingAsc: false,
            viewingAsc: false,
            offerAsc: false,
            dealAsc: false,
            offer_valueAsc: false,
            commissionAsc: false,
            total_commissions: "",
            total_commissions_average: "",
            total_leads: "",
            total_expired_leads: "",
            total_revnue: "",
            total_revnue_average: "",
            total_conversion: "",
            total_conversion_average: "",
            total_cnv: "",
            target: "",
            deals: "",
            new_listings: "",
            new_viewings: "",
            feeds_back: "",
            row_id: "",
            pledges: [],
            isLoading: false,
            selectedAgents: "",
            dateRange: [],
            month: "",
            selectedTeam: "",
            months: [{
                key: 1,
                labe: "January"
            },
            {
                key: 2,
                labe: "February"
            },
            {
                key: 3,
                labe: "March"
            },
            {
                key: 4,
                labe: "April"
            },
            {
                key: 5,
                labe: "May"
            },
            {
                key: 6,
                labe: "June"
            },
            {
                key: 7,
                labe: "July"
            },
            {
                key: 8,
                labe: "August"
            },
            {
                key: 9,
                labe: "September"
            },
            {
                key: 10,
                labe: "October"
            },
            {
                key: 11,
                labe: "November"
            },
            {
                key: 12,
                labe: "December"
            },

            ],


        }
    }

    componentDidMount() {



        const data = { month: current_month, selectedAgents: this.state.selectedAgents, selectedTeam: this.state.selectedTeam, dateRange: this.state.dateRange }

        this.fetchTotal(data)

        this.fetchData(data)

        API.get("/all_agents")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ agents: response.data.agents })
                }
            }
            )

        this.fetchGroup()
    }

    fetchGroup = () => {
        API.get("/teams")
            .then((response) => {

                if (response.data.success) {
                    this.setState({ teams: response.data.teams })
                }
            }
            )
    }

    fetchTotal = (data) => {

        API.post("/kpi_total", data)
            .then(async (response) => {

                if (response.data.success) {
                    const total_commissions = response.data.total_commissions
                    const total_amount = response.data.total_amounts
                    const total_deals = response.data.total_deals
                    const total_deals_count = response.data.total_deals_count
                    const total_leads = response.data.all_leads
                    const total_expired_leads = response.data.all_expired_leads
                    const total_users_record = response.data.total_users_record
                    const total_commissions_average = this.roundTotal(total_commissions / total_deals_count, 2)
                    const total_revnue = this.roundTotal(total_commissions / total_leads, 2)
                    const total_revnue_average = this.roundTotal(total_amount / total_revnue, 2)
                    const total_cnv = this.roundTotal(total_deals_count / total_leads, 3)

                    const total_conversion_average = this.roundTotal(total_cnv / total_users_record, 5)





                    this.setState({
                        total_commissions: total_commissions, total_commissions_average: total_commissions_average, total_conversion_average: total_conversion_average,
                        total_leads: total_leads, total_expired_leads: total_expired_leads, total_revnue: total_amount, total_revnue_average: total_revnue_average, total_cnv: total_cnv, total_users_record: total_users_record
                    })
                }

            })
    }

    fetchData = (data) => {
        this.setState({ isLoading: true });
        API.post("/kpi", data)
            .then(async (response) => {


                const res_data = response.data.kpi_users

                let total_listing_count = 0
                let total_lead_count = 0
                let total_leads_by_listing_sum = 0
                let total_comm = 0
                let total_viewing_count = 0
                let total_offer_count = 0
                let total_deal_count = 0
                let total_offer_sum = 0
                let total_rev_per_lead_sum = 0
                let total_viewing_count_by_offer_count = 0
                let total_offer_count_by_deal_count = 0


                // kpi_user.offer_commission/kpi_user.new_lead_count  kpi_user.viewings/kpi_user.offers_count

                for (let i = 0; i < res_data.length; i++) {
                    const new_leads = res_data[i].new_leads
                    const expired_leads = res_data[i].expired_leads
                    const new_listings = res_data[i].new_listings
                    const viewings = res_data[i].viewings
                    const offers = res_data[i].offers
                    const deals = res_data[i].deals

                    if (new_listings.length > 0) {
                        total_listing_count += new_listings[0].listing_count
                        res_data[i].new_listing_count = new_listings[0].listing_count
                    } else {
                        res_data[i].new_listing_count = 0
                        total_listing_count += 0
                    }


                    if (new_leads.length > 0) {
                        total_lead_count += new_leads[0].lead_count
                        res_data[i].new_lead_count = new_leads[0].lead_count
                    } else {
                        res_data[i].new_lead_count = 0
                        total_lead_count += 0
                    }

                    if (expired_leads.length > 0) {

                        res_data[i].expired_leads_count = expired_leads[0].lead_count
                    } else {
                        res_data[i].expired_leads_count = 0
                    }

                    if (new_listings.length > 0 && new_leads.length > 0) {
                        total_leads_by_listing_sum += !isNaN(parseInt(new_leads[0].lead_count / new_listings[0].listing_count)) ? new_leads[0].lead_count / new_listings[0].listing_count : 0
                    } else {
                        total_leads_by_listing_sum += 0
                    }

                    if (viewings.length > 0) {
                        total_viewing_count += viewings[0].viewings_count
                        res_data[i].viewings_count = viewings[0].viewings_count
                    } else {
                        total_viewing_count += 0
                        res_data[i].viewings_count = 0
                    }
                    if (offers.length > 0) {
                        total_offer_count += offers[0].offers_count
                        total_offer_sum += offers[0].offer_amount
                        res_data[i].offers_count = offers[0].offers_count
                        res_data[i].offer_amount = offers[0].offer_amount

                    } else {
                        total_offer_count += 0
                        res_data[i].offers_count = 0
                        res_data[i].offer_amount = 0
                        res_data[i].offer_commission = 0
                    }

                    if (deals.length > 0) {
                        total_deal_count += deals[0].deals_count
                        total_comm += deals[0].offer_commission
                        res_data[i].deals_count = deals[0].deals_count
                        res_data[i].offer_commission = deals[0].offer_commission
                    } else {
                        total_deal_count += 0
                        res_data[i].deals_count = 0
                        res_data[i].offer_commission = 0
                    }

                    if (deals.length > 0 && new_leads.length > 0) {
                        total_rev_per_lead_sum += !isNaN(parseInt(deals[0].offer_commission / new_leads[0].lead_count)) ? deals[0].offer_commission / new_leads[0].lead_count : 0
                    } else {
                        total_rev_per_lead_sum += 0
                    }

                    if (viewings.length > 0 && offers.length > 0) {
                        total_viewing_count_by_offer_count += !isNaN(parseInt(viewings[0].viewings_count / offers[0].offers_count)) ? viewings[0].viewings_count / offers[0].offers_count : 0
                    } else {
                        total_viewing_count_by_offer_count += 0
                    }

                    if (deals.length > 0 && offers.length > 0) {
                        total_offer_count_by_deal_count += !isNaN(parseInt(offers[0].offers_count / deals[0].deals_count)) ? offers[0].offers_count / deals[0].deals_count : 0
                    } else {
                        total_offer_count_by_deal_count += 0
                    }

                }



                this.setState({
                    kpi_users: res_data, isLoading: false, total_listing_count, total_lead_count: total_lead_count, total_comm: total_comm,
                    total_viewing_count: total_viewing_count, total_offer_count: total_offer_count,
                    total_offer_sum: total_offer_sum, total_deal_count: total_deal_count, total_leads_by_listing_sum: total_leads_by_listing_sum,
                    total_rev_per_lead_sum: total_rev_per_lead_sum, total_viewing_count_by_offer_count: total_viewing_count_by_offer_count,
                    total_offer_count_by_deal_count: total_offer_count_by_deal_count

                })

            })
            .catch(e => console.log("error"))

    }

    handleTabs = (e, event) => {

        const data = { month: e, selectedAgents: this.state.selectedAgents, selectedTeam: this.state.selectedTeam, dateRange: this.state.dateRange }
        this.setState({ month: e })
        this.fetchTotal(data)
        this.fetchData(data)
    }

    handleTeams = (e, event) => {

        const data = { month: this.state.month, selectedAgents: this.state.selectedAgents, selectedTeam: e, dateRange: this.state.dateRange }
        this.setState({ selectedTeam: e })
        this.fetchTotal(data)
        this.fetchData(data)
    }

    handleClearFilter = () => {

        const data = { month: "", selectedAgents: "", selectedTeam: this.state.selectedTeam, dateRange: this.state.dateRange }
        this.setState({ month: "", selectedAgents: "" })
        this.fetchData(data)
        this.fetchTotal(data)
    }

    handleClearTeam = () => {

        const data = { month: this.state.month, selectedAgents: "", selectedTeam: "", dateRange: this.state.dateRange }
        this.setState({ month: this.state.month, selectedAgents: "", selectedTeam: "" })
        this.fetchData(data)
        this.fetchTotal(data)
    }



    roundTotal = (value, precision) => {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
    handleAgent = (e) => {
        this.setState({ selectedAgents: e })

        const data = { selectedAgents: e, month: this.state.month, selectedTeam: this.state.selectedTeam, dateRange: this.state.dateRange }

        this.fetchTotal(data)
        this.fetchData(data)

    }

    handleRangeDate = (value, dateString) => {

        this.setState({ dateRange: value == null ? [] : dateString })

        const data = { selectedAgents: this.state.selectedAgents, month: this.state.month, selectedTeam: this.state.selectedTeam, dateRange: value == null ? [] : dateString }

        this.fetchTotal(data)
        this.fetchData(data)

    }


    handleSortByNameAsc = () => {


        const kpis = this.state.kpi_users
        kpis.sort((a, b) =>
            a.display_name > b.display_name ? 1 : -1);
        this.setState({ kpi_users: kpis, nameAsc: true })
    }

    handleSortNameDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) =>
            a.display_name > b.display_name ? -1 : 1);
        this.setState({ kpi_users: kpis, nameAsc: false })
    }

    handleSortByLeadsAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.new_lead_count - b.new_lead_count))
        this.setState({ kpi_users: kpis, isAsc: true })
    }

    handleSortByLeadsDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.new_lead_count - a.new_lead_count))
        this.setState({ kpi_users: kpis, isAsc: false })
    }

    handleSortByListingsAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.new_listing_count - b.new_listing_count))
        this.setState({ kpi_users: kpis, listingAsc: true })
    }

    handleSortByListingDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.new_listing_count - a.new_listing_count))
        this.setState({ kpi_users: kpis, listingAsc: false })
    }

    handleSortByLeadByListingsAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.new_lead_count / a.new_listing_count - b.new_lead_count / b.new_listing_count))
        this.setState({ kpi_users: kpis, lead_by_listingAsc: true })
    }

    handleSortByLeadByListingDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.new_lead_count / b.new_listing_count - a.new_lead_count / a.new_listing_count))
        this.setState({ kpi_users: kpis, lead_by_listingAsc: false })
    }

    handleSortViewingsAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.viewings_count - b.viewings_count))
        this.setState({ kpi_users: kpis, viewingAsc: true })
    }

    handleSortViewingsDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.viewings_count - a.viewings_count))
        this.setState({ kpi_users: kpis, viewingAsc: false })
    }

    handleSortOffersAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.offers_count - b.offers_count))
        this.setState({ kpi_users: kpis, offerAsc: true })
    }

    handleSortOffersDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.offers_count - a.offers_count))
        this.setState({ kpi_users: kpis, offerAsc: false })
    }


    handleSortDealsAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.deals_count - b.deals_count))
        this.setState({ kpi_users: kpis, dealAsc: true })
    }

    handleSortDealsDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.deals_count - a.deals_count))
        this.setState({ kpi_users: kpis, dealAsc: false })
    }

    handleSortOfferValueAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.offer_amount - b.offer_amount))
        this.setState({ kpi_users: kpis, offer_valueAsc: true })
    }

    handleSortOfferValueDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.offer_amount - a.offer_amount))
        this.setState({ kpi_users: kpis, offer_valueAsc: false })
    }

    handleSortCommissionAsc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (a.offer_commission - b.offer_commission))
        this.setState({ kpi_users: kpis, commissionAsc: true })
    }

    handleSortCommissionDesc = () => {

        const kpis = this.state.kpi_users
        kpis.sort((a, b) => (b.offer_commission - a.offer_commission))
        this.setState({ kpi_users: kpis, commissionAsc: false })
    }




    render() {
        const authentication = store.getState((state) => state);
        const user = authentication.auth.user
        const role = user.role

        console.log("lead", this.state.total_lead_count);

        console.log("deal", this.state.total_deal_count);

        return (
            <div className='p-4 contentwrap kpiwrap' >
                <Row gutter={16} className='align-items-center mb-4'>
                    <Col className="gutter-row" span={8} lg={8} md={8} sm={24} xs={24}>
                        <h1 className="font-bold text-uppercase mb-0 fs-3">KPI</h1>
                    </Col>



                    <Col span={16} lg={16} md={16} sm={24} xs={24} className='ml-auto'>
                        <Row className={`${window.isMobileView ? 'gap-1' : ''} justify-content-end d-flex align-items-center`} gutter={8}>


                            <Col span={12} lg={12} md={12} sm={12} xs={24}>
                                {role == 1 ? <Select className='mr-2'
                                    allowClear
                                    showSearch
                                    style={{
                                        width: 120, height: '36px'
                                        // minWidth:'200px',
                                        // width: '100%',
                                    }}
                                    value={this.state.community_filter}

                                    placeholder="Select agent"
                                    onChange={this.handleAgent}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.agents}
                                /> : null}
                            </Col>
                            <Col span={12} lg={12} md={12} sm={12} xs={24}>
                                <div className='form-group d-flex flex-row w-100 align-items-center mb-0 position-relative'>
                                    <label className='mr-2 mb-0' style={{ whiteSpace: 'nowrap' }}>Date:</label>
                                    <RangePicker style={{ height: '36px', overflow: 'hidden', position: 'relative', width: '100%' }} onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {role == 1 ? <Col className="gutter-row">
                    <Tabs className={'tabs-team'}>
                        <TabList>
                            {this.state.teams.length > 0 ? <Tab onClick={this.handleClearTeam}><span className='mx-3'>All</span></Tab> : null}
                            {this.state.teams.map((team, index) => (
                                <>
                                    <Tab onClick={this.handleTeams.bind(this, team.value)} ><span className="mx-3">{team.label}</span></Tab>
                                </>
                            ))}

                        </TabList>
                    </Tabs>
                </Col> : null}
                <div className='kpicontentwrap'>
                    <Row gutter={16} className='kpi-wrap'>
                        <Col className="gutter-row tabs-months" span={24}>
                            <Tabs defaultIndex={current_month}>
                                <TabList>
                                    {this.state.months.length > 0 ? <Tab onClick={this.handleClearFilter}><span className='mx-3'>All</span></Tab> : null}
                                    {this.state.months.map((month) => (
                                        <>
                                            <Tab onClick={this.handleTabs.bind(this, month.key)}><span className="mx-3">{month.labe}</span></Tab>
                                        </>
                                    ))}

                                </TabList>
                            </Tabs>
                        </Col>
                    </Row>
                    <Row className='contentwrap kpi-wrap'>
                        <Col className="gutter-row w-100 px-0 py-0">


                            <Row gutter={16} className='kpi-totals-wrap'>
                                <Col span={8} lg={8} md={12} sm={24} xs={24} style={{ padding: '0 3px' }}>
                                    <Col span={24} className='border p-3'>
                                        <p className='text-uppercase font-14 mb-1'>Commission</p>
                                        <Row className='w-100'>
                                            <Col span={12} lg={12} md={12} sm={12} xs={24} className='p-0'>
                                                <p className='font-bold fs-5 mb-0 fontRes-11'>{

                                                    this.state.total_commissions > 0 ? this.state.total_commissions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                                        : 0

                                                }</p>
                                                <p className='font-12'>Total</p>
                                            </Col>
                                            <Col span={12} lg={12} md={12} sm={12} xs={24} className='p-0'>
                                                <p className='font-bold fs-5 mb-0 fontRes-11'>{

                                                    this.state.total_commissions_average > 0 ? this.state.total_commissions_average.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                                        : 0

                                                }</p>
                                                <p className='font-12'>Average</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col span={8} lg={8} md={12} sm={24} xs={24} style={{ padding: '0 3px' }}>
                                    <Col span={24} className='border p-3'>
                                        <p className='text-uppercase font-14 mb-1'>Total Revenue</p>
                                        <Row className='w-100'>
                                            <Col span={12} lg={12} md={12} sm={12} xs={24} className='p-0'>
                                                <p className='font-bold fs-5 mb-0 fontRes-11'>{

                                                    this.state.total_revnue > 0 ? this.state.total_revnue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                                        : 0

                                                }</p>
                                                <p className='font-12'>Total</p>
                                            </Col>
                                            <Col span={12} lg={12} md={12} sm={12} xs={24} className='p-0'>
                                                <p className='font-bold fs-5 mb-0 fontRes-11'>{

                                                    this.state.total_revnue_average > 0 ? this.state.total_revnue_average.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                                        : 0

                                                }</p>
                                                <p className='font-12'>Average</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col span={8} lg={8} md={12} sm={24} xs={24} style={{ padding: '0 3px' }}>
                                    <Col span={24} className='border p-3'>
                                        <p className='text-uppercase font-14 mb-1'>Total Conversion</p>
                                        <Row className='w-100'>
                                            <Col span={12} lg={12} md={12} sm={12} xs={24} className='p-0'>
                                                <p className='font-bold fs-5 mb-0 fontRes-11'>{

                                                    !this.state.isLoading ?
                                                        <>{
                                                            this.state.total_lead_count > 0 ?
                                                                ((this.state.total_deal_count / this.state.total_lead_count) * 100).toFixed(4) + ' %' : "-"
                                                        }
                                                        </>

                                                        :
                                                        "-"




                                                }</p>
                                                <p className='font-12'>Total</p>
                                            </Col>
                                            <Col span={12} lg={12} md={12} sm={12} xs={24} className='p-0'>
                                                <p className='font-bold fs-5 mb-0 fontRes-11'>{

                                                    !this.state.isLoading ?
                                                        <>{this.state.total_lead_count > 0 ? (((this.state.total_deal_count / this.state.total_lead_count) * 100) / this.state.total_users_record).toFixed(4) + ' %' : "-"}</>

                                                        :
                                                        "-"

                                                }</p>
                                                <p className='font-12'>Average</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className='table-responsive tablelistwrap'>

                                {!window.isIpadView ?
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>

                                                <th scope="col">Name

                                                    {this.state.nameAsc ? <CaretDownOutlined onClick={this.handleSortNameDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortByNameAsc} />
                                                    }
                                                </th>
                                                <th scope="col">New Listings

                                                    {this.state.listingAsc ? <CaretDownOutlined onClick={this.handleSortByListingDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortByListingsAsc} />
                                                    }
                                                </th>
                                                <th scope="col">



                                                    New Leads
                                                    {this.state.isAsc ? <CaretDownOutlined onClick={this.handleSortByLeadsDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortByLeadsAsc} />
                                                    }
                                                </th>
                                                <th scope="col">



                                                    Expired Leads

                                                </th>
                                                <th scope="col">Leads/Listings

                                                    {this.state.lead_by_listingAsc ? <CaretDownOutlined onClick={this.handleSortByLeadByListingDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortByLeadByListingsAsc} />
                                                    }

                                                </th>
                                                <th scope="col">Viewings

                                                    {this.state.viewingAsc ? <CaretDownOutlined onClick={this.handleSortViewingsDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortViewingsAsc} />
                                                    }

                                                </th>
                                                <th scope="col">Offers

                                                    {this.state.offerAsc ? <CaretDownOutlined onClick={this.handleSortOffersDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortOffersAsc} />
                                                    }
                                                </th>
                                                <th scope="col">Deals

                                                    {this.state.dealAsc ? <CaretDownOutlined onClick={this.handleSortDealsDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortDealsAsc} />
                                                    }
                                                </th>
                                                <th scope="col">Offer Value


                                                    {this.state.offer_valueAsc ? <CaretDownOutlined onClick={this.handleSortOfferValueDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortOfferValueAsc} />
                                                    }
                                                </th>
                                                <th scope="col comborder">Commission

                                                    {this.state.commissionAsc ? <CaretDownOutlined onClick={this.handleSortCommissionDesc} /> :
                                                        <CaretUpOutlined onClick={this.handleSortCommissionAsc} />
                                                    }
                                                </th>
                                                <th scope="col">Rev Per Lead</th>
                                                <th scope="col">Viewings/Offers</th>
                                                <th scope="col">Offers/Deals</th>
                                                <th scope="col">CNV</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.isLoading ? <tr><td colspan="12" className='py-5 text-center'><Spin tip="Loading..." className='' size="large" /></td></tr> : <>
                                                {this.state.kpi_users.length > 0 ? <>{this.state.kpi_users.map((kpi_user) => (
                                                    <tr>

                                                        <td>{kpi_user.display_name}</td>
                                                        <td>{kpi_user.new_listing_count > 0 ? kpi_user.new_listing_count : 0}</td>
                                                        <td>{kpi_user.new_lead_count > 0 ? kpi_user.new_lead_count : 0}</td>
                                                        <td>{kpi_user.expired_leads_count > 0 ? kpi_user.expired_leads_count : 0}</td>
                                                        <td>{!isNaN(parseInt(kpi_user.new_lead_count / kpi_user.new_listing_count)) ? this.roundTotal(kpi_user.new_lead_count / kpi_user.new_listing_count, 2) : 0} </td>
                                                        <td>{kpi_user.viewings_count > 0 ? kpi_user.viewings_count : 0}</td>
                                                        <td>{kpi_user.offers_count > 0 ? kpi_user.offers_count : 0}</td>
                                                        <td>{kpi_user.deals_count > 0 ? kpi_user.deals_count : 0}</td>
                                                        <td><span>{kpi_user.offer_amount > 0 ? kpi_user.offer_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : 0}</span></td>
                                                        <td className='comborder'>
                                                            <span className={
                                                                kpi_user.offer_commission > 100000 && kpi_user.team_id == 6 || kpi_user.offer_commission > 40000 && kpi_user.team_id == 7 || kpi_user.offer_commission > 40000 && kpi_user.team_id == 8 || kpi_user.offer_commission > 40000 && kpi_user.team_id == 9 ? "targetsales font-bold" :
                                                                    'font-bold'
                                                            }>
                                                                {kpi_user.offer_commission > 0 ? kpi_user.offer_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : 0 + " AED"}
                                                            </span>
                                                        </td>
                                                        <td>{!isNaN(parseInt(kpi_user.offer_commission / kpi_user.new_lead_count)) ? this.roundTotal(kpi_user.offer_commission / kpi_user.new_lead_count, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : 0} </td>
                                                        <td>{!isNaN(parseInt(kpi_user.viewings_count / kpi_user.offers_count)) ? this.roundTotal(kpi_user.viewings_count / kpi_user.offers_count, 2) : 0} </td>
                                                        <td>{!isNaN(parseInt(kpi_user.offers_count / kpi_user.deals_count)) ? this.roundTotal(kpi_user.offers_count / kpi_user.deals_count, 2) : 0} </td>


                                                        <td>{!isNaN(parseInt(kpi_user.new_lead_count / kpi_user.deals_count)) ? ((kpi_user.deals_count / kpi_user.new_lead_count) * 100).toFixed(2) + "%" : 0.00 + "%"} </td>


                                                    </tr>

                                                ))}</> : <tr>No data</tr>}



                                            </>}
                                        </tbody>
                                        <tfoot style={{ background: '#fafafa' }}>
                                            <tr>
                                                <td className='font-bold'>TOTAL</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_listing_count)) ? this.state.total_listing_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_leads)) ? this.state.total_leads.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_expired_leads)) ? this.state.total_expired_leads.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.roundTotal(this.state.total_leads_by_listing_sum, 2))) ? this.roundTotal(this.state.total_leads_by_listing_sum, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_viewing_count)) ? this.state.total_viewing_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_offer_count)) ? this.state.total_offer_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_deal_count)) ? this.state.total_deal_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_offer_sum)) ? this.state.total_offer_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.state.total_comm)) ? this.state.total_comm.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.roundTotal(this.state.total_rev_per_lead_sum, 2))) ? this.roundTotal(this.state.total_rev_per_lead_sum, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.roundTotal(this.state.total_viewing_count_by_offer_count, 2))) ? this.roundTotal(this.state.total_viewing_count_by_offer_count, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!isNaN(parseInt(this.roundTotal(this.state.total_offer_count_by_deal_count, 2))) ? this.roundTotal(this.state.total_offer_count_by_deal_count, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-"}</td>
                                                <td className='text-center font-bold'>{!this.state.isLoading ?
                                                    ((this.state.total_deal_count / this.state.total_lead_count) * 100).toFixed(4) + ' %'
                                                    :
                                                    "-"}</td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    :
                                    <>

                                        {this.state.isLoading ?
                                            <div className="d-flex flex-row alignCenter justify-content-center p-2" role="status">
                                                <Spin tip="Loading..." className='' size="large" />
                                            </div>

                                            : <>
                                                <Col span={24} className='p-0'>
                                                    <Row>
                                                        {this.state.kpi_users.length > 0 ? <>{this.state.kpi_users.map((kpi_user) => (

                                                            <Col span={12} lg={12} md={12} sm={24} xs={24} className='p-0'>
                                                                <div className="TableCard" style={{ padding: '15px' }}>

                                                                    <div className='row alignCenter headerGrayBgColor borderBottom'>
                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='HeaderMainLeft'>
                                                                                {kpi_user.display_name}
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                                                                                <p className='info-title'>Commission:</p>
                                                                                <p>
                                                                                    <span className={
                                                                                        kpi_user.offer_commission > 100000 && kpi_user.team_id == 6 || kpi_user.offer_commission > 40000 && kpi_user.team_id == 7 || kpi_user.offer_commission > 40000 && kpi_user.team_id == 8 || kpi_user.offer_commission > 40000 && kpi_user.team_id == 9 ? "targetsales font-bold" :
                                                                                            'font-bold'
                                                                                    }>
                                                                                        {kpi_user.offer_commission > 0 ? kpi_user.offer_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : 0 + " AED"}
                                                                                    </span>
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row alignCenter borderGray pt-2 pb-2'>

                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='BodyFirstRowLeft d-flex flex-column'>
                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>New Listings:</p>
                                                                                    <p>
                                                                                        {kpi_user.new_listing_count > 0 ? kpi_user.new_listing_count : 0}
                                                                                    </p>
                                                                                </div>

                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>New Leads:</p>
                                                                                    <p>
                                                                                        {kpi_user.new_lead_count > 0 ? kpi_user.new_lead_count : 0}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='BodyFirstRowRight'>
                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Expired Leads:</p>
                                                                                    <p>
                                                                                        {kpi_user.expired_leads_count > 0 ? kpi_user.expired_leads_count : 0}
                                                                                    </p>
                                                                                </div>

                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'> Leads/Listings:</p>
                                                                                    <p>
                                                                                        {!isNaN(parseInt(kpi_user.new_lead_count / kpi_user.new_listing_count)) ? this.roundTotal(kpi_user.new_lead_count / kpi_user.new_listing_count, 2) : 0}
                                                                                    </p>
                                                                                </div>


                                                                            </div>

                                                                        </div>



                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='BodyFirstRowLeft d-flex flex-column'>
                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Viewings:</p>
                                                                                    <p>
                                                                                        {kpi_user.viewings_count > 0 ? kpi_user.viewings_count : 0}
                                                                                    </p>
                                                                                </div>

                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Offers:</p>
                                                                                    <p>
                                                                                        {kpi_user.offers_count > 0 ? kpi_user.offers_count : 0}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='BodyFirstRowRight'>
                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Deals:</p>
                                                                                    <p>
                                                                                        {kpi_user.deals_count > 0 ? kpi_user.deals_count : 0}
                                                                                    </p>
                                                                                </div>

                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Offer Value:</p>
                                                                                    <p>
                                                                                        <span>{kpi_user.offer_amount > 0 ? kpi_user.offer_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : 0}</span>
                                                                                    </p>
                                                                                </div>


                                                                            </div>

                                                                        </div>


                                                                    </div>


                                                                    <div className='row alignCenter borderGray pt-2 pb-2'>

                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='BodyFirstRowLeft d-flex flex-column'>
                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Rev Per Lead:</p>
                                                                                    <p>{!isNaN(parseInt(kpi_user.offer_commission / kpi_user.new_lead_count)) ? this.roundTotal(kpi_user.offer_commission / kpi_user.new_lead_count, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : 0}
                                                                                    </p>
                                                                                </div>

                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Viewings/Offers:</p>
                                                                                    <p>
                                                                                        {!isNaN(parseInt(kpi_user.viewings_count / kpi_user.offers_count)) ? this.roundTotal(kpi_user.viewings_count / kpi_user.offers_count, 2) : 0}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12 col-sm-6'>
                                                                            <div className='BodyFirstRowRight'>
                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>Offers/Deals:</p>
                                                                                    <p>{!isNaN(parseInt(kpi_user.offers_count / kpi_user.deals_count)) ? this.roundTotal(kpi_user.offers_count / kpi_user.deals_count, 2) : 0} </p>
                                                                                </div>

                                                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                                    <p className='info-title'>CNV:</p>
                                                                                    <p>{!isNaN(parseInt(kpi_user.new_lead_count / kpi_user.deals_count)) ? ((kpi_user.deals_count / kpi_user.new_lead_count) * 100).toFixed(2) + "%" : 0.00 + "%"}</p>
                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </Col>

                                                        ))}

                                                        </>
                                                            : <div className="d-flex flex-row alignCenter justify-content-center p-2" role="status">
                                                                <p>
                                                                    No data
                                                                </p>
                                                            </div>}
                                                    </Row>
                                                </Col>
                                            </>
                                        }</>
                                }
                            </Row>





                        </Col>
                    </Row>
                </div>
            </div>
        )
    }


}
export default Kpi;