import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,

  Route,
  Link,
  useHistory, Redirect
} from "react-router-dom";


import { Layout, Menu, Dropdown, Space, Avatar, Select, Modal, Row, Col, Input,Table, Button, Box } from 'antd';
import {
  SettingOutlined, HomeOutlined, FileOutlined,UserOutlined, LogoutOutlined, RiseOutlined,  CopyOutlined, FundProjectionScreenOutlined, BarChartOutlined, FileTextOutlined, YoutubeOutlined, LoadingOutlined
} from '@ant-design/icons';
import Dashboard from '../../Pages/Home/Dashboard';
import AllListings from '../../Pages/Listings/AllListings';
import AllListingsV2 from '../../Pages/Listings/AllListingsV2';
import HoldingBay from '../../Pages/Listings/HoldingBay';
import PreviewBrochure from '../../Pages/Listings/PreviewBrochure';
import OwnerListings from '../../Pages/OwnerListings/OwnerListings'
import Profile from '../../Pages/Auth/Profile';
import AddUser from '../../Pages/Users/AddUser';
import ListUsers from '../../Pages/Users/Users';
import EditUser from '../../Pages/Users/EditUser';
import AdminPrivate from '../protectRoutes/AdminPrivate';
import UseViewport from "../viewport/viewPort";
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";
import { store } from '../../store';
import { baseurlImg } from '../BaseUrl';
import EditListing from '../../Pages/Listings/EditListing';
import AddListing from '../../Pages/Listings/AddListing';
import ListRoles from '../../Pages/Roles/ListRoles';
import AddRole from '../../Pages/Roles/addRole';
// import MyListings from '../../Pages/Listings/MyListings'
import All_Listings_Leads_Viewings from '../../Pages/Listings/All_Listings_Leads_Viewings';
import All_Listings_Leads_Offers from '../../Pages/Listings/All_Listings_Leads_Offers';
import DocumentsList from "../../Pages/Documents/DocumentsList";
import All_Viewings from '../../Pages/Listings/All_Viewings';
import All_Lead_Viewings from '../../Pages/Leads/All_Lead_Viewings';
import LeadsPond from '../../Pages/Leads/LeadsPond';
import SharePropertyRequests from '../../Pages/Leads/Share_Property_Request';
import LeadsRedirect from '../../Pages/Leads/LeadsRedirect';
import Matching_Listing from '../../Pages/Listings/Matching_Listing';
import ListingProcess from '../../Pages/Listings/ListingProcess.js';
import Photography from '../../Pages/Requests/Photography.js';
import Restrict_Leads from '../../Pages/Leads/Restrict_Leads';
import Access_Leads from '../../Pages/Leads/Access_Leads';
// import Reports from '../../Pages/Reports/Reports';
import Followups from '../../Pages/Listings/Followups';
import Deals from '../../Pages/Listings/Deals';
import Pledge from '../../Pages/Listings/Pledge';
import API from '../../utils/API';
import Notifications from '../../Pages/Notifications/notifications';
import Kpi from '../../Pages/Notifications/kpi';

import Teams from '../../Pages/Teams/teams';

import Mou from '../../Pages/Requests/mourequest';
import Tenancy from '../../Pages/Requests/tenancycontract';
import Commission from '../../Pages/Requests/commission';
import tenancyContractPdf from '../../Pages/Requests/tenancyContractPdf';
import All_Leads_By_Listing_ID from '../../Pages/Listings/All_Leads_By_Listing_ID';
import MatchedListLeads from '../../Pages/Listings/MatchedListLeads';
import All_Match_Listings from '../../Pages/Listings/All_Match_Listings';
import All_C_Match_Listings from '../../Pages/Listings/All_C_Match_Listings';
import Training from '../../Pages/Training/Training';
import SuperPrivate from '../protectRoutes/SuperPrivate';
import MatchedLeadLists from '../../Pages/Leads/MatchedLeadLists';
import { toast } from 'react-toastify';

import Pipeline from '../../Pages/Pipeline/Pipeline';
import Stock from '../../Pages/Listings/Stock';
import AreaStock from '../../Pages/Listings/AreaStock';

// Leads V2
import AllLeads from '../../Pages/Leads/Leads.js';
import AllLeadsDetails from '../../Pages/Leads/AllLeadsDetails';
import BroadCastEmail from '../../Pages/Listings/BroadCastEmail';
import DownloadPDF from '../../Pages/Listings/DownloadPDF';
import New_Leads from '../../Pages/Leads/New_Leads';
import New_LeadsDetails from '../../Pages/Leads/New_LeadsDetails';
import ArchiveListings from '../../Pages/Listings/ArchiveListings';
import Pending_Leads from '../../Pages/Leads/Pending_Leads.js';

// HR Platform
import HR_Dashboard from '../../Pages/HR/hr_dashboard.js';
import SharedListings from '../../Pages/Listings/SharedListings';

import Homepage from '../../Pages/Cms/Website/Homepage'
// import Pages from '../../Pages/Cms/Pages/AllPages'
// import AddPage from '../../Pages/Cms/Pages/AddPage'
// import Blogs from '../../Pages/Cms/Blog/AllBlogs'
// import AddBlog from '../../Pages/Cms/Blog/AddBlog'


import ListCommunity from '../../Pages/Locations/ListCommunity.js';
import ListSubCommunity from "../../Pages/Locations/ListSubCommunity.js";
import Property from '../../Pages/Locations/Property.js';
import ListTelephone from '../../Pages/Telephone/ListTelephone.js';

const { Header, Content, Footer } = Layout;

const Option = Select.Option;
const { TextArea } = Input;


const menu_data = (role) => {
  
  
  const items = [
    // {
    //   label: (
    //     <Link to="/dashboard">
    //       Dashboard
    //     </Link>
    //   ),
    //   key: 'dashboard',
    //   icon: <DashboardOutlined />,

    // },
    {
        label: 'Leads',
        key: 'leads',
        icon: <RiseOutlined />,
        children: [
          {
            label: (
              <Link to="/leads" onClick={() =>store.dispatch({ type: "REMOVE_LEAD_STATISTICS", payload: false })}>
                Leads
              </Link>
            ),
            key: 'lead:1',
          },
          // {
          //     label: (
          //       <Link to="/allleads">
          //         Leads V2
          //       </Link>
          //     ),
          //     key: 'lead:5',
            
          // // {
          // //   label: (
          // //     <Link to="/leads-pond">
          // //       Lead Pond
          // //     </Link>
          // //   ),
          // //   key: 'lead:2',
  
          // // },
          //   },
            {
            label: (
              <Link to="/share_property_requests">
                Viewing Requests
              </Link>
            ),
            key: 'lead:3',
  
          },
          role === ("Super Admin" || "Admin") && {
            label: (
                <Link to="/leads_redirect">
                  Leads Redirect
                </Link>
            ),
            key: 'lead:4',
  
          },
          {
              label: (
                <Link to="/pending_leads">
                  Pending Leads
                </Link>
              ),
              key: 'lead:5',
          }
          
          // role === ("Super Admin" || "Admin") && {
          //   label: (
          //       <Link to="/leads/:id">
          //         Leads Details Page
          //       </Link>
          //   ),
          //   key: 'lead:4',
  
          // },
        ],
      },
    {
      label: 'Listings',
      key: 'Vendors',
      icon: <HomeOutlined />,
      children: [
        // {
        //   label: (
        //     <Link to="/my_listings" onClick={() =>store.dispatch({ type: "REMOVE_LISTING_STATISTICS", payload: false })}>
        //       My Listings
        //     </Link>
        //   ),
        //   key: 'vendor:4',

        // },
        // {
        //   label: (
        //     <Link to="/all_listings">
        //       All Listings
        //     </Link>
        //   ),
        //   key: 'vendor:1',

        // },
        {
            label: (
              <Link to="/all_listings">
                All Listings 
              </Link>
            ),
            key: 'vendor:6',
  
          },
          {
            label: (
              <Link to="/archive_listings">
                Archived Listings 
              </Link>
            ),
            key: 'vendor:7',
  
          },
        role === ("Super Admin" || "Admin") && {
            label: (
              <Link to="/holding_bay">
                Holding Bay
              </Link>
            ),
            key: 'vendor:9',

          },

          role === ("Super Admin" || "Admin") && {
            label: (
              <Link to="/broadcast">
                Broadcast
              </Link>
            ),
            key: 'vendor:10',

          },
        // {
        //   label: (
        //     <Link to="/owner_listings">
        //       Owners List
        //     </Link>
        //   ),
        //   key: 'vendor:2',

        // },
        {
            label: (
              <Link to="/listing_process">
                Pending Listings
              </Link>
            ),
            key: 'vendor:3',
  
          },
          {
              label: (
                <Link to="/photography">
                  Photography Requests
                </Link>
              ),
              key: 'vendor:5',
    
            },

            //  {
            //   label: (
            //     <Link to="/shared/lists">
            //       Share Links
            //     </Link>
            //   ),
            //   key: 'vendor:11',
    
            // },
            
      ],
    },
    // role === ("Super Admin" || "Admin") && {
    //   label: 'Contacts',
    //   key: 'contacts',
    //   icon: <ContactsOutlined />,
    //   children: [
    //     {
    //       label: 'All contacts',
    //       key: 'contact:1',

    //     },
    //     {
    //       label: 'Buyers',
    //       key: 'contact:2',

    //     },
    //     {
    //       label: 'Seller',
    //       key: 'contact:3',

    //     },
    //   ],
    // },

    

    {
      label: 'Activity',
      key: 'viewings',
      icon: <CopyOutlined />,
      children: [
        {
          label: (
            <Link to="/all_listings_leads_viewings">
              Viewings
            </Link>
          ),
          key: 'viewings:1',

        },
        {

          label: (
            <Link to="/all_listings_leads_offers">
              Offers
            </Link>
          ),
          key: 'viewings:2',

        },
        {

          label: (
            <Link to="/followups">
              Follow Ups
            </Link>
          ),
          key: 'viewings:3',

        },
        {

          label: (
            <Link to="/deals">
              Deals
            </Link>
          ),
          key: 'viewings:4',

        },
        // {

        //   label: (
        //     <Link to="/pledge" onClick={() =>store.dispatch({ type: "REMOVE_PLEDGE", payload: false })}>
        //       Pledge
        //     </Link>
        //   ),
        //   key: 'viewings:5',

        // }
      ],
    },

    {
      label: (
        <Link to="/documents/lists">
          Documents
        </Link>
      ),
      key: 'document',
      icon: <FileOutlined />,

    },
    {
      label: 'Requests',
      key: 'requests',
      icon: <FileTextOutlined />,
      children: [
        // {
        //   label: (
        //     <Link to="/mourequests">
        //       MOU
        //     </Link>
        //   ),
        //   key: 'mourequests:1',

        // },
        {

          label: (
            <Link to="/tenancycontract">
              Tenancy Contract
            </Link>
          ),
          key: 'tenancy:2',

        },
        // { 
        //     label: (
        //       <Link to="/commission">
        //         Commission Claims
        //       </Link>
        //     ),
        //     key: 'tenancy:3',
  
        //   }
    
      ],
    },

    {
      label: (
        <Link to="/training">
          CRM Training
        </Link>
      ),
      key: 'training',
      icon: <YoutubeOutlined />,

    },
    {label: 'Reports',
        key: 'reports',
        icon: <FundProjectionScreenOutlined />,
        children: [
    {
      label: (
        <Link to="/kpi">
          KPI
        </Link>
      ),
      key: 'kpi',
      icon: <BarChartOutlined />,
    // },
    // {
    //   label: (
    //     <Link to="/stock">
    //       Stock
    //     </Link>
    //   ),
    //   key: 'report:8',

    // },
    // role === ("Super Admin" || "Admin") && {
    //   label: (
    //     <Link to="/areastock">
    //       Area Performance
    //     </Link>
    //   ),
    //   key: 'report:7',

    }]
    // }, {
    //   label: (
    //     <Link to="/notifications">
    //       Notifications
    //     </Link>
    //   ),
    //   key: 'notifications',
    //   icon:<BellOutlined />,
    },
    
    role === ("Super Admin" || "Admin") && {
        label: 'Users',
        key: 'users',
        icon: <UserOutlined />,
        children: [
  
         
          {
            label: (
              <Link to="/users">
                Users
              </Link>
            ),
            key: 'user:2',
  
          },
          {
              label: (
                <Link to="/teams">
                  Teams
                </Link>
              ),
              key: 'user:3',
    
            },
            {
                label: (
                  <Link to="/roles">
                    Roles
                  </Link>
                ),
                key: 'user:1',
      
              },
        ],
      },
    role === ("Super Admin" || "Admin") && {
        label: 'CMS',
        key: 'cms',
        icon: <SettingOutlined />,
        children: [
  
          {
            label: (
              <Link to="/homepage">
                Homepage
              </Link>
            ),
            key: 'cms:1',
  
          },
        //   {
        //     label: (
        //       <Link to="/blogs">
        //         Blogs
        //       </Link>
        //     ),
        //     key: 'cms:3',
        //   },
        //   {
        //     label: (
        //       <Link to="/pages">
        //         Pages
        //       </Link>
        //     ),
        //     key: 'cms:2',
        //   },
          
        ],
      },

      role === ("Super Admin" || "Admin") && {
        label: 'Locations',
        key: 'locations',
        icon: <SettingOutlined />,
        children: [
  
          {
            label: (
              <Link to="/location/community">
                Community
              </Link>
            ),
            key: 'location:1',
  
          },
          {
            label: (
              <Link to="/location/subcommunity">
                SubCommunity
              </Link>
            ),
            key: 'location:2',
          },
          {
            label: (
              <Link to="/location/property">
                Property
              </Link>
            ),
            key: 'location:3',
          },
          
        ],
      },
      {
        label: (<Link to="/service-provider"> Service Provider </Link>),
        key: 'location:4',
      },

    // {
    //     label: (
    //       <Link to="/pipeline">
    //         Pipeline
    //       </Link>
    //     ),
    //     key: 'pipeline',
    //     icon: <ProjectOutlined />,
  
    //   },
    //    {
    //     label: 'Reports',
    //     key: 'reports',
    //     icon: <FundProjectionScreenOutlined />,
    //     children: [
    //         {
    //             label: (
    //                 <Link to="/reports">
    //                     Custom Reports
    //                 </Link>
    //             ),
    //             key: 'report:1',

    //         },
    //         {
    //           label: (
    //               <Link to="/listing-preformance">
    //                 Listing Performance
    //               </Link>
    //           ),
    //           key: 'report:2',

    //       }
    //     ],
    // },
    // role === ("Super Admin" || "Admin") && {
    //     label: (
    //       <Link to="/hr">
    //         HR
    //       </Link>
    //     ),
    //     key: 'hr',
    //     icon: <TeamOutlined />,
  
    //   },

  ];
  return items;


}
const SideBar = () => {
  

  useEffect(() => {
  
    API.get("/get_last_pludge")
        .then((response) => {
          if (response.data.success) {
            setPludgeAvailable(true)
            setPludgeData(response.data.last_pledge)
            
          }else{
            setPludgeAvailable(false);
          }
        })
    return () => {
    };
  }, []);
  const { width } = UseViewport();
  const dispatch = useDispatch();
  const history = useHistory();

  const [mode, setMode] = useState(false)
  const [pludgeAvailable, setPludgeAvailable] = useState(false);
  const [pludgeData, setPludgeData] = useState([]);

  const [visible, setVisible] = useState(false)
  const [viewingvisible, setViewingVisible] = useState(false)
  const [ref_nums, setRef_nums] = useState([])
  const [clients, setClients] = useState([])
  const [viewing_feedbacks, setViewingFeedbacks] = useState([])
  const [target, setTarget] = useState("")
  const [deals, setDeals] = useState("")
  const [new_listings, setNew_listings] = useState("")
  const [new_viewings, setNew_viewings] = useState("")

  const [listing_1, setListing_1] = useState("")
  const [listing_2, setListing_2] = useState("")
  const [listing_3, setListing_3] = useState("")

  const [leads_1, setLeads_1] = useState("")

  const [leads_2, setLeads_2] = useState("")
  const [leads_3, setLeads_3] = useState("")

  const [input, setInput] = useState({})
  const [acceptviewingvisible, setAcceptViewingVisible] = useState(false)
  const [viewing_accept_pendings, setViewingAcceptPendings] = useState([])
  const [rejectedviewingformvisible, setRejectedViewingFormVisible] = useState(false)
  const [viewingid, setViewingID] = useState(0)
  const [rejectednote, setRejectedNote] = useState("")
  const [rejectloading, setRejectLoading] = useState(false)

  const [saving, setSaving] = useState(false)

  
  
  useEffect(() => {
   fetchMe()
   fetchViewingFeedback()
   fetchPendingViewingForAccept()
  }, []);

  const fetchMe = () => {
    API.get("/me")
      .then((response) => {
        const user = response.data.user
        if (response.data.succes) {
        //   setVisible(user.pledge == 0 ? true : false)
         if(user.logged_in ==1){
          if('caches' in window){
            caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                      console.log("caches",name);
                        caches.delete(name);
                         localStorage.clear();
                    })
                });
        
                // Makes sure the page reloads. Changes are only visible after you refresh.
                //window.location.reload(true);
            }
           // return
          dispatch({ type: "SET_LOGOUT" });
          window.location.href = "/";
         }

        }
      })
      .catch((err) =>{
        if(err && err.code == "ERR_BAD_REQUEST"){
          dispatch({ type: "SET_LOGOUT" });
          window.location.href = "/";
        }
       
      })
  }

  const fetchViewingFeedback = () => {
    API.get("/viewing_feedback")
      .then((response) => {


        const viewings = response.data.viewings


        if (response.data.success) {
          setViewingFeedbacks(viewings)
          setViewingVisible(viewings.length > 0 ? true : false)
        }
      })
  }

  const fetchPendingViewingForAccept = () => {
    const page = 1;
    const perPage = 10;
    API.post(`/viewing_accept_pending?page=${page}&perPage=${perPage}`)
      .then((response) => {


      
  
        if (response.data.success) {
          const viewings = response.data.viewings
          setAcceptViewingVisible(viewings.data.length > 0 ? true : false)
          setViewingAcceptPendings(viewings)
        }
      })
  }

  const handleNote = (e, viewing) => {

    let index = viewing_feedbacks.indexOf(viewing);


    var mydata = viewing_feedbacks;
    var i;
    for (i = 0; i < mydata.length; i++) {
      if (i == index) {
        mydata[i].note = e.target.value
        // mydata[i].basicbutton = true;
        // mydata[i].featuredbutton = false;
        // mydata[i].premiumbutton = false;
      }
    }
  }

  const handleStatus = (e, viewing) => {


    let index = viewing_feedbacks.indexOf(viewing);


    var mydata = viewing_feedbacks;
    var i;
    for (i = 0; i < mydata.length; i++) {
      if (i == index) {
        mydata[i].status = e
      }
    }
  }

  const handleViewingFeedbackOk = () => {
    const data = { viewing_feedbacks: viewing_feedbacks }

    API.post("/update_feedback", data)
      .then((response) => {
        setViewingVisible(false)
        fetchViewingFeedback()

      })
  }

  // for data 
  const handleTarget = (e) => {
    setTarget(e.target.value)

  }

  const handleDeal = (e) => {

    setDeals(e.target.value)
  }

  const handleNewListings = (e) => {

    setNew_listings(e.target.value)
  }

  const handleNewViewings = (e) => {

    setNew_viewings(e.target.value)
  }


  const onSearchRefNo = (e) => {


    if (!e || e == '' || e == null) {
      setRef_nums([])
      return
    }
    API.get("/get_listings/" + e)
      .then((response) => {
        if (response.data.success) {
          setRef_nums(response.data.ref_nums)
        }
      })
  }


  const handleListingOne = (e) => {

    if (!e || e == '' || e == null || e == undefined) {
      setRef_nums([])
      return
    }
    setListing_1(e)
  }
  const handleListingTwo = (e) => {
    if (!e || e == '' || e == null || e == undefined) {
      setRef_nums([])
      return
    }
    setListing_2(e)
  }
  const handleListingThree = (e) => {
    if (!e || e == '' || e == null || e == undefined) {
      setRef_nums([])
      return
    }
    setListing_3(e)
  }

  const onSearchClients = (e) => {

    if (!e || e == '' || e == null) {

      return
    }

    API.get("/get_leads_clients/" + e)
      .then((response) => {
        if (response.data.success) {
          setClients(response.data.clients)
        }
      })
  }

  const handleLeadsOne = (e) => {
    if (!e || e == '' || e == null || e == undefined) {
      setClients([])
      return
    }
    setLeads_1(e)
  }
  const handleLeadsTwo = (e) => {
    if (!e || e == '' || e == null || e == undefined) {
      setClients([])
      return
    }
    setLeads_2(e)
  }
  const handleLeadsThree = (e) => {
    if (!e || e == '' || e == null || e == undefined) {
      setClients([])
      return
    }
    setLeads_3(e)
  }

  const showModal = () => {
    setVisible(true)
  }
  const handleOk = () => {
    const data = {
      target: target, deals: deals,
      listings: new_listings, viewings: new_viewings,
      listing_1: listing_1, listing_2: listing_2,
      listing_3: listing_3, leads_1: leads_1,
      leads_2: leads_2, leads_3: leads_3
    }

if(clients.length > 0 && leads_1 == "" && leads_2 == "" && leads_3 == ""){
  toast.error("Select one client")
  return
}

if(ref_nums.length > 0 && listing_1 == "" && listing_2 == "" && listing_3 == ""){
  toast.error("Select one listing")
  return
}
    API.post("/pledge", data)
      .then((response) => {

        if (response.data.success) {
          //  this.props.setUser(response.data) 

          setVisible(false)
        }
      })



  }


  const validate = () => {
    let input = input;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter  name.";
    }

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }


    this.setState({
      errors: errors,
    });

    return isValid;
  }



  const handleCancel = (e) => {

    setVisible(false)
    fetchMe()
  }


  const handleViewingCancel = () => {
    setViewingVisible(false)
    fetchViewingFeedback()
  }

  const signOut = () => {
    dispatch({ type: "SET_LOGOUT" });
    //this.props.history.push("/");
  }

const handleCloseViewingRequest = () =>{
  setAcceptViewingVisible(false)
  fetchPendingViewingForAccept()
}
const handleAcceptViewing = (e,view_id)=>{
  
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Accept!",
}).then((result) => {
    if (result.isConfirmed) {
        API.put("/viewing_accepted/"+view_id )
            .then((response) => {
                if (response.data.success) {
                    toast.success("Accepted")
                    fetchPendingViewingForAccept()
                }
            })
    }
})

}
const handleRejectViewing = (e,view_id) =>{
  setRejectedViewingFormVisible(true)
  setViewingID(view_id)

}

const handleRejectNote = (e) =>{
  setRejectedNote(e.target.value)
}

const handleRejectedOk = () =>{
  if(!rejectednote || rejectednote == ""){
    toast.error("add your comment please")
    return
  }
  const data = {note:rejectednote}
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Reject!",
}).then((result) => {
    if (result.isConfirmed) {
      setRejectLoading(true)
      API.put("/viewing_rejected/"+ viewingid, data)
             .then((response) => {
                 if (response.data.success) {
                   setRejectedViewingFormVisible(false)
                      toast.success("Rejected")
                     fetchPendingViewingForAccept()
                     setRejectLoading(false)
 
                 }
             })
     
    }
})


  
  
}
const handleRejectedCancel = () =>{
  setRejectedViewingFormVisible(false)
}

const columns = [
  {
    title: 'Property',
    render: (text, record) => (
      <div className="info-group w-100 propertyinfo">
              <div className="d-flex flex-row justify-content-between">
                <p className="info-title">
                  {(record.unitno) && <span className="font-bold font-12">{record.unitno}</span>} {(record.unitno) && "|"} <span className="font-bold font-12">{record.refno}</span>
                </p>
              </div>
              <p>
                {record.property?record.property+",":""}
              {record.sub_community}, {record.community}
              </p>
            </div>
  ),
  },
  {
    title: 'View date',
    dataIndex: 'view_date',
    key: 'view_date',
  },
  {
    title: 'Requested by',
    dataIndex: 'display_name',
    key: 'display_name',
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  

  { 
    title: 'Actions',
     dataIndex:'actions',
    render: (text,record) => 
        <>
            <a className='btn btn-default' style={{color:'white'}} onClick={(e) =>handleAcceptViewing(e,record.views_id)} >Accept</a>
            <span className='mx-2'>|</span>
            <a className='btn btn-default' style={{color:'white'}} onClick={(e) =>handleRejectViewing(e,record.views_id)}>Reject</a>
        </>,
}
];

  const  isValidHttpUrl = (string) =>{
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }
  
 

  const userdata = store.getState((state) => state);
  let authenticated = userdata.auth.loggedIn;
  let role = userdata.auth.user.role;
  let user = userdata.auth.user;
  let role_name = userdata.auth.user.role_name;


  const purgeBtn = () => {
    setSaving(true)
    API.get("/purge_cache")
        .then((response) => {
          if (response.data.message) {
            toast.success(response.data.message)            
          }
        }).finally(() => {
            setSaving(false)
        });
  }
 

  const changeMode = () => {
    setMode(!mode)
  }

  const menu1 = (
    <Menu
      items={[

        {
          key: "2",
          label: (
            <Link to="/profile">
              My Profile
            </Link>
          ),
          icon: <UserOutlined />,
        },
        {
          key: "3",
          danger: true,
          label: (
            <Link to="signOut" onClick={signOut}>
              Sign Out
            </Link>
          ),
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );
  return (
    <div>
      {authenticated ? (
        <>

      <Modal width={600} height={600} title="Reason to reject" 
      open={rejectedviewingformvisible}
       onOk={handleRejectedOk} onCancel={handleRejectedCancel}
       confirmLoading={rejectloading}
       >
        <TextArea value={rejectednote} onChange={handleRejectNote} rows={4} placeholder="Note" maxLength={150} />
      </Modal>


<Modal width={1000} title="Listings viewing requests" 
open={acceptviewingvisible} 
// onOk={handleOk}
 onCancel={handleCloseViewingRequest}
footer={null}
>
<div style={{marginBottom: '20px', paddingTop: '20px', width:'100%'}}>
                            <Table 
                            //onChange={handleTableChange}
                             className='tableleads'
                                    //rowSelection={rowSelection} 
                                    //showHeader={false}
                                    // loading= {this.state.isLoading?
                                    //     { indicator: <img tyle={{width:'80px',height:'60px'}} src='images/opportunityloading.gif' /> }:false
                                    // }
                                    pagination={{
                                        pageSizeOptions: ['10', '20', '50', '100'],
                                        total:
                                            viewing_accept_pendings?.total > 0 &&
                                            viewing_accept_pendings.total,
                                        pageSize: viewing_accept_pendings.per_page,
                                        current: viewing_accept_pendings.current_page,
                                        showTotal: (total, range) =>
                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                        showSizeChanger: true,
                                        onShowSizeChange: onShowSizeChange,
                                        itemRender: itemRender,
                                    }}
                                    columns={columns}
                                    scroll={{ x: '1300'}}
                                    dataSource={
                                      viewing_accept_pendings?.data
                                            ? viewing_accept_pendings?.data
                                            : []
                                    }
                                 
                                />
                            </div>
      </Modal>

 
          {/*<Modal className='pledge' width={600} visible={visible} onOk={handleOk} okText={"Save"} onCancel={handleCancel} style={{ borderRadius: "0.3rem", overflow: 'hidden' }}>
            <div className='row title'>
              <h1 className="fs-6 font-bold text-uppercase mb-0">Pledge</h1>
              <p className="font-12">Complete the form below to access your dashboard.</p>
            </div>
            <div className='row d-flex flex-row w-100' >
              <div className='form-group col-6'>
                <label className="font-bold cont-12">AED Target For The Week <em>*</em></label>
                <input className="form-controls font-14" placeholder="Enter number" onChange={handleTarget} />
              </div>
              <div className='form-group col-6'>
                <label className="font-bold font-12">New Deals For This Week <em>*</em></label>
                <input className="form-controls font-14" placeholder="Enter number" onChange={handleDeal} />
              </div>
            </div>
            <div className='row d-flex flex-row w-100 pt-1'>
              <div className='form-group col-6'>
                <label className="font-bold font-12">New Listings For This Week <em>*</em></label>
                <input className="form-controls font-14" placeholder="Enter number" onChange={handleNewListings} />
              </div>
              <div className='form-group col-6'>
                <label className="font-bold font-12">New Viewings For This Week <em>*</em></label>
                <input className="form-controls font-14" placeholder="Enter number" onChange={handleNewViewings} />
              </div>
            </div>
            <div className='row d-flex flex-row w-100 pt-1'>
              <div className='form-group col-6'>
                <label className="font-bold font-12">Hottest Listings</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  className="mb-2 font-14"

                  showSearch
                  allowClear
                  placeholder="Select Refs no"
                  maxTagCount='responsive'
                  optionFilterProp="children"
                  onChange={handleListingOne}
                  onSearch={onSearchRefNo}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={ref_nums}
                />
                <Select
                  style={{
                    width: "100%",
                  }}
                  className="mb-2 font-14"

                  showSearch
                  allowClear
                  placeholder="Select Refs no"
                  maxTagCount='responsive'
                  optionFilterProp="children"
                  onChange={handleListingTwo}
                  onSearch={onSearchRefNo}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={ref_nums}
                />
                <Select
                  style={{
                    width: "100%",
                  }}
                  className="mb-2 font-14"

                  showSearch
                  allowClear
                  placeholder="Select Refs no"
                  maxTagCount='responsive'
                  optionFilterProp="children"
                  onChange={handleListingThree}
                  onSearch={onSearchRefNo}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={ref_nums}
                />
              </div>
              <div className='form-group col-6'>
                <label className="font-bold font-12 ">Hottest Clients</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  className="mb-2 font-14"

                  showSearch
                  allowClear
                  placeholder="Select leads client"
                  maxTagCount='responsive'
                  optionFilterProp="children"
                  onChange={handleLeadsOne}
                  onSearch={onSearchClients}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={clients}
                />
                <Select
                  style={{
                    width: "100%",
                  }}
                  className="mb-2 font-14"

                  showSearch
                  allowClear
                  placeholder="Select leads client"
                  maxTagCount='responsive'
                  optionFilterProp="children"
                  onChange={handleLeadsTwo}
                  onSearch={onSearchClients}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={clients}
                />
                <Select
                  style={{
                    width: "100%",
                  }}
                  className="mb-2 font-14"

                  showSearch
                  allowClear
                  placeholder="Select leads client"
                  maxTagCount='responsive'
                  optionFilterProp="children"
                  onChange={handleLeadsThree}
                  onSearch={onSearchClients}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={clients}
                />
              </div>
            </div>

            {/*last pledge information
            <div className='row title' style={{paddingBottom: 15}}>
              <h1 className="fs-6 font-bold text-uppercase mb-0">Last Pledge History</h1>
            </div>

            {pludgeAvailable == false && <div className='row d-flex flex-row w-100' >
              <div className='form-group col-12'>
                <p className="font-12">You do not have any pledge history.</p>
              </div>
            </div>}

            {pludgeAvailable == true && (
                <>
                  <div className='row d-flex flex-row w-100' >
                    <div className='form-group col-6'>
                      <label className="font-bold cont-12">Target</label>
                      <p>{(pludgeData && pludgeData['target'] != null) ? pludgeData['target'] : "-"}</p>
                    </div>
                    <div className='form-group col-6'>
                      <label className="font-bold font-12">Management Feedback</label>
                      <p>{(pludgeData && pludgeData['feed_back'] != null) ? pludgeData['feed_back'] : "-"}</p>
                    </div>
                  </div>
                  <div className='row d-flex flex-row w-100 pt-0'>
                    <div className='form-group col-6'>
                      <label className="font-bold font-12">Deals</label>
                      <p>{(pludgeData && pludgeData['deals'] != null) ? pludgeData['deals'] : "-"}</p>
                    </div>
                    <div className='form-group col-6'>
                      <label className="font-bold font-12">Deals Done</label>
                      <p>{(pludgeData && pludgeData['deals'] != null) ? pludgeData['deals_done'] : "-"}</p>
                    </div>
                  </div>

                  <div className='row d-flex flex-row w-100' >
                    <div className='form-group col-6'>
                      <label className="font-bold cont-12">Listings</label>
                      <p>{(pludgeData && pludgeData['listings'] != null) ? pludgeData['listings'] : "-"}</p>
                    </div>
                    <div className='form-group col-6'>
                      <label className="font-bold font-12">Listings Done</label>
                      <p>{(pludgeData && pludgeData['listings'] != null) ? pludgeData['listings_done'] : "-"}</p>
                    </div>
                  </div>
                  <div className='row d-flex flex-row w-100 pt-0'>
                    <div className='form-group col-6'>
                      <label className="font-bold font-12">Viewings</label>
                      <p>{(pludgeData && pludgeData['viewings'] != null) ? pludgeData['viewings'] : "-"}</p>
                    </div>
                    <div className='form-group col-6'>
                      <label className="font-bold font-12">Viewings Done</label>
                      <p>{(pludgeData && pludgeData['viewings'] != null) ? pludgeData['viewings_done'] : "-"}</p>
                    </div>
                  </div>
                </>
            )}

            {/*last pledge information

          </Modal>

          <Modal className='viewfeedback-modal' width={600} visible={viewingvisible} onOk={handleViewingFeedbackOk} okText={"Save"} onCancel={handleViewingCancel} style={{ borderRadius: "0.2rem", overflow: 'hidden' }}>
            <Row className='title w-100'>
              <Col span={24}>
                <h1 className="fs-6 font-bold text-uppercase mb-0">Viewing Feedback</h1>
                <p className="font-12">You have viewings that were completed without any feedback.<br />Please provide your feedback on completed viewings to access your dashboard.</p>
              </Col>
            </Row>
            <Row className='w-100'>
              <Col span={24}>
                {viewing_feedbacks.map((viewing) => (
                  <Row className='w-100 vfeedbackgroup'>
                    <Col span={10} className='vdetails'>
                      <p className='font-bold fs-6 mb-0'>{viewing.view_date}</p>
                      <p className='mb-0 font-12'>Client: {viewing.fullname}</p>
                      <p className='mb-0 font-12'>{viewing.listing_refno}</p>
                      <p className='mb-0 font-12'>{viewing.unitno},{viewing.community},{viewing.subcommunity},{viewing.property}</p>
                      {/* 2702 South Ridge 4, South Ridge, Downtown Dubai 
                    </Col>
                    <Col span={14}>
                      <div className='form-group d-flex flex-row align-items-center'>
                        <label className='mr-1 font-14' style={{ whiteSpace: 'nowrap' }}>Offer Status</label>

                        <Select
                          defaultValue={viewing.status}
                          style={{
                            width: 120,
                          }}
                          onChange={(e) => handleStatus(e, viewing)}
                          options={[
                            {
                              value: 'Offer',
                              label: 'Offer',
                            },
                            {
                              value: 'Scheduled',
                              label: 'Scheduled',
                            },
                            {
                              value: 'Second Viewing',
                              label: 'Second Viewing',
                            },
                            {
                              value: 'Not Interested',
                              label: 'Not Interested',
                            },
                            {
                              value: 'Considering',
                              label: 'Considering',
                            },
                          ]}
                        />
                      </div>
                      <div className='form-group'>
                        <TextArea rows={2} className="mt-1" onChange={(e) => handleNote(e, viewing)} placeholder='Enter feedback' />
                      </div>
                    </Col>
                  </Row>
                ))}

                {/* <Row className='w-100 vfeedbackgroup'>
                            <Col span={10} className='vdetails'>
                                <p className='font-bold fs-6 mb-0'>April 12, 2023 | 04:20 PM</p>
                                <p className='mb-0 font-12'>Client: Hussein</p>
                                <p className='mb-0 font-12'>LP18439</p>
                                <p className='mb-0 font-12'>2702 South Ridge 4, South Ridge, Downtown Dubai</p>
                            </Col>
                            <Col span={14}>
                                <div className='form-group d-flex flex-row align-items-center'>
                                    <label className='mr-1 font-14' style={{whiteSpace: 'nowrap'}}>Offer Status</label>
                                    <Select style={{ minWidth: 120 }} defaultValue='Scheduled'>
                                        <Option value="offer">Offer</Option>
                                        <Option value="scheduled" >Scheduled</Option>
                                        <Option value="secondviewing">Second Viewing</Option>
                                        <Option value="notinterested">Not Interested</Option>
                                        <Option value="considering">Considering</Option>
                                    </Select>
                                </div>
                                <div className='form-group'>
                                    <TextArea rows={2} className="mt-1" placeholder='Enter feedback'/>
                                </div>
                            </Col>
                        </Row>
                        <Row className='w-100 vfeedbackgroup'>
                            <Col span={10} className='vdetails'>
                                <p className='font-bold fs-6 mb-0'>April 12, 2023 | 04:20 PM</p>
                                <p className='mb-0 font-12'>Client: Hussein</p>
                                <p className='mb-0 font-12'>LP18439</p>
                                <p className='mb-0 font-12'>2702 South Ridge 4, South Ridge, Downtown Dubai</p>
                            </Col>
                            <Col span={14}>
                                <div className='form-group d-flex flex-row align-items-center'>
                                    <label className='mr-1 font-14' style={{whiteSpace: 'nowrap'}}>Offer Status</label>
                                    <Select style={{ minWidth: 120 }} defaultValue='Scheduled'>
                                        <Option value="offer">Offer</Option>
                                        <Option value="scheduled" >Scheduled</Option>
                                        <Option value="secondviewing">Second Viewing</Option>
                                        <Option value="notinterested">Not Interested</Option>
                                        <Option value="considering">Considering</Option>
                                    </Select>
                                </div>
                                <div className='form-group'>
                                    <TextArea rows={2} className="mt-1" placeholder='Enter feedback'/>
                                </div>
                            </Col>
                        </Row> */}
              {/* </Col>
            </Row>
          </Modal> */}

          <Header style={{ width: "100vw", backgroundColor: "white", paddingInline: '0', height:'unset' }}>
            <nav className="navbar bg-dark pl-2 py-2" data-bs-theme="dark">
              <img src="/images/LP-NExa-White-MWebsite.svg" className='mx-2' width= {window.isMobileView ?'120px' : window.isIpadView? '150px' :"300px" }/>
              {/** By Farooq */}
              {/* <Dropdown overlay={menu1}> */}
              <Dropdown overlay={menu1}  >
                <a onClick={(e) => e.preventDefault()} className='d-flex flex-row align-items-center' style={{cursor:'pointer'}}>
                  <Space>
                    {window.isMobileView? '' :
                    <p style={{ color: '#fff', marginBottom:0, textAlign:"right", whiteSpace:'nowrap' }}>Hello,  {user.name}</p>}
                   
                    <Avatar
                      className='d-flex'
                      size={40}
                      style={{
                        backgroundColor: "#87d068",
                        marginRight: "0",
                      }}
                      icon={
                        <img
                          src={ 
                            isValidHttpUrl(user.profile_photo)? user.profile_photo:
                            baseurlImg +
                            "lpcrm/public/uploads/clients/2036/users/" +
                            user.profile_photo
                          }
                          style={{
                            width: "55x",
                            height: "55px",
                            borderRadius: "10px",
                          }}
                        />
                      }
                    /><p></p>
                  </Space>
                </a>
              </Dropdown>
            </nav>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >

              <Menu mode="horizontal" items={menu_data(role_name)} />
              {/* PURGE CACHE */}
              {role_name === ("Super Admin" || "Admin") && <div style={{borderBottom:'1px solid rgba(5, 5, 5, 0.15)', paddingRight:'2rem'}}>
                <Button onClick={purgeBtn}>{saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Clearing cache...</> : <>Purge CDN Cache</>}</Button>
            </div>}

              {mode ? <a className="changemode" href='#' title='switch to dark mode' onClick={changeMode}>

                <img src='/images/dark-mode.png' className='mr-3' style={{ height: '30px' }} />
              </a>
                : <a href='#' className="changemode" title='switch to light mode' onClick={changeMode}>

                  <img src='/images/moon.png' className='mr-3' style={{ height: '30px' }} />
                </a>}

            </div>
          </Header>
          <Layout className="layout">
            <Content
              className="site-layout"
              style={{
                padding: '0 50px',
              }} >

              <div>

              <AdminPrivate exact path="/training" component={Training} />
                {/* <AdminPrivate exact path="/dashboard" component={Dashboard} /> */}
                {/* <AdminPrivate exact path="/my_listings" component={MyListings} /> */}
                {/* Farooq */}
                {/* <AdminPrivate exact path="/my_listings/:id" component={MyListings} /> */}

                {/* <AdminPrivate exact path="/all_listings" component={AllListings} /> */}
                <AdminPrivate exact path="/all_listings" component={AllListingsV2} />
                {/* Farooq */}
                <AdminPrivate exact path="/all_listings/:id" component={AllListingsV2} />
                <AdminPrivate exact path="/archive_listings" component={ArchiveListings} />
                {/* Farooq */}
                <AdminPrivate exact path="/archive_listings/:id" component={ArchiveListings} />

                {/* <AdminPrivate exact path="/archive_listings" component={ArchiveListings} /> */}
                {/* Farooq */}
                {/* <AdminPrivate exact path="/archive_listings/:id" component={ArchiveListings} /> */}

                <AdminPrivate exact path="/holding_bay" component={HoldingBay} />

                <AdminPrivate exact path="/all_match_listings/:id" component={All_Match_Listings} />

                <AdminPrivate exact path="/all_c_match_listings/:id" component={All_C_Match_Listings} />


                {/*Download Brochure*/}
                <AdminPrivate exact path="/preview_brochure/:id" component={PreviewBrochure} />

                <AdminPrivate exact path="/owner_listings" component={OwnerListings} />
                <AdminPrivate exact path="/matching_listings" component={Matching_Listing} />
                <AdminPrivate exact path="/restricted_leads" component={Restrict_Leads} />
                <AdminPrivate exact path="/restricted_leads/:id" component={Restrict_Leads} />
                <AdminPrivate exact path="/access_leads" component={Access_Leads} />

                <AdminPrivate exact path="/all_viewings/:id" component={All_Viewings} />

                <AdminPrivate exact path="/add_listing" component={AddListing} />
          

                {/* <AdminPrivate exact path="/leads" component={ListLeadsList} />
                <AdminPrivate exact path="/leads/:id" component={ListLeadsListDetail} /> */}
                <AdminPrivate exact path="/leads-pond" component={LeadsPond} />
                <AdminPrivate exact path="/leads-pond/:id" component={LeadsPond} />
                <AdminPrivate exact path="/share_property_requests" component={SharePropertyRequests} />
                <AdminPrivate exact path="/leads_redirect" component={LeadsRedirect} />
                {/* <AdminPrivate exact path="/leads-pond/:id" component={LeadsPond} /> */}

                <AdminPrivate exact path="/all_leads/:id" component={All_Leads_By_Listing_ID} />
                <AdminPrivate exact path="/matched_list_leads/:id" component={MatchedListLeads} />
                <AdminPrivate exact path="/matched_lead_lists/:id" component={MatchedLeadLists} />

                {/* All Leads - V2 */}
                <AdminPrivate exact path="/allleads" component={AllLeads} />
                <AdminPrivate exact path="/leads" component={New_Leads} />
                {/* <AdminPrivate exact path="/leads/:id" component={New_LeadsDetails} /> */}
                <AdminPrivate exact path="/leads/:id" component={AllLeadsDetails} />
                <AdminPrivate exact path="/pending_leads" component={Pending_Leads} />
                
                <AdminPrivate exact path="/pipeline" component={Pipeline} />

                {/* <AdminPrivate  exact path="/reports" component={Reports}/> */}

                <AdminPrivate exact path="/followups" component={Followups} />
                <AdminPrivate exact path="/deals" component={Deals} />
                <AdminPrivate exact path="/pledge" component={Pledge} />

                <AdminPrivate exact path="/edit_listing/:id" component={EditListing} />
                <AdminPrivate exact path="/all_lead_viewings/:id" component={All_Lead_Viewings} />

                <Route exact path="/profile" component={Profile} />

                {/* adminstration */}
                <SuperPrivate exact path="/addUser" component={AddUser} />
                <SuperPrivate exact path="/users" component={ListUsers} />
                <SuperPrivate exact path="/editUser/:id" component={EditUser} />
                <SuperPrivate exact path="/teams" component={Teams} />
                <SuperPrivate exact path="/broadcast" component={BroadCastEmail} />



                {/* Location */}
                  <SuperPrivate exact path="/community" component={ListCommunity} />
                  <SuperPrivate exact path="/sub_community" component={ListSubCommunity} />
                  <SuperPrivate exact path="/property" component={Property} />
                  <SuperPrivate exact path="/service-provider" component={ListTelephone} />



                <AdminPrivate exact path="/all_listings_leads_viewings" component={All_Listings_Leads_Viewings} />
                <AdminPrivate exact path="/all_listings_leads_offers" component={All_Listings_Leads_Offers} />

                <AdminPrivate exact path="/documents/lists" component={DocumentsList} />
                <AdminPrivate exact path="/shared/lists" component={SharedListings} />

                <SuperPrivate exact path="/roles" component={ListRoles} />
                <SuperPrivate exact path="/addRole" component={AddRole
                } />

                <AdminPrivate exact path="/notifications" component={Notifications} />
                <AdminPrivate exact path="/kpi" component={Kpi} />
                
                <AdminPrivate exact path="/mourequests" component={Mou} />
                <AdminPrivate exact path="/tenancycontract" component={Tenancy} />
                <AdminPrivate exact path="/commission" component={Commission} />
               
                <AdminPrivate exact path="/download_pdf/:id" component={DownloadPDF} />
                

                <AdminPrivate exact path="/listing_process" component={ListingProcess} />
                <AdminPrivate exact path="/photography" component={Photography} />
                <AdminPrivate exact path="/listing_process/:id" component={ListingProcess} />
                <AdminPrivate exact path="/stock" component={Stock} />
                <AdminPrivate exact path="/areastock" component={AreaStock} />

                {/* HR */}
                <AdminPrivate exact path="/hr" component={HR_Dashboard} />

                {/* CMS */}
                <AdminPrivate exact path="/homepage" component={Homepage} />
                {/* <AdminPrivate exact path="/pages" component={Pages} />
                <AdminPrivate exact path="/addpage" component={AddPage} />
                <AdminPrivate exact path="/blogs" component={Blogs} />
                <AdminPrivate exact path="/addblog" component={AddBlog} /> */}

                <AdminPrivate exact path="/location/community" component={ListCommunity} />
                <AdminPrivate exact path="/location/subcommunity" component={ListSubCommunity} />
                <AdminPrivate exact path="/location/property" component={Property} />



              </div >
            </Content >
            <Footer style={{ fontSize: '11px', padding: '24px' }}>
            LuxuryProperty.com Â© 2024 All Rights Reserved
            </Footer>

          </Layout >
        </>
      ) : (<Redirect to="/" />)}


    </div >
  )
}


export default SideBar