const initialState = {
    status: "",
    bedroom:"",
    community_id:"",
    subcommunity_id:"",
    property_id:"",
    property_for:[],
    agent_value:[],
    publish_date_morethan_option:false,
    publish_date_lessthan_option:false,
    publish_date_value_id:1,
    publish_date_value:"",
    added_date_morethan_option:false,
    added_date_lessthan_option:false,
    added_date_value_id:1,
    added_date_value:"",
    available_date_morethan_option:false,
    available_date_lessthan_option:false,
    available_date_value_id:1,
    available_date_value:"",
    listings_id:[]
  };
  const Stock_Reducer = (state = initialState, action) => {
    
    
    switch (action.type) {
      case "SET_STATUS":
        return {
          ...state,
          listings_id: action?.payload ? action?.payload.listings_id:[],
          status: action?.payload ? action?.payload.status:[],
          community_id: action?.payload ? action?.payload.community_id:"",
          subcommunity_id: action?.payload ? action?.payload.subcommunity_id:"",
          property_id: action?.payload ? action?.payload.property_id:"",
          property_for:action?.payload ? action?.payload.property_for:[],
          agent_value:action?.payload ? action?.payload.agent_value:[],
          publish_date_morethan_option:action?.payload ? action?.payload.publish_date_morethan_option:false,
          publish_date_lessthan_option:action?.payload ? action?.payload.publish_date_lessthan_option:false,
          publish_date_value_id:action?.payload ? action?.payload.publish_date_value_id:1,
          publish_date_value:action?.payload ? action?.payload.publish_date_value:"",
          added_date_morethan_option:action?.payload ? action?.payload.added_date_morethan_option:false,
          added_date_lessthan_option:action?.payload ? action?.payload.added_date_lessthan_option:false,
          added_date_value_id:action?.payload ? action?.payload.added_date_value_id:1,
          added_date_value:action?.payload ? action?.payload.added_date_value:"",
          available_date_morethan_option:action?.payload ? action?.payload.available_date_morethan_option:false,
          available_date_lessthan_option:action?.payload ? action?.payload.available_date_lessthan_option:false,
          available_date_value_id:action?.payload ? action?.payload.available_date_value_id:1,
          available_date_value:action?.payload ? action?.payload.available_date_value:"",
        };

        case "SET_BED":
            return {
              ...state,
              bedroom: action?.payload ? action?.payload.bedroom:"",
              community_id: action?.payload ? action?.payload.community_id:"",
              subcommunity_id: action?.payload ? action?.payload.subcommunity_id:"",
              property_id: action?.payload ? action?.payload.property_id:"",
            };
            case "SET_PROPERTY_FOR":
              return {
                ...state,
                property_for: action?.payload ? action?.payload.property_for:[]
              };

              case "SET_AGENT":
                return {
                  ...state,
                  agent_value: action?.payload ? action?.payload.agent_value:[]
                };

            case "REMOVE_STOCK_REDUCER":
                return {
                  ...state,
                  listings_id:[],
                  status: "",
                  bedroom: "",
                  community_id: "",
                  subcommunity_id: "",
                  property_id: "",
                  property_for:[],
                  agent_value:[],
                  publish_date_morethan_option:false,
                  publish_date_lessthan_option:false,
                  publish_date_value_id:1,
                  publish_date_value:"",
                  added_date_morethan_option:false,
                  added_date_lessthan_option:false,
                  added_date_value_id:1,
                  added_date_value:"",
                  available_date_morethan_option:false,
                  available_date_lessthan_option:false,
                  available_date_value_id:1,
                  available_date_value:"",
                };


            
                    
      default:
        return state;
    }
  };
  
  export default Stock_Reducer;
  

  