import React, { Component } from 'react'

import { Col, Row, Modal, Button, Table, Space, Dropdown, Input, Checkbox, Switch } from 'antd';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import { ToastContainer, toast } from "react-toastify";

import Moment from 'react-moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ApiCalendar from "react-google-calendar-api";
import { UserAddOutlined, UsergroupAddOutlined, DownOutlined, DesktopOutlined, MobileOutlined } from '@ant-design/icons';

import './teams.css'
import API from '../../utils/API';
const CheckboxGroup = Checkbox.Group;
let columns = []
let data = []


const items = [
    { key: '1', label: 'Super Admin', },
    { key: '2', label: 'Sales Agent', },
    { key: '3', label: 'Editor' },
    { key: '4', label: 'Admin' },
    { key: '5', label: 'Sales Director' },
    { key: '6', label: 'External Agent' },
    { key: '7', label: 'Listing Manager' },
    { key: '8', label: 'Senior Sales Agent' },
    { key: '9', label: 'Rental Agent' },
    { key: '10', label: 'Senior Rental Agent 	' }
];
const teamgroup = [
    { value: '1', label: 'Sales', },
    { value: '2', label: 'Rental Apartments', },
    { value: '3', label: 'Rental Villas' },
];
const plainOptions = ['Mark Castley', 'Mark Richards', 'Simon Boden', 'Alex Eady', 'Carlos Cruz', 'Ryan Coleman'];

function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
}


class Teams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            teams: [],
            team_users: [],
            isaddTeamOpen: false,
            isaddUserOpen: false,
            selectedAgents: [],
            team_name: ""
        }
    }

    componentDidMount() {
        //this.fetchAgent();
        const page = 1;
        const perPage = 10;

        this.fetchTeam()
        this.fetchData(page, perPage)

    }

    fetchTeam = () => {
        API.get("/teams")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ teams: response.data.teams })
                }
            })
    }


    fetchData = (page, perPage) => {
        this.setState({ isLoading: true })
        API.post(`/teams?page=${page}&perPage=${perPage}`)
            .then(async (response) => {
                await response.data.teams;
                if (response.data.success) {
                    this.setState({ team_users: response.data.teams, isLoading: false })
                }

            })


    }

    fetchAgent = () => {
        API.get("/all_agents")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ agents: response.data.agents })
                }
            }
            )
    }

    handleAddTeamOpen = () => {
        this.setState({ isaddTeamOpen: true })
    }
    handleAddUserOpen = () => {
        this.setState({ isaddUserOpen: true })
    }
    handleOk = (e) => {
        const page = 1
        const perPage = 10
        const data = { selectedAgents: this.state.selectedAgents, team_name: this.state.team_name }
        API.post("/create_teams", data)
            .then((response) => {
                if (response.data.success)
                    this.fetchData(page, perPage)
                this.fetchTeam()
            })

        this.setState({
            isaddTeamOpen: false, isaddUserOpen: false,
        });
    }
    handleCancel = (e) => {
        this.setState({
            isaddTeamOpen: false, isaddUserOpen: false,
        });
    }
    handleGroupName = (e) => {
        this.setState({ team_name: e.target.value })
    }

    handleSearchName = (e) => {
        if (e.target.value == null || e.target.value == "" || e.target.value == undefined) {
            //this.setState({ agents: [] })
            return
        }
        API.get("/get_team/" + e.target.value)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ agents: response.data.agents })
                }
            })
    }
    handleUsers = (e) => {
        this.setState({ selectedAgents: e })
    }
    handleRole = (record, e) => {

        let index = this.state.team_users.data.indexOf(record);

        var mydata = this.state.team_users.data;
        var i;
        for (i = 0; i < mydata.length; i++) {
            if (i == index) {
                mydata[i].role_id = e.target.value;

            }
        }
        this.setState({})
        const user_id = record.user_id
        const data = { role_id: e.target.value }
        API.put("/changeRole/" + user_id, data)
            .then((response) => {
                if (response.data.success) {
                    toast.success("Successfully Updated")
                }
            })
    }

    handleTeam = (record, e) => {
        console.log(e.target.value);


        let index = this.state.team_users.data.indexOf(record);

        var mydata = this.state.team_users.data;
        var i;
        for (i = 0; i < mydata.length; i++) {
            if (i == index) {
                mydata[i].team_id = e.target.value;

            }
        }
        this.setState({})
        const user_id = record.user_id
        const data = { team_id: e.target.value }
        API.put("/changeTeam/" + user_id, data)
            .then((response) => {
                console.log("team", response.data);
                if (response.data.success) {
                    toast.success("Successfully Updated")
                }
            })
    }
    getCurrentTime = (date) => {
        if (date != null && date !== "" && date !== undefined) {
            let newDate = new Date(date);
            if (isNaN(newDate.getTime())) {
                console.error("Invalid date format:", date);
                return null;
            }
            newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
            console.log("New date:", newDate);

            return newDate.toISOString();
        } else {
            return null;
        }
    }

    render() {
        columns = [
            {
                title: 'Name',
                dataIndex: 'display_name',
                key: 'display_name'
            },
            {
                title: 'Role',
                // dataIndex: 'role',
                render: (text, record) => (
                    <Space size="middle">
                        <select className='form-select' value={record.role_id} onChange={this.handleRole.bind(this, record)}>
                            {items.map((item) => (
                                <option value={item.key}>{item.label}</option>
                            ))}

                        </select>

                    </Space>
                ),
            },
            {
                title: 'Team',
                //dataIndex: 'team',
                render: (text, record) => (
                    <Space size="middle">
                        <select className='form-select' value={record.team_id} onChange={this.handleTeam.bind(this, record)}>
                            <option value="0"> </option>
                            {this.state.teams.map((team) => (
                                <option value={team.value}>{team.label}</option>
                            ))}

                        </select>

                    </Space>
                ),
            },
            {
                title: 'Phone',
                dataIndex: 'mobile',
            },
            {
                title: 'Last seen',
                dataIndex: 'lastseencol',
                render: (text, record) => (
                    <Space className='d-flex flex-column' align='left'>
                        <span className='d-flex flex-row font-12 align-items-center' style={{ gap: '0.6rem' }}><DesktopOutlined />Web:
                            {
                                record.web_login != null && record.web_login != "" && record.web_login != undefined ? <Moment fromNow>

                                    {(record.web_login) ? this.getCurrentTime(record.web_login) : ""}
                                </Moment> : "Never"
                            }</span>
                        <span className='d-flex flex-row font-12 align-items-center' style={{ gap: '0.6rem' }}><MobileOutlined />Mobile:    {
                            record.mobile_login != null && record.mobile_login != "" && record.mobile_login != undefined ? <Moment fromNow>

                                {(record.mobile_login) ? this.getCurrentTime(record.mobile_login) : ""}
                            </Moment> : "Never"
                        }</span>

                    </Space>
                )
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (text, record) => (
                    <>
                        {text == "Y" ? "Active" : "Inactive"}
                    </>
                )
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                render: () =>
                    <>
                        <a>Edit</a>
                        <span className='mx-2'>|</span>
                        <a>Remove</a>
                    </>,
            }
        ]


        const responsiveColumns = [

            {
                title: '', dataIndex: '',
                render: (text, record) => {

                    return (
                        <div className="TableCard">

                            <div className='row alignCenter headerGrayBgColor borderBottom'>
                                <div className='col-6'>
                                    <div className='HeaderMainLeft'>
                                        <div className='info-group'>

                                            <div className='d-flex gap-1 BodyFirstRowRight'>
                                                <p style={{fontSize: window.isMobile576 ? '10px' : ''}}>{record.display_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>

                                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                                        <p className='info-title'>Status:</p>
                                        <p> {text == "Y" ? "Active" : "Inactive"}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='row alignCenter borderGray pt-2 pb-2'>

                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight selectColumn'>

                                            <p className='info-title'>Role:</p>
                                            <p className="font-12 w-100"> <Space size="middle" className='w-100' >
                                                <select className='form-select' style={{fontSize : window.isMobileView ? '10px' : ''}} value={record.role_id} onChange={this.handleRole.bind(this, record)}>
                                                    {items.map((item) => (
                                                        <option value={item.key}>{item.label}</option>
                                                    ))}

                                                </select>

                                            </Space></p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight selectColumn'>

                                            <p className='info-title'>	Team:</p>
                                            <p className="font-12 w-100" > <Space size="middle"  className="w-100">
                                                <select className='form-select' style={{fontSize : window.isMobileView ? '10px' : ''}} value={record.team_id} onChange={this.handleTeam.bind(this, record)}>
                                                    <option value="0"> </option>
                                                    {this.state.teams.map((team) => (
                                                        <option value={team.value}>{team.label}</option>
                                                    ))}

                                                </select>

                                            </Space></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>

                                            <p className='info-title'>Phone:</p>
                                            <p className="font-12">{record.mobile}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>

                                            <p className='info-title'>Last seen:</p>
                                            <p className="font-12"> <Space className='d-flex flex-column' align='left'>
                                                <span className='d-flex flex-row font-12 align-items-center' style={{ gap: '0.6rem' }}><DesktopOutlined />Web:
                                                    {
                                                        record.web_login != null && record.web_login != "" && record.web_login != undefined ? <Moment fromNow>

                                                            {(record.web_login) ? this.getCurrentTime(record.web_login) : ""}
                                                        </Moment> : "Never"
                                                    }</span>
                                                <span className='d-flex flex-row font-12 align-items-center' style={{ gap: '0.6rem' }}><MobileOutlined />Mobile:    {
                                                    record.mobile_login != null && record.mobile_login != "" && record.mobile_login != undefined ? <Moment fromNow>

                                                        {(record.mobile_login) ? this.getCurrentTime(record.mobile_login) : ""}
                                                    </Moment> : "Never"
                                                }</span>

                                            </Space></p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 justify-content-center BodyFirstRowRight'>
                                            <div className='d-flex flex-row align-items-baseline'>
                                                <a>Edit</a>
                                                <span className='mx-2'>|</span>
                                                <a>Remove</a>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                            </div>


                        </div >
                    )
                }
            },
        ]
        const handleTableChange = (pagination, filters) => {



            this.fetchData(pagination.current, pagination.pageSize);

        };
        // data = [
        //     {key: '1',name: 'Mark Castley', role:'Super Admin', team:'admin', phone:'+9715123456789',lastseencol:'test',status:'Active', actions: ''},
        //     {key: '2',name: 'Carlos Cruz', role:'Agent', team:'Sales', phone:'+9715123456789',lastseencol:'test',status:'Active', actions:'' },
        //     {key: '3',name: 'Ryan James Paul Coleman', role:'Agent', team:'Sales', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '4',name: 'Claire Towle', role:'Agent', team:'Sales', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '8',name: 'Mary Borghosizian', role:'Agent', team:'Rental Apartments', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '9',name: 'Jake Doolan', role:'Agent', team:'Rental Apartments', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '12',name: 'Anna Arxe', role:'Agent', team:'Rental Apartments', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '13',name: 'Suleyman Ahmed', role:'Agent', team:'Rental Villa', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '14',name: 'Ellie Chambers', role:'Agent', team:'Rental Villa', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '15',name: 'Liam Ure', role:'Agent', team:'Rental Villa', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        //     {key: '16',name: 'Cameron Gwinnett', role:'Agent', team:'Rental Villa', phone:'+9715123456789',lastseencol:'',status:'Active', actions:'' },
        // ]
        return (
            <>
                <ToastContainer />
                <Row className='teams-cont'>
                    <Col span={24}>
                        <Row className='contentwrap justify-content-between'>
                            <Col>
                                <h1 className='font-bold fs-2'>Teams</h1>
                            </Col>
                            <Col className='d-flex flex-row' style={{ gap: "0.6rem" }}>
                                <Button type="primary" onClick={this.handleAddTeamOpen} icon={<UsergroupAddOutlined />}>Add Team</Button>
                                <Button type="primary" onClick={this.handleAddUserOpen} icon={<UserAddOutlined />} >Add User</Button>
                            </Col>
                        </Row>
                        <Row className='contentwrap'>
                            <Col span={24}>
                                {/* <Table className='border' columns={columns} dataSource={data} /> */}
                                <Table
                                    onChange={handleTableChange}

                                    className={`${window.isTabView ? 'TableborderNoneMain' : ''}`}
                                    loading={this.state.isLoading}
                                    //className="table-striped table-bordered"
                                    pagination={{
                                        pageSizeOptions: ['10', '20', '50', '100'],
                                        total:
                                            this.state?.team_users?.total > 0 &&
                                            this.state.team_users.total,
                                        pageSize: this.state.team_users.per_page,
                                        current: this.state.team_users.current_page,
                                        showTotal: (total, range) =>
                                            window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                        showSizeChanger: true,
                                        onShowSizeChange: onShowSizeChange,
                                        itemRender: itemRender,
                                    }}
                                    style={{ overflowX: window.isMobileView ? '' : "auto" }}
                                    columns={window.isIpadView? responsiveColumns : columns}
                                    bordered
                                    dataSource={
                                        this.state?.team_users?.data
                                            ? this.state?.team_users?.data
                                            : []
                                    }

                                    //    rowKey={(record) => record}
                                    // Change By Farooq
                                    rowKey={(record) => record.id}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Modals */}
                <Modal width={600} className='addteamwrap' title="Add Team" onOk={this.handleOk} onCancel={this.handleCancel} open={this.state.isaddTeamOpen}>
                    <>
                        <div className='form-group w-100'>
                            <input type="text" placeholder="Enter group name" className='w-100' onChange={this.handleGroupName} />
                        </div>
                        <Row className='border w-100'>
                            <Col span={24} className='usertitle'>
                                <Row className='border'>
                                    <Col span={2}>&nbsp;</Col>
                                    <Col span={16} className='py-2'><span>Name</span></Col>
                                    <Col span={4}>&nbsp;</Col>
                                </Row>
                                <Row className='border'>
                                    <Col span={24}>
                                        <input type="text" placeholder="Search name" className='w-100 seaechteam' onChange={this.handleSearchName} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <CheckboxGroup options={this.state.agents} className='d-flex flex-column w-100' onChange={this.handleUsers} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                </Modal>
                <Modal title="Add User" className='addteamuser' onOk={this.handleOk} onCancel={this.handleCancel} open={this.state.isaddUserOpen}>
                    <div className='w-100'>
                        <div className='form-group'>
                            <label>Name</label>
                            <input id="name" />
                        </div>
                        <div className='form-group'>
                            <label>Email</label>
                            <input id="email" />
                        </div>
                        <div className='form-group'>
                            <label>Password</label>
                            <input id="password" />
                        </div>
                        <div className='form-group'>
                            <label>Confirm Password</label>
                            <input id="cpassword" />
                        </div>
                        <div className='form-group'>
                            <label>Role</label>
                            <input id="role" />
                        </div>
                        <div className='form-group'>
                            <label>Team</label>
                            <input id="team" />
                        </div>
                        <div className='form-group d-flex flex-row align-items-center'>
                            <label className='mr-2'>Active/Inactive</label>
                            <Switch defaultChecked={false} />
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default Teams;
