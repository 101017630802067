

const BrochurePdfOne = (data) => {
    const listing = data? data.data : []
    const listingimage = listing.property_images.split(',');
    
    const SuperAdmin = ['Christian', 'Kamela'];
    let agentname, agentpic, agentemail, agentmobile
    if (SuperAdmin.includes(listing.agent_name.split(' ')[0])) {
        agentname = listing.listing_agent_name
        agentpic = listing.listing_agent_picture
        agentemail = listing.listing_agent_email
        agentmobile = listing.listing_agent_mobile
    } else {
        agentname = listing.agent_name
        agentpic = listing.profile_photo
        agentemail = listing.email
        agentmobile = listing.mobile
    }

    return (
        <>
        <style>{`
            @page { size: a4 ;margin: 0in;padding:0}
            @media print{@page {size: portrait}; div {print-color-adjust: exact;}}
            @font-face { font-family: Poppins; src: url(/fonts/Poppins-Regular.ttf); font-weight:200 }
            @font-face { font-family: Cormorant Garamond; src: url(/fonts/cormorantgaramond-regular-webfont.woff); }

            .flyerbrochure { width:1080px; }
            .flyerbrochure .pagecontainer { background:#FFF; width:1080px; z-index:2; position:relative; overflow:hidden; margin-bottom:2rem}
            .flyerbrochure img {object-fit:cover; height:100%; width:100%}
            .flyerbrochure * {font-family: 'Poppins', sans-serif; font-size: 16px; margin:0; line-height:1.8}            
            .flyerbrochure .bgimage { position: absolute; z-index: 1; height:100%; width:100%; overflow:hidden; max-height: 1080px;}
            .flyerbrochure .bcontainer {  z-index:2; position:relative; overflow:hidden; display:flex; flex-direction:row; height:100%; width:100%}
            .flyerbrochure .poppins-semibold {font-family: "Poppins", sans-serif; font-weight: 600;font-style: normal; font-size:16px;}
            .flyerbrochure .poppins-bold {font-family: "Poppins", sans-serif;font-weight: 700;font-style: normal; font-size:18px}
            .flyerbrochure .cormorant-semibold { font-family: "Cormorant Garamond", serif; font-optical-sizing: auto; font-weight: 600; font-style: normal;}
            .flyerbrochure .cormorant-bold { font-family: "Cormorant Garamond", serif; font-optical-sizing: auto; font-weight: 700; font-style: normal; font-size:21px}
            .flyerbrochure .header {display:flex; width: 100%; justify-content:space-between; align-items: center; margin:0.6rem 0}
            .flyerbrochure .brochuredesc p{ font-size:8pt}

            .flyerbrochure .col-2 {display:flex; max-width: 20%; flex: 0 0 20%;}
            .flyerbrochure .col-3 {display:flex; max-width:25%; flex: 0 0 25%}
            .flyerbrochure .col-4 {display:flex; max-width: 32.33%; flex: 0 0 32.33%;}        
            .flyerbrochure .col-5 {display:flex; max-width: 41.66%; flex: 0 0 41.66%;}     
            .flyerbrochure .col-6 {display: flex; max-width: 50%; flex: 0 0 50%;}
            .flyerbrochure .col-7 {display: flex; max-width: 58.33%; flex: 0 0 58.33%;}
            .flyerbrochure .col-8 {max-width: 66.66%; flex:66.66%}
            .flyerbrochure .col-9 {max-width: 75%; flex:75%}
            .flyerbrochure .col-10 {max-width:83.33%; flex:83.33%}
            .flyerbrochure .col-12 {max-width:100%; flex:100%}
            .flyerbrochure .flex-column {flex-direction: column;}
            .flyerbrochure .flex-row {flex-direction: row;}
        `}</style>

        <div className="flyerbrochure">
            {/* First Page */}
            <div className="pagecontainer">
                <div className="bgimage"><img src="brochure_assets/backgroundDigital.png" style={{height:'900pt', zIndex:2}} /></div>
                <div className="col-12 px-0" style={{overflow:'hidden', maxHeight: '297mm', zIndex:999}}>
                    <img src={'https://www1.luxuryproperty.com/' + listingimage[0] + '?nowm'} width="auto" height="200mm" style={{objectFit: 'cover', position:'relative', left:'50%', transform: 'translateX(-50%)', height:'100%'}}/>
                </div>
                <div className="col-12 d-flex flex-row px-0" style={{zIndex:999}}>
                    <div className="col-5 d-flex flex-column" style={{background:'#DBDBDB', padding:'1.5rem 2rem', marginTop:'-8rem'}}>
                        <img src={'https://www1.luxuryproperty.com/' + listingimage[1] + '?nowm'} width="200pt" height="792.5pt" style={{objectFit: 'cover', position:'relative', left:'50%', transform: 'translateX(-50%)', height:'100%', marginBottom:'1rem'}}/>
                        <img src={'https://www1.luxuryproperty.com/' + listingimage[2] + '?nowm'} width="auto" height="792.5pt" style={{objectFit: 'cover', position:'relative', left:'50%', transform: 'translateX(-50%)', height:'100%'}}/>
                    </div>
                    <div className="col-7 d-flex flex-column" style={{padding:'2rem'}}>
                        <p style={{fontSize:18, fontWeight:300, textTransform:'uppercase', letterSpacing:'9px'}}>{'For ' + listing.property_for}</p>
                        <p style={{fontSize:32, fontWeight:800, lineHeight:'1.4'}}>{listing.subcommunity}</p>
                        <p style={{fontSize:21}}>{listing.community}</p>
                        <div className="col-12 d-flex flex-row justify-content-between" style={{padding:0}}>
                            <div className="col-9 d-flex flex-column" style={{padding:0}}>
                                <div className="d-flex flex-row justify-content-between" style={{width:'100%', marginTop:'2rem'}}>
                                    {listing.price && <div className="d-flex flex-column" style={{width:'66.66%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>AED {listing.price?listing.price.replace(/\B(?=(\d{3})+(?!\d))/g, ','):''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Price</span>
                                        </p>
                                    </div>}
                                    {listing.refno && <div className="d-flex flex-column" style={{width:'33.33%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.refno?listing.refno:''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Ref No</span>
                                        </p>
                                    </div>}
                                </div>
                                <div className="d-flex flex-row justify-content-between" style={{width:'100%', marginTop:'2rem'}}>
                                    {listing.bedrooms && ( <div className="d-flex flex-column" style={{width:'33.33%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.bedrooms?listing.bedrooms:''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Bedrooms</span>
                                        </p>
                                    </div>)}
                                    {listing.bathrooms && ( <div className="d-flex flex-column" style={{width:'33.33%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.bathrooms?listing.bathrooms:''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Bathroom</span>
                                        </p>
                                    </div>)}
                                    {listing.bua && ( <div className="d-flex flex-column" style={{width:'33.33%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.bua ? listing.bua.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' SQ. FT.' : ''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Built Up Area</span>
                                        </p>
                                    </div>)}
                                </div>
                                
                            </div>
                            {listing.qr_code && <div className="d-flex align-items-end">
                                <img src={'https://www1.luxuryproperty.com/' + listing.qr_code} width="130px" height="130px" style={{objectFit:'unset !important', height:'130px', width:'130px'}}/>
                            </div> }
                        </div>
                    </div>
                </div>
                <div style={{background:'#151515', display:'flex', alignItems:'center', width:'100%', justifyContent:'center', padding:'0.8rem 0', zIndex:999}}>
                    <img src={agentpic} height={'100px'} width={'100px'} style={{height:'100px', width:'100px', borderRadius:'50%'}} />
                    <div className="col-7 d-flex flex-column" >
                        <p style={{color:'#FFF', fontSize:14}}>For details, please contact:</p>
                        <p style={{color:'#FFF', fontSize:18, fontWeight:'700'}}>{agentname}</p>
                        <p style={{color:'#FFF', fontSize:14}}>{agentmobile}</p>
                        <p style={{color:'#FFF', fontSize:14}}>{agentemail}</p>
                    </div>
                    <img src="brochure_assets/Lp-Logo-2021-White-Web.png" style={{height:'30px', objectFit:'cover', width:'300px'}}  />


                </div>
            </div>

        </div>
        </> 
    )
}

export default BrochurePdfOne