import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";

import 'antd/dist/reset.css';
import {Table,Breadcrumb,DatePicker,Collapse,Tabs,Space,Button,Modal,Drawer, Select, Input,Avatar,Upload,Image,Divider} from 'antd'
import {CloseOutlined,PlusCircleOutlined,EditFilled,FilterFilled,EditOutlined,UploadOutlined, PlusOutlined, SearchOutlined, CaretDownOutlined} from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EdiText from "react-editext";
import Swal from "sweetalert2";
// import './index.css'
import './listing.css'
import { baseLpUrlImg, baseurlImg } from '../../components/BaseUrl';
import {store} from "../../store";

const { Dragger } = Upload;
const {TextArea} = Input
const {TabPane} =Tabs;
const { RangePicker } = DatePicker;

let columns =[]
let property_type_array = ["Residential","Commercial"]
let property_for_array = ["sale","rent","tenanted","short-term"]
class All_Viewings extends Component {

    constructor(props){
        super(props);
        this.state ={
            isLoading:false,
            all_viewings:[],
            date_range:"",
            date_range_status:null,
            viewings: {},
            showPrice:false,
            showDate:false,
            view_id:"",
            viewing_note: "",
            viewing_price: "",
          
        }

        this.textInput = React.createRef();
        
    }

    componentDidMount() {

      var loadScript = function(src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName('body')[0].appendChild(tag);
        }
     loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')

        const page = 1;
        const perPage = 10;
        const data = {
          id:this.props.match.params.id,
          date_range:this.state.date_range,
          date_range_status:this.state.date_range_status
        }

        this.fetchData(page, perPage,data);
       
    }

    handleCancel = () =>{
        this.setState({isView:false})
    }

    handleChangeStatus = () =>{
        const page = 1
        const perPage= 10

        const data = {
            date_range_status:this.state.date_range_status,
            date_range:this.state.date_range,
            community_filter:this.state.community_filter,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:this.state.property_filter,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
            status_filter:this.state.status_filter,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            client_value_id: this.state.client_value_id,
            client_value: this.state.client_value,
        }

        const data2 = {  view_id: this.state.view_id,viewing_date:this.state.viewing_date, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price,note:this.state.viewing_note }
        API.post("/change_all_viewing_status", data2)
            .then((response) => {
                if(response.data.success)
                    this.fetchData(page, perPage,data)
            })
        this.setState({isView:false})
    }

    handleViewingDate = (date, dateString) => {
        this.setState({ viewing_date: dateString })
    };

    handleViewStatus = (e) => {
        const status = e.target.value
        this.setState({ viewing_status: e.target.value })
        if (status == "Offer") {
            this.setState({ showPrice: true })
        } else {
            this.setState({ showPrice: false })
        }

        if (status == "Second Viewing") {
            this.setState({ showDate: true })
        } else {
            this.setState({ showDate: false })
        }
    }

    handleViewingPrice = (e) => {
        this.setState({ viewing_price: e.target.value })
    }

    handleViewingNote = (e) => {
        this.setState({ viewing_note: e.target.value })
    }

    handleViewRefNo = (view_id,e) =>
    {
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {
                    console.log("re", response)
                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Second Viewing" ? true : false,
                        viewing_price: response.data.viewings.amount, isView: true,
                        view_id:view_id
                    })
                }

            })


    }

    
    fetchData = (page, perPage,data) => {
        this.setState({isLoading:true})
       setTimeout(() => {
          
        
        API.post(`/all_viewings?page=${page}&perPage=${perPage}`,data)
             .then(async (response)  =>{
            
                var data = await response.data.all_viewings.data;

                await response.data.all_viewings;
                this.setState({
                    all_viewings: response.data.all_viewings,
                    isLoading: false
                })

             })
            }, 200);

    }

    handleRangeDate = (value, dateString) =>{
           
      this.setState({date_range:dateString,date_range_status: value !=null?1:null})
      const page = 1;
      const perPage = 10;

      const data = {
        id:this.props.match.params.id,
          date_range_status:value !=null?1:null,
          date_range:dateString,
    
      }


      this.fetchData(page, perPage,data);
    }

  render() {

      const authentication = store.getState((state) => state);
      const user = authentication.auth.user
      const role = user.role

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    // setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    // selectedRowKeys,
    onChange: onSelectChange,

  };
  // Common function for sort Validator, by farooq
  const sortValidator = (a, b, aVar, bVar) => {
    if (a && aVar && aVar.length && b && bVar && bVar.length) {
      return aVar.length - bVar.length;
    } else if (a && aVar && aVar.length) {
      // That means be has null rechargeType, so a will come first.
      return -1;
    } else if (b && bVar && bVar.length) {
      // That means a has null rechargeType so b will come first.
      return 1;
    }

    // Both rechargeType has null value so there will be no order change.
    return 0;
  }, 

   columns = [   
    {
      title: "Status",
      dataIndex:"status",
      className: "viewstat",
      // sorter: (a, b) => a.status.length - b.status.length,
      // sorter: (a, b) => {
      //   if (a && a.status && a.status.length && b && b.status && b.status.length) {
      //     return a.status.length - b.status.length;
      //   } else if (a && a.status && a.status.length) {
      //     // That means be has null rechargeType, so a will come first.
      //     return -1;
      //   } else if (b && b.status && b.status.length) {
      //     // That means a has null rechargeType so b will come first.
      //     return 1;
      //   }

      //   // Both rechargeType has null value so there will be no order change.
      //   return 0;
      // },
      sorter: (a, b) => sortValidator(a, b, a.status, b.status),
      render: (text, record) => {
          var pStatus = ''
          var color = ''
           switch (record.status) {
          case 'Offer':
            pStatus = 'Offer'
            color = 'viewings'
            break;
          case 'Not Interested':
            pStatus = 'Not Interested'
            color = 'cancelled';
            break;
           case 'Second Viewing':
            pStatus = 'Second Viewing'
            color = 'unsuccessful';
            break;
            case 'Considering':
              pStatus = 'Considering'
              color = 'successful';
            break;
           case 'Scheduled':
            pStatus = 'Scheduled'
            color = 'scheduled';
            break;
    
      
          default:                  
            pStatus = 'Scheduled'
            color = 'scheduled';
            break;
        }
        return (
          <div className={color + " pstat"} key={pStatus}>
            {pStatus}
          </div>
        );
      }    ,
      filterDropdown: (
          <div style={{ padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
             Status
              <Select  
              mode={'multiple'}
              showSearch   
              value={this.state.status_filter}
              allowClear    
             style={{
             width: '100%',
              }}
             
              placeholder="Search"
               onChange={this.handleStatusFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              options={[
                  {
                    value: 'Second Viewing',
                    label: 'Second Viewing',
                  },
                  {
                    value: 'Not Interested',
                    label: 'Not Interested',
                  },
                  {
                    value: 'Unsuccessful',
                    label: 'Unsuccessful',
                  },
                  {
                    value: 'Considering',
                    label: 'Considering',
                  },
                  {
                    value: 'Offer',
                    label: 'Offer',
                  },
                ]}
              />


              </div>
        ),

    },
    
    {
      title: 'Property',
      dataIndex: 'refno',
      sorter: (a, b) => sortValidator(a, b, a.community_title, b.community_title),
      render: (text, record) => (

          <Link onClick={this.handleViewRefNo.bind(this,record.views_id)} className="d-flex flex-column">
              <span className="propref">{record.refno}</span>
              <span className="propinfo">
              {record.property_title == null ? "" : record.property_title + ", "}
                  {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                  {record.community_title == null ? "" : record.community_title}</span>
          </Link>
      )
    }, 
    {
      title: "Unit no",
      dataIndex:"unitno",
      sorter: (a, b) => a.unitno.length - b.unitno.length,
    },    
    {
        title: "Date",
        dataIndex:"view_date",
        sorter: (a, b) => a.view_date.length - b.view_date.length,
        filterDropdown: (
          <div style={{ padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
             Date
             <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />


              </div>
        ),
      }, 
      {
        title: "Client",
        dataIndex: 'client_name',
        sorter: (a, b) => a.client_name.length - b.client_name.length,
      },
      {
        title: "Agent",
        dataIndex: 'fullname',
        sorter: (a, b) => a.fullname.length - b.fullname.length,
        
      },
    {
      title: "Note",
      className: "noteswrap",
      dataIndex: 'note',
      sorter: (a, b) => a.note.length - b.note.length,
    },

    


  ]

  


      const handleTableChange = (pagination,filters) => {

    
        const data = {
          id:this.props.match.params.id,
            date_range_status:this.state.date_range_status,
            date_range:this.state.date_range,
      
        }
      
        this.fetchData(pagination.current, pagination.pageSize,data);
    
      };

      const items = [
        {
          key: '1',
          label: `New listings  (10)`,
          closable: true,
        },
        {
          key: '2',
          label: `Contacted listings  (0)`,
        },
        {
          key: '3',
          label: `Other listings  (13)`,
        },
        {
          key: '4',
          label: `Other listings  (13)`,
        },
        {
          key: '5',
          label: `Other listings  (13)`,
        },
        {
          key: '6',
          label: `Other listings  (13)`,
        },
      ];

  

    return (
        <div>
        <ToastContainer />


            <Modal width={600} title="Viewing" open={this.state.isView}
                   onOk={this.handleChangeStatus} onCancel={this.handleCancel} >
                <div className="container">
                    <div className='row w-100 d-flex flex-row'>
                        <div className='px-0 pt-1 form-group'>
                            <label>Ref No</label>
                            <p className="form-control-disabled">{this.state.viewings.listing_refno}</p>
                        </div>
                        {role == 1? <div className='px-0 pt-1 form-group'>
                            <label>Agent Name</label>
                            <p className="form-control-disabled">{this.state.viewings.fullname}</p>
                        </div>:null}

                    </div>
                    <div className='row w-100 mt-3 d-flex flex-row'>

                        <div className='px-0 pt-1 form-group'>
                            <label>Date</label>
                            {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} format="YYYY-MM-DD HH:mm"  style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p className="form-group">{this.state.viewings.view_date}</p>}

                        </div>
                        <div className='px-0 pt-1 mt-3 form-group'>
                            <label>Status</label>
                            <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
                                <option value="Offer">Offer</option>
                                <option value="Scheduled">Scheduled</option>
                                <option value="Second Viewing">Second Viewing</option>
                                <option value="Not Interested">Not Interested</option>
                                <option value="Considering">Considering</option>
                            </select>
                        </div>
                        {this.state.showPrice ? <div className='form-group'>
                            <label>Price</label>
                            <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
                        </div> : null}
                    </div>
                    <div className='row w-100 mt-3'>

                        <div className='w-100 px-0 pt-1'>
                            <label>Viewing Feedback</label>
                            <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
                        </div>


                    </div>
                </div>
            </Modal>

    
      
          
       <div className='contentwrap' style={{
           
           minHeight: 380,
         }}>

         <div className='ml-auto d-flex px-0 mt-3'>
          
          <div className='row pl-3 w-100'>
          <Space direction="vertical">
        
        
        <Space wrap>
          
        <div className="d-flex flex-row justify-content-between my-2 px-3">
      <h3 className="font-bold mb-0">All Viewings</h3>
      <div  className="d-flex flex-row justify-content-between">
    
         </div>
      </div>

{/* edit filter */}


        
         
          
        </Space>
      </Space>
          </div>
         </div>



              
        <div class="table-responsive tablelistwrap px-3 allviewings" >
        <ReactDragListView.DragColumn  onDragEnd={this.onDragEnd}
        nodeSelector="th">

                                  <Table onChange={handleTableChange}

                              
                                   loading={this.state.isLoading}
                                   rowSelection={rowSelection}
                                  //className="table-striped table-bordered"
                                  pagination={{
                                    pageSizeOptions: ['10', '20','50','100'],
                                    total:
                                      this.state?.all_viewings?.total > 0 &&
                                      this.state.all_viewings.total,
                                    pageSize: this.state.all_viewings.per_page,
                                    current: this.state.all_viewings.current_page,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                  style={{ overflowX: "auto" }}
                                  columns={columns}
                                   bordered
                                  dataSource={
                                    this.state?.all_viewings?.data
                                      ? this.state?.all_viewings?.data
                                      : []
                                  }
                                  
                                  rowKey={(record) => record}
                                  />
                                  </ReactDragListView.DragColumn>
                             
                                  </div>
       </div>
       </div>
    )
  }
}




export default All_Viewings

// export default  MyListings;
