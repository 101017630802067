import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import 'antd/dist/reset.css';
import { Table, Breadcrumb, Badge, Drawer, Button, Space, Checkbox } from 'antd'
import { PlusCircleOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';


class ListRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role_id: 0,
      role_name: "",
      role: {},
      permissions: [],
      isLoading: false,
      users: [],
      roles: [],
      open_new_role: false,
      open_edit_role: false,

    }
  }
  componentDidMount() {

    API.get("/permissions")
      .then((response) => {
        var data = response.data.permissions
        for (let i = 0; i < data.length; i++) {
          data[i].ischecked = false;
        }
        this.setState({ permissions: data })


      })
    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage);
  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true })
    setTimeout(() => {


      API.post(`/roles?page=${page}&perPage=${perPage}`)
        .then(async (response) => {


          var data = await response.data.roles.data;

          await response.data.roles;
          this.setState({
            roles: response.data.roles,
            isLoading: false
          })

        })
    }, 300);

  }


  handleOpenNewRoleDrawer = () => {
    this.setState({ open_new_role: true })
  }

  handleCloseNewRoleDrawer = () => {
    this.setState({ open_new_role: false })
  }

  handleOpenEditRoleDrawer = (id, e) => {
    this.setState({ role_id: id })
    API.get("/role/" + id)
      .then((response) => {


        if (response.data.success) {

          const allpermissions = response.data.permissions
          const rolepermissions = response.data.rolepermissions
          const role = response.data.role

          for (let i = 0; i < allpermissions.length; i++) {

            for (let j = 0; j < rolepermissions.length; j++) {

              if (allpermissions[i].id == rolepermissions[j].permission_id) {

                allpermissions[i].ischecked = true;

              } else {
                //mydata[i].ischecked = false;
              }

            }

          }

          this.setState({ role_name: role.name, permissions: allpermissions, open_edit_role: true })



        }
      })

  }

  handleCloseEditRoleDrawer = () => {
    this.setState({ open_edit_role: false })
  }

  onChangePermission = (permission_id, e) => {



    let index = this.state.permissions.findIndex(obj => obj.id === permission_id);
    var mydata = this.state.permissions;





    var i;
    for (i = 0; i < mydata.length; i++) {

      if (i == index) {




        mydata[i].ischecked = !mydata[i].ischecked;

      }
    }

    console.log("my data", mydata)
    // return

    this.setState({ permissions: mydata })


  }


  handleRoleName = (e) => {
    this.setState({ role_name: e.target.value })
  }

  handleSubmit = () => {
    const data = {
      role_name: this.state.role_name,
      permissions: this.state.permissions
    }
    API.post("/addRole", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ open_new_role: false })
          const page = 1;
          const perPage = 10;
          toast.success("Successfully added")
          this.fetchData(page, perPage);
        }

      })
  }

  handleUpdate = () => {
    const id = this.state.role_id
    const data = {
      role_name: this.state.role_name,
      permissions: this.state.permissions
    }


    API.put("/updateRole/" + id, data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ open_edit_role: false })
          const page = 1;
          const perPage = 10;
          toast.success("Successfully updated")
          this.fetchData(page, perPage);
        }

      })
  }

  handleDelete = (id, e) => {
    const page = 1
    const perPage = 10

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.delete("/delete/" + id)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchData(page, perPage)
            }

          })






      }
    })
  }


  render() {

    const columns = [


      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
      },



      {
        title: 'Action',
        render: (text, record) => (
          <div className="ms-auto">
            <Button type='primary' size="medium" onClick={this.handleOpenEditRoleDrawer.bind(this, record.id)} > <EditOutlined />    Edit</Button>
            <a className="btn btn-danger btn-sm m-2" style={{ color: 'white' }} onClick={this.handleDelete.bind(this, record.id)}><i className="fa fa-trash" ></i>  Delete</a>
          </div>
        ),
      },

    ]

    const handleTableChange = (pagination) => {

      this.fetchData(pagination.current, pagination.pageSize);

    };
    return (
      <div>
        <ToastContainer />
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Roles</Breadcrumb.Item>


          </Breadcrumb>
          <Button type='primary' shape="round" size="large" onClick={this.handleOpenNewRoleDrawer} > <PlusCircleOutlined />    New Role</Button>

          <Drawer
            title={`Add new role`}
             className='RoleDrawerClass'
            placement="left"
            // size={"large"}
            width={"98%"}
            onClose={this.handleCloseNewRoleDrawer}
            open={this.state.open_new_role}
            extra={
              <Space>
                <Button type='primary' danger ghost onClick={this.handleCloseNewRoleDrawer} icon={<CloseOutlined />}>Cancel</Button>
                <Button type="primary" onClick={this.handleSubmit}>
                  Save changes
                </Button>
              </Space>
            }
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label>Role Name</label>
                  <input type="text" className="form-control" onChange={this.handleRoleName} />
                </div>
              </div>
              {/* <div className='col-md-4'></div>
    <div className='col-md-4 mt-4'>
        <label></label>
        <button className='btn btn-primary' onClick={this.handleSubmit}> <i class="fa fa-save"></i>  Save Data</button>
        <button className='btn btn-danger ml-2 '><i class="fa fa-remove"></i>   Cancel</button>
    </div> */}
            </div>

            <div className='row mt-5'>

              <table class="table table-striped table-bordered">
                <thead style={{ backgroundColor: '#151515', color: 'white' }}>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Permission</th>
                    <th>Status</th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.permissions.map((permission, index) => {
                    return (
                      <tr>
                        <th scope="row">{permission.id}</th>
                        <td>{permission.name}</td>
                        <td>
                          <Checkbox checked={permission.ischecked} onChange={this.onChangePermission.bind(this, permission.id)} >

                          </Checkbox>
                        </td>

                      </tr>

                    )
                  })}

                </tbody>
              </table>

              {/* {this.state.permissions.map((permission,index) =>{
     
     return  (<div className='col-md-3'>
          <Checkbox checked={permission.ischecked} onChange={this.onChangePermission.bind(this,permission.id)}  >
          {permission.name}
         </Checkbox>
        
         
         </div>)

  
         
       })} */}
            </div>


          </Drawer>

          {/* edit role */}
          <Drawer
            title={`Edit role  :    ` + this.state.role_name}
            placement="left"
            className='RoleDrawerClass'
            // size={"large"}
            width={"98%"}
            onClose={this.handleCloseEditRoleDrawer}
            open={this.state.open_edit_role}
            extra={
              <Space>
                <Button type='primary' danger ghost onClick={this.handleCloseEditRoleDrawer} icon={<CloseOutlined />}>Cancel</Button>
                <Button type="primary" onClick={this.handleUpdate}>
                  Save changes
                </Button>
              </Space>
            }
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label>Role Name</label>
                  <input value={this.state.role_name} type="text" className="form-control" onChange={this.handleRoleName} />
                </div>
              </div>

            </div>

            <div className='row mt-5'>

              <table class="table table-striped table-bordered">
                <thead style={{ backgroundColor: '#151515', color: 'white' }}>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Permission</th>
                    <th>Status</th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.permissions.map((permission, index) => {
                    return (
                      <tr>
                        <th scope="row">{permission.id}</th>
                        <td>{permission.name}</td>
                        <td>
                          <Checkbox checked={permission.ischecked} onChange={this.onChangePermission.bind(this, permission.id)} >

                          </Checkbox>
                        </td>

                      </tr>

                    )
                  })}

                </tbody>
              </table>

            </div>


          </Drawer>
        </div>
        <div style={{
          padding: 24,
          minHeight: 380,
          background: "#fff",
        }}>
          <div class="table-responsive">

            <Table onChange={handleTableChange}
              loading={this.state.isLoading}
              className="table-striped"
              pagination={{
                pageSizeOptions: ['5', '10', '20', '50', '100'],
                total:
                  this.state?.roles?.total > 0 &&
                  this.state.roles.total,
                pageSize: this.state.roles.per_page,
                current: this.state.roles.current_page,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              style={{ overflowX: "auto" }}
              columns={columns}
              // bordered
              dataSource={
                this.state?.roles?.data
                  ? this.state?.roles?.data
                  : []
              }
              rowKey={(record) => record}
            />

          </div>
        </div>
      </div>
    )
  }
}

export default ListRoles;
