import React, {Component} from 'react'
import 'antd/dist/reset.css';
import {Table, Space,Modal,Button,DatePicker, Row, Col} from 'antd'
import {itemRender, onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {oldBaseurl} from "../../components/BaseUrl";
import { Link } from 'react-router-dom';
// import './document.css'
import { EyeFilled,EditOutlined,CloseOutlined,PlusCircleOutlined  } from '@ant-design/icons';
import dayjs from "dayjs";
import { store } from '../../store';
import Swal from "sweetalert2";
import './training.css'

class Training extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            trainings: [],
            files: [],
            isOpen:false,
            isEditOpen:false,
            record_id:"",
            title:"",
            url:"",
            date:"",
            section:"",
            openVideo:false,
            current_url:"",
            dashboard:[],
            listing:[],
            leads:[],
            contacts:[],
            activities:[],
            documents:[],
            notifications:[],
            requests:[],
            reports:[],
        }
    }

    componentDidMount() {
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage);
    }


    fetchData = (page, perPage, data) => {
        this.setState({isLoading: true})

        API.post(`/training`)
            .then(async (response) => {
                // var data = await response.data.trainings;
                this.setState({
                    // trainings: data,
                    dashboard : response.data.dashboard,
                    listing:response.data.listing,
                    leads:response.data.leads,
                    contacts:response.data.contacts,
                    activities:response.data.activities,
                    documents:response.data.documents,
                    notifications:response.data.notifications,
                    requests:response.data.requests,
                    reports:response.data.reports,


                    isLoading: false
                })
            })

    }

    handleNewTraining = () =>{
        this.setState({isOpen:true})
    }

    handleTitle = (e) =>{
        this.setState({title:e.target.value})
    }
    handleURL = (e) =>{
        this.setState({url:e.target.value})
    }
    handleDate = (value,dateString) =>{
      
        this.setState({date:value !=null?dateString:""})
    }
    // new
    handleSection = (e) =>{
        this.setState({section:e.target.value})
    }

    handleCancel = () =>{
        this.setState({isOpen:false})
    }
    handleOk = () =>{
        const data = {title:this.state.title,url:this.state.url,date:this.state.date,section:this.state.section}
        if(this.state.section == "" || this.state.title == "" || this.state.url == ""){
            toast.error("Please enter title , URL and category")
            return 
        }
        API.post("/create_training",data)
        .then((res) =>{
            if(res.data.success){
                toast.success("successfully created")
                this.setState({title:"",url:"",date:"",section:""})
                this.setState({isOpen:false})
                const page = 1;
                const perPage = 10;
        
                this.fetchData(page, perPage);
            }
        })
        
    }
    showVideo = (record,e) =>{
        console.log(record.url);
        this.setState({openVideo:true,current_url:record.url})
    }

    handleCancelVideo = () =>{
        
        this.setState({openVideo:false})
    }

    handleEditTraining = (record,e) =>{
       
     this.setState({isEditOpen:true,record_id:record.id,  title:record.name, url:record.url, section:record.section})
    }

    handleCancelEdit = () =>{
        this.setState({isEditOpen:false})
    }
    handleUpdate = () =>{
        const data = {title:this.state.title,url:this.state.url,date:this.state.date,section:this.state.section}
        const record_id = this.state.record_id
        if(this.state.section == "" || this.state.title == "" || this.state.url == ""){
            toast.error("Please enter title , URL and category")
            return 
        }
        API.put("/training/"+record_id,data)
        .then((res) =>{
            if(res.data.success){
                toast.success("successfully updated")
                this.setState({title:"",url:"",date:"",section:""})
                this.setState({isEditOpen:false})
                const page = 1;
                const perPage = 10;
        
                this.fetchData(page, perPage);
            }
        })
      

    }

    handleDeleteTraining = (record_id,e) =>{

        const page = 1;
        const perPage = 10;
       
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              API.delete("/training/"+record_id)
              .then((response) =>{
               
                if(response.data.success){
                  toast.success("Successfully deleted")
                  this.fetchData(page, perPage);
                }
              })
            }
          })
    }

    render() {
        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;

        const onSelectChange = (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        };

        const rowSelection = {
            onChange: onSelectChange,

        };

       const columns = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     render: (id, record, index) => (
            //         <div key={index}>
            //            {index + 1}
            //         </div>
            //     ),
            // },
            {
                title: 'Source',
                dataIndex: 'url',
                render: (text,record) => (
                    <   div className="videowrap" style={{position: 'relative',  width: '100%', height:'250px'}}>
                    <iframe src={record.url} frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen="true" className='w-100 h-100'></iframe></div>
                    // <Link onClick={this.showVideo.bind(this,record)}>
                    // <EyeFilled  />
                    // </Link>
                ),
            },
            {
                title: 'Name',
                dataIndex: 'name',
                render: (text,record) => (
                    <>
                    <p className='font-bold mb-0' style={{whiteSpace:'normal'}}>{record.name}</p>
                    <Moment format="DD MMMM, YYYY" style={{opacity:0.5}}>
                        {record.date}
                    </Moment>
                    </>
                    // <>
                    //     <a href={(type === "file") ? oldBaseurl + url : url} target="_blank"
                    //        style={{color: "#337ab7"}}>{name}</a>
                    // </>
                ),
            },
            
            // {
            //     title: ' Date',
            //     dataIndex: 'date',
            //     render: (date) => (
            //         <Moment format="DD MMMM, YYYY">
            //             {date}
            //         </Moment>
            //     ),
            // },
            {
                title: ' Action',
                render: (text,record) => (
                   <>
                   {role == 1?<>
                   <div className='btngrp-actions'>
                   <Button type='link' onClick={this.handleEditTraining.bind(this,record)}><EditOutlined /> Edit</Button>
                   <Button type="link" onClick={this.handleDeleteTraining.bind(this,record.id)}><CloseOutlined />Delete</Button>
                   </div>
                   </>:null}
                   </>
                ),
                hidden:role==1?false: true
            },
        ].filter(item => !item.hidden);
        const handleTableChange = (pagination) => {
            this.fetchData(pagination.current, pagination.pageSize);
        };
       
        return (
            <div>
                <ToastContainer/>

                <Modal width={1200} title="Training video" 
                open={this.state.openVideo} 
                 onCancel={this.handleCancelVideo}
                 footer={null}
                >
         <div style={{position: 'relative', paddingBottom: '64.98194945848375%', width: '100%'}}>
            <iframe src={this.state.current_url} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{position: 'absolute' ,top: '0', left: '0', width: '100%', height: '100%'}}></iframe></div>
           </Modal>
               

                <Modal width={600} title="Add Training"   open={this.state.isOpen} 
                onOk={this.handleOk} onCancel={this.handleCancel}
                >
                    <Row>
                        <Col  span={24}>
                            <div class="form-group">
                                <label class="form-label">Title</label>
                                <textarea defaultValue={this.state.title} onChange={this.handleTitle} class="form-control font-12" rows="2"></textarea>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div class="form-group">
                                <label  class="form-label">Section</label>
                                <select value={this.state.section} className='form-select font-12' onChange={this.handleSection}>
                                    <option value="">Select </option>
                                    <option value="Dashboard">Dashboard </option>
                                    <option value="Listing">Listing </option>
                                    <option value="Leads">Leads </option>
                                    <option value="Contacts">Contacts </option>
                                    <option value="Activity">Activity </option>
                                    <option value="Documents">Documents </option>
                                    <option value="Notifications">Notifications </option>
                                    <option value="Requests">Requests </option>
                                    <option value="Reports">Reports </option>
                                </select>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div class="form-group">
                                <label  class="form-label">Date</label>
                                <DatePicker value={(this.state.date) ? dayjs(this.state.date) : dayjs()} format={'DD MMMM YYYY'}  style={{width:'100%', height:'36px', overflow:'hidden'}} onChange={this.handleDate} className='font-12' disabled/>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div class="form-group">
                                <label for="exampleFormControlInput1" class="form-label">URL</label>
                                <input value={this.state.url} onChange={this.handleURL} type="text" class="form-control font-12" />
                            </div>
                        </Col>
                    </Row>

                </Modal>

                <Modal width={600} title="Edit Training"   open={this.state.isEditOpen} 
                onOk={this.handleUpdate} onCancel={this.handleCancelEdit}
                >
                    <Row>
                        <Col  span={24}>
                            <div class="form-group">
                                <label class="form-label">Title</label>
                                <textarea defaultValue={this.state.title} onChange={this.handleTitle} class="form-control font-12" rows="2"></textarea>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div class="form-group">
                                <label  class="form-label">Section</label>
                                <select value={this.state.section} className='form-select font-12' onChange={this.handleSection}>
                                    <option value="">Select </option>
                                    <option value="Dashboard">Dashboard </option>
                                    <option value="Listing">Listing </option>
                                    <option value="Leads">Leads </option>
                                    <option value="Contacts">Contacts </option>
                                    <option value="Activity">Activity </option>
                                    <option value="Documents">Documents </option>
                                    <option value="Notifications">Notifications </option>
                                    <option value="Requests">Requests </option>
                                    <option value="Reports">Reports </option>
                                </select>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div class="form-group">
                                <label  class="form-label">Date</label>
                                <DatePicker value={(this.state.date) ? dayjs(this.state.date) : dayjs()} format={'DD MMMM YYYY'}  style={{width:'100%', height:'36px', overflow:'hidden'}} onChange={this.handleDate} className='font-12' disabled/>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div class="form-group">
                                <label for="exampleFormControlInput1" class="form-label">URL</label>
                                <input value={this.state.url} onChange={this.handleURL} type="text" class="form-control font-12" />
                            </div>
                        </Col>
                    </Row>

                </Modal>

                <div className='contentwrap mt-5 px-3 trainingwrap'>
                    <Row className='justify-content-between '>
                        <Col>
                            <h3>Training Videos</h3>
                        </Col>
                        {role == 1?
                        <Col>
                            <Button type="primary" onClick={this.handleNewTraining}>
                                <PlusCircleOutlined /> Add New Training Video
                            </Button>
                        </Col>
                        :null}
                    </Row>
                    {this.state.dashboard && this.state.dashboard.length>0?
                    <Row>
                        <Col span={24}><h4 className='font-bold mt-3 mb-0'><span>Dashboard</span></h4></Col>
                        <Col span={24} className='videowrap'>
                            <Table onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                   
                                   style={{overflowX: "auto"}}
                                   columns={columns}
                                   showHeader={false}
                                   dataSource={
                                       this.state?.dashboard
                                           ? this.state?.dashboard
                                           : []
                                   }

                                   rowKey={(record) => record}
                            />
                       
                        </Col>
                    </Row>
                    :null}
                    {this.state.listing && this.state.listing.length>0?
                    <Row>
                        <Col span={24}><h4 className='font-bold mt-3 mb-0'><span>Listings</span></h4></Col>
                        <Col span={24} className='videowrap'>
                            <Table onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                   
                                   style={{overflowX: "auto"}}
                                   columns={columns}
                                   showHeader={false}
                                   dataSource={
                                       this.state?.listing
                                           ? this.state?.listing
                                           : []
                                   }

                                   rowKey={(record) => record}
                            />
                       
                        </Col>
                    </Row>
                    :null}
                    {this.state.leads && this.state.leads.length>0?
                    <Row>
                        <Col span={24}><h4 className='font-bold mt-3 mb-0'><span>Leads</span></h4></Col>
                        <Col span={24} className='videowrap'>
                            <Table onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                   
                                   style={{overflowX: "auto"}}
                                   columns={columns}
                                   showHeader={false}
                                   dataSource={
                                       this.state?.leads
                                           ? this.state?.leads
                                           : []
                                   }

                                   rowKey={(record) => record}
                            />
                       
                        </Col>
                    </Row>
                    :null}
                    {this.state.activities && this.state.activities.length>0?
                    <Row>
                        <Col span={24}><h4 className='font-bold mt-3 mb-0'><span>Activities</span></h4></Col>
                        <Col span={24} className='videowrap'>
                            <Table onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                   
                                   style={{overflowX: "auto"}}
                                   columns={columns}
                                   showHeader={false}
                                   dataSource={
                                       this.state?.activities
                                           ? this.state?.activities
                                           : []
                                   }

                                   rowKey={(record) => record}
                            />
                       
                        </Col>
                    </Row>
                    :null}
                    {this.state.documents && this.state.documents.length>0?
                    <Row>
                        <Col span={24}><h4 className='font-bold mt-3 mb-0'><span>Documents</span></h4></Col>
                        <Col span={24} className='videowrap'>
                            <Table onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                   
                                   style={{overflowX: "auto"}}
                                   columns={columns}
                                   showHeader={false}
                                   dataSource={
                                       this.state?.documents
                                           ? this.state?.documents
                                           : []
                                   }

                                   rowKey={(record) => record}
                            />
                       
                        </Col>
                    </Row>
                    :null}
                    {this.state.requests && this.state.requests.length>0?
                    <Row>
                        <Col span={24}><h4 className='font-bold mt-3 mb-0'><span>Requests</span></h4></Col>
                        <Col span={24} className='videowrap'>
                            <Table onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                   
                                   style={{overflowX: "auto"}}
                                   columns={columns}
                                   showHeader={false}
                                   dataSource={
                                       this.state?.documents
                                           ? this.state?.documents
                                           : []
                                   }

                                   rowKey={(record) => record}
                            />
                       
                        </Col>
                    </Row>
                    :null}
                </div>
            </div>
        )
    }
}


export default Training