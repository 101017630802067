import React, { Component } from 'react'
import { Row, Col, Avatar, Button, Drawer, Tag, Tooltip, Input,Mentions,Modal,Spin,Divider,Select,DatePicker,Checkbox,Timeline,Image,Carousel } from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {UserOutlined, CalendarOutlined, CaretRightOutlined, CaretLeftOutlined, EyeFilled,CheckOutlined,CloseOutlined,SettingFilled} from '@ant-design/icons';
import Board from "react-trello"
import EdiText from "react-editext";
import dayjs from "dayjs";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import BeatLoader from "react-spinners/BeatLoader";
import CurrencyInput from 'react-currency-input-field';
import {baseLpUrlImg, liveUrl} from '../../components/BaseUrl';
import Swal from "sweetalert2";

// https://rcdexta.com/react-trello/?path=/story/basic-functions--full-board-example
// https://github.com/rcdexta/react-trello
import EasyEdit, { Types } from 'react-easy-edit';


import './pipeline.css'
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { baseurlImg } from '../../components/BaseUrl';
let new_enquiry_array = []
let working_array = []
let viewings_array = []
let second_viewings_array = []
let offers_array = []
let offers_accepted_array = []
let pipelines_array = []
const { TextArea } = Input;
class Pipeline extends Component {
    constructor(props){
        super(props)
       

        
          this.state = { 
            new_enquery:[],
            new_enquery_current_page:1,
            working_current_page:1,
            viewing_current_page:1,
            second_viewing_current_page:1,
            offer_current_page:1,
            offer_accepted_current_page:1,
            pipeline_current_page:1,
             new_enquery_count: "",
             selectedAgent:"",
            working_count: "",
            viewing_count: "",
            second_viewing_count: "",
            offer_count: "",
            offer_accepted_count: "",
            exact_match_listings:[],
            comparable_match_listings:[],
            exact_match_listings_count:0,
            comparable_match_listings_count:0,
            type: 2,
            reply: "",
            activity_agent_id: "",
            decline_note: "",
            activityFeeds: [],
            sendTagDataAll: [],
            sendTagDataReplied: [],
            sendTagDataPending: [],
            sendTagData: [],
            recieveTagDataAll: [],
            recieveTagDataReplied: [],
            recieveTagDataPending: [],
            recieveTagData: [],
            isActivityLoading: false,
            isTagLoading: false,
            requested_viewings: [],
            currentRequest: null,
            isWhatsappLoading: false,
            remainingTime: "00:00:00",
            vendor: "",
            isViewing: false,
            isLoading: false,
            isNextLoading: false,
            isPreviousLoading: false,
            isRequestviewingOpen: false,
            role: "",role_profile:"",
            agent_id: "",
            addNewFilterSelect: "",
            contact_type_call_note_status : "",
            rows: [],
            sendEvent: false,
            mylistings: [],
            propertytypes: [],
            validationerrors: {},
            followup_data: [],
            property_viewings: [],
            property_offers: [],
            collaborators: [],
            followups: [],
            record_id: '',
            view_id: 0,
            isviewingOpen: false,
            iseditviewingOpen: false,
            isOfferOpen: false,
            isfollowupOpen: false,
            isfollowupViewOpen: false,

            lead_stage_filter:[
                { label: 'None', value: '0' },
                { label: 'New Inquiry', value: '1' },
                { label: 'Working', value: '2' },
                { label: 'Hot', value: '3' },
                { label: 'Follow Up', value: '4' },
                { label: 'Dead', value: '5' },
                {
                    value: '6',
                    label: 'Offer',
                },
                {
                    value: '7',
                    label: 'Deal signed',
                },
                { label: 'Recruitment', value: '9' },
                { label: 'Viewing', value: '8' },
                { label: 'No Answer', value: '10' },
                { label: 'Expired', value: '11' },
                { label: 'Pond', value: '12' },
                ,
                {
                    value: '13',
                    label: 'Offer accepted',
                },
                {
                    value: '14',
                    label: 'Offer declined',
                },
                ,
                    {
                        value: '22',
                        label: '3rd party',
                    }
            ],
            lead_stage_filter_agent:[
                { label: 'None', value: '0' },
                { label: 'Dead', value: '5' },

            ],
            furnished_types: [
                {
                    value: '1',
                    label: 'Furnished'
                },
                {
                    value: '2',
                    label: 'Unfurnished'
                }
                ,
                    {
                        value: '22',
                        label: '3rd party',
                    }
            ],

            contact_types: [
                {
                    value: '1',
                    label: 'Tenant'
                },
                {
                    value: '2',
                    label: 'Buyer'
                },
                {
                    value: '3',
                    label: 'Landlord'
                },
                {
                    value: '4',
                    label: 'Seller'
                },
                {
                    value: '5',
                    label: 'Landlord+Seller'
                },
                {
                    value: '6',
                    label: 'Agent'
                },
                {
                    value: '7',
                    label: 'Other'
                },
                {
                    value: '8',
                    label: 'Portal'
                },
                {
                    value: '9',
                    label: 'Buyer/Tenant'
                },
                {
                    value: '10',
                    label: 'Unrecognized'
                }
            ],

            stages: [
                {
                    value: '1',
                    label: 'New Inquiry',
                },
                {
                    value: '2',
                    label: 'Working',
                },
                {
                    value: '3',
                    label: 'Hot',
                },
                {
                    value: '4',
                    label: 'Follow Up',
                },
                {
                    value: '5',
                    label: 'Dead',
                },
                {
                    value: '6',
                    label: 'Offer',
                },
                {
                    value: '7',
                    label: 'Deal signed',
                },
                {
                    value: '8',
                    label: 'Viewings',
                },
                {
                    value: '9',
                    label: 'Recruitment',
                },
                {
                    value: '10',
                    label: 'No Answer',
                },
                {
                    value: '11',
                    label: 'Expired',
                },
                {
                    value: '12',
                    label: 'Pond',
                },
                {
                    value: '13',
                    label: 'Offer accepted',
                },
                {
                    value: '14',
                    label: 'Offer declined',
                },
                {
                    value: '15',
                    label: 'whatsapp reply',
                },
                {
                    value: '16',
                    label: 'Second Viewing',
                },
                {
                    value: '17',
                    label: 'Not Interested',
                },
                {
                    value: '18',
                    label: 'Considering',
                },
                {
                    value: '19',
                    label: 'Whatsapp automation',
                },
                {
                    value: '20',
                    label: 'Whatsapp Agent',
                },
                    {
                        value: '21',
                        label: 'Pledge',
                    },
                    {
                        value: '22',
                        label: '3rd party',
                    }


            ],

            filter_options: [


                {
                    value: '1',
                    label: 'Contact type',
                },

                {
                    value: '2',
                    label: 'Community / Sub community',
                },
                {
                    value: '3',
                    label: 'Price',
                },
                {
                    value: '4',
                    label: 'Bedrooms',
                },
                {
                    value: '5',
                    label: 'Created',
                },
                {
                    value: '6',
                    label: 'Stage',
                },
                {
                    value: '7',
                    label: 'Furnished  / unfurnished',
                },
                {
                    value: '8',
                    label: 'Agents',
                },
                {
                    value: '9',
                    label: 'Viewings',
                },
                {
                    value: '10',
                    label: 'Offers',
                },
                {
                    value: '11',
                    label: 'Type',
                },
                {
                    value: '12',
                    label: 'Last call',
                },
                {
                    value: '13',
                    label: 'Last match',
                },
                {
                    value: '14',
                    label: 'Move in date',
                },
                {
                    value: '15',
                    label: 'Replied to whatsapp',
                },
                {
                    value: '16',
                    label: 'Clicked - Book Viewing/ More Info',
                },
                {
                    value: '17',
                    label: 'Viewing booked Since Requested',
                },
                {
                    value: '18',
                    label: 'Viewing date',
                },
                {
                    value: '19',
                    label: 'Viewing Outcome',
                },
                {
                    value: '20',
                    label: 'Number confirmed on whatsapp',
                },
                {
                    value: '21',
                    label: 'Offer Status',
                },
                {
                    value: '22',
                    label: 'Deal Signed',
                },


            ],
            price_options: [


                {
                    value: '1',
                    label: 'Equals',
                },
                {
                    value: '2',
                    label: 'Not equal',
                },
                {
                    value: '3',
                    label: 'Less than',
                },
                {
                    value: '4',
                    label: 'Less than or equals',
                },
                {
                    value: '5',
                    label: 'Greater than',
                },
                {
                    value: '6',
                    label: 'Greater than or equals',
                },

                {
                    value: '7',
                    label: 'In range',
                },

            ],

            name_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            phone_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            bedroom_filter_options: [
                {
                    value: '1',
                    label: 'Equals',
                },
                {
                    value: '2',
                    label: 'Not equal',
                },
                {
                    value: '3',
                    label: 'Less than',
                },
                {
                    value: '4',
                    label: 'Greater than',
                },


            ],
            agent_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            // add notes
            call_log: "",
            note_log: "",
            whatsapp_log: "",
            email_log: "",
            followup_log: '',
            emailSubject:"",

            // Loaders
            call_loader: false,
            note_loader: false,
            whatsapp_loader: false,
            email_loader: false,
            offer_loader: false,
            viewing_loader: false,
            followUp_loader: false,

            // Error
            checkErr: false,
            // IDs
            nextId: '',
            prevId: '',
            currId: '',

            offers: [],
            followups_details: [],
            ref_nums: [],
            unit_nums: [],
            userClients: [],
            selectedClients: [],
            selectedCallUsers: [],
            selectedNoteUsers: [],
            selectedWhatsappUsers: [],
            selectedEmailUsers: [],
            curr: "",
            comment: "",
            titleHasError:false,
            ismourequestOpen:"",
            sellers:[],
            sellersdata:[],

            selectedViewingUsers: [],
            selectedOfferUsers: [],
            selectedFollowupUsers: [],

            newArrSelectedAgents: [],
            selected_agent: "",
            followup_for: "",
            reminder: "",

            ref_def_nums: [],
            old_unit_num: [],
            viewings_listings: [],
            viewing_ref_no: "",
            viewing_unit_no: "",
            viewing_agent: "",
            viewing_date: "",
            viewing_note: "",
            viewing_status: "",
            viewing_price: "",
            showPrice: false,
            showDate: false,
            community_from_requirement: "",
            sub_community_from_requirement: "",
            min_from_requirement: "",
            max_from_requirement: "",
            bedroom_from_requirement: "",
            property_for_from_requirement: "",
            added_date_from_requirement: "",
            contact_type_requirement:"",
            price_requirement: "",

            lead_agent: "",
            lead_community: "",
            lead_sub_community: "",
            //followup
            followup_note: "",
            followup_date: "",
            leads: [],
            lead_details: {},
            lead_list_details: {},
            viewings: {},
            all: [],
            notes: [],
            agents: [],
            call_notes: [],
            whatsapp_notes: [],
            email_notes: [],
            viewing_notes: [],
            offer_notes: [],
            followup_notes: [],
            communities: [],
            sub_communities: [],
            properties: [],
            all_sub_communities: [],
            requirement_sub_communities: [],
            filters: [],
            greaterthanprice: false,
            leassthanprice: false,
            inbetweenprice: false,
            lessValue: 0,
            greaterValue: 0,

            owner_id: "",
            owner_name: "",
            owner_email: "",
            owner_mobile: "",

            community_match: "",
            sub_community_match: "",
            min_budget: 0,
            max_budget: 0,
            bedroom_match: "",
            property_for_match: "",
            property_type_match: "",
            added_date_match: "",
            date_morethan_option_match: true,
            date_lessthan_option_match: false,
            date_value_match: 0,
            date_value_id_match: 1,
            matchs: [],
            not_matchs: [],
            owner_other_properties: [],


            // requirements

            requirement_min_budget: 0,
            requirement_max_budget: 0,
            requirement_lead_stage: "",
            requirement_community: "",
            requirement_sub_community: "",
            requirement_verifyedby: "",
            requirement_agent_id: "",
            requirement_move_in_date: "",
            requirement_bedroom: "",
            pin:false,


            isOpenEditLead:false,
            record_id: "",
            view_id:"",
            sourceLaneId: "",
            targetLaneId: "",
            new_enquiry:[],
            selectedCallUsers: [],
            ref_nums: [],
            ref_def_nums: [],
            viewings_listings: [],
            collaborators: [],
            owner_other_properties: [],
            propertytypes:[],
            unit_nums: [],
            agents: [],
            
            viewings: {},
            offers: {},
            open:false,
            viewing_agent: "",
            viewing_date:"",
            viewing_note:"",
            view_date:"2023-07-19 15:39:13",
            viewing_unit_no: "",
            viewing_price:"",
            offers_date:"",
            offers_price:"",
            offers_note: "",
            offers_commission:"",
            showPrice: false,
            showCommission: false,
            showReferral: true,
            ReferralChecked:false,
            showReferralAgent:false,
            selectedRefrralAgent:"",
            refrral_commission:"",
            showDate: false,
            userClients: [],
            isCallLogOpen:false,
            isviewingOpen:false,
            iseditviewingOpen:false,
            offerModal:false,
            contact_type_requirement:1,
            requirement_community:1,
            requirement_sub_community:1,
            price_requirement:"",
            requirement_bedroom:1,
            call_log:"",
            lead_details:"",
            communities: [],
            sub_communities: [],
            contact_types: [
                {
                    value: '1',
                    label: 'Tenant'
                },
                {
                    value: '2',
                    label: 'Buyer'
                },
                {
                    value: '3',
                    label: 'Landlord'
                },
                {
                    value: '4',
                    label: 'Seller'
                },
                {
                    value: '5',
                    label: 'Landlord+Seller'
                },
                {
                    value: '6',
                    label: 'Agent'
                },
                {
                    value: '7',
                    label: 'Other'
                },
                {
                    value: '8',
                    label: 'Portal'
                },
                {
                    value: '9',
                    label: 'Buyer/Tenant'
                },
                {
                    value: '10',
                    label: 'Unrecognized'
                }
            ],
            requirement_move_in_date:""
          }
         
    }

    componentDidMount (){
      const page = 1;
      const perPage = 10;
      const data = {selectedAgent:this.state.selectedAgent}
      // pipeline reqs
      this.fetchDataNewEnquiry(page, perPage,data);
      this.fetchDataWorking(page, perPage,data);
      this.fetchDataViewings(page, perPage,data);
      this.fetchDataSecondViewings(page, perPage,data);
      this.fetchDataOffers(page, perPage,data);
      this.fetchDataOffersAccepted(page, perPage,data);
      this.fetchDataPipeline(page, perPage,data);
      this.fetchTotalPipeline(data)
      API.get("/community")
      .then((response) => {
          if (response.data.success) {
              this.setState({ communities: response.data.communities })
          }
      })
      API.get("/all_agents")
      .then((response) => {
              if (response.data.success) {
                  this.setState({ agents: response.data.agents })
              }
          }
      )
      API.get('/all_client_users').then((response) => {
        if (response.data.success) {
            const data = response.data.users
            for (let i = 0; i < data.length; i++) {
                const num = data[i].id
                data[i].key = num.toString()

            }
            this.setState({ userClients: data })
        }
    })

      API.get("/all_sub_community")
      .then((response) => {
          if (response.data.success) {
              this.setState({ sub_communities: response.data.sub_communities })
          }
      })
      API.get("/propertytypes")
      .then((response) => {
          if (response.data.success) {
              this.setState({ propertytypes: response.data.propertytypes })
          }
      })
    }

    fetchTotalPipeline = (data) =>{
        API.post("/pipeline_counts",data)
        .then((res)=>{
            
            if(res.data.success){
                this.setState({ new_enquery_count: res.data.new_enquery_count,
                working_count: res.data.working_count,
                viewing_count: res.data.viewing_count,
                second_viewing_count: res.data.second_viewing_count,
                offer_count: res.data.offer_count,
                offer_accepted_count: res.data.offer_accepted_count})
            }
        })
    }
    fetchDataNewEnquiry = (page, perPage,data) => {
 

        new_enquiry_array =[]
    //   this.setState({ isLoading: true })
      API.post(`/new_enquiry_pipline?page=${this.state.new_enquery_current_page}&perPage=${perPage}`,data)
          .then(async (response) => {
          
this.setState({new_enquery_current_page:response.data.leads.current_page+1,new_enquery:response.data.leads.data})
            var data_array = response.data.leads.data
// this.setState({new_enquiry:response.data.leads.data})
for (let i = 0; i < data_array.length; i++) {
 new_enquiry_array.push(
  {id: data_array[i].original_id, //ref no
            label: 
                <div className='d-flex btngrp'>
                    <Button type='text'><i className="bi bi-bookmark"></i></Button>
                    <Checkbox />
                    <Button type='text'><i className="bi bi-pin-angle"></i></Button>
                </div>
                , //Last Activity
            laneId: 1,
            title: 
            <div className="leadname">
            <p>{data_array[i].contact_type == 1?<span className='ctype tenant'>Tenant</span>:null}
              {data_array[i].contact_type == 2?<span className='ctype buyer'>Buyer</span>:null}
              {data_array[i].contact_type == 3?<span className='ctype landlord'>Landlord</span>:null}
              {data_array[i].contact_type == 4?<span className='ctype seller'>Seller</span>:null}
              {data_array[i].contact_type == 6?<span className='ctype agent'>Agent</span>:null}
              {data_array[i].contact_type == 7?<span className='ctype other'>Other</span>:null}
              {data_array[i].contact_type == 8?<span className='ctype portal'>Portal</span>:null}
              {data_array[i].contact_type == 9?<span className='ctype buyertenant'>Buyer/Tenant</span>:null}</p>
        <p className='font-bold font-14'>{data_array[i].fullname}</p>
        <p><span>{data_array[i].mobile}</span>
        </p>
        </div>,
            description: <>
              <Row className='carddesc'>
              <Col span={24} className='cardinfo'>
                <Row gutter={8} className='w-100'>
                    <Col span={24}>
                    <div className="descitem flex-row align-items-start">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row'>
                                        <span>{data_array[i].lead_price? data_array[i].lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED':"-"}</span>
                                    </div>
                                    <i className="bi bi-dot mx-2"></i>
                                    <span>{data_array[i].bedroom? data_array[i].bedroom:"-"} Beds</span>
                                </div>
                            </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-geo-alt-fill mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>-</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Viewing Date: -  {
                       data_array[i].view_date == null || data_array[i].view_date =='' || data_array[i].view_date == "0000-00-00 00:00:00"?
                       ""
                       :
                       <Moment fromNow>
                       {data_array[i].view_date}
                   </Moment>
                    }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Call: 
                                { data_array[i].last_call == null || data_array[i].last_call =='' || data_array[i].last_call == "0000-00-00 00:00:00"? "-"
                                :
                                <Moment fromNow>{data_array[i].last_call}</Moment>
                                }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className='agentwrap'>
                        <Tooltip title="Geoff Forknell" className='d-flex flex-row align-items-center mt-3'>
                            <Avatar size={23} icon={<img src={data_array[i].profile_photo== null || data_array[i].profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(data_array[i].profile_photo)? data_array[i].profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + data_array[i].profile_photo } className='h-100' />} />
                            <p className='ml-2'>{data_array[i].display_name}</p>
                        </Tooltip>
                    </div>
                    </Col>
                </Row>
              </Col>
              <Col span={24} className='footer justify-content-between'>
              <Button type='text' onClick={this.handleOpenDrawer.bind(this, data_array[i].parent_lead, false)} className='editbtn'><i className="bi bi-pencil-fill mr-1 font-10"></i>Edit</Button>
              <div className='d-flex flex-row' style={{gap:'0.6rem'}}>
                <Button type='text' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Note</Button>
                <Button type='secondary' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Call</Button>
              </div>
            </Col>
          </Row>
            </>,
  }
 )
}})
  }

  fetchDataWorking = (page, perPage,data) => {
    working_array = []
    this.setState({ isLoading: true })
    API.post(`/working_pipline?page=${this.state.working_current_page}&perPage=${perPage}`,data)
        .then( (response) => {
            this.setState({working_current_page:response.data.leads.current_page+1})
const data_array = response.data.leads.data
for (let i = 0; i < data_array.length; i++) {
working_array.push(
{id:  data_array[i].original_id, //ref no
          label: 
            <div className='d-flex btngrp'>
                    <Button type='text'><i className="bi bi-bookmark"></i></Button>
                    <Checkbox />
                    <Button type='text'><i className="bi bi-pin-angle"></i></Button>
                </div>
              , //Last Activity
          laneId: 2,
          title: 
          <div className="leadname">
          <p>{data_array[i].contact_type == 1?<span className='ctype tenant'>Tenant</span>:null}
            {data_array[i].contact_type == 2?<span className='ctype buyer'>Buyer</span>:null}
            {data_array[i].contact_type == 3?<span className='ctype landlord'>Landlord</span>:null}
            {data_array[i].contact_type == 4?<span className='ctype seller'>Seller</span>:null}
            {data_array[i].contact_type == 6?<span className='ctype agent'>Agent</span>:null}
            {data_array[i].contact_type == 7?<span className='ctype other'>Other</span>:null}
            {data_array[i].contact_type == 8?<span className='ctype portal'>Portal</span>:null}
            {data_array[i].contact_type == 9?<span className='ctype buyertenant'>Buyer/Tenant</span>:null}</p>
      <p className='font-bold font-14'>{data_array[i].fullname}</p>
      <p><span>{data_array[i].mobile}</span>
      </p>
      </div>,
          description: <>
            <Row className='carddesc'>
              <Col span={24} className='cardinfo'>
                <Row gutter={8} className='w-100'>
                    <Col span={24}>
                    <div className="descitem flex-row align-items-start">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row'>
                                        <span>{data_array[i].lead_price? data_array[i].lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED':"-"}</span>
                                    </div>
                                    <i className="bi bi-dot mx-2"></i>
                                    <span>{data_array[i].bedroom? data_array[i].bedroom:"-"} Beds</span>
                                </div>
                            </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-geo-alt-fill mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>-</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Viewing Date: -  {
                       data_array[i].view_date == null || data_array[i].view_date =='' || data_array[i].view_date == "0000-00-00 00:00:00"?
                       ""
                       :
                       <Moment fromNow>
                       {data_array[i].view_date}
                   </Moment>
                    }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Call: 
                                { data_array[i].last_call == null || data_array[i].last_call =='' || data_array[i].last_call == "0000-00-00 00:00:00"? "-"
                                :
                                <Moment fromNow>{data_array[i].last_call}</Moment>
                                }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className='agentwrap'>
                        <Tooltip title="Geoff Forknell" className='d-flex flex-row align-items-center mt-3'>
                            <Avatar size={23} icon={<img src={data_array[i].profile_photo== null || data_array[i].profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(data_array[i].profile_photo)? data_array[i].profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + data_array[i].profile_photo } className='h-100' />} />
                            <p className='ml-2'>{data_array[i].display_name}</p>
                        </Tooltip>
                    </div>
                    </Col>
                </Row>
              </Col>
              <Col span={24} className='footer justify-content-between'>
              <Button type='text' onClick={this.handleOpenDrawer.bind(this, data_array[i].parent_lead, false)} className='editbtn'><i className="bi bi-pencil-fill mr-1 font-10"></i>Edit</Button>
              <div className='d-flex flex-row' style={{gap:'0.6rem'}}>
                <Button type='text' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Note</Button>
                <Button type='secondary' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Call</Button>
              </div>
            </Col>
          </Row>
          </>,
}
)
}


       

        })


}
// viewings
fetchDataViewings = (page, perPage,data) => {
    viewings_array = []
  this.setState({ isLoading: true })
  API.post(`/viewings_pipline?page=${this.state.viewing_current_page}&perPage=${perPage}`,data)
      .then(async (response) => {
        this.setState({viewing_current_page:response.data.leads.current_page+1})
var data_array = response.data.leads.data
for (let i = 0; i < data_array.length; i++) {
viewings_array.push(
{id: data_array[i].original_id, //ref no
        label: <div className='d-flex btngrp'>
        <Button type='text'><i className="bi bi-bookmark"></i></Button>
        <Checkbox />
        <Button type='text'><i className="bi bi-pin-angle"></i></Button>
    </div>
            , //Last Activity
        laneId: 8,
        title: <div className="leadname">
        <p>{data_array[i].contact_type == 1?<span className='ctype tenant'>Tenant</span>:null}
          {data_array[i].contact_type == 2?<span className='ctype buyer'>Buyer</span>:null}
          {data_array[i].contact_type == 3?<span className='ctype landlord'>Landlord</span>:null}
          {data_array[i].contact_type == 4?<span className='ctype seller'>Seller</span>:null}
          {data_array[i].contact_type == 6?<span className='ctype agent'>Agent</span>:null}
          {data_array[i].contact_type == 7?<span className='ctype other'>Other</span>:null}
          {data_array[i].contact_type == 8?<span className='ctype portal'>Portal</span>:null}
          {data_array[i].contact_type == 9?<span className='ctype buyertenant'>Buyer/Tenant</span>:null}</p>
    <p className='font-bold font-14'>{data_array[i].fullname}</p>
    <p><span>{data_array[i].mobile}</span>
    </p>
    </div>,
        description: <>
          <Row className='carddesc'>
              <Col span={24} className='cardinfo'>
                <Row gutter={8} className='w-100'>
                    <Col span={24}>
                    <div className="descitem flex-row align-items-start">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row'>
                                        <span>{data_array[i].lead_price? data_array[i].lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED':"-"}</span>
                                    </div>
                                    <i className="bi bi-dot mx-2"></i>
                                    <span>{data_array[i].bedroom? data_array[i].bedroom:"-"} Beds</span>
                                </div>
                            </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-geo-alt-fill mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>-</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Viewing Date: -  {
                       data_array[i].view_date == null || data_array[i].view_date =='' || data_array[i].view_date == "0000-00-00 00:00:00"?
                       ""
                       :
                       <Moment fromNow>
                       {data_array[i].view_date}
                   </Moment>
                    }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Call: 
                                { data_array[i].last_call == null || data_array[i].last_call =='' || data_array[i].last_call == "0000-00-00 00:00:00"? "-"
                                :
                                <Moment fromNow>{data_array[i].last_call}</Moment>
                                }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className='agentwrap'>
                        <Tooltip title="Geoff Forknell" className='d-flex flex-row align-items-center mt-3'>
                            <Avatar size={23} icon={<img src={data_array[i].profile_photo== null || data_array[i].profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(data_array[i].profile_photo)? data_array[i].profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + data_array[i].profile_photo } className='h-100' />} />
                            <p className='ml-2'>{data_array[i].display_name}</p>
                        </Tooltip>
                    </div>
                    </Col>
                </Row>
              </Col>
              <Col span={24} className='footer justify-content-between'>
              <Button type='text' onClick={this.handleOpenDrawer.bind(this, data_array[i].parent_lead, false)} className='editbtn'><i className="bi bi-pencil-fill mr-1 font-10"></i>Edit</Button>
              <div className='d-flex flex-row' style={{gap:'0.6rem'}}>
                <Button type='text' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Note</Button>
                <Button type='secondary' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Call</Button>
              </div>
            </Col>
          </Row>
        </>,
}
)
}


     

      })


}

fetchDataSecondViewings = (page, perPage,data) => {
    second_viewings_array = []
  this.setState({ isLoading: true })
  API.post(`/second_viewings_pipline?page=${this.state.second_viewing_current_page}&perPage=${perPage}`,data)
      .then(async (response) => {
        this.setState({second_viewing_current_page:response.data.leads.current_page+1})
var data_array = response.data.leads.data
for (let i = 0; i < data_array.length; i++) {
second_viewings_array.push(
{id: data_array[i].original_id, //ref no
        label: <div className='d-flex btngrp'>
        <Button type='text'><i className="bi bi-bookmark"></i></Button>
        <Checkbox />
        <Button type='text'><i className="bi bi-pin-angle"></i></Button>
    </div>
            , //Last Activity
        laneId:16,
        title: 
        <div className="leadname">
        <p>{data_array[i].contact_type == 1?<span className='ctype tenant'>Tenant</span>:null}
          {data_array[i].contact_type == 2?<span className='ctype buyer'>Buyer</span>:null}
          {data_array[i].contact_type == 3?<span className='ctype landlord'>Landlord</span>:null}
          {data_array[i].contact_type == 4?<span className='ctype seller'>Seller</span>:null}
          {data_array[i].contact_type == 6?<span className='ctype agent'>Agent</span>:null}
          {data_array[i].contact_type == 7?<span className='ctype other'>Other</span>:null}
          {data_array[i].contact_type == 8?<span className='ctype portal'>Portal</span>:null}
          {data_array[i].contact_type == 9?<span className='ctype buyertenant'>Buyer/Tenant</span>:null}</p>
    <p className='font-bold font-14'>{data_array[i].fullname}</p>
    <p><span>{data_array[i].mobile}</span>
    </p>
    </div>,
        description: <>
          <Row className='carddesc'>
              <Col span={24} className='cardinfo'>
                <Row gutter={8} className='w-100'>
                    <Col span={24}>
                    <div className="descitem flex-row align-items-start">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row'>
                                        <span>{data_array[i].lead_price? data_array[i].lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED':"-"}</span>
                                    </div>
                                    <i className="bi bi-dot mx-2"></i>
                                    <span>{data_array[i].bedroom? data_array[i].bedroom:"-"} Beds</span>
                                </div>
                            </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-geo-alt-fill mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>-</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Viewing Date: -  {
                       data_array[i].view_date == null || data_array[i].view_date =='' || data_array[i].view_date == "0000-00-00 00:00:00"?
                       ""
                       :
                       <Moment fromNow>
                       {data_array[i].view_date}
                   </Moment>
                    }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Call: 
                                { data_array[i].last_call == null || data_array[i].last_call =='' || data_array[i].last_call == "0000-00-00 00:00:00"? "-"
                                :
                                <Moment fromNow>{data_array[i].last_call}</Moment>
                                }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className='agentwrap'>
                        <Tooltip title="Geoff Forknell" className='d-flex flex-row align-items-center mt-3'>
                            <Avatar size={23} icon={<img src={data_array[i].profile_photo== null || data_array[i].profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(data_array[i].profile_photo)? data_array[i].profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + data_array[i].profile_photo } className='h-100' />} />
                            <p className='ml-2'>{data_array[i].display_name}</p>
                        </Tooltip>
                    </div>
                    </Col>
                </Row>
              </Col>
              <Col span={24} className='footer justify-content-between'>
              <Button type='text' onClick={this.handleOpenDrawer.bind(this, data_array[i].parent_lead, false)} className='editbtn'><i className="bi bi-pencil-fill mr-1 font-10"></i>Edit</Button>
              <div className='d-flex flex-row' style={{gap:'0.6rem'}}>
                <Button type='text' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Note</Button>
                <Button type='secondary' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Call</Button>
              </div>
            </Col>
          </Row>
        </>,
}
)
}


     

      })


}

fetchDataOffers = (page, perPage,data) => {
    offers_array = []
  this.setState({ isLoading: true })
  API.post(`/offers_pipline?page=${this.state.offer_current_page}&perPage=${perPage}`,data)
      .then(async (response) => {
        this.setState({offer_current_page:response.data.leads.current_page+1})
var data_array = response.data.leads.data
for (let i = 0; i < data_array.length; i++) {
offers_array.push(
{id: data_array[i].original_id, //ref no
        label: <div className='d-flex btngrp'>
        <Button type='text'><i className="bi bi-bookmark"></i></Button>
        <Checkbox />
        <Button type='text'><i className="bi bi-pin-angle"></i></Button>
    </div>
            , //Last Activity
        laneId: 6,
        title: 
        <div className="leadname">
        <p>{data_array[i].contact_type == 1?<span className='ctype tenant'>Tenant</span>:null}
          {data_array[i].contact_type == 2?<span className='ctype buyer'>Buyer</span>:null}
          {data_array[i].contact_type == 3?<span className='ctype landlord'>Landlord</span>:null}
          {data_array[i].contact_type == 4?<span className='ctype seller'>Seller</span>:null}
          {data_array[i].contact_type == 6?<span className='ctype agent'>Agent</span>:null}
          {data_array[i].contact_type == 7?<span className='ctype other'>Other</span>:null}
          {data_array[i].contact_type == 8?<span className='ctype portal'>Portal</span>:null}
          {data_array[i].contact_type == 9?<span className='ctype buyertenant'>Buyer/Tenant</span>:null}</p>
    <p className='font-bold font-14'>{data_array[i].fullname}</p>
    <p><span>{data_array[i].mobile}</span>
    </p>
    </div>,
        description: <>
          <Row className='carddesc'>
              <Col span={24} className='cardinfo'>
                <Row gutter={8} className='w-100'>
                    <Col span={24}>
                    <div className="descitem flex-row align-items-start">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row'>
                                        <span>{data_array[i].lead_price? data_array[i].lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED':"-"}</span>
                                    </div>
                                    <i className="bi bi-dot mx-2"></i>
                                    <span>{data_array[i].bedroom? data_array[i].bedroom:"-"} Beds</span>
                                </div>
                            </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-geo-alt-fill mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>-</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Viewing Date: -  {
                       data_array[i].view_date == null || data_array[i].view_date =='' || data_array[i].view_date == "0000-00-00 00:00:00"?
                       ""
                       :
                       <Moment fromNow>
                       {data_array[i].view_date}
                   </Moment>
                    }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Call: 
                                { data_array[i].last_call == null || data_array[i].last_call =='' || data_array[i].last_call == "0000-00-00 00:00:00"? "-"
                                :
                                <Moment fromNow>{data_array[i].last_call}</Moment>
                                }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className='agentwrap'>
                        <Tooltip title="Geoff Forknell" className='d-flex flex-row align-items-center mt-3'>
                            <Avatar size={23} icon={<img src={data_array[i].profile_photo== null || data_array[i].profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(data_array[i].profile_photo)? data_array[i].profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + data_array[i].profile_photo } className='h-100' />} />
                            <p className='ml-2'>{data_array[i].display_name}</p>
                        </Tooltip>
                    </div>
                    </Col>
                </Row>
              </Col>
              <Col span={24} className='footer justify-content-between'>
              <Button type='text' onClick={this.handleOpenDrawer.bind(this, data_array[i].parent_lead, false)} className='editbtn'><i className="bi bi-pencil-fill mr-1 font-10"></i>Edit</Button>
              <div className='d-flex flex-row' style={{gap:'0.6rem'}}>
                <Button type='text' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Note</Button>
                <Button type='secondary' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Call</Button>
              </div>
            </Col>
          </Row>
        </>,
}
)
}


     

      })


}


fetchDataOffersAccepted = (page, perPage,data) => {
    offers_accepted_array = []
  this.setState({ isLoading: true })
  API.post(`/offers_accepted_pipline?page=${this.state.offers_accepted_array}&perPage=${perPage}`,data)
      .then(async (response) => {
        this.setState({offers_accepted_array:response.data.leads.current_page+1})
var data_array = response.data.leads.data
for (let i = 0; i < data_array.length; i++) {
offers_accepted_array.push(
{id: data_array[i].original_id, //ref no
        label: <div className='d-flex btngrp'>
        <Button type='text'><i className="bi bi-bookmark"></i></Button>
        <Checkbox />
        <Button type='text'><i className="bi bi-pin-angle"></i></Button>
    </div>
            , //Last Activity
        laneId: 13,
        title: 
        <div className="leadname">
        <p>{data_array[i].contact_type == 1?<span className='ctype tenant'>Tenant</span>:null}
          {data_array[i].contact_type == 2?<span className='ctype buyer'>Buyer</span>:null}
          {data_array[i].contact_type == 3?<span className='ctype landlord'>Landlord</span>:null}
          {data_array[i].contact_type == 4?<span className='ctype seller'>Seller</span>:null}
          {data_array[i].contact_type == 6?<span className='ctype agent'>Agent</span>:null}
          {data_array[i].contact_type == 7?<span className='ctype other'>Other</span>:null}
          {data_array[i].contact_type == 8?<span className='ctype portal'>Portal</span>:null}
          {data_array[i].contact_type == 9?<span className='ctype buyertenant'>Buyer/Tenant</span>:null}</p>
    <p className='font-bold font-14'>{data_array[i].fullname}</p>
    <p><span>{data_array[i].mobile}</span>
    </p>
    </div>,
        description: <>
          <Row className='carddesc'>
              <Col span={24} className='cardinfo'>
                <Row gutter={8} className='w-100'>
                    <Col span={24}>
                    <div className="descitem flex-row align-items-start">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row'>
                                        <span>{data_array[i].lead_price? data_array[i].lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED':"-"}</span>
                                    </div>
                                    <i className="bi bi-dot mx-2"></i>
                                    <span>{data_array[i].bedroom? data_array[i].bedroom:"-"} Beds</span>
                                </div>
                            </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-geo-alt-fill mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>-</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Viewing Date: -  {
                       data_array[i].view_date == null || data_array[i].view_date =='' || data_array[i].view_date == "0000-00-00 00:00:00"?
                       ""
                       :
                       <Moment fromNow>
                       {data_array[i].view_date}
                   </Moment>
                    }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <div className='d-flex flex-column'>
                                <span>Last Call: 
                                { data_array[i].last_call == null || data_array[i].last_call =='' || data_array[i].last_call == "0000-00-00 00:00:00"? "-"
                                :
                                <Moment fromNow>{data_array[i].last_call}</Moment>
                                }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className='agentwrap'>
                        <Tooltip title="Geoff Forknell" className='d-flex flex-row align-items-center mt-3'>
                            <Avatar size={23} icon={<img src={data_array[i].profile_photo== null || data_array[i].profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(data_array[i].profile_photo)? data_array[i].profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + data_array[i].profile_photo } className='h-100' />} />
                            <p className='ml-2'>{data_array[i].display_name}</p>
                        </Tooltip>
                    </div>
                    </Col>
                </Row>
              </Col>
              <Col span={24} className='footer justify-content-between'>
              <Button type='text' onClick={this.handleOpenDrawer.bind(this, data_array[i].parent_lead, false)} className='editbtn'><i className="bi bi-pencil-fill mr-1 font-10"></i>Edit</Button>
              <div className='d-flex flex-row' style={{gap:'0.6rem'}}>
                <Button type='text' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Note</Button>
                <Button type='secondary' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Call</Button>
              </div>
            </Col>
          </Row>
        </>,
}
)
}


     

      })


}

fetchDataPipeline = (page, perPage,data) => {
    pipelines_array = []
  this.setState({ isLoading: true })
  API.post(`/pipelines?page=${this.state.pipeline_current_page}&perPage=${perPage}`,data)
      .then( (response) => {
        this.setState({pipeline_current_page:response.data.leads.current_page+1})
var data_array = response.data.leads.data
for (let i = 0; i < data_array.length; i++) {
pipelines_array.push(
{id: data_array[i].original_id, //ref no
        label: <div className='d-flex btngrp'>
        <Button type='text'><i className="bi bi-bookmark"></i></Button>
        <Checkbox />
        <Button type='text'><i className="bi bi-pin-angle"></i></Button>
    </div>
            , //Last Activity
        laneId: 7,
        title: 
            <div className="leadname">
                <p>{data_array[i].contact_type == 1?<span className='ctype tenant'>Tenant</span>:null}
                  {data_array[i].contact_type == 2?<span className='ctype buyer'>Buyer</span>:null}
                  {data_array[i].contact_type == 3?<span className='ctype landlord'>Landlord</span>:null}
                  {data_array[i].contact_type == 4?<span className='ctype seller'>Seller</span>:null}
                  {data_array[i].contact_type == 6?<span className='ctype agent'>Agent</span>:null}
                  {data_array[i].contact_type == 7?<span className='ctype other'>Other</span>:null}
                  {data_array[i].contact_type == 8?<span className='ctype portal'>Portal</span>:null}
                  {data_array[i].contact_type == 9?<span className='ctype buyertenant'>Buyer/Tenant</span>:null}</p>
            <p className='font-bold font-14'>{data_array[i].fullname}</p>
            <p><span>{data_array[i].mobile}</span>
            </p>
            </div>,
        description: <>
          <Row className='carddesc'>
              <Col span={24} className='cardinfo'>
                <Row gutter={8} className='w-100'>
                    <Col span={24}>
                    <div className="descitem flex-row align-items-start">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row'>
                                        <span>{data_array[i].lead_price? data_array[i].lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED':"-"}</span>
                                    </div>
                                    <i className="bi bi-dot mx-2"></i>
                                    <span>{data_array[i].bedroom? data_array[i].bedroom:"-"} Beds</span>
                                </div>
                            </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-geo-alt-fill mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>-</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-clock-history mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>Last Viewing Date: -  {
                       data_array[i].view_date == null || data_array[i].view_date =='' || data_array[i].view_date == "0000-00-00 00:00:00"?
                       ""
                       :
                       <Moment fromNow>
                       {data_array[i].view_date}
                   </Moment>
                    }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="descitem flex-row align-items-start">
                            <i className="bi bi-clock-history mr-1"></i>
                            <div className='d-flex flex-column'>
                                <span>Last Call: 
                                { data_array[i].last_call == null || data_array[i].last_call =='' || data_array[i].last_call == "0000-00-00 00:00:00"? "-"
                                :
                                <Moment fromNow>{data_array[i].last_call}</Moment>
                                }</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className='agentwrap'>
                        <Tooltip title="Geoff Forknell" className='d-flex flex-row align-items-center mt-3'>
                            <Avatar size={23} icon={<img src={data_array[i].profile_photo== null || data_array[i].profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": this.isValidHttpUrl(data_array[i].profile_photo)? data_array[i].profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + data_array[i].profile_photo } className='h-100' />} />
                            <p className='ml-2'>{data_array[i].display_name}</p>
                        </Tooltip>
                    </div>
                    </Col>
                </Row>
              </Col>
              <Col span={24} className='footer justify-content-between'>
              <Button type='text' onClick={this.handleOpenDrawer.bind(this, data_array[i].parent_lead, false)} className='editbtn'><i className="bi bi-pencil-fill mr-1 font-10"></i>Edit</Button>
              <div className='d-flex flex-row' style={{gap:'0.6rem'}}>
                <Button type='text' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Note</Button>
                <Button type='secondary' onClick={this.handleOpenEditDrawer} className='editbtn'>Add Call</Button>
              </div>
            </Col>
          </Row>
        </>,
}
)
}


     

      })


}




// EDIT DRAWER
handleOpenEditDrawer=()=>{ this.setState({isOpenEditLead:true})}
handleCloseEditDrawer=()=>{ this.setState({isOpenEditLead:false})}




 handleDragStart = (cardId, laneId) => {
    // console.log('drag started')
    // console.log(`cardId: ${cardId}`)
    // console.log(`laneId: ${laneId}`)
  }
  
   handleDragEnd = (cardId, sourceLaneId, targetLaneId,position,cardDetails) => {

   

   
    const page = 1
    const perPage=10
    if(targetLaneId ==2 && sourceLaneId !=2){
    this.setState({isCallLogOpen:true})
    }else
    if(targetLaneId == 8 && sourceLaneId !=8){
    this.setState({isviewingOpen:true})
    }else

    if((sourceLaneId != 16 && targetLaneId == 16) || (sourceLaneId !=6 &&targetLaneId == 6)){
        API.get("/get_viewing_by_lead/"+cardId)
        .then((res) =>{
            if(res.data.success){
                this.setState({iseditviewingOpen:true,viewings:res.data.lead,viewing_status: res.data.lead.status,
                    viewing_note: res.data.lead.note, viewing_date: res.data.lead.view_date,
                    showPrice: res.data.lead.status == "Offer" ? true : false,
                    showDate: res.data.lead.status == "Scheduled" ? true : false,
                    showPrice: res.data.lead.status == "Offer" ? true : false,
                    
                    viewing_price: res.data.lead.amount})
            }
        })

     
    }
else
    if(targetLaneId == 13 && sourceLaneId != 13){
        API.get("/get_offers_by_lead/"+cardId)
        .then((res) =>{
            if(res.data.success){
                this.setState({
                    offerModal:true,offers:res.data.lead,viewing_status: res.data.lead.status,
                    offers_note: res.data.lead.note, offers_date: res.data.lead.view_date,
                    showPrice: res.data.lead.status == "Offer" ? true : false,
                    showDate: res.data.lead.status == "Scheduled" ? true : false,
                    showCommission: res.data.lead.status == "Offer" ? true : false,
                    offers_price:res.data.lead.price,
                    offers_commission:res.data.lead.comission,
                    showReferralAgent:res.data.lead.offer_status == 4 ? true : false,
                    viewing_price: res.data.lead.amount})
            }
        })
// this.setState({offerModal:true})
    }else
    if(targetLaneId == 7 && sourceLaneId !=7){
        const data = {leads_id:cardId}
        API.post("/move_to_pipeline",data)
        .then((response)=>{
            console.log("re",response.data);
        })
    }
    

   
    

    this.setState({record_id:cardId,sourceLaneId:sourceLaneId,targetLaneId:targetLaneId})

   
    return
    this.fetchDataNewEnquiry(page, perPage);
    this.fetchDataWorking(page, perPage);
    this.fetchDataViewings(page, perPage);
    this.fetchDataSecondViewings(page, perPage);
    this.fetchDataOffers(page, perPage);
    this.fetchDataOffersAccepted(page, perPage);
    // console.log('drag ended')

  }
  
  agentRender = (agent_id)=>{
    // let agent =  (this.state.agents) ? this.state.agents.find(items => items.value === agent_id) : record.agents;
    // return (agent && agent.label) ? agent.label : agent;
  }
  isValidHttpUrl = (string) =>{
    try {
        const url = new URL(string);
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
        return false;
    }

}

saveContactType = (value) => {
    this.setState({contact_type_requirement:value})
    // return
    // const data = { leads_id: this.state.record_id, verifier_by: value }
    this.setState({
        leads_id: this.state.record_id,
    })
    // API.post("/update_contact_type", { leads_id: this.state.record_id, contact_type: value })
    //     .then((response) => {

    //         if (response.data.success) {
    //             if(value==1){
    //                 this.setState({contact_type_requirement:1})
    //             }else{ this.setState({contact_type_requirement:value})}
    //             this.setState({
    //                 all: response.data.all,
    //                 notes: response.data.notes
    //             })
    //         }
    //     })

}

saveMoveInDate = (value) => {
    // const data = { leads_id: this.state.record_id, move_in_date: value }
    // edit by Farooq, the above one is wrong date state...
    this.setState({
        leads_id: this.state.record_id,
        requirement_move_in_date: value
    })
    // API.post("/update_move_in_date", {leads_id: this.state.record_id, move_in_date: value})
    //     .then((response) => {
    //         if (response.data.success) {
    //             this.setState({
    //                 all: response.data.all,
    //                 notes: response.data.notes
    //             })
    //         }
    //     })
}

saveCommunity = (value) => {
    console.log(value);
    this.setState({ requirement_community: value })
    const data = { leads_id: this.state.record_id, community: value }
    // API.post("/update_community", data)
    //     .then((response) => {



    //         if (response.data.success) {
    //             this.setState({
    //                 all: response.data.all,
    //                 notes: response.data.notes
    //             })
    //         }
    //     })
    if( value !== null || value !== ''){
        API.get("/sub_community/" + value)
            .then((response) => {
                if (response.data.success)
                    this.setState({ sub_communities: response.data.sub_communities })
            })
    }
}

saveSubCommunity = (value) => {
    this.setState({ requirement_sub_community: value })
    const data = { leads_id: this.state.record_id, subcommunity: value }
    // API.post("/update_subcommunity", data)
    //     .then((response) => {



    //         if (response.data.success) {
    //             this.setState({
    //                 all: response.data.all,
    //                 notes: response.data.notes
    //             })
    //         }
    //     })
}


saveBedroom = (value) => {
    const data = { leads_id: this.state.record_id, bedroom: value }
    // API.post("/update_bedroom", data)
    //     .then((response) => {

    //         if (response.data.success) {
    //             this.setState({
    //                 all: response.data.all,
    //                 notes: response.data.notes,
    //                 bedroom_match:value
    //             })
    //             this.fetchExactListings()
    //             this.fetchCoparableListings()
    //         }
    //     })
}
savePrice = (value) => {

    const value_ = value
    const new_value = value_.replace(/,/g, "")

    this.setState({
        price_requirement: value,min_budget:new_value
    })
    if (new_value > 0) {
        const data = { leads_id: this.state.record_id, price_requirement: new_value }
        // API.post("/update_price", data)
        //     .then((response) => {

        //         if (response.data.success) {
        //             this.setState({
        //                 all: response.data.all,
        //                 notes: response.data.notes
        //             })
        //             this.fetchExactListings()
        //             this.fetchCoparableListings()
        //         }
        //     })
    }

}
onSelectCallMentions = (option) => {
    this.setState({ selectedCallUsers: [...this.state.selectedCallUsers, option] })

}
onChangeCallNote = (value) => {
    this.setState({ call_log: value })
}
handleCallLogModalCancel=()=>{
    // const page =1;
    // const perPage = 10
    this.setState({isCallLogOpen:false})
    // this.fetchDataNewEnquiry(page, perPage);
    // this.fetchDataWorking(page, perPage);
    // this.fetchDataViewings(page, perPage);
    // this.fetchDataSecondViewings(page, perPage);
    // this.fetchDataOffers(page, perPage);
    // this.fetchDataOffersAccepted(page, perPage);
  }

  handleCallSubmit = () =>{
    const page = 1;
    const perPage = 10;
    const data = {
        leads_id:this.state.record_id,sourceLaneId:this.state.sourceLaneId,targetLaneId:this.state.targetLaneId,
        note: this.state.call_log,
        note_type: "phone",
        users: this.state.selectedCallUsers
    }
    API.post("/change_pipline_trip",data)
    .then((res) =>{
        if(res.data.success){
        if(this.state.sourceLaneId == 1 || this.state.targetLaneId == 1){
            this.fetchDataNewEnquiry(page, perPage);
         
        }
        if(this.state.sourceLaneId == 2 || this.state.targetLaneId == 2){
            this.fetchDataWorking(page, perPage);
        }
        if(this.state.sourceLaneId == 8 || this.state.targetLaneId == 8){
            this.fetchDataViewings(page, perPage); 
        }
        if(this.state.sourceLaneId == 16 || this.state.targetLaneId == 16){
            this.fetchDataSecondViewings(page, perPage);
        }
        if(this.state.sourceLaneId == 6 || this.state.targetLaneId == 6){
            this.fetchDataOffers(page, perPage);
        }
        if(this.state.sourceLaneId == 13 || this.state.targetLaneId == 13){
            this.fetchDataOffersAccepted(page, perPage); 
        }
        
    }
    })
    this.setState({isCallLogOpen:false})
   
  }
  handleLaneScroll = (requestedPage, laneId) =>{
    console.log("page",requestedPage);
    console.log("laneId",laneId);
  }

  onSearchRefNo = (e) => {
    if (!e || e == '' || e == null) {
        this.setState({ ref_nums: [] })
        return
    }
    API.get("/get_ref_no/" + e)
        .then((response) => {
            if (response.data.success) {
                this.setState({ ref_nums: response.data.ref_nums })
            }
        })
}
  handleRefsno = (e) => {
    this.setState({ viewing_ref_no: e, ref_def_nums: e })
    const data = {
        ref_nums: e
    }


    API.post("/get_all_listing_by_refnos", data)
        .then((response) => {

            if (response.data.success) {
                this.setState({ viewings_listings: response.data.listings })
            }
        })

}

handleUnitno = (e) => {



    if (!e || e == '' || e == null) {
        API.get("/get_old_unit/" + this.state.old_unit_num)
            .then((response) => {

                const refno = response.data.listing.value

                this.setState({
                    ref_def_nums: this.state.ref_def_nums.filter(function (value) {
                        return value.refno !== refno
                    })
                });

                // this.setState({viewings_listings: this.state.viewings_listings.filter(function(viewing_listing) {
                //   return viewing_listing.refno !== refno
                //  })});



            })
        // this.setState({ref_def_nums:[]})
        return
    }
    this.setState({ viewing_unit_no: e })
    const data = {
        unit_num: e
    }
    API.post("/get_all_listing_by_unitno", data)
        .then((response) => {
            if (response.data.success) {
                const listing = response.data.all_listings
                const unit = listing.unitno
                let data = response.data.all_listings
                const isFound = this.state.viewings_listings.some(element => {
                    if (element.unitno === e) {
                        return true;
                    }

                    return false;
                });
                if (!isFound) {
                    this.setState({ viewings_listings: [...this.state.viewings_listings, response.data.listing], ref_def_nums: [...this.state.ref_def_nums, data.value], old_unit_num: e, viewing_ref_no: [...this.state.viewing_ref_no, data.value] })
                } else {
                    return
                }
            }
        })
}

handleSearchUintNo = (e) => {


    if (!e || e == '' || e == null) {
        // this.setState({ref_nums:[]})
        return
    }

    API.get("/get_listing_by_unitno/" + e)
        .then((response) => {
            if (response.data.success) {
                this.setState({ unit_nums: response.data.unit_nums })
            }
        })
}

handleRemoveList = (refno, e) => {


    this.setState({
        viewings_listings: this.state.viewings_listings.filter(function (viewing_listing) {
            return viewing_listing.refno !== refno
        })
    });

    this.setState({
        ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
            return ref_num.value !== refno
        })
    });

    this.setState({
        ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
            return ref_num !== refno
        })
    });
}

handleViewingCancel = () =>{
    this.setState({isviewingOpen:false})
}

handleViewingAgent = (e) => {
    this.setState({ viewing_agent: e })
}

handleViewingDate = (date, dateString) => {
    this.setState({ viewing_date:dateString })
};

handleViewingOk = () => {
    const page = 1;
    const perPage = 10
    const data = {
        leads_id:this.state.record_id,sourceLaneId:this.state.sourceLaneId,targetLaneId:this.state.targetLaneId,
        lead_ref_no: this.state.lead_details.refno,
        viewing_ref_no: this.state.viewing_ref_no,
        viewing_agent: this.state.viewing_agent,
        viewing_date: this.state.viewing_date,
    }
    API.post("/change_pipline_viewing",data)
    .then((res) =>{
        if(res.data.success){
        if(this.state.sourceLaneId == 1 || this.state.targetLaneId == 1){
            this.fetchDataNewEnquiry(page, perPage);
         
        }
        if(this.state.sourceLaneId == 2 || this.state.targetLaneId == 2){
            this.fetchDataWorking(page, perPage);
        }
        if(this.state.sourceLaneId == 8 || this.state.targetLaneId == 8){
            this.fetchDataViewings(page, perPage); 
        }
        if(this.state.sourceLaneId == 16 || this.state.targetLaneId == 16){
            this.fetchDataSecondViewings(page, perPage);
        }
        if(this.state.sourceLaneId == 6 || this.state.targetLaneId == 6){
            this.fetchDataOffers(page, perPage);
        }
        if(this.state.sourceLaneId == 13 || this.state.targetLaneId == 13){
            this.fetchDataOffersAccepted(page, perPage); 
        }
        
    }
    })
    this.setState({isviewingOpen:false})
}


handleViewStatus = (e) => {
    const status = e
    this.setState({ viewing_status: e })
    if (status == "Offer") {
        this.setState({ showPrice: true })
    } else {
        this.setState({ showPrice: false })
    }

    if (status == "Scheduled") {
        this.setState({ showDate: true })
    } else {
        this.setState({ showDate: false })
    }
}

handleViewingPrice = (e) => {
    this.setState({ viewing_price: e.target.value })
}

handleViewStatusCancel = () =>{
    this.setState({iseditviewingOpen:false})
}
handleViewingNote = (e) => {
    this.setState({ viewing_note: e.target.value })
}
handleViewStatusChange = () => {
    const page = 1;
    const perPage = 10
    const data = { lead_id: this.state.record_id,sourceLaneId:this.state.sourceLaneId,targetLaneId:this.state.targetLaneId,
         view_id: this.state.viewings.view_id, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price, 
         note: this.state.viewing_note }
  
    API.post("/change_pipline_second_viewing",data)
    .then((res) =>{
        if(res.data.success){
        if(this.state.sourceLaneId == 1 || this.state.targetLaneId == 1){
            this.fetchDataNewEnquiry(page, perPage);
         
        }
        if(this.state.sourceLaneId == 2 || this.state.targetLaneId == 2){
            this.fetchDataWorking(page, perPage);
        }
        if(this.state.sourceLaneId == 8 || this.state.targetLaneId == 8){
            this.fetchDataViewings(page, perPage); 
        }
        if(this.state.sourceLaneId == 16 || this.state.targetLaneId == 16){
            this.fetchDataSecondViewings(page, perPage);
        }
        if(this.state.sourceLaneId == 6 || this.state.targetLaneId == 6){
            this.fetchDataOffers(page, perPage);
        }
        if(this.state.sourceLaneId == 13 || this.state.targetLaneId == 13){
            this.fetchDataOffersAccepted(page, perPage); 
        }
        
    }
    })
    // this.setState({isviewingOpen:false})
   // this.setState({ iseditviewingOpen: false })
}
handleCancel = () => {
    this.setState({ offerModal: false })
  }
  handleOfferingDate = (date, dateString) => {
    this.setState({ offers_date: dateString })
  };
  handleOfferStatus = (e) => {
    const status = e.target.value
    this.setState({ offers_status: e.target.value })
    this.setState({ showPrice: true ,showCommission:true})
  }

  handleOfferingPrice = (e) => {
    this.setState({ offers_price: e.target.value })
  }

  handleOfferingCommission  = (e) => {
    this.setState({ offers_commission: e.target.value })
  }

  // Handle offer Note
  handleOfferingNote = (e) => {
    this.setState({ offers_note: e.target.value })
  }

  // Handle offer Date
  handleOfferingDate = (date, dateString) => {
    this.setState({ offers_date: dateString })
  };
  handleReferral = (e) =>{
    const refrral_value = e.target.checked
    this.setState({ReferralChecked:!this.state.ReferralChecked})
    if(refrral_value){
      this.setState({showReferralAgent:true})
    }else{
      this.setState({showReferralAgent:false,selectedRefrralAgent:"",refrral_commission:""})
  
    }
  }
  
  handleReferralAgent = (e) =>{
   this.setState({selectedRefrralAgent:e})
  }

   handleRefrralCommission = (e) =>{
     this.setState({refrral_commission:e.target.value})
   }

   handleChangeStatus = () => {
    const page = 1;
    const perPage = 10
    const data = { lead_id: this.state.record_id,sourceLaneId:this.state.sourceLaneId,targetLaneId:this.state.targetLaneId,
        view_id: this.state.offers.view_id,
        vendor_agent_id:this.state.vendor_agent_id,
        client_agent_id:this.state.client_agent_id,
        split_commission:this.state.split_commission,
        // viewing_date:this.state.offers_date, 
        offer_status: this.state.offers_status,
        viewing_price: this.state.offers_price,
        commission_price: this.state.offers_commission,
        note: this.state.offers_note,
        selectedRefrralAgent:this.state.selectedRefrralAgent,
        refrral_commission:this.state.refrral_commission,
        offer_date: this.state.offers_date }
  
    API.post("/change_pipline_offer_accepted",data)
    .then((res) =>{
        if(res.data.success){
        if(this.state.sourceLaneId == 1 || this.state.targetLaneId == 1){
            this.fetchDataNewEnquiry(page, perPage);
         
        }
        if(this.state.sourceLaneId == 2 || this.state.targetLaneId == 2){
            this.fetchDataWorking(page, perPage);
        }
        if(this.state.sourceLaneId == 8 || this.state.targetLaneId == 8){
            this.fetchDataViewings(page, perPage); 
        }
        if(this.state.sourceLaneId == 16 || this.state.targetLaneId == 16){
            this.fetchDataSecondViewings(page, perPage);
        }
        if(this.state.sourceLaneId == 6 || this.state.targetLaneId == 6){
            this.fetchDataOffers(page, perPage);
        }
        if(this.state.sourceLaneId == 13 || this.state.targetLaneId == 13){
            this.fetchDataOffersAccepted(page, perPage); 
        }
        
    }
    })
   }

   handleOpenDrawer = (id, xPrev_Next) => {
    // console.log("**** ID *** ", id)
    if(xPrev_Next){
        this.setState({
            loader: true
        });
    }

    this.setState({matchs:[], not_matchs: []})
    // By Farooq
    // const nextId = this.findNextOrder(id);
    // const prevId = this.findPrevOrder(id);
    // console.log("**Now Id***", id);
    // console.log("**Prev Id***", prevId);
    // console.log("**Next Id***", nextId);

    // this.setState({
    //     // record_id: xPrev_Next ? this.state.record_id : id,
    //     nextId: nextId == undefined ? this.state.nextId : nextId.original_id,
    //     prevId: prevId == undefined ? this.state.prevId : prevId.original_id,
    //     currId: id
    // })
    ///////////////////
    this.setState({record_id: id})

    API.get("/lead_details/" + id)
        .then((response) => {



            if (response.data.success){
             
                this.setState({currentRequest: null})
                this.setState({
                    lead_details: response.data.lead_details, lead_list_details: response.data.lead_list_details, owner_other_properties: response.data.owner_other_properties,
                    all: response.data.all,
                    role: response.data.role.role,
                    agent_id: response.data.role.id,
                    requested_viewings: response.data.requested_viewings,
                    notes: response.data.notes, requirement_community: response.data.lead_list_details.community,
                    community_match:response.data.lead_list_details.community,
                    sub_community_match:response.data.lead_list_details.subcommunity,
                    min_budget: response.data.lead_details.budget_from,
                    max_budget: response.data.lead_details.budget_to,
                    bedroom_match: response.data.lead_list_details.bedroom,
                    comment: response.data.lead_details.comment,

                    call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                    //viewing_notes: response.data.viewing_notes,
                    email_notes: response.data.email_notes,
                    followup_notes: response.data.followup_notes,
                    offer_notes: response.data.offer_notes,
                    viewing_notes: response.data.viewing_notes,
                    owner_id: response.data.lead_list_details.owner_id,
                    owner_name: response.data.lead_list_details.owner,
                    owner_email: (response.data.lead_list_details && response.data.lead_list_details.owner_email) ? response.data.lead_list_details.owner_email : "",
                    owner_mobile: response.data.lead_list_details.mobile,
                    requirement_bedroom: response.data.lead_list_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                    requirement_max_budget: response.data.lead_details.budget_to,requirement_verifyedby:response.data.lead_details.verified_by,
                    requirement_lead_stage: response.data.lead_list_details.lead_stage,pin:response.data.lead_details.pinned ==1 || response.data.lead_details.pinned ==2?true:false,
                    requirement_community: response.data.lead_list_details.community, requirement_sub_community: response.data.lead_list_details.subcommunity,
                    requirement_agent_id: response.data.lead_list_details.lead_agent, lead_community: response.data.lead_list_details.community, lead_sub_community: response.data.lead_list_details.subcommunity,
                    contact_type_requirement:response.data.lead_list_details.contact_type,price_requirement:response.data.lead_list_details.lead_price,
                    collaborators: response.data.collaborators,requirement_move_in_date:response.data.lead_list_details.move_in_date,
                    property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                    move_in_date : response.data.lead_list_details.move_in_date,
                    // Farooq
                    loader: false
                })

                

                const lead_stage = response.data.lead_list_details.lead_stage
                if (lead_stage == 1) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '1') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '1') {
                            return true;
                        }

                        return false;
                    });

                    const item = {
                        label: 'New Inquiry',
                        value: '1'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })

                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })

                }else
                if (lead_stage == 2) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '2') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '2') {
                            return true;
                        }

                        return false;
                    });



                    const item = {
                        label: 'Working',
                        value: '2'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 3) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '3') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '3') {
                            return true;
                        }

                        return false;
                    });
                    const item = {
                        label: 'Hot',
                        value: '3'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 4) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '4') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '4') {
                            return true;
                        }

                        return false;
                    });
                    const item = {
                        label: 'Follow Up',
                        value: '4'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 5) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '5') {
                            return true;
                        }

                        return false;
                    });
                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '5') {
                            return true;
                        }

                        return false;
                    });

                    const item = {
                        label: 'Dead',
                        value: '5'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 6) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '6') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '6') {
                            return true;
                        }

                        return false;
                    });


                    const item = {
                        label: 'Offer',
                        value: '6'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 7) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '7') {
                            return true;
                        }

                        return false;
                    });
                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '7') {
                            return true;
                        }

                        return false;
                    });

                    const item = {
                        label: 'Deal signed',
                        value: '7'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else


                if (lead_stage == 8) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '8') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '8') {
                            return true;
                        }

                        return false;
                    });

                    const item = {
                        label: 'Viewings',
                        value: '8'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 9) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '9') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '9') {
                            return true;
                        }

                        return false;
                    });
                    const item = {
                        label: 'Recruitment',
                        value: '9'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 10) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '10') {
                            return true;
                        }

                        return false;
                    });
                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '10') {
                            return true;
                        }

                        return false;
                    });

                    const item = {
                        label: 'No Answer',
                        value: '10'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 11) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '11') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '11') {
                            return true;
                        }

                        return false;
                    });

                    const item = {
                        label: 'Expired',
                        value: '11'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 12) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '12') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '12') {
                            return true;
                        }

                        return false;
                    });


                    const item = {
                        label: 'Pond',
                        value: '12'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 13) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '13') {
                            return true;
                        }

                        return false;
                    });
                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '13') {
                            return true;
                        }

                        return false;
                    });


                    const item = {
                        label: 'Offer accepted',
                        value: '13'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }else
                if (lead_stage == 14) {


                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                        if (element.value === '14') {
                            return true;
                        }

                        return false;
                    });

                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                        if (element.value === '14') {
                            return true;
                        }

                        return false;
                    });


                    const item = {
                        label: 'Offer declined',
                        value: '14'

                    }
                    if(!isViewingFound)
                    this.setState({
                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                    })
                    if(!isViewingFoundAgent)
                    this.setState({
                        lead_stage_filter_agent:[...this.state.lead_stage_filter_agent, item]
                    })
                }

                // API.get("/sub_community/" + response.data.lead_details.community,)
                //     .then((response) => {
                //         if (response.data.success)
                //             this.setState({ sub_communities: response.data.sub_communities })
                //     })

                if (this.state.requested_viewings != []){
                    setInterval(() => {
                        this.getRemainingTime(this.state.requested_viewings && this.state.requested_viewings[0] && this.state.requested_viewings[0].added_date)
                    }, 1000)
                }
            }
            else{
                this.setState({
                    loader: false
                })
            }
            this.fetchFollowUpDetails(this.state.record_id)
            // By Farooq
            if (!xPrev_Next) {
                this.setState({
                    open: true,
                    disabledNext: false,
                    disabledPrev: false
                })
            }

            ///// chnages By Farooq ////
            // if(!this.props.match.params.id){
            //   this.setState({currUrl: window.location.href })
            //   window.history.replaceState(null, "Leads Id", window.location.href+'/'+id)
            // }

            // this.setState({ currUrl: window.location.href })
            // window.history.replaceState(null, "Leads Id", window.location.href + '/' + id)
            /////////////////////////


            API.get("/all_agents")
                .then((response) => {
                        if (response.data.success) {
                            this.setState({ agents: response.data.agents })
                        }
                    }
                )

            this.fetchFollowUp(id);
            const community = response.data.lead_list_details.community
            if(community == '' || community == null){
                console.log("community empty ", true)
            }
            else{
                API.get("/sub_community/" + community)
                    .then((response) => {
                        if (response.data.success)
                            this.setState({ requirement_sub_communities: response.data.sub_communities })
                    })

            }

        })
        .catch((err) => {
            console.log(err);
            this.setState({
                loader: false
            })
        })



}

getCurrentTime = (date) => {
    if (date != null && date != "" && date != undefined && date != "0000-00-00 00:00:00"){
        let newDate = new Date(date);
        newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
        return newDate.toISOString();
    }
}

getTime = (date) => {
    const added_date = new Date(date);
    const formattedTime = added_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return formattedTime;
}
getDate = (date) => {
    const added_date = new Date(date);
    const formattedDate = added_date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
    return formattedDate;
}

getPrice = (price) => {
    if (price != null && price != "" && price != undefined){
        if(!isNaN(parseInt(price))){
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
        }
    }
}
getUnixTime = (time) => {
    const getRegionalTime = this.getCurrentTime(time);
    const originalTimestamp = new Date(getRegionalTime).getTime() / 1000;
    return originalTimestamp;
}

getCurrentTimeStamp = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const unixTime = new Date(timestamp).getTime() / 1000;
    return unixTime;
}
getRemainingTime = (added_date) => {
    const dateObj = new Date(added_date);
    const unixTime = dateObj.getTime() / 1000;
    const addedDateUnixTime = unixTime + 28 * 60 * 60;
    let currentDateUnixTime = this.getCurrentTimeStamp();
    let timeDifference = addedDateUnixTime - currentDateUnixTime;
    if (timeDifference <= 0){
        this.setState({remainingTime: "00:00:00"})
        // return "00:00:00";
        // const postData = {
        //     "current_request": data,
        // }
        // API.post("/request_time_expire", postData)
        //     .then((response)=>{
        //         if(response.data.success){
        //             this.setState({all: response.data.all})
        //             this.setState({requested_viewings: response.data.requested_viewings})
        //         }
        //     }).catch((err) => {
        //     console.log(err)
        //     toast.error('Something went wrong');
        // })
    }else{
        const hours = Math.floor(timeDifference / 3600);
        const minutes = Math.floor((timeDifference % 3600) / 60);
        const seconds = timeDifference % 60;
        const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        this.setState({remainingTime: formattedDuration})
    }
}
getEndTime = (start_time) => {
    const originalDate = new Date(start_time);
    const updatedDate = new Date(originalDate.getTime() + (24 * 60 * 60 * 1000));
    const year = updatedDate.getFullYear();
    const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
    const day = String(updatedDate.getDate()).padStart(2, '0');
    const hours = String(updatedDate.getHours()).padStart(2, '0');
    const minutes = String(updatedDate.getMinutes()).padStart(2, '0');
    const seconds = String(updatedDate.getSeconds()).padStart(2, '0');
    const updatedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return updatedTime;
}

isValidHttpUrl = (string) =>{
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  handleSplitReq = (string) => {
    if(string.includes('changed from')) {
        var splitarr = string.split("changed from");
        var splitarrtwo = splitarr[1].split("To");
        return (<>
            <div className='d-flex adesc'><p className='font-12'>
                <span className='mr-2 font-bold'>{splitarr[0]}</span>
                <Tag>{splitarrtwo[0]}</Tag>
                <i className="bi bi-arrow-right mr-2"></i>
                <Tag className='updated'>{splitarr[0].includes("Move in date")? <Moment format='DD MMMM YYYY'>{splitarrtwo[1]}</Moment>: splitarrtwo[1]}</Tag>
            </p></div>
        </>)
    } if(string.includes('changed To')) {
        var splitarr = string.split("changed To");
        return (
            <div className='d-flex adesc'><p className='font-12'>
                <span className='mr-0 font-bold'>{splitarr[0]}</span>
                <Tag>{splitarr[0]}</Tag>
                <i className="bi bi-arrow-right mr-2 "></i>
                <Tag>{splitarr[1]}</Tag>
            </p></div>
        )
    } else {
        return false
    }
  }
  handleSplitViewing = (string, props) => {
    if (string.includes('Viewing booked')) {  console.log(props)
        var splitarr = string.split("booked for");
        var splitarr2 = splitarr[1].split("on")
        var splitarr3 = splitarr2[1].split(" ")
            return (
                <div className='d-flex flex-row adesc-sched w-100'>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                        <p>Date / Time</p>
                        <Moment format='hh:mm A' className='font-bold font-14' style={{whiteSpace:'nowrap'}}>{splitarr2[1]}</Moment>
                        <Moment format='DD MMMM YYYY' style={{whiteSpace:'nowrap'}}>{splitarr3[2]}</Moment>
                    </div>
                    {props ?
                        <div className='lp_details w-100 m-0 pr-0' style={{border:0}}>
                            <Carousel arrows style={{width:'130px',height:"68px",  overflow:'hidden'}} effect="fade" {...props}>
                                {this.getImageArray(props.property_images).map((image) => {
                                    return <Image src={baseurlImg + "/" + image} style={{width:'100%'}} />
                                })}
                            </Carousel>
                            <div className='lpdetails_text w-100'>
                                {props.status=="D"? <p className='stat publishstat'>Draft</p>
                                :props.status=="UC"? <p className='stat confirmedowner'>Confirmed Owner</p>
                                :props.status=="OM"? <p className='stat offmarket'>Off-Market</p>
                                :props.status=="CTM"? <p className='stat comingtomarket'>Coming to Market</p>
                                :props.status=="Y"? <p className='stat published'>Published</p>
                                :props.status=="F"? <p className='stat feeds'>Feeds</p>
                                :null
                                }
                                <span className='mr-0 font-bold'>{splitarr2[0]}</span><i className="bi bi-dot"></i><span>{props.price && props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</span>
                                <p>{props.unitno?props.unitno+",":null}{props.property_project?props.property_project+",":null}</p>
                            </div>

                        </div>
                    :null}
                </div>
            )
    } else {
        return false
    }

  }

  getImageArray = (imageString) => {
    if (imageString == "" || imageString == null){
        return [];
    }else{
        let images = imageString.split(",");
        return images;
    }
  }

    handleOpenTagged =()=>{
        this.setState({isOpenTagged: true})
    }
    handleCloseTagged =()=>{this.setState({isOpenTagged:false})}

    handleOpenMyActivity =()=>{
        this.setState({isOpenMyActivityFeed:true})
    }
    handleCloseMyActivity =()=>{this.setState({isOpenMyActivityFeed:false})}

    getActivityFeed = () => {
        this.setState({isActivityLoading: true})
        const data = {
            filter: "",
            agent_id: ""
        }
        API.post("/activity_feed", data)
            .then((response) =>{
                this.setState({isActivityLoading: false})
                if(response.data.success){
                    this.setState({activityFeeds: response.data.activity_feeds})
                }
            }).catch((e) =>{
            this.setState({isActivityLoading: false})
            toast.error("Something Went Wrong")
        })
    }

    getTags = () => {
        this.setState({isTagLoading: true})
        API.get("/send_tags")
            .then((response) => {
                if (response.data) {
                    this.setState({isTagLoading: false})
                    if (response.data.success) {
                        this.setState({
                            sendTagDataAll: response.data.all_tags,
                            sendTagDataReplied: response.data.replied_tags,
                            sendTagDataPending: response.data.pending_tags,
                            sendTagData: response.data.all_tags,
                        })
                    }
                }
                else {
                    toast.error("Something Went Wrong")
                    this.setState({isTagLoading: false})
                }
            }).catch((err) => {
            toast.error("Something Went Wrong")
            this.setState({isTagLoading: false})
        })

        API.get("/recieve_tags")
            .then((response) => {
                if (response.data) {
                    if (response.data.success) {
                        this.setState({
                            recieveTagDataAll: response.data.all_recieve_tags,
                            recieveTagDataReplied: response.data.replied_recieve_tags,
                            recieveTagDataPending: response.data.pending_recieve_tags,
                            recieveTagData: response.data.all_recieve_tags,
                        })
                    }
                }
            }).catch((err) => {
            console.log("Error", err)
        })
    }

    onEnterReply = (e) => {
        this.setState({
            reply: e.target.value
        })
    }
    onClickReply = (parent_data) => {
        if (parent_data !== "" && parent_data != null){
            console.log("data for debug", parent_data)
            console.log("note", this.state.reply)
            const data = {
                parent_data : parent_data,
                note: this.state.reply
            }
            API.post("/add_lead_note_reply ", data)
                .then((response) => {
                    if (response.data) {
                        if (response.data.success) {
                            this.setState({
                                reply: "",
                                notes: response.data.notes,
                                all: response.data.all,
                                call_notes: response.data.call_notes,
                                whatsapp_notes: response.data.whatsapp_notes,
                                email_notes: response.data.email_notes
                            })
                            API.post("/add_lead_note_reply_email ", data)
                                .then((response) => {
                                        if (response.data.success) {
                                           console.log("email send")
                                        }
                                }).catch((err) => {
                                console.log("error", err)
                            })
                        }
                    }
                    else {
                        toast.error("Something Went Wrong")
                    }
                }).catch((err) => {
                toast.error("Something Went Wrong")
            })
        }
    }



    onDeleteCollab = (user_id, index, id, e) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.post("/delete-leads-collabs/" + id, {lead_id: this.state.lead_details.id, user_id: user_id})
                    .then((response) =>{
                        if(response.data.success){
                            this.state.collaborators.splice(index, 1);
                            this.setState({collaborators: this.state.collaborators})
                            toast.success("Successfully deleted")
                        }
                    })
            }
        })
    }
    fetchFollowUpDetails(leads_id) {
        API.post("/followup_details/" + leads_id, {type: "client"})
            .then((response) => {
                if (response.data.success) {
                    this.setState({ followups_details: response.data.followups })
                }

            })
    }

    handleCloseDrawer = () =>{
        this.setState({open:false})
    }
    addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

    fAmountSaperator = (value) => {
        return this.addCommas(this.removeNonNumeric(value));
    }
    handleViewFollowup = (data, e) => {
        this.setState({ isfollowupViewOpen: true, followup_data: data })
    }
    handleEditViewing(view_id, e) {
        this.setState({ view_id: view_id })
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Scheduled" ? true : false,
                        viewing_price: response.data.viewings.amount, iseditviewingOpen: true
                    })
                }

            })
    }
    handleOfferView = (view_id, e) => {
        this.setState({ view_id: view_id })
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {

                    {
                        /**
                         viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                         viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                         showPrice: response.data.viewings.status == "Offer" ? true : false,
                         showDate: response.data.viewings.status == "Scheduled" ? true : false,
                         viewing_price: response.data.viewings.amount, iseditviewingOpen: true
                         */
                    }

                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Scheduled" ? true : false,
                        viewing_price: response.data.viewings.amount, isOfferOpen: true
                    })
                }

            })
    }

    handleByAgent = (e) =>{
        this.setState({selectedAgent:e})
        const page = 1;
        const perPage = 10
        const data = {selectedAgent:e}
        this.fetchDataNewEnquiry(page, perPage,data);
        this.fetchDataWorking(page, perPage,data);
        this.fetchDataViewings(page, perPage,data);
        this.fetchDataSecondViewings(page, perPage,data);
        this.fetchDataOffers(page, perPage,data);
        this.fetchDataOffersAccepted(page, perPage,data);
        this.fetchDataPipeline(page, perPage,data);
        this.fetchTotalPipeline(data)

    }

     startPaginate = (requestedPage, laneId) =>{
        console.log("lane is",laneId);
        console.log("requestedPage is",requestedPage);
        const data = {selectedAgent:this.state.selectedAgent}
        const page = 1;
        const perpage = 10;
        if(laneId == 1 && requestedPage > 0){
            // alert("new")
            return Promise.resolve(this.fetchDataNewEnquiry(requestedPage,perpage,data))
            
        }else
        if(laneId == 2 && requestedPage > 0){
            return Promise.resolve( this.fetchDataWorking(requestedPage,perpage,data))

        }else  
        if(laneId == 8 && requestedPage > 0){
            return Promise.resolve(this.fetchDataViewings(requestedPage,perpage,data))

        }else  
        if(laneId == 16 && requestedPage > 0){
            return Promise.resolve(this.fetchDataSecondViewings(requestedPage,perpage,data))

        }else  
        if(laneId == 6 && requestedPage > 0){
            return Promise.resolve(this.fetchDataOffers(requestedPage,perpage,data))

        }else
        if(laneId == 13 && requestedPage > 0){
            return Promise.resolve(this.fetchDataOffersAccepted(requestedPage,perpage,data))

        }else 
         if(laneId == 7 && requestedPage > 0){
            return Promise.resolve( this.fetchDataPipeline(requestedPage,perpage,data))

        }
        // alert("paginate")
        // return fetchCardsFromBackend(laneId, requestedPage); 
      };

    render() {

      
       
        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let role_name = userdata.auth.user.role_name;
        let role_profile = userdata.auth.user.profile_photo;
        const user_id = userdata.auth.user.id
        const props = {dots: true, infinite: true,speed: 500,lidesToShow: 1,slidesToScroll: 1,nextArrow: <CaretRightOutlined />,prevArrow: <CaretLeftOutlined />};


        const {offers_date} = this.state;
        const {split_agent} =this.state
        const isUserFound = this.state.collaborators.some(element => {
            if (element.user_id === user_id) {
                return true
            } else {
                return false
            }
        });
        const data = {
            lanes: [
              {
                id: 1,//'newenquirylane',
                title: 'New Enquiry',
                label: this.state.new_enquery_count?this.state.new_enquery_count:'0',
                cards: new_enquiry_array,
                currentPage: this.state.new_enquery_current_page
               
              },
              {
                id: 2,//'workinglane',
                title: 'Working',
                label: this.state.working_count?this.state.working_count:'0',
                cards: working_array, 
                currentPage: this.state.working_current_page,
              },
              {
                id: 8,//'viewingslane',
                title: 'Viewings',
                label: this.state.viewing_count?this.state.viewing_count:'0',
                cards: viewings_array,
                currentPage: this.state.viewing_current_page,
              },
              {
                id: 16,//'secondviewingslane',
                title: 'Second Viewings',
                label: this.state.second_viewing_count?this.state.second_viewing_count:"0",
                cards: second_viewings_array,
                currentPage: this.state.second_viewing_current_page,
              },
              {
                id: 6,//'offerslane',
                title: 'Offers',
                label: this.state.offer_count?this.state.offer_count:'0',
                cards: offers_array,
                currentPage: this.state.offer_current_page,
              },
              {
                id: 13,//'offersacceptedlane',
                title: 'Offer Accepted',
                label: this.state.offer_accepted_count?this.state.offer_accepted_count:'0',
                cards: offers_accepted_array,
                currentPage: this.state.offer_accepted_current_page,
              },
              {
                id: 7,//'leads',
                title: 'Pipeline',
                label: '0',
                class:'leadspipeline',
                cards: pipelines_array,
                currentPage: this.state.pipeline_current_page,
              },
              
            ],
            
            
          } 

        return (<>

<Modal title="Add Call" open={this.state.isCallLogOpen} width={800}
            // onOk={this.handleCallSubmit}
            onCancel={this.handleCallLogModalCancel}
            footer={<>{
                this.state.call_loader ?
                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                    <Spin style={{ alignSelf: 'center' }} />
                  </div>
                  :
                  <Button id="calllogbtn" onClick={this.handleCallSubmit} type="primary" className="d-flex flex-row"><i className="bi bi-plus me-1 fs-5" /> Add call log</Button>
              }
            </>}
            >
                <Row span={24} className='w-100 addcallpopup-wrap' gutter={8}>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Name:</label>
                            <EdiText value={(this.state.lead_details && this.state.lead_details.owner) ? this.state.lead_details.owner : ""} type="text" onSave={this.handleSaveOwnerName} className='leadetailsowner' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Contact Type<em>*</em>:</label>
                            <div className='form-text-wrapper'>
                                <EasyEdit type={Types.SELECT} value={this.state.contact_type_requirement == undefined || this.state.contact_type_requirement == null  || this.state.contact_type_requirement ==""? 'selected':
                                        this.state.contact_type_requirement.toString()
                                    }
                                    // options={this.state.agents}
                                    options={this.state.contact_types.map((e)=> {
                                        return {
                                        label:e.label, value: e.value.toString()
                                        }
                                    })}
                                    onSave={this.saveContactType}
                                    saveButtonLabel={<CheckOutlined />}
                                    cancelButtonLabel={<CloseOutlined />}
                                    placeholder="Please select"
                                    onValidate={value => {return value != null}}
                                    validationMessage="Add Contact Type"
                                    />
                                    </div>
                        </div>
                    </Col>
                    {this.state.contact_type_requirement.toString()=="1"?
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Move In Date: <em>*</em></label>
                                <div className='form-text-wrapper'>
                                    <EasyEdit type={Types.DATE}
                                    value={
                                        (this.state.requirement_move_in_date == "0000-00-00 00:00:00" || this.state.requirement_move_in_date == null || this.state.requirement_move_in_date == "")?
                                        "Select date"
                                        :
                                        <Moment format='DD MMM YYYY'>{this.state.requirement_move_in_date}</Moment>
                                    }
                                    onSave={this.saveMoveInDate}
                                    saveButtonLabel={<CheckOutlined />}
                                    cancelButtonLabel={<CloseOutlined />}
                                    onValidate={value => {return value != null}}
                                    validationMessage="Move In Date is required"
                                    />
                                    </div>
                            </div>
                        </Col>
                    :null}
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Budget<em>*</em>:</label>
                            <div className='form-text-wrapper'>
                                <EasyEdit type={Types.TEXT} value={this.state.price_requirement == null || this.state.price_requirement == '' ? 'Price' : this.fAmountSaperator(this.state.price_requirement) } onSave={this.savePrice} saveButtonLabel={<CheckOutlined />} cancelButtonLabel={<CloseOutlined />}  attributes={{ name: "awesome-input", id: 1 }}
                            onValidate={value => {return value != null}}
                            validationMessage="Budget is required"/>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Community<em>*</em>:</label>
                            <div className='form-text-wrapper'>
                                <EasyEdit type={Types.SELECT}
                                    value={
                                            this.state.requirement_community == undefined  || this.state.requirement_community == null?
                                                "Select"
                                                :
                                                this.state.requirement_community.toString()
                                            }
                                    options={this.state.communities.map((e)=> {
                                        return {
                                        label: e.label,
                                        value: e.value.toString()
                                        }
                                    })}
                                    // onSave={this.saveAgent}
                                    onSave={this.saveCommunity}
                                    saveButtonLabel={<CheckOutlined />}
                                    cancelButtonLabel={<CloseOutlined />}
                                    onValidate={value => {return value != null}}
                                    validationMessage="Community field is required"
                                    />
                                    </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Sub Community:</label>
                            <div className='form-text-wrapper'>
                                <EasyEdit
                                    type={Types.SELECT}
                                    value={
                                        this.state.requirement_sub_community == undefined  || this.state.requirement_sub_community == null?
                                        "Select"
                                        :
                                        this.state.requirement_sub_community.toString()
                                        }
                                    options={this.state.sub_communities.map((e) => {
                                        return {
                                        label: e.label,
                                        value: e.value.toString()
                                        }
                                    })}
                                    onSave={this.saveSubCommunity}
                                    saveButtonLabel={<CheckOutlined />}
                                    cancelButtonLabel={<CloseOutlined />}


                                    />
                                    </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Bedrooms:</label>
                            <div className='form-text-wrapper'>
                                <EasyEdit type={Types.SELECT} value={this.state.requirement_bedroom}
                                    options={[
                                        { label: 'Studio', value: 'Studio' },
                                        { label: '1', value: '1' },
                                        { label: '2', value: '2' },
                                        { label: '3', value: '3' },
                                        { label: '4', value: '4' },
                                        { label: '5', value: '5' },
                                        { label: '6', value: '6' },
                                        { label: '7', value: '7' },
                                        { label: '8', value: '8' },
                                        { label: '9', value: '9' },
                                        { label: '10', value: '10' },
                                        { label: '11', value: '11' },
                                        { label: '12', value: '12' },
                                        { label: '13', value: '13' },
                                        { label: '14', value: '14' },
                                        { label: '15', value: '15' }
                                    ]}
                                    onSave={this.saveBedroom}
                                    saveButtonLabel={<CheckOutlined />}
                                    cancelButtonLabel={<CloseOutlined />}
                            />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row span={24}  className='w-100'>
                    <Col span={24} className='w-100 form-group'>
                        <label className="font-10">Call Note:</label>
                        <Mentions style={{fontSize:10, width: '100%',fontFamily:"Poppins-Regular"}}
                              value={this.state.call_log}
                              onChange={this.onChangeCallNote}
                              onSelect={this.onSelectCallMentions}
                              rows={3}
                              options={this.state.userClients}
                            />
                    </Col>
                </Row>
            </Modal>

            <Modal width={1000} title="Add Viewings" open={this.state.isviewingOpen}
                       onOk={this.handleViewingOk} onCancel={this.handleViewingCancel}
                       confirmLoading={this.state.isViewing}
                >
                    <Divider />

                    <div className='row w-100'>
                        <div className='col-md-12 px-0 pt-1'>
                            <div className="form-group">
                                <label>Ref No</label>

                                <Select
                                    style={{
                                        width: "100%",
                                    }}
                                    mode={"multiple"}
                                    value={this.state.ref_def_nums}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleRefsno}
                                    onSearch={this.onSearchRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.ref_nums}
                                />
                                {
                                    this.state.ref_nums == '' || this.state.ref_def_nums.length == 0 && this.state.checkErr &&
                                    <label style={{ color: 'red' }} >empty</label>
                                }
                            </div>
                            <div className="row orgroup">
                                <div className="col-12 d-flex flex-row">
                                    <hr />
                                    <p className="mb-0">Or</p>
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Unit no:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            value={this.state.viewing_unit_no}
                                            showSearch
                                            allowClear
                                            placeholder="Select Unit no"
                                            optionFilterProp="children"
                                            onChange={this.handleUnitno}
                                            onSearch={this.handleSearchUintNo}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.unit_nums}
                                        />

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Agent:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            value={this.state.viewing_agent}
                                            showSearch
                                            placeholder="Select Agent"
                                            optionFilterProp="children"
                                            onChange={this.handleViewingAgent}

                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.agents}
                                        />
                                        {
                                            this.state.viewing_agent == '' && this.state.checkErr &&
                                            <label style={{ color: 'red' }} >empty</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Community:</label>
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            showSearch
                                            placeholder="Search"
                                            optionFilterProp="children"
                                            // onChange={this.handleCommunityMatch}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.communities}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Sub Community:</label>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            // mode={"multiple"}

                                            showSearch
                                            maxTagCount={"responsive"}
                                            placeholder="Search"
                                            optionFilterProp="children"
                                            //onChange={this.handleSubCommunityMatch}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.sub_communities}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Property:</label>
                                        <select name="property" id="property" className="form-control">
                                            <option value=""></option>
                                            <option selected="" value="386">Burj Views A</option>
                                            <option value="237">Burj Views B</option>
                                            <option value="283">Burj Views C</option>
                                            <option value="274">Burj Views Podium</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="">Date / Time:</label>
                                        <DatePicker
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            showTime
                                            onChange={this.handleViewingDate} />
                                        {
                                            this.state.viewing_date == '' && this.state.checkErr &&
                                            <label style={{ color: 'red' }} >empty</label>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
        <div className="col-12">
            <label className="">Notes:</label>
            <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            {
                this.state.viewing_note == '' && this.state.checkErr &&
                <label style={{color:'red'}} >empty</label>
               }
        </div>
    </div> */}


                            <div className="row">
                                <div className="col-12">
                                    <table className="table">

                                        <thead>
                                        <tr>
                                            <th scope="col">Ref no</th>
                                            <th scope="col">Unit no</th>
                                            <th scope="col">Community</th>
                                            <th scope="col">Subcommunity</th>
                                            <th scope="col">Property</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.viewings_listings.map((viewing_listing) => (
                                            <tr>
                                                <th >{viewing_listing.refno}</th>
                                                <th >{viewing_listing.unitno}</th>
                                                <td>{viewing_listing.community}</td>
                                                <td>{viewing_listing.subcommunity}</td>
                                                <td>{viewing_listing.property}</td>
                                                <td>
                                                    <CloseOutlined className='text-danger' onClick={this.handleRemoveList.bind(this, viewing_listing.refno)} />
                                                </td>
                                            </tr>
                                        ))}


                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
         

                </Modal>

                <Modal width={600} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
                    <div className="container">
                        <div className='row w-100 d-flex flex-row'>
                            <div className='px-0 pt-1 form-group'>
                                <label>Ref No</label>
                                <p className="form-control-disabled">
                                    {this.state.viewings.refno}
                                    </p>
                            </div>
                            <div className='px-0 pt-1 form-group'>
                                <label>Client Name</label>
                                <p className="form-control-disabled">
                                    {this.state.viewings.fullname}
                                    </p>
                            </div>
                        </div>
                        <div className='row w-100 mt-3 d-flex flex-row'>

                            <div className='px-0 pt-1 form-group'>
                                <label>Date</label>
                                {this.state.showDate ? <DatePicker value={(this.state.view_date) ? dayjs(this.state.view_date) : ""} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p className="form-group">
                                    {this.state.viewings.view_date}
                                    </p>}

                            </div>
                            <div className='px-0 pt-1 mt-3 form-group'>
                                <label>Status</label>
                                <Select
                                    style={{
                                        width: 160,
                                    }}
                                    showSearch
                                    maxTagCount={"responsive"}
                                    placeholder="Select beds"
                                    optionFilterProp="children"
                                    onChange={this.handleViewStatus}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        { value: 'Offer', label: 'Offer' },
                                        { value: "Successful", label: 'Successful' },
                                        { value: "Cancelled", label: 'Cancelled' },
                                        { value: "Unsuccessful", label: 'Unsuccessful' },
                                        { value: "Scheduled", label: 'Scheduled' }
                                    ]}
                                />
                               
                            </div>
                            {this.state.showPrice ? <div className='form-group'>
                                <label>Price</label>
                                <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
                            </div> : null}
                        </div>
                        <div className='row w-100 mt-3'>
                            <div className='w-100 px-0 pt-1'>
                                <label>Note</label>
                                <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
                            </div>


                        </div>
                    </div>
                </Modal>


                <Modal width={600} title="Offers" open={this.state.offerModal} height={600}
          onOk={this.handleChangeStatus} onCancel={this.handleCancel} 
          footer={[
            <>
                <Button key="ok" onClick={this.handleChangeStatus}>Submit </Button>
                {/* <Button key="ok" onClick={this.handleChangeStatus}>Submit</Button> */}
                <Button key="back" onClick={this.handleCancel}>Cancel</Button>
            </>
          ]}
          >
          <div className="container">
            <div className='row w-100 d-flex flex-row'>
              <div className='px-0 pt-1 form-group'>
                <label>Ref No</label>
                <p className="form-control-disabled">{this.state.offers.refno}</p>
              </div>
              {role == 1 ? <div className='px-0 pt-1 form-group'>
                <label>Agent Name</label>
                <p className="form-control-disabled">{this.state.offers.display_name}</p>
              </div> : null}

            </div>
            <div className='row w-100 d-flex flex-row'>

              <div className='px-0 pt-1 form-group'>
                <label>Date</label>
                {
                 role == 1 ?
                    <DatePicker value={(offers_date) ? dayjs(offers_date) : ""} format="YYYY-MM-DD" style={{ width: '100%' }} showTime onChange={this.handleOfferingDate} />
                    :
                    <p className="form-control mb-0" style={{ fontSize: "12px" }}>{this.state.offers_date}</p>
                }

              </div>
              <div className='px-0 pt-1  form-group'>
                <label>Offers Status</label>
                <select onChange={this.handleOfferStatus} value={this.state.offers_status} className='form-select'>
                  <option value="1">Offer Sent</option>
                  <option value="2">Offer Accepted</option>
                  <option value="3">Offer Rejected</option>
                  {role==1?<option value="4">Deal Signed</option>:null}
                  
                  {/* <option value="5">MOU Request</option> */}
                </select>
              </div>
              {/* {
              this.state.showPrice ? 
                <div className='form-group'>
                  <label>Price</label>
                  <input onChange={this.handleOfferingPrice} type="text" className="form-control" value={this.state.offers_price} />
                </div> 
                : 
                null
              } */}
              <div className='form-group px-0'>
                <label>Price</label>
                <input onChange={this.handleOfferingPrice} type="text" className="form-control" value={this.state.offers_price} />
              </div>
              <div className='form-group px-0'>
                <label>Commission</label>
                <input onChange={this.handleOfferingCommission} type="text" className="form-control" value={this.state.offers_commission} />
              </div>
            
              {this.state.showReferral?
              <>
              {this.state.vendor_agent_id != this.state.client_agent_id?<>
                <div className='form-group px-0'>
                <label>Split agent</label>
                <p className="form-control-disabled">{split_agent?split_agent:""}</p>
              </div>
              <div className='form-group px-0'>
                <label>Split Commission</label>
                <input onChange={this.handleSplitCommission} type="text" className="form-control"  />
              </div>
              </>:null}
                
              <div className='form-group px-0'>
              <label>Referral</label>
              <Checkbox checked={this.state.ReferralChecked} 
               onChange={this.handleReferral} 
              />
           
                                        </div></>:null}
                                        {this.state.showReferralAgent?<><div className='form-group px-0'>
              <label>Referral Agent</label>
   <Select className='form-select p-0 noborder m-0 font-14'
                               
                                showSearch
                                allowClear
                                maxTagCount= 'responsive'
                                style={{
                                    width: '100%',
                                }}
                              
                                placeholder="Select Agent"
                               onChange={this.handleReferralAgent}
                               filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }

                                options={this.state.agents}
                            />
              </div>
              <div className='form-group px-0'>
              <label>Commission</label>
            <input type="text" className="form-control" onChange={this.handleRefrralCommission} />
              </div></>:null}
            </div>
            <div className='row w-100'>

              <div className='w-100 px-0 pt-1 form-group'>
                <label>Offer feedback</label>
                <TextArea value={this.state.offers_note} style={{ width: '100%' }} onChange={this.handleOfferingNote} />
              </div>


            </div>
          </div>
        </Modal>
        <Drawer
                    title={`Lead details`}
                    placement="left"
                    // size={"large"}
                    width={"98%"}
                    onClose={this.handleCloseDrawer}
                    open={this.state.open}
                    // extra={
                    //   <Space>
                    //     <Button type='primary' danger ghost onClick={this.handleCloseDrawer} icon={<CloseOutlined /> }>Cancel</Button>
                    //     <Button type="primary" onClick={this.handleSaveData}>
                    //       Save changes
                    //     </Button>
                    //   </Space>
                    // }
                >

                    <div className="cont-wrap container">
                        {
                            this.state.loader ?
                                <div style={{ position:'absolute', alignSelf:"center", top:'50%', left:'50%', transform:'translate(-50%, -50%)' }} >
                                    <Spin className='spinnerOp' size='large' indicator={<img src='images/opportunityloading.gif' />} />
                                </div>
                                :
                                <>
                                    <div className="row">
                                        <div className="col-12 d-flex flex-row toptitle">
                                            <button className="btn btn-primary" disabled={this.state.isPreviousLoading || this.state.disabledPrev ? true : false} onClick={ this.state.loader? () =>  console.log('change') : this.handlePreviousRecord} >{this.state.isPreviousLoading ? <BeatLoader color={"blue"} loading={true} size={30} /> : "Previous"}</button>
                                            <div className="d-flex w-100 ps-3"><h2 className="page-title fs-5 font-bold"><i className="bi bi-pencil-fill me-2" />Leads Details - {this.state.lead_details.refno}</h2></div>
                                            <button className="btn btn-primary" disabled={this.state.isNextLoading || this.state.disabledNext ? true : false} onClick={ this.state.loader? () =>  console.log('change') : this.handleNextRecord}  >{this.state.isNextLoading ? <BeatLoader color={"blue"} loading={true} size={30} /> : "Next"}</button>
                                        </div>
                                    </div>


                                    <div className="row column-wrap mt-3">
                                        <div className="col-12 col-md-3" >


                                            {/** Contact Details */}

                                            <div className="bg-white border-top ownersdetails">
                                                {
                                                    // role == 1 || isUserFound || user_id.toString() == this.state.requirement_agent_id.toString() ?
                                                    role == 1 || isUserFound || this.state.collaborators.some((data)=> data.user_id == user_id) || user_id.toString() == this.state.requirement_agent_id.toString() || this.state.lead_details.lead_stage ==10 || this.state.lead_details.lead_agent ==430 ?
                                                        <>

                                                            <div className="d-flex flex-row justify-content-between"  >
                                                                <h3>Contact Details</h3>
                                                                <button type="button" className="btn btn-primary requestchange">Change Details</button>
                                                            </div>
                                                            <div className="d-flex flex-column formfieldset mt-3">
                                                                <div className="d-flex flex-column form-group">
                                                                    <label className="control-labels mr-4">Name</label>

                                                                    <EdiText
                                                                        value={(this.state.lead_list_details && this.state.lead_list_details.owner) ? this.state.lead_list_details.owner : ""}
                                                                        type="text"
                                                                        onSave={this.handleSaveOwnerName}

                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column form-group">
                                                                    <label className="control-labels mr-4">Phone </label>
                                                                    <p className="form-control-disabled">{this.state.owner_mobile}</p>
                                                                </div>
                                                                <div className="d-flex flex-column form-group">
                                                                    <label className="control-labels mr-4"> Email </label>
                                                                    <EdiText
                                                                        value={(this.state.lead_list_details && this.state.lead_list_details.owner_email) ? this.state.lead_list_details.owner_email : ""}
                                                                        type="text"
                                                                        onSave={this.handleSaveOwnerEmail}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="bg-white  ownersdetails mt-3">
                                                                <div className="d-flex flex-column form-group mb-0">
                                                                    <label className="control-labels mr-4">Other Enquires</label>
                                                                    <div className="col-12 d-flex flex-column border-wrap listtable-wrap">

                                                                        {this.state.owner_other_properties.map((owner_other_property) => {
                                                                            var pStatus = 'Draft'
                                                                            switch (owner_other_property.status) {
                                                                                case 'D':
                                                                                    pStatus = 'Draft'
                                                                                    break;
                                                                                case 'UC':
                                                                                    pStatus = 'Confirmed Owner'
                                                                                    break;
                                                                                case 'N':
                                                                                    pStatus = 'Unpublish'
                                                                                    break;
                                                                                case 'OM':
                                                                                    pStatus = 'Off-Market'
                                                                                    break;
                                                                                case 'CTM':
                                                                                    pStatus = 'Coming to market'
                                                                                    break;
                                                                                case 'Y':
                                                                                    pStatus = 'Published'
                                                                                    break;
                                                                                case 'U':
                                                                                    pStatus = 'Action'
                                                                                    break;

                                                                                case 'SEO':
                                                                                    pStatus = 'SEO'
                                                                                    break;
                                                                                case 'L':
                                                                                    pStatus = 'Unlisted'
                                                                                    break;
                                                                                case 'S':
                                                                                    pStatus = 'Sold'
                                                                                    break;
                                                                                case 'T':
                                                                                    pStatus = 'Let'
                                                                                    break;
                                                                                case 'P':
                                                                                    pStatus = 'Review'
                                                                                    break;
                                                                                case 'F':
                                                                                    pStatus = 'Feeds'
                                                                                    break;
                                                                                default:
                                                                                    pStatus = 'Draft'
                                                                                    break;
                                                                            }
                                                                            return (
                                                                                <a
                                                                                    target="_blank"
                                                                                    // href={"https://www.luxuryproperty.com/property/preview/" + owner_other_property.ref_no+"-"+owner_other_property.listing_id}
                                                                                    href={liveUrl + "/all_listings/" + owner_other_property.listing_id}
                                                                                    className="d-flex flex-row p-2 w-100"
                                                                                    style={{ textDecorationLine:'none' }}
                                                                                >
                                                                                    <div className="col-8 listtable">
                                                                                        <p className="propfor">{owner_other_property.property_for}</p>
                                                                                        <p className="refno">{owner_other_property.refno}</p>
                                                                                        <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                                                                        <p className="community">{owner_other_property.community}</p>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <div className="d-flex flex-row stat publishstat">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span>{pStatus}</span>
                                                                                        </div>
                                                                                        <div className="agentprop">
                                                                                            <p className="name">{owner_other_property.agent}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            )
                                                                        })}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null

                                                }





                                            </div>
                                            {/*** END */}


                                            {/* FORMS */}
                                            <div className="bg-white border-top offers p-4 mt-3">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4 mb-3">Requirements </h3>
                                                </div>
                                                <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
                                                <div className="form-group">
                                                <Checkbox  checked={this.state.pin}  onChange={this.onChangePin}>Pin</Checkbox>

                                                </div>
                                                    <div className="form-group"> {/* MULTISELECT */}
                                                        <label className="control-labels ">Lead Stage</label>
                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            //value={this.state.requirement_lead_stage.toString()}

                                                            value={this.state.requirement_lead_stage == undefined || this.state.requirement_lead_stage == null ||  this.state.requirement_lead_stage == "" ?
                                                                ''
                                                                :
                                                                this.state.requirement_lead_stage.toString()
                                                            }

                                                            options={role==1? this.state.lead_stage_filter:this.state.lead_stage_filter_agent}
                                                            onSave={this.saveLeadStage}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            placeholder={'please select'}
                                                            instructions="Custom instructions"
                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Verifier</label>
                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            // value={this.state.requirement_verifyedby.toString()}
                                                            value={this.state.requirement_verifyedby == undefined || this.state.requirement_verifyedby == null ||  this.state.requirement_verifyedby == "" ?
                                                                ''
                                                                :
                                                                this.state.requirement_verifyedby.toString()
                                                            }
                                                            options={[
                                                                { label: 'None', value: '0' },
                                                                { label: 'Danielle Robinson', value: '375' },
                                                                { label: 'Christalline Ybanez', value: '427' },
                                                            ]}

                                                            onSave={this.saveVerifier}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Custom instructions"
                                                            placeholder="Please select"
                                                        />

                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-labels ">Contact Type</label>

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={this.state.contact_type_requirement == undefined || this.state.contact_type_requirement == null  || this.state.contact_type_requirement ==""?
                                                                'selected'
                                                                :
                                                                this.state.contact_type_requirement.toString()
                                                            }
                                                            // options={this.state.agents}
                                                            options={this.state.contact_types.map((e)=> {
                                                                return {
                                                                    label:e.label, value: e.value.toString()
                                                                }
                                                            })}
                                                            onSave={this.saveContactType}
                                                            onChange={this.onChangeContactType}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Custom instructions"
                                                            placeholder="Please select"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-labels ">Agent Name</label>

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={this.state.requirement_agent_id == undefined || this.state.requirement_agent_id == null  || this.state.requirement_agent_id ==""?
                                                                ''
                                                                :
                                                                this.state.requirement_agent_id.toString()
                                                            }
                                                            // options={this.state.agents}
                                                            options={this.state.agents.map((e)=> {
                                                                return {
                                                                    label:e.label, value: e.value.toString()
                                                                }
                                                            })}
                                                            onSave={this.saveAgent}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Custom instructions"
                                                            placeholder="Please select"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Move in Date</label>
                                                        <EasyEdit
                                                            type={Types.DATETIME_LOCAL}
                                                            value={
                                                                this.state.requirement_move_in_date == "0000-00-00 00:00:00"?
                                                                    "select date"
                                                                    :
                                                                    this.state.requirement_move_in_date
                                                            }
                                                            onSave={this.saveMoveInDate}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            instructions="Select your date and time"
                                                        />
                                                        {/* <input value={this.state.lead_details.mov_in} onChange={this.handleRequirementMoveInDate} type="datetime-local" id="move_in_date" className="form-control" defaultValue /> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Price</label>
                                                        <div className="d-flex flex-row" style={{ gap: '0.6rem' }}>

                                                            <EasyEdit
                                                                type={Types.TEXT}
                                                                value={this.state.price_requirement == null || this.state.price_requirement == '' ?
                                                                    '0'
                                                                    :
                                                                    this.fAmountSaperator(this.state.price_requirement)
                                                                }
                                                                onSave={this.savePrice}
                                                                saveButtonLabel={<CheckOutlined />}
                                                                cancelButtonLabel={<CloseOutlined />}
                                                                attributes={{ name: "awesome-input", id: 1 }}
                                                                instructions="Star this repo!"

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Bedrooms</label>
                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={this.state.requirement_bedroom}

                                                            options={[
                                                                { label: 'Studio', value: 'Studio' },
                                                                { label: '1', value: '1' },
                                                                { label: '2', value: '2' },
                                                                { label: '3', value: '3' },
                                                                { label: '4', value: '4' },
                                                                { label: '5', value: '5' },
                                                                { label: '6', value: '6' },
                                                                { label: '7', value: '7' },
                                                                { label: '8', value: '8' },
                                                                { label: '9', value: '9' },
                                                                { label: '10', value: '10' },
                                                                { label: '11', value: '11' },
                                                                { label: '12', value: '12' },
                                                                { label: '13', value: '13' },
                                                                { label: '14', value: '14' },
                                                                { label: '15', value: '15' }
                                                            ]}
                                                            onSave={this.saveBedroom}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}


                                                        />


                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Community</label>
                                                        {/* <Select
                              style={{
                                width: '100%',
                              }}
                              defaultValue={this.state.requirement_community}
                              showSearch
                              placeholder="Search"
                              onChange={this.handleRequirementCommunity}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            /> */}

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={
                                                                this.state.requirement_community == undefined  || this.state.requirement_community == null?
                                                                    "select"
                                                                    :
                                                                    this.state.requirement_community.toString()
                                                            }
                                                            options={this.state.communities.map((e)=> {
                                                                return {
                                                                    label: e.label,
                                                                    value: e.value.toString()
                                                                }
                                                            })}
                                                            // onSave={this.saveAgent}
                                                            onSave={this.saveCommunity}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}


                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Sub Community</label>

                                                        <EasyEdit
                                                            type={Types.SELECT}
                                                            value={
                                                                this.state.requirement_sub_community == undefined  || this.state.requirement_sub_community == null?
                                                                    "select"
                                                                    :
                                                                    this.state.requirement_sub_community.toString()
                                                            }
                                                            options={this.state.sub_communities.map((e) => {
                                                                return {
                                                                    label: e.label,
                                                                    value: e.value.toString()
                                                                }
                                                            })}
                                                            onSave={this.saveSubCommunity}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}


                                                        />
                                                        {/* <Select
                              style={{
                                width: '100%',
                              }}
                              // mode={"multiple"}
                              value={this.state.requirement_sub_community}
                              showSearch
                              maxTagCount={"responsive"}
                              placeholder="Search"
                              optionFilterProp="children"
                              onChange={this.handleRequirementSubCommunity}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.requirement_sub_communities}
                            /> */}

                                                    </div>
                                                </div>
                                                {/**  HIDE SAVE BUTTON FOR NOW  */}
                                                {/* <button onClick={this.handleRequirementSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button> */}
                                            </div>{/* END FORMS*/}

                                            <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4 mt-4">
                                                <h3>Match Details</h3>
                                                <div className="d-flex flex-column pt-3">
                                                    <div className="d-flex flex-column matchfilter">
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="community" className="mb-0">Community:</label>
                                                                <Select id="community"
                                                                        style={{
                                                                            width: 160,
                                                                        }}

                                                                        showSearch
                                                                    // allowClear
                                                                    // defaultValue={this.state.community_match.toString()}
                                                                    // value={
                                                                    //   this.state.community_match == undefined  || this.state.community_match == null ||this.state.community_match=="" ?
                                                                    //     "Select"
                                                                    //     :
                                                                    //     this.state.community_match.toString()
                                                                    //   }

                                                                        placeholder="Select a person"
                                                                        optionFilterProp="children"
                                                                        onChange={this.handleCommunityMatch}
                                                                    // onSearch={onSearch}
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                    // options={this.state.communities}

                                                                        options={this.state.communities.map((e)=> {
                                                                            return {
                                                                                label:e.label, value: e.value.toString()
                                                                            }
                                                                        })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub-Community:</label>
                                                                <Select id="propsubcommunity"
                                                                        style={{
                                                                            width: 160,
                                                                        }}
                                                                    // mode={"multiple"}

                                                                        showSearch
                                                                    // value={this.state.sub_community_match.toString()}
                                                                    //  value={
                                                                    //   this.state.sub_community_match == undefined  || this.state.sub_community_match == null ||this.state.sub_community_match=="" ?
                                                                    //     "Select"
                                                                    //     :
                                                                    //     this.state.sub_community_match.toString()
                                                                    //   }
                                                                        maxTagCount={"responsive"}
                                                                        placeholder="Select a person"
                                                                        optionFilterProp="children"
                                                                        onChange={this.handleSubCommunityMatch}
                                                                    // onSearch={onSearch}
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                    //options={this.state.sub_communities}
                                                                        options={this.state.sub_communities.map((e)=> {
                                                                            return {
                                                                                label:e.label, value: e.value.toString()
                                                                            }
                                                                        })}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row budget">
                                                                <label for="matchingBudgetMin">Budget:</label>

                                                                <div className="d-flex flex-row">
                                                                    <CurrencyInput
                                                                        id="input-min"
                                                                        name="input-min"
                                                                        placeholder="Min"
                                                                        value={this.state.min_budget}
                                                                        decimalsLimit={2}
                                                                        onChange={this.handleMinBudget}
                                                                    />
                                                                    {/* <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" /> */}
                                                                    <span className="dash" style={{}}>-</span>
                                                                    <CurrencyInput
                                                                        id="input-max"
                                                                        name="input-max"
                                                                        placeholder="Max"
                                                                        value={this.state.max_budget}

                                                                        decimalsLimit={2}
                                                                        onChange={this.handleMaxBudget}
                                                                    />
                                                                    {/* <input type="text" onChange={this.handleMaxBudget}  className="currencyInput" id="matchingBudgetMax" placeholder="Max" /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="matchingBedrooms">Beds:</label>
                                                                <select value={this.state.bedroom_match} onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value="Studio">Studio</option>
                                                                    <option value={1}>1</option>
                                                                    <option value={2}>2</option>
                                                                    <option value={3} >3</option>
                                                                    <option value={4}>4</option>
                                                                    <option value={5}>5</option>
                                                                    <option value={6}>6</option>
                                                                    <option value={7}>7</option>
                                                                    <option value={8}>8</option>
                                                                    <option value={9}>9</option>
                                                                    <option value={10}>10</option>
                                                                    <option value={11}>11</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={13}>13</option>
                                                                    <option value={14}>14</option>
                                                                    <option value={15}>15</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row w-100">
                                                                <label for="addedDateM">Added Date:</label>
                                                                <select onChange={this.handleAddedDateMatch} id="addedDateM" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value={1}>Last 24 Hours</option>
                                                                    <option value={3}>Last 3 Days</option>
                                                                    <option value={7}>Last 7 Days</option>
                                                                    <option value={14}>Last 14 Days</option>
                                                                    <option value={30}>Last 1 Month</option>
                                                                    <option value={90}>Last 3 Month</option>
                                                                    <option value={180}>Last 6 Month</option>
                                                                    <option value={365}>Last 1 Year</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row">
                                                                <label for="matchingPfor">Property For:</label>
                                                                <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value="rent">Rent</option>
                                                                    <option value="sale">Sale</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row">
                                                                <label for="matchingType">Type:</label>
                                                                <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                                                                    <option>select</option>
                                                                    {this.state.propertytypes.map((propertytype) => {
                                                                        return <option value={propertytype.value}>{propertytype.label}</option>
                                                                    })}


                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                                                        </div>

                                                    </div>

                                                    <div className="matchresults">
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <label className="control-labels mr-4">Match with access</label>
                                                        </div>
                                                        <div className="matchresult">
                                                            {this.state.matchs.map((match) => {
                                                                const match_price = match.price


                                                                return (
                                                                    <a href className="matchitem">
                                                                        <div className="matchinfo">
                                                                            <div className="col-12 col-md-9 px-0">
                                                                                <p className="address">{match.unitno}</p>
                                                                                <p className="address">{match.address}</p>
                                                                                <p className="address"> {match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'}</p>

                                                                            </div>
                                                                            <div className="col-3">
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="matchhistory">
                                                                            <p className="addeddate">
                                                                                <Moment fromNow>
                                                                                    {match.added_date}
                                                                                </Moment>
                                                                            </p>
                                                                            <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                                                        </div>
                                                                    </a>
                                                                )
                                                            })}

                                                            {this.state.matchs.length > 0 ? <Link onClick={this.handleAllMatch} className="matchitem">

                                                                View All

                                                            </Link> : null}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* END Match*/}
                                        </div>
                                        <div className="col-12 col-md-6 timeline-wrap">

                                            <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4 timelinelist">
                                                <h3>Timeline</h3>
                                                {/* ADD NOTES */}

                                                <div className='tabtimeline'>
                                                    <Mentions
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        value={this.state.note_log}
                                                        onChange={this.onChangeNoteNote}
                                                        onSelect={this.onSelectNoteMentions}
                                                        options={this.state.userClients} rows={2}
                                                        />
                                                        {/* <textarea id="logsText" onChange={this.handleNoteLog} rows={4} placeholder="Add note.." className="font-14 mentionnote" defaultValue={""} /> */}
                                                        {
                                                        this.state.note_loader ?
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                            <Spin style={{ alignSelf: 'center' }} />
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row pt-2 mb-2 mx-auto justify-content-center'>
                                                                <Button id="logsbtn" onClick={this.handleNoteSubmit} type="primary" className="d-flex flex-row"><i className="bi bi-plus me-1 fs-5" />Add note</Button>
                                                                <Button id="calllogbtn" type="primary" onClick={this.handleCallLogModal} className="d-flex flex-row align-items-center mb-3 ml-2"><i className="bi bi-plus me-1 fs-5" /> <span className='font-12'>Add Call</span></Button>
                                                                <Button type='primary' onClick={this.handleNoAnswer} className='mb-3 ml-2'><i className="bi bi-telephone-x me-2"></i><span className='font-12'>No Answer</span></Button>
                                                            </div>
                                                        }

                                                </div>
                                                <div className='filteractivity'>
                                                    <p className='font-bold' style={{fontSize:14}}>Activity Log</p>
                                                    {/* <Button type='text'>
                                                        <i className="bi bi-funnel"></i>
                                                        <span>Filter Activity</span>
                                                    </Button> */}
                                                </div>
                                                <Tabs>
                                                    {/* <TabList> */}
                                                        {/* <Tab>All</Tab> */}
                                                        {/* <Tab>Notes</Tab>
                                                        <Tab>Calls</Tab>
                                                        <Tab>WhatsApp</Tab> */}
                                                    {/* </TabList> */}
                                                    <TabPanel>
                                                    
                                                    <Timeline>
                                                        
                                                            {this.state.requested_viewings && this.state.requested_viewings.map((requested_viewing) => (
                                                                <Timeline.Item dot={<i className="bi bi-house-fill"></i>} className={"timerequested_viewing"}>
                                                                    <div className='d-flex flex-column'>
                                                                       
                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                            <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                <Avatar className='d-flex mt-1' size={25} icon={ <img src={ requested_viewing.profile_photo== null || requested_viewing.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(requested_viewing.profile_photo)? requested_viewing.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + requested_viewing.profile_photo }/>} />
                                                                                <p className="awriter">
                                                                                    <span className="font-bold">{requested_viewing.by_user_name}</span>
                                                                                    <span> wants you to share property to your client.</span>
                                                                            </p>
                                                                            </div>
                                                                            <div className='align-content-center d-flex'>
                                                                                <p className="atime align-self-center">
                                                                                    <Moment fromNow>
                                                                                    {(requested_viewing.added_date) ? this.getCurrentTime(requested_viewing.added_date) : "-"}
                                                                                    </Moment>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='adesc p-0'>
                                                                            <div className='showpropertycontent'>
                                                                                            <a href=""
                                                                                               className='font-bold'>{requested_viewing.note}</a>
                                                                                            <p className="font-semibold">{requested_viewing.unitno}, {requested_viewing.community}, {requested_viewing.subcommunity} <i
                                                                                                className="bi bi-dot"></i><span>{(requested_viewing.price) ? this.getPrice(requested_viewing.price) : "-"}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <Row className='showpropbtm'>
                                                                                            <Col span={6} className='showprop-timer'>
                                                                                                <i className="bi bi-hourglass-split fs-6"></i>
                                                                                                <span className='font-bold'>{this.state.remainingTime}</span>
                                                                                            </Col>
                                                                                            <Col span={17}
                                                                                                 className='showprop-actions'>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        onClick={this.handleRequestNewViewing.bind(this, requested_viewing)}>Book
                                                                                                    a viewing</Button>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        disabled={this.state.isWhatsappLoading}
                                                                                                        onClick={this.handleisShareWhatsApp.bind(this, requested_viewing)}>Share
                                                                                                    via
                                                                                                    WhatsApp  {this.state.isWhatsappLoading && <Spin style={{ alignSelf: 'center' }} />}</Button>
                                                                                                <Button type='primary'
                                                                                                        size='small'
                                                                                                        onClick={this.handleisDecline.bind(this, requested_viewing)}>Decline</Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                            </div>
                                                                        </div>
                                                                </Timeline.Item>
                                                            ))}

                                                            {/*comment section*/}
                                                            {/*{this.state.comment &&*/}
                                                            {/*    <Timeline.Item dot={<i className="bi bi-chat-fill"></i>} className={"timecomment"}>*/}
                                                            {/*        <div className='d-flex flex-column'>*/}
                                                            {/*            <div className='d-flex flex-row justify-content-between'>*/}
                                                            {/*                <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>*/}
                                                            {/*                    <Avatar className='d-flex mt-1' size={25} icon={ <img src={"/images/Opportunitysquare.png"}/>} />*/}
                                                            {/*                    <p className="awriter"><span>Opportunity added a note</span></p>*/}
                                                            {/*                </div>*/}
                                                            {/*            </div>*/}
                                                            {/*            <div className='d-flex adesc'>{parse('<div>' + this.state.comment + '</div>')}</div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </Timeline.Item>*/}
                                                            {/*        */}
                                                            {/*    }*/}
                                                            {/*comment section*/}
                                                            

                                                            {this.state.all.map((al) => {
                                                                return (<>
                                                                    {al.note_type == "note" || al.note_type == "email"?
                                                                        <Timeline.Item dot={al.note_type == "note" ? <i className="bi bi-pin-angle-fill"></i>:al.note_type == "email" ?<i className="bi bi-envelope-fill" />:<i className="bi bi-pin-angle-fill"></i>} className={"time"+al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={ <img src={
                                                                                            al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                                                                            :al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name?al.by_user_name:al.note && al.note.includes('archived the chat') ?'':'Opportunity'}</span>
                                                                                            <span>
                                                                                                {al.note && al.note.includes('archived the chat') ? 'A client sent a message in the chat'
                                                                                                // :al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                                                                :al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                                                                ' updated a requirement'
                                                                                                :' added a note' }
                                                                                                </span>
                                                                                    </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                { al.note && al.note.includes("Call start :") ?
                                                                                    <div className='d-flex adesc'><p className='font-12'>{al.note && parse(al.note)}</p></div>
                                                                                :al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                                                    <>{this.handleSplitReq(al.note)}</>
                                                                                // :al.note.includes('property on Bayut') ?<span className="">{al.note}</span>
                                                                                :al.note && al.note.includes('archived the chat') ? <div className='d-flex adesc'>{al.note && parse(al.note)}</div>
                                                                                :<div className='d-flex adesc'>
                                                                                <div className='d-flex flex-column'>
                                                                                    <p className='font-12 mb-1'>
                                                                                        {al.note && parse(al.note)}
                                                                                    </p>
                                                                                    {al.lp_details ?
                                                                                        <div className='lp_details'>

                                                                                            <Carousel arrows style={{width:'160px',height:"98px",  overflow:'hidden'}} effect="fade" {...props}><Image.PreviewGroup>
                                                                                                {this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                                                    return <Image src={baseurlImg + "/" + image} style={{width:'100%'}} />
                                                                                                })}
                                                                                            </Image.PreviewGroup></Carousel>
                                                                                            <div className='lpdetails_text w-100'>
                                                                                                {al.lp_details.status=="D"? <p className='stat publishstat'>Draft</p>
                                                                                                    :al.lp_details.status=="UC"? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                                                    :al.lp_details.status=="OM"? <p className='stat offmarket'>Off-Market</p>
                                                                                                    :al.lp_details.status=="CTM"? <p className='stat comingtomarket'>Coming to Market</p>
                                                                                                    :al.lp_details.status=="Y"? <p className='stat published'>Published</p>
                                                                                                    :al.lp_details.status=="F"? <p className='stat feeds'>Feeds</p>
                                                                                                    :null
                                                                                                    }
                                                                                                <p>{al.lp_details.unitno?al.lp_details.unitno+",":null}{al.lp_details.property_project?al.lp_details.property_project+",":null}</p>
                                                                                                <p>{al.lp_details.subcommunity?al.lp_details.subcommunity+",":null}{al.lp_details.community?al.lp_details.community+",":null}</p>
                                                                                                <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    :null}
                                                                                </div>
                                                                            </div>}
                                                                            {al.note && al.note.includes("@") && !al.note.includes('archived the chat') ?
                                                                                   <>
                                                                                       <div className='d-flex flex-row w-100 taggedreply'>
                                                                                           <Avatar className='d-flex mt-1 mr-1' size={25} icon={ <img src={role_profile == null || role_profile == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(role_profile)? role_profile:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + role_profile }/>} />
                                                                                           <div className='d-flex flex-row w-100'>
                                                                                               <TextArea placeholder='Reply' className='adesctarea' rows={1} onChange={this.onEnterReply} value={this.state.reply}/>
                                                                                               <Button type='primary' onClick={this.onClickReply.bind(this, al)}>Reply</Button>
                                                                                           </div>
                                                                                       </div>
                                                                                       <Timeline className='d-flex flex-column mt-2'>
                                                                                           {al.children && al.children.length > 0 && al.children != [] && al.children.map((child,index) => (
                                                                                               /* Request Accepted : Book a viewing */
                                                                                               child && child.note_type == "reply" ?
                                                                                                   <Timeline.Item dot={<i className="bi bi-pin-angle-fill"></i>} className={"mt-2 time"+al.note_type}>
                                                                                                       <div className='d-flex flex-column'>
                                                                                                           <div className='d-flex flex-row justify-content-between'>
                                                                                                               <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                                                   <Avatar className='d-flex mt-1' size={25} icon={ <img src={ child.profile_photo== null || child.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(child.profile_photo)? child.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + child.profile_photo }/>} />
                                                                                                                   <p className="awriter">
                                                                                                                       <span className="font-bold">{child.by_user_name}</span>
                                                                                                                       <span> replied</span>
                                                                                                                   </p>
                                                                                                               </div>
                                                                                                               <div className='align-content-center d-flex'>
                                                                                                                   <p className="atime align-self-center">
                                                                                                                       <Moment fromNow>
                                                                                                                           {(child.added_date) ? this.getCurrentTime(child.added_date) : "-"}
                                                                                                                       </Moment>
                                                                                                                   </p>
                                                                                                               </div>
                                                                                                           </div>
                                                                                                           <div className="adesc">
                                                                                                               <span>{child.note}</span>
                                                                                                           </div>
                                                                                                       </div>
                                                                                                   </Timeline.Item>
                                                                                                   :null))}
                                                                                       </Timeline>
                                                                                   </>
                                                                                :null}
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    :al.note_type == "phone" ?
                                                                        <Timeline.Item dot={<i className="bi bi-telephone-fill"></i>} className={"time"+al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name? al.by_user_name:'Opportunity'}</span>
                                                                                            <span> logged a call</span>
                                                                                    </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex adesc'>
                                                                                    <p className='font-12'>
                                                                                        { (al.note && al.note != null) && parse(al.note)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    :al.note_type == "whatsapp" ?
                                                                        <Timeline.Item dot={ <i className="bi bi-whatsapp"></i>} className={this.state.whatsapp_from==1 ? 'timewhatsapp wa_child':'timewhatsapp wa_parent'}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                <img src={
                                                                                                    al.note && al.note.includes("Whatsapp - Propertyfinder") ?'/images/PropertyFinder.png'
                                                                                                    :al.note && al.note.includes("Whatsapp - Bayut")|| al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                                                                                    : al.note && al.note.includes("Automation sent whatsapp") || al.note && al.note.includes('Client Replied:') ?'/images/Opportunitysquare.png'
                                                                                                    : al.note && al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo
                                                                                                    } style={{transform:'unset'}}/>} />
                                                                                        <p className="awriter">
                                                                                            {al.note && al.note.includes("Whatsapp - Propertyfinder") ?<span className="">You got a new inquiry from <span className='font-bold'>PropertyFinder</span></span>
                                                                                            :al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ?<span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                                                            :al.note && al.note.includes('Client Replied:') ? <span> Client replied to the message</span>
                                                                                            :<span> Opportunity sent a message</span>}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex adesc'>
                                                                                <div className='d-flex flex-column'>
                                                                                    <p className='font-12 mb-1'>
                                                                                        {al.note && parse(al.note)}
                                                                                    </p>
                                                                                    {al.lp_details ?
                                                                                        <div className='lp_details'>
                                                                                            <Carousel arrows style={{width:'160px',height:"98px",  overflow:'hidden'}} effect="fade" {...props}>
                                                                                                {this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                                                    return <Image src={baseurlImg + "/" + image} style={{width:'100%'}} />
                                                                                                })}
                                                                                            </Carousel>
                                                                                            <div className='lpdetails_text w-100'>
                                                                                                {al.lp_details.status=="D"? <p className='stat publishstat'>Draft</p>
                                                                                                    :al.lp_details.status=="UC"? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                                                    :al.lp_details.status=="OM"? <p className='stat offmarket'>Off-Market</p>
                                                                                                    :al.lp_details.status=="CTM"? <p className='stat comingtomarket'>Coming to Market</p>
                                                                                                    :al.lp_details.status=="Y"? <p className='stat published'>Published</p>
                                                                                                    :al.lp_details.status=="F"? <p className='stat feeds'>Feeds</p>
                                                                                                    :null
                                                                                                    }
                                                                                                <p>{al.lp_details.unitno?al.lp_details.unitno+",":null}{al.lp_details.property_project?al.lp_details.property_project+",":null}</p>
                                                                                                <p>{al.lp_details.subcommunity?al.lp_details.subcommunity+",":null}{al.lp_details.community?al.lp_details.community+",":null}</p>
                                                                                                <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    :null}
                                                                                </div>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    :al.note_type == "viewing" ?
                                                                        <Timeline.Item dot={ <i className="bi bi-eye-fill" />} className={"time"+al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name? al.by_user_name:'Opportunity'}</span>
                                                                                            <span> scheduled a viewing</span>
                                                                                            {/* <span className='pstat scheduled'>Scheduled</span> */}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex adesc w-100'>
                                                                                    {this.handleSplitViewing(al.note, al.lp_details?al.lp_details:null)}
                                                                            </div>
                                                                            
                                                                            {al.property_viewings_feedback && al.property_viewings_feedback.map((vfeedback) => (
                                                                                <>
                                                                                { al.note.includes(vfeedback.refno) ?
                                                                                    <div className='d-flex flex-row mt-2'>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={
                                                                                            <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} />
                                                                                        <div className='messagewrap'>
                                                                                            <p>{vfeedback.note}</p>
                                                                                            <div className='d-flex w-100 align-items-center'>
                                                                                                <span className='mr-1 font-bold'>Status: </span>
                                                                                                <Tag>Scheduled</Tag>
                                                                                                <i className="bi bi-arrow-right mr-2 "></i>
                                                                                                <Tag className={vfeedback.status.replace(/ /g, '').toLowerCase() + ' pstat px-1'}>{vfeedback.status}</Tag>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :null}  
                                                                                </>
                                                                            ))}
                                                                        </Timeline.Item>
                                                                    :al.note_type=="offer"?
                                                                        <Timeline.Item dot={<i className="bi bi-tag-fill"></i>} className={"time"+al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?  "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } />} />
                                                                                        <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                                                        {al.note && al.note.includes("Viewing status") ?<span> updated the viewing status</span>
                                                                                        :al.note && al.note.includes("Offer status") ?<span> updated the offer status</span>
                                                                                        :null}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>{this.getCurrentTime(al.added_date)}</Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex adesc'>
                                                                                    <p className='font-12'>
                                                                                        {al.note}
                                                                                    </p>
                                                                                </div>
                                                                                    <div className="d-flex adesc-stat mt-1">
                                                                                        {al.note && al.note.includes("Viewing status changed to Offer") ?<>
                                                                                            <Tag className='pstat viewing'>Viewing</Tag>
                                                                                            <i className="bi bi-arrow-right"></i>
                                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                                        </>:al.note && al.note.includes("Not Interested") ?<>
                                                                                            <Tag className='pstat viewing'>Viewing</Tag>
                                                                                            <i className="bi bi-arrow-right"></i>
                                                                                            <Tag className='pstat cancelled'>Not Interested</Tag>
                                                                                        </>:al.note && al.note.includes("Offer Sent") ?<>
                                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                                            <i className="bi bi-arrow-right"></i>
                                                                                            <Tag className='pstat offer'>Offer Sent</Tag>
                                                                                        </>:al.note && al.note.includes("Offer Accepted") ?<>
                                                                                            <Tag className='pstat offer'>Offer Sent</Tag>
                                                                                            <i className="bi bi-arrow-right"></i>
                                                                                            <Tag className='pstat secondviewing'>Offer Accepted</Tag>
                                                                                        </>:al.note && al.note.includes("Offer Rejected") ?<>
                                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                                            <i className="bi bi-arrow-right"></i>
                                                                                            <Tag className='pstat scheduled'>Offer Rejected</Tag>
                                                                                        </>:al.note && al.note.includes("Deal Signed") ?<>
                                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                                            <i className="bi bi-arrow-right"></i>
                                                                                            <Tag className='pstat deal'>Deal Signed</Tag>
                                                                                        </>:null}
                                                                                    </div>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    :al.note_type=="noanswer"?
                                                                        <Timeline.Item dot={<i className="bi bi-telephone-x-fill"></i>} className={"time"+al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name? al.by_user_name:'Opportunity'}</span>
                                                                                            <span> called but no answer</span>
                                                                                    </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </Timeline.Item>
                                                                    :al.note_type=="req_expire"?
                                                                        <Timeline.Item dot={<i className="bi bi-house-fill"></i>} className={"time"+al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name? al.by_user_name:'Opportunity'}</span>
                                                                                            <span> requested to share his property to your client.</span>
                                                                                    </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='adesc'>
                                                                                    <a href="" className='font-bold color-text-black'>{al.note}</a>
                                                                                    <p className="font-semibold">{(al.unitno) ? al.unitno: ""} {(al.community) ? "," + al.community : ""} {(al.subcommunity) ? "," + al.subcommunity : ""} {(al.price) && <i className="bi bi-dot"></i>}<span>{(al.price) ? this.getPrice(al.price) : ""}</span></p>
                                                                                </div>
                                                                                {al.note_type && al.note_type == 'req_expire' ?
                                                                                    <Timeline className='d-flex flex-column mt-2'>
                                                                                        {al.children && al.children.length > 0 && al.children != [] && al.children.map((child) => (
                                                                                        /* Request Accepted : Book a viewing */
                                                                                        child && child.note_type == "req_accept" ?
                                                                                            <Timeline.Item dot={<i className="bi bi-calendar2-check"></i>} className={"time"+al.note_type}>
                                                                                                <div className='d-flex flex-column'>
                                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                                            <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                                            <p className="awriter">
                                                                                                                <span className="font-bold">{child.by_user_name}</span>
                                                                                                                <span> scheduled a viewing with client.</span>
                                                                                                        </p>
                                                                                                        </div>
                                                                                                        <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow>{(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="adesc">
                                                                                                        <Row>
                                                                                                            <Col span={24}><span className='font-10'>Date / Time:</span></Col>
                                                                                                            <Col span={8}>
                                                                                                                <p className='font-bold fs-6 mb-0'>{this.getTime(child.viewing_date)}</p>
                                                                                                                <p className="font-12">{this.getDate(child.added_date)}</p>
                                                                                                            </Col>
                                                                                                            <Col span={16}>
                                                                                                                <p>Viewing booked for <span className='font-bold'>{al.note}</span></p>
                                                                                                                <p className="font-12 mt-2">{al.unitno}, {al.community}, {al.subcommunity} <i  className="bi bi-dot"></i><span>{al.price} AED</span></p>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Timeline.Item>
                                                                                        :/* Share via WhatsApp - Replied */
                                                                                        child && child.note_type == "req_wait"?
                                                                                            <Timeline.Item dot={<i className="bi bi-pin-angle-fill"></i>} className={" time"+al.note_type}>
                                                                                                <div className='d-flex flex-column'>
                                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                                            <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                                            <p className="awriter">
                                                                                                                <span className="font-bold">{this.state.lead_list_details.owner}</span>
                                                                                                                <span> replied to the message.</span>
                                                                                                        </p>
                                                                                                        </div>
                                                                                                        <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="adesc">
                                                                                                        <Row className='align-content-center'>
                                                                                                            <Col span={16} className='d-flex flex-column'>
                                                                                                                <span className='font-10'>Best time to view:</span>
                                                                                                                <div className='d-flex flex-row'>
                                                                                                                    <p className='font-bold mb-0'>{child.note}</p>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col span={8}>
                                                                                                                <Button type='primary' onClick={this.handleRequestNewViewing.bind(this, al)}>Book a viewing</Button>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Timeline.Item>
                                                                                        :/* Share via WhatsApp - Shared via WhatsApp */
                                                                                        child && child.note_type == "req_w" ?
                                                                                            <Timeline.Item dot={<i className="bi bi-pin-angle-fill"></i>} className={"mt-2 time"+al.note_type}>
                                                                                                <div className='d-flex flex-column'>
                                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                                            <Avatar className='d-flex mt-1' size={25} icon={ <img src={ child.note.includes("System has shared")  ?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                                            <p className="awriter">
                                                                                                                <span>
                                                                                                                    {child.note.includes("System has shared") ?
                                                                                                                        <><span>Opportunity shared {child.by_user_name} property to the client</span>
                                                                                                                        </>
                                                                                                                    :child.note}
                                                                                                                </span>
                                                                                                        </p>
                                                                                                        </div>
                                                                                                        <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                            <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                    </Moment>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Timeline.Item>
                                                                                        :/* Share via WhatsApp - Shared via WhatsApp */
                                                                                        child && child.note_type == "req_decline" ?
                                                                                            <Timeline.Item dot={<i className="bi bi-pin-angle-fill"></i>} className={"mt-2 time"+al.note_type}>
                                                                                                <div className='d-flex flex-column'>
                                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                                            <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} />
                                                                                                            <p className="awriter">
                                                                                                                <span className="font-bold">{child.by_user_name}</span>
                                                                                                                <span> declined your request</span>
                                                                                                        </p>
                                                                                                        </div>
                                                                                                        <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="adesc">
                                                                                                        <span>{child.note}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Timeline.Item>
                                                                                        :null))}
                                                                                    </Timeline>
                                                                                :null}
                                                                            </div>
                                                                        </Timeline.Item>

                                                                    :al.note_type="followup" ?
                                                                        <Timeline.Item dot={<i className="bi bi-reply-fill"></i>} className={"timefollowup"}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name? al.by_user_name:'Opportunity'} added a feedback for viewing</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? al.added_date : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex adesc p-2'>
                                                                                <p className='font-12'>
                                                                                    {al.note}
                                                                                </p>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    : // DEfault
                                                                        <Timeline.Item dot={<i className="bi bi-pin-angle-fill"></i>} className={"time"+al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name? al.by_user_name:'Opportunity'}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex adesc'>
                                                                                <p className='font-12'>
                                                                                    {al.note}
                                                                                </p>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    }
                                                                </>)
                                                            })}
                                                        </Timeline>
                                                    </TabPanel>
                                                </Tabs>


                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                        <div className="d-flex flex-column w-100 bg-white border-top p-4 matchlistings">
                                                <h3>Match Listings</h3>
                                                <div className='tabtimeline'>
                                                    <Tabs>
                                                        <TabList>
                                                            <Tab><span className='font-12'>Exact ({this.state.exact_match_listings_count})</span></Tab>
                                                            <Tab><span className='font-12'>Comparable ({this.state.comparable_match_listings_count})</span></Tab>
                                                        </TabList>
                                                        <TabPanel>
                                                            <div className=' matchlistcontentwrap'>
                                                                {/* When they click the property it will open the drawer */}
                                                                {this.state.exact_match_listings.length > 0?<>
                                                                {this.state.exact_match_listings.map((exact_match)=>(
                                                                      <a className='rowmatchwrap'>
                                                                      <Row>
                                                                          <Col span={24} className='pl-1'>
                                                                              <div className='top-info'>
                                                                                  <div className='propfor font-10'>{exact_match.property_for =="sale"?"Sale":"Rent"} | {exact_match.refno}</div>

                                                                                  {exact_match.status =="Y"?
                                                                                  <div className="d-flex flex-row stat published ml-0">
                                                                                      <i className="bi bi-circle-fill" />
                                                                                      <span className="font-10">Published</span>
                                                                                  </div>:null}
                                                                                  {exact_match.status =="F"?
                                                                                  <div className="d-flex flex-row stat feeds ml-0">
                                                                                      <i className="bi bi-circle-fill" />
                                                                                      <span className="font-10">Feed</span>
                                                                                  </div>:null}
                                                                                  {exact_match.status =="CTM"?
                                                                                  <div className="d-flex flex-row stat comingtomarket ml-0">
                                                                                      <i className="bi bi-circle-fill" />
                                                                                      <span className="font-10">Coming to market</span>
                                                                                  </div>:null}
                                                                              </div>
                                                                              <p className="font-12 font-bold mb-0">{exact_match.unitno}, {exact_match.property}</p>
                                                                              <p className="font-10 mb-0 propadd">{exact_match.subcommunity} {exact_match.community}</p>
                                                                              <div className='bottominfo'>
                                                                                  <p className="font-10 mb-0 propadd">{exact_match.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                  <p className="font-10 mb-0 agentprop">{exact_match.display_name}</p>
                                                                              </div>
                                                                          </Col>
                                                                      </Row>
                                                                  </a>
                                                                ))}
                                                                </>:"no data"}


                                                                {/* View All: will go to the listing page design */}
                                                                {this.state.exact_match_listings.length > 0?
                                                                // <Link target="_blank" to={"/all_match_listings/"+this.state.record_id} className='rowmatchviewall font-12'>View All</Link>
                                                                <Link target="_blank" type='text' to={"/matched_lead_lists/"+this.state.record_id} className='rowmatchviewall font-10'>View All</Link>
                                                                :null}

                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel>
                                                        <div className=' matchlistcontentwrap'>
                                                                {/* When they click the property it will open the drawer */}
                                                                {this.state.comparable_match_listings.length > 0?<>
                                                                {this.state.comparable_match_listings.map((comparable_match)=>(
                                                                      <a className='rowmatchwrap'>
                                                                      <Row>
                                                                          <Col span={24} className='pl-1'>
                                                                              <div className='top-info'>
                                                                                  <div className='propfor font-10'>{comparable_match.property_for =="sale"?"Sale":"Rent"} | {comparable_match.refno}</div>

                                                                                  {comparable_match.status =="Y"?
                                                                                  <div className="d-flex flex-row stat published ml-0">
                                                                                      <i className="bi bi-circle-fill" />
                                                                                      <span className="font-10">Published</span>
                                                                                  </div>:null}
                                                                                  {comparable_match.status =="F"?
                                                                                  <div className="d-flex flex-row  stat feeds  ml-0">
                                                                                      <i className="bi bi-circle-fill" />
                                                                                      <span className="font-10">Feed</span>
                                                                                  </div>:null}
                                                                                  {comparable_match.status =="CTM"?
                                                                                  <div className="d-flex flex-row stat comingtomarket ml-0">
                                                                                      <i className="bi bi-circle-fill" />
                                                                                      <span className="font-10">Coming to market</span>
                                                                                  </div>:null}
                                                                              </div>
                                                                              <p className="font-12 font-bold mb-0">{comparable_match.unitno}, {comparable_match.property}</p>
                                                                              <p className="font-10 mb-0 propadd">{comparable_match.subcommunity} {comparable_match.community}</p>
                                                                              <div className='bottominfo'>
                                                                                  <p className="font-10 mb-0 propadd">{comparable_match.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                  <p className="font-10 mb-0 agentprop">{comparable_match.display_name}</p>
                                                                              </div>
                                                                          </Col>
                                                                      </Row>
                                                                  </a>
                                                                ))}
                                                                </>:"no data"}


                                                                {/* View All: will go to the listing page design */}
                                                                {this.state.comparable_match_listings.length > 0?
                                                                // <Link target="_blank" to={"/all_match_listings/"+this.state.record_id} className='rowmatchviewall font-12'>View All</Link>
                                                                <Button type='text' onClick={this.handleShowMatchListing} className='rowmatchviewall font-10'>View All</Button>
                                                                :null}

                                                            </div>
                                                        </TabPanel>
                                                    </Tabs>
                                                </div>
                                            </div>
                                            {/* OFFERS */}
                                            <div className="bg-white border-top offers p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4">Offers</h3>
                                                    <a href className="btn btn-link viewall">View All</a>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">

                                                    {this.state.property_offers.map((property_offer) => {

                                                        const offer_price = property_offer.price
                                                        return (
                                                            <a onClick={this.handleOfferView.bind(this, property_offer.views_id)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        <p className="address">{property_offer.address}</p>
                                                                        <p className="price">Offer:  {offer_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'}</p>
                                                                        <p className="price">{property_offer.view_date}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button type="button" className="btn btn-link addfeedback"></button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_offers.length > 0 ? <a className="matchitem viewall">
                                                        View All
                                                    </a> : null}
                                                </div>
                                            </div> {/* END OFFERS */}
                                            {/* VIEWINGS */}
                                            <div className="bg-white border-top viewings p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4">Viewings</h3>
                                                    <a onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</a>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">
                                                    {this.state.property_viewings.map((property_viewing) => {

                                                        return (
                                                            <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        <p className="address">{property_viewing.address}</p>
                                                                        <p className="price">
                                                                            {property_viewing.price != null && property_viewing.price != "" ? <>Offer: AED {property_viewing.price}</> : null}

                                                                        </p>
                                                                        <p className="price">{property_viewing.view_date}</p>
                                                                        <p className="price">{property_viewing.note}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button type="button" className="btn btn-link addfeedback"></button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_viewings.length > 0 ? <Link to={"/all_lead_viewings/" + this.state.record_id} target="_blank" className="btn btn-link viewall">View All</Link>
                                                        : null}

                                                </div>
                                            </div> {/* END VIEWINGS*/}

                                            {/* Followup */}
                                            <div className="bg-white border-top viewings p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4">Follow Up</h3>
                                                    <a onClick={this.handleNewFollowUp} className="btn btn-link viewall">Add Followup</a>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">
                                                    {this.state.followups_details.map((followup) => {
                                                        return (
                                                            <a onClick={this.handleViewFollowup.bind(this, followup)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        {/*<p className="price">{followups.first_name}</p>*/}
                                                                        <p className="price">{(followup.type === 1) ? "Vendor" : "Client"}</p>
                                                                        <p className="price">{(followup.status === 1) ? "Active" : "Inactive"}</p>
                                                                        <p className="address">
                                                                            {(followup.notes) ? (followup.notes.length > 40 ?
                                                                                `${followup.notes.substring(0, 40)}...` : followup.notes) : null
                                                                            }
                                                                        </p>
                                                                        <p className="price">{followup.date}</p>
                                                                        <p className="price">{(followup.agent_name) ?  "Follow up by " + followup.agent_name : ""}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button type="button" className="btn btn-link addfeedback"></button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                                                        View All
                                                    </a> : null}

                                                </div>
                                            </div> {/* END Followup*/}

                                            <div className="bg-white border-top viewings p-4 mt-4">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-start" }} >
                                                        <SettingFilled style={{ fontSize: '1rem', marginRight: '0.35rem' }} />
                                                        <h3 className="mr-4">Collaborators</h3>
                                                    </div>
                                                </div>

                                                <Select
                                                    // defaultValue="lucy"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '1rem'
                                                    }}
                                                    showSearch
                                                    mode={"multiple"}
                                                    value={this.state.newArrSelectedAgents}
                                                    placeholder="Select a person"
                                                    maxTagCount={"responsive"}
                                                    onChange={this.handleChangeSelect}
                                                    // onSelect={(itm)=> {
                                                    //   this.onSelectAgents(itm)
                                                    //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents)
                                                    // }}
                                                    // onDeselect={(itm)=> {
                                                    //   this.onDeSelectAgents(itm)
                                                    //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents)
                                                    // }}
                                                    options={this.state.agents}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                />
                                                {/* <button type="button" className="btn btn-dark w-40 mb-2"  >
                  <p style={{ fontSize:'1rem', color:'white' }} >
                    Add Collaborator
                  </p>
                </button> */}

                                                <Button onClick={this.postAddCollaborator} type="primary" style={{ backgroundColor: 'black', color: 'white' }} >Add Collaborator</Button>

                                                <div style={{ width: '100%', marginTop: '1rem', paddingLeft: '0.25rem' }} >
                                                    {this.state.collaborators.map((coll, index) => {
                                                        return (
                                                            <div className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        <p className="name">{coll.display_name}</p>
                                                                        <p className="price">{coll.added_date}</p>
                                                                    </div>
                                                                    {
                                                                        role == 1 ?
                                                                            <div className="col-3">
                                                                                <button type="button" className="btn btn-link addfeedback" onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)} > Delete</button>
                                                                            </div>
                                                                            :
                                                                            user_id == coll.user_id &&
                                                                            <div className="col-3">
                                                                                <button type="button" className="btn btn-link addfeedback" onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)} > Delete </button>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {this.state.collaborators.length > 0 ?
                                                        // <Link to={"/all_lead_viewings/" + this.state.record_id} target="_blank" className="btn btn-link viewall w-100 text-right">View All</Link>
                                                        <Button type='text' onClick={this.showMatchListings} className='rowmatchviewall font-12'>View All</Button>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>




                </Drawer>


            <Drawer title={`Lead details`} placement="left" width={"98%"}
            onClose={this.handleCloseEditDrawer}
            open={this.state.isOpenEditLead}>
                <Row>
                    <Col span={24}>
                        <h1>Lead Details</h1>
                    </Col>
                </Row>
            </Drawer>



            <Row className='contentwrap px-3 mt-4 pipeline'>
                <Col span={24} className='px-3'><h1 className='font-bold fs-4'>Leads</h1></Col>
                <Col span={24} className='px-3'>
                    <div className='form-group d-flex flex-row align-items-center'>
                        <label className='form-label mr-2'>Search: </label>
                        <Input style={{width:'250px'}}/>
                    </div>
                </Col>
                <Col span={20} className='filterwrap px-3'>
                    <Row className='align-items-center'>
                        <Col span={16}>
                            <div className='form-group d-flex flex-row align-items-center'>
                                <label className='form-label mr-2 font-bold'>Filter by: </label>
                                <Checkbox.Group options={[
                                    {label:'Buyer',value:'Buyer'},
                                    {label:'Tenant',value:'Tenant'},
                                    {label:'Pledge',value:'Pledge'},
                                ]} />
                            </div>    
                        </Col>
                        <Col span={8}>
                            <div className='form-group d-flex flex-row align-items-center'>
                                <label className='form-label mr-2 mb-0 font-bold' style={{whiteSpace:'nowrap'}}>Filter by Agent: </label>
                                <Select
                                            style={{
                                                width: "100%",
                                            }}
                                           
                                            showSearch
                                            allowClear
                                            placeholder="Select Agent"
                                            optionFilterProp="children"
                                            onChange={this.handleByAgent}

                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.agents}
                                        />
                            </div>
                        </Col>
                    </Row>
                    
                </Col>
                <Col className="ant-col-board">
                    <Board data={data} className=" board-container"
                          onCardAdd={this.handleCardAdd}
                          
                          onDataChange={this.shouldReceiveNewData}
                          eventBusHandle={this.setEventBus}
                          handleDragStart={this.handleDragStart}
                          handleDragEnd={this.handleDragEnd}
                        //   laneSortFunction={function noRefCheck(){}}
                          onLaneScroll={this.startPaginate}
                    />
                </Col>
            </Row>
        </>)
    }
}

export default Pipeline







