import React, { Component } from 'react'
import { Row, Col, Table, Input, Checkbox,Select, Button, Spin, Card, Popover,Segmented, Radio} from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import {RiseOutlined,CaretUpOutlined } from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import Moment from 'react-moment';
import {Link} from 'react-router-dom'

const { Meta } = Card;
const CheckboxGroup = Checkbox.Group;

class AreaStock extends Component {
    constructor(props){
        super(props)
            this.state = { 
                exclode:false,
                sortedInfo: null,
                columnKey:"",
                order:"",
                isLoading:false,
                selectedMonth:"All",
                provider:[],
                global_search:"",
                publish_date_morethan_option:false,
                publish_date_lessthan_option:false,
                publish_date_value_id:1,
                publish_date_value:"",
                property_for:[],
                bedroom:"",
                listings:[],
                agents: [],
                propertytypes:[],
                property_type_filter:[],
                added_date_morethan_option:false,
                added_date_lessthan_option:false,
                added_date_value_id:1,
                added_date_value:"",
                available_date_morethan_option:false,
                available_date_lessthan_option:false,
                available_date_value_id:1,
                available_date_value:"",
                agent_value:[],
                sales_volume:0,
                leads_count:0,
                listings_count:0,
                deals_count:0,
                total_commission:0,
                total_per_month:[],
                months:[{
                    key:1,
                    labe:"January"
                },
                {
                    key:2,
                    labe:"February"
                },
                {
                    key:3,
                    labe:"March"
                },
                {
                    key:4,
                    labe:"April"
                },
                {
                    key:5,
                    labe:"May"
                },
                {
                    key:6,
                    labe:"June"
                },
                {
                    key:7,
                    labe:"July"
                },
                {
                    key:8,
                    labe:"August"
                },
                {
                    key:9,
                    labe:"September"
                },
                {
                    key:10,
                    labe:"October"
                },
                {
                    key:11,
                    labe:"November"
                },
                {
                    key:12,
                    labe:"December"
                },
                
            ],
                month:""
            }
        }
    
        componentDidMount(){
            const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,
                agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
    
            API.get("/propertytypes")
            .then((response) =>{
              if(response.data.success){
                  this.setState({propertytypes:response.data.propertytypes})
              }
            })
    
            API.get("/all_agents")
            .then((response) => {
                    if (response.data.success) {
                        this.setState({ agents: response.data.agents })
                    }
                }
            )
        }
    
        fetchData = (page, perPage,data) => {
            this.setState({ isLoading: true })
            API.post(`/stock_listings_by_area?page=${page}&perPage=${perPage}`,data)
                .then(async (response) => {
                    await response.data.listings;
                    const alllist = response.data.listings.data;
    
                    
    
                    this.setState({
                        listings: response.data.listings,
                        isLoading: false
                    })
                    let total_confirmedowner = 0
                    let total_comingtomarket = 0
                    let total_published = 0
                    let total_feeds = 0
                    let total_averageprice = 0
                    let alltotal = 0
    
                    for (let i = 0; i < alllist.length; i++) {
                        total_confirmedowner +=alllist[i].confirmed
                        total_comingtomarket += alllist[i].coming
                        total_published += alllist[i].published
                        total_feeds += alllist[i].feeds
                        total_averageprice += parseInt(alllist[i].price_avg)
    
                    }
                    this.setState({
                        total_confirmedowner:total_confirmedowner,
                        total_comingtomarket:total_comingtomarket,
                        total_published:total_published,
                        total_feeds:total_feeds,
                        total_averageprice: total_averageprice/alllist.length,
                        alltotal: total_confirmedowner + total_comingtomarket + total_published + total_feeds
                    })
                    
                })
    
    
        }

        fetchTotalData = (data) => {
            this.setState({ isLoading: true })
            API.post("/stock_listings_by_area_total",data)
                .then(async (response) => {
                  this.setState({  sales_volume:response.data.sales_volume,
                   leads_count:response.data.leads_count,
                    listings_count:response.data.listings_count,
                    deals_count:response.data.deals_count,
                    total_commission:response.data.total_commission,total_per_month:response.data.total_per_month})
                    
                    
    
                })
    
    
        }
    
        handleGlobalSearch = (e) =>{
            this.setState({global_search:e.target.value})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:e.target.value,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            }
            this.fetchData(page,perPage,data)
            this.fetchTotalData(data);
        }
    
        handlePublishDateMorethan =() =>{
            this.setState({publish_date_morethan_option:true,publish_date_lessthan_option:false})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:true,
                publish_date_lessthan_option:false,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
    
                if(this.state.publish_date_value !=""){
                this.fetchData(page,perPage,data)
                this.fetchTotalData(data);
                }
        }
        handlePublishDateLessthan =() =>{
            this.setState({publish_date_morethan_option:false,publish_date_lessthan_option:true})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:false,
                publish_date_lessthan_option:true,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                 provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                if(this.state.publish_date_value !=""){
                 this.fetchData(page,perPage,data)
                 this.fetchTotalData(data);
                }
        }
    
        handlePublishDateValue_id = (e) =>{
            this.setState({publish_date_value_id:e.target.value})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:e.target.value,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                if(this.state.publish_date_value !=""){
                this.fetchData(page,perPage,data)
                this.fetchTotalData(data);
                }
        }
    
        handlePublishDateValue = (e)=>{
            this.setState({publish_date_value:e.target.value})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:e.target.value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                  this.fetchData(page,perPage,data)
                  this.fetchTotalData(data);
        }
    
        // added date
        handleAddedDateMorethan =() =>{
            this.setState({added_date_morethan_option:true,added_date_lessthan_option:false})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:true,
                added_date_lessthan_option:false,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
    
                if(this.state.added_date_value !=""){
                this.fetchData(page,perPage,data)
                this.fetchTotalData(data);
                }
        }
        handleAddedDateLessthan =() =>{
            this.setState({added_date_morethan_option:false,added_date_lessthan_option:true})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:false,
                added_date_lessthan_option:true,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                if(this.state.added_date_value !=""){
                 this.fetchData(page,perPage,data)
                 this.fetchTotalData(data);
                }
        }
    
        handleAddedDateValue_id = (e) =>{
            this.setState({added_date_value_id:e.target.value})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:e.target.value,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                if(this.state.added_date_value !=""){
                this.fetchData(page,perPage,data)
                this.fetchTotalData(data);
                }
        }
    
        handleAddedDateValue = (e)=>{
            this.setState({added_date_value:e.target.value})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:e.target.value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                  this.fetchData(page,perPage,data)
                  this.fetchTotalData(data);
        }
    
        // available date
        handleAvailableDateMorethan =() =>{
            this.setState({available_date_morethan_option:true,available_date_lessthan_option:false})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:true,
                available_date_lessthan_option:false,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
    
                if(this.state.available_date_value !=""){
                this.fetchData(page,perPage,data)
                this.fetchTotalData(data);
                }
        }
        handleAvailableDateLessthan =() =>{
            this.setState({available_date_morethan_option:false,available_date_lessthan_option:true})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                if(this.state.available_date_value !=""){
                 this.fetchData(page,perPage,data)
                 this.fetchTotalData(data);
                }
        }
    
        handleAvailableDateValue_id = (e) =>{
            this.setState({available_date_value_id:e.target.value})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:e.target.value,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                if(this.state.available_date_value !=""){
                this.fetchData(page,perPage,data)
                this.fetchTotalData(data);
                }
        }
    
        handleAvailableDateValue = (e)=>{
            this.setState({available_date_value:e.target.value})
            const page=1
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:e.target.value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
                  this.fetchData(page,perPage,data)
                  this.fetchTotalData(data);
        }
    
    
        handlePublished = (record,e)=>{
            const data = {status:"Y",community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id}
            this.props.setStatus(data)
            window.open("/all_listings", "_blank");
        }
    
        handleFeed = (record,e)=>{
            const data = {status:"F",community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id}
            this.props.setStatus(data)
            window.open("/all_listings", "_blank");
        }
    
        handleLeadCount = (record,e)=>{
            
            // const dataArray = record.lead_count
            // const localdata = []
            // for (let  i = 0;  i < dataArray.length;  i++) {
            //    localdata.push(dataArray[i].original_id)
                
            // }
            const dataArray = record.lead_count.length> 0?record.lead_count[0].original_id.split(",").filter(Boolean):[]; 
            // const localdata = []
            // for (let  i = 0;  i < dataArray.length;  i++) {
            //    localdata.push(dataArray[i].original_id)
                
            // }
          
           
           
            const listingArray = record.refno_list?record.refno_list.split(",").filter(Boolean):[]; 

            const data = {listing_array:dataArray,community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,provider:this.state.provider,agent_value:this.state.agent_value}
            this.props.setLeads(data)
            window.open("/leads", "_blank");
        }

        handleListingCount = (record,e)=>{
            

            const dataArray = record.listings[0].listing_id
          
            
            const localarray = dataArray? dataArray.split(','):[];
          
            
            const localdata = []
            for (let  i = 0;  i < localarray.length;  i++) {
               localdata.push(localarray[i])
                
            }

           
           
           
        const data = {listings_id:localdata,community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,publish_date_morethan_option:this.state.publish_date_morethan_option,
        publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
        publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
        property_type_filter:this.state.property_type_filter,
        added_date_morethan_option:this.state.added_date_morethan_option,
        added_date_lessthan_option:this.state.added_date_lessthan_option,
        added_date_value_id:this.state.added_date_value_id,
        added_date_value:this.state.added_date_value,
        available_date_morethan_option:this.state.available_date_morethan_option,
        available_date_lessthan_option:this.state.available_date_lessthan_option,
        available_date_value_id:this.state.available_date_value_id,
        available_date_value:e.target.value,agent_value:this.state.agent_value,provider:this.state.provider, exclode_zero:this.state.exclode ==true?"Yes":"No"}
        this.props.setStatus(data)
        window.open("/all_listings", "_blank");
        }

        handleDealCount = (record,all_refs,listing_id,e)=>{
            // return
            const listingArray = record.deals.length> 0?record.deals[0].property_viewings_refno.split(",").filter(Boolean):[]; 
            
            const data = {listing_id: listing_id, listing_array:all_refs,community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,provider:this.state.provider}
           
            this.props.setDeals(data)
            window.open("/deals", "_blank");
        }
        handleComingToMarket = (record,e)=>{
            const data = {status:"CTM",community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id}
            this.props.setStatus(data)
            window.open("/all_listings", "_blank");
        }
    
        // handleBed = (record,bed,e)=>{
        //     const data = {bedroom:bed,community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id}
        //     this.props.setBedroom(data)
        //     window.open("/all_listings", "_blank");
        // }
    
        handlePropertyFor = (e) =>{
    
            const redux_data = {property_for:e}
            this.props.setPropertyFor(redux_data)
                 
            this.setState({property_for:e})
            const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:e,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
        }
    
        handleBedroom = (e) =>{
            this.setState({bedroom:e})
            const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:e,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
        }
    
        roundTotal =(value, precision) =>{
            var multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        }
    
        handleClearFilter = () =>{
            this.setState({
                    columnKey:"",
                    order:"",
                    selectedMonth:"All",
                    isLoading:false,
                    global_search:"",
                    publish_date_morethan_option:false,
                    publish_date_lessthan_option:false,
                    publish_date_value_id:1,
                    publish_date_value:"",
                    property_for:[],
                    bedroom:"",
                    property_type_filter:[],
                    added_date_morethan_option:false,
                    added_date_lessthan_option:false,
                    added_date_value_id:1,
                    added_date_value:"",
                    available_date_morethan_option:false,
                    available_date_lessthan_option:false,
                    available_date_value_id:1,
                    available_date_value:"",
                    agent_value:[],
                    month:"",
                    provider:[],
                    exclode:false
            })
            const page=1;
            const perPage = 10
            const data = {
                columnKey:"",
                order:"",
                sLoading:false,
                global_search:"",
                publish_date_morethan_option:false,
                publish_date_lessthan_option:false,
                publish_date_value_id:1,
                publish_date_value:"",
                property_for:[],
                bedroom:"",
                property_type_filter:[],
                added_date_morethan_option:false,
                added_date_lessthan_option:false,
                added_date_value_id:1,
                added_date_value:"",
                available_date_morethan_option:false,
                available_date_lessthan_option:false,
                available_date_value_id:1,
                available_date_value:"",
                agent_value:[],
                month:"",
                provider:[],
                exclode_zero:"No"
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
        }
    
        handlePropertyTypeFilter = (e) =>{
    
            this.setState({property_type_filter:e})
    
            const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:e,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
    
        }
        handleAgent = (e) =>{
            this.setState({agent_value:e})
            const redux_data = {agent_value:e}
            this.props.setAgent(redux_data)
            const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,
                agent_value:e,month:this.state.month,
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
        }

        handleMonths = (e) =>{
            const found = this.state.months.find(obj => {
                return obj.labe === e;
              });
           if(found){
            this.setState({month:found.key,selectedMonth:found.labe})
             const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,
                agent_value:this.state.agent_value,month:found.key,
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
           }else{
            this.setState({month:"",selectedMonth:"All"})
             const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,
                agent_value:this.state.agent_value,month:"",
                provider:this.state.provider,
                exclode_zero:this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
           }
        }

        handleProvider = (e)=>{
      

            const value_lp= e.includes('90');
            if(value_lp){
              if(!e.includes('90')){
                 e.push('90')
              }
             //  else{
             //     e.pop('112')
             //  }
     
             if(!e.includes('121')){
                 e.push('121')
             }else{
                 e.pop('121')
             }

             if(!e.includes('129')){
                e.push('129')
            }else{
                e.pop('129')
            }

            if(!e.includes('130')){
                e.push('130')
            }else{
                e.pop('130')
            }
     
     
            }
            const value_arr= e.includes('112');
       if(value_arr){
         if(!e.includes('112')){
            e.push('112')
         }
        //  else{
        //     e.pop('112')
        //  }

        if(!e.includes('123')){
            e.push('123')
        }else{
            e.pop('123')
        }


       }

       const value_arr2= e.includes('71');
       if(value_arr2){
         if(!e.includes('71')){
            e.push('71')
         }
     

        if(!e.includes('122')){
            e.push('122')
        }else{
            e.pop('122')
        }


       }


       const value_arr26= e.includes('26');
       if(value_arr26){
         if(!e.includes('26')){
            e.push('26')
         }
     

        if(!e.includes('126')){
            e.push('126')
        }else{
            e.pop('126')
        }


       }

      
       


          this.setState({provider:e})
        
          const page=1;
          const perPage = 10
          const data = {
            columnKey:this.state.columnKey,
            order:this.state.order,
              global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
              publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
              publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
              property_type_filter:this.state.property_type_filter,
              added_date_morethan_option:this.state.added_date_morethan_option,
              added_date_lessthan_option:this.state.added_date_lessthan_option,
              added_date_value_id:this.state.added_date_value_id,
              added_date_value:this.state.added_date_value,
              available_date_morethan_option:this.state.available_date_morethan_option,
              available_date_lessthan_option:this.state.available_date_lessthan_option,
              available_date_value_id:this.state.available_date_value_id,
              available_date_value:this.state.available_date_value,
              agent_value:this.state.agent_value,month:this.state.month,
              provider:e,
              exclode_zero:this.state.exclode ==true?"Yes":"No"
          
          }
          this.fetchData(page,perPage,data);
          this.fetchTotalData(data);
        }

        handleDirect = (record,direct,e) =>{
           

           

            const listingArray = direct.length> 0?direct:[] 
            const current_data = {
                lead_listing_refno: record.deals[0].property_viewings_refno,
                original_id: record.deals[0].property_viewings_leads


              };

             

            const current_refnos = current_data.lead_listing_refno?current_data.lead_listing_refno.split(',').filter(Boolean):[]
            const current_ids = current_data.original_id?current_data.original_id.split(',').filter(Boolean):[]


     

            const current_result = [];
            if(current_ids.length > current_refnos.length){
            for (let i = 0; i < current_ids.length; i++) {
                current_result.push({
                lead_listing_refno: current_refnos[i],
                original_id: current_ids[i]
            });
            }}else{
                for (let i = 0; i < current_refnos.length; i++) {
                    current_result.push({
                    lead_listing_refno: current_refnos[i],
                    original_id: current_ids[i]
                });
                }
            }

          

          
            const newdata = {
                lead_listing_refno: record.exact_lead_count[0].lead_listing_refno,
                original_id: record.exact_lead_count[0].original_id
              };
            const refnos = newdata.lead_listing_refno?newdata.lead_listing_refno.split(',').filter(Boolean):[]
            const ids = newdata.original_id?newdata.original_id.split(',').filter(Boolean):[]

            // Create an array of objects
            const result = [];
            for (let i = 0; i < ids.length; i++) {
            result.push({
                lead_listing_refno: refnos[i],
                original_id: ids[i]
            });
            }

       


            const direct_data = [];
            const indirect_data = [];

            // Iterate over array2 and compare original_id with array1
            for (const item2 of current_result) {
            const foundInArray1 = result.some(item1 => item1.original_id === item2.original_id);
            
            if (foundInArray1) {
                direct_data.push(item2);
            } else {
                indirect_data.push(item2);
            }
            }




            const refnoArray = direct_data.map(item => item.lead_listing_refno);
            const direct_refs = [...new Set(refnoArray)];

         
           



            const data = {listing_array:direct_refs,community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,provider:this.state.provider}
            this.props.setDeals(data)
            window.open("/deals", "_blank");
        }

        handleInDirect =(record,indirect,e) =>{
           
         

            const current_data = {
                lead_listing_refno: record.deals[0].property_viewings_refno,
                original_id: record.deals[0].property_viewings_leads


              };

             

            const current_refnos = current_data.lead_listing_refno?current_data.lead_listing_refno.split(',').filter(Boolean):[]
            const current_ids = current_data.original_id?current_data.original_id.split(',').filter(Boolean):[]


     

            const current_result = [];
            if(current_ids.length > current_refnos.length){
            for (let i = 0; i < current_ids.length; i++) {
                current_result.push({
                lead_listing_refno: current_refnos[i],
                original_id: current_ids[i]
            });
            }}else{
                for (let i = 0; i < current_refnos.length; i++) {
                    current_result.push({
                    lead_listing_refno: current_refnos[i],
                    original_id: current_ids[i]
                });
                }
            }

          

          
            const newdata = {
                lead_listing_refno: record.exact_lead_count[0].lead_listing_refno,
                original_id: record.exact_lead_count[0].original_id
              };
            const refnos = newdata.lead_listing_refno?newdata.lead_listing_refno.split(',').filter(Boolean):[]
            const ids = newdata.original_id?newdata.original_id.split(',').filter(Boolean):[]

            // Create an array of objects
            const result = [];
            for (let i = 0; i < ids.length; i++) {
            result.push({
                lead_listing_refno: refnos[i],
                original_id: ids[i]
            });
            }

       


            const direct_data = [];
            const indirect_data = [];

            // Iterate over array2 and compare original_id with array1
            for (const item2 of current_result) {
            const foundInArray1 = result.some(item1 => item1.original_id === item2.original_id);
            
            if (foundInArray1) {
                direct_data.push(item2);
            } else {
                indirect_data.push(item2);
            }
            }





const refnoArray = indirect_data.map(item => item.lead_listing_refno);
const indirect_refs = [...new Set(refnoArray)];



            const data = {listing_array:indirect_refs,community_id:record.community_id,subcommunity_id:record.subcommunity_id,property_id:record.property_id,provider:this.state.provider}
            this.props.setDeals(data)
            window.open("/deals", "_blank");
        }

        handleExclodeZero = () =>{
            this.setState({exclode:!this.state.exclode})
            const page=1;
            const perPage = 10
            const data = {
                columnKey:this.state.columnKey,
                order:this.state.order,
                global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
                publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
                publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,
                property_type_filter:this.state.property_type_filter,
                added_date_morethan_option:this.state.added_date_morethan_option,
                added_date_lessthan_option:this.state.added_date_lessthan_option,
                added_date_value_id:this.state.added_date_value_id,
                added_date_value:this.state.added_date_value,
                available_date_morethan_option:this.state.available_date_morethan_option,
                available_date_lessthan_option:this.state.available_date_lessthan_option,
                available_date_value_id:this.state.available_date_value_id,
                available_date_value:this.state.available_date_value,
                agent_value:this.state.agent_value,month:this.state.month,
                provider:this.state.provider,
                exclode_zero:!this.state.exclode ==true?"Yes":"No"
            
            }
            this.fetchData(page,perPage,data);
            this.fetchTotalData(data);
        }
        
    render() {
        const current_available_months = this.state.total_per_month

       // updated to push

     
       let { sortedInfo } = this.state;
       sortedInfo = sortedInfo || {};



        const months_array_data = [
            {key:0, value:"All", label:(<div className='d-flex flex-column'><span>All</span><span className='font-12'></span></div>)},
            {key:1, value:"January", label:(<div className='d-flex flex-column'><span>January</span><span className='font-12'></span></div>)},
            {key:2, value:"February", label:(<div className='d-flex flex-column'><span>February</span><span className='font-12'></span></div>)},
            { key:3,value:"March", label:(<div className='d-flex flex-column'><span>March</span><span className='font-12'></span></div>)},
            { key:4,value:"April", label:(<div className='d-flex flex-column'><span>April</span><span className='font-12'></span></div>)},
            { key:5,value:"May", label:(<div className='d-flex flex-column'><span>May</span><span className='font-12'></span></div>)},
            {key:6, value:"June", label:(<div className='d-flex flex-column'><span>June</span><span className='font-12'></span></div>)},
            { key:7,value:"July", label:(<div className='d-flex flex-column'><span>July</span><span className='font-12'></span></div>)},
            { key:8,value:"August", label:(<div className='d-flex flex-column'><span>August</span><span className='font-12'></span></div>)},
            {key:9, value:"September", label:(<div className='d-flex flex-column'><span>September</span><span className='font-12'></span></div>)},
            { key:10,value:"October", label:(<div className='d-flex flex-column'><span>October</span><span className='font-12'></span></div>)},
            {key:11, value:"November", label:(<div className='d-flex flex-column'><span>November</span><span className='font-12'></span></div>)},
            {key:12, value:"December", label:(<div className='d-flex flex-column'><span>December</span><span className='font-12'></span></div>)},
        ]

        const updatedMonthsArray = months_array_data.map(month => {
            const correspondingData = current_available_months.find(data => data.month === month.key );
        
            if (correspondingData) {
              return {
                ...month,
                label: (
                  <div className='d-flex flex-column'>
                    <span className='font-bold'>{month.value}</span>
                    <span className='font-semibold font-10'>Volume: </span>
                    <span className='font-12'>{correspondingData.amount?correspondingData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':""} </span>
                    <span className='font-semibold font-10 mt-2'>Commission: </span>
                    <span className='font-12'>{correspondingData.comission?correspondingData.comission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':""} </span>
                  </div>
                ),
              };
            }
        
            return month;
          });


        const columns = [
            { title: 'Community', dataIndex: 'community',
           
                render:(text, record)=>(
                    <>
                        <div className='info-group d-flex flex-column'>
                            {/* <label>Community:</label> */}
                            <p>{record.community}</p>
                        </div>
                    </>
                )
            },{ title: 'Sub Community', dataIndex: 'subcommunity',
                render:(text, record)=>(
                    <>
                        <div className='info-group d-flex flex-column'>
                            {/* <label>Sub Community:</label> */}
                            <p>{record.subcommunity}</p>
                        </div>
                    </>
                )
            },{ title: 'Property', dataIndex: 'property',
                render:(text, record)=>(
                    <>
                        <div className='info-group d-flex flex-column'>
                            {/* <label>Property:</label> */}
                            <p>{record.property?record.property:"-"}</p>
                        </div>
                    </>
                )
            },
            // { title: 'Bedrooms', dataIndex: 'beds',
            //     render:(text, record)=>{
            //         const bedroomArray = record.bedroom?record.bedroom.split(",").filter(Boolean):[]; 
                    
            //        return( <div className='info-group d-flex flex-column statstock'>
            //             <label>Bedrooms:</label>
            //             {bedroomArray.map((bed)=>(
            //             <Link >{bed}</Link>

            //             ))}
            //             {/* Bedrooms - can we make it array then they will click on the number it will filter? */}
            //             {/* <div className='flex-row '>
            //             {record.beds && record.beds.map((bed)=>(
            //                 <>{bed.bedrooms?  <a href>{bed.bedrooms},</a>:null}</>
                          
            //             ))}
            //            </div> */}
            //         </div>
            //        )
            //     }
            // }
            { title: 'Volume', dataIndex: 'salesvolume',
            key:"volume",
            sorter: (a, b) => a.volume - b.volume,
            sortOrder: sortedInfo.columnKey === 'volume' && sortedInfo.order,
            render:(text, record)=>{
                const amount = record.deals[0].amount
            
                return (
                <div className='d-flex flex-column statstock'>
                    <div className='info-group d-flex flex-column'>
                        {/* <label>Average Price:</label> */}
                        <span>
                        {!isNaN(parseInt(amount))?<>
                            {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}   
                        </>:null}</span>
                    </div>
                </div>
                )
            }
            },
            { title: 'Leads', dataIndex: 'leads',
            key:"leads",
            sorter: (a, b) => a.leads - b.leads,
            sortOrder: sortedInfo.columnKey === 'leads' && sortedInfo.order,
                render:(text, record)=>{
                    const listingArray = record.deals[0].property_viewings_leads?record.deals[0].property_viewings_leads.split(",").filter(Boolean):[]; 
                
                    const uniqueArray = [...new Set(listingArray)];
                   
                  return(  <div className='d-flex flex-column statstock'>
                        <div className='info-group d-flex flex-column comingtomarket'>
                            {(record.lead_count && record.lead_count[0]) && record.lead_count[0].leads_count > 0? <Link  className="infostat" onClick={this.handleLeadCount.bind(this,record)}>{record.lead_count[0].leads_count && record.lead_count[0].leads_count >0?<>
                                <span>{record.lead_count[0].leads_count}   ({uniqueArray.length >= record.lead_count[0].lead_deal_signed?record.lead_count[0].lead_deal_signed:0})</span>
                            </>:"0"}</Link>:<Link  className="infostat"  >0</Link>}
                           
                           
                        </div>
                    </div>
                  )
                }
            },{ title: 'Listings', dataIndex: 'listings',
            key:"listings",
            sorter: (a, b) => a.listings - b.listings,
            sortOrder: sortedInfo.columnKey === 'listings' && sortedInfo.order,
                render:(text, record)=>{
                   
                   return( <div className='d-flex flex-column statstock' style={{gap:'0.6rem'}}>
                        <div className='info-group d-flex flex-column published'>
                        {(record.listings && record.listings[0]) && record.listings? record.listings[0].listing_counts > 0? <Link  className="infostat text-center" onClick={this.handleListingCount.bind(this,record)}>{record.listings[0].listing_counts && record.listings[0].listing_counts >0?<>
                                <span className='text-center'>{record.listings[0].listing_counts}</span>
                            </>:"0"}</Link>:<Link  className="infostat text-center">0</Link>:<span className='text-center'>0</span>}
                            
                        </div>
                    </div>
                   )
                }
            },{ title: 'Deals', dataIndex: 'noofdeals', 
            key:"deals",
            sorter: (a, b) => a.deals - b.deals,
            sortOrder: sortedInfo.columnKey === 'deals' && sortedInfo.order,
            render:(text, record)=>{

                
                const listingArray = record.deals[0].property_viewings_leads?record.deals[0].property_viewings_leads.split(",").filter(Boolean):[]; 
                

           
                
                // Use a Set to store unique values
                const uniqueArray = [...new Set(listingArray)];
                
                const current_data = {
                    lead_listing_refno: record.deals[0].property_viewings_refno,
                    original_id: record.deals[0].property_viewings_leads
    
    
                  };
    
                 
    
                const current_refnos = current_data.lead_listing_refno?current_data.lead_listing_refno.split(',').filter(Boolean):[]
                const current_ids = current_data.original_id?current_data.original_id.split(',').filter(Boolean):[]
    
    
         
    
                const current_result = [];
                if(current_ids.length > current_refnos.length){
                for (let i = 0; i < current_ids.length; i++) {
                    current_result.push({
                    lead_listing_refno: current_refnos[i],
                    original_id: current_ids[i]
                });
                }}else{
                    for (let i = 0; i < current_refnos.length; i++) {
                        current_result.push({
                        lead_listing_refno: current_refnos[i],
                        original_id: current_ids[i]
                    });
                    }
                }
    
              
    
              
                const newdata = {
                    lead_listing_refno: record.exact_lead_count[0].lead_listing_refno,
                    original_id: record.exact_lead_count[0].original_id
                  };
                const refnos = newdata.lead_listing_refno?newdata.lead_listing_refno.split(',').filter(Boolean):[]
                const ids = newdata.original_id?newdata.original_id.split(',').filter(Boolean):[]
    
                // Create an array of objects
                const result = [];
                for (let i = 0; i < ids.length; i++) {
                result.push({
                    lead_listing_refno: refnos[i],
                    original_id: ids[i]
                });
                }
    
               
    
    
                const direct_data = [];
                const indirect_data = [];
    
                // Iterate over array2 and compare original_id with array1
                for (const item2 of current_result) {
                const foundInArray1 = result.some(item1 => item1.original_id === item2.original_id);
                
                if (foundInArray1) {
                    direct_data.push(item2);
                } else {
                    indirect_data.push(item2);
                }
                }
    
    
                const refnoArray = direct_data.map(item => item.lead_listing_refno);
                const direct_refs = [...new Set(refnoArray)];
                const in_refnoArray = indirect_data.map(item => item.lead_listing_refno);
                const indirect_refs = [...new Set(in_refnoArray)];
    
                const all_refs = direct_refs.concat(indirect_refs)
         
               
              return( <div className='d-flex flex-column statstock'>
                    <div className='info-group d-flex flex-column feeds'>
                        {/* <label>Feeds:</label> */}

                        {/* {listingArray.length? <Link  className="infostat" onClick={this.handleDealCount.bind(this,record)}>{listingArray && listingArray.length >0?<>
                                <span>{record.deals[0].deal_signed}</span>
                            </>:"0"}</Link>:<Link  className="infostat"  >0</Link>} */}

                        {all_refs.length? <Link  className="infostat" onClick={this.handleDealCount.bind(this,record,all_refs, current_ids)}>{all_refs.length >0?<>
                                <span>{all_refs.length}</span>
                            </>:"0"}</Link>:<Link  className="infostat"  >0</Link>}
                        
                    </div>
                </div>
               )
            }
            },
            { title: 'Direct', dataIndex: 'direct',
                render:(text, record)=>{

                    // here
                     const current_data = {
                    lead_listing_refno: record.deals[0].property_viewings_refno,
                    original_id: record.deals[0].property_viewings_leads
    
    
                  };
    
                 
    
                const current_refnos = current_data.lead_listing_refno?current_data.lead_listing_refno.split(',').filter(Boolean):[]
                const current_ids = current_data.original_id?current_data.original_id.split(',').filter(Boolean):[]
    
    
         
    
                const current_result = [];
                if(current_ids.length > current_refnos.length){
                for (let i = 0; i < current_ids.length; i++) {
                    current_result.push({
                    lead_listing_refno: current_refnos[i],
                    original_id: current_ids[i]
                });
                }}else{
                    for (let i = 0; i < current_refnos.length; i++) {
                        current_result.push({
                        lead_listing_refno: current_refnos[i],
                        original_id: current_ids[i]
                    });
                    }
                }
    
              
    
              
                const newdata = {
                    lead_listing_refno: record.exact_lead_count[0].lead_listing_refno,
                    original_id: record.exact_lead_count[0].original_id
                  };
                const refnos = newdata.lead_listing_refno?newdata.lead_listing_refno.split(',').filter(Boolean):[]
                const ids = newdata.original_id?newdata.original_id.split(',').filter(Boolean):[]
    
                // Create an array of objects
                const result = [];
                for (let i = 0; i < ids.length; i++) {
                result.push({
                    lead_listing_refno: refnos[i],
                    original_id: ids[i]
                });
                }
    
               
    
    
                const direct_data = [];
                const indirect_data = [];
    
                // Iterate over array2 and compare original_id with array1
                for (const item2 of current_result) {
                const foundInArray1 = result.some(item1 => item1.original_id === item2.original_id);
                
                if (foundInArray1) {
                    direct_data.push(item2);
                } else {
                    indirect_data.push(item2);
                }
                }
    
    
                const refnoArray = direct_data.map(item => item.lead_listing_refno);
                const direct_refs = [...new Set(refnoArray)];
                const in_refnoArray = indirect_data.map(item => item.lead_listing_refno);
                const indirect_refs = [...new Set(in_refnoArray)];
    
                const all_refs = direct_refs.concat(indirect_refs)
                   
                    //  const leads_array =record.lead_count.length> 0?record.lead_count[0].lead_listing_refno.split(",").filter(Boolean):[]; 
                    const leads_array = record.exact_lead_count.length> 0 && record.exact_lead_count[0].original_id?record.exact_lead_count[0].original_id.split(",").filter(Boolean):[]
                    const uniquelead = [...new Set(leads_array)];
                    
                    // Use a Set to store unique values
                   

                    const propertyViewingsRefno = record.deals[0].property_viewings_leads // This is a string
                   
                 
                    const propertyViewingsRefnoArray = propertyViewingsRefno? propertyViewingsRefno.split(','):[];
                    const uniqueArray = [...new Set(propertyViewingsRefnoArray)];
                    
                
                    
                    const commonRefnos = uniqueArray.filter(refno => uniquelead.includes(refno));
                   return( <>
                        <div className='info-group d-flex flex-column'>
                            {/* <label>Property:</label> */}
                            {direct_refs.length > 0?<Link className="infostat text-center" onClick={this.handleDirect.bind(this,record,direct_refs)}>{direct_refs.length}</Link>:<p className="infostat text-center">{direct_refs.length}</p>}
                            
                        </div>
                    </>)
                }
            },{ title: 'Indirect', dataIndex: 'indirect',
            render:(text, record)=>{

                // here

                const current_data = {
                    lead_listing_refno: record.deals[0].property_viewings_refno,
                    original_id: record.deals[0].property_viewings_leads
    
    
                  };
    
                 
    
                const current_refnos = current_data.lead_listing_refno?current_data.lead_listing_refno.split(',').filter(Boolean):[]
                const current_ids = current_data.original_id?current_data.original_id.split(',').filter(Boolean):[]
    
    
         
    
                const current_result = [];
                if(current_ids.length > current_refnos.length){
                for (let i = 0; i < current_ids.length; i++) {
                    current_result.push({
                    lead_listing_refno: current_refnos[i],
                    original_id: current_ids[i]
                });
                }}else{
                    for (let i = 0; i < current_refnos.length; i++) {
                        current_result.push({
                        lead_listing_refno: current_refnos[i],
                        original_id: current_ids[i]
                    });
                    }
                }
    
              
    
              
                const newdata = {
                    lead_listing_refno: record.exact_lead_count[0].lead_listing_refno,
                    original_id: record.exact_lead_count[0].original_id
                  };
                const refnos = newdata.lead_listing_refno?newdata.lead_listing_refno.split(',').filter(Boolean):[]
                const ids = newdata.original_id?newdata.original_id.split(',').filter(Boolean):[]
    
                // Create an array of objects
                const result = [];
                for (let i = 0; i < ids.length; i++) {
                result.push({
                    lead_listing_refno: refnos[i],
                    original_id: ids[i]
                });
                }
    
               
    
    
                const direct_data = [];
                const indirect_data = [];
    
                // Iterate over array2 and compare original_id with array1
                for (const item2 of current_result) {
                const foundInArray1 = result.some(item1 => item1.original_id === item2.original_id);
                
                if (foundInArray1) {
                    direct_data.push(item2);
                } else {
                    indirect_data.push(item2);
                }
                }
    
    
                const refnoArray = direct_data.map(item => item.lead_listing_refno);
                const direct_refs = [...new Set(refnoArray)];
                const in_refnoArray = indirect_data.map(item => item.lead_listing_refno);
                const indirect_refs = [...new Set(in_refnoArray)];
    
                const all_refs = direct_refs.concat(indirect_refs)

                const leads_array = record.exact_lead_count.length> 0 && record.exact_lead_count[0].original_id?record.exact_lead_count[0].original_id.split(",").filter(Boolean):[]
                // const leads_array =
                //  []
                //     const lead_ref = record.lead_count
                //     for (let i = 0; i < lead_ref.length; i++) {
                //         leads_array.push(lead_ref[i].lead_listing_refno)
                        
                //     }
                    // return

                    const uniquelead = [...new Set(leads_array)];

                  
                    const propertyViewingsRefno = record.deals[0].property_viewings_leads  // This is a string
                   
                    const propertyViewingsRefnoArray = propertyViewingsRefno? propertyViewingsRefno.split(','):[];
                    const uniqueArray = [...new Set(propertyViewingsRefnoArray)];
                    const commonRefnos = uniqueArray.filter(refno => uniquelead.includes(refno));
                    const not_includes = uniqueArray.filter(refno => !uniquelead.includes(refno));
                  
               return ( <>
                    <div className='info-group d-flex flex-column'>
                        {/* <label>Property:</label> */}
                        {indirect_refs.length > 0?<Link className="infostat text-center" onClick={this.handleInDirect.bind(this,record,indirect_refs)}>{indirect_refs.length}</Link>:<p className="infostat text-center">{indirect_refs.length}</p>}
                        {/* <p className="infostat text-center">{record.deals[0].deal_signed - commonRefnos.length}</p> */}
                    </div>
                </>
               )
            }
        },{ title: 'Commission', dataIndex: 'totalcommission',
        key:"commission",
            sorter: (a, b) => a.commission - b.commission,
            sortOrder: sortedInfo.columnKey === 'commission' && sortedInfo.order,
            render:(text, record)=>{
                const comission = record.deals[0].comission
            
                return (
                <div className='d-flex flex-column statstock'>
                    <div className='info-group d-flex flex-column text-center'>
                        {/* <label>Average Price:</label> */}
                        <span>
                        {!isNaN(parseInt(comission))?<>
                            {comission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}   
                        </>:null}</span>
                    </div>
                </div>
                )
            }
        },{ title: 'Conversion', dataIndex: 'totalconversion',
       
        render:(text, record)=>{
            let total_conversion = 0
            const lead = (record.lead_count && record.lead_count[0]) && record.lead_count[0].leads_count
            const propertyViewingsRefno = record.deals[0].property_viewings_refno  // This is a string
                    const propertyViewingsRefnoArray = propertyViewingsRefno? propertyViewingsRefno.split(','):[];
            const deal = propertyViewingsRefnoArray.length?propertyViewingsRefnoArray.length:0
          
            if(lead > 0 && deal > 0){
                total_conversion = (deal / lead) * 100
            }else
            if(lead <=0 && deal > 0){
                total_conversion = 100
            }
            
        
            return (
            <div className='d-flex flex-column statstock'>
                <div className='info-group d-flex flex-column'>
                    {/* <label>Average Price:</label> */}
                    <span>
                    {!isNaN(parseInt(total_conversion))?<>
                        {this.roundTotal(total_conversion,1)} %
                    </>:null}</span>
                </div>
            </div>
            )
        }
    }
        ]

        const handleTableChange = (pagination,filters,sorter) =>{
       
            this.setState({
                sortedInfo: sorter,
                columnKey:sorter.order !=false? sorter.columnKey:"",
                order:sorter.order !=false?sorter.order:"",
              });
           const data = {global_search:this.state.global_search,publish_date_morethan_option:this.state.publish_date_morethan_option,
            publish_date_lessthan_option:this.state.publish_date_lessthan_option,publish_date_value_id:this.state.publish_date_value_id,
            publish_date_value:this.state.publish_date_value,property_for:this.state.property_for,bedroom:this.state.bedroom,   
             property_type_filter:this.state.property_type_filter,
            added_date_morethan_option:this.state.added_date_morethan_option,
            added_date_lessthan_option:this.state.added_date_lessthan_option,
            added_date_value_id:this.state.added_date_value_id,
            added_date_value:this.state.added_date_value,
            available_date_morethan_option:this.state.available_date_morethan_option,
            available_date_lessthan_option:this.state.available_date_lessthan_option,
            available_date_value_id:this.state.available_date_value_id,
            available_date_value:this.state.available_date_value,agent_value:this.state.agent_value,month:this.state.month,
            provider:this.state.provider,
            columnKey:sorter.order !=false? sorter.columnKey:"",
            order:sorter.order !=false?sorter.order:"",
            exclode_zero:this.state.exclode ==true?"Yes":"No"
        }
            this.fetchData(pagination.current, pagination.pageSize,data);
        }
        
        const sales_volume =this.state.sales_volume
        const leads_count = this.state.leads_count
        const listings_count = this.state.listings_count
        const deals_count = this.state.deals_count
        const total_commission = this.state.total_commission
        return (
            <>
                <Row className='contentwrap mt-5 areastockwrap stockwrap'>
                    <Col span={24} className='px-5'>
                        <h1 className='font-bold fs-3 mb-0'>Area Performance</h1>
                        <p>Explore a diverse range of stocks by area.</p>
                    </Col>
                    <Col span={24} className='mb-3 px-5'>
                        <Row gutter={16} className='w-100 totalcards-wrap'>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className=''>
                                            <Col span={20}>
                                                <span className='totalname'>Sales Volume</span>
                                                <p>
                                                    <span className="nototal">{sales_volume >0 ?sales_volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':0}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                            <i class="bi bi-activity"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className=''>
                                            <Col span={20}>
                                                <span className='totalname'>Leads</span>
                                                <p>
                                                    <span className="nototal">{leads_count >0 ?leads_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :0}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <RiseOutlined className='fs-5 mt-1' style={{opacity:'0.5'}}/>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className=''>
                                            <Col span={20}>
                                                <span className='totalname'>Listings</span>
                                                <p>
                                                    <span className="nototal">{listings_count >0 ?listings_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :0}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                                <i class="bi bi-house"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading}>
                                    <Meta
                                        description={<Row className=''>
                                            <Col span={20}>
                                                <span className='totalname'>Number of Deals</span>
                                                <p>
                                                    <span className="nototal">{deals_count >0 ?deals_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :0}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                              
                                                <i class="bi bi-file-earmark-check"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading} >
                                    <Meta
                                        description={<Row className=''>
                                            <Col span={20}>
                                                <span className='totalname'>Total Commission</span>
                                                <p>
                                                    <span className="nototal">{total_commission >0 ?total_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':0}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                            
                                            <i class="bi bi-credit-card"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card loading={this.state.isLoading} >
                                    <Meta
                                        description={<Row className=''>
                                            <Col span={20}>
                                                <span className='totalname'>Conversion</span>
                                                <p>
                                                    <span className="nototal">{(deals_count / leads_count *100)?this.roundTotal((deals_count / leads_count *100),1):0}</span>
                                                </p>
                                            </Col>
                                            <Col span={4}>
                                            
                                                <i class="bi bi-percent"></i>
                                            </Col>
                                            
                                        </Row>}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='monthfilter px-5 mt-3'>
                    {/* {updatedMonthsArray.map(month => (
                        <>
          <li key={month.key}>{month.label}</li> ||
          </>
        ))} */}
                        <Segmented value={this.state.selectedMonth} itemSelectedBg={'#151515'} onChange={this.handleMonths}
                        options={updatedMonthsArray}
                       />
                    </Col>
                    <hr className='mt-0'/>
                    <Col span={24} className='px-5 mt-3'>
                    {/* Filter */}
                        <Row className='filterwrap border-bottom' gutter={12}>
                            <Col span={24}>
                                <Row gutter={8}  className='w-100 justify-content-between mb-2'>
                                    <Col span={10}>
                                        <div className='searchbar w-100 form-group d-flex flex-row align-items-center'>
                                            <Input type="text" value={this.state.global_search} onChange={this.handleGlobalSearch} placeholder='Search community, sub community or property' allowClear style={{height:'36px', maxWidth:'99%', borderRadius:'0.3rem'}} prefix={<i class="bi bi-search"></i>}/>
                                        </div>
                                    </Col>
                                </Row>
                                
                            </Col>
                            <Col span={24} className='d-flex flex-column  align-items-center formcheckboxgroup'>
                                <Row gutter={24} className='w-100 filtersrow'>
                                    <Col className='d-flex flex-row align-items-center justify-content-end p-0'>
                                        {/* Note: It will hide the sub community and property */}
                                        {/* <Checkbox className='commfilter'>Show Communities only</Checkbox> */}
                                        <label className='font-bold mr-2 mb-0'>Filter by: </label>
                                    </Col>
                                    <Col>
                                        <div className='form-check mb-0 mr-0 pl-0'>
                                            <Checkbox.Group allowClear onChange={this.handlePropertyFor} value={this.state.property_for}
                                                options={[
                                                    { value: 'sale', label: 'Sale'},
                                                    { value: 'rent', label: 'Rent'},
                                                ]} />
                                                
                                        </div>
                                        
                                    </Col>
                                    <Col span={4}>
                                        <div className='form-check mb-0 mr-0 flex-row d-flex pl-0'>
                                            <label className='font-bold mr-2 mb-0'>Beds: </label>
                                            
                                            <Select allowClear className='bedswrap' bordered={false} value={this.state.bedroom?this.state.bedroom:'-'}
                                                options={[
                                                    {value:'Studio', label:'Studio'},
                                                    {value:'1', label:'1'},
                                                    {value:'2', label:'2'},
                                                    {value:'3', label:'3'},
                                                    {value:'4', label:'4'},
                                                    {value:'5', label:'5'},
                                                    {value:'6', label:'6'},
                                                    {value:'7', label:'7'},
                                                    {value:'8', label:'8'},
                                                    {value:'9', label:'9'},
                                                    {value:'10', label:'10'},
                                                ]}
                                                onChange={this.handleBedroom}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className='form-check mb-0 mr-0 flex-row d-flex pl-0'>
                                            <label className='font-bold mr-2 mb-0'>Type: </label>
                                            
                                            <Select
                                            showSearch
                                            allowClear

                                            style={{
                                            width: '100%',
                                            }}
                                            mode="multiple"
                                            value={this.state.property_type_filter}
                                            maxTagCount ='responsive'
                                            placeholder="Search"
                                            onChange={this.handlePropertyTypeFilter}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                            options={this.state.propertytypes}
                                            />
                                        </div>
                                    </Col>
                                    
                                    <Col>
                                        <div className='form-check mb-0 mr-0 flex-row d-flex pl-0'>
                                            <label className='font-bold mr-2 mb-0'>Agent: </label>
                                            
                                            <Select
                                                showSearch
                                                mode={'multiple'}
                                                value={this.state.agent_value}
                                                allowClear
                                                style={{
                                                    minWidth: '200px',width:'fit-content'
                                                }}
                                                placeholder="Search"
                                                onChange={this.handleAgent}

                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={this.state.agents}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='w-100 mt-2 justify-content-between'>
                                    <Col span={8}> <Row className='w-100' gutter={20}>
                                        <Col className='d-flex align-items-center'><label className='font-bold mr-2 mb-0'>Filter by dates: </label></Col>
                                        <Col>
                                            <Popover placement="bottomRight" content={
                                                <div className='d-flex flex-column popfilter'>
                                                    <p className='titledate'>Published Date</p>
                                                    <div className='d-flex flex-row p-2 w-100 pb-0'>
                                                        <div className="form-check mb-0 mr-3">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.publish_date_morethan_option} id="publisheddateMorethanoption" onChange={this.handlePublishDateMorethan}/>
                                                            <label className="form-check-label font-12 pl-0" for="publisheddateMorethanoption">More than</label>
                                                        </div>
                                                        <div className="form-check mb-0">
                                                            <input className="form-check-input" type="radio" checked={this.state.publish_date_lessthan_option}  name="flexRadioDefault" id="publisheddateLessthanoption" onChange={this.handlePublishDateLessthan}  />
                                                            <label className="form-check-label font-12 pl-0" for="publisheddateLessthanoption">Less than</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-check mb-0 mr-0 p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between', gap:'0.3rem'}}>
                                                        <input type="text" value={this.state.publish_date_value} className="form-control font-12"  onChange={this.handlePublishDateValue}/>
                                                        <select value={this.state.publish_date_value_id} className='form-select font-12'  onChange={this.handlePublishDateValue_id}>
                                                        <option value="1">days</option>
                                                        <option value="2">weeks</option>
                                                        <option value="3">months</option>
                                                        <option value="4">years</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            }>
                                                <Button type="text" className='filterbtn'>
                                                    {this.state.publish_date_value ? <>
                                                        <span>Published: </span>
                                                        <span>{this.state.publish_date_morethan_option?"More than "
                                                        :this.state.publish_date_lessthan_option?"Less than ":null}</span>
                                                        <span>{this.state.publish_date_value}</span>
                                                        <span>{this.state.publish_date_value_id?
                                                            this.state.publish_date_value_id==1? " days"
                                                            :this.state.publish_date_value_id==2? " weeks"
                                                            :this.state.publish_date_value_id==3? " months"
                                                            :this.state.publish_date_value_id==4? " years"
                                                            :null
                                                        :null}</span>
                                                    </>: <span>Published Date</span>}
                                                
                                                <i class="bi bi-caret-down-fill ml-3"></i></Button>
                                            </Popover>
                                        </Col>
                                        <Col>
                                            <Popover placement="bottomRight" content={
                                                    <div className='d-flex flex-column popfilter'>
                                                        <p className='titledate'>Added Date</p>
                                                        <div className='d-flex flex-row p-2 w-100 pb-0'>
                                                            <div className="form-check mb-0 mr-3">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.added_date_morethan_option} id="publisheddateMorethanoption" onChange={this.handleAddedDateMorethan}/>
                                                                <label className="form-check-label font-12 pl-0" for="publisheddateMorethanoption">More than</label>
                                                            </div>
                                                            <div className="form-check mb-0">
                                                                <input className="form-check-input" type="radio" checked={this.state.added_date_lessthan_option}  name="flexRadioDefault" id="publisheddateLessthanoption" onChange={this.handleAddedDateLessthan}  />
                                                                <label className="form-check-label font-12 pl-0" for="publisheddateLessthanoption">Less than</label>
                                                            </div>
                                                        </div>
                                                        <div className='form-check mb-0 mr-0 p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between', gap:'0.3rem'}}>
                                                            <input type="text" value={this.state.added_date_value} className="form-control font-12"  onChange={this.handleAddedDateValue}/>
                                                            <select value={this.state.added_date_value_id} className='form-select font-12'  onChange={this.handleAddedDateValue_id}>
                                                            <option value="1">days</option>
                                                            <option value="2">weeks</option>
                                                            <option value="3">months</option>
                                                            <option value="4">years</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                }>
                                                    <Button type="text" className='filterbtn'>
                                                        {this.state.added_date_value ? <>
                                                            <span>Added: </span>
                                                            <span>{this.state.added_date_morethan_option?"More than "
                                                            :this.state.added_date_lessthan_option?"Less than ":null}</span>
                                                            <span>{this.state.added_date_value}</span>
                                                            <span>{this.state.added_date_value_id?
                                                                this.state.added_date_value_id==1? " days"
                                                                :this.state.added_date_value_id==2? " weeks"
                                                                :this.state.added_date_value_id==3? " months"
                                                                :this.state.added_date_value_id==4? " years"
                                                                :null
                                                            :null}</span>
                                                        </>: <span>Added Date</span>}
                                                    
                                                    <i class="bi bi-caret-down-fill ml-3"></i></Button>
                                                </Popover>
                                        </Col>
                                        <Col>
                                            <Popover placement="bottomRight" content={
                                                <div className='d-flex flex-column popfilter'>
                                                    <p className='titledate'>Available Date</p>
                                                    <div className='d-flex flex-row p-2 w-100 pb-0'>
                                                        <div className="form-check mb-0 mr-3">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.available_date_morethan_option} id="publisheddateMorethanoption" onChange={this.handleAvailableDateMorethan}/>
                                                            <label className="form-check-label font-12 pl-0" for="publisheddateMorethanoption">More than</label>
                                                        </div>
                                                        <div className="form-check mb-0">
                                                            <input className="form-check-input" type="radio" checked={this.state.available_date_lessthan_option}  name="flexRadioDefault" id="publisheddateLessthanoption" onChange={this.handleAvailableDateLessthan}  />
                                                            <label className="form-check-label font-12 pl-0" for="publisheddateLessthanoption">Less than</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-check mb-0 mr-0 p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between', gap:'0.3rem'}}>
                                                        <input type="text" value={this.state.available_date_value} className="form-control font-12"  onChange={this.handleAvailableDateValue}/>
                                                        <select value={this.state.available_date_value_id} className='form-select font-12'  onChange={this.handleAvailableDateValue_id}>
                                                        <option value="1">days</option>
                                                        <option value="2">weeks</option>
                                                        <option value="3">months</option>
                                                        <option value="4">years</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            }>
                                                <Button type="text" className='filterbtn'>
                                                    {this.state.available_date_value ? <>
                                                        <span>Available: </span>
                                                        <span>{this.state.available_date_morethan_option?"More than "
                                                        :this.state.available_date_lessthan_option?"Less than ":null}</span>
                                                        <span>{this.state.available_date_value}</span>
                                                        <span>{this.state.available_date_value_id?
                                                            this.state.available_date_value_id==1? " days"
                                                            :this.state.available_date_value_id==2? " weeks"
                                                            :this.state.available_date_value_id==3? " months"
                                                            :this.state.available_date_value_id==4? " years"
                                                            :null
                                                        :null}</span>
                                                    </>: <span>Available Date</span>}
                                                
                                                <i class="bi bi-caret-down-fill ml-3"></i></Button>
                                            </Popover>
                                        </Col>
                                    </Row></Col>
                                    <Col className='d-flex justify-content-end'>
                                        <Row className='checkboxfilterareastock '>
                                            <Col className='justify-content-end d-flex formcheckboxgroup'>
                                                <div className='form-check mr-1'><Checkbox className="" checked={this.state.exclode} onChange={this.handleExclodeZero}>Exclude 0 Value</Checkbox></div>
                                                <CheckboxGroup
                                                value={this.state.provider} onChange={this.handleProvider} placeholder="Status"
                                                filterOption={(input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                options={[
                                                    {value: '90', label: 'LuxuryProperty.com' },
                                                    {value: '112', label: 'Bayut' },
                                                    {value: '71',label: 'Property finder'},
                                                    {value: '26',label: 'Dubizzle'},
                                            ]} />
                                           </Col>
                                           <Col className='resetfilter'>
                                                <Button onClick={this.handleClearFilter} className="resetbtn mb-0" type="text"><i class="bi bi-arrow-counterclockwise mr-1 ml-5"></i><span>Reset All Filter</span></Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>                            
                        </Row>
                    </Col>
                    <Col span={24} className='mb-2 tablerow px-5'>
                        <div className=''>
                        {this.state.isLoading ?
                            <div className='w-100 d-flex my-5 py-5'>
                                    <Spin className='spinnerOp mx-auto' size='large' indicator={<img src='images/opportunityloading.gif' />} />
                                </div>
                        :
                            <Table onChange={handleTableChange} 
                                   loading={this.state.isLoading}
                                  pagination={{
                                     pageSizeOptions: ['10', '20','50','100'],
                                  total:
                                    this.state?.listings?.total > 0 &&
                                    this.state.listings.total,
                                  pageSize: this.state.listings.per_page,
                                  current: this.state.listings.current_page,
                                  showTotal: (total, range) =>
                                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                  showSizeChanger: true,
                                  onShowSizeChange: onShowSizeChange,
                                  itemRender: itemRender,
                                }}
                                style={{ overflowX: "auto" }}
                                columns={columns}
                                // showHeader={false}
                                className='tbstockwrap'
                                dataSource={
                                  this.state?.listings?.data
                                    ? this.state?.listings?.data
                                    : []
                                }

                                // rowKey={(record) => record}
                                // Farooq
                                rowKey={(record) => record.id}
                                summary={() => (
                                    <Table.Summary fixed={'bottom'}>
                                      <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={3}>
                                          &nbsp;
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column confirmedowner text-center'>
                                                {/* <label>Total Confirmed Owner:</label> */}
                                                <p className='font-bold d-flex flex-column'>
                                                    <span>{sales_volume >0 ?sales_volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':0}</span>
                                                    {/* <span>{this.roundTotal((this.state.total_confirmedowner/this.state.alltotal)*100,0)+"%"}</span> */}
                                                </p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column comingtomarket text-center'>
                                                {/* <label>Total Coming to Market:</label> */}
                                                <p className='font-bold d-flex flex-column'>
                                                    <span>{leads_count >0 ?leads_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :0}</span>
                                                    {/* <span>{this.roundTotal((this.state.total_comingtomarket/this.state.alltotal)*100,0)+"%"}</span> */}
                                                    </p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column published text-center'>
                                                {/* <label>Total Published:</label> */}
                                                <p className='font-bold d-flex flex-column'><span>{listings_count >0 ?listings_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :0}</span>
                                                {/* <span>{this.roundTotal((this.state.total_published/this.state.alltotal)*100,2)+"%"}</span> */}
                                                </p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column feeds  text-center'>
                                                {/* <label>Total Feeds:</label>  */}
                                                <p className='font-bold d-flex flex-column'><span>{deals_count >0 ?deals_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :0}</span>
                                                {/* <span>{this.roundTotal((this.state.total_feeds/this.state.alltotal)*100,0)+"%"}</span> */}
                                                </p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>
                                            <div className='info-group d-flex flex-column average text-center'>
                                                {/* <label>Total Average Price:</label> */}
                                                <p className='font-bold'>{total_commission >0 ?total_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':0}</p>
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1}>

                                        </Table.Summary.Cell>
                                      </Table.Summary.Row>
                                    </Table.Summary>
                                  )}
                                />
                            }
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}
const mapDispatchToProps = dispatch =>{
    return {
      setStatus: (data) => dispatch({type: "SET_STATUS",payload:data}),
      setBedroom: (data) => dispatch({type: "SET_BED",payload:data}),
      setPropertyFor: (data) => dispatch({type: "SET_PROPERTY_FOR",payload:data}),
      setAgent: (data) => dispatch({type: "SET_AGENT",payload:data}),
      setLeads: (data) => dispatch({type: "SET_LEADS",payload:data}),
      setDeals: (data) => dispatch({type: "SET_DEALS",payload:data})
    }
  }
export default connect(null,mapDispatchToProps)(AreaStock)