import React, { Component } from 'react'
import { Modal, Button, Input, Select, Row, Col, Table, Radio, TimePicker, DatePicker, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { CameraOutlined, LinkOutlined, CloseOutlined } from '@ant-design/icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './request.css'
import API from '../../utils/API';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import Moment from 'react-moment';
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';


const Option = Select.Option;
const { TextArea } = Input;
const RadioGroup = Radio.Group;

class Photography extends Component {

    constructor(props) {
        super(props);
        this.modalContentRef = React.createRef();
        this.state = {
            showAddRequest: false,
            isLoading: false,
            isVideoLoading: false,
            isDriveLink: true,
            isEmail:true,
            isNote : true,
            isNOC : true,
            isPinLocation:true,
            isCompleteDate: true,
            isRescheduleActivate: false,
            isRescheduleActivateStatus: true,
            allphotography: [],
            allvideography: [],
            status: "",
            record_id: "",
            photography_check: false,
            videography_walk_check: false,
            videography_agent_check: false,
            haveNoc: false,
            property_contact: {},
            property_contact_name: "",
            property_contact_email: "",
            property_contact_mobile: "",
            photography_note: "",
            pin_location : "",
            present_photo: "",
            photography_date: "",
            photography_time: [],
            photography_start_time: "",
            photography_end_time: "",
            photographer: "",
            drive_link: "",
            completed_date: "",
            photography_status: [{
                key: 1,
                labe: "Pending"
            },
            {
                key: 2,
                labe: "Scheduled"
            },

            {
                key: 5,
                labe: "Rescheduled"
            },
            {
                key: 3,
                labe: "Done"
            },
            {
                key: 4,
                labe: "Dismissed"
            }
            ],

        }
    }



        scrollToBottom = () => {
            if (this.modalContentRef.current) {
            this.modalContentRef.current.scrollTo({
                top: this.modalContentRef.current.scrollHeight,
                behavior: 'smooth',
            });
            }
        };

    handleshowEditRequest = (record) => {
        const timeArray = []
        timeArray.push(record.photography_start_time)
        timeArray.push(record.photography_end_time)
        this.setState({
            showAddRequest: true,
            record_id: record.id,
            photography_check: record.photography_check,
            videography_walk_check: record.videography_walk_check,
            videography_agent_check: record.videography_agent_check,
            haveNoc: record.haveNoc,
            property_contact: {},
            property_contact_name: record.property_contact_name,
            property_contact_email: record.property_contact_email,
            property_contact_mobile: record.property_contact_mobile,
            photography_note: record.note,
            present_photo: record.present_photo != null ? record.present_photo : "",
            photography_date: record.photography_date,
            photography_start_time: record.photography_start_time,
            photography_end_time: record.photography_end_time,
            photography_time: timeArray,
            photographer: record.photographer,
            completed_date: record.completed_date,
            drive_link: record.drive_link,
        });
    }
    handleshowEditRequestok = () => {
        this.setState({ showAddRequest: false });
    }
    handleshowEditRequestCancel = () => {
        this.setState({ showAddRequest: false });

    }
    componentDidMount() {
        const page = 1
        const perPage = 10
        const data = { status: this.state.status }
        this.fetchData(page, perPage, data)



        // this.fetchVideo(page,perPage,data)
    }

    fetchData = (page, perPage, data) => {
        this.setState({ isLoading: true })
        API.post(`/allphotography?page=${page}&perPage=${perPage}`, data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        allphotography: response.data.photography,
                        isLoading: false
                    })
                }
            })

    }

    fetchVideo = (page, perPage, data) => {
        this.setState({ isVideoLoading: true })
        API.post(`/allvideography?page=${page}&perPage=${perPage}`, data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        allvideography: response.data.videography,
                        isVideoLoading: false
                    })
                }
            })

    }
    handleStatusTabs = (e, event) => {
        this.setState({ status: e })
        const data = { status: e }
        const page = 1
        const perPage = 10
        this.fetchData(page, perPage, data)
        this.fetchVideo(page, perPage, data)

    }
    handleClearStatus = () => {
        this.setState({ status: "" })
        const data = { status: "" }
        const page = 1
        const perPage = 10
        this.fetchData(page, perPage, data)
        this.fetchVideo(page, perPage, data)
    }

    handlePhotography = () => {
        this.setState({ photography_check: !this.state.photography_check })
    }

    handleVideoWalk = () => {
        this.setState({ videography_walk_check: !this.state.videography_walk_check })
    }

    handleVidewAgent = () => {
        this.setState({ videography_agent_check: !this.state.videography_agent_check })
    }
    handleNOC = (e) => {
        // this.setState({ haveNoc: !this.state.haveNoc });
        this.setState({ haveNoc: e.target.checked });
        this.setState({ isNOC: true });

    }

    handlePresentPhoto = (e) => {
        this.setState({ present_photo: e.target.value });
    }

    handlePhotographyNote = (e) => {
        this.setState({ photography_note: e.target.value })
        this.setState({ isNote: true });
    }

    handlePinLocation = (e) => {
        this.setState({ pin_location: e.target.value });
        this.setState({ isPinLocation: true });
    }


    handlePropertyEmail = (e) => {
         this.setState({ property_contact_email: e.target.value });
         this.setState({ isEmail: true });
    }


    handlePhotographyDate = (value, dateString) => {

        this.setState({ photography_date: value != null ? dateString : "", isRescheduleActivate: true });
    }

    handlePhotographyTime = (value, timeString) => {
        this.setState({ photography_time: value != null ? timeString : [], isRescheduleActivate: true })
        //    this.setState({photography_start_time:value !=null?timeString[0]:"",photography_end_time: value !=null?timeString[1]:""})
         this.setState({ isRescheduleActivateStatus: true });

    }

    handlePhotographer = (e) => {
        this.setState({ photographer: e.target.value })
    }
    handleDriveLink = (e) => {
        this.setState({ drive_link: e.target.value });
        this.setState({ isDriveLink: true });

    }
    handleCompletedDate = (value, dateString) => {
        this.setState({ completed_date: value != null ? dateString : "" })
        this.setState({ isCompleteDate : true});
    }

    handleScheduled = () => {
        const data = { id: this.state.record_id, status: "Scheduled" }
        API.post("/change_photography_status", data)
            .then((response) => {
                if (response.data.success) {
                    toast.success("Successfully Scheduled")
                    this.setState({ showAddRequest: false })
                    const page = 1
                    const perPage = 10
                    const fetch_data = { status: this.state.status }
                    this.fetchData(page, perPage, fetch_data)
                    this.fetchVideo(page, perPage, fetch_data)
                }
            })
    }

    handleRescheduled = () => {
        if (!this.state.isRescheduleActivate) {
            this.setState({ isRescheduleActivateStatus: false })
            return
        } else {
            this.setState({ isRescheduleActivateStatus: true })
        }
        const data = {
            id: this.state.record_id, status: "Rescheduled", photography_date: this.state.photography_date,
            photography_time: this.state.photography_time
        }
        API.post("/change_photography_status", data)
            .then((response) => {

                if (response.data.success) {
                    toast.success("Successfully Rescheduled")
                    this.setState({ showAddRequest: false })
                    const page = 1
                    const perPage = 10
                    const fetch_data = { status: this.state.status }
                    this.fetchData(page, perPage, fetch_data)
                    this.fetchVideo(page, perPage, fetch_data)
                }
            })
    }


    handleDone = () => {
         // Helper function to validate email format
            const isValidEmail = (email) => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            };


         if (!this.state.isRescheduleActivate) {
            this.setState({ isRescheduleActivateStatus: false })
            return
        } else {
            this.setState({ isRescheduleActivateStatus: true })
        }


        if (this.state.haveNoc != true) {
         this.setState({ isNOC: false });
            return;
         } else {
            this.setState({ isNOC: true });
         }

        if (this.state.property_contact_email == null || this.state.property_contact_email.trim() === "") {
           this.setState({ isEmail: false });
           return;
            } else if (!isValidEmail(this.state.property_contact_email)) {
                this.setState({ isEmail: false });
                return;
            } else {
                this.setState({ isEmail: true });
        }

        if (this.state.pin_location == null || this.state.pin_location == "") {
            this.setState({ isPinLocation: false });
            return
        } else {
            this.setState({ isPinLocation: true });
        }

         if (this.state.photography_note == null || this.state.photography_note == "") {
            this.setState({ isNote: false });
            return
        } else {
            this.setState({ isNote: true });
        }
        if (this.state.drive_link == null || this.state.drive_link == "") {
            this.setState({ isDriveLink: false })
            return
        } else {
            this.setState({ isDriveLink: true })
        }

        if (this.state.completed_date == null || this.state.completed_date == "") {
            this.setState({ isCompleteDate: false })
            return
        } else {
            this.setState({ isCompleteDate: true })
        }

        const data = { 
            id: this.state.record_id,
            status: "Done",
            photography_check: this.state.photography_check == true ? 1 : 0,
            videography_walk_check: this.state.videography_walk_check == true ? 1 : 0,
            videography_agent_check: this.state.videography_agent_check == true ? 1 : 0,
            haveNoc: this.state.haveNoc == true ? 1 : 0,
            property_contact_name: this.state.property_contact_name,
            property_contact_email: this.state.property_contact_email,
            property_contact_mobile: this.state.property_contact_mobile,
            photography_note: this.state.photography_note,
            present_photo: this.state.present_photo,
            photography_date: this.state.photography_date,
            photography_time: this.state.photography_time,
            photographer: this.state.photographer,
            drive_link: this.state.drive_link,
            completed_date: this.state.completed_date
        }
        // console.log(data);
        API.post("/change_photography_status", data)
            .then((response) => {
                if (response.data.success) {
                    toast.success("Successfully Completed")
                    this.setState({ showAddRequest: false })
                    const page = 1
                    const perPage = 10
                    const fetch_data = { status: this.state.status }
                    this.fetchData(page, perPage, fetch_data)
                    this.fetchVideo(page, perPage, fetch_data)
                }
            })
    }

    handleDismissed = () => {
        alert("diss")
    }

    handleUpdate = () => {
        const id = this.state.record_id
        const data = {
            photography_check: this.state.photography_check == true ? 1 : 0,
            videography_walk_check: this.state.videography_walk_check == true ? 1 : 0,
            videography_agent_check: this.state.videography_agent_check == true ? 1 : 0,
            haveNoc: this.state.haveNoc == true ? 1 : 0,
            property_contact_name: this.state.property_contact_name,
            property_contact_email: this.state.property_contact_email,
            property_contact_mobile: this.state.property_contact_mobile,
            photography_note: this.state.photography_note,
            present_photo: this.state.present_photo,
            photography_date: this.state.photography_date,
            photography_time: this.state.photography_time,
            photographer: this.state.photographer,
            drive_link: this.state.drive_link,
            completed_date: this.state.completed_date
        }

        API.put("/photography/" + id, data)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        showAddRequest: false, photography_check: false,
                        videography_walk_check: false,
                        videography_agent_check: false,
                        haveNoc: false,
                        property_contact: {},
                        property_contact_name: "",
                        property_contact_email: "",
                        property_contact_mobile: "",
                        photography_note: "",
                        present_photo: "",
                        photography_date: "",
                        photography_time: [], photographer: ""
                    })
                    toast.success("Successfully updated")
                }
            })
        const page = 1
        const perPage = 10
        const fetch_data = { status: this.state.status }
        this.fetchData(page, perPage, fetch_data)
        this.fetchVideo(page, perPage, fetch_data)
        //  return

        //   this.setState({showAddRequest:false})
    }


    render() {
        const columns = [
            {
                title: 'Property', dataIndex: 'refno', fixed: 'left', width: 200, className: "propertywrap",
                render: (text, record) => (

                    <><div className='info-group'>
                        <a className='font-bold mb-0 fs-6' onClick={this.handleshowEditRequest.bind(this, record)}>{record.refno}</a>
                        <p className='font-12'>{record.community},{record.subcommunity},{record.property_project}</p>
                        {/* unit no, property, subcommunity, community */}
                    </div>
                    </>
                )
            },
            {
                title: 'Status', dataIndex: 'status', width: 120,
                render: (text, record) => {
                    var pStatus = ''
                    var color = ''

                    switch (record.status) {
                        case 'Pending':
                            pStatus = 'Pending'
                            color = 'pending'
                            break;
                        case 'Scheduled':
                            pStatus = 'Scheduled'
                            color = 'action'
                            break;
                        case 'Rescheduled':
                            pStatus = 'Rescheduled'
                            color = 'changes'
                            break;
                        case 'Dismissed':
                            pStatus = 'Dismissed'
                            color = 'dismissed'
                            break;
                        case 'Done':
                            pStatus = 'Done'
                            color = 'signed'
                            break;
                        case 'Dismissed':
                            pStatus = 'Dismissed'
                            color = 'dismissed'
                            break;
                        default:
                            pStatus = 'Pending'
                            color = 'pending'
                            break;
                    }
                    return (
                        <>
                            <span className={color + " c-icon"} key={pStatus}>{pStatus}</span>
                            <span className='datestat'></span>

                        </>
                    );
                },
            },
            {
                title: 'Photography type', dataIndex: 'photographytype', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Type:</p>
                            <p>
                                {

                                }

                                {/* {
                                    record.photography_check == 0 && record.videography_walk_check == 1 ? "videography" : "photography"
                                } */}

                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Date of Video', dataIndex: 'dateofvideo', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Date of Shoot:</p>
                            <p>
                                {
                                    record.photography_date == null || record.photography_date == '' || record.photography_date == "0000-00-00 00:00:00" ?
                                        ''
                                        :
                                        <>
                                            <Moment format="MMMM DD, YYYY">{record.photography_date}</Moment>

                                        </>
                                }
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Created/Agent', dataIndex: 'createdagent', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Created at:</p>
                            <p><Moment format="MMMM DD, YYYY">{record.created_at}</Moment></p>
                        </div>

                    </>
                )
            },
            {
                title: 'Photographer', dataIndex: 'photographer', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Agent:</p>
                            <p>{record.display_name}</p>
                        </div>
                    </>
                )
            },
            {
                title: 'Photographer', dataIndex: 'photographer', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Photographer:</p>
                            <p>{record.photographer}</p>
                        </div>
                    </>
                )
            },

            {
                title: 'Drive Link', dataIndex: 'drivelink', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Drive Link:</p>
                            {/* <p>No photos yet</p> */}
                            {record.drive_link != null && record.drive_link != "" ?
                                <a href={record.drive_link} target="_blank" className='d-flex flex-row gap-06 align-items-center font-14'><LinkOutlined />View photos</a>
                                : ""}
                        </div>
                    </>
                )
            },
            {
                title: 'Drive Link', dataIndex: 'drivelink', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Date Completed:</p>
                            <p>May 15, 2023</p>
                        </div>
                    </>
                )
            }
        ]

        const allListingColumns = [
            {
                title: 'cardColumn', className: 'namecolwrap',
                // style: { zIndex: '9' },
                sorter: (a, b) => a.refno.length - b.refno.length,
                render: (text, record) => {
                    var pStatus = ''
                    var color = ''

                    switch (record.status) {
                        case 'Pending':
                            pStatus = 'Pending'
                            color = 'pending'
                            break;
                        case 'Scheduled':
                            pStatus = 'Scheduled'
                            color = 'action'
                            break;
                        case 'Rescheduled':
                            pStatus = 'Rescheduled'
                            color = 'changes'
                            break;
                        case 'Dismissed':
                            pStatus = 'Dismissed'
                            color = 'dismissed'
                            break;
                        case 'Done':
                            pStatus = 'Done'
                            color = 'signed'
                            break;
                        case 'Dismissed':
                            pStatus = 'Dismissed'
                            color = 'dismissed'
                            break;
                        default:
                            pStatus = 'Pending'
                            color = 'pending'
                            break;
                    }



                    return (<>

                        <div className="TableCard">
                            <Link className="btn-text listinginfo" onClick={this.handleshowEditRequest.bind(this, record)}>
                            <div className='row alignCenter headerGrayBgColor borderBottom'>
                                <div className='col-6'>
                                    <div className='HeaderMainLeft'>
                                        <p className='font-bold fs-6  mb-0'>{record.refno}</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>

                                        <p>
                                            <span className={color + " c-icon"} key={pStatus}>{pStatus}</span>
                                            <span className='datestat'></span>
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>


                            <div className='row alignCenter borderGray pt-2 pb-2'>
                                <div className='col-12 col-xs-6 col-sm-6 '>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p>{record.community},{record.subcommunity},{record.property_project}</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-12 col-xs-6 col-sm-6'>
                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Type:</p>
                                            <p>{record.photography_check == 1 && record.videography_walk_check == 0 ? "photography" : "videography"}</p>
                                        </div>
                                </div>


                                <div className='col-12 col-xs-6 col-sm-6'>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Created At:</p>
                                            <p><Moment format="MMMM DD, YYYY">{record.created_at}</Moment></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-xs-6 col-sm-6'>

                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                        <p className='info-title'>Date of Shoot:</p>
                                        <p> {
                                    record.photography_date == null || record.photography_date == '' || record.photography_date == "0000-00-00 00:00:00" ?
                                        ''
                                        :
                                        <>
                                            <Moment format="MMMM DD, YYYY">{record.photography_date}</Moment>

                                        </>
                                }
                                        </p>
                                    </div>
                                </div>


                                <div className='col-12 col-xs-6 col-sm-6'>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Agent:</p>
                                            <p>{record.display_name}
                                            </p>
                                        </div>



                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Photographer:</p>
                                            <p>{record.photographer}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-12 col-xs-6 col-sm-6'>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Drive Link:</p>
                                            <p>{record.drive_link != null && record.drive_link != "" ?
                                <a href={record.drive_link} target="_blank" className='d-flex flex-row gap-06 align-items-center font-14'><LinkOutlined />View photos</a>
                                : ""}
                                            </p>
                                        </div>



                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Date Completed:</p>
                                            <p>{record.completed_date? record.completed_date : '---'}</p>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            </Link>
                        </div>

                    </>)
                }

            }
        ];

        const handleTableChange = (pagination, filters) => {


            const data = { status: this.state.status }

            this.fetchData(pagination.current, pagination.pageSize, data);


        };

        const handleVideoTableChange = (pagination, filters) => {


            const data = { status: this.state.status }

            this.fetchVideo(pagination.current, pagination.pageSize, data);


        };

        const data = [
            { refno: '', status: 'Pending', createdagent: '', photographer: '', dateofvideo: '', drivelink: '' },
            { refno: '', status: 'Scheduled', createdagent: '', photographer: '', dateofvideo: '', drivelink: '' },
            { refno: '', status: 'Rescheduled', createdagent: '', photographer: '', dateofvideo: '', drivelink: '' },
            { refno: '', status: 'Done', createdagent: '', photographer: '', dateofvideo: '', drivelink: '' },
            { refno: '', status: 'Dismissed', createdagent: '', photographer: '', dateofvideo: '', drivelink: '' },
        ]
        const plainOptions = ['Photography', 'Videography - Walkthrough', 'Videography - Agent Tour'];
        return (
            <>
                <ToastContainer />
                <Modal width={1000} title="Edit Photo Request" open={this.state.showAddRequest} onOk={this.handleshowEditRequestok} onCancel={this.handleshowEditRequestCancel} className='photorequestmodal' centered
                    footer={[
                        <div className='d-flex flex-row flex-wrap'>
                        <Button type='primary' onClick={this.handleUpdate}>Update</Button>,
                        <Button type='primary' onClick={this.handleScheduled}>Scheduled</Button>,
                        <Button type='primary' onClick={this.handleRescheduled}>Rescheduled</Button>,
                        <Button type='primary' onClick={this.handleDone}>Mark as Done</Button>
                        </div>
                    ]}
                >
                    <Row span={24} className='w-100' gutter={24}>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className='pl-0'>
                            <div className='form-group'>
                                <p className='font-bold mb-0'>Type of shoot <em>*</em></p>
                                <span className='font-12'>Please only select the applicable option(s) to allocate appropriate time for the shoot</span>
                                <div className='d-flex flex-row flex-wrap'>
                                    <Checkbox checked={this.state.photography_check} onChange={this.handlePhotography} > Photography </Checkbox>
                                    <Checkbox checked={this.state.videography_walk_check} onChange={this.handleVideoWalk} >
                                        Videography - Walkthrough
                                    </Checkbox>
                                    <Checkbox checked={this.state.videography_agent_check} onChange={this.handleVidewAgent}  >
                                        Videography - Agent Tour
                                    </Checkbox>
                                    {/* <Checkbox.Group options={plainOptions} className="d-flex flex-column" /> */}
                                    {/* <RadioGroup>
                                        <Radio value={1}>Photography</Radio>
                                        <Radio value={2}>Videography - Walkthrough</Radio>
                                        <Radio value={3}>Videography - Agent Tour</Radio>
                                    </RadioGroup> */}
                                </div>
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className='pr-0'>
                            <div className='form-group'>
                                <p className='font-bold'>Preferred date and time for shoot <em>*</em></p>
                                <span className='font-12'>Not guaranteed, based on availability on first come first serve basis</span>
                                <div className='d-flex flex-column' style={{ gap: '0.6rem' }}>
                                    <DatePicker value={(this.state.photography_date) ? dayjs(this.state.photography_date) : ""} format="YYYY-MM-DD" style={{ width: '100%' }} onChange={this.handlePhotographyDate} />
                                    <TimePicker.RangePicker use12Hours format="h:mm a" showTime={{
                                        format: 'h:mm a',
                                        defaultValue: [dayjs(this.state.photography_time[0], 'h:mm a'), dayjs(this.state.photography_time[1],
                                            'h:mm a')],
                                    }} minuteStep={15} style={{ width: '100%' }} onChange={this.handlePhotographyTime} />
                                    {!this.state.isRescheduleActivateStatus ? <p className='text-danger'>please reschedule</p> : ""}
                                </div>
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className='pl-0'>
                            <div className='form-group'>
                                <p className='info-title font-bold'>Document Checklist <em>*</em></p>
                                <div className='info-check'>
                                    <CloseOutlined />
                                    <p className='mb-0'>Title Deed</p>
                                </div>
                                <div className='info-check'>
                                    <CloseOutlined />
                                    <p className='mb-0'>Landlord Passport/ID</p>
                                </div>
                                <div className='info-check'>
                                    <CloseOutlined />
                                    <p className='mb-0'>Leasing Form</p>
                                </div>
                                <div className='form-group mt-3'>
                                    <Checkbox className='info-title' value={this.state.haveNoc} onChange={this.handleNOC}>Do you have NOC?</Checkbox >
                                     {!this.state.isNOC ? <p className='text-danger'>This Required</p> : ""}
                                </div>
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pr-0'}`}>
                            <div className='form-group'>
                                <p className='info-title font-bold mb-0' >Are you gonna be present during the photography?</p>
                                <Radio.Group value={this.state.present_photo} className='d-flex flex-row' onChange={this.handlePresentPhoto} >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2} defaultChecked>No</Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pl-0'}`}>
                            <div className='form-group'>
                                <p className='info-title font-bold  mb-0'>Property contact name <em>*</em></p>
                                <Input value={this.state.property_contact_name} />
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pr-0'}`}>
                            <div className='form-group'>
                                <p className='info-titl font-bolde mb-0'>Property contact email <em>*</em></p>
                                <Input value={this.state.property_contact_email} type="email" onChange={this.handlePropertyEmail}/>
                                {!this.state.isEmail ? <p className='text-danger'>Please enter a valid emai </p> : ""}
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pl-0'}`}>
                            <div className='form-group'>
                                <p className='info-title font-bold'>Property contact number <em>*</em></p>
                                <Input value={this.state.property_contact_mobile} />
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pr-0'}`}>
                            <div className='form-group'>
                                <p className='info-title font-bold'>Pin Location</p>
                                <Input value={this.state.pin_location} onChange={this.handlePinLocation}/>
                                {!this.state.isPinLocation ? <p className='text-danger'>This Required</p> : ""}
                            </div>
                        </Col>

                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pl-0'}`}>
                            <div className='form-group'>
                                <p className='info-title font-bold'>Notes:</p>
                                <span>How to access the property, any additional requirements or relevant information.</span>
                                <TextArea value={this.state.photography_note} rows={4} onChange={this.handlePhotographyNote} />

                                {!this.state.isNote ? <p className='text-danger'>This Required</p> : ""}

                            </div>
                        </Col>
                    </Row>
                    <Row className=' w-100' gutter={24}>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pl-0'}`}>
                            <div className='form-group'>
                                <p className='info-title font-bold'>Photographers/Videographers:</p>
                                <Input value={this.state.photographer} onChange={this.handlePhotographer} />
                                {/* <Select style={{ width: 120 }} onChange={this.handlePhotographer}>
                                    <Option value="johnmamatlalo">John Paul Mamatlalo</Option>
                                    <Option value="nabeelyousaf">Nabeel Yousaf</Option>
                                    <Option value="yushafizalyunos">Yushafizal Yunos </Option>
                                </Select> */}
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pr-0'}`}>
                            <div className='form-group'>
                                {/* Required for Mark as Done button */}
                                <p className='info-title font-bold'>Drive Link:</p>
                                <Input value={this.state.drive_link} onChange={this.handleDriveLink} />

                                {!this.state.isDriveLink ? <p className='text-danger'>This required</p> : ""}
                            </div>
                        </Col>
                        <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isTabView? '': 'pl-0'}`}>
                            <div className='form-group'>
                                {/* Required for Mark as Done button */}
                                <p className='info-title font-bold'>Date Completed:</p>
                                <DatePicker value={(this.state.completed_date) ? dayjs(this.state.completed_date) : ""} format="YYYY-MM-DD" onChange={this.handleCompletedDate} />
                                {!this.state.isCompleteDate ? <p className='text-danger'>This required</p> : ""}
                            </div>
                        </Col>
                    </Row>
                </Modal>

                <Row gutter={16} className="contentwrap p-4">
                    <Col span={12} lg={12} md={12} sm={24} xs={24}>
                        <h1 className='font-bold text-uppercase my-2 fs-3'>Photography & Videography Requests</h1>
                    </Col>
                    <Col span={12} lg={12} md={12} sm={24} xs={24}>
                        {/* <Button type='primary' className="ml-auto " icon={<CameraOutlined />}>Request Photography</Button> */}
                    </Col>
                </Row>
                <Row className="contentwrap p-4 pt-0 photorequest">
                    <Col span={24}>
                        <div className='tabs-team responsiveTabs'>
                            <Tabs >
                                <TabList>
                                    <Tab>Photography</Tab>
                                    {/* <Tab>Videography</Tab> */}
                                </TabList>

                                <div className='tcontractwrap'>
                                    <Tabs >
                                        <TabList>
                                            {this.state.photography_status.length > 0 ? <Tab onClick={this.handleClearStatus}>All</Tab> : null}
                                            {this.state.photography_status.map((p_st) => (
                                                <>
                                                    <Tab onClick={this.handleStatusTabs.bind(this, p_st.labe)}><span>{p_st.labe}</span></Tab>
                                                </>
                                            ))}

                                        </TabList>
                                    </Tabs>

                                    <TabPanel>
                                        <div className='border '>
                                            <Table onChange={handleTableChange}
                                                loading={this.state.isLoading}

                                                //className="table-striped table-bordered"
                                                pagination={{
                                                    pageSizeOptions: ['10', '20', '50', '100'],
                                                    total:
                                                        this.state?.allphotography?.total > 0 &&
                                                        this.state.allphotography.total,
                                                    pageSize: this.state.allphotography.per_page,
                                                    current: this.state.allphotography.current_page,
                                                    showTotal: (total, range) =>
                                                         window.isMobileView? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                    showSizeChanger: true,
                                                    onShowSizeChange: onShowSizeChange,
                                                    itemRender: itemRender,
                                                }}
                                                style={{ overflowX:  window.isTabView ? "":"auto" }}
                                                columns={window.isTabView ? allListingColumns : columns}

                                                dataSource={
                                                    this.state?.allphotography?.data
                                                        ? this.state?.allphotography?.data
                                                        : []
                                                }
                                            /></div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className='border'>
                                            <Table onChange={handleVideoTableChange}
                                                loading={this.state.isVideoLoading}

                                                //className="table-striped table-bordered"
                                                pagination={{
                                                    pageSizeOptions: ['10', '20', '50', '100'],
                                                    total:
                                                        this.state?.allvideography?.total > 0 &&
                                                        this.state.allvideography.total,
                                                    pageSize: this.state.allvideography.per_page,
                                                    current: this.state.allvideography.current_page,
                                                    showTotal: (total, range) =>
                                                       window.isMobileView? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                    showSizeChanger: true,
                                                    onShowSizeChange: onShowSizeChange,
                                                    itemRender: itemRender,
                                                }}
                                                style={{ overflowX: window.isTabView ? "":"auto" }}
                                                columns={ window.isTabView ? allListingColumns :columns}

                                                dataSource={
                                                    this.state?.allvideography?.data
                                                        ? this.state?.allvideography?.data
                                                        : []
                                                }
                                            /></div>
                                    </TabPanel></div>
                            </Tabs>
                        </div>
                    </Col>
                </Row>

            </>
        )
    }
} export default Photography;
