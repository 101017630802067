import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'antd/dist/reset.css';
import {
    Table,
    Button,
    Modal,
    Select,
    Input,
    Popover
} from 'antd'

import { Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { store } from '../../store';
import "react-toastify/dist/ReactToastify.css";

class HoldingBay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            community: "",
            subcommunity: "",
            property: "",
            bedrooms: "",
            unitno: "",
            owner: "",
            owner_mobile: "",
            property_for: "",
            holding_listing: [],
            isLoading: false,
            added_by: "",
            added_date: "",
            status: "",
            stage: "",
            property_category: "",
            avail_date: "",
            notes: "",
            checkErr: false,
            loader: false,
            isDuplicateListingOpen: false,
            currentDuplicateListing: [],
        }

    }

    componentDidMount() {
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage);

    }

    getCurrentTime = (date) => {
        if (date != null && date != "" && date != undefined) {
            let newDate = new Date(date);
            newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
            return newDate.toISOString();
        }
    }

    onApprove = (holding_list_id) => {
        console.log("approve", holding_list_id)
        API.get("/publish_holding_list/" + holding_list_id)
            .then((response) => {
                if (response.data.success) {
                    toast.success("Listing Successfully Publish")
                    const page = 1;
                    const perPage = 10;
                    this.fetchData(page, perPage);
                }
            })
    }

    onReject = (holding_list_id) => {
        console.log("reject", holding_list_id)
        Swal.fire({
            title: "Are you sure to reject this listing?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.delete("/delete_holding_list/" + holding_list_id)
                    .then((response) => {
                        if (response.data.success) {
                            toast.success("Listing Successfully Deleted")
                            const page = 1;
                            const perPage = 10;
                            this.fetchData(page, perPage);
                        }
                    })
            }
        })
    }

    displayDuplicateListing = (listing) => {
        this.setState({ currentDuplicateListing: listing })
        this.setState({ isDuplicateListingOpen: true })
    }

    handleListings = (e, ev) => {
        window.open("/all_listings/" + e, "_blank");
    }

    handleContent = (r_l, e) => {
        return <div>
            <p>Unit no: {r_l.unitno}</p>
            <p>Ref no: {r_l.refno}</p>
            <p>Community: {r_l.community}</p>
            <p>Subcommunity: {r_l.subcommunity}</p>
            <p>Property: {r_l.property}</p>
        </div>

    }
    handleDuplicateListingModalCancel = () => {
        this.setState({ isDuplicateListingOpen: false })
        this.setState({ currentDuplicateListing: [] })
    }


    fetchData = (page, perPage, xChk) => {
        this.setState({ isLoading: true })
        API.get(`/listing_holding_bay?page=${page}&perPage=${perPage}`)
            .then(async (response) => {


                await response.data.holding_listing;
                this.setState({
                    holding_listing: response.data.holding_listing,
                    isLoading: false
                })

            })


    }

    render() {

        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let role_name = userdata.auth.user.role_name;
        const user_id = userdata.auth.user.id

        const onSelectChange = (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        };

        const rowSelection = {
            // selectedRowKeys,
            onChange: onSelectChange,

        };

        const columns = [

            {
                title: 'Unit no',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.unitno}
                    </>
                ),
            },
            {
                title: 'Duplicate',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        <button className="btn btn-text p-0"
                            onClick={this.displayDuplicateListing.bind(this, record.duplicate_listing)}>
                            <i className="bi bi-eye-fill" />
                        </button>
                    </>
                    // record.duplicate_listing.map((r_l)=>(
                    //     <div>
                    //         <Link onClick={this.handleListings.bind(this,r_l.listing_id)}  style={{lineHeight:2}}>  <Popover content={this.handleContent.bind(this,r_l)} title="Details" trigger="hover">
                    //             {r_l.refno}
                    //         </Popover> </Link>
                    //     </div>
                    // ))
                ),
            },
            {
                title: 'Property For',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.property_for}
                    </>
                ),
            },
            {
                title: 'Community',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.community}

                    </>
                ),
            },

            {
                title: 'Sub Community',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.subcommunity}
                    </>
                ),
            },

            {
                title: 'Property',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.property}
                    </>
                ),
            },

            {
                title: 'Property Type',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.property_category}
                    </>
                ),
            },
            {
                title: 'Bedrooms',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.bedrooms}
                    </>
                ),
            },
            {
                title: 'Added By',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.added_by}

                    </>
                ),
            },
            {
                title: 'Owner',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.owner}
                    </>
                ),
            },
            {
                title: 'Owner Phone',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.owner_mobile}
                    </>
                ),
            },
            {
                title: 'Price',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => {
                    if (!isNaN(parseInt(record.price))) {
                        return record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                    } else {
                        return null;
                    }
                },
            },
            {
                title: 'Stage',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => {
                    var pStatus = ''
                    var color = ''

                    switch (record.stage) {
                        case 'D':
                            pStatus = 'Draft'
                            color = 'draft'
                            break;
                        case 'UC':
                            pStatus = 'Confirmed Owner'
                            color = 'confirmedowner'
                            break;
                        case 'N':
                            pStatus = 'Unpublished'
                            color = 'unpublished'
                            break;
                        case 'OM':
                            pStatus = 'Off-Market'
                            color = 'offmarket'
                            break;
                        case 'CTM':
                            pStatus = 'Coming to market'
                            color = 'comingtomarket'
                            break;
                        case 'Y':
                            pStatus = 'Published'
                            color = 'published'
                            break;
                        case 'U':
                            pStatus = 'Action'
                            color = 'action'
                            break;

                        case 'SEO':
                            pStatus = 'SEO'
                            color = 'seo'
                            break;
                        case 'L':
                            pStatus = 'Unlisted'
                            color = 'unlisted'
                            break;
                        case 'S':
                            pStatus = 'Sold'
                            color = 'sold'
                            break;
                        case 'T':
                            pStatus = 'Let'
                            color = 'let'
                            break;
                        case 'P':
                            pStatus = 'Review'
                            color = 'review'
                            break;
                        case 'F':
                            pStatus = 'Feeds'
                            color = 'feeds'
                            break;
                        default:
                            pStatus = ''
                            color = ''
                            break;
                    }
                    return (
                        <div className={color + " pstat"} key={pStatus}>
                            {pStatus}
                        </div>
                    );
                },
            },
            {
                title: 'Added Date',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.added_date != null ?
                            <Moment fromNow>
                                {(record.added_date) && this.getCurrentTime(record.added_date)}
                            </Moment> : null}
                    </>
                ),
            },
            {
                title: 'Available Date',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.avail_date != null ?
                            <Moment fromNow>
                                {record.avail_date}
                            </Moment> : null}
                    </>
                ),
            },
            {
                title: 'Notes',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <>
                        {record.notes}
                    </>
                ),
            },
            {
                title: 'Actions',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                render: (text, record) => (
                    <div className="ms-auto">
                        <Button type='primary' size="small"> <i className="bi bi-check-circle-fill"
                            onClick={this.onApprove.bind(this, record.id)} /></Button>
                        <Button type='primary' size="small"> <i className="bi bi-x-circle-fill"
                            onClick={this.onReject.bind(this, record.id)} /></Button>
                    </div>
                ),
            },

        ];



        const allListingColumns = [
            {
                title: 'cardColumn', className: 'namecolwrap',
                // style: { zIndex: '9' },
                sorter: (a, b) => a.refno.length - b.refno.length,
                render: (text, record) => {
                    var pStatus = ''
                    var color = ''

                    switch (record.stage) {
                        case 'D':
                            pStatus = 'Draft'
                            color = 'draft'
                            break;
                        case 'UC':
                            pStatus = 'Confirmed Owner'
                            color = 'confirmedowner'
                            break;
                        case 'N':
                            pStatus = 'Unpublished'
                            color = 'unpublished'
                            break;
                        case 'OM':
                            pStatus = 'Off-Market'
                            color = 'offmarket'
                            break;
                        case 'CTM':
                            pStatus = 'Coming to market'
                            color = 'comingtomarket'
                            break;
                        case 'Y':
                            pStatus = 'Published'
                            color = 'published'
                            break;
                        case 'U':
                            pStatus = 'Action'
                            color = 'action'
                            break;

                        case 'SEO':
                            pStatus = 'SEO'
                            color = 'seo'
                            break;
                        case 'L':
                            pStatus = 'Unlisted'
                            color = 'unlisted'
                            break;
                        case 'S':
                            pStatus = 'Sold'
                            color = 'sold'
                            break;
                        case 'T':
                            pStatus = 'Let'
                            color = 'let'
                            break;
                        case 'P':
                            pStatus = 'Review'
                            color = 'review'
                            break;
                        case 'F':
                            pStatus = 'Feeds'
                            color = 'feeds'
                            break;
                        default:
                            pStatus = ''
                            color = ''
                            break;
                    }
                    return (<>

                        <div className="TableCard">
                            {/* <Link className="btn-text listinginfo" onClick={this.handleOpenDrawerToEdit.bind(this, record.id, false)}> */}
                            <div className='row alignCenter headerGrayBgColor borderBottom'>
                                <div className='col-6'>
                                    <div className='HeaderMainLeft'>
                                        <div className={color + " pstat"} key={pStatus}>
                                            {pStatus}
                                        </div>
                                        <p className='font-bold fs-6  mb-0'>{record.unitno}</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                                        <p className='info-title'>Price:</p>
                                        <p>
                                            {
                                                !isNaN(parseInt(record.price))
                                                    ? record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                                    : null
                                            }
                                        </p>
                                    </div>
                                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                                        <p className='info-title'>Added Date:</p>
                                        <p>{record.added_date != null ?
                                            <Moment fromNow>
                                                {(record.added_date) && this.getCurrentTime(record.added_date)}
                                            </Moment> : '---'}</p>
                                    </div>
                                </div>
                            </div>


                            <div className='row alignCenter borderGray pt-2 pb-2'>
                                <div className='col-12 col-xs-6 col-sm-6 '>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Owner:</p>
                                            <p>{record.owner}</p>
                                        </div>



                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Owner Phone:</p>
                                            <p>{record.owner_mobile}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-xs-6 col-sm-6'>

                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                        <p className='info-title'>Property:</p>
                                        <p> {record.property}</p>
                                    </div>
                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                        <p className='info-title'>Property Type:</p>
                                        <p>{record.property_category}</p>
                                    </div>

                                </div>


                                <div className='col-12 col-xs-6 col-sm-6'>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Property For:</p>
                                            <p>{record.property_for}</p>
                                        </div>



                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Bedrooms:</p>
                                            <p>{record.bedrooms}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-xs-6 col-sm-6'>

                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                        <p className='info-title'>Community:</p>
                                        <p> {record.community}
                                        </p>
                                    </div>
                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                        <p className='info-title'>Sub-Community:</p>
                                        <p>{record.subcommunity}</p>
                                    </div>

                                </div>


                                <div className='col-12 col-xs-6 col-sm-6'>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Added By:</p>
                                            <p>{record.added_by}

                                            </p>
                                        </div>



                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Available Dates:</p>
                                            <p>{record.avail_date != null ?
                                                <Moment fromNow>
                                                    {record.avail_date}
                                                </Moment> : null}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-xs-6 col-sm-6'>

                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                        <p className='info-title'>Notes:</p>
                                        <p>  {record.notes}
                                        </p>
                                    </div>

                                </div>
                            </div>
                            {/* </Link> */}
                            <div className='row alignCenter borderGray pt-2 pb-2'>
                                <div className='col-12 col-sm-12'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 justify-content-center BodyFirstRowRight'>
                                            <div className='d-flex flex-row align-items-center gap-2'>
                                                <Button type='primary' size="small"><i className="bi bi-eye-fill" onClick={this.displayDuplicateListing.bind(this, record.duplicate_listing)} /></Button>
                                                <Button type='primary' size="small"> <i className="bi bi-check-circle-fill"
                                                    onClick={this.onApprove.bind(this, record.id)} /></Button>
                                                <Button type='primary' size="small"> <i className="bi bi-x-circle-fill"
                                                    onClick={this.onReject.bind(this, record.id)} /></Button>

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                    </>)
                }

            }
        ];


        const handleTableChange = (pagination, filters) => {
            this.fetchData(pagination.current, pagination.pageSize);
        };

        return (
            <div>
                <ToastContainer />
                <Modal title="Add Call Log" open={this.state.isDuplicateListingOpen}
                    onCancel={this.handleDuplicateListingModalCancel}
                    footer={<><Button onClick={this.handleDuplicateListingModalCancel}>Cancel</Button>
                    </>}
                >
                    {this.state.currentDuplicateListing.map((r_l) => (
                        <div>
                            <div className='form-group w-100'>
                                <Link onClick={this.handleListings.bind(this, r_l.id)} style={{ lineHeight: 2 }}> <Popover
                                    content={this.handleContent.bind(this, r_l)} title="Details" trigger="hover">
                                    {r_l.refno}
                                </Popover> </Link>
                            </div>
                        </div>
                    ))}
                </Modal>
                <div className="contentwrap" style={{
                    minHeight: 380,
                    background: "#fff",
                }}>

                    <div className="table-responsive tablelistwrap px-3">
                        <Table onChange={handleTableChange}
                            rowSelection={window.isIpadView ? '' : rowSelection}
                            className="TableborderNoneMain"
                            loading={this.state.isLoading}
                            pagination={{
                                pageSizeOptions: ['10', '20', '50', '100'],
                                total:
                                    this.state?.holding_listing?.total > 0 &&
                                    this.state.holding_listing.total,
                                pageSize: this.state.holding_listing.per_page,
                                current: this.state.holding_listing.current_page,
                                showTotal: (total, range) =>
                                   window.isMobileView ? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                            }}
                            columns={window.isIpadView ? allListingColumns : columns}
                            scroll={{ x: '1300' }}
                            dataSource={
                                this.state?.holding_listing?.data
                                    ? this.state?.holding_listing?.data
                                    : []
                            }
                            rowKey={(record) => record.id}
                        />

                    </div>
                </div>

            </div>


        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNoMatch: (data) => dispatch({ type: "SET_Not_Match", payload: data })
    }
}

export default connect(null, mapDispatchToProps)(HoldingBay)