import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'antd/dist/reset.css';
import { Table, Tabs, Space, Button, Modal, Select, Drawer, Input, Avatar, Divider, DatePicker,Mentions } from 'antd'
import { FilterOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined, EditOutlined,CloseOutlined,SettingFilled,DeleteFilled,SearchOutlined,ClearOutlined } from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import EdiText from "react-editext";
import validator from 'validator'
import parse from 'html-react-parser';
import BeatLoader from "react-spinners/BeatLoader";
// import './index.css';
// import './listing.css'
import "react-toastify/dist/ReactToastify.css";

import Swal from "sweetalert2";
import { baseLpUrlImg, baseurlImg } from '../../components/BaseUrl';
import { store } from '../../store';



const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const width = window.screen.width;
// let columns = []
let property_type_array = ["Residential", "Commercial"]
let property_for_array = ["sale", "rent", "tenanted", "short-term"]



class LeadsPond extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isNextLoading: false,
      isPreviousLoading: false,
      rows: [],
      mylistings: [],
      propertytypes: [],
      property_viewings: [],
      property_offers: [],
      collaborators:[],
      followups:[],
      ref_nums: [],
      unit_nums:[],
      userClients: [],
      selectedClients:[],
      selectedCallUsers:[],
      selectedNoteUsers:[],
      selectedWhatsappUsers:[],
      selectedEmailUsers:[],

      selectedViewingUsers:[],
      selectedOfferUsers:[],
      selectedFollowupUsers:[],

      newArrSelectedAgents:[],

      ref_def_nums:[],
      old_unit_num:[],
      viewings_listings:[],
      viewing_ref_no: "",
      viewing_unit_no:"",
      viewing_agent: "",
      viewing_date: "",
      viewing_note: "",
      viewing_status: "",
      viewing_price: "",
      showPrice: false,
      showDate: false,
      community_from_requirement:"",
      sub_community_from_requirement:"",
      min_from_requirement:"",
      max_from_requirement:"",
      bedroom_from_requirement:"",
      property_for_from_requirement:"",
      property_type_from_requirement:"",
      added_date_from_requirement:"",

      lead_agent:"",
      lead_community:"",
      lead_sub_community:"",
      //followup
      followup_note:"",
      followup_date:"",
      leads: [],
      lead_details: {},
      viewings: {},
      all:[],
      notes: [],
      agents: [],
      call_notes: [],
      whatsapp_notes: [],
      email_notes: [],
      viewing_notes:[],
      offer_notes:[],
      followup_notes:[],
      communities: [],
      sub_communities: [],
      properties:[],
      all_sub_communities:[],
      requirement_sub_communities:[],
      filters: [],
      greaterthanprice: false,
      leassthanprice: false,
      inbetweenprice: false,
      lessValue: 0,
      greaterValue: 0,

      owner_id:"",
      owner_name:"",
      owner_email:"",
      owner_mobile:"",

      community_match: "",
      sub_community_match: "",
      min_budget: 0,
      max_budget: 0,
      bedroom_match: "",
      property_for_match: "",
      property_type_match: "",
      added_date_match:"",
      date_morethan_option_match: true,
      date_lessthan_option_match: false,
      date_value_match: 0,
      date_value_id_match: 1,
      matchs: [],
      not_matchs: [],
      owner_other_properties: [],


      // requirements

      requirement_min_budget: 0,
      requirement_max_budget: 0,
      requirement_lead_stage: "",
      requirement_community: "",
      requirement_sub_community: "",
      requirement_verifyedby: "",
      requirement_agent_id: "",
      requirement_move_in_date: "",
      requirement_bedroom: "",


      // filters option

      // price

      price_first_value_id: 1,
      price_second_value_id: 1,
      price_active_second_option: false,
      price_and_or_option: true,
      price_first_value: "",
      price_second_value: "",
      inrange: false,
      price_min: 0,
      price_max: 0,

      // date

      date_first_value_id: 1,
      date_second_value_id: 1,
      date_active_second_option: false,
      date_and_or_option: true,
      date_first_value: "",
      date_second_value: "",
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: 0,
      date_value_id: 1,

      // add new filter 
      isFilterOpen: false,
      isFilterEditOpen: false,
      filter_id: 0,
      filter_title: "",
      contact_type_id: 0,
      community_value_id: 0,
      sub_community_value_id: 0,
      price_value_id: 0,
      price_value: "",
      bed_rooms_value_id: 1,
      bed_rooms_value: "",
      created_value_id: 0,
      created_value: "",
      stage_value_id: 0,
      stage_value: [],
      contact_types_value: [],
      furnished_types_value: [],
      property_type_filter:[],

      default_stage_value: [],
      default_contact_types_value: [],
      default_furnished_types_value: [],

      name_value_id: 1,
      name_value: "",

      phone_value_id: 1,
      phone_value: "",

      agent_value_id: 1,
      agent_value: "",

      // drawer for details
      open: false,

      record_id: 0,
      view_id: 0,
      isviewingOpen: false,
      iseditviewingOpen: false,
      isOfferOpen: false,
      isfollowupOpen:false,
      isfollowupViewOpen:false,
      furnished_types: [
        {
          value: '1',
          label: 'Furnished'
        },
        {
          value: '2',
          label: 'Unfurnished'
        }
      ],

      contact_types: [
        {
          value: '1',
          label: 'Tenant'
        },
        {
          value: '2',
          label: 'Buyer'
        },
        {
          value: '3',
          label: 'Landlord'
        },
        {
          value: '4',
          label: 'Seller'
        },
        {
          value: '5',
          label: 'Landlord+Seller'
        },
        {
          value: '7',
          label: 'Other'
        },
        {
          value: '8',
          label: 'Portal'
        },
        {
          value: '9',
          label: 'Buyer/Tenant'
        },
        {
          value: '10',
          label: 'Unrecognized'
        }
      ],

      stages: [
        {
          value: '1',
          label: 'Uncontacted',
        },
        {
          value: '2',
          label: 'Working',
        },
        {
          value: '3',
          label: 'Hot',
        },
        {
          value: '4',
          label: 'Follow Up',
        },
        {
          value: '5',
          label: 'Dead',
        },
      ],

      filter_options: [


        {
          value: '1',
          label: 'Contact type',
        },

        {
          value: '2',
          label: 'Community / Sub community',
        },
        {
          value: '3',
          label: 'Price',
        },
        {
          value: '4',
          label: 'Bedrooms',
        },
        {
          value: '5',
          label: 'Created',
        },
        {
          value: '6',
          label: 'Stage',
        },
        {
          value: '7',
          label: 'Furnished  / unfurnished',
        },


      ],
      price_options: [


        {
          value: '1',
          label: 'Equals',
        },
        {
          value: '2',
          label: 'Not equal',
        },
        {
          value: '3',
          label: 'Less than',
        },
        {
          value: '4',
          label: 'Less than or equals',
        },
        {
          value: '5',
          label: 'Greater than',
        },
        {
          value: '6',
          label: 'Greater than or equals',
        },

        {
          value: '7',
          label: 'In range',
        },

      ],

      name_filter_options: [
        {
          value: '1',
          label: 'Contain',
        },
        {
          value: '2',
          label: 'Not contain',
        },
        {
          value: '3',
          label: 'Equals',
        },
        {
          value: '4',
          label: 'Not equal',
        },
        {
          value: '5',
          label: 'Start with',
        },
        {
          value: '6',
          label: 'Ends with',
        },

      ],
      phone_filter_options: [
        {
          value: '1',
          label: 'Contain',
        },
        {
          value: '2',
          label: 'Not contain',
        },
        {
          value: '3',
          label: 'Equals',
        },
        {
          value: '4',
          label: 'Not equal',
        },
        {
          value: '5',
          label: 'Start with',
        },
        {
          value: '6',
          label: 'Ends with',
        },

      ],
      bedroom_filter_options: [
        {
          value: '1',
          label: 'Equals',
        },
        {
          value: '2',
          label: 'Not equal',
        },
        {
          value: '3',
          label: 'Less than',
        },
        {
          value: '4',
          label: 'Greater than',
        },


      ],
      agent_filter_options: [
        {
          value: '1',
          label: 'Contain',
        },
        {
          value: '2',
          label: 'Not contain',
        },
        {
          value: '3',
          label: 'Equals',
        },
        {
          value: '4',
          label: 'Not equal',
        },
        {
          value: '5',
          label: 'Start with',
        },
        {
          value: '6',
          label: 'Ends with',
        },

      ],
      // add notes
      call_log: "",
      note_log: "",
      whatsapp_log: "",
      email_log: ""
    }
    this.textInput = React.createRef();

  }

  componentDidMount() {

    var loadScript = function (src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    }
    loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
    const page = 1;
    const perPage = 10;
    const data = {
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,
      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,
      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,
      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      property_type_filter:this.state.property_type_filter

    }
    this.fetchFilter();
    this.fetchData(page, perPage, data);

    setTimeout(() => {
      this.fetchAllRequest();
    }, 2000);


  }

  fetchAllRequest = () =>{
    API.get("/community")
    .then((response) => {
      if (response.data.success) {
        this.setState({ communities: response.data.communities })
      }
    })
  API.get("/propertytypes")
    .then((response) => {
      if (response.data.success) {
        this.setState({ propertytypes: response.data.propertytypes })
      }
    })



    API.get('/all_client_users').then((response) => {
      if(response.data.success){
        this.setState({userClients: response.data.users})
      }
    })

  }





  fetchFilter = () => {
    API.get('/filters')
      .then((response) => {
        if (response.data.success) {
          this.setState({ filters: response.data.filters })
        }
      })
  }
  handleAddRow = (value) => {
    const item = {
      name: "",
      mobile: "",
      id: value

    };

    let filter_options = this.state.filter_options.filter(item => item.value !== value)
    this.setState({
      rows: [...this.state.rows, item],
      filter_options: filter_options
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };
  handleRemoveSpecificRow = (idx, id) => () => {
    const rows = [...this.state.rows]
    if (id == 1) {
      const item = {
        value: '1',
        label: 'Contact type'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    } else
      if (id == 2) {
        const item = {
          value: '2',
          label: 'Community / Sub community'
        }

        this.setState({
          filter_options: [...this.state.filter_options, item]
        })
      } else
        if (id == 3) {
          const item = {
            value: '3',
            label: 'Price'
          }

          this.setState({
            filter_options: [...this.state.filter_options, item],
            price_first_value_id: 0,
            price_first_value: 0,
            price_min: 0,
            price_max: 0
          })
        } else
          if (id == 4) {
            const item = {
              value: '4',
              label: 'Bedrooms'
            }

            this.setState({
              filter_options: [...this.state.filter_options, item]
            })
          } else
            if (id == 5) {
              const item = {
                value: '5',
                label: 'Created'
              }

              this.setState({
                filter_options: [...this.state.filter_options, item]
              })
            } else
              if (id == 6) {
                const item = {
                  value: '',
                  label: 'Stages'
                }

                this.setState({
                  filter_options: [...this.state.filter_options, item]
                })
              } else
                if (id == 7) {
                  const item = {
                    value: '7',
                    label: 'Furnished / Unfurnished'
                  }

                  this.setState({
                    filter_options: [...this.state.filter_options, item]
                  })
                }

    rows.splice(idx, 1)

    this.setState({ rows })
  }

  handleNewFilter = () => {
    this.setState({ isFilterOpen: true })
   
  }


  handleEditFilter = (id, e) => {

    let filter_option = []

    const newrows = [
      {
        id: 1
      },
      { id: 2 },
      {
        id: 3,
      },
      { id: 4 },
      { id: 5 },
      {
        id: 6
      },
      { id: 7 }
    ]
    this.setState({ rows: newrows })

    this.setState({ isFilterEditOpen: true, filter_id: id })

    API.get("/get_saved_filter/" + id)
      .then((response) => {
        if (response.data.success) {
          const response_filter = response.data.filter
          // API.get("/sub_community/" + response_filter.community_value_id)
          //   .then((response) => {
          //     if (response.data.success)
          //       this.setState({ sub_communities: response.data.sub_communities })
          //   })


          const stage = response.data.filter_stages
          const contact_type = response.data.filters_contact_types
          const furnished_unfurnished = response.data.filters_furnished_unfurnisheds
          

          this.setState({
            stage_value: stage, contact_types_value: contact_type, furnished_types_value: furnished_unfurnished,
            filter_title: response_filter.title,
            community_value_id: response_filter.community_value_id,
            sub_community_value_id: response_filter.sub_community_value_id,

            price_first_value_id: response_filter.price_first_value_id,
            price_first_value: response_filter.price_first_value,
            price_min: response_filter.price_min,
            price_max: response_filter.price_max,
            inrange: response_filter.price_first_value_id == 7 ? true : false,

            date_morethan_option: response_filter.date_morethan_option,
            date_lessthan_option: response_filter.date_lessthan_option,
            date_value_id: response_filter.date_value_id,
            date_value: response_filter.date_value,

            bed_rooms_value_id: response_filter.bed_rooms_value_id,
            bed_rooms_value: response_filter.bed_rooms_value,
            default_stage_value: response.data.filter_stages,
            default_contact_types_value: response.data.filters_contact_types,
            default_furnished_types_value: response.data.filters_furnished_unfurnisheds

          })
        }
      })
  }

  handleEditOk = () => {
    const id = this.state.filter_id
    const data = {
      filter_title: this.state.filter_title,
      community_value_id: this.state.community_value_id,
      sub_community_value_id: this.state.sub_community_value_id,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      stage_value: this.state.stage_value,

      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

    }

    API.put("/update_filter/" + id, data)
      .then((response) => {

        if (response.data.success) {
          this.setState({ isFilterEditOpen: false, filter_title: "" })
          this.fetchFilter();

        }
      })

  }

  handleOk = () => {


    const data = {
      filter_title: this.state.filter_title,
      community_value_id: this.state.community_value_id,
      sub_community_value_id: this.state.sub_community_value_id,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      stage_value: this.state.stage_value,

      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

    }


    API.post("/add_filter", data)
      .then((response) => {
        //return
        if (response.data.success) {
          this.setState({ isFilterOpen: false, filter_title: "" })
          this.fetchFilter();

        }
      })
  };
  handleCancel = () => {
    this.setState({ isFilterOpen: false, filter_title: '' })
  };

  handleEditCancel = () => {
    this.setState({ isFilterEditOpen: false, filter_title: '' })
  };

  handleFilterTitle = (e) => {
    this.setState({ filter_title: e.target.value })
  }

  handleContactType = (e) => {



    this.setState({ contact_types_value: e })


    const page = 1;
    const perPage = 10;


    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: e,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }




    this.fetchData(page, perPage, data)

  }

  handleCommunity = (value) => {
    this.setState({ community_value_id: value })
    API.get("/sub_community/" + value)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }


  handleSubCommunity = (value) => {
    this.setState({ sub_community_value_id: value })
  }

  handleBedRooms_id = (e) => {

    this.setState({ bed_rooms_value_id: e })

    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: e,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,

      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }




    if (this.state.bed_rooms_value != '')
      this.fetchData(page, perPage, data);

  }

  handleBedRoomsValue = (e) => {

    this.setState({ bed_rooms_value: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: e.target.value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }

 
    this.fetchData(page, perPage, data);
  }

  handleStages = (e) => {


    this.setState({ stage_value: e, default_stage_value: e })

    const page = 1;
    const perPage = 10;


    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: e,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    this.fetchData(page, perPage, data)
  }
  handleFurnishedTypes = (e) => {
    this.setState({ furnished_types_value: e })

      const page = 1;
      const perPage = 10;
      const data = {
        property_type_filter:this.state.property_type_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,
      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,
      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: e,price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    this.fetchData(page, perPage, data)
  }

  handleDeleteFilter = (targetKey, action) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_filter/" + targetKey)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchFilter()
            }
          })
      }
    })
  };

  // filter options

  handleNameValue_id = (e) => {
    this.setState({ name_value_id: e })

    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: e,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,
      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }
    if (this.state.name_value != '')
      this.fetchData(page, perPage, data);
  }

  handleName = (e) => {

    this.setState({ name_value: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: e.target.value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

    }
    this.fetchData(page, perPage, data);
  }

  handlePhoneValue_id = (e) => {
    this.setState({ phone_value_id: e })
    const page = 1;
    const perPage = 10;
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: e,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }
    if (this.state.phone_value != '')
      this.fetchData(page, perPage, data);
  }

  handlePhone = (e) => {

    this.setState({ phone_value: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: e.target.value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

    }


    if (this.state.phone_value_id > 0)
      this.fetchData(page, perPage, data);

  }

  handleAgentValue_id = (e) => {

    this.setState({ agent_value_id: e })

    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: e,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }
    if (this.state.agent_value != '')
      this.fetchData(page, perPage, data);
     }
  handleAgent = (e) => {
    this.setState({ agent_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: e.target.value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }
    this.fetchData(page, perPage, data)
  }


  handlePropertyForFirstValue = (e) => {

    const property_for_first_value = e.target.value;
    if (property_for_first_value != '') {
      this.setState({ property_for_second_option: true })
    } else {
      this.setState({ property_for_second_option: false })
    }
  }

  handleRefnoFirst = (e) => {

    const refno_first_value = e.target.value;
    this.setState({ refno_first_value: refno_first_value })

    if (refno_first_value != '') {
      this.setState({ refno_active_second_option: true })
    } else {
      this.setState({ refno_active_second_option: false })
    }

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleRefnoSecond = (e) => {

    this.setState({ refno_second_value: e.target.value })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: e.target.value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleRefnoFirstValue_id = (e) => {
    this.setState({ refno_first_value_id: e.target.value })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: e.target.value,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,


      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleRefRadio = (e) => {
    // alert(!this.state.refno_and_or_option)
    this.setState({ refno_and_or_option: !this.state.refno_and_or_option })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: !this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleRefnoSecondValue_id = (e) => {
    this.setState({ refno_second_value_id: e.target.value })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: e.target.value,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_second_value != '')
      this.fetchData(page, perPage, data);

  }

  // property for 
  handleSales = (e) => {
    if (this.state.sale) {
      property_for_array = property_for_array.filter(e => e !== 'sale');
    } else {

      property_for_array.push('sale')
    }

    this.setState({ sale: !this.state.sale })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }
  handleRent = (e) => {
    if (this.state.rent) {
      property_for_array = property_for_array.filter(e => e !== 'rent');
    } else {

      property_for_array.push('rent')
    }
    this.setState({ rent: !this.state.rent })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }
  handleTenant = (e) => {
    if (this.state.tenant) {
      property_for_array = property_for_array.filter(e => e !== 'tenanted');
    } else {

      property_for_array.push('tenanted')
    }
    this.setState({ tenant: !this.state.tenant })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }
  handleShortterm = (e) => {
    if (this.state.short_term) {
      property_for_array = property_for_array.filter(e => e !== 'short-term');
    } else {

      property_for_array.push('short-term')
    }
    this.setState({ short_term: !this.state.short_term })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  // property type
  handleResidential = (e) => {



    if (this.state.residential) {

      property_type_array = property_type_array.filter(e => e !== 'Residential');


    } else {

      property_type_array.push('Residential')


    }

    this.setState({ residential: !this.state.residential })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }
  handleCommercial = (e) => {
    if (this.state.commercial) {
      property_type_array = property_type_array.filter(e => e !== 'Commercial');


    } else {
      property_type_array.push('Commercial')

    }
    this.setState({ commercial: !this.state.commercial })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,
      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  // unit no options
  handleUnitnoFirst = (e) => {
    

    const unitno_first_value = e.target.value;
    this.setState({ unitno_first_value: unitno_first_value })

    if (unitno_first_value != '') {
      this.setState({ unitno_active_second_option: true })
    } else {
      this.setState({ unitno_active_second_option: false })
    }

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleUnitnoSecond = (e) => {

    this.setState({ unitno_second_value: e.target.value })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: e.target.value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleUnitnoFirstValue_id = (e) => {
    this.setState({ unitno_first_value_id: e.target.value })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: e.target.value,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleUnitRadio = (e) => {
    // alert(!this.state.refno_and_or_option)
    this.setState({ unitno_and_or_option: !this.state.unitno_and_or_option })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleUnitnoSecondValue_id = (e) => {
    this.setState({ unitno_second_value_id: e.target.value })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: e.target.value,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_second_value != '')
      this.fetchData(page, perPage, data);

  }


  // price options

  handlePriceFirst = (e) => {
    const price_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { price_first_value: e.target.value } : prevState)
    )



    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array


    }

    const page = 1;
    const perPage = 10;
    if (e.target.value > 0) {
      this.fetchData(page, perPage, data);
    } else {
      return
    }




  }

  // price in range
  handlePriceMin = (e) => {

    this.setState({ price_min: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,



      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: e.target.value,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array


    }
    if (this.state.price_max > 0)
      this.fetchData(page, perPage, data);

  }

  handlePriceMax = (e) => {

    this.setState({ price_max: e.target.value })

    const max = e.target.value
    const page = 1;
    const perPage = 10;

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: e.target.value,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      // property_for:property_for_array,

      // property_type:property_type_array


    }
    if (this.state.price_min > 0 && max > this.state.price_min)
      this.fetchData(page, perPage, data);
  }

  handlePriceSecond = (e) => {

    this.setState({ price_second_value: e.target.value })

    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: e.target.value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handlePriceFirstValue_id = (e) => {



    this.setState({ inrange: false })
    if (e == 7) {
      this.setState({ inrange: true })
    }

    this.setState({ price_first_value_id: e, price_value_id: e })
    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,



      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: e,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handlePriceRadio = (e) => {
    // alert(!this.state.refno_and_or_option)
    this.setState({ price_and_or_option: !this.state.price_and_or_option })

    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handlePriceSecondValue_id = (e) => {
    this.setState({ price_second_value_id: e.target.value })



    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: e.target.value,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_second_value != '')
      this.fetchData(page, perPage, data);

  }


  // date option

  handleDateMorethan = (e) => {
    this.setState({ date_morethan_option: true, date_lessthan_option: false })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);

  }

  handleDateLessthan = (e) => {
    this.setState({ date_morethan_option: false, date_lessthan_option: true })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: false,
      date_lessthan_option: true,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);

  }

  handleDateValue_id = (e) => {
    this.setState({ date_value_id: e.target.value })
    const data = {
      property_type_filter:this.state.property_type_filter,
      //
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: e.target.value,

      //
    
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateValue = (e) => {
    this.setState({ date_value: e.target.value })

    const data = {
      property_type_filter:this.state.property_type_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: e.target.value,
      date_value_id: this.state.date_value_id,
    }

    const page = 1;
    const perPage = 10;
    if (e.target.value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateFirst = (e) => {

    const date_first_value = e.target.value;
    this.setState({ date_first_value: date_first_value })

    if (date_first_value != '') {
      this.setState({ date_active_second_option: true })
    } else {
      this.setState({ date_active_second_option: false })
    }

    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleDateSecond = (e) => {

    this.setState({ date_second_value: e.target.value })

    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: e.target.value,


      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleDateFirstValue_id = (e) => {
    this.setState({ date_first_value_id: e.target.value })
    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: e.target.value,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateRadio = (e) => {
    // alert(!this.state.refno_and_or_option)
    this.setState({ date_and_or_option: !this.state.date_and_or_option })

    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleDateSecondValue_id = (e) => {
    this.setState({ date_second_value_id: e.target.value })

    const data = {
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: e.target.value,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_second_value != '')
      this.fetchData(page, perPage, data);

  }





  onInputChange = (e) => {
    console.log("onchange", e.target.value)
  }


  handlePriceRange = (e) => {
    const radio = e.target.value
    if (radio == 1) {
      this.setState({ greaterthanprice: true, leassthanprice: false, inbetweenprice: false })
    } else
      if (radio == 2) {
        this.setState({ greaterthanprice: false, leassthanprice: true, inbetweenprice: false })
      } else
        if (radio == 3) {
          this.setState({ greaterthanprice: false, leassthanprice: false, inbetweenprice: true })
        }
  }

  handleGreaterValue = (e) => {
    this.setState({ greaterValue: e.target.value })
  }

  handleLessValue = (e) => {
    this.setState({ lessValue: e.target.value })
  }

  handleMinKeyDown = (event) => {
    const maxValue = this.state.greaterValue
    const data = {
      greaterValue: this.state.greaterValue,
      lessValue: this.state.lessValue
    }
    if (event.key === 'Enter') {
      if (maxValue > 0) {
      } else {
        this.textInput.current.focus();
      }
    }
  }



  fetchData = (page, perPage, data) => {
    this.setState({ isLoading: true })

    API.post(`/lead_pond?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {

    
       
        if (response.data.success) {

         var data = await response.data.leads.data;



          await response.data.leads;
          this.setState({
            leads: response.data.leads,
            isLoading: false
          })
        }

      })
    // return
    //   this.setState({isLoading:true})
    //  setTimeout(() => {


    //   API.post(`/listings?page=${page}&perPage=${perPage}`,data)
    //        .then(async (response)  =>{



    //           var data = await response.data.listings.data;

    //           await response.data.listings;
    //           this.setState({
    //               mylistings: response.data.listings,
    //               isLoading: false
    //           })

    //        })
    //       }, 200);

  }

  // onDragEnd = (fromIndex, toIndex) => {

  //   const item = columns.splice(fromIndex, 1)[0];
  //   //columns.splice(toIndex, 0, item);
  //   columns.push(item)

  // };

  handleTabs = (e) => {


    const page = 1;
    const perPage = 10;
    let filter_options = []

    API.get("/get_saved_filter/" + e)
      .then((response) => {
        const response_filter = response.data.filter

        const stage = response.data.filter_stages
        const contact_type = response.data.filters_contact_types
        const furnished_unfurnished = response.data.filters_furnished_unfurnisheds
        const stage_data = []
        const contact_type_data = []
        const furnished_unfurnished_data = []
        for (let i = 0; i < stage.length; i++) {
          stage_data.push(stage[i].value);

        }

        for (let i = 0; i < contact_type.length; i++) {
          contact_type_data.push(contact_type[i].value);

        }

        for (let i = 0; i < furnished_unfurnished.length; i++) {
          furnished_unfurnished_data.push(furnished_unfurnished[i].value);

        }

   
        const data = {
          property_type_filter:this.state.property_type_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,

          name_value_id: this.state.name_value_id,
          name_value: this.state.name_value,
          phone_value_id: this.state.phone_value_id,
          phone_value: this.state.phone_value,
          agent_value_id: this.state.agent_value_id,
          agent_value: this.state.agent_value,
          bed_rooms_value_id: response_filter.bed_rooms_value_id,
          bed_rooms_value: response_filter.bed_rooms_value,
          stage_value: stage_data,
          contact_types_value: contact_type_data,
          furnished_types_value: furnished_unfurnished_data,
          price_first_value_id: response_filter.price_first_value_id,
          price_first_value: response_filter.price_first_value,
          price_min: response_filter.price_min,
          price_max: response_filter.price_max,
          date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
          date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
          date_value: response_filter.date_value,
          date_value_id: response_filter.date_value_id,
        }
        this.fetchData(page, perPage, data);
      })





  }

  handleOpenDrawer = (id, e) => {
    // alert(id)

    this.setState({ record_id: id })

    API.get("/lead_details/" + id)
      .then((response) => {

      

        if (response.data.success)
          this.setState({
            lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
            all:response.data.all,
            notes: response.data.notes,requirement_community:response.data.lead_details.community,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes, 
            owner_id:response.data.lead_details.owner_id,
            owner_name:response.data.lead_details.owner,
            owner_email:response.data.lead_details.owner_email,
            owner_mobile:response.data.lead_details.mobile,
            requirement_bedroom:response.data.lead_details.bedroom,requirement_min_budget:response.data.lead_details.budget_from,
            requirement_max_budget:response.data.lead_details.budget_to,
            requirement_lead_stage:response.data.lead_details.lead_stage,
            requirement_community:response.data.lead_details.community,requirement_sub_community:response.data.lead_details.subcommunity,
            requirement_agent_id:response.data.lead_details.lead_agent,lead_community:response.data.lead_details.community,lead_sub_community:response.data.lead_details.subcommunity,
            collaborators:response.data.collaborators,
            property_viewings:response.data.property_viewings,property_offers:response.data.property_offers
          })
        this.setState({ open: true })

        window.history.replaceState(null, "leads-pond Id", window.location.href + '/' + id)

        API.get("/all_agents")
          .then((response) => {
            if (response.data.success) {
              this.setState({ agents: response.data.agents })
            }
          }
          )

          this.fetchFollowUp(id);
          const community = response.data.lead_details.community
          API.get("/sub_community/"+community)
          .then((response) =>{
            if(response.data.success)
            this.setState({requirement_sub_communities:response.data.sub_communities})
          })

      })



  }




  handleNextRecord = (e) => {
    const id = this.state.record_id;
this.setState({isNextLoading:true})

    API.get("/next_lead/" + id)
      .then((response) => {

        if (response.data.success) {
          this.setState({
             isNextLoading:false,
             record_id: response.data.lead_details.parent_lead,
             lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
             all:response.data.all,
             notes: response.data.notes,requirement_community:response.data.lead_details.community,
             call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
             email_notes: response.data.email_notes, property_offers: response.data.property_offers,
             owner_id:response.data.lead_details.owner_id,
             owner_name:response.data.lead_details.owner,
             owner_email:response.data.lead_details.owner_email,
             owner_mobile:response.data.lead_details.mobile,
             requirement_bedroom:response.data.lead_details.bedroom,requirement_min_budget:response.data.lead_details.budget_from,
             requirement_max_budget:response.data.lead_details.budget_to,
             requirement_community:response.data.lead_details.community,requirement_sub_community:response.data.lead_details.subcommunity,
             requirement_agent_id:response.data.lead_details.lead_agent,lead_community:response.data.lead_details.community,lead_sub_community:response.data.lead_details.subcommunity,
             collaborators:response.data.collaborators
          })

          // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
        } else {
          this.setState({isNextLoading:false})
          toast.warning("last record")
        }
      })
  }

  handlePreviousRecord = (e) => {

    const id = this.state.record_id;
  this.setState({isPreviousLoading:true})
    API.get("/previous_lead/" + id)
    .then((response) => {

      if (response.data.success) {
        this.setState({
          isPreviousLoading:false,
           record_id: response.data.lead_details.parent_lead,
           lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
           all:response.data.all,
           notes: response.data.notes,requirement_community:response.data.lead_details.community,
           call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
           email_notes: response.data.email_notes, property_offers: response.data.property_offers,
           owner_id:response.data.lead_details.owner_id,
           owner_name:response.data.lead_details.owner,
           owner_email:response.data.lead_details.owner_email,
           owner_mobile:response.data.lead_details.mobile,
           requirement_bedroom:response.data.lead_details.bedroom,requirement_min_budget:response.data.lead_details.budget_from,
           requirement_max_budget:response.data.lead_details.budget_to,
           requirement_community:response.data.lead_details.community,requirement_sub_community:response.data.lead_details.subcommunity,
           requirement_agent_id:response.data.lead_details.lead_agent,lead_community:response.data.lead_details.community,lead_sub_community:response.data.lead_details.subcommunity,
           collaborators:response.data.collaborators
        })

        // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
      } else {
        this.setState({isPreviousLoading:false})
        toast.warning("last record")
      }
    })
  }




  handleCloseDrawer = () => {
    this.setState({ open: false })
    const newUrl = window.location.href.replace(/leads-pond.*/, 'leads-pond');
    window.history.replaceState(null, "leads-pond Id", newUrl);
  }

  handleSaveData = () => {
    toast.success("Successfully addedd")
    this.setState({ open: false })
  }

  handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {


        toast.success("Successfully deleted")



      }
    })
  }

  handleCommunityMatch = (e) => {
    this.setState({ community_match: e })
    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }

  getCurrentTime = (date) => {
    if (date != null && date != "" && date != undefined && date != "0000-00-00 00:00:00"){
      let newDate = new Date(date);
      newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
      return newDate.toISOString();
    }
  }



  handleSubCommunityMatch = (e) => {
    this.setState({ sub_community_match: e })

  }

  handleRequirementCommunity = (e) => {

    this.setState({ requirement_community: e })
    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ requirement_sub_communities: response.data.sub_communities })
      })
  }


  handleRequirementSubCommunity = (e) => {
    this.setState({ requirement_sub_community: e })

  }

  handleRequirementBeds = (e) => {
    this.setState({ requirement_bedroom: e.target.value })
  }

  handleRequirementMin = (e) => {
    this.setState({ requirement_min_budget: e.target.value })
  }

  handleRequirementMax = (e) => {
    this.setState({ requirement_max_budget: e.target.value })
  }

  handleRequirementMoveInDate = (e) => {
    this.setState({ requirement_move_in_date: e.target.value })
  }

  handleRequirementStage = (e) => {
    this.setState({ requirement_lead_stage: e.target.value })
  }

  handleRequirementVerifyby = (e) => {
    this.setState({ requirement_verifyedby: e.target.value })
  }

  handleRequirementAgent = (e) => {
    this.setState({ requirement_agent_id: e })
  }

  handleRequirementSubmit = () => {

    const data = {
      lead_id: this.state.record_id,
      requirement_min_budget: this.state.requirement_min_budget,
      requirement_max_budget: this.state.requirement_max_budget,
      requirement_lead_stage: this.state.requirement_lead_stage,
      requirement_community: this.state.requirement_community,
      requirement_sub_community: this.state.requirement_sub_community,
      requirement_verifyedby: this.state.requirement_verifyedby,
      requirement_agent_id: this.state.requirement_agent_id,
      requirement_move_in_date: this.state.requirement_move_in_date,
      requirement_bedroom: this.state.requirement_bedroom
    }

    API.post("/requirements_save_update", data)
      .then((response) => {
        let requirement_note = ""
        if(response.data.success){

          this.setState({
            min_from_requirement: this.state.requirement_min_budget,
            max_from_requirement: this.state.requirement_max_budget,
            community_from_requirement: this.state.requirement_community,
            sub_community_from_requirement: this.state.requirement_sub_community,
            added_date_from_requirement: this.state.requirement_move_in_date,
            bedroom_from_requirement: this.state.requirement_bedroom
          })

          const old_community = this.state.lead_details.community !=null? this.state.lead_details.community:0
          const new_community = this.state.requirement_community !=null? this.state.requirement_community:0
          const old_sub_community = this.state.lead_details.subcommunity
          const new_sub_community = this.state.requirement_sub_community

          const old_bedroom= this.state.lead_details.bedroom
          const new_bedroom = this.state.requirement_bedroom
          const old_lead_stage = this.state.lead_details.lead_stage
          const new_lead_stage = this.state.requirement_lead_stage
         if(old_community != new_community){
          const old_community_obj = this.state.communities.find(obj =>{
            return obj.value == old_community
          }) 

          const new_community_obj = this.state.communities.find(obj =>{
            return obj.value == new_community
          })  
       requirement_note+="Community changed from "+old_community_obj.label+" to "+new_community_obj.label+"\n"

         }

         if(old_sub_community != new_sub_community){

          const old_sub_community_obj = this.state.all_sub_communities.find(obj =>{
            return obj.value == old_sub_community
          }) 

          const new_sub_community_obj = this.state.all_sub_communities.find(obj =>{
            return obj.value == new_sub_community
          }) 

    

        
         
       requirement_note+="\n Sub Community changed from "+old_sub_community_obj.label+" to "+new_sub_community_obj.label+"\n"

         }

         if(old_bedroom != new_bedroom){

         requirement_note+="Bedroom from "+old_bedroom+" to "+new_bedroom+"\n"

         }

         if(old_lead_stage != new_lead_stage){

          requirement_note+="Lead stage from "+old_lead_stage+" to "+new_lead_stage+"\n"
 
          }
      
        
      
         


     

    
   

          const data = {
            leads_id: this.state.record_id,
            note: requirement_note,
            note_type: "note"
          }
      
          API.post("/add_lead_note", data)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  
                  notes: response.data.notes,
                  call_notes: response.data.call_notes, 
                  whatsapp_notes: response.data.whatsapp_notes,
                  email_notes: response.data.email_notes
                })
      
              }
            })
        // }
      }
      })
  }

  handleMinBudget = (e) => {

    this.setState({ min_budget: e.target.value })
  }

  handleMaxBudget = (e) => {
    this.setState({ max_budget: e.target.value })
  }

  handleBeds = (e) => {
    this.setState({ bedroom_match: e.target.value })
  }

  handlePropertForMatch = (e) => {
    this.setState({ property_for_match: e.target.value })
  }

  handlePropertTypeMatch = (e) => {
    this.setState({ property_type_match: e.target.value })
  }

  handleAddedDateMatch = (e) =>{
    this.setState({added_date_match:e.target.value})
  }

  handleMatch = () => {
    const data = {
      lead_id: this.state.record_id,
      community_match: this.state.community_match,
      sub_community_match: this.state.sub_community_match,
      min_budget: this.state.min_budget, max_budget: this.state.max_budget,
      beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
      property_type_match: this.state.property_type_match,
      added_date_match:this.state.added_date_match
    }

   
    API.post("/match_leads", data)
      .then((response) => {

        if (response.data.success) {
          this.setState({ matchs: response.data.match_leads, not_matchs: response.data.not_match_leads })
        }
      })
  }

  // note logs
  handleCallLog = (e) => {
    this.setState({ call_log: e.target.value })
  }





  handleCallSubmit =()=>{
    const data = {
        leads_id:this.state.record_id,
        note:this.state.call_log,
        note_type:"phone",
        users:this.state.selectedCallUsers
    }

    API.post("/add_lead_pond_note",data)
        .then((response)=>{
            if(response.data.success){
                this.setState({
                    all:response.data.all,
                    notes:response.data.notes,
                    call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes,
                    call_log:"",
                    requirement_lead_stage:response.data.lead_details.lead_stage,
                    requirement_bedroom:response.data.lead_details.bedroom,
                    requirement_min_budget:response.data.lead_details.budget_from,
                    requirement_max_budget:response.data.lead_details.budget_to,
                    requirement_community:response.data.lead_details.community,
                    requirement_sub_community:response.data.lead_details.subcommunity,
                    requirement_agent_id:response.data.lead_details.lead_agent,

                })

            }
        })
}


handleNoteLog = (e) =>{
    this.setState({note_log:e.target.value})
}
handleNoteSubmit =()=>{
    const data = {
        listing_id:this.state.record_id,
        note:this.state.note_log,
        note_type:"note",
        users:this.state.selectedNoteUsers
    }

    API.post("/add_lead_note",data)
        .then((response)=>{

            if(response.data.success){
                this.setState({
                     all:response.data.all,
                     notes:response.data.notes,
                    call_notes:response.data.call_notes,
                    whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes})

            }
        })
}
handleWhatsappLog = (e) =>{
    this.setState({whatsapp_log:e.target.value})
}
handleWhatsappSubmit =()=>{
    const data = {
        listing_id:this.state.record_id,
        note:this.state.whatsapp_log,
        note_type:"whatsapp",
        users:this.state.selectedWhatsappUsers
    }

    API.post("/add_lead_note",data)
        .then((response)=>{
            if(response.data.success){
                this.setState({
                    all:response.data.all,
                    notes:response.data.notes,
                    call_notes:response.data.call_notes,
                    whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes})

            }
        })
}
handleEmailLog = (e) =>{
    this.setState({email_log:e.target.value})
}

handleEmailSubmit =()=>{
    const data = {
        listing_id:this.state.record_id,
        note:this.state.email_log,
        note_type:"email",
        users:this.state.selectedEmailUsers
    }



    API.post("/add_lead_note",data)
        .then((response)=>{
            if(response.data.success){
                this.setState({
                     all:response.data.all,
                    notes:response.data.notes,
                    call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes})

            }
        })
}

handleViewingSubmit =()=>{
    const data = {
        listing_id:this.state.record_id,
        note:this.state.viewing_log,
        note_type:"viewing",
        users:this.state.selectedViewingUsers
    }

    API.post("/add_lead_note",data)
        .then((response)=>{
            if(response.data.success){
                this.setState({
                  all:response.data.all,
                  notes:response.data.notes,
                    call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes})

            }
        })
}

handleOfferSubmit =()=>{
    const data = {
        listing_id:this.state.record_id,
        note:this.state.offer_log,
        note_type:"offer",
        users:this.state.selectedOfferUsers
    }

    API.post("/add_lead_note",data)
        .then((response)=>{
            if(response.data.success){
                this.setState({
                    all:response.data.all,
                    notes:response.data.notes,
                    call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes})

            }
        })
}

handleFollowupSubmit =()=>{
    const data = {
        listing_id:this.state.record_id,
        note:this.state.followup_log,
        note_type:"followup",
        users:this.state.selectedFollowupUsers
    }

    API.post("/add_lead_note",data)
        .then((response)=>{
            if(response.data.success){
                this.setState({
                  all:response.data.all,
                  notes:response.data.notes,
                    call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes})

            }
        })
}

  handleNewViewing = () => {
    this.setState({ isviewingOpen: true })
  }



  onSearchRefNo = (e) => {
    if (!e || e == '' || e == null) {
       this.setState({ref_nums:[]})
      return
    }
    API.get("/get_ref_no/" + e)
      .then((response) => {
        if (response.data.success) {
          this.setState({ ref_nums: response.data.ref_nums })
        }
      })
  }

  handleRefsno = (e) => { 
    this.setState({ viewing_ref_no: e,ref_def_nums:e })
      const data = {
        ref_nums:e
      }

    
    API.post("/get_all_listing_by_refnos",data)
    .then((response) =>{
    
     if(response.data.success)
     {
      this.setState({viewings_listings:response.data.listings})
     }
    })
  
  }

  handleUnitno = (e) =>{

   

    if (!e || e == '' || e == null) {
      API.get("/get_old_unit/"+this.state.old_unit_num)
      .then((response)=>{

        const refno = response.data.listing.value
     
        this.setState({ref_def_nums: this.state.ref_def_nums.filter(function(value) { 
          return value.refno !== refno
      })});

      // this.setState({viewings_listings: this.state.viewings_listings.filter(function(viewing_listing) { 
      //   return viewing_listing.refno !== refno
      //  })});

      

      })
      // this.setState({ref_def_nums:[]})
     return
   }
    this.setState({viewing_unit_no:e})
    const data = {
      unit_num:e
    }
  API.post("/get_all_listing_by_unitno",data)
  .then((response) =>{
    if(response.data.success){
      const listing = response.data.all_listings
      const unit = listing.unitno
      let data = response.data.all_listings
      const isFound = this.state.viewings_listings.some(element => {
        if (element.unitno === e) {
          return true;
        }
    
        return false;
      });
      if(!isFound){
      this.setState({viewings_listings:[...this.state.viewings_listings,response.data.listing],ref_def_nums:[...this.state.ref_def_nums,data.value],old_unit_num:e,viewing_ref_no:[...this.state.viewing_ref_no,data.value]})
      }else{
        return
      }
    }
  })
  }

  handleSearchUintNo = (e) =>{
   

    if (!e || e == '' || e == null) {
      // this.setState({ref_nums:[]})
      return
    }

    API.get("/get_listing_by_unitno/"+e)
    .then((response)=>{
      if (response.data.success) {
        this.setState({ unit_nums: response.data.unit_nums })
      }
    })
  }

  handleViewingNote = (e) => {
    this.setState({ viewing_note: e.target.value })
  }

  handleViewingDate = (date, dateString) => {
    this.setState({ viewing_date: dateString })
  };

  handleViewingAgent = (e) => {
    this.setState({ viewing_agent: e })
  }

  handleViewingOk = () => {
    const data = {
      lead_id: this.state.record_id,
      lead_ref_no: this.state.lead_details.refno,
      viewing_ref_no: this.state.viewing_ref_no,
      viewing_agent: this.state.viewing_agent,
      viewing_date: this.state.viewing_date,
      viewing_note: this.state.viewing_note
    }

    API.post("/add_viewing", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ isviewingOpen: false, property_viewings: response.data.property_viewings })

        }

      })

  }
  handleViewingCancel = () => {
    this.setState({ isviewingOpen: false })
  }

  handleEditViewing(view_id, e) {
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, iseditviewingOpen: true
          })
        }

      })
  }

  handleOfferView = (view_id, e) => {
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {

          // viewings: response.data.viewings, viewing_status: response.data.viewings.status,
          // viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
          // showPrice: response.data.viewings.status == "Offer" ? true : false,
          // showDate: response.data.viewings.status == "Scheduled" ? true : false,
          // viewing_price: response.data.viewings.amount, iseditviewingOpen: true

          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, isOfferOpen: true
          })
        }

      })
  }

  handleViewStatus = (e) => {
    const status = e
    this.setState({ viewing_status: e })
    if (status == "Offer") {
      this.setState({ showPrice: true })
    } else {
      this.setState({ showPrice: false })
    }

    if (status == "Scheduled") {
      this.setState({ showDate: true })
    } else {
      this.setState({ showDate: false })
    }
  }

  handleViewStatusChange = () => {
    const data = { lead_id: this.state.record_id, view_id: this.state.view_id, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price,note:this.state.viewing_note }
    API.post("/change_viewing_status", data)
      .then((response) => {
        this.setState({
          property_viewings:response.data.property_viewings,property_offers:response.data.property_offers,
        
        })
      })
    this.setState({ iseditviewingOpen: false })
  }
  handleViewStatusCancel = () => {
    this.setState({ iseditviewingOpen: false })
  }

  handleViewingPrice = (e) => {
    this.setState({ viewing_price: e.target.value })
  }


  fetchFollowUp(leads_id){
    API.get("/followups/"+leads_id)
    .then((response) =>{
      if(response.data.success){
        this.setState({followups:response.data.followups})
      }
      
    })
  }

  handleNewFollowUp = () =>{
    this.setState({ isfollowupOpen: true })
  }
  handleFollowupDate = (data,dateString)=>{
    this.setState({followup_date:dateString})
  }

  handleFollowupNote = (e) =>{
    this.setState({followup_note:e.target.value})
  }

  handleFollowupOk = () =>{
    const lead_id = this.state.record_id
    const data = {
      leads_id:this.state.record_id,
      remind_date:this.state.followup_date,
      remind_note:this.state.followup_note

    }

    API.post("/add_followup",data)
    .then((response) =>{
      
   if(response.data.success){
    this.fetchFollowUp(lead_id)
    this.setState({ isfollowupOpen: false })
   }
   

    })
  }
  handleFollowupCancel =()=>{
    this.setState({ isfollowupOpen: false })
  }

  handleViewFollowup =(reminder_id,e)=>{
    API.get("/followup_by_reminder_id/"+reminder_id)
    .then((response)=>{
      if(response.data.success){

        this.setState({isfollowupViewOpen:true,followup_date:response.data.followup.remind_date,followup_note:response.data.followup.remind_note})

      }
    })
       
  }

  handleFollowupViewOk = () =>{
    this.setState({isfollowupViewOpen:false})

  }

  handleFollowupViewCancel =() =>{
    this.setState({isfollowupViewOpen:false})
  }

  handleOkOffer  = () =>{
  this.setState({isOfferOpen:false})
  }
  handleCancelOffer = () =>{
  this.setState({isOfferOpen:false})
  }

  handleAllMatch = () =>{
   
    const data = {
      lead_id:this.state.record_id,
      community_match:this.state.community_match,
      sub_community_match:this.state.sub_community_match,
      min_budget:this.state.min_budget,max_budget:this.state.max_budget,
      beds:this.state.bedroom_match,property_for_match:this.state.property_for_match,
      property_type_match:this.state.property_type_match,
      added_date_match:this.state.added_date_match
     }
    this.props.setNoMatch(data)
    window.open("/matching_listings", "_blank");
    //this.props.history.push("/matching_listings")
  }

  handleRemoveList = (refno,e) =>{

   
    this.setState({viewings_listings: this.state.viewings_listings.filter(function(viewing_listing) { 
      return viewing_listing.refno !== refno
     })});

     this.setState({ref_def_nums: this.state.ref_def_nums.filter(function(ref_num) { 
      return ref_num.value !== refno
     })});

     this.setState({ref_def_nums: this.state.ref_def_nums.filter(function(ref_num) { 
      return ref_num !== refno
     })});
  }

   // New Code By Farooq

 
   handleCommunityFilter = (e) =>{

     
    this.setState({community_filter:e})

    API.get("/sub_community/"+e)
    .then((response) =>{
        if(response.data.success)
        this.setState({sub_communities:response.data.sub_communities})
    })

    const page = 1;
      const perPage = 10;

      const data  = {
        property_type_filter:this.state.property_type_filter,
        community_filter:e,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
      
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      }   


      this.fetchData(page, perPage,data);

  }

  handleSubCommunityFilter = (e) =>{


    API.get("/property/"+e)
    .then((response) =>{
        if(response.data.success)
        this.setState({properties:response.data.properties})
    })

   
    this.setState({sub_community_filter:e})



    const page = 1;
      const perPage = 10;

      const data  = {
        property_type_filter:this.state.property_type_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:e,
        property_filter:this.state.property_filter,
        name_value_id: this.state.name_value,
        name_value: this.state.name_value,
  
        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,
  
        bed_rooms_value_id: this.state.bed_rooms_value_id,
        bed_rooms_value: this.state.bed_rooms_value,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,
  
        stage_value: this.state.stage_value,
        contact_types_value: this.state.contact_types_value,
        furnished_types_value: this.state.furnished_types_value,
  
  
        price_first_value_id: this.state.price_first_value_id,
        price_first_value: this.state.price_first_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,
  
  
  
        date_morethan_option: this.state.date_morethan_option,
        date_lessthan_option: this.state.date_lessthan_option,
        date_value: this.state.date_value,
        date_value_id: this.state.date_value_id,
      }   


      this.fetchData(page, perPage,data);

  }




  handlePropertyFilter = (e) =>{

   
    this.setState({property_filter:e})

  

    const page = 1;
      const perPage = 10;

      const data  = {
        property_type_filter:this.state.property_type_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:e,
        name_value_id: this.state.name_value,
        name_value: this.state.name_value,
  
        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,
  
        bed_rooms_value_id: this.state.bed_rooms_value_id,
        bed_rooms_value: this.state.bed_rooms_value,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,
  
        stage_value: this.state.stage_value,
        contact_types_value: this.state.contact_types_value,
        furnished_types_value: this.state.furnished_types_value,
  
  
        price_first_value_id: this.state.price_first_value_id,
        price_first_value: this.state.price_first_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,
  
  
  
        date_morethan_option: this.state.date_morethan_option,
        date_lessthan_option: this.state.date_lessthan_option,
        date_value: this.state.date_value,
        date_value_id: this.state.date_value_id,
      }   


      this.fetchData(page, perPage,data);

  }

  handlePropertyTypeFilter = (e) =>{


    this.setState({property_type_filter:e})

    const page = 1;
    const perPage = 10;

    const data  = {
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      property_type_filter:e
    }   


    this.fetchData(page, perPage,data);
  }

  postAddCollaborator = () => {
    const data = {
      colls:this.state.newArrSelectedAgents,
      lead_id:this.state.record_id
    }
    API.post("/add_collaborate ",data)
    .then((response)=>{
      if(response.data){
         if(response.data.success){
          this.setState({collaborators:response.data.collaborators,newArrSelectedAgents:[]})
         }
      }
      else{
        console.log("***Check Add Collaborator Not Success *** ", response);
      }
    }).catch((err)=> console.log("**CHeck Error *** ", err))
  }

  handleChangeSelect = (value) => {
    this.setState({newArrSelectedAgents:value})
  };
  onSelectAgents = (value) => {
    console.log(value)
    const arr = this.state.agents.filter((data)=> {
      if(data.value == value){
        return data;
      }
    })
      // this.setState({newArrSelectedAgents: [...this.state.newArrSelectedAgents, arr]});
  this.setState((state, props) => ({
    newArrSelectedAgents: state.newArrSelectedAgents, arr
  }));
  }

  onChangeCallNote = (value) =>{
    this.setState({call_log:value})
}

onSelectCallMentions = (option) =>{
  console.log("opt is",option)
    this.setState({selectedCallUsers:[...this.state.selectedCallUsers,option]})

}

onChangeNoteNote = (value) =>{
    this.setState({note_log:value})
}

onSelectNoteMentions = (option) =>{
    this.setState({selectedNoteUsers:[...this.state.selectedNoteUsers,option]})

}

onChangeWhatsappNote = (value) =>{
    this.setState({whatsapp_log:value})
}

onSelectWhatsappMentions = (option) =>{
    this.setState({selectedWhatsappUsers:[...this.state.selectedWhatsappUsers,option]})

}

onChangeEmailNote = (value) =>{
    this.setState({email_log:value})
}

onSelectEmailMentions = (option) =>{
    this.setState({selectedEmailUsers:[...this.state.selectedEmailUsers,option]})

}

onChangeViewingNote = (value) =>{
    this.setState({viewing_log:value})
}

onSelectViewingMentions = (option) =>{
    this.setState({selectedViewingUsers:[...this.state.selectedEmailUsers,option]})

}
onChangeOfferNote = (value) =>{
    this.setState({offer_log:value})
}

onSelectOfferMentions = (option) =>{
    this.setState({selectedOfferUsers:[...this.state.selectedOfferUsers,option]})

}
onChangeFollowupNote = (value) =>{
    this.setState({followup_log:value})
}

onSelectFollowupMentions = (option) =>{
    this.setState({selectedFollowupUsers:[...this.state.selectedFollowupUsers,option]})

}

handleSaveOwnerName = (value) =>{

  const data = {
    owner_id:this.state.owner_id,
    owner_name:value
  }
  API.post("/update_owner_name",data)
  .then((response) =>{
   console.log("updated")
  })
  
}

handleSaveOwnerEmail  = (value) =>{
  const data = {
    owner_id:this.state.owner_id,
    owner_email:value
  }
  API.post("/update_owner_email",data)
  .then((response) =>{
   console.log("updated")
  })
}

handleClearFilter = () =>{
  this.setState({
    property_type_filter:[],
    community_filter:"",
    sub_community_filter:"",
    property_filter:"",
    name_value_id: 1,
    name_value: "",
    phone_value_id: 1,
    phone_value: "",
    bed_rooms_value_id: 1,
    bed_rooms_value: "",
    agent_value_id: 1,
    agent_value: "",
    stage_value: [],
    contact_types_value: [],
    furnished_types_value: [],
    price_first_value_id: 1,
    price_first_value: "",
    price_min: 0,
    price_max: 0,
    date_morethan_option: true,
    date_lessthan_option: false,
    date_value: "",
    date_value_id: 1,
    inrange: false
  })
  const page = 1;
    const perPage = 10;
    const data = {
      property_type_filter:[],
      community_filter:"",
      sub_community_filter:"",
      property_filter:"",
      name_value_id: 1,
      name_value: "",
      phone_value_id: 1,
      phone_value: "",
      bed_rooms_value_id: 1,
      bed_rooms_value: "",
      agent_value_id: 1,
      agent_value: "",
      stage_value: [],
      contact_types_value: [],
      furnished_types_value: [],
      price_first_value_id: 1,
      price_first_value: "",
      price_min: 0,
      price_max: 0,
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,
      inrange: false

    }

    this.fetchData(page,perPage,data)
}

isValidHttpUrl = (string) =>{
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

getCurrentTime = (date) => {
  if (date != null && date != "" && date != undefined){
    let newDate = new Date(date);
    newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
    return newDate.toISOString();
  }
}

  render() {

    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let role_name = userdata.auth.user.role_name;
    const user_id = userdata.auth.user.id
    

   
     const show = false
    const isUserFound = this.state.collaborators.some(element => {
      if (element.user_id === user_id) {
        return true
      }else{
        return false
      }
    });

    

    const columnsCollaborator = [
      {
        title: 'User',
        dataIndex: 'User',
        key: 'user',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Added By',
        dataIndex: 'Added By',
        key: 'addedBy',
      },
      {
        title: 'Added Date',
        dataIndex: 'Added Date',  
        key: 'addedDate',
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'Action',
        render: () => (
          <>
            <DeleteFilled style={{fontSize:'1rem'}} />
          </>
        ),
      },
    ];
    const dataCollaborator = [
      {
        key: '1',
        name: 'lead Pool',
        age: 32,
        address: '12/12/2022',
      },
      // {
      //   key: '2',
      //   name: 'Jim Green',
      //   age: 42,
      //   address: '12/12/2022',
      // },
      // {
      //   key: '3',
      //   name: 'Joe Black',
      //   age: 32,
      //   address: '12/12/2022',
      // },
    ];

   

 
    const logsitems = [
      {
        key: '1',
        label: `all (10)`,

      },
      {
        key: '2',
        label: <PhoneOutlined />,
      },
      {
        key: '3',
        label: <WhatsAppOutlined />,
      },
      {
        key: '4',
        label: <MailOutlined />,
      },

    ];

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      // setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      // selectedRowKeys,
      onChange: onSelectChange,

    };

    const columns = [

      {
        title: 'Name',
        //dataIndex: 'fullname',
        render: (text, record) => (
          <>
          {record.fullname == " " || record.fullname == "" || record.fullname == null  ?<Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}><SearchOutlined /></Link>:<Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname}</Link>}</>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
        sorter: (a, b) => a.fullname.length - b.fullname.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Name
            <hr />
            <Select
              style={{
                width: '100%',
              }}
             
              placeholder="Please select"
             
              onChange={this.handleNameValue_id}
              options={this.state.name_filter_options}
            />
            <div className='mt-2'>
              <input type="text" className="form-control"  value={this.state.name_value} onChange={this.handleName} placeholder="filter..." />
            </div>




          </div>
        ),
      },
      {
        title: 'Lead Stage',
        // dataIndex: 'lead_stage',
        render: (text, record) => (
          <>
            {record.lead_stage == 1 ? <span className="uncontacted pstat">Uncontacted</span> : null}
            {record.lead_stage == 2 ? <span className="working pstat">Working</span> : null}
            {record.lead_stage == 3 ? <span className="hot pstat">Hot</span> : null}
            {record.lead_stage == 4 ? <span className="followup pstat">Follow Up</span> : null}
            {record.lead_stage == 5 ?  <span className="dead pstat">Dead</span> : null}
          </>
        ),
        sorter: (a, b) => {
          if (a && a.lead_stage && a.lead_stage.length && b && b.lead_stage && b.lead_stage.length) {
            return a.lead_stage.length - b.lead_stage.length;
          } else if (a && a.lead_stage && a.lead_stage.length) {
            return -1;
          } else if (b && b.lead_stage && b.lead_stage.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Stages
            <hr />

            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              value={this.state.stage_value}
              placeholder="Please select"
              onChange={this.handleStages}
              options={this.state.stages}
            />


          </div>
        ),

      },
      {
        title: 'Phone',
        dataIndex: 'mobile',

        sorter: (a, b) => a.mobile.length - b.mobile.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Phone number
            <hr />
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onChange={this.handlePhoneValue_id}
              options={this.state.phone_filter_options}
            />
          
            <div className='mt-2'>
              <input type="text" value={this.state.phone_value} className="form-control" onChange={this.handlePhone} placeholder="filter..." />
            </div>




          </div>
        ),
      },
      {
        title: 'Client',
        dataIndex: 'contact_type',
        sorter: (a, b) => {
          if (a && a.contact_type && a.contact_type.length && b && b.contact_type && b.contact_type.length) {
            return a.contact_type.length - b.contact_type.length;
          } else if (a && a.contact_type && a.contact_type.length) {
            return -1;
          } else if (b && b.contact_type && b.contact_type.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Contact type
            <hr />

            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              value={this.state.contact_types_value}
              placeholder="Please select"
              onChange={this.handleContactType}
              options={this.state.contact_types}
            />


          </div>
        ),

      },
      {
        title: ' Community',
                  dataIndex: 'community',
                  sorter: (a, b) => a.community.length - b.community.length,
                  filterDropdown: (
                    <div style={{ padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                       Community
                        <Select  
                        allowClear
                        showSearch       
                       style={{
                       width: '100%',
                        }}
                       value={this.state.community_filter}
                        placeholder="Search"
                        onChange={this.handleCommunityFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        options={this.state.communities}
                        />
        
        
                        </div>
                  ),
      },
      {
        title: ' Sub Community',
                  dataIndex: 'subcommunity',
                  sorter: (a, b) => a.subcommunity.length - b.subcommunity.length,
                  filterDropdown: (
                    <div style={{ padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                       Sub Community
                        <Select  
                        showSearch   
                        allowClear    
                       style={{
                       width: '100%',
                        }}
                        value={this.state.sub_community_filter}
                       
                        placeholder="Search"
                        onChange={this.handleSubCommunityFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        options={this.state.sub_communities}
                        />
        
        
                        </div>
                  ),
      },
      {
        title: ' Property',
                  dataIndex: 'property',
                  sorter: (a, b) => a.property.length - b.property.length,
                  filterDropdown: (
                    <div style={{ padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                       Property
                        <Select  
                        showSearch   
                        allowClear    
                       style={{
                       width: '100%',
                        }}

                        value={this.state.property_filter}
                       
                        placeholder="Search"
                        onChange={this.handlePropertyFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        options={this.state.properties}
                        />
        
        
                        </div>
                  ),
      },


     



      {
        title: 'Price',
        dataIndex: 'price',
        sorter: (a, b) => {
          if (a && a.price && a.price.length && b && b.price && b.price.length) {
            return a.price.length - b.price.length;
          } else if (a && a.price && a.price.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.price && b.price.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        render: (text, record) => {
          if(!isNaN(parseInt(text))){
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'
          }else{
            return null;
          }
        },
        //a.price.length > 0 - b.price.length > 0,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Price
            <hr />


            <Select

              style={{
                width: '100%',
              }}

              placeholder="Please select"
              onChange={this.handlePriceFirstValue_id}
              options={this.state.price_options}
            />

            {/* <select class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select> */}
            <div className='mt-2'>
              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input type="text" value={this.state.price_min} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                <input type="text" value={this.state.price_max} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
              </div> : <input type="text" value={this.state.price_first_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
            </div>




          </div>
        ),
      },
      {
        title: 'Created',
        dataIndex: 'added_date',
        render: (text, record) => (
          <Moment fromNow>
            {record.parent_lead_date}
          </Moment>
        ),
        sorter: (a, b) => {
          if (a && a.added_date && a.added_date.length && b && b.added_date && b.added_date.length) {
            return a.added_date.length - b.added_date.length;
          } else if (a && a.added_date && a.added_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.added_date && b.added_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                Less than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                More than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },
      {
        title: 'Beds',
        dataIndex: 'bedroom',

        sorter: (a, b) => a.bedroom.length - b.bedroom.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Bedrooms
            <hr />

            <Select

              style={{
                width: '100%',
              }}

              placeholder="Please select"
              onChange={this.handleBedRooms_id}
              options={this.state.bedroom_filter_options}
            />
            {/* <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleBedRooms_id}>
              
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3"> Less than</option>
              <option value="4">Greater than</option>
            </select> */}
            <div className='mt-2'>
              <input type="text" value={this.state.bed_rooms_value} className="form-control" onChange={this.handleBedRoomsValue} placeholder="filter..." />
            </div>




          </div>
        ),
      },

      {
        title: 'Agent',
        dataIndex: 'agents',

        sorter: (a, b) => a.agents.length - b.agents.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Agent
            <hr />
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onChange={this.handleAgentValue_id}
              options={this.state.agent_filter_options}
            />
            <div className='mt-2'>
              <input type="text" value={this.state.agent_value} className="form-control" onChange={this.handleAgent} placeholder="filter..." />
            </div>
          </div>
        ),
      },

      {
        title: 'FF / UF',
        // dataIndex: 'furnished',
        render: (text, record) => (
          <>
            {record.furnished == 1 ? "Furnished" : "Unfurnished"}
          </>


        ),

        sorter: (a, b) => {
          if (a && a.furnished && a.furnished.length && b && b.furnished && b.furnished.length) {
            return a.furnished.length - b.furnished.length;
          } else if (a && a.furnished && a.furnished.length) {
            return -1;
          } else if (b && b.furnished && b.furnished.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Furnished / Unfurnished
            <hr />
            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              value={this.state.furnished_types_value}
              placeholder="Please select"
              onChange={this.handleFurnishedTypes}
              options={this.state.furnished_types}
            />


          </div>
        ),

      },

      {
        title: 'Move in date',
        dataIndex: 'move_in_date',
        render: (text, record) => (
          <>
          { validator.isDate(record.move_in_date)?
            <Moment fromNow>
              {record.move_in_date}
            </Moment>:""}
            {/* {record.move_in_date != null && record.move_in_date !='' ? <Moment fromNow>
              {record.move_in_date}
            </Moment> : null} */}
          </>

        ),
        sorter: (a, b) => {
          if (a && a.move_in_date && a.move_in_date.length && b && b.move_in_date && b.move_in_date.length) {
            return a.move_in_date.length - b.move_in_date.length;
          } else if (a && a.move_in_date && a.move_in_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.move_in_date && b.move_in_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                More than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" value={this.state.date_value} className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },

      {
        title: 'Type',
        dataIndex: 'prop_type',

        sorter: (a, b) => a.prop_type.length - b.prop_type.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Property type
            <hr />
          
           
            <Select  
                        showSearch   
                        allowClear   

                       style={{
                       width: '100%',
                        }}
                        mode="multiple"
                        value={this.state.property_type_filter}
                       
                        placeholder="Search"
                        onChange={this.handlePropertyTypeFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        options={this.state.propertytypes}
                        />



          </div>
        ),
      },

      {
        title: 'Last call',
        dataIndex: 'lastcall',
        render: (text, record) => (
          <>
          { validator.isDate(record.lastcall)?
            <Moment fromNow>
              {record.lastcall}
            </Moment>:""}
          
          </>
       

        ),
        sorter: (a, b) => {
          if (a && a.lastcall && a.lastcall.length && b && b.lastcall && b.lastcall.length) {
            return a.lastcall.length - b.move_in_date.length;
          } else if (a && a.lastcall && a.lastcall.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.lastcall && b.lastcall.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                More than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },

      

      

      {
        title: 'Last match',
        dataIndex: 'added_date',
        render: (text, record) => (
          <>
          { validator.isDate(record.added_date)?
            <Moment fromNow>
              {record.added_date}
            </Moment>:""}
          
          </>
        ),
        sorter: (a, b) => {
          if (a && a.added_date && a.added_date.length && b && b.added_date && b.added_date.length) {
            return a.added_date.length - b.added_date.length;
          } else if (a && a.added_date && a.added_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.added_date && b.added_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                More than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },







    ];




    const handleTableChange = (pagination, filters) => {



      const data = {
        property_type_filter:this.state.property_type_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        name_value_id: this.state.name_value_id,
        name_value: this.state.name_value,

        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,

        bed_rooms_value_id: this.state.bed_rooms_value_id,
        bed_rooms_value: this.state.bed_rooms_value,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,

        stage_value: this.state.stage_value,
        contact_types_value: this.state.contact_types_value,
        furnished_types_value: this.state.furnished_types_value,


        price_first_value_id: this.state.price_first_value_id,
        price_first_value: this.state.price_first_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,

        date_first_value_id: this.state.date_first_value_id,
        date_and_or_option: this.state.date_and_or_option,
        date_second_value_id: this.state.date_second_value_id,
        date_first_value: this.state.date_first_value,
        date_second_value: this.state.date_second_value,

        date_morethan_option: this.state.date_morethan_option,
        date_lessthan_option: this.state.date_lessthan_option,
        date_value: this.state.date_value,
        date_value_id: this.state.date_value_id,





      }




      this.fetchData(pagination.current, pagination.pageSize, data);

    };





    return (
      <div>
        <ToastContainer />

        <Modal width={600} title="Offers" open={this.state.isOfferOpen} 
        onOk={this.handleOkOffer} onCancel={this.handleCancelOffer}
        >
              <div className="container">
          <div className='row w-100 d-flex flex-row'>
            <div className='px-0 pt-1 form-group'>
              <label>Date</label>
              <p className="form-control-disabled">{this.state.viewings.view_date}</p>
             </div>
           
         
             <div className='px-0 pt-1 form-group'>
              <label>Note</label>
              <p className="form-control-disabled">{this.state.viewings.note}</p>
            </div>
            <div className='px-0 pt-1 form-group'>
              <label>Price</label>
              <p className="form-control-disabled">{this.state.viewings.amount}</p>
            </div>
          </div>
          </div>
      </Modal>



        <Modal width={1000} title="Add Followup" open={this.state.isfollowupOpen}
          onOk={this.handleFollowupOk} onCancel={this.handleFollowupCancel}
        >
          <Divider />
          
   
            
              <label>Date / Time</label>
              <DatePicker style={{ maxWidth: 600 }} showTime onChange={this.handleFollowupDate} />
             
        
              <label >Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleFollowupNote} />
          



        </Modal>

        <Modal width={1000} title=" Followup details" open={this.state.isfollowupViewOpen}
          onOk={this.handleFollowupViewOk} onCancel={this.handleFollowupViewCancel}
        >
          <Divider />
          
   
            
              <label>Date / Time</label>
              <p>{this.state.followup_date}</p>
             
        
              <label >Note</label>
              <p>{this.state.followup_note}</p>
          



        </Modal>
        <Modal width={600} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
          <div className="container">
          <div className='row w-100 d-flex flex-row'>
            <div className='px-0 pt-1 form-group'>
              <label>Ref No</label>
              <p className="form-control-disabled">{this.state.viewings.listing_refno}</p>
            </div>
            <div className='px-0 pt-1 form-group'>
              <label>Agent Name</label>
              <p className="form-control-disabled">{this.state.viewings.fullname}</p>
            </div>
          </div>
          <div className='row w-100 mt-3 d-flex flex-row'>
            
            <div className='px-0 pt-1 form-group'>
              <label>Date</label>
              {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p className="form-group">{this.state.viewings.view_date}</p>}

            </div>
            <div className='px-0 pt-1 mt-3 form-group'>
              <label>Status</label>
              <Select
                       style={{
                        width: 160,
                      }}
                    showSearch
                    maxTagCount={"responsive"}
                    placeholder="Select beds"
                    optionFilterProp="children"
                    onChange={this.handleViewStatus}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                      { value: 'Offer', label: 'Offer'},
                      { value: "Successful", label: 'Successful'},
                      { value: "Cancelled", label: 'Cancelled'},
                      { value: "Unsuccessful", label: 'Unsuccessful'},
                      { value: "Scheduled", label: 'Scheduled'}                      
                    ]}
                />
              {/* <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
              <option value="Offer">Offer</option>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select> */}
            </div>
            {this.state.showPrice ? <div className='form-group'>
              <label>Price</label>
              <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
            </div> : null}
          </div>
          <div className='row w-100 mt-3'>
            <div className='w-100 px-0 pt-1'>
              <label>Note</label>
              <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
            

          </div>
          </div>
        </Modal>

        {/* viewings modal */}
        {/* <Modal width={1000} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
          <Divider />
          <div className='row'>
            <div className='col-md-4'>
              <label>REf NO</label>
              <p>{this.state.viewings.listing_refno}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <label>Agent Name</label>
              <p>{this.state.viewings.fullname}</p>
            </div>
            <div className='col-md-4'>
              <label>Date</label>
              {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p>{this.state.viewings.view_date}</p>}

            </div>
            <div className='col-md-4'>
              <label>Status</label>
              <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-8'>
              <label>Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
            {this.state.showPrice ? <div className='col-md-4'>
              <label>Price</label>
              <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
            </div> : null}

          </div>
        </Modal> */}
        <Modal width={1000} title="Add Viewings" open={this.state.isviewingOpen}
          onOk={this.handleViewingOk} onCancel={this.handleViewingCancel}
        >
          <Divider />

          <div className='row w-100'>
  <div className='col-md-12 px-0 pt-1'>
    <div className="form-group">
      <label>Ref No</label>
      
      <Select
                style={{
                  width: "100%",
                }}
                mode={"multiple"}
                value={this.state.ref_def_nums}
                showSearch
                allowClear
                placeholder="Select Refs no"
                maxTagCount= 'responsive'
                optionFilterProp="children"
                onChange={this.handleRefsno}
                onSearch={this.onSearchRefNo}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.ref_nums}
              />
    </div>
    <div className="row orgroup">
      <div className="col-12 d-flex flex-row">
        <hr/>
        <p className="mb-0">Or</p>
        <hr/>
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <div className="form-group">
            <label className="">Unit no:</label>
            <Select
                style={{
                  width: "100%",
                }}
                
                showSearch
                allowClear
                placeholder="Select Unit no"
                optionFilterProp="children"
                onChange={this.handleUnitno}
                onSearch={this.handleSearchUintNo}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.unit_nums}
              />
           
        </div>                
      </div>
      <div className="col-6">
        <div className="form-group">
            <label className="">Agent:</label>
            <Select
                style={{
                  width: "100%",
                }}

                showSearch
                placeholder="Select Agent"
                optionFilterProp="children"
                onChange={this.handleViewingAgent}

                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.agents}
              />
        </div>                
      </div>
    </div>
    <div className="row">
      <div className="col-6">
                  <div className="form-group">
                    <label className="">Community:</label>
                    <Select
                      style={{
                        width: '100%',
                      }}

                      showSearch
                      placeholder="Search"
                      optionFilterProp="children"
                      // onChange={this.handleCommunityMatch}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.communities}
                    />
                  </div>                
      </div>
      <div className="col-6">
        <div className="form-group">
            <label className="">Sub Community:</label>
            <Select
                            style={{
                              width: "100%",
                            }}
                            // mode={"multiple"}

                            showSearch
                            maxTagCount={"responsive"}
                            placeholder="Search"
                            optionFilterProp="children"
                            //onChange={this.handleSubCommunityMatch}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.sub_communities}
                          />
        </div>                
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <div className="form-group">
            <label className="">Property:</label>
            <select name="property" id="property" className="form-control">
              <option value=""></option>
              <option selected="" value="386">Burj Views A</option>
              <option value="237">Burj Views B</option>
              <option value="283">Burj Views C</option>
              <option value="274">Burj Views Podium</option>
            </select>
        </div>                
      </div>
      <div className="col-6">
        <div className="form-group">
            <label className="">Date / Time:</label>
            <DatePicker format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} showTime onChange={this.handleViewingDate} />
        </div>                
      </div>
    </div>
    <div className="row">
        <div className="col-12">
            <label className="">Notes:</label>
            <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
        </div>
    </div>


    <div className="row">
        <div className="col-12">
        <table class="table">
          
  <thead>
    <tr>
      <th scope="col">Ref no</th>
      <th scope="col">Unit no</th>
      <th scope="col">Community</th>
      <th scope="col">Subcommunity</th>
      <th scope="col">Property</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    {this.state.viewings_listings.map((viewing_listing)=>(
  <tr>
  <th >{viewing_listing.refno}</th>
  <th >{viewing_listing.unitno}</th>
  <td>{viewing_listing.community}</td>
  <td>{viewing_listing.subcommunity}</td>
  <td>{viewing_listing.property}</td>
  <td>
  <CloseOutlined className='text-danger' onClick={this.handleRemoveList.bind(this,viewing_listing.refno)} />
  </td>
</tr>
    ))}
  
  
  </tbody>
</table>
        </div>
    </div>


  </div>
</div>
          {/* <div className='row'>
            <div className='col-md-8'>
              <label>Ref No:</label>
              <Select
                style={{
                  width: "100%",
                }}
                mode={"multiple"}
                showSearch
                placeholder="Select Refs no"
                optionFilterProp="children"
                onChange={this.handleRefsno}
                onSearch={this.onSearchRefNo}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.ref_nums}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-4'>
              <label>Agent</label>
              <Select
                style={{
                  width: "100%",
                }}

                showSearch
                placeholder="Select Agent"
                optionFilterProp="children"
                onChange={this.handleViewingAgent}

                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.agents}
              />
            </div>
            <div className='col-md-4'>
              <label>Date / Time</label>
              <DatePicker style={{ width: '100%' }} showTime onChange={this.handleViewingDate} />
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-10'>
              <label>Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
          </div>
 */}


        </Modal>
        <Drawer
          title={`Lead details`}
          placement="left"
          // size={"large"}
          width={"98%"}
          onClose={this.handleCloseDrawer}
          open={this.state.open}
        // extra={
        //   <Space>
        //     <Button type='primary' danger ghost onClick={this.handleCloseDrawer} icon={<CloseOutlined /> }>Cancel</Button>
        //     <Button type="primary" onClick={this.handleSaveData}>
        //       Save changes
        //     </Button>
        //   </Space>
        // }
        >

          <div className="cont-wrap container">
            <div className="row">
              <div className="col-12 d-flex flex-row toptitle">
                <button className="btn btn-primary" disabled={this.state.isPreviousLoading?true:false} onClick={this.handlePreviousRecord}>{this.state.isPreviousLoading?<BeatLoader color={"blue"} loading={true}  size={30} />:"Previous"}</button>
                <div className="d-flex w-100 ps-3"><h2 className="page-title fs-5 font-bold"><i className="bi bi-pencil-fill me-2" />Leads Details - {this.state.lead_details.refno}</h2></div>
                <button className="btn btn-primary" disabled={this.state.isNextLoading?true:false}  onClick={this.handleNextRecord}>{this.state.isNextLoading?<BeatLoader color={"blue"} loading={true}  size={30} />:"Next"}</button>
              </div>
            </div>
            <div className="row column-wrap mt-3">
              <div className="col-12 col-md-3">

                
                <div className="bg-white border-top ownersdetails">
                    <div className="d-flex flex-row justify-content-between">
                    <h3>Contact Details</h3>
                    <button type="button" className="btn btn-primary requestchange">Change Details</button>
                  </div>
                  <div className="d-flex flex-column formfieldset mt-3">
                    <div className="d-flex flex-column form-group">
                      <label className="control-labels mr-4">Name</label>
                      <EdiText
           
                      value={this.state.lead_details.owner}
                      type="text"
                      onSave={this.handleSaveOwnerName}
                      
                    />
                   </div>
                    <div className="d-flex flex-column form-group">
                      <label className="control-labels mr-4">Phone </label>
                      <p class="form-control-disabled">{this.state.owner_mobile}</p>
                    </div>
                    <div className="d-flex flex-column form-group">
                      <label className="control-labels mr-4"> Email </label>
                      <EdiText
           
                      value={this.state.lead_details.email}
                      type="text"
                      onSave={this.handleSaveOwnerEmail}
                      
                    />
                    </div>
                    </div>
                 


                    
                    <div className="bg-white  ownersdetails mt-3">
                    <div className="d-flex flex-column form-group mb-0">
                      <label className="control-labels mr-4">Other Enquires</label>
                      <div className="col-12 d-flex flex-column border-wrap listtable-wrap">

                        {this.state.owner_other_properties.map((owner_other_property) => {
                                   var pStatus = 'Draft'
                                   switch (owner_other_property.status) {
                                     case 'D':
                                       pStatus = 'Draft'
                                       break;
                                     case 'UC':
                                       pStatus = 'Confirmed Owner'
                                       break;
                                     case 'N':
                                       pStatus = 'Unpublish'
                                       break;
                                     case 'OM':
                                       pStatus = 'Off-Market'
                                       break;
                                     case 'CTM':
                                       pStatus = 'Coming to market'
                                       break;
                                     case 'Y':
                                       pStatus = 'Published'
                                       break;
                                     case 'U':
                                       pStatus = 'Action'
                                       break;
                   
                                     case 'SEO':
                                       pStatus = 'SEO'
                                       break;
                                     case 'L':
                                       pStatus = 'Unlisted'
                                       break;
                                     case 'S':
                                       pStatus = 'Sold'
                                       break;
                                     case 'T':
                                       pStatus = 'Let'
                                       break;
                                     case 'P':
                                       pStatus = 'Review'
                                       break;
                                     case 'F':
                                       pStatus = 'Feeds'
                                       break;
                                     default:                  
                                       pStatus = 'Draft'
                                       break;
                                   }
                          return (
                            <a href className="d-flex flex-row p-2 w-100">
                              <div className="col-8 listtable">
                                <p className="propfor">{owner_other_property.property_for}</p>
                                <p className="refno">{owner_other_property.refno}</p>
                                <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                <p className="community">{owner_other_property.community}</p>
                              </div>
                              <div className="col-4">
                                <div className="d-flex flex-row stat publishstat">
                                  <i className="bi bi-circle-fill" />
                                  <span>{pStatus}</span>
                                </div>
                                <div className="agentprop">
                                  <p className="name">{owner_other_property.agent}</p>
                                </div>
                              </div>
                            </a>
                          )
                        })}
       
                      </div>
                    </div>
                  </div>
                </div>
                {/* FORMS */}
                <div className="bg-white border-top offers p-4 mt-3">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4 mb-3">Requirements</h3>
                  </div>
                  <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
                    <div className="form-group"> {/* MULTISELECT */}
                      <label className="control-labels ">Lead Stage</label>
                      <select onChange={this.handleRequirementStage} value={this.state.requirement_lead_stage} name="lead_stage" className="form-control multiselects" id="lead_stage">
                        <option value>None</option>
                        <option  value={1}>Uncontacted</option>
                        <option value={2}>Working</option>
                        <option value={3}>Hot</option>
                        <option value={4}>Follow Up</option>
                        <option value={5}>Dead</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Verifier</label>
                      <select onChange={this.handleRequirementVerifyby} name="lead_verifier" className="form-control multiselects" id="lead_verifier">
                        <option value>None</option>
                        <option value={375}>Danielle Robinson</option>
                        <option value={427}>Christalline Ybanez</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Agent Name</label>
                      {(role_name == "Admin" || role_name == "Super Admin") && <Select
                          style={{
                            width: '100%',
                          }}
                          value={this.state.requirement_agent_id}
                          showSearch
                          placeholder="Search"
                          optionFilterProp="children"
                          onChange={this.handleRequirementAgent}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={this.state.agents}
                      />}

                      {(role_name != "Admin" && role_name != "Super Admin") && <Select
                          style={{
                            width: '100%',
                          }}
                          value={this.state.requirement_agent_id}
                          showSearch
                          disabled
                          placeholder="Search"
                          optionFilterProp="children"
                          onChange={this.handleRequirementAgent}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={this.state.agents}
                      />}
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Move in Date</label>
                      <input value={this.state.lead_details.mov_in} onChange={this.handleRequirementMoveInDate} type="datetime-local" id="move_in_date" className="form-control" defaultValue />
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Budget</label>
                      <div className="d-flex flex-row" style={{ gap: '0.6rem' }}>
                        <input value={this.state.requirement_min_budget} type="text" id="budgetfrom" className="form-control" onChange={this.handleRequirementMin} />
                        <span style={{ padding: '0 3px', lineHeight: 36, height: 36 }}>-</span>
                        <input value={this.state.requirement_max_budget} type="text" id="budgetto" className="form-control" onChange={this.handleRequirementMax} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Bedrooms</label>
                      <select value={this.state.requirement_bedroom} onChange={this.handleRequirementBeds} id="bedrooms" name="bedrooms" className="form-control select2 select2-offscreen" tabIndex={-1} title>
                        <option value>Select</option>
                        <option value="Studio">Studio</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5} >5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Community</label>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        defaultValue={this.state.requirement_community}
                        showSearch
                        placeholder="Search"
                        onChange={this.handleRequirementCommunity}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.communities}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Sub Community</label>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        // mode={"multiple"}
                        value={this.state.requirement_sub_community}
                        showSearch
                        maxTagCount={"responsive"}
                        placeholder="Search"
                        optionFilterProp="children"
                        onChange={this.handleRequirementSubCommunity}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.requirement_sub_communities}
                      />

                    </div>
                  </div>
                  <button onClick={this.handleRequirementSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button>
                </div>{/* END FORMS*/}
              </div>
              <div className="col-12 col-md-6 timeline-wrap">
                <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                  <h3>Timeline</h3>
                  {/* ADD NOTES */}
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-call-tab" data-bs-toggle="tab" data-bs-target="#nav-call" type="button" role="tab" aria-controls="nav-call" aria-selected="true">Call</button>
                    <button className="nav-link" id="nav-notes-tab" data-bs-toggle="tab" data-bs-target="#nav-notes" type="button" role="tab" aria-controls="nav-notes" aria-selected="false">Notes</button>
                    <button className="nav-link" id="nav-whatsapp-tab" data-bs-toggle="tab" data-bs-target="#nav-whatsapp" type="button" role="tab" aria-controls="nav-whatsapp" aria-selected="false">WhatsApp</button>
                    <button className="nav-link" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email" type="button" role="tab" aria-controls="nav-email" aria-selected="false">Email</button>
                    <button className="nav-link" id="nav-offer-tab" data-bs-toggle="tab" data-bs-target="#nav-offer" type="button" role="tab" aria-controls="nav-offer" aria-selected="false">Offer</button>
                    <button className="nav-link" id="nav-viewing-tab" data-bs-toggle="tab" data-bs-target="#nav-viewing" type="button" role="tab" aria-controls="nav-viewing" aria-selected="false">Viewing</button>
                    <button className="nav-link" id="nav-followup-tab" data-bs-toggle="tab" data-bs-target="#nav-followup" type="button" role="tab" aria-controls="nav-followup" aria-selected="false">Follow Up</button>
                  </div>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-call" role="tabpanel" aria-labelledby="nav-call-tab" tabIndex={0}>
                    <Mentions
                        style={{
                          width: '100%',
                        }}
                        value={this.state.call_log}
                        onChange={this.onChangeCallNote}
                        onSelect={this.onSelectCallMentions}
                       
                        options={this.state.userClients}
                      />
                      {/* <textarea onChange={this.handleCallLog} id="callLogsText" rows={4} placeholder="Add call log.." className="font-14 mention" /> */}
                      <button id="calllogbtn" onClick={this.handleCallSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" /> Add call log</button>
                    </div>
                    <div className="tab-pane fade" id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab" tabIndex={0}>
                    <Mentions
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={this.state.note_log}
                                                onChange={this.onChangeNoteNote}
                                                onSelect={this.onSelectNoteMentions}
                                                options={this.state.userClients}
                                            />
                      {/* <textarea id="logsText" onChange={this.handleNoteLog} rows={4} placeholder="Add note.." className="font-14 mentionnote" defaultValue={""} /> */}
                      <button id="logsbtn" onClick={this.handleNoteSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add note</button>
                    </div>
                    <div className="tab-pane fade" id="nav-whatsapp" role="tabpanel" aria-labelledby="nav-whatsapp-tab" tabIndex={0}>
                    <Mentions
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={this.state.whatsapp_log}
                                                onChange={this.onChangeWhatsappNote}
                                                onSelect={this.onSelectWhatsappMentions}
                                                options={this.state.userClients}
                                            />
                      {/* <textarea onChange={this.handleWhatsappLog} id="whatsappLogsText" rows={4} placeholder="Add WhatsApp notes.." className="font-14 mentionwa" defaultValue={""} /> */}
                      <button onClick={this.handleWhatsappSubmit} id="whatsapplogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add WhatsApp Note</button>
                    </div>
                    <div className="tab-pane fade" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab" tabIndex={0}>
                      <input type="text" placeholder="Subject" className="form-control font-14 mb-2"></input>
                   
                      <Mentions
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={this.state.email_log}
                                                onChange={this.onChangeEmailNote}
                                                onSelect={this.onSelectEmailMentions}
                                                options={this.state.userClients}
                                            />   {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add email log.." className="font-14 mentionemail" defaultValue={""} /> */}
                      <button onClick={this.handleEmailSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add email</button>
                    </div>
                    <div className="tab-pane fade" id="nav-offer" role="tabpanel" aria-labelledby="nav-offer-tab" tabIndex={0}>
                    <Mentions
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={this.state.offer_log}
                                                onChange={this.onChangeOfferNote}
                                                onSelect={this.onSelectOfferMentions}
                                                options={this.state.userClients}
                                            />
                      {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add offer log.." className="font-14 mentionemail" defaultValue={""} /> */}
                      <button onClick={this.handleOfferSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Offer</button>
                    </div>
                    <div className="tab-pane fade" id="nav-viewing" role="tabpanel" aria-labelledby="nav-viewing-tab" tabIndex={0}>
                    <Mentions
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={this.state.viewing_log}
                                                onChange={this.onChangeViewingNote}
                                                onSelect={this.onSelectViewingMentions}
                                                options={this.state.userClients}
                                            />
                      {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add viewing log.." className="font-14 mentionemail" defaultValue={""} /> */}
                      <button onClick={this.handleViewingSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Viewing</button>
                    </div>
                    <div className="tab-pane fade" id="nav-followup" role="tabpanel" aria-labelledby="nav-followup-tab" tabIndex={0}>
                     
                    <Mentions
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={this.state.followup_log}
                                                onChange={this.onChangeFollowupNote}
                                                onSelect={this.onSelectFollowupMentions}
                                                options={this.state.userClients}
                                            /> {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add followup log.." className="font-14 mentionemail" defaultValue={""} /> */}
                      <button onClick={this.handleFollowupSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Follow Up</button>
                    </div>
                  </div>
                  {/* TIMELINE */}
                  <div className="timeline border-wrap">
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">All</button>
                      <button className="nav-link" id="nav-notewrap-tab" data-bs-toggle="tab" data-bs-target="#nav-notewrap" type="button" role="tab" aria-controls="nav-notewrap" aria-selected="false">Notes</button>
                      <button className="nav-link" id="nav-calllist-tab" data-bs-toggle="tab" data-bs-target="#nav-calllist" type="button" role="tab" aria-controls="nav-calllist" aria-selected="false">Calls</button>
                      <button className="nav-link" id="nav-wa-tab" data-bs-toggle="tab" data-bs-target="#nav-wa" type="button" role="tab" aria-controls="nav-wa" aria-selected="false">WhatsApp</button>
                      <button className="nav-link" id="nav-mail-tab" data-bs-toggle="tab" data-bs-target="#nav-mail" type="button" role="tab" aria-controls="nav-mail" aria-selected="false">Email</button>
                      <button className="nav-link" id="nav-offers-tab" data-bs-toggle="tab" data-bs-target="#nav-offers" type="button" role="tab" aria-controls="nav-offers" aria-selected="false">Offers</button>
                      <button className="nav-link" id="nav-view-tab" data-bs-toggle="tab" data-bs-target="#nav-view" type="button" role="tab" aria-controls="nav-view" aria-selected="false">Viewings</button>
                      <button className="nav-link" id="nav-followups-tab" data-bs-toggle="tab" data-bs-target="#nav-followups" type="button" role="tab" aria-controls="nav-followups" aria-selected="false">Follow Up</button>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                      {/* ALL */}
                      <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabIndex={0}>
                        <div className="timeline-list">
                        {this.state.all.map((al) => {
                                  return (
                                    <div className={"timegroup " + al.note_type}>
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          {al.note_type == "phone" ? <i className="bi bi-telephone-fill"></i> : null}
                                          {al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : null}
                                          {al.note_type == "whatsapp" ? <i className="bi bi-whatsapp" /> : null}
                                          {al.note_type == "note" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                          {al.note_type == "viewing" ? <i className="bi bi-eye-fill"></i> : null}
                                          {al.note_type == "offer" ? <i className="bi bi-tag-fill"></i> : null}
                                          {al.note_type == "followup" ? <i className="bi bi-calendar"></i> : null}


                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-row">
                                          <div className="agentdetails">
                                            <div className="d-flex flex-row justify-content-between">
                                            <div className='d-flex flex-row align-items-center' style={{gap:'0.3rem'}}>
                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={ al.profile_photo== null || al.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg":
                                                                                        this.isValidHttpUrl(al.profile_photo)? al.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        al.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                              <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                {al.note_type == "phone" ? " logged a call" : null}
                                                {al.note_type == "email" ? " sent an email" : null}
                                                {al.note_type == "whatsapp" ? "" : null}
                                                {al.note_type == "note" ? " added a note" : null}
                                                {al.note_type == "viewing" ? " added a viewing" : null}
                                                {al.note_type == "offer" ? " added an offer" : null}
                                                {al.note_type == "followup" ? " scheduled a follow up" : null}
                                              </p>
                                              </div>
                                              <p className="atime">
                                                <Moment fromNow>
                                                  {this.getCurrentTime(al.added_date)}
                                                </Moment></p>
                                            </div>
                                            <div className="adesc">
                                              {parse('<div>' + al.note + '</div>')}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/* NOTES */}
                            <div className="tab-pane fade" id="nav-notewrap" role="tabpanel" aria-labelledby="nav-notewrap-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.notes.map((note) => {
                                  return (
                                    <div className="timegroup note">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-pin-angle-fill" />
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                          <div className='d-flex flex-row align-items-center' style={{gap:'0.3rem'}}>
                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={note.profile_photo== null || note.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg": 
                                                                                        this.isValidHttpUrl(note.profile_photo)? note.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        note.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{note.by_user_name}</span>
                                                {/* <span> added a note</span> */}
                                              </p>
                                              <p className="atime">
                                              <Moment fromNow>
                                                {this.getCurrentTime(note.added_date)}
                                              </Moment>
                                            </p>
                                            </div>
                                            </div>
                                            
                                          </div>
                                          <div className="adesc">

                                            {parse('<div className="font-12">' + note.note + '</div>')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/* CALLS */}
                            <div className="tab-pane fade" id="nav-calllist" role="tabpanel" aria-labelledby="nav-calllist-tab" tabIndex={0}>
                              <div className="timeline-list">

                                {this.state.call_notes.map((call_note) => {
                                  return (
                                    <div className="timegroup phone">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-telephone-fill" />
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                          <div className='d-flex flex-row align-items-center' style={{gap:'0.3rem'}}>
                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={ call_note.profile_photo== null || call_note.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg":
                                                                                        this.isValidHttpUrl(call_note.profile_photo)? call_note.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        call_note.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                            <div className="calldetails">
                                              <p className="awriter">
                                                <span className="font-bold">{call_note.by_user_name}</span>
                                                {/* <span> logged a call.</span> */}
                                              </p>
                                              <p className="atime">
                                              <Moment fromNow>
                                                {this.getCurrentTime(call_note.added_date)}
                                              </Moment></p>
                                            </div>
                                            </div>
                                            
                                          </div>
                                          <div className="adesc">
                                            {parse('<div className="font-12">' + call_note.note + '</div>')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/* WHATSAPP */}
                            <div className="tab-pane fade" id="nav-wa" role="tabpanel" aria-labelledby="nav-wa-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.whatsapp_notes.map((whatsapp_note) => {
                                  return (
                                    <div className="timegroup whatsapp">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-whatsapp" />
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={ whatsapp_note.profile_photo== null || whatsapp_note.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg":
                                                                                        this.isValidHttpUrl(whatsapp_note.profile_photo)? whatsapp_note.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        whatsapp_note.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{whatsapp_note.by_user_name}</span>
                                                {/* <span> sent a whatsapp message.</span> */}
                                              </p>
                                               <p className="atime">
                                              <Moment fromNow>
                                                {this.getCurrentTime(whatsapp_note.added_date)}
                                              </Moment></p>
                                            </div>
                                           
                                          </div>
                                          <div className="adesc">
                                            {parse('<div className="font-12">' + whatsapp_note.note + '</div>')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/** EMAIL */}
                            <div className="tab-pane fade" id="nav-mail" role="tabpanel" aria-labelledby="nav-mail-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.email_notes.map((email_note) => {
                                  return (
                                    <div className="timegroup email">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-envelope-fill" />
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={ email_note.profile_photo== null || email_note.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg":
                                                                                        this.isValidHttpUrl(email_note.profile_photo)? email_note.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        email_note.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                {/* <span> sent an email.</span> */}
                                              </p>
                                              <p className="atime">
                                              <Moment fromNow>
                                                {this.getCurrentTime(email_note.added_date)}
                                              </Moment></p>
                                            </div>
                                            
                                          </div>
                                          <div className="adesc">
                                            {parse('<div className="font-12">' + email_note.note + '</div>')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/** OFFER */}
                            <div className="tab-pane fade" id="nav-offers" role="tabpanel" aria-labelledby="nav-offers-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.offer_notes.map((offer_note) => {
                                  return (
                                    <div className="timegroup offer">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-tag-fill"></i>
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={ offer_note.profile_photo== null || offer_note.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg":
                                                                                        this.isValidHttpUrl(offer_note.profile_photo)? offer_note.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        offer_note.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{offer_note.by_user_name}</span>
                                                <span> added an offer.</span>
                                              </p>
                                              <p className="atime">
                                                {this.getCurrentTime(offer_note.added_date)}
                                              </p>
                                            </div>
                                            
                                          </div>
                                          <div className="adesc">
                                            <div className="col-12">
                                              <p className="address font-12">{offer_note.note}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/** VIEWING */}
                            <div className="tab-pane fade" id="nav-view" role="tabpanel" aria-labelledby="nav-view-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.viewing_notes.map((viewing_note) => {
                                  return (
                                    <div className="timegroup viewing">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-eye-fill"></i>
                                        </div>
                                      </div>
                                      <div className="col-11">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">

                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={ viewing_note.profile_photo== null || viewing_note.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg":
                                                                                        this.isValidHttpUrl(viewing_note.profile_photo)? viewing_note.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        viewing_note.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{viewing_note.by_user_name}</span>
                                                <span className='font-12'> added a viewing.</span>
                                        </p>
                                        <p className="atime">
                                          {this.getCurrentTime(viewing_note.time)}
                                        </p>
                                      </div>
                                      
                                    </div>
                                    <div className="adesc">
                                      <div class="col-12">
                                        <p class="font-12">{viewing_note.note} </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-followups" role="tabpanel" aria-labelledby="nav-followups-tab" tabIndex={0}>
                        <div className="timeline-list">
                          {this.state.followup_notes.map((followup_note) => {
                            return (
                                <div className="timegroup followup">
                                  <div className="col-1">
                                    <div className="iconwrap">
                                      <i className="bi bi-calendar"></i>
                                    </div>
                                  </div>
                                  <div className="col-11">
                                    <div className="col-12 d-flex flex-column">
                                      <div className="agentdetails">
                                                                            <Avatar
                                                                                className='d-flex'
                                                                                size={40}
                                                                                style={{
                                                                                    backgroundColor: "#87d068",
                                                                                    marginRight: "0",
                                                                                }}
                                                                                icon={
                                                                                    <img
                                                                                    src={ followup_note.profile_photo== null || followup_note.profile_photo == ""? baseurlImg+"lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg":
                                                                                        this.isValidHttpUrl(followup_note.profile_photo)? followup_note.profile_photo:
                                                                                        baseurlImg +
                                                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                                                        followup_note.profile_photo
                                                                                    }
                                                                                    style={{
                                                                                        width: "55x",
                                                                                        height: "55px",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                    />
                                                                                }
                                                                                />
                                        <div className="calldetails">
                                          <p className="awriter font-bold">
                                            <span className="font-bold">{followup_note.user_name}</span>
                                            <span> scheduled a follow up.</span>
                                          </p>
                                        </div>
                                        <p className="atime">
                                          {this.getCurrentTime(followup_note.date)}
                                        </p>
                                      </div>
                                      <div className="adesc">
                                        <div className="col-12">
                                          <p className="name font-bold">Followup Note</p>
                                          <p className="price">{followup_note.notes}</p></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                  <h3>Match Details</h3>
                  <div className="d-flex flex-column pt-3">
                  <div className="d-flex flex-column matchfilter">
          <div className="col-12 px-0">
            <div className="form-group">
              <label for="community" className="mb-0">Community:</label>
              <Select id="community"
                       style={{
                        width: 160,
                      }}
                      
                    showSearch
                    
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleCommunityMatch}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.communities}
                />
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="form-group">
                <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub-Community:</label>
                <Select id="propsubcommunity"
                       style={{
                        width: 160,
                      }}
                     // mode={"multiple"}
                      
                    showSearch
                    maxTagCount={"responsive"}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleSubCommunityMatch}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.sub_communities}
                />
            </div>
          </div>
          <div class="col-12 px-0">
              <div className="form-group d-flex flex-row budget">
                  <label for="matchingBudgetMin">Budget:</label>
                  <div className="d-flex flex-row">
                    <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" />
                    <span className="dash" style={{}}>-</span>
                    <input type="text" onChange={this.handleMaxBudget}  className="currencyInput" id="matchingBudgetMax" placeholder="Max" />
                  </div>
                </div>
          </div>
          <div class="col-12 px-0">
            <div className="form-group">
                <label for="matchingBedrooms">Beds:</label>
                <select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                  <option value>Select</option>
                  <option value="Studio">Studio</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3} >3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                </select>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="form-group d-flex flex-row w-100">
              <label for="addedDateM">Added Date:</label>
              <select onChange={this.handleAddedDateMatch}  id="addedDateM" className="form-control" title tabIndex={-1}>
                      <option value>Select</option>
                      <option value={1}>Last 24 Hours</option>
                      <option value={3}>Last 3 Days</option>
                      <option value={7}>Last 7 Days</option>
                      <option value={14}>Last 14 Days</option>
                      <option value={30}>Last 1 Month</option>
                      <option value={90}>Last 3 Month</option>
                      <option value={180}>Last 6 Month</option>
                      <option value={365}>Last 1 Year</option>
              </select>
            </div>
          </div>
          <div class="col-12 px-0">
            <div className="form-group d-flex flex-row">
                <label for="matchingPfor">Property For:</label>
                <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1}>
                  <option value>Select</option>
                  <option value="rent">Rent</option>
                  <option value="sale">Sale</option>
                </select>
            </div>
          </div>
          <div class="col-12 px-0">
            <div className="form-group d-flex flex-row">
                <label for="matchingType">Type:</label>
                <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                  <option>select</option>
                  {this.state.propertytypes.map((propertytype)=>{
              return <option value={propertytype.value}>{propertytype.label}</option>
                  })}
                  
               
                </select>
              </div>
          </div>
          <div class="col-12 px-0">
            <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
          </div>

        </div>
                    {/* <div className="comment advmatch w-100">
                      <div className="showMatchClass"><span className="font-bold">Match using: </span></div>
                      <div className="form-controls">
                        <div className="col-6 form-group">
                          <label className="control-labels ">Community<em>*</em></label>
                          <Select
                            style={{
                              width: 160,
                            }}
                            value={this.state.community_from_requirement}
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={this.handleCommunityMatch}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.communities}
                          />
                         
                        </div>
                        <div className="col-6 form-group">
                          <label className="control-labels " htmlFor="propsubcommunity">Sub-Community</label>
                          <Select
                            style={{
                              width: 160,
                            }}
                            // mode={"multiple"}

                            showSearch
                            value={this.state.sub_community_from_requirement}
                            maxTagCount={"responsive"}
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={this.handleSubCommunityMatch}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.sub_communities}
                          />
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column">
                          <label>Budget</label>
                          <div className="d-flex flex-row">
                            <input type="text" value={this.state.min_from_requirement} onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" />
                            <span className="dash" style={{}}>-</span>
                            <input type="text" value={this.state.max_from_requirement}  onChange={this.handleMaxBudget} className="currencyInput" id="matchingBudgetMax" placeholder="Max" />
                          </div>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column w-100">
                          <label>Beds</label>
                          <select value={this.state.bedroom_from_requirement}  onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control select2 w-100 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value="Studio">Studio</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3} >3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="w-50 px-0 form-group d-flex flex-column">
                          <label>Property For</label>
                          <select value={this.state.property_for_from_requirement}  onChange={this.handlePropertForMatch} id="matchingPfor" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value="rent">Rent</option>
                            <option value="sale">Sale</option>
                          </select>
                        </div>
                        <div className="w-50 px-0 form-group d-flex flex-column">
                          <label>Type</label>
                          <select value={this.state.property_type_from_requirement}  onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            {this.state.propertytypes.map((propertytype) => {
                              return <option value={propertytype.id}>{propertytype.title}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column w-100">
                          <label>Added Date</label>
                          <select value={this.state.added_date_from_requirement}  onChange={this.handleAddedDateMatch}  id="addedDateM" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value={1}>Last 24 Hours</option>
                            <option value={3}>Last 3 Days</option>
                            <option value={7}>Last 7 Days</option>
                            <option value={14}>Last 14 Days</option>
                            <option value={30}>Last 1 Month</option>
                            <option value={90}>Last 3 Month</option>
                            <option value={180}>Last 6 Month</option>
                            <option value={365}>Last 1 Year</option>
                          </select>
                        </div>
                      </div>
                      <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                    </div> */}
                    <div className="matchresults">
                      <div className="d-flex flex-row justify-content-between">
                        <label className="control-labels mr-4">Match with access</label>
                      </div>
                      <div className="matchresult">
                        {this.state.matchs.map((match) => {
                          return (
                            <a href className="matchitem">
                              <div className="matchinfo">
                                <div className="col-9">
                                  <p className="name">{match.owner}</p>
                                  <p className="address">{match.address}</p>
                                  <p className="price">AED {match.price}</p>
                                </div>
                                <div className="col-3">
                                  <p className="addeddate">
                                    <Moment fromNow>
                                      {match.added_date}
                                    </Moment>
                                  </p>
                                </div>
                              </div>
                              <div className="matchhistory">
                                <span className="time"></span>
                                <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                              </div>
                            </a>
                          )
                        })}
                
                      {this.state.matchs.length > 0?  <Link onClick={this.handleAllMatch} className="matchitem">

                        View All
                            
                          </Link>:null}

                      </div>
                    </div>
                   
                  </div>
                </div> {/* END Match*/}
                {/* OFFERS */}
                <div className="bg-white border-top offers p-4 mt-4">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4">Offers</h3>
                    <a href className="btn btn-link viewall">View All</a>
                  </div>
                  <div className="d-flex flex-column border-wrap">

                    {this.state.property_offers.map((property_offer) => {
                      return (
                        <a onClick={this.handleOfferView.bind(this, property_offer.views_id)} className="matchitem">
                          <div className="matchinfo">
                            <div className="col-9">
                              <p className="address">{property_offer.address}</p>
                              <p className="price">Offer: AED {property_offer.price}</p>
                              <p className="price">{property_offer.view_date}</p>
                            </div>
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"></button>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                    {this.state.property_offers.length > 0 ? <a className="matchitem viewall">
                      View All
                    </a> : null}
                  </div>
                </div> {/* END OFFERS */}
                {/* VIEWINGS */}
                <div className="bg-white border-top viewings p-4 mt-4">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4">Viewings</h3>
                    <a onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</a>
                  </div>
                  <div className="d-flex flex-column border-wrap">
                    {this.state.property_viewings.map((property_viewing) => {
                      return (
                        <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                          <div className="matchinfo">
                            <div className="col-9">
                              <p className="address">{property_viewing.address}</p>
                              <p className="price">
                                {property_viewing.price !=null && property_viewing.price !=""?<>Offer: AED {property_viewing.price}</>:null}
                                
                                </p>
                              <p className="price">{property_viewing.view_date}</p>
                              <p className="price">{property_viewing.note}</p>
                            </div>
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"></button>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                  {this.state.property_viewings.length > 0 ?<Link to={"/all_lead_viewings/"+this.state.record_id} target="_blank"  className="btn btn-link viewall">View All</Link>
         :null }

                  </div>
                </div> {/* END VIEWINGS*/}

                        {/* Followup */}
                        <div className="bg-white border-top viewings p-4 mt-4">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4">Follow Up</h3>
                    <a onClick={this.handleNewFollowUp} className="btn btn-link viewall">Add Followup</a>
                  </div>
                  <div className="d-flex flex-column border-wrap">
                    {this.state.followups.map((followup) => {
                      return (
                        <a onClick={this.handleViewFollowup.bind(this, followup.reminder_id)} className="matchitem">
                          <div className="matchinfo">
                            <div className="col-9">
                              
                              <p className="address">
                              {followup.remind_note && followup.remind_note.length > 40 ?
                                  `${followup.remind_note.substring(0, 40)}...` : followup.remind_note
                                }
                                </p>
                              <p className="price">{followup.remind_date}</p>
                            </div>
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"></button>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                    {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                      View All
                    </a> : null}

                  </div>
                </div> {/* END Followup*/}

                <div className="bg-white border-top viewings p-4 mt-4">
          <div className="d-flex flex-row justify-content-between">
            <div style={{ display:'flex', alignItems:"center", justifyContent:"flex-start" }} >
              <SettingFilled style={{fontSize:'1rem', marginRight:'0.35rem'}} />
              <h3 className="mr-4">Collaborators</h3>
            </div>
            <a href className="btn btn-link viewall">View All  </a>
          </div>

          <Select
            // defaultValue="lucy"
            style={{
              width: '100%',
              marginBottom:'1rem'
            }}
            showSearch
            mode={"multiple"}
            value={this.state.newArrSelectedAgents}
            placeholder="Select a person"
            maxTagCount={"responsive"}
            onChange={this.handleChangeSelect}
            // onSelect={(itm)=> {
            //   this.onSelectAgents(itm)
            //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents)
            // }}
            // onDeselect={(itm)=> {
            //   this.onDeSelectAgents(itm)
            //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents) 
            // }}
            options={this.state.agents}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
          />
          {/* <button type="button" className="btn btn-dark w-40 mb-2"  >
            <p style={{ fontSize:'1rem', color:'white' }} >
              Add Collaborator
            </p>
          </button> */}

<Button onClick={this.postAddCollaborator} type="primary" style={{backgroundColor:'black', color:'white'}} >Add Collaborator</Button>

          <div style={{ width:'100%', marginTop:'1rem', paddingLeft:'0.25rem'}} >
          {this.state.collaborators.map((coll) => {
                      return (
                        <a className="matchitem">
                          <div className="matchinfo">
                            <div className="col-9">
                              <p className="name">{coll.display_name}</p>
                              <p className="price">{coll.added_date}</p>
                            </div>
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"></button>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                  {this.state.collaborators.length > 0 ?<Link to={"/all_lead_viewings/"+this.state.record_id} target="_blank"  className="btn btn-link viewall">View All</Link>
         :null }
          </div>
        </div>
              </div>
            </div>
          </div>



        </Drawer>

        <div className="contentwrap" style={{
          minHeight: 380,
          background: "#fff",
        }}>
        {/* <Tabs style={{ color: '#8D949C', width: "100%" }} hideAdd type="editable-card" items={this.state.filters}  onChange={this.handleTabs} onEdit={this.handleDeleteFilter} /> */}

          {/* <Tabs style={{ color: '#8D949C', width: "100%" }} hideAdd type="editable-card" defaultActiveKey="1" onChange={this.handleTabs} onEdit={this.handleDeleteFilter}>
            {this.state.filters
              .map((key) => (

                <TabPane
                  tab={
                    <span>
                      <EditOutlined onClick={this.handleEditFilter.bind(this, key.key)} />
                      {key.label}


                    </span>
                  }
                  key={key.key}
                >

                </TabPane>
              ))
            }
          </Tabs> */}
          <div className='ml-auto d-flex px-0'>
            <div className='row w-100'>
              <Space direction="vertical">


                <Space wrap>

                <div className="d-flex flex-row justify-content-between my-2 w-100 px-3">
                <h3 className="mb-0">Lead Pond </h3>
                <div  className="d-flex flex-row justify-content-between">
                <Button type='primary' className="addfilter" size="large" onClick={this.handleNewFilter} icon={<FilterOutlined /> }> 
                      Add new filter
                  </Button>
                <Button className='ml-1 addnewlisting' type='primary' size="large" onClick={this.handleClearFilter} icon={<ClearOutlined /> }> 
                   Reset filter
                  </Button>
                  </div>
                </div>

                  {/* <Button className='my-2 ml-auto' type="primary" size="large" onClick={this.handleNewFilter}>
                    <FilterOutlined />


                    New filter
                  </Button> */}


                  <Modal width={600} title="Filter" open={this.state.isFilterOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <div className='row w-100 '>
                      <div className='col-md-12 px-0'>
                        <div className='form-group'>
                          <label>Title</label>
                          <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                        </div>
                      </div>
                    </div>
                    <div className='row w-100' />


                    <Select

                      showSearch
                      style={{
                        width: 300,
                      }}
                      placeholder="add filter"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.filter_options}
                      onChange={this.handleAddRow}
                    //   onChange={add()}
                    />








                    {this.state.rows.map((item, idx) => (
                      <div >
                        {item.id == 1 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Contact type</label>
                            <Select
                              mode="multiple"
                              style={{
                                width: '100%',
                              }}

                              placeholder="Please select"
                              onChange={this.handleContactType}
                              options={this.state.contact_types}
                            />
                          </div>
                        </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 2 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Community</label>
                            <Select
                              style={{
                                width: 300,
                              }}
                              showSearch
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={this.handleCommunity}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            />

                          </div>
                        </div>      <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Sub community</label>
                              <Select
                                style={{
                                  width: 300,
                                }}
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                onChange={this.handleSubCommunity}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.sub_communities}
                              />
                            </div>
                          </div><div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div></div> : null}
                        {item.id == 3 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Price</label>
                              <Select

                                style={{
                                  width: '100%',
                                }}

                                placeholder="Please select"
                                onChange={this.handlePriceFirstValue_id}
                                options={this.state.price_options}
                              />
                            </div>

                            <div className='mt-2'>
                              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input type="text" style={{ width: '150px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                                <input type="text" style={{ width: '150px' }} className="form-control ml-1" onChange={this.handlePriceMax} placeholder="max" />
                              </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 4 ? <div className='row'>

                          <div className='col-md-4'>
                            <label>Bedrooms</label>
                            <Select

                              style={{
                                width: '100%',
                              }}

                              placeholder="Please select"
                              onChange={this.handleBedRooms_id}
                              options={this.state.bedroom_filter_options}
                            />
                            <div className='mt-2'>
                              <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleBedRoomsValue} placeholder="filter..." />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div> </div> : null}

                        {item.id == 5 ?
                          <div className='row'>
                            <div className='col-md-4'>
                              <label>Created</label>


                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                  More than
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Less than
                                </label>
                              </div>

                              <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input type="text" className="form-control" onChange={this.handleDateValue} />
                                <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                  <option value="1">days</option>
                                  <option value="2">weeks</option>
                                  <option value="3">months</option>
                                  <option value="4">years</option>
                                </select>
                              </div>

                            </div>
                            <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={this.handleRemoveSpecificRow(idx, item.id)}
                            >
                              Remove
                            </button></div></div> : null}
                        {item.id == 6 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Stage</label>

                              <Select
                                mode="multiple"

                                style={{
                                  width: '100%',
                                }}

                                placeholder="Please select"
                                onChange={this.handleStages}
                                options={this.state.stages}
                              />

                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}
                        {item.id == 7 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Furnished / Unfurnished</label>
                              <Select
                                mode="multiple"
                                style={{
                                  width: '100%',
                                }}

                                placeholder="Please select"
                                onChange={this.handleFurnishedTypes}
                                options={this.state.furnished_types}
                              />

                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}


                      </div>
                    ))}




                  </Modal>

                  {/* edit filter */}

                  <Modal width={1000} title="Filter" open={this.state.isFilterEditOpen} onOk={this.handleEditOk} onCancel={this.handleEditCancel}>
                    {this.state.filter_id}
                    <hr />
                    <div className='row'>
                      <div className='col-md-5'>
                        <div className='form-group'>
                          <label>Tilte</label>
                          <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                        </div>
                      </div>
                    </div>
                    <div className='mt-4' />


                    <Select

                      showSearch
                      style={{
                        width: 300,
                      }}
                      placeholder="add filter"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.filter_options}
                      onChange={this.handleAddRow}
                    //   onChange={add()}
                    />

                    {this.state.rows.map((item, idx) => (
                      <div >
                        {item.id == 1 && this.state.default_contact_types_value.length > 0 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Contact type</label>
                            <Select
                              mode="multiple"
                              style={{
                                width: '100%',
                              }}
                              defaultValue={this.state.default_contact_types_value}

                              placeholder="Please select"
                              onChange={this.handleContactType}
                              options={this.state.contact_types}
                            />
                          </div>
                        </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 2 && this.state.community_value_id > 0 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Community</label>
                            <Select
                              style={{
                                width: 300,
                              }}
                              showSearch
                              defaultValue={this.state.community_value_id}
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={this.handleCommunity}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            />

                          </div>
                        </div>      <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Sub community</label>

                              <select value={this.state.sub_community_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                {this.state.sub_communities.map((sub_comm) => (
                                  <option value={sub_comm.value}>{sub_comm.label}</option>
                                ))}


                              </select>

                              {/* <Select
                       style={{
                        width: 300,
                      }}
                    showSearch
                    value={this.state.sub_community_value_id}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleSubCommunity}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.sub_communities}
                /> */}
                            </div>
                          </div><div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div></div> : null}
                        {item.id == 3 && this.state.price_first_value_id > 0 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Price</label>

                              <select value={this.state.price_first_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Less than or equals</option>
                                <option value="5">Greater than</option>
                                <option value="6">Greater than or equals</option>
                                <option value="7">In range</option>
                              </select>

                            </div>

                            <div className='mt-2'>
                              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input type="text" style={{ width: '150px' }} className="form-control" value={this.state.price_min} onChange={this.handlePriceMin} placeholder="min" />
                                <input type="text" style={{ width: '150px' }} className="form-control ml-1" value={this.state.price_max} onChange={this.handlePriceMax} placeholder="max" />
                              </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.price_first_value} onChange={this.handlePriceFirst} placeholder="filter..." />}
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 4 && this.state.bed_rooms_value > 0 ? <div className='row'>

                          <div className='col-md-4'>
                            <label>Bedrooms</label>
                            <select value={this.state.bed_rooms_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleBedRooms_id} >
                              <option value="1">Equals</option>
                              <option value="2">Not equal</option>
                              <option value="3">Less than</option>
                              <option value="4">Greater than</option>

                            </select>
                            <div className='mt-2'>
                              <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.bed_rooms_value} onChange={this.handleBedRoomsValue} placeholder="filter..." />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div> </div> : null}

                        {item.id == 5 && this.state.date_value_id > 0 && this.state.date_value > 0 ?
                          <div className='row'>
                            <div className='col-md-4'>
                              <label>Created</label>


                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                  More than
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Less than
                                </label>
                              </div>

                              <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input type="text" className="form-control" onChange={this.handleDateValue} />
                                <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                  <option value="1">days</option>
                                  <option value="2">weeks</option>
                                  <option value="3">months</option>
                                  <option value="4">years</option>
                                </select>
                              </div>

                            </div>
                            <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={this.handleRemoveSpecificRow(idx, item.id)}
                            >
                              Remove
                            </button></div></div> : null}
                        {item.id == 6 && this.state.default_stage_value.length > 0 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Stage </label>

                              <Select
                                mode="multiple"
                                style={{
                                  width: '100%',
                                }}
                                defaultValue={this.state.default_stage_value}

                                placeholder="Please select"
                                onChange={this.handleStages}
                                options={this.state.stages}
                              />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}

                        {item.id == 7 && this.state.default_furnished_types_value.length > 0 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Furnished / Unfurnished </label>

                              <Select
                                mode="multiple"
                                style={{
                                  width: '100%',
                                }}
                                defaultValue={this.state.default_furnished_types_value}

                                placeholder="Please select"
                                onChange={this.handleFurnishedTypes}
                                options={this.state.furnished_types}
                              />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}


                      </div>
                    ))}




                  </Modal>




                </Space>
              </Space>
            </div>
          </div>


          <div class="table-responsive tablelistwrap px-3" >
            {/* <ReactDragListView.DragColumn onDragEnd={this.onDragEnd}
              nodeSelector="th"> */}

              <Table onChange={handleTableChange}
                rowSelection={rowSelection}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: this.handleOpenDrawer.bind(this,record.id), // click row
                //   };
                // }}
                // scroll={{ x: 2500 }}
                // size="small"
                loading={this.state.isLoading}
                // className="table-striped mytable"
                pagination={{
                  pageSizeOptions: ['10', '20', '50', '100'],
                  total:
                    this.state?.leads?.total > 0 &&
                    this.state.leads.total,
                  pageSize: this.state.leads.per_page,
                  current: this.state.leads.current_page,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                
                dataSource={
                  this.state?.leads?.data
                    ? this.state?.leads?.data
                    : []
                }
                rowKey={(record) => record}
              />
            {/* </ReactDragListView.DragColumn> */}

          </div>
        </div>

      </div>




    )
  }
}

const mapDispatchToProps = dispatch =>{
  return {
      setNoMatch: (data) => dispatch({type: "SET_Not_Match",payload:data})
  }
}

export default connect(null,mapDispatchToProps)(LeadsPond)
