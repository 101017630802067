const initialState = {
    isPledge: false,
    selectedAgents:""
  };
  const Pledge_Statistics_Reducer = (state = initialState, action) => { 
    switch (action.type) {
      case "SET_PLEDGE":
        return {
          ...state,
          isPledge: action?.payload ? action?.payload.isPledgeStatistics : false,
          selectedAgents:action?.payload ? action?.payload.selectedAgents:""
       
        };

        case "REMOVE_PLEDGE":
            return {
              ...state,
              isPledge: false,
              selectedAgents:false
            };
  
  
      default:
        return state;
    }
  };
  
  export default Pledge_Statistics_Reducer;
  

  