// 
// Note:
// All Deal signed will show automatically on here
// Then Accounts will have to go through them to clear the unpaids
// 

import React, { Component } from 'react'
import { Row, Col,Table, Select, DatePicker, Modal, Button, Upload, message, Image, Switch, Input,Skeleton } from 'antd'
import {Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FileSearchOutlined, CloudUploadOutlined} from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import ApiCalendar from "react-google-calendar-api";
import "../../Pages/antdstyle.css"
import './request.css'
import API from '../../utils/API';
import { store } from '../../store';
import Swal from 'sweetalert2/src/sweetalert2.js'
import dayjs from "dayjs";
const Option = Select.Option;
const { RangePicker } = DatePicker;
let newDate = new Date()
let current_month = newDate.getMonth() + 1;

class Commission extends Component {
    constructor(props){
        super(props);
        this.state ={
            isLoading:false,
            fileList:[],
            input: {},
            errors: {},
            isTotalLoading:false,
            isCompleteLoading:false,
            total_commissions:0,
            total_recieved_commissions:0,
            total_outstanding_commissions:0,
            record:{},
            teams:[],
            agents:[],
            partial_payHasError:false,
            pending_commission_claims:[],
            complete_commission_claims:[],
            commissionShowSale:false,
            commissionShowRent:false,
            updatePaid: false,
            openTransferSlip:false,
            togglePayPartially:false,
            partial_pay:"",
            isCommissionReceived:false,

            recieved_amount:"",
            recieved_date:"",
            recieved_amountError:false,
            recieved_dateError:false,
            // files
            // seller
            seller_title_file:null,
            seller_title_file_imageShow:null,


            owner_passport:null,
            owner_passport_imageShow:null,

            owner_emirate_id:null,
            owner_emirate_id_imageShow:null,

            owner_visa:null,
            owner_visa_imageShow:null,

            signed_form_a:null,
            signed_form_a_imageShow:null,

            signed_form_b:null,
            signed_form_b_imageShow:null,

            signed_form_f:null,
            signed_form_f_imageShow:null,

            signed_form_i:null,
            signed_form_i_imageShow:null,
            // buyer
            buyer_title_file:null,
            buyer_title_file_imageShow:null,

            buyer_passport:null,
            buyer_passport_imageShow:null,

            buyer_emirate_id:null,
            buyer_emirate_id_imageShow:null,

            buyer_visa:null,
            buyer_visa_imageShow:null,

            copy_offer_letter:null,
            copy_offer_letter_imageShow:null,

            security_deposit:null,
            security_deposit_imageShow:null,

            tenancy_agreement:null,
            tenancy_agreement_imageShow:null,

            vacating_letter:null,
            vacating_letter_imageShow:null,

            transfer_slip_file:null,
            transfer_slip_file_imageShow:null,

            transfer_slip_image:null,

            title_deed_file:null,
            title_deed_file_imageShow:null,

            tenant_passport:null,
            tenant_passport_imageShow:null,

            tenant_emirate_id:null,
            tenant_emirate_id_imageShow:null,

            tenant_visa:null,
            tenant_visa_imageShow:null,

            agent_agreement_file:null,
            agent_agreement_file_imageShow:null,

            proof_commission_payment_file:null,
            proof_commission_payment_file_imageShow:null,

            proof_deal_closing_email_file:null,
            proof_deal_closing_email_file_imageShow:null,

            dateRange:[],
            month:"",
            selectedTeam:"",
            selectedAgents:"",
            months:[{
                key:1,
                labe:"January"
            },
            {
                key:2,
                labe:"February"
            },
            {
                key:3,
                labe:"March"
            },
            {
                key:4,
                labe:"April"
            },
            {
                key:5,
                labe:"May"
            },
            {
                key:6,
                labe:"June"
            },
            {
                key:7,
                labe:"July"
            },
            {
                key:8,
                labe:"August"
            },
            {
                key:9,
                labe:"September"
            },
            {
                key:10,
                labe:"October"
            },
            {
                key:11,
                labe:"November"
            },
            {
                key:12,
                labe:"December"
            },
            
        ],

        // columns filter
        refno_first_value_id:1,
        refno_first_value:"",
        agent_filter:[],
        // commission price
        commission_value_id: 1,
        commission_value: "",
        inrange_commission: false,
        commission_min: 0,
        commission_max: 0,
          // commission price
          received_commission_value_id: 1,
          received_commission_value: "",
          received_inrange_commission: false,
          received_commission_min: 0,
          received_commission_max: 0,

        // signed date 
     
        signed_in_date_value: "",
        signed_in_date_value_id: 1,
        signed_in_date_option_value:1,
        inrange_signed_in_date:false,
        signed_in_date_date_range:[],
        signed_in_date_is_empty:false,

        // received date

        received_commission_date_value: "",
        received_commission_date_value_id: 1,
        received_commission_date_option_value:1,
        inrange_received_commission_date:false,
        received_commission_date_date_range:[],
        received_commission_date_is_empty:false,

        paid_status_type:-1

        }
    }



    componentDidMount(){
        const page=1
        const perPage = 10
        this.setState({month:current_month})
        const data = {month:current_month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,

            paid_status_type:this.state.paid_status_type,

            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,

            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        this.fetchTotal(data)
        this.fetchGroup()


        API.get("/all_agents")
        .then((response) => {
          if (response.data.success) {
            this.setState({ agents: response.data.agents })
          }
        }
      )
    }



    fetchGroup = () =>{
        API.get("/teams")
        .then((response) => {
          
          if (response.data.success) {
            this.setState({teams:response.data.teams})
          }
        }
      )
    }

    fetchData = (page,perPage,data) =>{
        this.setState({isLoading:true})
        API.post(`/pending_commission_claims?page=${page}&perPage=${perPage}`,data)
        .then((response) =>{
            
            if(response.data.success){
                this.setState({
                    pending_commission_claims: response.data.pending_commission_claims
                    ,
                  isLoading: false
                })
              }
        })
    }

    fetchTotal = (data) =>{
        this.setState({isTotalLoading:true})
        API.post("/commission_claims_total",data)
        .then((response) =>{

            if(response.data.success){
                const total_outstanding_commissions = response.data.total_commissions - response.data.total_recieved_commissions
                this.setState({isTotalLoading:false,
                    total_commissions:response.data.total_commissions,
                    total_recieved_commissions:response.data.total_recieved_commissions,
                    total_outstanding_commissions:total_outstanding_commissions})
            }
            // if(response.data.success){
            //     this.setState({
            //         pending_commission_claims: response.data.pending_commission_claims
            //         ,
            //       isLoading: false
            //     })
              
        })
    }

    fetchCompleteData = (page,perPage,data) =>{
        this.setState({isCompleteLoading:true})
        API.post(`/complete_commission_claims?page=${page}&perPage=${perPage}`,data)
        .then((response) =>{
            
            if(response.data.success){
                this.setState({
                    complete_commission_claims: response.data.complete_commission_claims
                    ,
                    isCompleteLoading: false
                })
              }
        })
    }

    handleCommSale = (record,e) => {
        this.setState({ commissionShowSale: true,record:record });
    }
    handleComCancel = (e) => {
        this.setState({ commissionShowSale: false, commissionShowRent: false});
    }

    handleComOk = () =>{
        const data = {
            views_id:this.state.record.views_id,
            refno:this.state.record.refno,
            seller_title_file:this.state.seller_title_file,
            owner_passport:this.state.owner_passport,
            owner_emirate_id:this.state.owner_emirate_id,
            owner_visa:this.state.owner_visa,
            signed_form_a:this.state.signed_form_a,
            signed_form_b:this.state.signed_form_b,
            signed_form_f:this.state.signed_form_f,
            signed_form_i:this.state.signed_form_i,
            buyer_title_file:this.state.buyer_title_file,
            buyer_emirate_id:this.state.buyer_emirate_id,
            buyer_passport:this.state.buyer_passport,
            buyer_visa:this.state.buyer_visa,
            copy_offer_letter:this.state.copy_offer_letter,
            security_deposit:this.state.security_deposit,
            tenancy_agreement:this.state.tenancy_agreement,
            vacating_letter:this.state.vacating_letter,

        }
        if (this.validate()) {

        API.post("/commission_claim_uploads",data)
        .then((res) =>{
            if(res.data.success){
                const page=1
                const perPage = 10
                const data = {month:current_month,selectedAgents:this.state.selectedAgents,selectedTeam:this.state.selectedTeam,dateRange:this.state.dateRange,   
                    refno_first_value_id:this.state.refno_first_value_id,
                    refno_first_value:this.state.refno_first_value,
                    agent_filter:this.state.agent_filter,
                    
                    signed_in_date_option_value:this.state.signed_in_date_option_value,
                    signed_in_date_value_id:this.state.signed_in_date_value_id,
                    signed_in_date_value:this.state.signed_in_date_value,
                    signed_in_date_date_range:this.state.signed_in_date_date_range,
        
                    paid_status_type:this.state.paid_status_type,
        
                    commission_value_id:this.state.commission_value_id,
                    commission_value:this.state.commission_value,
                    commission_min:this.state.commission_min,
                    commission_max:this.state.commission_max,
        
                    received_commission_value_id:this.state.received_commission_value_id,
                    received_commission_value:this.state.received_commission_value,
                    received_commission_min:this.state.received_commission_min,
                    received_commission_max:this.state.received_commission_max,
        
                    received_commission_date_value: this.state.received_commission_date_value,
                    received_commission_date_value_id: this.state.received_commission_date_value_id,
                    received_commission_date_option_value:this.state.received_commission_date_option_value,
                    received_commission_date_date_range:this.state.received_commission_date_date_range}
                this.fetchData(page,perPage,data)
                this.fetchCompleteData(page,perPage,data)
                this.setState({commissionShowSale:false})
            }
        })
    }
        //this.setState({commissionShowSale:false})
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let isValid = true;
        if (!input["seller_title_file"]) {
          isValid = false;
          errors["seller_title_file"] = "Please enter seller title .";
        }
      
        if (!input["owner_passport"]) {
          isValid = false;
          errors["owner_passport"] = "Please enter owner passport.";
        }
        if (!input["buyer_passport"]) {
          isValid = false;
          errors["buyer_passport"] = "Please upload  passport.";
        }

        if (!input["buyer_visa"]) {
            isValid = false;
            errors["buyer_visa"] = "Please upload  visa.";
          }

        //   if (!input["proof_commission_payment_file"]) {
        //     isValid = false;
        //     errors["proof_commission_payment_file"] = "Please upload  .";
        //   }

        //   if (!input["proof_deal_closing_email_file"]) {
        //     isValid = false;
        //     errors["proof_deal_closing_email_file"] = "Please upload  .";
        //   }
  
   
      
      
        
      
      
        this.setState({
          errors: errors,
        });
      
        return isValid;
      }
      
      rentvalidate() {
        let input = this.state.input;
        let errors = {};
        let isValid = true;
        if (!input["seller_title_file"]) {
          isValid = false;
          errors["seller_title_file"] = "Please enter seller title .";
        }
      
        if (!input["owner_passport"]) {
          isValid = false;
          errors["owner_passport"] = "Please enter owner passport.";
        }
        if (!input["buyer_passport"]) {
          isValid = false;
          errors["buyer_passport"] = "Please upload  passport.";
        }

        if (!input["buyer_visa"]) {
            isValid = false;
            errors["buyer_visa"] = "Please upload  visa.";
          }

          if (!input["proof_commission_payment_file"]) {
            isValid = false;
            errors["proof_commission_payment_file"] = "Please upload  .";
          }

          if (!input["proof_deal_closing_email_file"]) {
            isValid = false;
            errors["proof_deal_closing_email_file"] = "Please upload  .";
          }
  
   
      
      
        
      
      
        this.setState({
          errors: errors,
        });
      
        return isValid;
      }
    
    
    handleRentComOk = () =>{
        const data = {
            views_id:this.state.record.views_id,
            refno:this.state.record.refno,
            title_deed:this.state.seller_title_file,
            owner_passport:this.state.owner_passport,
            owner_emirate_id:this.state.owner_emirate_id,
          
         
       
            tenant_emirate_id:this.state.buyer_emirate_id,
            tenant_passport:this.state.buyer_passport,
            tenant_visa:this.state.buyer_visa,
            security_deposit:this.state.security_deposit,
            agent_agreement:this.state.tenancy_agreement,
            proof_deal_closing_email_file:this.state.proof_deal_closing_email_file,
            proof_commission_payment_file:this.state.proof_commission_payment_file

        }
        if (this.rentvalidate()) {
        API.post("/rent_commission_claim_uploads",data)
        .then((res) =>{
            if(res.data.success){
                const page=1
                const perPage = 10
                const data = {month:current_month,selectedAgents:this.state.selectedAgents,selectedTeam:this.state.selectedTeam,dateRange:this.state.dateRange,     refno_first_value_id:this.state.refno_first_value_id,
                    refno_first_value:this.state.refno_first_value,
                    agent_filter:this.state.agent_filter,
                    
                    signed_in_date_option_value:this.state.signed_in_date_option_value,
                    signed_in_date_value_id:this.state.signed_in_date_value_id,
                    signed_in_date_value:this.state.signed_in_date_value,
                    signed_in_date_date_range:this.state.signed_in_date_date_range,
        
                    paid_status_type:this.state.paid_status_type,
        
                    commission_value_id:this.state.commission_value_id,
                    commission_value:this.state.commission_value,
                    commission_min:this.state.commission_min,
                    commission_max:this.state.commission_max,
        
                    received_commission_value_id:this.state.received_commission_value_id,
                    received_commission_value:this.state.received_commission_value,
                    received_commission_min:this.state.received_commission_min,
                    received_commission_max:this.state.received_commission_max,
        
                    received_commission_date_value: this.state.received_commission_date_value,
                    received_commission_date_value_id: this.state.received_commission_date_value_id,
                    received_commission_date_option_value:this.state.received_commission_date_option_value,
                    received_commission_date_date_range:this.state.received_commission_date_date_range}
                this.fetchData(page,perPage,data)
                this.fetchCompleteData(page,perPage,data)
                this.setState({commissionShowSale:false})
            }
        })
    }
        //this.setState({commissionShowSale:false})
    }
    handleCommRent = (record,e) => {
        this.setState({ commissionShowRent: true,record:record });
    }
    handleupdatePaid = (record,e) => {
        this.setState({ updatePaid: true ,record:record});
    }
    handleupdatePaidCancel = (e) => {
        this.setState({ updatePaid: false});
    }
    handleopenTransferSlip = (e) => {
        this.setState({ openTransferSlip: true });
    }
    handleTransferSlipCancel = (e) => {
        this.setState({ openTransferSlip: false});
    }
    handletogglePayPartially = () => {
        this.setState({ togglePayPartially: !this.state.togglePayPartially ,partial_pay:!this.state.togglePayPartially==false?"":this.state.partial_pay });
      };

      handlePartialPay = (e) =>{
        this.setState({partial_pay:e.target.value})
      }

    handleSellerTitleDeed = (e) =>{

       

        let files = e.file.originFileObj
      
        if (e.fileList.length < 1){
            this.setState({
                seller_title_file: null,
              });
         return;
        }
        let input = this.state.input;
        input["seller_title_file"] = files
        this.setState({input:input})
        this.createSellerTitleImage(files);
        this.setState({ seller_title_file_imageShow: URL.createObjectURL(files) });
    }

    createSellerTitleImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            seller_title_file: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

      //owner passport
      handleOwnerPassport = (e) =>{
        let files = e.file.originFileObj
        let input = this.state.input;
        input["owner_passport"] = e.file.originFileObj;
        this.setState({input:input})
      
        if (e.fileList.length < 1){
            this.setState({
                owner_passport: null,
              });
         return;
        }
        this.createOwnerPassportImage(files);
        this.setState({ owner_passport_imageShow: URL.createObjectURL(files) });
    }

    createOwnerPassportImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            owner_passport: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }
      // owner visa
      handleOwnerVisa = (e) =>{
        let files = e.file.originFileObj

        let input = this.state.input;
        input["owner_visa"] = e.file.originFileObj
        this.setState({input:input})
      
        if (e.fileList.length < 1){
            this.setState({
                owner_visa: null,
              });
         return;
        }
        this.createOwnerVisaImage(files);
        this.setState({ owner_visa_imageShow: URL.createObjectURL(files) });
    }

    createOwnerVisaImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            owner_visa: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }
      // owner emirate id
      handleOwnerEmirateID = (e) =>{
        let files = e.file.originFileObj
        let input = this.state.input;
        input["owner_emirate_id"] = e.file.originFileObj
        this.setState({input:input})
      
        if (e.fileList.length < 1){
            this.setState({
                owner_emirate_id: null,
              });
         return;
        }
        this.createOwnerEmirateIDImage(files);
        this.setState({ owner_emirate_id_imageShow: URL.createObjectURL(files) });
    }

    createOwnerEmirateIDImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            owner_emirate_id: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

      // signed form A
      handleSignedFormA = (e) =>{
        let files = e.file.originFileObj
      
        if (e.fileList.length < 1){
            this.setState({
                signed_form_a: null,
              });
         return;
        }
        this.createSignedFormAImage(files);
        this.setState({ signed_form_a_imageShow: URL.createObjectURL(files) });
    }

    createSignedFormAImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            signed_form_a: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }
            // signed form B
            handleSignedFormB = (e) =>{
                let files = e.file.originFileObj
              
                if (e.fileList.length < 1){
                    this.setState({
                        signed_form_b: null,
                      });
                 return;
                }
                this.createSignedFormBImage(files);
                this.setState({ signed_form_b_imageShow: URL.createObjectURL(files) });
            }
        
            createSignedFormBImage(file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    
                  this.setState({
                    signed_form_b: e.target.result,
                  });
                };
                reader.readAsDataURL(file);
              }

                    // signed form F
      handleSignedFormF = (e) =>{
        let files = e.file.originFileObj
      
        if (e.fileList.length < 1){
            this.setState({
                signed_form_f: null,
              });
         return;
        }
        this.createSignedFormFImage(files);
        this.setState({ signed_form_f_imageShow: URL.createObjectURL(files) });
    }

    createSignedFormFImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            signed_form_f: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }
            // signed form I
            handleSignedFormI = (e) =>{
                let files = e.file.originFileObj
              
                if (e.fileList.length < 1){
                    this.setState({
                        signed_form_i: null,
                      });
                 return;
                }
                this.createSignedFormIImage(files);
                this.setState({ signed_form_i_imageShow: URL.createObjectURL(files) });
            }
        
            createSignedFormIImage(file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    
                  this.setState({
                    signed_form_i: e.target.result,
                  });
                };
                reader.readAsDataURL(file);
              }
        
// buyer
handleBuyerTitleDeed = (e) =>{
    let files = e.file.originFileObj
  
    if (e.fileList.length < 1){
        this.setState({
            buyer_title_file: null,
          });
     return;
    }
    this.createBuyeritleImage(files);
    this.setState({ buyer_title_file_imageShow: URL.createObjectURL(files) });
}

createBuyeritleImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
        
      this.setState({
        buyer_title_file: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  //buyer passport
  handleBuyerPassport = (e) =>{
    let files = e.file.originFileObj
    let input = this.state.input;
    input["buyer_passport"] = e.file.originFileObj
    this.setState({input:input})
  
    if (e.fileList.length < 1){
        this.setState({
            buyer_passport: null,
          });
     return;
    }
    this.createBuyerPassportImage(files);
    this.setState({ buyer_passport_imageShow: URL.createObjectURL(files) });
}

createBuyerPassportImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
        
      this.setState({
        buyer_passport: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  // buyer visa
  handleBuyerVisa = (e) =>{
    let files = e.file.originFileObj
  
    if (e.fileList.length < 1){
        this.setState({
            buyer_visa: null,
          });
     return;
    }  
    let input = this.state.input;
    input["buyer_visa"] = e.file.originFileObj
    this.setState({input:input})
      this.createBuyerVisaImage(files);
    this.setState({ buyer_visa_imageShow: URL.createObjectURL(files) });
}

createBuyerVisaImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
        
      this.setState({
        buyer_visa: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  // buyer emirate id
  handleBuyerEmirateID = (e) =>{
    let files = e.file.originFileObj
  
    if (e.fileList.length < 1){
        this.setState({
            buyer_emirate_id: null,
          });
     return;
    }
    let input = this.state.input;
    input["buyer_emirate_id"] = e.file.originFileObj
    this.setState({input:input})
    this.createBuyerEmirateIDImage(files);
    this.setState({ buyer_emirate_id_imageShow: URL.createObjectURL(files) });
}

createBuyerEmirateIDImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
        
      this.setState({
        buyer_emirate_id: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

    // copy offer letter
    handleCopyOfferLetter = (e) =>{
        let files = e.file.originFileObj
      
        if (e.fileList.length < 1){
            this.setState({
                copy_offer_letter: null,
              });
         return;
        }
        this.createCopyOfferLetterImage(files);
        this.setState({ copy_offer_letter_imageShow: URL.createObjectURL(files) });
    }
    
    createCopyOfferLetterImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            copy_offer_letter: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

          // security deposit
    handleSecurityDeposit = (e) =>{
      
        let files = e.file.originFileObj
      
        if (e.fileList.length < 1){
            this.setState({
                security_deposit: null,
              });
         return;
        }
        this.createSecurityDepositImage(files);
        this.setState({ security_deposit_imageShow: URL.createObjectURL(files) });
    }
    
    createSecurityDepositImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            security_deposit: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }


                // tenancy agreement
    handleTenancyAgreement = (e) =>{
        let files = e.file.originFileObj
      
        if (e.fileList.length < 1){
            this.setState({
                tenancy_agreement: null,
              });
         return;
        }
        this.createTenancyAgreementImage(files);
        this.setState({ tenancy_agreement_imageShow: URL.createObjectURL(files) });
    }
    
    createTenancyAgreementImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            tenancy_agreement: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

                  // vacating letter
    handleVacatingLetter = (e) =>{
        let files = e.file.originFileObj
      
        if (e.fileList.length < 1){
            this.setState({
                vacating_letter: null,
              });
         return;
        }
        this.createVacatingLetterImage(files);
        this.setState({ vacating_letter_imageShow: URL.createObjectURL(files) });
    }
    
    createVacatingLetterImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            vacating_letter: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

      // transfer slip
      handleTransferSlip = (e) =>{
    //     let file = e.target.files || e.dataTransfer.files;
    //     this.setState({transfer_slip_image:file[0]})
let fileList = [...e.fileList];
 fileList = fileList.slice(-1);
    this.setState({fileList});

    // return
        let files = e.file.originFileObj
       
      
        if (e.fileList.length < 1){
            this.setState({
                transfer_slip_image:null,
                transfer_slip_file: null,
              });
         return;
        }
        this.createTransferSlipImage(files);
        this.setState({ transfer_slip_file_imageShow: URL.createObjectURL(files),transfer_slip_image:files });
    }
    
    createTransferSlipImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            
          this.setState({
            transfer_slip_file: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

// deal closing
handleDealClosing = (e) =>{
    let files = e.file.originFileObj
  
    if (e.fileList.length < 1){
        this.setState({
            proof_deal_closing_email_file: null,
          });
     return;
    }
    let input = this.state.input;
    input["proof_deal_closing_email_file"] = e.file.originFileObj
    this.setState({input:input})
    this.createDealClosingImage(files);
    this.setState({ proof_deal_closing_email_file_imageShow: URL.createObjectURL(files) });
}

createDealClosingImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
        
      this.setState({
        proof_deal_closing_email_file: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // prro commission
  handleProofCommission = (e) =>{
    let files = e.file.originFileObj
  
    if (e.fileList.length < 1){
        this.setState({
            proof_commission_payment_file: null,
          });
     return;
    }
    let input = this.state.input;
    input["proof_commission_payment_file"] = e.file.originFileObj
    this.setState({input:input})
    this.createProofCommissionImage(files);
    this.setState({ proof_commission_payment_file_imageShow: URL.createObjectURL(files) });
}

createProofCommissionImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
        
      this.setState({
        proof_commission_payment_file: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }


      

      handleSubmitPartialPay = () =>{

        if(this.state.partial_pay == "" || this.state.partial_pay ==null || this.state.partial_pay == undefined){
         this.setState({partial_payHasError:true})
        return
        }

        const data = {refno:this.state.record.refno, views_id:this.state.record.views_id, partial_pay:this.state.partial_pay,transfer_slip_file:this.state.transfer_slip_file}

        API.post("/commission_partial_pay",data)
        .then((response) =>{
          if(response.data.success){
            toast.success("paid success")
            this.setState({partial_pay:""})
            const formData = new FormData();
            formData.append("views_id", this.state.record.views_id);
            formData.append("commission_payment_id", response.data.commission_payment_id);
            formData.append("image", this.state.transfer_slip_image);
             const email_data = {image:this.state.transfer_slip_image}

             API.post("/commission_email",formData , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
    
            })
        .then((res) =>{
                console.log("res server",res);
            })


          }else{
            toast.error("cant pay more than expect")
            this.setState({partial_pay:""})
          }
        })
      } 

      handleTeams = (e,event) =>{
       const page = 1
       const perPage = 10
        const data = {month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:e,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.setState({selectedTeam:e})
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        this.fetchTotal(data)
    }

    handleClearTeam = () =>{
        const page = 1
        const perPage = 10
        const data = {month:this.state.month,
            selectedAgents:"",
            selectedTeam:"",
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.setState({month:this.state.month,selectedAgents:"",selectedTeam:""})
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        this.fetchTotal(data)
    }

    handleTabs = (e,event) => { 
        const page = 1
        const perPage = 10
        const data = {month:e,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.setState({month:e})
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        this.fetchTotal(data)
    }

    handleClearFilter = () =>{
        const page = 1
        const perPage = 10
        const data = {month:"",selectedAgents:"",
        selectedTeam:this.state.selectedTeam,
        dateRange:this.state.dateRange,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_first_value:this.state.refno_first_value,
        agent_filter:this.state.agent_filter,
        signed_in_date_option_value:this.state.signed_in_date_option_value,
        signed_in_date_value_id:this.state.signed_in_date_value_id,
        signed_in_date_value:this.state.signed_in_date_value,
        signed_in_date_date_range:this.state.signed_in_date_date_range,
        paid_status_type:this.state.paid_status_type,
        commission_value_id:this.state.commission_value_id,
        commission_value:this.state.commission_value,
        commission_min:this.state.commission_min,
        commission_max:this.state.commission_max,
        received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
    }
        this.setState({month:"",selectedAgents:""})
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        this.fetchTotal(data)
    }
    

    handleAgent = (e) =>{
        this.setState({selectedAgents:e})
        const page = 1
        const perPage = 10
        const data = {selectedAgents:e,
            month:this.state.month,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        this.fetchTotal(data)
      }

      handleRangeDate = (value, dateString) =>{

        this.setState({dateRange:value==null?[]:dateString})
        const page = 1
        const perPage = 10
        const data = {selectedAgents:this.state.selectedAgents,
            month:this.state.month,
            selectedTeam:this.state.selectedTeam,
            dateRange:value==null?[]:dateString,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        this.fetchTotal(data)
      }

    handleMngtApproval=(e)=>{
        Swal.fire({
            title: 'Do you want to approve this commission?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, approve!'
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire('Approved!')
            }
          })
    }

    handleCommReceived=(record,e)=> {
        this.setState({ isCommissionReceived: true,record:record})
    }
    handleCommReceivedCancel=(e)=> {
        this.setState({ isCommissionReceived: false})
    }


    handleAmountRecieved = (e) =>{
        this.setState({recieved_amount:e.target.value})
    }

    handleRecievedDate = (value,dateString) =>{
        console.log(dateString);
        this.setState({recieved_date:value !=null?dateString:""})
    }
    handleCommReceivedOk = () =>{
        if(this.state.recieved_amount=="" && this.state.recieved_date =="")
            {
                this.setState({recieved_amountError:true,recieved_dateError:true})
                return
            }else
            if(this.state.recieved_amount=="")
            {
                this.setState({recieved_amountError:true,recieved_dateError:false})
                return
            }else
            if(this.state.recieved_date ==""){
                this.setState({recieved_amountError:false,recieved_dateError:true})
                return
            }else{
                this.setState({recieved_amountError:false,recieved_dateError:false})
 
            }

        
        const data = {refno:this.state.record.refno, views_id:this.state.record.views_id, recieved_amount:this.state.recieved_amount,recieved_date:this.state.recieved_date}

        API.post("/commission_to_company_pay",data)
        .then((response) =>{
          if(response.data.success){
            toast.success("paid success")
            this.setState({recieved_amount:"",recieved_date:""})
            const page=1
            const perPage = 10
            const data = {month:current_month,selectedAgents:this.state.selectedAgents,selectedTeam:this.state.selectedTeam,dateRange:this.state.dateRange}
            this.fetchData(page,perPage,data)
            this.fetchCompleteData(page,perPage,data)
            if(response.data.sendmail){
               
                 const email_data = {views_id: this.state.record.views_id,commission_payment_id: response.data.commission_payment_id}
    
                 API.post("/commission_email_to_agent",email_data )
            .then((res) =>{
                    console.log("res server",res);
                })
            }

          }else{
            toast.error("cant pay more than expect")
            this.setState({recieved_amount:"",recieved_date:""})
          }
        })
        this.setState({ isCommissionReceived: false})


    }

    // columns filter
    handleRefnoFirstValue_id = (e) => {
        this.setState({ refno_first_value_id: e.target.value })
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:e.target.value,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
    }

    handleRefnoFirst = (e) => {

        const refno_first_value = e.target.value;
        this.setState({ refno_first_value: refno_first_value })
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)

    }

    handleAgentFilter =(e) =>{
        this.setState({agent_filter:e})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:e,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
    }
    handleSignedInDateOptionValue = (e) =>{
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:e.target.value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }

        if(e.target.value == 7 ){
            this.setState({inrange_signed_in_date:true,signed_in_date_is_empty:false})
            // if(this.state.signed_in_date_date_range.length > 0){

            
            // this.fetchData(page,perPage,data)
            // this.fetchCompleteData(page,perPage,data)
            // this.fetchTotal(data)
            // }
          
          }else
          if(e.target.value == 8 ){
            this.setState({signed_in_date_is_empty:true})
            this.fetchData(page,perPage,data)
            this.fetchCompleteData(page,perPage,data)
            // this.fetchTotal(data)
          }
          else
          {
            this.setState({inrange_signed_in_date:false,signed_in_date_is_empty:false})
            if(this.state.signed_in_date_value != ""){
            this.fetchData(page,perPage,data)
            this.fetchCompleteData(page,perPage,data)
            // this.fetchTotal(data)
            }
       
          }
        this.setState({  signed_in_date_option_value:e.target.value})
        
     
    }
   
    handleSignedInDateValue = (e) => {
        this.setState({signed_in_date_value:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:e.target.value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
    }
    handleSignedInDateValue_id = (e) => {
        this.setState({signed_in_date_value_id:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:e.target.value,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
    }

    handlePaid = (e) =>{
   this.setState({paid_status_type:e.target.value})
   const page=1
   const perPage = 10
   const data = {
       month:this.state.month,
       selectedAgents:this.state.selectedAgents,
       selectedTeam:this.state.selectedTeam,
       dateRange:this.state.dateRange,
       refno_first_value_id:this.state.refno_first_value_id,
       refno_first_value:this.state.refno_first_value,
       agent_filter:this.state.agent_filter,
       signed_in_date_option_value:this.state.signed_in_date_option_value,
       signed_in_date_value_id:this.state.signed_in_date_value_id,
       signed_in_date_value:this.state.signed_in_date_value,
       signed_in_date_date_range:this.state.signed_in_date_date_range,
       paid_status_type:e.target.value,
       commission_value_id:this.state.commission_value_id,
       commission_value:this.state.commission_value,
       commission_min:this.state.commission_min,
       commission_max:this.state.commission_max,
       received_commission_value_id:this.state.received_commission_value_id,
       received_commission_value:this.state.received_commission_value,
       received_commission_min:this.state.received_commission_min,
       received_commission_max:this.state.received_commission_max,

       received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
   }
   this.fetchData(page,perPage,data)
   this.fetchCompleteData(page,perPage,data)
//    this.fetchTotal(data)
    }

    handleSignedDateRange = (value, dateString) =>{   

     this.setState({signed_in_date_date_range:value !=null?dateString:[]})
     const page=1
     const perPage = 10
     const data = {
         month:this.state.month,
         selectedAgents:this.state.selectedAgents,
         selectedTeam:this.state.selectedTeam,
         dateRange:this.state.dateRange,
         refno_first_value_id:this.state.refno_first_value_id,
         refno_first_value:this.state.refno_first_value,
         agent_filter:this.state.agent_filter,
         signed_in_date_option_value:this.state.signed_in_date_option_value,
         signed_in_date_value_id:this.state.signed_in_date_value_id,
         signed_in_date_value:this.state.signed_in_date_value,
         signed_in_date_date_range:value !=null?dateString:[],
         paid_status_type:this.state.paid_status_type,
         commission_value_id:this.state.commission_value_id,
         commission_value:this.state.commission_value,
         commission_min:this.state.commission_min,
         commission_max:this.state.commission_max,
         received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
     }
     this.fetchData(page,perPage,data)
     this.fetchCompleteData(page,perPage,data)
    //  this.fetchTotal(data)
    }

    handleCommissionValue_id = (e) =>{
        this.setState({commission_value_id:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:e.target.value,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        if (this.state.commission_value != '' && this.state.commission_value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    handleCommissionValue = (e) =>{
        this.setState({commission_value:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:e.target.value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }

        if (e.target.value != '' && e.target.value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    handleCommissionMin = (e) =>{
        this.setState({commission_min:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:e.target.value,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        if (this.state.commission_max > 0 &&  e.target.value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    handleCommissionMax = (e) =>{
        this.setState({commission_max:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:e.target.value,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        if (this.state.commission_min > 0 &&  e.target.value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    // received commission
    handleReceivedCommissionValue_id = (e) =>{
        this.setState({received_commission_value_id:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,

            received_commission_value_id:e.target.value,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        if (this.state.received_commission_value != '' && this.state.received_commission_value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    handleReceivedCommissionValue = (e) =>{
        this.setState({received_commission_value:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,

            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:e.target.value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }

        if (e.target.value != '' && e.target.value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    handleReceivedCommissionMin = (e) =>{
        this.setState({received_commission_min:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:e.target.value,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        if (this.state.commission_max > 0 &&  e.target.value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    handleReceivedCommissionMax = (e) =>{
        this.setState({received_commission_max:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:e.target.value,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        if (this.state.commission_min > 0 &&  e.target.value > 0){
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
        }
    }

    // received commission date
    handleReceivedCommissionDateOptionValue = (e) =>{
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:e.target.value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }

        if(e.target.value == 7 ){
            this.setState({inrange_received_commission_date:true,received_commission_date_is_empty:false})
            // if(this.state.signed_in_date_date_range.length > 0){

            
            // this.fetchData(page,perPage,data)
            // this.fetchCompleteData(page,perPage,data)
            // this.fetchTotal(data)
            // }
          
          }else
          if(e.target.value == 8 ){
            this.setState({received_commission_date_is_empty:true})
            this.fetchData(page,perPage,data)
            this.fetchCompleteData(page,perPage,data)
            // this.fetchTotal(data)
          }
          else
          {
            this.setState({inrange_received_commission_date:false,received_commission_date_is_empty:false})
            if(this.state.received_commission_date_value != ""){
            this.fetchData(page,perPage,data)
            this.fetchCompleteData(page,perPage,data)
            // this.fetchTotal(data)
            }
       
          }
        this.setState({  received_commission_date_option_value:e.target.value})
        
     
    }
   
    handleReceivedCommissionDateValue = (e) => {
        this.setState({received_commission_date_value:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: e.target.value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
    }
    handleReceivedCommissionDateValue_id = (e) => {
        this.setState({received_commission_date_value_id:e.target.value})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
            received_commission_value:this.state.received_commission_value,
            received_commission_min:this.state.received_commission_min,
            received_commission_max:this.state.received_commission_max,

            received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: e.target.value,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:this.state.received_commission_date_date_range,
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
    }


    handleReceivedCommissionDateRange = (value, dateString) =>{   

        this.setState({received_commission_date_date_range:value !=null?dateString:[]})
        const page=1
        const perPage = 10
        const data = {
            month:this.state.month,
            selectedAgents:this.state.selectedAgents,
            selectedTeam:this.state.selectedTeam,
            dateRange:this.state.dateRange,
            refno_first_value_id:this.state.refno_first_value_id,
            refno_first_value:this.state.refno_first_value,
            agent_filter:this.state.agent_filter,
            signed_in_date_option_value:this.state.signed_in_date_option_value,
            signed_in_date_value_id:this.state.signed_in_date_value_id,
            signed_in_date_value:this.state.signed_in_date_value,
            signed_in_date_date_range:this.state.signed_in_date_date_range,
            paid_status_type:this.state.paid_status_type,
            commission_value_id:this.state.commission_value_id,
            commission_value:this.state.commission_value,
            commission_min:this.state.commission_min,
            commission_max:this.state.commission_max,
            received_commission_value_id:this.state.received_commission_value_id,
               received_commission_value:this.state.received_commission_value,
               received_commission_min:this.state.received_commission_min,
               received_commission_max:this.state.received_commission_max,

               received_commission_date_value: this.state.received_commission_date_value,
            received_commission_date_value_id: this.state.received_commission_date_value_id,
            received_commission_date_option_value:this.state.received_commission_date_option_value,
            received_commission_date_date_range:value !=null?dateString:[],
        }
        this.fetchData(page,perPage,data)
        this.fetchCompleteData(page,perPage,data)
        // this.fetchTotal(data)
       }
   

    render() {
        const authentication = store.getState((state) => state);
        const user = authentication.auth.user
        const role = user.role
        
        const columns =[
            {title:'Property', dataIndex:'refno', fixed: 'left',width:200 , className:"propertywrap", 
                render: (text, record) => (
     
                    <><div className='info-group'>
                        <p className='font-bold mb-0 fs-6'>{record.refno}</p>
                        <p className='font-12 mb-0'>{record.community}, {record.subcommunity}</p>
                        {/* unit no, property, subcommunity, community */}
                        <p className='font-12 text-capitalize'>{record.property_for}</p>
                    </div>
                    </>
                ),
                filterDropdown: (
                    <div style={{ padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                        <span style={{fontSize:'12px', fontWeight:'600', marginBottom:'15px'}}>Ref No</span>
                       <select class="form-select " aria-label="Default select example" name='refno_first_filter_value_id' value={this.state.refno_first_value_id} onChange={this.handleRefnoFirstValue_id}>
                        <option value="1" selected>Contain</option>
                        <option value="2">Not contain</option>
                        <option value="3">Equals</option>
                        <option value="4">Not equal</option>
                        <option value="5">Starts with</option>
                        <option value="6">Ends with</option>
                      </select>
                      <div className='mt-2'>
                      <input type="text" className="form-control" value={this.state.refno_first_value}  onChange={this.handleRefnoFirst} placeholder="Type the LP number" />
                      </div>
                     
                   
                      
          
                       </div>
                  ),
            },
            {title:'Signed Date', dataIndex:'date',
                render: (text, record) => (
     
                    <><div className='info-group'>
                        <p className='font-12'><Moment format="DD MMMM, YYYY">{record.view_date}</Moment></p>
                    </div>
                    </>
                ),
                filterDropdown: (
                    <div style={{
                      padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                      Signed date
                      <hr />
                       <div className='mb-1'>
                      <select value={this.state.signed_in_date_option_value} className="form-select" aria-label="Default select example" name='move_in_date' onChange={this.handleSignedInDateOptionValue} >
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Greater than</option>
                        <option value="4">Less than</option>
                        <option value="5">In Next</option>
                        <option value="6">In Previous</option>
                        <option value="7">Date from - to</option>
                        <option value="8">Is empty</option>
                       
                         </select>
                         </div>
          
                         {this.state.signed_in_date_is_empty?null:<>
                          {this.state.inrange_signed_in_date? <RangePicker onChange={this.handleSignedDateRange} onOk={this.handleRangeDateOk} />:<div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <input value={this.state.signed_in_date_value} type="text" className="form-control" onChange={this.handleSignedInDateValue} />
                        <select className='form-select ml-1' onChange={this.handleSignedInDateValue_id}>
                          <option value="1">days</option>
                          <option value="2">weeks</option>
                          <option value="3">months</option>
                          <option value="4">years</option>
                        </select>
                      </div>}
                         </>}
          
                    </div>
                  ),
            },
            
            {title:'Commission', dataIndex:'commission', 
                render: (text, record) => (
     
                    <><div className='info-group'>
                        <p className='font-12'>{record.comission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                    </div>
                    </>
                ),
                filterDropdown: (
                    <div style={{
                      padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                      Commission
                      <select value={this.state.commission_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleCommissionValue_id}>
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Less than</option>
                        <option value="4">Less than or equals</option>
                        <option value="5">Greater than</option>
                        <option value="6">Greater than or equals</option>
                        <option value="7">In range</option>
                      </select>
                      <div className='mt-2'>
                        {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handleCommissionMin} placeholder="min" value={this.state.commission_min} />
                          <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handleCommissionMax} placeholder="max" value={this.state.commission_max} />
                        </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleCommissionValue} placeholder="filter..." value={this.state.commission_value} />}
                      </div>
                   
        
        
        
                    </div>
                  ),
            },
            {title:'Agent', dataIndex:'agent', 
                render: (text, record) => (
     
                    <><div className='info-group'>
                        <p className='font-12'>{record.display_name}</p>
                    </div>
                    </>
                ),
                filterDropdown: (
                    <div style={{ padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',minWidth: 300,
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                        <span style={{fontSize:'12px', fontWeight:'600', marginBottom:'15px'}}>Agent name</span>
                        <Select className='form-select p-0 noborder m-0 font-14'
                                        mode="multiple"
                                        showSearch
                                        allowClear
                                        maxTagCount= 'responsive'
                                        style={{
                                            width: '100%',
                                        }}
                                      
                                        placeholder="Select Agent"
                                       onChange={this.handleAgentFilter}
                                       filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                      }
        
                                        options={this.state.agents}
                                    />
                   
                       </div>
                  ),
            },
            {title:'Received Commission', dataIndex:'receivedcommission', 
                render: (text, record) => (
                    <>
                    {/* <div className='info-group'>
                        <p className='font-12'>{record.comission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                    </div> */}
                    {record.c_paid_status ==0?
                        <div className='info-group'>
                            <Button type="link" className='p-0' onClick={this.handleCommReceived.bind(this,record)}>Pending</Button>
                        </div>:null}
                        {
                             record.commission_to_company_payments.map((cpayment) =>(
                                <div classname="d-flex flex-column">
                                { cpayment.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED '} 
                                <br/>
                                </div>
                               
                            ))
                        }
                    </>
                ),
                filterDropdown: (
                    <div style={{
                      padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                      Received commision
                      <select value={this.state.received_commission_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleReceivedCommissionValue_id}>
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Less than</option>
                        <option value="4">Less than or equals</option>
                        <option value="5">Greater than</option>
                        <option value="6">Greater than or equals</option>
                      </select>
                      <div className='mt-2'>
                        {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handleReceivedCommissionMin} placeholder="min" value={this.state.received_commission_min} />
                          <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handleReceivedCommissionMax} placeholder="max" value={this.state.received_commission_max} />
                        </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleReceivedCommissionValue} placeholder="filter..." value={this.state.received_commission_value} />}
                      </div>
                   
        
        
        
                    </div>
                  ),
                hidden:role==1?false: true
            },
            {title:'Received Date', dataIndex:'receiveddate', 
                render: (text, record) => (
     
                    <><div className='info-group'>
                        <p className='font-12'>
                        {
                             record.commission_to_company_payments.map((cpayment) =>(
                                
                                <div classname="d-flex flex-column">
                                    {
                                            cpayment.recieved_date == null || cpayment.recieved_date =='' || cpayment.recieved_date == "0000-00-00 00:00:00"? 
                                            <p></p>
                                            :
                                            <>
                                            <Moment format="MMMM DD, YYYY">{cpayment.recieved_date}</Moment>
                                         
                                            </>
                                    }
                                <br/>
                                </div>
                               
                            ))
                        }
                        </p>
                    </div>
                    </>
                ),
                filterDropdown: (
                    <div style={{
                      padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                      Received date
                      <hr />
                       <div className='mb-1'>
                      <select value={this.state.received_commission_date_option_value} className="form-select" aria-label="Default select example" name='move_in_date' onChange={this.handleReceivedCommissionDateOptionValue} >
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Greater than</option>
                        <option value="4">Less than</option>
                        <option value="5">In Next</option>
                        <option value="6">In Previous</option>
                        <option value="7">Date from - to</option>
                        <option value="8">Is empty</option>
                       
                         </select>
                         </div>
          
                         {this.state.received_commission_date_is_empty?null:<>
                          {this.state.inrange_received_commission_date? <RangePicker onChange={this.handleReceivedCommissionDateRange} onOk={this.handleRangeDateOk} />:<div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <input value={this.state.received_commission_date_value} type="text" className="form-control" onChange={this.handleReceivedCommissionDateValue} />
                        <select className='form-select ml-1' onChange={this.handleReceivedCommissionDateValue_id}>
                          <option value="1">days</option>
                          <option value="2">weeks</option>
                          <option value="3">months</option>
                          <option value="4">years</option>
                        </select>
                      </div>}
                         </>}
          
                    </div>
                  ),
            },
            {title:'Docs', dataIndex:'docs', 
                render: (text, record) => (
                    <>
                    {/* {record.refno=="sale"?
                            <Button type="text" className={record.docs=="complete"?'d-flex flex-row align-items-center p-0 docs-complete':'d-flex flex-row align-items-center p-0 docs-incomplete'} 
                            onClick={this.handleCommSale}>
                                <FileSearchOutlined className='mr-1 fs-4 ' />
                                <div className='info-group'>
                                    <p className='font-12 mb-0 text-capitalize'>{record.docs}</p>
                                    <p className='font-12 mb-0'>Click to view</p>
                                </div>
                            </Button>
                        :record.refno=="rent"?
                            <Button type="text" className={record.docs=="complete"?'d-flex flex-row align-items-center p-0 docs-complete':'d-flex flex-row align-items-center p-0 docs-incomplete'} 
                            onClick={this.handleCommRent}>
                                <FileSearchOutlined className='mr-1 fs-4 ' />
                                <div className='info-group'>
                                    <p className='font-12 mb-0 text-capitalize'>{record.docs}</p>
                                    <p className='font-12 mb-0'>Click to view</p>
                                </div>
                            </Button>
                        :null} */}
                        {record.property_for=="sale"?
                            <Button type="text" className={record.seller_title_deed!=null && record.owner_passport!=null && record.owner_emirate_id!=null && record.buyer_passport!=null && record.buyer_visa!=null ?'d-flex flex-row align-items-center p-0 docs-complete':'d-flex flex-row align-items-center p-0 docs-incomplete'} 
                            onClick={this.handleCommSale.bind(this,record)}>
                                <FileSearchOutlined className='mr-1 fs-4 ' />
                                <div className='info-group'>
                                    <p className='font-12 mb-0 text-capitalize'>{record.seller_title_deed!=null && record.owner_passport!=null && record.owner_emirate_id!=null && record.buyer_passport!=null && record.buyer_visa!=null ?"complete":"incomplete"}</p>
                                    <p className='font-12 mb-0'>Click to view</p>
                                </div>
                            </Button>
                        :record.property_for=="rent"?
                            <Button type="text" className={record.seller_title_deed!=null && record.owner_passport!=null && record.owner_emirate_id!=null && record.buyer_passport!=null && record.buyer_visa!=null ?'d-flex flex-row align-items-center p-0 docs-complete':'d-flex flex-row align-items-center p-0 docs-incomplete'}
                            onClick={this.handleCommRent.bind(this,record)}>
                                <FileSearchOutlined className='mr-1 fs-4 ' />
                                <div className='info-group'>
                                    <p className='font-12 mb-0 text-capitalize'>{record.seller_title_deed!=null && record.owner_passport!=null && record.owner_emirate_id!=null && record.buyer_passport!=null && record.buyer_visa!=null ?"complete":"incomplete"}</p>
                                    <p className='font-12 mb-0'>Click to view</p>
                                </div>
                            </Button>
                        :null}
                    </>
                )
            },
            {title:'Approval', dataIndex:'mng_approval', 
                render: (text, record) => (
                    <>
                        {/* {record.mng_approval==2? */}
                            <div className='info-group'>
                                <Button type="link" className='p-0' onClick={this.handleMngtApproval}>Not yet approved</Button>
                            </div>

                            {/* if approved
                            <div className='info-group'>
                                <p className='font-12 text-capitalize mb-0'>Approved</p>
                                <p className='font-12 text-capitalize'>
                                    {record.complete_payment_date == null || record.complete_payment_date =='' || record.complete_payment_date == "0000-00-00 00:00:00"? '':
                                    <><Moment format="MMMM DD, YYYY">{record.complete_payment_date}</Moment></>}
                                 </p>
                            </div>
                            
                            */}
                    </>
                ),
                hidden:role==1?false: true
            },
            {title:'Paid', dataIndex:'paid_status', 
                render: (text, record) => (
                    <>
                        {record.paid_status==2?
                            <div className='info-group'>
                                <p className='font-12 text-capitalize mb-0'>Paid</p>
                                <p className='font-12 text-capitalize'>
                                {
                    record.complete_payment_date == null || record.complete_payment_date =='' || record.complete_payment_date == "0000-00-00 00:00:00"? 
                    ''
                    :
                    <>
                    <Moment format="MMMM DD, YYYY">{record.complete_payment_date}</Moment>
                 
                    </>
                    }
                                 </p>
                            </div>
                        :record.paid_status==0?
                            <div className='info-group'>
                                <p className='font-12 text-capitalize'>Not yet paid</p>
                            </div>
                        :record.paid_status==1?
                            <div className='info-group'>
                                <p className='font-12 text-capitalize mb-0'>Partially Paid</p>
                                <p className='font-12 text-capitalize'>{
                                    record.commission_claim_payments.map((payment) =>(
                                        <div classname="d-flex flex-column">
                                        { payment.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED '} (<Moment format="DD MMMM, YYYY">{payment.created_at}</Moment>)
                                        <br/>
                                        </div>
                                       
                                    ))
                                }
                                    </p>
                            </div>
                        :null}
                    </>
                ),
                filterDropdown: (
                    <div style={{ padding: '8px',
                      borderRadius: '6px',
                      background: '#fff',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                       <span style={{fontSize:'12px', fontWeight:'600', marginBottom:'15px'}}>Paid </span>
                       <select value={this.state.viewing_feedback} className='form-select' onChange={this.handlePaid}>
                        <option value="-1" selected>All</option>
                        <option value="0">Not yet paid</option>
                        <option value="1">Partially Paid</option>
                        <option value="2"> Paid</option>
                       </select>
                     
            
            
                        </div>
                  ),
            },
            {title:'Actions', dataIndex:'actions', 
                render: (text, record) => (
                    <>
                     <div className='info-group'>
                            <Button type="text" onClick={this.handleupdatePaid.bind(this,record)} className='text-capitalize py-0'>Update</Button>
                        </div>
                        {/* {record.actions=="Update"?
                            <div className='info-group'>
                            <Button type="text" onClick={this.handleupdatePaid} className='font-12 text-capitalize'>Update</Button>
                        </div>
                        :record.actions=="View Transfer Slip"?
                            <div className='info-group'>
                                <Button type="text" onClick={this.handleopenTransferSlip} className='font-12 text-capitalize'>View Transfer Slip</Button>
                            </div>
                        :null} */}
                    </>
                ),
                hidden:role==1?false: true
            },
        ].filter(item => !item.hidden);

        const handleTableChange = (pagination, filters) => {


            const data = {month:this.state.month,
                selectedAgents:this.state.selectedAgents,
                selectedTeam:this.state.selectedTeam,
                dateRange:this.state.dateRange,
                refno_first_value_id:this.state.refno_first_value_id,
                refno_first_value:this.state.refno_first_value,
                agent_filter:this.state.agent_filter,
                signed_in_date_option_value:this.state.signed_in_date_option_value,
                signed_in_date_value_id:this.state.signed_in_date_value_id,
                signed_in_date_value:this.state.signed_in_date_value,
                signed_in_date_date_range:this.state.signed_in_date_date_range,
                paid_status_type:this.state.paid_status_type,
                commission_value_id:this.state.commission_value_id,
                commission_value:this.state.commission_value,
                commission_min:this.state.commission_min,
                commission_max:this.state.commission_max,
                received_commission_value_id:this.state.received_commission_value_id,
                received_commission_value:this.state.received_commission_value,
                received_commission_min:this.state.received_commission_min,
                received_commission_max:this.state.received_commission_max,
            }
     
           this.fetchData(pagination.current, pagination.pageSize,data);
           
     
         };

         const handleCompleteTableChange = (pagination, filters) => {


            const data = {month:this.state.month,
                selectedAgents:this.state.selectedAgents,
                selectedTeam:this.state.selectedTeam,
                dateRange:this.state.dateRange,
                refno_first_value_id:this.state.refno_first_value_id,
                refno_first_value:this.state.refno_first_value,
                agent_filter:this.state.agent_filter,
                signed_in_date_option_value:this.state.signed_in_date_option_value,
                signed_in_date_value_id:this.state.signed_in_date_value_id,
                signed_in_date_value:this.state.signed_in_date_value,
                signed_in_date_date_range:this.state.signed_in_date_date_range,
                paid_status_type:this.state.paid_status_type,
                commission_value_id:this.state.commission_value_id,
                commission_value:this.state.commission_value,
                commission_min:this.state.commission_min,
                commission_max:this.state.commission_max,
                received_commission_value_id:this.state.received_commission_value_id,
                received_commission_value:this.state.received_commission_value,
                received_commission_min:this.state.received_commission_min,
                received_commission_max:this.state.received_commission_max,
            }
     
           this.fetchCompleteData(pagination.current, pagination.pageSize,data);
           
     
         };
     
        return (
            <>
             <ToastContainer />

            <Modal title="Update Received Commission" className='commReceived-wrap' centered open={this.state.isCommissionReceived} 
            onCancel={this.handleCommReceivedCancel}
           onOk={this.handleCommReceivedOk}
            >
                {/* On Submit
                    will send email after they received commission
                */}
                {this.state.recieved_amountError || this.state.recieved_dateError?<div className='w-100 d-flex flex-column alert alert-danger'>
                    
                   <ul>
                    {this.state.recieved_amountError?<li>Amount is required</li>:null}
                    {this.state.recieved_dateError?<li>Date is required</li>:null}
                   </ul>
                </div>:null}
                <div className='w-100 d-flex flex-column'>
                    <div className='form-group'>
                        <p>Amount Received</p>
                        <Input value={this.state.recieved_amount} onChange={this.handleAmountRecieved} />
                    </div>
                    <div className='form-group'>
                        <p>Date Received</p>
                        <DatePicker format="YYYY-MM-DD" value={(this.state.recieved_date) ? dayjs(this.state.recieved_date) : ""}  onChange={this.handleRecievedDate} />
                    </div>  
                </div>
            </Modal>


            <Modal title="Update Payment" width={600} className='commisionmodal-wrap' centered open={this.state.updatePaid} onOk={this.handleupdatePaid} onCancel={this.handleupdatePaidCancel}
                footer={[<Button onClick={this.handleSubmitPartialPay} type="primary">Submit and Send Email</Button> ]}
            >
                {/* For Admin [Accounts]
                Transfer Slip is required to complete the payment and change
                the status to Paid - add date for Paid
                Once done - can sent email to agent with the transfer slip copy
                --- 
                IF  Pay Partially:
                Status will be Paid Partially - add date
                */}
                <Row span={24} className='w-100'>
                    <Col span={24}>
                        {/* <div className='form-group d-flex flex-row align-items-center'>
                            <p className='mr-2 mb-0'>Pay Partially?</p>
                            <Switch checkedChildren="Yes" unCheckedChildren="No" onClick={this.handletogglePayPartially} />
                        </div> */}
                            <div className='form-group'>
                                <p>How much?</p>
                                <Input value={this.state.partial_pay} onChange={this.handlePartialPay}  />
                              
                            </div>  
                          {this.state.partial_payHasError?<p className='text-danger'>Amount required </p>:""}
                        <div className='form-group'>
                            <p>Transfer Slip</p>
                            {/* <input type="file" onChange={this.handleTransferSlip} /> */}
                            <Upload listType='picture' fileList={[...this.state.fileList]}  onChange={this.handleTransferSlip}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                        </div>
                    </Col>
                    {/* IF Paid Partially and will add another payment 
                    <Col span={24}>
                        <div className='form-group d-flex flex-column'>
                            <p className='mr-2 mb-0 w-100'>Paid Partially</p>
                            <p className='font-14'>30,000 AED - April 15, 2023</p>
                        </div>
                        <div className='form-group'>
                            <p>Enter amount to pay</p>
                            <Input />
                        </div> 
                        <div className='form-group'>
                            <p>Transfer Slip</p>
                            <Upload {...props}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                        </div>
                    </Col> */}
                </Row>

            </Modal>
            <Modal title="View Transfer Slip" width={600} className='commisionmodal-wrap' centered open={this.state.openTransferSlip} onOk={this.handleopenTransferSlip} onCancel={this.handleTransferSlipCancel}
            footer={[<Button type="primary">Send Transfer Slip</Button> ]} >
                {/* For Admin [Accounts]
                
                */}
                <Row span={24} className='w-100'>
                    <Col span={24}>
                        <div className='form-group'>
                            <Image width={200} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"/>
                        </div>
                    </Col>
                </Row>

            </Modal>


            <Modal title="Sale Transaction Documents" open={this.state.commissionShowSale} onOk={this.handleComOk} onCancel={this.handleComCancel} width={800} className='commisionmodal-wrap' centered  >
            {/* SALE Documents */}
                <div className='d-flex flex-column w-100'>
                    <p className='font-12'>Documents marked with an asterisk (*) are mandatory to process the commission.</p>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Title of Deed (from Seller) <em>*</em></p>
                                <Upload   onChange={this.handleSellerTitleDeed}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.seller_title_file}</p>
                                {this.state.record.seller_title_deed !=null?  <Image
                                width={200}
                                src={"https://luxuryproperty.s3.ap-south-1.amazonaws.com/uploads/commission_claims/LP18534/"+this.state.record.seller_title_deed}
                            />:null}
                              
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Owner(s)'s Passport <em>*</em></p>
                                <Upload  onChange={this.handleOwnerPassport}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                              
                                <p className='text-danger'>{this.state.errors.owner_passport}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Owner's Visa </p>
                                <Upload  onChange={this.handleOwnerVisa}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                               
                                <p className='text-danger'>{this.state.errors.owner_visa}</p>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Owner's Emirates ID <em>*</em></p>
                                <Upload  onChange={this.handleOwnerEmirateID} ><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.owner_emirate_id}</p>

                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Signed Form A - Listing Agreement (If applicable)</p>
                                <Upload  onChange={this.handleSignedFormA}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p> Signed Form F along with addendum- Sales Contract</p>
                                <Upload   onChange={this.handleSignedFormF}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Signed Form B - Buyer/Agent Agreement (If applicable)</p>
                                <Upload  onChange={this.handleSignedFormB}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Signed Form I - Agent to Agent Agreement (If applicable)</p>
                                <Upload   onChange={this.handleSignedFormI}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Buyer's Passport <em>*</em></p>
                                <Upload  onChange={this.handleBuyerPassport}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.buyer_passport}</p>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Buyer's Visa <em>*</em></p>
                                <Upload   onChange={this.handleBuyerVisa}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.buyer_visa}</p>

                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Buyer's Emirates ID</p>
                                <Upload  onChange={this.handleBuyerEmirateID}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.buyer_emirate_id}</p>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Copy of Offer Letter</p>
                                <Upload  onChange={this.handleCopyOfferLetter}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>10% Security Deposit Cheque</p>
                                <Upload  onChange={this.handleSecurityDeposit}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Tenancy Agreement, If the property is rented.</p>
                                <Upload  onChange={this.handleTenancyAgreement}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Vacating Notification Letter (If applicable)</p>
                                <Upload  onChange={this.handleVacatingLetter}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Title of Deed (from Buyer)</p>
                                <Upload  onChange={this.handleBuyerTitleDeed}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24}>
                        <Col span={24}>
                            <ul className='w-100 pl-0 listinfo'>
                                <li className='font-bold'>CONTRACTS</li>
                                <li>Please ensure contracts are completed correctly with accurate information.</li>
                                <li>Signatories are authorized to sign.</li>
                                <li>Contracts are dated correctly.</li>
                                <li>All pages are numbered and initialed by both parties.</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <Modal title="Rent Transaction Documents" open={this.state.commissionShowRent} onOk={this.handleRentComOk} onCancel={this.handleComCancel} width={800} className='commisionmodal-wrap' centered  >
            {/* SALE Documents */}
                <div className='d-flex flex-column w-100'>
                    <p className='font-12'>Documents marked with an asterisk (*) are mandatory to process the commission.</p>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Title of Deed <em>*</em></p>
                                <Upload  onChange={this.handleSellerTitleDeed}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.seller_title_file}</p>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Owner(s)'s Passport <em>*</em></p>
                                <Upload  onChange={this.handleOwnerPassport}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.owner_passport}</p>

                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Owner(s)'s Emirates ID <em>*</em></p>
                                <Upload  onChange={this.handleOwnerEmirateID}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.owner_emirate_id}</p>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Agent to Agent Agreement (If Applicable)</p>
                                <Upload  onChange={this.handleTenancyAgreement}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Tenant(s)'s Passport Copy <em>*</em></p>
                                <Upload  onChange={this.handleBuyerPassport}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.buyer_passport}</p>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Tenant(s)'s Visa Copy <em>*</em></p>
                                <Upload  onChange={this.handleBuyerVisa}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.buyer_visa}</p>

                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Tenant(s)'s Emirates ID Copy (Front and Back) <em>*</em></p>
                                <Upload  onChange={this.handleBuyerTitleDeed}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.buyer_emirate_id}</p>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Proof for Rental and Security Deposit payment <em>*</em></p>
                                <Upload  onChange={this.handleSecurityDeposit}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Proof for Commission payment <em>*</em></p>
                                <Upload  onChange={this.handleProofCommission}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.proof_commission_payment_file}</p>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <p>Proof of Deal Closing Email <em>*</em></p>
                                <Upload  onChange={this.handleDealClosing}><Button><CloudUploadOutlined /> Click to Upload</Button></Upload>
                                <p className='text-danger'>{this.state.errors.proof_deal_closing_email_file}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row span={24}>
                        <Col span={24}>
                            <ul className='w-100 pl-0 listinfo'>
                                <li className='font-bold'>CONTRACTS</li>
                                <li className='font-12'>If the owner is a company, the trade license, signatories documents (Passport, Visa and Emirates ID copies) & VAT Certificate required.</li>
                                <li className='font-12'>If the tenancy agreement signed by POA holder, POA copy and POA holder documents are required (Passport, Visa & Emirates ID).</li>
                                <li className='font-12'>If property rented by Holiday Homes, their trade license and DTCM unit permit.</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div className='commision-wrap px-3'>
                <Row gutter={16} className="contentwrap p-4">
                    <Col span={12}>
                        <h1 className='font-bold text-uppercase my-2 fs-3'>Commission Claims</h1>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={12}>
                                <div className='form-group' width={120}>
                                {role == 1 ? <Select className='mr-2'
                                                allowClear
                                                showSearch
                                                style={{ 
                                                    width: 120, height:'36px'
                                                    // minWidth:'200px',
                                                    // width: '100%',
                                                }}
                                               

                                                placeholder="Select agent"
                                                onChange={this.handleAgent}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={this.state.agents}
                                            />:null}
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group d-flex flex-row align-items-center mb-0 position-relative'>
                                    <label className='mr-2 mb-0' style={{whiteSpace:'nowrap'}}>Date:</label>
                                    <RangePicker style={{height: '36px', overflow: 'hidden', position: 'relative'}} onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />
                                </div>
                            </Col>
                        </Row>                                
                    </Col>
                </Row>
                <Row className="contentwrap p-4 pt-0 mouwrap">
                    <Col span={24}>
                        <div className='tabs-months'>
                        <Tabs defaultIndex={current_month}>
                        <TabList>
                        {this.state.months.length > 0? <Tab onClick={this.handleClearFilter}><span className='mx-3'>All</span></Tab>:null}
                        {this.state.months.map((month)=>(
                            <>
                                <Tab onClick={this.handleTabs.bind(this,month.key)}><span  className="mx-3">{month.labe}</span></Tab>
                            </>
                        ))}
                        
                       </TabList>
                  </Tabs>
                        </div>
                    </Col>
                </Row>
                <Row className="contentwrap p-4 pt-0 " gutter={12} >
                    <Col span={8} className='border p-3'>
                        <p className='text-uppercase font-14 mb-1'>Total Commission</p>
                            <Row className='w-100'>
                                <Col span={12} className='p-0'>
                                    <p className='font-bold fs-5 mb-0'>
                                    {this.state.isTotalLoading?<Skeleton active />:
                                      <>{ this.state.total_commissions >0 ?this.state.total_commissions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':0}</>
                                      }
                                
                                    </p>
                                </Col>
                            </Row>
                    </Col>
                    <Col span={8} className='border p-3'>
                        <p className='text-uppercase font-14 mb-1'>Total Received</p>
                            <Row className='w-100'>
                                <Col span={12} className='p-0'>
                                    <p className='font-bold fs-5 mb-0'>
                                    {this.state.isTotalLoading?<Skeleton active />:
                                      <>{ this.state.total_recieved_commissions >0 ?this.state.total_recieved_commissions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':0}</>
                                      }
                                    </p>
                                </Col>
                            </Row>
                    </Col>
                    <Col span={8} className='border p-3'>
                        <p className='text-uppercase font-14 mb-1'>Total Outstanding</p>
                            <Row className='w-100'>
                                <Col span={12} className='p-0'>
                                    <p className='font-bold fs-5 mb-0'>
                                    {this.state.isTotalLoading?<Skeleton active />:
                                      <>{ this.state.total_outstanding_commissions >0 ?this.state.total_outstanding_commissions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':0}</>
                                      }
                                        
                                    </p>
                                </Col>
                            </Row>
                    </Col>
                </Row>
                <Row className="p-4 pt-0">
                    <Col span={24}>
                        <div className='tcontractwrap'>

             

                            <Tabs >
                                <TabList>
                                    <Tab><p>Pending Claims</p></Tab>
                                    <Tab><p>Previous Claims</p></Tab>
                                </TabList>
                                {role==1?  
                    <Tabs className={'tabs-team'}>
                        <TabList>
                        {this.state.teams.length > 0? <Tab onClick={this.handleClearTeam}><span className='mx-3'>All</span></Tab>:null}
                        {this.state.teams.map((team,index)=>(
                            <>
                                <Tab onClick={this.handleTeams.bind(this,team.value)} ><span className="mx-3">{team.label}</span></Tab>
                            </>
                        ))}
                        
                       </TabList>
                  </Tabs>:null}
                                <TabPanel>
                                    <div className='tabs-team p-4 border'>
                                   
                                  
                                            <div className=''>
                                            <Table onChange={handleTableChange} 
                                            loading={this.state.isLoading}
                                        
                                            //className="table-striped table-bordered"
                                            pagination={{
                                            pageSizeOptions: ['10', '20','50','100'],
                                            total:
                                            this.state?.pending_commission_claims?.total > 0 &&
                                            this.state.pending_commission_claims.total,
                                            pageSize: this.state.pending_commission_claims.per_page,
                                            current: this.state.pending_commission_claims.current_page,
                                            showTotal: (total, range) =>
                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                            showSizeChanger: true,
                                            onShowSizeChange: onShowSizeChange,
                                            itemRender: itemRender,
                                            }}
                                            style={{ overflowX: "auto", overflowY:'hidden' }}
                                            columns={columns}

                                            dataSource={
                                            this.state?.pending_commission_claims?.data
                                            ? this.state?.pending_commission_claims?.data
                                            : []
                                            }
                                            />
                                                {/* <Table className='border mt-2'
                                                columns={columns} dataSource={data}
                                                pagination={{hideOnSinglePage: true}}
                                                /> */}
                                             </div>
                                    
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <Table onChange={handleCompleteTableChange} 
                                            loading={this.state.isCompleteLoading}
                                        
                                            //className="table-striped table-bordered"
                                            pagination={{
                                            pageSizeOptions: ['10', '20','50','100'],
                                            total:
                                            this.state?.complete_commission_claims?.total > 0 &&
                                            this.state.complete_commission_claims.total,
                                            pageSize: this.state.complete_commission_claims.per_page,
                                            current: this.state.complete_commission_claims.current_page,
                                            showTotal: (total, range) =>
                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                            showSizeChanger: true,
                                            onShowSizeChange: onShowSizeChange,
                                            itemRender: itemRender,
                                            }}
                                            style={{ overflowX: "auto", overflowY:"hidden" }}
                                            columns={columns}

                                            dataSource={
                                            this.state?.complete_commission_claims?.data
                                            ? this.state?.complete_commission_claims?.data
                                            : []
                                            }
                                            />
                                                {/* <Table className='border mt-2'
                                                columns={columns} dataSource={data}
                                                pagination={{hideOnSinglePage: true}}
                                                /> */}
                                </TabPanel>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                
            </div>
        </>
        )
    }
}
export default  Commission;