import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";

import 'antd/dist/reset.css';
import {Table,Breadcrumb,DatePicker,Collapse,Tabs,Space,Button,Modal,Drawer, Select, Input,Avatar,Upload,Image,Divider, Row, Col} from 'antd'
import {CloseOutlined,PlusCircleOutlined,EditFilled,FilterFilled,EditOutlined,UploadOutlined, PlusOutlined, SearchOutlined, CaretDownOutlined} from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EdiText from "react-editext";
import Swal from "sweetalert2";
// import './index.css'
import './listing.css'
import { baseLpUrlImg, baseurlImg } from '../../components/BaseUrl';

const { Dragger } = Upload;
const {TextArea} = Input
const {TabPane} =Tabs;

let columns =[]
let property_type_array = ["Residential","Commercial"]
let property_for_array = ["sale","rent","tenanted","short-term"]
class All_Lead_Viewings extends Component {

    constructor(props){
        super(props);
        this.state ={
            isLoading:false,
            all_viewings:[]
          
        }

        this.textInput = React.createRef();
        
    }

    componentDidMount() {

      var loadScript = function(src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName('body')[0].appendChild(tag);
        }
     loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')

        const page = 1;
        const perPage = 10;


        this.fetchData(page, perPage);
       
    }

   

    
    fetchData = (page, perPage) => {
        this.setState({isLoading:true})
      //  setTimeout(() => {
             const data ={
                id:this.props.match.params.id
             }
        
        API.post(`/all_lead_viewings?page=${page}&perPage=${perPage}`,data)
             .then(async (response)  =>{

            
                var data = await response.data.all_viewings.data;

                await response.data.all_viewings;
                this.setState({
                    all_viewings: response.data.all_viewings,
                    isLoading: false
                })

             })
            // }, 200);

    }

   

  render() {

    

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    // setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    // selectedRowKeys,
    onChange: onSelectChange,

  };

   columns = [   
        {
            title: "Status",
            dataIndex:"status",
            className: "viewstat",
            // sorter: (a, b) => sortValidator(a, b, a.status, b.status),
            render: (text, record) => {
                var pStatus = ''
                var color = ''
                 switch (record.status) {
                case 'Offer':
                  pStatus = 'Offer'
                  color = 'viewings'
                  break;
                case 'Not Interested':
                  pStatus = 'Not Interested'
                  color = 'cancelled';
                  break;
                 case 'Second Viewing':
                  pStatus = 'Second Viewing'
                  color = 'unsuccessful';
                  break;
                  case 'Considering':
                    pStatus = 'Considering'
                    color = 'successful';
                  break;
                 case 'Scheduled':
                  pStatus = 'Scheduled'
                  color = 'scheduled';
                  break;
          
            
                default:                  
                  pStatus = 'Scheduled'
                  color = 'scheduled';
                  break;
              }
              return (
                <div className={color + " pstat"} key={pStatus}>
                  {pStatus}
                </div>
              );
            }
        },
      {
        title: "Property",
        dataIndex:"property_details",
        sorter: (a, b) => a.property_title.length - b.property_title.length,
        render: (text, record) => (
            <>
                <div className='d-flex flex-column'>
                    <p className='font-bold mb-0 fs-6'>{record.refno}</p>
                    <p className='mb-0 font-12'>{record.property_title?record.property_title+", ":null}</p>
                    <p className=' mb-0 font-12'>{(record.sub_community_title?record.sub_community_title+ ", " :null) + (record.community_title?record.community_title:null)}</p>
                </div>
            </>
        )
      },  
      {
        title: "Unit no",
        dataIndex:"unitno",
        sorter: (a, b) => a.unitno.length - b.unitno.length,
      },
      {
        title: "Date",
        dataIndex:"view_date",
        sorter: (a, b) => a.view_date.length - b.view_date.length,
        render: (text, record) => (
            <Moment format='hh:mm A | DD MMMM YYYY'>{record.view_date}</Moment>
        )
      },  
      {
        title: "Client",
        dataIndex:"fullname",sorter: (a, b) => a.fullname.length - b.fullname.length,
      },
      {
        title: "Agent",
        dataIndex: 'agent',
        render: (text, record) => (
            <p className='font-12'>{record.display_name}</p>
        )
      },
    {
      title: "Note",
      dataIndex: 'note',className: "noteswrap",
      sorter: (a, b) => a.note.length - b.note.length,
    },

    


  ]

  


      const handleTableChange = (pagination,filters) => {

    
        const data ={
            id:this.props.match.params.id
         }
      
        this.fetchData(pagination.current, pagination.pageSize,data);
    
      };

      const items = [
        {
          key: '1',
          label: `New listings  (10)`,
          closable: true,
        },
        {
          key: '2',
          label: `Contacted listings  (0)`,
        },
        {
          key: '3',
          label: `Other listings  (13)`,
        },
        {
          key: '4',
          label: `Other listings  (13)`,
        },
        {
          key: '5',
          label: `Other listings  (13)`,
        },
        {
          key: '6',
          label: `Other listings  (13)`,
        },
      ];

  

    return (
        <>
            <ToastContainer />

            <Row className='contentwrap px-3 mt-4'>
                <Col span={24}>
                    <h3 className="">All Viewings</h3>
                </Col>
                <Col span={24}>
                    <ReactDragListView.DragColumn  onDragEnd={this.onDragEnd} nodeSelector="th" className="bgwhite">
                        <Table onChange={handleTableChange}
                            loading={this.state.isLoading}
                            rowSelection={rowSelection}
                            //className="table-striped table-bordered"
                            pagination={{
                                        pageSizeOptions: ['10', '20','50','100'],
                                        total:
                                        this.state?.all_viewings?.total > 0 &&
                                        this.state.all_viewings.total,
                                        pageSize: this.state.all_viewings.per_page,
                                        current: this.state.all_viewings.current_page,
                                        showTotal: (total, range) =>
                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                        showSizeChanger: true,
                                        onShowSizeChange: onShowSizeChange,
                                        itemRender: itemRender,
                                    }}
                                    style={{ overflowX: "auto" }}
                                    columns={columns}
                                    bordered
                                    className='bgwhite'
                                    dataSource={
                                        this.state?.all_viewings?.data
                                        ? this.state?.all_viewings?.data
                                        : []
                                    }
                                    
                                    rowKey={(record) => record}
                        />
                    </ReactDragListView.DragColumn>
                </Col>
            </Row>
        </>
    )
  }
}




export default All_Lead_Viewings

// export default  MyListings;
