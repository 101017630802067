import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import 'antd/dist/reset.css';
import { Table, Space, Select, Tag, DatePicker, Modal, Button, Input, Row, Col, Collapse, Popover } from 'antd'
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import dayjs from "dayjs";
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClearOutlined, FilterOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import './tableResponsive.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { end } from '@popperjs/core';

import Swal from 'sweetalert2';

import './listing.css'
import { store } from '../../store';
import moment from 'moment';
import validator from 'validator';
import { liveUrl } from "../../components/BaseUrl";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
let columns = []
const TabPane = Tabs.TabPane;
const { Panel } = Collapse;


class All_Listings_Leads_Viewings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      todayLoading: false,
      isLoading: false,
      isPreviousLoading: false,
      isCurrent: false,
      isNext: false,
      isPrevious: false,
      isFilterOpen: false,
      all_viewings: [],
      all_today_viewings: [],
      all_upcomming_viewings: [],
      all_previous_viewings: [],
      filter_title: "",
      titleHasError: false,

      communities: [],
      sub_communities: [],
      properties: [],
      viewings: {},
      showPrice: false,
      showCommission: false,
      showDate: false,
      view_id: "",

      viewing_agent: "",
      viewing_date: "",
      viewing_note: "",
      viewing_status: "",
      viewing_price: "",
      viewing_commission: "",
      filters: [],

      // filters
      month: "",
      community_filter: "",
      sub_community_filter: "",
      property_filter: "",
      viewing_feedback: 0,
      property_for_filter: [],
      agent_filter: [],
      vendor_agent_filter: [],
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,
      // ref no
      refno_first_value_id: 1,
      refno_second_value_id: 1,
      refno_active_second_option: false,
      refno_and_or_option: true,
      refno_first_value: "",
      refno_second_value: "",
      // unit no
      unitno_first_value_id: 1,
      unitno_second_value_id: 1,
      unitno_active_second_option: false,
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_second_value: "",
      // status
      status_filter: [],
      contact_type_filter: [],
      date_range_filter: "",

      // agent filter
      agent_value_id: 1,
      agent_value: "",
      client_value_id: 1,
      client_value: "",
      // date_range:"",
      // date_range_status:null,
      isView: false,
      rows: [],
      filter_options: [


        {
          value: '1',
          label: 'Contact type',
        },

        {
          value: '2',
          label: 'Status',
        },
        {
          value: '3',
          label: 'Date range',
        },
        {
          value: '4',
          label: 'Property for',
        },
        {
          value: '5',
          label: 'Agent',
        }


      ],
      contact_types: [
        {
          value: '1',
          label: 'Tenant'
        },
        {
          value: '2',
          label: 'Buyer'
        },
        {
          value: '3',
          label: 'Landlord'
        },
        {
          value: '4',
          label: 'Seller'
        },
        {
          value: '5',
          label: 'Landlord+Seller'
        },
        {
          value: '7',
          label: 'Other'
        },
        {
          value: '8',
          label: 'Portal'
        },
        {
          value: '9',
          label: 'Buyer/Tenant'
        },
        {
          value: '10',
          label: 'Unrecognized'
        }
      ],
      months: [{
        key: 1,
        labe: "January"
      },
      {
        key: 2,
        labe: "February"
      },
      {
        key: 3,
        labe: "March"
      },
      {
        key: 4,
        labe: "April"
      },
      {
        key: 5,
        labe: "May"
      },
      {
        key: 6,
        labe: "June"
      },
      {
        key: 7,
        labe: "July"
      },
      {
        key: 8,
        labe: "August"
      },
      {
        key: 9,
        labe: "September"
      },
      {
        key: 10,
        labe: "October"
      },
      {
        key: 11,
        labe: "November"
      },
      {
        key: 12,
        labe: "December"
      },

      ],

    }

    this.textInput = React.createRef();

  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  componentDidMount() {
    var loadScript = function (src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    }
    loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
    //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')
    this.setState({ isNext: true, isPrevious: true })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    this.fetchToday(page, perPage, data);

    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
    this.fetchFilter()

    API.get("/community")
      .then((response) => {
        if (response.data.success) {
          this.setState({ communities: response.data.communities })
        }
      })



    API.get("/all_agents")
      .then((response) => {
        if (response.data.success) {
          this.setState({ agents: response.data.agents })
        }
      })

  }



  fetchFilter = () => {
    API.get("/viewing_filters")
      .then((response) => {
        if (response.data.success) {
          this.setState({ filters: response.data.filters })
        }
      })
  }

  fetchToday = (page, perPage, data) => {
    this.setState({ todayLoading: true })
    //  setTimeout(() => {


    API.post(`/all_listings_leads_today_viewings?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {




        await response.data;
        this.setState({
          all_today_viewings: response.data.all_today_viewings,
          todayLoading: false
        })

      })
    //  }, 300);

  }

  fetchData = (page, perPage, data) => {
    this.setState({ isLoading: true })
    //  setTimeout(() => {


    API.post(`/all_listings_leads_viewings?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {
        console.log(response.data)



        await response.data;
        this.setState({
          all_upcomming_viewings: response.data.all_upcomming_viewings,
          isLoading: false
        })

      })
    //  }, 300);

  }

  fetchPreviousData = (page, perPage, data) => {
    this.setState({ isPreviousLoading: true })
    //  setTimeout(() => {


    API.post(`/all_listings_leads_viewings_prev?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {
        console.log(response.data)



        await response.data;
        this.setState({
          all_previous_viewings: response.data.all_previous_viewings,
          isPreviousLoading: false
        })

      })
    //  }, 300);

  }
  handleCommunityFilter = (e) => {


    this.setState({ community_filter: e })

    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })

    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      community_filter: e,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }

    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handleSubCommunityFilter = (e) => {

    this.setState({ sub_community_filter: e })
    API.get("/property/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ properties: response.data.properties })
      })
    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: e,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handlePropertyFilter = (e) => {
    this.setState({ property_filter: e })
    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: e,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handleRefnoFirstValue_id = (e) => {
    this.setState({ refno_first_value_id: e.target.value })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: e.target.value,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_first_value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleRefnoFirst = (e) => {

    const refno_first_value = e.target.value;
    this.setState({ refno_first_value: refno_first_value })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    const page = 1;
    const perPage = 10;
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleUnitnoFirstValue_id = (e) => {
    this.setState({ unitno_first_value_id: e.target.value })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: e.target.value,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_first_value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleUnitnoFirst = (e) => {

    const unitno_first_value = e.target.value;
    this.setState({ unitno_first_value: unitno_first_value })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }

    const page = 1;
    const perPage = 10;
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleStatusFilter = (e) => {
    this.setState({ status_filter: e })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: e,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }

    const page = 1;
    const perPage = 10;
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  handleAgentValue_id = (e) => {

    this.setState({ agent_value_id: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }
    if (this.state.agent_value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleAgent = (e) => {

    this.setState({ agent_value: e });
    console.log("***** VALUE *** ", e)

    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handleClientValue_id = (e) => {

    this.setState({ client_value_id: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: e.target.value,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }
    if (this.state.client_value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleClient = (e) => {
    this.setState({ client_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: e.target.value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,

    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handleRangeDate = (value, dateString) => {
    // console.log("******Date String**** ", dateString);
    // console.log("******value String**** ", value);
    this.setState({ date_range: value !== null ? [dayjs(dateString[0]), dayjs(dateString[1])] : dateString, date_range_status: value })
    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  // Previous Viewings...
  handlePreviousRecord_Viewings = () => {
    const page = 1;
    const perPage = 10;
    // const dateRange = ['1970-01-01', this.formatDate(new Date())]; // Date method change by Farooq
    const dateRange = ['1970-01-01', moment().add(-1, 'days').format('YYYY-MM-DD')];
    const val1 = dayjs('1970-01-01');
    // const val2 = this.formatDate(new Date()); // Date method change by Farooq
    const val2 = dayjs(moment().add(-1, 'days').format('YYYY-MM-DD'));

    // console.log("****** Date Range******** ", dateRange);
    // console.log("****** Date Range Status******** ", [val1, val2]);

    this.setState({
      date_range: dateRange,
      date_range_status: "date_range_status"
    })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      date_range: dateRange,
      date_range_status: "date_range_status",

      back_btn: "previous",
    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  // Next Viewings...
  handleNextRecord_Viewings = () => {
    const page = 1;
    const perPage = 10;
    const dateRange = [this.formatDate(new Date()), '2050-12-12'];
    const val1 = dayjs(this.formatDate(new Date()));
    const val2 = dayjs('2050-12-12');

    this.setState({
      date_range: dateRange,
      date_range_status: "date_range_status"
    })

    //  console.log("****** Date Range******** ", dateRange);
    // console.log("****** Date Range Status******** ", [val1, val2]);

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      date_range: dateRange,
      date_range_status: "date_range_status",
      back_btn: "next",
    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  handleViewRefNo = (view_id, e) => {
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showCommission: response.data.viewings.status == "Offer" ? true : false,
            // showDate: response.data.viewings.status == "Second Viewing" ? true : false,
            viewing_price: response.data.viewings.amount, isView: true,
            view_id: view_id,
            view_date: response.data.viewings.view_date
          })
        }
        let admin = false;
        if (response.data.role.role == "Admin" || response.data.role.role == "Super Admin") {
          admin = true;
        } else if (response.data.viewings.status == "Second Viewing") {
          admin = true;
        } else {
          admin = false;
        }
        this.setState({ showDate: admin });

      })


  }

  handleChangeStatus = () => {
    const page = 1
    const perPage = 10

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    const data2 = { view_id: this.state.view_id, viewing_date: this.state.viewing_date, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price, note: this.state.viewing_note, viewing_commission: this.state.viewing_commission, view_date: this.state.view_date }
    API.post("/change_all_viewing_status", data2)
      .then((response) => {

        if (response.data.success) {
          this.fetchToday(page, perPage, data)
          this.fetchData(page, perPage, data)
          this.fetchPreviousData(page, perPage, data);
        }
      })
    this.setState({ isView: false })
  }

  handleCancelViewing = () => {
    this.setState({ isView: false })
  }

  // Reset All Filters
  resetAllFilters = () => {
    this.setState({
      month: "",
      viewing_feedback: 0,
      property_for_filter: [],
      status_filter: [],
      refno_first_value_id: 1,
      refno_first_value: "",
      date_range: "",
      unitno_first_value_id: 1,
      unitno_first_value: "",
      client_value_id: 1,
      client_value: "",
      agent_filter: [],
      vendor_agent_filter: [],
      date_range: "",
      date_range_status: null,
    });

    const page = 1;
    const perPage = 10;

    const data = {
      month: "",
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: 0,

      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: 1,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: "",
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: 1,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: "",
      unitno_second_value: this.state.unitno_second_value,
      status_filter: [],
      agent_filter: [],
      vendor_agent_filter: [],
      client_value_id: 1,
      client_value: "",
      date_range: "",
      date_range_status: null,
    }

    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  handleCancel = () => {
    this.setState({ isView: false })
  }

  handleViewStatus = (e) => {
    const status = e.target.value
    this.setState({ viewing_status: e.target.value })
    if (status == "Offer") {
      this.setState({ showPrice: true, showCommission: true })
    } else {
      this.setState({ showPrice: false, showCommission: false })
    }

    if (status == "Second Viewing") {
      this.setState({ showDate: true })
    } else {
      this.setState({ showDate: false })
    }
  }

  handleViewingPrice = (e) => {
    this.setState({ viewing_price: e.target.value })
  }
  handleViewingCommission = (e) => {
    this.setState({ viewing_commission: e.target.value })
  }
  handleViewingNote = (e) => {
    this.setState({ viewing_note: e.target.value })
  }

  handleViewingDate = (date, dateString) => {
    this.setState({ viewing_date: dateString })
  };

  handleDeleteViewing = (view_id, e) => {
    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: this.state.viewing_feedback,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }
    if (view_id == "" || view_id < 1)
      return
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.delete("/delete_viewing/" + view_id)
          .then((response) => {

            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchToday(page, perPage, data)
              this.fetchData(page, perPage, data)
              this.fetchPreviousData(page, perPage, data);
            }

          })






      }
    })
  }


  handleViewingFeedback = (e) => {

    this.setState({ viewing_feedback: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,

      viewing_feedback: e.target.value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }


  handlePropertyFor = (e) => {

    this.setState({ property_for_filter: e })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: e,
      viewing_feedback: this.state.viewing_feedback,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleNewFilter = () => {
    this.setState({ isFilterOpen: true })

  }

  handleCancel = () => {
    this.setState({ isFilterOpen: false, filter_title: '' })
  };

  handleAddRow = (value) => {
    const item = {
      name: "",
      mobile: "",
      id: value

    };

    let filter_options = this.state.filter_options.filter(item => item.value !== value)
    this.setState({
      rows: [...this.state.rows, item],
      filter_options: filter_options
    });
  };

  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };

  handleRemoveSpecificRow = (idx, id) => () => {
    const rows = [...this.state.rows]
    if (id == 1) {
      const item = {
        value: '1',
        label: 'Contact type'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    } else
      if (id == 2) {
        const item = {
          value: '2',
          label: 'Status'
        }

        this.setState({
          filter_options: [...this.state.filter_options, item]
        })
      } else
        if (id == 3) {
          const item = {
            value: '3',
            label: 'Date range'
          }

          this.setState({
            filter_options: [...this.state.filter_options, item],
            price_first_value_id: 0,
            price_first_value: 0,
            price_min: 0,
            price_max: 0
          })
        } else
          if (id == 4) {
            const item = {
              value: '4',
              label: 'Property for'
            }

            this.setState({
              filter_options: [...this.state.filter_options, item]
            })
          } else
            if (id == 5) {
              const item = {
                value: '5',
                label: 'Agent'
              }

              this.setState({
                filter_options: [...this.state.filter_options, item]
              })
            }

    rows.splice(idx, 1)

    this.setState({ rows })
  }

  handleFilterTitle = (e) => {
    this.setState({ filter_title: e.target.value, titleHasError: false })
  }


  handleOk = () => {

    if (this.state.filter_title == "") {

      this.setState({ titleHasError: true })
      return
    }

    const data = {
      filter_title: this.state.filter_title,

      contact_type_filter: this.state.contact_type_filter,
      status_filter: this.state.status_filter,
      property_for_filter: this.state.property_for_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      date_value_id: this.state.date_value_id,
      date_value: this.state.date_value,
      date_lessthan_option: this.state.date_lessthan_option,
      date_morethan_option: this.state.date_morethan_option

    }



    API.post("/add_viewing_filter", data)
      .then((response) => {




        //return
        if (response.data.success) {
          this.setState({ isFilterOpen: false, filter_title: "" })
          this.fetchFilter();

        }
      })
  };

  handleAgentFilter = (e) => {


    this.setState({ agent_filter: e })
    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: e,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleVendorAgentFilter = (e) => {
    this.setState({ vendor_agent_filter: e })
    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      vendor_agent_filter: e,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }
    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleContactType = (e) => {
    this.setState({ contact_type_filter: e })
  }

  handleDateValue = (e) => {
    this.setState({ date_value: e.target.value })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: e.target.value,
      date_value_id: this.state.date_value_id,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    const page = 1;
    const perPage = 10;
    if (e.target.value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleDateValue_id = (e) => {
    this.setState({ date_value_id: e.target.value })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: e.target.value,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
      //

    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleDeleteFilter = (targetKey, action) => {



    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_viewing_filter/" + targetKey)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchFilter()
            }
          })
      }
    })
  };

  handleMonthTabs = (e, event) => {

    this.setState({ month: e })

    let newDate = new Date()
    let month = newDate.getMonth() + 1;

    const page = 1;
    const perPage = 10;

    const data = {
      month: e,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,

      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      date_range: this.state.date_range,
      date_range_status: this.state.date_range_status,
    }

    if (e == month) {
      this.setState({ isNext: true, isPrevious: true, date_range_status: "date" })
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data)
      this.fetchPreviousData(page, perPage, data)

    } else
      if (e > month) {
        this.setState({ isNext: true, isPrevious: false })
        this.fetchToday(page, perPage, data)
        this.fetchData(page, perPage, data)
        //this.fetchPreviousData(page,perPage,data)

      } else
        if (e < month) {

          this.setState({ isNext: false, isPrevious: true })
          //this.fetchData(page,perPage,data)
          this.fetchToday(page, perPage, data)
          this.fetchPreviousData(page, perPage, data)

        }


    // const page=1
    // const perPage =10
    // const data = {month:e,selectedAgents:this.state.selectedAgents}
    // this.setState({month:e})
    // this.fetchTotal(data)
    // this.fetchData(page,perPage,data)
  }

  handleTabs = (e, event) => {




    const page = 1;
    const perPage = 10;
    let filter_options = []
    this.setState({ isLoading: true })

    API.get("/get_saved_viewing_filter/" + e)
      .then((response) => {




        const response_filter = response.data.filter

        const contact_types_filter = response.data.contact_types_filter
        const property_for_filter = response.data.property_for_filter
        const status_filter = response.data.status_filter
        const agent_filter = response.data.agent_filter


        this.setState({
          month: this.state.month,
          property_for_filter: property_for_filter,
          status_filter: status_filter,
          agent_filter: agent_filter,
          vendor_agent_filter: this.state.vendor_agent_filter,
          date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
          date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
          date_value: response_filter.date_value,
          date_value_id: response_filter.date_value_id
        })

        const data = {
          month: this.state.month,
          property_for_filter: property_for_filter,
          viewing_feedback: this.state.viewing_feedback,
          // date_range_status:this.state.date_range_status,
          // date_range:this.state.date_range,
          community_filter: this.state.community_filter,
          sub_community_filter: this.state.sub_community_filter,
          property_filter: this.state.property_filter,
          refno_first_value_id: this.state.refno_first_value_id,
          refno_and_or_option: this.state.refno_and_or_option,
          refno_second_value_id: this.state.refno_second_value_id,
          refno_first_value: this.state.refno_first_value,
          refno_second_value: this.state.refno_second_value,
          unitno_first_value_id: this.state.unitno_first_value_id,
          unitno_and_or_option: this.state.unitno_and_or_option,
          unitno_second_value_id: this.state.unitno_second_value_id,
          unitno_first_value: this.state.unitno_first_value,
          unitno_second_value: this.state.unitno_second_value,
          status_filter: status_filter,
          agent_filter: agent_filter,
          vendor_agent_filter: this.state.vendor_agent_filter,
          client_value_id: this.state.client_value_id,
          client_value: this.state.client_value,
          date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
          date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
          date_value: response_filter.date_value,
          date_value_id: response_filter.date_value_id,
        }

        this.fetchToday(page, perPage, data)
        this.fetchData(page, perPage, data);
        this.fetchPreviousData(page, perPage, data);
      })





  }

  handleDateMorethan = (e) => {
    this.setState({ date_morethan_option: true, date_lessthan_option: false })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      // date_range_status:this.state.date_range_status,
      // date_range:this.state.date_range,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,



      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }

  }

  handleDateLessthan = (e) => {
    this.setState({ date_morethan_option: false, date_lessthan_option: true })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      // date_range_status:this.state.date_range_status,
      // date_range:this.state.date_range,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      agent_filter: this.state.agent_filter,
      vendor_agent_filter: this.state.vendor_agent_filter,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,



      date_morethan_option: false,
      date_lessthan_option: true,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '') {
      this.fetchToday(page, perPage, data)
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }

  }

  handleClearFilter = () => {
    this.setState({
      month: "",
      property_for_filter: [],
      viewing_feedback: 0,

      community_filter: "",
      sub_community_filter: "",
      property_filter: "",
      refno_first_value_id: 1,
      refno_and_or_option: true,
      refno_second_value_id: 1,
      refno_first_value: "",
      refno_second_value: "",
      unitno_first_value_id: 1,
      unitno_and_or_option: false,
      unitno_second_value_id: 1,
      unitno_first_value: "",
      unitno_second_value: "",
      status_filter: [],
      agent_filter: [],
      vendor_agent_filter: [],
      client_value_id: 1,
      client_value: "",
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,
      date_range: "",
      date_range_status: null,
    })
    const page = 1;
    const perPage = 10;
    const data = {
      month: "",
      property_for_filter: [],
      viewing_feedback: 0,

      community_filter: "",
      sub_community_filter: "",
      property_filter: "",
      refno_first_value_id: 1,
      refno_and_or_option: true,
      refno_second_value_id: 1,
      refno_first_value: "",
      refno_second_value: "",
      unitno_first_value_id: 1,
      unitno_and_or_option: false,
      unitno_second_value_id: 1,
      unitno_first_value: "",
      unitno_second_value: "",
      status_filter: [],
      agent_filter: [],
      vendor_agent_filter: [],
      client_value_id: 1,
      client_value: "",
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,
      date_range: "",
      date_range_status: null,

    }

    this.fetchToday(page, perPage, data)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);

  }





  render() {
    {/* Responsive Filters List Roll 1(Reason for this is as ive to change Styling in Responsiveness and instead of implementing same logic for res i make common objects so if we have to change that change also implement on responsive layout) by Hassan 8/13/24 */ }
    const StatusFilter = (

      <Select
        mode={'multiple'}
        showSearch
        value={this.state.status_filter}
        allowClear
        style={{
          width: '100%',
        }}

        placeholder="Search"
        onChange={this.handleStatusFilter}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          {
            value: 'Second Viewing',
            label: 'Second Viewing',
          },
          {
            value: 'Not Interested',
            label: 'Not Interested',
          },
          {
            value: 'Unsuccessful',
            label: 'Unsuccessful',
          },
          {
            value: 'Considering',
            label: 'Considering',
          },
          {
            value: 'Offer',
            label: 'Offer',
          },
        ]}
      />

    );

    const propertyRefNo = (<>
      <select class="form-select " aria-label="Default select example" name='refno_first_filter_value_id' value={this.state.refno_first_value_id} onChange={this.handleRefnoFirstValue_id}>
        <option value="1" selected>Contain</option>
        <option value="2">Not contain</option>
        <option value="3">Equals</option>
        <option value="4">Not equal</option>
        <option value="5">Starts with</option>
        <option value="6">Ends with</option>
      </select>
      <div className='mt-2'>
        <input type="text" className="form-control" value={this.state.refno_first_value} onChange={this.handleRefnoFirst} placeholder="Type the LP number" />
      </div>
      {this.state.refno_active_second_option ? (<div className='mt-2' >
        <center>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
            <label class="form-check-label" for="flexRadioDefault1">
              AND
            </label>
            <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
            <label class="form-check-label ml-4" for="flexRadioDefault1">
              OR
            </label>

          </div>
        </center>
        <select class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
        </div>

      </div>) : null}
    </>
    )

    const ClientRefNo = (
      <>
        <select class="form-select pt-2" aria-label="Default select example" name='unitno_first_filter_value_id' value={this.state.client_value_id} onChange={this.handleClientValue_id}>
          <option value="1" selected>Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" value={this.state.client_value} onChange={this.handleClient} placeholder="filter..." />
        </div></>
    )

    const AgentName = (
      <Select className='form-select p-0 noborder m-0 font-14'
        mode="multiple"
        showSearch
        allowClear
        maxTagCount='responsive'
        style={{
          width: '100%',
        }}

        placeholder="Select Agent"
        onChange={this.handleAgentFilter}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }

        options={this.state.agents}
      />
    )

    const ViewingFeedback = (
      <select value={this.state.viewing_feedback} className='form-select' onChange={this.handleViewingFeedback}>
        <option value="0" selected>All</option>
        <option value="1">Note is empty</option>
        <option value="2">Note is not empty</option>
      </select>
    )

    const DateFilter = (
      <>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
          <label class="form-check-label font-14" for="flexRadioDefault1">
            Less than
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
          <label class="form-check-label font-14" for="flexRadioDefault2">
            More than
          </label>
        </div>

        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <input type="text" className="form-control" onChange={this.handleDateValue} />
          <select className='form-select ml-1' onChange={this.handleDateValue_id}>
            <option value="1">days</option>
            <option value="2">weeks</option>
            <option value="3">months</option>
            <option value="4">years</option>
          </select>
        </div></>
    )

    const PropertyFor = (
      <Select
        mode="multiple"
        maxTagCount='responsive'
        style={{
          width: '100%',
        }}

        placeholder="Please select"
        onChange={this.handlePropertyFor}


        options={[
          {
            value: 'sale',
            label: 'Sale',
          },
          {
            value: 'rent',
            label: 'Rent',
          },
          {
            value: 'tenanted',
            label: 'Tenanted',
          },
          {
            value: 'short-term',
            label: 'Short-term',
          },
        ]}
      />
    )

    const UnitNo = (
      <>
        <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' value={this.state.unitno_first_value_id} onChange={this.handleUnitnoFirstValue_id}>
          <option value="1" selected>Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" value={this.state.unitno_first_value} onChange={this.handleUnitnoFirst} placeholder="Search" />
        </div></>
    )

    const VendorAgent = (
      <Select className='form-select p-0 noborder m-0 font-14'
        mode="multiple"
        showSearch
        allowClear
        maxTagCount='responsive'
        style={{
          width: '100%',
        }}

        placeholder="Select Agent"
        onChange={this.handleVendorAgentFilter}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }

        options={this.state.agents}
      />
    )




    //Filters Listing of Column1 

    const StatusFilterAnother = (
      <Select
        mode={'multiple'}
        showSearch
        allowClear
        style={{
          width: '100%',
        }}

        placeholder="Search"
        onChange={this.handleStatusFilter}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          {
            value: 'Second Viewing',
            label: 'Second Viewing',
          },
          {
            value: 'Not Interested',
            label: 'Not Interested',
          },
          {
            value: 'Unsuccessful',
            label: 'Unsuccessful',
          },
          {
            value: 'Considering',
            label: 'Considering',
          },
          {
            value: 'Offer',
            label: 'Offer',
          },
        ]}
      />

    )

    const RefNoAnother = (
      <>
        <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
        </div>
        {this.state.refno_active_second_option ? (<div className='mt-2' >
          <center>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
              <label class="form-check-label" for="flexRadioDefault1">
                AND
              </label>
              <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
              <label class="form-check-label ml-4" for="flexRadioDefault1">
                OR
              </label>

            </div>
          </center>
          <select class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
            <option value="1">Contain</option>
            <option value="2">Not contain</option>
            <option value="3">Equals</option>
            <option value="4">Not equal</option>
            <option value="5">Starts with</option>
            <option value="6">Ends with</option>
          </select>
          <div className='mt-2'>
            <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
          </div>

        </div>) : null}
      </>
    )

    const PropertyForAnother = (
      <Select
        mode="multiple"
        maxTagCount='responsive'
        style={{
          width: '100%',
        }}

        placeholder="Please select"
        onChange={this.handlePropertyFor}


        options={[
          {
            value: 'sale',
            label: 'sale',
          },
          {
            value: 'rent',
            label: 'rent',
          },
          {
            value: 'tenanted',
            label: 'tenanted',
          },
          {
            value: 'short-term',
            label: 'short-term',
          },
        ]}
      />
    )

    const UnitNoAnother = (<>
      <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
        <option value="1">Contain</option>
        <option value="2">Not contain</option>
        <option value="3">Equals</option>
        <option value="4">Not equal</option>
        <option value="5">Starts with</option>
        <option value="6">Ends with</option>
      </select>
      <div className='mt-2'>
        <input type="text" className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." />
      </div>
    </>)

    const ClientName = (<>
      <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleClientValue_id}>
        <option value="1">Contain</option>
        <option value="2">Not contain</option>
        <option value="3">Equals</option>
        <option value="4">Not equal</option>
        <option value="5">Starts with</option>
        <option value="6">Ends with</option>
      </select>
      <div className='mt-2'>
        <input type="text" className="form-control" onChange={this.handleClient} placeholder="filter..." />
      </div></>)

    const viewingFeedbackanother = (
      <select value={this.state.viewing_feedback} className='form-select' onChange={this.handleViewingFeedback}>
        <option value="0" selected>All</option>
        <option value="1">Note is empty</option>
        <option value="2">Note is not empty</option>
      </select>
    )
    const authentication = store.getState((state) => state);
    const user = authentication.auth.user
    const role = user.role
    const { viewing_date } = this.state;
    const val1 = dayjs(viewing_date ? viewing_date : "");


    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      // setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      // selectedRowKeys,
      onChange: onSelectChange,

    };

    // Common function for sort Validator, by farooq
    const sortValidator = (a, b, aVar, bVar) => {
      if (a && aVar && aVar.length && b && bVar && bVar.length) {
        return aVar.length - bVar.length;
      } else if (a && aVar && aVar.length) {
        // That means be has null rechargeType, so a will come first.
        return -1;
      } else if (b && bVar && bVar.length) {
        // That means a has null rechargeType so b will come first.
        return 1;
      }

      // Both rechargeType has null value so there will be no order change.
      return 0;
    },



      columns = [
        {
          title: "Status", key: "status",
          dataIndex: "status", fixed: 'left', width: 150,
          className: "viewstat",
          // sorter: (a, b) => a.status.length - b.status.length,
          // sorter: (a, b) => {
          //   if (a && a.status && a.status.length && b && b.status && b.status.length) {
          //     return a.status.length - b.status.length;
          //   } else if (a && a.status && a.status.length) {
          //     // That means be has null rechargeType, so a will come first.
          //     return -1;
          //   } else if (b && b.status && b.status.length) {
          //     // That means a has null rechargeType so b will come first.
          //     return 1;
          //   }

          //   // Both rechargeType has null value so there will be no order change.
          //   return 0;
          // },
          sorter: (a, b) => sortValidator(a, b, a.status, b.status),
          render: (text, record) => {
            var pStatus = ''
            var color = ''
            switch (record.status) {
              case 'Offer':
                pStatus = 'Offer'
                color = 'viewings'
                break;
              case 'Not Interested':
                pStatus = 'Not Interested'
                color = 'cancelled';
                break;
              case 'Second Viewing':
                pStatus = 'Second Viewing'
                color = 'unsuccessful';
                break;
              case 'Considering':
                pStatus = 'Considering'
                color = 'successful';
                break;
              case 'Scheduled':
                pStatus = 'Scheduled'
                color = 'scheduled';
                break;


              default:
                pStatus = 'Scheduled'
                color = 'scheduled';
                break;
            }
            return (
              <div className={color + " pstat"} key={pStatus}>
                {pStatus}
              </div>
            );
          },
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Status
              {StatusFilter}
            </div>
          ),

        },
        {
          title: 'Property', fixed: 'left', width: 280,
          dataIndex: 'refno', key: 'refno',
          // sorter: (a, b) => a.refno.length - b.refno.length,
          // sorter: (a, b) => {
          //   if (a && a.community_title && a.community_title.length && b && b.community_title && b.community_title.length) {
          //     return a.community_title.length - b.community_title.length;
          //   } else if (a && a.community_title && a.community_title.length) {
          //     // That means be has null rechargeType, so a will come first.
          //     return -1;
          //   } else if (b && b.community_title && b.community_title.length) {
          //     // That means a has null rechargeType so b will come first.
          //     return 1;
          //   }

          //   // Both rechargeType has null value so there will be no order change.
          //   return 0;
          // },
          sorter: (a, b) => sortValidator(a, b, a.community_title, b.community_title),
          render: (text, record) => (

            <Link onClick={this.handleViewRefNo.bind(this, record.views_id)} className="d-flex flex-column">
              <span className="propref">{record.refno}</span>
              <span className="propinfo">
                {record.property_title == null ? "" : record.property_title + ", "}
                {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                {record.community_title == null ? "" : record.community_title}</span>
            </Link>
          ),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Ref No</span>


              {propertyRefNo}

            </div>
          ),
        },
        {
          title: "Client", fixed: 'left', width: 180, key: 'client', className: 'clientwrap',
          render: (text, record) => (
            <>
              {record.leads_id ? <a href={liveUrl + "/leads/" + record.leads_id} target="_blank">{text}</a> : text}
            </>
          ),
          dataIndex: 'client_name',
          // sorter: (a, b) => a.client_name.length - b.fulclient_namelname.length,
          // sorter: (a, b) => {
          //   if (a && a.client_name && a.client_name.length && b && b.client_name && b.client_name.length) {
          //     return a.client_name.length - b.client_name.length;
          //   } else if (a && a.client_name && a.client_name.length) {
          //     // That means be has null rechargeType, so a will come first.
          //     return -1;
          //   } else if (b && b.client_name && b.client_name.length) {
          //     // That means a has null rechargeType so b will come first.
          //     return 1;
          //   }

          //   // Both rechargeType has null value so there will be no order change.
          //   return 0;
          // },
          sorter: (a, b) => sortValidator(a, b, a.client_name, b.client_name),

          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Client name</span>
              {ClientRefNo}

            </div>
          ),
        },
        {
          title: "Client Agent", width: 180, fixed: "left",
          dataIndex: 'fullname',
          sorter: (a, b) => sortValidator(a, b, a.fullname, b.fullname),

          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff', minWidth: 300,
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Agent name</span>

              {AgentName}
            </div>
          ),
        },
        {
          title: "Viewing Feedback", width: 600,
          dataIndex: 'note',
          className: 'noteswrap',
          // sorter: (a, b) => a.note.length - b.note.length,
          // sorter: (a, b) => {
          //   if (a && a.note && a.note.length && b && b.note && b.note.length) {
          //     return a.note.length - b.note.length;
          //   } else if (a && a.note && a.note.length) {
          //     // That means be has null rechargeType, so a will come first.
          //     return -1;
          //   } else if (b && b.note && b.note.length) {
          //     // That means a has null rechargeType so b will come first.
          //     return 1;
          //   }

          //   // Both rechargeType has null value so there will be no order change.
          //   return 0;
          // },
          sorter: (a, b) => sortValidator(a, b, a.note, b.note),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Viewing feedback</span>


              {ViewingFeedback}

            </div>
          ),
        },
        {
          title: "Date", width: 200,
          dataIndex: "view_date",
          render: (text, record) => {
            return <>
              {
                record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                  ''
                  :
                  <>
                    <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                    {/*<span className="px-2">|</span>*/}
                    {/*<Moment format="hh:mm A">{record.view_date}</Moment>*/}
                  </>
              }
            </>


          },
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Date</span>

              {DateFilter}



            </div>
          ),
        },
        {
          title: 'For', width: 120,
          dataIndex: 'property_for',
          sorter: (a, b) => {
            if (a && a.property_for && a.property_for.length && b && b.property_for && b.property_for.length) {
              return a.property_for.length - b.property_for.length;
            } else if (a && a.property_for && a.property_for.length) {
              return -1;
            } else if (b && b.property_for && b.property_for.length) {
              return 1;
            }
            return 0;
          },
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Property for</span>

              {PropertyFor}

            </div>
          ),

        },



        {
          title: "Unit no", width: 150,
          dataIndex: "unitno",
          sorter: (a, b) => sortValidator(a, b, a.unitno, b.unitno),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Unit No</span>
              {UnitNo}

            </div>
          ),
        },
        {
          title: "Vendor Agent", width: 200,
          dataIndex: 'vendor_agent',
          // sorter: (a, b) => a.fullname.length - b.fullname.length,
          // sorter: (a, b) => {
          //   if (a && a.fullname && a.fullname.length && b && b.fullname && b.fullname.length) {
          //     return a.fullname.length - b.fullname.length;
          //   } else if (a && a.fullname && a.fullname.length) {
          //     // That means be has null rechargeType, so a will come first.
          //     return -1;
          //   } else if (b && b.fullname && b.fullname.length) {
          //     // That means a has null rechargeType so b will come first.
          //     return 1;
          //   }

          //   // Both rechargeType has null value so there will be no order change.
          //   return 0;
          // },
          sorter: (a, b) => sortValidator(a, b, a.fullname, b.fullname),

          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff', minWidth: 300,
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '15px' }}>Vendor Agent</span>

              {VendorAgent}
            </div>
          ),
        },





        {
          title: 'Action', width: 80,
          render: (text, record) => (
            <div className="ms-auto">
              <a className="btn btn-secondary btn-sm m-2" style={{ color: 'white', border: 'none', background: 'transparent' }} onClick={this.handleDeleteViewing.bind(this, record.views_id)}  ><i className="fa fa-trash fs-6 text-black" ></i></a>
            </div>
          ),
        },

      ]


    const columnsResponsiveThis = [
      {
        title: "Status", key: "status",
        render: (text, record) => {
          var pStatus = ''
          var color = ''
          switch (record.status) {
            case 'Offer':
              pStatus = 'Offer'
              color = 'viewings'
              break;
            case 'Not Interested':
              pStatus = 'Not Interested'
              color = 'cancelled';
              break;
            case 'Second Viewing':
              pStatus = 'Second Viewing'
              color = 'unsuccessful';
              break;
            case 'Considering':
              pStatus = 'Considering'
              color = 'successful';
              break;
            case 'Scheduled':
              pStatus = 'Scheduled'
              color = 'scheduled';
              break;


            default:
              pStatus = 'Scheduled'
              color = 'scheduled';
              break;
          }
          return (<>
            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                    <div className={color + " pstat"} key={pStatus}>
                      {pStatus}
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    <div className="ms-auto">
                      <a className="btn btn-secondary btn-sm m-2" style={{ color: 'white', border: 'none', background: 'transparent' }} onClick={this.handleDeleteViewing.bind(this, record.views_id)}  ><i className="fa fa-trash fs-6 text-black" ></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <Link className="btn-text listinginfo" onClick={this.handleViewRefNo.bind(this, record.views_id)}>
                <div className='row alignCenter borderGray pt-2 pb-2'>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>

                      <span className="propref">{record.refno}</span>
                      <span className="propinfo">
                        {record.property_title == null ? "" : record.property_title + ", "}
                        {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                        {record.community_title == null ? "" : record.community_title}</span>
                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Unit No:</p>
                        <p>{record.unitno ? record.unitno : null}</p>
                      </div>

                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Date:</p>
                        <p>
                          {
                            record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                              ''
                              :
                              <>
                                <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                                {/*<span className="px-2">|</span>*/}
                                {/*<Moment format="hh:mm A">{record.view_date}</Moment>*/}
                              </>
                          }

                        </p>
                      </div>
                    </div>

                  </div>



                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Client:</p>
                        <p>{record.client_name ? record.client_name : 'New Client'}</p>
                      </div>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Client Agent:</p>
                        <p>{record.fullname ? record.fullname : null}</p>
                      </div>

                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>For:</p>
                        <p>{record.property_for ? record.property_for : null}</p>
                      </div>

                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Vendor Agent:</p>
                        <p>{record.vendor_agent ? record.vendor_agent : null}</p>
                      </div>
                    </div>

                  </div>

                  <div className='col-12'>
                    <div className='BodyFirstRowRight'>
                      <p className='info-title'>Viewing Feedback:</p>
                      <p>{record.note ? record.note : '--'}</p>
                    </div>
                  </div>
                </div>

              </Link>

            </div>
          </>)
        }
      }
    ]




    const columns1 = [
      {
        title: "Status",
        dataIndex: "status",
        className: "viewstat",
        // sorter: (a, b) => a.status.length - b.status.length,
        sorter: (a, b) => sortValidator(a, b, a.status, b.status),
        render: (text, record) => {
          var pStatus = ''
          var color = ''
          switch (record.status) {
            case 'Offer':
              pStatus = 'Offer'
              color = 'viewings'
              break;
            case 'Not Interested':
              pStatus = 'Not Interested'
              color = 'cancelled';
              break;
            case 'Second Viewing':
              pStatus = 'Second Viewing'
              color = 'unsuccessful';
              break;
            case 'Considering':
              pStatus = 'Considering'
              color = 'successful';
              break;
            case 'Scheduled':
              pStatus = 'Scheduled'
              color = 'scheduled';
              break;


            default:
              pStatus = 'Scheduled'
              color = 'scheduled';
              break;
          }
          return (
            <div className={color + " pstat"} key={pStatus}>
              {pStatus}
            </div>
          );
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Status
            {StatusFilterAnother}

          </div>
        ),

      },
      {
        title: 'Property',
        dataIndex: 'refno',

        // sorter: (a, b) => a.refno.length - b.refno.length,
        sorter: (a, b) => sortValidator(a, b, a.community_title, b.community_title),
        render: (text, record) => (

          <Link onClick={this.handleViewRefNo.bind(this, record.views_id)} className="d-flex flex-column">
            <span className="propref">{record.refno}</span>
            <span className="propinfo">
              {record.property_title == null ? "" : record.property_title + ", "}
              {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
              {record.community_title == null ? "" : record.community_title}</span>
          </Link>
        ),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Ref no

            {RefNoAnother}

          </div>
        ),
      },

      {
        title: 'Property for',
        dataIndex: 'property_for',
        sorter: (a, b) => {
          if (a && a.property_for && a.property_for.length && b && b.property_for && b.property_for.length) {
            return a.property_for.length - b.property_for.length;
          } else if (a && a.property_for && a.property_for.length) {
            return -1;
          } else if (b && b.property_for && b.property_for.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Property for

            {PropertyForAnother}

          </div>
        ),

      },

      // {
      //     title: "Date",
      //     dataIndex:"view_date",
      //     sorter: (a, b) => a.view_date.length - b.view_date.length,
      //     filterDropdown: (
      //       <div style={{ padding: '8px',
      //         borderRadius: '6px',
      //         background: '#fff',
      //         boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
      //          Date
      //          <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />
      //
      //
      //           </div>
      //     ),
      //   },
      // {
      //   title: "Address",
      //   dataIndex:"community_title",
      //   sorter: (a, b) => a.community_title.length - b.community_title.length,
      //   filterDropdown: (
      //       <div style={{ padding: '8px',
      //         borderRadius: '6px',
      //         background: '#fff',
      //         boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
      //          Community
      //           <Select  
      //           allowClear
      //           showSearch       
      //          style={{
      //          width: '100%',
      //           }}

      //           placeholder="Search"
      //           onChange={this.handleCommunityFilter}
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //             }
      //           options={this.state.communities}
      //           />


      //           </div>
      //     ),
      // },  
      // {
      //   title: "Subcommunity",
      //   dataIndex:"sub_community_title",
      //   sorter: (a, b) => a.sub_community_title.length - b.sub_community_title.length,
      //   filterDropdown: (
      //       <div style={{ padding: '8px',
      //         borderRadius: '6px',
      //         background: '#fff',
      //         boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
      //          Sub Community
      //           <Select  
      //           showSearch   
      //           allowClear    
      //          style={{
      //          width: '100%',
      //           }}

      //           placeholder="Search"
      //           onChange={this.handleSubCommunityFilter}
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //             }
      //           options={this.state.sub_communities}
      //           />


      //           </div>
      //     ),
      // },  
      // {
      //   title: "Property",
      //   dataIndex:"property_title",
      //   sorter: (a, b) => a.property_title.length - b.property_title.length,
      //   filterDropdown: (
      //       <div style={{ padding: '8px',
      //         borderRadius: '6px',
      //         background: '#fff',
      //         boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
      //          Property
      //           <Select  
      //           showSearch   
      //           allowClear    
      //          style={{
      //          width: '100%',
      //           }}

      //           placeholder="Search"
      //           onChange={this.handlePropertyFilter}
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //             }
      //           options={this.state.properties}
      //           />


      //           </div>
      //     ),
      // },  

      {
        title: "Unit no",
        dataIndex: "unitno",
        // sorter: (a, b) => a.unitno.length - b.unitno.length,
        sorter: (a, b) => sortValidator(a, b, a.unitno, b.unitno),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Unit no
            {UnitNoAnother}
          </div>
        ),
      },
      {
        title: "Client",
        dataIndex: 'client_name',
        // sorter: (a, b) => a.client_name.length - b.fulclient_namelname.length,
        sorter: (a, b) => sortValidator(a, b, a.client_name, b.client_name),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Client name

            {ClientName}
          </div>
        ),
      },




      {
        title: "Viewing Feedback",
        dataIndex: 'note',
        className: 'noteswrap',
        sorter: (a, b) => a.note.length - b.note.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Viewing feedback

            {viewingFeedbackanother}


          </div>
        ),
      },


    ]
    const columns1Responsive = [
      {
        title: "Status", key: "status",
        render: (text, record) => {
          var pStatus = ''
          var color = ''
          switch (record.status) {
            case 'Offer':
              pStatus = 'Offer'
              color = 'viewings'
              break;
            case 'Not Interested':
              pStatus = 'Not Interested'
              color = 'cancelled';
              break;
            case 'Second Viewing':
              pStatus = 'Second Viewing'
              color = 'unsuccessful';
              break;
            case 'Considering':
              pStatus = 'Considering'
              color = 'successful';
              break;
            case 'Scheduled':
              pStatus = 'Scheduled'
              color = 'scheduled';
              break;


            default:
              pStatus = 'Scheduled'
              color = 'scheduled';
              break;
          }
          return (<>
            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                    <div className={color + " pstat"} key={pStatus}>
                      {pStatus}
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    <p className='info-title'>Unit No:</p>
                    <p>{record.unitno ? record.unitno : null}</p>
                  </div>
                </div>
              </div>
              <Link className="btn-text listinginfo" onClick={this.handleViewRefNo.bind(this, record.views_id)}>
                <div className='row alignCenter borderGray pt-2 pb-2'>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>

                      <span className="propref">{record.refno}</span>
                      <span className="propinfo">
                        {record.property_title == null ? "" : record.property_title + ", "}
                        {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                        {record.community_title == null ? "" : record.community_title}</span>
                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Client:</p>
                        <p>{record.client_name ? record.client_name : 'New Client'}</p>
                      </div>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>For:</p>
                        <p>{record.property_for ? record.property_for : null}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='BodyFirstRowRight'>
                      <p className='info-title'>Viewing Feedback:</p>
                      <p>{record.note ? record.note : '--'}</p>
                    </div>
                  </div>
                </div>

              </Link>

            </div>
          </>)
        }
      }
    ]


    const handleTodayTableChange = (pagination, filters) => {

      const data = {
        month: this.state.month,

        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,

        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        agent_filter: this.state.agent_filter,
        vendor_agent_filter: this.state.vendor_agent_filter,
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,
        date_morethan_option: this.state.date_morethan_option,
        date_lessthan_option: this.state.date_lessthan_option,
        date_value: this.state.date_value,
        date_value_id: this.state.date_value_id,
        date_range: this.state.date_range,
        date_range_status: this.state.date_range_status,

      }

      this.fetchToday(pagination.current, pagination.pageSize, data); // for push

    };

    const handleTableChange = (pagination, filters) => {

      const data = {
        month: this.state.month,

        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,

        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        agent_filter: this.state.agent_filter,
        vendor_agent_filter: this.state.vendor_agent_filter,
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,
        date_morethan_option: this.state.date_morethan_option,
        date_lessthan_option: this.state.date_lessthan_option,
        date_value: this.state.date_value,
        date_value_id: this.state.date_value_id,
        date_range: this.state.date_range,
        date_range_status: this.state.date_range_status,

      }

      this.fetchData(pagination.current, pagination.pageSize, data); // for push

    };

    const handlePreviousTableChange = (pagination, filters) => {

      const data = {
        month: this.state.month,

        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,

        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        agent_filter: this.state.agent_filter,
        vendor_agent_filter: this.state.vendor_agent_filter,
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,
        date_morethan_option: this.state.date_morethan_option,
        date_lessthan_option: this.state.date_lessthan_option,
        date_value: this.state.date_value,
        date_value_id: this.state.date_value_id,
        date_range: this.state.date_range,
        date_range_status: this.state.date_range_status,

      }

      this.fetchPreviousData(pagination.current, pagination.pageSize, data); // for push

    };
    return (
      <div>
        <ToastContainer />


        <Modal width={600} title="Filter" open={this.state.isFilterOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
          <div className='row w-100 '>
            <div className='col-md-12 px-0'>
              <div className='form-group'>
                <label>Title</label>

                <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                {this.state.titleHasError ? <div className='text-danger'>Title required</div> : null}
              </div>
            </div>
          </div>
          <div className='row w-100' />


          <Select

            showSearch
            allowClear
            style={{
              width: 300, height: 46
            }}
            placeholder="add filter"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={this.state.filter_options}
            onChange={this.handleAddRow}
          //   onChange={add()}
          />








          {this.state.rows.map((item, idx) => (
            <div className='d-flex flex-row w-100' >
              {item.id == 1 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='form-group w-100 px-0 mb-0'>
                    <label>Contact type</label>
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%', minWidth: "7rem"
                      }}

                      placeholder="Please select"
                      onChange={this.handleContactType}
                      options={this.state.contact_types}
                    />
                  </div>
                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div>
              </div> : null}
              {item.id == 2 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='form-group w-100 px-0 mb-0'>
                    <label>Status</label>
                    <Select
                      mode={'multiple'}
                      showSearch
                      value={this.state.status_filter}
                      allowClear
                      style={{
                        width: '100%',
                      }}

                      placeholder="Search"
                      onChange={this.handleStatusFilter}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: 'Second Viewing',
                          label: 'Second Viewing',
                        },
                        {
                          value: 'Not Interested',
                          label: 'Not Interested',
                        },
                        {
                          value: 'Unsuccessful',
                          label: 'Unsuccessful',
                        },
                        {
                          value: 'Considering',
                          label: 'Considering',
                        },
                        {
                          value: 'Offer',
                          label: 'Offer',
                        },
                      ]}
                    />

                  </div>
                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div></div> : null}
              {item.id == 3 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='w-100'>
                    <label>View date</label>
                    <div className='d-flex flex-row' style={{ gap: "0.6rem" }}>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                        <label class="form-check-label" for="flexRadioDefault1">
                          More than
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Less than
                        </label>
                      </div>
                    </div>
                    <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <input type="text" className="form-control" onChange={this.handleDateValue} />
                      <select className='form-select ml-1' onChange={this.handleDateValue_id} style={{ fontSize: "14px" }}>
                        <option value="1">days</option>
                        <option value="2">weeks</option>
                        <option value="3">months</option>
                        <option value="4">years</option>
                      </select>
                    </div>

                  </div>
                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div></div> : null}
              {item.id == 4 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='form-group w-100 px-0 mb-0'>
                    <label className='font-bold'>Property for</label>
                    <Select
                      mode="multiple"
                      maxTagCount='responsive'
                      style={{
                        width: '100%',
                      }}

                      placeholder="Please select"
                      onChange={this.handlePropertyFor}


                      options={[
                        {
                          value: 'sale',
                          label: 'sale',
                        },
                        {
                          value: 'rent',
                          label: 'rent',
                        },
                        {
                          value: 'tenanted',
                          label: 'tenanted',
                        },
                        {
                          value: 'short-term',
                          label: 'short-term',
                        },
                      ]}
                    />
                  </div>

                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div> </div> : null}

              {item.id == 5 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Agents</label>
                      <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        maxTagCount='responsive'
                        style={{
                          width: '100%',
                        }}

                        placeholder="Please select"
                        onChange={this.handleAgentFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }

                        options={this.state.agents}
                      />

                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}
              {item.id == 6 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='form-group w-100 mb-0'>
                    <label>Stage</label>

                    <Select
                      mode="multiple"

                      style={{
                        width: '100%', minWidth: "7rem", height: "46px"
                      }}

                      placeholder="Please select"
                      onChange={this.handleStages}
                      options={this.state.stages}
                    />

                  </div>
                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div>
              </div> : null}
              {item.id == 7 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='form-group w-100 mb-0'>
                    <label>Furnished / Unfurnished</label>
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%', minWidth: "7rem", height: "46px"
                      }}

                      placeholder="Please select"
                      onChange={this.handleFurnishedTypes}
                      options={this.state.furnished_types}
                    />

                  </div>
                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div>

              </div> : null}


            </div>
          ))}




        </Modal>

        <Modal width={600} title="Viewing" open={this.state.isView}
          onOk={this.handleChangeStatus} onCancel={this.handleCancelViewing} >
          <div className="container">
            <div className='row w-100 d-flex flex-row'>
              <div className='px-0 pt-1 form-group'>
                <label>Ref No</label>
                <p className="form-control-disabled">{this.state.viewings.listing_refno}</p>
              </div>
              {role == 1 ? <div className='px-0 pt-1 form-group'>
                <label>Agent Name</label>
                <p className="form-control-disabled">{this.state.viewings.fullname}</p>
              </div> : null}

            </div>
            <div className='row w-100 mt-3 d-flex flex-row'>

              <div className='px-0 pt-1 form-group'>
                <label>Date</label>
                {this.state.showDate ? <DatePicker value={(viewing_date) ? dayjs(viewing_date) : ""} format="YYYY-MM-DD" style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p className="form-group">{this.state.viewings.view_date}</p>}

              </div>
              <div className='px-0 pt-1 mt-3 form-group'>
                <label>Status</label>
                <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
                  <option value="Offer">Offer</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Second Viewing">Second Viewing</option>
                  <option value="Not Interested">Not Interested</option>
                  <option value="Considering">Considering</option>
                </select>
              </div>
              {this.state.showPrice ? <div className='form-group'>
                <label>Price</label>
                <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
              </div> : null}

              {this.state.showCommission ? <div className='form-group'>
                <label>Commission</label>
                <input onChange={this.handleViewingCommission} type="text" className="form-control" value={this.state.viewing_commission} />
              </div> : null}
            </div>
            <div className='row w-100 mt-3'>

              <div className='w-100 px-0 pt-1'>
                <label>Viewing Feedback</label>
                <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
              </div>


            </div>
          </div>
        </Modal>





        {/* <Modal title="Viewings" open={this.state.isView} 
        width={1000}
       onOk={this.handleChangeStatus} onCancel={this.handleCancel}
        >
             <Divider />
          <div className='row w-100'>
            <div className='col-md-12 px-0 pt-1'>
              <label>REf NO</label>
              <p>{this.state.viewings.listing_refno}</p>
            </div>
          </div>
          <div className='row w-100 mt-3'>
            <div className='col-md-4 px-0 pt-1'>
              <label>Agent Name</label>
              <p>{this.state.viewings.fullname}</p>
            </div>
            <div className='col-md-4 px-0 pt-1'>
              <label>Date</label>
              {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p>{this.state.viewings.view_date}</p>}

            </div>
            <div className='col-md-4 px-0 pt-1 mt-3'>
              <label>Status</label>
              <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
              <option value="Offer">Offer</option>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select>
            </div>
          </div>
          <div className='row w-100 mt-3'>
            <div className='col-md-8 px-0 pt-1'>
              <label>Note</label>
              <TextArea value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
            {this.state.showPrice ? <div className='col-md-4'>
              <label>Price</label>
              <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
            </div> : null}

          </div>
      </Modal> */}

        <Tabs >
          <TabList>
            {this.state.filters.length > 0 ? <Tab onClick={this.handleClearFilter}>All</Tab> : null}

            {this.state.filters.map((filter) => (
              <>
                {/* <CloseOutlined onClick={this.handleDeleteFilter.bind(this,filter.key)} /> */}
                <Tab><p><span onClick={this.handleTabs.bind(this, filter.key)}>{filter.label}</span> <CloseOutlined onClick={this.handleDeleteFilter.bind(this, filter.key)} /></p></Tab>

              </>
            ))}

          </TabList>


        </Tabs>

        {/* <Tabs style={{ color: '#8D949C', width: "100%" }} hideAdd type="editable-card" items={this.state.filters} onChange={this.handleTabs} onEdit={this.handleDeleteFilter} /> */}

        <div className='contentwrap' style={{

          minHeight: 380,
        }}>

          <div className='ml-auto d-flex px-0 mt-3'>

            <div className='px-3 w-100'>
              <Space direction="vertical">


                <Space wrap>

                  <div className="d-flex flex-row flex-wrap my-2 w-100">

                    <div className="d-flex flex-row justify-content-between">
                      <div className="col-12 d-flex flex-row toptitle px-0" >
                        <h3 className="my-2 ">Viewings</h3>

                      </div>


                    </div>

                    <div className='d-flex flex-row ml-auto'>
                      <Button type='primary' className="addnewlisting ml-1" onClick={this.handleNewFilter} icon={<FilterOutlined />}>
                        Add new filter
                      </Button>
                      <Button className='ml-1' type="primary" onClick={this.handleClearFilter} icon={<ClearOutlined />}>
                        Reset filter
                      </Button></div>


                  </div>

                  {/* edit filter */}





                </Space>
              </Space>
            </div>
          </div>


          <div className='tabs-months pb-2'>
            <Tabs >
              <TabList>
                {this.state.months.length > 0 ? <Tab onClick={this.handleClearFilter}>All</Tab> : null}
                {this.state.months.map((month) => (
                  <>
                    <Tab><span onClick={this.handleMonthTabs.bind(this, month.key)} className="mx-3">{month.labe}</span></Tab>
                  </>
                ))}

              </TabList>
            </Tabs>
          </div>


          {/* Responsive Filters List by Hassan 8/13/24 */}
          {window.isIpadView ? <>
            <div className='listingfilter-second'>
              <Row span={24}>
                <Col span={24}>
                  {/* Status */}
                  <div className='d-flex flex-row align-items-end form-group formcheckboxgroup'>
                    <div className="d-flex flex-column align-items-start "> {/* 11-23-2023 */}


                      <Collapse className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                        <Panel header={<p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Filter by</p>} key="2">
                          {/* {!this.state.isViewingTabShow && !this.state.isOfferTabShow?<> */}

                          {role == 1 ?
                            <>
                              <Popover title="Status" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {StatusFilter}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Status
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Ref No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {propertyRefNo}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Ref No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>


                              <Popover title="Client" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {ClientRefNo}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Client
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Agent Name" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {AgentName}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Agent Name
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>


                              <Popover title="Viewing Feedback" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {ViewingFeedback}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Viewing Feedback
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>


                              <Popover title="Date" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {DateFilter}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Date
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>


                              <Popover title="Property For" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {PropertyFor}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Property For
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Unit No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {UnitNo}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Unit No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>



                              <Popover title="Vendor Agent" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {VendorAgent}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Vendor Agent
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>
                            </> :

                            <>
                              <Popover title="Status" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {StatusFilterAnother}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Status
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Ref No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {RefNoAnother}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Ref No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Property For" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {PropertyForAnother}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Property For
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Unit No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {UnitNoAnother}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Unit No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Client Name" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {ClientName}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Client Name
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Viewing Feedback" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {viewingFeedbackanother}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Viewing Feedback
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>
                            </>
                          }


                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </> : ''
          }
          <div class="table-responsive tablelistwrap px-3 allviewings tabs-view" >

            <Tabs >
              <TabList >
                <Tab>Today's Viewings</Tab>
                <Tab>Upcoming Viewings</Tab>
                <Tab>Previous Viewings</Tab>
              </TabList>

              <TabPanel>
                <div className='upcomingviewings tabscontentwrap'>
                  <h5 className="mt-2 font-bold fs-5">Today's Viewings</h5>
                  <Table onChange={handleTodayTableChange}
                    loading={this.state.todayLoading}
                    rowSelection={window.isIpadView ? '' : rowSelection}
                    className="TableborderNoneMain"
                    pagination={{
                      pageSizeOptions: ['10', '20', '50', '100'],
                      total:
                        this.state?.all_today_viewings?.total > 0 &&
                        this.state.all_today_viewings.total,
                      pageSize: this.state.all_today_viewings.per_page,
                      current: this.state.all_today_viewings.current_page,
                      showTotal: (total, range) =>
                        window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    //   style={{ overflowX: "auto" }}
                    columns={role == 1 ? window.isIpadView ? columnsResponsiveThis : columns : window.isIpadView ?  columns1Responsive : columns1}
                    // scroll={{ x: window.isIpadView? 0:1500, y:window.isIpadView? 0: 2000 }}
                    dataSource={
                      this.state?.all_today_viewings?.data
                        ? this.state?.all_today_viewings?.data
                        : []
                    }

                    // rowKey={(record) => record}
                    rowKey={(record) => record.id}
                  />
                </div>
              </TabPanel>

              <TabPanel>
                {this.state.isNext ?
                  <div className='upcomingviewings tabscontentwrap'><h5 className="mt-2 font-bold fs-5">
                    Upcoming Viewings</h5>
                    <Table onChange={handleTableChange}
                      loading={this.state.isLoading}
                      rowSelection={window.isIpadView ? '' : rowSelection}
                      className="TableborderNoneMain"
                      pagination={{
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total:
                          this.state?.all_upcomming_viewings?.total > 0 &&
                          this.state.all_upcomming_viewings.total,
                        pageSize: this.state.all_upcomming_viewings.per_page,
                        current: this.state.all_upcomming_viewings.current_page,
                        showTotal: (total, range) =>
                          window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      //   style={{ overflowX: "auto" }}
                      columns={role == 1 ? (window.isIpadView ? columnsResponsiveThis : columns) : window.isIpadView ?  columns1Responsive : columns1}
                      // scroll={{ x:window.isIpadView? 0:1500, y:window.isIpadView? 0:2000 }}
                      dataSource={
                        this.state?.all_upcomming_viewings?.data
                          ? this.state?.all_upcomming_viewings?.data
                          : []
                      }

                      // rowKey={(record) => record}
                      rowKey={(record) => record.id}
                    />
                  </div>
                  : null} </TabPanel><TabPanel>
                {this.state.isPrevious ?
                  <div className='previousviewings tabscontentwrap'>
                    <h5 className="mt-2 font-bold fs-5">Previous Viewings</h5>
                    <Table onChange={handlePreviousTableChange}
                      loading={this.state.isPreviousLoading}
                      rowSelection={window.isIpadView ? '' : rowSelection}
                      className="TableborderNoneMain"
                      pagination={{
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total:
                          this.state?.all_previous_viewings?.total > 0 &&
                          this.state.all_previous_viewings.total,
                        pageSize: this.state.all_previous_viewings.per_page,
                        current: this.state.all_previous_viewings.current_page,
                        showTotal: (total, range) =>
                          window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      //   style={{ overflowX: "auto" }}
                      columns={role == 1 ? (window.isIpadView ? columnsResponsiveThis : columns) :  window.isIpadView ?  columns1Responsive : columns1}
                      // scroll={{ x: 1500, y: 2000 }}
                      dataSource={
                        this.state?.all_previous_viewings?.data
                          ? this.state?.all_previous_viewings?.data
                          : []
                      }

                      // rowKey={(record) => record}
                      rowKey={(record) => record.id}
                    />
                  </div>
                  : null} </TabPanel>
            </Tabs>
          </div>

        </div>
      </div>
    )
  }
}




export default All_Listings_Leads_Viewings

// export default  MyListings;
