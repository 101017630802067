import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Popover, Button, Drawer, Spin, Avatar, Image, Select, Timeline, Carousel, Input, Checkbox, DatePicker, Collapse, Modal, Empty, Tag, Table, Mentions, Tooltip, Segmented, Skeleton, Space, Radio, InputNumber, Switch, Divider, Statistic, Card } from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined, CheckOutlined, CloseOutlined, SettingFilled, DeleteFilled, SearchOutlined, CaretRightOutlined, CaretLeftOutlined, ClockCircleOutlined, EditOutlined, CloseCircleOutlined, RiseOutlined, UserAddOutlined, PlusOutlined, StarOutlined, CalendarOutlined, DollarOutlined, CompassOutlined } from '@ant-design/icons';
import './leads.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { baseurlImg, liveUrl } from '../../components/BaseUrl';
import Moment from 'react-moment';
import parse from 'html-react-parser';
import Swal from "sweetalert2";
import ApiCalendar from "react-google-calendar-api";
import { end } from '@popperjs/core';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import BeatLoader from "react-spinners/BeatLoader";
import CurrencyInput from 'react-currency-input-field';
import EasyEdit, { Types } from 'react-easy-edit';
import EdiText from "react-editext";
import axios from 'axios';
import './responsiveListing.css';
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const width = window.screen.width;
const Panel = Collapse.Panel;

let property_type_array = ["Residential", "Commercial"]
let property_for_array = ["sale", "rent", "tenanted", "short-term"]

const config = {
    "clientId": '8974307049-e0ug8edud9gp4ahcnah13i5kk8opivnh.apps.googleusercontent.com',
    "apiKey": 'AIzaSyAbmWx3GOaQ_HU-lD4wViBmV4kKNeWdk0Y',
    "scope": "https://www.googleapis.com/auth/calendar",
    "discoveryDocs": [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ]
}

const apiCalendar = new ApiCalendar(config)

//format aed
function formatNumber (value) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
function shortenPrice(price) {
    if (price >= 1000000) {
        return (price / 1000000) + 'M';
      } else if (price >= 1000) {
        return (price / 1000) + 'K';
      }
      return price;
  }




class New_Leads extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterTitleModal: false,
            isViewAsGallery: true,
            offplan: false,
            sortedInfo: null,
            columnKey: "",
            order: "",
            provider: [],
            listings_array: [],
            isListingsLoading: false,
            listings: [],
            number_of_cheque: "",
            isMergeLeadsOpen: false,
            new_to_dubai: "",
            current_live: "",
            availability_for_viewing: "",
            pre_approved: "",
            payment: "",
            selectedLeads: [],
            selectedStages: [],
            allAgents: [],
            buyer_type: "",
            new_bed: "",
            new_community: "",
            new_sub_community: "",
            price_first: "",
            price_second: "",
            new_move_in_date: "",
            SelectedRows: [],
            exact_match_listings: [],
            comparable_match_listings: [],
            exact_match_listings_count: 0,
            comparable_match_listings_count: 0,
            type: 2,
            isMergeLoading: false,
            reply: "",
            activity_agent_id: "",
            parent_lead: "",
            parent_lead_stage: "",
            parent_agent: "",
            assigned_agent: "",
            oldParentLead: "",
            maxParentLeadDate: "",
            maxLastCallDate: "",
            maxLastActivityDate: "",
            decline_note: "",
            activityFeeds: [],
            sendTagDataAll: [],
            sendTagDataReplied: [],
            sendTagDataPending: [],
            sendTagData: [],
            recieveTagDataAll: [],
            recieveTagDataReplied: [],
            sources: [
                { label: "Direct Call", value: 22 },
                { label: "LP.com", value: 90 },
                { label: "EDM", value: 103 },
                { label: "Direct Email", value: 102 },
                { label: "Business Card", value: 17 },
                { label: "PropertyFinder", value: 71 },
                { label: "Bayut", value: 112 },
                { label: "LinkedIn", value: 50 },
                { label: "Facebook", value: 34 },
                { label: "Whatsapp", value: 92 },
                { label: "Youtube", value: 94 },
                { label: "Open House", value: 114 },
                { label: "Agent External", value: 4 },
                { label: "Agent Internal", value: 5 },
                { label: "Other", value: 97 },                
            ],
            client_name: "",
            referred_by: "",
            referred_to: "",
            client_phone: "",
            client_email: "",
            client_refno: "",
            client_source: "",
            client_agent: "",
            client_note: "",
            recieveTagDataPending: [],
            recieveTagData: [],
            isActivityLoading: false,
            isTagLoading: false,
            requested_viewings: [],
            currentRequest: null,
            isWhatsappLoading: false,
            remainingTime: "00:00:00",
            vendor: "",
            isViewing: false,
            isLoading: false,
            isNextLoading: false,
            isPreviousLoading: false,
            isRequestviewingOpen: false,
            role: "", role_profile: "",
            agent_id: "",
            addNewFilterSelect: "",
            contact_type_call_note_status: "",
            rows: [],
            sendEvent: false,
            mylistings: [],
            propertytypes: [],
            validationerrors: {},
            validationerrorsMergeLead: {},
            addleadvalidationerrors: {},
            followup_data: [],
            property_viewings: [],
            property_offers: [],
            client_contact_type: "",
            client_data: [],
            collaborators: [],
            followups: [],
            offers: [],
            is_referred: false,
            followups_details: [],
            ref_nums: [],
            unit_nums: [],
            userClients: [],
            selectedClients: [],
            selectedCallUsers: [],
            selectedNoteUsers: [],
            selectedWhatsappUsers: [],
            selectedEmailUsers: [],
            curr: "",
            comment: "",
            titleHasError: false,
            ismourequestOpen: "",
            sellers: [],
            sellersdata: [],

            selectedViewingUsers: [],
            selectedOfferUsers: [],
            selectedFollowupUsers: [],

            newArrSelectedAgents: [],
            selected_agent: "",
            followup_for: "",
            reminder: "",

            ref_def_nums: [],
            old_unit_num: [],
            viewings_listings: [],
            viewing_ref_no: "",
            viewing_unit_no: "",
            viewing_agent: "",
            viewing_date: "",
            viewing_note: "",
            viewing_status: "",
            viewing_price: "",
            showPrice: false,
            showDate: false,
            community_from_requirement: "",
            sub_community_from_requirement: "",
            min_from_requirement: "",
            max_from_requirement: "",
            bedroom_from_requirement: "",
            property_for_from_requirement: "",
            added_date_from_requirement: "",
            contact_type_requirement: "",
            price_requirement: "",

            lead_agent: "",
            lead_community: "",
            lead_sub_community: "",
            //followup
            followup_note: "",
            followup_date: "",
            leads: [],
            lead_details: {},
            lead_list_details: {},
            viewings: {},
            all: [],
            notes: [],
            agents: [],
            call_notes: [],
            whatsapp_notes: [],
            email_notes: [],
            viewing_notes: [],
            offer_notes: [],
            followup_notes: [],
            communities: [],
            sub_communities: [],
            properties: [],
            all_sub_communities: [],
            requirement_sub_communities: [],
            filters: [],
            greaterthanprice: false,
            leassthanprice: false,
            inbetweenprice: false,
            lessValue: 0,
            greaterValue: 0,

            owner_id: "",
            owner_name: "",
            owner_email: "",
            owner_mobile: "",

            community_match: "",
            sub_community_match: "",
            min_budget: 0,
            max_budget: 0,
            bedroom_match: "",
            property_for_match: "",
            property_type_match: "",
            added_date_match: "",
            date_morethan_option_match: true,
            date_lessthan_option_match: false,
            date_value_match: 0,
            date_value_id_match: 1,
            matchs: [],
            not_matchs: [],
            owner_other_properties: [],


            // requirements

            requirement_min_budget: 0,
            requirement_max_budget: 0,
            requirement_lead_stage: "",
            requirement_community: "",
            requirement_sub_community: "",
            requirement_verifyedby: "",
            requirement_agent_id: "",
            requirement_move_in_date: "",
            requirement_bedroom: "",
            pin: false,
            // filters option
            // price
            search_owner: "",
            last_four_digit : "",

            isRequestInProgress: false,

            price_first_value_id: 1,
            price_second_value_id: 7,
            price_active_second_option: false,
            price_and_or_option: true,
            price_first_value: "",
            price_second_value: "",
            inrange: false,
            price_min: 0,
            price_max: 0,

            viewing_value: "",
            viewing_morethan_value: true,
            viewing_lessthan_value: false,
            viewing_option_value: 1,
            inrange_viewing: false,
            viewing_min_value: "",
            viewing_max_value: "",

            offer_value: "",
            offer_morthan_value: true,
            offer_lessthan_value: false,
            offer_option_value: 1,
            inrange_offer: false,
            offer_min_value: "",
            offer_max_value: "",
            // date

            date_first_value_id: 1,
            date_second_value_id: 1,
            date_active_second_option: false,
            date_and_or_option: true,
            date_first_value: "",
            date_second_value: "",
            date_morethan_option: true,
            date_lessthan_option: false,
            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            date_value: "",
            date_value_id: 1,

            // new 3 filters
            last_activity_date_morethan_option: true,
            last_activity_date_lessthan_option: false,
            last_activity_date_value: "",
            last_activity_date_value_id: 1,


            // new 4 digit filter

            

            last_whatsapp_date_morethan_option: true,
            last_whatsapp_date_lessthan_option: false,
            last_whatsapp_date_value: "",
            last_whatsapp_date_value_id: 1,

            last_whatsapp_received_date_morethan_option: true,
            last_whatsapp_received_date_lessthan_option: false,
            last_whatsapp_received_date_value: "",
            last_whatsapp_received_date_value_id: 1,

            last_call_morethan_option: true,
            last_call_lessthan_option: false,
            last_call_value: 0,
            last_call_value_id: 1,
            last_call_option_value: 1,

            last_match_morethan_option: true,
            last_match_lessthan_option: false,
            last_match_value: 0,
            last_match_value_id: 1,
            last_match_option_value: 1,

            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            move_in_date_value: "",
            move_in_date_value_id: 1,
            move_in_date_option_value: 1,
            inrange_move_in_date: false,
            move_in_date_date_range: [],
            move_in_date_is_empty: false,

            // add new filter
            isFilterOpen: false,
            isFilterEditOpen: false,
            filter_id: 0,
            filter_title: "",
            contact_type_id: 0,
            community_value_id: 0,
            sub_community_value_id: 0,
            price_value_id: 0,
            price_value: "",
            bed_rooms_value_id: 1,
            bed_rooms_value: "",
            created_value_id: 0,
            created_value: "",
            stage_value_id: 0,
            stage_value: ['1'],
            verified_sub_stage_value: [],
            contact_types_value: [],
            furnished_types_value: [],
            property_type_filter: [],

            default_stage_value: [],
            default_contact_types_value: [],
            default_furnished_types_value: [],

            name_value_id: 1,
            name_value: "",

            refno_value_id: 1,
            refno_value: "",

            phone_value_id: 1,
            phone_value: "",

            agent_value_id: 1,
            agent_value: [],
            replied_to_whatsapp: false,
            pending_leads: false,
            clicked_book_viewing: [],
            viewing_booked_since_requested: false,
            viewing_date_range: [],
            viewing_outcome: [],
            number_confirmed_on_whatsapp: false,
            offer_status: [],
            deal_signed: false,
            // drawer for details
            open: false,

            record_id: '',
            view_id: 0,
            isviewingOpen: false,
            iseditviewingOpen: false,
            isOfferOpen: false,
            isfollowupOpen: false,
            isfollowupViewOpen: false,

            


            ifFinanceYes: false,
            lead_stage_filter: [
                { label: 'None', value: '0' },
                { label: 'New Inquiry', value: '1' },
                { label: 'Working', value: '2' },
                { label: 'Hot', value: '3' },
                { label: 'Follow Up', value: '4' },
                { label: 'Dead', value: '5' },
                {
                    value: '6',
                    label: 'Offer',
                },
                {
                    value: '7',
                    label: 'Deal signed',
                },
                { label: 'Recruitment', value: '9' },
                { label: 'Viewing', value: '8' },
                { label: 'No Answer', value: '10' },
                { label: 'Expired', value: '11' },
                { label: 'Pond', value: '12' },
                ,
                {
                    value: '13',
                    label: 'Offer accepted',
                },
                {
                    value: '14',
                    label: 'Offer declined',
                },
                ,
                {
                    value: '22',
                    label: '3rd party',
                }
            ],
            lead_stage_filter_agent: [
                { label: 'None', value: '0' },
                { label: 'Dead', value: '5' },

            ],
            furnished_types: [
                {
                    value: '1',
                    label: 'Furnished'
                },
                {
                    value: '2',
                    label: 'Unfurnished'
                }
                ,
                {
                    value: '22',
                    label: '3rd party',
                }
            ],

            contact_types: [
                {
                    value: '1',
                    label: 'Tenant'
                },
                {
                    value: '2',
                    label: 'Buyer'
                },
                {
                    value: '3',
                    label: 'Landlord'
                },
                {
                    value: '4',
                    label: 'Seller'
                },
                {
                    value: '5',
                    label: 'Landlord+Seller'
                },
                {
                    value: '6',
                    label: 'Agent'
                },
                {
                    value: '13',
                    label: 'Verified Agent'
                },
                {
                    value: '7',
                    label: 'Other'
                },
                {
                    value: '8',
                    label: 'Portal'
                },
                {
                    value: '9',
                    label: 'Buyer/Tenant'
                },
                {
                    value: '10',
                    label: 'Unrecognized'
                },
                {
                    value: '12',
                    label: 'Candidate'
                }
            ],

            stages: [
                {
                    value: '1',
                    label: 'New Inquiry',
                },
                {
                    value: '2',
                    label: 'Working',
                },
                {
                    value: '3',
                    label: 'Hot',
                },
                {
                    value: '4',
                    label: 'Follow Up',
                },
                {
                    value: '5',
                    label: 'Dead',
                },
                {
                    value: '6',
                    label: 'Offer',
                },
                {
                    value: '7',
                    label: 'Deal signed',
                },
                {
                    value: '8',
                    label: 'Viewings',
                },
                {
                    value: '9',
                    label: 'Recruitment',
                },
                {
                    value: '10',
                    label: 'No Answer',
                },
                {
                    value: '11',
                    label: 'Expired',
                },
                {
                    value: '12',
                    label: 'Pond',
                },
                {
                    value: '13',
                    label: 'Offer accepted',
                },
                {
                    value: '14',
                    label: 'Offer declined',
                },
                {
                    value: '15',
                    label: 'whatsapp reply',
                },
                {
                    value: '16',
                    label: 'Second Viewing',
                },
                {
                    value: '17',
                    label: 'Not Interested',
                },
                {
                    value: '18',
                    label: 'Considering',
                },
                {
                    value: '19',
                    label: 'Whatsapp automation',
                },
                {
                    value: '20',
                    label: 'Whatsapp Agent',
                },
                {
                    value: '21',
                    label: 'Pledge',
                },
                {
                    value: '22',
                    label: '3rd party',
                },

                {
                    value: '25',
                    label: 'Verified',
                },

                {
                    value: '26',
                    label: 'Verification call',
                },
                {
                    value: '27',
                    label: 'Whatsapp Verified',
                },
                {
                    value: '28',
                    label: 'Past Client',
                }


            ],

            filter_options: [


                {
                    value: '1',
                    label: 'Contact type',
                },

                {
                    value: '2',
                    label: 'Community / Sub community',
                },
                {
                    value: '3',
                    label: 'Price',
                },
                {
                    value: '4',
                    label: 'Bedrooms',
                },
                {
                    value: '5',
                    label: 'Created',
                },
                {
                    value: '6',
                    label: 'Stage',
                },
                {
                    value: '7',
                    label: 'Furnished  / unfurnished',
                },
                {
                    value: '8',
                    label: 'Agents',
                },
                {
                    value: '9',
                    label: 'Viewings',
                },
                {
                    value: '10',
                    label: 'Offers',
                },
                {
                    value: '11',
                    label: 'Type',
                },
                {
                    value: '12',
                    label: 'Last call',
                },
                {
                    value: '13',
                    label: 'Last match',
                },
                {
                    value: '14',
                    label: 'Move in date',
                },
                {
                    value: '15',
                    label: 'Replied to whatsapp',
                },
                {
                    value: '16',
                    label: 'Clicked - Book Viewing/ More Info',
                },
                {
                    value: '17',
                    label: 'Viewing booked Since Requested',
                },
                {
                    value: '18',
                    label: 'Viewing date',
                },
                {
                    value: '19',
                    label: 'Viewing Outcome',
                },
                {
                    value: '20',
                    label: 'Number confirmed on whatsapp',
                },
                {
                    value: '21',
                    label: 'Offer Status',
                },
                {
                    value: '22',
                    label: 'Deal Signed',
                },


            ],
            price_options: [


                {
                    value: '1',
                    label: 'Equals',
                },
                {
                    value: '2',
                    label: 'Not equal',
                },
                {
                    value: '3',
                    label: 'Less than',
                },
                {
                    value: '4',
                    label: 'Less than or equals',
                },
                {
                    value: '5',
                    label: 'Greater than',
                },
                {
                    value: '6',
                    label: 'Greater than or equals',
                },

                {
                    value: '7',
                    label: 'In range',
                },

            ],

            name_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            phone_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            bedroom_filter_options: [
                {
                    value: '1',
                    label: 'Equals',
                },
                {
                    value: '2',
                    label: 'Not equal',
                },
                {
                    value: '3',
                    label: 'Less than',
                },
                {
                    value: '4',
                    label: 'Greater than',
                },


            ],
            agent_filter_options: [
                {
                    value: '1',
                    label: 'Contain',
                },
                {
                    value: '2',
                    label: 'Not contain',
                },
                {
                    value: '3',
                    label: 'Equals',
                },
                {
                    value: '4',
                    label: 'Not equal',
                },
                {
                    value: '5',
                    label: 'Start with',
                },
                {
                    value: '6',
                    label: 'Ends with',
                },

            ],
            // add notes
            call_log: "",
            note_log: "",
            whatsapp_log: "",
            email_log: "",
            followup_log: '',
            emailSubject: "",

            // Loaders
            call_loader: false,
            note_loader: false,
            whatsapp_loader: false,
            email_loader: false,
            offer_loader: false,
            viewing_loader: false,
            followUp_loader: false,

            // Error
            checkErr: false,
            // IDs
            nextId: '',
            prevId: '',
            currId: '',

            // states for disabled
            disabledNext: false,
            disabledPrev: false,

            // Farooq
            currUrl: "",
            loader: false,
            isCallLogOpen: false,
            isVerificationOpen: false,

            // 24hours
            isdecline: false,
            isSharetoWhatsApp: false,
            // status count
            new_inquire: 0,
            no_answer: 0,
            offer: 0,
            followup: 0,
            viewing: 0,
            working: 0,
            expired: 0,
            pond: 0,
            dead: 0,
            second_viewing: 0,
            not_interest: 0,
            consider: 0,
            offer_accepted: 0,
            offer_rejected: 0,
            // verified sub stages
            verfied_new_inquire: 0,
            verfied_no_answer: 0,
            verfied_offer: 0,
            verfied_viewing: 0,
            verfied_working: 0,
            verfied_expired: 0,
            teams: [],
            // teams:[{value: 6, label: "Sales Team"},
            // {value: 9, label: "Off Plan Team"},{value: 10, label: "Leasing Team"}],
            selectedTeam: "",

            // Match listing drawer
            showMatchListings: false,
            splitarrays: [],
            wa_parentchildarr: [],

            isOpenTagged: false,
            isOpenMyActivityFeed: false,
            FilterDrawerOpen: false, MobileFilterDrawerOpen:false,

            isAllActive: true,
            isRepliedActive: false,
            isPendingActive: false,
            isAllRecieveTags: true,
            isRepliedRecieveTags: false,
            isPendingRecieveTags: false,

            // Add New Lead
            isAddNewLead: false,
            filtershow: false,
            matched_leads_viewing_value_show: false,

            pendingLeadsShow: false,
            totalMilliseconds: 7200000,

            propertyFor:null,
            bedspopover:false,
            pricepopover:false,

            // Mobile filter
            statusfilt:false,
            verificationfilt:false,
            proptypemodal:false,
            selectedRowKeys: [],  selectedRows: [],
            exportLoading:false,

        }
        this.textInput = React.createRef();
        this.handlePendingViewChange = this.handlePendingViewChange.bind(this);



        // this.state = { disabled: false };
    }







    componentDidMount() {

        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents
        const listings_array = all_data.stock_area_reducer.listing_array
        const total_data = { selectedTeam: this.state.selectedTeam }

        this.setState({ listings_array: listings_array })
        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,

            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }


        this.fetchData(page, perPage, data);
        this.fetchTotalStatus(data)

        API.get("/teams")
            .then((response) => {

                if (response.data.success) {
                    this.setState({ teams: response.data.teams })
                }
            }
            )


        // }, 2000);

        this.interval = setInterval(() => {
            this.setState((prevState) => ({
                totalMilliseconds: prevState.totalMilliseconds - 1000,
            }));
        }, 1000);

    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }


    fetchMatchedVerificationCallLeadList = (page, perPage, data) => {
        this.setState({ isListingsLoading: true })
        API.post(`/matchedVerificationCallLead?page=${page}&perPage=${perPage}`, data)
            .then(async (response) => {


                await response.data.listings;
                this.setState({
                    listings: response.data.listings,
                    isListingsLoading: false
                })

            })
    }

    fetchGroup = () => {
        API.get("/teams")
            .then((response) => {

                if (response.data.success) {
                    this.setState({ teams: response.data.teams })
                }
            }
            )
    }

    fetchExactListings = () => {
        let id = this.state.record_id;
        API.get("/matchedleadlists/" + id)
            .then((res) => {
                if (res.data.success) {
                    this.setState({ exact_match_listings: res.data.listings, exact_match_listings_count: res.data.listings_count })
                }
            })
    }

    fetchCoparableListings = () => {
        let id = this.state.record_id;
        API.get("/comparable_matchedleadlists/" + id)
            .then((res) => {
                if (res.data.success) {
                    this.setState({ comparable_match_listings: res.data.listings, comparable_match_listings_count: res.data.listings_count })
                }
            })
    }

    fetchAllAgents = () => {
        API.get("/all_agents")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ agents: response.data.agents })
                }
            }
            )
    }


    fetchFourDigitWiseFilterLeads = () => {
        
    }



    onClickAccept = (data) => {
        const postData = {
            status: 1,
            lead_id: data.original_id
        }

        this.callForAcceptDecline(postData);
    }

    onClickReject = (data) => {
        const postData = {
            status: 2,
            lead_id: data.original_id
        }

        this.callForAcceptDecline(postData);
    }

    callForAcceptDecline = (postData) => {
        API.post("/accept_decline", postData)
            .then((response) => {
                if (response.data.success) {
                    const all_data = store.getState((state) => state);
                    const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
                    const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
                    const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
                    const selectedAgents = all_data.expiring_lead_reducer.selectedAgents
                    const listings_array = all_data.stock_area_reducer.listing_array
                    const total_data = { selectedTeam: this.state.selectedTeam }
                    const page = 1;
                    const perPage = 10;
                    const data = {
                        nested_stage_value: this.state.nested_stage_value,
                        listings_array: listings_array,
                        search_owner: this.state.search_owner,
                        replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
                        clicked_book_viewing: this.state.clicked_book_viewing,
                        viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
                        viewing_date_range: this.state.viewing_date_range,
                        viewing_outcome: this.state.viewing_outcome,
                        number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
                        offer_status: this.state.offer_status,
                        deal_signed: this.state.deal_signed ? "YES" : "NO",

                        last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
                        last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
                        last_activity_date_value: this.state.last_activity_date_value,
                        last_activity_date_value_id: this.state.last_activity_date_value_id,

                        last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
                        last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
                        last_whatsapp_date_value: this.state.last_whatsapp_date_value,
                        last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

                        last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
                        last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
                        last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
                        last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,

                        selectedTeam: this.state.selectedTeam,
                        selectedAgents: selectedAgents,
                        isMatchedLead: isMatchedLead == true ? 1 : 0,
                        isExpiringLead: isExpiringLead == true ? 1 : 0,
                        isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
                        community_filter: this.state.community_filter,
                        sub_community_filter: this.state.sub_community_filter,
                        property_filter: this.state.property_filter,
                        name_value_id: this.state.name_value_id,
                        name_value: this.state.name_value,
                        phone_value_id: this.state.phone_value_id,
                        phone_value: this.state.phone_value,
                        refno_value: this.state.refno_value,
                        refno_value_id: this.state.refno_value_id,
                        bed_rooms_value_id: this.state.bed_rooms_value_id,
                        bed_rooms_value: this.state.bed_rooms_value,
                        agent_value_id: this.state.agent_value_id,
                        agent_value: this.state.agent_value,
                        stage_value: this.state.stage_value,
                        verified_sub_stage_value: this.state.verified_sub_stage_value,
                        contact_types_value: this.state.contact_types_value,
                        furnished_types_value: this.state.furnished_types_value,
                        price_first_value_id: this.state.price_first_value_id,
                        price_first_value: this.state.price_first_value,
                        price_min: this.state.price_min,
                        price_max: this.state.price_max,
                        date_morethan_option: this.state.date_morethan_option,
                        date_lessthan_option: this.state.date_lessthan_option,
                        move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                        move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                        date_value: this.state.date_value,
                        date_value_id: this.state.date_value_id,
                        property_type_filter: this.state.property_type_filter,

                        viewing_value: this.state.viewing_value,
                        viewing_morethan_value: this.state.viewing_morethan_value,
                        viewing_lessthan_value: this.state.viewing_lessthan_value,
                        viewing_option_value: this.state.viewing_option_value,
                        inrange_viewing: this.state.inrange_viewing,
                        viewing_min_value: this.state.viewing_min_value,
                        viewing_max_value: this.state.viewing_max_value,


                        offer_value: this.state.offer_value,
                        offer_morthan_value: this.state.offer_morthan_value,
                        offer_lessthan_value: this.state.offer_lessthan_value,
                        offer_option_value: this.state.offer_option_value,
                        inrange_offer: this.state.inrange_offer,
                        offer_min_value: this.state.offer_min_value,
                        offer_max_value: this.state.offer_max_value,


                        last_call_morethan_option: this.state.last_call_morethan_option,
                        last_call_lessthan_option: this.state.last_call_lessthan_option,
                        last_call_value: this.state.last_call_value,
                        last_call_value_id: this.state.last_call_value_id,
                        last_call_option_value: this.state.last_call_option_value,


                        last_match_morethan_option: this.state.last_match_morethan_option,
                        last_match_lessthan_option: this.state.last_match_lessthan_option,
                        last_match_value: this.state.last_match_value,
                        last_match_value_id: this.state.last_match_value_id,
                        last_match_option_value: this.state.last_match_option_value,

                        move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                        move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                        move_in_date_value: this.state.move_in_date_value,
                        move_in_date_value_id: this.state.move_in_date_value_id,
                        move_in_date_option_value: this.state.move_in_date_option_value,
                        move_in_date_date_range: this.state.move_in_date_date_range


                    }


                    this.fetchData(page, perPage, data);
                }
            })
    }



    fetchAllRequest = () => {
        API.get("/community")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ communities: response.data.communities })
                }
            })

        API.get("/all_sub_community")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ sub_communities: response.data.sub_communities })
                }
            })
        API.get("/all_properties")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ properties: response.data.properties })
                }
            })
        API.get("/propertytypes")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ propertytypes: response.data.propertytypes })
                }
            })



        API.get('/all_client_users').then((response) => {
            if (response.data.success) {
                const data = response.data.users
                for (let i = 0; i < data.length; i++) {
                    const num = data[i].id
                    data[i].key = num.toString()

                }
                this.setState({ userClients: data })
            }
        })

    }





    fetchFilter = () => {
        API.get('/filters')
            .then((response) => {
                if (response.data.success) {
                    this.setState({ filters: response.data.filters })
                }
            })
    }
    handleAddRow = (value, e) => {
        this.setState({ addNewFilterSelect: e });

        const item = {
            name: "",
            mobile: "",
            id: value

        };

        let filter_options = this.state.filter_options.filter(item => item.value !== value)
        this.setState({
            rows: [...this.state.rows, item],
            filter_options: filter_options
        });
    };
    handleRemoveRow = () => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };
    handleRemoveSpecificRow = (idx, id) => () => {
        const rows = [...this.state.rows]
        if (id == 1) {
            const item = {
                value: '1',
                label: 'Contact type'
            }

            this.setState({
                filter_options: [...this.state.filter_options, item]
            })
        } else
            if (id == 2) {
                const item = {
                    value: '2',
                    label: 'Community / Sub community'
                }

                this.setState({
                    filter_options: [...this.state.filter_options, item]
                })
            } else
                if (id == 3) {
                    const item = {
                        value: '3',
                        label: 'Price'
                    }

                    this.setState({
                        filter_options: [...this.state.filter_options, item],
                        price_first_value_id: 0,
                        price_first_value: 0,
                        price_min: 0,
                        price_max: 0
                    })
                } else
                    if (id == 4) {
                        const item = {
                            value: '4',
                            label: 'Bedrooms'
                        }

                        this.setState({
                            filter_options: [...this.state.filter_options, item]
                        })
                    } else
                        if (id == 5) {
                            const item = {
                                value: '5',
                                label: 'Created'
                            }

                            this.setState({
                                filter_options: [...this.state.filter_options, item]
                            })
                        } else
                            if (id == 6) {
                                const item = {
                                    value: '',
                                    label: 'Stages'
                                }

                                this.setState({
                                    filter_options: [...this.state.filter_options, item]
                                })
                            } else
                                if (id == 7) {
                                    const item = {
                                        value: '7',
                                        label: 'Furnished / Unfurnished'
                                    }

                                    this.setState({
                                        filter_options: [...this.state.filter_options, item]
                                    })
                                }
                                else
                                    if (id == 8) {
                                        const item = {
                                            value: '8',
                                            label: 'Agents'
                                        }

                                        this.setState({
                                            filter_options: [...this.state.filter_options, item]
                                        })
                                    }
                                    else
                                        if (id == 9) {
                                            const item = {
                                                value: '9',
                                                label: 'Viewings'
                                            }

                                            this.setState({
                                                filter_options: [...this.state.filter_options, item]
                                            })
                                        }
                                        else
                                            if (id == 10) {
                                                const item = {
                                                    value: '10',
                                                    label: 'Offers'
                                                }

                                                this.setState({
                                                    filter_options: [...this.state.filter_options, item]
                                                })
                                            } else
                                                if (id == 12) {
                                                    const item = {
                                                        value: '12',
                                                        label: 'Last call'
                                                    }

                                                    this.setState({
                                                        filter_options: [...this.state.filter_options, item]
                                                    })
                                                } else
                                                    if (id == 13) {
                                                        const item = {
                                                            value: '13',
                                                            label: 'Last match'
                                                        }

                                                        this.setState({
                                                            filter_options: [...this.state.filter_options, item]
                                                        })
                                                    } else
                                                        if (id == 14) {
                                                            const item = {
                                                                value: '14',
                                                                label: 'Move in date'
                                                            }

                                                            this.setState({
                                                                filter_options: [...this.state.filter_options, item]
                                                            })
                                                        } else
                                                            if (id == 15) {
                                                                const item = {
                                                                    value: '15',
                                                                    label: 'Replied to whatsapp'
                                                                }

                                                                this.setState({
                                                                    filter_options: [...this.state.filter_options, item]
                                                                })
                                                            } else
                                                                if (id == 16) {
                                                                    const item = {
                                                                        value: '16',
                                                                        label: 'Clicked - Book Viewing/ More Info'
                                                                    }

                                                                    this.setState({
                                                                        filter_options: [...this.state.filter_options, item]
                                                                    })
                                                                } else
                                                                    if (id == 17) {
                                                                        const item = {
                                                                            value: '17',
                                                                            label: 'Viewing booked Since Requested'
                                                                        }

                                                                        this.setState({
                                                                            filter_options: [...this.state.filter_options, item]
                                                                        })
                                                                    } else
                                                                        if (id == 18) {
                                                                            const item = {
                                                                                value: '18',
                                                                                label: 'Viewing date'
                                                                            }

                                                                            this.setState({
                                                                                filter_options: [...this.state.filter_options, item]
                                                                            })
                                                                        } else
                                                                            if (id == 19) {
                                                                                const item = {
                                                                                    value: '19',
                                                                                    label: 'Viewing Outcome'
                                                                                }

                                                                                this.setState({
                                                                                    filter_options: [...this.state.filter_options, item]
                                                                                })
                                                                            } else
                                                                                if (id == 20) {
                                                                                    const item = {
                                                                                        value: '20',
                                                                                        label: 'Number confirmed on whatsapp'
                                                                                    }

                                                                                    this.setState({
                                                                                        filter_options: [...this.state.filter_options, item]
                                                                                    })
                                                                                } else
                                                                                    if (id == 21) {
                                                                                        const item = {
                                                                                            value: '21',
                                                                                            label: 'Offer Status'
                                                                                        }

                                                                                        this.setState({
                                                                                            filter_options: [...this.state.filter_options, item]
                                                                                        })
                                                                                    } else
                                                                                        if (id == 22) {
                                                                                            const item = {
                                                                                                value: '22',
                                                                                                label: 'Deal Signed'
                                                                                            }

                                                                                            this.setState({
                                                                                                filter_options: [...this.state.filter_options, item]
                                                                                            })
                                                                                        }

        rows.splice(idx, 1)

        this.setState({ rows })
    }

    resetAddFilterState = (e) => {
        this.setState({
            filter_id: 0,
            filter_title: "",
            titleHasError: false,
            rows: [],
            addNewFilterSelect: "",
            filter_options: [
                {
                    value: '1',
                    label: 'Contact type',
                },
                {
                    value: '2',
                    label: 'Community / Sub community',
                },
                {
                    value: '3',
                    label: 'Price',
                },
                {
                    value: '4',
                    label: 'Bedrooms',
                },
                {
                    value: '5',
                    label: 'Created',
                },
                {
                    value: '6',
                    label: 'Stage',
                },
                {
                    value: '7',
                    label: 'Furnished  / unfurnished',
                },
                {
                    value: '8',
                    label: 'Agents',
                },
                {
                    value: '9',
                    label: 'Viewings',
                },
                {
                    value: '10',
                    label: 'Offers',
                },
                {
                    value: '11',
                    label: 'Type',
                },
                {
                    value: '12',
                    label: 'Last call',
                },
                {
                    value: '13',
                    label: 'Last match',
                },
                {
                    value: '14',
                    label: 'Move in date',
                },
            ],
        })
    }

    handleNewFilter = () => {
        this.setState({ isFilterOpen: true })

    }
    handlemourequests = (e) => {
        this.setState({ ismourequestOpen: true })
    }
    handlemourequestsok = (e) => {
        this.setState({ ismourequestOpen: false })
    }
    handlemourequestscancel = (e) => {
        this.setState({ ismourequestOpen: false })
    }

    handleEditFilter = (id, e) => {

        let filter_option = []

        const newrows = [
            {
                id: 1
            },
            { id: 2 },
            {
                id: 3,
            },
            { id: 4 },
            { id: 5 },
            {
                id: 6
            },
            { id: 7 }
        ]
        this.setState({ rows: newrows })

        this.setState({ isFilterEditOpen: true, filter_id: id })

        API.get("/get_saved_filter/" + id)
            .then((response) => {
                if (response.data.success) {
                    const response_filter = response.data.filter
                    // API.get("/sub_community/" + response_filter.community_value_id)
                    //   .then((response) => {
                    //     if (response.data.success)
                    //       this.setState({ sub_communities: response.data.sub_communities })
                    //   })


                    const stage = response.data.filter_stages
                    const contact_type = response.data.filters_contact_types
                    const furnished_unfurnished = response.data.filters_furnished_unfurnisheds


                    this.setState({
                        stage_value: stage, contact_types_value: contact_type, furnished_types_value: furnished_unfurnished,
                        filter_title: response_filter.title,
                        community_value_id: response_filter.community_value_id,
                        sub_community_value_id: response_filter.sub_community_value_id,

                        price_first_value_id: response_filter.price_first_value_id,
                        price_first_value: response_filter.price_first_value,
                        price_min: response_filter.price_min,
                        price_max: response_filter.price_max,
                        inrange: response_filter.price_first_value_id == 7 ? true : false,

                        date_morethan_option: response_filter.date_morethan_option,
                        date_lessthan_option: response_filter.date_lessthan_option,

                        move_in_date_morethan_option: response_filter.move_in_date_morethan_option,
                        move_in_date_lessthan_option: response_filter.move_in_date_lessthan_option,
                        date_value_id: response_filter.date_value_id,
                        date_value: response_filter.date_value,
                        move_in_date_date_range: this.state.move_in_date_date_range,

                        bed_rooms_value_id: response_filter.bed_rooms_value_id,
                        bed_rooms_value: response_filter.bed_rooms_value,
                        default_stage_value: response.data.filter_stages,
                        default_contact_types_value: response.data.filters_contact_types,
                        default_furnished_types_value: response.data.filters_furnished_unfurnisheds

                    })
                }
            })
    }

    handleEditOk = () => {
        const id = this.state.filter_id
        const data = {

            filter_title: this.state.filter_title,
            community_value_id: this.state.community_value_id,
            sub_community_value_id: this.state.sub_community_value_id,

            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            stage_value: this.state.stage_value,

            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,

        }

        API.put("/update_filter/" + id, data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ isFilterEditOpen: false, filter_title: "" })
                    this.fetchFilter();

                }
            })

    }

    handleOk = () => {

        if (this.state.filter_title == "") {

            this.setState({ titleHasError: true })
            return
        }


        const data = {


            filter_title: this.state.filter_title,
            community_value_id: this.state.community_value_id,
            sub_community_value_id: this.state.sub_community_value_id,

            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            agent_value: this.state.agent_value,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
        }



        API.post("/add_filter", data)
            .then((response) => {


                //return
                if (response.data.success) {
                    this.setState({ isFilterOpen: false, filter_title: "" })
                    this.fetchFilter();
                    this.resetAddFilterState();

                }
            })
    };

    handleSaveFilter = () => {
        this.setState({ filterTitleModal: true })

    };

    handleCancelTitle = () => {
        this.setState({ filterTitleModal: false })
    }

    handleSaveAllFilter = () => {


        if (this.state.filter_title == "") {

            toast.error("Title Required")

            this.setState({ titleHasError: true })
            return
        }


        const data = {


            filter_title: this.state.filter_title,
            community_value_id: this.state.community_value_id,
            sub_community_value_id: this.state.sub_community_value_id,

            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            agent_value: this.state.agent_value,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
        }



        API.post("/add_filter", data)
            .then((response) => {


                //return
                if (response.data.success) {
                    this.setState({ filterTitleModal: false, filter_title: "" })
                    this.fetchFilter();
                    this.resetAddFilterState();

                }
            })

    }
    handleCancel = () => {
        this.setState({ isFilterOpen: false })
    };

    handleEditCancel = () => {
        this.setState({ isFilterEditOpen: false, filter_title: '' })
    };

    handleFilterTitle = (e) => {

        this.setState({ filter_title: e.target.value, titleHasError: false })
    }

    handleContactType = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ contact_types_value: e })


        const page = 1;
        const perPage = 10;

        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: e,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        this.fetchData(page, perPage, data)
        this.fetchTotalStatus(data)
    }

    handleCommunity = (value, e) => {
        this.setState({ community_value_id: value })
        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: e,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        this.setState({ isLoading: true })
        API.post(`/leads_table?page=${page}&perPage=${perPage}`, data)
            .then(async (response) => {
                await response.data.leads;
                this.setState({
                    leads: response.data.leads,
                    isLoading: false
                })

                if (value !== null || value !== '') {
                    API.get("/sub_community/" + value)
                        .then((response) => {
                            if (response.data.success)
                                this.setState({ sub_communities: response.data.sub_communities })
                        })
                }

            })
    }


    handleSubCommunity = (value, e) => {
        this.setState({ sub_community_value_id: value })
        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: e,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        this.setState({ isLoading: true })
        API.post(`/leads_table?page=${page}&perPage=${perPage}`, data)
            .then(async (response) => {
                await response.data.leads;
                this.setState({
                    leads: response.data.leads,
                    isLoading: false
                })
            })
    }

    handleBedRooms_id = (e) => {    console.log(e)
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ bed_rooms_value_id: e })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: e,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }




        if (this.state.bed_rooms_value != '') {
            this.fetchData(page, perPage, data);
            this.fetchTotalStatus(data)
        }

    }

    handleBedRoomsValue = (e) => {

        this.setState({ bed_rooms_value: e.target.value  })
        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: e.target.value ,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,


            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range,

            isViewingTableShow: false,
            isOfferTableShow: false,
            isOfferTabShow: false,
            isViewingTabShow: false
        }

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    // Handle Filters

    handleFilterStages = (e) => {

        if (e.length == 0) {
            e = this.state.filtered_lead_stage
        } else {
            const result = e.filter(element => !this.state.stage_value.includes(element));
            e = result;
        }

        this.setState({ default_stage_value: e, nested_stage_value: e, stage_value: e })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: e,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: e,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }

    handlePendingViewChange = (e) => {
        this.setState((prevState) => ({ pendingLeadsShow: !prevState.pendingLeadsShow, }));
        this.setState({ isViewingTableShow: false, isViewingTabShow: false })
        this.setState({ isOfferTabShow: false })
        this.setState({ stage_value: [] })
    }

    getPendingLeads = () => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents
        const listings_array = all_data.stock_area_reducer.listing_array
        const total_data = { selectedTeam: this.state.selectedTeam }

        this.setState({ listings_array: listings_array })
        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        this.setState({ pending_leads: true });
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: listings_array,
            pending_leads: true,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,

            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }


        this.fetchData(page, perPage, data);    
    }
    handleAllLeads=()=> {
        this.setState({ stage_value: [] })
        this.setState({ stage_value:  [], default_stage_value:  [] })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,

            last_four_digit : this.state.last_four_digit,

            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value:  ['12'],
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }

    accessPondLeads = () => {
        this.setState({ stage_value: ['12'] })
        this.setState({ stage_value:  ['12'], default_stage_value:  ['12'] })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,

            last_four_digit : this.state.last_four_digit,

            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value:  ['12'],
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    };

    handleStages = (e) => {

        this.setState({ pendingLeadsShow: false })
        if (e.length != 0) {
            const result = e.filter(element => !this.state.stage_value.includes(element));
            e = result;
        }

        const value_arr = e.includes('6');
        if (value_arr) {
            if (!e.includes('13')) {
                e.push('13')
            } else {
                e.pop('13')
            }

            if (!e.includes('14')) {
                e.push('14')
            } else {
                e.pop('14')
            }


        }

        if (!e.includes('25')) {
            this.setState({ verified_sub_stage_value: [] })
        }

        const viewing_value_arr = e.includes('8');
        if (viewing_value_arr) {
            this.setState({ isViewingTableShow: true, isViewingTabShow: true, })

            if (!e.includes('16')) {
                e.push('16')
            } else {
                e.pop('16')
            }

            if (!e.includes('17')) {
                e.push('17')
            } else {
                e.pop('17')
            }
            if (!e.includes('18')) {
                e.push('18')
            } else {
                e.pop('18')
            }


        } else {
            this.setState({ isViewingTableShow: false, isViewingTabShow: false })

        }

        //    if offr show filter tab
        const offer_value = e.includes('6')
        if (offer_value) {
            this.setState({ isOfferTabShow: true })
        } else {
            this.setState({ isOfferTabShow: false })
        }

        this.setState({ stage_value: e, default_stage_value: e })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,

            last_four_digit : this.state.last_four_digit,

            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: e,
            verified_sub_stage_value: (!e.includes('25')) ? [] : this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }

     handleVerifiedStages = (e) => {
        console.log("verified_sub_stage_value", e);

        // if(e.length != 0){
        //     const result = e.filter(element => !this.state.stage_value.includes(element));
        //     e = result;
        // }

        const value_arr = e.includes('6');
        if (value_arr) {
            if (!e.includes('13')) {
                e.push('13')
            } else {
                e.pop('13')
            }

            if (!e.includes('14')) {
                e.push('14')
            } else {
                e.pop('14')
            }


        }

        const viewing_value_arr = e.includes('8');
        if (viewing_value_arr) {
            // this.setState({isViewingTableShow:true})

            if (!e.includes('16')) {
                e.push('16')
            } else {
                e.pop('16')
            }

            if (!e.includes('17')) {
                e.push('17')
            } else {
                e.pop('17')
            }
            if (!e.includes('18')) {
                e.push('18')
            } else {
                e.pop('18')
            }


        } else {
            // this.setState({isViewingTableShow:false})
        }

        this.setState({ verified_sub_stage_value: e })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: e,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }
    handleStages2 = (e) => {




        this.setState({ stage_value: e, default_stage_value: e })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: e,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }

    handleStages3 = (e) => {


        // return
        // this.setState({stage_value:[]})

        const offer_array = []
        const viewing_array = []
        let backend_array = []
        const val = e.target.value
        if (val == 30) {
            backend_array = []
        } else
            if (val == 6) {
                if (!offer_array.includes('6')) {
                    offer_array.push('6')
                } else {
                    offer_array.pop('6')
                }
                if (!offer_array.includes('13')) {
                    offer_array.push('13')
                } else {
                    offer_array.pop('13')
                }
                if (!offer_array.includes('14')) {
                    offer_array.push('14')
                } else {
                    offer_array.pop('14')
                }
                backend_array.push('6', '13', '14')
            }
            else
                if (val == 8) {
                    if (!viewing_array.includes('8')) {
                        viewing_array.push('8')
                    } else {
                        viewing_array.pop('8')
                    }
                    if (!viewing_array.includes('16')) {
                        viewing_array.push('16')
                    } else {
                        viewing_array.pop('16')
                    }
                    if (!viewing_array.includes('17')) {
                        viewing_array.push('17')
                    } else {
                        viewing_array.pop('17')
                    }

                    if (!viewing_array.includes('18')) {
                        viewing_array.push('18')
                    } else {
                        viewing_array.pop('18')
                    }
                    backend_array.push('8', '16', '17', '18')
                } else {
                    backend_array.push(e.target.value)
                }




        //    const value_arr= e.includes('6');
        //    if(value_arr){
        //      if(!e.includes('13')){
        //         e.push('13')
        //      }else{
        //         e.pop('13')
        //      }

        //     if(!e.includes('14')){
        //         e.push('14')
        //     }else{
        //         e.pop('14')
        //     }


        //    }

        //    const viewing_value_arr= viewing_array.includes('8');
        //    if(viewing_value_arr){
        //     this.setState({isViewingTableShow:true})

        //     //  if(!e.includes('16')){
        //     //     e.push('16')
        //     //  }else{
        //     //     e.pop('16')
        //     //  }

        //     // if(!e.includes('17')){
        //     //     e.push('17')
        //     // }else{
        //     //     e.pop('17')
        //     // }
        //     // if(!e.includes('18')){
        //     //     e.push('18')
        //     // }else{
        //     //     e.pop('18')
        //     // }


        //    }else {
        //     this.setState({isViewingTableShow:false})
        //    }

        this.setState({ stage_value: backend_array, default_stage_value: e, selectedValue: e })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            columnKey: this.state.columnKey,
            order: this.state.order,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: backend_array,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }
    handleFurnishedTypes = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ furnished_types_value: e })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: e,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }

    handleDeleteFilter = (targetKey, action) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.delete("/delete_filter/" + targetKey)
                    .then((response) => {
                        if (response.data.success) {
                            toast.success("Successfully deleted")
                            this.fetchFilter()
                        }
                    })
            }
        })
    };

    // filter options

    handleNameValue_id = (e) => {
        this.setState({ name_value_id: e })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: e,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id:7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        if (this.state.name_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleName = (e) => {

        this.setState({ name_value: e.target.value })
        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: e.target.value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handlePhoneValue_id = (e) => {
        this.setState({ phone_value_id: e })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: e.target.value,
            phone_value_id: e,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        if (this.state.phone_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handlePhone = (e) => {

        this.setState({ phone_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: e.target.value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }


        if (this.state.phone_value_id > 0) {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleAgentValue_id = (e) => {

        this.setState({ agent_value_id: e })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: e,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        if (this.state.agent_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }
    handleAgent = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ agent_value: e })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: e,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }


    handlePropertyForFirstValue = (e) => {

        const property_for_first_value = e.target.value;
        if (property_for_first_value != '') {
            this.setState({ property_for_second_option: true })
        } else {
            this.setState({ property_for_second_option: false })
        }
    }

    handleRefnoFirst = (e) => {

        const refno_first_value = e.target.value;
        this.setState({ refno_first_value: refno_first_value })

        if (refno_first_value != '') {
            this.setState({ refno_active_second_option: true })
        } else {
            this.setState({ refno_active_second_option: false })
        }
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);


    }

    handleRefnoSecond = (e) => {

        this.setState({ refno_second_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: e.target.value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }

    handleRefnoFirstValue_id = (e) => {
        this.setState({ refno_first_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: e.target.value,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,


            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.refno_first_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleRefRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ refno_and_or_option: !this.state.refno_and_or_option })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: !this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.refno_second_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleRefnoSecondValue_id = (e) => {
        this.setState({ refno_second_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: e.target.value,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.refno_second_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    // property for
    handleSales = (e) => {
        if (this.state.sale) {
            property_for_array = property_for_array.filter(e => e !== 'sale');
        } else {

            property_for_array.push('sale')
        }

        this.setState({ sale: !this.state.sale })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id:7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }
    handleRent = (e) => {
        if (this.state.rent) {
            property_for_array = property_for_array.filter(e => e !== 'rent');
        } else {

            property_for_array.push('rent')
        }
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ rent: !this.state.rent })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id:7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }
    handleTenant = (e) => {
        if (this.state.tenant) {
            property_for_array = property_for_array.filter(e => e !== 'tenanted');
        } else {

            property_for_array.push('tenanted')
        }
        this.setState({ tenant: !this.state.tenant })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            selectedTeam: this.state.selectedTeam,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }
    handleShortterm = (e) => {
        if (this.state.short_term) {
            property_for_array = property_for_array.filter(e => e !== 'short-term');
        } else {

            property_for_array.push('short-term')
        }
        this.setState({ short_term: !this.state.short_term })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            selectedTeam: this.state.selectedTeam,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id:7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    // property type
    handleResidential = (e) => {



        if (this.state.residential) {

            property_type_array = property_type_array.filter(e => e !== 'Residential');


        } else {

            property_type_array.push('Residential')


        }

        this.setState({ residential: !this.state.residential })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            selectedTeam: this.state.selectedTeam,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }
    handleCommercial = (e) => {
        if (this.state.commercial) {
            property_type_array = property_type_array.filter(e => e !== 'Commercial');


        } else {
            property_type_array.push('Commercial')

        }
        this.setState({ commercial: !this.state.commercial })

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,
            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }

    // unit no options
    handleUnitnoFirst = (e) => {


        const unitno_first_value = e.target.value;
        this.setState({ unitno_first_value: unitno_first_value })

        if (unitno_first_value != '') {
            this.setState({ unitno_active_second_option: true })
        } else {
            this.setState({ unitno_active_second_option: false })
        }
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);


    }

    handleUnitnoSecond = (e) => {

        this.setState({ unitno_second_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: e.target.value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }

    handleUnitnoFirstValue_id = (e) => {
        this.setState({ unitno_first_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: e.target.value,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.unitno_first_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleUnitRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ unitno_and_or_option: !this.state.unitno_and_or_option })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.unitno_second_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleUnitnoSecondValue_id = (e) => {
        this.setState({ unitno_second_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,


            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: e.target.value,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.unitno_second_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }


    // price options

    handlePriceFirst = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        const price_first_value = e;

        // this.setState((prevState) => (
        //     e.target.validity.valid ? { price_first_value: e} : prevState)
        // )
        this.setState({ price_first_value: e })

        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e > 0) {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        } else {
            return
        }




    }

    // price in range
    handlePriceMin = (e) => { 
        this.setState({ price_min: e })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: e,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        if (this.state.price_max > 0) {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }


    }

    handlePriceMax = (e) => {

        this.setState({ price_max: e })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const max = e
        const page = 1;
        const perPage = 10;

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: e,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        if (this.state.price_min > 0 && max > this.state.price_min) {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handlePriceSecond = (e) => {

        this.setState({ price_second_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: e.target.value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }

    handlePriceFirstValue_id = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });


        this.setState({ inrange: false })
        if (e == 7) {
            this.setState({ inrange: true })
        }
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7, price_value_id: e })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.price_first_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handlePriceRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ price_and_or_option: !this.state.price_and_or_option })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.price_second_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handlePriceSecondValue_id = (e) => {
        this.setState({ price_second_value_id: e.target.value })

        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,


            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: e.target.value,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,

            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.price_second_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }


    // date option

    handleDateMorethan = (e) => {
        this.setState({ date_morethan_option: true, date_lessthan_option: false })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: true,
            date_lessthan_option: false,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleMoveInDateMorethan = (e) => {
        this.setState({ move_in_date_morethan_option: true, move_in_date_lessthan_option: false })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: true,
            date_lessthan_option: false,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,

            move_in_date_morethan_option: true,
            move_in_date_lessthan_option: false,
            move_in_date_value: e,
            move_in_date_value_id: this.state.move_in_date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleDateLessthan = (e) => {
        this.setState({ date_morethan_option: false, date_lessthan_option: true })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: false,
            date_lessthan_option: true,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleMoveInDateLessthan = (e) => {
        this.setState({ move_in_date_morethan_option: false, move_in_date_lessthan_option: true })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            move_in_date_filter: this.state.move_in_date_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: false,
            date_lessthan_option: true,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            move_in_date_morethan_option: false,
            move_in_date_lessthan_option: true,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleDateValue_id = (e) => {
        this.setState({ date_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: e.target.value,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    getCurrentTime = (date) => {
        if (date != null && date != "" && date != undefined && date != "0000-00-00 00:00:00") {
            let newDate = new Date(date);
            newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
            return newDate.toISOString();
        }
    }

    getTime = (date) => {
        const added_date = new Date(date);
        const formattedTime = added_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return formattedTime;
    }
    getDate = (date) => {
        const added_date = new Date(date);
        const formattedDate = added_date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
        return formattedDate;
    }

    getPrice = (price) => {
        if (price != null && price != "" && price != undefined) {
            if (!isNaN(parseInt(price))) {
                return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
            }
        }
    }
    getUnixTime = (time) => {
        const getRegionalTime = this.getCurrentTime(time);
        const originalTimestamp = new Date(getRegionalTime).getTime() / 1000;
        return originalTimestamp;
    }

    getCurrentTimeStamp = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const unixTime = new Date(timestamp).getTime() / 1000;
        return unixTime;
    }
    getRemainingTime = (added_date) => {
        const dateObj = new Date(added_date);
        const unixTime = dateObj.getTime() / 1000;
        const addedDateUnixTime = unixTime + 28 * 60 * 60;
        let currentDateUnixTime = this.getCurrentTimeStamp();
        let timeDifference = addedDateUnixTime - currentDateUnixTime;
        if (timeDifference <= 0) {
            this.setState({ remainingTime: "00:00:00" })
            // return "00:00:00";
            // const postData = {
            //     "current_request": data,
            // }
            // API.post("/request_time_expire", postData)
            //     .then((response)=>{
            //         if(response.data.success){
            //             this.setState({all: response.data.all})
            //             this.setState({requested_viewings: response.data.requested_viewings})
            //         }
            //     }).catch((err) => {
            //     console.log(err)
            //     toast.error('Something went wrong');
            // })
        } else {
            const hours = Math.floor(timeDifference / 3600);
            const minutes = Math.floor((timeDifference % 3600) / 60);
            const seconds = timeDifference % 60;
            const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            this.setState({ remainingTime: formattedDuration })
        }
    }
    getEndTime = (start_time) => {
        const originalDate = new Date(start_time);
        const updatedDate = new Date(originalDate.getTime() + (24 * 60 * 60 * 1000));
        const year = updatedDate.getFullYear();
        const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
        const day = String(updatedDate.getDate()).padStart(2, '0');
        const hours = String(updatedDate.getHours()).padStart(2, '0');
        const minutes = String(updatedDate.getMinutes()).padStart(2, '0');
        const seconds = String(updatedDate.getSeconds()).padStart(2, '0');
        const updatedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return updatedTime;
    }

    handleDateValue = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ date_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: e.target.value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    // last call
    handleLastCallOptionValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ last_call_option_value: e.target.value })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            move_in_date_filter: this.state.move_in_date_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: e.target.value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_call_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleLastCallValue_id = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ last_call_value_id: e.target.value })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: e.target.value,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_call_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleLastCallValue = (e) => {
        this.setState({ last_call_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: e.target.value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    // last match
    handleLastMatchOptionValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ last_match_option_value: e.target.value })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: e.target.value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_match_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleLastMatchValue_id = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ last_match_value_id: e.target.value })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: e.target.value,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_match_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleLastMatchValue = (e) => {
        this.setState({ last_match_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: e.target.value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    // move in date
    handleMoveInDateOptionValue = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: e.target.value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        if (e.target.value == 7) {
            this.setState({ inrange_move_in_date: true, move_in_date_is_empty: false })
            if (this.state.move_in_date_date_range.length > 0) {
                this.fetchTotalStatus(data)
                this.fetchData(page, perPage, data);
            }
        } else
            if (e.target.value == 8) {
                this.setState({ move_in_date_is_empty: true })
                this.fetchTotalStatus(data)
                this.fetchData(page, perPage, data);
            }
            else {
                this.setState({ inrange_move_in_date: false, move_in_date_is_empty: false })
                if (this.state.move_in_date_value != '') {
                    this.fetchTotalStatus(data)

                    this.fetchData(page, perPage, data);
                }
            }



        this.setState({ move_in_date_option_value: e.target.value })





    }

    handleMoveInDateValue_id = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ move_in_date_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: e.target.value,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }

        const page = 1;
        const perPage = 10;
        if (this.state.move_in_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleMoveInDateValue = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ move_in_date_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,

            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: e.target.value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }


    handleDateFirst = (e) => {

        const date_first_value = e.target.value;
        this.setState({ date_first_value: date_first_value })

        if (date_first_value != '') {
            this.setState({ date_active_second_option: true })
        } else {
            this.setState({ date_active_second_option: false })
        }
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);


    }

    handleDateSecond = (e) => {

        this.setState({ date_second_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: e.target.value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array

        }

        const page = 1;
        const perPage = 10;
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }

    handleDateFirstValue_id = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ date_first_value_id: e.target.value })
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: e.target.value,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_first_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleDateRadio = (e) => {
        // alert(!this.state.refno_and_or_option)
        this.setState({ date_and_or_option: !this.state.date_and_or_option })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,

            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: this.state.date_second_value_id,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_second_value != '') {
            this.fetchTotalStatus(data)

            this.fetchData(page, perPage, data);
        }

    }

    handleDateSecondValue_id = (e) => {
        this.setState({ date_second_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,

            refno_first_value_id: this.state.refno_first_value_id,
            refno_and_or_option: this.state.refno_and_or_option,
            refno_second_value_id: this.state.refno_second_value_id,
            refno_first_value: this.state.refno_first_value,
            refno_second_value: this.state.refno_second_value,


            unitno_first_value_id: this.state.unitno_first_value_id,
            unitno_and_or_option: this.state.unitno_and_or_option,
            unitno_second_value_id: this.state.unitno_second_value_id,
            unitno_first_value: this.state.unitno_first_value,
            unitno_second_value: this.state.unitno_second_value,

            price_first_value_id: this.state.price_first_value_id,
            price_and_or_option: this.state.price_and_or_option,
            price_second_value_id: this.state.price_second_value_id,
            price_first_value: this.state.price_first_value,
            price_second_value: this.state.price_second_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_first_value_id: this.state.date_first_value_id,
            date_and_or_option: this.state.date_and_or_option,
            date_second_value_id: e.target.value,
            date_first_value: this.state.date_first_value,
            date_second_value: this.state.date_second_value,

            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,

            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,


            property_for: property_for_array,

            property_type: property_type_array
        }

        const page = 1;
        const perPage = 10;
        if (this.state.date_second_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }





    onInputChange = (e) => {
        console.log("onchange", e.target.value)
    }

    onChangeFollowupFor = (e) => {

        this.setState({ followup_for: e.target.value })
    }
    onChangeReminder = (e) => {
        this.setState({ reminder: e.target.value })
    }
    handleAgentDetails = (e) => {
        this.setState({ selected_agent: e.target.value })
    }


    handlePriceRange = (e) => {
        const radio = e.target.value
        if (radio == 1) {
            this.setState({ greaterthanprice: true, leassthanprice: false, inbetweenprice: false })
        } else
            if (radio == 2) {
                this.setState({ greaterthanprice: false, leassthanprice: true, inbetweenprice: false })
            } else
                if (radio == 3) {
                    this.setState({ greaterthanprice: false, leassthanprice: false, inbetweenprice: true })
                }
    }

    handleGreaterValue = (e) => {
        this.setState({ greaterValue: e.target.value })
    }

    handleLessValue = (e) => {
        this.setState({ lessValue: e.target.value })
    }

    onTypeChange = (e) => {
        this.setState({ type: e.target.value })
    }

    onChangeVendor = (e) => {
        this.setState({ vendor: e })
    }
    onChangeParentLead = (e) => {
        this.setState({ parent_lead: e })
    }

    onChangeAssignAgent = (id, e) => {
        this.setState({ assigned_agent: e })
        const postData = {
            status: 3,
            lead_id: id,
            assigned_agent: e
        }
        this.callForAcceptDecline(postData);
    }

    onChangeParentAgent = (e) => {
        this.setState({ parent_agent: e })
    }

    onChangeParentLeadStage = (e) => {
        this.setState({ parent_lead_stage: e })
    }

    getAllTags = () => {
        this.setState({ sendTagData: this.state.sendTagDataAll, isAllActive: true, isRepliedActive: false, isPendingActive: false })

    }
    getRepliedTags = () => {
        this.setState({ sendTagData: this.state.sendTagDataReplied, isAllActive: false, isRepliedActive: true, isPendingActive: false })
    }
    getPendingTags = () => {
        this.setState({ sendTagData: this.state.sendTagDataPending, isAllActive: false, isRepliedActive: false, isPendingActive: true })
    }
    getAllRecieveTags = () => {
        this.setState({ recieveTagData: this.state.recieveTagDataAll, isAllRecieveTags: true, isRepliedRecieveTags: false, isPendingRecieveTags: false, })
    }
    getRepliedRecieveTags = () => {
        this.setState({ recieveTagData: this.state.recieveTagDataReplied, isAllRecieveTags: false, isRepliedRecieveTags: true, isPendingRecieveTags: false, })
    }
    getPendingRecieveTags = () => {
        this.setState({ recieveTagData: this.state.recieveTagDataPending, isAllRecieveTags: false, isRepliedRecieveTags: false, isPendingRecieveTags: true, })
    }

    onSearchClient = (e) => {

        API.get("/get_clients/" + e)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ clientList: response.data.contacts })
                }
            })
    }

    handleMinKeyDown = (event) => {
        const maxValue = this.state.greaterValue
        const data = {
            greaterValue: this.state.greaterValue,
            lessValue: this.state.lessValue
        }
        if (event.key === 'Enter') {
            if (maxValue > 0) {
            } else {
                this.textInput.current.focus();
            }
        }
    }



    fetchData = (page, perPage, data, xChk) => {
        this.setState({ isLoading: true })
        API.post(`/leads_table?page=${page}&perPage=${perPage}`, data)
            .then(async (response) => {
                // console.log('leads');
                // console.log(response);
                await response.data.leads;
                this.setState({ leads: response.data.leads, isLoading: false })
                if (this.state.agents.length < 1 && this.state.communities.length < 1) {
                    this.fetchAllAgents();
                    this.fetchFilter();
                    this.fetchAllRequest();
                    this.getActivityFeed();
                    this.getTags();
                }
                this.setState({ isRequestInProgress: false });
            })
    }

    fetchTotalStatus = (data) => {
        // alert();
        API.post("/leads_table_total", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        new_inquire: response.data.new_inquire,
                        no_answer: response.data.no_answer,
                        offer: response.data.offer,
                        followup: response.data.followup,
                        viewing: response.data.viewing,
                        working: response.data.working,
                        expired: response.data.expired,
                        pond: response.data.pond,
                        dead: response.data.dead,
                        second_viewing: response.data.second_viewing,
                        not_interest: response.data.not_interest,
                        consider: response.data.consider,
                        offer_accepted: response.data.offer_accepted,
                        offer_rejected: response.data.offer_rejected,
                        verfied_new_inquire: response.data.verfied_new_inquire,
                        verfied_no_answer: response.data.verfied_no_answer,
                        verfied_working: response.data.verfied_working,
                        verfied_expired: response.data.verfied_expired,
                        verfied_viewing: response.data.verfied_viewing,
                        verfied_offer: response.data.verfied_offer,
                    })
                }

            })
    }


    onDeleteCollab = (user_id, index, id, e) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.post("/delete-leads-collabs/" + id, { lead_id: this.state.lead_details.id, user_id: user_id })
                    .then((response) => {
                        if (response.data.success) {
                            this.state.collaborators.splice(index, 1);
                            this.setState({ collaborators: this.state.collaborators })
                            toast.success("Successfully deleted")
                        }
                    })
            }
        })
    }
    handleTabs = (e, event) => {




        const page = 1;
        const perPage = 10;
        let filter_options = []
        this.setState({ isLoading: true })

        API.get("/get_saved_filter/" + e)
            .then((response) => {


                const response_filter = response.data.filter

                const stage = response.data.filter_stages ? response.data.filter_stages : []
                const contact_type = response.data.filters_contact_types ? response.data.filters_contact_types : []
                const furnished_unfurnished = response.data.filters_furnished_unfurnisheds ? response.data.filters_furnished_unfurnisheds : []
                const agent_value_filter = response.data.agent_value_filter ? response.data.agent_value_filter : []

                const property_type_filter = response.data.property_type_filter ? response.data.property_type_filter : []

                const move_in_date_date_range = response.data.move_in_date_date_range ? response.data.move_in_date_date_range : []

                const clicked_book_viewing = response.data.clicked_book_viewing ? response.data.clicked_book_viewing : []
                const viewing_date_range = response.data.viewing_date_range ? response.data.viewing_date_range : []
                const offer_status = response.data.offer_status ? response.data.offer_status : []
                const viewing_outcome = response.data.viewing_outcome ? response.data.viewing_outcome : []

                this.setState({
                    last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
                    last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
                    last_activity_date_value: this.state.last_activity_date_value,
                    last_activity_date_value_id: this.state.last_activity_date_value_id,

                    last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
                    last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
                    last_whatsapp_date_value: this.state.last_whatsapp_date_value,
                    last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

                    last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
                    last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
                    last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
                    last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
                    selectedTeam: this.state.selectedTeam,
                    property_type_filter: property_type_filter,
                    community_filter: response_filter.community_value_id,
                    sub_community_filter: response_filter.sub_community_value_id,
                    property_filter: this.state.property_filter,
                    name_value_id: this.state.name_value_id,
                    name_value: this.state.name_value,
                    refno_value: this.state.refno_value,
                    refno_value_id: this.state.refno_value_id,
                    phone_value_id: this.state.phone_value_id,
                    phone_value: this.state.phone_value,
                    agent_value_id: this.state.agent_value_id,
                    replied_to_whatsapp: response_filter.replied_to_whatsapp,
                    viewing_booked_since_requested: response_filter.viewing_booked_since_requested,
                    number_confirmed_on_whatsapp: response_filter.number_confirmed_on_whatsapp,
                    replied_to_whatsapp: response_filter.replied_to_whatsapp,
                    agent_value: agent_value_filter,
                    clicked_book_viewing: clicked_book_viewing,
                    viewing_date_range: viewing_date_range,
                    offer_status: offer_status,
                    viewing_outcome: viewing_outcome,

                    bed_rooms_value_id: response_filter.bed_rooms_value_id,
                    bed_rooms_value: response_filter.bed_rooms_value ? response_filter.bed_rooms_value : "",
                    stage_value: stage,

                    contact_types_value: contact_type,
                    furnished_types_value: furnished_unfurnished,
                    price_first_value_id: response_filter.price_first_value_id,
                    price_first_value: response_filter.price_first_value ? response_filter.price_first_value : "",
                    price_min: response_filter.price_min,
                    price_max: response_filter.price_max,
                    date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
                    date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
                    move_in_date_morethan_option: response_filter.move_in_date_morethan_option == 1 ? true : false,
                    move_in_date_lessthan_option: response_filter.move_in_date_lessthan_option == 1 ? true : false,
                    date_value: response_filter.date_value,
                    date_value_id: response_filter.date_value_id,

                    viewing_value: response_filter.viewing_value != null ? response_filter.viewing_value : "",
                    viewing_option_value: response_filter.viewing_option_value ? response_filter.viewing_option_value : 1,
                    viewing_min_value: response_filter.viewing_min_value != null ? response_filter.viewing_min_value : "",
                    viewing_max_value: response_filter.viewing_max_value != null ? response_filter.viewing_max_value : "",


                    offer_value: response_filter.offer_value != null ? response_filter.offer_value : "",
                    offer_option_value: response_filter.offer_option_value ? response_filter.offer_option_value : 1,
                    offer_min_value: response_filter.offer_min_value != null ? response_filter.offer_min_value : "",
                    offer_max_value: response_filter.offer_max_value != null ? response_filter.offer_max_value : "",



                    last_call_value: response_filter.last_call_value ? response_filter.last_call_value : 0,
                    last_call_value_id: response_filter.last_call_value_id ? response_filter.last_call_value_id : 1,
                    last_call_option_value: response_filter.last_call_option_value ? response_filter.last_call_option_value : 1,



                    last_match_value: response_filter.last_match_value ? response_filter.last_match_value : 0,
                    last_match_value_id: response_filter.last_match_value_id ? response_filter.last_match_value_id : 1,
                    last_match_option_value: response_filter.last_match_option_value ? response_filter.last_match_option_value : 1,


                    move_in_date_value: response_filter.move_in_date_value ? response_filter.move_in_date_value : "",
                    move_in_date_value_id: response_filter.move_in_date_value_id ? response_filter.move_in_date_value_id : 1,
                    move_in_date_option_value: response_filter.move_in_date_option_value ? response_filter.move_in_date_option_value : 1,
                    move_in_date_date_range: move_in_date_date_range

                })
                const all_data = store.getState((state) => state);
                const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
                const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
                const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
                const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

                const data = {
                    nested_stage_value: this.state.nested_stage_value,
                    listings_array: this.state.listings_array,
                    search_owner: this.state.search_owner,
                    
                    selectedAgents: selectedAgents,
                    isMatchedLead: isMatchedLead == true ? 1 : 0,
                    isExpiringLead: isExpiringLead == true ? 1 : 0,
                    isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
                    property_type_filter: property_type_filter,
                    community_filter: response_filter.community_value_id,
                    sub_community_filter: response_filter.sub_community_value_id,
                    property_filter: this.state.property_filter,
                    name_value_id: this.state.name_value_id,
                    name_value: this.state.name_value,
                    refno_value: this.state.refno_value,
                    refno_value_id: this.state.refno_value_id,
                    phone_value_id: this.state.phone_value_id,
                    phone_value: this.state.phone_value,
                    agent_value_id: this.state.agent_value_id,
                    agent_value: agent_value_filter,
                    clicked_book_viewing: clicked_book_viewing,
                    viewing_date_range: viewing_date_range,
                    offer_status: offer_status,
                    viewing_outcome: viewing_outcome,
                    replied_to_whatsapp: response_filter.replied_to_whatsapp,
                    viewing_booked_since_requested: response_filter.viewing_booked_since_requested,
                    number_confirmed_on_whatsapp: response_filter.number_confirmed_on_whatsapp,
                    replied_to_whatsapp: response_filter.replied_to_whatsapp,

                    bed_rooms_value_id: response_filter.bed_rooms_value_id,
                    bed_rooms_value: response_filter.bed_rooms_value ? response_filter.bed_rooms_value : "",
                    stage_value: stage,
                    verified_sub_stage_value: this.state.verified_sub_stage_value,
                    contact_types_value: contact_type,
                    furnished_types_value: furnished_unfurnished,
                    price_first_value_id: response_filter.price_first_value_id,
                    price_first_value: response_filter.price_first_value ? response_filter.price_first_value : "",
                    price_min: response_filter.price_min,
                    price_max: response_filter.price_max,
                    date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
                    date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
                    move_in_date_morethan_option: response_filter.move_in_date_morethan_option == 1 ? true : false,
                    move_in_date_lessthan_option: response_filter.move_in_date_lessthan_option == 1 ? true : false,
                    date_value: response_filter.date_value,
                    date_value_id: response_filter.date_value_id,

                    viewing_value: response_filter.viewing_value != null ? response_filter.viewing_value : "",
                    viewing_option_value: response_filter.viewing_option_value ? response_filter.viewing_option_value : 1,
                    viewing_min_value: response_filter.viewing_min_value != null ? response_filter.viewing_min_value : "",
                    viewing_max_value: response_filter.viewing_max_value != null ? response_filter.viewing_max_value : "",


                    offer_value: response_filter.offer_value != null ? response_filter.offer_value : "",
                    offer_option_value: response_filter.offer_option_value ? response_filter.offer_option_value : 1,
                    offer_min_value: response_filter.offer_min_value != null ? response_filter.offer_min_value : "",
                    offer_max_value: response_filter.offer_max_value != null ? response_filter.offer_max_value : "",



                    last_call_value: response_filter.last_call_value ? response_filter.last_call_value : 0,
                    last_call_value_id: response_filter.last_call_value_id ? response_filter.last_call_value_id : 1,
                    last_call_option_value: response_filter.last_call_option_value ? response_filter.last_call_option_value : 1,



                    last_match_value: response_filter.last_match_value ? response_filter.last_match_value : 0,
                    last_match_value_id: response_filter.last_match_value_id ? response_filter.last_match_value_id : 1,
                    last_match_option_value: response_filter.last_match_option_value ? response_filter.last_match_option_value : 1,


                    move_in_date_value: response_filter.move_in_date_value ? response_filter.move_in_date_value : "",
                    move_in_date_value_id: response_filter.move_in_date_value_id ? response_filter.move_in_date_value_id : 1,
                    move_in_date_option_value: response_filter.move_in_date_option_value ? response_filter.move_in_date_option_value : 1,
                    move_in_date_date_range: move_in_date_date_range

                }

                this.fetchTotalStatus(data)
                this.fetchData(page, perPage, data);
            })





    }

    // By Farooq


    // fTablePageUpdate = async (xPageNumber, xChk) => {
    //   this.setState({
    //     open: false,
    //   })
    //   const data = {
    //     property_type_filter:this.state.property_type_filter,
    //     community_filter:this.state.community_filter,
    //     sub_community_filter:this.state.sub_community_filter,
    //     property_filter:this.state.property_filter,
    //     name_value_id: this.state.name_value_id,
    //     name_value: this.state.name_value,

    //     phone_value_id: this.state.phone_value_id,
    //     phone_value: this.state.phone_value,

    //     bed_rooms_value_id: this.state.bed_rooms_value_id,
    //     bed_rooms_value: this.state.bed_rooms_value,
    //     agent_value_id: this.state.agent_value_id,
    //     agent_value: this.state.agent_value,

    //     stage_value: this.state.stage_value,
    //     contact_types_value: this.state.contact_types_value,
    //     furnished_types_value: this.state.furnished_types_value,


    //     price_first_value_id: this.state.price_first_value_id,
    //     price_first_value: this.state.price_first_value,
    //     price_min: this.state.price_min,
    //     price_max: this.state.price_max,

    //     date_first_value_id: this.state.date_first_value_id,
    //     date_and_or_option: this.state.date_and_or_option,
    //     date_second_value_id: this.state.date_second_value_id,
    //     date_first_value: this.state.date_first_value,
    //     date_second_value: this.state.date_second_value,

    //     date_morethan_option: this.state.date_morethan_option,
    //     date_lessthan_option: this.state.date_lessthan_option,
    //     date_value: this.state.date_value,
    //     date_value_id: this.state.date_value_id,
    //   }

    //   await this.fetchData(xPageNumber, 10, data, xChk);
    // }

    findNextOrder = (xId) => {
        let index = this.state.leads.data.findIndex(({ original_id }) => original_id === xId)
        return index > -1 && index < this.state.leads.data.length - 1 ? this.state.leads.data[index + 1] : undefined
    }

    findPrevOrder = (xId) => {
        // console.log("***xID *** ", xId);
        // console.log("***leads Data *** ", this.state.leads.data);
        let index = this.state.leads.data.findIndex(({ original_id }) => original_id === xId)
        // console.log("*** Index Prev Value **** ", index)
        // return index > -1 && index < this.state.leads.data.length - 1 ? this.state.leads.data[index - 1] : undefined
        return index > -1 && index < this.state.leads.data.length ? this.state.leads.data[index - 1] : undefined
    }

    handleOpenDrawer = (id, xPrev_Next) => {
        // console.log("**** ID *** ", id)
        if (xPrev_Next) {
            this.setState({
                loader: true
            });
        }

        this.setState({ matchs: [], not_matchs: [] })
        // By Farooq
        const nextId = this.findNextOrder(id);
        const prevId = this.findPrevOrder(id);
        // console.log("**Now Id***", id);
        // console.log("**Prev Id***", prevId);
        // console.log("**Next Id***", nextId);

        this.setState({
            // record_id: xPrev_Next ? this.state.record_id : id,
            nextId: nextId == undefined ? this.state.nextId : nextId.original_id,
            prevId: prevId == undefined ? this.state.prevId : prevId.original_id,
            currId: id
        })
        ///////////////////
        this.setState({ record_id: id })

        API.get("/lead_details/" + id)
            .then((response) => {



                if (response.data.success) {

                    this.setState({ currentRequest: null })
                    this.setState({
                        lead_details: response.data.lead_details, lead_list_details: response.data.lead_list_details, owner_other_properties: response.data.owner_other_properties,
                        all: response.data.all,
                        role: response.data.role.role,
                        agent_id: response.data.role.id,
                        requested_viewings: response.data.requested_viewings,
                        notes: response.data.notes, requirement_community: response.data.lead_list_details.community,
                        community_match: response.data.lead_list_details.community,
                        sub_community_match: response.data.lead_list_details.subcommunity,
                        min_budget: response.data.lead_details.budget_from,
                        max_budget: response.data.lead_details.budget_to,
                        bedroom_match: response.data.lead_list_details.bedroom,
                        comment: response.data.lead_details.comment,
                        referred_by: (response.data.lead_list_details.referred_by != "" && response.data.lead_list_details.referred_by != null && response.data.lead_list_details.referred_by != undefined) ? response.data.lead_list_details.referred_by : response.data.lead_list_details.lead_agent,
                        referred_to: response.data.lead_list_details.referred_to,
                        is_referred: (response.data.lead_list_details.referred_to == null || response.data.lead_list_details.referred_to == "") ? false : true,
                        call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                        //viewing_notes: response.data.viewing_notes,
                        email_notes: response.data.email_notes,
                        followup_notes: response.data.followup_notes,
                        offer_notes: response.data.offer_notes,
                        viewing_notes: response.data.viewing_notes,
                        owner_id: response.data.lead_list_details.owner_id,
                        owner_name: response.data.lead_list_details.owner,
                        owner_email: (response.data.lead_list_details && response.data.lead_list_details.owner_email) ? response.data.lead_list_details.owner_email : "",
                        owner_mobile: response.data.lead_list_details.mobile,
                        requirement_bedroom: response.data.lead_list_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                        requirement_max_budget: response.data.lead_details.budget_to, requirement_verifyedby: response.data.lead_details.verified_by,
                        requirement_lead_stage: response.data.lead_list_details.lead_stage, pin: response.data.lead_details.pinned == 1 || response.data.lead_details.pinned == 2 ? true : false,
                        requirement_community: response.data.lead_list_details.community, requirement_sub_community: response.data.lead_list_details.subcommunity,
                        requirement_agent_id: response.data.lead_list_details.lead_agent, lead_community: response.data.lead_list_details.community, lead_sub_community: response.data.lead_list_details.subcommunity,
                        contact_type_requirement: response.data.lead_list_details.contact_type, price_requirement: response.data.lead_list_details.lead_price,
                        price_first: response.data.lead_list_details.lead_price,
                        collaborators: response.data.collaborators, requirement_move_in_date: response.data.lead_list_details.move_in_date,
                        property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                        move_in_date: response.data.lead_list_details.move_in_date,
                        new_move_in_date: response.data.lead_list_details.move_in_date, new_bed: response.data.lead_list_details.bedroom,
                        new_community: response.data.lead_list_details.community, new_sub_community: response.data.lead_list_details.subcommunity,
                        // Farooq
                        loader: false
                    })

                    this.fetchExactListings()
                    this.fetchCoparableListings()
                    const page = 1;
                    const perPage = 5;
                    const data = {
                        leads_id: this.state.record_id,
                        bedroom: response.data.lead_list_details.bedroom,
                        price: response.data.lead_list_details.lead_price,
                        community: response.data.lead_list_details.community,
                        subcommunity: response.data.lead_list_details.subcommunity
                    }
                    this.fetchMatchedVerificationCallLeadList(page, perPage, data);

                    const lead_stage = response.data.lead_list_details.lead_stage
                    if (lead_stage == 1) {


                        const isViewingFound = this.state.lead_stage_filter.some(element => {
                            if (element.value === '1') {
                                return true;
                            }

                            return false;
                        });

                        const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                            if (element.value === '1') {
                                return true;
                            }

                            return false;
                        });

                        const item = {
                            label: 'New Inquiry',
                            value: '1'

                        }
                        if (!isViewingFound)
                            this.setState({
                                lead_stage_filter: [...this.state.lead_stage_filter, item]
                            })

                        if (!isViewingFoundAgent)
                            this.setState({
                                lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                            })

                    } else
                        if (lead_stage == 2) {


                            const isViewingFound = this.state.lead_stage_filter.some(element => {
                                if (element.value === '2') {
                                    return true;
                                }

                                return false;
                            });

                            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                if (element.value === '2') {
                                    return true;
                                }

                                return false;
                            });



                            const item = {
                                label: 'Working',
                                value: '2'

                            }
                            if (!isViewingFound)
                                this.setState({
                                    lead_stage_filter: [...this.state.lead_stage_filter, item]
                                })
                            if (!isViewingFoundAgent)
                                this.setState({
                                    lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                })
                        } else
                            if (lead_stage == 3) {


                                const isViewingFound = this.state.lead_stage_filter.some(element => {
                                    if (element.value === '3') {
                                        return true;
                                    }

                                    return false;
                                });

                                const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                    if (element.value === '3') {
                                        return true;
                                    }

                                    return false;
                                });
                                const item = {
                                    label: 'Hot',
                                    value: '3'

                                }
                                if (!isViewingFound)
                                    this.setState({
                                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                                    })
                                if (!isViewingFoundAgent)
                                    this.setState({
                                        lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                    })
                            } else
                                if (lead_stage == 4) {


                                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                                        if (element.value === '4') {
                                            return true;
                                        }

                                        return false;
                                    });

                                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                        if (element.value === '4') {
                                            return true;
                                        }

                                        return false;
                                    });
                                    const item = {
                                        label: 'Follow Up',
                                        value: '4'

                                    }
                                    if (!isViewingFound)
                                        this.setState({
                                            lead_stage_filter: [...this.state.lead_stage_filter, item]
                                        })
                                    if (!isViewingFoundAgent)
                                        this.setState({
                                            lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                        })
                                } else
                                    if (lead_stage == 5) {


                                        const isViewingFound = this.state.lead_stage_filter.some(element => {
                                            if (element.value === '5') {
                                                return true;
                                            }

                                            return false;
                                        });
                                        const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                            if (element.value === '5') {
                                                return true;
                                            }

                                            return false;
                                        });

                                        const item = {
                                            label: 'Dead',
                                            value: '5'

                                        }
                                        if (!isViewingFound)
                                            this.setState({
                                                lead_stage_filter: [...this.state.lead_stage_filter, item]
                                            })
                                        if (!isViewingFoundAgent)
                                            this.setState({
                                                lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                            })
                                    } else
                                        if (lead_stage == 6) {


                                            const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                if (element.value === '6') {
                                                    return true;
                                                }

                                                return false;
                                            });

                                            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                if (element.value === '6') {
                                                    return true;
                                                }

                                                return false;
                                            });


                                            const item = {
                                                label: 'Offer',
                                                value: '6'

                                            }
                                            if (!isViewingFound)
                                                this.setState({
                                                    lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                })
                                            if (!isViewingFoundAgent)
                                                this.setState({
                                                    lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                })
                                        } else
                                            if (lead_stage == 7) {


                                                const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                    if (element.value === '7') {
                                                        return true;
                                                    }

                                                    return false;
                                                });
                                                const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                    if (element.value === '7') {
                                                        return true;
                                                    }

                                                    return false;
                                                });

                                                const item = {
                                                    label: 'Deal signed',
                                                    value: '7'

                                                }
                                                if (!isViewingFound)
                                                    this.setState({
                                                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                    })
                                                if (!isViewingFoundAgent)
                                                    this.setState({
                                                        lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                    })
                                            } else


                                                if (lead_stage == 8) {


                                                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                        if (element.value === '8') {
                                                            return true;
                                                        }

                                                        return false;
                                                    });

                                                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                        if (element.value === '8') {
                                                            return true;
                                                        }

                                                        return false;
                                                    });

                                                    const item = {
                                                        label: 'Viewings',
                                                        value: '8'

                                                    }
                                                    if (!isViewingFound)
                                                        this.setState({
                                                            lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                        })
                                                    if (!isViewingFoundAgent)
                                                        this.setState({
                                                            lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                        })
                                                } else
                                                    if (lead_stage == 9) {


                                                        const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                            if (element.value === '9') {
                                                                return true;
                                                            }

                                                            return false;
                                                        });

                                                        const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                            if (element.value === '9') {
                                                                return true;
                                                            }

                                                            return false;
                                                        });
                                                        const item = {
                                                            label: 'Recruitment',
                                                            value: '9'

                                                        }
                                                        if (!isViewingFound)
                                                            this.setState({
                                                                lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                            })
                                                        if (!isViewingFoundAgent)
                                                            this.setState({
                                                                lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                            })
                                                    } else
                                                        if (lead_stage == 10) {


                                                            const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                                if (element.value === '10') {
                                                                    return true;
                                                                }

                                                                return false;
                                                            });
                                                            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                                if (element.value === '10') {
                                                                    return true;
                                                                }

                                                                return false;
                                                            });

                                                            const item = {
                                                                label: 'No Answer',
                                                                value: '10'

                                                            }
                                                            if (!isViewingFound)
                                                                this.setState({
                                                                    lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                                })
                                                            if (!isViewingFoundAgent)
                                                                this.setState({
                                                                    lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                                })
                                                        } else
                                                            if (lead_stage == 11) {


                                                                const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                                    if (element.value === '11') {
                                                                        return true;
                                                                    }

                                                                    return false;
                                                                });

                                                                const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                                    if (element.value === '11') {
                                                                        return true;
                                                                    }

                                                                    return false;
                                                                });

                                                                const item = {
                                                                    label: 'Expired',
                                                                    value: '11'

                                                                }
                                                                if (!isViewingFound)
                                                                    this.setState({
                                                                        lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                                    })
                                                                if (!isViewingFoundAgent)
                                                                    this.setState({
                                                                        lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                                    })
                                                            } else
                                                                if (lead_stage == 12) {


                                                                    const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                                        if (element.value === '12') {
                                                                            return true;
                                                                        }

                                                                        return false;
                                                                    });

                                                                    const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                                        if (element.value === '12') {
                                                                            return true;
                                                                        }

                                                                        return false;
                                                                    });


                                                                    const item = {
                                                                        label: 'Pond',
                                                                        value: '12'

                                                                    }
                                                                    if (!isViewingFound)
                                                                        this.setState({
                                                                            lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                                        })
                                                                    if (!isViewingFoundAgent)
                                                                        this.setState({
                                                                            lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                                        })
                                                                } else
                                                                    if (lead_stage == 13) {


                                                                        const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                                            if (element.value === '13') {
                                                                                return true;
                                                                            }

                                                                            return false;
                                                                        });
                                                                        const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                                            if (element.value === '13') {
                                                                                return true;
                                                                            }

                                                                            return false;
                                                                        });


                                                                        const item = {
                                                                            label: 'Offer accepted',
                                                                            value: '13'

                                                                        }
                                                                        if (!isViewingFound)
                                                                            this.setState({
                                                                                lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                                            })
                                                                        if (!isViewingFoundAgent)
                                                                            this.setState({
                                                                                lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                                            })
                                                                    } else
                                                                        if (lead_stage == 14) {


                                                                            const isViewingFound = this.state.lead_stage_filter.some(element => {
                                                                                if (element.value === '14') {
                                                                                    return true;
                                                                                }

                                                                                return false;
                                                                            });

                                                                            const isViewingFoundAgent = this.state.lead_stage_filter_agent.some(element => {
                                                                                if (element.value === '14') {
                                                                                    return true;
                                                                                }

                                                                                return false;
                                                                            });


                                                                            const item = {
                                                                                label: 'Offer declined',
                                                                                value: '14'

                                                                            }
                                                                            if (!isViewingFound)
                                                                                this.setState({
                                                                                    lead_stage_filter: [...this.state.lead_stage_filter, item]
                                                                                })
                                                                            if (!isViewingFoundAgent)
                                                                                this.setState({
                                                                                    lead_stage_filter_agent: [...this.state.lead_stage_filter_agent, item]
                                                                                })
                                                                        }

                    // API.get("/sub_community/" + response.data.lead_details.community,)
                    //     .then((response) => {
                    //         if (response.data.success)
                    //             this.setState({ sub_communities: response.data.sub_communities })
                    //     })

                    if (this.state.requested_viewings != []) {
                        setInterval(() => {
                            this.getRemainingTime(this.state.requested_viewings && this.state.requested_viewings[0] && this.state.requested_viewings[0].added_date)
                        }, 1000)
                    }
                }
                else {
                    this.setState({
                        loader: false
                    })
                }
                this.fetchFollowUpDetails(this.state.record_id)
                // By Farooq
                if (!xPrev_Next) {
                    this.setState({
                        open: true,
                        disabledNext: false,
                        disabledPrev: false
                    })
                }

                ///// chnages By Farooq ////
                // if(!this.props.match.params.id){
                //   this.setState({currUrl: window.location.href })
                //   window.history.replaceState(null, "Leads Id", window.location.href+'/'+id)
                // }

                this.setState({ currUrl: window.location.href })
                window.history.replaceState(null, "Leads Id", window.location.href + '/' + id)
                /////////////////////////


                API.get("/all_agents")
                    .then((response) => {
                        if (response.data.success) {
                            this.setState({ agents: response.data.agents })
                        }
                    }
                    )

                this.fetchFollowUp(id);
                const community = response.data.lead_list_details.community
                if (community == '' || community == null) {
                    console.log("community empty ", true)
                }
                else {
                    API.get("/sub_community/" + community)
                        .then((response) => {
                            if (response.data.success)
                                this.setState({ requirement_sub_communities: response.data.sub_communities })
                        })

                }

            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loader: false
                })
            })



    }

    handleNextRecord = (e) => {
        let newUrl;

        const arr = this.state.leads.data.slice(-1);
        if (this.state.currId == arr[0].original_id) {
            // this.fTablePageUpdate(this.state.leads.current_page+1, 'next');
            this.setState({ disabledNext: true });

        }
        else {
            this.handleOpenDrawer(this.state.nextId, true);
            this.setState({ disabledPrev: false });

            // Farooq
            if (this.props.match.params.id) {
                newUrl = window.location.href.replace(/leads.*/, `leads`);
            }
            else {
                newUrl = window.location.href.replace(/leads.*/, `leads`);
            }
            // const newUrl = window.location.href.replace(/leads.*/, `leads/${this.state.nextId}`);
            this.setState({ currUrl: newUrl })
            window.history.replaceState(null, "Leads Id", newUrl);
            /////////////////////////
        }

        {/**

         const newArray = this.state.leads[Symbol.iterator]();
         const nextArray  = newArray.next();



         const id = this.state.record_id;
         this.setState({isNextLoading:true})

         API.get("/next_lead/" + id)
         .then((response) => {

            if (response.data.success) {
              this.setState({
                 isNextLoading:false,
                 record_id: response.data.lead_details.parent_lead,
                 lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
                 all:response.data.all,
                 notes: response.data.notes,requirement_community:response.data.lead_details.community,
                 call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                 email_notes: response.data.email_notes, property_offers: response.data.property_offers,
                 owner_id:response.data.lead_details.owner_id,
                 owner_name:response.data.lead_details.owner,
                 owner_email:response.data.lead_details.owner_email,
                 owner_mobile:response.data.lead_details.mobile,
                 requirement_bedroom:response.data.lead_details.bedroom,requirement_min_budget:response.data.lead_details.budget_from,
                 requirement_max_budget:response.data.lead_details.budget_to,
                 requirement_community:response.data.lead_details.community,requirement_sub_community:response.data.lead_details.subcommunity,
                 requirement_agent_id:response.data.lead_details.lead_agent,lead_community:response.data.lead_details.community,lead_sub_community:response.data.lead_details.subcommunity,
                 collaborators:response.data.collaborators
              })

              // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
            } else {
              this.setState({isNextLoading:false})
              toast.warning("last record")
            }
          })

         */}
    }

    handlePreviousRecord = (e) => {
        const arr = this.state.leads.data.slice(0, 1);
        if (this.state.currId == arr[0].original_id) {
            // if(this.state.leads.current_page !== 1){
            //   this.fTablePageUpdate( this.state.leads.current_page-1, 'prev');
            // }
            this.setState({ disabledPrev: true })
        }
        else {
            this.handleOpenDrawer(this.state.prevId, true)
            this.setState({ disabledNext: false })

            // Farooq
            const newUrl = window.location.href.replace(/leads.*/, `leads`);
            this.setState({ currUrl: newUrl })
            window.history.replaceState(null, "Leads Id", newUrl);
            /////////////////////////
        }


        {/***
         const id = this.state.record_id;
         this.setState({isPreviousLoading:true})
         API.get("/previous_lead/" + id)
         .then((response) => {

        if (response.data.success) {
          this.setState({
            isPreviousLoading:false,
             record_id: response.data.lead_details.parent_lead,
             lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
             all:response.data.all,
             notes: response.data.notes,requirement_community:response.data.lead_details.community,
             call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
             email_notes: response.data.email_notes, property_offers: response.data.property_offers,
             owner_id:response.data.lead_details.owner_id,
             owner_name:response.data.lead_details.owner,
             owner_email:response.data.lead_details.owner_email,
             owner_mobile:response.data.lead_details.mobile,
             requirement_bedroom:response.data.lead_details.bedroom,requirement_min_budget:response.data.lead_details.budget_from,
             requirement_max_budget:response.data.lead_details.budget_to,
             requirement_community:response.data.lead_details.community,requirement_sub_community:response.data.lead_details.subcommunity,
             requirement_agent_id:response.data.lead_details.lead_agent,lead_community:response.data.lead_details.community,lead_sub_community:response.data.lead_details.subcommunity,
             collaborators:response.data.collaborators
          })

          // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
        } else {
          this.setState({isPreviousLoading:false})
          toast.warning("last record")
        }
      })
         */}
    }

    resetState = () => {
        this.setState({
            lead_details: {},
            owner_other_properties: [],
            all: [],
            role: "",
            agent_id: "",
            contact_type_requirement: "",
            notes: [],
            call_notes: [],
            whatsapp_notes: [],
            referred_by: "",
            referred_to: "",
            viewing_notes: [],
            email_notes: [],
            followup_notes: [],
            offer_notes: [],
            owner_id: "",
            comment: "",
            owner_name: "",
            owner_email: "",
            owner_mobile: "",
            requirement_bedroom: "",
            requirement_min_budget: 0,
            requirement_max_budget: 0,
            requirement_lead_stage: "",
            requirement_verifyedby: "",
            requirement_community: "",
            requirement_sub_community: "",
            requirement_agent_id: "",
            lead_community: "",
            lead_sub_community: "",
            collaborators: [],
            property_viewings: [],
            property_offers: [],
        })
    }

    saveNavigation = (id) => {
        const data = this.state.leads.data;
        this.props.setNavigation(data)
    }


    handleCloseDrawer = () => {
        this.setState({ open: false })


        this.resetState();

        const newUrl = window.location.href.replace(/leads.*/, 'leads');
        // window.history.replaceState(null, "Leads Id", this.state.currUrl)
        window.history.replaceState(null, "Leads Id", newUrl);
        this.setState({ currUrl: "" })

        //////////////////

        /// Empty all log values...
        this.setState({
            call_log: '',
            note_log: '',
            whatsapp_log: '',
            email_log: '',
            offer_log: '',
            viewing_log: '',
            followup_log: '',
        })
    }

    handleSaveData = () => {
        toast.success("Successfully addedd")
        this.setState({ open: false })
    }

    handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {


                toast.success("Successfully deleted")



            }
        })
    }

    handleCommunityMatch = (e) => {
        this.setState({ community_match: e })
        if (e !== null || e !== '') {
            API.get("/sub_community/" + e)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ sub_communities: response.data.sub_communities })
                })
        }
    }



    handleSubCommunityMatch = (e) => {
        this.setState({ sub_community_match: e })

    }

    handleRequirementCommunity = (e) => {

        this.setState({ requirement_community: e })
        if (e !== null || e !== '') {
            API.get("/sub_community/" + e)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ requirement_sub_communities: response.data.sub_communities })
                })
        }
    }


    handleRequirementSubCommunity = (e) => {
        this.setState({ requirement_sub_community: e })

    }

    handleRequirementBeds = (e) => {
        this.setState({ requirement_bedroom: e.target.value })
    }

    handleRequirementMin = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ requirement_min_budget: new_value })
    }

    handleRequirementMax = (e) => {

        const value = e.target.value

        const new_value = value.replace(/,/g, "")

        this.setState({ requirement_max_budget: new_value })
    }

    handleRequirementMoveInDate = (e) => {
        this.setState({ requirement_move_in_date: e.target.value })
    }

    handleRequirementStage = (e) => {
        this.setState({ requirement_lead_stage: e.target.value })
    }

    handleRequirementVerifyby = (e) => {
        this.setState({ requirement_verifyedby: e.target.value })
    }

    handleRequirementAgent = (e) => {
        this.setState({ requirement_agent_id: e })
    }

    handleRequirementSubmit = () => {
        const data = {
            lead_id: this.state.lead_details.id,
            requirement_min_budget: this.state.requirement_min_budget,
            requirement_max_budget: this.state.requirement_max_budget,
            requirement_lead_stage: this.state.requirement_lead_stage,
            requirement_community: this.state.requirement_community,
            requirement_sub_community: this.state.requirement_sub_community,
            requirement_verifyedby: this.state.requirement_verifyedby,
            requirement_agent_id: this.state.requirement_agent_id,
            requirement_move_in_date: this.state.requirement_move_in_date,
            requirement_bedroom: this.state.requirement_bedroom
        }

        API.post("/requirements_save_update", data)
            .then((response) => {
                let requirement_note = ""
                if (response.data.success) {

                    this.setState({
                        min_from_requirement: this.state.requirement_min_budget,
                        max_from_requirement: this.state.requirement_max_budget,
                        community_from_requirement: this.state.requirement_community,
                        sub_community_from_requirement: this.state.requirement_sub_community,
                        added_date_from_requirement: this.state.requirement_move_in_date,
                        bedroom_from_requirement: this.state.requirement_bedroom
                    })

                    const old_community = this.state.lead_details.community != null ? this.state.lead_details.community : 0
                    const new_community = this.state.requirement_community != null ? this.state.requirement_community : 0
                    const old_sub_community = this.state.lead_details.subcommunity
                    const new_sub_community = this.state.requirement_sub_community

                    const old_bedroom = this.state.lead_details.bedroom
                    const new_bedroom = this.state.requirement_bedroom
                    const old_lead_stage = this.state.lead_details.lead_stage
                    const new_lead_stage = this.state.requirement_lead_stage
                    if (old_community != new_community) {
                        const old_community_obj = this.state.communities.find(obj => {
                            return obj.value == old_community
                        })

                        const new_community_obj = this.state.communities.find(obj => {
                            return obj.value == new_community
                        })
                        requirement_note += "Community changed from " + old_community_obj.label + " to " + new_community_obj.label + "\n"

                    }

                    if (old_sub_community != new_sub_community) {

                        const old_sub_community_obj = this.state.all_sub_communities.find(obj => {
                            return obj.value == old_sub_community
                        })

                        const new_sub_community_obj = this.state.all_sub_communities.find(obj => {
                            return obj.value == new_sub_community
                        })
                        requirement_note += "\n Sub Community changed from " + old_sub_community_obj.label + " to " + new_sub_community_obj.label + "\n"
                    }

                    if (old_bedroom != new_bedroom) {

                        requirement_note += "Bedroom from " + old_bedroom + " to " + new_bedroom + "\n"

                    }

                    if (old_lead_stage != new_lead_stage) {

                        requirement_note += "Lead stage from " + old_lead_stage + " to " + new_lead_stage + "\n"

                    }











                    const data = {
                        leads_id: this.state.record_id,
                        note: requirement_note,
                        note_type: "note"
                    }

                    API.post("/add_lead_note", data)
                        .then((response) => {
                            if (response.data.success) {
                                this.setState({

                                    notes: response.data.notes,
                                    call_notes: response.data.call_notes,
                                    whatsapp_notes: response.data.whatsapp_notes,
                                    email_notes: response.data.email_notes
                                })

                            }
                        })
                    // }
                }
            })
    }

    handleMinBudget = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ min_budget: new_value })
    }

    handleMaxBudget = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ max_budget: new_value })
    }

    handleBeds = (e) => {
        this.setState({ bedroom_match: e.target.value })
    }

    handlePropertForMatch = (e) => {
        this.setState({ property_for_match: e.target.value })
    }

    onReply = (data) => {
        this.handleOpenDrawer(data.parent_lead_notes, false)
    }

    onReplyActivity = (data) => {
        this.handleOpenDrawer(data.parent_lead_notes, false)
    }

    onChangeActivityAgent = (e) => {
        this.setState({ activity_agent_id: e })
        this.setState({ isActivityLoading: true })
        const data = {
            filter: "",
            agent_id: e
        }
        API.post("/activity_feed", data)
            .then((response) => {
                this.setState({ isActivityLoading: false })
                if (response.data.success) {
                    this.setState({ activityFeeds: response.data.activity_feeds })
                }
            }).catch((e) => {
                this.setState({ isActivityLoading: false })
                // toast.error("Something Went Wrong")
            })
    }

    onActivityFilter = (filter) => {
        this.setState({ isActivityLoading: true })
        const data = {
            filter: filter,
            agent_id: this.state.activity_agent_id
        }
        API.post("/activity_feed", data)
            .then((response) => {
                this.setState({ isActivityLoading: false })
                if (response.data.success) {
                    this.setState({ activityFeeds: response.data.activity_feeds })
                }
            }).catch((e) => {
                this.setState({ isActivityLoading: false })
                // toast.error("Something Went Wrong")
            })
    }

    handlePropertTypeMatch = (e) => {
        this.setState({ property_type_match: e.target.value })
    }

    handleAddedDateMatch = (e) => {
        this.setState({ added_date_match: e.target.value })
    }

    handleMatch = () => {

        const data = {
            lead_id: this.state.record_id,
            community_match: this.state.community_match,
            sub_community_match: this.state.sub_community_match,
            min_budget: this.state.min_budget, max_budget: this.state.max_budget,
            beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
            property_type_match: this.state.property_type_match,
            added_date_match: this.state.added_date_match
        }



        API.post("/match_leads", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({
                        matchs: response.data.match_leads,
                        // not_matchs: response.data.not_match_leads
                    })
                }
            })
    }

    // note logs
    handleCallLog = (e) => {
        this.setState({ call_log: e.target.value })
    }

    handleCallSubmit = () => {

        if (!this.state.contact_type_requirement || !this.state.requirement_move_in_date || !this.state.price_requirement || !this.state.requirement_community) {
            toast.warning('Please enter all required');
            return
        }


        const userdata = store.getState((state) => state);
        const user_id = userdata.auth.user.id

        if (this.state.call_log == '') {
            toast.warning('Call log cannot be empty');
            return
        }
        else {
            this.setState({ call_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.call_log,
                note_type: "phone",
                users: this.state.selectedCallUsers
            }


            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        if (this.state.lead_details.lead_stage == 10) {
                            this.setState({ requirement_lead_stage: 2, requirement_agent_id: user_id })
                        }

                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            // call_log: "",
                            call_loader: false,
                            // selectedCallUsers: [],
                        })

                        Swal.fire({
                            title: 'Do you want to add follow up?',
                            icon: 'questions',
                            width: '400px',
                            className: 'followuconfirm',
                            showCancelButton: true,
                            confirmButtonColor: '#151515',
                            cancelButtonColor: '#FFF',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.setState({ isfollowupOpen: true })
                                this.setState({ isCallLogOpen: false })
                            } else {
                                this.setState({ isCallLogOpen: false })
                            }
                        })

                        let status = (this.state.contact_type_call_note_status != "") ? this.state.contact_type_call_note_status : 2

                        const data = { leads_id: this.state.record_id, lead_stage: status }
                        API.post("/update_lead_stage", data)
                            .then((response) => {
                                if (response.data.success) {
                                    this.setState({
                                        all: response.data.all,
                                        notes: response.data.notes
                                    })
                                    this.setState({ contact_type_call_note_status: "" })
                                    this.setState({ requirement_lead_stage: status })
                                }
                            }).catch((err) => console.log(err));

                        const email_info = { leads_id: this.state.record_id, users: this.state.selectedNoteUsers, note: this.state.call_log, }
                        API.post("/send_lead_note_email", email_info)
                            .then((res) => {
                                if (res.data.success) {
                                    this.setState({
                                        selectedCallUsers: [],
                                        call_log: "",

                                        selectedNoteUsers: []
                                    })
                                }
                            })
                    }
                    else {
                        this.setState({ call_loader: false })
                    }
                }).catch(() => {
                    this.setState({ callloader: false })
                })
        }
    }

    handleVerificationCallSubmit = () => {

        if (!this.state.contact_type_requirement || !this.state.requirement_move_in_date || !this.state.price_requirement || !this.state.requirement_community) {
            toast.warning('Please enter all required');
            return
        }


        const userdata = store.getState((state) => state);
        const user_id = userdata.auth.user.id

        if (this.state.call_log == '') {
            toast.warning('Call log cannot be empty');
            return
        }
        else {
            this.setState({ call_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.call_log,
                note_type: "verify",
                users: this.state.selectedCallUsers,
                number_of_cheque: this.state.number_of_cheque,
                new_to_dubai: this.state.new_to_dubai,
                current_live: this.state.current_live,
                availability_for_viewing: this.state.availability_for_viewing,
                pre_approved: this.state.pre_approved,
                payment: this.state.payment,
                buyer_type: this.state.buyer_type,
                price_requirement: this.state.price_requirement,
                requirement_community: this.state.requirement_community,
                requirement_sub_community: this.state.requirement_sub_community,
                new_bed: this.state.new_bed,
                new_community: this.state.new_community,
                new_sub_community: this.state.new_sub_community,
                price_first: this.state.price_first,
                price_second: this.state.price_second,
                new_move_in_date: this.state.new_move_in_date,
                contact_type_requirement: this.state.contact_type_requirement
            }



            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        if (this.state.lead_details.lead_stage == 10) {
                            this.setState({ requirement_lead_stage: 2, requirement_agent_id: user_id })
                        }

                        this.setState({
                            isVerificationOpen: false,
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            // call_log: "",
                            call_loader: false,
                            // selectedCallUsers: [],
                        })
                        const email_info = { leads_id: this.state.record_id, users: this.state.selectedCallUsers, note: this.state.call_log }
                        API.post("/send_lead_note_email", email_info)
                            .then((res) => {
                                if (res.data.success) {
                                    this.setState({
                                        selectedCallUsers: [],
                                        call_log: "",

                                        selectedNoteUsers: []
                                    })
                                }
                            })


                        //    let status = (this.state.contact_type_call_note_status != "") ? this.state.contact_type_call_note_status : 2

                        //    const data = { leads_id: this.state.record_id, lead_stage: status }
                        //    API.post("/update_lead_stage", data)
                        //        .then((response) => {
                        //            if (response.data.success) {
                        //                this.setState({
                        //                    all: response.data.all,
                        //                    notes: response.data.notes
                        //                })
                        //                this.setState({contact_type_call_note_status: ""})
                        //                this.setState({requirement_lead_stage: status})
                        //            }
                        //        }).catch((err) => console.log(err));

                        //    const email_info = {leads_id:this.state.record_id,  users: this.state.selectedNoteUsers,note: this.state.call_log}
                        //    API.post("/send_lead_note_email",email_info)
                        //    .then((res) =>{
                        //        if(res.data.success){
                        //            this.setState({
                        //                selectedCallUsers: [],
                        //                call_log: "",

                        //                 selectedNoteUsers:[]
                        //            })
                        //        }
                        //    })
                    }
                    else {
                        this.setState({ call_loader: false })
                    }
                }).catch(() => {
                    this.setState({ callloader: false })
                })
        }
    }

    handleNoteLog = (e) => {
        this.setState({ note_log: e.target.value })
    }

    handleNoteSubmit = () => {
        if (this.state.note_log == '') {
            toast.warning('Note log cannot be empty');
        }
        else {
            this.setState({ note_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.note_log,
                note_type: "note",
                users: this.state.selectedNoteUsers
            }

            const email_info = { leads_id: this.state.record_id, users: this.state.selectedNoteUsers, note: this.state.note_log, }

            API.post("/add_lead_note", data)
                .then((response) => {

                    if (response.data.success) {
                        console.log("*****Is Successs ", response.data.success)
                        this.setState({
                            // selectedCallUsers: [],
                            // note_log: "",
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            note_loader: false,
                            note_log: ""
                            // selectedNoteUsers:[]
                        })

                        API.post("/send_lead_note_email", email_info)
                            .then((res) => {
                                if (res.data.success) {
                                    this.setState({
                                        selectedCallUsers: [],
                                        note_log: "",

                                        selectedNoteUsers: []
                                    })
                                }
                            })

                    }
                    else {
                        this.setState({ note_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ note_loader: false })
                })
        }
    }

    handleWhatsappLog = (e) => {
        this.setState({ whatsapp_log: e.target.value })
    }

    handleWhatsappSubmit = () => {
        if (this.state.whatsapp_log == '') {
            toast.warning('whatsApp log cannot be empty')
        }
        else {
            this.setState({ whatsapp_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.whatsapp_log,
                note_type: "whatsapp",
                users: this.state.selectedWhatsappUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            whatsapp_log: "",
                            selectedCallUsers: [],
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            whatsapp_loader: false,
                            selectedWhatsappUsers: []
                        })

                    }
                    else {
                        this.setState({ whatsapp_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ whatsapp_loader: false })
                })
        }
    }

    handleEmailLog = (e) => {
        this.setState({ email_log: e.target.value })
    }

    handleEmailSubmit = () => {
        if (this.state.email_log == '') {
            toast.warning('Email log cannot be empty')
        }
        else {
            this.setState({ email_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.email_log,
                note_type: "email",
                users: this.state.selectedEmailUsers
            }



            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            email_log: "",
                            emailSubject: "",
                            selectedCallUsers: [],
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            email_loader: false,
                            selectedEmailUsers: []
                        })
                    }
                    else {
                        this.setState({ email_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ email_loader: false })
                })
        }
    }

    handleViewingSubmit = () => {
        if (this.state.viewing_log == '') {
            toast.warning('Viewing log cannot be empty')
        }
        else {
            this.setState({ viewing_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.viewing_log,
                note_type: "viewing",
                users: this.state.selectedViewingUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            viewing_log: "",
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            viewing_loader: false,
                            selectedViewingUsers: []
                        })

                    }
                    else {
                        this.setState({ viewing_loader: false })
                    }
                })
                .then((err) => {
                    this.setState({ viewing_loader: false })
                })
        }
    }

    handleOfferSubmit = () => {
        if (this.state.offer_log == '') {
            toast.warning('toast log icannot be empty')
        }
        else {
            this.setState({ offer_loader: true })
            this.setState({})
            const data = {
                leads_id: this.state.record_id,
                note: this.state.offer_log,
                note_type: "offer",
                users: this.state.selectedOfferUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            offer_loader: false,
                            offer_log: '',
                            selectedOfferUsers: []
                        })

                    }
                    else {
                        this.setState({ offer_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ offer_loader: false })
                })
        }
    }

    handleFollowupSubmit = () => {
        if (this.state.followup_log == '') {
            toast.warning('Followup cannot be empty')
        }
        else {
            this.setState({ followUp_loader: true })
            const data = {
                leads_id: this.state.record_id,
                note: this.state.followup_log,
                note_type: "followup",
                users: this.state.selectedFollowupUsers
            }

            API.post("/add_lead_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            followup_log: '',
                            all: response.data.all,
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            followup_notes: response.data.followup_notes,
                            offer_notes: response.data.offer_notes,
                            viewing_notes: response.data.viewing_notes,
                            followUp_loader: false,
                            selectedFollowupUsers: []
                        })

                    }
                    else {
                        this.setState({ followUp_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ followUp_loader: false })
                })
        }
    }

    handleNewViewing = (data = null) => {

        this.setState({ isviewingOpen: true })
        if (this.state.sendEvent == false) {
            setTimeout(this.signIn(), 5000)
        }
    }

    handleRequestNewViewing = (data = null) => {
        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let role_name = userdata.auth.user.role_name;
        const user_id = userdata.auth.user.id

        this.setState({ isRequestviewingOpen: true })
        this.setState({ currentRequest: data })
        this.setState({ ref_def_nums: data.refno })
        this.setState({ viewing_ref_no: [data.refno] })
        this.setState({ viewing_agent: user_id })

        if (this.state.sendEvent == false) {
            setTimeout(this.signIn(), 5000)
        }
    }



    onSearchRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ ref_nums: [] })
            return
        }
        API.get("/get_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ ref_nums: response.data.ref_nums })
                }
            })
    }

    handleRefsno = (e) => {
        this.setState({ viewing_ref_no: e, ref_def_nums: e })
        const data = {
            ref_nums: e
        }


        API.post("/get_all_listing_by_refnos", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ viewings_listings: response.data.listings })
                }
            })

    }

    handleUnitno = (e) => {



        if (!e || e == '' || e == null) {
            API.get("/get_old_unit/" + this.state.old_unit_num)
                .then((response) => {

                    const refno = response.data.listing.value

                    this.setState({
                        ref_def_nums: this.state.ref_def_nums.filter(function (value) {
                            return value.refno !== refno
                        })
                    });

                    // this.setState({viewings_listings: this.state.viewings_listings.filter(function(viewing_listing) {
                    //   return viewing_listing.refno !== refno
                    //  })});



                })
            // this.setState({ref_def_nums:[]})
            return
        }
        this.setState({ viewing_unit_no: e })
        const data = {
            unit_num: e
        }
        API.post("/get_all_listing_by_unitno", data)
            .then((response) => {
                if (response.data.success) {
                    const listing = response.data.all_listings
                    const unit = listing.unitno
                    let data = response.data.all_listings
                    const isFound = this.state.viewings_listings.some(element => {
                        if (element.unitno === e) {
                            return true;
                        }

                        return false;
                    });
                    if (!isFound) {
                        this.setState({ viewings_listings: [...this.state.viewings_listings, response.data.listing], ref_def_nums: [...this.state.ref_def_nums, data.value], old_unit_num: e, viewing_ref_no: [...this.state.viewing_ref_no, data.value] })
                    } else {
                        return
                    }
                }
            })
    }

    handleSearchUintNo = (e) => {


        if (!e || e == '' || e == null) {
            // this.setState({ref_nums:[]})
            return
        }

        API.get("/get_listing_by_unitno/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ unit_nums: response.data.unit_nums })
                }
            })
    }

    handleViewingNote = (e) => {
        this.setState({ viewing_note: e.target.value })
    }

    handleViewingDate = (date, dateString) => {
        console.log("***** Date to String ***** ", dateString)
        this.setState({ viewing_date: dateString })
    };

    getFollowUpType = (type) => {
        if (type == 1) {
            return "Follow Up";
        } else if (type == 2) {
            return "Call"
        } else if (type == 3) {
            return "Email"
        } else if (type == 4) {
            return "Viewing"
        } else if (type == 5) {
            return "Offer"
        } else {
            return "Whatsapp"
        }
    };

    handleViewingAgent = (e) => {
        this.setState({ viewing_agent: e })
    }

    generateEventDescription = (viewing_listings) => {
        let htmlDesc = "<div>"
        viewing_listings.map((viewings_listing) => {
            htmlDesc += '<p>Ref No:' + viewings_listing.refno + '</p>'
            htmlDesc += '<p>Unit No:' + viewings_listing.unitno + '</p>'
            htmlDesc += '<p>Community:' + viewings_listing.community + '</p>'
            htmlDesc += '<p>Sub Community:' + viewings_listing.subcommunity + '</p>'
            htmlDesc += '<p>Property:' + viewings_listing.property + '</p><br>'

        });
        htmlDesc += "</div>"
        return htmlDesc;
    }

    generateFollowUpsEventDescription = (viewing_listings) => {
        let htmlDesc = "<div>"
        htmlDesc += '<p>Notes:' + viewing_listings.followup_note + '</p>'
        htmlDesc += '<p>Type:' + (viewing_listings.type == 1) ? "Vendor" : "Client" + '</p><br>'
        htmlDesc += '<p>Type:' + "Client" + '</p><br>'
        htmlDesc += "</div>"
        return htmlDesc;
    }

    agentEmails = (viewing_listings) => {
        let agentEmailsData = []
        viewing_listings.map((viewings_listing) => {
            agentEmailsData.push({ email: viewings_listing.agent_email })

        });
        return agentEmailsData;
    }

    createEvent = (viewing_state_data, startingDate, type) => {
        const [date, time] = startingDate.split(' ');
        const [year, month, day] = date.split('-');
        const [hours, minutes] = time.split(':');
        const isoString = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
        const dateObject = new Date(isoString);
        const startDateInIsoFormat = dateObject.toISOString();

        const starting_date = new Date(startDateInIsoFormat);
        const newStartingDate = new Date(starting_date.getTime() - (4 * 60 * 60 * 1000));
        const startingDateInUtc = newStartingDate.toISOString();
        const endDate = new Date(startingDateInUtc);
        endDate.setHours(endDate.getHours() + 1);
        const endDateInIsoFormat = endDate.toISOString();
        let eventData = {};
        if (type == "viewing") {
            eventData = {
                summary: "Leads Viewing Event",
                description: this.generateEventDescription(viewing_state_data),
                start: {
                    dateTime: startingDateInUtc,
                    timeZone: "Asia/Dubai",
                },
                end: {
                    dateTime: endDateInIsoFormat,
                    timeZone: "Asia/Dubai",
                },
                attendees: this.agentEmails(viewing_state_data),
                sendUpdates: "all"
            };
        } else {
            eventData = {
                summary: "Leads Followups Event",
                description: this.generateFollowUpsEventDescription(viewing_state_data),
                start: {
                    dateTime: startingDateInUtc,
                    timeZone: "Asia/Dubai",
                },
                end: {
                    dateTime: endDateInIsoFormat,
                    timeZone: "Asia/Dubai",
                },
                // attendees: this.agentEmails(viewing_state_data),
                // sendUpdates: "all"
            };
        }

        try {
            apiCalendar
                .createEvent(eventData)
                .then((response) => {
                    this.setState({ sendEvent: true })
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    signIn = () => {
        apiCalendar.handleAuthClick();
    }

    handleRequestViewingOk = () => {
        if (this.state.viewing_ref_no == '' ||
            this.state.viewing_agent == '' ||
            this.state.viewing_date == ''
        ) {
            this.setState({ checkErr: true });
            // toast.error( "fields cannot be empty");
        }
        else {
            this.setState({ isViewing: true });
            this.setState({ checkErr: false });
            const data = {
                lead_id: this.state.record_id,
                lead_ref_no: this.state.lead_details.refno,
                viewing_ref_no: this.state.viewing_ref_no,
                viewing_agent: this.state.viewing_agent,
                viewing_date: this.state.viewing_date,
                viewing_note: this.state.viewing_note,
                "current_request": this.state.currentRequest
            }
            API.post("/add_request_viewing", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({ isRequestviewingOpen: false, property_viewings: response.data.property_viewings, viewing_notes: response.data.viewing_notes, isViewing: false })
                        this.clearViewingForm();
                        this.setState({ checkErr: false });
                        API.get("/lead_details/" + this.state.record_id)
                            .then((response) => {
                                if (response.data.success) {
                                    this.setState({ all: response.data.all })
                                    this.setState({ requested_viewings: response.data.requested_viewings })
                                    this.setState({ currentRequest: null })
                                    this.setState({
                                        lead_details: response.data.lead_details, lead_list_details: response.data.lead_list_details, owner_other_properties: response.data.owner_other_properties,
                                        all: response.data.all,
                                        requested_viewings: response.data.requested_viewings,
                                        role: response.data.role.role,
                                        agent_id: response.data.role.id,
                                        contact_type_requirement: response.data.lead_list_details.contact_type,
                                        notes: response.data.notes, requirement_community: response.data.lead_list_details.community,
                                        call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                                        viewing_notes: response.data.viewing_notes,
                                        email_notes: response.data.email_notes,
                                        followup_notes: response.data.followup_notes,
                                        offer_notes: response.data.offer_notes,
                                        owner_id: response.data.lead_list_details.owner_id,
                                        comment: response.data.lead_details.comment,
                                        owner_name: response.data.lead_list_details.owner,
                                        owner_email: response.data.lead_list_details.owner_email,
                                        owner_mobile: response.data.lead_list_details.mobile,
                                        requirement_bedroom: response.data.lead_list_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                                        requirement_max_budget: response.data.lead_details.budget_to,
                                        requirement_lead_stage: response.data.lead_list_details.lead_stage, requirement_verifyedby: response.data.lead_details.verified_by,
                                        requirement_community: response.data.lead_list_details.community, requirement_sub_community: response.data.lead_list_details.subcommunity,
                                        requirement_agent_id: response.data.lead_list_details.lead_agent, lead_community: response.data.lead_list_details.community, lead_sub_community: response.data.lead_list_details.subcommunity,
                                        collaborators: response.data.collaborators,
                                        property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                                    })
                                }
                                else {
                                    this.setState({ isRequestviewingOpen: false, property_viewings: response.data.property_viewings, viewing_notes: response.data.viewing_notes, isViewing: false })
                                    this.clearViewingForm();
                                    this.setState({ checkErr: false });
                                }
                            }).catch((err) => {
                                console.log(err)
                                this.setState({ currentRequest: null })
                                toast.error('Something went wrong');
                            }).finally(() => {
                                this.setState({ currentRequest: null })
                            })
                        const email_data = {
                            lead_id: this.state.record_id,
                            lead_ref_no: this.state.lead_details.refno,
                            viewing_ref_no: this.state.viewing_ref_no,
                            viewing_agent: this.state.viewing_agent,
                            viewing_date: this.state.viewing_date,
                            viewing_note: this.state.viewing_note,
                            views_id: response.data.propertyviewing_ids
                        }
                        API.post("/add_viewing_emails", email_data)
                            .then((res) => {

                                if (res.data.success) {
                                    console.log("success")
                                }
                            })
                        this.createEvent(this.state.viewings_listings, this.state.viewing_date, "viewing");
                    }
                    else {
                        this.setState({ isViewing: false });
                        this.setState({ checkErr: false });
                    }

                })
                .catch((err) => {
                    this.setState({ isViewing: false });
                    this.setState({ checkErr: false });
                })
        }

    }

    handleViewingOk = () => {
        if (this.state.viewing_ref_no == '' ||
            this.state.viewing_agent == '' ||
            this.state.viewing_date == ''
            // ||
            // this.state.viewing_note == ''
        ) {
            this.setState({ checkErr: true });
            // toast.error( "fields cannot be empty");
        }
        else {
            this.setState({ isViewing: true });
            this.setState({ checkErr: false });
            const data = {
                lead_id: this.state.record_id,
                lead_ref_no: this.state.lead_details.refno,
                viewing_ref_no: this.state.viewing_ref_no,
                viewing_agent: this.state.viewing_agent,
                viewing_date: this.state.viewing_date,
                viewing_note: this.state.viewing_note
            }
            API.post("/add_viewing", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({ isviewingOpen: false, property_viewings: response.data.property_viewings, viewing_notes: response.data.viewing_notes, isViewing: false })
                        this.clearViewingForm();
                        this.setState({ checkErr: false });
                        API.get("/lead_details/" + this.state.record_id)
                            .then((response) => {
                                if (response.data.success)
                                    this.setState({ requested_viewings: response.data.requested_viewings })
                                this.setState({ currentRequest: null })
                                this.setState({
                                    lead_details: response.data.lead_details, lead_list_details: response.data.lead_list_details, owner_other_properties: response.data.owner_other_properties,
                                    all: response.data.all,
                                    requested_viewings: response.data.requested_viewings,
                                    role: response.data.role.role,
                                    agent_id: response.data.role.id,
                                    contact_type_requirement: response.data.lead_list_details.contact_type,
                                    notes: response.data.notes, requirement_community: response.data.lead_list_details.community,
                                    call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                                    viewing_notes: response.data.viewing_notes,
                                    email_notes: response.data.email_notes,
                                    followup_notes: response.data.followup_notes,
                                    offer_notes: response.data.offer_notes,
                                    owner_id: response.data.lead_list_details.owner_id,
                                    comment: response.data.lead_details.comment,
                                    owner_name: response.data.lead_list_details.owner,
                                    owner_email: response.data.lead_list_details.owner_email,
                                    owner_mobile: response.data.lead_list_details.mobile,
                                    requirement_bedroom: response.data.lead_list_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                                    requirement_max_budget: response.data.lead_details.budget_to,
                                    requirement_lead_stage: response.data.lead_list_details.lead_stage, requirement_verifyedby: response.data.lead_details.verified_by,
                                    requirement_community: response.data.lead_list_details.community, requirement_sub_community: response.data.lead_list_details.subcommunity,
                                    requirement_agent_id: response.data.lead_list_details.lead_agent, lead_community: response.data.lead_list_details.community, lead_sub_community: response.data.lead_list_details.subcommunity,
                                    collaborators: response.data.collaborators,
                                    property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                                })
                            })
                        const email_data = {
                            lead_id: this.state.record_id,
                            lead_ref_no: this.state.lead_details.refno,
                            viewing_ref_no: this.state.viewing_ref_no,
                            viewing_agent: this.state.viewing_agent,
                            viewing_date: this.state.viewing_date,
                            viewing_note: this.state.viewing_note,
                            views_id: response.data.propertyviewing_ids
                        }
                        API.post("/add_viewing_emails", email_data)
                            .then((res) => {

                                if (res.data.success) {
                                    console.log("success")
                                }
                            })
                        this.createEvent(this.state.viewings_listings, this.state.viewing_date, "viewing");
                    }
                    else {
                        this.setState({ isViewing: false });
                        this.setState({ checkErr: false });
                    }

                })
                .catch((err) => {
                    this.setState({ isViewing: false });
                    this.setState({ checkErr: false });
                })
        }

    }

    clearViewingForm = () => {
        this.setState({
            viewing_ref_no: '',
            viewing_unit_no: '',
            viewing_agent: '',
            // viewing_date:'',
            ref_def_nums: [],
            viewings_listings: []
        })
    }

    handleViewingCancel = () => {
        this.setState({ isviewingOpen: false })
        this.setState({ checkErr: false });
        this.setState({ currentRequest: null });
    }

    onClickDecline = () => {
        const data = {
            "decline_note": this.state.decline_note,
            "current_request": this.state.currentRequest
        }
        API.post("/decline_note", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ all: response.data.all })
                    this.setState({ requested_viewings: response.data.requested_viewings })
                    this.setState({ isdecline: false })
                    this.setState({ decline_note: "" })
                    this.setState({ currentRequest: null })
                }
            }).catch((err) => {
                console.log(err)
                this.setState({ isdecline: false })
                this.setState({ decline_note: "" })
                this.setState({ currentRequest: null })
                // toast.error('Something went wrong');
            }).finally(() => {
                this.setState({ isdecline: false })
                this.setState({ decline_note: "" })
                this.setState({ currentRequest: null })
            })
    }
    changeDeclineNote = (e) => {
        this.setState({ decline_note: e.target.value })
    }

    handleisShareWhatsApp = (data) => {
        this.setState({ isWhatsappLoading: true })
        this.setState({ currentRequest: data })
        const postData = {
            "current_request": data
        }
        API.post("/share_request_viewing", postData)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ all: response.data.all })
                    this.setState({ requested_viewings: response.data.requested_viewings })
                    this.setState({ isWhatsappLoading: false })
                    this.setState({ currentRequest: null })
                }
            }).catch((err) => {
                console.log(err)
                this.setState({ isWhatsappLoading: false })
                this.setState({ currentRequest: null })
                // toast.error('Something went wrong');
            }).finally(() => {
                this.setState({ isWhatsappLoading: false })
                this.setState({ currentRequest: null })
            })
        // this.setState({isSharetoWhatsApp:true})
    }
    handleisShareWhatsAppClose = () => {
        this.setState({ isSharetoWhatsApp: false })
    }

    handleisDecline = (data) => {
        this.setState({ isdecline: true })
        this.setState({ currentRequest: data })
    }
    handleisDeclineClose = () => {
        this.setState({ isdecline: false })
        this.setState({ currentRequest: null })
    }

    handleEditViewing(view_id, e) {
        this.setState({ view_id: view_id })
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Scheduled" ? true : false,
                        viewing_price: response.data.viewings.amount, iseditviewingOpen: true
                    })
                }

            })
    }

    handleOfferView = (view_id, e) => {
        this.setState({ view_id: view_id })
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {

                    {
                        /**
                         viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                         viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                         showPrice: response.data.viewings.status == "Offer" ? true : false,
                         showDate: response.data.viewings.status == "Scheduled" ? true : false,
                         viewing_price: response.data.viewings.amount, iseditviewingOpen: true
                         */
                    }

                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Scheduled" ? true : false,
                        viewing_price: response.data.viewings.amount, isOfferOpen: true
                    })
                }

            })
    }

    handleViewStatus = (e) => {
        const status = e
        this.setState({ viewing_status: e })
        if (status == "Offer") {
            this.setState({ showPrice: true })
        } else {
            this.setState({ showPrice: false })
        }

        if (status == "Scheduled") {
            this.setState({ showDate: true })
        } else {
            this.setState({ showDate: false })
        }
    }

    handleViewStatusChange = () => {
        const data = { lead_id: this.state.record_id, view_id: this.state.view_id, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price, note: this.state.viewing_note }
        API.post("/change_viewing_status", data)
            .then((response) => {

                this.setState({
                    property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                    all: response.data.all, notes: response.data.notes,
                    call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                    //viewing_notes: response.data.viewing_notes,
                    email_notes: response.data.email_notes,
                    followup_notes: response.data.followup_notes,
                    offer_notes: response.data.offer_notes,
                    viewing_notes: response.data.viewing_notes,
                })
            })
        this.setState({ iseditviewingOpen: false })
    }
    handleViewStatusCancel = () => {
        this.setState({ iseditviewingOpen: false })
    }

    handleViewingPrice = (e) => {
        this.setState({ viewing_price: e.target.value })
    }


    fetchFollowUp(leads_id) {
        API.get("/followups/" + leads_id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ followups: response.data.followups })
                }

            })
    }

    fetchFollowUpDetails(leads_id) {
        API.post("/followup_details/" + leads_id, { type: "client" })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ followups_details: response.data.followups })
                }

            })
    }

    handleNewFollowUp = () => {
        this.setState({
            followup_date: "",
            followup_note: "",
            selected_agent: "",
            followup_for: "",
            reminder: "",
            isfollowupOpen: true
        })
        if (this.state.sendEvent == false) {
            setTimeout(this.signIn(), 5000)
        }
        if (this.state.role != 1 && this.state.role != 2) {
            this.setState({
                selected_agent: this.state.agent_id
            })
        }
    }
    handleFollowupDate = (data, dateString) => {
        this.setState({ followup_date: dateString })
    }

    handleFollowupNote = (e) => {
        this.setState({ followup_note: e.target.value })
    }

    handleFollowupOk = () => {
        if (this.validate()) {
            const lead_id = this.state.record_id
            const data = {
                leads_id: this.state.record_id,
                listing_id: 0,
                contacts_id: this.state.owner_id,
                followup_date: this.state.followup_date,
                followup_note: this.state.followup_note,
                type: this.state.type,
                selected_agent: this.state.selected_agent,
                followup_for: this.state.followup_for,
                reminder_time: this.state.reminder
            }

            API.post("/add_followup_details", data)
                .then((response) => {
                    if (response.data.success) {
                        this.fetchFollowUpDetails(this.state.record_id)
                        this.createEvent(data, this.state.followup_date, "followups");
                        this.setState({ isfollowupOpen: false })
                        API.get("/lead_details/" + this.state.record_id)
                            .then((response) => {
                                if (response.data.success)
                                    this.setState({ requested_viewings: response.data.requested_viewings })
                                this.setState({ currentRequest: null })
                                this.setState({
                                    lead_details: response.data.lead_details, lead_list_details: response.data.lead_list_details, owner_other_properties: response.data.owner_other_properties,
                                    all: response.data.all,
                                    role: response.data.role.role,
                                    requested_viewings: response.data.requested_viewings,
                                    agent_id: response.data.role.id,
                                    comment: response.data.lead_details.comment,
                                    contact_type_requirement: response.data.lead_list_details.contact_type,
                                    notes: response.data.notes, requirement_community: response.data.lead_list_details.community,
                                    call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                                    viewing_notes: response.data.viewing_notes,
                                    email_notes: response.data.email_notes,
                                    followup_notes: response.data.followup_notes,
                                    offer_notes: response.data.offer_notes,
                                    owner_id: response.data.lead_list_details.owner_id,
                                    owner_name: response.data.lead_list_details.owner,
                                    owner_email: response.data.lead_list_details.owner_email,
                                    owner_mobile: response.data.lead_list_details.mobile,
                                    requirement_bedroom: response.data.lead_list_details.bedroom, requirement_min_budget: response.data.lead_details.budget_from,
                                    requirement_max_budget: response.data.lead_details.budget_to,
                                    requirement_lead_stage: response.data.lead_list_details.lead_stage, requirement_verifyedby: response.data.lead_details.verified_by,
                                    requirement_community: response.data.lead_list_details.community, requirement_sub_community: response.data.lead_list_details.subcommunity,
                                    requirement_agent_id: response.data.lead_list_details.lead_agent, lead_community: response.data.lead_list_details.community, lead_sub_community: response.data.lead_list_details.subcommunity,
                                    collaborators: response.data.collaborators,
                                    property_viewings: response.data.property_viewings, property_offers: response.data.property_offers,
                                })
                            })
                    }


                })
        }
    }
    handleFollowupCancel = () => {
        this.setState({ isfollowupOpen: false })
    }
    mergeLeadValidate() {
        let parent_lead = this.state.parent_lead;
        let validationerrors = {};
        let isValid = true;

        if (parent_lead == null || parent_lead == "" || parent_lead == undefined) {
            isValid = false;
            validationerrors["merge_lead_error"] = "Parent lead is required.";
        }

        this.setState({
            validationerrorsMergeLead: validationerrors
        });

        return isValid;
    }

    validate() {
        let followup_for = this.state.followup_for;
        let selected_agent = this.state.selected_agent;
        let followup_date = this.state.followup_date;
        let followup_note = this.state.followup_note;
        let validationerrors = {};
        let isValid = true;


        if (followup_for == null || followup_for == "" || followup_for == 0) {
            isValid = false;
            validationerrors["followup_for"] = "Follow up for is required.";
        }
        if (selected_agent == null || selected_agent == "" || selected_agent == 0) {
            isValid = false;
            validationerrors["selected_agent"] = "Agent is required.";
        }
        if (followup_date == null || followup_date == "" || followup_date == 0) {
            isValid = false;
            validationerrors["followup_date"] = "Date is required.";
        }
        if (followup_note == null || followup_note == "" || followup_note == 0) {
            isValid = false;
            validationerrors["followup_note"] = "Note is required.";
        }

        this.setState({
            validationerrors: validationerrors
        });

        return isValid;
    }

    handleViewFollowup = (data, e) => {
        this.setState({ isfollowupViewOpen: true, followup_data: data })
    }

    handleFollowupViewOk = () => {
        this.setState({ isfollowupViewOpen: false })

    }

    handleFollowupViewCancel = () => {
        this.setState({ isfollowupViewOpen: false })
    }

    handleOkOffer = () => {
        this.setState({ isOfferOpen: false })
    }
    handleCancelOffer = () => {
        this.setState({ isOfferOpen: false })
    }

    handleAllMatch = () => {

        const data = {
            lead_id: this.state.record_id,
            community_match: this.state.community_match,
            sub_community_match: this.state.sub_community_match,
            min_budget: this.state.min_budget, max_budget: this.state.max_budget,
            beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
            property_type_match: this.state.property_type_match,
            added_date_match: this.state.added_date_match
        }
        this.props.setNoMatch(data)
        window.open("/matching_listings", "_blank");
        //this.props.history.push("/matching_listings")
    }

    handleRemoveList = (refno, e) => {


        this.setState({
            viewings_listings: this.state.viewings_listings.filter(function (viewing_listing) {
                return viewing_listing.refno !== refno
            })
        });

        this.setState({
            ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
                return ref_num.value !== refno
            })
        });

        this.setState({
            ref_def_nums: this.state.ref_def_nums.filter(function (ref_num) {
                return ref_num !== refno
            })
        });
    }

    // New Code By Farooq


    handleCommunityFilter = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ community_filter: e })
        if (e != null || e !== '') {
            API.get("/sub_community/" + e)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ sub_communities: response.data.sub_communities })
                })
        }

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            property_type_filter: this.state.property_type_filter,
            community_filter: e,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,

            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,


            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }

    handleSubCommunityFilter = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });

        API.get("/property/" + e)
            .then((response) => {
                if (response.data.success)
                    this.setState({ properties: response.data.properties })
            })


        this.setState({ sub_community_filter: e })



        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: e,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,


            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }




    handlePropertyFilter = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });

        this.setState({ property_filter: e })

        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,

            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: e,
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,


            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }

    handlePropertyTypeFilter = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ property_type_filter: e })

        const page = 1;
        const perPage = 10;

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,

            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,

            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,

            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,


            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,



            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: e,


            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    onEnterReply = (e, index) => {

        const { value } = e.target;

        this.setState(prevState => {

            const reply = [...prevState.reply];

            reply[index] = value;
            this.setState({ reply: reply })
            return { reply };

        });

    }
    onClickReply = (parent_data, index, e) => {
        if (parent_data !== "" && parent_data != null) {
            const data = {
                parent_data: parent_data,
                note: this.state.reply[index] || ''
            };
            API.post("/add_lead_note_reply ", data)
                .then((response) => {
                    if (response.data) {
                        if (response.data.success) {
                            this.setState({
                                reply: "",
                                notes: response.data.notes,
                                all: response.data.all,
                                call_notes: response.data.call_notes,
                                whatsapp_notes: response.data.whatsapp_notes,
                                email_notes: response.data.email_notes
                            })
                            API.post("/add_lead_note_reply_email ", data)
                                .then((response) => {
                                    if (response.data.success) {
                                        console.log("email send")
                                    }
                                }).catch((err) => {
                                    console.log("error", err)
                                })
                        }
                    }
                    else {
                        // toast.error("Something Went Wrong")
                    }
                }).catch((err) => {
                    /* toast.error("Something Went Wrong") */
                })
        }
    }


    postAddCollaborator = () => {
        const data = {
            colls: this.state.newArrSelectedAgents,
            lead_id: this.state.record_id
        }
        API.post("/add_collaborate ", data)
            .then((response) => {
                if (response.data) {
                    if (response.data.success) {
                        this.setState({ collaborators: response.data.collaborators, newArrSelectedAgents: [] })
                    }
                }
                else {
                    console.log("***Check Add Collaborator Not Success *** ", response);
                }
            }).catch((err) => console.log("**CHeck Error *** ", err))
    }

    handleChangeSelect = (value) => {
        this.setState({ newArrSelectedAgents: value })
    };
    onSelectAgents = (value) => {
        const arr = this.state.agents.filter((data) => {
            if (data.value == value) {
                return data;
            }
        })
        // this.setState({newArrSelectedAgents: [...this.state.newArrSelectedAgents, arr]});
        this.setState((state, props) => ({
            newArrSelectedAgents: state.newArrSelectedAgents, arr
        }));
    }

    onChangeCallNote = (value) => {
        this.setState({ call_log: value })
    }

    onSelectCallMentions = (option) => {
        this.setState({ selectedCallUsers: [...this.state.selectedCallUsers, option] })

    }

    onChangeNoteNote = (value) => {
        this.setState({ note_log: value })
    }

    onSelectNoteMentions = (option) => {
        this.setState({ selectedNoteUsers: [...this.state.selectedNoteUsers, option] })

    }

    onChangeWhatsappNote = (value) => {
        this.setState({ whatsapp_log: value })
    }

    onSelectWhatsappMentions = (option) => {
        this.setState({ selectedWhatsappUsers: [...this.state.selectedWhatsappUsers, option] })

    }

    onChangeEmailNote = (value) => {
        this.setState({ email_log: value })
    }

    onSelectEmailMentions = (option) => {
        this.setState({ selectedEmailUsers: [...this.state.selectedEmailUsers, option] })

    }

    onChangeViewingNote = (value) => {
        this.setState({ viewing_log: value })
    }

    onSelectViewingMentions = (option) => {
        this.setState({ selectedViewingUsers: [...this.state.selectedViewingUsers, option] })

    }
    onChangeOfferNote = (value) => {
        this.setState({ offer_log: value })
    }

    onSelectOfferMentions = (option) => {
        this.setState({ selectedOfferUsers: [...this.state.selectedOfferUsers, option] })

    }
    onChangeFollowupNote = (value) => {
        this.setState({ followup_log: value })
    }

    onSelectFollowupMentions = (option) => {
        this.setState({ selectedFollowupUsers: [...this.state.selectedFollowupUsers, option] })

    }

    handleSaveOwnerName = (value) => {

        const data = {
            owner_id: this.state.owner_id,
            owner_name: value
        }
        API.post("/update_owner_name", data)
            .then((response) => {
                console.log("updated")
            })

    }

    handleSaveOwnerEmail = (value) => {
        const data = {
            owner_id: this.state.owner_id,
            owner_email: value
        }
        API.post("/update_owner_email", data)
            .then((response) => {
                console.log("updated")
            })
    }

    handleClearFilter = () => {

        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.props.removeLeadReducer()
        this.props.removeStockAreaReducer()


        this.setState({
            sortedInfo: null,
            nested_stage_value: [],
            listings_array: [],
            search_owner: "",
            replied_to_whatsapp: "NO",
            clicked_book_viewing: [],
            viewing_booked_since_requested: "NO",
            viewing_date_range: [],
            viewing_outcome: [],
            number_confirmed_on_whatsapp: "NO",
            offer_status: [],
            deal_signed: "NO",

            last_activity_date_morethan_option: false,
            last_activity_date_lessthan_option: false,
            last_activity_date_value: "",
            last_activity_date_value_id: 1,

            last_whatsapp_date_morethan_option: false,
            last_whatsapp_date_lessthan_option: false,
            last_whatsapp_date_value: "",
            last_whatsapp_date_value_id: 1,

            last_whatsapp_received_date_morethan_option: false,
            last_whatsapp_received_date_lessthan_option: false,
            last_whatsapp_received_date_value: "",
            last_whatsapp_received_date_value_id: 1,

            selectedTeam: "",
            selectedAgents: "",
            isMatchedLead: 0,
            isExpiringLead: 0,
            isMoveInDateLead: 0,
            community_filter: "",
            sub_community_filter: "",
            property_filter: "",
            name_value_id: 1,
            name_value: "",
            phone_value_id: 1,
            phone_value: "",
            refno_value: "",
            refno_value_id: 1,
            bed_rooms_value_id: 1,
            bed_rooms_value: "",
            agent_value_id: 1,
            agent_value: [],
            stage_value: [],
            verified_sub_stage_value: [],
            contact_types_value: [],
            furnished_types_value: [],
            price_first_value_id: 1,
            price_first_value: "",
            price_min: 0,
            price_max: 0,
            date_morethan_option: false,
            date_lessthan_option: false,
            move_in_date_morethan_option: false,
            move_in_date_lessthan_option: false,
            date_value: "",
            date_value_id: 1,
            property_type_filter: [],

            viewing_value: "",
            viewing_morethan_value: false,
            viewing_lessthan_value: false,
            viewing_option_value: 1,
            inrange_viewing: false,
            viewing_min_value: "",
            viewing_max_value: "",


            offer_value: "",
            offer_morthan_value: false,
            offer_lessthan_value: false,
            offer_option_value: 1,
            inrange_offer: false,
            offer_min_value: "",
            offer_max_value: "",


            last_call_morethan_option: false,
            last_call_lessthan_option: false,
            last_call_value: 0,
            last_call_value_id: 1,
            last_call_option_value: 1,


            last_match_morethan_option: false,
            last_match_lessthan_option: false,
            last_match_value: 0,
            last_match_value_id: 1,
            last_match_option_value: 1,

            move_in_date_morethan_option: false,
            move_in_date_lessthan_option: false,
            move_in_date_value: "",
            move_in_date_value_id: 1,
            move_in_date_option_value: 1,
            move_in_date_date_range: [],
            inrange: false
        })
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: [],
            listings_array: [],
            search_owner: "",
            replied_to_whatsapp: "NO",
            clicked_book_viewing: [],
            viewing_booked_since_requested: "NO",
            viewing_date_range: [],
            viewing_outcome: [],
            number_confirmed_on_whatsapp: "NO",
            offer_status: [],
            deal_signed: "NO",

            last_activity_date_morethan_option: false,
            last_activity_date_lessthan_option: false,
            last_activity_date_value: "",
            last_activity_date_value_id: 1,

            last_whatsapp_date_morethan_option: false,
            last_whatsapp_date_lessthan_option: false,
            last_whatsapp_date_value: "",
            last_whatsapp_date_value_id: 1,

            last_whatsapp_received_date_morethan_option: false,
            last_whatsapp_received_date_lessthan_option: false,
            last_whatsapp_received_date_value: "",
            last_whatsapp_received_date_value_id: 1,

            selectedTeam: "",
            selectedAgents: "",
            isMatchedLead: 0,
            isExpiringLead: 0,
            isMoveInDateLead: 0,
            community_filter: "",
            sub_community_filter: "",
            property_filter: "",
            name_value_id: 1,
            name_value: "",
            phone_value_id: 1,
            phone_value: "",
            refno_value: "",
            refno_value_id: 1,
            bed_rooms_value_id: 1,
            bed_rooms_value: "",
            agent_value_id: 1,
            agent_value: [],
            stage_value: [],
            verified_sub_stage_value: [],
            contact_types_value: [],
            furnished_types_value: [],
            price_first_value_id: 1,
            price_first_value: "",
            price_min: 0,
            price_max: 0,
            date_morethan_option: false,
            date_lessthan_option: false,
            move_in_date_morethan_option: false,
            move_in_date_lessthan_option: false,
            date_value: "",
            date_value_id: 1,
            property_type_filter: [],

            viewing_value: "",
            viewing_morethan_value: false,
            viewing_lessthan_value: false,
            viewing_option_value: 1,
            inrange_viewing: false,
            viewing_min_value: "",
            viewing_max_value: "",


            offer_value: "",
            offer_morthan_value: false,
            offer_lessthan_value: false,
            offer_option_value: 1,
            inrange_offer: false,
            offer_min_value: "",
            offer_max_value: "",


            last_call_morethan_option: false,
            last_call_lessthan_option: false,
            last_call_value: 0,
            last_call_value_id: 1,
            last_call_option_value: 1,


            last_match_morethan_option: false,
            last_match_lessthan_option: false,
            last_match_value: 0,
            last_match_value_id: 1,
            last_match_option_value: 1,

            move_in_date_morethan_option: false,
            move_in_date_lessthan_option: false,
            move_in_date_value: "",
            move_in_date_value_id: 1,
            move_in_date_option_value: 1,
            move_in_date_date_range: []
        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }


    saveLeadStage = (value) => {

        // done


        const data = { leads_id: this.state.record_id, lead_stage: value }
        if (value == 5) {
            Swal.fire({
                title: 'Lead Stage Status Update',
                text: 'Please provide a note about the status being changed to Dead.',
                input: 'text',
                className: 'deadstage-wrap',
                inputAttributes: { autocapitalize: 'off' },
                showCancelButton: false,
                confirmButtonText: 'Add note',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire("<p class='confirmedstat'>Status changed to Dead lead.</p>")
                    API.post("/update_lead_stage", data)
                        .then((response) => {
                            if (response.data.success) {
                                this.setState({
                                    all: response.data.all,
                                    notes: response.data.notes
                                })
                            }
                        }).catch((err) => console.log(err));
                    const confirm_data = { leads_id: this.state.record_id, confirm_message: result.value }
                    console.log(confirm_data);
                    API.post("/add_confirm_note", confirm_data)
                        .then((response) => {
                            if (response.data.success) {
                                this.setState({
                                    all: response.data.all,
                                    notes: response.data.notes
                                })
                            }
                        }).catch((err) => console.log(err));
                }
            })
        } else if (value == 2 || value == 3 || value == 4) {
            this.setState({ contact_type_call_note_status: value })
            this.setState({ isCallLogOpen: true })
        }
        else {
            API.post("/update_lead_stage", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes
                        })
                    }
                }).catch((err) => console.log(err));
        }

        // const { value: ipAddress } =  Swal.fire({
        //     title: 'Enter confirm',
        //     input: 'text',
        //     inputLabel: 'Note',
        //     //inputValue: 2,
        //     showCancelButton: true,
        //     inputValidator: (value) => {
        //       if (!value) {
        //         return 'You need to write something!'
        //       }
        //     }
        //   })

        //   .then((result) => {
        //     if (result.isConfirmed) {



        //         const confir_data = {confirm_message:result.value}


        //         API.post("/update_lead_stage", data)
        //         .then((response) => {
        //             if (response.data.success) {
        //                 this.setState({
        //                     all: response.data.all,
        //                     notes: response.data.notes
        //                 })
        //             }
        //         }).catch((err) => console.log(err));

        //         API.post("/add_confirm_note", confir_data)
        //         .then((response) => {
        //             if (response.data.success) {
        //                 this.setState({
        //                     all: response.data.all,
        //                     notes: response.data.notes
        //                 })
        //             }
        //         }).catch((err) => console.log(err));
        //     }
        //   })


    }

    saveMoveInDate = (value) => {
        // const data = { leads_id: this.state.record_id, move_in_date: value }
        // edit by Farooq, the above one is wrong date state...
        this.setState({
            leads_id: this.state.record_id,
            requirement_move_in_date: value
        })
        API.post("/update_move_in_date", { leads_id: this.state.record_id, move_in_date: value })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }

    saveVerifier = (value) => {
        // return
        // const data = { leads_id: this.state.record_id, verifier_by: value }
        this.setState({
            leads_id: this.state.record_id, verifier_by: value
        })
        API.post("/update_verifier_by", { leads_id: this.state.record_id, verifier_by: value })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }

    onChangeContactType = (e) => {
        console.log("e", e)
    }

    saveContactType = (value) => {
        this.setState({ contact_type_requirement: value })
        // return
        // const data = { leads_id: this.state.record_id, verifier_by: value }
        this.setState({
            leads_id: this.state.record_id,
        })
        API.post("/update_contact_type", { leads_id: this.state.record_id, contact_type: value })
            .then((response) => {

                if (response.data.success) {
                    if (value == 1) {
                        this.setState({ contact_type_requirement: 1 })
                    } else { this.setState({ contact_type_requirement: value }) }
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })

    }




    saveAgent = (value) => {
        const data = { leads_id: this.state.record_id, lead_agent: value }
        API.post("/update_agent", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }

    saveCurrentLive = (value) => {
        this.setState({ current_live: value })
    }

    saveCommunity = (value) => {
        this.setState({ requirement_community: value })
        const data = { leads_id: this.state.record_id, community: value }
        API.post("/update_community", data)
            .then((response) => {



                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                    this.fetchExactListings()
                    this.fetchCoparableListings()
                }
            })
        if (value !== null || value !== '') {
            API.get("/sub_community/" + value)
                .then((response) => {
                    if (response.data.success)
                        this.setState({ requirement_sub_communities: response.data.sub_communities })
                })
        }
    }

    saveSubCommunity = (value) => {
        this.setState({ requirement_sub_community: value })
        const data = { leads_id: this.state.record_id, subcommunity: value }
        API.post("/update_subcommunity", data)
            .then((response) => {



                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }


    saveBedroom = (value) => {
        const data = { leads_id: this.state.record_id, bedroom: value }
        API.post("/update_bedroom", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes,
                        bedroom_match: value
                    })
                    this.fetchExactListings()
                    this.fetchCoparableListings()
                }
            })
    }

    // By Farooq

    addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

    fAmountSaperator = (value) => {
        return this.addCommas(this.removeNonNumeric(value));
    }

    savePrice = (value) => {

        const value_ = value
        const new_value = value_.replace(/,/g, "")

        this.setState({
            price_requirement: value, min_budget: new_value
        })
        if (new_value > 0) {
            const data = { leads_id: this.state.record_id, price_requirement: new_value }
            API.post("/update_price", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes
                        })
                        this.fetchExactListings()
                        this.fetchCoparableListings()
                    }
                })
        }

    }

    saveMax = (value) => {
        const value_ = value
        const new_value = value_.replace(/,/g, "")


        this.setState({
            requirement_max_budget: value, max_budget: new_value
        })
        if (new_value > 0) {
            const data = { leads_id: this.state.record_id, max_budget: new_value }
            API.post("/update_max_budget", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes
                        })
                    }
                })
        }

    }



    handleViewingValue_id = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ viewing_option_value: e.target.value })
        if (e.target.value == 5) {
            this.setState({ inrange_viewing: true })
        } else {
            this.setState({ inrange_viewing: false })

        }
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: e.target.value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }

        if (this.state.viewing_value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }



    }
    handleViewingValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ viewing_value: e.target.value })

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: e.target.value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleViewingMinValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ viewing_min_value: e.target.value })

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: e.target.value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }

        const page = 1
        const perPage = 10
        if (this.state.viewing_max_value != "" && e.target.value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data)
        }
    }

    handleViewingMaxValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ viewing_max_value: e.target.value })
        const page = 1
        const perPage = 10
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: e.target.value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        if (this.state.viewing_min_value != "" && e.target.value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data)
        }
    }
    // offer

    handleOfferValue_id = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ offer_option_value: e.target.value })
        if (e.target.value == 5) {
            this.setState({ inrange_offer: true })
        } else {
            this.setState({ inrange_offer: false })
        }
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: e.target.value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }

        if (this.state.offer_value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }



    }
    handleOfferValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ offer_value: e.target.value })

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: e.target.value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (e.target.value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleOfferMinValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ offer_min_value: e.target.value })

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: e.target.value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1
        const perPage = 10
        if (this.state.offer_max_value != "" && e.target.value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data)
        }
    }

    handleOfferMaxValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ offer_max_value: e.target.value })
        const page = 1
        const perPage = 10
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: e.target.value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        if (this.state.offer_min_value != "" && e.target.value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data)
        }
    }
    handleRefnoValue_id = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ refno_value_id: e })
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: e,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }

        if (this.state.refno_value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleRefnoValue = (e) => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ refno_value: e.target.value })
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: e.target.value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }

        if (e.target.value != "") {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }

    handleRangeDate = (value, dateString) => {


        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ move_in_date_date_range: value != null ? dateString : [] })

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: value != null ? dateString : []


        }

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleCallLogModal = () => {
        this.setState({ isCallLogOpen: true })
    }
    handleCallLogModalCancel = () => {
        this.setState({ isCallLogOpen: false })
    }
    handleCallNoteButtons = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            this.setState({ isCallEnable: true })
        } else { this.setState({ isCallEnable: false }) }
    }

    handleVerificationCallLogOpen = () => { this.setState({ isVerificationOpen: true }) }
    handleVerificationCallLogClose = () => { this.setState({ isVerificationOpen: false }) }

    handleNoAnswer = () => {
        const data = { leads_id: this.state.record_id }
        API.post("/lead_add_call_no_answer", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ requirement_lead_stage: 10 })
                    Swal.fire({
                        // title: "Create MOU Request!",

                        text: "Would you like to make Follow up?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes!",
                        cancelButtonText: "No"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({ isfollowupOpen: true })
                        }
                    })
                    this.setState({
                        all: response.data.all,
                        call_notes: response.data.call_notes,
                        notes: response.data.notes,
                        requirement_lead_stage: 10
                    })
                }
            })
    }

    handleTeams = (e, event) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ selectedTeam: e })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents



        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: e,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }



    handleClearTeam = () => {


        this.setState({ selectedTeam: "" })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: "",
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleClearCreatedDate = () => {



        this.setState({ date_value: "", date_value_id: 1 })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: "",
            date_value_id: 1,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }
    handleClearLastActivity = () => {



        this.setState({ last_activity_date_value: "", last_activity_date_value_id: 1 })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: "",
            last_activity_date_value_id: 1,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleClearMoveInDate = () => {



        this.setState({ move_in_date_value: "", move_in_date_value_id: 1, move_in_date_date_range: [], move_in_date_option_value: 1 })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: "",
            move_in_date_value_id: 1,
            move_in_date_option_value: 1,
            move_in_date_date_range: []


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }
    onChangePin = (e) => {

        const data = { leads_id: this.state.record_id, pin: e.target.checked == true ? 1 : 0 }
        API.post("/update_pin", data)
            .then((res) => {
                if (res.data.success) {
                    this.setState({ pin: !this.state.pin })
                } else {
                    toast.error("More than 5")
                }
            })
    }

    changeReferred_by = (e) => {
        this.setState({ referred_by: e })
    }
    changeReferred_to = (e) => {
        this.setState({ referred_to: e })
    }
    onSaveRef = () => {
        if ((this.state.referred_by == "" || this.state.referred_by == null || this.state.referred_by == undefined)) {
            toast.error("Referred by is required")
        } else if ((this.state.referred_to == "" || this.state.referred_to == null || this.state.referred_to == undefined)) {
            toast.error("Referred to is required")
        } else {
            const data = {
                referred_to: this.state.referred_to,
                referred_by: this.state.referred_by,
                lead_id: this.state.record_id,
            }
            API.post("/lead_referred", data)
                .then((response) => {
                    // this.setState({isActivityLoading: false})
                    if (response.data.success) {
                        let new_agent = this.state.referred_to;
                        this.setState({ requirement_agent_id: new_agent.toString() })
                        toast.success("Lead is successfully referred")
                        // this.setState({activityFeeds: response.data.activity_feeds})
                    }
                }).catch((e) => {
                    // this.setState({isActivityLoading: false})
                    // toast.error("Something Went Wrong")
                })
        }
    }

    // Match Listing
    handleShowMatchListing = () => {
        this.setState({ showMatchListings: true })
    }
    handleShowMatchListingClose = () => {
        this.setState({ showMatchListings: false })
    }

    // new 3 filters
    //  last activity filter
    handleLastActivityDateMorethan = (e) => {
        this.setState({ last_activity_date_morethan_option: true, last_activity_date_lessthan_option: false, })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: true,
            last_activity_date_lessthan_option: false,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_activity_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }
    handleLastActivityDateLessthan = (e) => {
        this.setState({ last_activity_date_morethan_option: false, last_activity_date_lessthan_option: true, })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: false,
            last_activity_date_lessthan_option: true,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_activity_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }
    handleLastActivityDateValue_id = (e) => {
        this.setState({ last_activity_date_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: e.target.value,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_activity_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleLastActivityDateValue = (e) => {
        if (e == null || e == "") this.setState({ filtershow: false }); else this.setState({ filtershow: true });
        this.setState({ last_activity_date_value: e.target.value, })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: e.target.value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    // last whatsapp
    handleLastWhatsappDateMorethan = (e) => {
        this.setState({ last_whatsapp_date_morethan_option: true, last_whatsapp_date_lessthan_option: false })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: true,
            last_whatsapp_date_lessthan_option: false,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_whatsapp_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }
    handleLastWhatsappDateLessthan = (e) => {
        this.setState({ last_whatsapp_date_morethan_option: false, last_whatsapp_date_lessthan_option: true, })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: false,
            last_whatsapp_date_lessthan_option: true,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_whatsapp_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }
    handleLastWhatsappDateValue_id = (e) => {
        this.setState({ last_whatsapp_date_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: e.target.value,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_whatsapp_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleLastWhatsappDateValue = (e) => {
        this.setState({ last_whatsapp_date_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: e.target.value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }
    // last whatsapp received
    handleLastWhatsappReceivedDateMorethan = (e) => {
        this.setState({ last_whatsapp_received_date_morethan_option: true, last_whatsapp_received_date_lessthan_option: false, })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: true,
            last_whatsapp_received_date_lessthan_option: false,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_whatsapp_received_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }
    handleLastWhatsappReceivedDateLessthan = (e) => {
        this.setState({ last_whatsapp_received_date_morethan_option: false, last_whatsapp_received_date_lessthan_option: true, })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: false,
            last_whatsapp_received_date_lessthan_option: true,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_whatsapp_received_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }

    }
    handleLastWhatsappReceivedDateValue_id = (e) => {
        this.setState({ last_whatsapp_received_date_value_id: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: e.target.value,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;
        if (this.state.last_whatsapp_received_date_value != '') {
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        }
    }

    handleLastWhatsappReceivedDateValue = (e) => {
        this.setState({ last_whatsapp_received_date_value: e.target.value })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: e.target.value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }

        const page = 1;
        const perPage = 10;

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }


    isValidHttpUrl = (string) => {
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    handleSplitReq = (string) => {
        if (string && string.includes('changed from')) {
            var splitarr = string.split("changed from");
            var splitarrtwo = splitarr[1].split("To");
            return (<>
                <div className='d-flex adesc'><p className='font-12'>
                    <span className='mr-2 font-bold'>{splitarr[0]}</span>
                    <Tag>{splitarrtwo[0]}</Tag>
                    <i class="bi bi-arrow-right mr-2"></i>
                    <Tag className='updated'>{splitarr[0].includes("Move in date") ? <Moment format='DD MMMM YYYY'>{splitarrtwo[1]}</Moment> : splitarrtwo[1]}</Tag>
                </p></div>
            </>)
        } if (string.includes('changed To')) {
            var splitarr = string.split("changed To");
            return (
                <div className='d-flex adesc'><p className='font-12'>
                    <span className='mr-0 font-bold'>{splitarr[0]}</span>
                    <Tag>{splitarr[0]}</Tag>
                    <i class="bi bi-arrow-right mr-2 "></i>
                    <Tag>{splitarr[1]}</Tag>
                </p></div>
            )
        } if (string.includes('Expired')) {
            var fromStatus = string.match(/(?<=from\s+).*?(?=\s+to)/gs);
            var toStatus = string.match(/(?<=to\s+).*?(?=\s+because)/gs);
            var splitarr = string.split("following:");
            if (splitarr && splitarr[1]) {
                return (<>
                    <div className='d-flex flex-column w-100'>
                        <div className='adesc'>
                            <span className='font-bold' style={{ marginBottom: "-15px", display: 'flex' }}>Reason:</span>
                            <p className='font-12 mt-0'>{parse(splitarr[1])}</p>
                        </div>
                        <div className='d-flex adesc-stat mt-1'>
                            <Tag className='pstat'>{fromStatus}</Tag>
                            <i class="bi bi-arrow-right mr-2 "></i>
                            <Tag className='pstat expired'>{toStatus}</Tag>
                        </div>
                    </div>
                </>)
            } else {
                return (<>
                    <div className='d-flex flex-column w-100'>
                        <div className='adesc'>
                            <p className='font-12 mt-0'>{string}</p>
                        </div>
                        <div className='d-flex adesc-stat mt-1'>
                            <Tag className='pstat'>New Inquiry</Tag>
                            <i class="bi bi-arrow-right mr-2 "></i>
                            <Tag className='pstat expired'>Expired</Tag>
                        </div>
                    </div>
                </>)
            }
        }

    }
    handleSplitViewing = (string, props) => {
        if (string.includes('Viewing booked')) {
            var splitarr = string.split("booked for");
            var splitarr2 = splitarr[1].split("on")
            var splitarr3 = splitarr2[1].split(" ")
            return (
                <div className='d-flex flex-row adesc-sched w-100'>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                        <p>Date / Time</p>
                        <Moment format='hh:mm A' className='font-bold font-14' style={{ whiteSpace: 'nowrap' }}>{splitarr2[1]}</Moment>
                        <Moment format='DD MMMM YYYY' style={{ whiteSpace: 'nowrap' }}>{splitarr3[2]}</Moment>
                    </div>
                    {props ?
                        <div className='lp_details w-100 m-0 pr-0' style={{ border: 0 }}>
                            <Carousel arrows style={{ width: '130px', height: "68px", overflow: 'hidden' }} effect="fade" {...props}>
                                {this.getImageArray(props.property_images).map((image) => {
                                    return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                })}
                            </Carousel>
                            <div className='lpdetails_text w-100'>
                                {props.status == "D" ? <p className='stat publishstat'>Draft</p>
                                    : props.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                        : props.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                            : props.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                : props.status == "Y" ? <p className='stat published'>Published</p>
                                                    : props.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                        : null
                                }
                                <span className='mr-0 font-bold'>{splitarr2[0]}</span><i class="bi bi-dot"></i><span>{props.price && props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</span>
                                <p>{props.unitno ? props.unitno + "," : null}{props.property_project ? props.property_project + "," : null}</p>
                            </div>

                        </div>
                        : null}
                </div>
            )
        } else {
            return false
        }

    }

    getImageArray = (imageString) => {
        if (imageString == "" || imageString == null) {
            return [];
        } else {
            let images = imageString.split(",");
            return images;
        }
    }
    handleFilterDrawerOpen = () => {
        this.setState({ FilterDrawerOpen: true })
    }
    handleFilterDrawerClose = () => {
        this.setState({ FilterDrawerOpen: false })
    }
    handleFilterDrawerMobileOpen = () => {
        this.setState({ MobileFilterDrawerOpen: true })
    }
    handleFilterDrawerMobileClose = () => {
        this.setState({ MobileFilterDrawerOpen: false })
    }

    handleOpenTagged = () => {
        this.setState({ isOpenTagged: true })
    }



    handleCloseTagged = () => { this.setState({ isOpenTagged: false }) }


    handleOpenMyActivity = () => {
        this.setState({ isOpenMyActivityFeed: true })
    }
    handleCloseMyActivity = () => { this.setState({ isOpenMyActivityFeed: false }) }

    getActivityFeed = () => {
        this.setState({ isActivityLoading: true })
        const data = {
            filter: "",
            agent_id: ""
        }
        API.post("/activity_feed", data)
            .then((response) => {
                this.setState({ isActivityLoading: false })
                if (response.data.success) {
                    this.setState({ activityFeeds: response.data.activity_feeds })
                }
            }).catch((e) => {
                this.setState({ isActivityLoading: false })
                // toast.error("Something Went Wrong")
            })
    }

    getTags = () => {
        this.setState({ isTagLoading: true })
        API.get("/send_tags")
            .then((response) => {
                if (response.data) {
                    this.setState({ isTagLoading: false })
                    if (response.data.success) {
                        this.setState({
                            sendTagDataAll: response.data.all_tags,
                            sendTagDataReplied: response.data.replied_tags,
                            sendTagDataPending: response.data.pending_tags,
                            sendTagData: response.data.all_tags,
                        })
                    }
                }
                else {
                    // toast.error("Something Went Wrong")
                    this.setState({ isTagLoading: false })
                }
            }).catch((err) => {
                // toast.error("Something Went Wrong")
                this.setState({ isTagLoading: false })
            })

        API.get("/recieve_tags")
            .then((response) => {
                if (response.data) {
                    if (response.data.success) {
                        this.setState({
                            recieveTagDataAll: response.data.all_recieve_tags,
                            recieveTagDataReplied: response.data.replied_recieve_tags,
                            recieveTagDataPending: response.data.pending_recieve_tags,
                            recieveTagData: response.data.all_recieve_tags,
                        })
                    }
                }
            }).catch((err) => {
                console.log("Error", err)
            })
    }

    handleOpenBedsPopover=(bedspopover)=>{this.setState({bedspopover})}
    handleCloseBedsPopover=()=>{this.setState({bedspopover:false})}
    handleOpenPricePopover=(pricepopover)=>{this.setState({pricepopover})}
    handleClosePricePopover=()=>{this.setState({pricepopover:false})}

    handleResetBeds=()=> {
        this.setState({ bed_rooms_value: "" })
        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value:"",
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,


            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range,

            isViewingTableShow: false,
            isOfferTableShow: false,
            isOfferTabShow: false,
            isViewingTabShow: false
        }

        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }


    handleInquiryChange = (value) => {
        this.setState({ date_value:value=='365+' ? 365:value, })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,
            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,
            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value:    this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,

            date_morethan_option: value=='365+' ? false:true,
            date_lessthan_option: value=='365+' ? true:false,
            date_value: value=='365+' ? 365:value,
            date_value_id: 1,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            property_type_filter: this.state.property_type_filter,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,
            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,
            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
        
    }

    handleSaleRentChange = (value) => {
        this.setState({ propertyFor: value.target.value , contact_types_value: value.target.value === 'rent' ? ["1"] : value.target.value === 'sale' ? ["2"] : [] })
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,
            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,
            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: value.target.value === 'rent' ? ["1"] : value.target.value === 'sale' ? ["2"] : this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,
            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,
            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    };

    handleSearchAll = (e) => {
        const all_data = store.getState((state) => state);

        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const total_data = { selectedTeam: this.state.selectedTeam }

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,

            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }


        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }


    handleSearchOwner = (e) => {
        this.setState({ search_owner: e.target.value ? e.target.value : "" })
        const all_data = store.getState((state) => state);

        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const total_data = { selectedTeam: this.state.selectedTeam }

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: e.target.value ? e.target.value : "",
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,

            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }


        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }


    handleSearchOwnerWithFourDigit = (e) => {

        //  const input = e.target.value;
        const lastFourDigits = e.target.value
        this.setState({ last_four_digit: lastFourDigits })
        const all_data = store.getState((state) => state);

        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const total_data = { selectedTeam: this.state.selectedTeam }

        const page = 1;
        const perPage = 10;

        const data = {

            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            last_four_digit: e.target.value ? e.target.value : "",

            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",

            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,

            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,

            
            stage_value: [],
            // stage_value: [1,2,3,4,5,6,7,8,9,10,11,12],
            // stage_value: [9],

            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }


        if(lastFourDigits.length > 3){

            this.setState({ isRequestInProgress: true });
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        } 
        //  this.setState({ isRequestInProgress: false });

        if(lastFourDigits.length == 0){
            this.fetchTotalStatus(data)
            this.fetchData(page, perPage, data);
        } 

       //  else if (lastFourDigits.length > 2 || lastFourDigits.length > 3 || lastFourDigits.length > 1){
        //     this.fetchTotalStatus(data)
        //     this.fetchData(page, perPage, data);
        // }



    // const state = store.getState();
        
    // console.log(this.leads)
    // const all_data = state.expiring_lead_reducer && state.expiring_lead_reducer.leads; 
    // console.log(all_data);
    // const input = e.target.value;
    // const searchDigits = input.slice(-4);
    // console.log(this.state.number_confirmed_on_whatsapp);
    // const filteredData = all_data.filter(item => {
    //     const phoneNumber = item.phone_number || "";
    //     // Extract the last four digits of the phone number
    //     const lastFourDigits = phoneNumber.slice(-4);
    //     // Compare with the input value
    //     return lastFourDigits === searchDigits;
    // });
    // console.log(filteredData);
    // this.setState({ filteredData });




    // const all_data = store.getState((state) => state);

    // const filteredData = all_data.filter(item => {

        // console.log(item)

    // });
    // console.log(all_data);
        // this.setState({ search_owner: e.target.value ? e.target.value : "" })
        // const all_data = store.getState((state) => state);

        // console.log(all_data)

    }


    handleAddNewLeadOpen = () => (this.setState({ isAddNewLead: true }))

    handleAddNewLeadClose = () => {
        this.setState({ isAddNewLead: false });
        this.resetNewLeadForm();
    }
    resetNewLeadForm = () => {
        this.setState({ client_name: "" });
        this.setState({ client_phone: "" });
        this.setState({ client_email: "" });
        this.setState({ client_refno: "" });
        this.setState({ client_source: "" });
        this.setState({ client_agent: "" });
        this.setState({ client_note: "" });
        this.setState({ client_contact_type: "" });
        this.setState({ client_data: "" });
    }

    handleAddLead = () => {
        if (this.addLeadValidate()) {
            const data = {
                client_name: this.state.client_name,
                client_phone: this.state.client_phone,
                client_email: this.state.client_email,
                client_refno: this.state.client_refno,
                client_source: this.state.client_source,
                client_agent: this.state.client_agent,
                client_note: this.state.client_note,
                client_contact_type: this.state.client_contact_type
            }
            API.post("/add_new_lead", data)
                .then((response) => {
                    if (response.data.success) {
                        toast.success("Lead has been Successfully Created")
                        this.setState({ isAddNewLead: false });
                    }
                    this.setState({ isAddNewLead: false })
                }).catch((e) => {
                    console.log("error", e)
                    toast.error("Something went wrong")
                }).finally(() => {
                    this.setState({ isAddNewLead: false })
                })
        }
    }

    addLeadValidate() {
        let client_name = this.state.client_name;
        let client_phone = this.state.client_phone;
        let client_email = this.state.client_email;
        let client_refno = this.state.client_refno;
        let client_source = this.state.client_source;
        let client_agent = this.state.client_agent;
        let client_note = this.state.client_note;
        let client_contact_type = this.state.client_contact_type;
        let addleadvalidationerrors = {};
        let isValid = true;


        if (client_name == null || client_name == "" || client_name == 0) {
            isValid = false;
            addleadvalidationerrors["client_name"] = "Client name is required.";
        }
        // if (client_phone == null || client_phone == "" || client_phone == 0) {
        //     isValid = false;
        //     addleadvalidationerrors["client_phone"] = "Client phone number is required.";
        // }
        // if (client_email == null || client_email == "" || client_email == 0) {
        //     isValid = false;
        //     addleadvalidationerrors["client_email"] = "Email address is required.";
        // }
        if (client_refno == null || client_refno == "" || client_refno == 0) {
            isValid = false;
            addleadvalidationerrors["client_refno"] = "Ref no is required.";
        }
        // if (client_source == null || client_source == "" || client_source == 0) {
        //     isValid = false;
        //     addleadvalidationerrors["client_source"] = "Source is required.";
        // }
        if (client_agent == null || client_agent == "" || client_agent == 0) {
            isValid = false;
            addleadvalidationerrors["client_agent"] = "Agent is required.";
        }
        // if (client_note == null || client_note == "" || client_note == 0) {
        //     isValid = false;
        //     addleadvalidationerrors["client_note"] = "Note is required.";
        // }
        // if (client_contact_type == null || client_contact_type == "" || client_contact_type == 0) {
        //     isValid = false;
        //     addleadvalidationerrors["client_contact_type"] = "Contact Type is required.";
        // }

        this.setState({
            addleadvalidationerrors: addleadvalidationerrors
        });

        return isValid;
    }
    searchName = (e) => {
        this.setState({ client_data: "" })
        this.setState({ client_name: (e) ? e.target.value : "" })
        const data = {
            client_name: e.target.value
        }
        API.post("/fetch_client_name", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ client_data: response.data.client })
                }
            }).catch((e) => {
            })
    }
    searchPhone = (e) => {
        this.setState({ client_data: "" })
        this.setState({ client_phone: (e) ? e.target.value : "" })
        const data = {
            client_phone: e.target.value
        }
        API.post("/fetch_client_phone", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ client_data: response.data.client })
                }
            }).catch((e) => {
            })
    }
    clientEmail = (e) => {
        this.setState({ client_email: (e) ? e.target.value : "" })
    }
    clientRefno = (e) => {
        this.setState({ client_refno: (e) ? e.target.value : "" })
        const data = {
            client_refno: e.target.value
        }
        API.post("/fetch_client_agent", data)
            .then((response) => {
                if (response.data.success) {
                    if (response.data.agent != null && response.data.agent != "" && response.data.agent != undefined) {
                        this.setState({ client_agent: response.data.agent })
                    } else {
                        this.setState({ client_agent: "" })
                    }
                }
            }).catch((e) => {
            })
    }
    clientSource = (e) => {
        this.setState({ client_source: (e) ? e : "" })
    }
    clientAgent = (e) => {
        this.setState({ client_agent: (e) ? e : "" })
    }
    clientContactType = (e) => {
        this.setState({ client_contact_type: (e) ? e : "" })
    }
    clientNote = (e) => {
        this.setState({ client_note: (e) ? e.target.value : "" })
    }





    submitMergeLeads = () => {
        if (this.mergeLeadValidate()) {
            this.setState({ isMergeLoading: true });
            const parent_lead = this.state.parent_lead;
            const children_leads_data = this.state.selectedLeads.filter(lead => lead !== parent_lead);
            const children_leads = children_leads_data.map(item => item.value);
            const data = {
                parent_lead: parent_lead,
                children_leads: children_leads,
                parent_agent: this.state.parent_agent,
                parent_lead_stage: this.state.parent_lead_stage,
                oldParentLead: this.state.oldParentLead,
                maxParentLeadDate: this.state.maxParentLeadDate,
                maxLastCallDate: this.state.maxLastCallDate,
                maxLastActivityDate: this.state.maxLastActivityDate
            }


            API.post("/merge_leads", data).then((response) => {

                    this.setState({ isMergeLoading: false });
                    if (response.data.success) {
                        this.setState({ isMergeLeadsOpen: false });
                        toast.success("Leads has been merged successfully")

                        const page = 1;
                        const perPage = 10;
                        const all_data = store.getState((state) => state);
                        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
                        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
                        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
                        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

                        const data = {
                            columnKey: this.state.columnKey,
                            order: this.state.order,
                            listings_array: this.state.listings_array,
                            search_owner: this.state.search_owner,
                            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
                            clicked_book_viewing: this.state.clicked_book_viewing,
                            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
                            viewing_date_range: this.state.viewing_date_range,
                            viewing_outcome: this.state.viewing_outcome,
                            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
                            offer_status: this.state.offer_status,
                            deal_signed: this.state.deal_signed ? "YES" : "NO",
                            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
                            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
                            last_activity_date_value: this.state.last_activity_date_value,
                            last_activity_date_value_id: this.state.last_activity_date_value_id,

                            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
                            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
                            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
                            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

                            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
                            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
                            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
                            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
                            selectedTeam: this.state.selectedTeam,
                            selectedAgents: selectedAgents,
                            isMatchedLead: isMatchedLead == true ? 1 : 0,
                            isExpiringLead: isExpiringLead == true ? 1 : 0,
                            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
                            property_type_filter: this.state.property_type_filter,
                            community_filter: this.state.community_filter,
                            sub_community_filter: this.state.sub_community_filter,
                            property_filter: this.state.property_filter,

                            name_value_id: this.state.name_value,
                            name_value: this.state.name_value,
                            refno_value: this.state.refno_value,
                            refno_value_id: this.state.refno_value_id,

                            phone_value_id: this.state.phone_value_id,
                            phone_value: this.state.phone_value,

                            bed_rooms_value_id: this.state.bed_rooms_value_id,
                            bed_rooms_value: this.state.bed_rooms_value,
                            agent_value_id: this.state.agent_value_id,
                            agent_value: this.state.agent_value,

                            stage_value: this.state.stage_value,
                            verified_sub_stage_value: this.state.verified_sub_stage_value,
                            contact_types_value: this.state.contact_types_value,
                            furnished_types_value: this.state.furnished_types_value,


                            price_first_value_id: this.state.price_first_value_id,
                            price_first_value: this.state.price_first_value,
                            price_min: this.state.price_min,
                            price_max: this.state.price_max,



                            date_morethan_option: this.state.date_morethan_option,
                            date_lessthan_option: this.state.date_lessthan_option,

                            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                            date_value: this.state.date_value,
                            date_value_id: this.state.date_value_id,


                            viewing_value: this.state.viewing_value,
                            viewing_morethan_value: this.state.viewing_morethan_value,
                            viewing_lessthan_value: this.state.viewing_lessthan_value,
                            viewing_option_value: this.state.viewing_option_value,
                            inrange_viewing: this.state.inrange_viewing,
                            viewing_min_value: this.state.viewing_min_value,
                            viewing_max_value: this.state.viewing_max_value,


                            offer_value: this.state.offer_value,
                            offer_morthan_value: this.state.offer_morthan_value,
                            offer_lessthan_value: this.state.offer_lessthan_value,
                            offer_option_value: this.state.offer_option_value,
                            inrange_offer: this.state.inrange_offer,
                            offer_min_value: this.state.offer_min_value,
                            offer_max_value: this.state.offer_max_value,


                            last_call_morethan_option: this.state.last_call_morethan_option,
                            last_call_lessthan_option: this.state.last_call_lessthan_option,
                            last_call_value: this.state.last_call_value,
                            last_call_value_id: this.state.last_call_value_id,
                            last_call_option_value: this.state.last_call_option_value,


                            last_match_morethan_option: this.state.last_match_morethan_option,
                            last_match_lessthan_option: this.state.last_match_lessthan_option,
                            last_match_value: this.state.last_match_value,
                            last_match_value_id: this.state.last_match_value_id,
                            last_match_option_value: this.state.last_match_option_value,

                            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                            move_in_date_value: this.state.move_in_date_value,
                            move_in_date_value_id: this.state.move_in_date_value_id,
                            move_in_date_option_value: this.state.move_in_date_option_value,
                            move_in_date_date_range: this.state.move_in_date_date_range
                        }

                        this.fetchData(page, perPage, data);
                        this.setState({ selectedLeads: [] })
                    }
                }).catch((e) => {
                    this.setState({ isMergeLoading: false });
                    toast.error("Something Went Wrong")
                }).finally(() => {
                    this.setState({ isMergeLoading: false });
                })
        }
    }

    
    selectAllRows = () => {
        if (this.state.selectedRowKeys.length === this.state.leads.data.length) {
            // Deselect all
            this.setState({ selectedRowKeys: [] });
        } else {
            // Select all
            const allRowKeys = this.state.leads.data.map((lead) => lead.original_id);
            this.setState({ selectedRowKeys: allRowKeys });
        }
    };
      

    exportLeads = (e) => {
        // if( this.state.selectedRowKeys.length > 0) {
        console.log(this.state.selectedRowKeys)
        
         const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        
        const data = {
            selectedLeads:this.state.SelectedRows,
            columnKey: this.state.columnKey,
            order: this.state.order,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,
            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,
            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            property_type_filter: this.state.property_type_filter,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id:this.state.price_min==0 && this.state.price_max==0?1:7 ,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,
            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,
            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,
            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range
        
    }
        API.post("/export_csv", data).then((response) => {
             console.log(response);
             window.open(response.data.data);
         })
        // } else {
        //     toast.warning("Please select rows to export")
        // }

    }


    exportChosenLeads = (e) => {        
        if( this.state.selectedRowKeys.length > 0) {
        const data = { selected_array:this.state.SelectedRows}
        console.log(data)
        API.post("/export_selected_csv", data).then((response) => {
             console.log(response);
             window.open(response.data.data);
         })
        } else {
            toast.warning("Please select rows to export")
        }
    }


    handleMergeLeadsClose = () => {
        this.setState({ isMergeLeadsOpen: false });
    }

    handleMergeLeads = () => {
        if (this.state.selectedLeads.length <= 0) {
            toast.error("Nothing is selected");
        } else {
            this.setState({ isMergeLeadsOpen: true });
        }
    }

    handleVerificationCall = () => {
        if (this.state.SelectedRows.length < 1) {
            toast.warning('Select leads');
            return
        }

        const data = { SelectedRows: this.state.SelectedRows }
        
        API.post("/add_verification", data)
            .then((res) => {

                if (res.data.success) {
                    toast.success('Verification call added successfully');
                    this.setState({ SelectedRows: [] })
                }
            })

    }
    handleIfFinanceYes = () => {
        this.setState({ ifFinanceYes: true })
    }
    handleIfFinanceNo = () => {
        this.setState({ ifFinanceYes: false })
    }

    handleAddVerification = () => {
        const data = { leads_id: this.state.record_id }
        API.post("/add_verification_by_lead_id", data)
            .then((res) => {
                if (res.data.success) {
                    toast.success('Verification call added successfully');
                    const lead_details = this.state.lead_details
                    lead_details.verifier = "verifier"
                    this.setState({ lead_details: lead_details })
                }
            })
    }

    handleRemoveVerification = () => {
        const data = { leads_id: this.state.record_id }
        API.post("/remove_verification_by_lead_id", data)
            .then((res) => {
                if (res.data.success) {
                    toast.success('Verification call removed successfully');
                    const lead_details = this.state.lead_details
                    lead_details.verifier = null
                    this.setState({ lead_details: lead_details })
                }
            })
    }
    handleNumberOfCheques = (e) => {
        this.setState({ number_of_cheque: e })
    }
    handleNewToDubai = (e) => {
        this.setState({ new_to_dubai: e.target.value })
    }

    handleAvailabilityForViewings = (e) => {
        this.setState({ availability_for_viewing: e.target.value })
    }

    handlePreApproved = (e) => {
        this.setState({ pre_approved: e.target.value })
    }

    handlePayment = (e) => {
        this.setState({ payment: e.target.value })
        if (e.target.value == "Finance") {
            this.setState({ ifFinanceYes: true })
        } else {
            this.setState({ ifFinanceYes: false })
        }
    }
    handleBuyerType = (e) => {
        this.setState({ buyer_type: e.target.value })
    }

    saveNewBedroom = (value) => {
        this.setState({ new_bed: value, requirement_bedroom: value })
        const data = { leads_id: this.state.record_id, bedroom: value }
        API.post("/update_bedroom", data)
            .then((response) => {

                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes,
                        bedroom_match: value
                    })
                    this.fetchExactListings()
                    this.fetchCoparableListings()
                }
            })
        const page = 1;
        const perPage = 5;
        const data_fetch = {
            leads_id: this.state.record_id,
            bedroom: value,
            price: this.state.price_first,
            community: this.state.new_community,
            subcommunity: this.state.new_sub_community
        }
        this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);

    }
    saveNewCommunity = (value) => {

        this.setState({ requirement_community: value, new_community: value })
        const data = { leads_id: this.state.record_id, community: value }
        API.post("/update_community", data)
            .then((response) => {



                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                    this.fetchExactListings()
                    this.fetchCoparableListings()
                }
            })
        API.get("/sub_community/" + value)
            .then((response) => {
                if (response.data.success)
                    this.setState({ requirement_sub_communities: response.data.sub_communities })
            })
        const page = 1;
        const perPage = 5;
        const data_fetch = {
            leads_id: this.state.record_id,
            bedroom: this.state.new_bed,
            price: this.state.price_first,
            community: value,
            subcommunity: this.state.new_sub_community
        }
        this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);
    }
    saveNewSubCommunity = (value) => {

        this.setState({ requirement_sub_community: value, new_sub_community: value })
        const data = { leads_id: this.state.record_id, subcommunity: value }
        API.post("/update_subcommunity", data)
            .then((response) => {



                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
        const page = 1;
        const perPage = 5;
        const data_fetch = {
            leads_id: this.state.record_id,
            bedroom: this.state.new_bed,
            price: this.state.price_first,
            community: this.state.new_community,
            subcommunity: value
        }
        this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);
    }

    savePriceFirst = (value) => {
        const value_ = value
        const new_value = value_.replace(/,/g, "")


        this.setState({
            price_requirement: value, min_budget: new_value, price_first: value
        })
        if (new_value > 0) {
            const data = { leads_id: this.state.record_id, price_requirement: new_value }
            API.post("/update_price", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({
                            all: response.data.all,
                            notes: response.data.notes
                        })
                        this.fetchExactListings()
                        this.fetchCoparableListings()
                    }
                })
        }
        const page = 1;
        const perPage = 5;
        const data_fetch = {
            leads_id: this.state.record_id,
            bedroom: this.state.new_bed,
            price: value,
            community: this.state.new_community,
            subcommunity: this.state.new_sub_community
        }
        this.fetchMatchedVerificationCallLeadList(page, perPage, data_fetch);


    }
    savePriceSecond = (value) => {
        this.setState({ price_second: value })
    }

    saveNewMoveInDate = (value) => {
        this.setState({
            requirement_move_in_date: value, new_move_in_date: value
        })
        API.post("/update_move_in_date", { leads_id: this.state.record_id, move_in_date: value })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        all: response.data.all,
                        notes: response.data.notes
                    })
                }
            })
    }

    getFilteredLeads = (lead_stage) => {
        this.setState({ stage_value: lead_stage, default_stage_value: lead_stage })
        this.setState({ filtered_lead_stage: lead_stage })

        const page = 1;
        const perPage = 10;
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: lead_stage,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range

        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data)
    }

    handleviewChange = (stat) => {
        if (stat == "Viewings") {
            this.setState({ isOfferTableShow: false, isViewingTableShow: true, isViewingTabShow: true, isOfferTabShow: false })
            const lead_stage = [8, 16, 17, 18];
            this.getFilteredLeads(lead_stage);

        } else if (stat == "Offers") {
            this.setState({ isOfferTableShow: false, isViewingTabShow: false, isViewingTableShow: true, isOfferTabShow: true })
            const lead_stage = [6, 13, 14];
            this.getFilteredLeads(lead_stage);
        } else {
            this.setState({ isOfferTableShow: false, isViewingTableShow: false, isOfferTabShow: false, isViewingTabShow: false })
            this.getFilteredLeads([]);
        }
    }

    handleMatchedLeadsAndViewingMorethan = (e) => {
        this.setState({ matched_leads_and_viewings_morethan_option: true, matched_leads_viewing_value_show: true, matched_leads_and_no_viewings_option: false })
    }

    handleMatchedLeadsAndNoViewing = (e) => {
        this.setState({ matched_leads_and_viewings_morethan_option: false, matched_leads_viewing_value_show: false, matched_leads_and_no_viewings_option: false })
    }



    handleClearAgent = (e) => {
        const agent_val = this.state.agent_value
        const valuesToRemove = []
        valuesToRemove.push(e)
        const filteredArray = agent_val.filter(item => !valuesToRemove.includes(item));

        this.setState({ agent_value: filteredArray })

        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: filteredArray,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);



    }

    handleClearPropertyType = (e) => {

        const property_type_filter = this.state.property_type_filter
        const valuesToRemove = []
        valuesToRemove.push(e)
        const filteredArray = property_type_filter.filter(item => !valuesToRemove.includes(item));

        this.setState({ property_type_filter: filteredArray })



        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: filteredArray,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);



    }

    handleClearContactType = (e) => {
        const contact_type_val = this.state.contact_types_value
        const valuesToRemove = []
        valuesToRemove.push(e)
        const filteredArray = contact_type_val.filter(item => !valuesToRemove.includes(item));


        this.setState({ contact_types_value: filteredArray })


        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: filteredArray,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleClearCommunity = () => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ community_filter: "", community_value_id: 0 })


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: "",
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }
    handleClearSubCommunity = () => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ sub_community_filter: "", sub_community_value_id: 0 })


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: "",
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleClearProperty = () => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ property_filter: "" })


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: "",
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleClearPrice = () => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ price_first_value_id: 1, price_first_value: "", price_min: 0, price_max: 0, inrange: false })


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: 1,
            price_first_value: "",
            price_min: 0,
            price_max: 0,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleClearBedroom = () => {
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        this.setState({ bed_rooms_value_id: 1, bed_rooms_value: "" })


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: 1,
            bed_rooms_value: "",
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    }

    handleClearFurnished = (e) => {


        const furnished_types_value = this.state.furnished_types_value
        const valuesToRemove = []
        valuesToRemove.push(e)
        const filteredArray = furnished_types_value.filter(item => !valuesToRemove.includes(item));


        this.setState({ furnished_types_value: filteredArray })


        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents


        // this.fetchGroup()
        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: this.state.last_activity_date_value,
            last_activity_date_value_id: this.state.last_activity_date_value_id,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: this.state.stage_value,
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: filteredArray,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);

    }



    handleToggleViewGallery = () => { this.setState({ isViewAsGallery: true }) }
    handleToggleViewTable = () => { this.setState({ isViewAsGallery: false }) }

    // Mobile Handlers
    handleStatusFiltOpen=()=> { this.setState({ statusfilt: true }) }
    handleStatusFiltClose=()=> { this.setState({ statusfilt: false }) }
    handleVerificationFiltOpen=()=> { this.setState({ verificationfilt: true }) }
    handleVerificationFiltClose=()=> { this.setState({ verificationfilt: false }) }

    handleResetStatus=()=>{
        this.setState({ stage_value:[] }) 
        const all_data = store.getState((state) => state);
        const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
        const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
        const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
        const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

        const page = 1;
        const perPage = 10;
        const data = {
            nested_stage_value: this.state.nested_stage_value,
            listings_array: this.state.listings_array,
            search_owner: this.state.search_owner,
            replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
            clicked_book_viewing: this.state.clicked_book_viewing,
            viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
            viewing_date_range: this.state.viewing_date_range,
            viewing_outcome: this.state.viewing_outcome,
            number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
            offer_status: this.state.offer_status,
            deal_signed: this.state.deal_signed ? "YES" : "NO",
            last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
            last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
            last_activity_date_value: "",
            last_activity_date_value_id: 1,

            last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
            last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
            last_whatsapp_date_value: this.state.last_whatsapp_date_value,
            last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

            last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
            last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
            last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
            last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
            selectedTeam: this.state.selectedTeam,
            selectedAgents: selectedAgents,
            isMatchedLead: isMatchedLead == true ? 1 : 0,
            isExpiringLead: isExpiringLead == true ? 1 : 0,
            isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
            community_filter: this.state.community_filter,
            sub_community_filter: this.state.sub_community_filter,
            property_filter: this.state.property_filter,
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
            refno_value: this.state.refno_value,
            refno_value_id: this.state.refno_value_id,
            bed_rooms_value_id: this.state.bed_rooms_value_id,
            bed_rooms_value: this.state.bed_rooms_value,
            agent_value_id: this.state.agent_value_id,
            agent_value: this.state.agent_value,
            stage_value: [],
            verified_sub_stage_value: this.state.verified_sub_stage_value,
            contact_types_value: this.state.contact_types_value,
            furnished_types_value: this.state.furnished_types_value,
            price_first_value_id: this.state.price_first_value_id,
            price_first_value: this.state.price_first_value,
            price_min: this.state.price_min,
            price_max: this.state.price_max,
            date_morethan_option: this.state.date_morethan_option,
            date_lessthan_option: this.state.date_lessthan_option,
            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            date_value: this.state.date_value,
            date_value_id: this.state.date_value_id,
            property_type_filter: this.state.property_type_filter,

            viewing_value: this.state.viewing_value,
            viewing_morethan_value: this.state.viewing_morethan_value,
            viewing_lessthan_value: this.state.viewing_lessthan_value,
            viewing_option_value: this.state.viewing_option_value,
            inrange_viewing: this.state.inrange_viewing,
            viewing_min_value: this.state.viewing_min_value,
            viewing_max_value: this.state.viewing_max_value,


            offer_value: this.state.offer_value,
            offer_morthan_value: this.state.offer_morthan_value,
            offer_lessthan_value: this.state.offer_lessthan_value,
            offer_option_value: this.state.offer_option_value,
            inrange_offer: this.state.inrange_offer,
            offer_min_value: this.state.offer_min_value,
            offer_max_value: this.state.offer_max_value,


            last_call_morethan_option: this.state.last_call_morethan_option,
            last_call_lessthan_option: this.state.last_call_lessthan_option,
            last_call_value: this.state.last_call_value,
            last_call_value_id: this.state.last_call_value_id,
            last_call_option_value: this.state.last_call_option_value,


            last_match_morethan_option: this.state.last_match_morethan_option,
            last_match_lessthan_option: this.state.last_match_lessthan_option,
            last_match_value: this.state.last_match_value,
            last_match_value_id: this.state.last_match_value_id,
            last_match_option_value: this.state.last_match_option_value,

            move_in_date_morethan_option: this.state.move_in_date_morethan_option,
            move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
            move_in_date_value: this.state.move_in_date_value,
            move_in_date_value_id: this.state.move_in_date_value_id,
            move_in_date_option_value: this.state.move_in_date_option_value,
            move_in_date_date_range: this.state.move_in_date_date_range


        }
        this.fetchTotalStatus(data)
        this.fetchData(page, perPage, data);
    
    }


    handlePropTypeOpen=()=> {this.setState({ proptypemodal:true })}
    handlePropTypeClose=()=> {this.setState({ proptypemodal:false })}


    rowSelection = () => ({
        selectedRowKeys: this.state.selectedRowKeys,
        onChange: this.onSelectChange,  // Ensure the correct context is bound
    });
    




    render() {
        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let role_name = userdata.auth.user.role_name;
        let role_profile = userdata.auth.user.profile_photo;
        const user_id = userdata.auth.user.id
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};
        const all_values = this.state.stage_value

        const offer_sent = this.state.offer
        const offer_accepted = this.state.offer_accepted

        const offer_rejected = this.state.offer_rejected
        const all_offer = offer_sent + offer_accepted + offer_rejected

        const viewing_data = this.state.viewing
        const second_viewing = this.state.second_viewing
        const consider = this.state.consider
        const not_interest = this.state.not_interest

        const all_viewings = viewing_data + second_viewing + consider + not_interest

        const isUserFound = this.state.collaborators.some(element => {
            if (element.user_id === user_id) {
                return true
            } else {
                return false
            }
        });
        const rowSelectionForClient = {
            onChange: (selectedRowKeys, selectedRows) => {
                let fullname = ((selectedRows[0]['firstname']) ? selectedRows[0]['firstname'] : "") + " " + ((selectedRows[0]['lastname']) ? selectedRows[0]['lastname'] : "");

                let contact_type = selectedRows[0]['contact_type'];
                this.setState({ client_name: fullname })
                this.setState({ client_phone: selectedRows[0]['mobile'] })
                this.setState({ client_contact_type: contact_type.toString() })
            },
        };

        const onSelectChange = (newSelectedRowKeys) => {        
            this.setState({ SelectedRows: newSelectedRowKeys });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
            const selectedLeadsData = this.state.leads.data.filter(item => newSelectedRowKeys.includes(item.original_id));
            let oldParentLead = null
            let maxParentLeadDate = null
            let maxLastCallDate = null
            let maxLastActivityDate = null

            for (const item of selectedLeadsData) {
                const parentLeadDate = new Date(item.parent_lead_date);
                const lastCallDate = item.last_call != null && item.last_call != "0000-00-00 00:00:00" ? new Date(item.last_call) : null;
                const lastActivityDate = item.last_activity != null && item.last_activity != "0000-00-00 00:00:00" ? new Date(item.last_activity) : null;
                const lastParentLead = item.parent_lead;


                if (!oldParentLead || lastParentLead < oldParentLead) {
                    oldParentLead = lastParentLead;
                }

                if (!maxParentLeadDate || parentLeadDate > maxParentLeadDate) {
                    maxParentLeadDate = parentLeadDate;
                }

                if (!maxLastCallDate || lastCallDate > maxLastCallDate) {
                    maxLastCallDate = lastCallDate;
                }
                if (!maxLastActivityDate || lastActivityDate > maxLastActivityDate) {
                    maxLastActivityDate = lastActivityDate;
                }

            }
            // If you want to format the maxDate back to a string
            const formattedMaxParentLeadDate = maxParentLeadDate ? maxParentLeadDate.toISOString().slice(0, 19).replace("T", " ") : null;
            const formattedMaxLastCallDate = maxLastCallDate ? maxLastCallDate.toISOString().slice(0, 19).replace("T", " ") : null;
            const formattedMaxLastActivityDate = maxLastActivityDate ? maxLastActivityDate.toISOString().slice(0, 19).replace("T", " ") : null;

            // return selectedLeadsData
            const selectOptions = selectedLeadsData.map(item => ({
                label: item.fullname,
                value: item.original_id,
            }));
            const selectedStages = selectedLeadsData.map(item => {
                const foundStage = this.state.stages.find(stage => stage.value == item.lead_stage);
                return foundStage
            });

            const allAgents = selectedLeadsData.map(record => {
                const foundAgent = this.state.agents.find(agent => agent.value == record.agents);
                if (foundAgent) {
                    return foundAgent;
                } else {
                    // If agent is not found, create a new agent object
                    return { value: 430, label: "pond" };
                }
            });
            this.setState({
                selectedLeads: selectOptions, selectedStages: selectedStages, allAgents: allAgents, oldParentLead: oldParentLead,
                maxParentLeadDate: formattedMaxParentLeadDate, maxLastCallDate: formattedMaxLastCallDate, maxLastActivityDate: formattedMaxLastActivityDate
            })
        };

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: onSelectChange,
        };

        const columns = [
            {
                title: 'Name', className: 'namecolwrap',
                width: 180, fixed: 'left', style: { zIndex: '9' },
                //dataIndex: 'fullname',
                render: (text, record) => (
                    <>
                        <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}>
                            <div className='contactinfo'>

                                <div className='cgroup'>
                                    <p className='cname'>{record.fullname == " " || record.fullname == "" || record.fullname == null ? <SearchOutlined /> : record.fullname}</p>
                                    <p className='ctype'>
                                        {record.contact_type == 1 ? "Tenant"
                                            : record.contact_type == 2 ? "Buyer"
                                                : record.contact_type == 3 ? "Landlord"
                                                    : record.contact_type == 4 ? "Seller"
                                                        : record.contact_type == 6 ? "Agent"
                                                            : record.contact_type == 7 ? "Other"
                                                                : record.contact_type == 8 ? "Portal"
                                                                    : record.contact_type == 9 ? "Buyer/Tenant"
                                                                        : record.contact_type == 12 ? "Candidate"
                                                                        : "Unrecognized"}
                                    </p>
                                    <p>{record.mobile ? record.mobile : null}</p>
                                </div>
                            </div>
                        </Link>
                    </>
                ),
            },
            {
                title: 'Lead Stage', className: 'lead_stage', width: 180,
                render: (text, record) => {
                    let agent = (this.state.agents) ? this.state.agents.find(items => items.value === record.agents) : record.agents;
                    const agentName = (agent && agent.label) ? agent.label : agent
                    return (<>
                        <div className='listinginfowrap listingagentwrap' style={{ height: 0 }}>
                            <p className='cstatus' style={{ minWidth: '85px', width: 'fit-content' }}>
                                {record.agents == 430 && record.lead_stage == 12 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Pond</span> : <>
                                    {record.lead_stage == 1 ? <span style={{ width: '100%' }} className="uncontacted pstat status-styling">New Inquiry</span> : null}
                                    {record.lead_stage == 2 ? <span style={{ width: '100%' }} className="working pstat status-styling">Working</span> : null}
                                    {record.lead_stage == 10 ? <span style={{ width: '100%' }} className="noanswer pstat status-styling">No Answer</span> : null}
                                    {record.lead_stage == 11 ? <span style={{ width: '100%' }} className="expiry pstat status-styling">Expired</span> : null}
                                    {record.lead_stage == 3 ? <span style={{ width: '100%' }} className="hot pstat status-styling">Hot</span> : null}
                                    {record.lead_stage == 4 ? <span style={{ width: '100%' }} className="followup pstat status-styling">Follow Up</span> : null}
                                    {record.lead_stage == 5 ? <span style={{ width: '100%' }} className="dead pstat status-styling">Dead</span> : null}
                                    {record.lead_stage == 6 ? <span style={{ width: '100%' }} className="offer pstat status-styling">Offer</span> : null}
                                    {record.lead_stage == 7 ? <span style={{ width: '100%' }} className="dealsigned pstat status-styling">Deal signed</span> : null}
                                    {record.lead_stage == 8 ? <span style={{ width: '100%' }} className="viewings pstat status-styling">Viewings</span> : null}
                                    {record.lead_stage == 9 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Recruitment</span> : null}
                                    {record.lead_stage == 13 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Offer accepted</span> : null}
                                    {record.lead_stage == 14 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Offer declined</span> : null}
                                    {record.lead_stage == 28 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Past Client</span> : null}
                                </>}
                            </p>
                            <div className='cgroup'>
                                {record.wa_confirmed == 1 ?
                                    <p className='vstat whatsapp d-flex flex-row mr-2' style={{ width: '85px' }}><i style={{ width: '100%' }} class="bi bi-check-all"></i>WhatsApp</p> : null}
                                {record.verified == 1 ?
                                    <p className='vstat verified d-flex flex-row' style={{ width: '85px' }}><i style={{ width: '100%' }} class="bi bi-check-all"></i>Verified</p> : null}
                            </div>
                        </div>
                    </>)
                }
            },
            {
                title: 'Agent', className: 'agent', width: 180,
                render: (text, record) => {
                    let agent = this.state.agents ? this.state.agents.find(items => items.value.toString() === record.agents.toString()) : record.agents;
                    const agentName = agent && agent.label ? agent.label : agent;
                    return (<>
                        <div className='listinginfowrap listingagentwrap' style={{ height: 0 }}>
                            <div className='info-group'>
                                <p className='info-title'>Agent:</p>
                                <div className='agentwrap d-flex flex-row align-items-center'>

                                    <p className='infovalue'>
                                        {agentName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>)
                }
            },
            {
                title: 'Created Date', className: 'created', width: 150,
                render: (text, record) => (
                    // table data
                    <>
                        <div className='listinginfowrap' style={{ height: 0 }}>
                            <div className='info-group d-flex flex-col  '>
                                <p className='info-title'>Inquiry Date:</p>
                                <Moment className='infovalue' format={'DD MMMM, YYYY'}>{(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}</Moment>
                                <Moment className='infovalue' format={'HH:mm:ss'}>{(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}</Moment>
                            </div>

                        </div>
                    </>
                )
            },
            {
                title: 'Requirements', className: '', width: 350,
                render: (text, record) => {
                    let com = "", subcom = "", prop = ""
                    if (record.community != null && record.community != "" && record.community != undefined) {
                        let community = (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                        (community && community.label) ? com = community.label : com = community
                    }
                    if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined) {
                        let subcommunity = (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                        (subcommunity && subcommunity.label) ? subcom = subcommunity.label : subcom = subcommunity;
                    }
                    if (record.property != null && record.property != "" && record.property != undefined) {
                        let property = (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                        (property && property.label) ? prop = property.label : prop = property;
                    }
                    const price = record.lead_price
                    let ptype = ""
                    if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined) {
                        let prop_type = (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                        (prop_type && prop_type.label) ? ptype = prop_type.label : ptype = prop_type;
                    }
                    return (<>
                        <div className='listinginfowrap' style={{ height: 0 }}>
                            <div className='info-group'>
                                <p className='info-title'>Requirements:</p>
                                {com ?
                                    <p className='infovalue' > {(subcom ? subcom + ", " : "") + (com ? com : "")}
                                    </p>
                                    : <p className='infovalue'>-</p>}
                            </div>

                        </div>
                    </>)
                }
            },
            {
                title: 'Requirements', className: '', width: 300,
                render: (text, record) => {
                    let com = "", subcom = "", prop = ""
                    if (record.community != null && record.community != "" && record.community != undefined) {
                        let community = (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                        (community && community.label) ? com = community.label : com = community
                    }
                    if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined) {
                        let subcommunity = (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                        (subcommunity && subcommunity.label) ? subcom = subcommunity.label : subcom = subcommunity;
                    }
                    if (record.property != null && record.property != "" && record.property != undefined) {
                        let property = (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                        (property && property.label) ? prop = property.label : prop = property;
                    }
                    const price = record.lead_price
                    let ptype = ""
                    if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined) {
                        let prop_type = (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                        (prop_type && prop_type.label) ? ptype = prop_type.label : ptype = prop_type;
                    }
                    return (<>
                        <div className='' style={{ height: 0 }}>

                            <div className='d-flex flex-row w-100' style={{ gap: '3rem' }}>
                                <div className='info-group' style={{ width: '50%' }}>
                                    <p className='info-title'>Price:</p>
                                    <p className='infovalue'>
                                        {!isNaN(parseInt(price)) ?
                                            price > 0 && price != null ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                                : "-"
                                            : "-"}
                                    </p>
                                </div>
                                <div className='info-group'>
                                    <p className='info-title'>Type:</p>
                                    <p className='infovalue'>
                                        {ptype ? ptype : "-"}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className='' style={{ height: 0 }}>
                                    <div className='info-group'>
                                        <p className='info-title'>Bed:</p>
                                        <p className='infovalue'>{record.bedroom}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)
                }
            },
            {
                title: 'ViewOffers', width: 100,
                render: (text, record) => (<>
                    <div className='info-group' style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: 15 }}>
                            <p className='info-title'>Viewings:</p>
                            <p className='infovalue'>
                                {(record.viewings != null) ? record.viewings : 0}
                            </p>
                        </div>

                        <div>
                            <p className='info-title'>Offers:</p>
                            <p className='infovalue'>
                                {(record.offers != null) ? record.offers : 0}
                            </p>
                        </div>
                    </div>
                </>)
            },
            {
                title: 'Preview', width: 100, className: "actionwrap",
                render: (text, record) => (
                    <>
                        <div className="info-group d-flex flex-column align-items-center" style={{ height: '80px',  }}>
                            {/* <p className='info-title'><i class="bi bi-three-dots"></i></p> */}
                            <Tooltip placement="topRight" title={"Edit this lead"}><a className='btn btn-text px-0 align-items-start d-flex mb-2 gap-1 '><EditOutlined style={{marginTop:2}} /> <span className='font-12'>Edit lead</span></a></Tooltip>
                            {/* <Tooltip placement="topRight" title={"Verified this listing"}><a className='btn btn-text px-0 align-items-center d-flex'><i class="bi bi-check-all font-14"></i></a></Tooltip> */}
                            <Tooltip placement="topRight" title={"Add Note"}><a className='btn btn-text px-0 align-items-start d-flex gap-1'><i class="bi bi-plus" style={{marginTop:'-2px'}}></i><span className='font-12'>Add Note</span></a></Tooltip>
                        </div>
                    </>
                ),
            },
            // if Accept/Decline will show
            {
                title: 'Accept Decline', width: 30, className: "acceptDeclineWrap",
                render: (text, record) => (
                    <>
                        <div className="info-group d-flex flex-row align-items-center" >
                            <Button className='btnAccept' onClick={this.onClickAccept.bind(this, record)}>Accept</Button>
                            <Button className='btnDecline' onClick={this.onClickReject.bind(this, record)}>Reject</Button>
                            {role_name == "Admin" || role_name == "Super Admin" &&
                                <Select style={{ minWidth: '200px', height: 36, borderRadius: 0 }}
                                    showSearch className='selectAgentwrap'
                                    value={this.state.assigned_agent ? this.state.assigned_agent : 'Reassign to'}
                                    allowClear
                                    placeholder="Select Assigned Agent"
                                    maxTagCount='responsive'
                                    onChange={this.onChangeAssignAgent.bind(this, record.original_id)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={
                                        this.state.allAgents && this.state.allAgents.length > 0 ? this.state.allAgents.map(item => ({
                                            label: item.label,
                                            value: item.value,
                                        })) :
                                            this.state.agents
                                    }
                                />
                            }
                        </div>
                    </>
                ),

            }


        ];

        // console.log(this.state.leads, 'byHassan')
        const LeadsColumns = [
            {
                title: 'Name', className: 'namecolwrap',
                // width: 150, fixed: 'left', 
                style: { zIndex: '9' },
                render: (text, record) => {
                    let com = "", subcom = "", prop = ""
                    if (record.community != null && record.community != "" && record.community != undefined) {
                        let community = (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                        (community && community.label) ? com = community.label : com = community
                    }
                    if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined) {
                        let subcommunity = (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                        (subcommunity && subcommunity.label) ? subcom = subcommunity.label : subcom = subcommunity;
                    }
                    if (record.property != null && record.property != "" && record.property != undefined) {
                        let property = (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                        (property && property.label) ? prop = property.label : prop = property;
                    }
                    const price = record.lead_price
                    let ptype = ""
                    if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined) {
                        let prop_type = (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                        (prop_type && prop_type.label) ? ptype = prop_type.label : ptype = prop_type;
                    }

                    let agent = (this.state.agents) ? this.state.agents.find(items => items.value === record.agents) : record.agents;
                    const agentName = (agent && agent.label) ? agent.label : agent
                    let agent1 = this.state.agents ? this.state.agents.find(items => items.value.toString() === record.agents.toString()) : record.agents;
                    const agentName1 = agent1 && agent1.label ? agent1.label : agent1;
                    return (<>
                        <div className='row customCardDesign'>
                            <div className='col-12'>
                                <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}>
                                    <div className='flexBaseBtween'>
                                        <div>
                                            <div className='contactinfo'>

                                                <div className='cgroup'>
                                                    <p className='cname'>{record.fullname == " " || record.fullname == "" || record.fullname == null ? <SearchOutlined /> : record.fullname}</p>
                                                    <p className='ctype'>
                                                        {record.contact_type == 1 ? "Tenant"
                                                            : record.contact_type == 2 ? "Buyer"
                                                                : record.contact_type == 3 ? "Landlord"
                                                                    : record.contact_type == 4 ? "Seller"
                                                                        : record.contact_type == 6 ? "Agent"
                                                                            : record.contact_type == 7 ? "Other"
                                                                                : record.contact_type == 8 ? "Portal"
                                                                                    : record.contact_type == 9 ? "Buyer/Tenant"
                                                                                        :record.contact_type == 12 ? "Candidate"
                                                                                        : "Unrecognized"}
                                                    </p>
                                                    <p>{record.mobile ? record.mobile : null}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div >
                                            <div className='listinginfowrap width-110' >
                                                <div className='info-group d-flex flex-col  '>
                                                    <div className='info-group d-flex gap-1'>
                                                        <CalendarOutlined />
                                                        <Moment className='infovalue' format={'DD MMMM, YYYY'}>{(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}</Moment>
                                                    </div>
                                                    <div className='info-group d-flex gap-1'>
                                                        <ClockCircleOutlined />
                                                        <Moment className='infovalue' format={'HH:mm:ss'}>{(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}</Moment>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flexCenterBtween'>
                                        <div>
                                            <div className='listinginfowrap'>
                                                <div className='info-group'>
                                                    <div className='info-group d-flex gap-1'>
                                                    {com ? '' : <p className='info-title'>Requirements:</p>}
                                                        {com ?
                                                            <p className='infovalue' > {(subcom ? subcom + ", " : "") + (com ? com : "")}
                                                            </p>
                                                            : <p className='infovalue'>-</p>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div >
                                            <div className='listinginfowrap width-110' >
                                                <div className='info-group'>
                                                    <p className='infovalue'>
                                                        <div className='info-group d-flex gap-1'>
                                                            <DollarOutlined />
                                                            {!isNaN(parseInt(price)) ?
                                                                price > 0 && price != null ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                                                                    : "-"
                                                                : "-"}
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flexCenterBtween'>
                                        <div>
                                            <div className='listinginfowrap'>
                                                <div className='info-group'>
                                                    <div className='info-group d-flex gap-1'>
                                                        <p className='info-title'>Type:</p>
                                                        <p className='infovalue'>
                                                            {ptype ? ptype : "-"}
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div >

                                            <div className='listinginfowrap width-110'>
                                                <div className='info-group'>
                                                    <div className='info-group d-flex gap-1'>
                                                        <p className='info-title'>Bed:</p>
                                                        <p className='infovalue'>{record.bedroom}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='flexCenterBtween'>
                                        <div>
                                            <div className='listingagentwrap'>
                                                <p className='cstatus' style={{ minWidth: '85px', width: 'fit-content' }}>
                                                    {record.agents == 430 && record.lead_stage == 12 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Pond</span> : <>
                                                        {record.lead_stage == 1 ? <span style={{ width: '100%' }} className="uncontacted pstat status-styling">New Inquiry</span> : null}
                                                        {record.lead_stage == 2 ? <span style={{ width: '100%' }} className="working pstat status-styling">Working</span> : null}
                                                        {record.lead_stage == 10 ? <span style={{ width: '100%' }} className="noanswer pstat status-styling">No Answer</span> : null}
                                                        {record.lead_stage == 11 ? <span style={{ width: '100%' }} className="expiry pstat status-styling">Expired</span> : null}
                                                        {record.lead_stage == 3 ? <span style={{ width: '100%' }} className="hot pstat status-styling">Hot</span> : null}
                                                        {record.lead_stage == 4 ? <span style={{ width: '100%' }} className="followup pstat status-styling">Follow Up</span> : null}
                                                        {record.lead_stage == 5 ? <span style={{ width: '100%' }} className="dead pstat status-styling">Dead</span> : null}
                                                        {record.lead_stage == 6 ? <span style={{ width: '100%' }} className="offer pstat status-styling">Offer</span> : null}
                                                        {record.lead_stage == 7 ? <span style={{ width: '100%' }} className="dealsigned pstat status-styling">Deal signed</span> : null}
                                                        {record.lead_stage == 8 ? <span style={{ width: '100%' }} className="viewings pstat status-styling">Viewings</span> : null}
                                                        {record.lead_stage == 9 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Recruitment</span> : null}
                                                        {record.lead_stage == 13 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Offer accepted</span> : null}
                                                        {record.lead_stage == 14 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Offer declined</span> : null}
                                                        {record.lead_stage == 28 ? <span style={{ width: '100%' }} className="recruitment pstat status-styling">Past Client</span> : null}
                                                    </>}
                                                </p>
                                                <div className='cgroup'>
                                                    {record.wa_confirmed == 1 ?
                                                        <p className='vstat whatsapp d-flex flex-row mr-2' style={{ width: '85px' }}><i style={{ width: '100%' }} class="bi bi-check-all"></i>WhatsApp</p> : null}
                                                    {record.verified == 1 ?
                                                        <p className='vstat verified d-flex flex-row' style={{ width: '85px' }}><i style={{ width: '100%' }} class="bi bi-check-all"></i>Verified</p> : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div>


                                            <div className='listinginfowrap listingagentwrap width-110'>
                                                <div className='info-group'>
                                                    {/* <p className='info-title'>Agent:</p> */}
                                                    <div className='agentwrap d-flex flex-row align-items-center'>
                                                        <Avatar shape="square" style={{ borderRadius: '10px !important',width: "40px", height: "40px", minWidth: '40px', minHeight: '40px',maxWidth: '60px',  }} size={40} icon={<img style={{ borderRadius: '10px', boxShadow: '1px 1px 10px lightgray', width: "40px", height: "40px", minWidth: '40px', minHeight: '40px',maxWidth: '60px', borderRadius: '10px !important' }} src={record.profile_photo == null || record.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(record.profile_photo) ? record.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo} />} />
                                                        <p className='infovalue'>
                                                            {agentName1}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>


                        </div>
                        {/* <div className='listinginfowrap' style={{ height: 0 }}>
                            <div className='info-group'>
                                <p className='info-title'>Requirements11:</p>
                                {com ?
                                    <p className='infovalue' > {(subcom ? subcom + ", " : "") + (com ? com : "")}
                                    </p>
                                    : <p className='infovalue'>-</p>}
                            </div>

                        </div> */}
                    </>)
                }
            },
        ];


        const table_columns = [



            {
                title: 'Name', className: 'namecolwrap',
                width: 230, fixed: 'left', style: { zIndex: '9' },
                //dataIndex: 'fullname',
                render: (text, record) => (
                    <>
                        {record.pinned == 1 || record.pinned == 2 ? <i class="bi bi-pin-angle-fill mr-1"></i> : null}
                        {record.fullname == " " || record.fullname == "" || record.fullname == null ?
                            <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}><SearchOutlined /></Link>
                            // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link>
                            :
                            <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}>{record.fullname}</Link>
                        }</>
                    // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
                ),
                sorter: (a, b) => a.fullname.length - b.fullname.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Name
                        <hr />
                        <Select
                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"

                            onChange={this.handleNameValue_id}
                            options={this.state.name_filter_options}
                        />
                        <div className='mt-2'>
                            <input type="text" className="form-control" value={this.state.name_value} onChange={this.handleName} placeholder="filter..." />
                        </div>




                    </div>
                ),
            },
            {
                title: 'Lead Stage', width: 160,
                // dataIndex: 'lead_stage',
                render: (text, record) => (
                    <>
                        {record.agents == 430 && record.lead_stage == 12 ? <span style={{ width: '100%' }} className="recruitment pstat">Pond</span> : <>
                            {record.lead_stage == 1 ? <span className="uncontacted pstat">New Inquiry</span> : null}
                            {record.lead_stage == 2 ? <span className="working pstat">Working</span> : null}
                            {record.lead_stage == 10 ? <span className="noanswer pstat">No Answer</span> : null}
                            {record.lead_stage == 11 ? <span className="expiry pstat">Expired</span> : null}
                            {record.lead_stage == 3 ? <span className="hot pstat">Hot</span> : null}
                            {record.lead_stage == 4 ? <span className="followup pstat">Follow Up</span> : null}
                            {record.lead_stage == 5 ? <span className="dead pstat">Dead</span> : null}
                            {record.lead_stage == 6 ? <span className="offer pstat">Offer</span> : null}
                            {record.lead_stage == 7 ? <span className="dealsigned pstat">Deal signed</span> : null}
                            {record.lead_stage == 8 ? <span className="viewings pstat">Viewings</span> : null}
                            {record.lead_stage == 9 ? <span className="recruitment pstat">Recruitment</span> : null}
                            {record.lead_stage == 12 ? <span className="recruitment pstat">Candidate</span> : null}
                            {record.lead_stage == 13 ? <span className="recruitment pstat">Offer accepted</span> : null}
                            {record.lead_stage == 14 ? <span className="recruitment pstat">Offer declined</span> : null}
                        </>}

                    </>
                ),
                sorter: (a, b) => {
                    if (a && a.lead_stage && a.lead_stage.length && b && b.lead_stage && b.lead_stage.length) {
                        return a.lead_stage.length - b.lead_stage.length;
                    } else if (a && a.lead_stage && a.lead_stage.length) {
                        return -1;
                    } else if (b && b.lead_stage && b.lead_stage.length) {
                        return 1;
                    }
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Stages
                        <hr />

                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            value={this.state.stage_value}
                            placeholder="Please select"
                            onChange={this.handleStages2}
                            options={this.state.stages}
                        />


                    </div>
                ),

            },

            {
                title: 'Agent', width: 200, minWidth: '200px',
                dataIndex: 'agents',
                render: (text, record) => {
                    let agent = (this.state.agents) ? this.state.agents.find(items => items.value === record.agents) : record.agents;
                    return (agent && agent.label) ? agent.label : agent;
                },

                sorter: (a, b) => a.agents.length - b.agents.length,

                filterDropdown: (
                    <div className='form-group'
                        style={{
                            borderRadius: '0.3rem',
                            background: '#fff',
                            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                        }}>
                        <label>Agent</label>
                        {/* <Select
              style={{
                width: '100%',
              }}
              showSearch
              allowClear
              placeholder="Please select"
              onChange={this.handleAgent}
              options={this.state.agent_filter_options}
            /> */}
                        <div className='mt-2'>
                            {/* <input type="text" value={this.state.agent_value} className="form-control" onChange={this.handleAgent} placeholder="filter..." /> */}

                            <Select
                                showSearch
                                mode={'multiple'}
                                value={this.state.agent_value}
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Search"
                                onChange={this.handleAgent}

                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.agents}
                            />

                        </div>
                    </div>
                ),
            },

            {
                title: 'Phone', width: 140,
                dataIndex: 'mobile',

                sorter: (a, b) => a.mobile.length - b.mobile.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Phone number
                        <hr />
                        <Select
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select"
                            onChange={this.handlePhoneValue_id}
                            options={this.state.phone_filter_options}
                        />

                        <div className='mt-2'>
                            <input type="text" value={this.state.phone_value} className="form-control" onChange={this.handlePhone} placeholder="filter..." />
                        </div>




                    </div>
                ),
            },

            {
                title: 'Created', width: 160,
                // dataIndex: 'parent_lead_date',
                key: "parent_lead_date",
                sorter: (a, b) => a.parent_lead_date - b.parent_lead_date,
                sortOrder: sortedInfo.columnKey === 'parent_lead_date' && sortedInfo.order,
                sortDirections: ['ascend', 'descend', 'ascend'],
                render: (text, record) => (
                    <Moment fromNow>
                        {(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}
                    </Moment>
                    // <Moment fromNow>
                    // <>
                    // {record.parent_lead_date}
                    //     {validator.isDate(record.parent_lead_date) ?
                    //     <Moment fromNow>
                    //       {record.parent_lead_date}
                    //     </Moment> : ""}
                    // </>
                    //   {record.parent_lead_date}
                    // </Moment>

                ),
                sorter: (a, b) => {
                    if (a && a.parent_lead_date && a.parent_lead_date.length && b && b.parent_lead_date && b.parent_lead_date.length) {
                        return a.parent_lead_date.length - b.parent_lead_date.length;
                    } else if (a && a.parent_lead_date && a.parent_lead_date.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.parent_lead_date && b.parent_lead_date.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Date
                        <hr />


                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Less than
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                More than
                            </label>
                        </div>

                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleDateValue} />
                            <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },
            {
                title: 'Last Activity', width: 180,
                dataIndex: 'last_activity',
                key: "last_activity",
                sorter: (a, b) => a.last_activity - b.last_activity,
                sortOrder: sortedInfo.columnKey === 'last_activity' && sortedInfo.order,
                sortDirections: ['ascend', 'descend', 'ascend'],
                render: (text, record) => (
                    <>
                        {record.last_activity && record.last_activity != null ? <Moment fromNow>

                            {(record.last_activity) ? this.getCurrentTime(record.last_activity) : ""}
                        </Moment> : null}
                    </>

                    // <Moment fromNow>
                    // <>
                    // {record.parent_lead_date}
                    //     {validator.isDate(record.parent_lead_date) ?
                    //     <Moment fromNow>
                    //       {record.parent_lead_date}
                    //     </Moment> : ""}
                    // </>
                    //   {record.parent_lead_date}
                    // </Moment>

                ),
                sorter: (a, b) => {
                    if (a && a.last_activity && a.last_activity.length && b && b.last_activity && b.last_activity.length) {
                        return a.last_activity.length - b.last_activity.length;
                    } else if (a && a.last_activity && a.last_activity.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.last_activity && b.last_activity.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Date
                        <hr />


                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.last_activity_date_morethan_option} id="flexRadioDefault1" onChange={this.handleLastActivityDateMorethan} />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Less than
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" checked={this.state.last_activity_date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleLastActivityDateLessthan} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                More than
                            </label>
                        </div>

                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleLastActivityDateValue} />
                            <select className='form-select ml-1' onChange={this.handleLastActivityDateValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },
            {
                title: 'Verified Date', width: 180,
                dataIndex: 'verified_date',
                key: "verified_date",
                sorter: (a, b) => a.verified_date - b.verified_date,
                sortOrder: sortedInfo.columnKey === 'verified_date' && sortedInfo.order,
                sortDirections: ['ascend', 'descend', 'ascend'],
                render: (text, record) => (
                    <>
                        {record.verified_date && record.verified_date != null ? <Moment fromNow>

                            {(record.verified_date) ? this.getCurrentTime(record.verified_date) : ""}
                        </Moment> : null}
                    </>



                ),
                only_verified: all_values.includes('25') ? false : true


            },
            {
                title: 'Match', width: 100,
                dataIndex: 'match',
                render: (text, record) => (

                    <Link target="_blank" to={"all_match_listings/" + record.original_id}>
                        {record.matched} (<span className={record.matched_new > 0 ? 'newmatchcolor' : ''}>{record.matched_new}</span>)
                    </Link>
                ),
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Matched
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.matched_leads_and_no_viewings_option} id="flexRadioDefault1" onChange={this.handleMatchedLeadsAndNoViewing} />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Match leads & no viewings
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" checked={this.state.matched_leads_and_viewings_morethan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleMatchedLeadsAndViewingMorethan} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Match leads
                            </label>
                        </div>
                        {this.state.matched_leads_viewing_value_show ?

                            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <select className="form-select font-12" aria-label="Default select example" name='offers_count_first_filter_value_id' onChange={this.handleMatchedLeadsFilter_Value_Option}>
                                    <option value="1">Equals</option>
                                    <option value="2">Not equal</option>
                                    <option value="3">Less than</option>
                                    <option value="4">Less than or equals</option>
                                    <option value="5">Greater than</option>
                                    <option value="6">Greater than or equals</option>
                                    {/* <option value="7">In range</option> */}
                                </select>
                                <input type="text" value={this.state.matched_leads_viewing_value} className="form-control" onChange={this.handleMatchedLeadsFilter} />

                            </div> : null}


                    </div>
                ),
            },
            {
                title: 'Contact type', width: 180,
                dataIndex: 'contact_type',
                render: (text, record) => {

                    const contact_type = record.contact_type
                    if (contact_type == 1) {
                        return "Tenant";
                    } else if (contact_type == 2) {
                        return "Buyer";
                    } else if (contact_type == 3) {
                        return "Landlord";
                    } else if (contact_type == 4) {
                        return "Seller";
                    } else if (contact_type == 6) {
                        return "Agent";
                    } else if (contact_type == 7) {
                        return "Other";
                    } else if (contact_type == 8) {
                        return "Portal";
                    } else if (contact_type == 9) {
                        return "Buyer/Tenant";
                    } else {
                        return "Unrecognized";
                    }
                },
                sorter: (a, b) => {
                    if (a && a.contact_type && a.contact_type.length && b && b.contact_type && b.contact_type.length) {
                        return a.contact_type.length - b.contact_type.length;
                    } else if (a && a.contact_type && a.contact_type.length) {
                        return -1;
                    } else if (b && b.contact_type && b.contact_type.length) {
                        return 1;
                    }
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Contact type
                        <hr />

                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            value={this.state.contact_types_value}
                            placeholder="Please select"
                            onChange={this.handleContactType}
                            options={this.state.contact_types}
                        />


                    </div>
                ),

            },
            {
                title: ' Community', width: 200,
                dataIndex: 'community',
                sorter: (a, b) => a.community.length - b.community.length,
                render: (text, record) => {
                    if (record.community != null && record.community != "" && record.community != undefined) {
                        let community = (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                        return (community && community.label) ? community.label : community;
                    } else {
                        return "";
                    }
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Community
                        <Select
                            allowClear
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            value={this.state.community_filter}
                            placeholder="Search"
                            onChange={this.handleCommunityFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.communities}
                        />


                    </div>
                ),
            },
            {
                title: ' Sub Community', width: 300,
                dataIndex: 'subcommunity',
                sorter: (a, b) => a.subcommunity.length - b.subcommunity.length,
                render: (text, record) => {
                    if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined) {
                        let subcommunity = (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                        return (subcommunity && subcommunity.label) ? subcommunity.label : subcommunity;
                    } else {
                        return "";
                    }
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Sub Community
                        <Select
                            showSearch
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            value={this.state.sub_community_filter}

                            placeholder="Search"
                            onChange={this.handleSubCommunityFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.sub_communities}
                        />


                    </div>
                ),
            },
            {
                title: ' Property', width: 250,
                dataIndex: 'property',
                sorter: (a, b) => a.property.length - b.property.length,
                render: (text, record) => {
                    if (record.property != null && record.property != "" && record.property != undefined) {
                        let property = (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                        return (property && property.label) ? property.label : property;
                    } else {
                        return "";
                    }
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Property
                        <Select
                            showSearch
                            allowClear
                            style={{
                                width: '100%',
                            }}

                            value={this.state.property_filter}

                            placeholder="Search"
                            onChange={this.handlePropertyFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.properties}
                        />


                    </div>
                ),
            },
            {
                title: 'Price', width: 150,
                dataIndex: 'lead_price',
                sorter: (a, b) => {
                    if (a && a.price && a.price.length && b && b.price && b.price.length) {
                        return a.price.length - b.price.length;
                    } else if (a && a.price && a.price.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.price && b.price.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                render: (text, record) => {

                    const price = record.lead_price
                    if (!isNaN(parseInt(price))) {
                        if (price > 0 && price != null) {
                            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'

                        } else {
                            return null
                        }
                    } else {
                        return null;
                    }
                },
                //a.price.length > 0 - b.price.length > 0,
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Price
                        <hr />


                        <Select

                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"
                            onChange={this.handlePriceFirstValue_id}
                            options={this.state.price_options}
                        />

                        {/* <select className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select> */}
                        <div className='mt-2'>
                            {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input type="text" value={this.state.price_min} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                                <input type="text" value={this.state.price_max} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
                            </div> : <input type="text" value={this.state.price_first_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
                        </div>




                    </div>
                ),
            },

            {
                title: 'Beds', width: 100,
                dataIndex: 'bedroom',

                sorter: (a, b) => a.bedroom.length - b.bedroom.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Bedrooms
                        <hr />

                        <Select

                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"
                            onChange={this.handleBedRooms_id}
                            options={this.state.bedroom_filter_options}
                        />
                        {/* <select className="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleBedRooms_id}>

              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3"> Less than</option>
              <option value="4">Greater than</option>
            </select> */}
                        <div className='mt-2'>
                            <input type="text" value={this.state.bed_rooms_value} className="form-control" onChange={this.handleBedRoomsValue} placeholder="filter..." />
                        </div>




                    </div>
                ),
            },

            {
                title: 'FF / UF', width: 150,
                // dataIndex: 'furnished',
                render: (text, record) => (
                    <>
                        {record.furnished == 1 ? "Furnished" : "Unfurnished"}
                    </>


                ),

                sorter: (a, b) => {
                    if (a && a.furnished && a.furnished.length && b && b.furnished && b.furnished.length) {
                        return a.furnished.length - b.furnished.length;
                    } else if (a && a.furnished && a.furnished.length) {
                        return -1;
                    } else if (b && b.furnished && b.furnished.length) {
                        return 1;
                    }
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Furnished / Unfurnished
                        <hr />
                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            value={this.state.furnished_types_value}
                            placeholder="Please select"
                            onChange={this.handleFurnishedTypes}
                            options={this.state.furnished_types}
                        />


                    </div>
                ),

            },

            {
                title: 'Move in date', width: 180,
                dataIndex: 'move_in_date',
                render: (text, record) => (

                    <>

                        {
                            record.move_in_date == null || record.move_in_date == '' || record.move_in_date == "0000-00-00 00:00:00" ?
                                ""
                                :
                                <Moment format="YYYY/MM/DD">{record.move_in_date}</Moment>
                        }


                    </>

                ),
                sorter: (a, b) => {
                    if (a && a.move_in_date && a.move_in_date.length && b && b.move_in_date && b.move_in_date.length) {
                        return a.move_in_date.length - b.move_in_date.length;
                    } else if (a && a.move_in_date && a.move_in_date.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.move_in_date && b.move_in_date.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Move in date
                        <hr />
                        <div className='mb-1'>
                            <select value={this.state.move_in_date_option_value} className="form-select" aria-label="Default select example" name='move_in_date' onChange={this.handleMoveInDateOptionValue} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Greater than</option>
                                <option value="4">Less than</option>
                                <option value="5">In Next</option>
                                <option value="6">In Previous</option>
                                <option value="7">Date from - to</option>
                                <option value="8">Is empty</option>

                            </select>
                        </div>

                        {this.state.move_in_date_is_empty ? null : <>
                            {this.state.inrange_move_in_date ? <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} /> : <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input value={this.state.move_in_date_value} type="text" className="form-control" onChange={this.handleMoveInDateValue} />
                                <select className='form-select ml-1' value={this.state.move_in_date_value_id} onChange={this.handleMoveInDateValue_id}>
                                    <option value="1">days</option>
                                    <option value="2">weeks</option>
                                    <option value="3">months</option>
                                    <option value="4">years</option>
                                </select>
                            </div>}
                        </>}

                    </div>
                ),
            },

            {
                title: 'Type', width: 150,
                dataIndex: 'prop_type',
                render: (text, record) => {
                    if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined) {
                        let prop_type = (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                        return (prop_type && prop_type.label) ? prop_type.label : prop_type;
                    } else {
                        return "";
                    }
                },
                sorter: (a, b) => a.prop_type.length - b.prop_type.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Property type
                        <hr />


                        <Select
                            showSearch
                            allowClear

                            style={{
                                width: '100%',
                            }}
                            mode="multiple"
                            value={this.state.property_type_filter}

                            placeholder="Search"
                            onChange={this.handlePropertyTypeFilter}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.propertytypes}
                        />



                    </div>
                ),
            },
            {
                title: 'Viewings', width: 150,
                dataIndex: 'viewings',
                render: (text, record) => (
                    <>
                        {(record.viewings != null) ? record.viewings : 0}
                    </>

                ),
                sorter: (a, b) => {

                },
                filterDropdown: (
                    <div style={{
                        padding: '15px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        <div >
                            <label>Viewings</label>
                            <select value={this.state.viewing_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleViewingValue_id} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>
                                <option value="5">In range</option>

                            </select>
                            <div className='mt-2'>
                                {this.state.inrange_viewing ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input type="text" value={this.state.viewing_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMinValue} placeholder="min value" />
                                    <input type="text" value={this.state.viewing_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMaxValue} placeholder="max value" />
                                </div> : <input type="text" value={this.state.viewing_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleViewingValue} placeholder="filter..." />}
                            </div>
                        </div>


                    </div>
                ),
            },
            {
                title: 'Offers', width: 130,
                dataIndex: 'offers',
                render: (text, record) => (
                    <>
                        {(record.offers != null) ? record.offers : 0}
                    </>

                ),
                sorter: (a, b) => {

                },
                filterDropdown: (
                    <div style={{
                        padding: '15px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>

                        <div >
                            <label>Offers</label>
                            <select value={this.state.offer_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleOfferValue_id} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>
                                <option value="5">In range</option>

                            </select>
                            <div className='mt-2'>
                                {this.state.inrange_offer ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input type="text" value={this.state.offer_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMinValue} placeholder="min value" />
                                    <input type="text" value={this.state.offer_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMaxValue} placeholder="max value" />
                                </div> : <input type="text" value={this.state.offer_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleOfferValue} placeholder="filter..." />}
                            </div>
                        </div>


                        {/* <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.offer_morthan_value} id="flexRadioDefault1" onChange={this.handleOfferMorethan} />
              <label className="form-check-label" for="flexRadioDefault1">More than</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" checked={this.state.offer_lessthan_value} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleOfferLessthan} />
              <label className="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>
            <div className='form-group mt-2 mb-0'>
              <input type="text" value={this.state.offer_value} className="form-control" onChange={this.handleOfferValue} />
            </div> */}
                    </div>
                ),
            },
            {
                title: 'Last call', width: 150,
                dataIndex: 'last_call',
                render: (text, record) => (
                    <>
                        {
                            record.last_call == null || record.last_call == '' || record.last_call == "0000-00-00 00:00:00" ?
                                ""
                                :
                                <Moment fromNow>
                                    {record.last_call}
                                </Moment>
                        }

                    </>


                ),
                sorter: (a, b) => {
                    if (a && a.last_call && a.last_call.length && b && b.last_call && b.last_call.length) {
                        return a.last_call.length - b.last_call.length;
                    } else if (a && a.last_call && a.last_call.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.last_call && b.last_call.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Last call
                        <hr />
                        <div className='mb-1'>
                            <select value={this.state.last_call_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastCallOptionValue} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>

                            </select>
                        </div>

                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleLastCallValue} />
                            <select className='form-select ml-1' onChange={this.handleLastCallValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },
            {
                title: 'Last match', width: 170,
                dataIndex: 'last_match',
                render: (text, record) => (
                    <>
                        {/* { validator.isDate(record.added_date)?
            <Moment fromNow>
              {record.added_date}
            </Moment>:""} */}

                        {
                            record.last_match == null || record.last_match == '' || record.last_match == "0000-00-00 00:00:00" ?
                                ""
                                :
                                <Moment fromNow>
                                    {record.last_match}
                                </Moment>
                        }

                    </>
                ),
                sorter: (a, b) => {
                    if (a && a.last_match && a.last_match.length && b && b.last_match && b.last_match.length) {
                        return a.last_match.length - b.last_match.length;
                    } else if (a && a.last_match && a.last_match.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.last_match && b.last_match.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Last match
                        <hr />

                        <div className='mb-1'>
                            <select value={this.state.last_match_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastMatchOptionValue} >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Greater than</option>

                            </select>
                        </div>
                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleLastMatchValue} />
                            <select className='form-select ml-1' onChange={this.handleLastMatchValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },

            {
                title: 'Last Whatsapp', width: 190,
                dataIndex: 'last_whatsapp',
                render: (text, record) => (
                    <>
                        {record.last_whatsapp && record.last_whatsapp != null ? <Moment fromNow>
                            {record.last_whatsapp}
                        </Moment> : null}
                    </>


                ),
                sorter: (a, b) => {
                    if (a && a.last_whatsapp && a.last_whatsapp.length && b && b.last_whatsapp && b.last_whatsapp.length) {
                        return a.last_whatsapp.length - b.last_whatsapp.length;
                    } else if (a && a.last_whatsapp && a.last_whatsapp.length) {
                        // That means be has null rechargeType, so a will come first.
                        return -1;
                    } else if (b && b.last_whatsapp && b.last_whatsapp.length) {
                        // That means a has null rechargeType so b will come first.
                        return 1;
                    }

                    // Both rechargeType has null value so there will be no order change.
                    return 0;
                },
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Date
                        <hr />


                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.last_whatsapp_date_morethan_option} id="flexRadioDefault1" onChange={this.handleLastWhatsappDateMorethan} />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Less than
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" checked={this.state.last_whatsapp_date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleLastWhatsappDateLessthan} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                More than
                            </label>
                        </div>

                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleLastWhatsappDateValue} />
                            <select className='form-select ml-1' onChange={this.handleLastWhatsappDateValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },
            {
                title: 'Last Whatsapp Received', width: 230,
                dataIndex: 'last_whatsapp_received',
                render: (text, record) => (
                    <>
                        {record.last_whatsapp_received && record.last_whatsapp_received != null ? <Moment fromNow>
                            {record.last_whatsapp_received}
                        </Moment> : null}
                    </>


                ),
                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Date
                        <hr />


                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.last_whatsapp_received_date_morethan_option} id="flexRadioDefault1" onChange={this.handleLastWhatsappReceivedDateMorethan} />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Less than
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" checked={this.state.last_whatsapp_received_date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleLastWhatsappReceivedDateLessthan} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                More than
                            </label>
                        </div>

                        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type="text" className="form-control" onChange={this.handleLastWhatsappReceivedDateValue} />
                            <select className='form-select ml-1' onChange={this.handleLastWhatsappReceivedDateValue_id}>
                                <option value="1">days</option>
                                <option value="2">weeks</option>
                                <option value="3">months</option>
                                <option value="4">years</option>
                            </select>
                        </div>


                    </div>
                ),
            },


            {
                title: 'Ref no', width: 150,
                //dataIndex: 'fullname',
                render: (text, record) => (
                    <>
                        {record.refno == " " || record.refno == "" || record.refno == null ?
                            <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}> <SearchOutlined /></Link>
                            :
                            <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}>{record.fullname}</Link>}
                    </>
                ),
                sorter: (a, b) => a.refno.length - b.refno.length,

                filterDropdown: (
                    <div style={{
                        padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                    }}>
                        Ref no
                        <hr />
                        <Select
                            style={{
                                width: '100%',
                            }}

                            placeholder="Please select"

                            onChange={this.handleRefnoValue_id}
                            options={this.state.name_filter_options}
                        />
                        <div className='mt-2'>
                            <input type="text" className="form-control" value={this.state.refno_value} onChange={this.handleRefnoValue} placeholder="filter..." />
                        </div>




                    </div>
                ),
            },




        ].filter(item => !item.only_verified);

        const pendingleads_columns = [
            {
                title: 'Name', dataIndex: 'name',
                render: (text, record) => {
                    let agent = (this.state.agents) ? this.state.agents.find(items => items.value === record.agents) : record.agents;
                    const agentName = (agent && agent.label) ? agent.label : agent
                    return (
                        <div className='listinginfowrap'>
                            <div className='info-group'>
                                <div className='agentwrap d-flex flex-row align-items-center'>
                                    <Avatar shape="square" style={{ borderRadius: '10px !important' }} size={80} icon={<img style={{ borderRadius: '10px', boxShadow: '1px 1px 10px lightgray', width: "80px", height: "80px", borderRadius: '10px !important' }} src={record.profile_photo == null || record.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(record.profile_photo) ? record.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo} />} />
                                    <p className="font-semibold font-14 ">{agentName}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'requirements', dataIndex: 'requirements', width: 400,
                render: (text, record) => {
                    let com = "", subcom = "", prop = ""
                    if (record.community != null && record.community != "" && record.community != undefined) {
                        let community = (this.state.communities) ? this.state.communities.find(items => items.value == record.community) : record.community;
                        (community && community.label) ? com = community.label : com = community
                    }
                    if (record.subcommunity != null && record.subcommunity != "" && record.subcommunity != undefined) {
                        let subcommunity = (this.state.sub_communities) ? this.state.sub_communities.find(items => items.value == record.subcommunity) : record.subcommunity;
                        (subcommunity && subcommunity.label) ? subcom = subcommunity.label : subcom = subcommunity;
                    }
                    if (record.property != null && record.property != "" && record.property != undefined) {
                        let property = (this.state.properties) ? this.state.properties.find(items => items.value == record.property) : record.property;
                        (property && property.label) ? prop = property.label : prop = property;
                    }
                    const price = record.lead_price
                    let ptype = ""
                    if (record.prop_type != null && record.prop_type != "" && record.prop_type != undefined) {
                        let prop_type = (this.state.propertytypes) ? this.state.propertytypes.find(items => items.value == record.prop_type) : record.prop_type;
                        (prop_type && prop_type.label) ? ptype = prop_type.label : ptype = prop_type;
                    }
                    return (<>
                        <div className='listinginfowrap'>
                            <div className='info-group'>
                                <p className='info-title'>Requirements:</p>
                                <div className='d-flex flex-column align-items-start'>
                                    <p>{prop ? <span className="font-semibold font-12">{prop + ", "}</span> : ''}
                                        {subcom ? <span className="font-semibold font-12">{subcom + ", "}</span> : ''}
                                        {com ? <span className="font-semibold font-12">{com}</span> : '-'}</p>
                                    <div className='d-flex flex-row' style={{ gap: '0.6rem' }}>
                                        {record.lead_price ? <p className=" font-12">{record.lead_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED"}</p> : ''}
                                        <i class="bi bi-dot"></i>
                                        {record.bedroom ? <p className="font-12">{record.bedroom + " beds"}</p> : ''}
                                        <i class="bi bi-dot"></i>
                                        {ptype ? <p className="font-12">{ptype}</p> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)
                }
            },
            {
                title: 'created date', dataIndex: 'created_date', width: 130,
                render: (text, record) => (
                    <div className='listinginfowrap'>
                        <div className='info-group'>
                            <p className='info-title'>Created date:</p>
                            <div className='d-flex flex-row align-items-center'>
                                {record.parent_lead_date ? <Moment className='infovalue' fromNow>{(record.parent_lead_date) ? this.getCurrentTime(record.parent_lead_date) : ""}</Moment> : ''}

                            </div>
                        </div>
                    </div>
                )
            },
            {
                title: 'expired in', dataIndex: 'expires_in',
                render: (text, record) => {
                    const { totalMilliseconds } = this.state;
                    const hoursValue = Math.floor((totalMilliseconds / (1000 * 60 * 60)) % 24);
                    const minutesValue = Math.floor((totalMilliseconds / 1000 / 60) % 60);
                    const secondsValue = Math.floor((totalMilliseconds / 1000) % 60);

                    return (
                        <div className='listinginfowrap' style={{ alignItems: 'center' }}>
                            <div className='info-group' style={{ textAlign: 'center' }}>
                                <p className='info-title' style={{ color: "#095EDD" }}>Expires in:</p>
                                <div className='d-flex flex-row align-items-center'>
                                    <Row gutter={16} className='countdownwrap'>
                                        <Col>
                                            <Statistic value={"0" + hoursValue} className='font-bold' />
                                            <span className='lblStatistics'>Hours</span>
                                        </Col>
                                        <Col className='d-flex align-items-center font-18 font-bold' style={{ color: '#095EDD' }}>:</Col>
                                        <Col>
                                            <Statistic value={minutesValue} className='font-bold' />
                                            <span className='lblStatistics'>Minutes</span>
                                        </Col>
                                        <Col className='d-flex align-items-center font-18 font-bold' style={{ color: '#095EDD' }}>:</Col>
                                        <Col>
                                            <Statistic value={secondsValue} className='font-bold' />
                                            <span className='lblStatistics'>seconds</span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'action', dataIndex: 'actions',
                render: (text, record) => (
                    <div className='listinginfowrap' style={{ alignSelf: 'center' }}>
                        <div className='info-group'>
                            <div className='d-flex flex-row align-items-center'>
                                <Button className='btn btnAccept  font-14'>Accept</Button>
                                <Button className="btn btn-secondary  font-14" >Decline</Button>
                            </div>
                        </div>
                    </div>
                )
            },
            {
                title: 'Reassign to', dataIndex: 'reassign_to',
                render: (text, record) => (
                    <div className='listinginfowrap' style={{ alignSelf: 'center' }}>
                        <div className='info-group'>
                            <div className='d-flex flex-row align-items-center'>
                                <Select style={{ minWidth: '200px', height: 36, borderRadius: 0 }}
                                    showSearch className='selectAgentwrap'
                                    value={this.state.parent_agent ? this.state.parent_agent : 'Reassign to'}
                                    allowClear
                                    placeholder="Select Parent Lead"
                                    maxTagCount='responsive'
                                    // onChange={this.onChangeParentAgent}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={
                                        this.state.allAgents && this.state.allAgents.length > 0 ? this.state.allAgents.map(item => ({
                                            label: item.label,
                                            value: item.value,
                                        })) :
                                            this.state.agents
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )
            },
        ]

        const ViewingCol = [
            {
                title: 'Name', className: 'namecolwrap', width: '20%',
                render: (text, record) => (
                    <>
                        <div className='listinginfowrap listingagentwrap' style={{ height: 0 }}>
                            <div className='info-group'>
                                <p className='info-title'>Name:</p>
                                <div className='d-flex flex-row'>
                                    {record.pinned == 1 || record.pinned == 2 ? <i class="bi bi-pin-angle-fill mr-1"></i> : null}
                                    <i class="bi bi-pin-angle-fill mr-1"></i>
                                    <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}> <SearchOutlined /></Link>
                                    {/* <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>
                                        <SearchOutlined />
                                        </Link>  */}
                                    :
                                    {/* <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link> */}
                                    <Link to={`/leads/${record.parent_lead}`} target="_blank" onClick={this.saveNavigation.bind(this, record.parent_lead)}>{record.fullname}</Link>
                                </div>
                            </div>
                        </div>
                    </>
                ),
            }, {
                title: 'Viewing Status', className: 'viewingstatuswrap', width: '5%',
                // dataIndex: 'lead_stage',
                render: (text, record) => (
                    <>
                        <div className='listinginfowrap listingagentwrap'>
                            <div className='info-group'>
                                <p className='info-title'>Status:</p>
                                <p>{record.agents == 430 || record.lead_stage == 12 ? <span className="recruitment pstat">Second Viewing</span> : <>
                                    {record.lead_stage == 1 ? <span className="uncontacted pstat">Not Interested</span> : null}
                                    {record.lead_stage == 2 ? <span className="working pstat">Scheduled</span> : null}
                                    {record.lead_stage == 10 ? <span className="noanswer pstat">Unsuccessful</span> : null}
                                    {record.lead_stage == 11 ? <span className="expiry pstat">Considering</span> : null}
                                    {record.lead_stage == 3 ? <span className="hot pstat">Offer</span> : null}
                                </>}</p>
                            </div>
                        </div>

                    </>
                ),
            }, {
                title: 'Last Viewing Date', className: 'lastviewingdatewrap', width: '25%',
                render: (text, record) => (
                    <>
                        <div className='listinginfowrap listingagentwrap' style={{ height: 0 }}>
                            <div className='info-group'>
                                <p className='info-title'>Last Viewing Date:</p>
                                <Moment format='hh:mm A | DD MMMM YYYY' className='font-bold'>2023-08-19 10:00:00</Moment>
                            </div>
                            <div className='info-group'>
                                <p className='info-title'>Feedback:</p>
                                <p className='font-bold'>This is feedback</p>
                            </div>
                        </div>
                    </>
                ),
            }, {
                title: 'Next Viewing Date', className: 'nextviewingdatewrap', width: '25%',
                render: (text, record) => (
                    <div className='listinginfowrap listingagentwrap' style={{ height: 0 }}>
                        <div className='info-group'>
                            <p className='info-title'>Next Viewing Date:</p>
                            <Moment className='font-bold' format='hh:mm A | DD MMMM YYYY'>2023-08-19 10:00:00</Moment>
                        </div>
                        <div className='info-group'>
                            <p className='info-title'>Next Viewing Unit:</p>
                            <a href="">
                                <p className='font-bold'>LP36177 | 123</p>
                                <p className='font-bold'> Signature Villas Frond M, Signature Villas, Palm Jumeirah</p>
                                {/* Unit no, Property,  Subcom, Community */}
                            </a>
                        </div>
                    </div>
                ),
            }

        ]

        const ViewingDate = [
            { name: 'test', lead_stage: '2', lastviewingdate: 'test', nextviewingdate: 'test' },
            { name: 'test', lead_stage: '2', lastviewingdate: 'test', nextviewingdate: 'test' },
            { name: 'test', lead_stage: '2', lastviewingdate: 'test', nextviewingdate: 'test' },
            { name: 'test', lead_stage: '2', lastviewingdate: 'test', nextviewingdate: 'test' },
            { name: 'test', lead_stage: '2', lastviewingdate: 'test', nextviewingdate: 'test' }



        ]

        const handleListingTableChange = (pagination, filters) => {
            const page = 1;
            const perPage = 5;
            const data_fetch = {
                leads_id: this.state.record_id,
                bedroom: this.new_bed,
                price: this.state.price_first,
                community: this.state.new_community,
                subcommunity: this.state.new_sub_community
            }
            this.fetchMatchedVerificationCallLeadList(pagination.current, pagination.pageSize, data_fetch);
        }

        const handleTableChange = (pagination, filters, sorter) => {

            this.setState({
                sortedInfo: sorter,
                columnKey: sorter.order != false ? sorter.columnKey : "",
                order: sorter.order != false ? sorter.order : "",
            });

            const all_data = store.getState((state) => state);
            const isExpiringLead = all_data.expiring_lead_reducer.isExpiringLead
            const isMoveInDateLead = all_data.expiring_lead_reducer.isMoveInDateLead
            const isMatchedLead = all_data.expiring_lead_reducer.isMatchedLead
            const selectedAgents = all_data.expiring_lead_reducer.selectedAgents

            const data = {
                columnKey: this.state.columnKey,
                order: this.state.order,
                pending_leads: this.state.pending_leads,
                listings_array: this.state.listings_array,
                search_owner: this.state.search_owner,
                replied_to_whatsapp: this.state.replied_to_whatsapp ? "YES" : "NO",
                clicked_book_viewing: this.state.clicked_book_viewing,
                viewing_booked_since_requested: this.state.viewing_booked_since_requested ? "YES" : "NO",
                viewing_date_range: this.state.viewing_date_range,
                viewing_outcome: this.state.viewing_outcome,
                number_confirmed_on_whatsapp: this.state.number_confirmed_on_whatsapp ? "YES" : "NO",
                offer_status: this.state.offer_status,
                deal_signed: this.state.deal_signed ? "YES" : "NO",
                last_activity_date_morethan_option: this.state.last_activity_date_morethan_option,
                last_activity_date_lessthan_option: this.state.last_activity_date_lessthan_option,
                last_activity_date_value: this.state.last_activity_date_value,
                last_activity_date_value_id: this.state.last_activity_date_value_id,

                last_whatsapp_date_morethan_option: this.state.last_whatsapp_date_morethan_option,
                last_whatsapp_date_lessthan_option: this.state.last_whatsapp_date_lessthan_option,
                last_whatsapp_date_value: this.state.last_whatsapp_date_value,
                last_whatsapp_date_value_id: this.state.last_whatsapp_date_value_id,

                last_whatsapp_received_date_morethan_option: this.state.last_whatsapp_received_date_morethan_option,
                last_whatsapp_received_date_lessthan_option: this.state.last_whatsapp_received_date_lessthan_option,
                last_whatsapp_received_date_value: this.state.last_whatsapp_received_date_value,
                last_whatsapp_received_date_value_id: this.state.last_whatsapp_received_date_value_id,
                selectedTeam: this.state.selectedTeam,
                selectedAgents: selectedAgents,
                isMatchedLead: isMatchedLead == true ? 1 : 0,
                isExpiringLead: isExpiringLead == true ? 1 : 0,
                isMoveInDateLead: isMoveInDateLead == true ? 1 : 0,
                community_filter: this.state.community_filter,
                sub_community_filter: this.state.sub_community_filter,
                property_filter: this.state.property_filter,
                name_value_id: this.state.name_value_id,
                name_value: this.state.name_value,
                phone_value_id: this.state.phone_value_id,
                phone_value: this.state.phone_value,
                refno_value: this.state.refno_value,
                refno_value_id: this.state.refno_value_id,
                bed_rooms_value_id: this.state.bed_rooms_value_id,
                bed_rooms_value: this.state.bed_rooms_value,
                agent_value_id: this.state.agent_value_id,
                agent_value: this.state.agent_value,
                stage_value: this.state.stage_value,
                verified_sub_stage_value: this.state.verified_sub_stage_value,
                contact_types_value: this.state.contact_types_value,
                furnished_types_value: this.state.furnished_types_value,
                price_first_value_id: this.state.price_min==0 && this.state.price_max==0 ? 1 : 7,
                price_first_value: this.state.price_first_value,
                price_min: this.state.price_min,
                price_max: this.state.price_max,
                date_morethan_option: this.state.date_morethan_option,
                date_lessthan_option: this.state.date_lessthan_option,
                move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                date_value: this.state.date_value,
                date_value_id: this.state.date_value_id,
                property_type_filter: this.state.property_type_filter,

                viewing_value: this.state.viewing_value,
                viewing_morethan_value: this.state.viewing_morethan_value,
                viewing_lessthan_value: this.state.viewing_lessthan_value,
                viewing_option_value: this.state.viewing_option_value,
                inrange_viewing: this.state.inrange_viewing,
                viewing_min_value: this.state.viewing_min_value,
                viewing_max_value: this.state.viewing_max_value,


                offer_value: this.state.offer_value,
                offer_morthan_value: this.state.offer_morthan_value,
                offer_lessthan_value: this.state.offer_lessthan_value,
                offer_option_value: this.state.offer_option_value,
                inrange_offer: this.state.inrange_offer,
                offer_min_value: this.state.offer_min_value,
                offer_max_value: this.state.offer_max_value,


                last_call_morethan_option: this.state.last_call_morethan_option,
                last_call_lessthan_option: this.state.last_call_lessthan_option,
                last_call_value: this.state.last_call_value,
                last_call_value_id: this.state.last_call_value_id,
                last_call_option_value: this.state.last_call_option_value,


                last_match_morethan_option: this.state.last_match_morethan_option,
                last_match_lessthan_option: this.state.last_match_lessthan_option,
                last_match_value: this.state.last_match_value,
                last_match_value_id: this.state.last_match_value_id,
                last_match_option_value: this.state.last_match_option_value,

                move_in_date_morethan_option: this.state.move_in_date_morethan_option,
                move_in_date_lessthan_option: this.state.move_in_date_lessthan_option,
                move_in_date_value: this.state.move_in_date_value,
                move_in_date_value_id: this.state.move_in_date_value_id,
                move_in_date_option_value: this.state.move_in_date_option_value,
                move_in_date_date_range: this.state.move_in_date_date_range,
                columnKey: sorter.order != false ? sorter.columnKey : "",
                order: sorter.order != false ? sorter.order : "",

            }




            this.fetchData(pagination.current, pagination.pageSize, data);

        };

        const sellers = [
            { title: 'Name', dataIndex: 'name' },
            { title: 'Mobile', dataIndex: 'mobile' },
            { title: 'Email', dataIndex: 'email' },
            { title: 'Agent', dataIndex: 'agent' },
        ]

        const sellersdata = [
            { name: 'Rohit Batra', mobile: '0505588463', email: '', agent: 'Claire Towle' },
            { name: 'Asha', mobile: '447739408250', email: '', agent: 'George Hughes' },
        ]

        // MATCH LISTING
        // rowSelection object indicates the need for row selection
        const rowMatchSelection = {
            onChange: (selectedRowKeys, selectedRows) => {

            }
        };
        const props = { dots: true, infinite: true, speed: 500, lidesToShow: 1, slidesToScroll: 1, nextArrow: <CaretRightOutlined />, prevArrow: <CaretLeftOutlined /> };
        const matchColumn = [
            {
                title: 'Image', dataIndex: 'listingimage', className: 'listingimagewrap', width: '20%',
                render: (text, record) => (
                    <>
                        {record.property_images == null ?
                            <>
                                <div className='noimageyet'><img src={"../../images/noimageyet.png"} className='defimg mx-auto' width="80px" style={{ opacity: '0.6' }} /><span className='font-12'>Image pending</span></div>
                            </>
                            :
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center' }}>
                                <Carousel arrows style={{ width: '280px', height: '157px', overflow: 'hidden' }} effect="fade" {...props}>
                                    {this.getImageArray(record.property_images).map((image) => {
                                        return <img src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                    })}
                                </Carousel>
                            </div>
                        }
                    </>
                )
            }, {
                title: 'Ref',
                dataIndex: 'refno', width: '50%',

                sorter: (a, b) => a.refno.length - b.refno.length,
                render: (text, record) => {
                    var pStatus = ''
                    var color = ''

                    switch (record.status) {
                        case 'D':
                            pStatus = 'Draft'
                            color = 'draft'
                            break;
                        case 'UC':
                            pStatus = 'Confirmed Owner'
                            color = 'confirmedowner'
                            break;
                        case 'N':
                            pStatus = 'Unpublished'
                            color = 'unpublished'
                            break;
                        case 'OM':
                            pStatus = 'Off-Market'
                            color = 'offmarket'
                            break;
                        case 'CTM':
                            pStatus = 'Coming to market'
                            color = 'comingtomarket'
                            break;
                        case 'Y':
                            pStatus = 'Published'
                            color = 'published'
                            break;
                        case 'U':
                            pStatus = 'Action'
                            color = 'action'
                            break;

                        case 'SEO':
                            pStatus = 'SEO'
                            color = 'seo'
                            break;
                        case 'L':
                            pStatus = 'Unlisted'
                            color = 'unlisted'
                            break;
                        case 'S':
                            pStatus = 'Sold'
                            color = 'sold'
                            break;
                        case 'T':
                            pStatus = 'Let'
                            color = 'let'
                            break;
                        case 'P':
                            pStatus = 'Review'
                            color = 'review'
                            break;
                        case 'F':
                            pStatus = 'Feeds'
                            color = 'feeds'
                            break;
                        default:
                            pStatus = 'Draft'
                            color = 'draft'
                            break;
                    }
                    return (
                        <>
                            <Link className="btn-text listinginfo">
                                <div className={color + " pstat "} key={pStatus}>{pStatus}</div>
                                <p className='font-bold fs-6 pt-2 mb-0'>{record.refno}</p>
                                <p className='mb-0'>{record.property_project}</p>
                                <p className='mb-0'>{record.subcommunity + ", " + record.community}</p>
                                <p>{record.price ? record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : null}</p>
                            </Link>

                        </>
                    );
                },
            }, {
                title: 'For', width: '20%',
                dataIndex: 'property_for',
                render: (text, record) => (
                    <div className='listinginfowrap listingagentwrap'>
                        <div className='info-group'>
                            <p className='info-title'>Unit No:</p>
                            <p>{record.unitno ? record.unitno : null}</p>
                        </div>
                        <div className='info-group'>
                            <p className='info-title'>Agent:</p>
                            <div className='agentwrap'>

                                <p>{record.agent_name}</p>
                            </div>
                        </div>
                    </div>
                ),
            }
        ]
        const matchlistingdata = [
            { listingimage: 'test', refno: 'LP123456', status: 'Y', property_project: null, subcommunity: 'The Springs', community: 'Emirates Living', unitno: '12345', agent_name: 'Clementine Givoiset', price: '123456790', property_for: 'Sale', prop_type: 'Villa', bedrooms: 3, furnished: 0, avail_date: '2023-06-11 09:54:56' },
            { listingimage: 'test', refno: 'LP222222', status: 'CTM', property_project: null, subcommunity: 'The Springs', community: 'Emirates Living', unitno: '12345', agent_name: 'Clementine Givoiset', price: '123456790', property_for: 'Sale', prop_type: 'Villa', bedrooms: 3, furnished: 0, avail_date: '2023-06-11 09:54:56' },
            { listingimage: 'test', refno: 'LP333333', status: 'UC', property_project: null, subcommunity: 'The Springs', community: 'Emirates Living', unitno: '12345', agent_name: 'Clementine Givoiset', price: '123456790', property_for: 'Sale', prop_type: 'Villa', bedrooms: 3, furnished: 0, avail_date: '2023-06-11 09:54:56' },
            { listingimage: 'test', refno: 'LP123456', status: 'Y', property_project: null, subcommunity: 'The Springs', community: 'Emirates Living', unitno: '12345', agent_name: 'Clementine Givoiset', price: '123456790', property_for: 'Sale', prop_type: 'Villa', bedrooms: 3, furnished: 0, avail_date: '2023-06-11 09:54:56' },
        ]

        const newleadcol = [
            {
                title: 'Name', dataIndex: 'firstname',
                render: (text, record) => (
                    <div className='leadwrap'>
                        <div className='info-group'>
                            <p className='info-title'>Client:</p>
                            <p className='font-bold font-14'>{record.firstname ? record.firstname : null}{record.lastname ? " " + record.lastname : null}</p>
                            <p>{record.mobile || record.mobile != null ? record.mobile : null}</p>
                        </div>
                    </div>
                )
            },
            // { title: 'Phone Number', dataIndex:'mobile'},
        ]

        const matchverifycol = [
            {
                title: 'Property', dataIndex: 'propertyinfo',
                render: (text, record) => (<Link target="_blank" to={"/all_listings/" + record.id}>
                    <div className='info-group w-100 propertyinfo'>
                        <div className='d-flex flex-row justify-content-between'>
                            <p className='info-title'>{record.property_for} | {record.refno}</p>

                            {record.status == "Y" ? <p className='published stat'>Published</p> : null}
                            {record.status == "CTM" ? <p className='comingtomarket stat'>Coming to market</p> : null}
                            {record.status == "F" ? <p className='feeds stat'>Feeds</p> : null}
                            {record.status == "D" ? <p className='draft stat'>Draft</p> : null}
                            {record.status == "UC" ? <p className='confirmedowner stat'>Confirmed Owner</p> : null}
                            {record.status == "N" ? <p className='unpublished stat'>Unpublished</p> : null}
                            {record.status == "OM" ? <p className='offmarket stat'>Off-Market</p> : null}
                            {record.status == "U" ? <p className='action stat'>Action</p> : null}
                            {record.status == "SEO" ? <p className='seo stat'>SEO</p> : null}


                        </div>
                        <p className='font-bold font-12'>{record.unitno}</p>
                        <p>{record.subcommunity}, {record.community}</p>
                        <div className='d-flex flex-row justify-content-between'>
                            <p>{record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                            <div className='d-flex flex-row'>
                                {/* <Avatar size={18} className='mr-1'/> */}
                                <Avatar className='d-flex' size={18} style={{ backgroundColor: "#FFF", marginRight: "0", }} icon={<img src={record.profile_photo == null || record.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(record.profile_photo) ? record.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo} />} />
                                <span>{record.agent_name}</span>
                            </div>
                        </div>
                    </div>
                </Link>
                )
            },
        ]

        const filterbyVerification = (
            <Col className='d-flex flex-row mb-3 referral' >
                {window.isIpadView ? '' : <p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Filter by Verification:</p>}
                <div className='d-flex flex-row'>
                    {/* {(role_name == "Admin" || role_name == "Super Admin") &&<Button onClick={this.getPendingLeads} 
                                            className={this.state.pendingLeadsShow ? 'statPendingLeadbtn showPending' : 'statPendingLeadbtn'}>Pending Leads
                                        </Button>} */}
                    <CheckboxGroup value={this.state.stage_value} onChange={this.handleStages} placeholder="Status" defaultValue={'1'}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={[
                            { value: '24', label: 'Referral Sent' },
                            { value: '23', label: 'Referral Received' },
                            { value: '25', label: 'Verified' },
                            { value: '26', label: 'Verification call', hidden: role == 1 || user_id == 488 || user_id == 497 || user_id == 498 ? false : true },
                            { value: '27', label: 'Whatsapp verfied', hidden: role == 1 || user_id == 488 || user_id == 497 || user_id == 498 ? false : true },
                            { value: '28', label: 'Past Client' },
                        ].filter(item => !item.hidden && !item.other)} />
                </div>
            </Col>
        );

        const filterByStatus = (
            <Col className='d-flex flex-row pb-2 '>
                {window.isIpadView ? '' : <p className='mr-1 mb-0 font-bold' style={{ whiteSpace: "nowrap", fontSize: 11 }}>Filter by status:</p>}
                <div className='d-flex flex-row'>
                    {/* {(role_name == "Admin" || role_name == "Super Admin") &&<Button onClick={this.getPendingLeads} 
                            className={this.state.pendingLeadsShow ? 'statPendingLeadbtn showPending' : 'statPendingLeadbtn'}>Pending Leads
                        </Button>} */}
                    <CheckboxGroup value={this.state.stage_value} onChange={this.handleStages} placeholder="Status" defaultValue={'1'}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={[
                            { value: '1', label: 'New Inquiry ( ' + this.state.new_inquire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                            { value: '2', label: 'Working ( ' + this.state.working.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                            { value: '8', label: 'Viewings  ( ' + this.state.viewing.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                            { value: '6', label: 'Offer ( ' + this.state.offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                            // {value: '10',label: 'No Answer ( '+this.state.no_answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                            // {value: '11',label: 'Expired ( '+this.state.expired.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                            // {value: '971',label: 'Expired ( other agents )',other:role ==1?true:false},
                            { value: '4', label: 'Follow Up ( ' + this.state.followup.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                            { value: '12', label: 'Pond ( ' + this.state.pond.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },

                            { value: '5', label: 'Dead ( ' + this.state.dead.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },

                        ].filter(item => !item.hidden && !item.other)} />

                </div>
            </Col>
        )
        const log = (e) => {
            console.log(e);
        };

        return (<>
            <ToastContainer />
            <Modal title="Basic Modal" open={this.state.filterTitleModal} onOk={this.handleSaveAllFilter} onCancel={this.handleCancelTitle} >
                <Input placeholder="Title" onChange={this.handleFilterTitle} />

            </Modal>
            <Modal title="Merge Leads" open={this.state.isMergeLeadsOpen} width={1000} centered
                onCancel={this.handleMergeLeadsClose}
                footer={<>{
                    this.state.isMergeLoading ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                            <Spin style={{ alignSelf: 'center' }} />
                        </div>
                        :
                        <Button id="calllogbtn" onClick={this.submitMergeLeads} type="primary" className="d-flex flex-row">Merge Leads</Button>
                }
                </>}
            >
                <Row span={24} className='w-100 addcallpopup-wrap' gutter={8}>
                    <Col span={12} offset={6}>
                        <div className='form-group w-100'>
                            <label>Select Parent Lead<em>*</em>:</label>
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                value={this.state.parent_lead}
                                allowClear
                                placeholder="Select Parent Lead"
                                maxTagCount='responsive'
                                onChange={this.onChangeParentLead}
                                options={this.state.selectedLeads.map(item => ({
                                    label: item.label,
                                    value: item.value,
                                }))}
                            />

                            <label>Select Agent<em>*</em>:</label>
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                showSearch
                                value={this.state.parent_agent}
                                allowClear
                                placeholder="Select Parent Lead"
                                maxTagCount='responsive'
                                onChange={this.onChangeParentAgent}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    this.state.allAgents && this.state.allAgents.length > 0 ? this.state.allAgents.map(item => ({
                                        label: item.label,
                                        value: item.value,
                                    })) :
                                        this.state.agents
                                }
                            />

                            <label>Select lead stage<em>*</em>:</label>
                            {this.state.selectedStages.length && <Select
                                style={{
                                    width: "100%",
                                }}
                                value={this.state.parent_lead_stage}
                                allowClear
                                placeholder="Select Parent Lead"
                                maxTagCount='responsive'
                                onChange={this.onChangeParentLeadStage}
                                options={this.state.selectedStages.map(item => ({
                                    label: item.label,
                                    value: item.value,
                                }))}
                            />}

                            <div
                                className="text-danger">{this.state.validationerrorsMergeLead.merge_lead_error}</div>

                        </div>
                    </Col>
                </Row>
            </Modal>

            {/* Merge Modal */}


            <Modal title="Add Verification Call" open={this.state.isVerificationOpen} width={1000} centered
                onCancel={this.handleVerificationCallLogClose}
                footer={<>{
                    this.state.call_loader ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                            <Spin style={{ alignSelf: 'center' }} />
                        </div>
                        :
                        <Button id="calllogbtn" onClick={this.handleVerificationCallSubmit} type="primary" className="d-flex flex-row"><i class="bi bi-person-fill-add mr-1"></i>Add Verification Details</Button>
                }
                </>}
            >
                <Row span={24} className='verify-wrap' gutter={8}>
                    <Col span={12}>
                        <Row span={24} className='w-100 addcallpopup-wrap' gutter={8}>
                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Name:</label>
                                    <EdiText value={(this.state.lead_details && this.state.lead_details.owner) ? this.state.lead_details.owner : ""} type="text" onSave={this.handleSaveOwnerName} className='leadetailsowner' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Contact Type<em>*</em>:</label>
                                    <div className='form-text-wrapper'><EasyEdit type={Types.SELECT} value={this.state.contact_type_requirement == undefined || this.state.contact_type_requirement == null || this.state.contact_type_requirement == "" ? 'selected' :
                                        this.state.contact_type_requirement.toString()
                                    }
                                        // options={this.state.agents}
                                        options={this.state.contact_types.map((e) => {
                                            return {
                                                label: e.label, value: e.value.toString()
                                            }
                                        })}
                                        onSave={this.saveContactType}
                                        saveButtonLabel={<CheckOutlined />}
                                        cancelButtonLabel={<CloseOutlined />}
                                        placeholder="Please select"
                                        onValidate={value => { return value != null }}
                                        validationMessage="Add Contact Type"
                                    /></div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Budget<em>*</em>:</label>
                                    <div className='form-text-wrapper'>
                                        <EasyEdit type={Types.TEXT} onSave={this.savePriceFirst} saveButtonLabel={<CheckOutlined />}
                                            cancelButtonLabel={<CloseOutlined />} value={this.state.price_first == null || this.state.price_first == '' ? 'Price' : this.fAmountSaperator(this.state.price_first)} attributes={{ name: "awesome-input", id: 1 }} onValidate={value => { return value != null }} validationMessage="Budget is required" />
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Bedrooms:</label>
                                    <div className='form-text-wrapper'><EasyEdit type={Types.SELECT} value={this.state.requirement_bedroom}
                                        options={[
                                            { label: 'Studio', value: 'Studio' },
                                            { label: '1', value: '1' },
                                            { label: '2', value: '2' },
                                            { label: '3', value: '3' },
                                            { label: '4', value: '4' },
                                            { label: '5', value: '5' },
                                            { label: '6', value: '6' },
                                            { label: '7', value: '7' },
                                            { label: '8', value: '8' },
                                            { label: '9', value: '9' },
                                            { label: '10', value: '10' },
                                            { label: '11', value: '11' },
                                            { label: '12', value: '12' },
                                            { label: '13', value: '13' },
                                            { label: '14', value: '14' },
                                            { label: '15', value: '15' }
                                        ]}
                                        onSave={this.saveNewBedroom}
                                        saveButtonLabel={<CheckOutlined />}
                                        cancelButtonLabel={<CloseOutlined />}
                                    /></div>
                                </div>
                            </Col>


                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Community<em>*</em>:</label>
                                    <div className='form-text-wrapper'><EasyEdit type={Types.SELECT}
                                        value={
                                            this.state.requirement_community == undefined || this.state.requirement_community == null ?
                                                "Select"
                                                :
                                                this.state.requirement_community
                                        }
                                        options={this.state.communities.map((e) => {
                                            return {
                                                label: e.label,
                                                value: e.value.toString()
                                            }
                                        })}
                                        // onSave={this.saveAgent}
                                        onSave={this.saveNewCommunity}
                                        saveButtonLabel={<CheckOutlined />}
                                        cancelButtonLabel={<CloseOutlined />}
                                        onValidate={value => { return value != null }}
                                        validationMessage="Community field is required"
                                    /></div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Sub Community:</label>
                                    <div className='form-text-wrapper'><EasyEdit
                                        type={Types.SELECT}
                                        value={
                                            this.state.requirement_sub_community == undefined || this.state.requirement_sub_community == null ?
                                                "Select"
                                                :
                                                this.state.requirement_sub_community.toString()
                                        }
                                        options={this.state.requirement_sub_communities.map((e) => {
                                            return {
                                                label: e.label,
                                                value: e.value.toString()
                                            }
                                        })}
                                        onSave={this.saveNewSubCommunity}
                                        saveButtonLabel={<CheckOutlined />}
                                        cancelButtonLabel={<CloseOutlined />}


                                    /></div>
                                </div>
                            </Col>
                            {/* {this.state.contact_type_requirement.toString()=="1"? */}
                            <Col span={12}>
                                <div className='form-group w-100'>
                                    <label>Move In Date: <em>*</em></label>
                                    <div className='form-text-wrapper'><EasyEdit type={Types.DATE}
                                        value={
                                            this.state.new_move_in_date
                                            // (this.state.new_move_in_date == "0000-00-00 00:00:00" || this.state.new_move_in_date == null)?
                                            // "Select date"
                                            // :
                                            // <Moment format='DD MMM YYYY'>{this.state.new_move_in_date}</Moment>
                                        }
                                        onSave={this.saveNewMoveInDate}
                                        saveButtonLabel={<CheckOutlined />}
                                        cancelButtonLabel={<CloseOutlined />}
                                        onValidate={value => { return value != null }}
                                        validationMessage="Move In Date is required"
                                    /></div>
                                </div>
                            </Col>
                            {/* // :null} */}
                            {this.state.contact_type_requirement.toString() == "2" ? <>
                                <Col span={12}>
                                    <div className='form-group w-100 justify-content-start'>
                                        <label>Buyer Type: </label>
                                        <div className=''>
                                            <Radio.Group buttonStyle="solid" className='flex-row' onChange={this.handleBuyerType}>
                                                <Radio.Button value="End User">End User</Radio.Button>
                                                <Radio.Button value="Investor">Investor</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12} className="flex-row">
                                    <div className='form-group w-100'>
                                        <label>Payment Type:</label>
                                        <div className=''>
                                            <Radio.Group buttonStyle="solid" className='flex-row' onChange={this.handlePayment}>
                                                <Radio.Button value="Cash" >Cash</Radio.Button>
                                                <Radio.Button value="Finance" >Finance</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {/* If Finance */}

                                </Col>



                                <Col span={12}>
                                    <div className='form-group w-100 justify-content-start'>
                                        <label>Availability for viewings: </label>
                                        <Input onChange={this.handleAvailabilityForViewings} />
                                    </div>
                                </Col>
                                {this.state.ifFinanceYes ?
                                    <Col span={12}>
                                        <div className='form-group w-100'>
                                            <label>Pre-Approved?</label>
                                            <div className=''>
                                                <Radio.Group buttonStyle="solid" className='flex-row' onChange={this.handlePreApproved}>
                                                    <Radio.Button value="Yes">Yes</Radio.Button>
                                                    <Radio.Button value="No">No</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                            </>
                                : this.state.contact_type_requirement.toString() == "1" ? <>
                                    <Col span={12}>
                                        <div className='form-group w-100 justify-content-start'>
                                            <label>Number of cheques:</label>
                                            <div className=''>
                                                <Select
                                                    onChange={this.handleNumberOfCheques}
                                                    options={[
                                                        { value: '1', label: '1', }, { value: '2', label: '2', }, { value: '3', label: '3', }, { value: '4', label: '4', }, { value: '5', label: '5', }, { value: '6', label: '6', }, { value: '7', label: '7', }, { value: '8', label: '8', }, { value: '9', label: '9', }, { value: '10', label: '10', }, { value: '11', label: '11', }, { value: '12', label: '12', }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <Col span={12}>
                                        <div className='form-group w-100 justify-content-start'>
                                            <label>New to Dubai:</label>
                                            <div className=''>
                                                <Radio.Group buttonStyle="solid" className='flex-row' onChange={this.handleNewToDubai}>
                                                    <Radio.Button value="Yes">Yes</Radio.Button>
                                                    <Radio.Button value="No">No</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </Col> */}
                                    <Col span={12}>
                                        <div className='form-group w-100'>
                                            <label>Currently living:</label>
                                            <div className='form-text-wrapper'><EasyEdit type={Types.SELECT}
                                                value={
                                                    this.state.requirement_community == undefined || this.state.requirement_community == null ?
                                                        "Select"
                                                        :
                                                        this.state.requirement_community
                                                }
                                                options={this.state.communities.map((e) => {
                                                    return {
                                                        label: e.label,
                                                        value: e.value.toString()
                                                    }
                                                })}
                                                // onSave={this.saveAgent}
                                                onSave={this.saveCurrentLive}
                                                saveButtonLabel={<CheckOutlined />}
                                                cancelButtonLabel={<CloseOutlined />}
                                                onValidate={value => { return value != null }}
                                                validationMessage="Community field is required"
                                            /></div>
                                        </div>
                                    </Col>
                                </>
                                    : null}
                        </Row>


                        <Row span={24} className='w-100' gutter={8}>
                            <Col span={24} className='w-100 form-group'>
                                <label className="font-10">Call Note:</label>
                                <Mentions style={{ fontSize: 10, width: '100%', fontFamily: "Poppins-Regular" }}
                                    value={this.state.call_log}
                                    onChange={this.onChangeCallNote}
                                    onSelect={this.onSelectCallMentions}
                                    rows={3}
                                    options={this.state.userClients}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        {/* {this.state.client_data.length > 0 ? */}
                        {/* <Table columns={matchverifycol} dataSource={matchverify} style={{maxWidth:"100%", height:'100%'}}
                                pagination={{hideOnSinglePage: true, defaultPageSize:5, pageSize:5}} showHeader={false}
                                   rowSelection={{type:"radio", ...rowSelectionForClient}}
                            /> */}
                        <Table onChange={handleListingTableChange}
                            loading={this.state.isListingsLoading}
                            pagination={{
                                hideOnSinglePage: true, defaultPageSize: 5, pageSize: 5,
                                total:
                                    this.state?.listings?.total > 0 &&
                                    this.state.listings.total,
                                current: this.state.listings.current_page,
                                showTotal: (total, range) =>
                                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,

                                itemRender: itemRender,
                            }} showHeader={false}

                            columns={matchverifycol}

                            dataSource={
                                this.state?.listings?.data
                                    ? this.state?.listings?.data
                                    : []
                            }
                            rowSelection={{ type: "radio", ...rowSelectionForClient }}
                        />

                    </Col>
                </Row>
            </Modal>



            {/* Share via WhatsApp */}
            <Modal title="Share property to the client" open={this.state.isSharetoWhatsApp} width={400}
                // onOk={this.handleCallSubmit}
                onCancel={this.handleisShareWhatsAppClose}
                footer={<>
                    <Button type="primary" className="d-flex flex-row">Submit</Button>
                    <Button type="text" className="d-flex flex-row" style={{ height: '36px' }} onClick={this.handleisShareWhatsAppClose}>Cancel</Button>
                </>}
            >
                <Row className='w-100'>
                    <Col span={24} className='d-flex flex-column'>
                        <p>What works best for you?</p>
                        <Radio.Group className='mb-2'>
                            <Radio value={'Morning'}>Morning</Radio>
                            <Radio value={'Afternoon'}>Afternoon</Radio>
                        </Radio.Group>
                        <Radio.Group>
                            <Radio value={'weekdays'}>Weekdays</Radio>
                            <Radio value={'weekends'}>Weekend</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </Modal>

            {/* Decline Popup */}
            <Modal title="Decline to Share the Property" open={this.state.isdecline} width={600}
                // onOk={this.handleCallSubmit}
                onCancel={this.handleisDeclineClose}
                footer={<>
                    <Button type="primary" className="d-flex flex-row" onClick={this.onClickDecline}>Submit</Button>
                    <Button type="text" className="d-flex flex-row" style={{ height: '36px' }} onClick={this.handleisDeclineClose}>Cancel</Button>
                </>}
            >
                <Row className='w-100'>
                    <Col span={24}>
                        <p>Why do you want to decline this property?</p>
                        <TextArea rows={3} value={this.state.decline_note} onChange={this.changeDeclineNote} />
                    </Col>
                </Row>
            </Modal>

            {/* Call Log Pop up */}
            <Modal title="Add Call" open={this.state.isCallLogOpen} width={800}
                // onOk={this.handleCallSubmit}
                onCancel={this.handleCallLogModalCancel}
                footer={<>{
                    this.state.call_loader ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                            <Spin style={{ alignSelf: 'center' }} />
                        </div>
                        :
                        <Button id="calllogbtn" onClick={this.handleCallSubmit} type="primary" className="d-flex flex-row"><i className="bi bi-plus me-1 fs-5" /> Add call log</Button>
                }
                </>}
            >
                {user_id == 388 || role == 1 ? <Row span={24} className='w-100 addcallpopup-wrap' gutter={8}>
                    <Checkbox
                        checked={this.state.offplan}
                        onChange={this.handleOffplan}
                    >Offplan</Checkbox>
                </Row> : null}

                <Row span={24} className='w-100 addcallpopup-wrap' gutter={8}>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Name:</label>
                            <EdiText value={(this.state.lead_details && this.state.lead_details.owner) ? this.state.lead_details.owner : ""} type="text" onSave={this.handleSaveOwnerName} className='leadetailsowner' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Contact Type<em>*</em>:</label>
                            <div className='form-text-wrapper'><EasyEdit type={Types.SELECT} value={this.state.contact_type_requirement == undefined || this.state.contact_type_requirement == null || this.state.contact_type_requirement == "" ? 'selected' :
                                this.state.contact_type_requirement.toString()
                            }
                                // options={this.state.agents}
                                options={this.state.contact_types.map((e) => {
                                    return {
                                        label: e.label, value: e.value.toString()
                                    }
                                })}
                                onSave={this.saveContactType}
                                saveButtonLabel={<CheckOutlined />}
                                cancelButtonLabel={<CloseOutlined />}
                                placeholder="Please select"
                                onValidate={value => { return value != null }}
                                validationMessage="Add Contact Type"
                            /></div>
                        </div>
                    </Col>
                    {this.state.contact_type_requirement.toString() == "1" ?
                        <Col span={12}>
                            <div className='form-group w-100'>
                                <label>Move In Date: <em>*</em></label>
                                <div className='form-text-wrapper'><EasyEdit type={Types.DATE}
                                    value={
                                        (this.state.requirement_move_in_date == "0000-00-00 00:00:00" || this.state.requirement_move_in_date == null) ?
                                            "Select date"
                                            :
                                            <Moment format='DD MMM YYYY'>{this.state.requirement_move_in_date}</Moment>
                                    }
                                    onSave={this.saveMoveInDate}
                                    saveButtonLabel={<CheckOutlined />}
                                    cancelButtonLabel={<CloseOutlined />}
                                    onValidate={value => { return value != null }}
                                    validationMessage="Move In Date is required"
                                /></div>
                            </div>
                        </Col>
                        : null}
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Budget<em>*</em>:</label>
                            <div className='form-text-wrapper'><EasyEdit type={Types.TEXT} value={this.state.price_requirement == null || this.state.price_requirement == '' ? 'Price' : this.fAmountSaperator(this.state.price_requirement)} onSave={this.savePrice} saveButtonLabel={<CheckOutlined />} cancelButtonLabel={<CloseOutlined />} attributes={{ name: "awesome-input", id: 1 }}
                                onValidate={value => { return value != null }}
                                validationMessage="Budget is required" /></div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Community<em>*</em>:</label>
                            <div className='form-text-wrapper'><EasyEdit type={Types.SELECT}
                                value={
                                    this.state.requirement_community == undefined || this.state.requirement_community == null ?
                                        "Select"
                                        :
                                        this.state.requirement_community
                                }
                                options={this.state.communities.map((e) => {
                                    return {
                                        label: e.label,
                                        value: e.value.toString()
                                    }
                                })}
                                // onSave={this.saveAgent}
                                onSave={this.saveCommunity}
                                saveButtonLabel={<CheckOutlined />}
                                cancelButtonLabel={<CloseOutlined />}
                                onValidate={value => { return value != null }}
                                validationMessage="Community field is required"
                            /></div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Sub Community:</label>
                            <div className='form-text-wrapper'><EasyEdit
                                type={Types.SELECT}
                                value={
                                    this.state.requirement_sub_community == undefined || this.state.requirement_sub_community == null ?
                                        "Select"
                                        :
                                        this.state.requirement_sub_community.toString()
                                }
                                options={this.state.requirement_sub_communities.map((e) => {
                                    return {
                                        label: e.label,
                                        value: e.value.toString()
                                    }
                                })}
                                onSave={this.saveSubCommunity}
                                saveButtonLabel={<CheckOutlined />}
                                cancelButtonLabel={<CloseOutlined />}


                            /></div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='form-group w-100'>
                            <label>Bedrooms:</label>
                            <div className='form-text-wrapper'><EasyEdit type={Types.SELECT} value={this.state.requirement_bedroom}
                                options={[
                                    { label: 'Studio', value: 'Studio' },
                                    { label: '1', value: '1' },
                                    { label: '2', value: '2' },
                                    { label: '3', value: '3' },
                                    { label: '4', value: '4' },
                                    { label: '5', value: '5' },
                                    { label: '6', value: '6' },
                                    { label: '7', value: '7' },
                                    { label: '8', value: '8' },
                                    { label: '9', value: '9' },
                                    { label: '10', value: '10' },
                                    { label: '11', value: '11' },
                                    { label: '12', value: '12' },
                                    { label: '13', value: '13' },
                                    { label: '14', value: '14' },
                                    { label: '15', value: '15' }
                                ]}
                                onSave={this.saveBedroom}
                                saveButtonLabel={<CheckOutlined />}
                                cancelButtonLabel={<CloseOutlined />}
                            /></div>
                        </div>
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <div className="form-group">*/}
                    {/*        <label for="matchingPfor" className=''>Property For:</label>*/}
                    {/*        <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1} value={this.state.property_for_from_requirement}>*/}
                    {/*            <option value>Select</option>*/}
                    {/*            <option value="rent">Rent</option>*/}
                    {/*            <option value="sale">Sale</option>*/}
                    {/*            <option value="short-term">Short Term</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                <Row span={24} className='w-100'>
                    <Col span={24} className='w-100 form-group'>
                        <label className="font-10">Call Note:</label>
                        <Mentions style={{ fontSize: 10, width: '100%', fontFamily: "Poppins-Regular" }}
                            value={this.state.call_log}
                            onChange={this.onChangeCallNote}
                            onSelect={this.onSelectCallMentions}
                            rows={3}
                            options={this.state.userClients}
                        />
                    </Col>
                </Row>
            </Modal>
            <Drawer title={`Lead details`}
                placement="left"
                // size={"large"}
                width={"98%"}
                onClose={this.handleCloseDrawer}
                open={this.state.open}
            // extra={
            //   <Space>
            //     <Button type='primary' danger ghost onClick={this.handleCloseDrawer} icon={<CloseOutlined /> }>Cancel</Button>
            //     <Button type="primary" onClick={this.handleSaveData}>
            //       Save changes
            //     </Button>
            //   </Space>
            // }
            >

                <div className="cont-wrap container">
                    {
                        this.state.loader ?
                            <div style={{ position: 'absolute', alignSelf: "center", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} >
                                <Spin className='spinnerOp' size='large' indicator={<Spin size="large" style={{ color: '#151515' }} />} />
                            </div>
                            :
                            <>
                                <div className="row">
                                    <div className="col-12 d-flex flex-row toptitle">
                                        <button className="btn btn-primary" disabled={this.state.isPreviousLoading || this.state.disabledPrev ? true : false} onClick={this.state.loader ? () => console.log('change') : this.handlePreviousRecord} >{this.state.isPreviousLoading ? <BeatLoader color={"blue"} loading={true} size={30} /> : "Previous"}</button>
                                        <div className="d-flex w-100 ps-3"><h2 className="page-title fs-5 font-bold"><i className="bi bi-pencil-fill me-2" />Leads Details - {this.state.lead_details.refno}</h2></div>
                                        <button className="btn btn-primary" disabled={this.state.isNextLoading || this.state.disabledNext ? true : false} onClick={this.state.loader ? () => console.log('change') : this.handleNextRecord}  >{this.state.isNextLoading ? <BeatLoader color={"blue"} loading={true} size={30} /> : "Next"}</button>
                                    </div>
                                </div>


                                <div className="row column-wrap mt-3">
                                    <div className="col-12 col-md-3" >


                                        {/** Contact Details */}

                                        <div className="bg-white border-top ownersdetails">
                                            {
                                                // role == 1 || isUserFound || user_id.toString() == this.state.requirement_agent_id.toString() ?
                                                role == 1 || isUserFound || this.state.collaborators.some((data) => data.user_id == user_id) || user_id.toString() == this.state.requirement_agent_id.toString() || this.state.lead_details.lead_stage == 10 || this.state.lead_details.lead_agent == 430 ?
                                                    <>

                                                        <div className="d-flex flex-row justify-content-between"  >
                                                            <h3>Contact Details</h3>
                                                            <button type="button" className="btn btn-primary requestchange">Change Details</button>
                                                        </div>
                                                        <div className="d-flex flex-column formfieldset mt-3">
                                                            <div className="d-flex flex-column form-group">
                                                                <label className="control-labels mr-4">Name</label>

                                                                <EdiText
                                                                    value={(this.state.lead_list_details && this.state.lead_list_details.owner) ? this.state.lead_list_details.owner : ""}
                                                                    type="text"
                                                                    onSave={this.handleSaveOwnerName}

                                                                />
                                                            </div>
                                                            <div className="d-flex flex-column form-group">
                                                                <label className="control-labels mr-4">Phone </label>
                                                                <p className="form-control-disabled">{this.state.owner_mobile}</p>
                                                            </div>
                                                            <div className="d-flex flex-column form-group">
                                                                <label className="control-labels mr-4"> Email </label>
                                                                <EdiText
                                                                    value={(this.state.lead_list_details && this.state.lead_list_details.owner_email) ? this.state.lead_list_details.owner_email : ""}
                                                                    type="text"
                                                                    onSave={this.handleSaveOwnerEmail}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="bg-white  ownersdetails mt-3">
                                                            <div className="d-flex flex-column form-group mb-0">
                                                                <label className="control-labels mr-4">Other Enquires</label>
                                                                <div className="col-12 d-flex flex-column border-wrap listtable-wrap">

                                                                    {this.state.owner_other_properties.map((owner_other_property) => {
                                                                        var pStatus = 'Draft'
                                                                        switch (owner_other_property.status) {
                                                                            case 'D':
                                                                                pStatus = 'Draft'
                                                                                break;
                                                                            case 'UC':
                                                                                pStatus = 'Confirmed Owner'
                                                                                break;
                                                                            case 'N':
                                                                                pStatus = 'Unpublish'
                                                                                break;
                                                                            case 'OM':
                                                                                pStatus = 'Off-Market'
                                                                                break;
                                                                            case 'CTM':
                                                                                pStatus = 'Coming to market'
                                                                                break;
                                                                            case 'Y':
                                                                                pStatus = 'Published'
                                                                                break;
                                                                            case 'U':
                                                                                pStatus = 'Action'
                                                                                break;

                                                                            case 'SEO':
                                                                                pStatus = 'SEO'
                                                                                break;
                                                                            case 'L':
                                                                                pStatus = 'Unlisted'
                                                                                break;
                                                                            case 'S':
                                                                                pStatus = 'Sold'
                                                                                break;
                                                                            case 'T':
                                                                                pStatus = 'Let'
                                                                                break;
                                                                            case 'P':
                                                                                pStatus = 'Review'
                                                                                break;
                                                                            case 'F':
                                                                                pStatus = 'Feeds'
                                                                                break;
                                                                            default:
                                                                                pStatus = 'Draft'
                                                                                break;
                                                                        }
                                                                        return (
                                                                            <a
                                                                                target="_blank"
                                                                                // href={"https://www.luxuryproperty.com/property/preview/" + owner_other_property.ref_no+"-"+owner_other_property.listing_id}
                                                                                href={liveUrl + "/all_listings/" + owner_other_property.listing_id}
                                                                                className="d-flex flex-row p-2 w-100"
                                                                                style={{ textDecorationLine: 'none' }}
                                                                            >
                                                                                <div className="col-8 listtable">
                                                                                    <p className="propfor">{owner_other_property.property_for}</p>
                                                                                    <p className="refno">{owner_other_property.refno}</p>
                                                                                    <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                                                                    <p className="community">{owner_other_property.community}</p>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <div className="d-flex flex-row stat publishstat">
                                                                                        <i className="bi bi-circle-fill" />
                                                                                        <span>{pStatus}</span>
                                                                                    </div>
                                                                                    <div className="agentprop">
                                                                                        <p className="name">{owner_other_property.agent}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        )
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null

                                            }





                                        </div>
                                        {/*** END */}
                                        {this.state.lead_details.verified == 1 ? null :
                                            <div className='editleadwrap addtoverification mt-2 py-3'>
                                                {
                                                    !this.state.lead_details.verifier || this.state.lead_details.verifier == null || this.state.lead_details.verifier == "" ?
                                                        <Button type="text" className='mt-0 font-bold pl-0' onClick={this.handleAddVerification}><i className='bi bi-telephone-plus mr-2'></i>Add to Verification Call Log</Button>
                                                        :
                                                        <Button type="text" className='mt-0 font-bold pl-0' onClick={this.handleRemoveVerification}><i className='bi bi-telephone-plus mr-2'></i>Remove Verification Call Log</Button>

                                                }

                                            </div>
                                        }
                                        <div className='editleadwrap referral mt-2 py-3'>
                                            <Collapse ghost>
                                                <Panel header={<>
                                                    <span className='font-bold'>Referral</span>
                                                </>} key="5">
                                                    <Row>
                                                        <Col span={24}>
                                                            <div className="form-group mt-2">
                                                                <label className='mb-0'>Referred by:</label>
                                                                <Select showSearch placeholder="Add Agent"
                                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                                    name="agents_assign"
                                                                    disabled={role_name != "Admin" && role_name != "Super Admin" && this.state.is_referred}
                                                                    defaultValue={this.state.referred_by}
                                                                    onChange={this.changeReferred_by}
                                                                >
                                                                    <Option
                                                                        value="">Refer agent</Option>
                                                                    {this.state.agents.map((agent) => {
                                                                        return <>
                                                                            <Option
                                                                                value={agent.value}>{agent.label}</Option>
                                                                        </>
                                                                    })}
                                                                </Select>
                                                            </div>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div className="form-group">
                                                                <label className='mb-0'>Referred to:</label>
                                                                <Select showSearch placeholder="Add Agent"
                                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                                    name="agents_assign"
                                                                    disabled={role_name != "Admin" && role_name != "Super Admin" && this.state.is_referred}
                                                                    defaultValue={this.state.referred_to}
                                                                    onChange={this.changeReferred_to}
                                                                >
                                                                    <Option
                                                                        value="">Refer agent</Option>
                                                                    {this.state.agents.map((agent) => {
                                                                        return <>
                                                                            <Option
                                                                                value={agent.value}>{agent.label}</Option>
                                                                        </>
                                                                    })}
                                                                </Select>
                                                            </div>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div className="form-group mt-2">
                                                                <Button
                                                                    onClick={this.onSaveRef}
                                                                    type="primary"
                                                                    disabled={role_name != "Admin" && role_name != "Super Admin" && this.state.is_referred}
                                                                    className="d-flex flex-row">
                                                                    <i className="bi bi-plus me-1 fs-5" />
                                                                    Save</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Panel>
                                            </Collapse>
                                        </div>

                                        {/* FORMS */}
                                        <div className="bg-white border-top offers p-4 mt-3">
                                            <div className="d-flex flex-row justify-content-between">
                                                <h3 className="mr-4 mb-3">Requirements </h3>
                                            </div>
                                            <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
                                                <div className="form-group">
                                                    <Checkbox checked={this.state.pin} onChange={this.onChangePin}>Pin</Checkbox>

                                                </div>
                                                <div className="form-group"> {/* MULTISELECT */}
                                                    <label className="control-labels ">Lead Stage</label>
                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        //value={this.state.requirement_lead_stage.toString()}

                                                        value={this.state.requirement_lead_stage == undefined || this.state.requirement_lead_stage == null || this.state.requirement_lead_stage == "" ?
                                                            ''
                                                            :
                                                            this.state.requirement_lead_stage.toString()
                                                        }

                                                        options={role == 1 ? this.state.lead_stage_filter : this.state.lead_stage_filter_agent}
                                                        onSave={this.saveLeadStage}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}
                                                        placeholder={'please select'}
                                                        instructions="Custom instructions"
                                                    />

                                                </div>
                                                <div className="form-group">
                                                    <label className="control-labels ">Verifier</label>
                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        // value={this.state.requirement_verifyedby.toString()}
                                                        value={this.state.requirement_verifyedby == undefined || this.state.requirement_verifyedby == null || this.state.requirement_verifyedby == "" ?
                                                            ''
                                                            :
                                                            this.state.requirement_verifyedby.toString()
                                                        }
                                                        options={[
                                                            { label: 'None', value: '0' },
                                                            { label: 'Danielle Robinson', value: '375' },
                                                            { label: 'Christalline Ybanez', value: '427' },
                                                        ]}

                                                        onSave={this.saveVerifier}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}
                                                        instructions="Custom instructions"
                                                        placeholder="Please select"
                                                    />

                                                </div>

                                                <div className="form-group">
                                                    <label className="control-labels ">Contact Type</label>

                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        value={this.state.contact_type_requirement == undefined || this.state.contact_type_requirement == null || this.state.contact_type_requirement == "" ?
                                                            'selected'
                                                            :
                                                            this.state.contact_type_requirement.toString()
                                                        }
                                                        // options={this.state.agents}
                                                        options={this.state.contact_types.map((e) => {
                                                            return {
                                                                label: e.label, value: e.value.toString()
                                                            }
                                                        })}
                                                        onSave={this.saveContactType}
                                                        onChange={this.onChangeContactType}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}
                                                        instructions="Custom instructions"
                                                        placeholder="Please select"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-labels ">Agent Name</label>

                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        value={this.state.requirement_agent_id == undefined || this.state.requirement_agent_id == null || this.state.requirement_agent_id == "" ?
                                                            ''
                                                            :
                                                            this.state.requirement_agent_id.toString()
                                                        }
                                                        // options={this.state.agents}
                                                        options={this.state.agents.map((e) => {
                                                            return {
                                                                label: e.label, value: e.value.toString()
                                                            }
                                                        })}
                                                        onSave={this.saveAgent}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}
                                                        instructions="Custom instructions"
                                                        placeholder="Please select"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-labels ">Move in Date</label>
                                                    <EasyEdit
                                                        type={Types.DATETIME_LOCAL}
                                                        value={
                                                            this.state.requirement_move_in_date == "0000-00-00 00:00:00" ?
                                                                "select date"
                                                                :
                                                                this.state.requirement_move_in_date
                                                        }
                                                        onSave={this.saveMoveInDate}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}
                                                        instructions="Select your date and time"
                                                    />
                                                    {/* <input value={this.state.lead_details.mov_in} onChange={this.handleRequirementMoveInDate} type="datetime-local" id="move_in_date" className="form-control" defaultValue /> */}
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-labels ">Price</label>
                                                    <div className="d-flex flex-row" style={{ gap: '0.6rem' }}>

                                                        <EasyEdit
                                                            type={Types.TEXT}
                                                            value={this.state.price_requirement == null || this.state.price_requirement == '' ?
                                                                '0'
                                                                :
                                                                this.fAmountSaperator(this.state.price_requirement)
                                                            }
                                                            onSave={this.savePrice}
                                                            saveButtonLabel={<CheckOutlined />}
                                                            cancelButtonLabel={<CloseOutlined />}
                                                            attributes={{ name: "awesome-input", id: 1 }}
                                                            instructions="Star this repo!"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-labels ">Bedrooms</label>
                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        value={this.state.requirement_bedroom}

                                                        options={[
                                                            { label: 'Studio', value: 'Studio' },
                                                            { label: '1', value: '1' },
                                                            { label: '2', value: '2' },
                                                            { label: '3', value: '3' },
                                                            { label: '4', value: '4' },
                                                            { label: '5', value: '5' },
                                                            { label: '6', value: '6' },
                                                            { label: '7', value: '7' },
                                                            { label: '8', value: '8' },
                                                            { label: '9', value: '9' },
                                                            { label: '10', value: '10' },
                                                            { label: '11', value: '11' },
                                                            { label: '12', value: '12' },
                                                            { label: '13', value: '13' },
                                                            { label: '14', value: '14' },
                                                            { label: '15', value: '15' }
                                                        ]}
                                                        onSave={this.saveBedroom}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}


                                                    />


                                                </div>
                                                <div className="form-group">
                                                    <label className="control-labels ">Community</label>
                                                    {/* <Select
                              style={{
                                width: '100%',
                              }}
                              defaultValue={this.state.requirement_community}
                              showSearch
                              placeholder="Search"
                              onChange={this.handleRequirementCommunity}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            /> */}

                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        value={
                                                            this.state.requirement_community == undefined || this.state.requirement_community == null ?
                                                                "select"
                                                                :
                                                                this.state.requirement_community.toString()
                                                        }
                                                        options={this.state.communities.map((e) => {
                                                            return {
                                                                label: e.label,
                                                                value: e.value.toString()
                                                            }
                                                        })}
                                                        // onSave={this.saveAgent}
                                                        onSave={this.saveCommunity}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}


                                                    />

                                                </div>
                                                <div className="form-group">
                                                    <label className="control-labels ">Sub Community</label>

                                                    <EasyEdit
                                                        type={Types.SELECT}
                                                        value={
                                                            this.state.requirement_sub_community == undefined || this.state.requirement_sub_community == null ?
                                                                "select"
                                                                :
                                                                this.state.requirement_sub_community.toString()
                                                        }
                                                        options={this.state.requirement_sub_communities.map((e) => {
                                                            return {
                                                                label: e.label,
                                                                value: e.value.toString()
                                                            }
                                                        })}
                                                        onSave={this.saveSubCommunity}
                                                        saveButtonLabel={<CheckOutlined />}
                                                        cancelButtonLabel={<CloseOutlined />}


                                                    />
                                                    {/* <Select
                              style={{
                                width: '100%',
                              }}
                              // mode={"multiple"}
                              value={this.state.requirement_sub_community}
                              showSearch
                              maxTagCount={"responsive"}
                              placeholder="Search"
                              optionFilterProp="children"
                              onChange={this.handleRequirementSubCommunity}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.requirement_sub_communities}
                            /> */}

                                                </div>
                                            </div>
                                            {/**  HIDE SAVE BUTTON FOR NOW  */}
                                            {/* <button onClick={this.handleRequirementSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button> */}
                                        </div>{/* END FORMS*/}

                                        <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4 mt-4">
                                            <h3>Match Details</h3>
                                            <div className="d-flex flex-column pt-3">
                                                <div className="d-flex flex-column matchfilter">
                                                    <div className="col-12 px-0">
                                                        <div className="form-group">
                                                            <label for="community" className="mb-0">Community:</label>
                                                            <Select id="community"
                                                                style={{
                                                                    width: 160,
                                                                }}

                                                                showSearch
                                                                // allowClear
                                                                // defaultValue={this.state.community_match.toString()}
                                                                // value={
                                                                //   this.state.community_match == undefined  || this.state.community_match == null ||this.state.community_match=="" ?
                                                                //     "Select"
                                                                //     :
                                                                //     this.state.community_match.toString()
                                                                //   }

                                                                placeholder="Select a person"
                                                                optionFilterProp="children"
                                                                onChange={this.handleCommunityMatch}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                // options={this.state.communities}

                                                                options={this.state.communities.map((e) => {
                                                                    return {
                                                                        label: e.label, value: e.value.toString()
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <div className="form-group">
                                                            <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub-Community:</label>
                                                            <Select id="propsubcommunity"
                                                                style={{
                                                                    width: 160,
                                                                }}
                                                                // mode={"multiple"}

                                                                showSearch
                                                                // value={this.state.sub_community_match.toString()}
                                                                //  value={
                                                                //   this.state.sub_community_match == undefined  || this.state.sub_community_match == null ||this.state.sub_community_match=="" ?
                                                                //     "Select"
                                                                //     :
                                                                //     this.state.sub_community_match.toString()
                                                                //   }
                                                                maxTagCount={"responsive"}
                                                                placeholder="Select a person"
                                                                optionFilterProp="children"
                                                                onChange={this.handleSubCommunityMatch}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                //options={this.state.sub_communities}
                                                                options={this.state.sub_communities.map((e) => {
                                                                    return {
                                                                        label: e.label, value: e.value.toString()
                                                                    }
                                                                })}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <div className="form-group d-flex flex-row budget">
                                                            <label for="matchingBudgetMin">Budget:</label>

                                                            <div className="d-flex flex-row">
                                                                <CurrencyInput
                                                                    id="input-min"
                                                                    name="input-min"
                                                                    placeholder="Min"
                                                                    value={this.state.min_budget}
                                                                    decimalsLimit={2}
                                                                    onChange={this.handleMinBudget}
                                                                />
                                                                {/* <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" /> */}
                                                                <span className="dash" style={{}}>-</span>
                                                                <CurrencyInput
                                                                    id="input-max"
                                                                    name="input-max"
                                                                    placeholder="Max"
                                                                    value={this.state.max_budget}

                                                                    decimalsLimit={2}
                                                                    onChange={this.handleMaxBudget}
                                                                />
                                                                {/* <input type="text" onChange={this.handleMaxBudget}  className="currencyInput" id="matchingBudgetMax" placeholder="Max" /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <div className="form-group">
                                                            <label for="matchingBedrooms">Beds:</label>
                                                            <select value={this.state.bedroom_match} onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                                                                <option value>Select</option>
                                                                <option value="Studio">Studio</option>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3} >3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                                <option value={11}>11</option>
                                                                <option value={12}>12</option>
                                                                <option value={13}>13</option>
                                                                <option value={14}>14</option>
                                                                <option value={15}>15</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <div className="form-group d-flex flex-row w-100">
                                                            <label for="addedDateM">Added Date:</label>
                                                            <select onChange={this.handleAddedDateMatch} id="addedDateM" className="form-control" title tabIndex={-1}>
                                                                <option value>Select</option>
                                                                <option value={1}>Last 24 Hours</option>
                                                                <option value={3}>Last 3 Days</option>
                                                                <option value={7}>Last 7 Days</option>
                                                                <option value={14}>Last 14 Days</option>
                                                                <option value={30}>Last 1 Month</option>
                                                                <option value={90}>Last 3 Month</option>
                                                                <option value={180}>Last 6 Month</option>
                                                                <option value={365}>Last 1 Year</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <div className="form-group d-flex flex-row">
                                                            <label for="matchingPfor">Property For:</label>
                                                            <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1}>
                                                                <option value>Select</option>
                                                                <option value="rent">Rent</option>
                                                                <option value="sale">Sale</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <div className="form-group d-flex flex-row">
                                                            <label for="matchingType">Type:</label>
                                                            <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                                                                <option>select</option>
                                                                {this.state.propertytypes.map((propertytype) => {
                                                                    return <option value={propertytype.value}>{propertytype.label}</option>
                                                                })}


                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                                                    </div>

                                                </div>

                                                <div className="matchresults">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <label className="control-labels mr-4">Match with access</label>
                                                    </div>
                                                    <div className="matchresult">
                                                        {this.state.matchs.map((match) => {
                                                            const match_price = match.price


                                                            return (
                                                                <a href className="matchitem">
                                                                    <div className="matchinfo">
                                                                        <div className="col-12 col-md-9 px-0">
                                                                            <p className="address">{match.unitno}</p>
                                                                            <p className="address">{match.address}</p>
                                                                            <p className="address"> {match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'}</p>

                                                                        </div>
                                                                        <div className="col-3">
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="matchhistory">
                                                                        <p className="addeddate">
                                                                            <Moment fromNow>
                                                                                {match.added_date}
                                                                            </Moment>
                                                                        </p>
                                                                        <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                                                    </div>
                                                                </a>
                                                            )
                                                        })}

                                                        {this.state.matchs.length > 0 ? <Link onClick={this.handleAllMatch} className="matchitem">

                                                            View All

                                                        </Link> : null}

                                                    </div>
                                                </div>

                                            </div>
                                        </div> {/* END Match*/}
                                    </div>
                                    <div className="col-12 col-md-6 timeline-wrap">

                                        <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4 timelinelist">
                                            <h3>Timeline</h3>
                                            {/* ADD NOTES */}

                                            <div className='tabtimeline'>
                                                <Mentions
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    value={this.state.note_log}
                                                    onChange={this.onChangeNoteNote}
                                                    onSelect={this.onSelectNoteMentions}
                                                    options={this.state.userClients} rows={2}
                                                />
                                                {/* <textarea id="logsText" onChange={this.handleNoteLog} rows={4} placeholder="Add note.." className="font-14 mentionnote" defaultValue={""} /> */}
                                                {
                                                    this.state.note_loader ?
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                            <Spin style={{ alignSelf: 'center' }} />
                                                        </div>
                                                        :
                                                        <div className='d-flex flex-row pt-2 mb-2 mx-auto justify-content-center timelineaddbtns'>
                                                            <Button id="logsbtn" onClick={this.handleNoteSubmit} type="primary" className="d-flex flex-row"><i className="bi bi-plus me-1 fs-5" />Add note</Button>
                                                            <Button id="calllogbtn" type="primary" onClick={this.handleCallLogModal} className="d-flex flex-row align-items-center mb-3 ml-2"><i className="bi bi-plus me-1 fs-5" /> <span className='font-12'>Add Call</span></Button>
                                                            <Button type='primary' onClick={this.handleNoAnswer} className='mb-3 ml-2'><i class="bi bi-telephone-x me-2"></i><span className='font-12'>No Answer</span></Button>
                                                            {role == 1 || user_id == 488 || user_id == 497 || user_id == 498 ?
                                                                <Button type='primary' onClick={this.handleVerificationCallLogOpen} className='mb-3 ml-2'><i class="bi bi-check-all me-1 fs-5"></i><span className='font-12'>Add Verification Call</span></Button>
                                                                : null}
                                                        </div>
                                                }

                                            </div>
                                            <div className='filteractivity'>
                                                <p className='font-bold' style={{ fontSize: 14 }}>Activity Log</p>
                                                {/* <Button type='text'>
                                                        <i class="bi bi-funnel"></i>
                                                        <span>Filter Activity</span>
                                                    </Button> */}
                                            </div>
                                            <Tabs>
                                                {/* <TabList> */}
                                                {/* <Tab>All</Tab> */}
                                                {/* <Tab>Notes</Tab>
                                                        <Tab>Calls</Tab>
                                                        <Tab>WhatsApp</Tab> */}
                                                {/* </TabList> */}
                                                <TabPanel>

                                                    <Timeline>

                                                        {/* <Timeline.Item dot={<i class="bi bi-person-fill-check"></i>} className={"timeverification"}>
                                                            <div className='d-flex flex-column'>
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>
                                                                        <Avatar className='d-flex mt-1' size={25} icon={ <img src="https://luxuryproperty.s3.ap-south-1.amazonaws.com/uploads/users/images/George%20Hughes.png"/>} />
                                                                        <p className="awriter">
                                                                            <span className="font-bold">George Hughes</span>
                                                                            <span> verified a call.</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className='align-content-center d-flex'>
                                                                        <p className="atime align-self-center">
                                                                            <Moment fromNow>2023-07-17 07:43:35</Moment>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='adesc green'>
                                                                            <Row className="listinginfowrap flex-row">
                                                                                <Col span={24} className='d-flex flex-row'>
                                                                                    <div className='d-flex flex-column'>
                                                                                        <p className='font-bold'>Jermaine</p>
                                                                                        <p>Buyer</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <div class="info-group">
                                                                                        <p class="info-title">Budget:</p>
                                                                                        <p>20,000,000 AED</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <div class="info-group">
                                                                                        <p class="info-title">Location:</p>
                                                                                        <p>Alaya, Tilal Al Ghaf</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <div class="info-group">
                                                                                        <p class="info-title">Beds:</p>
                                                                                        <p>4</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <div class="info-group">
                                                                                        <p class="info-title">Comment:</p>
                                                                                        <p>Client went missing for a week. Spoke to him yesterday and he said by Tuesday will be in touch with an update. Donâ€™t really trust the client anymore but let see.</p>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                </div>
                                                            </div>
                                                        </Timeline.Item> */}


                                                        {this.state.requested_viewings && this.state.requested_viewings.map((requested_viewing) => (
                                                            <Timeline.Item dot={<i class="bi bi-house-fill"></i>} className={"timerequested_viewing"}>
                                                                <div className='d-flex flex-column'>

                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                            <Avatar className='d-flex mt-1' size={25} icon={<img src={requested_viewing.profile_photo == null || requested_viewing.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(requested_viewing.profile_photo) ? requested_viewing.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + requested_viewing.profile_photo} />} />
                                                                            <p className="awriter">
                                                                                <span className="font-bold">{requested_viewing.by_user_name}</span>
                                                                                <span> wants you to share property to your client.</span>
                                                                            </p>
                                                                        </div>
                                                                        <div className='align-content-center d-flex'>
                                                                            <p className="atime align-self-center">
                                                                                <Moment fromNow>
                                                                                    {(requested_viewing.added_date) ? this.getCurrentTime(requested_viewing.added_date) : "-"}
                                                                                </Moment>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='adesc p-0'>
                                                                        <div className='showpropertycontent'>
                                                                            <a href=""
                                                                                className='font-bold'>{requested_viewing.note}</a>
                                                                            <p className="font-semibold">{requested_viewing.unitno}, {requested_viewing.community}, {requested_viewing.subcommunity} <i
                                                                                className="bi bi-dot"></i><span>{(requested_viewing.price) ? this.getPrice(requested_viewing.price) : "-"}</span>
                                                                            </p>
                                                                        </div>
                                                                        <Row className='showpropbtm'>
                                                                            <Col span={6} className='showprop-timer'>
                                                                                <i className="bi bi-hourglass-split fs-6"></i>
                                                                                <span className='font-bold'>{this.state.remainingTime}</span>
                                                                            </Col>
                                                                            <Col span={17}
                                                                                className='showprop-actions'>
                                                                                <Button type='primary'
                                                                                    size='small'
                                                                                    onClick={this.handleRequestNewViewing.bind(this, requested_viewing)}>Book
                                                                                    a viewing</Button>
                                                                                <Button type='primary'
                                                                                    size='small'
                                                                                    disabled={this.state.isWhatsappLoading}
                                                                                    onClick={this.handleisShareWhatsApp.bind(this, requested_viewing)}>Share
                                                                                    via
                                                                                    WhatsApp  {this.state.isWhatsappLoading && <Spin style={{ alignSelf: 'center' }} />}</Button>
                                                                                <Button type='primary'
                                                                                    size='small'
                                                                                    onClick={this.handleisDecline.bind(this, requested_viewing)}>Decline</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </Timeline.Item>
                                                        ))}

                                                        {/*comment section*/}
                                                        {/*{this.state.comment &&*/}
                                                        {/*    <Timeline.Item dot={<i className="bi bi-chat-fill"></i>} className={"timecomment"}>*/}
                                                        {/*        <div className='d-flex flex-column'>*/}
                                                        {/*            <div className='d-flex flex-row justify-content-between'>*/}
                                                        {/*                <div className='d-flex flex-row align-items-center w-100' style={{gap:'0.3rem'}}>*/}
                                                        {/*                    <Avatar className='d-flex mt-1' size={25} icon={ <img src={"/images/defaultLogo.png"}/>} />*/}
                                                        {/*                    <p className="awriter"><span>CRM added a note</span></p>*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className='d-flex adesc'>{parse('<div>' + this.state.comment + '</div>')}</div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </Timeline.Item>*/}
                                                        {/*        */}
                                                        {/*    }*/}
                                                        {/*comment section*/}


                                                        {this.state.all.map((al, index) => {
                                                            return (<>
                                                                {al.note_type == "note" || al.note_type == "email" ?
                                                                    <Timeline.Item dot={al.note_type == "note" ? <i class="bi bi-pin-angle-fill"></i> : al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : <i class="bi bi-pin-angle-fill"></i>} className={"time" + al.note_type}>
                                                                        <div className='d-flex flex-column'>
                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                    <Avatar className='d-flex mt-1' size={25} icon={<img src={
                                                                                        al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                                                            : al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                    <p className="awriter">
                                                                                        <span className="font-bold">{al.by_user_name ? al.by_user_name : al.note && al.note.includes('archived the chat') ? '' : al.note.includes('Expired') ? 'Automation' : 'CRM'}</span>
                                                                                        <span>
                                                                                            {al.note && al.note.includes('archived the chat') ? 'A client sent a message in the chat'
                                                                                                // :al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                                                                : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                                                                    ' updated a requirement'
                                                                                                    : al.note && al.note.includes('Expired') ? ' changed the status'
                                                                                                        : ' added a note'}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className='align-content-center d-flex'>
                                                                                    <p className="atime align-self-center">
                                                                                        <Moment fromNow>
                                                                                            {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                        </Moment>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            {al.note && al.note.includes("Call start :") ?
                                                                                <div className='d-flex adesc'><p className='font-12'>{al.note && parse(al.note)}</p></div>
                                                                                : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                                                    <>{al.note && this.handleSplitReq(al.note)}</>
                                                                                    // :al.note.includes('property on Bayut') ?<span className="">{al.note}</span>
                                                                                    : al.note && al.note.includes('archived the chat') ? <div className='d-flex adesc'>{al.note && parse(al.note)}</div>
                                                                                        : al.note.includes('Expired') ? <>
                                                                                            <div className='d-flex flex-row w-100'>
                                                                                                {(al.note) && this.handleSplitReq(al.note)}
                                                                                            </div>
                                                                                        </>
                                                                                            : <div className='d-flex adesc'>
                                                                                                <div className='d-flex flex-column'>
                                                                                                    <p className='font-12 mb-1'>
                                                                                                        {al.note && parse(al.note)}
                                                                                                    </p>
                                                                                                    {al.lp_details ?
                                                                                                        <div className='lp_details'>

                                                                                                            <Carousel arrows style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}><Image.PreviewGroup>
                                                                                                                {this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                                                                    return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                                                                                })}
                                                                                                            </Image.PreviewGroup></Carousel>
                                                                                                            <div className='lpdetails_text w-100'>
                                                                                                                {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                                                                                    : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                                                                        : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                                                                            : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                                                                                : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                                                                                    : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                                                                                        : null
                                                                                                                }
                                                                                                                <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                                                                                <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                                                                                <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : null}
                                                                                                </div>
                                                                                            </div>}
                                                                            {al.note && al.note.includes("@") && !al.note.includes('archived the chat') ?
                                                                                <>
                                                                                    <div className='d-flex flex-row w-100 taggedreply'>
                                                                                        <Avatar className='d-flex mt-1 mr-1' size={25} icon={<img src={role_profile == null || role_profile == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(role_profile) ? role_profile : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + role_profile} />} />
                                                                                        <div className='d-flex flex-row w-100'>
                                                                                            <TextArea placeholder='Reply' className='adesctarea' rows={1} onChange={event => this.onEnterReply(event, index)} value={this.state.reply[index] || ''} />
                                                                                            <Button type='primary' onClick={this.onClickReply.bind(this, al, index)}>Reply</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Timeline className='d-flex flex-column mt-2'>
                                                                                        {al.children && al.children.length > 0 && al.children != [] && al.children.map((child, index) => (
                                                                                            /* Request Accepted : Book a viewing */
                                                                                            child && child.note_type == "reply" ?
                                                                                                <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"mt-2 time" + al.note_type}>
                                                                                                    <div className='d-flex flex-column'>
                                                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                <Avatar className='d-flex mt-1' size={25} icon={<img src={child.profile_photo == null || child.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(child.profile_photo) ? child.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + child.profile_photo} />} />
                                                                                                                <p className="awriter">
                                                                                                                    <span className="font-bold">{child.by_user_name}</span>
                                                                                                                    <span> replied</span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className='align-content-center d-flex'>
                                                                                                                <p className="atime align-self-center">
                                                                                                                    <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : "-"}
                                                                                                                    </Moment>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="adesc">
                                                                                                            <span>{child.note}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Timeline.Item>
                                                                                                : null))}
                                                                                    </Timeline>
                                                                                </>
                                                                                : null}
                                                                        </div>
                                                                    </Timeline.Item>
                                                                    : al.note_type == "phone" ?
                                                                        <Timeline.Item dot={<i className="bi bi-telephone-fill"></i>} className={"time" + al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                        <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                            <span> logged a call</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex adesc'>
                                                                                    <p className='font-12'>
                                                                                        {(al.note && al.note != null) && parse(al.note)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                        : al.note_type == "verify" ?
                                                                            <Timeline.Item dot={<i className="bi bi-person-fill-check"></i>} className={"time" + al.note_type}>
                                                                                <div className='d-flex flex-column'>
                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                            <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                            <p className="awriter">
                                                                                                <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                                <span> verified this lead</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className='align-content-center d-flex'>
                                                                                            <p className="atime align-self-center">
                                                                                                <Moment fromNow>
                                                                                                    {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                                </Moment>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    {(al.note && al.note != null) && parse(al.note)}
                                                                                </div>
                                                                            </Timeline.Item>
                                                                            : al.note_type == "whatsapp" ?
                                                                                <Timeline.Item dot={<i className="bi bi-whatsapp"></i>} className={this.state.whatsapp_from == 1 ? 'timewhatsapp wa_child' : 'timewhatsapp wa_parent'}>
                                                                                    <div className='d-flex flex-column'>
                                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                    <img src={
                                                                                                        al.note && al.note.includes("Whatsapp - Propertyfinder") ? '/images/PropertyFinder.png'
                                                                                                            : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                                                                                : al.note && al.note.includes("Automation sent whatsapp") || al.note && al.note.includes('Client Replied:') ? '/images/defaultLogo.png'
                                                                                                                    : al.note && al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo
                                                                                                    } style={{ transform: 'unset' }} />} />
                                                                                                <p className="awriter">
                                                                                                    {al.note && al.note.includes("Whatsapp - Propertyfinder") ? <span className="">You got a new inquiry from <span className='font-bold'>PropertyFinder</span></span>
                                                                                                        : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                                                                            : al.note && al.note.includes('Client Replied:') ? <span> Client replied to the message</span>
                                                                                                                : <span> CRM sent a message</span>}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='align-content-center d-flex'>
                                                                                                <p className="atime align-self-center">
                                                                                                    <Moment fromNow>
                                                                                                        {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                                    </Moment>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='d-flex adesc'>
                                                                                        <div className='d-flex flex-column'>
                                                                                            <p className='font-12 mb-1'>
                                                                                                {al.note && parse(al.note)}
                                                                                            </p>
                                                                                            {al.lp_details ?
                                                                                                <div className='lp_details'>
                                                                                                    <Carousel arrows style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}>
                                                                                                        {this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                                                            return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                                                                        })}
                                                                                                    </Carousel>
                                                                                                    <div className='lpdetails_text w-100'>
                                                                                                        {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                                                                            : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                                                                : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                                                                    : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                                                                        : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                                                                            : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                                                                                : null
                                                                                                        }
                                                                                                        <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                                                                        <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                                                                        <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </Timeline.Item>
                                                                                : al.note_type == "viewing" ?
                                                                                    <Timeline.Item dot={<i className="bi bi-eye-fill" />} className={"time" + al.note_type}>
                                                                                        <div className='d-flex flex-column'>
                                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                    <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                        <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />} />
                                                                                                    <p className="awriter">
                                                                                                        <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                                        <span> scheduled a viewing</span>
                                                                                                        {/* <span className='pstat scheduled'>Scheduled</span> */}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='align-content-center d-flex'>
                                                                                                    <p className="atime align-self-center">
                                                                                                        <Moment fromNow>
                                                                                                            {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                                        </Moment>
                                                                                                    </p>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d-flex adesc w-100'>
                                                                                            {this.handleSplitViewing(al.note, al.lp_details ? al.lp_details : null)}
                                                                                        </div>

                                                                                        {al.property_viewings_feedback && al.property_viewings_feedback.map((vfeedback) => (
                                                                                            <>
                                                                                                {al.note.includes(vfeedback.refno) ?
                                                                                                    <div className='d-flex flex-row mt-2'>
                                                                                                        <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                            <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />} />
                                                                                                        <div className='messagewrap'>
                                                                                                            <p>{vfeedback.note}</p>
                                                                                                            <div className='d-flex w-100 align-items-center'>
                                                                                                                <span className='mr-1 font-bold'>Status: </span>
                                                                                                                <Tag>Scheduled</Tag>
                                                                                                                <i className="bi bi-arrow-right mr-2 "></i>
                                                                                                                <Tag className={vfeedback.status.replace(/ /g, '').toLowerCase() + ' pstat px-1'}>{vfeedback.status}</Tag>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : null}
                                                                                            </>
                                                                                        ))}
                                                                                    </Timeline.Item>
                                                                                    : al.note_type == "offer" ?
                                                                                        <Timeline.Item dot={<i class="bi bi-tag-fill"></i>} className={"time" + al.note_type}>
                                                                                            <div className='d-flex flex-column'>
                                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                        <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                                        <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                                                                            {al.note && al.note.includes("Viewing status") ? <span> updated the viewing status</span>
                                                                                                                : al.note && al.note.includes("Offer status") ? <span> updated the offer status</span>
                                                                                                                    : null}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className='align-content-center d-flex'>
                                                                                                        <p className="atime align-self-center">
                                                                                                            <Moment fromNow>{this.getCurrentTime(al.added_date)}</Moment>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='d-flex adesc'>
                                                                                                    <p className='font-12'>
                                                                                                        {al.note}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="d-flex adesc-stat mt-1">
                                                                                                    {al.note && al.note.includes("Viewing status changed to Offer") ? <>
                                                                                                        <Tag className='pstat viewing'>Viewing</Tag>
                                                                                                        <i class="bi bi-arrow-right"></i>
                                                                                                        <Tag className='pstat offer'>Offer</Tag>
                                                                                                    </> : al.note && al.note.includes("Not Interested") ? <>
                                                                                                        <Tag className='pstat viewing'>Viewing</Tag>
                                                                                                        <i class="bi bi-arrow-right"></i>
                                                                                                        <Tag className='pstat cancelled'>Not Interested</Tag>
                                                                                                    </> : al.note && al.note.includes("Offer Sent") ? <>
                                                                                                        <Tag className='pstat offer'>Offer</Tag>
                                                                                                        <i class="bi bi-arrow-right"></i>
                                                                                                        <Tag className='pstat offer'>Offer Sent</Tag>
                                                                                                    </> : al.note && al.note.includes("Offer Accepted") ? <>
                                                                                                        <Tag className='pstat offer'>Offer Sent</Tag>
                                                                                                        <i class="bi bi-arrow-right"></i>
                                                                                                        <Tag className='pstat secondviewing'>Offer Accepted</Tag>
                                                                                                    </> : al.note && al.note.includes("Offer Rejected") ? <>
                                                                                                        <Tag className='pstat offer'>Offer</Tag>
                                                                                                        <i class="bi bi-arrow-right"></i>
                                                                                                        <Tag className='pstat scheduled'>Offer Rejected</Tag>
                                                                                                    </> : al.note && al.note.includes("Deal Signed") ? <>
                                                                                                        <Tag className='pstat offer'>Offer</Tag>
                                                                                                        <i class="bi bi-arrow-right"></i>
                                                                                                        <Tag className='pstat deal'>Deal Signed</Tag>
                                                                                                    </> : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Timeline.Item>
                                                                                        : al.note_type == "noanswer" ?
                                                                                            <Timeline.Item dot={<i class="bi bi-telephone-x-fill"></i>} className={"time" + al.note_type}>
                                                                                                <div className='d-flex flex-column'>
                                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                            <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                                            <p className="awriter">
                                                                                                                <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                                                <span> called but no answer</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow>
                                                                                                                    {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                                                </Moment>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Timeline.Item>
                                                                                            : al.note_type == "req_expire" ?
                                                                                                <Timeline.Item dot={<i class="bi bi-house-fill"></i>} className={"time" + al.note_type}>
                                                                                                    <div className='d-flex flex-column'>
                                                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                                                <p className="awriter">
                                                                                                                    <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                                                    <span> requested to share his property to your client.</span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className='align-content-center d-flex'>
                                                                                                                <p className="atime align-self-center">
                                                                                                                    <Moment fromNow>
                                                                                                                        {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                                                    </Moment>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='adesc'>
                                                                                                            <a href="" className='font-bold color-text-black'>{al.note}</a>
                                                                                                            <p className="font-semibold">{(al.unitno) ? al.unitno : ""} {(al.community) ? "," + al.community : ""} {(al.subcommunity) ? "," + al.subcommunity : ""} {(al.price) && <i className="bi bi-dot"></i>}<span>{(al.price) ? this.getPrice(al.price) : ""}</span></p>
                                                                                                        </div>
                                                                                                        {al.note_type && al.note_type == 'req_expire' ?
                                                                                                            <Timeline className='d-flex flex-column mt-2'>
                                                                                                                {al.children && al.children.length > 0 && al.children != [] && al.children.map((child) => (
                                                                                                                    /* Request Accepted : Book a viewing */
                                                                                                                    child && child.note_type == "req_accept" ?
                                                                                                                        <Timeline.Item dot={<i class="bi bi-calendar2-check"></i>} className={"time" + al.note_type}>
                                                                                                                            <div className='d-flex flex-column'>
                                                                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                                        <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                                                                        <p className="awriter">
                                                                                                                                            <span className="font-bold">{child.by_user_name}</span>
                                                                                                                                            <span> scheduled a viewing with client.</span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                    <div className='align-content-center d-flex'>
                                                                                                                                        <p className="atime align-self-center">
                                                                                                                                            <Moment fromNow>{(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="adesc">
                                                                                                                                    <Row>
                                                                                                                                        <Col span={24}><span className='font-10'>Date / Time:</span></Col>
                                                                                                                                        <Col span={8}>
                                                                                                                                            <p className='font-bold fs-6 mb-0'>{this.getTime(child.viewing_date)}</p>
                                                                                                                                            <p className="font-12">{this.getDate(child.added_date)}</p>
                                                                                                                                        </Col>
                                                                                                                                        <Col span={16}>
                                                                                                                                            <p>Viewing booked for <span className='font-bold'>{al.note}</span></p>
                                                                                                                                            <p className="font-12 mt-2">{al.unitno}, {al.community}, {al.subcommunity} <i className="bi bi-dot"></i><span>{al.price} AED</span></p>
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Timeline.Item>
                                                                                                                        :/* Share via WhatsApp - Replied */
                                                                                                                        child && child.note_type == "req_wait" ?
                                                                                                                            <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={" time" + al.note_type}>
                                                                                                                                <div className='d-flex flex-column'>
                                                                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                                            <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                                                                            <p className="awriter">
                                                                                                                                                <span className="font-bold">{this.state.lead_list_details.owner}</span>
                                                                                                                                                <span> replied to the message.</span>
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                        <div className='align-content-center d-flex'>
                                                                                                                                            <p className="atime align-self-center">
                                                                                                                                                <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="adesc">
                                                                                                                                        <Row className='align-content-center'>
                                                                                                                                            <Col span={16} className='d-flex flex-column'>
                                                                                                                                                <span className='font-10'>Best time to view:</span>
                                                                                                                                                <div className='d-flex flex-row'>
                                                                                                                                                    <p className='font-bold mb-0'>{child.note}</p>
                                                                                                                                                </div>
                                                                                                                                            </Col>
                                                                                                                                            <Col span={8}>
                                                                                                                                                <Button type='primary' onClick={this.handleRequestNewViewing.bind(this, al)}>Book a viewing</Button>
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Timeline.Item>
                                                                                                                            :/* Share via WhatsApp - Shared via WhatsApp */
                                                                                                                            child && child.note_type == "req_w" ?
                                                                                                                                <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"mt-2 time" + al.note_type}>
                                                                                                                                    <div className='d-flex flex-column'>
                                                                                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                                                <Avatar className='d-flex mt-1' size={25} icon={<img src={child.note.includes("System has shared") ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                                                                                <p className="awriter">
                                                                                                                                                    <span>
                                                                                                                                                        {child.note.includes("System has shared") ?
                                                                                                                                                            <><span>CRM shared {child.by_user_name} property to the client</span>
                                                                                                                                                            </>
                                                                                                                                                            : child.note}
                                                                                                                                                    </span>
                                                                                                                                                </p>
                                                                                                                                            </div>
                                                                                                                                            <div className='align-content-center d-flex'>
                                                                                                                                                <p className="atime align-self-center">
                                                                                                                                                    <Moment fromNow>
                                                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                                                    </Moment>
                                                                                                                                                </p>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </Timeline.Item>
                                                                                                                                :/* Share via WhatsApp - Shared via WhatsApp */
                                                                                                                                child && child.note_type == "req_decline" ?
                                                                                                                                    <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"mt-2 time" + al.note_type}>
                                                                                                                                        <div className='d-flex flex-column'>
                                                                                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                                                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                                                    <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                                                                                                                    <p className="awriter">
                                                                                                                                                        <span className="font-bold">{child.by_user_name}</span>
                                                                                                                                                        <span> declined your request</span>
                                                                                                                                                    </p>
                                                                                                                                                </div>
                                                                                                                                                <div className='align-content-center d-flex'>
                                                                                                                                                    <p className="atime align-self-center">
                                                                                                                                                        <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                                                                    </p>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="adesc">
                                                                                                                                                <span>{child.note}</span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </Timeline.Item>
                                                                                                                                    : null))}
                                                                                                            </Timeline>
                                                                                                            : null}
                                                                                                    </div>
                                                                                                </Timeline.Item>

                                                                                                : al.note_type = "followup" ?
                                                                                                    <Timeline.Item dot={<i class="bi bi-reply-fill"></i>} className={"timefollowup"}>
                                                                                                        <div className='d-flex flex-column'>
                                                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                    <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                                        <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />} />
                                                                                                                    <p className="awriter">
                                                                                                                        <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'} added a feedback for viewing</span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='align-content-center d-flex'>
                                                                                                                    <p className="atime align-self-center">
                                                                                                                        <Moment fromNow>
                                                                                                                            {(al.added_date) ? al.added_date : "-"}
                                                                                                                        </Moment>
                                                                                                                    </p>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='d-flex adesc p-2'>
                                                                                                            <p className='font-12'>
                                                                                                                {al.note}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </Timeline.Item>
                                                                                                    : // DEfault
                                                                                                    <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"time" + al.note_type}>
                                                                                                        <div className='d-flex flex-column'>
                                                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                    <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                                        <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />} />
                                                                                                                    <p className="awriter">
                                                                                                                        <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='align-content-center d-flex'>
                                                                                                                    <p className="atime align-self-center">
                                                                                                                        <Moment fromNow>
                                                                                                                            {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                                                        </Moment>
                                                                                                                    </p>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='d-flex adesc'>
                                                                                                            <p className='font-12'>
                                                                                                                {al.note}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </Timeline.Item>
                                                                }
                                                            </>)
                                                        })}
                                                    </Timeline>
                                                </TabPanel>
                                            </Tabs>


                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="d-flex flex-column w-100 bg-white border-top p-4 matchlistings">
                                            <h3>Match Listings</h3>
                                            <div className='tabtimeline'>
                                                <Tabs>
                                                    <TabList>
                                                        <Tab><span className='font-12'>Exact ({this.state.exact_match_listings_count})</span></Tab>
                                                        <Tab><span className='font-12'>Comparable ({this.state.comparable_match_listings_count})</span></Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <div className=' matchlistcontentwrap'>
                                                            {/* When they click the property it will open the drawer */}
                                                            {this.state.exact_match_listings.length > 0 ? <>
                                                                {this.state.exact_match_listings.map((exact_match) => (
                                                                    <a className='rowmatchwrap'>
                                                                        <Row>
                                                                            <Col span={24} className='pl-1'>
                                                                                <div className='top-info'>
                                                                                    <div className='propfor font-10'>{exact_match.property_for == "sale" ? "Sale" : "Rent"} | {exact_match.refno}</div>

                                                                                    {exact_match.status == "Y" ?
                                                                                        <div className="d-flex flex-row stat published ml-0">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span className="font-10">Published</span>
                                                                                        </div> : null}
                                                                                    {exact_match.status == "F" ?
                                                                                        <div className="d-flex flex-row stat feeds ml-0">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span className="font-10">Feed</span>
                                                                                        </div> : null}
                                                                                    {exact_match.status == "CTM" ?
                                                                                        <div className="d-flex flex-row stat comingtomarket ml-0">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span className="font-10">Coming to market</span>
                                                                                        </div> : null}
                                                                                </div>
                                                                                <p className="font-12 font-bold mb-0">{exact_match.unitno}, {exact_match.property}</p>
                                                                                <p className="font-10 mb-0 propadd">{exact_match.subcommunity} {exact_match.community}</p>
                                                                                <div className='bottominfo'>
                                                                                    <p className="font-10 mb-0 propadd">{exact_match.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                    <p className="font-10 mb-0 agentprop">{exact_match.display_name}</p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </a>
                                                                ))}
                                                            </> : "no data"}


                                                            {/* View All: will go to the listing page design */}
                                                            {this.state.exact_match_listings.length > 0 ?
                                                                // <Link target="_blank" to={"/all_match_listings/"+this.state.record_id} className='rowmatchviewall font-12'>View All</Link>
                                                                <Link target="_blank" type='text' to={"/matched_lead_lists/" + this.state.record_id} className='rowmatchviewall font-10'>View All</Link>
                                                                : null}

                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <div className=' matchlistcontentwrap'>
                                                            {/* When they click the property it will open the drawer */}
                                                            {this.state.comparable_match_listings.length > 0 ? <>
                                                                {this.state.comparable_match_listings.map((comparable_match) => (
                                                                    <a className='rowmatchwrap'>
                                                                        <Row>
                                                                            <Col span={24} className='pl-1'>
                                                                                <div className='top-info'>
                                                                                    <div className='propfor font-10'>{comparable_match.property_for == "sale" ? "Sale" : "Rent"} | {comparable_match.refno}</div>

                                                                                    {comparable_match.status == "Y" ?
                                                                                        <div className="d-flex flex-row stat published ml-0">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span className="font-10">Published</span>
                                                                                        </div> : null}
                                                                                    {comparable_match.status == "F" ?
                                                                                        <div className="d-flex flex-row  stat feeds  ml-0">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span className="font-10">Feed</span>
                                                                                        </div> : null}
                                                                                    {comparable_match.status == "CTM" ?
                                                                                        <div className="d-flex flex-row stat comingtomarket ml-0">
                                                                                            <i className="bi bi-circle-fill" />
                                                                                            <span className="font-10">Coming to market</span>
                                                                                        </div> : null}
                                                                                </div>
                                                                                <p className="font-12 font-bold mb-0">{comparable_match.unitno}, {comparable_match.property}</p>
                                                                                <p className="font-10 mb-0 propadd">{comparable_match.subcommunity} {comparable_match.community}</p>
                                                                                <div className='bottominfo'>
                                                                                    <p className="font-10 mb-0 propadd">{comparable_match.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                    <p className="font-10 mb-0 agentprop">{comparable_match.display_name}</p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </a>
                                                                ))}
                                                            </> : "no data"}


                                                            {/* View All: will go to the listing page design */}
                                                            {this.state.comparable_match_listings.length > 0 ?
                                                                // <Link target="_blank" to={"/all_match_listings/"+this.state.record_id} className='rowmatchviewall font-12'>View All</Link>
                                                                <Button type='text' onClick={this.handleShowMatchListing} className='rowmatchviewall font-10'>View All</Button>
                                                                : null}

                                                        </div>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                        </div>
                                        {/* OFFERS */}
                                        <div className="bg-white border-top offers p-4 mt-4">
                                            <div className="d-flex flex-row justify-content-between">
                                                <h3 className="mr-4">Offers</h3>
                                                <a href className="btn btn-link viewall">View All</a>
                                            </div>
                                            <div className="d-flex flex-column border-wrap">

                                                {this.state.property_offers.map((property_offer) => {

                                                    const offer_price = property_offer.price
                                                    return (
                                                        <a onClick={this.handleOfferView.bind(this, property_offer.views_id)} className="matchitem">
                                                            <div className="matchinfo">
                                                                <div className="col-9">
                                                                    <p className="address">{property_offer.address}</p>
                                                                    <p className="price">Offer:  {offer_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'}</p>
                                                                    <p className="price">{property_offer.view_date}</p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <button type="button" className="btn btn-link addfeedback"></button>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })}
                                                {this.state.property_offers.length > 0 ? <a className="matchitem viewall">
                                                    View All
                                                </a> : null}
                                            </div>
                                        </div> {/* END OFFERS */}
                                        {/* VIEWINGS */}
                                        <div className="bg-white border-top viewings p-4 mt-4">
                                            <div className="d-flex flex-row justify-content-between">
                                                <h3 className="mr-4">Viewings</h3>
                                                <a onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</a>
                                            </div>
                                            <div className="d-flex flex-column border-wrap">
                                                {this.state.property_viewings.map((property_viewing) => {

                                                    return (
                                                        <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                                                            <div className="matchinfo">
                                                                <div className="col-9">
                                                                    <p className="address">{property_viewing.address}</p>
                                                                    <p className="price">
                                                                        {property_viewing.price != null && property_viewing.price != "" ? <>Offer: AED {property_viewing.price}</> : null}

                                                                    </p>
                                                                    <p className="price">{property_viewing.view_date}</p>
                                                                    <p className="price">{property_viewing.note}</p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <button type="button" className="btn btn-link addfeedback"></button>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })}
                                                {this.state.property_viewings.length > 0 ? <Link to={"/all_lead_viewings/" + this.state.record_id} target="_blank" className="btn btn-link viewall">View All</Link>
                                                    : null}

                                            </div>
                                        </div> {/* END VIEWINGS*/}

                                        {/* Followup */}
                                        <div className="bg-white border-top viewings p-4 mt-4">
                                            <div className="d-flex flex-row justify-content-between">
                                                <h3 className="mr-4">Follow Up</h3>
                                                <a onClick={this.handleNewFollowUp} className="btn btn-link viewall">Add Followup</a>
                                            </div>
                                            <div className="d-flex flex-column border-wrap">
                                                {this.state.followups_details.map((followup) => {
                                                    return (
                                                        <a onClick={this.handleViewFollowup.bind(this, followup)} className="matchitem">
                                                            <div className="matchinfo">
                                                                <div className="col-9">
                                                                    {/*<p className="price">{followups.first_name}</p>*/}
                                                                    <p className="price">{(followup.type === 1) ? "Vendor" : "Client"}</p>
                                                                    <p className="price">{(followup.status === 1) ? "Active" : "Inactive"}</p>
                                                                    <p className="address">
                                                                        {(followup.notes) ? (followup.notes.length > 40 ?
                                                                            `${followup.notes.substring(0, 40)}...` : followup.notes) : null
                                                                        }
                                                                    </p>
                                                                    <p className="price">{followup.date}</p>
                                                                    <p className="price">{(followup.agent_name) ? "Follow up by " + followup.agent_name : ""}</p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <button type="button" className="btn btn-link addfeedback"></button>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })}
                                                {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                                                    View All
                                                </a> : null}

                                            </div>
                                        </div> {/* END Followup*/}

                                        <div className="bg-white border-top viewings p-4 mt-4">
                                            <div className="d-flex flex-row justify-content-between">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-start" }} >
                                                    <SettingFilled style={{ fontSize: '1rem', marginRight: '0.35rem' }} />
                                                    <h3 className="mr-4">Collaborators</h3>
                                                </div>
                                            </div>

                                            <Select
                                                // defaultValue="lucy"
                                                style={{
                                                    width: '100%',
                                                    marginBottom: '1rem'
                                                }}
                                                showSearch
                                                mode={"multiple"}
                                                value={this.state.newArrSelectedAgents}
                                                placeholder="Select a person"
                                                maxTagCount={"responsive"}
                                                onChange={this.handleChangeSelect}
                                                // onSelect={(itm)=> {
                                                //   this.onSelectAgents(itm)
                                                //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents)
                                                // }}
                                                // onDeselect={(itm)=> {
                                                //   this.onDeSelectAgents(itm)
                                                //   console.log("******Arr agent1212 ****", this.state.newArrSelectedAgents)
                                                // }}
                                                options={this.state.agents}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                            />
                                            {/* <button type="button" className="btn btn-dark w-40 mb-2"  >
                  <p style={{ fontSize:'1rem', color:'white' }} >
                    Add Collaborator
                  </p>
                </button> */}

                                            <Button onClick={this.postAddCollaborator} type="primary" style={{ backgroundColor: 'black', color: 'white' }} >Add Collaborator</Button>

                                            <div style={{ width: '100%', marginTop: '1rem', paddingLeft: '0.25rem' }} >
                                                {this.state.collaborators.map((coll, index) => {
                                                    return (
                                                        <div className="matchitem">
                                                            <div className="matchinfo">
                                                                <div className="col-9">
                                                                    <p className="name">{coll.display_name}</p>
                                                                    <p className="price">{coll.added_date}</p>
                                                                </div>
                                                                {
                                                                    role == 1 ?
                                                                        <div className="col-3">
                                                                            <button type="button" className="btn btn-link addfeedback" onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)} > Delete</button>
                                                                        </div>
                                                                        :
                                                                        user_id == coll.user_id &&
                                                                        <div className="col-3">
                                                                            <button type="button" className="btn btn-link addfeedback" onClick={this.onDeleteCollab.bind(this, coll.user_id, index, coll.id)} > Delete </button>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {this.state.collaborators.length > 0 ?
                                                    // <Link to={"/all_lead_viewings/" + this.state.record_id} target="_blank" className="btn btn-link viewall w-100 text-right">View All</Link>
                                                    <Button type='text' onClick={this.showMatchListings} className='rowmatchviewall font-12'>View All</Button>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>




            </Drawer>
            {/* Tagged Drawer */}
            <Drawer title={<p className='mb-0'><i className="bi bi-at"></i>Tagged</p>} placement="right" onClose={this.handleCloseTagged} open={this.state.isOpenTagged} className='taggednoti'>
                {this.state.isTagLoading && <div style={{ position: 'absolute', alignSelf: "center", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} >
                    <Spin className='spinnerOp' size='large' indicator={<Spin size="large" style={{ color: '#151515' }} />} />
                </div>}
                {!this.state.isTagLoading && <div className='filterwrap mt-3'>
                    <Tabs>
                        <TabList>
                            <Tab><span><i class="bi bi-send-check-fill"></i>Sent</span></Tab>
                            <Tab><span><i class="bi bi-envelope-check-fill"></i>Received</span></Tab>
                        </TabList>
                        <TabPanel>
                            <Row >
                                <Col span={24} className='btnfiltergrp'>
                                    <Button type='text' onClick={this.getAllTags} className={this.state.isAllActive ? 'active' : ''}><span><i class="bi bi-infinity"></i> All</span><span className="circle">{this.state.sendTagDataAll.length}</span></Button>
                                    <Button type='text' onClick={this.getRepliedTags} className={this.state.isRepliedActive ? 'active' : ''}><span><i class="bi bi-reply"></i> Replied</span><span className="circle">{this.state.sendTagDataReplied.length}</span></Button>
                                    <Button type='text' onClick={this.getPendingTags} className={this.state.isPendingActive ? 'active' : ''}><span><i class="bi bi-chat-right-dots"></i> Pending</span><span className="circle">{this.state.sendTagDataPending.length}</span></Button>
                                    <Button type='text' onClick={this.getAllTags} className='active'><span><i
                                        className="bi bi-infinity"></i> All</span><span
                                            className="circle">{this.state.sendTagDataAll.length}</span></Button>
                                    <Button type='text' onClick={this.getRepliedTags}><span><i
                                        className="bi bi-reply"></i> Replied</span><span
                                            className="circle">{this.state.sendTagDataReplied.length}</span></Button>
                                    <Button type='text' onClick={this.getPendingTags}><span><i
                                        className="bi bi-chat-right-dots"></i> Pending</span><span
                                            className="circle">{this.state.sendTagDataPending.length}</span></Button>
                                </Col>
                            </Row>
                            <Row className='filter-content'>
                                <Col span={24} className='filtcont-row'>
                                    <Row gutter={8} span={24} className='d-flex flex-row justify-content-between'>
                                        {this.state.sendTagData.map((tagsData) => {
                                            return <>
                                                <Col span={2} className='pt-1'>
                                                    <Avatar className='d-flex' size={30}
                                                        style={{ backgroundColor: "#FFF", marginRight: "0", }}
                                                        icon={<Avatar className='d-flex' style={{
                                                            backgroundColor: "#87d068",
                                                            marginRight: "0",
                                                        }} icon={<img
                                                            src={tagsData.profile_photo == null || tagsData.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(tagsData.profile_photo) ? tagsData.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + tagsData.profile_photo}
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                borderRadius: "50%",
                                                            }} />} />} />
                                                </Col>
                                                <Col span={20}>
                                                    <p><span className='font-bold'>{tagsData.agent_name}</span>
                                                        <span> you have started a lead thread</span></p>
                                                    <div className='border p-2'>
                                                        <p>{tagsData.note}</p>
                                                    </div>
                                                    <div className='d-flex flex-row mt-1 replywrap'>

                                                        <Button type='default' className='font-12'
                                                            onClick={this.onReply.bind(this, tagsData)}>
                                                            <i className="nbi bi-check-all mr-1"></i>Reply
                                                        </Button>
                                                    </div>
                                                    <div className='datestamp'>
                                                        {tagsData.read == 0 ? <p className='unread'><i
                                                            className="bi bi-check2 mr-1"></i>Unread</p> : <p className='read'><i
                                                                className="bi bi-check-all mr-1 "></i>Read</p>}
                                                        <i className="bi bi-dot"></i>
                                                        <p className='unread'>
                                                            <Moment fromNow>
                                                                {(tagsData.added_date) ? this.getCurrentTime(tagsData.added_date) : "-"}
                                                            </Moment>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col span={1}><Button type='text' className='p-0 m-0'><i class="bi bi-x"></i></Button></Col>
                                            </>
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel>
                            <Row>
                                <Col span={24} className='btnfiltergrp'>
                                    <Button type='text' onClick={this.getAllRecieveTags} className={this.state.isAllRecieveTags ? 'active' : ''}><span><i class="bi bi-infinity"></i> All</span><span className="circle">{this.state.recieveTagDataAll.length}</span></Button>
                                    <Button type='text' onClick={this.getRepliedRecieveTags} className={this.state.isRepliedRecieveTags ? 'active' : ''}><span><i class="bi bi-reply"></i> Replied</span><span className="circle">{this.state.recieveTagDataReplied.length}</span></Button>
                                    <Button type='text' onClick={this.getPendingRecieveTags} className={this.state.isPendingRecieveTags ? 'active' : ''}><span><i class="bi bi-chat-right-dots"></i> Pending</span><span className="circle">{this.state.recieveTagDataPending.length}</span></Button>
                                    <Button type='text' onClick={this.getAllRecieveTags} className='active'><span><i
                                        className="bi bi-infinity"></i> All</span><span
                                            className="circle">{this.state.recieveTagDataAll.length}</span></Button>
                                    <Button type='text' onClick={this.getRepliedRecieveTags}><span><i
                                        className="bi bi-reply"></i> Replied</span><span
                                            className="circle">{this.state.recieveTagDataReplied.length}</span></Button>
                                    <Button type='text' onClick={this.getPendingRecieveTags}><span><i
                                        className="bi bi-chat-right-dots"></i> Pending</span><span
                                            className="circle">{this.state.recieveTagDataPending.length}</span></Button>
                                </Col>
                            </Row>
                            <Row className='filter-content'>
                                <Col span={24} className='filtcont-row'>
                                    <Row gutter={8} span={24} className='d-flex flex-row justify-content-between'>
                                        {this.state.recieveTagData.map((tagsData) => {
                                            return <>
                                                <Col span={2} className='pt-1'>
                                                    <Avatar className='d-flex' size={30}
                                                        style={{ backgroundColor: "#FFF", marginRight: "0", }}
                                                        icon={<Avatar className='d-flex' style={{
                                                            backgroundColor: "#87d068",
                                                            marginRight: "0",
                                                        }} icon={<img
                                                            src={tagsData.profile_photo == null || tagsData.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(tagsData.profile_photo) ? tagsData.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + tagsData.profile_photo}
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                borderRadius: "50%",
                                                            }} />} />} />
                                                </Col>
                                                <Col span={20}>
                                                    <p><span className='font-bold'>{tagsData.agent_name}</span>
                                                        <span> mentioned you in a lead thread</span></p>
                                                    <div className='border p-2'>
                                                        <p>{tagsData.note}</p>
                                                    </div>
                                                    <div className='d-flex flex-row mt-1 replywrap'>
                                                        <Button type='default' className='font-12'
                                                            onClick={this.onReply.bind(this, tagsData)}>
                                                            <i className="bi bi-check-all mr-1"></i>Reply
                                                        </Button>
                                                    </div>
                                                    <div className='datestamp'>
                                                        {tagsData.read == 0 ? <p className='unread'><i
                                                            className="bi bi-check2 mr-1"></i>Unread</p> : <p className='read'><i
                                                                className="bi bi-check-all mr-1 "></i>Read</p>}
                                                        <i className="bi bi-dot"></i>
                                                        <p className='unread'>
                                                            <Moment fromNow>
                                                                {(tagsData.added_date) ? this.getCurrentTime(tagsData.added_date) : "-"}
                                                            </Moment>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col span={1}><Button type='text' className='p-0 m-0'><i class="bi bi-x"></i></Button></Col>
                                            </>
                                        })}
                                    </Row>
                                </Col>

                            </Row>
                        </TabPanel>
                    </Tabs>
                </div>}
            </Drawer>

            {/* My Activity Feed Drawer */}
            <Drawer title={<p className='mb-0 d-flex align-items-center'><ClockCircleOutlined className='mr-1' /> My Activity Feed</p>} placement="right" width={450} className='myactivityfeed' onClose={this.handleCloseMyActivity} open={this.state.isOpenMyActivityFeed} closeIcon={<i class="bi bi-box-arrow-right"></i>}>
                {this.state.isActivityLoading && <Row className=''><div style={{ position: 'absolute', alignSelf: "center", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} >
                    <Spin className='spinnerOp' size='large' indicator={<Spin size="large" style={{ color: '#151515' }} />} />
                </div></Row>}
                {
                    !this.state.isActivityLoading &&
                    <Row className=''>
                        <Col span={24}>
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                allowClear
                                value={this.state.activity_agent_id}
                                placeholder="Please select agent"
                                onChange={this.onChangeActivityAgent}
                                options={this.state.agents}
                            />
                            <div className='d-flex flex-row filter'>
                                <Popover placement="bottomRight" trigger="click" overlayClassName='filtermyact'
                                    content={
                                        <Row>
                                            <Col className='d-flex flex-column'>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "new_leads")}><i class="bi bi-person-fill"></i> New Leads</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "note")}><i class="bi bi-pin-angle-fill"></i>New Notes</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "phone")}><i class="bi bi-telephone-fill"></i>Calls</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "tag_sent")}><i class="bi bi-at"></i>Tag Sent</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "tag_recieved")}><i class="bi bi-envelope-at-fill"></i>Tag Received</Button>
                                                {/*<Button type='link' onClick={this.onActivityFilter.bind(this, "listings_added")}><i class="bi bi-house"></i>Listings Added</Button>*/}
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "")}><i class="bi bi-bezier2"></i>Match Run</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "viewing_request")}><i class="bi bi-eye-fill"></i>Viewing Request</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "viewing_booked")}><i class="bi bi-eye"></i>Viewing Booked</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "viewing_feedback")}><i class="bi bi-reply-fill"></i>Viewing Feedback</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "offer_sent")}><i class="bi bi-tag-fill"></i>Offer Sent</Button>
                                                {/*<Button type='link' onClick={this.onActivityFilter.bind(this, "contract_request")}><i class="bi bi-file-earmark-pdf-fill"></i>Contract Request</Button>*/}
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "expired_leads")}><i class="bi bi-house-exclamation-fill"></i>Expired Leads</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "more_info_request")}><i class="bi bi-info-circle-fill"></i>More Info Request</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "accepted_viewing_my_leads")}><i class="bi bi-eye-fill"></i>Accepted Viewing - My Lead</Button>
                                                <Button type='link' onClick={this.onActivityFilter.bind(this, "accepted_viewing_my_property")}><i class="bi bi-house-check-fill"></i>Accepted Viewing - My Property</Button>
                                            </Col>
                                        </Row>
                                    }
                                >
                                    <Button><i class="bi bi-funnel mr-1 font-12"></i><span className='font-12'>Filter</span> </Button>
                                </Popover>
                            </div>
                        </Col>
                        <Col span={24} className='p-4'>
                            <Timeline className='p-1'>
                                {this.state.activityFeeds.map((al, index) => {
                                    return (<>
                                        {al.note_type == "note" || al.note_type == "email" ?
                                            <Timeline.Item dot={al.note_type == "note" ? <i class="bi bi-pin-angle-fill"></i> : al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : <i class="bi bi-pin-angle-fill"></i>} className={"time" + al.note_type}>
                                                <div className='d-flex flex-column'>
                                                    <div className='d-flex flex-row justify-content-between'>
                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                            <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={
                                                                al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                                    : al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                            <p className="awriter">
                                                                <span className="font-bold">{al.by_user_name ? al.by_user_name : al.note && al.note.includes('archived the chat') ? '' : al.note.includes('Expired') ? 'Automation' : 'CRM'}</span>
                                                                <span>
                                                                    {al.note && al.note.includes('archived the chat') ? 'A client sent a message in the chat'
                                                                        // :al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                                        : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                                            ' updated a requirement'

                                                                            : ' added a note'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {al.note && al.note.includes("Call start :") ?
                                                        <div className='d-flex adesc'><p className='font-12'>{al.note && parse(al.note)}</p></div>
                                                        : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                            <>{al.note && this.handleSplitReq(al.note)}</>
                                                            : al.note && al.note.includes('property on Bayut') ? <span className="">{al.note}</span>
                                                                : al.note && al.note.includes('archived the chat') ? <div className='d-flex adesc'>{al.note && parse(al.note)}</div>
                                                                    : <div className='d-flex adesc'>
                                                                        <div className='d-flex flex-column'>
                                                                            <p className={this.state.readtag ? 'font-12 mb-1 tagged' : 'font-12 mb-1'}>
                                                                                {al.note && parse(al.note)}
                                                                                {this.state.readtag ?
                                                                                    <div className='read'><i class="bi bi-check2-all mr-1"></i><span className='font-10'>Read</span></div>
                                                                                    : null}
                                                                            </p>
                                                                            {al.lp_details ?
                                                                                <div className='lp_details'>
                                                                                    <Carousel arrows style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}>
                                                                                        {this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                                            return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                                                        })}
                                                                                    </Carousel>
                                                                                    <div className='lpdetails_text w-100'>
                                                                                        {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                                                            : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                                                : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                                                    : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                                                        : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                                                            : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                                                                : null
                                                                                        }
                                                                                        <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                                                        <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                                                        <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                    }
                                                    <div className='align-content-center d-flex mt-1'>
                                                        <p className="atime align-self-center">
                                                            <Moment fromNow>
                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                            </Moment>
                                                        </p>
                                                    </div>

                                                    {al.note && al.note.includes("@") && !al.note.includes('archived the chat') ? (
                                                        <div className='d-flex flex-column w-100' key={index}>

                                                            <Timeline className='d-flex flex-column mt-2 childrenreply'>
                                                                {al.children && al.children.length > 0 && al.children != [] && al.children.map((child, childIndex) => (
                                                                    /* Request Accepted : Book a viewing */
                                                                    child && child.note_type == "reply" ? (
                                                                        <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"mt-2 time" + al.note_type} key={childIndex}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                        <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={child.profile_photo == null || child.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(child.profile_photo) ? child.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + child.profile_photo} />} /></div>
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{child.by_user_name}</span>
                                                                                            <span> replied</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(child.added_date) ? this.getCurrentTime(child.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="adesc">
                                                                                    <span>{child.note}</span>
                                                                                </div>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    ) : null
                                                                ))}
                                                            </Timeline>
                                                            <div className='d-flex flex-row w-100 taggedreply'>
                                                                <div className='d-flex flex-row w-100'>
                                                                    <Button type='default' className='font-12' onClick={this.onReplyActivity.bind(this, al)}>
                                                                        <i className="bi bi-check-all mr-1"></i>Reply
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Timeline.Item>
                                            : al.note_type == "phone" ?
                                                <Timeline.Item dot={<i className="bi bi-telephone-fill"></i>} className={"time" + al.note_type}>
                                                    <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-row justify-content-between'>
                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                <p className="awriter">
                                                                    <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                    <span> logged a call</span>
                                                                </p>
                                                            </div>
                                                            <div className='align-content-center d-flex'>
                                                                <p className="atime align-self-center">
                                                                    <Moment fromNow>
                                                                        {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                    </Moment>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex adesc'>
                                                            <p className='font-12'>
                                                                {(al.note && al.note != null) && parse(al.note)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Timeline.Item>
                                                : al.note_type == "whatsapp" ?
                                                    <Timeline.Item dot={<i className="bi bi-whatsapp"></i>} className={this.state.whatsapp_from == 1 ? 'timewhatsapp wa_child' : 'timewhatsapp wa_parent'}>
                                                        <div className='d-flex flex-column'>
                                                            <div className='d-flex flex-row justify-content-between'>
                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                    <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={
                                                                        <img src={
                                                                            al.note && al.note.includes("Whatsapp - Propertyfinder") ? '/images/PropertyFinder.png'
                                                                                : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                                                    : al.note && al.note.includes("Automation sent whatsapp") || al.note && al.note.includes('Client Replied:') ? '/images/defaultLogo.png'
                                                                                        : al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo
                                                                        } style={{ transform: 'unset' }} />} /></div>
                                                                    <p className="awriter">
                                                                        {al.note && al.note.includes("Whatsapp - Propertyfinder") ? <span className="">You got a new inquiry from <span className='font-bold'>PropertyFinder</span></span>
                                                                            : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                                                : al.note && al.note.includes('Client Replied:') ? <span> Client replied to the message</span>
                                                                                    : <span> CRM sent a message</span>}
                                                                    </p>
                                                                </div>
                                                                <div className='align-content-center d-flex'>
                                                                    <p className="atime align-self-center">
                                                                        <Moment fromNow>
                                                                            {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                        </Moment>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex adesc'>
                                                            <div className='d-flex flex-column'>
                                                                <p className='font-12 mb-1'>
                                                                    {(al.note && al.note != null) && parse(al.note)}
                                                                </p>
                                                                {al.lp_details ?
                                                                    <div className='lp_details'>
                                                                        <Carousel arrows style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}>
                                                                            {this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                                return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                                            })}
                                                                        </Carousel>
                                                                        <div className='lpdetails_text w-100'>
                                                                            {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                                                : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                                    : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                                        : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                                            : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                                                : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                                                    : null
                                                                            }
                                                                            <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                                            <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                                            <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                        <div className='align-content-center d-flex mt-1'>
                                                            <p className="atime align-self-center">
                                                                <Moment fromNow>
                                                                    {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                </Moment>
                                                            </p>
                                                        </div>
                                                    </Timeline.Item>
                                                    : al.note_type == "viewing" ?
                                                        <Timeline.Item dot={<i className="bi bi-eye-fill" />} className={"time" + al.note_type}>
                                                            <div className='d-flex flex-column'>
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                        <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={
                                                                            <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />} /></div>
                                                                        <p className="awriter">
                                                                            <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                            <span> scheduled a viewing</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className='align-content-center d-flex'>
                                                                        <p className="atime align-self-center">
                                                                            <Moment fromNow>
                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                            </Moment>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='d-flex adesc'>
                                                                <p className='font-12'>
                                                                    {this.handleSplitViewing(al.note, al.lp_details ? al.lp_details : null)}
                                                                </p>
                                                            </div>
                                                        </Timeline.Item>
                                                        : al.note_type == "offer" ?
                                                            <Timeline.Item dot={<i class="bi bi-tag-fill"></i>} className={"time" + al.note_type}>
                                                                <div className='d-flex flex-column'>
                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                            <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                            <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                                                {al.note && al.note.includes("Viewing status") ? <span> updated the viewing status</span>
                                                                                    : al.note && al.note.includes("Offer status") ? <span> updated the offer status</span>
                                                                                        : null}
                                                                            </p>
                                                                        </div>
                                                                        <div className='align-content-center d-flex'>
                                                                            <p className="atime align-self-center">
                                                                                <Moment fromNow>{this.getCurrentTime(al.added_date)}</Moment>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex adesc'>
                                                                        <p className='font-12'>
                                                                            {al.note}
                                                                        </p>
                                                                    </div>
                                                                    <div className="d-flex adesc-stat mt-1">
                                                                        {al.note && al.note.includes("Viewing status changed to Offer") ? <>
                                                                            <Tag className='pstat viewing'>Viewing</Tag>
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                        </> : al.note && al.note.includes("Not Interested") ? <>
                                                                            <Tag className='pstat viewing'>Viewing</Tag>
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            <Tag className='pstat cancelled'>Not Interested</Tag>
                                                                        </> : al.note && al.note.includes("Offer Sent") ? <>
                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            <Tag className='pstat offer'>Offer Sent</Tag>
                                                                        </> : al.note && al.note.includes("Offer Accepted") ? <>
                                                                            <Tag className='pstat offer'>Offer Sent</Tag>
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            <Tag className='pstat secondviewing'>Offer Accepted</Tag>
                                                                        </> : al.note && al.note.includes("Offer Rejected") ? <>
                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            <Tag className='pstat scheduled'>Offer Rejected</Tag>
                                                                        </> : al.note && al.note.includes("Deal Signed") ? <>
                                                                            <Tag className='pstat offer'>Offer</Tag>
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            <Tag className='pstat deal'>Deal Signed</Tag>
                                                                        </> : null}
                                                                    </div>
                                                                </div>
                                                            </Timeline.Item>
                                                            : al.note_type == "noanswer" ?
                                                                <Timeline.Item dot={<i class="bi bi-telephone-x-fill"></i>} className={"time" + al.note_type}>
                                                                    <div className='d-flex flex-column'>
                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                                <p className="awriter">
                                                                                    <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                    <span> called but no answer</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='align-content-center d-flex mt-1'>
                                                                            <p className="atime align-self-center">
                                                                                <Moment fromNow>
                                                                                    {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                </Moment>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </Timeline.Item>
                                                                : al.note_type == "req_expire" ?
                                                                    <Timeline.Item dot={<i class="bi bi-house-fill"></i>} className={"time" + al.note_type}>
                                                                        <div className='d-flex flex-column'>
                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                    <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                                    <p className="awriter">
                                                                                        <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                        <span> requested to share his property to your client.</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className='align-content-center d-flex'>
                                                                                    <p className="atime align-self-center">
                                                                                        <Moment fromNow>
                                                                                            {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                        </Moment>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='adesc'>
                                                                                <a href="" className='font-bold color-text-black'>{al.note}</a>
                                                                                <p className="font-semibold">{(al.unitno) ? al.unitno : ""} {(al.community) ? "," + al.community : ""} {(al.subcommunity) ? "," + al.subcommunity : ""} {(al.price) && <i className="bi bi-dot"></i>}<span>{(al.price) ? this.getPrice(al.price) : ""}</span></p>
                                                                            </div>
                                                                            {al.note_type && al.note_type == 'req_expire' ?
                                                                                <Timeline className='d-flex flex-column mt-2'>
                                                                                    {al.children && al.children.length > 0 && al.children != [] && al.children.map((child) => (
                                                                                        /* Request Accepted : Book a viewing */
                                                                                        child && child.note_type == "req_accept" ?
                                                                                            <Timeline.Item dot={<i class="bi bi-calendar2-check"></i>} className={"time" + al.note_type}>
                                                                                                <div className='d-flex flex-column'>
                                                                                                    <div className='d-flex flex-row justify-content-between'>
                                                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                            <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                                                            <p className="awriter">
                                                                                                                <span className="font-bold">{child.by_user_name}</span>
                                                                                                                <span> scheduled a viewing with client.</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow>{(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="adesc">
                                                                                                        <Row>
                                                                                                            <Col span={24}><span className='font-10'>Date / Time:</span></Col>
                                                                                                            <Col span={5}>
                                                                                                                <p className='font-bold fs-6 mb-0'>{this.getTime(child.viewing_date)}</p>
                                                                                                                <p className="font-12">{this.getDate(child.added_date)}</p>
                                                                                                            </Col>
                                                                                                            <Col span={19} style={{ border: 'none' }}>
                                                                                                                <p>Viewing booked for <span className='font-bold'>{al.note}</span></p>
                                                                                                                <p className="font-12 mt-2">{al.unitno}, {al.community}, {al.subcommunity} <i className="bi bi-dot"></i><span>{al.price} AED</span></p>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Timeline.Item>
                                                                                            :/* Share via WhatsApp - Replied */
                                                                                            child && child.note_type == "req_wait" ?
                                                                                                <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={" time" + al.note_type}>
                                                                                                    <div className='d-flex flex-column'>
                                                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                <div className='avatarwrap'><Avatar className='d-flex ' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                                                                <p className="awriter">
                                                                                                                    <span className="font-bold">{this.state.lead_list_details.owner}</span>
                                                                                                                    <span> replied to the message.</span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className='align-content-center d-flex'>
                                                                                                                <p className="atime align-self-center">
                                                                                                                    <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="adesc">
                                                                                                            <Row className='align-content-center'>
                                                                                                                <Col span={16} className='d-flex flex-column'>
                                                                                                                    <span className='font-10'>Best time to view:</span>
                                                                                                                    <div className='d-flex flex-row'>
                                                                                                                        <p className='font-bold mb-0'>{child.note}</p>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                <Col span={8}>
                                                                                                                    <Button type='primary' onClick={this.handleRequestNewViewing.bind(this, al)}>Book a viewing</Button>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Timeline.Item>
                                                                                                :/* Share via WhatsApp - Shared via WhatsApp */
                                                                                                child && child.note_type == "req_w" ?
                                                                                                    <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"mt-2 time" + al.note_type}>
                                                                                                        <div className='d-flex flex-column'>
                                                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                    <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={<img src={child.note.includes("System has shared") ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                                                                    <p className="awriter">
                                                                                                                        <span>
                                                                                                                            {child.note.includes("System has shared") ?
                                                                                                                                <><span>CRM shared {child.by_user_name} property to the client</span>
                                                                                                                                </>
                                                                                                                                : child.note}
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className='align-content-center d-flex'>
                                                                                                                    <p className="atime align-self-center">
                                                                                                                        <Moment fromNow>
                                                                                                                            {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                        </Moment>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Timeline.Item>
                                                                                                    :/* Share via WhatsApp - Shared via WhatsApp */
                                                                                                    child && child.note_type == "req_decline" ?
                                                                                                        <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"mt-2 time" + al.note_type}>
                                                                                                            <div className='d-flex flex-column'>
                                                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                                                        <div className='avatarwrap'><Avatar className='d-flex ' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} /></div>
                                                                                                                        <p className="awriter">
                                                                                                                            <span className="font-bold">{child.by_user_name}</span>
                                                                                                                            <span> declined your request</span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className='align-content-center d-flex'>
                                                                                                                        <p className="atime align-self-center">
                                                                                                                            <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="adesc">
                                                                                                                    <span>{child.note}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Timeline.Item>
                                                                                                        : null))}
                                                                                </Timeline>
                                                                                : null}
                                                                        </div>
                                                                    </Timeline.Item>

                                                                    : al.note_type = "followup" ?
                                                                        <Timeline.Item dot={<i class="bi bi-reply-fill"></i>} className={"timefollowup"}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                        <div className='avatarwrap'><Avatar className='d-flex' size={25} icon={
                                                                                            <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />} /></div>
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'} added a feedback for viewing</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? al.added_date : ""}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex adesc p-2'>
                                                                                <p className='font-12'>
                                                                                    {al.note ? al.note : al.notes}
                                                                                </p>
                                                                            </div>
                                                                        </Timeline.Item>
                                                                        : // DEfault
                                                                        <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"time" + al.note_type}>
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='d-flex flex-row justify-content-between'>
                                                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                        <div className='avatarwrap'><Avatar className='d-flex mt-1' size={25} icon={
                                                                                            <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/defaultLogo.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />} /></div>
                                                                                        <p className="awriter">
                                                                                            <span className="font-bold">{al.by_user_name ? al.by_user_name : 'CRM'}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex adesc'>
                                                                                <p className='font-12'>
                                                                                    {al.note}
                                                                                </p>
                                                                            </div>
                                                                        </Timeline.Item>



                                        }
                                    </>)
                                })}
                            </Timeline>
                            <div className='endoftimeline'>
                                <hr /><p>End of Activity Feed</p><hr />
                            </div>
                        </Col>
                    </Row>
                }
            </Drawer>

            {/* ADD NEW LEAD */}
            <Modal title={<p className='mb-0 font-bold'><i className="bi bi-plus-circle mr-1"></i>Add New Lead</p>} className="newleadwrap" open={this.state.isAddNewLead} onOk={this.handleAddNewLeadClose} onCancel={this.handleAddNewLeadClose}
                footer={<>
                    <Button type="text" className="d-flex flex-row" style={{ height: '36px' }} onClick={this.handleAddNewLeadClose}>Cancel</Button>
                    <Button type="primary" className="d-flex flex-row" onClick={this.handleAddLead}>Submit</Button>
                </>} width={'80vw'} height={'80vh'}>
                <Row gutter={16} className='w-100 p-3'>
                    <Col span={12} xl={12} md={24} sm={24} xs={24}>
                        <Row gutter={8}>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label>Add Client Name:</label>
                                    <Input type='text' placeholder='Name' onChange={this.searchName} value={this.state.client_name} />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_name}</div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label>Contact Type:</label>
                                    <Select allowClear onChange={this.clientContactType} value={this.state.client_contact_type} showSearch
                                    placeholder="Select contact type" 
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={[
                                            { value: '1', label: 'Tenant' },
                                            { value: '2', label: 'Buyer' },
                                            { value: '3', label: 'Landlord' },
                                            { value: '4', label: 'Seller' },
                                            { value: '6', label: 'Agent' },
                                            { value: '12', label: 'Candidate' },
                                        ]}
                                    />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_contact_type}</div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label>Phone number</label>
                                    <Input type='text' placeholder='Enter phone' onChange={this.searchPhone} value={this.state.client_phone} />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_phone}</div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label>Email address:</label>
                                    <Input type='text' placeholder='Enter email address' onChange={this.clientEmail} value={this.state.client_email} />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_email}</div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label>Add Ref No</label>
                                    <Input type='text' placeholder='Enter refno' onChange={this.clientRefno} value={this.state.client_refno} />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_refno}</div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='form-group'>
                                    <label>Assign Agent</label>
                                    <Select allowClear onChange={this.clientAgent} value={this.state.client_agent} showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={this.state.agents}
                                    />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_agent}</div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label>Source</label>
                                    <Select allowClear onChange={this.clientSource} value={this.state.client_source} showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={this.state.sources} placeholder="Select source of lead"
                                    />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_source}</div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div className='form-group'>
                                    <label>Note</label>
                                    <TextArea rows={3} onChange={this.clientNote} value={this.state.client_note} />
                                    <div
                                        className="text-danger">{this.state.addleadvalidationerrors.client_note}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} xl={12} md={24} sm={24} xs={24}>
                        {this.state.client_data.length > 0 ?
                            <Table columns={newleadcol} dataSource={this.state.client_data} style={{ maxWidth: "100%", height: '100%' }}
                                pagination={{ hideOnSinglePage: true, defaultPageSize: 5, pageSize: 5 }} showHeader={false}
                                rowSelection={{ type: "radio", ...rowSelectionForClient }}
                            />
                            : <Empty className="my-5" description={<>
                                <p className='font-bold font-14 mb-1'>Start searching by name or phone</p>
                                <p className='font-12'>Enter name or phone on the {window.isIpadView ? 'top' : 'left'} to start viewing the results.</p>
                            </>} />}
                    </Col>
                </Row>
            </Modal>

            {/* ================================================================ */}
            {/* ========================== FE - START ========================== */}
            {/* ================================================================ */}
            
            <Row className='leadswrap'>
                {/* <Col span={1} className='sideactions'>
                    <div className='icongroup border-bottom' style={{height:'41px'}}>
                        <Button type='text' onClick={this.handleNewFilter}>
                            <i class="bi bi-funnel fs-5"></i>
                            <i class="bi bi-plus-circle-fill overlay-icon font-12"></i>
                        </Button>
                    </div>
                    <div className="icongroup">
                        <Popover placement="left" content={<span className='font-12'>View Tagged List</span>}><Button type='text' className="p-1 " onClick={this.handleOpenTagged}><i class="bi bi-at text-black fs-5"></i></Button></Popover>
                    </div>
                    <div className="icongroup">
                        <Popover placement="left" content={<span className='font-12'>View My Activity Feed</span>}><Button type='text' className="p-1 " onClick={this.handleOpenMyActivity}><i class="bi bi-clock-history fs-6"></i></Button></Popover>
                    </div>
                    <div className="icongroup">
                            <Segmented onChange={this.handleviewChange} className='w-100 viewasicongroup' style={{borderBottom:'1px solid rgba(21,21,21,0.16)'}}
                                options={[
                                    { value: 'Leads',label:'Leads', icon: <RiseOutlined />, },
                                    { value: 'Viewings', label:'Viewings', icon:<i class="bi bi-eye"></i>},
                                    { value: 'Offers', label:'Offers', icon:<i class="bi bi-tag"></i>},
                                ]}
                                
                                />
                        </div>
                </Col> */}
                <Col span={24} className='leads-content'>
                    <Row gutter={8} className='justify-content-between border-bottom d-none'>
                        <Col span={21}>
                            <Tabs >
                                <TabList>
                                    <Tab onClick={this.handleClearFilter}>All Leads</Tab>
                                    {this.state.filters.map((filter) => (
                                        <>
                                            <Tab><span onClick={this.handleTabs.bind(this, filter.key)} className="mr-3">{filter.label}</span><CloseOutlined onClick={this.handleDeleteFilter.bind(this, filter.key)} style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '12px', verticalAlign: 'middle' }} /></Tab>

                                        </>
                                    ))}
                                    <Tab onClick={this.handleNewFilter}><span className='font-14' style={{ opacity: '0.5' }}><i class="bi bi-plus-lg"></i>Add Filter View</span></Tab>
                                </TabList>
                            </Tabs>
                        </Col>
                        {window.isIpadView ? '' : <Col span={4} className='resetfilter'>
                            <Button type='primary' onClick={this.handleClearFilter} className={'resetallbtn w-100'}><i class="bi bi-arrow-clockwise mr-5 mt-0"></i>Reset All Filters</Button>
                        </Col>}
                    </Row>
                    {window.isIpadView ? <>
                        <Col span={24} className="px-3 py-2 filteradvwrap">
                            <Row className='d-flex flex-row align-items-center gap-2'>
                                <Input placeholder="Search client name or number" allowClear prefix={<i className="bi bi-search"></i>} onChange={this.handleSearchOwner} style={{ width: '85%', height: '46px', background: '#FFF', borderRadius: '5px' }} />
                                <Button type='text' onClick={this.handleFilterDrawerOpen} className='applyfiltersbtn'><i className="bi bi-sliders"></i></Button>
                            </Row>
                            <Row className="d-flex flex-row gap-2 align-items-center justify-content-between">
                                <Col>
                                    <Button className="w-100 statusfilt gap-3 font-14" onClick={this.handleStatusFiltOpen}>
                                        {this.state.stage_value.length > 0 ? this.state.stages.filter((stage) => this.state.stage_value.includes(stage.value)) .map((stage) => stage.label) : <p className="mb-0">Status </p>}
                                        <i className="bi bi-chevron-down"></i>
                                    </Button>

                                    <Modal transitionName="" maskTransitionName=""
                                        title={<p className='font-bold mb-0'>Select Status</p>}
                                        visible={this.state.statusfilt}
                                        onCancel={this.handleStatusFiltClose}
                                        footer={null} className="bottom-slide-modal"
                                        >
                                            <Row><Col span={24}>
                                            {filterByStatus}
                                        </Col>
                                        <Col span={24} className="d-flex justify-content-between w-100 border-t py-2">
                                            <Button className="btnreset" type="text" onClick={this.handleResetStatus}>Reset</Button>
                                            <Button className="btndone" onClick={this.handleStatusFiltClose}>Done</Button>
                                        </Col>
                                        </Row>
                                    </Modal>
                                </Col>
                                <Col className="pondswrap">
                                    {this.state.stage_value==12 ? <Button className='pondsbtn mt-0' onClick={this.handleAllLeads}><i class="bi bi-reply-all-fill" ></i> Back to All Leads</Button>
                                :<Button type='primary' className='pondsbtn mt-0 d-flex flex-row align-items-center' onClick={this.accessPondLeads}> <StarOutlined /> Access Ponds ({this.state.pond.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</Button>}
                                </Col>
                                {/* <Col>
                                    <Button className="w-100 statusfilt gap-3 font-14" onClick={this.handleVerificationFiltOpen}>
                                        {this.state.stage_value.length > 0 ? this.state.stages.filter((stage) => this.state.stage_value.includes(stage.value)) .map((stage) => stage.label) : <p className="mb-0">Verificaton </p>}
                                        <i className="bi bi-chevron-down"></i>
                                    </Button>

                                    <Modal transitionName="" maskTransitionName=""
                                        title={<p className='font-bold mb-0'>Filter by Verification</p>}
                                        visible={this.state.verificationfilt}
                                        onCancel={this.handleVerificationFiltClose}
                                        footer={null} className="bottom-slide-modal"
                                        >
                                            <Row><Col span={24}>
                                            {filterbyVerification}
                                        </Col>
                                        <Col span={24} className="d-flex justify-content-between w-100 border-t py-2">
                                            <Button className="btnreset" type="text" onClick={this.handleResetStatus}>Reset</Button>
                                            <Button className="btndone" onClick={this.handleVerificationFiltClose}>Done</Button>
                                        </Col>
                                        </Row>
                                    </Modal>
                                </Col> */}
                            </Row>




                            <Drawer closeIcon={false} className={'drawermobilefilter'} placement="left" bodyStyle={{ padding: 0, height: window.isIpadView ? '100vh' : 'auto' }}
                                title={<p className="font-bold mb-0 font-18">Filters</p>}
                                width={window.isMobileView ? "100vw" : "50%"}
                                extra={
                                    <Space>
                                        <Button type={'text'} className='mt-0' onClick={this.handleFilterDrawerClose}><i className="bi bi-x font-16"></i></Button>
                                    </Space>
                                }
                                footer ={
                                    <Row className="d-flex flex-row justify-content-between align-items-center">
                                        <Col>
                                            <Button type='text' className="searchresetbtn" onClick={this.handleClearFilter}><i className="bi bi-arrow-clockwise"></i>Clear All Filters</Button>
                                        </Col>
                                        <Col><Button type={'text'} className='searchbtn' onClick={this.handleFilterDrawerClose}>Search</Button></Col>
                                    </Row>
                                }
                                onClose={this.handleFilterDrawerClose}
                                open={this.state.FilterDrawerOpen}>
                                <Row className='content align-items-center advfilterswrap justify-content-center p-3 mt-2 align-items-center' gutter={[0, 14]}>
                                    <Col span={23}>
                                        <Space direction="vertical">
                                            <Radio.Group className="allpropfor" buttonStyle="solid" defaultValue="all" onChange={this.handleSaleRentChange }>
                                                <Radio.Button value="all">All</Radio.Button>
                                                <Radio.Button value="sale">Sale</Radio.Button>
                                                <Radio.Button value="rent">Rent</Radio.Button>
                                            </Radio.Group>
                                        </Space>
                                    </Col>
                                    <Col span={23}>
                                        <label className='font-12 font-bold mb-1'>Search</label>
                                        <Input placeholder="Enter client name or phone number" allowClear prefix={<i class="bi bi-search"></i>} onChange={this.handleSearchOwner} style={{ width: '100%', height: '46px', background: '#FFF', borderRadius: '5px' }} />
                                    </Col>
                                    <Col span={23}>
                                        <label className='font-12 font-bold mb-1'>Search by the last 4 digits</label>
                                        <Input maxLength={4} placeholder="Enter the last 4 digits" allowClear prefix={<i class="bi bi-telephone"></i>} onChange={this.handleSearchOwnerWithFourDigit} style={{ width: '100%', height: '46px', background: '#FFF', borderRadius: '5px',}} disabled={this.state.isRequestInProgress} />
                                    </Col>
                                    <Col span={23}>
                                        <label className='font-12 font-bold mb-1'> <i className="bi bi-calendar2-week mr-1"></i>Inquiry Date:</label>
                                        <Space direction="vertical" style={{ rowGap: '5px'}}>
                                            <div style={{ position:'relative', width:'100%'}}>
                                            <Select placeholder="Inquiry Date" className="dateInquiry" style={{height:46,borderLeft:0, borderRadius:0}} allowClear
                                            onChange={this.handleInquiryChange}
                                            options={[
                                            { value: '7', label: 'One Week' },
                                            { value: '30', label: 'Last 30 Days' },
                                            { value: '90', label: 'Last 3 Months' },
                                            { value: '180', label: 'Last 6 Months' },
                                            { value: '270', label: 'Last 9 Months' },
                                            { value: '365', label: 'Last 12 Months' },
                                            { value: '365+', label: 'One Year +' },
                                            ]} />
                                            </div>
                                        </Space>
                                    </Col>
                                    <Col span={23}>
                                        <label className='font-12 font-bold'>Contact Type</label>
                                        { window.isMobileView ? <>
                                            <Button className="w-100 contacttypefilt" style={{ minWidth: 120, height: 46 }} onClick={this.handleFilterDrawerMobileOpen}>
                                                {this.state.contact_types_value.length > 0
                                                    ? this.state.contact_types
                                                        .filter((type) => this.state.contact_types_value.includes(type.value))
                                                        .map((type) => type.label)
                                                        .join(', ')
                                                    : 'Choose Contact Type'}
                                            </Button>

                                            <Modal transitionName="" maskTransitionName=""
                                                title={<p className='font-bold mb-0'>Select Contact Type</p>}
                                                visible={this.state.MobileFilterDrawerOpen}
                                                onCancel={this.handleFilterDrawerMobileClose}
                                                footer={null} className="bottom-slide-modal"
                                                >
                                                    <Row><Col span={24}>
                                                    <Checkbox.Group
                                                        onChange={this.handleContactType}
                                                        value={this.state.contact_types_value}
                                                    >
                                                        {this.state.contact_types.map((type) => (
                                                            <Checkbox key={type.value} value={type.value}>
                                                                {type.label}
                                                            </Checkbox>
                                                        ))}
                                                    </Checkbox.Group>
                                                </Col>
                                                <Col span={24} className="d-flex justify-content-between w-100 border-t py-2">
                                                    {/* <Button className="btnreset"  type="text" /* </Col>onClick={this.handleResetBeds}  >Reset</Button> */}
                                                    <Button className="btndone" onClick={this.handleFilterDrawerMobileClose}>Done</Button>
                                                </Col>
                                                </Row>
                                                </Modal>
                                        </> : <Select mode="multiple" value={this.state.contact_types_value} className='w-100 contacttypefilt' 
                                        placeholder="Contact Type" onChange={this.handleContactType} options={this.state.contact_types} style={{ minWidth: 120, height:46, borderLeft:0, borderRadius:0 }} allowClear
                                        />}
                                    </Col>
                                    <Col span={23}>
                                        <label className='font-12 font-bold mb-1'>Property Type</label>
                                        { window.isMobileView ? <>
                                            <Button className="w-100 contacttypefilt" style={{ minWidth: 120, height: 46 }} onClick={this.handlePropTypeOpen}>
                                                {this.state.property_type_filter.length > 0
                                                    ? this.state.propertytypes
                                                        .filter((type) => this.state.property_type_filter.includes(type.value))
                                                        .map((type) => type.label)
                                                        .join(', ') // Join labels with commas or any other separator you prefer
                                                : 'Choose Property Type'}
                                            </Button>

                                            <Modal transitionName="" maskTransitionName=""
                                                title={<p className='font-bold mb-0'>Select Property Type</p>}
                                                visible={this.state.proptypemodal}
                                                onCancel={this.handlePropTypeClose}
                                                footer={null} className="bottom-slide-modal proptypemodal"
                                                >
                                                    <Row><Col span={24}>
                                                    <Checkbox.Group
                                                        onChange={this.handlePropertyTypeFilter}
                                                        value={this.state.property_type_filter}
                                                    >
                                                        {this.state.propertytypes.map((type) => (
                                                            <Checkbox key={type.value} value={type.value}>
                                                                {type.label}
                                                            </Checkbox>
                                                        ))}
                                                    </Checkbox.Group>
                                                </Col>
                                                <Col span={24} className="d-flex justify-content-between w-100 border-t py-2">
                                                    <Button className="btnreset"  type="text" onClick={this.handleClearPropertyType}>Reset</Button>
                                                    <Button className="btndone" onClick={this.handlePropTypeClose}>Done</Button>
                                                </Col>
                                                </Row>
                                                </Modal>
                                        </> : <Select className="propertyTypewrap" showSearch allowClear mode="multiple" value={this.state.property_type_filter} placeholder="Property Type" onChange={this.handlePropertyTypeFilter} style={{ width: '100%', height:46, borderLeft:0, borderRadius:0 }} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.propertytypes} />
                                        }
                                    </Col>
                                    <Col span={23}>
                                    <label className='font-12 font-bold mb-1'>Community</label>
                                        <Space direction="vertical">
                                            <Select allowClear showSearch className="filter-community" style={{ width: '100%', height:46 }} value={this.state.community_filter || null} placeholder="Community" onChange={this.handleCommunityFilter}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                options={this.state.communities} />
                                        </Space>
                                    </Col>
                                    <Col span={23}>
                                    <label className='font-12 font-bold mb-1'>Sub Community</label>
                                    <Space direction="vertical">
                                        <Select showSearch allowClear value={this.state.sub_community_filter || null} style={{ width: '100%', height:46 }} placeholder="Sub Community" onChange={this.handleSubCommunityFilter}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.state.sub_communities} />
                                    </Space>
                                    </Col>
                                    <Col span={23}>
                                    <label className='font-12 font-bold mb-1'>Property</label>
                                        <Space direction="vertical">
                                            <Select showSearch allowClear value={this.state.property_filter|| null} style={{ width: '100%', height:46 }} placeholder="Property" onChange={this.handlePropertyFilter}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.properties} />
                                        </Space>
                                    </Col>
                                    <Col span={23} className="mobfilterwrap">
                                    <label className='font-12 font-bold mb-1'>Bedrooms</label>
                                    <Space direction="vertical">
                                        <div className="d-flex flex-row filterselect w-100">
                                            <Popover  title={<Row className="d-flex flex-row justify-content-between align-items-start"><Col><p className='pb-0 font-bold'>Bedrooms</p></Col><Col><i className="bi bi-x" style={{color:'#151515', fontSize:'2rem', cursor:'pointer', height:10, display:'flex'}} onClick={this.handleCloseBedsPopover}></i></Col></Row>} placement="bottom" trigger="click"
                                            visible={this.state.bedspopover} onVisibleChange={this.handleOpenBedsPopover}
                                                content={<Row className='bedroomfilter flex-column'><Col>
                                                            <Radio.Group buttonStyle="solid" size="large" onChange={this.handleBedRoomsValue} value={this.state.bed_rooms_value}>
                                                                <Radio.Button value="1">1</Radio.Button>
                                                                <Radio.Button value="2">2</Radio.Button>
                                                                <Radio.Button value="3">3</Radio.Button>
                                                                <Radio.Button value="4">4</Radio.Button> 
                                                                <Radio.Button value="5">5</Radio.Button>
                                                                <Radio.Button value="6">6</Radio.Button>
                                                                <Radio.Button value="7">7</Radio.Button>
                                                                <Radio.Button value="7+">7+</Radio.Button>
                                                            </Radio.Group>
                                                        </Col>
                                                        <Col className="d-flex justify-content-between">
                                                            <Button className="btnreset"  onClick={this.handleResetBeds}>Reset</Button>
                                                            <Button className="btndone" onClick={this.handleCloseBedsPopover}>Done</Button>
                                                        </Col>
                                                    </Row>}>
                                                <Button className="btnWrap">{this.state.bed_rooms_value? this.state.bed_rooms_value + ' Beds' : "Bedrooms"}</Button>
                                            </Popover>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={23} className="mobfilterwrap">
                                <label className='font-12 font-bold mb-1'>Price</label>
                                    <Space direction="vertical">
                                        <div className="d-flex flex-row filterselect w-100">
                                            {/* <Select placeholder="Price" className='w-100' style={{ width: 80 }} /> */}
                                            <Popover title={<Row className="d-flex flex-row justify-content-between align-items-start"><Col><p className='pb-0 font-bold'>Price (AED)</p></Col><Col><i className="bi bi-x" style={{color:'#151515', fontSize:'2rem', cursor:'pointer', height:10, display:'flex'}} onClick={this.handleClosePricePopover}></i></Col></Row>} placement="bottom" trigger="click" visible={this.state.pricepopover} onVisibleChange={this.handleOpenPricePopover}
                                                content={<Row className='pricefiltergrp d-flex flex-column gap-1'>
                                                <Col className="d-flex flex-row gap-1 align-items-center">
                                                        <div className={'d-flex flex-row pricefilt'} style={{ width: window.isMobileView ? '150px' : 'unset' }}>
                                                        <label className='lblfilter d-flex align-items-center px-2' style={{ background: 'rgba(21,21,21,0.06)' }}>Min</label>
                                                        <CurrencyInput style={{ padding: '5px 11px' }} className="pricefilt-min"
                                                            id="input-example"
                                                            name="input-price-min"
                                                            placeholder="min"
                                                            defaultValue={this.state.price_min==0?"":this.state.price_min}
                                                            decimalsLimit={2}
                                                            onValueChange={this.handlePriceMin}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    <span className="px-1">-</span>
                                                    <div className={'d-flex flex-row pricefilt'}>
                                                        <label className='lblfilter d-flex align-items-center px-2' style={{ background: 'rgba(21,21,21,0.06)' }}>Max</label>
                                                        <CurrencyInput style={{ padding: '5px 11px' }}  className="pricefilt-min"
                                                            id="input-example"
                                                            name="input-price-max"
                                                            placeholder="max"
                                                            defaultValue={this.state.price_max==0?"":this.state.price_max}
                                                            decimalsLimit={2}
                                                            onValueChange={this.handlePriceMax}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="d-flex justify-content-between w-100">
                                                    <Button className="btnreset"  /* </Col>onClick={this.handleResetBeds} */ >Reset</Button>
                                                    <Button className="btndone" onClick={() => { this.handleClosePricePopover(); }}>Done</Button>
                                                </Col>
                                                
                                                </Row>}>
                                                <Button className="btnWrap">
                                                    {this.state.price_min || this.state.price_max ? 
                                                        (this.state.price_min ? "From " + shortenPrice(this.state.price_min) : "")+
                                                        (this.state.price_max ? this.state.price_min ? " - " + shortenPrice(this.state.price_max) : 'Up to ' + shortenPrice(this.state.price_max) : '')
                                                    :'Price'
                                                    }

                                                </Button>
                                            </Popover>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={23}>
                                <label className='font-12 font-bold mb-1'>Fully Furnished/Unfurnished</label>
                                    <Space direction="vertical">
                                        <div className="d-flex flex-row filterselect w-100">
                                            <Select placeholder="Choose" className='w-100' style={{ width: 80, borderTopRightRadius:(role_name == "Admin" || role_name == "Super Admin")?0:5, borderBottomRightRadius:(role_name == "Admin" || role_name == "Super Admin")?0:5 }} value={this.state.furnished_types_value} onChange={this.handleFurnishedTypes} options={this.state.furnished_types} mode="multiple" />
                                        </div>
                                    </Space>
                                </Col>
                                {(role_name == "Admin" || role_name == "Super Admin") &&
                                <Col span={23} className='d-flex flex-column'><label className='font-12 font-bold mb-1'>Agent</label>
                                    <Space direction="vertical">
                                        <Select showSearch mode={'multiple'} maxTagCount={'responsive'} className='agentwrapfilter'
                                            value={this.state.agent_value} allowClear style={{ width: '100%', minWidth: '100px' }}
                                            placeholder="Agent"
                                            onChange={this.handleAgent} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.state.agents}
                                        />
                                    </Space>
                                </Col>
                                }
                                </Row>
                            </Drawer>
                        </Col>

                        
                    </> :
                        
                        <Card style={{maxWidth:'97vw', margin:'1rem auto 0', border:'none', borderRadius:0}} className="leadsfilterwrap">
                            <Row className='d-flex flex-row w-100 mb-3 pb-3 justify-content-between border-b'>
                                <Col span={12} className='d-flex flex-row gap-2'>
                                    <h2 className='font-bold'>Leads</h2>
                                </Col>
                                
                                <Col className="d-flex flex-row gap-3">
                                {(role_name == "Admin" || role_name == "Super Admin" || user_id == 488 || user_id == 497 || user_id == 498) && <Button type='primary' className=" mt-0" onClick={this.handleMergeLeads}><i className="bi bi-sign-merge-right mr-1" ></i>Merge Leads</Button> }
                                    <Button type='primary' className='mt-0 font-12' onClick={this.handleAddNewLeadOpen}><i className=" bi bi-plus-lg fs-5"></i>Add New Lead</Button>
                                </Col>
                            </Row>
                            <Row gutter={[0,8]} className="leadsfilters-cont">
                                <Col span={24} className='propforFilter'>
                                    <Row className="d-flex justify-content-between">
                                        <Col span={12} className="d-flex flex-row gap-1">
                                            <Radio.Group className="allpropfor" buttonStyle="solid" defaultValue="all" onChange={this.handleSaleRentChange }>
                                                <Radio.Button value="all">All</Radio.Button>
                                                <Radio.Button value="sale">Sale</Radio.Button>
                                                <Radio.Button value="rent">Rent</Radio.Button>
                                            </Radio.Group>
                                            {this.state.stage_value==12 ? <Button className='pondsbtn mt-0 ml-1' style={{height:46}} onClick={this.handleAllLeads}><i class="bi bi-reply-all-fill" ></i> Show All Leads</Button>
                                            : <Button type='primary' className='pondsbtn mt-0 d-flex flex-row align-items-center ml-1' onClick={this.accessPondLeads} style={{height:46, paddingLeft:7}}> <StarOutlined style={{pl:0}}/> Ponds ({this.state.pond.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</Button>}
                                        </Col>
                                        <Col className="d-flex">
                                            <Button type='text' className='mt-0 resetallbtn' onClick={this.handleClearFilter}><i class="bi bi-arrow-clockwise mt-0"></i>Reset All Filters</Button>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col span={24}>
                                    <Row className="filtersearchwrap">
                                        <Col span={7}>
                                            <Input placeholder="Search client name, phone number or requirements" allowClear prefix={<i class="bi bi-search"></i>} onChange={this.handleSearchOwner} style={{ width: '100%', height: '46px', background: '#FFF', borderRadius: '5px 0 0 5px' }} />
                                        </Col>
                                        <Col span={5}>
                                            <Input maxLength={4} placeholder="Enter the last 4 digits" allowClear prefix={<i class="bi bi-telephone"></i>} onChange={this.handleSearchOwnerWithFourDigit} style={{ width: '100%', height: '46px', background: '#FFF', borderRadius: '0', borderLeft:0}} disabled={this.state.isRequestInProgress} />
                                        </Col>
                                        <Col span={4}>
                                            <Space direction="vertical" style={{ rowGap: '5px'}}>
                                                <div style={{ position:'relative', width:'100%'}}>
                                                <i className="bi bi-calendar2-week" style={{ position: 'absolute',top: '50%',left: 10,transform: 'translateY(-50%)',zIndex: 1,}}></i>
                                                <Select placeholder="Inquiry Date" className="dateInquiry" style={{height:46,borderLeft:0, borderRadius:0}} allowClear
                                                onChange={this.handleInquiryChange}
                                                options={[
                                                { value: '7', label: 'One Week' },
                                                { value: '30', label: 'Last 30 Days' },
                                                { value: '90', label: 'Last 3 Months' },
                                                { value: '180', label: 'Last 6 Months' },
                                                { value: '270', label: 'Last 9 Months' },
                                                { value: '365', label: 'Last 12 Months' },
                                                { value: '365+', label: 'One Year +' },
                                                ]} />
                                                </div>
                                            </Space>
                                        </Col>
                                        <Col span={4}>
                                            <Space direction="vertical" style={{ rowGap: '2px'}}>
                                                <Select mode="multiple" value={this.state.contact_types_value} className='w-100 contacttypefilt' 
                                                    placeholder="Contact Type" onChange={this.handleContactType} options={this.state.contact_types} style={{ minWidth: 120, height:46, borderLeft:0, borderRadius:0 }} allowClear filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                />
                                            </Space>
                                        </Col>
                                        <Col span={4}>
                                            <Space direction="vertical" style={{ rowGap: '2px' }}>
                                                <Select className="propertyTypewrap" showSearch allowClear mode="multiple" value={this.state.property_type_filter} placeholder="Property Type" onChange={this.handlePropertyTypeFilter} style={{ width: '100%', height:46, borderLeft:0, borderRadius:0 }}
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.propertytypes} />
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical">
                                        <Select allowClear showSearch className="filter-community" style={{ width: '100%', }} value={this.state.community_filter || null} placeholder="Community" onChange={this.handleCommunityFilter}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.state.communities} />
                                    </Space>
                                </Col>
                                <Col span={4}>
                                <Space direction="vertical">
                                     <Select showSearch allowClear value={this.state.sub_community_filter || null} placeholder="Sub Community" onChange={this.handleSubCommunityFilter}
                                         filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                         options={this.state.sub_communities} />
                                 </Space>
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical">
                                        <Select showSearch allowClear value={this.state.property_filter|| null} placeholder="Property" onChange={this.handlePropertyFilter}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.properties} />
                                    </Space>
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical">
                                        <div className="d-flex flex-row filterselect w-100">
                                            <Popover  title={<Row className="d-flex flex-row justify-content-between align-items-start"><Col><p className='pb-0 font-bold'>Bedrooms</p></Col><Col><i className="bi bi-x" style={{color:'#151515', fontSize:'2rem', cursor:'pointer', height:10, display:'flex'}} onClick={this.handleCloseBedsPopover}></i></Col></Row>} placement="bottom" trigger="click"
                                            visible={this.state.bedspopover} onVisibleChange={this.handleOpenBedsPopover}
                                                content={<Row className='bedroomfilter flex-column'><Col>
                                                            <Radio.Group buttonStyle="solid" size="large" onChange={this.handleBedRoomsValue} value={this.state.bed_rooms_value}>
                                                                <Radio.Button value="1">1</Radio.Button>
                                                                <Radio.Button value="2">2</Radio.Button>
                                                                <Radio.Button value="3">3</Radio.Button>
                                                                <Radio.Button value="4">4</Radio.Button> 
                                                                <Radio.Button value="5">5</Radio.Button>
                                                                <Radio.Button value="6">6</Radio.Button>
                                                                <Radio.Button value="7">7</Radio.Button>
                                                                <Radio.Button value="7+">7+</Radio.Button>
                                                            </Radio.Group>
                                                        </Col>
                                                        <Col className="d-flex justify-content-between">
                                                            <Button className="btnreset"  onClick={this.handleResetBeds}>Reset</Button>
                                                            <Button className="btndone" onClick={this.handleCloseBedsPopover}>Done</Button>
                                                        </Col>
                                                    </Row>}>
                                                <Button className="btnWrap">{this.state.bed_rooms_value? this.state.bed_rooms_value + ' Beds' : "Bedrooms"}</Button>
                                            </Popover>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical">
                                        <div className="d-flex flex-row filterselect w-100">
                                            <Popover title={<Row className="d-flex flex-row justify-content-between align-items-start"><Col><p className='pb-0 font-bold'>Price (AED)</p></Col><Col><i className="bi bi-x" style={{color:'#151515', fontSize:'2rem', cursor:'pointer', height:10, display:'flex'}} onClick={this.handleClosePricePopover}></i></Col></Row>} placement="bottom" trigger="click" visible={this.state.pricepopover} onVisibleChange={this.handleOpenPricePopover}
                                                content={<Row className='pricefiltergrp d-flex flex-column gap-1'>
                                                <Col className="d-flex flex-row gap-1 align-items-center">
                                                        <div className={'d-flex flex-row pricefilt'} style={{ width: window.isMobileView ? '150px' : 'unset' }}>
                                                        <label className='lblfilter d-flex align-items-center px-2' style={{ background: 'rgba(21,21,21,0.06)' }}>Min</label>
                                                        <CurrencyInput style={{ padding: '5px 11px' }} className="pricefilt-min"
                                                            id="input-example"
                                                            name="input-price-min"
                                                            placeholder="min"
                                                            value={this.state.price_min==0?"":this.state.price_min}
                                                            decimalsLimit={2}
                                                            onValueChange={this.handlePriceMin}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    <span className="px-1">-</span>
                                                    <div className={'d-flex flex-row pricefilt'}>
                                                        <label className='lblfilter d-flex align-items-center px-2' style={{ background: 'rgba(21,21,21,0.06)' }}>Max</label>
                                                        <CurrencyInput style={{ padding: '5px 11px' }}  className="pricefilt-min"
                                                            id="input-example"
                                                            name="input-price-max"
                                                            placeholder="max"
                                                            value={this.state.price_max==0?"":this.state.price_max}
                                                            decimalsLimit={2}
                                                            onValueChange={this.handlePriceMax}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="d-flex justify-content-between w-100">
                                                    <Button className="btnreset"  /* </Col>onClick={this.handleResetBeds} */ >Reset</Button>
                                                    <Button className="btndone" onClick={() => { this.handleClosePricePopover(); }}>Done</Button>
                                                </Col>
                                                
                                                </Row>}>
                                                <Button className="btnWrap">
                                                    {this.state.price_min || this.state.price_max ? 
                                                        (this.state.price_min ? "From " + shortenPrice(this.state.price_min) : "")+
                                                        (this.state.price_max ? this.state.price_min ? " - " + shortenPrice(this.state.price_max) : 'Up to ' + shortenPrice(this.state.price_max) : '')
                                                    :'Price'
                                                    }

                                                </Button>
                                            </Popover>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical">
                                        <div className="d-flex flex-row filterselect w-100">
                                            <Select placeholder="Fully Furnished/Unfurnished" className='w-100' style={{ width: 80, borderTopRightRadius:(role_name == "Admin" || role_name == "Super Admin")?0:5, borderBottomRightRadius:(role_name == "Admin" || role_name == "Super Admin")?0:5 }} value={this.state.furnished_types_value} onChange={this.handleFurnishedTypes} options={this.state.furnished_types} mode="multiple" />
                                        </div>
                                    </Space>
                                </Col>
                                {(role_name == "Admin" || role_name == "Super Admin") &&
                                <Col span={4} className='d-flex flex-column'>
                                    <Space direction="vertical">
                                        <Select showSearch mode={'multiple'} maxTagCount={'responsive'} className='agentwrapfilter'
                                            value={this.state.agent_value} allowClear style={{ width: '100%', minWidth: '100px' }}
                                            placeholder="Agent"
                                            onChange={this.handleAgent} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.state.agents}
                                        />
                                    </Space>
                                </Col>
                                }
                                <Col span={4} offset={(role_name == "Admin" || role_name == "Super Admin") ? 16: 20} >
                                    <Button onClick={this.handleSearchAll} className="searchbtn mt-0" style={{height:46,minWidth:'100%', opacity:1}}>Search</Button>
                                </Col>
                            </Row>
                        </Card>
                    }

                    <Row className='content filterwrap formcheckboxgroup filterbg border-t'>
                        <Col xs={24} sm={24} md={20} lg={19} xl={19} xxl={19}>
                            {window.isIpadView ? <>
                                </> : this.state.stage_value!=12 && [filterbyVerification]}

                            {window.isIpadView ? <></> 
                                : (this.state.stage_value!=12 ? [filterByStatus] : <p className="totalpond font-bold">{this.state.leads.total 
                                    ? this.state.leads.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'} Leads</p>)
                                }


                            {this.state.stage_value && this.state.stage_value.length > 0 && this.state.stage_value[0] == 25 ?
                                <Col className='d-flex flex-row'>
                                    <p className='mr-1 mb-0 font-12 font-bold' style={{ whiteSpace: "nowrap" }}>Filter by verified:</p>
                                    <div className='d-flex flex-column'>
                                        <CheckboxGroup value={this.state.verified_sub_stage_value} onChange={this.handleVerifiedStages} placeholder="Status"
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                { value: '1', label: 'New Inquiry  ( ' + this.state.verfied_new_inquire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                                { value: '2', label: 'Working ( ' + this.state.verfied_working.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                                { value: '8', label: 'Viewings  ( ' + this.state.verfied_viewing.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                                { value: '6', label: 'Offer ( ' + this.state.verfied_offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                                { value: '10', label: 'No Answer ( ' + this.state.verfied_no_answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                                { value: '11', label: 'Expired ( ' + this.state.verfied_expired.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                                { value: '12', label: 'Pond' },
                                                { value: '24', label: 'Referral Sent', hidden: role == 1 || user_id == 488 || user_id == 497 || user_id == 498 ? false : true },
                                                { value: '23', label: 'Referral Received', hidden: role == 1 || user_id == 488 || user_id == 497 || user_id == 498 ? false : true },
                                                { value: '26', label: 'Verification call', hidden: role == 1 || user_id == 488 || user_id == 497 || user_id == 498 ? false : true },
                                                { value: '27', label: 'Whatsapp verfied', hidden: role == 1 || user_id == 488 || user_id == 497 || user_id == 498 ? false : true },
                                                { value: '28', label: 'Past Client' }
                                            ].filter(item => !item.hidden)} />

                                    </div>
                                </Col>
                                : null}

                            {this.state.isViewingTabShow == true ?
                                <Col span={24} className='d-flex flex-row pt-3 mt-1'>
                                    <p className='mr-2 mb-0 font-12 font-bold'>Filter by Viewing status:</p>
                                    <CheckboxGroup
                                        value={this.state.nested_stage_value} onChange={this.handleFilterStages} placeholder="Status"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={[
                                            { value: '8', label: 'Scheduled  ( ' + viewing_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                            { value: '16', label: 'Second Viewing ( ' + second_viewing.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                            { value: '17', label: 'Not Interested  ( ' + not_interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                            { value: '18', label: 'Considering ( ' + consider.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                        ].filter(item => !item.hidden)}
                                    />
                                </Col> : null}
                            {this.state.isOfferTabShow ? <Col span={24} className='d-flex flex-row pt-3 mt-1'>
                                <p className='mr-2 mb-0 font-12 font-bold'>Filter by Offer status:</p>
                                <CheckboxGroup
                                    value={this.state.nested_stage_value} onChange={this.handleFilterStages} placeholder="Status"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={[
                                        { value: '6', label: 'Offer sent  ( ' + offer_sent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                        { value: '13', label: 'Offer accepted  ( ' + offer_accepted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                        { value: '14', label: 'Offer rejected ( ' + offer_rejected.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' },
                                    ].filter(item => !item.hidden)}
                                />
                            </Col> : null}

                            {this.state.pendingLeadsShow == true ?
                                <Col span={24} className=" pendingleadswrap">
                                    <Row className="d-flex flex-row justify-content-between w-100 align-items-center py-2">
                                        <Col className="d-flex flex-row" style={{ gap: '0.3rem' }}>
                                            <p className="font-12 mb-0">Sort by: </p>
                                            <p className="font-12 mb-0">Time Remaining</p>
                                            <i class="bi bi-sort-down"></i>
                                            {/* <i class="bi bi-sort-up"></i> */}
                                        </Col>
                                        {role_name == "Admin" || role_name == "Super Admin" && (<Row className='d-flex flex-row' style={{ gap: '0.6rem' }}>
                                            <Col className="d-flex flex-row align-items-center" style={{ gap: '0.3rem' }}>
                                                <p className="font-12 mb-0">Filter by Team: </p>
                                                <Select showSearch
                                                    style={{ minWidth: '200px', height: 36, borderRadius: 0 }}
                                                    // onChange={handleChange}
                                                    options={[
                                                        { value: '1', label: 'Chris Timoney' },
                                                        { value: '2', label: 'Rhys Benhadj' },
                                                        { value: '3', label: 'George Hughes' },
                                                        { value: '4', label: 'Kyle Guisti' },
                                                        { value: '5', label: 'Ellie Chambers' },
                                                    ]}
                                                />
                                            </Col>
                                            <Col className="d-flex flex-row align-items-center" style={{ gap: '0.3rem' }}>
                                                <p className="font-12 mb-0">Filter by Agents: </p>
                                                <Select showSearch
                                                    style={{ minWidth: '200px', height: 36, borderRadius: 0 }}
                                                    // onChange={handleChange}
                                                    options={[
                                                        { value: '1', label: 'Chris Timoney' },
                                                        { value: '2', label: 'Rhys Benhadj' },
                                                        { value: '3', label: 'George Hughes' },
                                                        { value: '4', label: 'Kyle Guisti' },
                                                        { value: '5', label: 'Ellie Chambers' },
                                                    ]}
                                                />
                                            </Col>
                                        </Row>)}
                                    </Row>
                                </Col>
                                : null
                            }
                        </Col>
                        {/* {window.isIpadView ? '':<Col className='d-flex align-items-end pb-1 pr-1'>
                            {this.state.stage_value==12 ? <Button className='pondsbtn mt-0' onClick={this.handleAllLeads}><i class="bi bi-reply-all-fill" ></i> Back to All Leads</Button>
                            :<Button type='primary' className='pondsbtn mt-0' onClick={this.accessPondLeads}> <StarOutlined /> Access Pond Leads ({this.state.pond.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</Button>}
                        </Col>} */}
                        {/* {(role_name == "Admin" || role_name == "Super Admin") &&<Col span={4}>
                             <Button type='primary' className=" mt-0" onClick={this.handleMergeLeads}><i className="bi bi-sign-merge-right mr-2" ></i>Merge Leads</Button>
                             <Button type='primary' className=" mt-0" onClick={this.handleVerificationCall}><i className="bi bi-telephone-plus mr-2" ></i>Verification Call</Button>
                        </Col>} */}
                    </Row>

                    {(role_name == "Admin" || role_name == "Super Admin") && <Row className="d-flex justify-content-between px-4 pt-3">
                        <Col>
                            <Button onClick={this.selectAllRows}>
                                {this.state.selectedRowKeys?.length > 0 &&
                                this.state.leads?.data?.length > 0 &&
                                this.state.selectedRowKeys.length === this.state.leads.data.length
                                ? "Deselect All"
                                : "Select All"}
                            </Button>
                            </Col>

                        <Col className="d-flex flex-row gap-2">
                            <Button type='primary' className=" mt-0" onClick={this.exportChosenLeads}><i className="bi bi-files mr-2" ></i>Export selected (Excel)</Button>
                            <Button type='primary' className=" mt-0" onClick={this.exportLeads}><i className="bi bi-files mr-2" ></i>Export All (Excel)</Button>
                        </Col>
                    </Row>}
                    <Row>
                        <Col span={24}>
                            {this.state.isLoadingt ?
                                <div className='w-100 d-flex my-5 py-5'>
                                    <Spin className='spinnerOp mx-auto' size='large' indicator={<Spin size="large" style={{ color: '#151515' }} />} />
                                </div>
                                : <>
                                    {this.state.isViewAsGallery ? <>
                                        {this.state.pendingLeadsShow ?
                                            <Table showHeader={false} className='pendingLeadsTable'

                                                pagination={{
                                                    pageSizeOptions: ['10', '20', '50', '100'],
                                                    total:
                                                        this.state?.leads?.total > 0 &&
                                                        this.state.leads.total,
                                                    pageSize: this.state.leads.per_page,
                                                    current: this.state.leads.current_page,
                                                    showTotal: window.isMobileView ? (total, range) =>
                                                        `Showing ${range[0]} to ${range[1]} of ${total} entries` : '',
                                                    showSizeChanger: true,
                                                    onShowSizeChange: onShowSizeChange,
                                                    itemRender: itemRender,
                                                }}
                                                columns={pendingleads_columns}
                                                dataSource={
                                                    this.state?.leads?.data
                                                        ? this.state?.leads?.data
                                                        : []
                                                }
                                            />

                                            : <div style={{ marginBottom: '20px' }}>
                                                <Table onChange={handleTableChange} className='tableleads'
                                                    rowSelection={rowSelection} 
                                                    showHeader={false}
                                                    loading={this.state.isLoading ?
                                                        { indicator: <Spin size="large" style={{ color: '#151515' }} /> } : false
                                                    }
                                                    pagination={{
                                                        pageSizeOptions: ['10', '20', '50', '100'],
                                                        total:
                                                            this.state?.leads?.total > 0 &&
                                                            this.state.leads.total,
                                                        pageSize: this.state.leads.per_page,
                                                        current: this.state.leads.current_page,
                                                        showTotal: (total, range) =>
                                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                        showSizeChanger: true,
                                                        onShowSizeChange: onShowSizeChange,
                                                        itemRender: itemRender,
                                                    }}
                                                    columns={window.isTabView ? LeadsColumns : columns}
                                                    scroll={{ x: window.isTabView ? 'auto' : '1300' }}  
                                                    dataSource={
                                                        this.state?.leads?.data
                                                            ? this.state?.leads?.data
                                                            : []
                                                    }
                                                    rowKey={(record) => record.original_id}
                                                    rowClassName={(record) => {
                                                        let classNames = '';
                                                        // change lead_stage to accept or decline record
                                                        if (record.lead_status == 0) { classNames += 'blur-row '; }
                                                        if (record.pinned === 1 || record.pinned === 2) { classNames += 'pinned '; }
                                                        return classNames.trim();
                                                    }}
                                                /></div>
                                        }
                                    </> : <div style={{ marginBottom: '20px', paddingTop: '20px' }}>
                                        <Table onChange={handleTableChange} className='tableleads'
                                            rowSelection={rowSelection}
                                            //showHeader={false}
                                            loading={this.state.isLoading ?
                                                { indicator: <Spin size="large" style={{ color: '#151515' }} /> } : false
                                            }
                                            pagination={{
                                                pageSizeOptions: ['10', '20', '50', '100'],
                                                total:
                                                    this.state?.leads?.total > 0 &&
                                                    this.state.leads.total,
                                                pageSize: this.state.leads.per_page,
                                                current: this.state.leads.current_page,
                                                showTotal: (total, range) =>
                                                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                showSizeChanger: true,
                                                onShowSizeChange: onShowSizeChange,
                                                itemRender: itemRender,
                                            }}
                                            columns={window.isTabView ? LeadsColumns : table_columns}
                                            scroll={{ x: window.isTabView ? 'auto' :'1300' }}
                                            dataSource={
                                                this.state?.leads?.data
                                                    ? this.state?.leads?.data
                                                    : []
                                            }
                                            rowKey={(record) => record.original_id}
                                            rowClassName={(record) =>
                                                record.pinned == 1 || record.pinned == 2 ? "pinned" : null
                                            }
                                        />
                                    </div>}
                                </>}

                        </Col>
                    </Row>

                    <div className='d-flex px-3'>
                        <div className='row w-100'>
                            <Space direction="vertical">
                                <Space wrap>
                                    {/* <div className="d-flex flex-row justify-content-between my-2 w-100">
                                       
                                        <div className="d-flex flex-row justify-content-between">
      
                                            {(role_name == "Admin" || role_name == "Super Admin" || user_id==488 || user_id==497 || user_id==498) && <Button type='primary' className='' onClick={this.handleAddNewLeadOpen}>Add New Lead</Button>}
                                            <Button type='primary' className="ml-2 addfilter" onClick={this.handleNewFilter} icon={<FilterOutlined />}>
                                                Add new filter
                                            </Button>
                                            <Button className='ml-2 font-12 align-items-center d-flex flex-row' type='primary' onClick={this.handleClearFilter}><i class="bi bi-arrow-clockwise mr-1 mt-1"></i>Reset filter</Button>
                                        </div>
                                    </div> */}
                                    {/* <Row>
                                        <Col span={24}>
                                            <div className='d-flex flex-row align-items-center'>
                                                <p className='mr-2 mb-0 font-12 font-bold' style={{whiteSpace:"nowrap"}}>Smart search:</p>
                                                <Input placeholder="" allowClear onChange={this.handleSearchOwner}  style={{maxWidth:'300px', height:'32px', borderRadius:'0.3rem'}}/>
                                            </div>
                                        </Col>
                                    </Row> */}
                                    {/* {role==1?
                                    <Row className='filterswrap teamfilter'>
                                        <Col className='d-flex flex-row  align-items-center'>
                                        <p className='mr-2 mb-0 font-12 font-bold' style={{whiteSpace:"nowrap"}}>Filter by Team:</p>
                                        <Tabs>
                                            <TabList>
                                            {this.state.teams.length > 0? <Tab onClick={this.handleClearTeam}><span>All</span></Tab>:null}
                                            {this.state.teams.map((team,index)=>(
                                                <>
                                                    <Tab onClick={this.handleTeams.bind(this,team.value)} ><span>{team.label}</span></Tab>
                                                </>
                                            ))}
                                            </TabList>
                                        </Tabs>
                                        </Col>
                                    </Row>
                                    :null} */}

                                    <Row className='filterswrap formcheckboxgroup'>
                                        <Col className='d-flex flex-row  align-items-start'>
                                            {/* <p className='mr-2 mb-0 font-12 font-bold mt-1'>Status:</p>
                                        <CheckboxGroup value={this.state.stage_value} onChange={this.handleStages} placeholder="Status"
                                        filterOption={(input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={[
                                            {value: '1', label: 'New Inquiry  ( '+this.state.new_inquire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )' },
                                            {value: '2',label: 'Working ( '+this.state.working.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                            {value: '8',label: 'Viewings  ( '+all_viewings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                            {value: '6',label: 'Offer ( '+all_offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                            {value: '10',label: 'No Answer ( '+this.state.no_answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                            {value: '11',label: 'Expired ( '+this.state.expired.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                            {value: '12',label: 'Pond'},
                                            {value: '24',label: 'Referral Sent'},
                                            {value: '23',label: 'Referral Received'},
                                        ].filter(item => !item.hidden)} /> */}

                                            {/* <Radio.Group   optionType="button"
                                     buttonStyle="solid"
                                      options={[
                                              {value: '30', label: 'All' },
                                                 {value: '1', label: 'New Inquiry  ( '+this.state.new_inquire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )' },
                                                {value: '2',label: 'Working ( '+this.state.working.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                                {value: '8',label: 'Viewings  ( '+this.state.viewing.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                                {value: '6',label: 'Offer ( '+this.state.offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                                {value: '10',label: 'No Answer ( '+this.state.no_answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                                {value: '11',label: 'Expired ( '+this.state.expired.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' )'},
                                                {value: '12',label: 'Pond'},
                                                // {value: '15',label: 'Whatsapp reply',hidden:role ==1?false: true},
                                                // {value: '19',label: ' Whatsapp automation' ,hidden:role ==1?false: true},
                                                // {value: '20',label: ' Whatsapp Agent' ,hidden:role ==1?false: true},
                                                {value: '21',label: 'Pledge' ,hidden:role ==1?false: true},
                                                {value: '24',label: 'Referral Sent'},
                                                {value: '23',label: 'Referral Received'},
                                                {value: '25',label: 'Verified',hidden:role ==1 || user_id==488 || user_id==497 || user_id==498?false: true},
                                                {value: '26',label: 'Verification call',hidden:role ==1 || user_id==488 || user_id==497 || user_id==498?false: true}
                                            ].filter(item => !item.hidden)} 
                                            defaultValue={this.state.stage_value} onChange={this.handleStages3}  /> */}



                                        </Col>


                                    </Row>


                                    <Modal width={600} title="Filter" open={this.state.isFilterOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
                                        <div className='row w-100 '>
                                            <div className='col-md-12 px-0'>
                                                <div className='form-group'>
                                                    <label>Title</label>

                                                    <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                                                    {this.state.titleHasError ? <div className='text-danger'>Title required</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row w-100' />


                                        <Select

                                            showSearch
                                            style={{
                                                width: 300, height: 46
                                            }}
                                            placeholder="add filter"
                                            value={this.state.addNewFilterSelect}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.filter_options}
                                            onChange={this.handleAddRow}
                                        //   onChange={add()}
                                        />
                                        {this.state.rows.map((item, idx) => (
                                            <div className='d-flex flex-row w-100' >
                                                {item.id == 1 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 px-0 mb-0'>
                                                            <label>Contact type</label>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%', minWidth: "7rem"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleContactType}
                                                                options={this.state.contact_types}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 2 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-6 d-flex flex-row mt-3 align-content-end pl-0'>
                                                        <div className='form-group w-100 px-0 mb-0'>
                                                            <label>Community</label>
                                                            <Select
                                                                style={{
                                                                    width: 300,
                                                                }}
                                                                showSearch
                                                                placeholder="Select a person"
                                                                optionFilterProp="children"
                                                                onChange={this.handleCommunity}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.communities}
                                                            />

                                                        </div>
                                                    </div>      <div className='col-5 pr-0 d-flex flex-row mt-3 align-content-end'>
                                                        <div className='form-group w-100 px-0 mb-0'>
                                                            <label>Sub community</label>
                                                            <Select
                                                                style={{
                                                                    width: 300,
                                                                }}
                                                                showSearch
                                                                placeholder="Select a person"
                                                                optionFilterProp="children"
                                                                onChange={this.handleSubCommunity}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.sub_communities}
                                                            />
                                                        </div>
                                                    </div><div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div></div> : null}
                                                {item.id == 3 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group col-6 px-0 mb-0'>
                                                            <label className='font-bold'>Price</label>
                                                            <Select
                                                                className='pricedrop'
                                                                style={{
                                                                    width: '100%', minWidth: "7rem", height: "46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handlePriceFirstValue_id}
                                                                options={this.state.price_options}
                                                            />
                                                        </div>

                                                        <div className='d-flex flex-row col-6 pr-0' style={{ alignItems: end }}>
                                                            {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                {/* <input type="text" style={{ width: '50%', height:"46px",fontSize: "14px" }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                                                                <input type="text" style={{ width: '50%', height:"46px",fontSize: "14px" }} className="form-control ml-1 " onChange={this.handlePriceMax} placeholder="max" /> */}
                                                                <CurrencyInput
                                                                    id="input-example"
                                                                    name="input-name"
                                                                    placeholder="min"
                                                                    defaultValue={this.state.price_min}
                                                                    decimalsLimit={2}
                                                                    onValueChange={this.handlePriceMin}
                                                                />
                                                                <CurrencyInput
                                                                    id="input-example"
                                                                    name="input-name"
                                                                    placeholder="max"
                                                                    defaultValue={this.state.price_max}
                                                                    decimalsLimit={2}
                                                                    onValueChange={this.handlePriceMax}
                                                                />
                                                            </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." style={{ height: "46px", fontSize: "14px" }} />}
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 4 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>

                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group col-6 px-0 mb-0'>
                                                            <label className='font-bold'>Bedrooms</label>
                                                            <Select
                                                                className='pricedrop'
                                                                style={{
                                                                    width: '100%', minWidth: "7rem", height: "46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleBedRooms_id}
                                                                options={this.state.bedroom_filter_options}
                                                            />
                                                        </div>
                                                        <div className='d-flex flex-row col-6 pr-0 align-items-end'>
                                                            <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleBedRoomsValue} placeholder="filter..." style={{ height: "46px", fontSize: "14px" }} />
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div> </div> : null}

                                                {item.id == 5 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Created</label>
                                                                <div className='d-flex flex-row' style={{ gap: "0.6rem" }}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                                                        <label className="form-check-label" for="flexRadioDefault1">
                                                                            More than
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                                                        <label className="form-check-label" for="flexRadioDefault2">
                                                                            Less than
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <input type="text" className="form-control" onChange={this.handleDateValue} />
                                                                    <select className='form-select ml-1' onChange={this.handleDateValue_id} style={{ fontSize: "14px" }}>
                                                                        <option value="1">days</option>
                                                                        <option value="2">weeks</option>
                                                                        <option value="3">months</option>
                                                                        <option value="4">years</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{ display: "flex", }} />
                                                        </button></div></div> : null}
                                                {item.id == 6 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 mb-0'>
                                                            <label>Stage</label>

                                                            <Select
                                                                mode="multiple"

                                                                style={{
                                                                    width: '100%', minWidth: "7rem", height: "46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleStages}
                                                                options={this.state.stages}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 7 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 mb-0'>
                                                            <label>Furnished / Unfurnished</label>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%', minWidth: "7rem", height: "46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleFurnishedTypes}
                                                                options={this.state.furnished_types}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 8 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 mb-0'>
                                                            <label>Agents</label>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%', minWidth: "7rem", height: "46px"
                                                                }}

                                                                placeholder="Please select"
                                                                onChange={this.handleAgent}
                                                                options={this.state.agents}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 9 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div style={{
                                                        padding: '15px',
                                                        // borderRadius: '6px',
                                                        background: '#fff',
                                                        //  boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                                                    }}>
                                                        <label>Viewings</label>

                                                        <select value={this.state.viewing_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleViewingValue_id} >
                                                            <option value="1">Equals</option>
                                                            <option value="2">Not equal</option>
                                                            <option value="3">Less than</option>
                                                            <option value="4">Greater than</option>
                                                            <option value="5">In range</option>

                                                        </select>
                                                        <div className='mt-2'>
                                                            {this.state.inrange_viewing ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text" value={this.state.viewing_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMinValue} placeholder="min value" />
                                                                <input type="text" value={this.state.viewing_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleViewingMaxValue} placeholder="max value" />
                                                            </div> : <input type="text" value={this.state.viewing_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleViewingValue} placeholder="filter..." />}
                                                        </div>

                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 10 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div style={{
                                                        padding: '15px',
                                                        // borderRadius: '6px',
                                                        background: '#fff',
                                                        // boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                                                    }}>
                                                        <label>Offers</label>

                                                        <select value={this.state.offer_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleOfferValue_id} >
                                                            <option value="1">Equals</option>
                                                            <option value="2">Not equal</option>
                                                            <option value="3">Less than</option>
                                                            <option value="4">Greater than</option>
                                                            <option value="5">In range</option>

                                                        </select>
                                                        <div className='mt-2'>
                                                            {this.state.inrange_offer ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text" value={this.state.offer_min_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMinValue} placeholder="min value" />
                                                                <input type="text" value={this.state.offer_max_value} style={{ width: '170px' }} className="form-control" onChange={this.handleOfferMaxValue} placeholder="max value" />
                                                            </div> : <input type="text" value={this.state.offer_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleOfferValue} placeholder="filter..." />}
                                                        </div>

                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 11 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                    <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                        <div className='form-group w-100 px-0 mb-0'>
                                                            <label> Type</label>
                                                            <Select
                                                                showSearch
                                                                allowClear

                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                mode="multiple"
                                                                value={this.state.property_type_filter}

                                                                placeholder="Search"
                                                                onChange={this.handlePropertyTypeFilter}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.propertytypes}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    ><CloseOutlined style={{ display: "flex", }} />
                                                    </button></div>
                                                </div> : null}

                                                {item.id == 12 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Last call</label>
                                                                <div className='mb-1'>
                                                                    <select value={this.state.last_call_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastCallOptionValue} >
                                                                        <option value="1">Equals</option>
                                                                        <option value="2">Not equal</option>
                                                                        <option value="3">Less than</option>
                                                                        <option value="4">Greater than</option>

                                                                    </select>
                                                                </div>

                                                                <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <input type="text" className="form-control" onChange={this.handleLastCallValue} />
                                                                    <select className='form-select ml-1' onChange={this.handleLastCallValue_id}>
                                                                        <option value="1">days</option>
                                                                        <option value="2">weeks</option>
                                                                        <option value="3">months</option>
                                                                        <option value="4">years</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{ display: "flex", }} />
                                                        </button></div></div> : null}

                                                {item.id == 13 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Last match</label>
                                                                <div className='mb-1'>
                                                                    <select value={this.state.last_match_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastMatchOptionValue} >
                                                                        <option value="1">Equals</option>
                                                                        <option value="2">Not equal</option>
                                                                        <option value="3">Less than</option>
                                                                        <option value="4">Greater than</option>

                                                                    </select>
                                                                </div>
                                                                <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <input type="text" className="form-control" onChange={this.handleLastMatchValue} />
                                                                    <select className='form-select ml-1' onChange={this.handleLastMatchValue_id}>
                                                                        <option value="1">days</option>
                                                                        <option value="2">weeks</option>
                                                                        <option value="3">months</option>
                                                                        <option value="4">years</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{ display: "flex", }} />
                                                        </button></div></div> : null}

                                                {item.id == 14 ?
                                                    <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                                                        <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                                                            <div className='w-100'>
                                                                <label>Move in date</label>
                                                                <div className='mb-1'>
                                                                    <select value={this.state.move_in_date_option_value} className="form-select" aria-label="Default select example" name='move_in_date' onChange={this.handleMoveInDateOptionValue} >
                                                                        <option value="1">Equals</option>
                                                                        <option value="2">Not equal</option>
                                                                        <option value="3">Greater than</option>
                                                                        <option value="4">Less than</option>
                                                                        <option value="5">In Next</option>
                                                                        <option value="6">In Previous</option>
                                                                        <option value="7">Date from - to</option>
                                                                        <option value="8">Is empty</option>

                                                                    </select>
                                                                </div>

                                                                {this.state.move_in_date_is_empty ? null : <>
                                                                    {this.state.inrange_move_in_date ? <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} /> : <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                        <input value={this.state.move_in_date_value} type="text" className="form-control" onChange={this.handleMoveInDateValue} />
                                                                        <select className='form-select ml-1' onChange={this.handleMoveInDateValue_id}>
                                                                            <option value="1">days</option>
                                                                            <option value="2">weeks</option>
                                                                            <option value="3">months</option>
                                                                            <option value="4">years</option>
                                                                        </select>
                                                                    </div>}
                                                                </>}


                                                            </div>
                                                        </div>
                                                        <div className='col-1 pr-0  d-flex align-items-end'>  <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        ><CloseOutlined style={{ display: "flex", }} />
                                                        </button></div></div> : null}

                                                {item.id == 15 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Replied to whatsapp</label>
                                                        <Switch defaultChecked={this.state.replied_to_whatsapp}
                                                            onChange={this.handleRepliedWhatsapp}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}

                                                {item.id == 16 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Clicked - Book Viewing/ More Info</label>
                                                        <Select
                                                            mode='multiple'
                                                            style={{
                                                                width: 120,
                                                            }}
                                                            onChange={this.handleClickedBookViewing}
                                                            options={[
                                                                {
                                                                    value: 'Clicked',
                                                                    label: 'Clicked',
                                                                },
                                                                {
                                                                    value: 'Book Viewing',
                                                                    label: 'Book Viewing',
                                                                }

                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}

                                                {item.id == 17 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Viewing booked Since Requested</label>
                                                        <Switch defaultChecked={this.state.viewing_booked_since_requested}
                                                            onChange={this.handleViewingBookedSinceRequested}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 18 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Viewing date</label>
                                                        <RangePicker onChange={this.handleViewingDateRange} />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}

                                                {item.id == 19 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Viewing Outcome</label>
                                                        <Select
                                                            mode='multiple'
                                                            style={{
                                                                width: 120,
                                                            }}
                                                            onChange={this.handleViewingOutcome}
                                                            options={[
                                                                {
                                                                    value: 'Not Interested',
                                                                    label: 'Not Interested',
                                                                },
                                                                {
                                                                    value: 'Considering',
                                                                    label: 'Considering',
                                                                },
                                                                {
                                                                    value: 'Offer',
                                                                    label: 'Offer',
                                                                }

                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 20 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Number confirmed on whatsapp</label>
                                                        <Switch defaultChecked={this.state.number_confirmed_on_whatsapp}
                                                            onChange={this.handleNumberConfirmedOnWhatsapp}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 21 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Offer Status</label>
                                                        <Select
                                                            mode='multiple'
                                                            style={{
                                                                width: 120,
                                                            }}
                                                            onChange={this.handleOfferStatus}
                                                            options={[
                                                                {
                                                                    value: 'Offer Sent',
                                                                    label: 'Offer Sent',
                                                                },
                                                                {
                                                                    value: 'Offer Rejected',
                                                                    label: 'Offer Rejected',
                                                                },
                                                                {
                                                                    value: 'Offer Accepted',
                                                                    label: 'Offer Accepted',
                                                                }

                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}

                                                {item.id == 22 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Deal Signed</label>
                                                        <Switch defaultChecked={this.state.deal_signed}
                                                            onChange={this.handleDealSigned}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}


                                            </div>
                                        ))}




                                    </Modal>

                                    {/* edit filter */}

                                    <Modal width={1000} title="Filter" open={this.state.isFilterEditOpen} onOk={this.handleEditOk} onCancel={this.handleEditCancel}>
                                        {this.state.filter_id}
                                        <hr />
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='form-group'>
                                                    <label>Title</label>
                                                    <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-4' />


                                        <Select

                                            showSearch
                                            style={{
                                                width: 300,
                                            }}
                                            placeholder="add filter"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={this.state.filter_options}
                                            onChange={this.handleAddRow}
                                        //   onChange={add()}
                                        />

                                        {this.state.rows.map((item, idx) => (
                                            <div >
                                                {item.id == 1 && this.state.default_contact_types_value.length > 0 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Contact type</label>
                                                        <Select
                                                            mode="multiple"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            defaultValue={this.state.default_contact_types_value}

                                                            placeholder="Please select"
                                                            onChange={this.handleContactType}
                                                            options={this.state.contact_types}
                                                        />
                                                    </div>
                                                </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 2 && this.state.community_value_id > 0 ? <div className='row'>  <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label>Community</label>
                                                        <Select
                                                            style={{
                                                                width: 300,
                                                            }}
                                                            showSearch
                                                            defaultValue={this.state.community_value_id}
                                                            placeholder="Select a person"
                                                            optionFilterProp="children"
                                                            onChange={this.handleCommunity}
                                                            // onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={this.state.communities}
                                                        />

                                                    </div>
                                                </div>      <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label>Sub community</label>

                                                            <select value={this.state.sub_community_value_id} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                                                {this.state.sub_communities.map((sub_comm) => (
                                                                    <option value={sub_comm.value}>{sub_comm.label}</option>
                                                                ))}


                                                            </select>
                                                        </div>
                                                    </div><div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div></div> : null}
                                                {item.id == 3 && this.state.price_first_value_id > 0 ? <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label>Price</label>

                                                            <select value={this.state.price_first_value_id} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                                                <option value="1">Equals</option>
                                                                <option value="2">Not equal</option>
                                                                <option value="3">Less than</option>
                                                                <option value="4">Less than or equals</option>
                                                                <option value="5">Greater than</option>
                                                                <option value="6">Greater than or equals</option>
                                                                <option value="7">In range</option>
                                                            </select>

                                                        </div>

                                                        <div className='mt-2'>
                                                            {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text" style={{ width: '150px' }} className="form-control" value={this.state.price_min} onChange={this.handlePriceMin} placeholder="min" />
                                                                <input type="text" style={{ width: '150px' }} className="form-control ml-1" value={this.state.price_max} onChange={this.handlePriceMax} placeholder="max" />
                                                            </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.price_first_value} onChange={this.handlePriceFirst} placeholder="filter..." />}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>
                                                </div> : null}
                                                {item.id == 4 && this.state.bed_rooms_value > 0 ? <div className='row'>

                                                    <div className='col-md-4'>
                                                        <label>Bedrooms</label>
                                                        <select value={this.state.bed_rooms_value_id} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleBedRooms_id} >
                                                            <option value="1">Equals</option>
                                                            <option value="2">Not equal</option>
                                                            <option value="3">Less than</option>
                                                            <option value="4">Greater than</option>

                                                        </select>
                                                        <div className='mt-2'>
                                                            <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.bed_rooms_value} onChange={this.handleBedRoomsValue} placeholder="filter..." />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'>  <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div> </div> : null}

                                                {item.id == 5 && this.state.date_value_id > 0 && this.state.date_value > 0 ?
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Created</label>


                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                                                <label className="form-check-label" for="flexRadioDefault1">
                                                                    More than
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                                                <label className="form-check-label" for="flexRadioDefault2">
                                                                    Less than
                                                                </label>
                                                            </div>

                                                            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <input type="text" className="form-control" onChange={this.handleDateValue} />
                                                                <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                                                    <option value="1">days</option>
                                                                    <option value="2">weeks</option>
                                                                    <option value="3">months</option>
                                                                    <option value="4">years</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                        >
                                                            Remove
                                                        </button></div></div> : null}
                                                {item.id == 6 && this.state.default_stage_value.length > 0 ? <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label>Stage </label>

                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                defaultValue={this.state.default_stage_value}

                                                                placeholder="Please select"
                                                                onChange={this.handleStages}
                                                                options={this.state.stages}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'> <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>

                                                </div> : null}

                                                {item.id == 7 && this.state.default_furnished_types_value.length > 0 ? <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='form-group'>
                                                            <label>Furnished / Unfurnished </label>

                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                defaultValue={this.state.default_furnished_types_value}

                                                                placeholder="Please select"
                                                                onChange={this.handleFurnishedTypes}
                                                                options={this.state.furnished_types}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4 mt-4'> <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={this.handleRemoveSpecificRow(idx, item.id)}
                                                    >
                                                        Remove
                                                    </button></div>

                                                </div> : null}


                                            </div>
                                        ))}




                                    </Modal>

                                    <Modal open={this.state.isfollowupOpen} title="Add Follow Up" onOk={this.handleFollowupOk} onCancel={this.handleFollowupCancel}>
                                        <div className="d-flex flex-column w-100 mt-4">
                                            <div className="form-group">
                                                <Radio.Group className='d-flex flex-row' onChange={this.onTypeChange} value={this.state.type} disabled>
                                                    <Radio value={1}>Vendor</Radio>
                                                    <Radio value={2}>Client</Radio>
                                                </Radio.Group>
                                            </div>
                                            {this.state.type == 1 && <div className="form-group">
                                                <label className="form-label">Search vendor</label> {/* if client switch to Search Vendor*/}
                                                <Select
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    value={this.state.vendor}
                                                    showSearch
                                                    allowClear
                                                    placeholder="Select Vendor"
                                                    maxTagCount='responsive'
                                                    optionFilterProp="children"
                                                    onChange={this.onChangeVendor}
                                                    onSearch={this.onSearchClient}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={this.state.clientList}
                                                />
                                            </div>}
                                            {this.state.type == 2 && <div className="form-group">
                                                <label className="form-label">Client:</label>
                                                <span className='form-control font-14 disabled'>{this.state.owner_name}</span>
                                            </div>}
                                            <div className="d-flex flex-row">
                                                <div class="form-group col-6 pl-0 mb-1">
                                                    <label class="form-label">Follow up for</label>
                                                    <select onChange={this.onChangeFollowupFor} value={this.state.followup_for} name="followup_for" id="followup_for" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                                        <option value="">Select Follow up For</option>
                                                        <option value={1}>Follow Up</option>
                                                        <option value={2}>Call</option>
                                                        <option value={3}>Email</option>
                                                        <option value={4}>Viewing</option>
                                                        <option value={5}>Offer</option>
                                                        <option value={6}>Whatsapp</option>
                                                    </select>
                                                    <div
                                                        className="text-danger">{this.state.validationerrors.followup_for}</div>
                                                </div>
                                                {(role_name == "Admin" || role_name == "Super Admin") && <div className="form-group col-6 pr-0 mb-1">
                                                    <label className="form-label">Agent</label>
                                                    <select onChange={this.handleAgentDetails}
                                                        value={this.state.selected_agent}
                                                        name="selected_agent" id="selected_agent"
                                                        className="form-control select2 required selectsearch select2-offscreen"
                                                        title
                                                        tabIndex={-1}>
                                                        <option value="">Select Agent</option>
                                                        {this.state.agents.map((agent) => {
                                                            return (
                                                                <option value={agent.value}>{agent.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <div
                                                        className="text-danger">{this.state.validationerrors.selected_agent}</div>
                                                </div>}
                                                {(role_name != "Admin" && role_name != "Super Admin") && <div className="form-group col-6 pr-0 mb-1">
                                                    <label className="form-label">Agent</label>
                                                    <select onChange={this.handleAgentDetails}
                                                        value={this.state.selected_agent}
                                                        name="selected_agent" id="selected_agent"
                                                        className="form-control select2 required selectsearch select2-offscreen"
                                                        title
                                                        tabIndex={-1}>
                                                        <option value="">Select Agent</option>
                                                        {this.state.agents.map((agent) => {
                                                            return (
                                                                <option value={agent.value}>{agent.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <div
                                                        className="text-danger">{this.state.validationerrors.selected_agent}</div>
                                                </div>}
                                            </div>

                                            <div className="form-group mb-0">
                                                <div className='d-flex flex-row'>
                                                    <div className='form-group col-6 pl-0 mb-0'>
                                                        <label className="form-label">Date and Time</label>
                                                        <DatePicker
                                                            format="YYYY-MM-DD HH:mm"
                                                            style={{ width: '100%', height: '36px', 'overflow': 'hidden' }}
                                                            showTime={{ minuteStep: 5 }}
                                                            onChange={this.handleFollowupDate} />
                                                        <div
                                                            className="text-danger">{this.state.validationerrors.followup_date}</div>
                                                    </div>
                                                    <div className='form-group col-6 pl-0 mb-0'>
                                                        <label className="form-label">Reminder</label>
                                                        <select onChange={this.onChangeReminder} value={this.state.reminder} name="reminder" id="reminder" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                                            <option value="">Select Reminder</option>
                                                            <option value="600">10 minutes before</option>
                                                            <option value="1800">30 minutes before</option>
                                                            <option value="3600">1 hour before</option>
                                                            <option value="86400">1 day before</option>
                                                            <option value="604800">1 week before</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Note</label>
                                                <TextArea rows={4} onChange={this.handleFollowupNote} />
                                                <div
                                                    className="text-danger">{this.state.validationerrors.followup_note}</div>
                                            </div>
                                        </div>
                                    </Modal>

                                    <Modal width={600} title=" Followup Details" open={this.state.isfollowupViewOpen}
                                        onOk={this.handleFollowupViewOk} onCancel={this.handleFollowupViewCancel}
                                    >
                                        <Divider />


                                        <div className="d-flex flex-column w-100 ">
                                            <div className="form-group">
                                                <label>Date & Time</label>
                                                <p>{this.state.followup_data.date}</p>
                                            </div>
                                            <div className="form-group">
                                                <label >Followup by</label>
                                                <p>{this.state.followup_data.agent_name}</p>
                                            </div>
                                            <div className="form-group">
                                                <label >Status</label>
                                                <p>{(this.state.followup_data.status == 0) ? "Inactive" : "Active"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label >Type</label>
                                                <p>{(this.state.followup_data.type == 1) ? "Vendor" : "Client"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label >Followup for</label>
                                                <p>{this.getFollowUpType(this.state.followup_data.followup_type)}</p>
                                            </div>
                                            <div className="form-group">
                                                <label >Followup note</label>
                                                <p>{this.state.followup_data.notes}</p>
                                            </div>
                                        </div>


                                    </Modal>
                                    <Modal width={600} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
                                        <div className="container">
                                            <div className='row w-100 d-flex flex-row'>
                                                <div className='px-0 pt-1 form-group'>
                                                    <label>Ref No</label>
                                                    <p className="form-control-disabled">{this.state.viewings.listing_refno}</p>
                                                </div>
                                                <div className='px-0 pt-1 form-group'>
                                                    <label>Agent Name</label>
                                                    <p className="form-control-disabled">{this.state.viewings.fullname}</p>
                                                </div>
                                            </div>
                                            <div className='row w-100 mt-3 d-flex flex-row'>

                                                <div className='px-0 pt-1 form-group'>
                                                    <label>Date</label>
                                                    {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p className="form-group">{this.state.viewings.view_date}</p>}

                                                </div>
                                                <div className='px-0 pt-1 mt-3 form-group'>
                                                    <label>Status</label>
                                                    <Select
                                                        style={{
                                                            width: 160,
                                                        }}
                                                        showSearch
                                                        maxTagCount={"responsive"}
                                                        placeholder="Select beds"
                                                        optionFilterProp="children"
                                                        onChange={this.handleViewStatus}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={[
                                                            { value: 'Offer', label: 'Offer' },
                                                            { value: "Successful", label: 'Successful' },
                                                            { value: "Cancelled", label: 'Cancelled' },
                                                            { value: "Unsuccessful", label: 'Unsuccessful' },
                                                            { value: "Scheduled", label: 'Scheduled' }
                                                        ]}
                                                    />
                                                </div>
                                                {this.state.showPrice ? <div className='form-group'>
                                                    <label>Price</label>
                                                    <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
                                                </div> : null}
                                            </div>
                                            <div className='row w-100 mt-3'>
                                                <div className='w-100 px-0 pt-1'>
                                                    <label>Note</label>
                                                    <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
                                                </div>


                                            </div>
                                        </div>
                                    </Modal>
                                    <Modal width={1000} title="Add ViewingsS" open={this.state.isviewingOpen}
                                        onOk={this.handleViewingOk} onCancel={this.handleViewingCancel}
                                        confirmLoading={this.state.isViewing}
                                    >
                                        <Divider />

                                        <div className='row w-100'>
                                            <div className='col-md-6 px-0 pt-1'>
                                                <div className="form-group">
                                                    <label>Ref No</label>

                                                    <Select
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        mode={"multiple"}
                                                        value={this.state.ref_def_nums}
                                                        showSearch
                                                        allowClear
                                                        placeholder="Select Refs no"
                                                        maxTagCount='responsive'
                                                        optionFilterProp="children"
                                                        onChange={this.handleRefsno}
                                                        onSearch={this.onSearchRefNo}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={this.state.ref_nums}
                                                    />
                                                    {
                                                        this.state.ref_nums == '' || this.state.ref_def_nums.length == 0 && this.state.checkErr &&
                                                        <label style={{ color: 'red' }} >empty</label>
                                                    }
                                                </div>
                                                <div className="row orgroup">
                                                    <div className="col-12 d-flex flex-row">
                                                        <hr />
                                                        <p className="mb-0">Or</p>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Unit no:</label>
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                value={this.state.viewing_unit_no}
                                                                showSearch
                                                                allowClear
                                                                placeholder="Select Unit no"
                                                                optionFilterProp="children"
                                                                onChange={this.handleUnitno}
                                                                onSearch={this.handleSearchUintNo}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.unit_nums}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Agent:</label>
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                value={this.state.viewing_agent}
                                                                showSearch
                                                                placeholder="Select Agent"
                                                                optionFilterProp="children"
                                                                onChange={this.handleViewingAgent}

                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.agents}
                                                            />
                                                            {
                                                                this.state.viewing_agent == '' && this.state.checkErr &&
                                                                <label style={{ color: 'red' }} >empty</label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Community:</label>
                                                            <Select
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                showSearch
                                                                placeholder="Search"
                                                                optionFilterProp="children"
                                                                // onChange={this.handleCommunityMatch}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.communities}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Sub Community:</label>
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                // mode={"multiple"}

                                                                showSearch
                                                                maxTagCount={"responsive"}
                                                                placeholder="Search"
                                                                optionFilterProp="children"
                                                                //onChange={this.handleSubCommunityMatch}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.sub_communities}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Property:</label>
                                                            <select name="property" id="property" className="form-control">
                                                                <option value=""></option>
                                                                <option selected="" value="386">Burj Views A</option>
                                                                <option value="237">Burj Views B</option>
                                                                <option value="283">Burj Views C</option>
                                                                <option value="274">Burj Views Podium</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Date / Time:</label>
                                                            <DatePicker
                                                                format="YYYY-MM-DD HH:mm"
                                                                style={{ width: '100%' }}
                                                                showTime
                                                                onChange={this.handleViewingDate} />
                                                            {
                                                                this.state.viewing_date == '' && this.state.checkErr &&
                                                                <label style={{ color: 'red' }} >empty</label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <table className="table">

                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Ref no</th>
                                                                    <th scope="col">Unit no</th>
                                                                    <th scope="col">Community</th>
                                                                    <th scope="col">Subcommunity</th>
                                                                    <th scope="col">Property</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.viewings_listings.map((viewing_listing) => (
                                                                    <tr>
                                                                        <th >{viewing_listing.refno}</th>
                                                                        <th >{viewing_listing.unitno}</th>
                                                                        <td>{viewing_listing.community}</td>
                                                                        <td>{viewing_listing.subcommunity}</td>
                                                                        <td>{viewing_listing.property}</td>
                                                                        <td>
                                                                            <CloseOutlined className='text-danger' onClick={this.handleRemoveList.bind(this, viewing_listing.refno)} />
                                                                        </td>
                                                                    </tr>
                                                                ))}


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Modal>

                                    <Modal width={1000} title="Add Viewings" open={this.state.isRequestviewingOpen}
                                        onOk={this.handleRequestViewingOk} onCancel={this.handleRequestViewingCancel}
                                        confirmLoading={this.state.isViewing}
                                    >
                                        <Divider />

                                        <div className='row w-100'>
                                            <div className='col-md-12 px-0 pt-1'>
                                                <div className="form-group">
                                                    <label>Ref No</label>

                                                    <Select
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        mode={"multiple"}
                                                        value={this.state.ref_def_nums}
                                                        showSearch
                                                        allowClear
                                                        disabled
                                                        placeholder="Select Refs no"
                                                        maxTagCount='responsive'
                                                        optionFilterProp="children"
                                                        onChange={this.handleRefsno}
                                                        onSearch={this.onSearchRefNo}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={this.state.ref_nums}
                                                    />
                                                    {
                                                        this.state.ref_nums == '' || this.state.ref_def_nums.length == 0 && this.state.checkErr &&
                                                        <label style={{ color: 'red' }} >empty</label>
                                                    }
                                                </div>
                                                <div className="row orgroup">
                                                    <div className="col-12 d-flex flex-row">
                                                        <hr />
                                                        <p className="mb-0">Or</p>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Unit no:</label>
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                value={this.state.viewing_unit_no}
                                                                showSearch
                                                                allowClear
                                                                disabled
                                                                placeholder="Select Unit no"
                                                                optionFilterProp="children"
                                                                onChange={this.handleUnitno}
                                                                onSearch={this.handleSearchUintNo}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.unit_nums}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Agent:</label>
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                value={this.state.viewing_agent}
                                                                showSearch
                                                                disabled
                                                                placeholder="Select Agent"
                                                                optionFilterProp="children"
                                                                onChange={this.handleViewingAgent}

                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.agents}
                                                            />
                                                            {
                                                                this.state.viewing_agent == '' && this.state.checkErr &&
                                                                <label style={{ color: 'red' }} >empty</label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Community:</label>
                                                            <Select
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                showSearch
                                                                disabled
                                                                placeholder="Search"
                                                                value={this.state.currentRequest && this.state.currentRequest.community}
                                                                optionFilterProp="children"
                                                                // onChange={this.handleCommunityMatch}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.communities}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Sub Community:</label>
                                                            <Select
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                // mode={"multiple"}

                                                                showSearch
                                                                maxTagCount={"responsive"}
                                                                disabled
                                                                placeholder="Search"
                                                                value={this.state.currentRequest && this.state.currentRequest.subcommunity}
                                                                optionFilterProp="children"
                                                                //onChange={this.handleSubCommunityMatch}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.sub_communities}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Property:</label>
                                                            <select name="property" id="property" className="form-control" disabled>
                                                                <option value=""></option>
                                                                <option selected="" value="386">Burj Views A</option>
                                                                <option value="237">Burj Views B</option>
                                                                <option value="283">Burj Views C</option>
                                                                <option value="274">Burj Views Podium</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="">Date / Time:</label>
                                                            <DatePicker
                                                                format="YYYY-MM-DD HH:mm"
                                                                style={{ width: '100%' }}
                                                                showTime
                                                                onChange={this.handleViewingDate} />
                                                            {
                                                                this.state.viewing_date == '' && this.state.checkErr &&
                                                                <label style={{ color: 'red' }} >empty</label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <table className="table">

                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Ref no</th>
                                                                    <th scope="col">Unit no</th>
                                                                    <th scope="col">Community</th>
                                                                    <th scope="col">Subcommunity</th>
                                                                    <th scope="col">Property</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.viewings_listings.map((viewing_listing) => (
                                                                    <tr>
                                                                        <th >{viewing_listing.refno}</th>
                                                                        <th >{viewing_listing.unitno}</th>
                                                                        <td>{viewing_listing.community}</td>
                                                                        <td>{viewing_listing.subcommunity}</td>
                                                                        <td>{viewing_listing.property}</td>
                                                                        <td>
                                                                            <CloseOutlined className='text-danger' onClick={this.handleRemoveList.bind(this, viewing_listing.refno)} />
                                                                        </td>
                                                                    </tr>
                                                                ))}


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Modal>


                                </Space>
                            </Space>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* ================================================================ */}
            {/* =========================== FE - END =========================== */}
            {/* ================================================================ */}

        </>)
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setNoMatch: (data) => dispatch({ type: "SET_Not_Match", payload: data }),
        removeLeadReducer: () => dispatch({ type: "REMOVE_LEAD_STATISTICS", payload: false }),
        removeStockAreaReducer: () => dispatch({ type: "REMOVE_LEADS_STOCK_AREA_REDUCER", payload: false }),
        setNavigation: (data) => dispatch({ type: "SET_NAVIGATION", payload: data })
    }
}

export default connect(null, mapDispatchToProps)(New_Leads)
