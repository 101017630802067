import React from 'react';
// import { PDFDownloadLink, PDFViewer } from 'react-pdf-html';
import API from "../../utils/API";
import { withRouter } from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {baseLpUrlImg, baseurlImg, liveUrl} from "../../components/BaseUrl";
import ReactPDFHTML from 'react-pdf-html';
import {Button} from "antd";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Html from 'react-pdf-html';
// import Page from 'react-pdf-html';
// import Document from 'react-pdf-html';
import {Document, Page} from 'react-pdf';
import Pdf from "react-to-pdf";

export default class PreviewBrochure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listing_details: [],
            property_images: [],
            agent_name: "",
            job_title: "",
            agent_mobile: "",
            agent_email: "",
            description: "",
            property: "",
            sub_community_title: "",
            community_title: "",
            unit_no: "",
            property_title: "",
            bedrooms: "",
            property_type: "",
            price: "",
            plot_area: "",
            profile_photo: "",
            meta_title: ""
        }
    }


    componentDidMount() {
        const id = this.props.match.params.id;
        API.get("/listing_details/"+id)
            .then((response)=>{
                if(response.data.success){
                    this.setState({
                        listing_details:response.data.listing_details,
                        property_images:response.data.property_images,
                        agent_name:response.data.listing_details.agent_name,
                        job_title:response.data.listing_details.job_title,
                        agent_mobile:response.data.listing_details.mobile,
                        agent_email:response.data.listing_details.email,
                        description:response.data.listing_details.description,
                        property:response.data.listing_details.property,
                        sub_community_title:response.data.listing_details.sub_community_title,
                        community_title:response.data.listing_details.community_title,
                        property_title:response.data.listing_details.property_title,
                        unit_no:response.data.listing_details.unitno,
                        bedrooms:response.data.listing_details.bedrooms,
                        property_type:response.data.listing_details.property_type,
                        price:response.data.listing_details.price,
                        plot_area:response.data.listing_details.plot_area,
                        profile_photo:response.data.listing_details.profile_photo,
                        meta_title:response.data.listing_details.meta_title,
                    })
                }
            }).catch((err) => {
            console.log(err)
            toast.error('something went wrong');
        })
    }
    downloadPdf(){
        let data = {
            html: "<p>Test paragraphfor pdf download</p>"
        };
        API.post("/download_pdf/", data)
            .then((response)=>{
                if(response.data.success){
                    console.log("resp", response)
                    const blob = new Blob([response]);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'file.pdf';
                    link.click();
                }
            }).catch((err) => {
            console.log(err)
            toast.error('something went wrong');
        })
        // const input = document.getElementById('pdf-content');
        // const pdf = new jsPDF('l', 'pt', 'a4');
        //
        // pdf.html(input, {
        //     callback: function () {
        //         pdf.save('download.pdf');
        //     },
        //     x: 10,
        //     y: 10,
        //     html2canvas: {
        //         scale: 1,
        //         windowHeight: input.scrollHeight,
        //         windowWidth: input.scrollWidth,
        //     },
        // });
        // html2canvas(document.querySelector("#pdf-content")).then(canvas => {
        //     document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //     const imgData = canvas.toDataURL('image/png');
        //     const pdf = new jsPDF();
        //     pdf.addImage(imgData, 'PNG', 0, 0);
        //     pdf.save("download.pdf");
        // });
        // const input = document.getElementById('pdf-content');
        // html2canvas(input)
        //     .then((canvas) => {
        //         const imgData = canvas.toDataURL('image/png');
        //         const pdf = new jsPDF();
        //         pdf.addImage(imgData, 'PNG', 0, 0);
        //         pdf.save('download.pdf');
        //     });
        // const doc = new jsPDF();
        //
        // doc.html("<h1>Hello world!</h1>", 10, 10);
        // doc.save("a4.pdf");

        // return (
        //     <PDFDownloadLink document={<PreviewBrochure />} fileName="mypdf.pdf">
        //         {({ blob, url, loading, error }) =>
        //             loading ? 'Loading document...' : 'Download now!'
        //         }
        //     </PDFDownloadLink>
        // );

        // const content = document.getElementById('pdf-content').innerHTML;
        // const pdf = ReactPDFHTML.generatePDF(content);
        // pdf.download('download.pdf');

    }
    render() {
        return (
            <>
                <ToastContainer />
                <Button type="primary" onClick={this.downloadPdf}>Download PDF</Button>

                <span id="pdf-content">
                    <html>
                <head>
                    <title>Brochure</title>
                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap"
                          rel="stylesheet"/>
                    <style>{'* {font-family: "Poppins", sans-serif;}'}</style>
                </head>
                <body style={{
                    maxWidth: '650px',
                    margin: '1rem auto',
                    background: '#FFF',
                    boxShadow: '0 0 7px rgba(21,21,21,0.26)'
                }}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{width: '100%', background: '#151515'}}>
                        <img
                            src="https://www.luxuryproperty.com/addons/shared_addons/themes/luxury/assets/img/lp_com_white.svg"
                            width="200px" style={{margin: '1rem'}}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: '1rem'}}>
                        {this.state.property_images && this.state.property_images[0] ?
                            <img
                                src={baseurlImg + this.state.property_images[0]['image']}
                                style={{width: '95%'}}/> :
                            <img
                                src={baseurlImg + "uploads/images/5-bedroom-villa-for-sale-parkway_vistas-LP18479-2923f586eae89000.jpg"}
                                style={{width: '95%'}}/>
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '0.6rem',
                            marginTop: '1rem',
                            width: '95%',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                {this.state.property_images && this.state.property_images[1] ?
                                    <img
                                        src={baseurlImg + this.state.property_images[1]['image']}
                                        style={{width: '100%'}}/> :
                                    <img
                                        src={baseurlImg + "uploads/images/5-bedroom-villa-for-sale-parkway_vistas-LP18479-12e8e9a5904bef00.jpg"}
                                        style={{width: '100%'}}/>
                                }
                            </div>
                            <div>
                                {this.state.property_images && this.state.property_images[2] ?
                                    <img
                                        src={baseurlImg + this.state.property_images[2]['image']}
                                        style={{width: '100%'}}/> :
                                    <img
                                        src={baseurlImg + "uploads/images/5-bedroom-villa-for-sale-parkway_vistas-LP18479-56e6a77c3e04cc0.jpg"}
                                        style={{width: '100%'}}/>
                                }
                            </div>
                            <div>
                                {this.state.property_images && this.state.property_images[3] ?
                                    <img
                                        src={baseurlImg + this.state.property_images[3]['image']}
                                        style={{width: '100%'}}/> :
                                    <img
                                        src={baseurlImg + "uploads/images/5-bedroom-villa-for-sale-parkway_vistas-LP18479-2977cee035e71e00.jpg"}
                                        style={{width: '100%'}}/>
                                }
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '1rem'}}>
                            {this.state.meta_title && <p style={{fontSize: '17px', fontFamily: 'Poppins', fontWeight: 'bold', margin: '0'}}>
                                {this.state.meta_title}
                            </p>}
                            {(this.state.price) && <>
                                <p style={{margin: '0'}}>
                                    AED {" " + this.state.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                            </>}
                            <p style={{
                                fontSize: '12px',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '0.6rem',
                                margin: '2px 0 0',
                                borderBottom: '1px solid rgba(21,21,21,0.06)',
                                width: '95%',
                                paddingBottom: '5px',
                                flexWrap: 'wrap'
                            }}>
                                <span>
                                     {(this.state.unit_no) && this.state.unit_no + " - "}
                                    {(this.state.property) && this.state.property + ", "}
                                    {(this.state.property_title) && this.state.property_title + ", "}
                                    {(this.state.sub_community_title) && this.state.sub_community_title + ", "}
                                    {(this.state.community_title) && this.state.community_title}
                                </span><span>•</span>
                                {(this.state.bedrooms) && <>
                                    <span>
                                    {this.state.bedrooms} Beds
                                </span>
                                    <span>•</span>
                                </>}
                                {(this.state.property_type) && <>
                                    <span>
                                    {this.state.property_type}
                                </span>
                                    <span>•</span>
                                </>}
                                {(this.state.plot_area) && <>
                                    <span>
                                    {"BUA: " + this.state.plot_area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Sq Ft
                                </span>
                                </>}
                            </p>

                            <p style={{fontSize: "12px", width: "95%", lineHeight: 2}}>
                                {this.state.description}
                            </p>

                        </div>
                    </div>
                    <div style={{ background: "#151515", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", padding: "1rem", width: "100%", alignItems: "center" }}>
                            <div style={{ display: "flex", borderRadius: "50%", overflow: "hidden", width: "60px", height: "60px", alignContent: "center", marginRight: "1rem" }}>
                                {(this.state.profile_photo) && <>
                                    {(this.state.profile_photo.includes("https://luxuryproperty") ? <img src={this.state.profile_photo} alt="avatar" style={{ height: "100%" }} /> : <img src={baseLpUrlImg + "/" + this.state.profile_photo} alt="avatar" style={{ height: "100%" }} />)}
                                </>}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <p style={{ color: "#FFF", fontSize: "14px", margin: "0 0 5px" }}>{this.state.agent_name}</p>
                                <p style={{ color: "#FFF", fontSize: "11px", margin: "0" }}>{this.state.job_title}</p>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "auto", textAlign: "right" }}>
                                <p style={{ color: "#FFF", fontSize: "12px", margin: "0 0 5px" }}>{this.state.agent_mobile}</p>
                                <p style={{ color: "#FFF", fontSize: "12px", margin: "0" }}>{this.state.agent_email}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </body>
                </html>
                </span>
            </>
        );
    }
}