import React, { Component } from 'react'
import { Row, Col, Table, Select, Drawer, Space, Button, Spin, Mentions, DatePicker, Upload, Modal } from 'antd'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SortableList, { SortableItem } from 'react-easy-sort'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-dropzone-uploader/dist/styles.css';
import ReactDropzoneUploader from 'react-dropzone-uploader';
import SortableContainer from '../../components/SortableContainer';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';



import 'react-tabs/style/react-tabs.css';
import {
    CheckOutlined,
    EyeOutlined,
    CloseOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    EyeFilled, UploadOutlined
} from '@ant-design/icons';
import Moment from 'react-moment';
import ApiCalendar from "react-google-calendar-api";
import "../../Pages/antdstyle.css"
import './listing.css'
import API from "../../utils/API";
import { Link } from "react-router-dom";
import EdiText from "react-editext";
import { baseurlImg, liveUrl } from "../../components/BaseUrl";
import CurrencyInput from "react-currency-input-field";
import { store } from "../../store";
import { toast, ToastContainer } from "react-toastify";
import dayjs from "dayjs";

import { arrayMoveImmutable } from 'array-move'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
const styles = {
    dropzoneActive: {
        borderColor: 'green',
    },
    submitButton: {
        display: 'none',
    },
};
let imagesArray = []
const Option = Select.Option;
const { Dragger } = Upload;

class ListingProcess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listing_process: [],
            isLoading: false,
            followup_note: "",
            followup_date: "",
            type: 1,
            followups: [],
            title_details: "",
            followups_details: [],
            websitevalidationerrors: {},
            whatsappcols: [],
            seller: [],
            buyers: [],
            description_loader: false,
            selected_facilities: [],
            whatsappClients: [],
            whatsappSelectedClients: [],
            SelectedRows: [],
            iswhatsappOpen: false,
            isviewingOpen: false,
            isfollowupOpen: false,
            iseditviewingOpen: false,
            dateRange: [],
            leads_count: "",
            ismourequestOpen: false,
            selectedFacilities: [],
            lead_gen: false,
            just_listed: false,
            curation: [],
            life_style: [],
            communities: [],
            curation_details: [],
            lifestyle_details: "",
            bedrooms: [],
            bathrooms: [],
            countries: [],
            cities: [],
            allUsers: [],
            selectedCallUsers: [],
            validationerrors: {},
            selectedNoteUsers: [],
            selectedWhatsappUsers: [],
            selectedEmailUsers: [],

            selectedViewingUsers: [],
            selectedOfferUsers: [],
            selectedFollowupUsers: [],
            purchaseprice: 0,
            servicecharge: 0,
            rentalamount: 0,
            rentalyieldtotal: 0,
            // call


            // note
            noteUsers: [],

            // whatsapp
            whatsappUsers: [],
            //email
            emailUsers: [],
            //offer
            offerUsers: [],
            // viewing
            viewingUsers: [],
            //fllowup
            followupUsers: [],
            files: [],
            sub_communities: [],
            properties: [],
            facilities: [],
            agents: [],
            propertytypes: [],
            property_images: [],
            image_files: [],
            community_match: "",
            sub_community_match: "",
            min_budget: 0,
            max_budget: 0,
            bedroom_match: "",
            property_for_match: "",
            property_type_match: "",
            added_date_match: "",
            date_morethan_option_match: true,
            date_lessthan_option_match: false,
            date_value_match: 0,
            date_value_id_match: 1,
            matchs: [],
            not_matchs: [],
            property_viewings: [],
            listing_offers: [],
            offer: {},
            showOffer: false,
            viewings: {},
            document_notes: [],
            documents: [],
            document_title: "",
            document_file: null,
            documentImageShow: "",
            property_note: "",
            value: "This is a sample text",

            mylistings: [],
            filters: [],
            rows: [],
            ref_nums: [],
            userClients: [],
            selectedClients: [],
            viewing_ref_no: "",
            viewing_agent: "",
            viewing_date: "",
            viewing_note: "",
            viewing_status: "",
            viewing_price: "",
            showPrice: false,
            showDate: false,
            listing_details: {},
            owner_id: "",
            owner_name: "",
            owner_email: "",
            owner_mobile: "",
            property_type: "",
            stage: "UC",
            avail_date: "",
            bedroom: "",
            price: "",
            property_for: "",
            community_value_id: "",
            sub_community_value_id: "",
            property_value_id: "",
            is_loading: false,
            owner_other_properties: [],
            all: [],
            notes: [],
            call_notes: [],
            whatsapp_notes: [],
            email_notes: [],
            record_id: 0,
            property_for_is: false,
            greaterthanprice: false,
            leassthanprice: false,
            inbetweenprice: false,
            lessValue: 0,
            greaterValue: 0,

            // details
            agent_name_details: "",
            property_for_details: "",
            completiondetails: "",
            style_property_details: "",
            property_type_details: 0,
            unit_no_details: 0,
            unit_no: "",
            community_details: 0,
            subcommunity_details: 0,
            bedroom_details: "",
            bathroom_details: "",
            price_details: 0,
            bua_details: "",
            rera_permit_details: "",
            view_details: "",
            tags_detail: "",
            description_details: "",
            listing_status_details: "",
            furnished_details: "",
            bua: "",
            plot_area: "",
            maids_room: "",
            vacant_details: "",
            completion_status: "Yes",
            emirate_id: "",
            form_a: "",
            title_deed: "",
            exclusive: "",
            deed_expire: "",

            // for website

            video_link: "",
            livetour_link: "",
            brochure: "",
            curations: "",
            lifestyle: "",
            meta_title: "",
            meta_description: "",
            meta_keywords: "",

            // international
            international: false,
            country: "",
            city: "",

            // filters option
            property_for_first_value: 0,
            property_for_second_value: 0,
            property_for_second_option: false,
            community_filter: "",
            agent_filter: "",
            sub_community_filter: "",
            property_filter: "",
            property_type_filter: [],
            bedroom_filter: "",

            // ref no
            refno_first_value_id: 1,
            refno_second_value_id: 1,
            refno_active_second_option: false,
            refno_and_or_option: true,
            refno_first_value: "",
            refno_second_value: "",

            // property for
            sale: true,
            rent: true,
            tenant: true,
            short_term: true,

            // property type

            residential: true,
            commercial: true,
            // unit no
            unitno_first_value_id: 1,
            unitno_second_value_id: 1,
            unitno_active_second_option: false,
            unitno_and_or_option: true,
            unitno_first_value: "",
            unitno_second_value: "",
            role: null,
            role_name: "",

            // price

            price_first_value_id: 1,
            price_second_value_id: 1,
            price_active_second_option: false,
            price_and_or_option: true,
            price_first_value: "",
            price_second_value: "",
            inrange: false,
            price_min: 0,
            price_max: 0,

            leads_count_first_value_id: 1,
            leads_count_second_value_id: 1,
            leads_count_active_second_option: false,
            leads_count_and_or_option: true,
            leads_count_first_value: "",
            leads_count_second_value: "",
            leads_count_inrange: false,
            leads_count_min: 0,
            leads_count_price_max: 0,

            viewings_count_first_value_id: 1,
            viewings_count_second_value_id: 1,
            viewings_count_active_second_option: false,
            viewings_count_and_or_option: true,
            viewings_count_first_value: "",
            viewings_count_second_value: "",
            viewings_count_inrange: false,
            viewings_count_min: 0,
            viewings_count_price_max: 0,

            offers_count_first_value_id: 1,
            offers_count_second_value_id: 1,
            offers_count_active_second_option: false,
            offers_count_and_or_option: true,
            offers_count_first_value: "",
            offers_count_second_value: "",
            offers_count_inrange: false,
            offers_count_min: 0,
            offers_count_price_max: 0,

            // date

            date_first_value_id: 1,
            date_second_value_id: 1,
            date_active_second_option: false,
            date_and_or_option: true,
            date_first_value: "",
            date_second_value: "",
            date_morethan_option: true,
            date_lessthan_option: false,
            date_value: 0,
            date_value_id: 1,
            status_filter: [],

            // add new filter
            isFilterOpen: false,
            isFilterEditOpen: false,
            filter_title: "",
            open: false,
            openToEdit: false,
            openToAmenities: false,

            property_type_option: [
                {
                    value: 'Residential',
                    label: 'Residential'
                },
                {
                    value: 'Commercial',
                    label: 'Commercial'
                }
            ],
            property_for_option: [
                {
                    value: 'sale',
                    label: 'Sales'
                },
                {
                    value: 'rent',
                    label: 'Rent'
                },
                {
                    value: 'tenanted',
                    label: 'Tenanted'
                },
                {
                    value: 'short-term',
                    label: 'Short-term'
                }
            ],
            propert_type_value: [],
            propert_for_value: [],


            filter_options: [


                {
                    value: '1',
                    label: 'Ref no',
                },

                {
                    value: '2',
                    label: 'Property for',
                },
                {
                    value: '3',
                    label: 'Property type',
                },
                {
                    value: '4',
                    label: 'Unit no',
                },
                {
                    value: '5',
                    label: 'Created',
                },
                {
                    value: '6',
                    label: 'Price',
                }


            ],

            // add notes
            call_log: "",
            note_log: "",
            whatsapp_log: "",
            email_log: "",
            viewing_log: "",
            offer_log: "",
            followup_log: "",

            // Loaders
            call_loader: false,
            note_loader: false,
            whatsapp_loader: false,
            email_loader: false,
            offer_loader: false,
            viewing_loader: false,
            followUp_loader: false,

            // Farooq
            // IDs
            nextId: '',
            prevId: '',
            currId: '',

            // states for disabled
            disabledNext: false,
            disabledPrev: false,

            currUrl: "",
            loader: false
        }
    }


    handleOpenDrawerToEdit = (id, xPrev_Next) => {
        this.emptyState();
        if (xPrev_Next) {
            this.setState({
                loader: true
            });
        }

        this.setState({ matchs: [], not_matchs: [] })

        // uncomment this
        // const nextId = this.findNextOrder(id);
        // const prevId = this.findPrevOrder(id);
        // uncomment this

        // console.log("**Now Id***", id);
        // console.log("**Prev Id***", prevId);
        // console.log("**Next Id***", nextId);
        // uncomment this
        //         this.setState({
        //             // record_id: xPrev_Next ? this.state.record_id : id,
        //             nextId: nextId == undefined ? this.state.nextId : nextId.id,
        //             prevId: prevId == undefined ? this.state.prevId : prevId.id,
        //             currId: id
        //         })
        // uncomment this
        ///////////////////

        this.setState({ record_id: id })

        API.get("/listing_details/" + id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        listing_details: response.data.listing_details, property_images: response.data.images, notes: response.data.notes,
                        call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                        email_notes: response.data.email_notes, agent_name_details: response.data.listing_details.agent,
                        selected_portals_id: response.data.selected_portals_id,
                        selected_portals: response.data.selected_portals,
                        lifestyle_details: response.data.life_style_detail,
                        curation_details: response.data.curation_details,
                        selected_facilities: response.data.selected_amenities,
                        avail_date: response.data.listing_details.avail_date,
                        title_details: response.data.listing_details.title,
                        isSameValueCheck: this.isCheckSameValue(response.data.selected_portals),
                        agent_name_details: response.data.listing_details.agent_id, property_for_details: response.data.listing_details.property_for,
                        international: response.data.listing_details.international, country: (response.data.listing_details.country) ? response.data.listing_details.country : 198,
                        city: (response.data.listing_details.emirate) ? response.data.listing_details.emirate : 1,
                        property_type_details: response.data.listing_details.property_category_id, completion_status: (response.data.listing_details.completed == null) ? 'Yes' : response.data.listing_details.completed,
                        style_property_details: response.data.listing_details.style_property_details,
                        video_link: response.data.listing_details.video_link, livetour_link: response.data.listing_details.livetour_link,
                        meta_title: response.data.listing_details.meta_title,
                        meta_description: response.data.listing_details.meta_desc,
                        meta_keywords: response.data.listing_details.meta_keywords,
                        unit_no_details: response.data.listing_details.unitno, lead_gen: response.data.listing_details.leadgen == "Yes" ? true : false,
                        just_listed: response.data.listing_details.just_listed == 1 ? true : false,
                        rera_permit_details: response.data.listing_details.rera_permit,
                        bua: response.data.listing_details.bua,
                        plot_area: response.data.listing_details.plot_area,
                        community_details: response.data.listing_details.development, subcommunity_details: response.data.listing_details.project,
                        property_details: response.data.listing_details.property, bedroom_details: response.data.listing_details.bedrooms, bathroom_details: response.data.listing_details.bathrooms,
                        price_details: response.data.listing_details.price, bua_details: response.data.listing_details.bua, rera_permit_details: response.data.listing_details.rera_permit, view_details: response.data.listing_details.view,
                        vacant_details: response.data.listing_details.vacant_details,
                        upgraded_details: response.data.listing_details.upgraded_details,
                        furnished_details: response.data.listing_details.furnished,
                        maids_room: response.data.listing_details.maids_room,
                        // tags_detail:this.state.tags_detail,description_details:this.state.description_details,
                        listing_status_details: response.data.listing_details.status,
                        emirate_id: response.data.listing_details.emirate_id, form_a: response.data.listing_details.form_a,
                        title_deed: response.data.listing_details.title_deed, exclusive: response.data.listing_details.exclusive,
                        deed_expire: response.data.listing_details.deed_expire, owner_other_properties: response.data.owner_other_properties,
                        document_notes: response.data.document_notes, documents: response.data.documents,
                        description_details: response.data.listing_details.description,
                        owner_id: response.data.listing_details.owners_id,
                        owner_name: response.data.listing_details.owner,
                        owner_email: response.data.listing_details.owner_email,
                        owner_mobile: response.data.listing_details.owner_mobile,
                        // Add By Farooq
                        all: response.data.all,
                        // Farooq
                        loader: false
                    })

                    // this.setState({openToEdit:true})
                    this.fetchFollowUpDetails(this.state.record_id)
                    this.fetchViewings(id)
                    this.fetchOffers(id);
                    this.setState({
                        role: response.data.role,
                        role_name: response.data.role_name
                    })

                    // By Farooq
                    if (!xPrev_Next) {
                        this.setState({
                            openToEdit: true,
                            disabledNext: false,
                            disabledPrev: false
                        })
                    }

                    ///// chnages By Farooq ////
                    this.setState({ currUrl: window.location.href })
                    window.history.replaceState(null, "listing_process Id", window.location.href + '/' + id)
                    /////////////////////////


                    API.get("/all_agents")
                        .then((response) => {
                            if (response.data.success) {
                                this.setState({ agents: response.data.agents })
                            }
                        })

                    API.get("/portals")
                        .then((response) => {
                            if (response.data.success) {
                                this.setState({ portals: response.data.portals })
                            }
                        })

                    API.get("/facilities")
                        .then((response) => {
                            if (response.data.success) {
                                this.setState({ facilities: response.data.facilities })
                            }
                        })

                    API.get("/all_sub_community")
                        .then((response) => {

                            if (response.data.success) {
                                this.setState({ sub_communities: response.data.sub_communities })
                            }
                        })

                    API.get("/all_properties")
                        .then((response) => {

                            if (response.data.success) {
                                this.setState({ properties: response.data.properties })
                            }
                        })

                    API.get("/all_client_users")
                        .then((response) => {
                            // console.log(response.data.users)


                            if (response.data.success) {
                                this.setState({ allUsers: response.data.users })
                            }
                        })
                }
                // Farooq
                else {
                    this.setState({
                        loader: false
                    })
                }
            })
    }

    fetchViewings(listing_id) {


        API.get("/listing_viewings/" + listing_id)
            .then((response) => {



                if (response.data.success) {
                    this.setState({ property_viewings: response.data.property_viewings })
                }
            })
    }

    fetchOffers(listing_id) {
        API.get("/listing_offers/" + listing_id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ listing_offers: response.data.listing_offers })
                }

            })
    }

    fetchFollowUpDetails(leads_id) {
        API.post("/followup_details/" + leads_id, { type: "vendor" })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ followups_details: response.data.followups })
                }

            })
    }
    componentDidMount() {
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')

        this.fetchActionData();

        API.get("/propertytypes")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ property_categories: response.data.propertytypes, propertytypes: response.data.propertytypes })
                }
            })

        API.get("/get_curation")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ curation: response.data.curation })
                }
            })

        API.get("/get_lifestyle")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ life_style: response.data.life_style })
                }
            })

        API.get("/community")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ communities: response.data.communities })
                }
            })

        API.get("/bedrooms")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ bedrooms: response.data.bedrooms })
                }
            })

        API.get("/bathrooms")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ bathrooms: response.data.bathroom })
                }
            })

        API.get("/countries")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ countries: response.data.countries })
                }
            })

        API.get("/all_cities")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ cities: response.data.cities })
                }
            })
    }

    onChangeTitle(selected_portal, e) {
        this.state.selected_portals.filter((portal) => {
            if (portal.portals_id === selected_portal.portals_id) {
                this.setState({ selected_portal: portal })
                portal.property_title = e.target.value;
            }
        });
    }

    onChangeDescription(selected_portal, e) {
        this.state.selected_portals.filter((portal) => {
            if (portal.portals_id === selected_portal.portals_id) {
                this.setState({ selected_portal: portal })
                portal.property_desc = e.target.value;
            }
        });
    }

    onChangeSameValue(selected_portal, e) {
        if (selected_portal.length != 0) {
            if (e.target.checked) {
                this.setState({ isSameValueCheck: true })
            } else {
                this.setState({ isSameValueCheck: false })
                selected_portal.forEach((portal, index) => {
                    if (index >= 1) {
                        portal['property_desc'] = ""
                        portal['property_title'] = ""
                        this.setState({ selected_portal: portal })
                    }
                });
            }
        }
    }

    onChangePortal(portal, e) {
        let portalExist = this.state.selected_portals.map((item) => {
            return (portal.id == item.portals_id) ? true : false
        });

        if (portalExist.includes(true)) {
            let new_portals = this.state.selected_portals.filter((item) => {
                return item.portals_id !== portal.id
            })
            this.setState({ selected_portals: new_portals })
        } else {
            this.state.selected_portals.push(
                {
                    portals_id: portal.id,
                    property_desc: "",
                    property_title: "",
                    portal_title: portal.title
                }
            );
            this.setState({ selected_portals: this.state.selected_portals })
        }
    }

    websiteValidate() {
        let meta_title = this.state.meta_title;
        let meta_description = this.state.meta_description;
        let meta_keywords = this.state.meta_keywords;
        let validationerrors = {};
        let isValid = true;
        if (meta_title == null || meta_title == "" || meta_title == 0) {
            isValid = false;
            validationerrors["meta_title"] = "Meta Title is required.";
        }
        if (meta_description == null || meta_description == "" || meta_description == 0) {
            isValid = false;
            validationerrors["meta_description"] = "Meta Description is required.";
        }
        if (meta_keywords == null || meta_keywords == "" || meta_keywords == 0) {
            isValid = false;
            validationerrors["meta_keywords"] = "Meta Keywords is required.";
        }
        this.setState({
            websitevalidationerrors: validationerrors
        });
        return isValid;
    }

    isCheckSameValue(portals, e) {
        if (portals && portals.length != 0) {
            let sameData = 0;
            portals.map((portal) => {
                if ((portal.property_title == portals[0].property_title) && (portal.property_desc == portals[0].property_desc)) {
                    sameData++
                }
            })
            if (sameData == portals.length) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    handleMinBudget = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ min_budget: new_value })
    }

    handleMaxBudget = (e) => {
        const value = e.target.value
        const new_value = value.replace(/,/g, "")
        this.setState({ max_budget: new_value })
    }

    handleBeds = (e) => {
        this.setState({ bedroom_match: e.target.value })
    }

    handleCommunityMatch = (e) => {
        this.setState({ community_match: e })
        API.get("/sub_community/" + e)
            .then((response) => {
                if (response.data.success)
                    this.setState({ sub_communities: response.data.sub_communities })
            })
    }

    onSaveInternational = () => {
        const listing_id = this.state.record_id
        const data = {
            international: this.state.international,
            country: this.state.country,
            city: this.state.city,
        }

        API.put("/update_international/" + listing_id, data)
            .then((response) => {
                if (response.data.success) {
                    toast.success("International section has been sucessfully added")
                }
            })
    }

    handleSubCommunityMatch = (e) => {
        this.setState({ sub_community_match: e })

    }


    handleSubCommunity = (value) => {
        this.setState({ sub_community_value_id: value })
        API.get("/property/" + value)
            .then((response) => {
                if (response.data.success)
                    if (response.data.success)
                        this.setState({ properties: response.data.properties })
            })
    }

    handleProperty = (value) => {
        this.setState({ property_value_id: value })
    }

    handleOwnerName = (e) => {
        this.setState({ owner_name: e.target.value })
    }

    handleUnitNo = (e) => {
        this.setState({ unit_no: e.target.value })
    }

    handlePrice = (e) => {
        this.setState({ price: e.target.value })
    }

    handleOwnerPhone = (e) => {
        this.setState({ owner_mobile: e.target.value })
    }

    handleOwnerEmail = (e) => {
        this.setState({ owner_email: e.target.value })
    }

    handlePropertyTypeValue = (e) => {
        this.setState({ property_type: e })
    }

    handleStage = (e) => {
        this.setState({ stage: e.target.value })
    }

    handleBedroom = (e) => {
        this.setState({ bedroom: e.target.value })
    }

    handlePropertForMatch = (e) => {
        this.setState({ property_for_match: e.target.value })
    }

    handlePropertTypeMatch = (e) => {
        this.setState({ property_type_match: e.target.value })
    }

    handleAddedDateMatch = (e) => {
        this.setState({ added_date_match: e.target.value })
    }

    handlePreview = (file) => {
        window.open(file.thumbUrl, '_blank');
    }

    handleAvailableDate = (date, dateString) => {
        this.setState({ avail_date: dateString })
    }

    handleAgentDetails = (e) => {

        this.setState({ agent_name_details: e.target.value })
    }

    updateImages = (images) => {
        this.setState({ property_images: images })
    }

    onLeadGen = (e) => {
        this.setState({ lead_gen: e.target.checked })
    }

    getCurrentTime = (date) => {
        if (date != null && date != "" && date != undefined) {
            let newDate = new Date(date);
            newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
            return newDate.toISOString();
        }
    }

    onJustListed = (e) => {
        this.setState({ just_listed: e.target.checked })
    }

    onChangeInternational = (e) => {
        this.setState({ international: e.target.value })
    }

    onChangeCountry = (e) => {
        this.setState({ country: e.target.value })
    }

    onChangeCity = (e) => {
        this.setState({ city: e.target.value })
    }
    handlePropertyForDetails = (e) => {
        this.setState({ property_for_details: e.target.value })
    }
    getSubCommunity = (id) => {
        API.get("/sub_community/" + id)
            .then((response) => {
                if (response.data.success)
                    this.setState({ sub_communities: response.data.sub_communities })
            })
    }
    handleCompletionDetails = (e) => {

        this.setState({ completion_status: e.target.value })
    }
    handlePropertyTypeDetails = (e) => {
        this.setState({ property_type_details: e.target.value })
    }
    handlePropertyStyleDetails = (e) => {
        this.setState({ style_property_details: e.target.value })
    }
    handleUnitNoDetails = (e) => {
        this.setState({ unit_no_details: e.target.value })
    }
    handleCommunityDetails = (e) => {
        this.setState({ community_details: e.target.value })
        this.getSubCommunity(e.target.value);
    }

    handleCurationDetails = (value) => {
        this.setState({ curation_details: value })
    }

    handleLifeStyleDetails = (e) => {
        this.setState({ lifestyle_details: e.target.value })
    }

    getProperty = (id) => {
        API.get("/property/" + id)
            .then((response) => {
                if (response.data.success)
                    this.setState({ properties: response.data.properties })
            })
    }
    handleSubCommunityDetails = (e) => {
        this.setState({ subcommunity_details: e.target.value })
        this.getProperty(e.target.value)
    }
    handlePropertyDetails = (e) => {
        this.setState({ property_details: e.target.value })
    }
    handleBedDetails = (e) => {
        this.setState({ bedroom_details: e.target.value })
    }
    handleaBathroomDetails = (e) => {
        this.setState({ bathroom_details: e.target.value })
    }
    handlePriceDetails = (e) => {
        this.setState({ price_details: e.target.value })
    }
    handleBuaDetails = (e) => {
        this.setState({ bua_details: e.target.value })
    }

    handleReraDetails = (e) => {
        this.setState({ rera_permit_details: e.target.value })
    }
    handleViewDetails = (e) => {
        this.setState({ view_details: e.target.value })
    }
    handleVacantDetails = (e) => {
        this.setState({ vacant_details: e.target.value })
    }
    handleBua = (e) => {
        this.setState({ bua: e.target.value })
    }
    handlePlotArea = (e) => {
        this.setState({ plot_area: e.target.value })
    }
    handleReraPermit = (e) => {
        this.setState({ rera_permit_details: e.target.value })
    }
    handleUpgradedDetails = (e) => {
        this.setState({ upgraded_details: e.target.value })
    }
    handleFurnishedDetails = (e) => {
        this.setState({ furnished_details: e.target.value })
    }
    handleMaidsDetails = (e) => {
        this.setState({ maids_room: e.target.value })
    }
    handleTagsDetails = (e) => {
        this.setState({ tags_detail: e.target.value })
    }
    handleTitleDetails = (e) => {
        this.setState({ title_details: e.target.value })
    }
    handleListingStatusDetails = (e) => {
        this.setState({ listing_status_details: e.target.value })
    }

    onChangeCallNote = (value) => {
        this.setState({ call_log: value })
    }

    onSelectCallMentions = (option) => {
        this.setState({ selectedCallUsers: [...this.state.selectedCallUsers, option] })

    }

    onChangeNoteNote = (value) => {
        this.setState({ note_log: value })
    }

    onSelectNoteMentions = (option) => {
        this.setState({ selectedNoteUsers: [...this.state.selectedNoteUsers, option] })

    }

    onChangeWhatsappNote = (value) => {
        this.setState({ whatsapp_log: value })
    }

    onSelectWhatsappMentions = (option) => {
        this.setState({ selectedWhatsappUsers: [...this.state.selectedWhatsappUsers, option] })

    }

    onChangeEmailNote = (value) => {
        this.setState({ email_log: value })
    }

    onSelectEmailMentions = (option) => {
        this.setState({ selectedEmailUsers: [...this.state.selectedEmailUsers, option] })

    }

    onChangeViewingNote = (value) => {
        this.setState({ viewing_log: value })
    }

    onSelectViewingMentions = (option) => {
        this.setState({ selectedViewingUsers: [...this.state.selectedEmailUsers, option] })

    }
    onChangeOfferNote = (value) => {
        this.setState({ offer_log: value })
    }

    onSelectOfferMentions = (option) => {
        this.setState({ selectedOfferUsers: [...this.state.selectedOfferUsers, option] })

    }
    onChangeFollowupNote = (value) => {
        this.setState({ followup_log: value })
    }

    onSelectFollowupMentions = (option) => {
        this.setState({ selectedFollowupUsers: [...this.state.selectedFollowupUsers, option] })

    }

    handleFormA = (e) => {
        this.setState({ form_a: e.target.value })
    }

    handleEmirateID = (e) => {
        this.setState({ emirate_id: e.target.value })
    }

    handleExclusive = (e) => {
        this.setState({ exclusive: e.target.value })
    }
    handleTitleDeed = (e) => {
        this.setState({ title_deed: e.target.value })
    }
    handleDeedExpire = (e) => {
        this.setState({ deed_expire: e.target.value })
    }

    handlepurchaseprice = (e) => {
        this.setState({ purchaseprice: e.target.value })
    }

    handleservicecharge = (e) => {
        this.setState({ servicecharge: e.target.value })
    }
    handlerentalamount = (e) => {
        this.setState({ rentalamount: e.target.value })
    }

    handleNewFollowUp = () => {
        this.setState({ isfollowupOpen: true })
    }

    handleViewFollowup = (reminder_id, e) => {
        API.get("/followup_by_reminder_id/" + reminder_id)
            .then((response) => {
                if (response.data.success) {

                    this.setState({ isfollowupViewOpen: true, followup_date: response.data.followup.remind_date, followup_note: response.data.followup.remind_note })

                }
            })

    }

    handleEditViewing(view_id, e) {

        this.setState({ view_id: view_id })
        API.get("/get_viewing/" + view_id)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        viewings: response.data.viewings, viewing_status: response.data.viewings.status,
                        viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
                        showPrice: response.data.viewings.status == "Offer" ? true : false,
                        showDate: response.data.viewings.status == "Scheduled" ? true : false,
                        viewing_price: response.data.viewings.amount, iseditviewingOpen: true
                    })
                }

            })
    }

    handleNewViewing = () => {
        this.setState({ isviewingOpen: true })
    }
    handleNewFollowUp = () => {
        this.setState({ isfollowupOpen: true })
    }
    handleShowOffer = (view_id, e) => {

        API.get("/get_offer/" + view_id)
            .then((response) => {

                if (response.data.success) {
                    this.setState({ offer: response.data.offer })
                    this.setState({ showOffer: true })
                }
            })

    }

    handleAllNotMatch = () => {

        const data = {
            listing_id: this.state.record_id,
            community_match: this.state.community_match,
            sub_community_match: this.state.sub_community_match,
            min_budget: this.state.min_budget, max_budget: this.state.max_budget,
            beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
            property_type_match: this.state.property_type_match,
            added_date_match: this.state.added_date_match
        }
        this.props.setNoMatch(data)
        window.open("/restricted_leads", "_blank");

        //this.props.history.push("/restricted_leads")
    }

    handleAllMatch = () => {
        const data = {
            listing_id: this.state.record_id,
            community_match: this.state.community_match,
            sub_community_match: this.state.sub_community_match,
            min_budget: this.state.min_budget, max_budget: this.state.max_budget,
            beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
            property_type_match: this.state.property_type_match,
            added_date_match: this.state.added_date_match
        }
        this.props.setMatch(data)
        window.open("/access_leads", "_blank");
        //this.props.history.push("/access_leads")
    }


    handleMatch = () => {
        const data = {
            status_filter: this.state.status_filter,
            // listing_id:this.state.record_id,
            community_match: this.state.community_match,
            sub_community_match: this.state.sub_community_match,
            min_budget: this.state.min_budget, max_budget: this.state.max_budget,
            beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
            property_type_match: this.state.property_type_match,
            added_date_match: this.state.added_date_match
        }

        API.post("/match_listings", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ matchs: response.data.match_listings, not_matchs: response.data.not_match_listings })
                }
            })
    }

    arrayMove = (array, sourceIndex, destinationIndex) => {
        const clonedArray = [...array]; // Clone the original array

        const [removedElement] = clonedArray.splice(sourceIndex, 1); // Remove the element at the source index

        clonedArray.splice(destinationIndex, 0, removedElement); // Insert the removed element at the destination index

        return clonedArray; // Return the modified array
    }

    onSortEnd = (position) => {
        let oldIndex = position.source.index;
        let newIndex = position.destination.index;
        let new_position = this.arrayMove(this.state.property_images, oldIndex, newIndex);
        this.setState({ property_images: new_position })
        const data = {
            old_sorting: oldIndex,
            new_sorting: newIndex,
            property_images: this.arrayMove(this.state.property_images, oldIndex, newIndex)
        }
        API.post("/sort_listing_images", data)
            .then((response) => {
                console.log("test", response)
            })
            .catch((e) => {
                console.log("error", e)
            })
    }
    handleUploadImages = (fileWithMeta) => {
        return;
        for (let i = 0; i < fileWithMeta.length; i++) {
            const formData = new FormData();
            formData.append("listing_id", this.state.record_id);
            formData.append("files[]", fileWithMeta[i].file);
            API.post("/upload_listing_files", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({ property_images: response.data.images })
                    fileWithMeta[i].remove();
                    imagesArray = []
                }
            })
        }
    }

    handleDocumentsSubmit = () => {
        const data = {
            listing_id: this.state.record_id, listing_refno: this.state.listing_details.refno,
            document_file: this.state.document_file, document_title: this.state.document_title

        }
        API.post("/add_documents", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ documents: response.data.documents, document_title: "" })
                    toast.success("Document has been sucessfully added")
                }
            })
    }

    handleDocumentTitle = (e) => {
        this.setState({ document_title: e.target.value })
    }

    handleDocumentFile = (e) => {
        let files = e.target.files || e.dataTransfer.files;

        if (!files.length) return;
        this.createImage(files[0]);
        this.setState({ documentImageShow: URL.createObjectURL(e.target.files[0]) });
        console.log("debug", URL.createObjectURL(e.target.files[0]))
    };

    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                document_file: e.target.result,
            });
        };
        reader.readAsDataURL(file);

    }

    handlePropertyNotes = (e) => {
        this.setState({ property_note: e.target.value })
    }
    handlePropertyNotesSubmit = () => {
        const data = {
            listing_id: this.state.record_id,
            property_note: this.state.property_note
        }
        API.post("/add_document_notes", data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ document_notes: response.data.document_notes, property_note: "" })
                    toast.success("Document note has been successfully added")
                }
            })
    }

    handleOpenAmenities = () => {
        this.setState({ openToAmenities: true })
    }

    handleSaveAmenities = () => {
        const listing_id = this.state.record_id
        const data = {
            "selected_facilities": this.state.selected_facilities,
            "id": listing_id
        }
        API.put("/update_amenities/", data)
            .then((response) => {
                if (response.data.success) {
                    toast.success("Amenities are successfully updated")
                    this.setState({ openToAmenities: false })
                } else {
                    toast.success("Something went wrong")
                }
            })
    }

    handleCancelAmenities = () => {
        this.setState({ openToAmenities: false })
    }

    onChangeFacilities = (facility, e) => {
        let selected_amenities = this.state.selected_facilities;
        let element_exist = selected_amenities.includes(facility.value);
        if (element_exist) {
            var index = selected_amenities.indexOf(facility.value);
            if (index !== -1) {
                selected_amenities.splice(index, 1);
            }
        } else {
            selected_amenities.push(facility.value);
        }
        this.setState({ selected_facilities: selected_amenities });
    }

    handleDocumentDelete = (document) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    added_by: document.added_by,
                    added_date: document.added_date,
                    docs_id: document.docs_id,
                    document: document.document,
                    title: document.title,
                    listing_id: this.state.record_id
                }
                API.post("/delete_document", data)
                    .then((response) => {
                        if (response.data.success) {
                            toast.success("Document Successfully deleted")
                            this.setState({ documents: response.data.documents })
                        } else {
                            toast.error('Something went wrong');
                        }
                    }).catch((error) => {
                        console.log(error);
                        toast.error('something went wrong');
                    });
            }
        })
    }

    generateDescription = (e) => {
        this.setState({ description_loader: true })
        const description_data = {
            community_details: this.state.community_details,
            subcommunity_details: this.state.subcommunity_details,
            furnished_details: this.state.furnished_details,
            maids_room: this.state.maids_room,
            bedroom_details: this.state.bedroom_details,
            price_details: this.state.price_details,
            bua: this.state.bua,
            plot_area: this.state.plot_area,
            view_details: this.state.view_details,
            title_details: this.state.title_details,
            style_property_details: this.state.style_property_details,
            listing_refno: this.state.listing_details.refno,
            property_type_details: this.state.property_type_details,
            vacant_details: this.state.vacant_details,
            upgraded_details: this.state.upgraded_details,
            property_for_details: this.state.property_for_details
        }
        API.post("/generate_description", description_data)
            .then((response) => {
                this.setState({ description_loader: false })
                if (response.data.success) {
                    this.setState({
                        description_details: response.data.description
                    })
                } else {
                    this.setState({ description_loader: false })
                    toast.error('Something went wrong');
                }
            }).catch((err) => {
                this.setState({ description_loader: false })
                console.log(err)
                toast.error('Something went wrong');
            }).finally(() => {
                this.setState({ description_loader: false })
            })
    }


    handleSetailsSubmit = () => {
        const listing_id = this.state.record_id
        const data = {
            agent_name_details: this.state.agent_name_details, property_for_details: this.state.property_for_details,
            completiondetails: this.state.completiondetails, property_type_details: this.state.property_type_details,
            style_property_details: this.state.style_property_details, unit_no_details: this.state.unit_no_details,
            community_details: this.state.community_details, subcommunity_details: this.state.subcommunity_details, completion_status: this.state.completion_status,
            property_details: this.state.property_details, bedroom_details: this.state.bedroom_details, bathroom_details: this.state.bathroom_details,
            avail_date: this.state.avail_date,
            title_details: this.state.title_details,
            price_details: this.state.price_details, rera_permit_details: this.state.rera_permit_details,
            plot_area: this.state.plot_area,
            bua: this.state.bua, view_details: this.state.view_details, vacant_details: this.state.vacant_details, upgraded_details: this.state.upgraded_details,
            furnished_details: this.state.furnished_details, maids_room: this.state.maids_room, tags_detail: this.state.tags_detail, description_details: this.state.description_details,
            listing_status_details: this.state.listing_status_details, leadgen: this.state.lead_gen == true ? "Yes" : "No", just_listed: this.state.just_listed == true ? 1 : 0
        }
        API.put("/update_listing/" + listing_id, data)
            .then((response) => {
                this.handleCloseEditDrawer();
                if (this.state.listing_details.status == "U") {
                    this.fetchActionData();
                } else if (this.state.listing_details.status == "SEO") {
                    this.fetchSeoData();
                } else if (this.state.listing_details.status == "P") {
                    this.fetchReraPermitData();
                } else if (this.state.listing_details.status == "PA") {
                    this.fetchPublishData();
                } else {
                    this.fetchActionData();
                }
            })
    }

    handleFollowupDate = (data, dateString) => {
        this.setState({ followup_date: dateString })
    }

    getUploadParams = ({ meta }) => {
        const url = 'https://httpbin.org/post';
        const fileUrl = `${url}/${encodeURIComponent(meta.name)}`;
        return { url, meta: { fileUrl } };
    };
    handleChangeStatus = ({ meta, file, remove }, status) => {
        if (status == 'done') {
            const formData = new FormData();
            formData.append("listing_id", this.state.record_id);
            formData.append("files[]", file);
            API.post("/upload_listing_files", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({ property_images: response.data.images })
                    remove();
                    imagesArray = []
                }
            })
        }
    };

    handleFollowupNote = (e) => {
        this.setState({ followup_note: e.target.value })
    }

    handleFollowupSubmit = () => {
        if (this.state.followup_log == '') {
            toast.warning('Followup cannot be empty')
        }
        else {
            this.setState({ followUp_loader: true })
            const data = {
                listing_id: this.state.record_id,
                note: this.state.followup_log,
                note_type: "followup",
                users: this.state.selectedFollowupUsers
            }

            API.post("/add_note", data)
                .then((response) => {
                    console.log("res", response.data)
                    if (response.data.success) {
                        this.setState({
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            selectedFollowupUsers: [],
                            followup_log: '',
                            followUp_loader: false,
                            all: response.data.all
                        })
                    } else {
                        this.setState({ followUp_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ followUp_loader: false });
                    toast.error('something went wrong');
                })
        }
    }

    handleViewingSubmit = () => {
        if (this.state.viewing_log == '') {
            toast.warning('Viewing log cannot be empty')
        }
        else {
            this.setState({ viewing_loader: true })
            const data = {
                listing_id: this.state.record_id,
                note: this.state.viewing_log,
                note_type: "viewing",
                users: this.state.selectedViewingUsers
            }

            API.post("/add_note", data)
                .then((response) => {
                    console.log("res", response.data)
                    if (response.data.success) {
                        this.setState({
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            selectedViewingUsers: [],
                            viewing_log: '',
                            viewing_loader: false,
                            all: response.data.all
                        })

                    }
                    else {
                        this.setState({ viewing_loader: false })
                    }
                })
                .catch(() => [
                    this.setState({ viewing_loader: false })
                ])
        }
    }

    handleOfferSubmit = () => {
        if (this.state.offer_log == '') {
            toast.warning('toast log icannot be empty')
        }
        else {
            this.setState({ offer_loader: true })
            const data = {
                listing_id: this.state.record_id,
                note: this.state.offer_log,
                note_type: "offer",
                users: this.state.selectedOfferUsers
            }

            API.post("/add_note", data)
                .then((response) => {
                    console.log("res", response.data)
                    if (response.data.success) {
                        this.setState({
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            selectedOfferUsers: [],
                            offer_log: '',
                            offer_loader: false,
                            all: response.data.all
                        })

                    }
                    else {
                        this.setState({ offer_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ offer_loader: false });
                    toast.error('something went wrong');
                })
        }
    }

    handleWhatsappLog = (e) => {
        this.setState({ whatsapp_log: e.target.value })
    }
    handleWhatsappSubmit = () => {
        if (this.state.whatsapp_log == '') {
            toast.warning('whatsApp log cannot be empty')
        }
        else {
            this.setState({ whatsapp_loader: true })
            const data = {
                listing_id: this.state.record_id,
                note: this.state.whatsapp_log,
                note_type: "whatsapp",
                users: this.state.selectedWhatsappUsers
            }

            API.post("/add_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            selectedWhatsappUsers: [],
                            whatsapp_log: '',
                            whatsapp_loader: false,
                            all: response.data.all
                        })

                    } else {
                        this.setState({ whatsapp_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ whatsapp_loader: false });
                    toast.error('something went wrong');
                })
        }
    }

    handleEmailLog = (e) => {
        this.setState({ email_log: e.target.value })
    }

    handleEmailSubmit = () => {
        if (this.state.email_log == '') {
            toast.warning('Email log cannot be empty')
        }
        else {
            this.setState({ email_loader: true })
            const data = {
                listing_id: this.state.record_id,
                note: this.state.email_log,
                note_type: "email",
                users: this.state.selectedEmailUsers,

            }
            API.post("/add_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            selectedEmailUsers: [],
                            email_log: '',
                            email_loader: false,
                            all: response.data.all
                        })

                    }
                    else {
                        this.setState({ email_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ email_loader: false });
                    toast.error('something went wrong');
                })
        }
    }

    handleNoteLog = (e) => {
        this.setState({ note_log: e.target.value })
    }

    handleCallLog = (e) => {
        this.setState({ call_log: e.target.value })
    }

    handleMetaTitle = (e) => {
        this.setState({ meta_title: e.target.value })
    }

    handleVideoLink = (e) => {
        this.setState({ video_link: e.target.value })
    }

    handleLiveTourLink = (e) => {
        this.setState({ livetour_link: e.target.value })
    }

    handleMetaKeywords = (e) => {
        this.setState({ meta_keywords: e.target.value })
    }

    handleMetaDescription = (e) => {
        this.setState({ meta_description: e.target.value })
    }

    handleNoteSubmit = () => {
        if (this.state.note_log == '') {
            toast.warning('Note log cannot be empty');
        }
        else {
            this.setState({ note_loader: true })
            const data = {
                listing_id: this.state.record_id,
                note: this.state.note_log,
                note_type: "note",
                users: this.state.selectedNoteUsers
            }

            API.post("/add_note", data)
                .then((response) => {

                    if (response.data.success) {
                        this.setState({
                            notes: response.data.notes,
                            call_notes: response.data.call_notes,
                            whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            selectedNoteUsers: [],
                            note_log: "",
                            note_loader: false,
                            all: response.data.all
                        })
                    }
                    else {
                        this.setState({ note_loader: false })
                    }
                })
                .catch((err) => {
                    this.setState({ note_loader: false });
                    toast.error('something went wrong');
                })
        }
    }

    handleCallSubmit = () => {
        if (this.state.call_log == '') {
            toast.warning('Call log cannot be empty');
        }
        else {
            this.setState({ call_loader: true })
            const data = {
                listing_id: this.state.record_id,
                note: this.state.call_log,
                note_type: "phone",
                users: this.state.selectedCallUsers
            }

            API.post("/add_note", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            notes: response.data.notes,
                            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                            email_notes: response.data.email_notes,
                            call_log: "",
                            selectedCallUsers: [],
                            call_loader: false,
                            all: response.data.all
                        })

                    } else {
                        this.setState({
                            call_loader: false
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        call_loader: false
                    })
                    toast.error('something went wrong');
                })
        }
    }

    handleFormSubmit = () => {
        const listing_id = this.state.record_id
        const data = {
            form_a: this.state.form_a, emirate_id: this.state.emirate_id,
            title_deed: this.state.title_deed, exclusive: this.state.exclusive,
            deed_expire: this.state.deed_expire
        }
        API.put("/Update_form/" + listing_id, data)
            .then((response) => {
                console.log("res", response.data)
            })
    }

    handleForWebsiteSubmit = () => {
        if (this.websiteValidate()) {
            const listing_id = this.state.record_id
            const data = {
                meta_title: this.state.meta_title,
                meta_description: this.state.meta_description,
                meta_keywords: this.state.meta_keywords,
                video_link: this.state.video_link,
                livetour_link: this.state.livetour_link,
                curations: this.state.curation_details,
                lifestyle: this.state.lifestyle_details
            }
            API.put("/update_website/" + listing_id, data)
                .then((response) => {
                    if (response.data.success) {
                        toast.success("Data for website is successfully updated")
                    } else {
                        toast.success("Something went wrong")
                    }
                })
        }
    }

    handleCalculate = () => {
        const total = ((this.state.rentalamount - this.state.servicecharge) / this.state.purchaseprice) * 100
        this.setState({ rentalyieldtotal: total })
    }

    handleSaveOwnerName = (value) => {

        const data = {
            owner_id: this.state.listing_details.owners_id,
            owner_name: value
        }
        console.log("dadad", data)
        API.post("/update_owner_name", data)
            .then((response) => {
                console.log("owner name", response.data)
            })
    }
    handleSaveOwnerEmail = (value) => {
        const data = {
            owner_id: this.state.listing_details.owners_id,
            owner_email: value
        }
        API.post("/update_owner_email", data)
            .then((response) => {
                console.log("owner email", response.data)
            })
    }
    handleSaveOwnerMobile = (value) => {
        const data = {
            owner_id: this.state.listing_details.owners_id,
            owner_mobile: value
        }
        API.post("/update_owner_mobile", data)
            .then((response) => {
                console.log("owner mobile", response.data)
            })
    }

    onSubmit(state, e) {
        const listings_id = state.listing_details.id;
        if (this.state.isSameValueCheck) {
            this.state.selected_portals.forEach((portal, index) => {
                portal['property_desc'] = state.selected_portals[0]['property_desc']
                portal['property_title'] = state.selected_portals[0]['property_title']
                const updatedPortals = [...state.selected_portals];
                updatedPortals[index] = portal;
                this.setState({ selected_portals: updatedPortals });
            });
        }
        const data = {
            selected_portals: state.selected_portals,
            listings_id: state.listing_details.id
        }
        API.put("/update_portals/" + listings_id, data)
            .then((response) => {
                if (response.data.success) {
                    toast.success("Portal successfully updated")
                } else {
                    toast.success("Something went wrong")
                }
            })
    }

    onImageUpload = () => {
        const data = {
            files: this.state.image_files
        }
        API.post("/upload_listing_files", data)
            .then((response) => {
                console.log("Res", response)
                // if(response.data.success){
                //     this.setState({property_images:response.data.images})
                // }
            })
    }


    handleSortUpdate = (oldIndex, newIndex, totalItems) => {
        let new_position = this.arrayMove(this.state.property_images, oldIndex, newIndex);
        this.setState({ property_images: new_position })
        const data = {
            old_sorting: oldIndex,
            new_sorting: newIndex,
            property_images: this.arrayMove(this.state.property_images, oldIndex, newIndex)
        }
        API.post("/sort_listing_images", data)
            .then((response) => {
                console.log("test", response)
            })
            .catch((e) => {
                console.log("error", e)
            })
    };

    emptyState() {
        this.setState({
            selected_portals_id: [],
            selected_portals: [],
            isSameValueCheck: false,
            avail_date: "",
            lifestyle_details: "",
            curation_details: [],
            selected_facilities: [],
            listing_details: {},
            property_images: [],
            notes: [],
            call_notes: [],
            whatsapp_notes: [],
            email_notes: [],
            agent_name_details: "",
            agent_name_details: "",
            property_for_details: "",
            international: 0,
            country: "",
            city: "",
            property_type_details: 0,
            completion_status: "",
            video_link: "",
            livetour_link: "",
            meta_title: "",
            meta_description: "",
            meta_keywords: "",
            unit_no_details: 0,
            lead_gen: false,
            just_listed: false,
            community_details: 0,
            subcommunity_details: 0,
            property_details: "",
            bedroom_details: 0,
            bathroom_details: "",
            price_details: "",
            bua_details: "",
            rera_permit_details: "",
            view_details: "",
            furnished_details: "",
            listing_status_details: "",
            emirate_id: "",
            form_a: 0,
            description_details: "",
            title_details: "",
            title_deed: "",
            exclusive: "",
            deed_expire: "",
            owner_other_properties: [],
            document_notes: [],
            documents: [],
            owner_id: "",
            available_date: "",
            owner_name: '',
            owner_email: '',
            owner_mobile: "",
            all: [],
        })
    }

    handleCloseEditDrawer = () => {
        this.setState({ openToEdit: false })

        // Farooq
        const newUrl = window.location.href.replace(/listing_process.*/, 'listing_process');
        // window.history.replaceState(null, "Leads Id", this.state.currUrl)
        window.history.replaceState(null, "listing_process Id", newUrl);
        this.setState({ currUrl: "" })
        //////////////////
    }

    fetchData = (index) => {
        if (index === 0) {
            this.fetchActionData();
        } else if (index === 1) {
            this.fetchReraPermitData();
        } else if (index === 2) {
            this.fetchSeoData();
        } else if (index === 3) {
            this.fetchPublishData();
        } else {
            this.fetchActionData();
        }
    }
    fetchReraPermitData = async () => {
        const page = 1;
        const perPage = 10;
        this.setState({ isLoading: true })
        await API.post(`/listings_process_rera_permit?page=${page}&perPage=${perPage}`)
            .then(async (response) => {
                if (response.data.success) {
                    this.setState({
                        listing_process: response.data.listings_process,
                        isLoading: false,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isLoading: false,
                })
                return err;
            })
    }

    fetchSeoData = async () => {
        const page = 1;
        const perPage = 10;
        this.setState({ isLoading: true })
        await API.post(`/listings_process_seo?page=${page}&perPage=${perPage}`)
            .then(async (response) => {
                if (response.data.success) {
                    this.setState({
                        listing_process: response.data.listings_process,
                        isLoading: false,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isLoading: false,
                })
                return err;
            })
    }

    fetchPublishData = async () => {
        const page = 1;
        const perPage = 10;
        this.setState({ isLoading: true })
        await API.post(`/listings_process_publish?page=${page}&perPage=${perPage}`)
            .then(async (response) => {
                if (response.data.success) {
                    this.setState({
                        listing_process: response.data.listings_process,
                        isLoading: false,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isLoading: false,
                })
                return err;
            })
    }
    fetchActionData = async () => {
        const page = 1;
        const perPage = 10;
        this.setState({ isLoading: true })
        await API.post(`/listings_process_action?page=${page}&perPage=${perPage}`)
            .then(async (response) => {
                if (response.data.success) {
                    this.setState({
                        listing_process: response.data.listings_process,
                        isLoading: false,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isLoading: false,
                })
                return err;
            })
    }
    render() {
        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let user = userdata.auth.user;
        let role_name = userdata.auth.user.role_name;
        let user_id = user.id
        const columns = [
            {
                title: 'Property', dataIndex: 'refno', className: "propertywrap", width: 250,
                render: (text, record) => (

                    <><div className='info-group'>
                        {/* When they click the ref no it will open the edit listing page */}
                        {/*<a className='font-bold mb-0'>{record.ref_no}</a>*/}
                        <Link className="btn-link fs-6 font-bold" onClick={this.handleOpenDrawerToEdit.bind(this, record.listing_id, false)}>{record.ref_no}</Link>
                        <p className='font-12' style={{ whiteSpace: 'normal' }}>{record.unit_no} {record.development}, {record.project}, {record.property} </p>
                    </div>
                        {record.sales_price != null ?
                            <div className='info-group'>
                                <p className='info-title'>Sales Price:</p>
                                <p className=''>{(record.sales_price && !isNaN(parseInt(record.sales_price)) && record.sales_price != null) ? record.sales_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : "-"}</p>
                            </div>
                            : null}
                    </>
                )
            },
            {
                title: 'Status', dataIndex: 'listingstatus', width: 80,
                render: (text, record) => (
                    <>
                        {record.listing_status === 'U' ?
                            <><span className='c-icon action'>Action</span></>
                            : record.listing_status === 'SEO' ?
                                <><span className='c-icon seo'>Proofreading / SEO</span></>
                                : record.listing_status === 'P' ?
                                    <><span className='c-icon trakheesi'>Rera Permit & Portal Info</span></>
                                    : record.listing_status === 'PA' ?
                                        <><span className='c-icon publish'>Publish / Feeds</span></>
                                        :
                                        <><span className='c-icon action'>Action</span></>
                        }
                    </>
                )
            },
            {
                title: 'Created Date', dataIndex: 'createddate', width: 120,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Created at:</p>
                            <p><Moment format="MMMM DD, YYYY">{record.created_at}</Moment></p>
                        </div>
                    </>
                )
            },
            {
                title: 'Agent', dataIndex: 'agent', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Agent:</p>
                            <p>{record.agent_name}</p>
                        </div>
                    </>
                )
            },
            {
                title: 'Actions', dataIndex: 'actions', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group'>
                            <p className='info-title'>Actions:</p>
                            <p>Ronald Saleem</p>
                        </div>
                        <div className='info-check'>
                            {(record.pictures == "" || record.pictures == null) ? <CloseOutlined /> : <CheckOutlined />}
                            <p>Pictures</p>
                        </div>
                        <div className='info-check'>
                            {(record.documents == "" || record.documents == null) ? <CloseOutlined /> : <CheckOutlined />}
                            <p>Documents</p>
                        </div>
                        <div className='info-check'>
                            {(record.description == "" || record.description == null) ? <CloseOutlined /> : <CheckOutlined />}
                            <p>Description</p>
                        </div>
                        <div className='info-check'>
                            {((record.bua == "" || record.bua == null) ||
                                (record.property_type == "" || record.property_type == null) ||
                                (record.bedrooms == "" || record.bedrooms == null) ||
                                (record.pictures == "" || record.pictures == null) ||
                                (record.description == "" || record.description == null) ||
                                (record.development == "" || record.development == null) ||
                                (record.project == "" || record.project == null) ||
                                (record.property == "" || record.property == null)) ? <CloseOutlined /> : <CheckOutlined />}
                            <p>Property Info</p>
                        </div>
                    </>
                )
            },
            {
                title: 'Trakheesi & Portal Info', dataIndex: 'trakheesi', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group-check'>
                            {(record.trakheesi == "" || record.trakheesi == null) ? <CloseOutlined /> : <CheckOutlined />}
                            <div className='info-group'>
                                <p className='info-title'>Rera Permit:</p>
                                <p>Ronald Saleem</p>
                                {/*<p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p>*/}
                            </div>
                        </div>


                        <div className='info-group-check'>
                            {(record.portal_info == "" || record.portal_info == null) ? <CloseOutlined /> : <CheckOutlined />}
                            <div className='info-group'>
                                <p className='info-title'>Portal Info:</p>
                                <p>Ronald Saleem</p>
                                {/* If it is not updated yet do not show */}
                                {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                            </div>
                        </div>
                    </>
                )
            },
            {
                title: 'Proofreading & SEO', dataIndex: 'proofreading', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group-check'>
                            {(record.description == "" || record.description == null) ? <CloseOutlined /> : <CheckOutlined />}
                            <div className='info-group'>
                                <p className='info-title'>Proofreading:</p>
                                <p>Ronald Saleem</p>
                                {/* If it is not updated yet do not show */}
                                {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                            </div>
                        </div>
                        <div className='info-group-check'>
                            {((record.meta_title == "" || record.meta_title == null) ||
                                (record.meta_desc == "" || record.meta_desc == null) ||
                                (record.meta_keywords == "" || record.meta_keywords == null)) ? <CloseOutlined /> : <CheckOutlined />}
                            <div className='info-group'>
                                <p className='info-title'>SEO:</p>
                                <p>Ronald Saleem</p>
                                {/* If it is not updated yet do not show */}
                                {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                            </div>
                        </div>
                    </>
                )
            },
            {
                title: 'Publish/Feeds', dataIndex: 'trakheesi', width: 150,
                render: (text, record) => (
                    <>
                        <div className='info-group-check'>
                            {((record.published_date == "" || record.published_date == null) || (record.listing_status != "U")) ? <CloseOutlined /> : <CheckOutlined />}
                            <div className='info-group'>
                                <p className='info-title'>Publish/Feeds:</p>
                                <p>Ronald Saleem</p>
                                {/* If it is not updated yet do not show */}
                                {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                            </div>
                        </div>
                    </>
                )
            }
        ]


        const allListingColumns = [
            {
                title: 'cardColumn', className: 'namecolwrap',
                // style: { zIndex: '9' },
                sorter: (a, b) => a.refno.length - b.refno.length,
                render: (text, record) => {

                    return (<>

                        <div className="TableCard">

                            <div className='row alignCenter headerGrayBgColor borderBottom'>
                                <div className='col-6'>
                                    <div className='HeaderMainLeft'>
                                        <div className='info-group'>
                                            <Link className="btn-link fs-6 font-bold" onClick={this.handleOpenDrawerToEdit.bind(this, record.listing_id, false)}> <p className='font-bold fs-6  mb-0'>{record.ref_no}</p></Link>
                                            {record.sales_price != null ? <>
                                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                                    <p className='info-title'>Sales Price:</p>
                                                    <p style={{fontSize: window.isMobile576 ? '10px' : ''}}>{(record.sales_price && !isNaN(parseInt(record.sales_price)) && record.sales_price != null) ? record.sales_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : "-"}</p>
                                                </div></> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                                        <p>{record.listing_status === 'U' ?
                                            <><span className='c-icon action'>Action</span></>
                                            : record.listing_status === 'SEO' ?
                                                <><span className='c-icon seo'>Proofreading / SEO</span></>
                                                : record.listing_status === 'P' ?
                                                    <><span className='c-icon trakheesi'>Rera Permit & Portal Info</span></>
                                                    : record.listing_status === 'PA' ?
                                                        <><span className='c-icon publish'>Publish / Feeds</span></>
                                                        :
                                                        <><span className='c-icon action'>Action</span></>
                                        }</p>
                                    </div>
                                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                                        <p className='info-title'>Created at:</p>
                                        <p><Moment format="MMMM DD, YYYY">{record.created_at}</Moment></p>
                                    </div>
                                </div>
                            </div>


                            <div className='row alignCenter borderGray pt-2 pb-2'>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowLeft'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p>{record.unit_no} {record.development}, {record.project}, {record.property} </p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>

                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                        <p className='info-title'>Agent:</p>
                                        <p>{record.agent_name}</p>
                                    </div>

                                </div>
                            </div>




                            <div className='row alignCenter borderGray pt-2 pb-2'>
                                <div className='col-6 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Actions:</p>
                                            <p className='px-2'>

                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='BodyFirstRowRight'>
                                                            <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                <p className='font-bold' style={{ fontSize: '14px' }}>
                                                                    <p>Ronald Saleem</p>
                                                                    <div className='info-check'>
                                                                        {(record.pictures == "" || record.pictures == null) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <p>Pictures</p>
                                                                    </div>
                                                                    <div className='info-check'>
                                                                        {(record.documents == "" || record.documents == null) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <p>Documents</p>
                                                                    </div>
                                                                    <div className='info-check'>
                                                                        {(record.description == "" || record.description == null) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <p>Description</p>
                                                                    </div>
                                                                    <div className='info-check'>
                                                                        {((record.bua == "" || record.bua == null) ||
                                                                            (record.property_type == "" || record.property_type == null) ||
                                                                            (record.bedrooms == "" || record.bedrooms == null) ||
                                                                            (record.pictures == "" || record.pictures == null) ||
                                                                            (record.description == "" || record.description == null) ||
                                                                            (record.development == "" || record.development == null) ||
                                                                            (record.project == "" || record.project == null) ||
                                                                            (record.property == "" || record.property == null)) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <p>Property Info</p>
                                                                    </div>
                                                                </p>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className='col-6 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                                            <p className='px-2'>

                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='BodyFirstRowRight'>
                                                            <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                <p className='font-bold' style={{ fontSize: '14px' }}>

                                                                    <div className='info-group-check'>
                                                                        {(record.trakheesi == "" || record.trakheesi == null) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <div className='info-group'>
                                                                            <p className='info-title'>Rera Permit:</p>
                                                                            <p>Ronald Saleem</p>
                                                                            {/*<p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p>*/}
                                                                        </div>
                                                                    </div>


                                                                    <div className='info-group-check'>
                                                                        {(record.portal_info == "" || record.portal_info == null) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <div className='info-group'>
                                                                            <p className='info-title'>Portal Info:</p>
                                                                            <p>Ronald Saleem</p>
                                                                            {/* If it is not updated yet do not show */}
                                                                            {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                                                                        </div>
                                                                    </div>
                                                                </p>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className='col-6 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                                            <p className='px-2'>

                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='BodyFirstRowRight'>
                                                            <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                <p className='font-bold' style={{ fontSize: '14px' }}>
                                                                    <div className='info-group-check'>
                                                                        {(record.description == "" || record.description == null) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <div className='info-group'>
                                                                            <p className='info-title'>Proofreading:</p>
                                                                            <p>Ronald Saleem</p>
                                                                            {/* If it is not updated yet do not show */}
                                                                            {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='info-group-check'>
                                                                        {((record.meta_title == "" || record.meta_title == null) ||
                                                                            (record.meta_desc == "" || record.meta_desc == null) ||
                                                                            (record.meta_keywords == "" || record.meta_keywords == null)) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <div className='info-group'>
                                                                            <p className='info-title'>SEO:</p>
                                                                            <p>Ronald Saleem</p>
                                                                            {/* If it is not updated yet do not show */}
                                                                            {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                                                                        </div>
                                                                    </div>
                                                                </p>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </p>
                                        </div>
                                    </div>
                                </div>





                                <div className='col-6 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex flex-column gap-1 BodyFirstRowRight'>
                                            <p className='px-2'>

                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='BodyFirstRowRight'>
                                                            <div className='d-flex gap-1 BodyFirstRowRight'>
                                                                <p className='font-bold' style={{ fontSize: '14px' }}>

                                                                    <div className='info-group-check'>
                                                                        {((record.published_date == "" || record.published_date == null) || (record.listing_status != "U")) ? <CloseOutlined /> : <CheckOutlined />}
                                                                        <div className='info-group'>
                                                                            <p className='info-title'>Publish/Feeds:</p>
                                                                            <p>Ronald Saleem</p>
                                                                            {/* If it is not updated yet do not show */}
                                                                            {/* <p><span>Updated: </span><Moment format="MMMM DD, YYYY">May 8, 2023</Moment></p> */}
                                                                        </div>
                                                                    </div>
                                                                </p>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </>)
                }

            }
        ];


        return (
            <>
                <ToastContainer />

                <Modal width={1000} title="Choose Amenities" open={this.state.openToAmenities} onOk={this.handleSaveAmenities} onCancel={this.handleCancelAmenities}>
                    <div className='row'>
                        {this.state.facilities.map((facility) => {
                            return (
                                <div className='col-md-4'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id={facility.value} onChange={this.onChangeFacilities.bind(this, facility)} checked={(this.state.selected_facilities.includes(facility.value) ? true : false)} />
                                        <label className="form-check-label" for={facility.value}>
                                            {facility.label}
                                        </label>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </Modal>

                <Drawer
                    title={`Listing details`}
                    placement="left"
                    // size={"large"}
                    width={"98%"}
                    onClose={this.handleCloseEditDrawer}
                    open={this.state.openToEdit}
                    extra={
                        <Space className='d-flex flex-row align-content-center' style={{ gap: '0.8rem' }}>
                            {/* <a target="_blank" href={"https://www.luxuryproperty.com/property/preview/" + this.state.listing_details.refno+"-"+this.state.record_id} className="btn btn-default btn-sm btn-preview" style={{height: '31px'}}> <EyeFilled />Preview</a> */}
                            <Button type='primary' danger ghost onClick={this.handleCloseEditDrawer} icon={<CloseOutlined />} style={{ height: '31px', justifyContent: 'center' }}></Button>
                            {/* <Button type="primary" onClick={this.handleSaveEditDrawer}>
              Save changes
            </Button> */}

                        </Space>
                    }
                >
                    <div className="cont-wrap container">

                        {
                            this.state.loader ?
                                <div style={{ position: 'absolute', alignSelf: "center", top: '50%' }} >
                                    <Spin size='large' />
                                </div>
                                :
                                <>
                                    <div className={`row ${window.isIpadView? "" : "column-wrap"}  toptitle m-0`}>
                                        
                                            <div className='d-flex flex-row  col-12 col-sm-12 col-md-3 pl-0 pr-4 justify-content-between' style={{ gap: "0.6rem" }}>

                                                <h2 className="page-title fs-5 font-bold my-1 ">{this.state.listing_details.refno}</h2>
                                                <p className="d-flex mb-0">
                                                    {this.state.listing_details.status == "D" ? <span className="draft pstat">Draft</span> : ""}
                                                    {this.state.listing_details.status == "UC" ? <span className="confirmedowner pstat">Confirmed Owner</span> : ""}
                                                    {this.state.listing_details.status == "N" ? <span className="unpublished pstat">Unpublished</span> : ""}
                                                    {this.state.listing_details.status == "OM" ? <span className="offmarket pstat">Off-Market</span> : ""}
                                                    {this.state.listing_details.status == "CTM" ? <span className="comingtomarket pstat">Coming to Market</span> : ""}
                                                    {this.state.listing_details.status == "Y" ? <span className="published pstat">Published</span> : ""}
                                                    {this.state.listing_details.status == "U" ? <span className="action pstat">Action</span> : ""}
                                                    {this.state.listing_details.status == "SEO" ? <span className="seo pstat">SEO</span> : ""}
                                                    {this.state.listing_details.status == "L" ? <span className="unlisted pstat">Unlisted</span> : ""}
                                                    {this.state.listing_details.status == "S" ? <span className="sold pstat">Sold</span> : ""}
                                                    {this.state.listing_details.status == "T" ? <span className="let pstat">Let</span> : ""}
                                                    {this.state.listing_details.status == "P" ? <span className="review pstat">Review</span> : ""}
                                                    {this.state.listing_details.status == "F" ? <span className="feeds pstat">Feeds</span> : ""}
                                                </p>
                                            </div>
                                            <div className="pinfo col-12 col-sm-12 col-md-6">

                                                <p className="mb-0">
                                                    {this.state.listing_details.unitno + " "}
                                                    {this.state.listing_details.property_title == null ? "" : this.state.listing_details.property_title + ", "}
                                                    {this.state.listing_details.sub_community_title == null ? "" : this.state.listing_details.sub_community_title + ", "}
                                                    {this.state.listing_details.community_title}
                                                </p>
                                                <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>
                                                <span>{this.state.listing_details.property_for}</span>
                                                <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>
                                                <span className="">{this.state.listing_details.property_type}</span>
                                                <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>
                                                <span><i className="fa fa-bed mr-1" aria-hidden="true"></i>{this.state.listing_details.bedrooms}</span>
                                                <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>

                                                <span>{parseInt(this.state.listing_details.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED"}</span>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-3 d-flex flex-row pr-1 justify-content-between" >
                                                <a target="_blank" href={"https://www.luxuryproperty.com/property/preview/" + this.state.listing_details.refno + "-" + this.state.record_id} className="btn btn-default btn-sm btn-preview ml-auto" style={{ height: '36px' }} > <EyeFilled />Preview</a>


                                            </div>

                                       
                                    </div>
                                    <div className={`row ${window.isIpadView ? '' : 'column-wrap'} mt-3`}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                            {role == 1 || user_id == this.state.listing_details.agent_id ?
                                                <div className="bg-white border-top ownersdetails">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <h3 className='p18pt10'>Owner Details</h3>
                                                        <button type="button" className="btn btn-primary requestchange">Request Change</button>
                                                        {/*{role_name === ("Super Admin" || "Admin") && <button type="button" className="btn btn-primary requestchange">Request Change</button>}*/}
                                                    </div>
                                                    <div className="d-flex flex-column formfieldset mt-3">
                                                        <div className="d-flex flex-column form-group">
                                                            <label className="control-labels mr-4"> Name</label>

                                                            {/* editing */}
                                                            <EdiText

                                                                value={this.state.owner_name}
                                                                type="text"
                                                                onSave={this.handleSaveOwnerName}

                                                            />

                                                        </div>
                                                        <div className="d-flex flex-column form-group">
                                                            <label className="control-labels mr-4">Phone </label>
                                                            <p>{this.state.owner_mobile}</p>
                                                        </div>
                                                        <div className="d-flex flex-column form-group">
                                                            <label className="control-labels mr-4">Email </label>
                                                            <EdiText

                                                                value={this.state.owner_email}
                                                                type="text"
                                                                onSave={this.handleSaveOwnerEmail}
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column form-group">
                                                            <label className="control-labels mr-4">Owner's Other Properties</label>
                                                            <div className="col-12 d-flex flex-column border-wrap listtable-wrap">
                                                                {this.state.owner_other_properties.map((owner_other_property) => {
                                                                    var pStatus = 'Draft'
                                                                    switch (owner_other_property.status) {
                                                                        case 'D':
                                                                            pStatus = 'Draft'
                                                                            break;
                                                                        case 'UC':
                                                                            pStatus = 'Confirmed Owner'
                                                                            break;
                                                                        case 'N':
                                                                            pStatus = 'Unpublish'
                                                                            break;
                                                                        case 'OM':
                                                                            pStatus = 'Off-Market'
                                                                            break;
                                                                        case 'CTM':
                                                                            pStatus = 'Coming to market'
                                                                            break;
                                                                        case 'Y':
                                                                            pStatus = 'Publish'
                                                                            break;
                                                                        case 'U':
                                                                            pStatus = 'Action'
                                                                            break;

                                                                        case 'SEO':
                                                                            pStatus = 'SEO'
                                                                            break;
                                                                        case 'L':
                                                                            pStatus = 'Unlisted'
                                                                            break;
                                                                        case 'S':
                                                                            pStatus = 'Sold'
                                                                            break;
                                                                        case 'T':
                                                                            pStatus = 'Let'
                                                                            break;
                                                                        case 'P':
                                                                            pStatus = 'Review'
                                                                            break;
                                                                        case 'F':
                                                                            pStatus = 'Feeds'
                                                                            break;
                                                                        default:
                                                                            pStatus = 'Draft'
                                                                            break;
                                                                    }
                                                                    return (
                                                                        <a href className="d-flex flex-row p-2 w-100">
                                                                            <div className="col-8 listtable">
                                                                                <p className="propfor">{owner_other_property.property_for}</p>
                                                                                <p className="refno">{owner_other_property.refno}</p>
                                                                                <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                                                                <p className="community">{owner_other_property.community}</p>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="d-flex flex-row stat publishstat">
                                                                                    <i className="bi bi-circle-fill" />
                                                                                    <span>

                                                                                        {pStatus}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )
                                                                })}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column mt-3 val-wrap">
                                                        <button className="btn btn-primary" id="getvaluation">Get Valuation</button>
                                                        <p id="predicted_value" />
                                                        <p id="precision" />
                                                    </div>
                                                </div> : null}
                                            {this.state.listing_details.property_for == "sale" ?
                                                /* Mini Calculator */
                                                <div className={`${window.isTabView ? 'px-0 py-2' : 'p-4'} bg-white border-top offers mt-3`}>
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <h3 className="mr-4 p18pt10">Rental Yield Calculator</h3>
                                                    </div>
                                                    <div className="col-12 px-0 form-group d-flex flex-column mt-2">
                                                        <div className="form-group">
                                                            <label className="control-labels ">Purchase Price (AED)</label>
                                                            <input type="text" id="purchaseprice" onChange={this.handlepurchaseprice} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-labels ">Service Charges - Per Year (AED)</label>
                                                            <input type="text" id="servicecharges" onChange={this.handleservicecharge} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-labels ">Rental Amount (AED)</label>
                                                            <input type="text" id="rentalamount" onChange={this.handlerentalamount} />
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <button className="btn btn-primary" id="getcalculate" onClick={this.handleCalculate}>Calculate</button>
                                                            {/* total(%) = (rental amount - service charge ) / purchase price
                when they click the calculate total shows and it will add a value on the column Rental Yield
              */}

                                                        </div>

                                                        {this.state.rentalyieldtotal >= 0 ?
                                                            <div className="d-flex flex-row w-100 mt-4 rentalyield">
                                                                <p className="form-control">Rental Yield  : <strong>{this.state.rentalyieldtotal + "%"}</strong></p>
                                                            </div> : null}
                                                    </div>
                                                </div> : null}
                                            {/* FORMS */}
                                            <div className={`${window.isTabView ? 'px-0 py-2' : 'p-4'} bg-white border-top offers mt-3`}>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4 p18pt10">Forms</h3>
                                                </div>
                                                <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
                                                    <div className="form-group">
                                                        <label className="control-labels ">Form A</label>
                                                        <select onChange={this.handleFormA} value={this.state.form_a} className="select2 select2-offscreen" id="forma_doc" title tabIndex={-1}>
                                                            <option value>Select</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Emirates ID</label>
                                                        <select onChange={this.handleEmirateID} value={this.state.emirate_id} className="select2 select2-offscreen" id="eid_doc" title tabIndex={-1}>
                                                            <option value>Select</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Title Deed</label>
                                                        <select onChange={this.handleTitleDeed} value={this.state.title_deed} className="select2 select2-offscreen" id="titledeed_doc" title tabIndex={-1}>
                                                            <option value>Select</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Exclusive</label>
                                                        <select onChange={this.handleExclusive} value={this.state.exclusive} className="select2 select2-offscreen" id="exclusive_doc" title tabIndex={-1}>
                                                            <option value>Select</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-labels ">Deed Expiry</label>

                                                        <input onChange={this.handleDeedExpire} defaultValue={this.state.deed_expire} type="datetime-local" id="dead_expiry" />
                                                    </div>
                                                </div>
                                                <button onClick={this.handleFormSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button>
                                            </div>{/* END FORMS*/}
                                            <div className={`${window.isTabView ? 'px-0 py-2' : 'p-4'} bg-white border-top marketrequest mt-4`}>
                                                <h3 className='p18pt10'>Marketing Request</h3>
                                                <div className="d-flex flex-row mb-2">
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-star-fill" /><span>Premium</span></button>
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#photorequest"><i className="bi bi-camera-fill" /><span>Photos</span></button>
                                                </div>
                                                <div className="d-flex flex-row mb-2">
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-file-earmark-arrow-down-fill" /><span>Brochure</span></button>
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-house-fill" />Open House</button>
                                                </div>
                                                <div className="d-flex flex-row mb-2">
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-instagram" /><span>Social Media</span></button>
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-whatsapp" /><span>WhatsApp Blast</span></button>
                                                </div>
                                                <div className="d-flex flex-row mb-2">
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-envelope-fill" /><span>EDM</span></button>
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-file-earmark-pdf-fill" /><span>Market Report</span></button>
                                                </div>
                                                <div className="d-flex flex-row mb-2">
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-image" /><span>Photo Edit</span></button>
                                                    <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-signpost-fill" /><span>Sign Board</span></button>
                                                </div>
                                                <div className="d-flex flex-column border-wrap marketrequesthistory">
                                                    <a href className="d-flex flex-row p-2 w-100 marketwrap">
                                                        <div className="col-12 requesttable d-flex flex-row">
                                                            <div className="col-6">
                                                                <p className="requestfor">Premium</p>
                                                                <p className="requestname">Jason Hayes</p>
                                                            </div>
                                                            <div className="col-6 text-end">
                                                                <p className="requestdate">10/02/2023</p>
                                                                <p className="requeststat">In Review</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href className="d-flex flex-row p-2 w-100 marketwrap">
                                                        <div className="col-12 requesttable d-flex flex-row">
                                                            <div className="col-6">
                                                                <p className="requestfor">Brochure</p>
                                                                <p className="requestname">Jason Hayes</p>
                                                            </div>
                                                            <div className="col-6 text-end">
                                                                <p className="requestdate">10/02/2023</p>
                                                                <p className="requeststat">In Review</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href className="d-flex flex-row p-2 w-100 marketwrap">
                                                        <div className="col-12 requesttable d-flex flex-row">
                                                            <div className="col-6">
                                                                <p className="requestfor">Social Media</p>
                                                                <p className="requestname">Jason Hayes</p>
                                                            </div>
                                                            <div className="col-6 text-end">
                                                                <p className="requestdate">10/02/2023</p>
                                                                <p className="requeststat">In Review</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 timeline-wrap">
                                            <div className= {`${window.isTabView ? 'px-0 py-2' : 'p-4'} d-flex flex-column w-100 bg-white border-top ownersdetails`}>
                                                <h3 className='p18pt10'>Timeline</h3>
                                                {/* ADD NOTES */}
                                                <div className={`nav nav-tabs nav-fill  ${window.isIpadView ?  'XScroll' : ''}`} id="nav-tab" role="tablist">
                                                    <button className="nav-link active" id="nav-call-tab" data-bs-toggle="tab" data-bs-target="#nav-call" type="button" role="tab" aria-controls="nav-call" aria-selected="true">Call</button>
                                                    <button className="nav-link" id="nav-notes-tab" data-bs-toggle="tab" data-bs-target="#nav-notes" type="button" role="tab" aria-controls="nav-notes" aria-selected="false">Notes</button>
                                                    <button className="nav-link" id="nav-whatsapp-tab" data-bs-toggle="tab" data-bs-target="#nav-whatsapp" type="button" role="tab" aria-controls="nav-whatsapp" aria-selected="false">WhatsApp</button>
                                                    <button className="nav-link" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email" type="button" role="tab" aria-controls="nav-email" aria-selected="false">Email</button>
                                                    <button className="nav-link" id="nav-offer-tab" data-bs-toggle="tab" data-bs-target="#nav-offer" type="button" role="tab" aria-controls="nav-offer" aria-selected="false">Offer</button>
                                                    <button className="nav-link" id="nav-viewing-tab" data-bs-toggle="tab" data-bs-target="#nav-viewing" type="button" role="tab" aria-controls="nav-viewing" aria-selected="false">Viewing</button>
                                                    <button className="nav-link" id="nav-followup-tab" data-bs-toggle="tab" data-bs-target="#nav-followup" type="button" role="tab" aria-controls="nav-followup" aria-selected="false">Follow Up</button>
                                                </div>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-call" role="tabpanel" aria-labelledby="nav-call-tab" tabIndex={0}>
                                                        <Mentions
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            value={this.state.call_log}
                                                            onChange={this.onChangeCallNote}
                                                            onSelect={this.onSelectCallMentions}
                                                            options={this.state.userClients}
                                                        />
                                                        {
                                                            this.state.call_loader ?
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                    <Spin style={{ alignSelf: 'center' }} />
                                                                </div>
                                                                :
                                                                <button id="calllogbtn" onClick={this.handleCallSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" /> Add call log</button>
                                                        }
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab" tabIndex={0}>
                                                        <Mentions
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            value={this.state.note_log}
                                                            onChange={this.onChangeNoteNote}
                                                            onSelect={this.onSelectNoteMentions}
                                                            options={this.state.userClients}
                                                        />
                                                        {
                                                            this.state.note_loader ?
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                    <Spin style={{ alignSelf: 'center' }} />
                                                                </div>
                                                                :
                                                                <button id="logsbtn" onClick={this.handleNoteSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add note</button>
                                                        }
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-whatsapp" role="tabpanel" aria-labelledby="nav-whatsapp-tab" tabIndex={0}>
                                                        <Mentions
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            value={this.state.whatsapp_log}
                                                            onChange={this.onChangeWhatsappNote}
                                                            onSelect={this.onSelectWhatsappMentions}
                                                            options={this.state.userClients}
                                                        />
                                                        {
                                                            this.state.whatsapp_loader ?
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                    <Spin style={{ alignSelf: 'center' }} />
                                                                </div>
                                                                :
                                                                <button onClick={this.handleWhatsappSubmit} id="whatsapplogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add WhatsApp Note</button>
                                                        }
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab" tabIndex={0}>
                                                        <input type="text" placeholder="Subject" className="form-control font-14 mb-2"></input>
                                                        <Mentions
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            value={this.state.email_log}
                                                            onChange={this.onChangeEmailNote}
                                                            onSelect={this.onSelectEmailMentions}
                                                            options={this.state.userClients}
                                                        />
                                                        {
                                                            this.state.email_loader ?
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                    <Spin style={{ alignSelf: 'center' }} />
                                                                </div>
                                                                :
                                                                <button onClick={this.handleEmailSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add email</button>
                                                        }
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-offer" role="tabpanel" aria-labelledby="nav-offer-tab" tabIndex={0}>
                                                        <Mentions
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            value={this.state.offer_log}
                                                            onChange={this.onChangeOfferNote}
                                                            onSelect={this.onSelectOfferMentions}
                                                            options={this.state.userClients}
                                                        />
                                                        {
                                                            this.state.offer_loader ?
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                    <Spin style={{ alignSelf: 'center' }} />
                                                                </div>
                                                                :
                                                                <button onClick={this.handleOfferSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Offer</button>
                                                        }
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-viewing" role="tabpanel" aria-labelledby="nav-viewing-tab" tabIndex={0}>
                                                        <Mentions
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            value={this.state.viewing_log}
                                                            onChange={this.onChangeViewingNote}
                                                            onSelect={this.onSelectViewingMentions}
                                                            options={this.state.userClients}
                                                        />
                                                        {
                                                            this.state.viewing_loader ?
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                    <Spin style={{ alignSelf: 'center' }} />
                                                                </div>
                                                                :
                                                                <button onClick={this.handleViewingSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Viewing</button>
                                                        }
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-followup" role="tabpanel" aria-labelledby="nav-followup-tab" tabIndex={0}>
                                                        <div className="form-group">
                                                            <label>Date / Time</label>
                                                            <DatePicker style={{ maxWidth: "50%", height: 32, overflow: "hidden" }} showTime onChange={this.handleFollowupDate} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Note</label>
                                                            <Mentions
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                value={this.state.followup_log}
                                                                onChange={this.onChangeFollowupNote}
                                                                onSelect={this.onSelectFollowupMentions}
                                                                options={this.state.userClients}
                                                            />
                                                        </div>
                                                        {
                                                            this.state.followUp_loader ?
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                                                    <Spin style={{ alignSelf: 'center' }} />
                                                                </div>
                                                                :
                                                                <button onClick={this.handleFollowupSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Follow Up</button>
                                                        }
                                                    </div>
                                                </div>
                                                {/* TIMELINE */}
                                                <div className="timeline border-wrap">
                                                    <div className={`nav nav-tabs nav-fill  ${window.isIpadView ?  'XScroll' : ''}`} id="nav-tab" role="tablist">
                                                        <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">All</button>
                                                        <button className="nav-link" id="nav-notewrap-tab" data-bs-toggle="tab" data-bs-target="#nav-notewrap" type="button" role="tab" aria-controls="nav-notewrap" aria-selected="false">Notes</button>
                                                        <button className="nav-link" id="nav-calllist-tab" data-bs-toggle="tab" data-bs-target="#nav-calllist" type="button" role="tab" aria-controls="nav-calllist" aria-selected="false">Calls</button>
                                                        <button className="nav-link" id="nav-wa-tab" data-bs-toggle="tab" data-bs-target="#nav-wa" type="button" role="tab" aria-controls="nav-wa" aria-selected="false">WhatsApp</button>
                                                        <button className="nav-link" id="nav-mail-tab" data-bs-toggle="tab" data-bs-target="#nav-mail" type="button" role="tab" aria-controls="nav-mail" aria-selected="false">Email</button>
                                                        <button className="nav-link" id="nav-offers-tab" data-bs-toggle="tab" data-bs-target="#nav-offers" type="button" role="tab" aria-controls="nav-offers" aria-selected="false">Offers</button>
                                                        <button className="nav-link" id="nav-view-tab" data-bs-toggle="tab" data-bs-target="#nav-view" type="button" role="tab" aria-controls="nav-view" aria-selected="false">Viewings</button>
                                                        <button className="nav-link" id="nav-followups-tab" data-bs-toggle="tab" data-bs-target="#nav-followups" type="button" role="tab" aria-controls="nav-followups" aria-selected="false">Follow Up</button>
                                                    </div>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        {/* ALL */}
                                                        <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabIndex={0}>
                                                            <div className="timeline-list">

                                                                {this.state.all.map((al) => {
                                                                    return (
                                                                        <div className={"timegroup " + al.note_type}>
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    {al.note_type == "phone" ? <i className="bi bi-telephone-fill"></i> : null}
                                                                                    {al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : null}
                                                                                    {al.note_type == "whatsapp" ? <i className="bi bi-whatsapp" /> : null}
                                                                                    {al.note_type == "note" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                                                                    {al.note_type == "followup" ? <i className="bi bi-calendar"></i> : null}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-11 px-0">
                                                                                <div className="col-12 d-flex flex-row">
                                                                                    <div className="agentdetails">
                                                                                        <div className="d-flex flex-row justify-content-between">
                                                                                            <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                                                                {al.note_type == "phone" ? " logged a call" : null}
                                                                                                {al.note_type == "email" ? " sent an email" : null}
                                                                                                {al.note_type == "whatsapp" ? "" : null}
                                                                                                {al.note_type == "note" ? " added a note" : null}
                                                                                                {al.note_type == "followup" ? " scheduled a follow up" : null}
                                                                                                {al.note_type == "req_view" ? " requested to view listing" : null}
                                                                                            </p>
                                                                                            <p className="atime">
                                                                                                <Moment fromNow>
                                                                                                    {(al.time)}
                                                                                                    {/*{this.getCurrentTime(al.time)}*/}
                                                                                                </Moment>
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="adesc">
                                                                                            {al.note_type == "phone" ?
                                                                                                <><p className='font-12'><strong className='mr-1 font-12'>Price:</strong>{al.confirm_price && al.confirm_price > 0 ? al.confirm_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : 0}</p>
                                                                                                    <p className='font-12'><strong className='mr-1 font-12'>Available:</strong>{al.isPropertyAvailable}</p>
                                                                                                    <p className='font-12'><strong className='mr-1 font-12'>Discuss with vendor:</strong>{al.call_log_discuss}</p>
                                                                                                    <p className='font-12'>{al.note}</p></>
                                                                                                : (al.note_type == "req_view") ? <>
                                                                                                    <p>{al.note}</p>
                                                                                                    {al.leads_user.map((lead_id, index) => (
                                                                                                        <>
                                                                                                            <a key={index} href={liveUrl + "/leads/" + lead_id} target="_blank">{lead_id}</a><br />
                                                                                                        </>
                                                                                                    ))}
                                                                                                </> : <>{al.note}</>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* NOTES */}
                                                        <div className="tab-pane fade" id="nav-notewrap" role="tabpanel" aria-labelledby="nav-notewrap-tab" tabIndex={0}>
                                                            <div className="timeline-list">
                                                                {this.state.notes.map((note) => {
                                                                    return (
                                                                        <div className="timegroup note">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-pin-angle-fill" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-11 px-0">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter font-bold">
                                                                                                <span className="font-bold">{note.by_user_name}</span>
                                                                                                <span> added a note</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="atime">{note.time}</p>
                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        {note.note}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* CALLS */}
                                                        <div className="tab-pane fade" id="nav-calllist" role="tabpanel" aria-labelledby="nav-calllist-tab" tabIndex={0}>
                                                            <div className="timeline-list">

                                                                {this.state.call_notes.map((call_note) => {
                                                                    return (
                                                                        <div className="timegroup phone">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-telephone-fill" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-11 px-0">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter">
                                                                                                <span className="font-bold">{call_note.by_user_name}</span>
                                                                                                <span> logged a call.</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="atime">{call_note.time}</p>
                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        {call_note.note}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* WHATSAPP */}
                                                        <div className="tab-pane fade" id="nav-wa" role="tabpanel" aria-labelledby="nav-wa-tab" tabIndex={0}>
                                                            <div className="timeline-list">
                                                                {this.state.whatsapp_notes.map((whatsapp_note) => {
                                                                    return (
                                                                        <div className="timegroup whatsapp">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-whatsapp" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-10">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter font-bold">
                                                                                                <span className="font-bold">{whatsapp_note.by_user_name}</span>
                                                                                                <span> sent a whatsapp message.</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="atime">{whatsapp_note.time}</p>
                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        {whatsapp_note.note}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-mail" role="tabpanel" aria-labelledby="nav-mail-tab" tabIndex={0}>
                                                            <div className="timeline-list">
                                                                {this.state.email_notes.map((email_note) => {
                                                                    return (
                                                                        <div className="timegroup email">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-envelope-fill" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-10">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter font-bold">
                                                                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                                                                <span> sent an email.</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="atime">{email_note.time}</p>
                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        {email_note.note}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-offers" role="tabpanel" aria-labelledby="nav-offers-tab" tabIndex={0}>
                                                            <div className="timeline-list">
                                                                {this.state.email_notes.map((email_note) => {
                                                                    return (
                                                                        <div className="timegroup offer">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-tag-fill"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-10">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter font-bold">
                                                                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                                                                <span> added an offer.</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="atime">{email_note.time}</p>
                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        <div className="col-12">
                                                                                            <p className="name font-bold">Goran Buvac </p>
                                                                                            <p className="address">Offer: AED 2,500,000</p>
                                                                                            <p className="price">2023-02-15 11:32:22</p></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-view" role="tabpanel" aria-labelledby="nav-view-tab" tabIndex={0}>
                                                            <div className="timeline-list">
                                                                {this.state.email_notes.map((email_note) => {
                                                                    return (
                                                                        <div className="timegroup viewing">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-eye-fill"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-10">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter font-bold">
                                                                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                                                                <span> added an offer.</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="atime">{email_note.time}</p>
                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        <div className="col-12">
                                                                                            <p className="name font-bold">Goran Buvac </p>
                                                                                            <p className="address">Offer: AED 2,500,000</p>
                                                                                            <p className="price">2023-02-15 11:32:22</p></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-followups" role="tabpanel" aria-labelledby="nav-followups-tab" tabIndex={0}>
                                                            <div className="timeline-list">
                                                                {this.state.email_notes.map((email_note) => {
                                                                    return (
                                                                        <div className="timegroup followup">
                                                                            <div className="col-1">
                                                                                <div className="iconwrap">
                                                                                    <i className="bi bi-calendar"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-10">
                                                                                <div className="col-12 d-flex flex-column">
                                                                                    <div className="agentdetails">
                                                                                        <div className="calldetails">
                                                                                            <p className="awriter font-bold">
                                                                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                                                                <span> scheduled a follow up.</span>
                                                                                            </p>
                                                                                        </div>
                                                                                        <p className="atime">{email_note.time}</p>
                                                                                    </div>
                                                                                    <div className="adesc">
                                                                                        <div className="col-12">
                                                                                            <p className="name font-bold">Reminder Note</p>
                                                                                            <p className="price">2023-02-15 11:32:22</p></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column w-100 bg-white border-top ownersdetails mt-3 accordion accordion-flush">
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header p18pt10" id="flush-headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            Property Details
                                                        </button>
                                                    </h3>
                                                    {/* Property Details */}
                                                    <div id="flush-collapseOne" className="accordion-collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                        <div className={`nav nav-tabs nav-fill  ${window.isIpadView ?  'XScroll' : ''}`} id="prop-tab" role="tablist">
                                                            <button className="nav-link active" id="nav-details-tab" data-bs-toggle="tab" data-bs-target="#nav-details" type="button" role="tab" aria-controls="nav-details" aria-selected="true">Details</button>
                                                            <button className="nav-link" id="nav-forwebsite-tab" data-bs-toggle="tab" data-bs-target="#nav-forwebsite" type="button" role="tab" aria-controls="nav-forwebsite" aria-selected="false">For website</button>
                                                            <button className="nav-link" id="nav-documents-tab" data-bs-toggle="tab" data-bs-target="#nav-documents" type="button" role="tab" aria-controls="nav-documents" aria-selected="false">Documents</button>
                                                            <button className="nav-link" id="nav-portal-tab" data-bs-toggle="tab" data-bs-target="#nav-portal" type="button" role="tab" aria-controls="nav-portal" aria-selected="false">Portal</button>
                                                            <button className="nav-link" id="nav-images-tab" data-bs-toggle="tab" data-bs-target="#nav-images" type="button" role="tab" aria-controls="nav-images" aria-selected="false">Images</button>
                                                            <button className="nav-link" id="nav-international-tab" data-bs-toggle="tab" data-bs-target="#nav-international" type="button" role="tab" aria-controls="nav-international" aria-selected="false">International</button>
                                                        </div>
                                                        <div className="tab-content" id="nav-tabContent">
                                                            {/* DETAILS */}
                                                            <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab" tabIndex={0}>
                                                                <div className="row">
                                                                    <div className="mb-3 col-6">
                                                                        <div className="input-group-text">
                                                                            <input className="form-check-input mt-0" type="checkbox" checked={this.state.lead_gen} aria-label="Checkbox for following text input" id="leadgen" onChange={this.onLeadGen} />
                                                                            <label className="control-labels" htmlFor="leadgen">Lead Gen</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3 col-6">
                                                                        <div className="input-group-text">
                                                                            <input className="form-check-input mt-0" type="checkbox" checked={this.state.just_listed} aria-label="Checkbox for following text input" id="justlisted" onChange={this.onJustListed} />
                                                                            <label className="control-labels" htmlFor="justlisted">Just Listed</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    {role == 1 ? <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group d-flex flex-column">
                                                                        <label className="control-labels">Agent Name</label>
                                                                        <select onChange={this.handleAgentDetails} value={this.state.agent_name_details} name="listing_agent" id="listing_agent" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                                                            <option value="">Select Agent</option>
                                                                            {this.state.agents.map((agent) => {
                                                                                return (
                                                                                    <option value={agent.value}>{agent.label}</option>
                                                                                )
                                                                            })}


                                                                        </select>
                                                                    </div> : null}

                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group mb-0">
                                                                        <label className="control-labels">Listing Status</label>
                                                                        {(this.state.role_name == "Super Admin" || this.state.role_name == "Admin") && (
                                                                            <select onChange={this.handleListingStatusDetails} value={this.state.listing_status_details} name="listingstatus">
                                                                                <option value="U">Action</option>
                                                                                <option value="P">Rera Permit & Portal Info</option>
                                                                                <option value="SEO">Proofreading / SEO</option>
                                                                                <option value="PA">Publish / Feeds</option>
                                                                            </select>
                                                                        )}
                                                                        {(this.state.role_name != "Super Admin" && this.state.role_name != "Admin") && (
                                                                            <select onChange={this.handleListingStatusDetails} value={this.state.listing_status_details} name="listingstatus" disabled>
                                                                                <option value="U">Action</option>
                                                                                <option value="P">Rera Permit & Portal Info</option>
                                                                                <option value="SEO">Proofreading / SEO</option>
                                                                                <option value="PA">Publish / Feeds</option>
                                                                            </select>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Property For</label>
                                                                        <select onChange={this.handlePropertyForDetails} value={this.state.property_for_details} name="propertyfor">
                                                                            <option value="">Select Property For</option>
                                                                            <option value="sale">Sale</option>
                                                                            <option value="rent">Rent</option>
                                                                            <option value="short-term">Short term</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Completion Status</label>
                                                                        <select onChange={this.handleCompletionDetails} name="completion_status" value={this.state.completion_status}>
                                                                            <option value="">Select Completion Status</option>
                                                                            <option value="Yes">Completed</option>
                                                                            <option value="Offplan">Offplan</option>
                                                                            <option value="Offplan_development">Offplan Development</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {(this.state.property_for_details == "rent" || this.state.property_for_details == "short-term") ?
                                                                    <div className='row'>
                                                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 form-group'>
                                                                            <label>Available Date</label>

                                                                            <DatePicker onChange={this.handleAvailableDate} format="YYYY-MM-DD" value={(this.state.avail_date) ? dayjs(this.state.avail_date) : ""} onOk={this.handleAvailableDate} style={{ height: 36, overflow: "hidden", fontSize: 12 }} />
                                                                        </div>
                                                                    </div> : null}
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Property Type</label>
                                                                        <select onChange={this.handlePropertyTypeDetails} value={this.state.property_type_details} name="propertyfor">
                                                                            <option value="">Select</option>
                                                                            {this.state.propertytypes.map((propertytype) => {
                                                                                return <option selected={propertytype.value === this.state.listing_details.property_category_id} value={propertytype.value}>{propertytype.label}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Style of Property</label>
                                                                        <select onChange={this.handlePropertyStyleDetails} name="style_of_property" value={this.state.style_property_details}>
                                                                            <option value="">Select</option>
                                                                            <option value="modern">Modern</option>
                                                                            <option value="mediterranean">Mediterranean</option>
                                                                            <option value="spanish">Spanish</option>
                                                                            <option value="contemporary">Contemporary</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Unit No</label>
                                                                        <input onChange={this.handleUnitNoDetails} type="text" placeholder="Unit no" value={this.state.unit_no_details} />
                                                                    </div>

                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Community</label>
                                                                        <select onChange={this.handleCommunityDetails} value={this.state.community_details} name="community">
                                                                            <option>Choose Community</option>
                                                                            {this.state.communities.map((community) => {
                                                                                return <option value={community.value}>{community.label}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Sub Community</label>
                                                                        <select onChange={this.handleSubCommunityDetails} value={this.state.listing_details.project} name="community">
                                                                            <option>Choose Sub Community</option>
                                                                            {this.state.sub_communities.map((subcommunity) => {
                                                                                return <option value={subcommunity.value}>{subcommunity.label}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Property</label>
                                                                        <select onChange={this.handlePropertyDetails} name="community" value={this.state.property_details}>
                                                                            <option>Select</option>
                                                                            {this.state.properties.map((property) => {
                                                                                return <option
                                                                                    value={property.value}>{property.label}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Bedroom</label>
                                                                        <select onChange={this.handleBedDetails} value={this.state.bedroom_details} name="bedroom">
                                                                            <option>Select Bedroom</option>
                                                                            {this.state.bedrooms.map((bedroom) => {
                                                                                return <option value={bedroom.id}>{bedroom.bedroom}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Bathroom</label>
                                                                        <select onChange={this.handleaBathroomDetails} value={this.state.bathroom_details} name="bathroom">
                                                                            <option>Select Bathroom</option>
                                                                            {this.state.bathrooms.map((bathroom) => {
                                                                                return <option value={bathroom.id}>{bathroom.bathroom}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6 price">
                                                                        <label className="control-labels">BUA (Sq.Ft)</label>
                                                                        <input onChange={this.handleBua} type="text" name="price" id="property_price" value={this.state.bua} className="form-control" placeholder="BUA" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Plot Size (Sq.Ft)</label>
                                                                        <input onChange={this.handlePlotArea} value={this.state.plot_area} type="text" placeholder="Plot Size" />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6 price">
                                                                        <label className="control-labels">Price<em>*</em></label>
                                                                        <input onChange={this.handlePriceDetails} type="text" name="price" id="property_price" value={this.state.price_details} className="form-control" placeholder="Price" />
                                                                        <button className="btn btn-primary ml-auto requestchange mt-2 font-14">Request Price Change</button>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">View</label>
                                                                        <input onChange={this.handleViewDetails} value={this.state.view_details} type="text" />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Upgraded</label>
                                                                        <select onChange={this.handleUpgradedDetails} name="upgraded" value={this.state.upgraded_details}>
                                                                            <option value="">Select</option>
                                                                            <option value="1">Yes</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Furnished</label>
                                                                        <select onChange={this.handleFurnishedDetails} name="furnished" value={this.state.furnished_details}>
                                                                            <option value="1">Yes</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Vacant</label>
                                                                        <select onChange={this.handleVacantDetails} name="vacant" value={this.state.vacant_details}>
                                                                            <option value="">Select</option>
                                                                            <option value="1">Yes</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Maids Room</label>
                                                                        <select onChange={this.handleMaidsDetails} name="maids_room" value={this.state.maids_room}>
                                                                            <option value="">Select</option>
                                                                            <option value="1">Yes</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Rera Permit</label>
                                                                        <input onChange={this.handleReraPermit} value={this.state.rera_permit_details} type="text" placeholder="Enter Rera Permit" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Tags</label>
                                                                        <textarea style={{ border: '1px solid gainsboro' }} onChange={this.handleTagsDetails} rows={3} defaultValue={""} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 form-group">
                                                                        <div className='form-group'>
                                                                            <label>Title</label>
                                                                            <input type="text" className="form-control" placeholder="Enter Property Title"
                                                                                onChange={this.handleTitleDetails} value={this.state.title_details} />
                                                                            <p style={{ fontSize: '10px' }}>{(this.state.title_details == "" || this.state.title_details == null) ? "0" : this.state.title_details.length} characters</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 form-group">
                                                                        <div className="d-flex flex-row justify-content-between">
                                                                            <label className="control-labels">Description</label>
                                                                            <button type="button" className="btn btn-primary btn-sm mb-1" disabled={(this.state.description_loader)} onClick={this.generateDescription}>{(this.state.description_loader) ? <Spin style={{ alignSelf: 'center' }} /> : "Generate Description"}</button>
                                                                        </div>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            data={(this.state.description_details != null) ? this.state.description_details : ""}
                                                                            onChange={(event, editor) => {
                                                                                this.setState({ description_details: (editor) ? editor.getData() : "" })
                                                                            }}
                                                                        />
                                                                        <p style={{ fontSize: '10px' }}>{(this.state.description_details == "" || this.state.description_details == null) ? "0" : this.state.description_details.length} characters</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row savegroup">
                                                                    <div className="col-12 d-flex flex-row justify-content-between">

                                                                        <button onClick={this.handleSetailsSubmit} className="btn btn-primary px-3 ml-auto">Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="nav-forwebsite" role="tabpanel" aria-labelledby="nav-forwebsite-tab" tabIndex={0}>
                                                                {/* SEO Details */}
                                                                <div className="row seogroup">
                                                                    <label className="mb-2">SEO Details</label>
                                                                    <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                                                                        <div className="form-group">
                                                                            <label className="control-labels">Meta Title</label>
                                                                            <input type="text" placeholder="Meta Title" onChange={this.handleMetaTitle} value={this.state.meta_title} />
                                                                            <p style={{ fontSize: '10px' }}>{(this.state.meta_title == "" || this.state.meta_title == null) ? "0" : this.state.meta_title.length} characters</p>
                                                                            <div
                                                                                className="text-danger">{this.state.websitevalidationerrors.meta_title}</div>
                                                                        </div>
                                                                        <div className="form-group mb-0">
                                                                            <label className="control-labels">Meta Keywords</label>
                                                                            <input type="text" placeholder="Meta Keywords" onChange={this.handleMetaKeywords} value={this.state.meta_keywords} />
                                                                            <p style={{ fontSize: '10px' }}>{(this.state.meta_keywords == "" || this.state.meta_keywords == null) ? "0" : this.state.meta_keywords.length} characters</p>
                                                                            <div
                                                                                className="text-danger">{this.state.websitevalidationerrors.meta_keywords}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Meta Description</label>
                                                                        <textarea style={{ border: '1px solid gainsboro' }} id="metadesc" className="h-100" rowSpan={4} onChange={this.handleMetaDescription} value={this.state.meta_description} />
                                                                        <p style={{ fontSize: '10px' }}>{(this.state.meta_description == "" || this.state.meta_description == null) ? "0" : this.state.meta_description.length} characters</p>
                                                                        <div
                                                                            className="text-danger">{this.state.websitevalidationerrors.meta_description}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Youtube Video Link</label>
                                                                        <input type="text" placeholder="youtube" onChange={this.handleVideoLink} value={this.state.video_link} />
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Livetour 360 Video Link</label>
                                                                        <input type="text" placeholder="livetour" onChange={this.handleLiveTourLink} value={this.state.livetour_link} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className=" col-12 col-sm-12 col-md-6 col-lg-6 mb-3 form-group uploadgrp">
                                                                        <label className="control-labels">Brochure</label>
                                                                        <input type="file" className="form-control w-100" id="inputGroupFile02" />
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Facilites &amp; Amenities (Maximum of 10)</label>
                                                                        <button onClick={this.handleOpenAmenities} type="button" className="btn btn-primary w-100">Choose Amenities</button>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Curations</label>
                                                                        <Select
                                                                            mode="multiple"
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            value={this.state.curation_details}
                                                                            placeholder="Choose Curations"
                                                                            maxTagCount={"responsive"}
                                                                            onChange={this.handleCurationDetails}
                                                                            optionFilterProp="children"
                                                                        >
                                                                            {this.state.curation.map(item => (
                                                                                <Option key={item.id} value={item.id}>{item.title}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                                                                        <label className="control-labels">Life Style</label>
                                                                        <select onChange={this.handleLifeStyleDetails} value={this.state.lifestyle_details} name="life_style">
                                                                            <option>Choose Life Style</option>
                                                                            {this.state.life_style.map((lifestyle) => {
                                                                                return <option value={lifestyle.id}>{lifestyle.title}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <button onClick={this.handleForWebsiteSubmit} className="btn btn-primary" id="saveForWebsiteCheck">Save</button>
                                                            </div>
                                                            <div className="tab-pane fade" id="nav-documents" role="tabpanel" aria-labelledby="nav-documents-tab" tabIndex={0}>
                                                                <div className="row">
                                                                    <div className="form-group col-12">
                                                                        <label className="control-labels">Notes</label>
                                                                        <div className="d-flex flex-row" style={{ gap: '0.3rem' }}>
                                                                            <textarea onChange={this.handlePropertyNotes} name="notes[]" className="form-control notes" placeholder="Notes" rowSpan={1} value={this.state.property_note} />
                                                                            <a onClick={this.handlePropertyNotesSubmit} className="btn btn-success btnnotes" id="addobj1" href="javascript:void(0)"><i className="bi bi-plus" /> </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-12'>
                                                                        <table className='table table-bordered'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Note</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.document_notes.map((document_note) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td> {document_note.note}</td>
                                                                                            <td> {document_note.added_date}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group col-12">
                                                                        <label className="control-labels">Documents</label>
                                                                        <table className="table border-wrap">
                                                                            <thead>
                                                                                <tr><th>Title</th>
                                                                                    <th>File</th>
                                                                                    <th>Action</th>
                                                                                </tr></thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><input onChange={this.handleDocumentTitle} type="text" tabIndex={2} name="doctitle" placeholder="Title" className="form-control input-sm" id="doctitle" /></td>
                                                                                    <td><input onChange={this.handleDocumentFile} type="file" tabIndex={2} name="documents" className="form-control input-sm" accept="image/gif, image/jpeg,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /></td>
                                                                                    <td><button onClick={this.handleDocumentsSubmit} className="btn btn-success" type="submit" href="javascript:void(0)" data-href><i className="bi bi-plus" /></button></td>
                                                                                </tr>
                                                                                {this.state.documents && this.state.documents.map((document) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td> {document.title}</td>
                                                                                            <td>  <a href={(document.document) ? document.document : "#"} target="_blank">
                                                                                                <button className="btn btn-text p-0">
                                                                                                    <i className="bi bi-eye-fill" />
                                                                                                </button>
                                                                                            </a> </td>
                                                                                            <td>
                                                                                                <button onClick={this.handleDocumentDelete.bind(this, document)} className="btn btn-text p-0"><i className="bi bi-trash-fill" /></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="tab-pane fade portal" id="nav-portal" role="tabpanel"
                                                                aria-labelledby="nav-portal-tab" tabIndex={0}>
                                                                <div className="row">
                                                                    {this.state.portals && this.state.portals.map((portal, index) => {
                                                                        if (this.state.selected_portals_id && this.state.selected_portals_id.includes(portal.id)) {
                                                                            var inputProps = {
                                                                                defaultChecked: 'true'
                                                                            };
                                                                        } else {
                                                                            var inputProps = {};
                                                                        }
                                                                        return (
                                                                            <div className={`${window.isTabView? '' : 'input-group'} col-sm-12 col-md-6 col-lg-4`}>
                                                                                <div className="input-group-text">
                                                                                    <input className="form-check-input mt-0"
                                                                                        type="checkbox"
                                                                                        defaultValue
                                                                                        onChange={this.onChangePortal.bind(this, portal)}
                                                                                        aria-label="Checkbox for following text input"
                                                                                        id={portal.feedkey}
                                                                                        {...inputProps}
                                                                                    />
                                                                                    <label className="control-labels"
                                                                                        htmlFor="leadgen">{portal.title}</label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                                <div className={`${window.isTabView? '' : 'input-group'} col-sm-12 col-md-6 col-lg-4`}>
                                                                    <div className="input-group-text">

                                                                        <input className="form-check-input mt-0"
                                                                            type="checkbox"
                                                                            defaultChecked={this.state.isSameValueCheck}
                                                                            onChange={this.onChangeSameValue.bind(this, this.state.selected_portals)}
                                                                            aria-label="Checkbox for using same value"
                                                                            id="same_value"
                                                                        />
                                                                        <label className="control-labels"
                                                                            htmlFor="leadgen">Use same value</label>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-3">
                                                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                                        {!this.state.isSameValueCheck && <Tabs>
                                                                            <TabList>
                                                                                {this.state.selected_portals && this.state.selected_portals.map((selected_portal, index) => {
                                                                                    return (
                                                                                        <Tab>{selected_portal.portal_title}</Tab>
                                                                                    )
                                                                                })}
                                                                            </TabList>


                                                                            {this.state.selected_portals && this.state.selected_portals.map((selected_portal, index) => {
                                                                                return (
                                                                                    <TabPanel>
                                                                                        <div className="tab-content p-3"
                                                                                            id="nav-tabContent">
                                                                                            <div className="form-group mb-0">
                                                                                                <div className="d-flex flex-column">
                                                                                                    <label
                                                                                                        className="control-labels">Title</label>
                                                                                                    <input type="text"
                                                                                                        className="p-1"
                                                                                                        value={selected_portal.property_title}
                                                                                                        disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                                                                        placeholder="Add title"
                                                                                                        onChange={this.onChangeTitle.bind(this, selected_portal)}
                                                                                                    />
                                                                                                    <p style={{ fontSize: '10px' }}>{(selected_portal.property_title == "" || selected_portal.property_title == null) ? "0" : selected_portal.property_title.length} characters</p>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="form-group mb-0">
                                                                                                <div className="d-flex flex-column">
                                                                                                    <label
                                                                                                        className="control-labels">Description</label>
                                                                                                    <CKEditor
                                                                                                        editor={ClassicEditor}
                                                                                                        disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                                                                        data={(selected_portal != null) ? selected_portal.property_desc : ""}
                                                                                                        onChange={(event, editor) => {
                                                                                                            this.state.selected_portals.filter((portal) => {
                                                                                                                if (portal.portals_id === selected_portal.portals_id) {
                                                                                                                    this.setState({ selected_portal: portal })
                                                                                                                    portal.property_desc = (editor) ? editor.getData() : "";
                                                                                                                }
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                    <p style={{ fontSize: '10px' }}>{(selected_portal.property_desc == "" || selected_portal.property_desc == null) ? "0" : selected_portal.property_desc.length} characters</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </TabPanel>
                                                                                )
                                                                            })}
                                                                        </Tabs>}
                                                                        {this.state.isSameValueCheck && <Tabs>
                                                                            {this.state.selected_portals &&
                                                                                <TabPanel>
                                                                                    <div className="tab-content p-3"
                                                                                        id="nav-tabContent">
                                                                                        <div className="form-group mb-0">
                                                                                            <div className="d-flex flex-column">
                                                                                                <label
                                                                                                    className="control-labels">Title</label>
                                                                                                <input type="text"
                                                                                                    className="p-1"
                                                                                                    value={this.state.selected_portals[0].property_title}
                                                                                                    disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                                                                    placeholder="Add title"
                                                                                                    onChange={this.onChangeTitle.bind(this, this.state.selected_portals[0])}
                                                                                                />
                                                                                                <p style={{ fontSize: '10px' }}>{(this.state.selected_portals[0].property_title == "" || this.state.selected_portals[0].property_title == null) ? "0" : this.state.selected_portals[0].property_title.length} characters</p>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-0">
                                                                                            <div className="d-flex flex-column">
                                                                                                <label
                                                                                                    className="control-labels">Description</label>
                                                                                                <CKEditor
                                                                                                    editor={ClassicEditor}
                                                                                                    disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                                                                    data={(this.state.selected_portals[0] != null) ? this.state.selected_portals[0].property_desc : ""}
                                                                                                    onChange={(event, editor) => {
                                                                                                        this.state.selected_portals.filter((portal) => {
                                                                                                            if (portal.portals_id === this.state.selected_portals[0].portals_id) {
                                                                                                                this.setState({ selected_portal: portal })
                                                                                                                portal.property_desc = (editor) ? editor.getData() : "";
                                                                                                            }
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                                <p style={{ fontSize: '10px' }}>{(this.state.selected_portals[0].property_desc == "" || this.state.selected_portals[0].property_desc == null) ? "0" : this.state.selected_portals[0].property_desc.length} characters</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </TabPanel>
                                                                            }
                                                                        </Tabs>}

                                                                    </div>
                                                                    <button id="calllogbtn" type="button"
                                                                        onClick={this.onSubmit.bind(this, this.state)}
                                                                        className="btn btn-primary requestchange mt-2">Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade images" id="nav-images" role="tabpanel" aria-labelledby="nav-images-tab" tabIndex={0}>
                                                                <div className="row">
                                                                    <div className="d-flex flex-row justify-content-between btngrp">
                                                                        <div className="d-flex flex-row">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row upload-group" style={{ marginTop: 15 }}>
                                                                    <ReactDropzoneUploader
                                                                        getUploadParams={this.getUploadParams}
                                                                        onChangeStatus={this.handleChangeStatus}
                                                                        onSubmit={this.handleUploadImages}
                                                                        styles={styles}
                                                                        height={200}
                                                                    />
                                                                </div>
                                                                <div className="row imgupgrp" >

                                                                  

                                                                        <SortableContainer
                                                                            updateImages={this.updateImages}
                                                                            onUpdate={this.handleSortUpdate}
                                                                            property_images={this.state.property_images}
                                                                        />
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade international" id="nav-international" role="tabpanel" aria-labelledby="nav-international-tab" tabIndex={0}>
                                                                <div className="row">
                                                                    <div className="col-12 form-group d-flex flex-column">
                                                                        <label className="control-labels">International</label>
                                                                        <select name="international" id="international" className="form-control" value={this.state.international} onChange={this.onChangeInternational}>
                                                                            <option value="1">Yes</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group d-flex flex-column">
                                                                        <label className="control-labels">Country</label>
                                                                        <select onChange={this.onChangeCountry} value={this.state.country} name="country">
                                                                            {this.state.countries.map((country) => {
                                                                                return <option value={country.id}>{country.name}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group d-flex flex-column">
                                                                        <label className="control-labels">City</label>
                                                                        <select onChange={this.onChangeCity} value={this.state.city} name="city">
                                                                            {this.state.cities.map((city) => {
                                                                                return <option value={city.id}>{city.name}</option>
                                                                            })}
                                                                        </select>
                                                                        {/*<select name="intlcity" id="intlcity" className="form-control">*/}
                                                                        {/*    <option selected>Dubai</option>*/}
                                                                        {/*    <option>Abu Dhabi</option>*/}
                                                                        {/*    <option>Ras Al Khaimah</option>*/}
                                                                        {/*</select>*/}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <button className="btn btn-primary ml-auto" onClick={this.onSaveInternational}>Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12  col-sm-12 col-md-12 col-lg-3">
                                            <div className={`${window.isTabView ? 'px-0 py-2' : 'p-4'} d-flex flex-column w-100 bg-white border-top ownersdetails`}>
                                                <h3 className='p18pt10'>Match Details</h3>
                                                <div className="d-flex flex-column pt-3">
                                                    <div className="d-flex flex-column matchfilter">
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="community" className="mb-0">Community:</label>
                                                                <Select id="community"
                                                                    style={{
                                                                        width: 160,
                                                                    }}

                                                                    showSearch
                                                                    placeholder="Select a person"
                                                                    optionFilterProp="children"
                                                                    onChange={this.handleCommunityMatch}
                                                                    // onSearch={onSearch}
                                                                    filterOption={(input, option) =>
                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    options={this.state.communities}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub-Community:</label>
                                                                <Select id="propsubcommunity"
                                                                    style={{
                                                                        width: 160,
                                                                    }}
                                                                    // mode={"multiple"}

                                                                    showSearch
                                                                    maxTagCount={"responsive"}
                                                                    placeholder="Select a person"
                                                                    optionFilterProp="children"
                                                                    onChange={this.handleSubCommunityMatch}
                                                                    // onSearch={onSearch}
                                                                    filterOption={(input, option) =>
                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    options={this.state.sub_communities}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row budget">
                                                                <label for="matchingBudgetMin">Budget:</label>
                                                                <div className="d-flex flex-row">

                                                                    <CurrencyInput
                                                                        id="input-min"
                                                                        name="input-min"
                                                                        placeholder="Min"

                                                                        decimalsLimit={2}
                                                                        onChange={this.handleMinBudget}
                                                                    />
                                                                    {/* <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" /> */}
                                                                    <span className="dash" style={{}}>-</span>
                                                                    <CurrencyInput
                                                                        id="input-max"
                                                                        name="input-max"
                                                                        placeholder="Max"

                                                                        decimalsLimit={2}
                                                                        onChange={this.handleMaxBudget}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <label for="matchingBedrooms">Beds:</label>
                                                                <select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value="Studio">Studio</option>
                                                                    <option value={1}>1</option>
                                                                    <option value={2}>2</option>
                                                                    <option value={3} >3</option>
                                                                    <option value={4}>4</option>
                                                                    <option value={5}>5</option>
                                                                    <option value={6}>6</option>
                                                                    <option value={7}>7</option>
                                                                    <option value={8}>8</option>
                                                                    <option value={9}>9</option>
                                                                    <option value={10}>10</option>
                                                                    <option value={11}>11</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={13}>13</option>
                                                                    <option value={14}>14</option>
                                                                    <option value={15}>15</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row w-100">
                                                                <label for="addedDateM">Added Date:</label>
                                                                <select onChange={this.handleAddedDateMatch} id="addedDateM" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value={1}>Last 24 Hours</option>
                                                                    <option value={3}>Last 3 Days</option>
                                                                    <option value={7}>Last 7 Days</option>
                                                                    <option value={14}>Last 14 Days</option>
                                                                    <option value={30}>Last 1 Month</option>
                                                                    <option value={90}>Last 3 Month</option>
                                                                    <option value={180}>Last 6 Month</option>
                                                                    <option value={365}>Last 1 Year</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row">
                                                                <label for="matchingPfor">Property For:</label>
                                                                <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1}>
                                                                    <option value>Select</option>
                                                                    <option value="rent">Rent</option>
                                                                    <option value="sale">Sale</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="form-group d-flex flex-row">
                                                                <label for="matchingType">Type:</label>
                                                                <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                                                                    <option>select</option>
                                                                    {this.state.propertytypes.map((propertytype) => {
                                                                        return <option value={propertytype.value}>{propertytype.label}</option>
                                                                    })}


                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                                                        </div>

                                                    </div>
                                                    <div className="matchresults">
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <label className="control-labels mr-4">Match with access</label>
                                                        </div>
                                                        <div className="matchresult">

                                                            {this.state.matchs.map((match) => {
                                                                const match_price = match.price
                                                                return (
                                                                    <a href className="matchitem">
                                                                        <div className="matchinfo">
                                                                            <div className="col-9 px-0">
                                                                                <p className="name">{match.owner}</p>
                                                                                <p className="address">{match.address}</p>
                                                                                <p className="price">{match_price > 0 ? match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : null}</p>
                                                                            </div>
                                                                            <div className="col-3 px-0">
                                                                                <p className="addeddate">
                                                                                    <Moment fromNow>
                                                                                        {match.added_date}
                                                                                    </Moment>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="matchhistory">
                                                                            <span className="time"></span>
                                                                            <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                                                        </div>
                                                                    </a>
                                                                )
                                                            })}


                                                            {this.state.matchs.length > 0 ? <Link onClick={this.handleAllMatch} className="matchitem">

                                                                View All

                                                            </Link> : null}
                                                        </div>
                                                    </div>
                                                    <div className="matchresults">
                                                        <label className="control-labels mr-4">Match with restricted access</label>
                                                        <div className="matchresult">
                                                            {this.state.not_matchs.map((not_match) => {
                                                                const not_match_price = not_match.price
                                                                return (
                                                                    <a href className="matchitem">
                                                                        <div className="matchinfo">
                                                                            <div className="col-9 px-0">
                                                                                <p className="name">{not_match.owner}</p>
                                                                                <p className="address">{not_match.address}</p>
                                                                                <p className="price">{not_match_price > 0 ? not_match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : null}</p>
                                                                            </div>
                                                                            <div className="col-3 px-0">
                                                                                <p className="addeddate">
                                                                                    <Moment fromNow>
                                                                                        {not_match.added_date}
                                                                                    </Moment>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="matchhistory">
                                                                            <span className="time"></span>
                                                                            <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                                                        </div>
                                                                    </a>
                                                                )
                                                            })}

                                                            {this.state.not_matchs.length > 0 ? <Link onClick={this.handleAllNotMatch} className="matchitem">

                                                                View All

                                                            </Link> : null}



                                                        </div>
                                                    </div>
                                                </div>
                                            </div> {/* END Match*/}
                                            {/* OFFERS */}
                                            <div className={`${window.isTabView ? 'px-0 py-2' : 'p-4'} bg-white border-top offers mt-4`}>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4 p18pt10">Offers</h3>

                                                </div>
                                                <div className="d-flex flex-column border-wrap">

                                                    {this.state.listing_offers.map((listing_offer) => (
                                                        <a onClick={this.handleShowOffer.bind(this, listing_offer.views_id)} className="matchitem">
                                                            <div className="matchinfo">
                                                                <div className="col-9 px-0">
                                                                    <p className="name">{listing_offer.fullname}</p>
                                                                    <p className="address">Offer: AED {listing_offer.price}</p>
                                                                    <p className="price">{listing_offer.view_date}</p>
                                                                </div>
                                                                <div className="col-3 px-0">
                                                                    <button type="button" className="btn btn-link addfeedback">View feedback</button>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    ))}


                                                    {this.state.listing_offers.length > 0 ? <Link to={"/all_offers/" + this.state.record_id} className="btn btn-link viewall">View All</Link>
                                                        : null}

                                                </div>
                                            </div> {/* END OFFERS */}
                                            {/* VIEWINGS */}
                                            <div className={`${window.isTabView ? 'px-0 py-2' : 'p-4'} bg-white border-top viewings mt-4`}>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4 p18pt10">Viewings</h3>
                                                    <Link onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</Link>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">
                                                    {this.state.property_viewings.map((property_viewing) => {
                                                        return (
                                                            <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9 px-0">
                                                                        <p className="name">{property_viewing.fullname}</p>
                                                                        <p className="address">Offer: AED {property_viewing.price}</p>
                                                                        <p className="price">{property_viewing.view_date}</p>
                                                                    </div>
                                                                    <div className="col-3 px-0">
                                                                        <button type="button" className="btn btn-link addfeedback">Add feedback</button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_viewings.length > 0 ? <Link to={"/all_viewings/" + this.state.record_id} className="btn btn-link viewall">View All</Link>
                                                        : null}

                                                    {/* {this.state.property_viewings.length > 0?

                      <a href className="matchitem viewall">
                        View All
                      </a>:null
                      } */}

                                                </div>
                                            </div> {/* END VIEWINGS*/}

                                            {/* Followup */}
                                            <div className={`${window.isTabView ? 'px-0 py-2' : 'p-4'} bg-white border-top viewings mt-4`}>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <h3 className="mr-4 p18pt10">Follow Up</h3>
                                                    <a onClick={this.handleNewFollowUp} className="btn btn-link viewall">Add Followup</a>
                                                </div>
                                                <div className="d-flex flex-column border-wrap">
                                                    {this.state.followups_details.map((followup) => {
                                                        return (
                                                            <a onClick={this.handleViewFollowup.bind(this, followup.id)} className="matchitem">
                                                                <div className="matchinfo">
                                                                    <div className="col-9">
                                                                        {/*<p className="price">{followups.first_name}</p>*/}
                                                                        <p className="price">{(followup.type === 1) ? "Vendor" : "Client"}</p>
                                                                        <p className="price">{(followup.status === 1) ? "Active" : "Inactive"}</p>
                                                                        <p className="address">
                                                                            {followup.notes && followup.notes.length > 40 ?
                                                                                `${followup.notes.substring(0, 40)}...` : followup.notes
                                                                            }
                                                                        </p>
                                                                        <p className="price">{followup.date}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button type="button" className="btn btn-link addfeedback"></button>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                    {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                                                        View All
                                                    </a> : null}

                                                </div>
                                            </div> {/* END Followup*/}



                                        </div>
                                    </div>
                                </>
                        }
                    </div>


                </Drawer>

                <div className='contentwrap my-3 listingprocess-wrap'>
                    <Row gutter={16} className="contentwrap p-4">
                        <Col span={12}>
                            <h1 className='font-bold text-uppercase my-2 fs-3'>Pending Listing</h1>
                        </Col>
                        <Col className='d-flex flex-row justify-content-between align-items-center ml-auto' span={10} style={{ gap: '0.8rem' }}>
                            {/* <RangePicker format="YYYY-MM-DD" style={{height: '36px'}} onChange={this.handleDateRange}/>
                            <Button type="primary" onClick={this.handlecontract}><PlusOutlined /> Create New Contract</Button> */}
                        </Col>
                    </Row>
                    <Row className="contentwrap p-4 pt-0 mouwrap">
                        <Col span={24}>
                            <div className='tcontractwrap'>
                                <Tabs onSelect={this.fetchData}>
                                    <TabList>
                                        {/* Filters for process */}
                                        <Tab>Action</Tab>
                                        <Tab>Rera Permit & Portal Info</Tab>
                                        <Tab>Proofreading & SEO</Tab>
                                        <Tab>Ready to Publish</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <Table className={`${window.isTabView? 'TableborderNoneMain' : 'border'}`}
                                            columns={window.isTabView ? allListingColumns : columns}
                                            loading={this.state.isLoading}
                                            dataSource={
                                                this.state?.listing_process?.data
                                                    ? this.state?.listing_process?.data
                                                    : []
                                            }
                                            pagination={{
                                                hideOnSinglePage: true,
                                            }}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <Table className={`${window.isTabView? 'TableborderNoneMain' : 'border'}`}
                                            columns={window.isTabView ? allListingColumns : columns}
                                            loading={this.state.isLoading}
                                            dataSource={
                                                this.state?.listing_process?.data
                                                    ? this.state?.listing_process?.data
                                                    : []
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <Table className={`${window.isTabView? 'TableborderNoneMain' : 'border'}`}
                                            columns={window.isTabView ? allListingColumns : columns}
                                            loading={this.state.isLoading}
                                            dataSource={
                                                this.state?.listing_process?.data
                                                    ? this.state?.listing_process?.data
                                                    : []
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <Table className={`${window.isTabView? 'TableborderNoneMain' : 'border'}`}
                                            columns={window.isTabView ? allListingColumns : columns}
                                            loading={this.state.isLoading}
                                            dataSource={
                                                this.state?.listing_process?.data
                                                    ? this.state?.listing_process?.data
                                                    : []
                                            }
                                        />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default ListingProcess;
