import {combineReducers} from 'redux'
import Access_Reducer from './Access_Reducer'
import AuthReducer from './AuthReducer'
import Expiring_Lead_Reducer from './Expiring_Lead_Reducer'
import Expiring_Listing_Reducer from './Expiring_Listing_Reducer'
import Not_Match_Listing_Reducer from './Not_Match_Listing_Reducer'
import Pledge_Statistics_Reducer from './Pledge_Statistics_Reducer'
import Restrict_Access_Reducer from './Restrict_Access_Reducer'
import Stock_Reducer from './Stock_Reducer'
import Tenancy_Contract_Reducer from './Tenancy_Contract_Reducer'
import Stock_Area_Reducer from './Stock_Area_Reducer'
import NavigationReducer from './NavigationReducer'

const rootReducer = combineReducers({
    auth:AuthReducer,
    not_matched_listing:Not_Match_Listing_Reducer,
    restricted_lead:Restrict_Access_Reducer,
    access_lead:Access_Reducer,
    tenancy_contract_reducer:Tenancy_Contract_Reducer,
    pledge_statistics_reducer:Pledge_Statistics_Reducer,
    expiring_lead_reducer:Expiring_Lead_Reducer,
    expiring_listing_reducer:Expiring_Listing_Reducer,
    stock_reducer:Stock_Reducer,
    stock_area_reducer:Stock_Area_Reducer,
    navigation: NavigationReducer
})

export default rootReducer