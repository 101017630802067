import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'antd/dist/reset.css';
import { Table, Tabs, Space, Button, Modal, Select, Drawer, Input, Avatar, Divider, DatePicker } from 'antd'
import { FilterOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined, EditOutlined } from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";

// import './index.css';
import './listing.css'
import "react-toastify/dist/ReactToastify.css";

import Swal from "sweetalert2";
import { baseLpUrlImg } from '../../components/BaseUrl';
import { store } from '../../store';


const { TextArea } = Input;
const { TabPane } = Tabs;
const width = window.screen.width;
let columns = []
let property_type_array = ["Residential", "Commercial"]
let property_for_array = ["sale", "rent", "tenanted", "short-term"]



class Access_Leads extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      rows: [],
      mylistings: [],
      propertytypes: [],
      property_viewings: [],
      property_offers: [],
      followups:[],
      ref_nums: [],
      viewing_ref_no: "",
      viewing_agent: "",
      viewing_date: "",
      viewing_note: "",
      viewing_status: "",
      viewing_price: "",
      showPrice: false,
      showDate: false,
      //followup
      followup_note:"",
      followup_date:"",
      leads: [],
      lead_details: {},
      viewings: {},
      notes: [],
      agents: [],
      call_notes: [],
      whatsapp_notes: [],
      email_notes: [],
      communities: [],
      sub_communities: [],
      filters: [],
      greaterthanprice: false,
      leassthanprice: false,
      inbetweenprice: false,
      lessValue: 0,
      greaterValue: 0,

      community_match: "",
      sub_community_match: "",
      min_budget: 0,
      max_budget: 0,
      bedroom_match: "",
      property_for_match: "",
      property_type_match: "",
      added_date_match:"",
      date_morethan_option_match: true,
      date_lessthan_option_match: false,
      date_value_match: 0,
      date_value_id_match: 1,
      matchs: [],
      not_matchs: [],
      owner_other_properties: [],


      // requirements

      requirement_min_budget: 0,
      requirement_max_budget: 0,
      requirement_lead_stage: "",
      requirement_community: "",
      requirement_sub_community: "",
      requirement_verifyedby: "",
      requirement_agent_id: "",
      requirement_move_in_date: "",
      requirement_bedroom: "",


      // filters option

      // price

      price_first_value_id: 1,
      price_second_value_id: 1,
      price_active_second_option: false,
      price_and_or_option: true,
      price_first_value: "",
      price_second_value: "",
      inrange: false,
      price_min: 0,
      price_max: 0,

      // date

      date_first_value_id: 1,
      date_second_value_id: 1,
      date_active_second_option: false,
      date_and_or_option: true,
      date_first_value: "",
      date_second_value: "",
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: 0,
      date_value_id: 1,

      // add new filter 
      isFilterOpen: false,
      isFilterEditOpen: false,
      filter_id: 0,
      filter_title: "",
      contact_type_id: 0,
      community_value_id: 0,
      sub_community_value_id: 0,
      price_value_id: 0,
      price_value: "",
      bed_rooms_value_id: 1,
      bed_rooms_value: "",
      created_value_id: 0,
      created_value: "",
      stage_value_id: 0,
      stage_value: [],
      contact_types_value: [],
      furnished_types_value: [],

      default_stage_value: [],
      default_contact_types_value: [],
      default_furnished_types_value: [],

      name_value_id: 1,
      name_value: "",

      phone_value_id: 1,
      phone_value: "",

      agent_value_id: 1,
      agent_value: "",

      // drawer for details
      open: false,

      record_id: 0,
      view_id: 0,
      isviewingOpen: false,
      iseditviewingOpen: false,
      isOfferOpen: false,
      isfollowupOpen:false,
      isfollowupViewOpen:false,
      furnished_types: [
        {
          value: '1',
          label: 'Furnished'
        },
        {
          value: '2',
          label: 'Unfurnished'
        }
      ],

      contact_types: [
        {
          value: '1',
          label: 'Tenant'
        },
        {
          value: '2',
          label: 'Buyer'
        },
        {
          value: '3',
          label: 'Landlord'
        },
        {
          value: '4',
          label: 'Seller'
        },
        {
          value: '5',
          label: 'Landlord+Seller'
        },
        {
          value: '6',
          label: 'Agent'
        },
        {
          value: '7',
          label: 'Other'
        },
        {
          value: '8',
          label: 'Portal'
        },
        {
          value: '9',
          label: 'Buyer/Tenant'
        },
        {
          value: '10',
          label: 'Unrecognized'
        }
      ],

      stages: [
        {
          value: '0',
          label: 'Uncontacted',
        },
        {
          value: '1',
          label: 'Working',
        },
        {
          value: '2',
          label: 'Hot',
        },
        {
          value: '3',
          label: 'Follow Up',
        },
        {
          value: '4',
          label: 'Dead',
        },
      ],

      filter_options: [


        {
          value: '1',
          label: 'Contact type',
        },

        {
          value: '2',
          label: 'Community / Sub community',
        },
        {
          value: '3',
          label: 'Price',
        },
        {
          value: '4',
          label: 'Bedrooms',
        },
        {
          value: '5',
          label: 'Created',
        },
        {
          value: '6',
          label: 'Stage',
        },
        {
          value: '7',
          label: 'Furnished  / unfurnished',
        },


      ],
      price_options: [


        {
          value: '1',
          label: 'Equals',
        },
        {
          value: '2',
          label: 'Not equal',
        },
        {
          value: '3',
          label: 'Less than',
        },
        {
          value: '4',
          label: 'Less than or equals',
        },
        {
          value: '5',
          label: 'Greater than',
        },
        {
          value: '6',
          label: 'Greater than or equals',
        },

        {
          value: '7',
          label: 'In range',
        },

      ],

      name_filter_options: [
        {
          value: '1',
          label: 'Contain',
        },
        {
          value: '2',
          label: 'Not contain',
        },
        {
          value: '3',
          label: 'Equals',
        },
        {
          value: '4',
          label: 'Not equal',
        },
        {
          value: '5',
          label: 'Start with',
        },
        {
          value: '6',
          label: 'Ends with',
        },

      ],
      phone_filter_options: [
        {
          value: '1',
          label: 'Contain',
        },
        {
          value: '2',
          label: 'Not contain',
        },
        {
          value: '3',
          label: 'Equals',
        },
        {
          value: '4',
          label: 'Not equal',
        },
        {
          value: '5',
          label: 'Start with',
        },
        {
          value: '6',
          label: 'Ends with',
        },

      ],
      bedroom_filter_options: [
        {
          value: '1',
          label: 'Equals',
        },
        {
          value: '2',
          label: 'Not equal',
        },
        {
          value: '3',
          label: 'Less than',
        },
        {
          value: '4',
          label: 'Greater than',
        },


      ],
      agent_filter_options: [
        {
          value: '1',
          label: 'Contain',
        },
        {
          value: '2',
          label: 'Not contain',
        },
        {
          value: '3',
          label: 'Equals',
        },
        {
          value: '4',
          label: 'Not equal',
        },
        {
          value: '5',
          label: 'Start with',
        },
        {
          value: '6',
          label: 'Ends with',
        },

      ],
      // add notes
      call_log: "",
      note_log: "",
      whatsapp_log: "",
      email_log: ""
    }
    this.textInput = React.createRef();

  }

  componentDidMount() {

    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    var loadScript = function (src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    }
    loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
    const page = 1;
    const perPage = 10;


    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,

      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }

    // console.log("data",data)
    this.fetchFilter();
    this.fetchData(page, perPage, data);

  }



  fetchFilter = () => {
    API.get('/filters')
      .then((response) => {
        if (response.data.success) {
          this.setState({ filters: response.data.filters })
        }
      })
  }

  //  dragProps = {
  //     onDragEnd(fromIndex, toIndex) {
  //        // const columns = this.props.columns;
  //         console.log("col",columns)
  //         const item = columns.splice(fromIndex, 1)[0];
  //         columns.splice(toIndex, 0, item);
  //         //columns.push(columns)

  //     },
  //     nodeSelector: "th"
  // };


  // add new filter

  handleAddRow = (value) => {





    const item = {
      name: "",
      mobile: "",
      id: value

    };

    let filter_options = this.state.filter_options.filter(item => item.value !== value)


    this.setState({
      rows: [...this.state.rows, item],
      filter_options: filter_options
    });
  };


  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };
  handleRemoveSpecificRow = (idx, id) => () => {
    const rows = [...this.state.rows]


    if (id == 1) {
      const item = {
        value: '1',
        label: 'Contact type'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    } else
      if (id == 2) {
        const item = {
          value: '2',
          label: 'Community / Sub community'
        }

        this.setState({
          filter_options: [...this.state.filter_options, item]
        })
      } else
        if (id == 3) {
          const item = {
            value: '3',
            label: 'Price'
          }

          this.setState({
            filter_options: [...this.state.filter_options, item],
            price_first_value_id: 0,
            price_first_value: 0,
            price_min: 0,
            price_max: 0
          })
        } else
          if (id == 4) {
            const item = {
              value: '4',
              label: 'Bedrooms'
            }

            this.setState({
              filter_options: [...this.state.filter_options, item]
            })
          } else
            if (id == 5) {
              const item = {
                value: '5',
                label: 'Created'
              }

              this.setState({
                filter_options: [...this.state.filter_options, item]
              })
            } else
              if (id == 6) {
                const item = {
                  value: '',
                  label: 'Stages'
                }

                this.setState({
                  filter_options: [...this.state.filter_options, item]
                })
              } else
                if (id == 7) {
                  const item = {
                    value: '7',
                    label: 'Furnished / Unfurnished'
                  }

                  this.setState({
                    filter_options: [...this.state.filter_options, item]
                  })
                }

    // if (index !== -1) {
    //   array.splice(index, 1);
    //   this.setState({people: array});
    // }

    rows.splice(idx, 1)

    // if(idx == 2){
    //   this.setState({price_min:0,price_max:0})
    //   const page = 1;
    //   const perPage = 10;


    //   const data  = {
    //     name_value_id:this.state.name_value,
    //     name_value:this.state.name_value,

    //     phone_value_id:this.state.phone_value_id,
    //     phone_value:this.state.phone_value,

    //     bed_rooms_value_id:this.state.bed_rooms_value_id,
    //     bed_rooms_value:this.state.bed_rooms_value,


    //     price_first_value_id:this.state.price_first_value_id,
    //     price_first_value:0,
    //     price_min:0,
    //     price_max:0,

    //     date_first_value_id:this.state.date_first_value_id,
    //     date_and_or_option:this.state.date_and_or_option,
    //     date_second_value_id:this.state.date_second_value_id,
    //     date_first_value:this.state.date_first_value,
    //     date_second_value:this.state.date_second_value,

    //     date_morethan_option:this.state.date_morethan_option,
    //     date_lessthan_option:this.state.date_lessthan_option,
    //     date_value:this.state.date_value,
    //     date_value_id:this.state.date_value_id,





    //   }

    //   // console.log("data",data)

    //  // this.fetchData(page, perPage,data);
    // }
    this.setState({ rows })
  }

  handleNewFilter = () => {
    this.setState({ isFilterOpen: true })
    API.get("/community")
      .then((response) => {
        if (response.data.success) {
          this.setState({ communities: response.data.communities })
        }
      })
  }


  handleEditFilter = (id, e) => {

    let filter_option = []

    const newrows = [
      {
        id: 1
      },
      { id: 2 },
      {
        id: 3,
      },
      { id: 4 },
      { id: 5 },
      {
        id: 6
      },
      { id: 7 }
    ]


    //this.setState({filter_options:[]})
    this.setState({ rows: newrows })

    this.setState({ isFilterEditOpen: true, filter_id: id })
    API.get("/community")
      .then((response) => {
        console.log("comm", response.data)
        if (response.data.success) {
          this.setState({ communities: response.data.communities })
        }
      })
    API.get("/get_saved_filter/" + id)
      .then((response) => {



        if (response.data.success) {
          const response_filter = response.data.filter

          API.get("/sub_community/" + response_filter.community_value_id)
            .then((response) => {
              if (response.data.success)
                this.setState({ sub_communities: response.data.sub_communities })
            })


          const stage = response.data.filter_stages
          const contact_type = response.data.filters_contact_types
          const furnished_unfurnished = response.data.filters_furnished_unfurnisheds
          // const stage_data=[]
          // const contact_type_data=[]
          // const furnished_unfurnished_data=[]
          // for (let i = 0; i < stage.length; i++) {
          //   stage_data.push(stage[i].value);

          // }

          // for (let i = 0; i < contact_type.length; i++) {
          //   contact_type_data.push(contact_type[i].value);

          // }

          // for (let i = 0; i < furnished_unfurnished.length; i++) {
          //   furnished_unfurnished_data.push(furnished_unfurnished[i].value);

          // }

          // if(contact_type.length < 1){
          //   const item = {
          //     value:'1',
          //     label:'Contact type'
          //   }
          //   filter_option.push(item)

          //   this.setState({
          //     filter_options:filter_option
          //   })
          // } else
          // if(response_filter.community_value_id == 2){
          //   const item = {
          //     value:'2',
          //     label:'Community / Sub community'
          //   }
          //   filter_option.push(item)
          //   this.setState({
          //     filter_options:filter_option
          //   })
          // }else
          // if(response_filter.price_first_value_id < 1){
          //   const item = {
          //     value:'3',
          //     label:'Price'
          //   }


          //   filter_option.push(item)

          //   this.setState({
          //     filter_options:filter_option
          //   })
          // }else
          // if(response_filter.bed_rooms_value_id < 1){
          //   const item = {
          //     value:'4',
          //     label:'Bedrooms'
          //   }

          //   filter_option.push(item)

          //   this.setState({
          //     filter_options:filter_option
          //   })
          // }else
          // if(response_filter.date_value_id < 1){
          //   const item = {
          //     value:'5',
          //     label:'Created'
          //   }
          //   filter_option.push(item)
          //   this.setState({
          //     filter_options:filter_option
          //   })
          // }else
          // if(stage.length < 0){
          //   const item = {
          //     value:'6',
          //     label:'Stages'
          //   }
          // filter_option.push(item)
          //   this.setState({
          //     filter_options:filter_option
          //   })
          // }else
          // if(furnished_unfurnished_data.length < 0){
          //   const item = {
          //     value:'7',
          //     label:'Furnished / Unfurnished'
          //   }

          //   filter_option.push(item)
          //   this.setState({
          //     filter_options:filter_option
          //   })
          // }
          // filter_option=[]

          //  this.setState({stage_value:stagedata})

          this.setState({
            stage_value: stage, contact_types_value: contact_type, furnished_types_value: furnished_unfurnished,
            filter_title: response_filter.title,
            community_value_id: response_filter.community_value_id,
            sub_community_value_id: response_filter.sub_community_value_id,

            price_first_value_id: response_filter.price_first_value_id,
            price_first_value: response_filter.price_first_value,
            price_min: response_filter.price_min,
            price_max: response_filter.price_max,
            inrange: response_filter.price_first_value_id == 7 ? true : false,

            date_morethan_option: response_filter.date_morethan_option,
            date_lessthan_option: response_filter.date_lessthan_option,
            date_value_id: response_filter.date_value_id,
            date_value: response_filter.date_value,

            bed_rooms_value_id: response_filter.bed_rooms_value_id,
            bed_rooms_value: response_filter.bed_rooms_value,


            default_stage_value: response.data.filter_stages,

            default_contact_types_value: response.data.filters_contact_types,

            default_furnished_types_value: response.data.filters_furnished_unfurnisheds

          })
        }
      })
  }

  handleEditOk = () => {
    const id = this.state.filter_id
    const data = {
      filter_title: this.state.filter_title,
      community_value_id: this.state.community_value_id,
      sub_community_value_id: this.state.sub_community_value_id,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      stage_value: this.state.stage_value,

      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

    }

    API.put("/update_filter/" + id, data)
      .then((response) => {

        if (response.data.success) {
          this.setState({ isFilterEditOpen: false, filter_title: "" })
          this.fetchFilter();

        }
      })

  }

  handleOk = () => {


    const data = {
      filter_title: this.state.filter_title,
      community_value_id: this.state.community_value_id,
      sub_community_value_id: this.state.sub_community_value_id,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      stage_value: this.state.stage_value,

      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

    }


    API.post("/add_filter", data)
      .then((response) => {
        //return
        if (response.data.success) {
          this.setState({ isFilterOpen: false, filter_title: "" })
          this.fetchFilter();

        }
      })
  };
  handleCancel = () => {
    this.setState({ isFilterOpen: false, filter_title: '' })
  };

  handleEditCancel = () => {
    this.setState({ isFilterEditOpen: false, filter_title: '' })
  };

  handleFilterTitle = (e) => {
    this.setState({ filter_title: e.target.value })
  }

  handleContactType = (e) => {

    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    this.setState({ contact_types_value: e })


    const page = 1;
    const perPage = 10;


    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: e,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }




    this.fetchData(page, perPage, data)

  }

  handleCommunity = (value) => {
    this.setState({ community_value_id: value })
    API.get("/sub_community/" + value)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }


  handleSubCommunity = (value) => {
    this.setState({ sub_community_value_id: value })
  }

  handleBedRooms_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ bed_rooms_value_id: e })

    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: e,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,

      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }




    if (this.state.bed_rooms_value != '')
      this.fetchData(page, perPage, data);

  }

  handleBedRoomsValue = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ bed_rooms_value: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value_id,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: e.target.value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }

    // console.log("data bef",data)
    // return



    this.fetchData(page, perPage, data);
  }

  handleStages = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    this.setState({ stage_value: e, default_stage_value: e })

    const page = 1;
    const perPage = 10;


    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: e,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,



      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    this.fetchData(page, perPage, data)
  }
  handleFurnishedTypes = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ furnished_types_value: e })

      const page = 1;
      const perPage = 10;
      const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,
      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,
      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: e,price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }

    this.fetchData(page, perPage, data)
  }

  handleDeleteFilter = (targetKey, action) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_filter/" + targetKey)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchFilter()
            }
          })
      }
    })
  };

  // filter options

  handleNameValue_id = (e) => {
    this.setState({ name_value_id: e })
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: e,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,
      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }
    if (this.state.name_value != '')
      this.fetchData(page, perPage, data);
  }

  handleName = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ name_value: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value_id,
      name_value: e.target.value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

    }
    this.fetchData(page, perPage, data);
  }

  handlePhoneValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ phone_value_id: e })
    const page = 1;
    const perPage = 10;
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: e,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }
    if (this.state.phone_value != '')
      this.fetchData(page, perPage, data);
  }

  handlePhone = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ phone_value: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: e.target.value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

    }


    if (this.state.phone_value_id > 0)
      this.fetchData(page, perPage, data);

  }

  handleAgentValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ agent_value_id: e })

    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: e,
      agent_value: this.state.agent_value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
    }
    if (this.state.agent_value != '')
      this.fetchData(page, perPage, data);
     }
  handleAgent = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ agent_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      agent_value_id: this.state.agent_value_id,
      agent_value: e.target.value,
      contact_types_value: this.state.contact_types_value,
      stage_value: this.state.stage_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,





    }
    this.fetchData(page, perPage, data)
  }


  handlePropertyForFirstValue = (e) => {

    const property_for_first_value = e.target.value;
    if (property_for_first_value != '') {
      this.setState({ property_for_second_option: true })
    } else {
      this.setState({ property_for_second_option: false })
    }
  }

  handleRefnoFirst = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    const refno_first_value = e.target.value;
    this.setState({ refno_first_value: refno_first_value })

    if (refno_first_value != '') {
      this.setState({ refno_active_second_option: true })
    } else {
      this.setState({ refno_active_second_option: false })
    }

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleRefnoSecond = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ refno_second_value: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: e.target.value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleRefnoFirstValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ refno_first_value_id: e.target.value })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: e.target.value,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,


      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleRefRadio = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    // alert(!this.state.refno_and_or_option)
    this.setState({ refno_and_or_option: !this.state.refno_and_or_option })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: !this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleRefnoSecondValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ refno_second_value_id: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: e.target.value,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_second_value != '')
      this.fetchData(page, perPage, data);

  }

  // property for 
  handleSales = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    if (this.state.sale) {
      property_for_array = property_for_array.filter(e => e !== 'sale');
    } else {

      property_for_array.push('sale')
    }

    this.setState({ sale: !this.state.sale })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }
  handleRent = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    if (this.state.rent) {
      property_for_array = property_for_array.filter(e => e !== 'rent');
    } else {

      property_for_array.push('rent')
    }
    this.setState({ rent: !this.state.rent })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }
  handleTenant = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    if (this.state.tenant) {
      property_for_array = property_for_array.filter(e => e !== 'tenanted');
    } else {

      property_for_array.push('tenanted')
    }
    this.setState({ tenant: !this.state.tenant })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }
  handleShortterm = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    if (this.state.short_term) {
      property_for_array = property_for_array.filter(e => e !== 'short-term');
    } else {

      property_for_array.push('short-term')
    }
    this.setState({ short_term: !this.state.short_term })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  // property type
  handleResidential = (e) => {



    if (this.state.residential) {

      property_type_array = property_type_array.filter(e => e !== 'Residential');


    } else {

      property_type_array.push('Residential')


    }

    this.setState({ residential: !this.state.residential })
    const data = {

      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }
  handleCommercial = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    if (this.state.commercial) {
      property_type_array = property_type_array.filter(e => e !== 'Commercial');


    } else {
      property_type_array.push('Commercial')

    }
    this.setState({ commercial: !this.state.commercial })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,
      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  // unit no options
  handleUnitnoFirst = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    const unitno_first_value = e.target.value;
    this.setState({ unitno_first_value: unitno_first_value })

    if (unitno_first_value != '') {
      this.setState({ unitno_active_second_option: true })
    } else {
      this.setState({ unitno_active_second_option: false })
    }

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleUnitnoSecond = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    this.setState({ unitno_second_value: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: e.target.value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleUnitnoFirstValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ unitno_first_value_id: e.target.value })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: e.target.value,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleUnitRadio = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    // alert(!this.state.refno_and_or_option)
    this.setState({ unitno_and_or_option: !this.state.unitno_and_or_option })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleUnitnoSecondValue_id = (e) => {

    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

      this.setState({ unitno_second_value_id: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: e.target.value,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_second_value != '')
      this.fetchData(page, perPage, data);

  }


  // price options

  handlePriceFirst = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    const price_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { price_first_value: e.target.value } : prevState)
    )



    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array


    }

    const page = 1;
    const perPage = 10;
    if (e.target.value > 0) {
      this.fetchData(page, perPage, data);
    } else {
      return
    }




  }

  // price in range
  handlePriceMin = (e) => {

    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ price_min: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,



      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: e.target.value,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array


    }
    if (this.state.price_max > 0)
      this.fetchData(page, perPage, data);

  }

  handlePriceMax = (e) => {

    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ price_max: e.target.value })

    const max = e.target.value
    const page = 1;
    const perPage = 10;

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,

      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,


      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: e.target.value,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      // property_for:property_for_array,

      // property_type:property_type_array


    }
    if (this.state.price_min > 0 && max > this.state.price_min)
      this.fetchData(page, perPage, data);
  }

  handlePriceSecond = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    this.setState({ price_second_value: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: e.target.value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handlePriceFirstValue_id = (e) => {

    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    this.setState({ inrange: false })
    if (e == 7) {
      this.setState({ inrange: true })
    }

    this.setState({ price_first_value_id: e, price_value_id: e })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      name_value_id: this.state.name_value,
      name_value: this.state.name_value,

      phone_value_id: this.state.phone_value_id,
      phone_value: this.state.phone_value,



      bed_rooms_value_id: this.state.bed_rooms_value_id,
      bed_rooms_value: this.state.bed_rooms_value,

      stage_value: this.state.stage_value,
      contact_types_value: this.state.contact_types_value,
      furnished_types_value: this.state.furnished_types_value,

      price_first_value_id: e,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handlePriceRadio = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    // alert(!this.state.refno_and_or_option)
    this.setState({ price_and_or_option: !this.state.price_and_or_option })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handlePriceSecondValue_id = (e) => {
    this.setState({ price_second_value_id: e.target.value })

    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: e.target.value,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_second_value != '')
      this.fetchData(page, perPage, data);

  }


  // date option

  handleDateMorethan = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ date_morethan_option: true, date_lessthan_option: false })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);

  }

  handleDateLessthan = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ date_morethan_option: false, date_lessthan_option: true })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: false,
      date_lessthan_option: true,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);

  }

  handleDateValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ date_value_id: e.target.value })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: e.target.value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateValue = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ date_value: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: e.target.value,
      date_value_id: this.state.date_value_id,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (e.target.value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateFirst = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    const date_first_value = e.target.value;
    this.setState({ date_first_value: date_first_value })

    if (date_first_value != '') {
      this.setState({ date_active_second_option: true })
    } else {
      this.setState({ date_active_second_option: false })
    }

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleDateSecond = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  

    this.setState({ date_second_value: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: e.target.value,


      property_for: property_for_array,

      property_type: property_type_array

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleDateFirstValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ date_first_value_id: e.target.value })
    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: e.target.value,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateRadio = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    // alert(!this.state.refno_and_or_option)
    this.setState({ date_and_or_option: !this.state.date_and_or_option })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleDateSecondValue_id = (e) => {
    const restricted_lead = store.getState((state) => state);
    const all_restrict = restricted_lead.restricted_lead.restcrict_data

  
    this.setState({ date_second_value_id: e.target.value })

    const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: e.target.value,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_second_value != '')
      this.fetchData(page, perPage, data);

  }





  onInputChange = (e) => {
    console.log("onchange", e.target.value)
  }


  handlePriceRange = (e) => {
    const radio = e.target.value
    if (radio == 1) {
      this.setState({ greaterthanprice: true, leassthanprice: false, inbetweenprice: false })
    } else
      if (radio == 2) {
        this.setState({ greaterthanprice: false, leassthanprice: true, inbetweenprice: false })
      } else
        if (radio == 3) {
          this.setState({ greaterthanprice: false, leassthanprice: false, inbetweenprice: true })
        }
  }

  handleGreaterValue = (e) => {
    this.setState({ greaterValue: e.target.value })
  }

  handleLessValue = (e) => {
    this.setState({ lessValue: e.target.value })
  }

  handleMinKeyDown = (event) => {
    const maxValue = this.state.greaterValue
    const data = {
      greaterValue: this.state.greaterValue,
      lessValue: this.state.lessValue
    }
    if (event.key === 'Enter') {
      if (maxValue > 0) {
      } else {
        this.textInput.current.focus();
      }
    }
  }



  fetchData = (page, perPage, data) => {
    this.setState({ isLoading: true })

    API.post(`/access_leads?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {
        if (response.data.success) {

          // var data = await response.data.leads.data;



          await response.data.leads;
          this.setState({
            leads: response.data.leads,
            isLoading: false
          })
        }

      })
    // return
    //   this.setState({isLoading:true})
    //  setTimeout(() => {


    //   API.post(`/listings?page=${page}&perPage=${perPage}`,data)
    //        .then(async (response)  =>{



    //           var data = await response.data.listings.data;

    //           await response.data.listings;
    //           this.setState({
    //               mylistings: response.data.listings,
    //               isLoading: false
    //           })

    //        })
    //       }, 200);

  }

  onDragEnd = (fromIndex, toIndex) => {
    console.log("col", columns)
    // const columns = columns
    const item = columns.splice(fromIndex, 1)[0];
    //columns.splice(toIndex, 0, item);
    columns.push(item)

  };

  handleTabs = (e) => {


    const page = 1;
    const perPage = 10;
    let filter_options = []

    API.get("/get_saved_filter/" + e)
      .then((response) => {



        const response_filter = response.data.filter

        const stage = response.data.filter_stages
        const contact_type = response.data.filters_contact_types
        const furnished_unfurnished = response.data.filters_furnished_unfurnisheds

        console.log("cont", contact_type)

        const stage_data = []
        const contact_type_data = []
        const furnished_unfurnished_data = []
        for (let i = 0; i < stage.length; i++) {
          stage_data.push(stage[i].value);

        }

        for (let i = 0; i < contact_type.length; i++) {
          contact_type_data.push(contact_type[i].value);

        }

        for (let i = 0; i < furnished_unfurnished.length; i++) {
          furnished_unfurnished_data.push(furnished_unfurnished[i].value);

        }

  

        const data = {

          name_value_id: this.state.name_value,
          name_value: this.state.name_value,

          phone_value_id: this.state.phone_value_id,
          phone_value: this.state.phone_value,

          bed_rooms_value_id: response_filter.bed_rooms_value_id,
          bed_rooms_value: response_filter.bed_rooms_value,
          stage_value: stage_data,
          contact_types_value: contact_type_data,
          furnished_types_value: furnished_unfurnished_data,

          price_first_value_id: response_filter.price_first_value_id,
          price_first_value: response_filter.price_first_value,
          price_min: response_filter.price_min,
          price_max: response_filter.price_max,


          date_morethan_option: response_filter.date_morethan_option == 1 ? true : false,
          date_lessthan_option: response_filter.date_lessthan_option == 1 ? true : false,
          date_value: response_filter.date_value,
          date_value_id: response_filter.date_value_id,


        }



        // return

        this.fetchData(page, perPage, data);
      })





  }

  handleOpenDrawer = (id, e) => {
    // alert(id)

    this.setState({ record_id: id })

    API.get("/lead_details/" + id)
      .then((response) => {

        if (response.data.success)
          this.setState({
            lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties,
            notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes, property_offers: response.data.property_offers
          })
        this.setState({ open: true })
        this.fetchViewings(id)
        // this.fetchOffers(id);

        API.get("/community")
          .then((response) => {
            if (response.data.success) {
              this.setState({ communities: response.data.communities })
            }
          })
        API.get("/propertytypes")
          .then((response) => {
            if (response.data.success) {
              this.setState({ propertytypes: response.data.propertytypes })
            }
          })

        API.get("/all_agents")
          .then((response) => {
            if (response.data.success) {
              this.setState({ agents: response.data.agents })
            }
          }
          )

          this.fetchFollowUp(id);

      })



  }

  fetchViewings(lead_id)
  {
    

    API.get("/lead_viewings/"+lead_id)
    .then((response) =>{
    
      if(response.data.success){
        this.setState({property_viewings:response.data.property_viewings})
      }
    })
  }

  fetchOffers(lead_id){
    API.get("/lead_offers/"+lead_id)
    .then((response) =>{
      if(response.data.success){
        this.setState({property_offers:response.data.listing_offers})
      }

    })
}



  handleNextRecord = (e) => {
    const id = this.state.record_id;


    API.get("/next_lead/" + id)
      .then((response) => {

        if (response.data.success) {
          this.setState({
            lead_details: response.data.lead_details, owner_other_properties: response.data.owner_other_properties, record_id: response.data.lead_details.id,
            notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes, property_viewings: response.data.property_viewings, property_offers: response.data.property_offers
          })

          // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
        } else {
          toast.warning("last record")
        }
      })
  }

  handlePreviousRecord = (e) => {

    const id = this.state.record_id;
    API.get("/previous_lead/" + id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            lead_details: response.data.lead_details,
            owner_other_properties: response.data.owner_other_properties, record_id: response.data.lead_details.id,
            notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes, property_viewings: response.data.property_viewings,
            property_offers: response.data.property_offers
          })

        } else {
          toast.warning("last record")
        }
      })
  }




  handleCloseDrawer = () => {
    this.setState({ open: false })
  }

  handleSaveData = () => {
    toast.success("Successfully addedd")
    this.setState({ open: false })
  }

  handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {


        toast.success("Successfully deleted")



      }
    })
  }

  handleCommunityMatch = (e) => {
    this.setState({ community_match: e })
    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }



  handleSubCommunityMatch = (e) => {
    this.setState({ sub_community_match: e })

  }

  handleRequirementCommunity = (e) => {

    this.setState({ requirement_community: e })
    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }


  handleRequirementSubCommunity = (e) => {
    this.setState({ requirement_sub_community: e })

  }

  handleRequirementBeds = (e) => {
    this.setState({ requirement_bedroom: e.target.value })
  }

  handleRequirementMin = (e) => {
    this.setState({ requirement_min_budget: e.target.value })
  }

  handleRequirementMax = (e) => {
    this.setState({ requirement_max_budget: e.target.value })
  }

  handleRequirementMoveInDate = (e) => {
    this.setState({ requirement_move_in_date: e.target.value })
  }

  handleRequirementStage = (e) => {
    this.setState({ requirement_lead_stage: e.target.value })
  }

  handleRequirementVerifyby = (e) => {
    this.setState({ requirement_verifyedby: e.target.value })
  }

  handleRequirementAgent = (e) => {
    this.setState({ requirement_agent_id: e })
  }

  handleRequirementSubmit = () => {

    const data = {
      lead_id: this.state.record_id,
      requirement_min_budget: this.state.requirement_min_budget,
      requirement_max_budget: this.state.requirement_max_budget,
      requirement_lead_stage: this.state.requirement_lead_stage,
      requirement_community: this.state.requirement_community,
      requirement_sub_community: this.state.requirement_sub_community,
      requirement_verifyedby: this.state.requirement_verifyedby,
      requirement_agent_id: this.state.requirement_agent_id,
      requirement_move_in_date: this.state.requirement_move_in_date,
      requirement_bedroom: this.state.requirement_bedroom
    }

    API.post("/requirements_save_update", data)
      .then((response) => {
        console.log(response.data)
      })
  }

  handleMinBudget = (e) => {

    this.setState({ min_budget: e.target.value })
  }

  handleMaxBudget = (e) => {
    this.setState({ max_budget: e.target.value })
  }

  handleBeds = (e) => {
    this.setState({ bedroom_match: e.target.value })
  }

  handlePropertForMatch = (e) => {
    this.setState({ property_for_match: e.target.value })
  }

  handlePropertTypeMatch = (e) => {
    this.setState({ property_type_match: e.target.value })
  }

  handleAddedDateMatch = (e) =>{
    this.setState({added_date_match:e.target.value})
  }

  handleMatch = () => {
    const data = {
      lead_id: this.state.record_id,
      community_match: this.state.community_match,
      sub_community_match: this.state.sub_community_match,
      min_budget: this.state.min_budget, max_budget: this.state.max_budget,
      beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
      property_type_match: this.state.property_type_match,
      added_date_match:this.state.added_date_match
    }

   
    API.post("/match_leads", data)
      .then((response) => {

        if (response.data.success) {
          this.setState({ matchs: response.data.match_leads, not_matchs: response.data.not_match_leads })
        }
      })
  }

  // note logs
  handleCallLog = (e) => {
    this.setState({ call_log: e.target.value })
  }
  handleCallSubmit = () => {
    const data = {
      listing_id: this.state.record_id,
      note: this.state.call_log,
      note_type: "phone"
    }

    API.post("/add_note", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes
          })

        }
      })
  }


  handleNoteLog = (e) => {
    this.setState({ note_log: e.target.value })
  }
  handleNoteSubmit = () => {
    const data = {
      listing_id: this.state.record_id,
      note: this.state.note_log,
      note_type: "note"
    }

    API.post("/add_note", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes
          })

        }
      })
  }
  handleWhatsappLog = (e) => {
    this.setState({ whatsapp_log: e.target.value })
  }
  handleWhatsappSubmit = () => {
    const data = {
      listing_id: this.state.record_id,
      note: this.state.whatsapp_log,
      note_type: "whatsapp"
    }

    API.post("/add_note", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes
          })

        }
      })
  }
  handleEmailLog = (e) => {
    this.setState({ email_log: e.target.value })
  }

  handleEmailSubmit = () => {
    const data = {
      listing_id: this.state.record_id,
      note: this.state.email_log,
      note_type: "email"
    }

    API.post("/add_note", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes
          })

        }
      })
  }

  handleNewViewing = () => {
    this.setState({ isviewingOpen: true })
  }



  onSearchRefNo = (e) => {
    if (!e || e == '' || e == null) {
      // this.setState({ref_nums:[]})
      return
    }
    API.get("/get_ref_no/" + e)
      .then((response) => {
        console.log("refs no", response.data)
        if (response.data.success) {
          this.setState({ ref_nums: response.data.ref_nums })
        }
      })
  }

  handleRefsno = (e) => {
    this.setState({ viewing_ref_no: e })
  }

  handleViewingNote = (e) => {
    this.setState({ viewing_note: e.target.value })
  }

  handleViewingDate = (date, dateString) => {
    this.setState({ viewing_date: dateString })
  };

  handleViewingAgent = (e) => {
    this.setState({ viewing_agent: e })
  }

  handleViewingOk = () => {
    const data = {
      lead_id: this.state.lead_details.id,
      lead_ref_no: this.state.lead_details.refno,
      viewing_ref_no: this.state.viewing_ref_no,
      viewing_agent: this.state.viewing_agent,
      viewing_date: this.state.viewing_date,
      viewing_note: this.state.viewing_note
    }

    API.post("/add_viewing", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ isviewingOpen: false, property_viewings: response.data.property_viewings })

        }

      })

  }
  handleViewingCancel = () => {
    this.setState({ isviewingOpen: false })
  }

  handleEditViewing(view_id, e) {
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Successful" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, iseditviewingOpen: true
          })
        }

      })
  }

  handleOfferView = (view_id, e) => {
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Successful" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, isOfferOpen: true
          })
        }

      })
  }

  handleViewStatus = (e) => {
    const status = e.target.value
    this.setState({ viewing_status: e.target.value })
    if (status == "Successful") {
      this.setState({ showPrice: true })
    } else {
      this.setState({ showPrice: false })
    }

    if (status == "Scheduled") {
      this.setState({ showDate: true })
    } else {
      this.setState({ showDate: false })
    }
  }

  handleViewStatusChange = () => {

    const data = { lead_id: this.state.lead_details.id, view_id: this.state.view_id, viewing_status: this.state.viewing_status, viewing_price: this.state.viewing_price }
    API.post("/change_viewing_status", data)
      .then((response) => {
        this.setState({
          property_viewings: response.data.property_viewings,
          property_offers: response.data.property_offers
        })
      })
    this.setState({ iseditviewingOpen: false })
  }
  handleViewStatusCancel = () => {
    this.setState({ iseditviewingOpen: false })
  }

  handleViewingPrice = (e) => {
    this.setState({ viewing_price: e.target.value })
  }


  fetchFollowUp(leads_id){
    API.get("/followups/"+leads_id)
    .then((response) =>{
      console.log("followups",response.data)
      if(response.data.success){
        this.setState({followups:response.data.followups})
      }
      
    })
  }

  handleNewFollowUp = () =>{
    this.setState({ isfollowupOpen: true })
  }



  handleFollowupDate = (data,dateString)=>{
    this.setState({followup_date:dateString})
  }

  handleFollowupNote = (e) =>{
    this.setState({followup_note:e.target.value})
  }

  handleFollowupOk = () =>{
    const lead_id = this.state.record_id
    const data = {
      leads_id:this.state.record_id,
      remind_date:this.state.followup_date,
      remind_note:this.state.followup_note

    }

    API.post("/add_followup",data)
    .then((response) =>{
      
   if(response.data.success){
    this.fetchFollowUp(lead_id)
    this.setState({ isfollowupOpen: false })
   }
   

    })
  }
  handleFollowupCancel =()=>{
    this.setState({ isfollowupOpen: false })
  }

  handleViewFollowup =(reminder_id,e)=>{
    API.get("/followup_by_reminder_id/"+reminder_id)
    .then((response)=>{
      if(response.data.success){

        this.setState({isfollowupViewOpen:true,followup_date:response.data.followup.remind_date,followup_note:response.data.followup.remind_note})

      }
    })
       
  }

  handleFollowupViewOk = () =>{
    this.setState({isfollowupViewOpen:false})

  }

  handleFollowupViewCancel =() =>{
    this.setState({isfollowupViewOpen:false})
  }

  handleOkOffer  = () =>{
  this.setState({isOfferOpen:false})
  }
  handleCancelOffer = () =>{
  this.setState({isOfferOpen:false})
  }

  handleAllMatch = () =>{
   
    const data = {
      lead_id:this.state.record_id,
      community_match:this.state.community_match,
      sub_community_match:this.state.sub_community_match,
      min_budget:this.state.min_budget,max_budget:this.state.max_budget,
      beds:this.state.bedroom_match,property_for_match:this.state.property_for_match,
      property_type_match:this.state.property_type_match,
      added_date_match:this.state.added_date_match
     }
    this.props.setNoMatch(data)
    this.props.history.push("/matching_listings")
  }

  render() {
    const logsitems = [
      {
        key: '1',
        label: `all (10)`,

      },
      {
        key: '2',
        label: <PhoneOutlined />,
      },
      {
        key: '3',
        label: <WhatsAppOutlined />,
      },
      {
        key: '4',
        label: <MailOutlined />,
      },

    ];

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      // setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      // selectedRowKeys,
      onChange: onSelectChange,

    };

    columns = [

      {
        title: 'Name',
        dataIndex: 'firstname',
        render: (text, record) => (
          <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.firstname}</Link>
        ),
        sorter: (a, b) => a.firstname.length - b.firstname.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Name
            <hr />
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onChange={this.handleNameValue_id}
              options={this.state.name_filter_options}
            />
            {/* <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleNameValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select> */}
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleName} placeholder="filter..." />
            </div>




          </div>
        ),
      },
      {
        title: 'Contact type',
        dataIndex: 'contact_type',
        sorter: (a, b) => {
          if (a && a.contact_type && a.contact_type.length && b && b.contact_type && b.contact_type.length) {
            return a.contact_type.length - b.contact_type.length;
          } else if (a && a.contact_type && a.contact_type.length) {
            return -1;
          } else if (b && b.contact_type && b.contact_type.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Contact type
            <hr />

            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              defaultValue={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
              placeholder="Please select"
              onChange={this.handleContactType}
              options={this.state.contact_types}
            />


          </div>
        ),

      },

      {
        title: 'Phone',
        dataIndex: 'mobile',

        sorter: (a, b) => a.mobile.length - b.mobile.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Phone number
            <hr />
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onChange={this.handlePhoneValue_id}
              options={this.state.phone_filter_options}
            />
            {/* <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handlePhoneValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select> */}
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handlePhone} placeholder="filter..." />
            </div>




          </div>
        ),
      },



      {
        title: 'Price',
        dataIndex: 'price',
        sorter: (a, b) => {
          if (a && a.price && a.price.length && b && b.price && b.price.length) {
            return a.price.length - b.price.length;
          } else if (a && a.price && a.price.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.price && b.price.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        render: (text, record) => {
          if(!isNaN(parseInt(text))){
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED'
          }else{
            return null;
          }
        },
        //a.price.length > 0 - b.price.length > 0,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Price
            <hr />


            <Select

              style={{
                width: '100%',
              }}

              placeholder="Please select"
              onChange={this.handlePriceFirstValue_id}
              options={this.state.price_options}
            />

            {/* <select class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select> */}
            <div className='mt-2'>
              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
              </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
            </div>




          </div>
        ),
      },

      {
        title: 'Bedrooms',
        dataIndex: 'bedroom',

        sorter: (a, b) => a.bedroom.length - b.bedroom.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Bedrooms
            <hr />

            <Select

              style={{
                width: '100%',
              }}

              placeholder="Please select"
              onChange={this.handleBedRooms_id}
              options={this.state.bedroom_filter_options}
            />
            {/* <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleBedRooms_id}>
              
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3"> Less than</option>
              <option value="4">Greater than</option>
            </select> */}
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleBedRoomsValue} placeholder="filter..." />
            </div>




          </div>
        ),
      },

      {
        title: 'Agent',
        dataIndex: 'agents',

        sorter: (a, b) => a.agents.length - b.agents.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Agent
            <hr />
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onChange={this.handleAgentValue_id}
              options={this.state.agent_filter_options}
            />
            {/* <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleAgentValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select> */}
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleAgent} placeholder="filter..." />
            </div>




          </div>
        ),
      },

      {
        title: 'FF / UF',
        // dataIndex: 'furnished',
        render: (text, record) => (
          <>
            {record.furnished == 1 ? "Furnished" : "Unfurnished"}
          </>


        ),

        sorter: (a, b) => {
          if (a && a.furnished && a.furnished.length && b && b.furnished && b.furnished.length) {
            return a.furnished.length - b.furnished.length;
          } else if (a && a.furnished && a.furnished.length) {
            return -1;
          } else if (b && b.furnished && b.furnished.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Furnished / Unfurnished
            <hr />
            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              defaultValue={["1", "2"]}
              placeholder="Please select"
              onChange={this.handleFurnishedTypes}
              options={this.state.furnished_types}
            />


          </div>
        ),

      },

      {
        title: 'Move in date',
        dataIndex: 'move_in_date',
        render: (text, record) => (
          <>
            {record.move_in_date != null ? <Moment fromNow>
              {record.move_in_date}
            </Moment> : null}
          </>

        ),
        sorter: (a, b) => {
          if (a && a.move_in_date && a.move_in_date.length && b && b.move_in_date && b.move_in_date.length) {
            return a.move_in_date.length - b.move_in_date.length;
          } else if (a && a.move_in_date && a.move_in_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.move_in_date && b.move_in_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                More than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },

      {
        title: 'Type',
        dataIndex: 'prop_type',

        sorter: (a, b) => a.prop_type.length - b.prop_type.length,

        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Ref no
            <hr />
            <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
            </div>
            {this.state.refno_active_second_option ? (<div className='mt-2' >
              <center>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                  <label class="form-check-label" for="flexRadioDefault1">
                    AND
                  </label>
                  <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                  <label class="form-check-label ml-4" for="flexRadioDefault1">
                    OR
                  </label>

                </div>
              </center>
              <select class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
                <option value="1">Contain</option>
                <option value="2">Not contain</option>
                <option value="3">Equals</option>
                <option value="4">Not equal</option>
                <option value="5">Starts with</option>
                <option value="6">Ends with</option>
              </select>
              <div className='mt-2'>
                <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
              </div>

            </div>) : null}



          </div>
        ),
      },

      {
        title: 'Last call',
        dataIndex: 'lastcall',
        render: (text, record) => (
          <>
            {record.lastcall != null ?
              <Moment fromNow>
                {record.lastcall}
              </Moment> : null}
          </>

        ),
        sorter: (a, b) => {
          if (a && a.lastcall && a.lastcall.length && b && b.lastcall && b.lastcall.length) {
            return a.lastcall.length - b.move_in_date.length;
          } else if (a && a.lastcall && a.lastcall.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.lastcall && b.lastcall.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                More than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },

      {
        title: 'Created',
        dataIndex: 'added_date',
        render: (text, record) => (
          <Moment fromNow>
            {record.added_date}
          </Moment>
        ),
        sorter: (a, b) => {
          if (a && a.added_date && a.added_date.length && b && b.added_date && b.added_date.length) {
            return a.added_date.length - b.added_date.length;
          } else if (a && a.added_date && a.added_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.added_date && b.added_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                More than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },

      {
        title: 'Lead Stage',
        // dataIndex: 'lead_stage',
        render: (text, record) => (
          <>
            {record.lead_stage == 0 ? "Uncontacted" : null}
            {record.lead_stage == 1 ? "Working" : null}
            {record.lead_stage == 2 ? "Hot" : null}
            {record.lead_stage == 3 ? "Follow Up" : null}
            {record.lead_stage == 4 ? "Dead" : null}
          </>
        ),
        sorter: (a, b) => {
          if (a && a.lead_stage && a.lead_stage.length && b && b.lead_stage && b.lead_stage.length) {
            return a.lead_stage.length - b.lead_stage.length;
          } else if (a && a.lead_stage && a.lead_stage.length) {
            return -1;
          } else if (b && b.lead_stage && b.lead_stage.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Stages
            <hr />

            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              defaultValue={["0", "1", "2", "3", "4"]}
              placeholder="Please select"
              onChange={this.handleStages}
              options={this.state.stages}
            />


          </div>
        ),

      },

      {
        title: 'Last match',
        dataIndex: 'added_date',
        render: (text, record) => (
          <Moment fromNow>
            {record.added_date}
          </Moment>
        ),
        sorter: (a, b) => {
          if (a && a.added_date && a.added_date.length && b && b.added_date && b.added_date.length) {
            return a.added_date.length - b.added_date.length;
          } else if (a && a.added_date && a.added_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.added_date && b.added_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <hr />


            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label class="form-check-label" for="flexRadioDefault1">
                More than
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label class="form-check-label" for="flexRadioDefault2">
                Less than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },







    ];




    const handleTableChange = (pagination, filters) => {

        const restricted_lead = store.getState((state) => state);
        const all_restrict = restricted_lead.restricted_lead.restcrict_data
    
      

      const data = {
        params_listing_id: all_restrict.listing_id,
        params_community_match: all_restrict.community_match,
        params_sub_community_match:all_restrict.sub_community_match,
        params_min_budget: all_restrict.min_budget,
        params_max_budget: all_restrict.max_budget,
        params_beds: all_restrict.beds,

        params_property_for_match: all_restrict.property_for_match,
        params_property_type_match: all_restrict.property_type_match,
        params_added_date_match: all_restrict.added_date_match,
        name_value_id: this.state.name_value,
        name_value: this.state.name_value,

        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,

        bed_rooms_value_id: this.state.bed_rooms_value_id,
        bed_rooms_value: this.state.bed_rooms_value,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,

        stage_value: this.state.stage_value,
        contact_types_value: this.state.contact_types_value,
        furnished_types_value: this.state.furnished_types_value,


        price_first_value_id: this.state.price_first_value_id,
        price_first_value: this.state.price_first_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,

        date_first_value_id: this.state.date_first_value_id,
        date_and_or_option: this.state.date_and_or_option,
        date_second_value_id: this.state.date_second_value_id,
        date_first_value: this.state.date_first_value,
        date_second_value: this.state.date_second_value,

        date_morethan_option: this.state.date_morethan_option,
        date_lessthan_option: this.state.date_lessthan_option,
        date_value: this.state.date_value,
        date_value_id: this.state.date_value_id,





      }




      this.fetchData(pagination.current, pagination.pageSize, data);

    };





    return (
      <div>
        <ToastContainer />

        <Modal width={1000} title="Basic Modal" open={this.state.isOfferOpen} 
        onOk={this.handleOkOffer} onCancel={this.handleCancelOffer}
        >
             <p>
             <p>{this.state.viewings.viewing_date}</p>
           
          </p>
          <p>
            {this.state.viewings.viewing_note}
          </p>
      </Modal>



        <Modal width={1000} title="Add Followup" open={this.state.isfollowupOpen}
          onOk={this.handleFollowupOk} onCancel={this.handleFollowupCancel}
        >
          <Divider />
          
   
            
              <label>Date / Time</label>
              <DatePicker style={{ maxWidth: 600 }} showTime onChange={this.handleFollowupDate} />
             
        
              <label >Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleFollowupNote} />
          



        </Modal>

        <Modal width={1000} title=" Followup details" open={this.state.isfollowupViewOpen}
          onOk={this.handleFollowupViewOk} onCancel={this.handleFollowupViewCancel}
        >
          <Divider />
          
   
            
              <label>Date / Time</label>
              <p>{this.state.followup_date}</p>
             
        
              <label >Note</label>
              <p>{this.state.followup_note}</p>
          



        </Modal>


        {/* viewings modal */}
        <Modal width={1000} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
          <Divider />
          <div className='row'>
            <div className='col-md-4'>
              <label>REf NO</label>
              <p>{this.state.viewings.listing_refno}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <label>Agent Name</label>
              <p>{this.state.viewings.fullname}</p>
            </div>
            <div className='col-md-4'>
              <label>Date</label>
              {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p>{this.state.viewings.view_date}</p>}

            </div>
            <div className='col-md-4'>
              <label>Status</label>
              <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-8'>
              <label>Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
            {this.state.showPrice ? <div className='col-md-4'>
              <label>Price</label>
              <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
            </div> : null}

          </div>
        </Modal>
        <Modal width={1000} title="Add Viewings" open={this.state.isviewingOpen}
          onOk={this.handleViewingOk} onCancel={this.handleViewingCancel}
        >
          <Divider />

          <div className='row w-100'>
  <div className='col-md-12 px-0 pt-1'>
    <div className="form-group">
      <label>Ref No</label>
      <Select
                style={{
                  width: "100%",
                }}
                mode={"multiple"}
                showSearch
                placeholder="Select Refs no"
                optionFilterProp="children"
                onChange={this.handleRefsno}
                onSearch={this.onSearchRefNo}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.ref_nums}
              />
    </div>
    <div className="row orgroup">
      <div className="col-12 d-flex flex-row">
        <hr/>
        <p className="mb-0">Or</p>
        <hr/>
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <div className="form-group">
            <label className="">Unit no:</label>
            <input type="text" placeholder="Unit no"/>
        </div>                
      </div>
      <div className="col-6">
        <div className="form-group">
            <label className="">Agent:</label>
            <Select
                style={{
                  width: "100%",
                }}

                showSearch
                placeholder="Select Agent"
                optionFilterProp="children"
                onChange={this.handleViewingAgent}

                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.agents}
              />
        </div>                
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <div className="form-group">
            <label className="">Community:</label>
            <Select
                            style={{
                              width: '100%',
                            }}

                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                           // onChange={this.handleCommunityMatch}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.communities}
                          />
        </div>                
      </div>
      <div className="col-6">
        <div className="form-group">
            <label className="">Sub Community:</label>
            <Select
                            style={{
                              width: "100%",
                            }}
                            // mode={"multiple"}

                            showSearch
                            maxTagCount={"responsive"}
                            placeholder="Select a person"
                            optionFilterProp="children"
                            //onChange={this.handleSubCommunityMatch}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.sub_communities}
                          />
        </div>                
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <div className="form-group">
            <label className="">Property:</label>
            <select name="property" id="property" className="form-control">
              <option value=""></option>
              <option selected="" value="386">Burj Views A</option>
              <option value="237">Burj Views B</option>
              <option value="283">Burj Views C</option>
              <option value="274">Burj Views Podium</option>
            </select>
        </div>                
      </div>
      <div className="col-6">
        <div className="form-group">
            <label className="">Date / Time:</label>
            <DatePicker style={{ width: '100%' }} showTime onChange={this.handleViewingDate} />
        </div>                
      </div>
    </div>
    {/* <div className="row">
        <div className="col-12">
            <label className="">Notes:</label>
            <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
        </div>
    </div> */}
  </div>
</div>
          {/* <div className='row'>
            <div className='col-md-8'>
              <label>Ref No:</label>
              <Select
                style={{
                  width: "100%",
                }}
                mode={"multiple"}
                showSearch
                placeholder="Select Refs no"
                optionFilterProp="children"
                onChange={this.handleRefsno}
                onSearch={this.onSearchRefNo}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.ref_nums}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-4'>
              <label>Agent</label>
              <Select
                style={{
                  width: "100%",
                }}

                showSearch
                placeholder="Select Agent"
                optionFilterProp="children"
                onChange={this.handleViewingAgent}

                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.agents}
              />
            </div>
            <div className='col-md-4'>
              <label>Date / Time</label>
              <DatePicker style={{ width: '100%' }} showTime onChange={this.handleViewingDate} />
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-10'>
              <label>Note</label>
              <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
          </div>
 */}


        </Modal>
        <Drawer
          title={`Lead details`}
          placement="left"
          // size={"large"}
          width={"98%"}
          onClose={this.handleCloseDrawer}
          open={this.state.open}
        // extra={
        //   <Space>
        //     <Button type='primary' danger ghost onClick={this.handleCloseDrawer} icon={<CloseOutlined /> }>Cancel</Button>
        //     <Button type="primary" onClick={this.handleSaveData}>
        //       Save changes
        //     </Button>
        //   </Space>
        // }
        >

          <div className="cont-wrap container">
            <div className="row">
              <div className="col-12 d-flex flex-row toptitle">
                <a className="btn btn-primary " onClick={this.handlePreviousRecord}>Previous</a>
                <div className="d-flex w-100 ps-3"><h2 className="page-title fs-5 font-bold"><i className="bi bi-pencil-fill me-2" />Leads Details - {this.state.lead_details.refno}</h2></div>
                <a className="btn btn-primary" onClick={this.handleNextRecord}>Next</a>
              </div>
            </div>
            <div className="row column-wrap mt-3">
              <div className="col-12 col-md-3">
                <div className="bg-white border-top ownersdetails">
                  <div className="d-flex flex-row justify-content-between">
                    <h3>Contact Details</h3>
                    <button type="button" className="btn btn-primary requestchange">Change Details</button>
                  </div>
                  <div className="d-flex flex-column formfieldset mt-3">
                    <div className="d-flex flex-column form-group">
                      <label className="control-labels mr-4">Name</label>
                      <input type="text" id="contactName" value={this.state.lead_details.owner} disabled className="form-control" placeholder />
                    </div>
                    <div className="d-flex flex-column form-group">
                      <label className="control-labels mr-4">Phone </label>
                      <input type="text" id="contactPhone" value={this.state.lead_details.mobile} disabled className="form-control" placeholder />
                    </div>
                    <div className="d-flex flex-column form-group">
                      <label className="control-labels mr-4"> Email </label>
                      <input type="text" id="contactEmail" value={this.state.lead_details.email} disabled className="form-control" placeholder />
                    </div>
                    <div className="d-flex flex-column form-group mb-0">
                      <label className="control-labels mr-4">Other Enquires</label>
                      <div className="col-12 d-flex flex-column border-wrap listtable-wrap">

                        {this.state.owner_other_properties.map((owner_other_property) => {
                          return (
                            <a href className="d-flex flex-row p-2 w-100">
                              <div className="col-8 listtable">
                                <p className="propfor">{owner_other_property.property_for}</p>
                                <p className="refno">{owner_other_property.refno}</p>
                                <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                <p className="community">{owner_other_property.community}</p>
                              </div>
                              <div className="col-4">
                                <div className="d-flex flex-row stat publishstat">
                                  <i className="bi bi-circle-fill" />
                                  <span>Published</span>
                                </div>
                                <div className="agentprop">
                                  <p className="name">Mark C.</p>
                                  <p className="label">Agent</p>
                                </div>
                              </div>
                            </a>
                          )
                        })}
                        {/* <a href className="d-flex flex-row p-2 w-100">
                <div className="col-8 listtable">
                  <p className="propfor">Sale</p>
                  <p className="refno">LP14494</p>
                  <p className="propadd">61 Palma Residence</p>
                  <p className="community">Palm Jumeirah</p>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row stat publishstat">
                    <i className="bi bi-circle-fill" />
                    <span>Published</span>
                  </div>
                  <div className="agentprop">
                    <p className="name">Mark C.</p>
                    <p className="label">Agent</p>
                  </div>
                </div>
              </a> */}
                        {/* <a href className="d-flex flex-row p-2 w-100">
                <div className="col-8 listtable">
                  <p className="propfor">Sale</p>
                  <p className="refno">LP12000</p>
                  <p className="propadd">61 Palma Views</p>
                  <p className="community">Palm Jumeirah</p>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row stat publishstat">
                    <i className="bi bi-circle-fill" />
                    <span>Published</span>
                  </div>
                  <div className="agentprop">
                    <p className="name">Mark R.</p>
                    <p className="label">Agent</p>
                  </div>
                </div>
              </a> */}
                        {/* <a href className="d-flex flex-row p-2 w-100">
                <div className="col-8 listtable">
                  <p className="propfor">Sale</p>
                  <p className="refno">LP14494</p>
                  <p className="propadd">61 Palma Residence</p>
                  <p className="community">Palm Jumeirah</p>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row stat publishstat">
                    <i className="bi bi-circle-fill" />
                    <span>Published</span>
                  </div>
                  <div className="agentprop">
                    <p className="name">Alex E.</p>
                    <p className="label">Agent</p>
                  </div>
                </div>
              </a> */}
                        {/* <a href className="d-flex flex-row p-2 w-100">
                <div className="col-8 listtable">
                  <p className="propfor">Sale</p>
                  <p className="refno">LP12000</p>
                  <p className="propadd">61 Palma Views</p>
                  <p className="community">Palm Jumeirah</p>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row stat publishstat">
                    <i className="bi bi-circle-fill" />
                    <span>Published</span>
                  </div>
                  <div className="agentprop">
                    <p className="name">Simon B.</p>
                    <p className="label">Agent</p>
                  </div>
                </div>
              </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* FORMS */}
                <div className="bg-white border-top offers p-4 mt-3">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4 mb-3">Requirements</h3>
                  </div>
                  <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
                    <div className="form-group"> {/* MULTISELECT */}
                      <label className="control-labels ">Lead Stage</label>
                      <select onChange={this.handleRequirementStage} value={this.state.lead_details.lead_stage} name="lead_stage" className="form-control multiselects" id="lead_stage">
                        <option value>None</option>
                        <option selected value={0}>Uncontacted</option>
                        <option value={1}>Working</option>
                        <option value={2}>Hot</option>
                        <option value={3}>Follow Up</option>
                        <option value={4}>Dead</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Verifier</label>
                      <select onChange={this.handleRequirementVerifyby} name="lead_verifier" className="form-control multiselects" id="lead_verifier">
                        <option value>None</option>
                        <option value={375}>Danielle Robinson</option>
                        <option value={427}>Christalline Ybanez</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Agent Name</label>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        value={this.state.lead_details.lead_agent}
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={this.handleRequirementAgent}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.agents}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Move in Date</label>
                      <input value={this.state.lead_details.mov_in} onChange={this.handleRequirementMoveInDate} type="datetime-local" id="move_in_date" className="form-control" defaultValue />
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Budget</label>
                      <div className="d-flex flex-row" style={{ gap: '0.6rem' }}>
                        <input value={this.state.lead_details.budget_from} type="text" id="budgetfrom" className="form-control" onChange={this.handleRequirementMin} />
                        <span style={{ padding: '0 3px', lineHeight: 36, height: 36 }}>-</span>
                        <input value={this.state.lead_details.budget_to} type="text" id="budgetto" className="form-control" onChange={this.handleRequirementMax} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Bedrooms</label>
                      <select value={this.state.lead_details.bedroom} onChange={this.handleRequirementBeds} id="bedrooms" name="bedrooms" className="form-control select2 select2-offscreen" tabIndex={-1} title>
                        <option value>Select</option>
                        <option value="Studio">Studio</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5} >5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Community</label>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        value={this.state.lead_details.community}

                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={this.handleRequirementCommunity}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.communities}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-labels ">Sub Community</label>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        // mode={"multiple"}
                        value={this.state.lead_details.subcommunity}
                        showSearch
                        maxTagCount={"responsive"}
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={this.handleRequirementSubCommunity}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.sub_communities}
                      />

                    </div>
                  </div>
                  <button onClick={this.handleRequirementSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button>
                </div>{/* END FORMS*/}
              </div>
              <div className="col-12 col-md-6 timeline-wrap">
                <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                  <h3>Timeline</h3>
                  {/* ADD NOTES */}
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-call-tab" data-bs-toggle="tab" data-bs-target="#nav-call" type="button" role="tab" aria-controls="nav-call" aria-selected="true"><i className="bi bi-telephone-fill me-1" />Log Call</button>
                    <button className="nav-link" id="nav-notes-tab" data-bs-toggle="tab" data-bs-target="#nav-notes" type="button" role="tab" aria-controls="nav-notes" aria-selected="false"><i className="bi bi-plus me-1" />Create Notes</button>
                    <button className="nav-link" id="nav-whatsapp-tab" data-bs-toggle="tab" data-bs-target="#nav-whatsapp" type="button" role="tab" aria-controls="nav-whatsapp" aria-selected="false"><i className="bi bi-whatsapp me-1" />Send WhatsApp</button>
                    <button className="nav-link" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email" type="button" role="tab" aria-controls="nav-email" aria-selected="false"><i className="bi bi-envelope me-1" />Send Email</button>
                  </div>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-call" role="tabpanel" aria-labelledby="nav-call-tab" tabIndex={0}>
                      <textarea onChange={this.handleCallLog} id="callLogsText" rows={4} placeholder="Add call log.." className="font-14 mention" />
                      <button id="calllogbtn" onClick={this.handleCallSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" /> Add call log</button>
                    </div>
                    <div className="tab-pane fade" id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab" tabIndex={0}>
                      <textarea id="logsText" onChange={this.handleNoteLog} rows={4} placeholder="Add note.." className="font-14 mentionnote" defaultValue={""} />
                      <button id="logsbtn" onClick={this.handleNoteSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add note</button>
                    </div>
                    <div className="tab-pane fade" id="nav-whatsapp" role="tabpanel" aria-labelledby="nav-whatsapp-tab" tabIndex={0}>
                      <textarea onChange={this.handleWhatsappLog} id="whatsappLogsText" rows={4} placeholder="Add WhatsApp notes.." className="font-14 mentionwa" defaultValue={""} />
                      <button onClick={this.handleWhatsappSubmit} id="whatsapplogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add WhatsApp Note</button>
                    </div>
                    <div className="tab-pane fade" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab" tabIndex={0}>
                      <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add email log.." className="font-14 mentionemail" defaultValue={""} />
                      <button onClick={this.handleEmailSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add email</button>
                    </div>
                  </div>
                  {/* TIMELINE */}
                  <div className="timeline border-wrap">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true"><i className="bi bi-list-ul me-1" />All</button>
                      <button className="nav-link" id="nav-calllist-tab" data-bs-toggle="tab" data-bs-target="#nav-calllist" type="button" role="tab" aria-controls="nav-calllist" aria-selected="false"><i className="bi bi-telephone-fill me-1" />{this.state.call_notes.length}</button>
                      <button className="nav-link" id="nav-wa-tab" data-bs-toggle="tab" data-bs-target="#nav-wa" type="button" role="tab" aria-controls="nav-wa" aria-selected="false"><i className="bi bi-whatsapp me-1" />{this.state.whatsapp_notes.length}</button>
                      <button className="nav-link" id="nav-mail-tab" data-bs-toggle="tab" data-bs-target="#nav-mail" type="button" role="tab" aria-controls="nav-mail" aria-selected="false"><i className="bi bi-envelope me-1" />{this.state.email_notes.length}</button>
                      <button className="nav-link" id="nav-view-tab" data-bs-toggle="tab" data-bs-target="#nav-view" type="button" role="tab" aria-controls="nav-view" aria-selected="false"><i className="bi bi-eye-fill me-1" />0</button>
                      <button className="nav-link" id="nav-offer-tab" data-bs-toggle="tab" data-bs-target="#nav-offer" type="button" role="tab" aria-controls="nav-offer" aria-selected="false"><i className="bi bi-tag-fill me-1" />0</button>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabIndex={0}>
                        <div className="timeline-list">
                          {this.state.notes.map((note) => {
                            return (
                              <div className="timegroup viewing">
                                <div className="col-1 me-2">
                                  <div className="iconwrap">
                                    {note.note_type == "phone" ? <i class="bi bi-telephone-fill me-1"></i> : null}
                                    {note.note_type == "email" ? <i className="bi bi-envelope-fill me-1" /> : null}
                                    {note.note_type == "whatsapp" ? <i className="bi bi-whatsapp me-1" /> : null}
                                    {note.note_type == "note" ? <i class="bi bi-pin-angle-fill"></i> : null}



                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="col-12 d-flex flex-row">
                                    <div className="agentwrap me-3">
                                      <div className="namewrap">
                                        <span> <Avatar src={baseLpUrlImg + "/" + note.profile_photo} alt="avatar" /> </span>
                                      </div>
                                    </div>
                                    <div className="agentdetails">
                                      <p className="awriter font-bold">{note.by_user_name}</p>
                                      <p className="atime">11:30 am</p>
                                      <p className="aclass">View note</p>
                                      <div className="adesc">
                                        Owner : firstname , lastname <br />
                                        Date : {note.time}<br />
                                        {note.note}<br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          {/* <div className="timegroup viewing">
                  <div className="col-1 me-2">
                    <div className="iconwrap">
                      <i className="bi bi-eye-fill" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="col-12 d-flex flex-row">
                      <div className="agentwrap me-3">
                        <div className="namewrap">
                          <span>MC</span>
                        </div>
                      </div>
                      <div className="agentdetails">
                        <p className="awriter font-bold">Mark Castley</p>
                        <p className="atime">11:30 am</p>
                        <p className="aclass">View note</p>
                        <div className="adesc">
                          Client : Jason Hayes <br />
                          Date : December 10,2022 | 11:30 am<br />
                          Outcome : In review<br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                          {/* <div className="timegroup call">
                  <div className="col-1 me-2">
                    <div className="iconwrap">
                      <i className="bi bi-telephone-fill me-1" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="col-12 d-flex flex-row">
                      <div className="agentwrap me-3">
                        <div className="namewrap">
                          <span>MC</span>
                        </div>
                      </div>
                      <div className="agentdetails">
                        <div className="calldetails">
                          <p className="awriter font-bold">Mark Castley</p>
                          <span>&gt;</span>
                          <p className="whocall">Jason Hayes</p>
                        </div>
                        <p className="atime">11:30 am</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                          {/* <div className="timegroup viewing">
                  <div className="col-1 me-2">
                    <div className="iconwrap">
                      <i className="bi bi-eye-fill" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="col-12 d-flex flex-row">
                      <div className="agentwrap me-3">
                        <div className="namewrap">
                          <span>MC</span>
                        </div>
                      </div>
                      <div className="agentdetails">
                        <p className="awriter font-bold">Mark Castley</p>
                        <p className="atime">11:30 am</p>
                        <p className="aclass">View note</p>
                        <div className="adesc">
                          Client : Jason Hayes <br />
                          Date : December 10,2022 | 11:30 am<br />
                          Outcome : In review<br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                          {/* <div className="timegroup system">
                  <div className="col-1 me-2">
                    <div className="iconwrap">
                      <i className="bi bi-pin-angle-fill" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="col-12 d-flex flex-row">
                      <div className="agentwrap me-3">
                        <div className="namewrap">
                          <span>LP</span>
                        </div>
                      </div>
                      <div className="agentdetails">
                        <p className="awriter font-bold">System</p>
                        <p className="atime">11:30 am</p>
                        <p className="aclass">Lead note, Lead: LP121555</p>
                        <div className="adesc">
                          Hello, I would like to get more information about this property you posted on propertyfinder.ae.Reference LP10000 Apartment Price 1350000 AED year Location Rimal 2
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                          {/* <div className="timegroup call">
                  <div className="col-1 me-2">
                    <div className="iconwrap">
                      <i className="bi bi-telephone-fill me-1" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="col-12 d-flex flex-row">
                      <div className="agentwrap me-3">
                        <div className="namewrap">
                          <span>MC</span>
                        </div>
                      </div>
                      <div className="agentdetails">
                        <div className="calldetails">
                          <p className="awriter font-bold">Mark Castley</p>
                          <span>&gt;</span>
                          <p className="whocall">Jason Hayes</p>
                        </div>
                        <p className="atime">11:30 am</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                          {/* <div className="timegroup system">
                  <div className="col-1 me-2">
                    <div className="iconwrap">
                      <i className="bi bi-pin-angle-fill" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="col-12 d-flex flex-row">
                      <div className="agentwrap me-3">
                        <div className="namewrap">
                          <span>LP</span>
                        </div>
                      </div>
                      <div className="agentdetails">
                        <p className="awriter font-bold">System</p>
                        <p className="atime">11:30 am</p>
                        <p className="aclass">Lead note, Lead: LP121555</p>
                        <div className="adesc">
                          Hello, I would like to get more information about this property you posted on propertyfinder.ae.Reference LP10000 Apartment Price 1350000 AED year Location Rimal 2
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-calllist" role="tabpanel" aria-labelledby="nav-calllist-tab" tabIndex={0}>
                        <div className="timeline-list">

                          {this.state.call_notes.map((call_note) => {
                            return (
                              <div className="timegroup call">
                                <div className="col-1 me-2">
                                  <div className="iconwrap">
                                    <i className="bi bi-telephone-fill me-1" />
                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="col-12 d-flex flex-row">
                                    <div className="agentwrap me-3">
                                      <div className="namewrap">
                                        {/* initials will show if there is no agent image */}
                                        <span><Avatar src={baseLpUrlImg + "/" + call_note.profile_photo} alt="avatar" /> </span>
                                      </div>
                                    </div>
                                    <div className="agentdetails">
                                      <div className="calldetails">
                                        <p className="awriter font-bold">{call_note.by_user_name}</p>
                                        <span>&gt;</span>
                                        <p className="whocall">Jason Hayes</p>
                                      </div>
                                      <p className="atime">{call_note.time}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>


                      <div className="tab-pane fade" id="nav-wa" role="tabpanel" aria-labelledby="nav-wa-tab" tabIndex={0}>
                        <div className="timeline-list">
                          {this.state.whatsapp_notes.map((whatsapp_note) => {
                            return (
                              <div className="timegroup whatsapp">
                                <div className="col-1 me-2">
                                  <div className="iconwrap">
                                    <i className="bi bi-whatsapp me-1" />
                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="col-12 d-flex flex-row">
                                    <div className="agentwrap me-3">
                                      <div className="namewrap">
                                        {/* initials will show if there is no agent image */}
                                        <span><Avatar src={baseLpUrlImg + "/" + whatsapp_note.profile_photo} alt="avatar" /> </span>
                                      </div>
                                    </div>
                                    <div className="agentdetails">
                                      <div className="calldetails">
                                        <p className="awriter font-bold">{whatsapp_note.by_user_name}</p>
                                        <span>&gt;</span>
                                        <p className="whocall">Jason Hayes</p>
                                      </div>
                                      <p className="atime">{whatsapp_note.time}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                      </div>
                      <div className="tab-pane fade" id="nav-mail" role="tabpanel" aria-labelledby="nav-mail-tab" tabIndex={0}>
                        <div className="timeline-list">
                          {this.state.email_notes.map((email_note) => {
                            return (
                              <div className="timegroup call">
                                <div className="col-1 me-2">
                                  <div className="iconwrap">
                                    <i className="bi bi-envelope-fill me-1" />
                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="col-12 d-flex flex-row">
                                    <div className="agentwrap me-3">
                                      <div className="namewrap">
                                        {/* initials will show if there is no agent image */}
                                        <span><Avatar src={baseLpUrlImg + "/" + email_note.profile_photo} alt="avatar" /> </span>
                                      </div>
                                    </div>
                                    <div className="agentdetails">
                                      <div className="calldetails">
                                        <p className="awriter font-bold">{email_note.by_user_name}</p>
                                        <span>&gt;</span>
                                        <p className="whocall">Jason Hayes</p>
                                      </div>
                                      <p className="atime">{email_note.time}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-view" role="tabpanel" aria-labelledby="nav-view-tab" tabIndex={0}>
                      </div>
                      <div className="tab-pane fade" id="nav-offer" role="tabpanel" aria-labelledby="nav-offer-tab" tabIndex={0}>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                  <h3>Match Details</h3>
                  <div className="d-flex flex-column pt-3">
                    <div className="comment advmatch w-100">
                      <div className="showMatchClass"><span className="font-bold">Match using: </span></div>
                      <div className="form-controls">
                        <div className="col-6 form-group">
                          <label className="control-labels ">Community<em>*</em></label>
                          <Select
                            style={{
                              width: 160,
                            }}

                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={this.handleCommunityMatch}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.communities}
                          />
                         
                        </div>
                        <div className="col-6 form-group">
                          <label className="control-labels " htmlFor="propsubcommunity">Sub-Community</label>
                          <Select
                            style={{
                              width: 160,
                            }}
                            // mode={"multiple"}

                            showSearch
                            maxTagCount={"responsive"}
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={this.handleSubCommunityMatch}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.sub_communities}
                          />
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column">
                          <label>Budget</label>
                          <div className="d-flex flex-row">
                            <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" />
                            <span className="dash" style={{}}>-</span>
                            <input type="text" onChange={this.handleMaxBudget} className="currencyInput" id="matchingBudgetMax" placeholder="Max" />
                          </div>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column w-100">
                          <label>Beds</label>
                          <select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control select2 w-100 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value="Studio">Studio</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3} >3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="w-50 px-0 form-group d-flex flex-column">
                          <label>Property For</label>
                          <select onChange={this.handlePropertForMatch} id="matchingPfor" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value="rent">Rent</option>
                            <option value="sale">Sale</option>
                          </select>
                        </div>
                        <div className="w-50 px-0 form-group d-flex flex-column">
                          <label>Type</label>
                          <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            {this.state.propertytypes.map((propertytype) => {
                              return <option value={propertytype.id}>{propertytype.title}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column w-100">
                          <label>Added Date</label>
                          <select onChange={this.handleAddedDateMatch}  id="addedDateM" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value={1}>Last 24 Hours</option>
                            <option value={3}>Last 3 Days</option>
                            <option value={7}>Last 7 Days</option>
                            <option value={14}>Last 14 Days</option>
                            <option value={30}>Last 1 Month</option>
                            <option value={90}>Last 3 Month</option>
                            <option value={180}>Last 6 Month</option>
                            <option value={365}>Last 1 Year</option>
                          </select>
                        </div>
                      </div>
                      <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                    </div>
                    <div className="matchresults">
                      <div className="d-flex flex-row justify-content-between">
                        <label className="control-labels mr-4">Match with access</label>
                      </div>
                      <div className="matchresult">
                        {this.state.matchs.map((match) => {
                          return (
                            <a href className="matchitem">
                              <div className="matchinfo">
                                <div className="col-9">
                                  <p className="name">{match.owner}</p>
                                  <p className="address">{match.address}</p>
                                  <p className="price">AED {match.price}</p>
                                </div>
                                <div className="col-3">
                                  <p className="addeddate">
                                    <Moment fromNow>
                                      {match.added_date}
                                    </Moment>
                                  </p>
                                </div>
                              </div>
                              <div className="matchhistory">
                                <span className="time"></span>
                                <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                              </div>
                            </a>
                          )
                        })}
                
                      {this.state.matchs.length > 0?  <Link onClick={this.handleAllMatch} className="matchitem">

                        View All
                            
                          </Link>:null}

                      </div>
                    </div>
                   
                  </div>
                </div> {/* END Match*/}
                {/* OFFERS */}
                <div className="bg-white border-top offers p-4 mt-4">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4">Offers</h3>
                    <a href className="btn btn-link viewall">View All</a>
                  </div>
                  <div className="d-flex flex-column border-wrap">

                    {this.state.property_offers.map((property_offer) => {
                      return (
                        <a onClick={this.handleOfferView.bind(this, property_offer.views_id)} className="matchitem">
                          <div className="matchinfo">
                            <div className="col-9">
                              <p className="name">{property_offer.fullname}</p>
                              <p className="address">Offer: AED {property_offer.price}</p>
                              <p className="price">{property_offer.view_date}</p>
                            </div>
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"></button>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                    {this.state.property_offers.length > 0 ? <a className="matchitem viewall">
                      View All
                    </a> : null}
                  </div>
                </div> {/* END OFFERS */}
                {/* VIEWINGS */}
                <div className="bg-white border-top viewings p-4 mt-4">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4">Viewings</h3>
                    <a onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</a>
                  </div>
                  <div className="d-flex flex-column border-wrap">
                    {this.state.property_viewings.map((property_viewing) => {
                      return (
                        <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                          <div className="matchinfo">
                            <div className="col-9">
                              <p className="name">{property_viewing.fullname}</p>
                              <p className="address">Offer: AED {property_viewing.price}</p>
                              <p className="price">{property_viewing.view_date}</p>
                            </div>
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"></button>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                    {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                      View All
                    </a> : null}

                  </div>
                </div> {/* END VIEWINGS*/}

                        {/* Followup */}
                        <div className="bg-white border-top viewings p-4 mt-4">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mr-4">Follow Up</h3>
                    <a onClick={this.handleNewFollowUp} className="btn btn-link viewall">Add Followup</a>
                  </div>
                  <div className="d-flex flex-column border-wrap">
                    {this.state.followups.map((followup) => {
                      return (
                        <a onClick={this.handleViewFollowup.bind(this, followup.reminder_id)} className="matchitem">
                          <div className="matchinfo">
                            <div className="col-9">
                              
                              <p className="address">
                              {followup.remind_note.length > 40 ?
                                  `${followup.remind_note.substring(0, 40)}...` : followup.remind_note
                                }
                                </p>
                              <p className="price">{followup.remind_date}</p>
                            </div>
                            <div className="col-3">
                              <button type="button" className="btn btn-link addfeedback"></button>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                    {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                      View All
                    </a> : null}

                  </div>
                </div> {/* END Followup*/}
              </div>
            </div>
          </div>



        </Drawer>

        <div className="contentwrap" style={{
          minHeight: 380,
          background: "#fff",
        }}>
   
          <div className='ml-auto d-flex px-0'>
            <div className='container'>
              <Space direction="vertical">


                <Space wrap>

               
                <div className="d-flex flex-row justify-content-between my-2 w-100">
                    <h3 className="mb-0">Access Leads</h3>
                    <div  className="d-flex flex-row justify-content-between">
                
                        </div>
                    </div>
                  <Modal width={1000} title="Filter" open={this.state.isFilterOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <hr />
                    <div className='row'>
                      <div className='col-md-5'>
                        <div className='form-group'>
                          <label>Tilte</label>
                          <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                        </div>
                      </div>
                    </div>
                    <div className='mt-4' />


                    <Select

                      showSearch
                      style={{
                        width: 300,
                      }}
                      placeholder="add filter"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.filter_options}
                      onChange={this.handleAddRow}
                    //   onChange={add()}
                    />








                    {this.state.rows.map((item, idx) => (
                      <div >
                        {item.id == 1 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Contact type</label>
                            <Select
                              mode="multiple"
                              style={{
                                width: '100%',
                              }}

                              placeholder="Please select"
                              onChange={this.handleContactType}
                              options={this.state.contact_types}
                            />
                          </div>
                        </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 2 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Community</label>
                            <Select
                              style={{
                                width: 300,
                              }}
                              showSearch
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={this.handleCommunity}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            />

                          </div>
                        </div>      <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Sub community</label>
                              <Select
                                style={{
                                  width: 300,
                                }}
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                onChange={this.handleSubCommunity}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.sub_communities}
                              />
                            </div>
                          </div><div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div></div> : null}
                        {item.id == 3 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Price</label>
                              <Select

                                style={{
                                  width: '100%',
                                }}

                                placeholder="Please select"
                                onChange={this.handlePriceFirstValue_id}
                                options={this.state.price_options}
                              />
                            </div>

                            <div className='mt-2'>
                              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input type="text" style={{ width: '150px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                                <input type="text" style={{ width: '150px' }} className="form-control ml-1" onChange={this.handlePriceMax} placeholder="max" />
                              </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 4 ? <div className='row'>

                          <div className='col-md-4'>
                            <label>Bedrooms</label>
                            <Select

                              style={{
                                width: '100%',
                              }}

                              placeholder="Please select"
                              onChange={this.handleBedRooms_id}
                              options={this.state.bedroom_filter_options}
                            />
                            <div className='mt-2'>
                              <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleBedRoomsValue} placeholder="filter..." />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div> </div> : null}

                        {item.id == 5 ?
                          <div className='row'>
                            <div className='col-md-4'>
                              <label>Created</label>


                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                  More than
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Less than
                                </label>
                              </div>

                              <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input type="text" className="form-control" onChange={this.handleDateValue} />
                                <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                  <option value="1">days</option>
                                  <option value="2">weeks</option>
                                  <option value="3">months</option>
                                  <option value="4">years</option>
                                </select>
                              </div>

                            </div>
                            <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={this.handleRemoveSpecificRow(idx, item.id)}
                            >
                              Remove
                            </button></div></div> : null}
                        {item.id == 6 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Stage</label>

                              <Select
                                mode="multiple"

                                style={{
                                  width: '100%',
                                }}

                                placeholder="Please select"
                                onChange={this.handleStages}
                                options={this.state.stages}
                              />

                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}
                        {item.id == 7 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Furnished / Unfurnished</label>
                              <Select
                                mode="multiple"
                                style={{
                                  width: '100%',
                                }}

                                placeholder="Please select"
                                onChange={this.handleFurnishedTypes}
                                options={this.state.furnished_types}
                              />

                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}


                      </div>
                    ))}




                  </Modal>

                  {/* edit filter */}

                  <Modal width={1000} title="Filter" open={this.state.isFilterEditOpen} onOk={this.handleEditOk} onCancel={this.handleEditCancel}>
                    {this.state.filter_id}
                    <hr />
                    <div className='row'>
                      <div className='col-md-5'>
                        <div className='form-group'>
                          <label>Tilte</label>
                          <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                        </div>
                      </div>
                    </div>
                    <div className='mt-4' />


                    <Select

                      showSearch
                      style={{
                        width: 300,
                      }}
                      placeholder="add filter"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.filter_options}
                      onChange={this.handleAddRow}
                    //   onChange={add()}
                    />

                    {this.state.rows.map((item, idx) => (
                      <div >
                        {item.id == 1 && this.state.default_contact_types_value.length > 0 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Contact type</label>
                            <Select
                              mode="multiple"
                              style={{
                                width: '100%',
                              }}
                              defaultValue={this.state.default_contact_types_value}

                              placeholder="Please select"
                              onChange={this.handleContactType}
                              options={this.state.contact_types}
                            />
                          </div>
                        </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 2 && this.state.community_value_id > 0 ? <div className='row'>  <div className='col-md-4'>
                          <div className='form-group'>
                            <label>Community</label>
                            <Select
                              style={{
                                width: 300,
                              }}
                              showSearch
                              defaultValue={this.state.community_value_id}
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={this.handleCommunity}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            />

                          </div>
                        </div>      <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Sub community</label>

                              <select value={this.state.sub_community_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                {this.state.sub_communities.map((sub_comm) => (
                                  <option value={sub_comm.value}>{sub_comm.label}</option>
                                ))}


                              </select>

                              {/* <Select
                       style={{
                        width: 300,
                      }}
                    showSearch
                    value={this.state.sub_community_value_id}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleSubCommunity}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.sub_communities}
                /> */}
                            </div>
                          </div><div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div></div> : null}
                        {item.id == 3 && this.state.price_first_value_id > 0 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Price</label>

                              <select value={this.state.price_first_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Less than or equals</option>
                                <option value="5">Greater than</option>
                                <option value="6">Greater than or equals</option>
                                <option value="7">In range</option>
                              </select>

                            </div>

                            <div className='mt-2'>
                              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input type="text" style={{ width: '150px' }} className="form-control" value={this.state.price_min} onChange={this.handlePriceMin} placeholder="min" />
                                <input type="text" style={{ width: '150px' }} className="form-control ml-1" value={this.state.price_max} onChange={this.handlePriceMax} placeholder="max" />
                              </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.price_first_value} onChange={this.handlePriceFirst} placeholder="filter..." />}
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>
                        </div> : null}
                        {item.id == 4 && this.state.bed_rooms_value > 0 ? <div className='row'>

                          <div className='col-md-4'>
                            <label>Bedrooms</label>
                            <select value={this.state.bed_rooms_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleBedRooms_id} >
                              <option value="1">Equals</option>
                              <option value="2">Not equal</option>
                              <option value="3">Less than</option>
                              <option value="4">Greater than</option>

                            </select>
                            <div className='mt-2'>
                              <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' defaultValue={this.state.bed_rooms_value} onChange={this.handleBedRoomsValue} placeholder="filter..." />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div> </div> : null}

                        {item.id == 5 && this.state.date_value_id > 0 && this.state.date_value > 0 ?
                          <div className='row'>
                            <div className='col-md-4'>
                              <label>Created</label>


                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                  More than
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Less than
                                </label>
                              </div>

                              <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input type="text" className="form-control" onChange={this.handleDateValue} />
                                <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                                  <option value="1">days</option>
                                  <option value="2">weeks</option>
                                  <option value="3">months</option>
                                  <option value="4">years</option>
                                </select>
                              </div>

                            </div>
                            <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={this.handleRemoveSpecificRow(idx, item.id)}
                            >
                              Remove
                            </button></div></div> : null}
                        {item.id == 6 && this.state.default_stage_value.length > 0 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Stage </label>

                              <Select
                                mode="multiple"
                                style={{
                                  width: '100%',
                                }}
                                defaultValue={this.state.default_stage_value}

                                placeholder="Please select"
                                onChange={this.handleStages}
                                options={this.state.stages}
                              />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}

                        {item.id == 7 && this.state.default_furnished_types_value.length > 0 ? <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <label>Furnished / Unfurnished </label>

                              <Select
                                mode="multiple"
                                style={{
                                  width: '100%',
                                }}
                                defaultValue={this.state.default_furnished_types_value}

                                placeholder="Please select"
                                onChange={this.handleFurnishedTypes}
                                options={this.state.furnished_types}
                              />
                            </div>
                          </div>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 mt-4'> <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          >
                            Remove
                          </button></div>

                        </div> : null}


                      </div>
                    ))}




                  </Modal>




                </Space>
              </Space>
            </div>
          </div>


          <div class="table-responsive tablelistwrap container" >
            <ReactDragListView.DragColumn onDragEnd={this.onDragEnd}
              nodeSelector="th">

              <Table onChange={handleTableChange}
                rowSelection={rowSelection}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: this.handleOpenDrawer.bind(this,record.id), // click row
                //   };
                // }}
                // scroll={{ x: 2500 }}
                // size="small"
                loading={this.state.isLoading}
                // className="table-striped mytable"
                pagination={{
                  pageSizeOptions: ['10', '20', '50', '100'],
                  total:
                    this.state?.leads?.total > 0 &&
                    this.state.leads.total,
                  pageSize: this.state.leads.per_page,
                  current: this.state.leads.current_page,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                bordered
                dataSource={
                  this.state?.leads?.data
                    ? this.state?.leads?.data
                    : []
                }
                rowKey={(record) => record}
              />
            </ReactDragListView.DragColumn>

          </div>
        </div>

      </div>




    )
  }
}



export default  Access_Leads

