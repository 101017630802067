const initialState = {
    restcrict_data: {},
  };
  const Restrict_Access_Reducer = (state = initialState, action) => {
   
    switch (action.type) {
      case "SET_RESTRICT_LEAD":
        return {
          ...state,
          restcrict_data: action?.payload ? action?.payload : {},
       
        };
  
  
      default:
        return state;
    }
  };
  
  export default Restrict_Access_Reducer;
  

  