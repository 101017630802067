import React, { Component } from 'react'
import { Modal, Button, Input,Select, Row, Col, Avatar, DatePicker, Tabs,Skeleton,Carousel, Empty,Radio,Checkbox } from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {UserOutlined, CalendarOutlined, CaretRightOutlined, CaretLeftOutlined, EyeFilled} from '@ant-design/icons';
import './dashboard.css'
import dayjs from 'dayjs';
import { TabList, TabPanel } from 'react-tabs';
import {baseurlImg, liveUrl} from '../../components/BaseUrl';
import Moment from 'react-moment';
import {Link} from 'react-router-dom'

const Option = Select.Option;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

class Dashboard extends Component {
constructor(props){
  super(props)
    this.state = { 
      visible: true ,
      ref_nums: [],
      selected_user_role:"",
      agents:[],
      selectedAgents:"",
      clients:[],
      target:"",
      deals:"",
      new_listings:"",
      new_viewings:"",
      listing_1:"",
        feedback_note: [],
      listing_2:"",
      listing_3:"",
      leads_1:"",
      leads_2:"",
      leads_3:"",
      //statistics
      isPledgeLoading:false,
      isListingsLoading:false,
      isHExpiringBuyerLoading:false,
      isExpireListingLoading:false,
      isMoveInDateLeadLoading:false,
      isMoveInDateListingLoading:false,
      isMatchedListingLoading:false,
      isMatchedLeadLoading:false,
        offerFeedbackData: [],
      pledge:{},
      listings:[],
      expiring_buyers:[],
      expiring_listings_buyers:[],
      move_in_date_leads:[],
      move_in_date_listings:[],
      matched_listings:[],
      matched_leads:[],
      all_property_images:[],
      matched_listings_count:0,
      matched_leads_count:0,
      pledge_count:0,
      listings_count:0,
      expiring_leads_count:0,
      expiring_listings_count:0,
      move_in_date_leads_count:0,
      move_in_date_listings_count:0,


      isShareClientPopup:false,
        sharePropertyData:null,

      modalVisible:false,
      modalClientVisible: false,
      modalLoading: false,
      sSelectUpadateModalValue: 1,
      sSelectClientUpadateModalValue: 1,
    
      isOAforcefeedback:false,

    }

  }

  componentDidMount(){
    // 
    const data = {selectedAgents:this.state.selectedAgents}
      this.fetchOfferFeedbackData();
    this.fetchAgents(data);
    this.fetchPledge(data);
    this.fetchListings(data);  
    this.fetchExpiringBuyer(data); 
    this.fetchExpiringListingsBuyer(data); 
    this.fetchMoveInDateLeads(data);
    this.fetchMoveInDateListings(data);  
    this.fetchMatchListings(data); 
    this.fetchMatchLeads(data);
    this.fetchRequestViewing();
  }


  fetchAgents =() =>{
    API.get("/all_agents")
    .then((response) =>{
        if(response.data.success){
            this.setState({agents:response.data.agents})
        }
    })

  }


  fetchPledge =(data) =>{
    this.setState({isPledgeLoading:true})
    API.post("/get_pledge",data)
    .then((response) =>{
        if(response.data.success){
            this.setState({isPledgeLoading:false,pledge:response.data.pledge,pledge_count:response.data.pledge_count})
        }else{
            this.setState({isPledgeLoading:false}) 
        }
    })

  }

  fetchListings =(data) =>{
    this.setState({isListingsLoading:true})
    API.post("/get_listings",data)
    .then((response) =>{
        if(response.data.success){
            this.setState({isListingsLoading:false,listings:response.data.listings,listings_count:response.data.listings_count})
        }else{
            this.setState({isListingsLoading:false})
        }
    })

  }
  fetchExpiringBuyer =(data) =>{
    this.setState({isHExpiringBuyerLoading:true})
    API.post("/get_expiring_buyer",data)
    .then((response) =>{
        if(response.data.success){
            this.setState({isHExpiringBuyerLoading:false,expiring_buyers:response.data.leads,expiring_leads_count:response.data.expiring_leads_count})
        }else{
            this.setState({isHExpiringBuyerLoading:false})
        }
    })

  }
  fetchExpiringListingsBuyer =(data) =>{
    this.setState({isExpireListingLoading:true})
    API.post("/get_expiring_listings_buyer",data)
    .then((response) =>{
        if(response.data.success){
            this.setState({isExpireListingLoading:false,expiring_listings_buyers:response.data.listings,expiring_listings_count:response.data.expiring_listings_count})
        }else{
            this.setState({isExpireListingLoading:false})
        }
    })

  }
  fetchMoveInDateLeads =(data) =>{
    this.setState({isMoveInDateLeadLoading:true,})
    API.post("/get_moving_date",data)
    .then((response) =>{
        if(response.data.success){
            this.setState({isMoveInDateLeadLoading:false,move_in_date_leads:response.data.leads,move_in_date_leads_count:response.data.move_in_date_leads_count})
        }else{
            this.setState({isMoveInDateLeadLoading:false,move_in_date_leads:response.data.leads,move_in_date_leads_count:response.data.move_in_date_leads_count})
        }
    })

  }

  fetchMoveInDateListings =(data) =>{
    this.setState({isMoveInDateListingLoading:true})
    API.post("/get_listings_moving_date",data)
    .then((response) =>{
        if(response.data.success){
            this.setState({isMoveInDateListingLoading:false,move_in_date_listings:response.data.listings,move_in_date_listings_count:response.data.move_in_date_listings_count})
        }else{
            this.setState({isMoveInDateListingLoading:false})
        }
    })

  }

  fetchMatchListings =(data) =>{
    this.setState({isMatchedListingLoading:true})
    API.post("/get_matched_listings",data)
    .then((response) =>{
        
        if(response.data.success){
            
            this.setState({isMatchedListingLoading:false,matched_listings:response.data.listings,
                matched_listings_count:response.data.listings_count,              
                  
        })
        }else{
            this.setState({isMatchedListingLoading:false})
        }
    })

  }

  fetchMatchLeads =(data) =>{
    this.setState({isMatchedLeadLoading:true})
    API.post("/get_matched_leads",data)
    .then((response) =>{
       
        if(response.data.success){
            
            this.setState({isMatchedLeadLoading:false,matched_leads:response.data.leads,
                matched_leads_count:response.data.leads_count,              
        })
        }else{
            this.setState({isMatchedLeadLoading:false})
        }
    })

  }

    fetchRequestViewing = () => {
    API.get("/get_share_property_status")
    .then((response) =>{
        if(response.data.success){
            this.setState({isShareClientPopup:response.data.status})
            this.setState({sharePropertyData:response.data.property_data})
        }else{
            this.setState({isShareClientPopup:false})
        }
    })

  }

    fetchOfferFeedbackData = () => {
    API.get("/get_order_accepted_feedback")
    .then((response) =>{
        if(response.data.offer_feedback && response.data.offer_feedback.length > 0){
            this.setState({offerFeedbackData:response.data.offer_feedback})
            this.setState({isOAforcefeedback:true})
        }
    }).catch((e) =>{
        toast.success("Something Went Wrong")
    })

  }


    handleTarget = (e) => {
    
     this.setState({target:e.target.value})
    }

    handleDeal = (e) => {
    
      this.setState({deals:e.target.value})
     }

     handleNewListings = (e) => {
    
      this.setState({new_listings:e.target.value})
     }

     handleNewViewings = (e) => {
    
      this.setState({new_viewings:e.target.value})
     }


    onSearchRefNo = (e) => {
      
     
      if (!e || e == '' || e == null) {
        this.setState({ ref_nums: [] })
        return
      }
      API.get("/get_listings/" + e)
        .then((response) => {
          if (response.data.success) {
            this.setState({ ref_nums: response.data.ref_nums })
          }
        })
    }


    handleListingOne = (e) => {
      if (!e || e == '' || e == null || e == undefined) {
        this.setState({ ref_nums: [] })
        return
      }
     this.setState({listing_1:e})
    }
    handleListingTwo = (e) => {
      if (!e || e == '' || e == null || e == undefined) {
        this.setState({ ref_nums: [] })
        return
      }
     this.setState({listing_2:e})
    }
    handleListingThree = (e) => {
      if (!e || e == '' || e == null || e == undefined) {
        this.setState({ ref_nums: [] })
        return
      }
     this.setState({listing_3:e})
    }

    onSearchClients = (e) => {
      
      if (!e || e == '' || e == null) {
       
        return
      }

      API.get("/get_leads_clients/" + e)
        .then((response) => {
          if (response.data.success) {
            this.setState({ clients: response.data.clients })
          }
        })
    }

    handleLeadsOne = (e) => {
      if (!e || e == '' || e == null || e == undefined) {
        this.setState({ clients: [] })
        return
      }
     this.setState({leads_1:e})
    }
    handleLeadsTwo = (e) => {
      if (!e || e == '' || e == null || e == undefined) {
        this.setState({ clients: [] })
        return
      }
     this.setState({leads_2:e})
    }
    handleLeadsThree = (e) => {
      if (!e || e == '' || e == null || e == undefined) {
        this.setState({ clients: [] })
        return
      }
     this.setState({leads_3:e})
    }

    showModal = () => {
        this.setState({
          visible: true,
        });
      }
      handleOk = () => {
       const data = {target:this.state.target,deals:this.state.deals,
                     listings:this.state.new_listings,viewings:this.state.new_viewings,
                      listing_1:this.state.listing_1,listing_2:this.state.listing_2,
                      listing_3:this.state.listing_3,leads_1:this.state.leads_1,
                      leads_2:this.state.leads_2,leads_3:this.state.leads_3}

                      API.post("/pledge",data)
                      .then((response) => {
                        if (response.data.success) {
                          this.props.setUser(response.data) 

                          this.setState({ visible: false, })
                        }
                      })

                      
       
      }
      handleCancel = (e) => {
        this.setState({
          visible: false,
        });
      }

        isValidHttpUrl = (string) =>{
        try {
          const url = new URL(string);
          return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
          return false;
        }
      }
      handlePledge = () =>{
        
        const data = {selectedAgents:this.state.selectedAgents,isPledgeStatistics:true}
        this.props.setPledge(data) 
        window.open("/pledge", "_blank");
      }

      handleExpiringLeads =() =>{
        const data = {selectedAgents:this.state.selectedAgents,isExpiringLead:true}
        this.props.setExpiringLead(data)
        window.open("/leads", "_blank");

      }
      handleExpiringListings =() =>{
       
        const data = {selectedAgents:this.state.selectedAgents,isExpiringListing:true}
        this.props.setExpiringListing(data) 
        window.open("/all_listings", "_blank");

      }
      handleMoveInDateLeads =() =>{
        const data = {selectedAgents:this.state.selectedAgents,isMoveInDateLead:true}

        this.props.setMoveInDateLead(data) 
        window.open("/leads", "_blank");
      }
      handleMoveInDateListings =() =>{
        const data = {selectedAgents:this.state.selectedAgents,isMoveInDateListing:true}

        this.props.setMoveInDateListing(data) 
        window.open("/all_listings", "_blank");
      }

      handleMatchedLeads=() =>{
        const data = {selectedAgents:this.state.selectedAgents,isMatchedLead:true}

        this.props.setMatchedLead(data) 
        window.open("/leads", "_blank");
      }

    getImageArray = (imageString) => {
        if (imageString == "" || imageString == null){
            return [];
        }else{
            let images = imageString.split(",");
            return images;
        }

    }

      handleMatchedListing = () =>{
        const data = {selectedAgents:this.state.selectedAgents,isMatchedListing:true}

        this.props.setMatchedListing(data) 
        window.open("/all_listings", "_blank");
      }

      handleAgent = (e) =>{

        API.get("/get_agent_team/"+e)
        .then((res) =>{
            if(res.data.success){
                this.setState({selected_user_role:res.data.user_role})
            }
        } )
       
        this.setState({selectedAgents:e})
        const data = {selectedAgents:e}
        this.fetchAgents(data);
        this.fetchPledge(data);
        this.fetchListings(data);  
        this.fetchExpiringBuyer(data); 
        this.fetchExpiringListingsBuyer(data); 
        this.fetchMoveInDateLeads(data);
        this.fetchMoveInDateListings(data);  
        this.fetchMatchListings(data); 
        this.fetchMatchLeads(data);
     

      }

      renderStatus = (status) =>{
        var pStatus = 'None'
        var color =""
                          switch (status) {
                            case 1:
                              pStatus = 'Uncontacted'
                              color = "pstat uncontacted"
                              break;
                            case 2:
                              pStatus = 'Working'
                              color = 'pstat working'
                              break;
                            case 3:
                              pStatus = 'Hot'
                              color = 'pstat hot'
                              break;
                            case 4:
                              pStatus = 'Follow Up'
                              color = 'pstat followup'
                              break;
                            case 5:
                              pStatus = 'Dead'
                              color = 'pstat dead'
                              break;
                            case 6:
                              pStatus = 'Offer'
                              color = 'offer'
                              break;
                              case 7:
                                pStatus = 'Deal signed'
                                color = 'pstat recruitment'
                                break;
                                case 8:
                                    pStatus = 'Viewings'
                                    color = "pstat working "
                                    break;
                       
                            default:
                              pStatus = 'None'
                              break;
                          }
                          return (<div className={color}>
                          <p>{pStatus}</p>
                      </div> )
      }

handleSharePropertyClose=()=>{
    // this.setState({isShareClientPopup:false})
}
      fHandleModalCancel = () => {
        this.setState({currentVendor: {}})
        this.setState({sSelectUpadateModalValue: 1})
        this.setState({
          isfollowupOpen: false,
          modalVisible: false
        });
      };
    
      fHandleClientModalClosed = () => {
        if (this.clientValidate()) {
          this.addClientNote()
          this.setState({currentClient: {}})
          this.setState({sSelectClientUpadateModalValue: 1})
          this.setState({
            isfollowupOpen: false,
            modalClientVisible: false
          });
        }
      };

      openVendorNotesSection = (text, item) => {
        this.setState({currentVendor: text})
        this.setState({sSelectUpadateModalValue: 1})
        this.setState({ modalVisible: true })
      }

      onChangeAddNewFollowup = (e) => {
        this.setState({add_new_followup: e.target.checked})
      }

    onChangeFeedback = (feedback_data, e) => {
        let data = {
            "refno": feedback_data.refno,
            "note": e.target.value,
            "listing_id": feedback_data.listing_id,
            "leads_id": feedback_data.leads_id,
            "community": feedback_data.community,
            "subcommunity": feedback_data.subcommunity,
            "unitno": feedback_data.unitno,
            "price": feedback_data.price,
        }
        const { feedback_note } = this.state;
        const { refno, note, listing_id, leads_id, community, subcommunity, unitno, price } = data;
        const matchingIndex = feedback_note.findIndex(
            (item) => item.refno === refno
        );
        if (matchingIndex !== -1) {
            feedback_note[matchingIndex].note = note;
        } else {
            feedback_note.push({ refno, note, listing_id, leads_id, community, subcommunity, unitno, price });
        }
        this.setState({ feedback_note });
    }

      handleNewFollowupDate = (data, dateString) => {
        this.setState({ new_followup_date: dateString })
      }
    
      handleNewFollowupNote = (e) => {
        this.setState({ new_followup_note: e.target.value })
      }
      fHandleModalClosed = () => {
        if (this.validate()) {
          this.addVendorNote()
          this.setState({currentVendor: {}})
          this.setState({sSelectUpadateModalValue: 1})
          this.setState({
            isfollowupOpen: false,
            modalVisible: false
          });
        }
      };
      validate() {
        let note = this.state.notes;
        let new_followup_date = this.state.new_followup_date;
        let add_new_followup = this.state.add_new_followup;
        let new_followup_note = this.state.new_followup_note;
        let validationerrors = {};
        let isValid = true;
    
    
        if (note == null || note == "") {
          isValid = false;
          validationerrors["note"] = "Note is required.";
        }
    
        if (add_new_followup == true){
          if (new_followup_date == null || new_followup_date == "") {
            isValid = false;
            validationerrors["date"] = "Date is required.";
          }
          if (new_followup_note == null || new_followup_note == "") {
            isValid = false;
            validationerrors["new_followup_note"] = "New Followup Note is required.";
          }
        }
    
        this.setState({
          validationerrors: validationerrors
        });
    
        return isValid;
      }
      addVendorNote = () => {
        let data = {
          note_type : (this.state.sSelectUpadateModalValue == 1) ? "followup_p" : "followup_n",
          listing_id: this.state.currentVendor.listing_id,
          agent_id: this.state.currentVendor.user_id,
          note: this.state.notes,
          followup_id: this.state.currentVendor.id,
          page: 1,
          perPage: 10
        }
      if (this.state.add_new_followup == true){
        data.new_followup = true;
        data.new_followup_data = {
          leads_id : 0,
          listing_id : this.state.currentVendor.listing_id,
          contacts_id : this.state.currentVendor.contacts_id,
          followup_date : this.state.new_followup_date,
          followup_note : this.state.new_followup_note,
          type : 1,
        }
      }
  
        this.setState({ isLoadingVendors: true });
        API.post("/followups-vendor-notes", data)
            .then((response) => {
              if (response.data.success) {
                // this.setState({
                //   sListOfVendors: response.data.vendor,
                //   sListOfVendorsPagination: response.data.vendor,
                //   isLoadingVendors: false,
                // });
              }
            })
    }

 
    
      fOnSelectUpdateModalValue = (e) => {
        this.setState({ sSelectUpadateModalValue: e.target.value });
      };

      handleisOAforcefeedbackOpen = () => {
        this.setState({isOAforcefeedback:true})
      }
      handleisOAforcefeedbackClose = () => {
        // this.setState({isOAforcefeedback:false})
      }

    sendEmailForFeedback = (data) => {
        API.post("/send_email_for_feedback", data)
            .then((response) =>{
               console.log("email send")
            })
      }

    onAddFeedback = () => {
          if (this.state.feedback_note.length > 0){
              const data = {
                  feedback_note: this.state.feedback_note
              }
              API.post("/add_offer_feedback_note", data)
                  .then((response) =>{
                      if(response.data.success){
                          toast.success("Feedback Successfully Saved")
                          this.setState({isOAforcefeedback:false})
                          this.setState({feedback_note:[]})
                          this.sendEmailForFeedback(data);
                          if(response.data.offer_feedback && response.data.offer_feedback.length > 0){
                              this.setState({offerFeedbackData:response.data.offer_feedback})
                              this.setState({feedback_note:[]})
                              this.setState({isOAforcefeedback:true})
                          }
                      }else{
                          toast.success("Something Went Wrong")
                      }
                  }).catch((e) =>{
                  toast.success("Something Went Wrong")
              })
          }else{
              toast.error("Please add feedback")
          }
    }
    
    

  render() {
   
    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let user = userdata.auth.user;
    let role_name = userdata.auth.user.role_name;

    const listings = this.state.listings
    listings.sort((a, b) =>
    a.date > b.date ? 1 : -1);
  
    const props = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CaretRightOutlined />,
            prevArrow: <CaretLeftOutlined />
      };

    const curr = new Date;
    const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));

    const price = this.state.pledge.target;
    // changed
  
    return (
        <div className='dashboardwrap'>
            <ToastContainer />
           
            {/* OFFER - Force Popup 
            If not moved to deal stage after 4 days total then force feedback popup and what is said is sent to management.
            */}
             <Modal title={'Offer Accepted No Feedback'} open={this.state.isOAforcefeedback} width={600} onCancel={this.handleisOAforcefeedbackClose} centered
                footer={
                    <>
                        <Button type='primary' onClick={this.onAddFeedback}>Save</Button>
                    </>
                }
             >
                <Row className='w-100'>
                    <Col span={24}><p>You have a pending feedback from 4 days ago that is in Offer Accepted status.</p></Col>
                    {this.state.offerFeedbackData.map((offer_feedback) => (
                       <>
                           <Col span={12}>
                               <ul className='w-100 pl-0'>
                                   <li><span className='font-bold'>{offer_feedback.refno}</span></li>
                                   <li className='font-12'>{offer_feedback.unitno}, {offer_feedback.community}, {offer_feedback.subcommunity}</li>
                                   <li className='font-12'>{offer_feedback.price} AED</li>
                               </ul>
                           </Col>
                           <Col span={12}>
                               <div className='form-group mb-0'>
                                   <label>Add Feedback</label>
                                   <TextArea rows={4} onChange={this.onChangeFeedback.bind(this, offer_feedback)}></TextArea>
                               </div>
                           </Col>
                       </>
                    ))}
                </Row>
            </Modal>

            {/* FORCE POPUP when the lead client replied YES 
                will not close until book a viewing or they take action
                Book a viewing button will open the viewing form or go to lead page then open viewing form.
            */}
            <Modal title={'Property Share Approved'} open={this.state.isShareClientPopup} width={600} onCancel={this.handleSharePropertyClose} centered
                footer={
                    <>
                        <Button type='primary' className='btnprimary'>
                            <a className='btn btn-text px-0 d-flex align-items-center mt-3' href={liveUrl + "/leads/" + (this.state.sharePropertyData && this.state.sharePropertyData.leads_id)} >View Lead</a>
                            {/*View Lead*/}
                        </Button>
                    </>
                }
            >
                <Row className='w-100'>
                    <Col span={24}><p><span className='font-bold'>{this.state.sharePropertyData && this.state.sharePropertyData.lead_agent_name}</span> client has approved to book a viewing with your property</p></Col>
                    <Col span={24}>
                        <ul className='w-100 pl-0'>
                            <li><span className='font-bold'>{this.state.sharePropertyData && this.state.sharePropertyData.refno}</span></li>
                            <li>{this.state.sharePropertyData && this.state.sharePropertyData.unitno}, {this.state.sharePropertyData && this.state.sharePropertyData.community}. {this.state.sharePropertyData && this.state.sharePropertyData.subcommunity}</li>
                            <li>{this.state.sharePropertyData && this.state.sharePropertyData.price} AED</li>
                        </ul>
                    </Col>
                    <Col span={12}>
                        <ul className='w-100 pl-0'>
                            <li><p className='font-bold mb-0'>Client details:</p></li>
                            <li>{this.state.sharePropertyData && this.state.sharePropertyData.client_first_name} {this.state.sharePropertyData && this.state.sharePropertyData.client_last_name}</li>
                            <li>{this.state.sharePropertyData && this.state.sharePropertyData.client_mobile}</li>
                        </ul>
                    </Col>
                    <Col span={12}>
                        <ul className='w-100 pl-0'>
                            <li><p className='font-bold mb-0'>Best viewing time for the client</p></li>
                            <li>{this.state.sharePropertyData && this.state.sharePropertyData.note}</li>
                            {/*<li>Weekdays</li>*/}
                        </ul>
                    </Col>
                </Row>
            </Modal>





           <Modal
          onCancel={this.fHandleModalCancel}
          onOk={this.fHandleModalClosed}
          open={this.state.modalVisible}
          title="Updates"
        >
          <div className="d-flex flex-column w-100 mt-4">
            <div className="form-group">
              <Radio.Group
                onChange={this.fOnSelectUpdateModalValue}
                value={this.state.sSelectUpadateModalValue}
                className="d-flex flex-row"
              >
                <Radio value={1}>Add Call</Radio>
                <Radio value={2}>Add Note</Radio>
              </Radio.Group>
            </div>
            <div className="form-group">
              <label className="form-label">{
                this.state.sSelectUpadateModalValue == 1?
                "Add Call"
                :
                "Add Note"
              }</label>
              <TextArea
                rows={4}
                value={this.state.notes}
                onChange={this.addNotes}
                placeholder={
                  this.state.sSelectUpadateModalValue == 1?
                  "Add Call ..."
                  :
                  "Add Note ..."
                }
              />
              <div
                  className="text-danger">{this.state.validationerrors && this.state.validationerrors.note}
              </div>
            </div>
            <div className="form-group">
            <Checkbox checked={this.state.add_new_followup} onChange={this.onChangeAddNewFollowup}>
              Add New Followup
            </Checkbox>
            </div>
            {this.state.add_new_followup && <div className="form-group">
              <label className="form-label">Date and Time</label>
              <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  style={{width: '100%'}}
                  showTime
                  onChange={this.handleNewFollowupDate}/>
              <div
                  className="text-danger">{this.state.validationerrors && this.state.validationerrors.date}
              </div>
            </div>}

            {this.state.add_new_followup && <div className="form-group">
              <label className="form-label">New Followup Note</label>
              <TextArea rows={4} onChange={this.handleNewFollowupNote}/>
              <div
                  className="text-danger">{this.state.validationerrors && this.state.validationerrors.new_followup_note}
              </div>
            </div>}
          </div>
        </Modal>
            <Row gutter={16} className='dashrow-one'>
                <Col span={14}>
                    <Row gutter={12}>
                        <Col>
                            <Avatar size={80} icon={
                        <img
                          src={ 
                            this.isValidHttpUrl(user.profile_photo)? user.profile_photo:
                            baseurlImg +
                            "lpcrm/public/uploads/clients/2036/users/" +
                            user.profile_photo
                          }
                          style={{
                            width: "80px",
                            height: "80px",
                          }}
                        />
                      } />
                        </Col>
                        <Col className='d-flex flex-column justify-content-center pl-3'>
                            <h1 className='mb-0'>Hi, {user.name}</h1>
                            <p className='mb-0'>Here's your activity today, take a moment to have a look at this.</p>
                        </Col>
                       
                        {role ==1?       
                        <Col span={16} className='d-flex flex-column justify-content-center' style={{paddingRight:'24px'}}>
                            <div className='form-group flex-row align-items-center mb-0 mt-2 agentwrapfilter'>
                                <label className='mr-2 mb-0 font-bold' style={{whiteSpace:'nowrap'}}>Filter by Agent:</label>
                                <Select className='mr-2' allowClear
                                                showSearch
                                                value={this.state.community_filter}

                                                placeholder="Select agent"
                                                onChange={this.handleAgent}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={this.state.agents}
                                />
                            </div>
                        </Col>
                        :null}
                    </Row>
                </Col>
                <Col span={8} className='ml-auto' style={{paddingLeft: 33,cursor:'pointer'}} onClick={this.handlePledge}>
                    <div className='border dashpledge'>
                        <Row className='justify-content-between'>
                            <Col>
                                <h2 className='mb-0'>Your pledge for this week</h2>
                            </Col>
                            <Col>
                                <p className='weeksdate mb-0'><CalendarOutlined /> <span>{firstday.toDateString()} - {lastday.toDateString()}</span></p>
                            </Col>
                        </Row>
                        <Row>
                            {/* classnames: target=green|#25D366
                            nottarget=ref | #ff0000
                             */}
                               {this.state.isPledgeLoading?
                                    <Skeleton active />:<>
                                     <Col span={24}>
                                <p className='pledgecnt targetaed mb-2'>Target AED: <span className='nottarget'>{ price?price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED":0}</span></p>
                            </Col>
                            <Col span={8}>
                                <p className='pledgecnt'>Viewings: <span className='nottarget circle'>{this.state.pledge.viewings}</span><span>({this.state.pledge.viewings_done})</span></p>
                            </Col>
                            <Col span={8}>
                                <p className='pledgecnt'>Listings: <span className='target circle'>{this.state.pledge.listings}</span><span>({this.state.pledge.listings_done})</span></p>
                            </Col>
                            <Col span={8}>
                                <p className='pledgecnt'>Deals: <span className='nottarget circle'>{this.state.pledge.deals}</span><span>({this.state.pledge.deals_done})</span></p>
                            </Col>
                                    </>}
                           
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className='dashrow-two' span={24} gutter={24} type="flex">
                <Col span={9}> 
                {/* Today's Activity */}
                    <div className='border dashtodaysactivity'>
                        <Row className='toptitle'>
                            <Col span={24}>
                                <h2>Today's Activity</h2>
                            </Col>
                            <Col>
                                <p className='todaysdate'><CalendarOutlined /> <span><Moment format='dddd, DD MMMM YYYY' /></span></p>
                            </Col>
                            <Col>
                        
                            {listings && listings.length > 0?
                                <div className='schedulefilter'>
                                    <p className='stat viewing'>Viewings</p>
                                    <p className='stat followup'>Follow Up</p>
                                </div>
                            :null}
                            </Col>
                        </Row>
                        {listings && listings.length > 0?
                        <Row className='rowdashwrap'>
                            <Col span={24}>
                                {this.state.isListingsLoading?
                                    <Skeleton active />:<>
                                      {listings.map((listing) =>(
                                    <>
                                    {listing.type==1?  <a href="#" className='scheddetails'>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <div className='scheduletime'>
                                        <p className='font-bold mb-0'>
                                            <Link style={{textDecoration: 'none',color:'grey'}}  onClick={this.openVendorNotesSection}>
                                        <Moment format='hh:mm A' className='font-bold'>{listing.date}</Moment>
                                        </Link>
                                            
                                        </p>
                                        <p className='stat followup'>Followup</p>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='schedprop'>
                                    <p className='font-bold'><Link style={{textDecoration: 'none',color:'black'}} target="_blank" to={"/all_listings/"+listing.id}>{listing.refno}</Link></p>
                                        <p>{listing.unitno?listing.unitno+", ":null} {listing.property_project?listing.property_project+", ":null}</p>
                                        <p>{listing.subcommunity?listing.subcommunity+", ":null} {listing.community?listing.community:null}</p>
                                     
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className='schedclientvendor'>
                                        <p className='label'>Client:</p>
                                        
                                        <p><Link style={{textDecoration: 'none',color:'black'}} target="_blank" to={"/leads/"+listing.id}>{listing.firstname?listing.firstname:null} {listing.lastname?listing.lastname:null}</Link></p> 
                                    </div>
                                </Col>
                            </Row>
                            </a>:null}
                            {listing.type==2?  <a href="#" className='scheddetails'>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <div className='scheduletime'>
                                        <p className='font-bold mb-0'>
                                        <Link style={{textDecoration: 'none',color:'grey'}}  onClick={this.handleFollowupModalOpen}>
                                        <Moment format='hh:mm A' className='font-bold'>{listing.date}</Moment>
                                        </Link>
                                        
                                        </p>
                                        <p className='stat viewing'>Viewing</p>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='schedprop'>
                                    
                                        <p className='font-bold'><Link style={{textDecoration: 'none',color:'black'}} target="_blank" to={"/all_listings/"+listing.id}>{listing.refno}</Link></p>
                                        <p>{listing.unitno?listing.unitno+", ":null} {listing.property_project?listing.property_project+", ":null}</p>
                                        <p>{listing.subcommunity?listing.subcommunity+", ":null}{listing.community}</p>
                                        {/* Unit No, Property,
                                        Sub Community, Community */}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className='schedclientvendor'>
                                        <p className='label'>Client:</p>
                                        <p>{listing.firstname?listing.firstname:null} {listing.lastname?listing.lastname:null}</p> {/* Lead's name */}
                                    </div>
                                </Col>
                            </Row>
                            </a>:null}
                                    </>
                          
                                ))}
                                    
                                    </>}
                              
                             
                          
                            </Col>
                        </Row>
                        :
                        <div className='emptystate'>
                        <i class="bi bi-calendar4-event fs-3"></i>
                        <span className='font-12'>You don't have any schedule for today.</span>
                    </div>}

                    </div>
                </Col>
                <Col span={15}>
                    <Row className='justify-content-between' gutter={8}>
                        <Col span={12} style={{paddingRight:21}}>
                            <div className='border dashexpiringbuyers'>
                                <Row className='toptitle'>
                                    <Col span={24}>
                                        <h2>Expiring {this.state.selected_user_role == 9 || this.state.selected_user_role == 10 ?"Tenant":"Buyers"} </h2>
                                        <p>List of {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"} who have not received a call in more than 6 days</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className={this.state.expiring_leads_count>0?'bordercontent':'bordercontentempty'}>
                                    {this.state.isHExpiringBuyerLoading?
                                    <Skeleton active />:<>
                                    {this.state.expiring_leads_count>0?
                                        this.state.expiring_buyers.slice(0,2).map((expiring_buyer) =>(
                                            
                                        <a href="#" className='scheddetails'>
                                        <Row>
                                            <Col span={10}>
                                                <div className='buyersdetails'>
                                                    <div className='lastcall'>
                                                        <span>Last Call: </span>
                                                        <span>
                                                        <Moment fromNow>
                                                        {expiring_buyer.last_call?expiring_buyer.last_call:null}
                                                        </Moment>
                                                        </span>
                                                    </div>
                                                    <div className='schedprop'>
                                                        <p className='font-bold'>
                                                            <Link style={{textDecoration: 'none',color: 'black'}} target="_blank" to={"/leads/"+expiring_buyer.id} className='font-bold'>
                                                            {expiring_buyer.firstname?expiring_buyer.firstname:''} {expiring_buyer.lastname?expiring_buyer.lastname:''}
                                                            </Link>
                                                            </p>
                                                        <p>{expiring_buyer.mobile?expiring_buyer.mobile:''}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={14}>
                                                <div className='reqdetails'>
                                                    <p className='label'>Requirements:</p>
                                                    <p>{expiring_buyer.subcommunity}, {expiring_buyer.community}</p>
                                                    <p>
                                                        <span>{expiring_buyer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</span><span className='px-1'>|</span>
                                                        <span>{expiring_buyer.bedroom} Beds</span><span className='px-1'>|</span>
                                                        <span>{expiring_buyer.property_type}</span>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        </a>
                                        ))
                                        :<div className='emptystate'>
                                            <i class="bi bi-telephone-fill fs-3"></i>
                                            <span className='font-12'>You don't have expiring {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"} yet</span>
                                        </div>}
                                    </>}
                                    </Col>
                                    <Col span={24}>{this.state.expiring_leads_count>0?
                                        <Link className='dashviewall'  onClick={this.handleExpiringLeads}>View More ({this.state.expiring_leads_count}) {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"}</Link>
                                    :null}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='border dashmoveindate'>
                                <Row className='toptitle'>
                                    <Col span={24}>
                                        <h2>Move in Date</h2>
                                        <p>Your list of {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"} who plan to move within the next 30 days</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className={this.state.move_in_date_leads_count>0?'bordercontent':'bordercontentempty'}>
                                    {this.state.isMoveInDateLeadLoading?
                                    <Skeleton active />:<>
                                    {this.state.move_in_date_leads_count>0?
                                    this.state.move_in_date_leads.slice(0,2).map((move_in_date_lead) =>(
                                            <a href="#" className='scheddetails'>
                                            <Row>
                                                <Col span={8}>
                                                    <div className='buyersdetails'>
                                                        <div className='lastcall'>
                                                            <span>Move in : </span>
                                                            <span>
                                                            <Moment format="D MMM YYYY" withTitle>
                                                               {move_in_date_lead.move_in_date}
                                                            </Moment>
                                                               </span>
                                                        </div>
                                                        <div className='schedprop'>
                                                            <p className='font-bold'>{move_in_date_lead.firstname}  {move_in_date_lead.lastname} </p>
                                                            <p>{move_in_date_lead.mobile}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={13}>
                                                    <div className='reqdetails'>
                                                        <p className='label'>Requirements:</p>
                                                        <p>{move_in_date_lead.subcommunity}, {move_in_date_lead.community}</p>
                                                        <p> 
                                                            <span>{move_in_date_lead.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</span><span className='px-1'>|</span>
                                                            <span>{move_in_date_lead.bedroom} Beds</span><span className='px-1'>|</span>
                                                            <span>{move_in_date_lead.property_type}</span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col span={3}>
                                                {this.renderStatus(move_in_date_lead.lead_stage)}
                                                  
                                                </Col>
                                            </Row>
                                            </a>
                                        ))
                                        :<div className='emptystate'>
                                        <i class="bi bi-calendar-minus fs-3"></i>
                                        <span className='font-12'>You don't have {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"} who plan to move within the next 30 days yet</span>
                                    </div>}
                                      
                                    </>}
                                    </Col>
                                    <Col span={24}>{this.state.move_in_date_leads_count>0?
                                        <Link onClick={this.handleMoveInDateLeads} className='dashviewall'>View More ({this.state.move_in_date_leads_count} buyers)</Link>
                                    :null}</Col>
                                </Row>
                            </div>
                        
                        </Col>
                        <Col span={12} style={{paddingRight:21}}>
                            <div className='border dashexpiringlistings'>
                                <Row className='toptitle'>
                                    <Col span={24}>
                                        <h2>Expiring Listings</h2>
                                        <p className='font-10'>Coming to Market listings with no calls in more than 25 days</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className={this.state.expiring_listings_count>0?'bordercontent':'bordercontentempty'}>
                                    {this.state.isExpireListingLoading?
                                    <Skeleton active />:<>
                                      {this.state.expiring_listings_count>0?this.state.expiring_listings_buyers.slice(0,2).map((expiring_listings_buyer) =>(
                                <a href="#" className='scheddetails'>
                                <Row className='justify-content-between'>
                                    <Col span={14}>
                                        <div className='buyersdetails'>
                                            <div className='schedprop'>
                                                <p>{expiring_listings_buyer.refno}</p>
                                                <p className='font-bold'>{expiring_listings_buyer.unitno},{expiring_listings_buyer.property_project}</p>
                                                <p>{expiring_listings_buyer.subcommunity}, {expiring_listings_buyer.community}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className='reqdetails'>
                                            <p className='label'>Last Call:</p>
                                            <p> 
                                                <Moment fromNow>
                                                 {expiring_listings_buyer.last_call}
                                                </Moment>
                                                </p>
                                        </div>
                                    </Col>
                                </Row>
                                </a>
                                        ))
                                        :<div className='emptystate'>
                                        <i class="bi bi-view-list fs-3"></i>
                                        <span className='font-12'>You don't have expiring listings yet</span>
                                    </div>}
                                    </>
                                    }
                                        
                                    </Col>
                                    <Col span={24}>
                                    {this.state.expiring_listings_count>0?
                                        <Link className='dashviewall' onClick={this.handleExpiringListings}>View More ({this.state.expiring_listings_count} Listings)</Link>
                                        :null}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='border dashexpiringbuyers'>
                                <Row className='toptitle'>
                                    <Col span={24}>
                                        <h2>Properties Coming Available</h2>
                                        <p>Your Coming to Market listings in the next 60 days</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className={this.state.move_in_date_listings_count>0?'bordercontent':'bordercontentempty'}>
                                    {this.state.isMoveInDateListingLoading?
                                    <Skeleton active/>:<>
                                      {this.state.move_in_date_listings_count>0?this.state.move_in_date_listings.slice(0,2).map((move_in_date_listing) =>(
                                    <a href="#" className='scheddetails'>
                                    <Row className='justify-content-between'>
                                        <Col span={14}>
                                            <div className='buyersdetails'>
                                                <div className='schedprop'>
                                                    <p>{move_in_date_listing.refno}</p>
                                                    <p className='font-bold'>{move_in_date_listing.unitno}, {move_in_date_listing.property_project}</p>
                                                    <p>{move_in_date_listing.subcommunity}, {move_in_date_listing.community}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className='reqdetails'>
                                                <p className='label'>Available date::</p>
                                                <p>
                                                <Moment format="D MMM YYYY" withTitle>
                                                   {move_in_date_listing.avail_date}
                                                     </Moment>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    </a>
                                        ))
                                        :<div className='emptystate'>
                                        <i class="bi bi-house fs-3"></i>
                                        <span className='font-12'>You don't have properties coming available yet</span>
                                    </div>}
                                    </>}
                                    </Col>
                                    <Col span={24}>
                                        {this.state.move_in_date_listings_count>0?
                                        <Link onClick={this.handleMoveInDateListings} className='dashviewall'>View More ({this.state.move_in_date_listings_count} Listings)</Link>
                                        :null}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='dashrow-two' span={24} gutter={16}>
                <Col span={12}> 
                    <div className='border buyersnewlymatchproperties'>
                        <Row className='toptitle'>
                            <Col span={24}>
                                <h2>{this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"} with Newly Match Properties</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className='bordercontent'>
                            { this.state.isMatchedLeadLoading?
                                    <Skeleton active />:<>
                                        {this.state.matched_leads_count>0?this.state.matched_leads.slice(0,5).map((matched_lead) =>(
                        <a href="#" className='scheddetails'>
                        <Row className='justify-content-between'>
                            <Col span={5}>
                                <div className='buyersdetails'>
                                    <div className='schedprop'>
                                        <p className='font-bold'>{matched_lead.firstname} {matched_lead.lastname}</p>
                                        <p>{matched_lead.mobile}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='reqdetails'>
                                    <p className='label'>Requirements:</p>
                                    <p>{matched_lead.subcommunity}, {matched_lead.community}</p>
                                    <p>
                                        <span>{matched_lead.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</span><span className='px-1'>|</span>
                                        <span>{matched_lead.bedroom} Beds</span><span className='px-1'>|</span>
                                        <span>{matched_lead.property_type}</span>
                                    </p>
                                </div>
                            </Col>
                            <Col span={7}>
                                <div className='reqdetails'>
                                    <p className='label'>Match Listings:</p>
                                    <div className='justify-content-between matchlistingcount d-flex flex-row'>
                                        <p>Exact Match: <span>{matched_lead.matched_new}</span></p>
                                        {/* <p>Comparable: <span>103</span></p> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        </a>
                                ))
                                :<div className='emptystate'>
                                        <i class="bi bi-people fs-3"></i>
                                        <span className='font-12'>You don't have {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"} with newly match properties yet</span>
                                    </div>}
                                    </>
                            
                            }
                               
                            </Col>
                            <Col span={24}>
                            {this.state.matched_leads_count>0?
                                 <Link onClick={this.handleMatchedLeads}  className='dashviewall'>View More ({this.state.matched_leads_count} Listings)</Link>
                                :null}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={12}> 
                <div className='border propertiesnewlymatchbuyers'>
                        <Row className='toptitle'>
                            <Col span={24}>
                                <h2>Properties with Newly Match {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className='bordercontent'>
                            {this.state.isMatchedListingLoading?
                                    <Skeleton active />:<>
                                     {this.state.matched_listings_count>0?this.state.matched_listings.slice(0,4).map((matched_listing) =>(
                                            <a href="#" className='scheddetails'>
                                            <Row className='justify-content-between' gutter={16}>
                                                <Col span={6}>
                                                    <div className='imagewrap'>
                                                    {matched_listing.property_images==null ? 
                <>
                    <div className='noimageyet'><img src={"../../images/noimageyet.png"} className='defimg mx-auto' width="80px" style={{opacity:'0.6'}} /><span className='font-10'>Image pending</span></div>
                </>
            :
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItem:'center'}}>
                <Carousel arrows style={{width:'140px', height: '78.75px', overflow:'hidden'}} effect="fade" {...props} >
                    {this.getImageArray(matched_listing.property_images).map((image) => {
                        return <img src={baseurlImg + "/" + image} style={{width:'100%'}} />
                    })}
                </Carousel>
               
                </div>
                 }
                                                        {/* <img src='https://www.luxuryproperty.com//uploads/images/3-bedroom-townhouse-for-sale-mira_oasis-LP28083-272f7d1140f86a00.jpg' width={'100%'} /> */}
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                <div className='buyersdetails'>
                                                        <div className='schedprop'>
                                                            <p className='stat published'>Published</p>
                                                            <p className='font-bold'>{matched_listing.refno}</p>
                                                            <p>{matched_listing.unitno}</p>
                                                            <p>{matched_listing.subcommunity}, {matched_listing.community} </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className='reqdetails'>
                                                        <p className='label'>Match Leads:</p>
                                                        <div className='justify-content-between matchlistingcount d-flex flex-column'>
                                                            <p>Exact Match: <span>{matched_listing.new_matched}</span></p>
                                                            {/* <p>Comparable: <span>103</span></p> */}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </a>  
                                ))
                                :<div className='emptystate'>
                                <i class="bi bi-houses fs-3"></i>
                                <span className='font-12'>You don't have properties with newly match {this.state.selected_user_role == 9 || this.state.selected_user_role == 10?"Tenant":"Buyers"} yet</span>
                            </div>}
                                    </>}
                                
                            </Col>
                            <Col span={24}>
                            {this.state.matched_listings_count>0?
                                <Link onClick={this.handleMatchedListing} className='dashviewall'>View More ({this.state.matched_listings_count} Listings)</Link>                         
                                :null}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
  }
}



const mapDispatchToProps = dispatch =>{
  return {
    setUser: (data) => dispatch({type: "SET_USER",payload:data}),
    setPledge: (data) => dispatch({type: "SET_PLEDGE",payload:data}),
    setExpiringLead: (data) => dispatch({type: "SET_EXPIRING_LEAD",payload:data}),
    setExpiringListing: (data) => dispatch({type: "SET_EXPIRING_LISTING",payload:data}),
    setMoveInDateLead: (data) => dispatch({type: "SET_MOVE_IN_DATE_LEAD",payload:data}),
    setMoveInDateListing: (data) => dispatch({type: "SET_MOVE_IN_DATE_LISTING",payload:data}),
    setMatchedLead: (data) => dispatch({type: "SET_MATCHED_LEAD",payload:data}),
    setMatchedListing: (data) => dispatch({type: "SET_MATCHED_LISTING",payload:data}),
    
  }
}

export default connect(null,mapDispatchToProps)(Dashboard)
