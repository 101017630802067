const initialState = {
    access_data: {},
  };
  const Access_Reducer = (state = initialState, action) => {
    
    switch (action.type) {
      case "SET_ACCESS_LEAD":
        return {
          ...state,
          access_data: action?.payload ? action?.payload : {},
       
        };
  
  
      default:
        return state;
    }
  };
  
  export default Access_Reducer;
  

  