const initialState = {
    isExpiringLead: false,
    isMoveInDateLead:false,
    isMatchedLead:false,
    selectedAgents:""
  };
  const Expiring_Lead_Reducer = (state = initialState, action) => {
   
    
    
    switch (action.type) {
      case "SET_EXPIRING_LEAD":
        return {
          ...state,
          isExpiringLead: true,
          isMoveInDateLead:false,
          isMatchedLead:false,
          selectedAgents:action?.payload ? action?.payload.selectedAgents:""
        };
        case "SET_MOVE_IN_DATE_LEAD":
            return {
              ...state,
              isMoveInDateLead: true,
              isExpiringLead: false,
              isMatchedLead:false,
              selectedAgents:action?.payload ? action?.payload.selectedAgents:""
            };
            case "SET_MATCHED_LEAD":
              return {
                ...state,
                isMatchedLead: true,
                isMoveInDateLead:false,
                isExpiringLead: false,
                selectedAgents:action?.payload ? action?.payload.selectedAgents:""
              };
    

        case "REMOVE_LEAD_STATISTICS":
            return {
              ...state,
              isExpiringLead: false,
              isMoveInDateLead:false,
              isMatchedLead:false,
              selectedAgents:""
           
            };
  
            
      default:
        return state;
    }
  };
  
  export default Expiring_Lead_Reducer;
  

  