const initialState = {
    isExpiringListing: false,
    isMoveInDateListing:false,
    isMatchedListing:false,
    selectedAgents:""
  };
  const Expiring_Listing_Reducer = (state = initialState, action) => {
   
    
    
    switch (action.type) {
      case "SET_EXPIRING_LISTING":
        return {
          ...state,
          isExpiringListing: true,
          isMoveInDateListing:false,
          isMatchedListing:false,
          selectedAgents:action?.payload ? action?.payload.selectedAgents:""

        };
        case "SET_MOVE_IN_DATE_LISTING":
          return {
            ...state,
            isMoveInDateListing: true,
            isExpiringListing: false,
            isMatchedListing:false,
            selectedAgents:action?.payload ? action?.payload.selectedAgents:""

          };
          case "SET_MATCHED_LISTING":
            return {
              ...state,
              isMoveInDateListing: false,
              isExpiringListing: false,
              isMatchedListing:true,
              selectedAgents:action?.payload ? action?.payload.selectedAgents:""
  
            };

        case "REMOVE_LISTING_STATISTICS":
            return {
              ...state,
              isExpiringListing: false,
              isMoveInDateListing:false,
              isMatchedListing:false,
              selectedAgents:""

           
            };
  
  
      default:
        return state;
    }
  };
  
  export default Expiring_Listing_Reducer;
  

  