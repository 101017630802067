import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";

import 'antd/dist/reset.css';
import {
    Table,
    DatePicker,
    Tabs,
    Space,
    Button,
    Modal,
    Drawer,
    Select,
    Input,
    Upload,
    Image,
    Divider,
    Mentions,
    Spin
} from 'antd'
import {CloseOutlined,UploadOutlined, ClearOutlined, PlusCircleOutlined, SaveOutlined} from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EdiText from "react-editext";
import Swal from "sweetalert2";
// import './index.css'
import './listing.css'
import { baseLpUrlImg, baseurlImg } from '../../components/BaseUrl';
import { store } from '../../store';

const { Dragger } = Upload;
const {TextArea} = Input
const {TabPane} =Tabs;

let columns =[]
let property_type_array = ["Residential","Commercial"]
let property_for_array = ["sale","rent","tenanted","short-term"]
class OwnerListings extends Component {

    constructor(props){
        super(props);
        this.state ={
            isLoading:false,
            isviewingOpen:false,
            iseditviewingOpen: false,
            selectedFacilities:[],
            property_categories: [],
            lead_gen:false,
            just_listed:false,
            communities:[],
            bedrooms: [],
            allUsers:[],
            showhidefilter:false,
            property_type_filter:[],
            selectedCallUsers:[],
            selectedNoteUsers:[],
            selectedWhatsappUsers:[],
            selectedEmailUsers:[],
            selectedViewingUsers:[],
            selectedOfferUsers:[],
            selectedFollowupUsers:[],
            stage_details: "UTC",
            // call


            // note
            noteUsers:[],
            
            // whatsapp
            whatsappUsers:[],
            //email
            emailUsers:[],
            //offer
            offerUsers:[],
            // viewing
            viewingUsers:[],
            //fllowup
            followupUsers:[],
            files: [],
            sub_communities:[],
            properties:[],
            facilities:[],
            agents:[],
            propertytypes:[],
            property_images:[],
            image_files:null,
            community_match:"",
            sub_community_match:"",
            min_budget:0,
            max_budget:0,
            bedroom_match:"",
            property_for_match:"",
            property_type_match:"",
            added_date_match:"",
            date_morethan_option_match:true,
            date_lessthan_option_match:false,
            date_value_match:0,
            date_value_id_match:1,
            matchs:[],
            not_matchs:[],
            property_viewings:[],
            listing_offers:[],
            offer:{},
            showOffer:false,
            viewings: {},
            document_notes:[],
            documents:[],
            document_title:"",
            document_file:null,
            documentImageShow:"",
            property_note:"",
            value:"This is a sample text",
            // Loaders
            call_loader: false,
            note_loader: false,
            whatsapp_loader: false,
            email_loader: false,
            offer_loader: false,
            viewing_loader: false,
            followUp_loader: false,
            owner_listings:[],
            filters:[],
            rows: [],
            ref_nums: [],
            userClients: [],
            selectedClients:[],
            viewing_ref_no: "",
            viewing_agent: "",
            viewing_date: "",
            viewing_note: "",
            viewing_status: "",
            viewing_price: "",
            showPrice: false,
            showDate: false,
            listing_details:{},
            owner_id:"",
            owner_name:"",
            owner_email:"",
            owner_mobile:"",
            owner_other_properties:[],
            all:[],
            notes:[],
            call_notes:[],
            whatsapp_notes:[],
            email_notes:[],
            offer_notes: [],
            followup_notes: [],
            viewing_notes: [],
            record_id:0,
            property_for_is:false,
            greaterthanprice:false,
            leassthanprice:false,
            inbetweenprice:false,
            lessValue:0,
            greaterValue:0,

            // details
            agent_name_details:0,
            property_for_details:0,
            completiondetails:"",
            style_property_details:"",
            property_type_details:0,
            unit_no_details:0,
            community_details:0,
            subcommunity_details:0,
            bedroom_details:0,
            bathroom_details:0,
            price_details:0,
            view_details:"",
            tags_detail:"",
            description_details:"",
            listing_status_details:"",
            furnished_details:"",
            maids_room:"",
            vacant_details:"",
            completion_status:"",
            emirate_id:"",
            form_a:"",
            title_deed:"",
            exclusive:"",
            deed_expire:"",

            // for website

            youtube_link:"",
            livetour_link:"",
            brochure:"",
            curations:"",
            lifestyle:"",
            meta_title:"",
            meta_description:"",
            meta_keywords:"",

            // international
            international:"",
            country:"",
            city:"",
            
            // filters option
            property_for_first_value:0,
            property_for_second_value:0,
            property_for_second_option:false,
            community_filter:"",
            sub_community_filter:"",
            property_filter:"",
            propert_type:"",
            status_filter:[],

            name_value_id: "",
            name_value: "",
      
            phone_value_id: "",
            phone_value: "",

           // ref no
            refno_first_value_id:1,
            refno_second_value_id:1,
            refno_active_second_option:false,
            refno_and_or_option:true,
            refno_first_value:"",
            refno_second_value:"",

            // property for
            sale:true,
            rent:true,
            tenant:true,
            short_term:true,

            // property type
          
            residential:true,
            commercial:true,
              // unit no
              unitno_first_value_id:1,
              unitno_second_value_id:1,
              unitno_active_second_option:false,
              unitno_and_or_option:true,
              unitno_first_value:"",
              unitno_second_value:"",

              // price

              price_first_value_id:1,
              price_second_value_id:1,
              price_active_second_option:false,
              price_and_or_option:true,
              price_first_value:"",
              price_second_value:"",
              inrange:false,
              price_min:0,
              price_max:0,

              // date

              date_first_value_id:1,
              date_second_value_id:1,
              date_active_second_option:false,
              date_and_or_option:true,
              date_first_value:"",
              date_second_value:"",
              date_morethan_option:true,
              date_lessthan_option:false,
              date_value:0,
              date_value_id:1,

              // add new filter 
              isFilterOpen:false,
              isFilterEditOpen:false,
              filter_title:"",
              open:false,
              openToEdit:false,
              openToAmenities:false,

              property_type_option:[
                {
                  value:'Residential',
                  label:'Residential'
                },
                {
                  value:'Commercial',
                  label:'Commercial'
                }
              ],
              property_for_option:[
                {
                  value:'sale',
                  label:'Sales'
                },
                {
                  value:'rent',
                  label:'Rent'
                },
                {
                  value:'tenanted',
                  label:'Tenanted'
                },
                {
                  value:'short-term',
                  label:'Short-term'
                }
              ],
              propert_type_value:[],
              propert_for_value:[],


                  filter_options :[
                        

                    { 
                      value: '1',
                      label: 'Ref no',
                    },
                  
                    { 
                      value: '2',
                      label: 'Property for',
                    },
                    { 
                      value: '3',
                      label: 'Property type',
                    },
                    { 
                      value: '4',
                      label: 'Unit no',
                    },
                    { 
                      value: '5',
                      label: 'Created',
                    },
                    { 
                      value: '6',
                      label: 'Price',
                    }


                  ],
                  name_filter_options: [
                    {
                      value: '1',
                      label: 'Contain',
                    },
                    {
                      value: '2',
                      label: 'Not contain',
                    },
                    {
                      value: '3',
                      label: 'Equals',
                    },
                    {
                      value: '4',
                      label: 'Not equal',
                    },
                    {
                      value: '5',
                      label: 'Start with',
                    },
                    {
                      value: '6',
                      label: 'Ends with',
                    },
            
                  ],
                  phone_filter_options: [
                    {
                      value: '1',
                      label: 'Contain',
                    },
                    {
                      value: '2',
                      label: 'Not contain',
                    },
                    {
                      value: '3',
                      label: 'Equals',
                    },
                    {
                      value: '4',
                      label: 'Not equal',
                    },
                    {
                      value: '5',
                      label: 'Start with',
                    },
                    {
                      value: '6',
                      label: 'Ends with',
                    },
                    {
                      value: '7',
                      label: 'Mobile not empty',
                    },
            
                  ],

                  // add notes
                  call_log:"",
                  note_log:"",
                  whatsapp_log:"",
                  email_log:"",
                  viewing_log:"",
                  offer_log:"",
                  followup_log:""
        }

        this.textInput = React.createRef();
        
    }

    componentDidMount() {

      var loadScript = function(src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName('body')[0].appendChild(tag);
        }
     loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')

        const page = 1;
        const perPage = 10;

        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,

          name_value_id: this.state.name_value,
          name_value: this.state.name_value,
    
          phone_value_id: this.state.phone_value_id,
          phone_value: this.state.phone_value,

          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,

          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,
          property_type:this.state.propert_type
        }   


        this.fetchData(page, perPage,data);
        this.fetchFilter();

        API.get("/community")
        .then((response) =>{
          if(response.data.success){
              this.setState({communities:response.data.communities})
          }
        })

        API.get("/all_sub_community/")
            .then((response) => {
                if (response.data.success)
                    this.setState({sub_communities: response.data.sub_communities})
            })

        API.get("/all_properties/")
            .then((response) => {
                if (response.data.success)
                    this.setState({properties: response.data.properties})
            })


        API.get("/propertytypes")
        .then((response) =>{
          if(response.data.success){
              this.setState({property_categories:response.data.propertytypes,propertytype:response.data.propertytypes})
          }
        })

        API.get("/bedrooms")
    .then((response) => {
        if (response.data.success) {
            this.setState({bedrooms: response.data.bedrooms})
        }
    })
        this.fetchClientUsers();
    }

    fetchClientUsers = () => {
      API.get('/all_client_users').then((response) => {
        if(response.data.success){
          this.setState({userClients: response.data.users})
        }
      })
    }

    fetchFilter = ()=>{
      API.get('/listings_filters')
      .then((response) =>{
        if(response.data.success){
          this.setState({filters:response.data.filters})
         
        }
      })
    }

    handleCommunity = (value) =>{
      this.setState({community_value_id:value})
      API.get("/sub_community/"+value)
      .then((response) =>{
          if(response.data.success)
          this.setState({sub_communities:response.data.sub_communities})
      })
  }

    handleNewFilter = () =>{
      this.setState({isFilterOpen:true})
    }

    handleEditFilter = (id,e) =>{

     

      let filter_option=[]
  
        const newrows = [
          {
            id:1
          },
          {id:2},
          {
          id:3,
           },
          {id:4},
          {id:5},
          {
            id:6
          }
        ]
  
        
        //this.setState({filter_options:[]})
          this.setState({rows:newrows})
        
        this.setState({isFilterEditOpen:true,filter_id:id})

     

   

        API.get("/get_listing_saved_filter/"+id)
        .then((response)=>{
  
     
         
          if(response.data.success){
            const response_filter = response.data.filter
           
            const property_for = response.data.filter_property_for
            const property_type = response.data.filters_property_types
           
  
            this.setState({
              
              propert_type_value:property_type,
              propert_for_value:property_for,
              price_first_value_id:response_filter.price_first_value_id,
              price_first_value:response_filter.price_first_value,
              price_min:response_filter.price_min,
              price_max:response_filter.price_max,
              inrange:response_filter.price_first_value_id == 7?true:false,
  
              date_morethan_option:response_filter.date_morethan_option,
              date_lessthan_option:response_filter.date_lessthan_option,
              date_value_id:response_filter.date_value_id,
              date_value:response_filter.date_value,
  
              bed_rooms_value_id:response_filter.bed_rooms_value_id,
              bed_rooms_value:response_filter.bed_rooms_value,
              
  
              default_stage_value:response.data.filter_stages,
  
              default_contact_types_value:response.data.filters_contact_types,
  
              default_furnished_types_value:response.data.filters_furnished_unfurnisheds
            
            })
          }
        })
      }

      

     handleOk = () => {

      const data = {
        filter_title:this.state.filter_title,
        refno_value_id:this.state.refno_first_value_id,
        refno_value:this.state.refno_first_value,

        unitno_value_id:this.state.unitno_first_value_id,
        unitno_value:this.state.unitno_first_value,

        price_first_value_id:this.state.price_first_value_id,
        price_first_value:this.state.price_first_value,
        price_min:this.state.price_min,
        price_max:this.state.price_max,
      
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        propert_for_value:this.state.propert_for_value,

        propert_type_value:this.state.propert_type_value

      }
      API.post("/add_listing_filter",data)
      .then((response) =>{
        
        if(response.data.success){
          this.setState({isFilterOpen:false,filter_title:""})
          this.fetchFilter();
          
        }
      })

     
      //   const data = {
      //     filter_title:this.state.filter_title
      //   }
      // API.post("/add_filter",data)
      // .then((response) =>{
      //   if(response.data.success){
      //     this.setState({isFilterOpen:false,filter_title:""})
      //     this.fetchFilter();
          
      //   }
      // })
    };
     handleCancel = () => {
      this.setState({isFilterOpen:false,filter_title:''})
    };

    handleCancelEit = ()=>{
      this.setState({isFilterEditOpen:false,filter_title:''})
    }

    handleFilterTitle = (e) =>{
      this.setState({filter_title:e.target.value})
    }


    handleDeleteFilter = (targetKey, action) => {

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/delete_listing_filter/"+targetKey)
          .then((response) =>{
            if(response.data.success){
              toast.success("Successfully deleted")
              this.fetchFilter()
            }
          })
        }
      })
    
      // if (action === 'add') {
      //   this.setState({isFilterOpen:true})
      //  // add();
      // } else {
      //   //remove(targetKey);
      // }
    };

    // filter options
    handlePropertyForFirstValue = (e) =>{

      const property_for_first_value = e.target.value;
      if(property_for_first_value !=''){
        this.setState({property_for_second_option:true})
      }else{
        this.setState({property_for_second_option:false})
      }

      
    
      
    }

    handleRefnoFirst = (e) =>{
     
      const refno_first_value = e.target.value;
      this.setState({refno_first_value:refno_first_value})

      // if(refno_first_value !=''){
      //   this.setState({refno_active_second_option:true})
      // }else{
      //   this.setState({refno_active_second_option:false})
      // }

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,

          name_value_id: this.state.name_value,
          name_value: this.state.name_value,
    
          phone_value_id: this.state.phone_value_id,
          phone_value: this.state.phone_value,

          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:refno_first_value,
          refno_second_value:this.state.refno_second_value,

          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,
          property_type:this.state.propert_type
      }

         const page = 1;
        const perPage = 10;
       
        this.fetchData(page, perPage,data);
    
      
    }

    handleRefnoSecond =(e)=>{

      this.setState({refno_second_value:e.target.value})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:e.target.value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:property_for_array,

        property_type:property_type_array

      }

      const page = 1;
        const perPage = 10;
       
        this.fetchData(page, perPage,data);

    }

    handleRefnoFirstValue_id =(e) =>{
      this.setState({refno_first_value_id:e.target.value})
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,

        name_value_id: this.state.name_value,
        name_value: this.state.name_value,
  
        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,

        refno_first_value_id:e.target.value,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,
        property_type:this.state.propert_type
      }

      const page = 1;
      const perPage = 10;
      if(this.state.refno_first_value !='')
      this.fetchData(page, perPage,data);
    }

    handleRefRadio = (e) =>{
      // alert(!this.state.refno_and_or_option)
      this.setState({refno_and_or_option:!this.state.refno_and_or_option})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:!this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      if(this.state.refno_second_value !='')
     
      this.fetchData(page, perPage,data);

    }

    handleRefnoSecondValue_id =(e) =>{
      this.setState({refno_second_value_id:e.target.value})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:e.target.value,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      if(this.state.refno_second_value !='')
      this.fetchData(page, perPage,data);

    }

    // property for 
     handlePropertyFor = (e) =>{
      //   if(this.state.sale){
      //     property_for_array = property_for_array.filter(e => e !== 'sale'); 
      //   }else{
  
      //       property_for_array.push('sale')
      //   }

      // this.setState({sale:!this.state.sale})
      this.setState({propert_for_value:e})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:e,

        property_type:this.state.propert_type_value
      }
     

      const page = 1;
      const perPage = 10;
      this.fetchData(page, perPage,data);

     }
     handleRent = (e) =>{
      if(this.state.rent){
        property_for_array = property_for_array.filter(e => e !== 'rent'); 
      }else{

          property_for_array.push('rent')
      }
      this.setState({rent:!this.state.rent})
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      this.fetchData(page, perPage,data);
     }
     handleTenant = (e) =>{
      if(this.state.tenant){
        property_for_array = property_for_array.filter(e => e !== 'tenanted'); 
      }else{

          property_for_array.push('tenanted')
      }
      this.setState({tenant:!this.state.tenant})
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      this.fetchData(page, perPage,data);
     }
     handleShortterm = (e) =>{
      if(this.state.short_term){
        property_for_array = property_for_array.filter(e => e !== 'short-term'); 
      }else{

          property_for_array.push('short-term')
      }
      this.setState({short_term:!this.state.short_term})
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      this.fetchData(page, perPage,data);
     }

     // property type
     handlePropertyType = (e) =>{
     
   
      this.setState({propert_type:e.target.value})
  
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,

        name_value_id: this.state.name_value,
        name_value: this.state.name_value,
  
        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,

        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        property_type:e.target.value
      }

      const page = 1;
      const perPage = 10;
      this.fetchData(page, perPage,data);

     }
     handleCommercial = (e) =>{
      if(this.state.commercial){
        property_type_array = property_type_array.filter(e => e !== 'Commercial'); 

      
      }else{
        property_type_array.push('Commercial')
        
      }
      this.setState({commercial:!this.state.commercial})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,

        property_for:property_for_array,
        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      this.fetchData(page, perPage,data);

     }

     // unit no options
     handleUnitnoFirst = (e) =>{
     
      const unitno_first_value = e.target.value;
      this.setState({unitno_first_value:unitno_first_value})

      // if(unitno_first_value !=''){
      //   this.setState({unitno_active_second_option:true})
      // }else{
      //   this.setState({unitno_active_second_option:false})
      // }

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,

        name_value_id: this.state.name_value,
        name_value: this.state.name_value,
  
        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,

        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,
        property_type:this.state.propert_type
      }

         const page = 1;
        const perPage = 10;
       
        this.fetchData(page, perPage,data);
    
      
    }

    handleUnitnoSecond =(e)=>{

      this.setState({unitno_second_value:e.target.value})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:e.target.value,

        price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,


        property_for:property_for_array,

        property_type:property_type_array

      }

      const page = 1;
        const perPage = 10;
       
        this.fetchData(page, perPage,data);

    }

    handleUnitnoFirstValue_id =(e) =>{
      this.setState({unitno_first_value_id:e.target.value})
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,

        name_value_id: this.state.name_value,
        name_value: this.state.name_value,
  
        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,

        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:e.target.value,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,
        property_type:this.state.propert_type
      }

      const page = 1;
      const perPage = 10;
      if(this.state.unitno_first_value !='')
      this.fetchData(page, perPage,data);
    }

    handleNameValue_id = (e) => {
        this.setState({ name_value_id: e })
    
        const page = 1;
        const perPage = 10;
    
        const data = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
            community_filter:this.state.community_filter,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:this.state.property_filter,
    
            name_value_id: e,
            name_value: this.state.name_value,
      
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
    
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
    
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
            property_type:this.state.propert_type
        }
        if (this.state.name_value != '')
          this.fetchData(page, perPage, data);
      }
    
      handleName = (e) => {
    
        this.setState({ name_value: e.target.value })
        const page = 1;
        const perPage = 10;
    
        const data = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
            community_filter:this.state.community_filter,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:this.state.property_filter,
    
            name_value_id: this.state.name_value_id,
            name_value: e.target.value,
      
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
    
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
    
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
            property_type:this.state.propert_type
    
        }
        this.fetchData(page, perPage, data);
      }
    
      handlePhoneValue_id = (e) => {
        this.setState({ phone_value_id: e })
        if(e == 7){
          this.setState({showhidefilter:true})
        }else{
          this.setState({showhidefilter:false})
        }
        const page = 1;
        const perPage = 10;
        const data = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
            community_filter:this.state.community_filter,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:this.state.property_filter,
    
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
      
            phone_value_id: e,
            phone_value: this.state.phone_value,
    
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
    
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
            property_type:this.state.propert_type
    
        }
        if (e < 7 && this.state.phone_value != ''){
          this.fetchData(page, perPage, data);
        }else
        if(e == 7){
          this.fetchData(page, perPage, data);
        }
      }
    
      handlePhone = (e) => {
    
        this.setState({ phone_value: e.target.value })
    
        const page = 1;
        const perPage = 10;
    
        const data = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
            community_filter:this.state.community_filter,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:this.state.property_filter,
    
            name_value_id: this.state.name_value_id,
            name_value: this.state.name_value,
      
            phone_value_id: this.state.phone_value_id,
            phone_value: e.target.value,
    
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
    
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
            property_type:this.state.propert_type
    
        }
    
    
        if (this.state.phone_value_id > 0)
          this.fetchData(page, perPage, data);
    
      }

    handleUnitRadio = (e) =>{
      // alert(!this.state.refno_and_or_option)
      this.setState({unitno_and_or_option:!this.state.unitno_and_or_option})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,


        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      if(this.state.unitno_second_value !='')
     
      this.fetchData(page, perPage,data);

    }

    handleUnitnoSecondValue_id =(e) =>{
      this.setState({unitno_second_value_id:e.target.value})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,


        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:e.target.value,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,


        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      if(this.state.unitno_second_value !='')
      this.fetchData(page, perPage,data);

    }


    // price options
      
      handlePriceFirst = (e) =>{
        const price_first_value = e.target.value;

        this.setState((prevState) => (
          e.target.validity.valid ? {price_first_value: e.target.value} : prevState)
        )    

        
       
        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,
  
          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

          date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,
  
  
          property_for:property_for_array,
  
          property_type:property_type_array
        }
  
           const page = 1;
          const perPage = 10;
          if(e.target.value > 0){
            this.fetchData(page, perPage,data);
          }else{
            return
          }
         
          
      
        
      }

  // price in range
      handlePriceMin = (e) =>{
        this.setState({price_min:e.target.value})


        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,
  
          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:e.target.value,
          price_max:this.state.price_max,

          date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,
  
  
          property_for:this.state.propert_for_value,
  
          property_type:this.state.propert_type_value
        }
  
           const page = 1;
          const perPage = 10;
          if(this.state.price_max > 0)
          this.fetchData(page, perPage,data);

      }

      handlePriceMax = (e) =>{
        this.setState({price_max:e.target.value})
        const max = e.target.value
        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,
  
          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:e.target.value,

          date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,
  
  
          property_for:this.state.propert_for_value,
  
          property_type:this.state.propert_type_value
        }
  
           const page = 1;
          const perPage = 10;
          if(this.state.price_min > 0 && max > this.state.price_min)
          this.fetchData(page, perPage,data);
      }
  
      handlePriceSecond =(e)=>{
  
        this.setState({price_second_value:e.target.value})
  
        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,
  
          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:e.target.value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

          date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,
  
  
          property_for:property_for_array,
  
          property_type:property_type_array
  
        }
  
        const page = 1;
          const perPage = 10;
         
          this.fetchData(page, perPage,data);
  
      }
  
      handlePriceFirstValue_id =(e) =>{
        this.setState({inrange:false})
        if(e.target.value == 7){
          this.setState({inrange:true,price_first_value:0})
        }else{
          this.setState({price_min:0,price_max:0})
        }

        this.setState({price_first_value_id:e.target.value})
        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,
  
          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:e.target.value,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,
  
          date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,
  
          property_for:property_for_array,
  
          property_type:property_type_array
        }
  
        const page = 1;
        const perPage = 10;
        if(this.state.price_first_value !='')
        this.fetchData(page, perPage,data);
      }
  
      handlePriceRadio = (e) =>{
        // alert(!this.state.refno_and_or_option)
        this.setState({price_and_or_option:!this.state.price_and_or_option})
  
        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,
  
          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

          date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,

        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,
  
  
          property_for:property_for_array,
  
          property_type:property_type_array
        }
  
        const page = 1;
        const perPage = 10;
        if(this.state.price_second_value !='')
       
        this.fetchData(page, perPage,data);
  
      }
  
      handlePriceSecondValue_id =(e) =>{
        this.setState({price_second_value_id:e.target.value})

       
  
        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,
  
  
          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:e.target.value,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

          date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:this.state.date_second_value_id,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,
  
        date_morethan_option:this.state.date_morethan_option,
        date_lessthan_option:this.state.date_lessthan_option,
        date_value:this.state.date_value,
        date_value_id:this.state.date_value_id,
  
          property_for:property_for_array,
  
          property_type:property_type_array
        }
  
        const page = 1;
        const perPage = 10;
        if(this.state.price_second_value !='')
        this.fetchData(page, perPage,data);
  
      }
  

   // date option

   handleDateMorethan = (e)=>{
    this.setState({date_morethan_option:true,date_lessthan_option:false})

    const data  = {
      property_type_filter:this.state.property_type_filter,
      status_filter:this.state.status_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id:this.state.refno_first_value_id,
      refno_and_or_option:this.state.refno_and_or_option,
      refno_second_value_id:this.state.refno_second_value_id,
      refno_first_value:this.state.refno_first_value,
      refno_second_value:this.state.refno_second_value,


      unitno_first_value_id:this.state.unitno_first_value_id,
      unitno_and_or_option:this.state.unitno_and_or_option,
      unitno_second_value_id:this.state.unitno_second_value_id,
      unitno_first_value:this.state.unitno_first_value,
      unitno_second_value:this.state.unitno_second_value,

      price_first_value_id:this.state.price_first_value_id,
      price_and_or_option:this.state.price_and_or_option,
      price_second_value_id:this.state.price_second_value_id,
      price_first_value:this.state.price_first_value,
      price_second_value:this.state.price_second_value,
      price_min:this.state.price_min,
      price_max:this.state.price_max,

      date_first_value_id:this.state.date_first_value_id,
    date_and_or_option:this.state.date_and_or_option,
    date_second_value_id:this.state.date_second_value_id,
    date_first_value:this.state.date_first_value,
    date_second_value:this.state.date_second_value,

    date_morethan_option:true,
    date_lessthan_option:false,
    date_value:this.state.date_value,
    date_value_id:this.state.date_value_id,

      property_for:property_for_array,

      property_type:property_type_array
    }

    const page = 1;
    const perPage = 10;
    if(this.state.date_value != '')
    this.fetchData(page, perPage,data);

   }

   handleDateLessthan = (e)=>{
    this.setState({date_morethan_option:false,date_lessthan_option:true})

    const data  = {
      property_type_filter:this.state.property_type_filter,
      status_filter:this.state.status_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id:this.state.refno_first_value_id,
      refno_and_or_option:this.state.refno_and_or_option,
      refno_second_value_id:this.state.refno_second_value_id,
      refno_first_value:this.state.refno_first_value,
      refno_second_value:this.state.refno_second_value,


      unitno_first_value_id:this.state.unitno_first_value_id,
      unitno_and_or_option:this.state.unitno_and_or_option,
      unitno_second_value_id:this.state.unitno_second_value_id,
      unitno_first_value:this.state.unitno_first_value,
      unitno_second_value:this.state.unitno_second_value,

      price_first_value_id:this.state.price_first_value_id,
      price_and_or_option:this.state.price_and_or_option,
      price_second_value_id:this.state.price_second_value_id,
      price_first_value:this.state.price_first_value,
      price_second_value:this.state.price_second_value,
      price_min:this.state.price_min,
      price_max:this.state.price_max,

      date_first_value_id:this.state.date_first_value_id,
    date_and_or_option:this.state.date_and_or_option,
    date_second_value_id:this.state.date_second_value_id,
    date_first_value:this.state.date_first_value,
    date_second_value:this.state.date_second_value,

    date_morethan_option:false,
    date_lessthan_option:true,
    date_value:this.state.date_value,
    date_value_id:this.state.date_value_id,

      property_for:property_for_array,

      property_type:property_type_array
    }

    const page = 1;
    const perPage = 10;
    if(this.state.date_value != '')
    this.fetchData(page, perPage,data);

   }

   handleDateValue_id = (e) =>{
   this.setState({date_value_id:e.target.value})
   const data  = {
    property_type_filter:this.state.property_type_filter,
    status_filter:this.state.status_filter,
    community_filter:this.state.community_filter,
    sub_community_filter:this.state.sub_community_filter,
    property_filter:this.state.property_filter,
    refno_first_value_id:this.state.refno_first_value_id,
    refno_and_or_option:this.state.refno_and_or_option,
    refno_second_value_id:this.state.refno_second_value_id,
    refno_first_value:this.state.refno_first_value,
    refno_second_value:this.state.refno_second_value,


    unitno_first_value_id:this.state.unitno_first_value_id,
    unitno_and_or_option:this.state.unitno_and_or_option,
    unitno_second_value_id:this.state.unitno_second_value_id,
    unitno_first_value:this.state.unitno_first_value,
    unitno_second_value:this.state.unitno_second_value,

    price_first_value_id:this.state.price_first_value_id,
    price_and_or_option:this.state.price_and_or_option,
    price_second_value_id:this.state.price_first_value_id,
    price_first_value:this.state.price_first_value,
    price_second_value:this.state.price_second_value,
    price_min:this.state.price_min,
    price_max:this.state.price_max,

    date_first_value_id:this.state.date_first_value_id,
  date_and_or_option:this.state.date_and_or_option,
  date_second_value_id:this.state.date_second_value_id,
  date_first_value:this.state.date_first_value,
  date_second_value:this.state.date_second_value,


  date_morethan_option:this.state.date_morethan_option,
  date_lessthan_option:this.state.date_lessthan_option,
  date_value:this.state.date_value,
  date_value_id:e.target.value,


    property_for:property_for_array,

    property_type:property_type_array
  }

  const page = 1;
  const perPage = 10;
  if(this.state.date_value !='')
  this.fetchData(page, perPage,data);
   }

   handleDateValue = (e) =>{
    this.setState({date_value:e.target.value})

    const data  = {
      property_type_filter:this.state.property_type_filter,
      status_filter:this.state.status_filter,
      community_filter:this.state.community_filter,
      sub_community_filter:this.state.sub_community_filter,
      property_filter:this.state.property_filter,
      refno_first_value_id:this.state.refno_first_value_id,
      refno_and_or_option:this.state.refno_and_or_option,
      refno_second_value_id:this.state.refno_second_value_id,
      refno_first_value:this.state.refno_first_value,
      refno_second_value:this.state.refno_second_value,
  
  
      unitno_first_value_id:this.state.unitno_first_value_id,
      unitno_and_or_option:this.state.unitno_and_or_option,
      unitno_second_value_id:this.state.unitno_second_value_id,
      unitno_first_value:this.state.unitno_first_value,
      unitno_second_value:this.state.unitno_second_value,
  
      price_first_value_id:this.state.price_first_value_id,
      price_and_or_option:this.state.price_and_or_option,
      price_second_value_id:this.state.price_first_value_id,
      price_first_value:this.state.price_first_value,
      price_second_value:this.state.price_second_value,
      price_min:this.state.price_min,
      price_max:this.state.price_max,
  
      date_first_value_id:this.state.date_first_value_id,
    date_and_or_option:this.state.date_and_or_option,
    date_second_value_id:this.state.date_second_value_id,
    date_first_value:this.state.date_first_value,
    date_second_value:this.state.date_second_value,
   
    date_morethan_option:this.state.date_morethan_option,
    date_lessthan_option:this.state.date_lessthan_option,
    date_value:e.target.value,
    date_value_id:this.state.date_value_id,
  
  
      property_for:property_for_array,
  
      property_type:property_type_array
    }
  
    const page = 1;
    const perPage = 10;
    if(e.target.value !='')
    this.fetchData(page, perPage,data);
  }
      
    handleDateFirst = (e) =>{
     
      const date_first_value = e.target.value;
      this.setState({date_first_value:date_first_value})

      if(date_first_value !=''){
        this.setState({date_active_second_option:true})
      }else{
        this.setState({date_active_second_option:false})
      }

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
        price_and_or_option:this.state.price_and_or_option,
        price_second_value_id:this.state.price_second_value_id,
        price_first_value:this.state.price_first_value,
        price_second_value:this.state.price_second_value,
        price_min:this.state.price_min,
        price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
      date_and_or_option:this.state.date_and_or_option,
      date_second_value_id:this.state.date_second_value_id,
      date_first_value:date_first_value,
      date_second_value:this.state.date_second_value,


        property_for:property_for_array,

        property_type:property_type_array
      }

         const page = 1;
        const perPage = 10;
       
        this.fetchData(page, perPage,data);
    
      
    }

    handleDateSecond =(e)=>{

      this.setState({date_second_value:e.target.value})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
        price_and_or_option:this.state.price_and_or_option,
        price_second_value_id:this.state.price_second_value_id,
        price_first_value:this.state.price_first_value,
        price_second_value:this.state.price_second_value,
        price_min:this.state.price_min,
        price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
      date_and_or_option:this.state.date_and_or_option,
      date_second_value_id:this.state.date_second_value_id,
      date_first_value:this.state.date_first_value,
      date_second_value:e.target.value,


        property_for:property_for_array,

        property_type:property_type_array

      }

      const page = 1;
        const perPage = 10;
       
        this.fetchData(page, perPage,data);

    }

    handleDateFirstValue_id =(e) =>{
      this.setState({date_first_value_id:e.target.value})
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
        price_and_or_option:this.state.price_and_or_option,
        price_second_value_id:this.state.price_second_value_id,
        price_first_value:this.state.price_first_value,
        price_second_value:this.state.price_second_value,
        price_min:this.state.price_min,
        price_max:this.state.price_max,

        date_first_value_id:e.target.value,
      date_and_or_option:this.state.date_and_or_option,
      date_second_value_id:this.state.date_second_value_id,
      date_first_value:this.state.date_first_value,
      date_second_value:this.state.date_second_value,
     

        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      if(this.state.date_first_value !='')
      this.fetchData(page, perPage,data);
    }

    handleDateRadio = (e) =>{
      // alert(!this.state.refno_and_or_option)
      this.setState({date_and_or_option:!this.state.date_and_or_option})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
        price_and_or_option:this.state.price_and_or_option,
        price_second_value_id:this.state.price_second_value_id,
        price_first_value:this.state.price_first_value,
        price_second_value:this.state.price_second_value,
        price_min:this.state.price_min,
        price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
      date_and_or_option:this.state.date_and_or_option,
      date_second_value_id:this.state.date_second_value_id,
      date_first_value:this.state.date_first_value,
      date_second_value:this.state.date_second_value,


        property_for:property_for_array,

        property_type:property_type_array
      }

      const page = 1;
      const perPage = 10;
      if(this.state.date_second_value !='')
     
      this.fetchData(page, perPage,data);

    }

    handleDateSecondValue_id =(e) =>{
      this.setState({date_second_value_id:e.target.value})

      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,


        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
        price_and_or_option:this.state.price_and_or_option,
        price_second_value_id:this.state.price_second_value_id,
        price_first_value:this.state.price_first_value,
        price_second_value:this.state.price_second_value,
        price_min:this.state.price_min,
        price_max:this.state.price_max,

        date_first_value_id:this.state.date_first_value_id,
        date_and_or_option:this.state.date_and_or_option,
        date_second_value_id:e.target.value,
        date_first_value:this.state.date_first_value,
        date_second_value:this.state.date_second_value,


        property_for:this.state.propert_for_value,

        property_type:this.state.propert_type_value
      }

      const page = 1;
      const perPage = 10;
      if(this.state.date_second_value !='')
      this.fetchData(page, perPage,data);

    }


 


    onInputChange  = (e)=>{
      console.log("onchange",e.target.value)
    }


    handlePriceRange = (e) =>{
      const radio = e.target.value
      if(radio == 1){
     this.setState({greaterthanprice:true,leassthanprice:false,inbetweenprice:false})
      }else
      if(radio==2){
        this.setState({greaterthanprice:false,leassthanprice:true,inbetweenprice:false})
      }else
        if(radio==3){
          this.setState({greaterthanprice:false,leassthanprice:false,inbetweenprice:true})
        }
    }

    handleGreaterValue = (e) =>{
      this.setState({greaterValue:e.target.value})
    }

    handleLessValue = (e) =>{
      this.setState({lessValue:e.target.value})
    }

    handleMinKeyDown =(event) =>{
      const maxValue = this.state.greaterValue
      const data = {
        greaterValue:this.state.greaterValue,
        lessValue:this.state.lessValue
      }
      if (event.key === 'Enter') {
         if(maxValue > 0){
         }else{
          this.textInput.current.focus();
         }
      }
    }


    
    fetchData = (page, perPage,data) => {
        this.setState({isLoading:true})
      //  setTimeout(() => {
            
        
        API.post(`/owner_listings?page=${page}&perPage=${perPage}`,data)
             .then(async (response)  =>{
            
             

               
                var data = await response.data.owner_listings.data;

                await response.data.owner_listings;
                this.setState({
                    owner_listings: response.data.owner_listings,
                    notes:response.data.notes,
                    call_notes:response.data.call_notes,
                    whatsapp_notes:response.data.whatsapp_notes,
                    email_notes:response.data.email_notes,
                    offer_notes:response.data.offer_notes,
                    viewing_notes:response.data.viewing_notes,
                    followup_notes:response.data.followup_notes,
                    isLoading: false
                })

             })
            // }, 200);

    }

     onDragEnd = (fromIndex, toIndex) => {
     
      // const columns = columns
      const item = columns.splice(fromIndex, 1)[0];
      //columns.splice(toIndex, 0, item);
      columns.push(item)
     
    };

    handleTabs =(e) => {
      

     

      API.get("/get_listing_saved_filter/"+e)
        .then((response)=>{

       

          // return
          const response_filter = response.data.filter

          const property_type = response.data.filters_property_types
          const property_for = response.data.filter_property_for

          
          const property_type_data=[]
          const property_for_data=[]
          for (let i = 0; i < property_type.length; i++) {
            property_type_data.push(property_type[i].value);
            
          }

          for (let i = 0; i < property_for.length; i++) {
            property_for_data.push(property_for[i].value);
            
          }

         


          const data = {
            property_type_filter:this.state.property_type_filter,
            status_filter:this.state.status_filter,
            community_filter:this.state.community_filter,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:this.state.property_filter,
          refno_first_value_id:response_filter.refno_value_id,
          refno_first_value:response_filter.refno_value,
          unitno_first_value_id:response_filter.unitno_value_id,
          unitno_first_value:response_filter.unitno_value,
       
          property_type:property_type_data,
          property_for:property_for_data,

          price_first_value_id:response_filter.price_value_id,
          price_first_value:response_filter.price_value,
          price_min:response_filter.price_min,
          price_max:response_filter.price_max,

        
          date_morethan_option:response_filter.date_morethan_option == 1?true:false,
          date_lessthan_option:response_filter.date_lessthan_option == 1?true:false,
          date_value:response_filter.date_value,
          date_value_id:response_filter.date_value_id,
        
       
          }

   
          this.fetchData(page, perPage,data);
        })




      const page = 1;
        const perPage = 10;

        const data  = {
          property_type_filter:this.state.property_type_filter,
          status_filter:this.state.status_filter,
          community_filter:this.state.community_filter,
          sub_community_filter:this.state.sub_community_filter,
          property_filter:this.state.property_filter,
          refno_first_value_id:this.state.refno_first_value_id,
          refno_and_or_option:this.state.refno_and_or_option,
          refno_second_value_id:this.state.refno_second_value_id,
          refno_first_value:this.state.refno_first_value,
          refno_second_value:this.state.refno_second_value,

          unitno_first_value_id:this.state.unitno_first_value_id,
          unitno_and_or_option:this.state.unitno_and_or_option,
          unitno_second_value_id:this.state.unitno_second_value_id,
          unitno_first_value:this.state.unitno_first_value,
          unitno_second_value:this.state.unitno_second_value,

          price_first_value_id:this.state.price_first_value_id,
          price_and_or_option:this.state.price_and_or_option,
          price_second_value_id:this.state.price_second_value_id,
          price_first_value:this.state.price_first_value,
          price_second_value:this.state.price_second_value,
          price_min:this.state.price_min,
          price_max:this.state.price_max,

          date_first_value_id:this.state.date_first_value_id,
          date_and_or_option:this.state.date_and_or_option,
          date_second_value_id:this.state.date_second_value_id,
          date_first_value:this.state.date_first_value,
          date_second_value:this.state.date_second_value,
          date_morethan_option:this.state.date_morethan_option,
          date_lessthan_option:this.state.date_lessthan_option,
          date_value:this.state.date_value,
          date_value_id:this.state.date_value_id,
        
          property_type:property_type_array,
          property_for:property_for_array
        }
  
        
       
        this.fetchData(page, perPage,data);
     
    }

    handleOpenDrawer = ()=>{
        this.state.unit_no_details = this.state.listing_details.unitno
        let community = this.state.communities.filter((community) => {return (community.label === this.state.listing_details.community_title)})
        this.state.community_details = (community && community[0]) ? community[0].value : []
        let sub_community = this.state.sub_communities.filter((sub_community) => {return (sub_community.label === this.state.listing_details.sub_community_title)})
        this.state.subcommunity_details = (sub_community && sub_community[0]) ? sub_community[0].value : []
        this.state.stage_details = "UC"
        let property = this.state.properties.filter((property) => {return (property.label === this.state.listing_details.property_title)})
        this.state.property_details = (property && property[0]) ? property[0].value : []
        this.state.owner_name = this.state.owner_name
        this.state.owner_email = this.state.owner_email
        this.state.owner_mobile = this.state.owner_mobile
        this.setState({open: true})
    }

    handleCloseDrawer = () =>{
      this.setState({open:false})
    }
    handleSaveData=() => {
      toast.success("Successfully addedd")
      this.setState({open:false})
    }

    addNewListing(state, e) {
      const data = {
          unit_no: state.unit_no_details,
          community: state.community_details,
          sub_community: state.subcommunity_details,
          property: state.property_details,
          owner_name: state.owner_name,
          owner_phone: state.owner_mobile,
          owner_email: state.owner_email,
          property_type: state.property_type_details,
          stage: state.stage_details,
          bedrooms: state.bedroom_details,
          price: state.price_details,
          property_for: state.property_for_details,
          ref_no: state.listing_details.refno,
          type: "owner_listing",
          datasheet_ref: state.listing_details.id,
          available_date: (state.property_for_details === "rent" || state.property_for_details === "short-term") ? state.available_date : "",
      }
      
  
      API.post("/add_new_listing", data)
          .then((response) => {
              if (response.data.success) {
                  toast.success("Listing successfully added")
                  this.handleCloseDrawer();
              } else {
                  toast.warning("Something went wrong")
              }
          })
  }
  
  addUnitNo(e) {
      this.setState({unit_no_details: e.target.value})
  }

    addCommunity(state, value, e) {
        state.community_details = value
      state.subcommunity_details = 0
        this.getSubCommunity(value);
  }

    addSubCommunity(state, value, e) {
        state.subcommunity_details = value
      state.property_details = 0
        this.getProperty(value)
  }
  
  getSubCommunity = (id) => {
      API.get("/sub_community/" + id)
          .then((response) => {
              if (response.data.success)
                  this.setState({sub_communities: response.data.sub_communities})
          })
  }
  
  getProperty = (id) => {
      API.get("/property/" + id)
          .then((response) => {
              if (response.data.success)
                  this.setState({properties: response.data.properties})
          })
  }

    addProperty(state, value, e) {
        state.property_details = value
  }
  
  addOwnersName(e) {
      this.setState({owner_name: e.target.value})
  }
  
  addOwnersPhone(e) {
      this.setState({owner_mobile: e.target.value})
  }
  
  addOwnersEmail(e) {
      this.setState({owner_email: e.target.value})
  }
  
  addPropertyType(state, e) {
      state.property_type_details = e.target.value
  }
  
  addStage(state, e) {
      state.stage_details = e.target.value
  }
  
  addBedrooms(state, e) {
      state.bedroom_details = e.target.value
  }
  
  addPrice(state, e) {
      state.price_details = e.target.value
  }
  
  addPropertyFor(state, e) {
      state.property_for_details = e.target.value
      const selected_property_for = e.target.value
      if (selected_property_for == "rent" || selected_property_for == "short-term") {
          this.setState({property_for_is: true})
      } else {
          this.setState({property_for_is: false})
      }
  }
  
  handleAvailableDate = (state, date, dateString) => {
      state.available_date = dateString
  }

    handleOpenDrawerToEdit = (id,e)=>{
     
      
      this.setState({record_id:id})
 

  


      API.get("/owner_listing/"+id)
      .then((response)=>{
       
        if(response.data.success){
          this.setState({listing_details:response.data.owner_listing,
            owner_name:(response.data.owner_listing) ? response.data.owner_listing.owner_name : "",
            owner_mobile:(response.data.owner_listing) ? response.data.owner_listing.mobile : "",
            owner_email:(response.data.owner_listing) ? response.data.owner_listing.email : "",
              all: response.data.all,
              call_notes: response.data.call_notes,
              email_notes: response.data.email_notes,
              notes: response.data.notes,
              whatsapp_notes: response.data.whatsapp_notes,
              offer_notes:response.data.offer_notes,
              viewing_notes:response.data.viewing_notes,
              followup_notes:response.data.followup_notes,
            
          })
          this.setState({openToEdit:true})
          
          this.fetchViewings(id)
          this.fetchOffers(id);

      API.get("/all_agents")
      .then((response) =>{
        if(response.data.success){
            this.setState({agents:response.data.agents})
        }
      })

      API.get("/facilities")
      .then((response) =>{
        if(response.data.success){
            this.setState({facilities:response.data.facilities})
        }
      })

      API.get("/propertytypes")
      .then((response) =>{
        if(response.data.success){
            this.setState({propertytypes:response.data.propertytypes})
        }
      })

          API.get("/all_sub_community")
          .then((response) =>{
            
            if(response.data.success){
                this.setState({sub_communities:response.data.sub_communities})
            }
          })

          API.get("/all_client_users")
          .then((response) =>{
            console.log(response.data.users)
            
            
            if(response.data.success){
                this.setState({allUsers:response.data.users})
            }
          })


          
         

        }
       
      })
      
     
    }

    fetchOffers(listing_id){
        API.get("/listing_offers/"+listing_id)
        .then((response) =>{
          if(response.data.success){
            this.setState({listing_offers:response.data.listing_offers})
          }

        })
    }

    fetchViewings(listing_id)
    {
      

      API.get("/listing_viewings/"+listing_id)
      .then((response) =>{
      
        if(response.data.success){
          this.setState({property_viewings:response.data.property_viewings})
        }
      })
    }

    handleCloseEditDrawer = () =>{
      this.setState({openToEdit:false})
    }

    handleSaveEditDrawer = () =>{
      toast.success("Successfully updated")
      this.setState({openToEdit:false}) 
    }

    handleDelete = () =>{
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
       
           
              toast.success("Successfully deleted")
              
            
       
        }
      })
    }

    handleNextRecord =(e) =>{
      const id = this.state.record_id;
    
      API.get("/next_listing/"+id)
      .then((response)=>{
       if(response.data.success){
        this.setState({listing_details:response.data.listing_details,property_images:response.data.images,notes:response.data.notes,
          call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
          email_notes:response.data.email_notes,
          agent_name_details:response.data.listing_details.agent_id,property_for_details:response.data.listing_details.property_for,
          international:response.data.listing_details.international,country:response.data.listing_details.country,
          city:response.data.listing_details.emirate,
          property_type_details:response.data.listing_details.property_category_id,completion_status:response.data.listing_details.completed,
          youtube_link:response.data.listing_details.video_link,livetour_link:response.data.listing_details.livetour_link,
           meta_title:response.data.listing_details.meta_title,meta_description:response.data.listing_details.meta_desc,
           meta_keywords:response.data.listing_details.meta_keywords,
          unit_no_details:response.data.listing_details.unitno,lead_gen:response.data.listing_details.leadgen =="Yes"?true:false,
          just_listed:response.data.listing_details.just_listed ==1?true:false,
          community_details:response.data.listing_details.development,subcommunity_details:response.data.listing_details.project,
          property_details:response.data.listing_details.property,bedroom_details:response.data.listing_details.bedrooms,bathroom_details:response.data.listing_details.bathrooms,
          price_details:response.data.listing_details.price,view_details:response.data.listing_details.view,
          //vacant_details:this.state.vacant_details,upgraded_details:this.state.upgraded_details,
          furnished_details:response.data.listing_details.furnished_details,
          //maids_room:this.state.maids_room,tags_detail:this.state.tags_detail,description_details:this.state.description_details,
          listing_status_details:response.data.listing_details.status,
          emirate_id:response.data.listing_details.emirate_id,form_a:response.data.listing_details.form_a,
          title_deed:response.data.listing_details.title_deed,exclusive:response.data.listing_details.exclusive,
          deed_expire:response.data.listing_details.deed_expire,owner_other_properties:response.data.owner_other_properties,record_id:response.data.listing_details.id,
         document_notes:response.data.document_notes,documents:response.data.documents
        })
        this.fetchViewings(id)
        this.fetchOffers(id);
          // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
       }else{
        toast.warning("last record")
       }
      })
     }
 
     handlePreviousRecord =(e) =>{
       const id = this.state.record_id;
       API.get("/previous_listing/"+id)
       .then((response)=>{
        if(response.data.success){
          this.setState({listing_details:response.data.listing_details,property_images:response.data.images,notes:response.data.notes,
            call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
            email_notes:response.data.email_notes,
            agent_name_details:response.data.listing_details.agent_id,property_for_details:response.data.listing_details.property_for,
            international:response.data.listing_details.international,country:response.data.listing_details.country,
            city:response.data.listing_details.emirate,
            property_type_details:response.data.listing_details.property_category_id,completion_status:response.data.listing_details.completed,
            youtube_link:response.data.listing_details.video_link,livetour_link:response.data.listing_details.livetour_link,
           meta_title:response.data.listing_details.meta_title,meta_description:response.data.listing_details.meta_desc,
           meta_keywords:response.data.listing_details.meta_keywords,
            unit_no_details:response.data.listing_details.unitno,lead_gen:response.data.listing_details.leadgen =="Yes"?true:false,
            just_listed:response.data.listing_details.just_listed ==1?true:false,
            community_details:response.data.listing_details.development,subcommunity_details:response.data.listing_details.project,
            property_details:response.data.listing_details.property,bedroom_details:response.data.listing_details.bedrooms,bathroom_details:response.data.listing_details.bathrooms,
            price_details:response.data.listing_details.price,view_details:response.data.listing_details.view,
            //vacant_details:this.state.vacant_details,upgraded_details:this.state.upgraded_details,
            furnished_details:response.data.listing_details.furnished_details,
            //maids_room:this.state.maids_room,tags_detail:this.state.tags_detail,description_details:this.state.description_details,
            listing_status_details:response.data.listing_details.status,
            emirate_id:response.data.listing_details.emirate_id,form_a:response.data.listing_details.form_a,
            title_deed:response.data.listing_details.title_deed,exclusive:response.data.listing_details.exclusive,
            deed_expire:response.data.listing_details.deed_expire,owner_other_properties:response.data.owner_other_properties,record_id:response.data.listing_details.id,
           document_notes:response.data.document_notes,documents:response.data.documents
          })
          this.fetchViewings(id)
          this.fetchOffers(id);
        }else{
          toast.warning("last record")
        }
       })
      }
 

      handlePropertFor =(e) =>{
        const selected_property_for = e.target.value
        if(selected_property_for == 1){ this.setState({property_for_is:true})}
        else{ this.setState({property_for_is:false})}
       
      }

      handleAvailableDate = (date, dateString) =>{
       
      }

      handleAddRow = (value) => {
        const item = {
          name: "",
          mobile: "",
          id:value

        };

        let filter_options = this.state.filter_options.filter(item => item.value !== value)
      
        this.setState({
          rows: [...this.state.rows, item],
          filter_options:filter_options
        });
      };

      handleRemoveSpecificRow = (idx,id) => () => {
        const rows = [...this.state.rows]

    
        if(id == 1){
          const item = {
            value:'1',
            label:'Contact type'
          }

          this.setState({
            filter_options:[...this.state.filter_options,item]
          })
        } else
        if(id == 2){
          const item = {
            value:'2',
            label:'Community / Sub community'
          }

          this.setState({
            filter_options:[...this.state.filter_options,item]
          })
        }else
        if(id == 3){
          const item = {
            value:'3',
            label:'Price'
          }

          this.setState({
            filter_options:[...this.state.filter_options,item],
            price_first_value_id:0,
            price_first_value:0,
            price_min:0,
            price_max:0
          })
        }else
        if(id == 4){
          const item = {
            value:'4',
            label:'Bedrooms'
          }

          this.setState({
            filter_options:[...this.state.filter_options,item]
          })
        }else
        if(id == 5){
          const item = {
            value:'5',
            label:'Created'
          }

          this.setState({
            filter_options:[...this.state.filter_options,item]
          })
        }else
        if(id == 6){
          const item = {
            value:'',
            label:'Stages'
          }

          this.setState({
            filter_options:[...this.state.filter_options,item]
          })
        }else
        if(id == 7){
          const item = {
            value:'7',
            label:'Furnished / Unfurnished'
          }

          this.setState({
            filter_options:[...this.state.filter_options,item]
          })
        }
       
        // if (index !== -1) {
        //   array.splice(index, 1);
        //   this.setState({people: array});
        // }
        
        rows.splice(idx, 1)
        
     
        this.setState({ rows })
      }

      handleMinBudget = (e) =>{
        this.setState({min_budget:e.target.value})
      }

      handleMaxBudget = (e) =>{
        this.setState({max_budget:e.target.value})
      }

      handleBeds = (e) =>{
        this.setState({bedroom_match:e.target.value})
      }

      handleCommunityMatch = (e)=>{
        this.setState({community_match:e})
        API.get("/sub_community/"+e)
        .then((response) =>{
            if(response.data.success)
            this.setState({sub_communities:response.data.sub_communities})
        })
      }

      handleSubCommunityMatch = (e)=>{
        this.setState({sub_community_match:e})
    
      }
      

      handleSubCommunity = (e)=>{
        this.setState({sub_community_match:e})
      }

      handlePropertForMatch = (e)=>{
        this.setState({property_for_match:e.target.value})
      }

      handlePropertTypeMatch = (e)=>{
        this.setState({property_type_match:e.target.value})
      }

      handleAddedDateMatch = (e) =>{
        this.setState({added_date_match:e.target.value})
      }

      handleMatch = () =>{
        const data = {
                      // listing_id:this.state.record_id,
                      community_match:this.state.community_match,
                      sub_community_match:this.state.sub_community_match,
                      min_budget:this.state.min_budget,max_budget:this.state.max_budget,
                      beds:this.state.bedroom_match,property_for_match:this.state.property_for_match,
                      property_type_match:this.state.property_type_match,
                      added_date_match:this.state.added_date_match
                     }

                     API.post("/match_listings",data)
                     .then((response) =>{
                      if(response.data.success){
                        this.setState({matchs:response.data.match_listings,not_matchs:response.data.not_match_listings})
                      }
                     })
      }

      handleCallLog = (e) =>{
        this.setState({call_log:e.target.value})
      }
      handleCallSubmit =()=>{
        const data = {
          listing_id:this.state.record_id,
          note:this.state.call_log,
          note_type:"phone",
          users:this.state.selectedCallUsers
        }

          this.setState({call_loader:true})

        API.post("/add_note",data)
        .then((response)=>{
          if(response.data.success){
            this.setState({notes:response.data.notes,
              call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
              email_notes:response.data.email_notes,
                all:response.data.all,
                offer_notes:response.data.offer_notes,
                viewing_notes:response.data.viewing_notes,
                followup_notes:response.data.followup_notes,
              call_log:""
            })
              this.setState({call_loader:false})
          }
        })
      }


      handleNoteLog = (e) =>{
        this.setState({note_log:e.target.value})
      }
      handleNoteSubmit =()=>{
        const data = {
          listing_id:this.state.record_id,
          note:this.state.note_log,
          note_type:"note",
          users:this.state.selectedNoteUsers
        }
          this.setState({note_loader:true})

        API.post("/add_note",data)
        .then((response)=>{
          
          if(response.data.success){
            this.setState({notes:response.data.notes,
              call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                all:response.data.all,
                offer_notes:response.data.offer_notes,
                viewing_notes:response.data.viewing_notes,
                followup_notes:response.data.followup_notes,
              email_notes:response.data.email_notes})
              this.setState({note_loader:false})
          }
        })
      }
      handleWhatsappLog = (e) =>{
        this.setState({whatsapp_log:e.target.value})
      }
      handleWhatsappSubmit =()=>{
        const data = {
          listing_id:this.state.record_id,
          note:this.state.whatsapp_log,
          note_type:"whatsapp",
          users:this.state.selectedWhatsappUsers
        }
          this.setState({whatsapp_loader:true})

        API.post("/add_note",data)
        .then((response)=>{
          if(response.data.success){
            this.setState({notes:response.data.notes,
              call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                all:response.data.all,
                offer_notes:response.data.offer_notes,
                viewing_notes:response.data.viewing_notes,
                followup_notes:response.data.followup_notes,
              email_notes:response.data.email_notes})
              this.setState({whatsapp_loader:false})
          }
        })
      }
      handleEmailLog = (e) =>{
        this.setState({email_log:e.target.value})
      }

      handleEmailSubmit =()=>{
        const data = {
          listing_id:this.state.record_id,
          note:this.state.email_log,
          note_type:"email",
          users:this.state.selectedEmailUsers
        }
          this.setState({email_loader:true})

        API.post("/add_note",data)
        .then((response)=>{
          if(response.data.success){
            this.setState({notes:response.data.notes,
              call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                all:response.data.all,
                offer_notes:response.data.offer_notes,
                viewing_notes:response.data.viewing_notes,
                followup_notes:response.data.followup_notes,
              email_notes:response.data.email_notes})
              this.setState({email_loader:false})
          }
        })
      }

      handleViewingSubmit =()=>{
        const data = {
          listing_id:this.state.record_id,
          note:this.state.viewing_log,
          note_type:"viewing",
          users:this.state.selectedViewingUsers
        }
        this.setState({viewing_loader: true});
        API.post("/add_note",data)
        .then((response)=>{
          if(response.data.success){
            this.setState({notes:response.data.notes,
              call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                all:response.data.all,
                offer_notes:response.data.offer_notes,
                viewing_notes:response.data.viewing_notes,
                followup_notes:response.data.followup_notes,
              email_notes:response.data.email_notes})
              this.setState({viewing_loader: false});
          }
        })
      }

      handleOfferSubmit =()=>{
        const data = {
          listing_id:this.state.record_id,
          note:this.state.offer_log,
          note_type:"offer",
          users:this.state.selectedOfferUsers
        }
        this.setState({offer_loader: true});
        API.post("/add_note",data)
        .then((response)=>{
          if(response.data.success){
            this.setState({notes:response.data.notes,
              call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                all:response.data.all,
                offer_notes:response.data.offer_notes,
                viewing_notes:response.data.viewing_notes,
                followup_notes:response.data.followup_notes,
              email_notes:response.data.email_notes})
              this.setState({offer_loader: false});
          }
        })
      }

      handleFollowupSubmit =()=>{
        const data = {
          listing_id:this.state.record_id,
          note:this.state.followup_log,
          note_type:"followup",
          users:this.state.selectedFollowupUsers
        }
        this.setState({followUp_loader: true});
        API.post("/add_note",data)
        .then((response)=>{
          if(response.data.success){
            this.setState({notes:response.data.notes,
              call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
                all:response.data.all,
                offer_notes:response.data.offer_notes,
                viewing_notes:response.data.viewing_notes,
                followup_notes:response.data.followup_notes,
              email_notes:response.data.email_notes})
              this.setState({followUp_loader: false});
          }
        })
      }

      handleFeature = (id,e) =>{
     
        const data = {id:id,listing_id:this.state.record_id}

        API.post("/update_image_feature",data)
        .then((response) =>{
          if(response.data.success){
            this.setState({property_images:response.data.images})
          }
        })
      }

      onDrop = (files) => {
        this.setState({files})
    };

    handleChangeImages  = (e) =>{
      console.log("images",e.fileList)
      this.setState({image_files:e.fileList})
    }

    handleOnRemove = (e)=>{
      this.setState({image_files:e.fileList})
    }

    handleUploadImages = () =>{
      // const data = {listing_id:this.state.record_id,files:this.state.image_files}

      const formData = new FormData();
      formData.append("listing_id", this.state.record_id);
      for (let i = 0; i < this.state.image_files.length; i++) {
        formData.append("files[]", this.state.image_files[i]);
      }

    
      API.post("/listing_upload_files",formData)
      .then((response) =>{
        console.log("resp from server after upload",response.data)
      })
    }

    // save details

   handleAgentDetails = (e)=>{

    this.setState({agent_name_details:e})
   }
   handlePropertyForDetails = (e)=>{
    this.setState({property_for_details:e.target.value})
   }
   handleCompletionDetails = (e)=>{
   
    this.setState({completion_status:e.target.value})
   }
   handlePropertyTypeDetails = (e)=>{
    this.setState({property_type_details:e.target.value})
   }
   handlePropertyStyleDetails = (e) =>{
    this.setState({property_style_details:e.target.value})
   }
   handleUnitNoDetails = (e) =>{
    this.setState({unit_no_details:e.target.value})
   }
   handleCommunityDetails = (e) =>{
    this.setState({community_details:e.target.value})
   }
   handleSubCommunityDetails = (e) =>{
    this.setState({subcommunity_details:e.target.value})
   }
   handlePropertyDetails = (e)=>{
    this.setState({property_details:e.target.value})
   }
   handleBedDetails= (e) =>{
    this.setState({bedroom_details:e.target.value})
   }
   handleaBathroomDetails = (e) =>{
    this.setState({bathroom_details:e.target.value})
   }
   handlePriceDetails = (e) =>{
    this.setState({price_details:e.target.value})
   }
   handleViewDetails= (e) =>{
    this.setState({view_details:e.target.value})
   }
   handleVacantDetails = (e) =>{
    this.setState({vacant_details:e.target.value})
   }
   handleUpgradedDetails = (e) =>{
    this.setState({upgraded_details:e.target.value})
   }
   handleFurnishedDetails = (e) =>{
    this.setState({furnished_details:e.target.value})
   }
   handleMaidsDetails = (e) =>{
    this.setState({maids_room:e.target.value})
   }
   handleTagsDetails = (e) =>{
    this.setState({tags_detail:e.target.value})
   }
   handleDescriptionDetails = (e) =>{
    this.setState({description_details:e.target.value})
   }
   handleListingStatusDetails = (e) =>{
    this.setState({listing_status_details:e.target.value})
   }
    handleSetailsSubmit = ()=>{
      const listing_id = this.state.record_id
      const data ={
        agent_name_details:this.state.agent_name_details,property_for_details:this.state.property_for_details,
        completiondetails:this.state.completiondetails,property_type_details:this.state.property_type_details,
        style_property_details:this.state.style_property_details,unit_no_details:this.state.unit_no_details,
        community_details:this.state.community_details,subcommunity_details:this.state.subcommunity_details,completion_status:this.state.completion_status,
        property_details:this.state.property_details,bedroom_details:this.state.bedroom_details,bathroom_details:this.state.bathroom_details,
        price_details:this.state.price_details,view_details:this.state.view_details,vacant_details:this.state.vacant_details,upgraded_details:this.state.upgraded_details,
        furnished_details:this.state.furnished_details,maids_room:this.state.maids_room,tags_detail:this.state.tags_detail,description_details:this.state.description_details,
        listing_status_details:this.state.listing_status_details,leadgen:this.state.lead_gen ==true?"Yes":"No",just_listed:this.state.just_listed ==true?1:0
      }
      API.put("/update_listing/"+listing_id,data)
      .then((response)=>{
        console.log("updated",response.data)
      })
    }

    handleFormA = (e) =>{
      this.setState({form_a:e.target.value})
    }

    handleEmirateID = (e) =>{
      this.setState({emirate_id:e.target.value})
    }

    handleExclusive = (e) =>{
      this.setState({exclusive:e.target.value})
    }
    handleTitleDeed = (e) =>{
      this.setState({title_deed:e.target.value})
    }
    handleDeedExpire = (e) =>{
      this.setState({deed_expire:e.target.value})
    }

    handleFormSubmit = () =>{
      const listing_id = this.state.record_id
      const data = {
        form_a:this.state.form_a,emirate_id:this.state.emirate_id,
        title_deed:this.state.title_deed,exclusive:this.state.exclusive,
        deed_expire:this.state.deed_expire
      }
      API.put("/Update_form/"+listing_id,data)
      .then((response)=>{
        console.log("res",response.data)
      })
    }

    handlePropertyNotes  = (e) =>{
      this.setState({property_note:e.target.value})
    }
    handlePropertyNotesSubmit = () =>{
      const data = {
        listing_id:this.state.record_id,
        property_note:this.state.property_note
      }
      API.post("/add_document_notes",data)
      .then((response)=>{
        if(response.data.success){
          this.setState({document_notes:response.data.document_notes,property_note:""})
        }
      })
    }
    handleDocumentTitle = (e) =>{
      this.setState({document_title:e.target.value})
    }

    handleDocumentFile  =(e) =>{
      let files = e.target.files || e.dataTransfer.files;
      
      if (!files.length) return;
      this.createImage(files[0]);
      this.setState({ documentImageShow: URL.createObjectURL(e.target.files[0]) });
    };
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          document_file: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    
    }

    handleDocumentsSubmit  = () =>{
      const data = {
        listing_id:this.state.record_id,listing_refno:this.state.listing_details.refno,
        document_file:this.state.document_file,document_title:this.state.document_title

      }
      API.post("/add_documents",data)
      .then((response) =>{
        if(response.data.success){
          this.setState({documents:response.data.documents,document_title:""})
        }
      })
    }

    handleOpenAmenities = () =>{
    this.setState({openToAmenities:true})
    }

    handleSaveAmenities = () =>{
      this.setState({openToAmenities:false})
    }
    handleCancelAmenities = () =>{
      this.setState({openToAmenities:false})
    }

    handleAllNotMatch = () =>{
    
      const data = {
        listing_id:this.state.record_id,
        community_match:this.state.community_match,
        sub_community_match:this.state.sub_community_match,
        min_budget:this.state.min_budget,max_budget:this.state.max_budget,
        beds:this.state.bedroom_match,property_for_match:this.state.property_for_match,
        property_type_match:this.state.property_type_match,
        added_date_match:this.state.added_date_match
       }
      this.props.setNoMatch(data)
      window.open("/restricted_leads", "_blank");

      //this.props.history.push("/restricted_leads")
    }

    handleAllMatch = () =>{
      const data = {
        listing_id:this.state.record_id,
        community_match:this.state.community_match,
        sub_community_match:this.state.sub_community_match,
        min_budget:this.state.min_budget,max_budget:this.state.max_budget,
        beds:this.state.bedroom_match,property_for_match:this.state.property_for_match,
        property_type_match:this.state.property_type_match,
        added_date_match:this.state.added_date_match
       }
      this.props.setMatch(data)
      window.open("/access_leads", "_blank");
      //this.props.history.push("/access_leads")
    }

    handleSaveOwnerName = (value) =>{
     
      const data = {
        owner_id:this.state.record_id,
        owner_name:value
      }
     
      API.post("/update_owners_name",data)
      .then((response) =>{
       console.log("owner name",response.data)
      })
    }
    handleSaveOwnerEmail = (value) =>{
      const data = {
        owner_id:this.state.record_id,
        owner_email:value
      }
      API.post("/update_owners_email",data)
      .then((response) =>{
       console.log("owner email",response.data)
      })
    }
    handleSaveOwnerMobile = (value) =>{
      const data = {
        owner_id:this.state.record_id,
        owner_mobile:value
      }
      API.post("/update_owners_mobile",data)
      .then((response) =>{
       console.log("owner mobile",response.data)
      })
    }

    handleLeadGen = (e) =>{
      this.setState({lead_gen:!this.state.lead_gen})
      console.log("lead gen",e.target.value)
    }

    handleJustListed =(e) =>{
      this.setState({just_listed:!this.state.just_listed})
      console.log("just list",e.target.value)
    }

    handleNewViewing = () => {
      this.setState({ isviewingOpen: true })
    }


  onSearchRefNo = (e) => {
    if (!e || e == '' || e == null) {
      // this.setState({ref_nums:[]})
      return
    }
    API.get("/get_ref_no/" + e)
      .then((response) => {
        console.log("refs no", response.data)
        if (response.data.success) {
          this.setState({ ref_nums: response.data.ref_nums })
        }
      })
  }

  handleRefsno = (e) => {
    this.setState({ viewing_ref_no: e })
  }

  handleViewingNote = (e) => {
    
    this.setState({ viewing_note: e.target.value })
  }

  handleViewingDate = (date, dateString) => {
    this.setState({ viewing_date: dateString })
  };
  handleViewingPrice = (e) =>{
    this.setState({viewing_price:e.target.value})
  }

  handleViewingAgent = (e) => {
    this.setState({ viewing_agent: e })
  }

  handleViewingOk = () => {
    const id = this.state.record_id
    const data = {
      listing_id: this.state.record_id,
      viewing_date: this.state.viewing_date,
      viewing_note: this.state.viewing_note
    }

    API.post("/add_listing_viewing", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({isviewingOpen:false})
          this.fetchViewings(id)

        }

      })

  }
  handleViewingCancel = () => {
    this.setState({ isviewingOpen: false })
  }



  handleEditViewing(view_id, e) {
   
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, iseditviewingOpen: true
          })
        }

      })
  }

  handleViewStatus = (e) => {
    const status = e.target.value
    this.setState({ viewing_status: e.target.value })
    if (status == "Offer") {
      this.setState({ showPrice: true })
    } else {
      this.setState({ showPrice: false })
    }

    if (status == "Scheduled") {
      this.setState({ showDate: true })
    } else {
      this.setState({ showDate: false })
    }
  }

  handleViewStatusChange = () => {
    const id = this.state.record_id

    const data = { listing_id: this.state.record_id, view_id: this.state.view_id, viewing_status: this.state.viewing_status,
       viewing_price: this.state.viewing_price,viewing_note:this.state.viewing_note }
   
    API.post("/change_listing_viewing_status", data)
      .then((response) => {
        
        if(response.data.success){
        this.setState({ iseditviewingOpen: false })
    this.fetchViewings(id)
    this.fetchOffers(id)
        }
      })
  
  }
  handleViewStatusCancel = () => {
    this.setState({ iseditviewingOpen: false })
  }


  handlesetSelectedAmenities = (value) =>{
   this.setState({selectedFacilities:value})
  }

  handleYoutubeLink = (e) =>{
    this.setState({youtube_link:e.target.value})
  }
  handleLivetourLink= (e) =>{
    this.setState({livetour_link:e.target.value})
  }

  handleBrochure= (e) =>{
    this.setState({brochure:e.target.value})
  }

  handleCrutions = (e) =>{
    this.setState({curations:e.target.value})
  }
  handleLifestyle= (e) =>{
    this.setState({lifestyle:e.target.value})
  }

  handleMetaTitle = (e) =>{
    this.setState({meta_title:e.target.value})
  }
  handleMetaKeywords = (e) =>{
    this.setState({meta_keywords:e.target.value})
  }

  handleMetaDescription = (e) =>{
    this.setState({meta_description:e.target.value})
  }

  handleSaveWebsite = () =>{
    const id = this.state.record_id
    const data = {
      video_link:this.state.youtube_link,
      livetour_link:this.state.livetour_link,
      meta_title:this.state.meta_title,
      meta_description:this.state.meta_description,
      meta_keywords:this.state.meta_keywords
    }
    API.put("/update_website/"+id,data)
    .then((response) =>{
      if(response.data.success){

      }
    })
  }

  handleInternational =(e)=>{
    this.setState({international:e.target.value})
  }
  handleCountry =(e)=>{
    this.setState({country:e.target.value})
  }
  handleCity =(e)=>{
    this.setState({city:e.target.value})
  }

  handleInternationalSubmit = () =>{
    const id = this.state.record_id
    const data = {
      international:this.state.international,
      country:this.state.country,
      city:this.state.city
    }
    API.put("/update_international/"+id,data)
    .then((response) =>{
      if(response.data.success){
        console.log(response.data)
      }
    })
  }

  handleShowOffer = (view_id,e) =>{
   
    API.get("/get_offer/"+view_id)
    .then((response) =>{
      
      if(response.data.success){
        this.setState({offer:response.data.offer})
        this.setState({showOffer:true})
      }
    })
 
  }
  handleOkOffer  = () =>{
    this.setState({showOffer:false})
    }
    handleCancelOffer = () =>{
    this.setState({showOffer:false})
    }

    handleCommunityFilter = (e) =>{

     
      this.setState({community_filter:e})

      API.get("/sub_community/"+e)
      .then((response) =>{
          if(response.data.success)
          this.setState({sub_communities:response.data.sub_communities})
      })

      const page = 1;
        const perPage = 10;

        const data  = {
          property_type_filter:this.state.property_type_filter,
            status_filter:this.state.status_filter,
            community_filter:e,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:this.state.property_filter,
  
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
      
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
  
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
  
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
        }   


        this.fetchData(page, perPage,data);

    }

    handleSubCommunityFilter = (e) =>{


      API.get("/property/"+e)
      .then((response) =>{
          if(response.data.success)
          this.setState({properties:response.data.properties})
      })

     
      this.setState({sub_community_filter:e})



      const page = 1;
        const perPage = 10;

        const data  = {
          property_type_filter:this.state.property_type_filter,
            status_filter:this.state.status_filter,
            community_filter:this.state.community_filter,
            sub_community_filter:e,
            property_filter:this.state.property_filter,
  
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
      
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
  
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
  
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
        }   


        this.fetchData(page, perPage,data);

    }

    handlePropertyFilter = (e) =>{

     
      this.setState({property_filter:e})

    

      const page = 1;
        const perPage = 10;

        const data  = {
          property_type_filter:this.state.property_type_filter,
            status_filter:this.state.status_filter,
            community_filter:this.state.community_filter,
            sub_community_filter:this.state.sub_community_filter,
            property_filter:e,
  
            name_value_id: this.state.name_value,
            name_value: this.state.name_value,
      
            phone_value_id: this.state.phone_value_id,
            phone_value: this.state.phone_value,
  
            refno_first_value_id:this.state.refno_first_value_id,
            refno_and_or_option:this.state.refno_and_or_option,
            refno_second_value_id:this.state.refno_second_value_id,
            refno_first_value:this.state.refno_first_value,
            refno_second_value:this.state.refno_second_value,
  
            unitno_first_value_id:this.state.unitno_first_value_id,
            unitno_and_or_option:this.state.unitno_and_or_option,
            unitno_second_value_id:this.state.unitno_second_value_id,
            unitno_first_value:this.state.unitno_first_value,
            unitno_second_value:this.state.unitno_second_value,
        }   


        this.fetchData(page, perPage,data);

    }

    onChangeCallNote = (value) =>{
      this.setState({call_log:value})
    }

    onSelectCallMentions = (option) =>{
     this.setState({selectedCallUsers:[...this.state.selectedCallUsers,option]})
  
    }

    onChangeNoteNote = (value) =>{
      this.setState({note_log:value})
    }

    onSelectNoteMentions = (option) =>{
     this.setState({selectedNoteUsers:[...this.state.selectedNoteUsers,option]})
  
    }

    onChangeWhatsappNote = (value) =>{
      this.setState({whatsapp_log:value})
    }

    onSelectWhatsappMentions = (option) =>{
     this.setState({selectedWhatsappUsers:[...this.state.selectedWhatsappUsers,option]})
  
    }

    onChangeEmailNote = (value) =>{
      this.setState({email_log:value})
    }

    onSelectEmailMentions = (option) =>{
     this.setState({selectedEmailUsers:[...this.state.selectedEmailUsers,option]})
  
    }

    onChangeViewingNote = (value) =>{
      this.setState({viewing_log:value})
    }

    onSelectViewingMentions = (option) =>{
     this.setState({selectedViewingUsers:[...this.state.selectedEmailUsers,option]})
  
    }
    onChangeOfferNote = (value) =>{
      this.setState({offer_log:value})
    }

    onSelectOfferMentions = (option) =>{
     this.setState({selectedOfferUsers:[...this.state.selectedOfferUsers,option]})
  
    }
    onChangeFollowupNote = (value) =>{
      this.setState({followup_log:value})
    }

    onSelectFollowupMentions = (option) =>{
     this.setState({selectedFollowupUsers:[...this.state.selectedFollowupUsers,option]})
  
    }

    handleStatusChange = (e) =>{
      this.setState({status_filter:e})

      const page = 1
      const perPage=10
const data = {
  property_type_filter:this.state.property_type_filter,
  status_filter:e,
  community_filter:this.state.community_filter,
  sub_community_filter:this.state.sub_community_filter,
  property_filter:this.state.property_filter,

  name_value_id: this.state.name_value,
  name_value: this.state.name_value,

  phone_value_id: this.state.phone_value_id,
  phone_value: this.state.phone_value,

  refno_first_value_id:this.state.refno_first_value_id,
  refno_and_or_option:this.state.refno_and_or_option,
  refno_second_value_id:this.state.refno_second_value_id,
  refno_first_value:this.state.refno_first_value,
  refno_second_value:this.state.refno_second_value,

  unitno_first_value_id:this.state.unitno_first_value_id,
  unitno_and_or_option:this.state.unitno_and_or_option,
  unitno_second_value_id:this.state.unitno_second_value_id,
  unitno_first_value:this.state.unitno_first_value,
  unitno_second_value:this.state.unitno_second_value,
  property_type:this.state.propert_type
}
this.fetchData(page,perPage,data);
  }


  
  handleClearFilter = () =>{
    this.setState({
        status_filter:[],
        property_type_filter:[],
        community_filter:"",
        sub_community_filter:"",
        phone_value_id: 1,
          phone_value: "",
        property_filter:"",
        refno_first_value_id:1,
        refno_and_or_option:true,
        refno_second_value_id:1,
        refno_first_value:"",
        refno_second_value:"",

        unitno_first_value_id:1,
        unitno_and_or_option:true,
        unitno_second_value_id:1,
        unitno_first_value:"",
        unitno_second_value:"",

        price_first_value_id:1,
        price_and_or_option:true,
        price_second_value_id:1,
        price_first_value:"",
        price_second_value:"",
        price_min:0,
        price_max:0,

        date_first_value_id:1,
        date_and_or_option:true,
        date_second_value_id:1,
        date_first_value:"",
        date_second_value:"",
        date_morethan_option:true,
        date_lessthan_option:false,
        date_value:"",
        date_value_id:1,

        property_type:[],
        property_for:[],
    })
    const page = 1;
      const perPage = 10;
      const data = {
        property_type_filter:[],
        status_filter:[],
        community_filter:"",
        phone_value_id: 1,
        phone_value: "",
        sub_community_filter:"",
        property_filter:"",
        refno_first_value_id:1,
        refno_and_or_option:true,
        refno_second_value_id:1,
        refno_first_value:"",
        refno_second_value:"",

        unitno_first_value_id:1,
        unitno_and_or_option:true,
        unitno_second_value_id:1,
        unitno_first_value:"",
        unitno_second_value:"",

        price_first_value_id:1,
        price_and_or_option:true,
        price_second_value_id:1,
        price_first_value:"",
        price_second_value:"",
        price_min:0,
        price_max:0,

        date_first_value_id:1,
        date_and_or_option:true,
        date_second_value_id:1,
        date_first_value:"",
        date_second_value:"",
        date_morethan_option:true,
        date_lessthan_option:false,
        date_value:"",
        date_value_id:1,

        property_type:[],
        property_for:[],
  
      }
  
      this.fetchData(page,perPage,data)
  }

  handlePropertyTypeFilter = (e) =>{


    this.setState({property_type_filter:e})

    const page = 1;
    const perPage = 10;

    const data  = {
      
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,
        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,

        price_first_value_id:this.state.price_first_value_id,
        price_and_or_option:this.state.price_and_or_option,
        price_second_value_id:this.state.price_second_value_id,
        price_first_value:this.state.price_first_value,
        price_second_value:this.state.price_second_value,
        price_min:this.state.price_min,
        price_max:this.state.price_max,

      

        property_type:this.state.propert_type_value,
        property_for:this.state.propert_for_value,
         property_type_filter:e
    }   


    this.fetchData(page, perPage,data);
  }

  render() {

    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let user = userdata.auth.user;
    let role_name = userdata.auth.user.role_name;
    let user_id = user.id

    const files = this.state.files.map(file => (
      <li key={file.name}>
          {file.name} - {file.size} bytes
      </li>
  ));

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    // setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    // selectedRowKeys,
    onChange: onSelectChange,

  };

     columns = [   
      
  
            
      {
        title: 'Ref no',
        dataIndex: 'refno',
        
        sorter: (a, b) => a.refno.length - b.refno.length,
        render: (text, record) => (

          <Link className=""  onClick={this.handleOpenDrawerToEdit.bind(this,record.id)}>{record.refno}</Link>
        ),
        filterDropdown: (
          <div style={{ padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
              Ref no
             <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
            <input type="text" value={this.state.refno_first_value} className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
            </div>
            {this.state.refno_active_second_option?(   <div className='mt-2' > 
            <center>
            <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option?true:false} onChange={this.handleRefRadio} />
            <label class="form-check-label" for="flexRadioDefault1">
              AND
            </label>
            <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option?true:false} onChange={this.handleRefRadio} />
            <label class="form-check-label ml-4" for="flexRadioDefault1">
              OR
            </label>
            
             </div>
          </center>
          <select class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
            <input type="text" value={this.state.refno_second_value}  className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
            </div>

            </div>):null}
         
            

             </div>
        ),
      },

         {
             title: 'Imported date',
             dataIndex: 'imported_date',
             render: (text, record) => (
                 <Moment fromNow>
                     {record.imported_date}
                 </Moment>
             ),
             sorter: (a, b) => {
                 if(a && a.imported_date && a.imported_date.length && b && b.imported_date && b.imported_date.length) {
                     return a.imported_date.length - b.imported_date.length;
                 } else if(a && a.imported_date && a.imported_date.length) {
                     return -1;
                 } else if(b && b.imported_date && b.imported_date.length) {
                     return 1;
                 }

                 // Both rechargeType has null value so there will be no order change.
                 return 0;
             },
         },
        
        {
          title: ' Community',
                    dataIndex: 'community_title',
                    sorter: (a, b) => a.community_title.length - b.community_title.length,
                    filterDropdown: (
                      <div style={{ padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                         Community
                          <Select  
                          allowClear
                          showSearch       
                         style={{
                         width: '100%',
                          }}
                         value={this.state.community_filter}
                          placeholder="Search"
                          onChange={this.handleCommunityFilter}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          options={this.state.communities}
                          />
          
          
                          </div>
                    ),
        },
        {
          title: ' Sub Community',
                    dataIndex: 'sub_community_title',
                    sorter: (a, b) => a.sub_community_title.length - b.sub_community_title.length,
                    filterDropdown: (
                      <div style={{ padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                         Sub Community
                          <Select  
                          showSearch   
                          allowClear    
                         style={{
                         width: '100%',
                          }}
                         value={this.state.sub_community_filter}
                          placeholder="Search"
                          onChange={this.handleSubCommunityFilter}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          options={this.state.sub_communities}
                          />
          
          
                          </div>
                    ),
        },
        {
          title: ' Property',
                    dataIndex: 'property_title',
                    sorter: (a, b) => a.property_title.length - b.property_title.length,
                    filterDropdown: (
                      <div style={{ padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                         Property
                          <Select  
                          showSearch   
                          allowClear    
                         style={{
                         width: '100%',
                          }}
                          value={this.state.property_filter}
                          placeholder="Search"
                          onChange={this.handlePropertyFilter}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          options={this.state.properties}
                          />
          
          
                          </div>
                    ),
        },

    
     
        
          {
            title: 'Unit no',
            dataIndex: 'unitno',
            sorter: (a, b) => a.unitno.length - b.unitno.length,
            filterDropdown: (
              <div style={{ padding: '8px',
                borderRadius: '6px',
                background: '#fff',
                boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                  Unit no
                 <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
                  <option value="1">Contain</option>
                  <option value="2">Not contain</option>
                  <option value="3">Equals</option>
                  <option value="4">Not equal</option>
                  <option value="5">Starts with</option>
                  <option value="6">Ends with</option>
                </select>
                <div className='mt-2'>
                <input type="text" value={this.state.unitno_first_value} className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." />
                </div>
                {this.state.unitno_active_second_option?(   <div className='mt-2' > 
                <center>
                <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadio" id="flexRadio1" checked={this.state.unitno_and_or_option?true:false} onChange={this.handleUnitRadio} />
                <label class="form-check-label" for="flexRadio1">
                  AND
                </label>
                <input class="form-check-input ml-3" type="radio" name="flexRadio" id="flexRadio1" checked={!this.state.unitno_and_or_option?true:false} onChange={this.handleUnitRadio} />
                <label class="form-check-label ml-4" for="flexRadio1">
                  OR
                </label>
                
                 </div>
              </center>
              <select class="form-select" aria-label="Default select example" name='unitno_second_filter_value_id' onChange={this.handleUnitnoSecondValue_id}>
                  <option value="1">Contain</option>
                  <option value="2">Not contain</option>
                  <option value="3">Equals</option>
                  <option value="4">Not equal</option>
                  <option value="5">Starts with</option>
                  <option value="6">Ends with</option>
                </select>
                <div className='mt-2'>
                <input type="text" value={this.state.unitno_second_value} className="form-control" onChange={this.handleUnitnoSecond} placeholder="filter..." />
                </div>
  
                </div>):null}
             
                
  
                 </div>
            ),
          },
          {
            title: 'Name',
            dataIndex: 'owner_name',
        
            sorter: (a, b) => a.owner_name.length - b.owner_name.length,
    
            filterDropdown: (
              <div style={{
                padding: '8px',
                borderRadius: '6px',
                background: '#fff',
                boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
              }}>
                Name
                <hr />
                <Select
                  style={{
                    width: '100%',
                  }}
                  placeholder="Please select"
                  onChange={this.handleNameValue_id}
                  options={this.state.name_filter_options}
                />
                <div className='mt-2'>
                  <input type="text" value={this.state.name_value} className="form-control" onChange={this.handleName} placeholder="filter..." />
                </div>
    
    
    
    
              </div>
            ),
          },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            sorter: (a, b) => a.mobile.length - b.mobile.length,
            filterDropdown: (
                <div style={{
                  padding: '8px',
                  borderRadius: '6px',
                  background: '#fff',
                  boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
                }}>
                  Phone number
                  <hr />
                  <Select
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please select"
                    onChange={this.handlePhoneValue_id}
                    options={this.state.phone_filter_options}
                  />
                
                  <div className='mt-2'>
                    {this.state.showhidefilter?null:<input type="text" value={this.state.phone_value} className="form-control" onChange={this.handlePhone} placeholder="filter..." />
                }
                 </div>
      
      
      
      
                </div>
              ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.length - b.email.length,
        },


          {
            title: 'Property Type',
            dataIndex: 'property_type',
            sorter: (a, b) => a.property_type.length - b.property_type.length,
           filterDropdown: (
                    <div style={{ padding: '8px',
                        borderRadius: '6px',
                        background: '#fff',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                        Property type
                        <Select  
                        showSearch   
                        allowClear   

                       style={{
                       width: '100%',
                        }}
                        mode="multiple"
                        value={this.state.property_type_filter}
                       
                        placeholder="Search"
                        onChange={this.handlePropertyTypeFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        options={this.state.propertytypes}
                        />
                    </div>
                ),

            
          },
         
         
          {
            title: 'Status',
            dataIndex: 'status',

                      render: (text, record) => {
                        var pStatus = ''
                        var color = ''

                         switch (record.status) {
                        case 'D':
                          pStatus = 'Draft'
                          color='draft'
                          break;
                        case 'UC':
                          pStatus = 'Confirmed Owner'
                          color='confirmedowner'
                          break;
                        case 'N':
                          pStatus = 'Unpublish'
                          color='unpublished'
                          break;
                        case 'OM':
                          pStatus = 'Off-Market'
                          color='offmarket'
                          break;
                        case 'CTM':
                          pStatus = 'Coming to market'
                          color='comingtomarket'
                          break;
                        case 'Y':
                          pStatus = 'Published'
                          color='published'
                          break;
                        case 'U':
                          pStatus = 'Action'
                          color='action'
                          break;
      
                        case 'SEO':
                          pStatus = 'SEO'
                          color='seo'
                          break;
                        case 'L':
                          pStatus = 'Unlisted'
                          color='unlisted'
                          break;
                        case 'S':
                          pStatus = 'Sold'
                          color='sold'
                          break;
                        case 'T':
                          pStatus = 'Let'
                          color='let'
                          break;
                        case 'P':
                          pStatus = 'Review'
                          color='review'
                          break;
                        case 'F':
                          pStatus = 'Feeds'
                          color='feeds'
                          break;
                        default:                  
                          pStatus = ''
                          color=''
                          break;
                      }
                      return (
                        <div className={color} key={pStatus}>
                          {pStatus.toUpperCase()}
                        </div>
                      );
                    }   ,
                    filterDropdown: (
                      <div style={{ padding: '3px',
                          borderRadius: '8px',
                          background: '#fff',
                          boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'}}>
                         Status
                          <Select
                              
                              style={{
                                  width: '100%',
                              }}
                              mode="multiple"
                              value={this.state.status_filter}
                              maxTagCount= 'responsive'
                              onChange={this.handleStatusChange}
                              options={[
                                  {
                                      value: 'D',
                                      label: 'Draft',
                                  },
                                  {
                                      value: 'UC',
                                      label: 'Confirmed Owner',
                                  },
                                  {
                                      value: 'N',
                                      label: 'Unpublish',
                                  },
                                  {
                                      value: 'OM',
                                      label: 'Off-Market',
                                  },
                                  {
                                      value: 'CTM',
                                      label: 'Coming to market',
                                  },
                                  {
                                      value: 'Y',
                                      label: 'Published',
                                  },
                                  {
                                      value: 'u',
                                      label: 'Action',
                                  },
                                  {
                                      value: 'SEO',
                                      label: 'SEO',
                                  },
                                  {
                                      value: 'L',
                                      label: 'Unlisted',
                                  },
                                  {
                                      value: 'S',
                                      label: 'Sold',
                                  },
                                  {
                                      value: 'T',
                                      label: 'Let',
                                  },
                                  {
                                      value: 'P',
                                      label: 'Review',
                                  },
                                  {
                                      value: 'F',
                                      label: 'Feeds',
                                  },      
                                                                  
                                                                  
                              ]}
                              />
                      </div>
                  ), 
          },
     
      ];

  


      const handleTableChange = (pagination,filters) => {

       
      const data  = {
        property_type_filter:this.state.property_type_filter,
        status_filter:this.state.status_filter,
        community_filter:this.state.community_filter,
        sub_community_filter:this.state.sub_community_filter,
        property_filter:this.state.property_filter,

        name_value_id: this.state.name_value,
        name_value: this.state.name_value,
  
        phone_value_id: this.state.phone_value_id,
        phone_value: this.state.phone_value,

        refno_first_value_id:this.state.refno_first_value_id,
        refno_and_or_option:this.state.refno_and_or_option,
        refno_second_value_id:this.state.refno_second_value_id,
        refno_first_value:this.state.refno_first_value,
        refno_second_value:this.state.refno_second_value,

        unitno_first_value_id:this.state.unitno_first_value_id,
        unitno_and_or_option:this.state.unitno_and_or_option,
        unitno_second_value_id:this.state.unitno_second_value_id,
        unitno_first_value:this.state.unitno_first_value,
        unitno_second_value:this.state.unitno_second_value,
        property_type:this.state.propert_type
      }
  
      
      
        this.fetchData(pagination.current, pagination.pageSize,data);
    
      };

      const items = [
        {
          key: '1',
          label: `New listings  (10)`,
          closable: true,
        },
        {
          key: '2',
          label: `Contacted listings  (0)`,
        },
        {
          key: '3',
          label: `Other listings  (13)`,
        },
        {
          key: '4',
          label: `Other listings  (13)`,
        },
        {
          key: '5',
          label: `Other listings  (13)`,
        },
        {
          key: '6',
          label: `Other listings  (13)`,
        },
      ];

  

    return (
        <div>
        <ToastContainer />
        <Modal width={1000} title="Offers" open={this.state.showOffer} 
        onOk={this.handleOkOffer} onCancel={this.handleCancelOffer}
        >
           <label>Date</label>
             <p>{this.state.offer.view_date}</p>
           
          <label>Note</label>
          <p>
            {this.state.offer.note}
          </p>
          <label>Price</label>
          <p>
            {this.state.offer.price}
          </p>
      </Modal>

        <Modal width={1000} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
          <Divider />
          <div className='row w-100'>
            <div className='col-md-12 px-0 pt-1'>
              <label>REf NO</label>
              <p>{this.state.viewings.listing_refno}</p>
            </div>
          </div>
          <div className='row w-100 mt-3'>
            <div className='col-md-4 px-0 pt-1'>
              <label>Agent Name</label>
              <p>{this.state.viewings.fullname}</p>
            </div>
            <div className='col-md-4 px-0 pt-1'>
              <label>Date</label>
              {this.state.showDate ? <DatePicker defaultValue={this.state.view_date} style={{ width: '100%' }} showTime onChange={this.handleViewingDate} /> : <p>{this.state.viewings.view_date}</p>}

            </div>
            <div className='col-md-4 px-0 pt-1 mt-3'>
              <label>Status</label>
              <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
              <option value="Offer">Offer</option>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select>
            </div>
          </div>
          <div className='row w-100 mt-3'>
            <div className='col-md-8 px-0 pt-1'>
              <label>Note</label>
              <TextArea value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
            </div>
            {this.state.showPrice ? <div className='col-md-4'>
              <label>Price</label>
              <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
            </div> : null}

          </div>
        </Modal>

        <Modal width={1000} title="Add Viewings" open={this.state.isviewingOpen}
          onOk={this.handleViewingOk} onCancel={this.handleViewingCancel}
        >
          <Divider />

          <div className='row w-100'>
  <div className='col-md-12 px-0 pt-1'>
 
    <div className="row">
 
      <div className="col-6">
        <div className="form-group">
            <label className="">Date / Time:</label>
            <DatePicker style={{ width: '100%' }} showTime onChange={this.handleViewingDate} />
        </div>                
      </div>
    </div>
    <div className="row">
        <div className="col-12">
            <label className="">Notes:</label>
            <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
        </div>
    </div>
  </div>
</div>
        </Modal>

        <Drawer
        title={`Listing details`}
        placement="left"
        // size={"large"}
        width={"98%"}
        onClose={this.handleCloseEditDrawer}
        open={this.state.openToEdit}
        extra={
          <Space>
            <Button type='primary' danger ghost onClick={this.handleCloseEditDrawer} icon={<CloseOutlined /> }>Cancel</Button>
            <Button type="primary" onClick={this.handleSaveEditDrawer}>
              Save changes
            </Button>
          </Space>
        }
      >
<div className="cont-wrap container">
  <div className="row">
    <div className="col-12 d-flex flex-row toptitle">
      <a className="btn btn-primary btn-sm" onClick={this.handlePreviousRecord}>Previous</a>
      <div className="d-flex w-100 ps-3"><h2 className="page-title fs-5 font-bold"><i className="bi bi-pencil-fill me-2" />Listing Details - {this.state.listing_details.refno}</h2></div>
        <Button className='ml-1 addnewlisting' type='primary' size="large"
                onClick={this.handleOpenDrawer} icon={<PlusCircleOutlined/>}>
            Add new listing
        </Button>
      <a className="btn btn-primary btn-sm" onClick={this.handleNextRecord}>Next</a>
    </div>
  </div>
  <div className="row column-wrap mt-3">
    <div className="col-12 col-md-3">
      <div className="bg-white border-top ownersdetails"> 
        <div className="d-flex flex-row justify-content-between">
          <h3>Owner Details</h3>
          <button type="button" className="btn btn-primary requestchange">Request Change</button>
        </div>
        <div className="d-flex flex-column formfieldset mt-3">
          <div className="d-flex flex-column form-group">
            <label className="control-labels mr-4"> Name</label>

            {/* editing */}
            <EdiText
           
          value={this.state.owner_name}
          type="text"
          onSave={this.handleSaveOwnerName}
          
        />
           
          </div>
          <div className="d-flex flex-column form-group">
            <label className="control-labels mr-4">Phone </label>
            <p>{this.state.owner_mobile}</p>
            {/* <EdiText
            
          value={this.state.owner_mobile}
          type="text"
          onSave={this.handleSaveOwnerMobile}
          //onSave={handleSave}
          //editing={editing}
        /> */}
          </div>
          <div className="d-flex flex-column form-group">
            <label className="control-labels mr-4">Email </label>
            <EdiText
            
          value={this.state.owner_email}
          type="text"
          onSave={this.handleSaveOwnerEmail}
          //onSave={handleSave}
          //editing={editing}
        />          </div>
          <div className="d-flex flex-column form-group">
            <div className="col-12 d-flex flex-column border-wrap listtable-wrap">
           
      
              {/* <a href className="d-flex flex-row p-2 w-100">
                <div className="col-8 listtable">
                  <p className="propfor">Sale</p>
                  <p className="refno">LP12000</p>
                  <p className="propadd">61 Palma Views</p>
                  <p className="community">Palm Jumeirah</p>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row stat publishstat">
                    <i className="bi bi-circle-fill" />
                    <span>Published</span>
                  </div>
                  <div className="agentprop">
                    <p className="name">Mark R.</p>
                    <p className="label">Agent</p>
                  </div>
                </div>
              </a> */}
              {/* <a href className="d-flex flex-row p-2 w-100">
                <div className="col-8 listtable">
                  <p className="propfor">Sale</p>
                  <p className="refno">LP14494</p>
                  <p className="propadd">61 Palma Residence</p>
                  <p className="community">Palm Jumeirah</p>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row stat publishstat">
                    <i className="bi bi-circle-fill" />
                    <span>Published</span>
                  </div>
                  <div className="agentprop">
                    <p className="name">Alex E.</p>
                    <p className="label">Agent</p>
                  </div>
                </div>
              </a> */}
              {/* <a href className="d-flex flex-row p-2 w-100">
                <div className="col-8 listtable">
                  <p className="propfor">Sale</p>
                  <p className="refno">LP12000</p>
                  <p className="propadd">61 Palma Views</p>
                  <p className="community">Palm Jumeirah</p>
                </div>
                <div className="col-4">
                  <div className="d-flex flex-row stat publishstat">
                    <i className="bi bi-circle-fill" />
                    <span>Published</span>
                  </div>
                  <div className="agentprop">
                    <p className="name">Simon B.</p>
                    <p className="label">Agent</p>
                  </div>
                </div>
              </a> */}
            </div>
          </div>
        </div>
     
      </div>
      {/* FORMS */}
      <div className="bg-white border-top offers p-4 mt-3">
        <div className="d-flex flex-row justify-content-between">
          <h3 className="mr-4">Forms</h3>
        </div>
        <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
          <div className="form-group">
            <label className="control-labels ">Form A</label>
            <select onChange={this.handleFormA} value={this.state.form_a} className="select2 select2-offscreen" id="forma_doc" title tabIndex={-1}>
              <option value>Select</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
          <div className="form-group">
            <label className="control-labels ">Emirates ID</label>
            <select onChange={this.handleEmirateID} value={this.state.emirate_id}  className="select2 select2-offscreen" id="eid_doc" title tabIndex={-1}>
              <option value>Select</option>
              <option value="1">Yes</option>
              <option  value="0">No</option>
            </select>
          </div>
          <div className="form-group">
            <label className="control-labels ">Title Deed</label>
            <select onChange={this.handleTitleDeed} value={this.state.title_deed}  className="select2 select2-offscreen" id="titledeed_doc" title tabIndex={-1}>
              <option value>Select</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
          <div className="form-group">
            <label className="control-labels ">Exclusive</label>
            <select onChange={this.handleExclusive} value={this.state.exclusive}  className="select2 select2-offscreen" id="exclusive_doc" title tabIndex={-1}>
              <option value>Select</option>
              <option value="1">Yes</option>
              <option  value="0">No</option>
            </select>
          </div>
          <div className="form-group">
            <label className="control-labels ">Deed Expiry</label>
            
            <input onChange={this.handleDeedExpire} defaultValue={this.state.deed_expire}  type="datetime-local" id="dead_expiry"  />
          </div>
        </div>
        <button onClick={this.handleFormSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button>
      </div>{/* END FORMS*/}
      <div className="bg-white border-top marketrequest p-4 mt-4">
        <h3>Marketing Request</h3>
        <div className="d-flex flex-row mb-2">
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-star-fill" /><span>Premium</span></button>
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#photorequest"><i className="bi bi-camera-fill" /><span>Photos</span></button>
        </div>
        <div className="d-flex flex-row mb-2">
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-file-earmark-arrow-down-fill" /><span>Brochure</span></button>
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-house-fill" />Open House</button>
        </div>
        <div className="d-flex flex-row mb-2">
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-instagram" /><span>Social Media</span></button>
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-whatsapp" /><span>WhatsApp Blast</span></button>
        </div>
        <div className="d-flex flex-row mb-2">
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-envelope-fill" /><span>EDM</span></button>
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-file-earmark-pdf-fill" /><span>Market Report</span></button>
        </div>
        <div className="d-flex flex-row mb-2">
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-image" /><span>Photo Edit</span></button>
          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-signpost-fill" /><span>Sign Board</span></button>
        </div>
        <div className="d-flex flex-column border-wrap marketrequesthistory">
          <a href className="d-flex flex-row p-2 w-100 marketwrap">
            <div className="col-12 requesttable d-flex flex-row">
              <div className="col-6">
                <p className="requestfor">Premium</p>
                <p className="requestname">Jason Hayes</p>
              </div>
              <div className="col-6 text-end">
                <p className="requestdate">10/02/2023</p>
                <p className="requeststat">In Review</p>
              </div>
            </div>
          </a>
          <a href className="d-flex flex-row p-2 w-100 marketwrap">
            <div className="col-12 requesttable d-flex flex-row">
              <div className="col-6">
                <p className="requestfor">Brochure</p>
                <p className="requestname">Jason Hayes</p>
              </div>
              <div className="col-6 text-end">
                <p className="requestdate">10/02/2023</p>
                <p className="requeststat">In Review</p>
              </div>
            </div>
          </a>
          <a href className="d-flex flex-row p-2 w-100 marketwrap">
            <div className="col-12 requesttable d-flex flex-row">
              <div className="col-6">
                <p className="requestfor">Social Media</p>
                <p className="requestname">Jason Hayes</p>
              </div>
              <div className="col-6 text-end">
                <p className="requestdate">10/02/2023</p>
                <p className="requeststat">In Review</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 timeline-wrap">
                <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                  <h3>Timeline</h3>
                  {/* ADD NOTES */}
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-call-tab" data-bs-toggle="tab" data-bs-target="#nav-call" type="button" role="tab" aria-controls="nav-call" aria-selected="true">Call</button>
                    <button className="nav-link" id="nav-notes-tab" data-bs-toggle="tab" data-bs-target="#nav-notes" type="button" role="tab" aria-controls="nav-notes" aria-selected="false">Notes</button>
                    <button className="nav-link" id="nav-whatsapp-tab" data-bs-toggle="tab" data-bs-target="#nav-whatsapp" type="button" role="tab" aria-controls="nav-whatsapp" aria-selected="false">WhatsApp</button>
                    <button className="nav-link" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email" type="button" role="tab" aria-controls="nav-email" aria-selected="false">Email</button>
                    <button className="nav-link" id="nav-offer-tab" data-bs-toggle="tab" data-bs-target="#nav-offer" type="button" role="tab" aria-controls="nav-offer" aria-selected="false">Offer</button>
                    <button className="nav-link" id="nav-viewing-tab" data-bs-toggle="tab" data-bs-target="#nav-viewing" type="button" role="tab" aria-controls="nav-viewing" aria-selected="false">Viewing</button>
                    <button className="nav-link" id="nav-followup-tab" data-bs-toggle="tab" data-bs-target="#nav-followup" type="button" role="tab" aria-controls="nav-followup" aria-selected="false">Follow Up</button>
                  </div>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-call" role="tabpanel" aria-labelledby="nav-call-tab" tabIndex={0}>
                    <Mentions
                        style={{
                          width: '100%',
                        }}
                        value={this.state.call_log}
                        onChange={this.onChangeCallNote}
                        onSelect={this.onSelectCallMentions}
                        options={this.state.userClients}
                      />
                        {
                            this.state.call_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button id="calllogbtn" onClick={this.handleCallSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" /> Add call log</button>
                        }
                      {/* <textarea onChange={this.handleCallLog} id="callLogsText" rows={4} placeholder="Add call log.." className="font-14 mention" /> */}
                    </div>
                    <div className="tab-pane fade" id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab" tabIndex={0}>
                    <Mentions
                        style={{
                          width: '100%',
                        }}
                        onChange={this.onChangeNoteNote}
                        onSelect={this.onSelectNoteMentions}
                        options={this.state.userClients}
                      />
                        {
                            this.state.note_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button id="logsbtn" onClick={this.handleNoteSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add note</button>
                        }
                      {/* <textarea id="logsText" onChange={this.handleNoteLog} rows={4} placeholder="Add note.." className="font-14 mentionnote" defaultValue={""} /> */}
                    </div>
                    <div className="tab-pane fade" id="nav-whatsapp" role="tabpanel" aria-labelledby="nav-whatsapp-tab" tabIndex={0}>
                    <Mentions
                        style={{
                          width: '100%',
                        }}
                        onChange={this.onChangeWhatsappNote}
                        onSelect={this.onSelectWhatsappMentions}
                        options={this.state.userClients}
                      />
                        {
                            this.state.whatsapp_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleWhatsappSubmit} id="whatsapplogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add WhatsApp Note</button>
                        }
                      {/* <textarea onChange={this.handleWhatsappLog} id="whatsappLogsText" rows={4} placeholder="Add WhatsApp notes.." className="font-14 mentionwa" defaultValue={""} /> */}
                    </div>
                    <div className="tab-pane fade" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab" tabIndex={0}>
                      <input type="text" placeholder="Subject" className="form-control font-14 mb-2"></input>
                      <Mentions
                        style={{
                          width: '100%',
                        }}
                        onChange={this.onChangeEmailNote}
                        onSelect={this.onSelectEmailMentions}
                        options={this.state.userClients}
                      />
                        {
                            this.state.email_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleEmailSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add email</button>
                        }
                      {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add email log.." className="font-14 mentionemail" defaultValue={""} /> */}
                    </div>
                    <div className="tab-pane fade" id="nav-offer" role="tabpanel" aria-labelledby="nav-offer-tab" tabIndex={0}>
                    <Mentions
                        style={{
                          width: '100%',
                        }}
                        onChange={this.onChangeOfferNote}
                        onSelect={this.onSelectOfferMentions}
                        options={this.state.userClients}
                      />
                        {
                            this.state.offer_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleOfferSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Offer</button>
                        }
                      {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add offer log.." className="font-14 mentionemail" defaultValue={""} /> */}
                    </div>
                    <div className="tab-pane fade" id="nav-viewing" role="tabpanel" aria-labelledby="nav-viewing-tab" tabIndex={0}>
                    <Mentions
                        style={{
                          width: '100%',
                        }}
                        onChange={this.onChangeViewingNote}
                        onSelect={this.onSelectViewingMentions}
                        options={this.state.userClients}
                      />
                        {
                            this.state.viewing_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleViewingSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Viewing</button>
                        }
                      {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add viewing log.." className="font-14 mentionemail" defaultValue={""} /> */}
                    </div>
                    <div className="tab-pane fade" id="nav-followup" role="tabpanel" aria-labelledby="nav-followup-tab" tabIndex={0}>
                    <Mentions
                        style={{
                          width: '100%',
                        }}
                        onChange={this.onChangeFollowupNote}
                        onSelect={this.onSelectFollowupMentions}
                        options={this.state.userClients}
                      />
                        {
                            this.state.followUp_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleFollowupSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Follow Up</button>
                        }
                      {/* <textarea onChange={this.handleEmailLog} id="emailLogsText" rows={4} placeholder="Add followup log.." className="font-14 mentionemail" defaultValue={""} /> */}
                    </div>
                  </div>
                  {/* TIMELINE */}
                  <div className="timeline border-wrap">
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">All</button>
                      <button className="nav-link" id="nav-notewrap-tab" data-bs-toggle="tab" data-bs-target="#nav-notewrap" type="button" role="tab" aria-controls="nav-notewrap" aria-selected="false">Notes</button>
                      <button className="nav-link" id="nav-calllist-tab" data-bs-toggle="tab" data-bs-target="#nav-calllist" type="button" role="tab" aria-controls="nav-calllist" aria-selected="false">Calls</button>
                      <button className="nav-link" id="nav-wa-tab" data-bs-toggle="tab" data-bs-target="#nav-wa" type="button" role="tab" aria-controls="nav-wa" aria-selected="false">WhatsApp</button>
                      <button className="nav-link" id="nav-mail-tab" data-bs-toggle="tab" data-bs-target="#nav-mail" type="button" role="tab" aria-controls="nav-mail" aria-selected="false">Email</button>
                      <button className="nav-link" id="nav-offers-tab" data-bs-toggle="tab" data-bs-target="#nav-offers" type="button" role="tab" aria-controls="nav-offers" aria-selected="false">Offers</button>
                      <button className="nav-link" id="nav-view-tab" data-bs-toggle="tab" data-bs-target="#nav-view" type="button" role="tab" aria-controls="nav-view" aria-selected="false">Viewings</button>
                      <button className="nav-link" id="nav-followups-tab" data-bs-toggle="tab" data-bs-target="#nav-followups" type="button" role="tab" aria-controls="nav-followups" aria-selected="false">Follow Up</button>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                      {/* ALL */}
                      <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabIndex={0}>
                        <div className="timeline-list">
                          
                          {this.state.all.map((al) => {
                            return (
                              <div className={"timegroup " + al.note_type}>
                                <div className="col-1">
                                  <div className="iconwrap">
                                    {al.note_type == "phone" ? <i class="bi bi-telephone-fill"></i> : null}
                                    {al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : null}
                                    {al.note_type == "whatsapp" ? <i className="bi bi-whatsapp" /> : null}
                                    {al.note_type == "note" ? <i class="bi bi-pin-angle-fill"></i> : null}
                                      {al.note_type == "viewing" ? <i class="bi bi-eye-fill"></i> : null}
                                      {al.note_type == "offer" ? <i className="bi bi-tag-fill"></i> : null}
                                      {al.note_type == "followup" ? <i className="bi bi-calendar"></i> : null}
                                          </div>
                                </div>
                                <div className="col-11 px-0">
                                  <div className="col-12 d-flex flex-row">
                                    <div className="agentdetails">
                                      <div className="d-flex flex-row justify-content-between">
                                      <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                        {al.note_type == "phone" ? " logged a call" : null}
                                        {al.note_type == "email" ? " sent an email" : null}
                                        {al.note_type == "whatsapp" ? " sent a whatsapp message" : null}
                                        {al.note_type == "note" ? " added a note" : null}
                                        {al.note_type == "offer" ? " added an offer" : null}
                                        {al.note_type == "viewing" ? " added a viewing" : null}
                                        {al.note_type == "followup" ? " scheduled a follow up" : null}
                                      </p>
                                        <p className="atime">{al.time}</p>
                                      </div>
                                      <div className="adesc">
                                        {al.note}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* NOTES */}
                      <div className="tab-pane fade" id="nav-notewrap" role="tabpanel" aria-labelledby="nav-notewrap-tab" tabIndex={0}>
                        <div className="timeline-list">
                          {(this.state.notes) && this.state.notes.map((note) => {
                            return (
                              <div className="timegroup note">
                                <div className="col-1">
                                  <div className="iconwrap">
                                    <i class="bi bi-pin-angle-fill"/>
                                  </div>
                                </div>
                                <div className="col-11 px-0">
                                  <div className="col-12 d-flex flex-column">
                                    <div className="agentdetails">
                                      <div className="calldetails">
                                        <p className="awriter font-bold">
                                          <span className="font-bold">{note.by_user_name}</span>
                                          <span> added a note</span>
                                        </p>
                                      </div>
                                      <p className="atime">{note.time}</p>
                                    </div>
                                    <div className="adesc">
                                      {note.note}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* CALLS */}
                      <div className="tab-pane fade" id="nav-calllist" role="tabpanel" aria-labelledby="nav-calllist-tab" tabIndex={0}>
                        <div className="timeline-list">

                          {(this.state.call_notes) && this.state.call_notes.map((call_note) => {
                            return (
                              <div className="timegroup phone">
                                <div className="col-1">
                                  <div className="iconwrap">
                                    <i className="bi bi-telephone-fill" />
                                  </div>
                                </div>
                                <div className="col-11 px-0">
                                  <div className="col-12 d-flex flex-column">
                                    <div className="agentdetails">
                                      <div className="calldetails">
                                        <p className="awriter">
                                          <span className="font-bold">{call_note.by_user_name}</span>
                                          <span> logged a call.</span>
                                        </p>
                                      </div>
                                      <p className="atime">{call_note.time}</p>
                                    </div>
                                    <div className="adesc">
                                      {call_note.note}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* WHATSAPP */}
                      <div className="tab-pane fade" id="nav-wa" role="tabpanel" aria-labelledby="nav-wa-tab" tabIndex={0}>
                        <div className="timeline-list">
                          {(this.state.whatsapp_notes) && this.state.whatsapp_notes.map((whatsapp_note) => {
                            return (
                              <div className="timegroup whatsapp">
                                <div className="col-1">
                                  <div className="iconwrap">
                                    <i className="bi bi-whatsapp" />
                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="col-12 d-flex flex-column">
                                    <div className="agentdetails">
                                      <div className="calldetails">
                                        <p className="awriter font-bold">
                                          <span className="font-bold">{whatsapp_note.by_user_name}</span>
                                          <span> sent a whatsapp message.</span>
                                        </p>
                                      </div>
                                      <p className="atime">{whatsapp_note.time}</p>
                                    </div>
                                    <div className="adesc">
                                      {whatsapp_note.note}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-mail" role="tabpanel" aria-labelledby="nav-mail-tab" tabIndex={0}>
                        <div className="timeline-list">
                          {(this.state.email_notes) && this.state.email_notes.map((email_note) => {
                            return (
                              <div className="timegroup email">
                                <div className="col-1">
                                  <div className="iconwrap">
                                    <i className="bi bi-envelope-fill" />
                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="col-12 d-flex flex-column">
                                    <div className="agentdetails">
                                      <div className="calldetails">
                                        <p className="awriter font-bold">
                                          <span className="font-bold">{email_note.by_user_name}</span>
                                          <span> sent an email.</span>
                                        </p>
                                      </div>
                                      <p className="atime">{email_note.time}</p>
                                    </div>
                                    <div className="adesc">
                                      {email_note.note}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-offers" role="tabpanel" aria-labelledby="nav-offers-tab" tabIndex={0}>
                        <div className="timeline-list">
                            {(this.state.offer_notes) && this.state.offer_notes.map((offer_note) => {
                                return (
                                    <div className="timegroup email">
                                        <div className="col-1">
                                            <div className="iconwrap">
                                                <i className="bi bi-tag-fill" />
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div className="col-12 d-flex flex-column">
                                                <div className="agentdetails">
                                                    <div className="calldetails">
                                                        <p className="awriter font-bold">
                                                            <span className="font-bold">{offer_note.by_user_name}</span>
                                                            <span> added an offer.</span>
                                                        </p>
                                                    </div>
                                                    <p className="atime">{offer_note.time}</p>
                                                </div>
                                                <div className="adesc">
                                                    {offer_note.note}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-view" role="tabpanel" aria-labelledby="nav-view-tab" tabIndex={0}>
                        <div className="timeline-list">
                            {(this.state.viewing_notes) && this.state.viewing_notes.map((viewing_note) => {
                                return (
                                    <div className="timegroup email">
                                        <div className="col-1">
                                            <div className="iconwrap">
                                                <i className="bi bi-eye-fill" />
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div className="col-12 d-flex flex-column">
                                                <div className="agentdetails">
                                                    <div className="calldetails">
                                                        <p className="awriter font-bold">
                                                            <span className="font-bold">{viewing_note.by_user_name}</span>
                                                            <span> added a viewing.</span>
                                                        </p>
                                                    </div>
                                                    <p className="atime">{viewing_note.time}</p>
                                                </div>
                                                <div className="adesc">
                                                    {viewing_note.note}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-followups" role="tabpanel" aria-labelledby="nav-followups-tab" tabIndex={0}>
                        <div className="timeline-list">
                            {(this.state.followup_notes) && this.state.followup_notes.map((followup_note) => {
                                return (
                                    <div className="timegroup email">
                                        <div className="col-1">
                                            <div className="iconwrap">
                                                <i className="bi bi-calendar" />
                                            </div>
                                        </div>
                                        <div className="col-10">
                                            <div className="col-12 d-flex flex-column">
                                                <div className="agentdetails">
                                                    <div className="calldetails">
                                                        <p className="awriter font-bold">
                                                            <span className="font-bold">{followup_note.by_user_name}</span>
                                                            <span> scheduled a follow up.</span>
                                                        </p>
                                                    </div>
                                                    <p className="atime">{followup_note.time}</p>
                                                </div>
                                                <div className="adesc">
                                                    {followup_note.note}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column w-100 bg-white border-top ownersdetails mt-3 accordion accordion-flush">
        <div className="accordion-item">
          <h3 className="accordion-header" id="flush-headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Property Details
            </button>
          </h3>
          {/* Property Details */}
          <div id="flush-collapseOne" className="accordion-collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div className="nav nav-tabs nav-fill" id="prop-tab" role="tablist">
              <button className="nav-link active" id="nav-details-tab" data-bs-toggle="tab" data-bs-target="#nav-details" type="button" role="tab" aria-controls="nav-details" aria-selected="true">Details</button>
              <button className="nav-link" id="nav-forwebsite-tab" data-bs-toggle="tab" data-bs-target="#nav-forwebsite" type="button" role="tab" aria-controls="nav-forwebsite" aria-selected="false">For website</button>
              <button className="nav-link" id="nav-documents-tab" data-bs-toggle="tab" data-bs-target="#nav-documents" type="button" role="tab" aria-controls="nav-documents" aria-selected="false">Documents</button>
              <button className="nav-link" id="nav-portal-tab" data-bs-toggle="tab" data-bs-target="#nav-portal" type="button" role="tab" aria-controls="nav-portal" aria-selected="false">Portal</button>
              <button className="nav-link" id="nav-images-tab" data-bs-toggle="tab" data-bs-target="#nav-images" type="button" role="tab" aria-controls="nav-images" aria-selected="false">Images</button>
              <button className="nav-link" id="nav-international-tab" data-bs-toggle="tab" data-bs-target="#nav-international" type="button" role="tab" aria-controls="nav-international" aria-selected="false">International</button>
            </div>
            <div className="tab-content" id="nav-tabContent">
              {/* DETAILS */}
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab" tabIndex={0}>
                <div className="row">
                  <div className="input-group mb-3 col-3">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">Lead Gen</label>
                    </div>
                  </div>  
                  <div className="input-group mb-3 col-3">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="justlisted" />
                      <label className="control-labels" htmlFor="justlisted">Just Listed</label>
                    </div>
                  </div>  
                </div>
                <div className="row">
                  {/*<div className="col-6 form-group d-flex flex-column">*/}
                  {/*  <label className="control-labels">Agent Name</label>*/}
                  {/*  <select onChange={this.handleAgentDetails} value={this.state.listing_details.agent_id} name="listing_agent" id="listing_agent" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>*/}
                  {/*    {this.state.agents.map((agent)=>{*/}
                  {/*      return (*/}
                  {/*   <option value={agent.id}>{agent.fullname}</option>*/}
                  {/*      )*/}
                  {/*    })}*/}
                  {/*    */}
                  {/* */}
                  {/*  </select>*/}
                  {/*</div>*/}
                  <div className="col-6 form-group mb-0">
                      <label className="control-labels">Listing Status</label>
                      <select onChange={this.handleListingStatusDetails}  name="listingstatus">
                        <option>Draft</option>
                        <option selected>Confirmed Owner</option>
                        <option>Unpublished</option>
                        <option>Off-Market</option>
                        <option>Coming to Market</option>
                        <option>Publish</option>
                        <option>Action</option>
                        <option>SEO</option>
                        <option>Unlisted</option>
                        <option>Sold</option>
                        <option>Let</option>
                        <option>Review</option>
                        <option>Feeds</option>
                        <option>Move to Archive</option>
                      </select>
                    </div>
                {/*</div>*/}
                {/*<div className="row">*/}
                  <div className="col-6 form-group">
                    <label className="control-labels">Property For</label>
                    <select onChange={this.handlePropertyForDetails}  value={this.state.listing_details.property_for} name="propertyfor">
                      <option value="sale">Sale</option>
                      <option value="rent">Rent</option>
                    </select>
                  </div>
                    </div>
                    <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Completion Status</label>
                    <select onChange={this.handleCompletionDetails}  name="completion_status">
                      <option>Ready</option>
                      <option>Off Plan</option>
                      <option>Off Plan Resale</option>
                    </select>
                  </div>
                {/*</div>*/}
                {/*<div className="row">*/}
                {/*  <div className="col-6 form-group">*/}
                {/*    <label className="control-labels">Property Type</label>*/}
                {/*    <select onChange={this.handlePropertyTypeDetails}  value={this.state.listing_details.property_category_id} name="propertyfor">*/}
                {/*    <option value>Select</option>*/}
                {/*    {this.state.propertytypes.map((propertytype)=>{*/}
                {/*    return <option value={propertytype.id}>{propertytype.title}</option>*/}
                {/*  })}*/}
                {/*    </select>*/}
                {/*  </div>*/}
                  <div className="col-6 form-group">
                    <label className="control-labels">Style of Property</label>
                    <select onChange={this.handlePropertyStyleDetails}  name="stye_of_property">
                      <option>Modern</option>
                      <option>Contemporary</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Unit No</label>
                    <input onChange={this.handleUnitNoDetails}  type="text" placeholder="Unit no" />
                  </div>
                  <div className="col-6 form-group">
                    <label className="control-labels">Community</label>
                    <select onChange={this.handleCommunityDetails}  value={this.state.listing_details.development} name="community">
                    <option>Choose Community</option>
                      {this.state.communities.map((community)=>{
                        return  <option value={community.value}>{community.label}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Sub Community</label>
                    <select onChange={this.handleSubCommunityDetails}  value={this.state.listing_details.project} name="community">
                      <option>Choose Sub Community</option>
                      {this.state.sub_communities.map((subcommunity)=>{
                        return  <option value={subcommunity.value}>{subcommunity.label}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-6 form-group">
                    <label className="control-labels">Property</label>
                    <select onChange={this.handlePropertyDetails}  name="community">
                      <option>Choose Property</option>
                      <option>Frond A</option>
                      <option>Frond B</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Bedroom</label>
                    <select onChange={this.handleBedDetails}  value={this.state.listing_details.bedrooms} name="community">
                      <option value>Select</option>
                      <option value="Studio">Studio</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3} >3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                      <option value={11}>11</option>
                      <option value={12}>12</option>
                      <option value={13}>13</option>
                      <option value={14}>14</option>
                      <option value={15}>15</option>
                    </select>
                  </div>
                  <div className="col-6 form-group">
                    <label className="control-labels">Bathroom</label>
                    <select onChange={this.handleaBathroomDetails}  name="community">
                      <option>Choose Property</option>
                      <option>Frond A</option>
                      <option>Frond B</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-6 price">
                    <label className="control-labels">Price<em>*</em></label>
                    <input onChange={this.handlePriceDetails}  type="text" name="price" id="property_price" value={this.state.listing_details.price} className="form-control" placeholder="Price"  />
                    <button className="btn btn-primary ml-auto requestchange mt-2 font-14">Request Price Change</button>
                  </div>
                  <div className="col-6 form-group">
                    <label className="control-labels">View</label>
                    <input onChange={this.handleViewDetails} value={this.state.view_details} type="text" placeholder="Unit no" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Upgraded</label>
                    <select onChange={this.handleUpgradedDetails}  name="community">
                      <option>Yes</option>
                      <option selected>No</option>
                    </select>
                  </div>
                  <div className="col-6 form-group">
                    <label className="control-labels">Furnished</label>
                    <select onChange={this.handleFurnishedDetails}  name="community">
                      <option>Yes</option>
                      <option selected>No</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Vacant</label>
                    <select onChange={this.handleVacantDetails}  name="community">
                      <option>Yes</option>
                      <option selected>No</option>
                    </select>
                  </div>
                  <div className="col-6 form-group">
                    <label className="control-labels">Maids Room</label>
                    <select onChange={this.handleMaidsDetails}  name="community">
                      <option>Yes</option>
                      <option selected>No</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Tags</label>
                    <textarea onChange={this.handleTagsDetails}  rows={3} defaultValue={""} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 form-group">
                    <div className="d-flex flex-row justify-content-between">
                      <label className="control-labels">Description</label>
                      <button type="button" className="btn btn-primary btn-sm mb-1">Generate Description</button>
                    </div>
                    <textarea onChange={this.handleDescriptionDetails}  rows={3} defaultValue={""} />
                  </div>
                </div>
                <div className="row savegroup">
                  <div className="col-12 d-flex flex-row justify-content-between">
                    
                    <button onClick={this.handleSetailsSubmit} className="btn btn-primary px-3 ml-auto">Save</button>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-forwebsite" role="tabpanel" aria-labelledby="nav-forwebsite-tab" tabIndex={0}>
                <div className="row">
                  <div className="col-6 form-group">
                    <label className="control-labels">Youtube Video Link</label>
                    <input type="text" placeholder="youtube" />
                  </div>
                  <div className="col-6 form-group">
                    <label className="control-labels">Livetour 360 Video Link</label>
                    <input type="text" placeholder="livetour" />
                  </div>
                </div>
                <div className="row">
                  <div className="input-group mb-3 form-group uploadgrp">
                    <label className="control-labels">Brochure</label>
                    <input type="file" className="form-control w-100" id="inputGroupFile02" />
                  </div>                              
                  <div className="col-6 form-group">
                    <label className="control-labels">Facilites &amp; Amenities (Maximum of 10)</label>
                    <button onClick={this.handleOpenAmenities} type="button" className="btn btn-primary w-100">Choose Amenities</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group mb-3">
                    <label className="control-labels">Curations</label>
                    <textarea id="curations" className rowSpan={3} defaultValue={""} />
                  </div>                              
                  <div className="col-6 form-group">
                    <label className="control-labels">Lifestyle</label>
                    <textarea id="lifestyle" className rowSpan={3} defaultValue={""} />
                  </div>
                </div>
                {/* SEO Details */}
                <div className="row seogroup">
                  <label className="mb-2">SEO Details</label>
                  <div className="mb-3 col-6">
                    <div className="form-group">
                      <label className="control-labels">Meta Title</label>
                      <input type="text" placeholder="Meta Title" />
                    </div>
                    <div className="form-group mb-0">
                      <label className="control-labels">Meta Keywords</label>
                      <input type="text" placeholder="metakeywords" />
                    </div>
                  </div>                              
                  <div className="col-6 form-group">
                    <label className="control-labels">Meta Description</label>
                    <textarea id="metadesc" className="h-100" rowSpan={4} defaultValue={""} />
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-documents" role="tabpanel" aria-labelledby="nav-documents-tab" tabIndex={0}>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="control-labels">Notes</label>
                    <div className="d-flex flex-row" style={{gap: '0.3rem'}}>
                      <textarea onChange={this.handlePropertyNotes} name="notes[]" className="form-control notes" placeholder="Notes" rowSpan={1} value={this.state.property_note} />
                      <a onClick={this.handlePropertyNotesSubmit} className="btn btn-success btnnotes" id="addobj1" href="javascript:void(0)"><i className="bi bi-plus" /> </a>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <table className='table table-bordered'>
                      <thead>
                      <tr>
                        <th>Note</th>
                        <th>Date</th>
                       </tr>
                      </thead>
                    <tbody>
                    {this.state.document_notes.map((document_note) =>{
                      return (
                        <tr>
                          <td> {document_note.note}</td>
                          <td> {document_note.added_date}</td>
                          </tr>
                      )
                    })}
                    </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="control-labels">Documents</label>
                    <table className="table border-wrap">
                      <thead>
                        <tr><th>Title</th>
                          <th>File</th>
                          <th>Action</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td><input onChange={this.handleDocumentTitle} type="text" tabIndex={2} name="doctitle"  placeholder="Title" className="form-control input-sm" id="doctitle" /></td>
                          <td><input onChange={this.handleDocumentFile} type="file" tabIndex={2} name="documents" className="form-control input-sm" accept="image/gif, image/jpeg,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /></td>
                          <td><button onClick={this.handleDocumentsSubmit} className="btn btn-success" type="submit" href="javascript:void(0)" data-href><i className="bi bi-plus" /></button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade portal" id="nav-portal" role="tabpanel" aria-labelledby="nav-portal-tab" tabIndex={0}>
                <div className="row">
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="lpae" defaultChecked />
                      <label className="control-labels" htmlFor="leadgen">LP.ae</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="pf" defaultChecked />
                      <label className="control-labels" htmlFor="leadgen">Property Finder</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">Property Finder 2</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">Bayut</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">Broker Connection</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">Dubizzle</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">James Edition</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">Andson</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">STR PF</label>
                    </div>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="leadgen" />
                      <label className="control-labels" htmlFor="leadgen">STR Bayut</label>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-lpae-tab" data-bs-toggle="tab" data-bs-target="#nav-lpae" type="button" role="tab" aria-controls="nav-lpae" aria-selected="false">LP.ae</button>
                    <button className="nav-link" id="nav-pf-tab" data-bs-toggle="tab" data-bs-target="#nav-pf" type="button" role="tab" aria-controls="nav-pf" aria-selected="true">Property Finder</button>
                  </div>
                  <div className="tab-content p-3 border-wrap" id="nav-tabContent">
                    <div className="form-group mb-0">
                      <div className="d-flex flex-column">
                        <label className="control-labels">Title</label>
                        <input type="text" className="p-1" placeholder="Add title" />
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <div className="d-flex flex-column">
                        <label className="control-labels">Description</label>
                        <textarea id="description" placeholder="Add Description" rowSpan={3} defaultValue={""} />
                      </div>
                      <button id="calllogbtn" type="button" className="btn btn-primary requestchange mt-2">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade images" id="nav-images" role="tabpanel" aria-labelledby="nav-images-tab" tabIndex={0}>
                <div className="row">
                  <div className="d-flex flex-row justify-content-between btngrp">
                    <div className="d-flex flex-row">
                      <button onClick = {this.handleUploadImages} type="button" className="btn btn-primary">Upload All</button>
                      <button type="button" className="btn btn-secondary">Rename Images</button>
                    </div>
                    <button type="button" className="btn btn-primary">Preview</button>
                  </div>
                </div>
                <div className="row">

                <Dragger 
                  listType="picture-card"
                  // fileList={fileList}
                  // beforeUpload={beforeUpload}
                  // onPreview={handlePreview}
                  onChange={this.handleChangeImages}
                  onRemove={this.handleOnRemove}
                  multiple={true}
                  >
                    <div className="uploadIcon">
                      <UploadOutlined />
                    </div>
                    <div className="uploadText">
                      <p>Drag and drop here</p>
                      <p>OR</p>
                      <p>Click</p>
                    </div>
                  </Dragger>
            

                  {/* <div id="dropzone" className="text-center dropzone">
                    <i className="bi bi-cloud-arrow-up" />
                    <span>Drag and drop files here</span>
                    <span>or</span>
                    <span className="input-file float-right btn btn-primary mx-auto position-relative overflow-hidden mb-4" style={{maxWidth: 'fit-content', position: 'relative', marginBottom: '2rem'}}>
                      Browse<input id="fileupload" type="file" name="files[]" multiple className="position-absolute" style={{top: 0, right: 0, margin: 0, height: '100%', opacity: 0, filter: 'alpha(opacity=0)', direction: 'ltr', cursor: 'pointer', position: 'absolute', width: '100%'}} /></span>
                    <span>Recommended Minimum Dimensions: 1920 x 1080px</span>
                    <span>Supports: jpg, jpeg, png, gif</span>
                  </div> */}
                </div>
                {/* <div className="row">
                  <div className="d-flex flex-row btngrp mt-2">
                    <button type="button" className="btn btn-primary">Select All</button>
                    <button type="button" className="btn btn-secondary">Delete selected images</button>
                  </div>
                </div> */}
                <div className="row imgupgrp" style={{marginTop:'50px'}}>
                  {this.state.property_images.map((image)=>{
                    return (
                      <div className="imageuploadgrp col-4">
                      <img src={baseurlImg+"/"+image.image} className="w-100" />
                      <div className="d-flex flex justify-content-between">
                        <div className="input-group">
                          <div className="input-group-text">
                            <input className="form-check-input mt-0" type="radio" onChange={this.handleFeature.bind(this,image.id)} name={image.id} checked={image.default=="Y"?true:false} aria-label="Checkbox for following text input" id="featured" />
                            <label className="control-labels" htmlFor="leadgen">Featured</label>
                          </div>
                        </div>
                        <div className="input-group">
                          <div className="input-group-text">
                            <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="floorplan" />
                            <label className="control-labels" htmlFor="leadgen">Floor Plan</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-1">
                        <select name="tags">
                          <option>Tag</option>
                          <option>Exterior</option>
                          <option>Interior</option>
                          <option>Litchen</option>
                        </select>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="input-group">
                          <div className="input-group-text">
                            <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="delete" />
                            <label className="control-labels" htmlFor="leadgen">Delete</label>
                          </div>
                        </div>
                        <button className="btn btn-secondary"><i className="bi bi-eye-fill" /></button>
                        <button className="btn btn-secondary"><i className="bi bi-magic" /></button>
                      </div>
                    </div>
                    )
                  })}
                

                  {/* <div className="imageuploadgrp col-4">
                    <img src="https://www.luxuryproperty.com/uploads/images/550x375/7-bedroom-villa-for-sale-sector_w-LP13778-87e4123bf87ae80.jpg" className="w-100" />
                    <div className="d-flex flex justify-content-between">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="featured" />
                          <label className="control-labels" htmlFor="leadgen">Featured</label>
                        </div>
                      </div>
                      <div className="input-group">
                        <div className="input-group-text">
                          <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="floorplan" />
                          <label className="control-labels" htmlFor="leadgen">Floor Plan</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-1">
                      <select name="tags">
                        <option>Tag</option>
                        <option>Exterior</option>
                        <option>Interior</option>
                        <option>Litchen</option>
                      </select>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="delete" />
                          <label className="control-labels" htmlFor="leadgen">Delete</label>
                        </div>
                      </div>
                      <button className="btn btn-secondary"><i className="bi bi-eye-fill" /></button>
                      <button className="btn btn-secondary"><i className="bi bi-magic" /></button>
                    </div>
                  </div>
                  <div className="imageuploadgrp col-4">
                    <img src="https://www.luxuryproperty.com/uploads/images/550x375/7-bedroom-villa-for-sale-sector_w-LP13778-87e4123bf87ae80.jpg" className="w-100" />
                    <div className="d-flex flex justify-content-between">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="featured" />
                          <label className="control-labels" htmlFor="leadgen">Featured</label>
                        </div>
                      </div>
                      <div className="input-group">
                        <div className="input-group-text">
                          <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="floorplan" />
                          <label className="control-labels" htmlFor="leadgen">Floor Plan</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-1">
                      <select name="tags">
                        <option>Tag</option>
                        <option>Exterior</option>
                        <option>Interior</option>
                        <option>Litchen</option>
                      </select>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input className="form-check-input mt-0" type="checkbox" defaultValue aria-label="Checkbox for following text input" id="delete" />
                          <label className="control-labels" htmlFor="leadgen">Delete</label>
                        </div>
                      </div>
                      <button className="btn btn-secondary"><i className="bi bi-eye-fill" /></button>
                      <button className="btn btn-secondary"><i className="bi bi-magic" /></button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="tab-pane fade international" id="nav-international" role="tabpanel" aria-labelledby="nav-international-tab" tabIndex={0}>
                <div className="row">
                  <div className="col-12 form-group d-flex flex-column">
                    <label className="control-labels">International</label>
                    <select name="international" id="international" className="form-control">
                      <option>Yes</option>
                      <option selected>No</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group d-flex flex-column">
                    <label className="control-labels">Country</label>
                    <select name="intlcountry" id="intlcountry" className="form-control">
                      <option selected>United Arab Emirates</option>
                      <option>United Kingdom</option>
                      <option>United States</option>
                    </select>
                  </div>
                  <div className="col-6 form-group d-flex flex-column">
                    <label className="control-labels">City</label>
                    <select name="intlcity" id="intlcity" className="form-control">
                      <option selected>Dubai</option>
                      <option>Abu Dhabi</option>
                      <option>Ras Al Khaimah</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button className="btn btn-primary ml-auto">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
              </div>
    <div className="col-12 col-md-3">
      <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
        <h3>Match Details</h3>
        <div className="d-flex flex-column pt-3">
        <div className="d-flex flex-column matchfilter">
          <div className="col-12 px-0">
            <div className="form-group">
              <label for="community" className="mb-0">Community:</label>
              <Select id="community"
                       style={{
                        width: 160,
                      }}
                      
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleCommunityMatch}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.communities}
                />
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="form-group">
                <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub-Community:</label>
                <Select id="propsubcommunity"
                       style={{
                        width: 160,
                      }}
                     // mode={"multiple"}
                      
                    showSearch
                    maxTagCount={"responsive"}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleSubCommunityMatch}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.sub_communities}
                />
            </div>
          </div>
          <div class="col-12 px-0">
              <div className="form-group d-flex flex-row budget">
                  <label for="matchingBudgetMin">Budget:</label>
                  <div className="d-flex flex-row">
                    <input value={this.state.min_budget} type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" />
                    <span className="dash" style={{}}>-</span>
                    <input type="text" onChange={this.handleMaxBudget}  className="currencyInput" id="matchingBudgetMax" placeholder="Max" />
                  </div>
                </div>
          </div>
          <div class="col-12 px-0">
            <div className="form-group">
                <label for="matchingBedrooms">Beds:</label>
                <select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                  <option value>Select</option>
                  <option value="Studio">Studio</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3} >3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                </select>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="form-group d-flex flex-row w-100">
              <label for="addedDateM">Added Date:</label>
              <select onChange={this.handleAddedDateMatch}  id="addedDateM" className="form-control" title tabIndex={-1}>
                      <option value>Select</option>
                      <option value={1}>Last 24 Hours</option>
                      <option value={3}>Last 3 Days</option>
                      <option value={7}>Last 7 Days</option>
                      <option value={14}>Last 14 Days</option>
                      <option value={30}>Last 1 Month</option>
                      <option value={90}>Last 3 Month</option>
                      <option value={180}>Last 6 Month</option>
                      <option value={365}>Last 1 Year</option>
              </select>
            </div>
          </div>
          <div class="col-12 px-0">
            <div className="form-group d-flex flex-row">
                <label for="matchingPfor">Property For:</label>
                <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1}>
                  <option value>Select</option>
                  <option value="rent">Rent</option>
                  <option value="sale">Sale</option>
                </select>
            </div>
          </div>
          <div class="col-12 px-0">
            <div className="form-group d-flex flex-row">
                <label for="matchingType">Type:</label>
                <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                  <option>select</option>
                  {this.state.propertytypes.map((propertytype)=>{
              return <option value={propertytype.id}>{propertytype.title}</option>
                  })}
                  
               
                </select>
              </div>
          </div>
          <div class="col-12 px-0">
            <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
          </div>

        </div>
          {/* <div className="comment advmatch w-100">
            <div className="showMatchClass"><span className="font-bold">Match using: </span></div>
            <div className="form-controls">
              <div className="col-6 form-group px-0">
                <label className="control-labels ">Community<em>*</em></label>
                <Select
                       style={{
                        width: 160,
                      }}
                      
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleCommunityMatch}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.communities}
                />
              </div>
              <div className="col-6 form-group px-0">
                <label className="control-labels " htmlFor="propsubcommunity">Sub-Community</label>
                <Select
                       style={{
                        width: 160,
                      }}
                     // mode={"multiple"}
                      
                    showSearch
                    maxTagCount={"responsive"}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handleSubCommunityMatch}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.sub_communities}
                />
              </div>
            </div>
            <div className="form-controls">
              <div className="col-12 px-0 form-group d-flex flex-column">
                <label>Budget</label>
                <div className="d-flex flex-row">
                  <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" />
                  <span className="dash" style={{}}>-</span>
                  <input type="text" onChange={this.handleMaxBudget}  className="currencyInput" id="matchingBudgetMax" placeholder="Max" />
                </div>
              </div>
            </div>
            <div className="form-controls">
              <div className="col-6 px-0 form-group d-flex flex-column w-100">
                <label>Beds</label>
                <select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control select2 w-100 select2-offscreen" title tabIndex={-1}>
                  <option value>Select</option>
                  <option value="Studio">Studio</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3} >3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                </select>
              </div>
              <div className="col-6 px-0">
                <div className="form-controls">
                  <div className="col-12 px-0 form-group d-flex flex-column w-100">
                    <label>Added Date</label>
                    <select  id="addedDateM" className="form-control select2 select2-offscreen" title tabIndex={-1}>
                      <option value>Select</option>
                      <option value={1}>Last 24 Hours</option>
                      <option value={3}>Last 3 Days</option>
                      <option value={7}>Last 7 Days</option>
                      <option value={14}>Last 14 Days</option>
                      <option value={30}>Last 1 Month</option>
                      <option value={90}>Last 3 Month</option>
                      <option value={180}>Last 6 Month</option>
                      <option value={365}>Last 1 Year</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-controls">
              <div className="w-50 px-0 form-group d-flex flex-column">
                <label>Property For</label>
                <select onChange={this.handlePropertForMatch} id="matchingPfor" className="select2 select2-offscreen" title tabIndex={-1}>
                  <option value>Select</option>
                  <option value="rent">Rent</option>
                  <option value="sale">Sale</option>
                </select>
              </div>
              <div className="w-50 px-0 form-group d-flex flex-column">
                <label>Type</label>
                <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                  <option>select</option>
                  {this.state.propertytypes.map((propertytype)=>{
              return <option value={propertytype.id}>{propertytype.title}</option>
                  })}
                  
               
                </select>
              </div>
            </div>
            <div className="form-controls">
              <div className="col-12 px-0 form-group d-flex flex-column w-100">
                <label>Added Date</label>
                <select onChange={this.handleAddedDateMatch}  id="addedDateM" className="select2 select2-offscreen" title tabIndex={-1}>
                  <option value>Select</option>
                  <option value={1}>Last 24 Hours</option>
                  <option value={3}>Last 3 Days</option>
                  <option value={7}>Last 7 Days</option>
                  <option value={14}>Last 14 Days</option>
                  <option value={30}>Last 1 Month</option>
                  <option value={90}>Last 3 Month</option>
                  <option value={180}>Last 6 Month</option>
                  <option value={365}>Last 1 Year</option>
                </select>
              </div>
            </div>
            <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
          </div> */}
          <div className="matchresults">
            <div className="d-flex flex-row justify-content-between">
              <label className="control-labels mr-4">Match with access</label>
            </div>
            <div className="matchresult">

              {this.state.matchs.map((match)=>{
                return (
                  <a href className="matchitem">
                  <div className="matchinfo">
                    <div className="col-9 px-0">
                      <p className="name">{match.owner}</p>
                      <p className="address">{match.address}</p>
                      <p className="price">AED {match.price}</p>
                    </div>
                    <div className="col-3 px-0">
                      <p className="addeddate">
                      <Moment fromNow>
                      {match.added_date}
                      </Moment>
                        </p>
                    </div>
                  </div>
                  <div className="matchhistory">
                    <span className="time"></span>
                    <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                  </div>
                </a>
                )
              })}
    
        
                {this.state.matchs.length > 0?  <Link onClick={this.handleAllMatch} className="matchitem">

            View All
                
              </Link>:null}
            </div>
          </div>
          <div className="matchresults">
            <label className="control-labels mr-4">Match with restricted access</label>
            <div className="matchresult">
            {this.state.not_matchs.map((not_match)=>{
                return (
                  <a href className="matchitem">
                  <div className="matchinfo">
                    <div className="col-9 px-0">
                      <p className="name">{not_match.owner}</p>
                      <p className="address">{not_match.address}</p>
                      <p className="price">AED {not_match.price}</p>
                    </div>
                    <div className="col-3 px-0">
                      <p className="addeddate">
                      <Moment fromNow>
                      {not_match.added_date}
                      </Moment>
                        </p>
                    </div>
                  </div>
                  <div className="matchhistory">
                    <span className="time"></span>
                    <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                  </div>
                </a>
                )
              })}

                    {this.state.not_matchs.length > 0?  <Link onClick={this.handleAllNotMatch} className="matchitem">

                    View All
                        
                      </Link>:null}
          
             
             
            </div>
          </div>
        </div>
      </div> {/* END Match*/}
      {/* OFFERS */}
      <div className="bg-white border-top offers p-4 mt-4">
        <div className="d-flex flex-row justify-content-between">
          <h3 className="mr-4">Offers</h3>
        
        </div>
        <div className="d-flex flex-column border-wrap">
         
            {this.state.listing_offers.map((listing_offer) =>(
              <a onClick={this.handleShowOffer.bind(this, listing_offer.views_id)} className="matchitem">
              <div className="matchinfo">
                <div className="col-9 px-0">
                  <p className="name">{listing_offer.fullname}</p>
                  <p className="address">Offer: AED {listing_offer.price}</p>
                  <p className="price">{listing_offer.view_date}</p>
                </div>
                <div className="col-3 px-0">
                  <button type="button" className="btn btn-link addfeedback">Add feedback</button>
                </div>
              </div>
            </a>
            ))}
        
    
        {this.state.listing_offers.length > 0 ?<Link to={"/all_offers/"+this.state.record_id} className="btn btn-link viewall">View All</Link>
         :null }
         
        </div>
      </div> {/* END OFFERS */}
      {/* VIEWINGS */}
      <div className="bg-white border-top viewings p-4 mt-4">
        <div className="d-flex flex-row justify-content-between">
          <h3 className="mr-4">Viewings</h3>
          <Link onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</Link>
        </div>
        <div className="d-flex flex-column border-wrap">
          {this.state.property_viewings.map((property_viewing) =>{
            return (
              <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
              <div className="matchinfo">
                <div className="col-9 px-0">
                  <p className="name">{property_viewing.fullname}</p>
                  <p className="address">Offer: AED {property_viewing.price}</p>
                  <p className="price">{property_viewing.view_date}</p>
                </div>
                <div className="col-3 px-0">
                  <button type="button" className="btn btn-link addfeedback">Add feedback</button>
                </div>
              </div>
            </a>
            )
          })}
       
          {/* <a href className="matchitem">
            <div className="matchinfo">
              <div className="col-9">
                <p className="name">Mark Castley</p>
                <p className="address">Offer: AED 19,000,000</p>
                <p className="price">December 10, 2022</p>
              </div>
              <div className="col-3">
                <button type="button" className="btn btn-link viewfeedback">View feedback</button>
              </div>
            </div>
          </a> */}
          {/* <a href className="matchitem">
            <div className="matchinfo">
              <div className="col-9">
                <p className="name">Mark Castley</p>
                <p className="address">Offer: AED 19,000,000</p>
                <p className="price">December 10, 2022</p>
              </div>
              <div className="col-3">
                <button type="button" className="btn btn-link addfeedback">Add feedback</button>
              </div>
            </div>
          </a> */}
          {/* <a href className="matchitem">
            <div className="matchinfo">
              <div className="col-9">
                <p className="name">Mark Castley</p>
                <p className="address">Offer: AED 19,000,000</p>
                <p className="price">December 10, 2022</p>
              </div>
              <div className="col-3">
                <button type="button" className="btn btn-link viewfeedback">View feedback</button>
              </div>
            </div>
          </a> */}

{this.state.property_viewings.length > 0 ?<Link to={"/all_viewings/"+this.state.record_id} className="btn btn-link viewall">View All</Link>
         :null }

            {/* {this.state.property_viewings.length > 0?
            
            <a href className="matchitem viewall">
              View All
            </a>:null
            } */}
       
        </div>
      </div> {/* END VIEWINGS*/}
    </div>
  </div>
</div>


      </Drawer>
      
          
       <div className='contentwrap' style={{
           
           minHeight: 380,
         }}>


         <div className='ml-auto d-flex px-0'>
          
          <div className='row px-3 w-100'>
          <Space direction="vertical">
        
        
        <Space wrap>
          
        <div className="d-flex flex-row justify-content-between my-2 w-100">
                                        <h3 className="mb-0">Owner List</h3>
                                        <div  className="d-flex flex-row justify-content-between">
                                          
                                            <Button type='primary'  className=" ml-1 addnewlisting" size="large" onClick={this.handleClearFilter} icon={<ClearOutlined />  } style={{color:'white'}}>
                                                Reset filter
                                            </Button>

                                        </div>
                                    </div>


                                    <Drawer
                                        title={`Add new listing`}
                                        placement="left"
                                        // size={"large"}
                                        width={"98%"}
                                        onClose={this.handleCloseDrawer}
                                        open={this.state.open}
                                        extra={
                                            <Space>
                                                <Button type='primary' danger ghost onClick={this.handleCloseDrawer}
                                                        icon={<CloseOutlined/>}>Cancel</Button>
                                                <Button type="primary" onClick={this.handleSaveData}>
                                                    Save changes
                                                </Button>
                                            </Space>
                                        }
                                    >

                                        <div className="addlisting">
                                            {/*add listing start*/}
                                            <div className="container px-0"><h3 className='title'>Property Details</h3>
                                            </div>
                                            <div className='container border-wrap addwrap' style={{
                                                padding: 24,
                                                minHeight: 380,
                                                background: "#fff",
                                            }}>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Unit no</label>
                                                            <input type="text" className="form-control"
                                                                   value={(this.state.unit_no_details) ? this.state.unit_no_details : ""}
                                                                   onChange={this.addUnitNo.bind(this)}/>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Community</label>
                                                            <Select
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                showSearch
                                                                placeholder="Select a person"
                                                                defaultValue={this.state.communities && this.state.communities.filter((community) => {
                                                                    return (community.label === this.state.listing_details.community_title)
                                                                })}
                                                                onChange={this.addCommunity.bind(this, this.state)}
                                                                optionFilterProp="children"
                                                                // onChange={this.handleCommunity}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.communities}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-6'>

                                                        <div className='form-group'>
                                                            <label>Sub community</label>
                                                            <Select
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                showSearch
                                                                defaultValue={this.state.sub_communities && this.state.sub_communities.filter((sub_community) => {
                                                                    return (sub_community.label === this.state.listing_details.sub_community_title)
                                                                })}
                                                                placeholder="Select a person"
                                                                onChange={this.addSubCommunity.bind(this, this.state)}
                                                                optionFilterProp="children"
                                                                // onChange={this.handleCommunity}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.sub_communities}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>

                                                        <div className='form-group'>
                                                            <label>Property</label>
                                                            <Select
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                showSearch
                                                                placeholder="Select a person"
                                                                defaultValue={this.state.properties && this.state.properties.filter((property) => {
                                                                    return (property.label === this.state.listing_details.property_title)
                                                                })}
                                                                onChange={this.addProperty.bind(this, this.state)}
                                                                optionFilterProp="children"
                                                                // onChange={this.handleCommunity}
                                                                // onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={this.state.properties}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                    

                                                <div className='row mt-3'>
                                                    <div className='col-md-6'>
                                                        <label>Owner's Name</label>
                                                        <input type="text" className="form-control"
                                                               value={(this.state.owner_name) ? this.state.owner_name : ""}
                                                               onChange={this.addOwnersName.bind(this)}/>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <label>Owner's Phone Number</label>
                                                        <input type="text" className="form-control"
                                                               value={(this.state.owner_mobile) ? this.state.owner_mobile : ""}
                                                               onChange={this.addOwnersPhone.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-md-6'>
                                                        <label>Owner's Email Address </label>
                                                        <input type="text" className="form-control"
                                                               value={(this.state.owner_email) ? this.state.owner_email : ""}
                                                               onChange={this.addOwnersEmail.bind(this)}/>
                                                    </div>

                                                    <div className="col-6 form-group">
                                                        <label className="control-labels">Property Type</label>
                                                        <select onChange={this.addPropertyType.bind(this, this.state)}
                                                                defaultValue={this.state.property_categories && this.state.property_categories.filter((propertytype) => {
                                                                    return (propertytype.label === this.state.listing_details.property_type)
                                                                })}
                                                                name="property_type">
                                                            <option value>Select</option>
                                                            {this.state.property_categories.map((propertytype) => {
                                                                return <option
                                                                    value={propertytype.value}>{propertytype.label}</option>
                                                            })}
                                                        </select>
                                                    </div>

                                                  
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-md-6'>
                                                        <label>Stage </label>
                                                        <select className='form-select'
                                                                defaultValue={this.state.stage_details}
                                                                onChange={this.addStage.bind(this, this.state)}>
                                                            <option value="CTM">Coming to market</option>
                                                            <option value="UC">Confirmed owner</option>
                                                        </select>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <label>Bedrooms</label>
                                                        <select className='form-select'
                                                                onChange={this.addBedrooms.bind(this, this.state)}>
                                                            <option value>Select</option>
                                                            {this.state.bedrooms.map((bedroom) => {
                                                                return <option
                                                                    value={bedroom.id}>{bedroom.bedroom}</option>
                                                            })}
                                                        </select>
                                                    </div>

                                                </div>

                                                <div className='row mt-3 mb-4'>
                                                    <div className='col-md-6'>
                                                        <label>Price </label>
                                                        <input type="text" className='form-control'
                                                               onChange={this.addPrice.bind(this, this.state)}/>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <label>Property for</label>
                                                        <select className='form-select'
                                                                onChange={this.addPropertyFor.bind(this, this.state)}>
                                                            <option>select property for</option>
                                                            <option value="rent">Rent</option>
                                                            <option value="sale">Sale</option>
                                                            <option value="short-term">Short term</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                {this.state.property_for_is ?
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label>Available date</label>
                                                            <DatePicker showTime
                                                                        onChange={this.handleAvailableDate.bind(this, this.state)}
                                                                        onOk={this.handleAvailableDateOk}/>
                                                        </div>
                                                    </div> : null
                                                }

                                                <Button type="primary"
                                                        onClick={this.addNewListing.bind(this, this.state)}>
                                                    <SaveOutlined/> Save
                                                </Button>
                                                {/*add listing end*/}
                                            </div>
                                        </div>

                                    </Drawer>

        <Modal width={1000} title="Basic Modal" open={this.state.openToAmenities} onOk={this.handleSaveAmenities} onCancel={this.handleCancelAmenities}>
      <div className='row'>
        {this.state.facilities.map((facility) =>{
          return (
            <div className='col-md-4'>
              <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id={facility.value} />
              <label class="form-check-label" for={facility.value}>
                {facility.label}
              </label>
            </div>

              </div>
          )
        })}
      </div>
      </Modal>



          <Modal width={600} title="Filter" open={this.state.isFilterOpen} onOk={this.handleOk} onCancel={this.handleCancel}>

            <div className='col-12 d-flex flex-row px-0'>
                <div className='form-group w-100'>
                  <label>Title</label>
                   <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
                </div>
            </div>


         <Select
         
         showSearch
         style={{
           width: 300,
         }}
         placeholder="add filter"
         optionFilterProp="children"
         filterOption={(input, option) =>
             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
             }
         options={this.state.filter_options}
         onChange={this.handleAddRow}
         //   onChange={add()}
       />

{this.state.rows.map((item, idx) => (
                    <div className="w-100">
                        {item.id==1?<div className='row mt-3'>  <div className='col-md-8'>
                        <div className='form-group'>
                            <label>Ref no</label>
                            <div >
             
             <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
            <input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
            </div>
            {this.state.refno_active_second_option?(   <div className='mt-2' > 
            <center>
            <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option?true:false} onChange={this.handleRefRadio} />
            <label class="form-check-label" for="flexRadioDefault1">
              AND
            </label>
            <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option?true:false} onChange={this.handleRefRadio} />
            <label class="form-check-label ml-4" for="flexRadioDefault1">
              OR
            </label>
            
             </div>
          </center>
          <select class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
            <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
            </div>

            </div>):null}
         
            

             </div>
                            </div>
                            </div>
                            <div className='col-md-4 mt-4'>  <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx,item.id)}
                        >Remove
                        </button></div>
                            </div>:null}
                        {item.id==2?     <div className='row'>  <div className='col-md-4'>
                   <div className='form-group'>
                   <label>Property for</label>
                   <Select
               mode="multiple"            
               style={{
               width: '100%',
                }}
                placeholder="Please select"
                onChange={this.handlePropertyFor}
                options={this.state.property_for_option}
                />
                
                   </div>
                </div>      <div className='col-md-4'>
                   </div><div className='col-md-4 mt-4'>  <button
                          className="btn btn-outline-danger"
                          onClick={this.handleRemoveSpecificRow(idx,item.id)}
                        >Remove
                        </button></div></div>:null}
                        {item.id==3?     <div className='row'>  <div className='col-md-4'>
                   <div className='form-group'>
                   <label>Property types</label>
                   <Select
               mode="multiple"            
               style={{
               width: '100%',
                }}
                placeholder="Please select"
                onChange={this.handlePropertyType}
                options={this.state.property_type_option}
                />
                
                   </div>
                </div>      <div className='col-md-4'>
                   </div><div className='col-md-4 mt-4'>  <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx,item.id)}
                        >
                          Remove
                        </button></div></div>:null}
                        {item.id==4?<div className='row mt-3'>

                        <div className='col-md-4'>
                  
                        Unit no
                 <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
                  <option value="1">Contain</option>
                  <option value="2">Not contain</option>
                  <option value="3">Equals</option>
                  <option value="4">Not equal</option>
                  <option value="5">Starts with</option>
                  <option value="6">Ends with</option>
                </select>
                <div className='mt-2'>
                <input type="text" className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." />
                </div>
                {this.state.unitno_active_second_option?(   <div className='mt-2' > 
                <center>
                <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadio" id="flexRadio1" checked={this.state.unitno_and_or_option?true:false} onChange={this.handleUnitRadio} />
                <label class="form-check-label" for="flexRadio1">
                  AND
                </label>
                <input class="form-check-input ml-3" type="radio" name="flexRadio" id="flexRadio1" checked={!this.state.unitno_and_or_option?true:false} onChange={this.handleUnitRadio} />
                <label class="form-check-label ml-4" for="flexRadio1">
                  OR
                </label>
                
                 </div>
              </center>
              <select class="form-select" aria-label="Default select example" name='unitno_second_filter_value_id' onChange={this.handleUnitnoSecondValue_id}>
                  <option value="1">Contain</option>
                  <option value="2">Not contain</option>
                  <option value="3">Equals</option>
                  <option value="4">Not equal</option>
                  <option value="5">Starts with</option>
                  <option value="6">Ends with</option>
                </select>
                <div className='mt-2'>
                <input type="text" className="form-control" onChange={this.handleUnitnoSecond} placeholder="filter..." />
                </div>
  
                </div>):null}
             
                
  
                 </div>
             <div className='col-md-4'></div>
             <div className='col-md-4 mt-4'>  <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx,item.id)}
                        >
                          Remove
                        </button></div> </div>:null}

                {item.id == 5? 
                <div className='row mt-3'>
             <div className='col-md-4'>
                <label>Created</label>


                <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan}/>
                <label class="form-check-label" for="flexRadioDefault1">
                 More than
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option}  name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan}  />
                <label class="form-check-label" for="flexRadioDefault2">
                  Less than
                </label>
              </div>

              <div className='' style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <input type="text" className="form-control"  onChange={this.handleDateValue}/>
                <select className='form-select ml-1'  onChange={this.handleDateValue_id}>
                  <option value="1">days</option>
                  <option value="2">weeks</option>
                  <option value="3">months</option>
                  <option value="4">years</option>
                </select>
               </div>

             </div>
             <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx,item.id)}
                        >
                          Remove
                        </button></div></div>:null}
                        {item.id==6?<div className='row'>
                        <div className='col-md-4'>
                    <div className='form-group'>
                        <label>Price</label>
                        <div >
                  
                 <select class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                  <option value="1">Equals</option>
                  <option value="2">Not equal</option>
                  <option value="3">Less than</option>
                  <option value="4">Less than or equals</option>
                  <option value="5">Greater than</option>
                  <option value="6">Greater than or equals</option>
                  <option value="7">In range</option>
                </select>
                <div className='mt-2'>
                  {this.state.inrange?<div style={{display:'flex',flexDirection:'row'}}>
                    <input type="text" style={{width:'105px'}} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                    <input type="text" style={{width:'105px'}} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
                  </div>:   <input type="text" className="form-control" pattern = '[0-9]*\.?[0-9]*'   onChange={this.handlePriceFirst} placeholder="filter..." />}
                </div>
                {this.state.price_active_second_option?(   <div className='mt-2' > 
                <center>
                <div class="form-check">
                <input class="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option?true:false} onChange={this.handlePriceRadio} />
                <label class="form-check-label" for="pricefirst">
                  AND
                </label>
                <input class="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option?true:false} onChange={this.handlePriceRadio} />
                <label class="form-check-label ml-4" for="pricesecond">
                  OR
                </label>
                
                 </div>
              </center>
              <select class="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
                  <option value="1">Equals</option>
                  <option value="2">Not equal</option>
                  <option value="3">Less than</option>
                  <option value="4">Less than or equals</option>
                  <option value="5">Greater than</option>
                  <option value="6">Greater than or equals</option>
                  <option value="7">In range</option>
                </select>
                <div className='mt-2'>
                <input type="text" className="form-control" onChange={this.handlePriceSecond} placeholder="filter..."  />
                </div>
  
                </div>):null}
             
                
  
                 </div>
                       

                    </div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-4'> <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx,item.id)}
                        >
                          Remove
                        </button></div>

                        </div>:null}
                        {item.id==7?<div className='row'>
                        <div className='col-md-4'>
                    <div className='form-group'>
                        <label>Furnished / Unfurnished</label>
                            <Select
                            mode="multiple"            
                            style={{
                            width: '100%',
                              }}
                            
                              placeholder="Please select"
                              onChange={this.handleFurnishedTypes}
                              options={this.state.furnished_types}
                              />

                    </div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-4'> <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx,item.id)}
                        >
                          Remove
                        </button></div>

                        </div>:null}

                
                </div>
                  ))}
               
            
          </Modal>


{/* edit filter */}
<Modal width={1000} title="Filter" open={this.state.isFilterEditOpen} onOk={this.handleOk} onCancel={this.handleCancelEit}>

<div className='row'>
  <div className='col-md-6'>
    <div className='form-group w-100'>
      <label>Title</label>
       <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
    </div>
  </div>
</div>


<Select

showSearch
style={{
width: 300,
}}
placeholder="add filter"
optionFilterProp="children"
filterOption={(input, option) =>
 (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
 }
options={this.state.filter_options}
onChange={this.handleAddRow}
//   onChange={add()}
/>

{this.state.rows.map((item, idx) => (
        <div >
            {item.id==1?<div className='row mt-3'>  <div className='col-md-4'>
            <div className='form-group'>
                <label>Ref no</label>
                <div >
 
 <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
  <option value="1">Contain</option>
  <option value="2">Not contain</option>
  <option value="3">Equals</option>
  <option value="4">Not equal</option>
  <option value="5">Starts with</option>
  <option value="6">Ends with</option>
</select>
<div className='mt-2'>
<input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
</div>
{this.state.refno_active_second_option?(   <div className='mt-2' > 
<center>
<div class="form-check">
<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option?true:false} onChange={this.handleRefRadio} />
<label class="form-check-label" for="flexRadioDefault1">
  AND
</label>
<input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option?true:false} onChange={this.handleRefRadio} />
<label class="form-check-label ml-4" for="flexRadioDefault1">
  OR
</label>

 </div>
</center>
<select class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
  <option value="1">Contain</option>
  <option value="2">Not contain</option>
  <option value="3">Equals</option>
  <option value="4">Not equal</option>
  <option value="5">Starts with</option>
  <option value="6">Ends with</option>
</select>
<div className='mt-2'>
<input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
</div>

</div>):null}



 </div>
                </div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-4'>  <button
              className="btn btn-outline-danger btn-sm"
              onClick={this.handleRemoveSpecificRow(idx,item.id)}
            >
              Remove
            </button></div>
                </div>:null}
            {item.id==2?     <div className='row'>  <div className='col-md-4'>
       <div className='form-group'>
       <label>Property for</label>
       <Select
   mode="multiple"            
   style={{
   width: '100%',
    }}
    placeholder="Please select"
    onChange={this.handlePropertyFor}
    options={this.state.property_for_option}
    />
    
       </div>
    </div>      <div className='col-md-4'>
       </div><div className='col-md-4 mt-4'>  <button
              className="btn btn-outline-danger btn-sm"
              onClick={this.handleRemoveSpecificRow(idx,item.id)}
            >
              Remove
            </button></div></div>:null}
            {item.id==3?     <div className='row'>  <div className='col-md-4'>
       <div className='form-group'>
       <label>Property types</label>
       <Select
   mode="multiple"            
   style={{
   width: '100%',
    }}
    placeholder="Please select"
    onChange={this.handlePropertyType}
    options={this.state.property_type_option}
    />
    
       </div>
    </div>      <div className='col-md-4'>
       </div><div className='col-md-4 mt-4'>  <button
              className="btn btn-outline-danger btn-sm"
              onClick={this.handleRemoveSpecificRow(idx,item.id)}
            >
              Remove
            </button></div></div>:null}
            {item.id==4?<div className='row mt-3'>

            <div className='col-md-4'>
      
            Unit no
     <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
      <option value="1">Contain</option>
      <option value="2">Not contain</option>
      <option value="3">Equals</option>
      <option value="4">Not equal</option>
      <option value="5">Starts with</option>
      <option value="6">Ends with</option>
    </select>
    <div className='mt-2'>
    <input type="text" className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." />
    </div>
    {this.state.unitno_active_second_option?(   <div className='mt-2' > 
    <center>
    <div class="form-check">
    <input class="form-check-input" type="radio" name="flexRadio" id="flexRadio1" checked={this.state.unitno_and_or_option?true:false} onChange={this.handleUnitRadio} />
    <label class="form-check-label" for="flexRadio1">
      AND
    </label>
    <input class="form-check-input ml-3" type="radio" name="flexRadio" id="flexRadio1" checked={!this.state.unitno_and_or_option?true:false} onChange={this.handleUnitRadio} />
    <label class="form-check-label ml-4" for="flexRadio1">
      OR
    </label>
    
     </div>
  </center>
  <select class="form-select" aria-label="Default select example" name='unitno_second_filter_value_id' onChange={this.handleUnitnoSecondValue_id}>
      <option value="1">Contain</option>
      <option value="2">Not contain</option>
      <option value="3">Equals</option>
      <option value="4">Not equal</option>
      <option value="5">Starts with</option>
      <option value="6">Ends with</option>
    </select>
    <div className='mt-2'>
    <input type="text" className="form-control" onChange={this.handleUnitnoSecond} placeholder="filter..." />
    </div>

    </div>):null}
 
    

     </div>
 <div className='col-md-4'></div>
 <div className='col-md-4 mt-4'>  <button
              className="btn btn-outline-danger btn-sm"
              onClick={this.handleRemoveSpecificRow(idx,item.id)}
            >
              Remove
            </button></div> </div>:null}

    {item.id == 5? 
    <div className='row mt-3'>
 <div className='col-md-4'>
    <label>Created</label>


    <div class="form-check">
    <input class="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan}/>
    <label class="form-check-label" for="flexRadioDefault1">
     More than
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" checked={this.state.date_lessthan_option}  name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan}  />
    <label class="form-check-label" for="flexRadioDefault2">
      Less than
    </label>
  </div>

  <div className='' style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
    <input type="text" className="form-control"  onChange={this.handleDateValue}/>
    <select className='form-select ml-1'  onChange={this.handleDateValue_id}>
      <option value="1">days</option>
      <option value="2">weeks</option>
      <option value="3">months</option>
      <option value="4">years</option>
    </select>
   </div>

 </div>
 <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
              className="btn btn-outline-danger btn-sm"
              onClick={this.handleRemoveSpecificRow(idx,item.id)}
            >
              Remove
            </button></div></div>:null}
            {item.id==6?<div className='row'>
            <div className='col-md-4'>
        <div className='form-group'>
            <label>Price</label>
            <div >
      
     <select class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
      <option value="1">Equals</option>
      <option value="2">Not equal</option>
      <option value="3">Less than</option>
      <option value="4">Less than or equals</option>
      <option value="5">Greater than</option>
      <option value="6">Greater than or equals</option>
      <option value="7">In range</option>
    </select>
    <div className='mt-2'>
      {this.state.inrange?<div style={{display:'flex',flexDirection:'row'}}>
        <input type="text" style={{width:'105px'}} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
        <input type="text" style={{width:'105px'}} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
      </div>:   <input type="text" className="form-control" pattern = '[0-9]*\.?[0-9]*'   onChange={this.handlePriceFirst} placeholder="filter..." />}
    </div>
    {this.state.price_active_second_option?(   <div className='mt-2' > 
    <center>
    <div class="form-check">
    <input class="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option?true:false} onChange={this.handlePriceRadio} />
    <label class="form-check-label" for="pricefirst">
      AND
    </label>
    <input class="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option?true:false} onChange={this.handlePriceRadio} />
    <label class="form-check-label ml-4" for="pricesecond">
      OR
    </label>
    
     </div>
  </center>
  <select class="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
      <option value="1">Equals</option>
      <option value="2">Not equal</option>
      <option value="3">Less than</option>
      <option value="4">Less than or equals</option>
      <option value="5">Greater than</option>
      <option value="6">Greater than or equals</option>
      <option value="7">In range</option>
    </select>
    <div className='mt-2'>
    <input type="text" className="form-control" onChange={this.handlePriceSecond} placeholder="filter..."  />
    </div>

    </div>):null}
 
    

     </div>
           

        </div>
    </div>
    <div className='col-md-4'></div>
    <div className='col-md-4 mt-4'> <button
              className="btn btn-outline-danger btn-sm"
              onClick={this.handleRemoveSpecificRow(idx,item.id)}
            >
              Remove
            </button></div>

            </div>:null}
            {item.id==7?<div className='row'>
            <div className='col-md-4'>
        <div className='form-group'>
            <label>Furnished / Unfurnished</label>
                <Select
                mode="multiple"            
                style={{
                width: '100%',
                  }}
                
                  placeholder="Please select"
                  onChange={this.handleFurnishedTypes}
                  options={this.state.furnished_types}
                  />

        </div>
    </div>
    <div className='col-md-4'></div>
    <div className='col-md-4 mt-4'> <button
              className="btn btn-outline-danger btn-sm"
              onClick={this.handleRemoveSpecificRow(idx,item.id)}
            >
              Remove
            </button></div>

            </div>:null}

    
    </div>
      ))}
   

</Modal>

        
         
          
        </Space>
      </Space>
          </div>
         </div>



              
        <div class="table-responsive tablelistwrap px-3" >
        <ReactDragListView.DragColumn  onDragEnd={this.onDragEnd}
        nodeSelector="th">

                                  <Table onChange={handleTableChange}

                              
                                   loading={this.state.isLoading}
                                   rowSelection={rowSelection}
                                  //className="table-striped table-bordered"
                                  pagination={{
                                    pageSizeOptions: ['10', '20','50','100'],
                                    total:
                                      this.state?.owner_listings?.total > 0 &&
                                      this.state.owner_listings.total,
                                    pageSize: this.state.owner_listings.per_page,
                                    current: this.state.owner_listings.current_page,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                  style={{ overflowX: "auto" }}
                                  columns={columns}
                                   bordered
                                  dataSource={
                                    this.state?.owner_listings?.data
                                      ? this.state?.owner_listings?.data
                                      : []
                                  }
                                  
                                  rowKey={(record) => record}
                                  />
                                  </ReactDragListView.DragColumn>
                             
                                  </div>
       </div>
       </div>
    )
  }
}




export default OwnerListings

// export default  MyListings;
