import React, {Component} from "react";
import { Link } from 'react-router-dom';
import { Breadcrumb,Button, Checkbox, Form, Input,InputNumber,Select,Switch,Table, Modal } from 'antd';
import { SaveOutlined} from '@ant-design/icons';
import API from '../../utils/API';
import { PlusCircleOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction";
import Swal from "sweetalert2";

class ListCommunity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      community_name: "",
      add_community_modal: false,
      edit_community_id : "",
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage);
  }
  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      API.get(`/community?page=${page}&perPage=${perPage}`).then(
        async (response) => {
          console.log(response);
          // const communities = response.data.communities;
          var data = await response.data.communities;
          await response.data.communities;
          this.setState({
            communities: response.data.communities,
            isLoading: false,
          });
        }
      );
    }, 300);
  };

  OpenAddCommunityModal = () => {
    this.setState({ community_name: "" });
    this.setState({ add_community_modal: true });
  };

  handleOKModal = () => {
    this.setState({ add_community_modal: false });
  };
  handleCancel = () => {
    this.setState({ add_community_modal: false });
  };

  HandelCommunityName = (e) => {
    this.setState({ community_name: e.target.value });
  };

  SaveCommunity = () => {
    const { community_name, edit_community_id } = this.state;
    const data = {
      community_name: community_name,
    };
    if (edit_community_id){
       API.put(`/update_community/${edit_community_id}`, data).then((response) => {
      if (response.data.status === "success") {
        toast.success("Community Updated Successfully");
        this.setState({ community_name: "", edit_community_id: "", add_community_modal: false });
        this.fetchData(1, 10);
      }
    });
    }else{
      API.post("/add_community", data).then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          this.setState({ community_name: "" });
          toast.success("Comminity Add SuccessFully");
          this.setState({ add_community_modal: false });
          const page = 1;
          const perPage = 10;
          this.fetchData(page, perPage);
        }
      });
    }
  };

  handleDelete = (id, e) => {
    const page = 1;
    const perPage = 10;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_community/" + id).then((response) => {
          if (response.data.status === "success") {
            toast.success("Successfully deleted");
            this.fetchData(page, perPage);
          }
        });
      }
    });
  };
  handleEdit = (id, e) => {
    const page = 1;
    const perPage = 10;
    API.get("/editCommunity/" + id).then((response) => {
      if (response.data.communities){
          this.setState(
            {
              community_name: response.data.communities.title,
              add_community_modal: true,
              edit_community_id: id,
            },
            () => {
              const data = {
                // edit_community_id: this.state.id,
                community_name: this.state.community_name,
              };
            }
          );
        }
    });

  };

  // Render Html & Functions
  render() {
    const columns = [
      {
        title: "Community Name",
        dataIndex: "label", // Use 'label' to access the community name
        key: "label",
        sorter: (a, b) => a.label.localeCompare(b.label), // Sorting by label text
      },
      {
        title: "Action",
        render: (text, record) => (
          <div className="ms-auto">
            {/* Value means Id */}
            <a
              href="#"
              className="btn btn-success btn-sm m-2"
              onClick={this.handleEdit.bind(this, record.value)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-edit"></i>{" "}
            </a>
            <a
              className="btn btn-danger btn-sm m-2"
              onClick={this.handleDelete.bind(this, record.value)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </a>
          </div>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };
    return (
      <div>
        <ToastContainer />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item> <Link to="/dashboard">Dashboard</Link> </Breadcrumb.Item>
            <Breadcrumb.Item>Locations</Breadcrumb.Item>
            <Breadcrumb.Item>communities List</Breadcrumb.Item>
          </Breadcrumb>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={this.OpenAddCommunityModal}
          >
            {" "}
            <PlusCircleOutlined />
            Add communities
          </Button>
        </div>
        <Modal
          width={600}
          title="Offers"
          open={this.state.add_community_modal}
          onOk={this.handleOKModal}
          onCancel={this.handleCancel}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group mt-2">
                  <input
                    type="text"
                    name="community"
                    placeholder="Community"
                    className="form-control input-sm"
                    id="community"
                    onChange={this.HandelCommunityName}
                    value={this.state.community_name}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <button
                  onClick={this.SaveCommunity}
                  className="btn btn-success"
                  type="submit"
                  href="javascript:void(0)"
                  data-href
                >
                  {" "}
                  <i className="bi bi-check" />{" "}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        ;
        <div style={{ padding: 24, minHeight: 380, background: "#fff" }}>
          <div class="table-responsive">
            <Table
              onChange={handleTableChange}
              loading={this.state.isLoading}
              className="table-striped"
              pagination={{
                pageSizeOptions: ["5", "10", "20", "50", "100"],

                total: this.state.communities
                  ? this.state.communities.length
                  : 0,
                // total: this.state?.title?.total > 0 && this.state.title.total,
                // pageSize : this.state.communities.per_page,
                // current  : this.state.communities.current_page,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              style={{ overflowX: "auto" }}
              columns={columns}
              // bordered
              dataSource={this.state.communities ? this.state.communities : []}
              rowKey={(value) => value}
            />
          </div>
        </div>
      </div>
    );
  }
}



// componentDidMount(){}







export default ListCommunity;