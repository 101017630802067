import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import 'antd/dist/reset.css';
import { Table, Space, Select, Tag, DatePicker, Modal, Row, Col, Checkbox, Button, Spin, Drawer, Radio, Skeleton, Input, Collapse, Popover } from 'antd'
import { ClearOutlined, UploadOutlined } from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import dayjs from "dayjs";
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { liveUrl } from '../../components/BaseUrl';

import './listing.css'
import { store } from '../../store';
import TextArea from 'antd/es/input/TextArea';
import Swal from 'sweetalert2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const { Panel } = Collapse;
let columns = []




class All_Listings_Leads_Offers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sale_screen_status: false,
      showSaleScreenStatus: false,
      isLoading: false,
      isTotalLoading: false,
      seller_data: {},
      propertytypes: [],
      input: {},
      errors: {},
      buyer_data: {},
      isPreviousLoading: false,
      isCurrent: false,
      isNext: false,
      isPrevious: false,
      deal_signed: 0,
      accepted_offer: 0,
      rejected_offer: 0,
      sent_offer: 0,
      commission: 0,
      total_listings: 0,
      all_viewings: [],
      all_upcomming_offers: [],
      role: "",
      all_previous_offers: [],
      communities: [],
      sub_communities: [],
      properties: [],
      agents: [],
      // filters
      community_filter: "",
      sub_community_filter: "",
      property_filter: "",
      viewing_feedback: 0,
      property_for_filter: [],
      // ref no
      refno_first_value_id: 1,
      refno_second_value_id: 1,
      refno_active_second_option: false,
      refno_and_or_option: true,
      refno_first_value: "",
      refno_second_value: "",
      // unit no
      unitno_first_value_id: 1,
      unitno_second_value_id: 1,
      unitno_active_second_option: false,
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_second_value: "",
      // status
      status_filter: [],
      offer_status_filter: [],
      // agent filter
      agent_value_id: 1,
      vendor_agent_value_id: 1,
      agent_value: "",
      vendor_agent_value: [],

      // client filter
      client_value_id: 1,
      client_value: "",

      // price
      price_first_value_id: 1,
      price_second_value_id: 1,
      price_active_second_option: false,
      price_and_or_option: true,
      price_first_value: "",
      price_second_value: "",
      inrange: false,
      price_min: 0,
      price_max: 0,

      // offers
      offers: {},
      offer_id: "",
      offers_agent: "",
      offers_date: "",
      offers_note: "",
      offers_status: 1,
      offers_price: "",
      offers_commission: "",
      split_agent: "",
      split_commission: "",
      vendor_agent_id: "",
      client_agent_id: "",
      offerModal: false,
      showPrice: false,
      showCommission: false,
      showReferral: true,
      ReferralChecked: false,
      showReferralAgent: false,
      selectedRefrralAgent: "",
      refrral_commission: "",
      showDate: false,

      month: "",

      months: [{
        key: 1,
        labe: "January"
      },
      {
        key: 2,
        labe: "February"
      },
      {
        key: 3,
        labe: "March"
      },
      {
        key: 4,
        labe: "April"
      },
      {
        key: 5,
        labe: "May"
      },
      {
        key: 6,
        labe: "June"
      },
      {
        key: 7,
        labe: "July"
      },
      {
        key: 8,
        labe: "August"
      },
      {
        key: 9,
        labe: "September"
      },
      {
        key: 10,
        labe: "October"
      },
      {
        key: 11,
        labe: "November"
      },
      {
        key: 12,
        labe: "December"
      },

      ],

      // MOU REQUEST
      ismourequestOpen: false,
      mou_price: "",
      mou_transfer_date: "",
      mou_annual_charge: "",
      mou_is_vacant: "no",
      mou_tenancy_exp_date: "",
      //seller
      seller_title_deed: "",
      seller_title_file: null,
      seller_title_imageShow: null,


      seller_passport: null,
      seller_passport_imageShow: null,

      seller_emirate_id: null,
      seller_emirate_id_imageShow: null,

      seller_visa: null,
      seller_visa_imageShow: null,

      seller_commission: "",
      seller_conveyancing_fee: "",
      // buyer
      buyer_title_deed: "",
      buyer_title_file: null,
      buyer_title_file_imageShow: null,

      buyer_passport: null,
      buyer_passport_imageShow: null,

      buyer_emirate_id: null,
      buyer_emirate_id_imageShow: null,

      buyer_visa: null,
      buyer_visa_imageShow: null,

      buyer_commission: "",
      buyer_conveyancing_fee: "",
      //other details
      furnished_unfurnished: "",
      valuation_variance: "",
      special_conditions: "",


      // Tenancy Contract
      iscontractOpen: false,
      address: "",
      dewa: "",
      location: "",
      property_category_id: "",
      plot: "",
      property_number: "",
      building_name: "",
      property_type: "",
      bedrooms: "",
      area_size: "",
      landloard_emirate_id_number: "",
      landloard_name: "",
      landloard_nationality: "",
      landloard_mobile: "",
      landloard_email: "",
      lms_link: "",
      tenant_emirate_id_number: "",
      tenant_name: "",
      tenant_mobile: "",
      tenant_email: "",
      contract_start_date: "",
      contract_end_date: "",
      rental_price: "",
      security_deposit: "",
      payment_type: "",
      month_number: "",
      contract_commission: "",
      contract_value: "",
      condition_terms_one: "",
      condition_terms_two: "",
      condition_terms_three: "",
      condition_terms_four: "",
      condition_terms_five: "",
      // additional_clauses_contract:"",
      additional_clauses_addendum: "",
      title_deed_image: null,
      title_deed_imageShow: null,

      landloard_passport_image: null,
      landloard_passport_imageShow: null,

      landloard_emirate_id_image: null,
      landloard_emirate_id_imageShow: null,

      tenant_passport_image: null,
      tenant_passport_imageShow: null,

      tenant_emirate_id_image: null,
      tenant_emirate_id_imageShow: null,

      tenant_visa_image: null,
      tenant_visa_imageShow: null,

      poa_image: null,
      poa_imageShow: null,

      cheque_image: null,
      cheque_imageShow: null,

    }

    this.textInput = React.createRef();

  }

  componentDidMount() {

    var loadScript = function (src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    }
    loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
    //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')
    this.setState({ isNext: true, isPrevious: true })
    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const monthData = { month: this.state.month }
    this.fetchTotal(monthData);
    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

    API.get("/community")
      .then((response) => {
        if (response.data.success) {
          this.setState({ communities: response.data.communities })
        }
      })
    API.get("/all_agents")
      .then((response) => {
        if (response.data.success) {
          this.setState({ agents: response.data.agents })
        }
      })
    API.get("/propertytypes")
      .then((response) => {
        if (response.data.success) {
          this.setState({ propertytypes: response.data.propertytypes })
        }
      })

  }

  handleDeleteOffer = (offer_id) => {
    if (offer_id == "" || offer_id < 1)
      return;
    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }


    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.delete("/delete_offer/" + offer_id)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchData(page, perPage, data)
            }

          })
      }
    })
  }




  // fetchData = (page, perPage, data) => {
  //   this.setState({ isLoading: true })
  //   //    setTimeout(() => {


  //   API.post(`/all_listings_leads_offers?page=${page}&perPage=${perPage}`, data)
  //     .then(async (response) => {
  //       var data = await response.data.all_viewings.data;

  //       await response.data.all_viewings;
  //       this.setState({
  //         all_viewings: response.data.all_viewings,
  //         isLoading: false
  //       })

  //     })
  //   // }, 200);

  // }

  fetchTotal = (monthData) => {
    this.setState({ isTotalLoading: true })
    API.post("/total_offers", monthData)
      .then(async (response) => {
        if (response.data.success) {
          this.setState({
            deal_signed: response.data.deal_signed,
            accepted_offer: response.data.accepted_offer,
            rejected_offer: response.data.rejected_offer,
            sent_offer: response.data.sent_offer,
            commission: response.data.commission,
            total_listings: response.data.total_listing,
            isTotalLoading: false
          })
        }
      })
  }

  fetchData = (page, perPage, data) => {
    this.setState({ isLoading: true })
    //  setTimeout(() => {


    API.post(`/all_listings_leads_offers?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {

        await response.data;
        this.setState({
          all_upcomming_offers: response.data.all_upcomming_offers,
          isLoading: false,
          // role: response.data.role.role,
        })
        // if (response.data.role.role == "Super Admin" || response.data.role.role == "Admin"){
        //   this.setState({showDate: true})
        // }else{
        //   this.setState({showDate: false})
        // }

      })
    //  }, 300);

  }

  fetchPreviousData = (page, perPage, data) => {
    this.setState({ isPreviousLoading: true })
    // setTimeout(() => {


    API.post(`/all_listings_leads_offers_prev?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {




        await response.data;
        this.setState({
          all_previous_offers: response.data.all_previous_offers,
          isPreviousLoading: false,
          // role: response.data.role.role
        })

      })
    //  }, 300);

  }

  handleCommunityFilter = (e) => {


    this.setState({ community_filter: e })

    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })

    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: e,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handleSubCommunityFilter = (e) => {

    this.setState({ sub_community_filter: e })
    API.get("/property/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ properties: response.data.properties })
      })
    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: e,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handlePropertyFilter = (e) => {
    this.setState({ property_filter: e })
    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: e,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handleRefnoFirstValue_id = (e) => {
    this.setState({ refno_first_value_id: e.target.value })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: e.target.value,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_first_value != '')
      this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  handleRefnoFirst = (e) => {

    const refno_first_value = e.target.value;
    this.setState({ refno_first_value: refno_first_value })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleUnitnoFirstValue_id = (e) => {
    this.setState({ unitno_first_value_id: e.target.value })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: e.target.value,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_first_value != '')
      this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  handleUnitnoFirst = (e) => {

    const unitno_first_value = e.target.value;
    this.setState({ unitno_first_value: unitno_first_value })

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleStatusFilter = (e) => {
    this.setState({ status_filter: e })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      offer_status_filter: this.state.offer_status_filter,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: e,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }

  handleOfferStatusFilter = (e) => {
    this.setState({ offer_status_filter: e })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: e,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);
  }



  // handle Client... by Farooq 
  handleClientValue_id = (e) => {

    this.setState({ client_value_id: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: e.target.value,
      client_value: this.state.client_value,

    }
    if (this.state.client_value != '') {
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleClient = (e) => {
    this.setState({ client_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: e.target.value,
    }
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }
  ///////////////////////////////////////////

  // handle agent ......
  handleAgentValue_id = (e) => {

    this.setState({ agent_value_id: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: e.target.value,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }
    if (this.state.agent_value != '') {
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleVendorAgentValue_id = (e) => {

    this.setState({ vendor_agent_value_id: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      vendor_agent_value_id: e.target.value,
      vendor_agent_value: this.state.vendor_agent_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }
    if (this.state.vendor_agent_value != '') {
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handleAgent = (e) => {
    this.setState({ agent_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: e.target.value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handleVendorAgent = (e) => {
    this.setState({ vendor_agent_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: e.target.value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);
  }

  handlePriceFirstValue_id = (e) => {
    this.setState({ inrange: false })
    if (e.target.value == 7) {
      this.setState({ inrange: true, price_first_value: 0 })
    } else {
      this.setState({ price_min: 0, price_max: 0 })
    }

    this.setState({ price_first_value_id: e.target.value })
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: e.target.value,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,


    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_first_value != '') {
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handlePriceMin = (e) => {
    this.setState({ price_min: e.target.value })


    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: e.target.value,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,


    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_max > 0) {
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }

  }

  handlePriceMax = (e) => {
    this.setState({ price_max: e.target.value })
    const max = e.target.value
    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: e.target.value,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,


    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_min > 0 && max > this.state.price_min) {
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    }
  }

  handlePriceFirst = (e) => {
    const price_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { price_first_value: e.target.value } : prevState)
    )



    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const page = 1;
    const perPage = 10;
    if (e.target.value > 0) {
      this.fetchData(page, perPage, data);
      this.fetchPreviousData(page, perPage, data);
    } else {
      return
    }

  }




  //////////////////  Handle Offer's Modal .... By Farooq  //////////////////////////

  // Handel Ref View .... By Farooq
  handleViewRefNo = (record, e) => {



    if (record.client_agent_id == record.vendor_agent_id) {
      this.setState({ split_agent: "" })
    } else {
      this.setState({ split_agent: record.vendor_agent })
    }
    this.setState({
      offers: record,
      // showReferral: false,
      showReferralAgent: record.offer_status == 4 ? true : false,
      // ReferralChecked:false,
      vendor_agent_id: record.vendor_agent_id,
      client_agent_id: record.client_agent_id,
      offers_agent: record.fullname,
      offers_date: record.view_date,
      offers_status: record.offer_status,
      offers_note: record.note,
      showPrice: record.status == "Offer" ? true : false,
      showCommission: record.status == "Offer" ? true : false,
      // showReferral: record.offer_status == 4 ? true : false,
      // showDate: record.status == "Second Viewing" ? true : false,
      offers_price: record.price,
      offers_commission: record.comission,
      offerModal: true,
      offer_id: record.views_id
    })
  }

  // Handle Change Status
  handleChangeStatus = () => {

    const property_for = this.state.offers.property_for
    const offer_status = this.state.offers_status



    if (this.state.offers_note == "") {
      toast.warning('Feedback is required...')
    }
    else {
      const page = 1
      const perPage = 10

      const data = {
        month: this.state.month,
        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,
        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        offer_status_filter: this.state.offer_status_filter,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,
        vendor_agent_value_id: this.state.vendor_agent_value_id,
        vendor_agent_value: this.state.vendor_agent_value,
        price_first_value_id: this.state.price_first_value_id,
        price_and_or_option: this.state.price_and_or_option,
        price_second_value_id: this.state.price_second_value_id,
        price_first_value: this.state.price_first_value,
        price_second_value: this.state.price_second_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,
        // by Farooq
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,
      }

      const data2 = {
        view_id: this.state.offer_id,
        vendor_agent_id: this.state.vendor_agent_id,
        client_agent_id: this.state.client_agent_id,
        split_commission: this.state.split_commission,
        // viewing_date:this.state.offers_date, 
        offer_status: this.state.offers_status,
        viewing_price: this.state.offers_price,
        commission_price: this.state.offers_commission,
        note: this.state.offers_note,
        selectedRefrralAgent: this.state.selectedRefrralAgent,
        refrral_commission: this.state.refrral_commission,
        offer_date: this.state.offers_date,
        sale_screen_status: this.state.sale_screen_status ? "No" : "Yes"
      }


      API.post("/change_offer_status", data2)
        .then((response) => {



          // if(response.data.success)
          if (response.data.success) {

            this.setState({
              vendor_agent_id: "",
              client_agent_id: "",
              split_commission: "",
              // viewing_date:this.state.offers_date, 
              offer_status: "",
              viewing_price: "",
              commission_price: "",
              note: "",
              selectedRefrralAgent: "",
              refrral_commission: "",
              offer_date: ""
            })

            if (property_for == "sale" && offer_status == 2) {

              Swal.fire({
                // title: "Create MOU Request!",

                text: "Would you like to make an MOU Request?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
                cancelButtonText: "No"
              }).then((result) => {
                if (result.isConfirmed) {
                  const listing_id = this.state.offers.listing_id
                  const leads_id = this.state.offers.leads_id

                  API.get("listing_offer_details/" + listing_id)
                    .then((response) => {
                      if (response.data.success) {
                        this.setState({ seller_data: response.data.listing })
                      }
                    })

                  API.get("lead_offer_details/" + leads_id)
                    .then((response) => {


                      if (response.data.success) {
                        this.setState({ buyer_data: response.data.lead })
                      }
                    })

                  this.setState({ ismourequestOpen: true })
                }
              })



            }

            // tenancy contract
            if (property_for == "rent" && offer_status == 2) {

              Swal.fire({
                // title: "Create MOU Request!",

                text: "Would you like to make an Tenancy contract?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
                cancelButtonText: "No"
              }).then((result) => {
                if (result.isConfirmed) {
                  const listing_id = this.state.offers.listing_id
                  const leads_id = this.state.offers.leads_id

                  API.get("listing_offer_details/" + listing_id)
                    .then((response) => {
                      if (response.data.success) {
                        const listing = response.data.listing
                        this.setState({
                          address: listing.address, landloard_name: listing.fullname, property_category_id: listing.property_category_id,
                          landloard_mobile: listing.mobile, landloard_email: listing.email, area_size: listing.bua, property_number: listing.unitno, bedrooms: listing.bedrooms
                        })
                      }
                    })

                  API.get("lead_offer_details/" + leads_id)
                    .then((response) => {


                      if (response.data.success) {
                        const lead = response.data.lead
                        this.setState({ tenant_name: lead.fullname, tenant_mobile: lead.mobile, tenant_email: lead.email })
                      }
                    })

                  this.setState({ iscontractOpen: true })
                }
              })



            }

            this.fetchData(page, perPage, data)
            this.fetchPreviousData(page, perPage, data);
          }
        })

      this.setState({ offerModal: false })
    }
  }

  // Handle Modal close
  handleCancel = () => {
    this.setState({ offerModal: false })
  }

  // Handle Offer Status
  handleOfferStatus = (e) => {


    const status = e.target.value
    this.setState({ offers_status: e.target.value })
    this.setState({ showPrice: true, showCommission: true })


    if (status == 4) {
      this.setState({ showSaleScreenStatus: true })
    } else {
      this.setState({ showSaleScreenStatus: false })
    }

    //  if (status == 4) {
    //   this.setState({ showReferral: true, })
    // } else {
    //   this.setState({ showReferral: false })
    // }

    if (status == 5) {

      const listing_id = this.state.offers.listing_id
      const leads_id = this.state.offers.leads_id
      const listing_for = this.state.offers.property_for

      API.get("listing_offer_details/" + listing_id)
        .then((response) => {
          if (response.data.success) {
            this.setState({ seller_data: response.data.listing })
          }
        })

      API.get("lead_offer_details/" + leads_id)
        .then((response) => {


          if (response.data.success) {
            this.setState({ buyer_data: response.data.lead })
          }
        })

      if (listing_for == "sale") {
        this.setState({ ismourequestOpen: true, mou_price: this.state.offers.price })
      }
    } else {
      this.setState({ ismourequestOpen: false })
    }
    // if (status == "Offer") {
    //   this.setState({ showPrice: true })
    // } else {
    //   this.setState({ showPrice: false })
    // }

    // if (status == "Second Viewing") {
    //   this.setState({ showDate: true })
    // } else {
    //   this.setState({ showDate: false })
    // }
  }

  // Handle offer Price
  handleOfferingPrice = (e) => {
    this.setState({ offers_price: e.target.value })
  }

  handleOfferingCommission = (e) => {
    this.setState({ offers_commission: e.target.value })
  }

  // Handle offer Note
  handleOfferingNote = (e) => {
    this.setState({ offers_note: e.target.value })
  }

  // Handle offer Date
  handleOfferingDate = (date, dateString) => {
    this.setState({ offers_date: dateString })
  };

  // Handle offer Delete, we will use it when need.
  // handleDeleteOffer = (offer_id,e) =>{
  //   const page = 1;
  //   const perPage = 10;
  //   const data = {
  //     date_range_status:this.state.date_range_status,
  //     date_range:this.state.date_range,
  //     community_filter:this.state.community_filter,
  //     sub_community_filter:this.state.sub_community_filter,
  //     property_filter:this.state.property_filter,
  //     refno_first_value_id:this.state.refno_first_value_id,
  //     refno_and_or_option:this.state.refno_and_or_option,
  //     refno_second_value_id:this.state.refno_second_value_id,
  //     refno_first_value:this.state.refno_first_value,
  //     refno_second_value:this.state.refno_second_value,
  //     unitno_first_value_id:this.state.unitno_first_value_id,
  //     unitno_and_or_option:this.state.unitno_and_or_option,
  //     unitno_second_value_id:this.state.unitno_second_value_id,
  //     unitno_first_value:this.state.unitno_first_value,
  //     unitno_second_value:this.state.unitno_second_value,
  //     status_filter:this.state.status_filter,
  //     agent_value_id: this.state.agent_value_id,
  //     agent_value: this.state.agent_value,
  //     client_value_id: this.state.client_value_id,
  //     client_value: this.state.client_value,
  // }
  //   if(offer_id == "" || offer_id < 1)
  //   return
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {

  //       API.delete("/delete_viewing/"+view_id)
  //       .then((response) =>{

  //           if(response.data.success){
  //               toast.success("Successfully deleted")
  //             this.fetchData(page,perPage,data)
  //           }

  //       })






  //     }
  //   })
  // }


  handleViewingFeedback = (e) => {

    this.setState({ viewing_feedback: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: e.target.value,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
    }

    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }


  handlePropertyFor = (e) => {

    this.setState({ property_for_filter: e })

    const page = 1;
    const perPage = 10;

    const data = {
      month: this.state.month,
      property_for_filter: e,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
    }

    this.fetchData(page, perPage, data);
    this.fetchPreviousData(page, perPage, data);

  }

  handleMonthTabs = (e, event) => {

    this.setState({ month: e })

    let newDate = new Date()
    let month = newDate.getMonth() + 1;

    const page = 1;
    const perPage = 10;

    const data = {
      month: e,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const monthData = { month: e }
    this.fetchTotal(monthData)

    if (e == month) {
      this.setState({ isNext: true, isPrevious: true, date_range_status: "date" })
      this.fetchData(page, perPage, data)
      this.fetchPreviousData(page, perPage, data)

    } else
      if (e > month) {
        this.setState({ isNext: true, isPrevious: true })
        this.fetchData(page, perPage, data)
        this.fetchPreviousData(page, perPage, data)

      } else
        if (e < month) {

          this.setState({ isNext: true, isPrevious: true })
          this.fetchData(page, perPage, data)
          this.fetchPreviousData(page, perPage, data)

        }


  }

  handleClearMonth = () => {
    this.setState({
      month: "",
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    })
    const page = 1;
    const perPage = 10;
    const data = {
      month: "",
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const monthData = { month: "" }
    this.fetchTotal(monthData)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);

  }

  handleClearFilter = () => {
    this.setState({
      agent_value: "",
      agent_value_id: 1,
      client_value: "",
      client_value_id: 1,
      community_filter: "",
      month: "",
      offer_status_filter: [],
      price_and_or_option: true,
      price_first_value: "",
      price_first_value_id: 1,
      price_max: 0,
      price_min: 0,
      price_second_value: "",
      price_second_value_id: 1,
      property_filter: "",
      property_for_filter: [],
      refno_and_or_option: true,
      refno_first_value: "",
      refno_first_value_id: 1,
      refno_second_value: "",
      refno_second_value_id: 1,
      status_filter: [],
      sub_community_filter: "",
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_first_value_id: 1,
      unitno_second_value: "",
      unitno_second_value_id: 1,
      vendor_agent_value: [],
      vendor_agent_value_id: 1,
      viewing_feedback: 0
    })
    const page = 1;
    const perPage = 10;
    const data = {
      agent_value: "",
      agent_value_id: 1,
      client_value: "",
      client_value_id: 1,
      community_filter: "",
      month: "",
      offer_status_filter: [],
      price_and_or_option: true,
      price_first_value: "",
      price_first_value_id: 1,
      price_max: 0,
      price_min: 0,
      price_second_value: "",
      price_second_value_id: 1,
      property_filter: "",
      property_for_filter: [],
      refno_and_or_option: true,
      refno_first_value: "",
      refno_first_value_id: 1,
      refno_second_value: "",
      refno_second_value_id: 1,
      status_filter: [],
      sub_community_filter: "",
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_first_value_id: 1,
      unitno_second_value: "",
      unitno_second_value_id: 1,
      vendor_agent_value: [],
      vendor_agent_value_id: 1,
      viewing_feedback: 0

    }

    const monthData = { month: "" }
    this.fetchTotal(monthData)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);

  }

  handleReferral = (e) => {
    const refrral_value = e.target.checked
    this.setState({ ReferralChecked: !this.state.ReferralChecked })
    if (refrral_value) {
      this.setState({ showReferralAgent: true })
    } else {
      this.setState({ showReferralAgent: false, selectedRefrralAgent: "", refrral_commission: "" })

    }
  }

  handleReferralAgent = (e) => {
    this.setState({ selectedRefrralAgent: e })
  }

  handleRefrralCommission = (e) => {
    this.setState({ refrral_commission: e.target.value })
  }

  handleSplitCommission = (e) => {
    this.setState({ split_commission: e.target.value })
  }

  // MOU REQUEST

  handlemourequests = (e) => {
    this.setState({ ismourequestOpen: true, mou_price: this.state.offers.price })
  }
  handlemourequestsok = (e) => {
    this.setState({ ismourequestOpen: false })
  }
  handlemourequestscancel = (e) => {
    this.setState({ ismourequestOpen: false })
  }

  handleMouPrice = (e) => {
    this.setState({ mou_price: e.target.value })
  }

  handleMouTransferDate = (value, dateString) => {
    this.setState({ mou_transfer_date: value == null ? "" : dateString })
  }

  handleMouAnnualAmount = (e) => {
    this.setState({ mou_annual_charge: e.target.value })
  }


  handleIdVacant = (e) => {
    const radio_option = e.target.value
    this.setState({ mou_is_vacant: radio_option == "yes" ? true : false })
  }

  handleMouExpireDate = (value, dateString) => {
    this.setState({ mou_tenancy_exp_date: value == null ? "" : dateString })

  }

  handleSellerTitleDeed = (e) => {
    let input = this.state.input;
    input["seller_title_deed"] = e.target.value;

    this.setState({ seller_title_deed: e.target.value, input })
  }

  handleBuyerTitleDeed = (e) => {
    let input = this.state.input;
    input["buyer_title_deed"] = e.target.value;
    this.setState({ buyer_title_deed: e.target.value, input })
  }


  // seller title file
  handleSellerTitleFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createSellerTitleImage(files[0]);
    this.setState({ seller_title_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createSellerTitleImage(file) {

    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        seller_title_file: e.target.result
      });
    };
    reader.readAsDataURL(file);
  }




  // seller passport file
  handleSellerPassport = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createSellerPassportImage(files[0]);
    this.setState({ seller_passport_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createSellerPassportImage(file) {
    let input = this.state.input;

    let reader = new FileReader();
    reader.onload = (e) => {
      input["seller_passport"] = e.target.result;
      this.setState({
        seller_passport: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }



  // seller emirate id file
  handleSellerEmirateID = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createSellerEmirateIdImage(files[0]);
    this.setState({ seller_emirate_id_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createSellerEmirateIdImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        seller_emirate_id: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }





  // seller visa file
  handleSellerVisa = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createSellerVisaImage(files[0]);
    this.setState({ seller_visa_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createSellerVisaImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        seller_visa: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  handleSellerCommission = (e) => {
    let input = this.state.input;
    input["seller_commission"] = e.target.value;
    this.setState({ seller_commission: e.target.value, input })
  }

  handleSellerConveyancingFee = (e) => {
    let input = this.state.input;
    input["seller_conveyancing_fee"] = e.target.value;
    this.setState({ seller_conveyancing_fee: e.target.value, input })
  }



  // buyer title file
  handleBuyerTitleFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createBuyerTitleImage(files[0]);
    this.setState({ buyer_title_file_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createBuyerTitleImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        buyer_title_file: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }


  // buyer passport file
  handleBuyerPassport = (e) => {


    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createBuyerPassportImage(files[0]);
    this.setState({ buyer_passport_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createBuyerPassportImage(file) {
    let input = this.state.input;
    let reader = new FileReader();
    reader.onload = (e) => {

      input["buyer_passport"] = e.target.result;
      this.setState({
        buyer_passport: e.target.result, input
      });
    };
    reader.readAsDataURL(file);
  }


  // buyer emirate id file
  handleBuyerEmirateID = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createBuyerEmirateIdImage(files[0]);
    this.setState({ buyer_emirate_id_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createBuyerEmirateIdImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        buyer_emirate_id: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }


  // buyer visa file
  handleBuyerVisa = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createBuyerVisaImage(files[0]);
    this.setState({ buyer_visa_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createBuyerVisaImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        buyer_visa: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }



  handleBuyerCommission = (e) => {
    let input = this.state.input;
    input["buyer_commission"] = e.target.value;
    this.setState({ buyer_commission: e.target.value, input })
  }

  handleBuyerConveyancingFee = (e) => {
    let input = this.state.input;
    input["buyer_conveyancing_fee"] = e.target.value;
    this.setState({ buyer_conveyancing_fee: e.target.value, input })
  }

  handleFurnished = (e) => {
    let input = this.state.input;
    input["furnished_unfurnished"] = e;
    this.setState({ furnished_unfurnished: e, input })
  }

  handleValuationVariance = (e) => {
    let input = this.state.input;
    input["valuation_variance"] = e;
    this.setState({ valuation_variance: e, input })
  }

  handleSpecialConditions = (e) => {
    this.setState({ special_conditions: e.target.value })
  }

  handleSubmit = () => {

    const data = {
      lp_number: this.state.offers.refno,
      listing_id: this.state.offers.listing_id,
      leads_id: this.state.offers.leads_id,
      mou_price: this.state.mou_price,
      mou_transfer_date: this.state.mou_transfer_date,
      mou_annual_charge: this.state.mou_annual_charge,
      mou_is_vacant: this.state.mou_is_vacant,
      mou_tenancy_exp_date: this.state.mou_tenancy_exp_date,
      //seller
      seller_title_deed: this.state.seller_title_deed,
      seller_title_file: this.state.seller_title_file,
      seller_passport: this.state.seller_passport,
      seller_emirate_id: this.state.seller_emirate_id,
      seller_visa: this.state.seller_visa,
      seller_commission: this.state.seller_commission,
      seller_conveyancing_fee: this.state.seller_conveyancing_fee,
      // buyer
      buyer_title_deed: this.state.buyer_title_deed,
      buyer_title_file: this.state.buyer_title_file,
      buyer_passport: this.state.buyer_passport,
      buyer_emirate_id: this.state.buyer_emirate_id,
      buyer_visa: this.state.buyer_visa,
      buyer_commission: this.state.buyer_commission,
      buyer_conveyancing_fee: this.state.buyer_conveyancing_fee,
      //other details
      furnished_unfurnished: this.state.furnished_unfurnished,
      valuation_variance: this.state.valuation_variance,
      special_conditions: this.state.special_conditions
    }


    if (this.validate()) {
      API.post("/mou", data)
        .then((response) => {
          if (response.data.success) {




            this.setState({ offerModal: false })
            toast.success("MOU Request created")
            this.setState({
              mou_price: "",
              mou_transfer_date: "",
              mou_annual_charge: "",
              mou_is_vacant: "",
              mou_tenancy_exp_date: "",
              //seller
              seller_title_deed: "",
              seller_title_file: "",
              seller_passport: "",
              seller_emirate_id: "",
              seller_visa: "",
              seller_commission: "",
              seller_conveyancing_fee: "",
              // buyer
              buyer_title_deed: "",
              buyer_title_file: "",
              buyer_passport: "",
              buyer_emirate_id: "",
              buyer_visa: "",
              buyer_commission: "",
              buyer_conveyancing_fee: "",
              //other details
              furnished_unfurnished: "",
              valuation_variance: "",
              special_conditions: "",
              ismourequestOpen: false
            })
            const email_data = {
              listing_id: response.data.listing_id,
              leads_id: response.data.leads_id, mou_request_id: response.data.mou_request_id
            }
            API.post("/send_mou_email", email_data)
              .then((res) => {
                console.log(res.data);
              })
          }
        })
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["seller_title_deed"]) {
      isValid = false;
      errors["seller_title_deed"] = "Please enter seller title .";
    }

    if (!input["buyer_title_deed"]) {
      isValid = false;
      errors["buyer_title_deed"] = "Please enter buyer title.";
    }
    if (!input["seller_passport"]) {
      isValid = false;
      errors["seller_passport"] = "Please upload seller passport.";
    }
    if (!input["buyer_passport"]) {
      isValid = false;
      errors["buyer_passport"] = "Please upload buyer passport.";
    }

    if (!input["seller_commission"]) {
      isValid = false;
      errors["seller_commission"] = "Seller commission required.";
    }

    if (!input["buyer_commission"]) {
      isValid = false;
      errors["buyer_commission"] = "Buyer commission required.";
    }

    if (!input["seller_conveyancing_fee"]) {
      isValid = false;
      errors["seller_conveyancing_fee"] = "Seller conveyancing fee required .";
    }

    if (!input["buyer_conveyancing_fee"]) {
      isValid = false;
      errors["buyer_conveyancing_fee"] = "Buyer conveyancing fee required .";
    }

    if (!input["furnished_unfurnished"]) {
      isValid = false;
      errors["furnished_unfurnished"] = "This is required .";
    }


    if (!input["valuation_variance"]) {
      isValid = false;
      errors["valuation_variance"] = "This is required .";
    }






    this.setState({
      errors: errors,
    });

    return isValid;
  }


  // Tenancy contract handle state
  handleAddress = (e) => {
    this.setState({ address: e.target.value })
  }

  handleDewa = (e) => {
    this.setState({ dewa: e.target.value })
  }
  handleLocation = (e) => {
    this.setState({ location: e.target.value })
  }

  handlePlotNumber = (e) => {
    this.setState({ plot: e.target.value })
  }
  handleBuildingName = (e) => {
    this.setState({ building_name: e.target.value })
  }
  handleUnitNumber = (e) => {
    this.setState({ property_number: e.target.value })
  }
  handlePropertyType = (e) => {
    this.setState({ property_type: e.target.value })
  }
  handleBedroom = (e) => {
    this.setState({ bedrooms: e.target.value })
  }

  handleAreaSize = (e) => {
    this.setState({ area_size: e.target.value })
  }

  handleLandLoardEmirateIDNumber = (e) => {
    this.setState({ landloard_emirate_id_number: e.target.value })
  }
  handleLandLoardName = (e) => {
    this.setState({ landloard_name: e.target.value })
  }

  handleLandLoardMobile = (e) => {
    this.setState({ landloard_mobile: e.target.value })
  }

  handleLandLoardEmail = (e) => {
    this.setState({ landloard_email: e.target.value })
  }
  handleLandLoardNationality = (e) => {
    this.setState({ landloard_nationality: e.target.value })
  }

  handleLMSLink = (e) => {
    this.setState({ lms_link: e.target.value })
  }
  handleTenantEmirateIDNumber = (e) => {
    this.setState({ tenant_emirate_id_number: e.target.value })
  }
  handleTenantName = (e) => {
    this.setState({ tenant_name: e.target.value })
  }
  handleTenantMobile = (e) => {
    this.setState({ tenant_mobile: e.target.value })
  }
  handleTenantEmail = (e) => {
    this.setState({ tenant_email: e.target.value })
  }
  handleStartDate = (value, dateString) => {
    this.setState({ contract_start_date: value != null ? dateString : "" })
  }

  handleEndDate = (value, dateString) => {
    this.setState({ contract_end_date: value != null ? dateString : "" })
  }

  handleRentalPrice = (e) => {
    this.setState({ rental_price: e.target.value })
  }

  handleSecurityDeposit = (e) => {
    this.setState({ security_deposit: e.target.value })
  }
  handleCommission = (e) => {
    this.setState({ contract_commission: e.target.value })
  }

  handleContractValue = (e) => {
    this.setState({ contract_value: e.target.value })
  }

  handlePaymentType = (e) => {
    this.setState({ payment_type: e.target.value })
  }

  handleNumberOfPayment = (e) => {
    this.setState({ month_number: e.target.value })
  }

  // handleAdditionalContract = (e) =>{
  //    this.setState({additional_clauses_contract:e.target.value})
  // }

  handleConditionTermsOne = (e) => {
    this.setState({ condition_terms_one: e.target.value })
  }

  handleConditionTermsTwo = (e) => {
    this.setState({ condition_terms_two: e.target.value })
  }
  handleConditionTermsThree = (e) => {
    this.setState({ condition_terms_three: e.target.value })
  }
  handleConditionTermsFour = (e) => {
    this.setState({ condition_terms_four: e.target.value })
  }
  handleConditionTermsFive = (e) => {
    this.setState({ condition_terms_five: e.target.value })
  }
  handleAdditionalAddendum = (e) => {
    this.setState({ additional_clauses_addendum: e.target.value })
  }
  // title deed
  handleTitleDeed = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTitleDeedImage(files[0]);
    this.setState({ title_deed_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTitleDeedImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        title_deed_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // landloard passport
  handleLandLoardPassportFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createLandloardPassportImage(files[0]);
    this.setState({ landloard_passport_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createLandloardPassportImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        landloard_passport_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // landloard emirate id
  handleLandLoardEmirateIDFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createLandloardEmirateIdImage(files[0]);
    this.setState({ landloard_emirate_id_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createLandloardEmirateIdImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        landloard_emirate_id_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // tenant passport
  handleTenantPassportFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTenantPassportImage(files[0]);
    this.setState({ tenant_passport_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTenantPassportImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        tenant_passport_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // tenant emirate id
  handleTenantEmirateIDFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTenantEmirateIDImage(files[0]);
    this.setState({ tenant_emirate_id_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTenantEmirateIDImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        tenant_emirate_id_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // tenant visa
  handleTenantVisa = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createTenantVisaImage(files[0]);
    this.setState({ tenant_visa_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createTenantVisaImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        tenant_visa_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  // cheque doc
  handleCheque = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createChequeImage(files[0]);
    this.setState({ cheque_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createChequeImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        cheque_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  // pow doc
  handlePOA = (e) => {

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createPOAImage(files[0]);
    this.setState({ poa_imageShow: URL.createObjectURL(e.target.files[0]) });

  }
  createPOAImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        poa_image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  handlecontractcancel = (e) => {
    this.setState({ iscontractOpen: false })
  }

  handleSubmitTenancyContract = () => {
    const data = {
      lp_number: this.state.offers.refno,
      listing_id: this.state.offers.listing_id,
      leads_id: this.state.offers.leads_id,
      address: this.state.address,
      dewa: this.state.dewa,
      location: this.state.location,
      plot: this.state.plot,
      property_number: this.state.property_number,
      property_type: this.state.property_type,
      building_name: this.state.building_name,
      bedrooms: this.state.bedrooms,
      area_size: this.state.area_size,
      commission: this.state.contract_commission,
      contract_value: this.state.contract_value,
      landloard_emirate_id_number: this.state.landloard_emirate_id_number,
      landloard_name: this.state.landloard_name,
      landloard_nationality: this.state.landloard_nationality,
      landloard_mobile: this.state.landloard_mobile,
      landloard_email: this.state.landloard_email,
      lms_link: this.state.lms_link,
      tenant_emirate_id_number: this.state.tenant_emirate_id_number,
      tenant_name: this.state.tenant_name,
      tenant_mobile: this.state.tenant_mobile,
      tenant_email: this.state.tenant_email,
      contract_start_date: this.state.contract_start_date,
      contract_end_date: this.state.contract_end_date,
      rental_price: this.state.rental_price,
      security_deposit: this.state.security_deposit,
      payment_type: this.state.payment_type,
      month_number: this.state.month_number,
      condition_terms_one: this.state.condition_terms_one,
      condition_terms_two: this.state.condition_terms_two,
      condition_terms_three: this.state.condition_terms_three,
      condition_terms_four: this.state.condition_terms_four,
      condition_terms_five: this.state.condition_terms_five,
      // additional_clauses_contract:this.state.additional_clauses_contract,
      additional_clauses_addendum: this.state.additional_clauses_addendum,
      title_deed_image: this.state.title_deed_image,

      landloard_passport_image: this.state.landloard_passport_image,

      landloard_emirate_id_image: this.state.landloard_emirate_id_image,

      tenant_passport_image: this.state.tenant_passport_image,

      tenant_emirate_id_image: this.state.tenant_emirate_id_image,

      tenant_visa_image: this.state.tenant_visa_image,

      poa_image: this.state.poa_image,

      cheque_image: this.state.cheque_image
    }

    API.post("/tenancy_contract", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ offerModal: false, iscontractOpen: false })
          toast.success("Tenancy contract created")
          this.setState({
            lp_number: "",
            listing_id: "",
            leads_id: "",
            address: "",
            dewa: "",
            location: "",
            plot: "",
            property_number: "",
            property_type: "",
            bedrooms: "",
            area_size: "",
            landloard_emirate_id_number: "",
            landloard_name: "",
            landloard_nationality: "",
            landloard_mobile: "",
            landloard_email: "",
            lms_link: "",
            tenant_emirate_id_number: "",
            tenant_name: "",
            tenant_mobile: "",
            tenant_email: "",
            contract_start_date: "",
            contract_end_date: "",
            rental_price: "",
            security_deposit: "",
            payment_type: "",
            month_number: "",
            condition_terms_one: "",
            condition_terms_two: "",
            condition_terms_three: "",
            condition_terms_four: "",
            condition_terms_five: "",
            // additional_clauses_contract:"",
            additional_clauses_addendum: "",
            title_deed_image: null,

            landloard_passport_image: null,

            landloard_emirate_id_image: null,

            tenant_passport_image: null,

            tenant_emirate_id_image: null,

            tenant_visa_image: null,

            poa_image: null,

            cheque_image: null
          })

          const email_data = {
            listing_id: response.data.listing_id,
            leads_id: response.data.leads_id, tenancy_contract_id: response.data.tenancy_contract_id
          }

          API.post("/send_tenancy_contract_email", email_data)
            .then((res) => {
              console.log(res.data);
            })
        }
      })
  }
  handleSaleScreenStatus = () => {
    this.setState({ sale_screen_status: !this.state.sale_screen_status })
  }

  render() {



    const offerStatus = (
      <Select
        mode={'multiple'}
        showSearch
        allowClear
        style={{
          width: '100%',
        }}
        value={this.state.offer_status_filter}
        placeholder="Search"
        onChange={this.handleOfferStatusFilter}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          {
            value: '1',
            label: 'Offer Sent',
          },
          {
            value: '2',
            label: 'Offer Accepted',
          },
          {
            value: '3',
            label: 'Offer Rejected',
          },
          {
            value: '4',
            label: 'Deal Signed',
          },
          {
            value: '5',
            label: 'MOU Request',
          }
        ]}
      />

    )

    const RefNoProperty = (
      <>
        <select value={this.state.refno_first_value_id} class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." value={this.state.refno_first_value} />
        </div>
        {this.state.refno_active_second_option ? (<div className='mt-2' >
          <center>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
              <label class="form-check-label" for="flexRadioDefault1">
                AND
              </label>
              <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
              <label class="form-check-label ml-4" for="flexRadioDefault1">
                OR
              </label>

            </div>
          </center>
          <select value={this.state.refno_second_value_id} class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
            <option value="1">Contain</option>
            <option value="2">Not contain</option>
            <option value="3">Equals</option>
            <option value="4">Not equal</option>
            <option value="5">Starts with</option>
            <option value="6">Ends with</option>
          </select>
          <div className='mt-2'>
            <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." value={this.state.refno_second_value} />
          </div>

        </div>) : null}</>
    )

    const clientName = (
      <>
        <select value={this.state.client_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleClientValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleClient} placeholder="filter..." value={this.state.client_value} />
        </div></>
    )

    const offerFeedback = (
      <>
        <select value={this.state.viewing_feedback} className='form-select' onChange={this.handleViewingFeedback}>
          <option value="0" selected>All</option>
          <option value="1">Note is empty</option>
          <option value="2">Note is not empty</option>
        </select>
      </>
    )

    const propertyFor = (
      <Select
        mode="multiple"
        maxTagCount='responsive'
        style={{
          width: '100%',
        }}
        value={this.state.property_for_filter}
        placeholder="Please select"
        onChange={this.handlePropertyFor}


        options={[
          {
            value: 'sale',
            label: 'sale',
          },
          {
            value: 'rent',
            label: 'rent',
          },
          {
            value: 'tenanted',
            label: 'tenanted',
          },
          {
            value: 'short-term',
            label: 'short-term',
          },
        ]}
      />
    )

    const vendorAgent = (
      <>
        <select value={this.state.vendor_agent_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleVendorAgentValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleVendorAgent} placeholder="filter..." value={this.state.vendor_agent_value} />
        </div></>
    )

    const unitNo = (
      <>
        <select value={this.state.unitno_first_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." value={this.state.unitno_first_value} />
        </div></>
    )

    const clientAgent = (
      <>
        <select value={this.state.agent_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleAgentValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleAgent} placeholder="filter..." value={this.state.agent_value} />
        </div>
      </>
    )

    const propertyPrice = (
      <>
        <select value={this.state.price_first_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
          <option value="1">Equals</option>
          <option value="2">Not equal</option>
          <option value="3">Less than</option>
          <option value="4">Less than or equals</option>
          <option value="5">Greater than</option>
          <option value="6">Greater than or equals</option>
          <option value="7">In range</option>
        </select>
        <div className='mt-2'>
          {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
            <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" value={this.state.price_min} />
            <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" value={this.state.price_max} />
          </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." value={this.state.price_first_value} />}
        </div>
        {this.state.price_active_second_option ? (<div className='mt-2' >
          <center>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
              <label class="form-check-label" for="pricefirst">
                AND
              </label>
              <input class="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
              <label class="form-check-label ml-4" for="pricesecond">
                OR
              </label>

            </div>
          </center>
          <select class="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
            <option value="1">Equals</option>
            <option value="2">Not equal</option>
            <option value="3">Less than</option>
            <option value="4">Less than or equals</option>
            <option value="5">Greater than</option>
            <option value="6">Greater than or equals</option>
            <option value="7">In range</option>
          </select>
          <div className='mt-2'>
            <input type="text" className="form-control" onChange={this.handlePriceSecond} placeholder="filter..." />
          </div>

        </div>) : null}</>
    )

    const propertyStatus = (
      <Select
        mode={'multiple'}
        showSearch
        allowClear
        style={{
          width: '100%',
        }}
        value={this.state.status_filter}
        placeholder="Search"
        onChange={this.handleStatusFilter}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          {
            value: 'Viewings',
            label: 'Viewings',
          },
          {
            value: 'Second Viewing',
            label: 'Second Viewing',
          },
          {
            value: 'Not Interested',
            label: 'Not Interested',
          },
          {
            value: 'Scheduled',
            label: 'Scheduled',
          },
          {
            value: 'Considering',
            label: 'Considering',
          },
        ]}
      />
    )
    const authentication = store.getState((state) => state);
    const user = authentication.auth.user
    const role = user.role
    const { offers_date } = this.state;

    const val1 = dayjs(offers_date ? offers_date : "");
    const deal_signed = this.state.deal_signed

    const accepted_offer = this.state.accepted_offer
    const rejected_offer = this.state.rejected_offer
    const sent_offer = this.state.sent_offer
    const commission = this.state.commission
    const total_listings = this.state.total_listings
    const offer_record = this.state.offers;

    const offer_record_price = offer_record.price

    const { split_agent } = this.state
    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      // setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      // selectedRowKeys,
      onChange: onSelectChange,

    };

    // Common function for sort Validator, by farooq
    const sortValidator = (a, b, aVar, bVar) => {
      if (a && aVar && aVar.length && b && bVar && bVar.length) {
        return aVar.length - bVar.length;
      } else if (a && aVar && aVar.length) {
        // That means be has null rechargeType, so a will come first.
        return -1;
      } else if (b && bVar && bVar.length) {
        // That means a has null rechargeType so b will come first.
        return 1;
      }

      // Both rechargeType has null value so there will be no order change.
      return 0;
    },

      columns = [

        {
          title: "Offer Status", fixed: 'left', width: 180,
          dataIndex: "offer_status",
          // sorter: (a, b) => a.status.length - b.status.length,
          sorter: (a, b) => sortValidator(a, b, a.offer_status, b.offer_status),
          render: (text, record) => {
            var pStatus = 'Offer'
            var color = 'offer'
            switch (record.offer_status) {
              case 0:
                pStatus = 'Pending'
                color = ''
                break;
              case 1:
                pStatus = 'Offer Sent'
                color = 'offer'
                break;
              case 2:
                pStatus = 'Offer Accepted'
                color = 'secondviewing';
                break;
              case 3:
                pStatus = 'Offer Rejected'
                color = 'scheduled';
                break;
              case 4:
                pStatus = 'Deal Signed'
                color = 'deal';
                break;
              case 5:
                pStatus = 'MOU Request'
                color = 'deal';
                break;
              default:
                pStatus = 'Pending'
                color = '';
                break;
            }
            return (
              <div className={color + " pstat"} key={pStatus}>
                {pStatus}
              </div>
            );
          },
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Status
              {offerStatus}

            </div>
          ),

        },
        {
          title: 'Property', fixed: 'left', width: 270,
          dataIndex: 'refno',

          // sorter: (a, b) => a.refno.length - b.refno.length,
          sorter: (a, b) => sortValidator(a, b, a.status.replace("LP", ""), b.status.replace("LP", "")),

          render: (text, record) => (
            <Link onClick={this.handleViewRefNo.bind(this, record)} className="d-flex flex-column">
              <span className="propref font-bold fs-6">{record.refno}</span>
              <span className="propinfo">
                {record.property_title == null ? "" : record.property_title + ", "}
                {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                {record.community_title == null ? "" : record.community_title}
              </span>
            </Link>
          ),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Ref no

              {RefNoProperty}


            </div>
          ),
        },
        {
          title: "Client", fixed: 'left', className: 'clientwrap',
          dataIndex: 'client_name', width: 150,
          render: (text, record) => (
            <>
              {record.leads_id ? <a href={liveUrl + "/leads/" + record.leads_id} target="_blank">{text}</a> : text}
            </>
          ),
          // sorter: (a, b) => a.client_name.length - b.client_name.length,
          sorter: (a, b) => sortValidator(a, b, a.client_name, b.client_name),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Client name
              {clientName}

            </div>
          ),
        },

        {
          title: "Offer Feedback", width: 500,
          dataIndex: 'note',
          className: "noteswrap",
          // sorter: (a, b) => a.note.length - b.note.length,
          sorter: (a, b) => sortValidator(a, b, a.note, b.note),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Offer feedback
              {offerFeedback}




            </div>
          ),

        },
        {
          title: "Date", width: 180,
          dataIndex: "view_date",
          sorter: (a, b) => a.view_date.length - b.view_date.length,
          render: (text, record) => {
            return <>
              {
                record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                  ''
                  :
                  <>
                    <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                    {/*<span className="px-2">|</span>*/}
                    {/*<Moment format="hh:mm A">{record.view_date}</Moment>*/}
                  </>
              }
            </>
          },
        },
        {
          title: 'For', width: 80,
          dataIndex: 'property_for',
          sorter: (a, b) => {
            if (a && a.property_for && a.property_for.length && b && b.property_for && b.property_for.length) {
              return a.property_for.length - b.property_for.length;
            } else if (a && a.property_for && a.property_for.length) {
              return -1;
            } else if (b && b.property_for && b.property_for.length) {
              return 1;
            }
            return 0;
          },
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Property for
              {propertyFor}



            </div>
          ),

        },


        {
          title: "Vendor Agent", width: 180,
          dataIndex: 'vendor_agent',
          sorter: (a, b) => sortValidator(a, b, a.vendor_agent, b.vendor_agent),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Agent name
              {vendorAgent}

            </div>
          ),
        },

        {
          title: "Unit no", width: 150,
          dataIndex: "unitno",
          // sorter: (a, b) => a.unitno.length - b.unitno.length,
          sorter: (a, b) => sortValidator(a, b, a.unitno, b.unitno),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Unit no
              {unitNo}

            </div>
          ),
        },


        {
          title: "Client Agent", width: 180,
          dataIndex: 'fullname',
          // sorter: (a, b) => a.fullname.length - b.fullname.length,
          sorter: (a, b) => sortValidator(a, b, a.fullname, b.fullname),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Agent name
              {clientAgent}

            </div>
          ),
        },
        {
          title: "Price",
          dataIndex: 'price', width: 180,
          render: (text, record) => {
            if (!isNaN(parseInt(text))) {
              return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
            } else {
              return null;
            }
          },
          // sorter: (a, b) => {
          //   if(a && a.price && a.price.length && b && b.price && b.price.length) {
          //     return a.price.length - b.price.length;
          // } else if(a && a.price && a.price.length) {
          //     // That means be has null rechargeType, so a will come first. 
          //     return -1;
          // } else if(b && b.price && b.price.length) {
          //     // That means a has null rechargeType so b will come first.
          //     return 1;
          // }

          // // Both rechargeType has null value so there will be no order change.
          // return 0;
          // },
          sorter: (a, b) => sortValidator(a, b, a.price, b.price),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Price
              {propertyPrice}



            </div>
          ),
        },
        {
          title: "Commission",
          dataIndex: 'comission', width: 180,
          render: (text, record) => {
            if (!isNaN(parseInt(text))) {
              return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
            } else {
              return null;
            }
          }
        },
        {
          title: "Status",
          dataIndex: "status", width: 130,
          // sorter: (a, b) => a.status.length - b.status.length,
          sorter: (a, b) => sortValidator(a, b, a.status, b.status),
          render: (text, record) => {
            var pStatus = 'Offer'
            var color = 'offer'
            switch (record.status) {
              case 'Offer':
                pStatus = 'Offer'
                color = 'offer'
                break;
              case 'Second Viewing':
                pStatus = 'Second Viewing'
                color = 'secondviewing';
                break;
              case 'Not Interested':
                pStatus = 'Not Interested'
                color = 'notinterested';
                break;
              case 'Scheduled':
                pStatus = 'Scheduled'
                color = 'scheduled';
                break;
              case 'Considering':
                pStatus = 'Considering'
                color = 'considering';
                break;


              default:
                pStatus = 'Offer'
                color = 'offer';
                break;
            }
            return (
              <div className={color + " pstat"} key={pStatus}>
                {pStatus}
              </div>
            );
          },
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Status
              {propertyStatus}


            </div>
          ),

        },
        {
          title: 'Action', width: 80,
          render: (text, record) => (
            <div className="ms-auto">
              <a className="btn btn-link btn-sm m-2" onClick={this.handleDeleteOffer.bind(this, record.views_id)} style={{ fontSize: '16px' }}><i className="fa fa-trash" style={{ fontSize: "14px" }}></i></a>
            </div>
          ),
        },


      ]


    const columnsResponsiveThis = [
      {
        title: "Status", key: "status",
        render: (text, record) => {
          var pStatus = 'Offer'
          var color = 'offer'
          switch (record.offer_status) {
            case 0:
              pStatus = 'Pending'
              color = ''
              break;
            case 1:
              pStatus = 'Offer Sent'
              color = 'offer'
              break;
            case 2:
              pStatus = 'Offer Accepted'
              color = 'secondviewing';
              break;
            case 3:
              pStatus = 'Offer Rejected'
              color = 'scheduled';
              break;
            case 4:
              pStatus = 'Deal Signed'
              color = 'deal';
              break;
            case 5:
              pStatus = 'MOU Request'
              color = 'deal';
              break;
            default:
              pStatus = 'Pending'
              color = '';
              break;
          }


          var pStatus1 = 'Offer'
          var color1 = 'offer'
          switch (record.status) {
            case 'Offer':
              pStatus1 = 'Offer'
              color1 = 'offer'
              break;
            case 'Second Viewing':
              pStatus1 = 'Second Viewing'
              color1 = 'secondviewing';
              break;
            case 'Not Interested':
              pStatus1 = 'Not Interested'
              color1 = 'notinterested';
              break;
            case 'Scheduled':
              pStatus1 = 'Scheduled'
              color1 = 'scheduled';
              break;
            case 'Considering':
              pStatus1 = 'Considering'
              color1 = 'considering';
              break;


            default:
              pStatus1 = 'Offer'
              color1 = 'offer';
              break;
          }

          return (<>
            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                    <div className={color + " pstat"} key={pStatus}>
                      {pStatus}
                    </div>
                  </div>
                </div>
                {role == 1 ?  <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    <div className="ms-auto">
                      <a className="btn btn-link btn-sm m-2" onClick={this.handleDeleteOffer.bind(this, record.views_id)} style={{ fontSize: '16px' }}><i className="fa fa-trash" style={{ fontSize: "14px" }}></i></a>
                    </div>
                  </div>
                </div> : '' }
               
              </div>
              <Link onClick={this.handleViewRefNo.bind(this, record)} className="d-flex flex-column">
                <div className='row alignCenter borderGray pt-2 pb-2'>

                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>
                      <span className="propref font-bold fs-6">{record.refno}</span>
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 flex-align-center BodyFirstRowRight'>
                        <p className='info-title'>Status:</p>
                        <div className={color1 + " pstat"} key={pStatus1}>
                          {pStatus1}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>


                      <span className="propinfo">
                        {record.property_title == null ? "" : record.property_title + ", "}
                        {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                        {record.community_title == null ? "" : record.community_title}
                      </span>

                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Unit No:</p>
                        <p>{record.unitno ? record.unitno : null}</p>
                      </div>

                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Date:</p>
                        <p>
                          {
                            record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                              ''
                              :
                              <>
                                <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                                {/*<span className="px-2">|</span>*/}
                                {/*<Moment format="hh:mm A">{record.view_date}</Moment>*/}
                              </>
                          }

                        </p>
                      </div>
                    </div>

                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Price:</p>
                        <p>
                          {!isNaN(parseInt(text.price)) ? text.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : null}
                        </p>

                      </div>


                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Commission:</p>
                        <p>{!isNaN(parseInt(text.comission)) ? text.comission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : null}</p>
                      </div>
                    </div>

                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Client:</p>
                        <p>{record.client_name ? record.client_name : 'New Client'}</p>
                      </div>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Client Agent:</p>
                        <p>{record.fullname ? record.fullname : null}</p>
                      </div>

                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>For:</p>
                        <p>{record.property_for ? record.property_for : null}</p>
                      </div>

                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Vendor Agent:</p>
                        <p>{record.vendor_agent ? record.vendor_agent : null}</p>
                      </div>
                    </div>

                  </div>

                  <div className='col-12'>
                    <div className='BodyFirstRowRight'>
                      <p className='info-title'>Viewing Feedback:</p>
                      <p>{record.note ? record.note : '--'}</p>
                    </div>
                  </div>
                </div>

              </Link>

            </div>
          </>)
        }
      }
    ]

    const columns1 = [

      {
        title: "Offer Status", fixed: 'left', width: 180,
        dataIndex: "offer_status",
        // sorter: (a, b) => a.status.length - b.status.length,
        sorter: (a, b) => sortValidator(a, b, a.offer_status, b.offer_status),
        render: (text, record) => {
          var pStatus = 'Offer'
          var color = 'offer'
          switch (record.offer_status) {
            case 0:
              pStatus = 'Pending'
              color = ''
              break;
            case 1:
              pStatus = 'Offer Sent'
              color = 'offer'
              break;
            case 2:
              pStatus = 'Offer Accepted'
              color = 'secondviewing';
              break;
            case 3:
              pStatus = 'Offer Rejected'
              color = 'scheduled';
              break;
            case 4:
              pStatus = 'Deal Signed'
              color = 'deal';
              break;
            case 5:
              pStatus = 'MOU Request'
              color = 'deal';
              break;
            default:
              pStatus = 'Pending'
              color = '';
              break;
          }
          return (
            <div className={color + " pstat"} key={pStatus}>
              {pStatus}
            </div>
          );
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Status
            <Select
              mode={'multiple'}
              showSearch
              allowClear
              style={{
                width: '100%',
              }}
              value={this.state.offer_status_filter}
              placeholder="Search"
              onChange={this.handleOfferStatusFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  value: '1',
                  label: 'Offer Sent',
                },
                {
                  value: '2',
                  label: 'Offer Accepted',
                },
                {
                  value: '3',
                  label: 'Offer Rejected',
                },
                {
                  value: '4',
                  label: 'Deal Signed',
                },
                {
                  value: '5',
                  label: 'MOU Request',
                }
              ]}
            />


          </div>
        ),

      },
      {
        title: 'Property', fixed: 'left', width: 270,
        dataIndex: 'refno',

        // sorter: (a, b) => a.refno.length - b.refno.length,
        sorter: (a, b) => sortValidator(a, b, a.status.replace("LP", ""), b.status.replace("LP", "")),

        render: (text, record) => (
          <Link onClick={this.handleViewRefNo.bind(this, record)} className="d-flex flex-column">
            <span className="propref">{record.refno}</span>
            <span className="propinfo">
              {record.property_title == null ? "" : record.property_title + ", "}
              {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
              {record.community_title == null ? "" : record.community_title}
            </span>
          </Link>
        ),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Ref no
            <select value={this.state.refno_first_value_id} class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." value={this.state.refno_first_value} />
            </div>
            {this.state.refno_active_second_option ? (<div className='mt-2' >
              <center>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                  <label class="form-check-label" for="flexRadioDefault1">
                    AND
                  </label>
                  <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                  <label class="form-check-label ml-4" for="flexRadioDefault1">
                    OR
                  </label>

                </div>
              </center>
              <select value={this.state.refno_second_value_id} class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
                <option value="1">Contain</option>
                <option value="2">Not contain</option>
                <option value="3">Equals</option>
                <option value="4">Not equal</option>
                <option value="5">Starts with</option>
                <option value="6">Ends with</option>
              </select>
              <div className='mt-2'>
                <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." value={this.state.refno_second_value} />
              </div>

            </div>) : null}



          </div>
        ),
      },
      {
        title: "Client", fixed: 'left', className: 'clientwrap',
        dataIndex: 'client_name', width: 150,
        render: (text, record) => (
          <>
            {record.leads_id ? <a href={liveUrl + "/leads/" + record.leads_id} target="_blank">{text}</a> : text}
          </>
        ),
        // sorter: (a, b) => a.client_name.length - b.client_name.length,
        sorter: (a, b) => sortValidator(a, b, a.client_name, b.client_name),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Client name
            <select value={this.state.client_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleClientValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleClient} placeholder="filter..." value={this.state.client_value} />
            </div>

          </div>
        ),
      },

      {
        title: "Offer Feedback", width: 500,
        dataIndex: 'note',
        className: "noteswrap",
        // sorter: (a, b) => a.note.length - b.note.length,
        sorter: (a, b) => sortValidator(a, b, a.note, b.note),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Offer feedback
            <select value={this.state.viewing_feedback} className='form-select' onChange={this.handleViewingFeedback}>
              <option value="0" selected>All</option>
              <option value="1">Note is empty</option>
              <option value="2">Note is not empty</option>
            </select>



          </div>
        ),

      },
      {
        title: "Date", width: 180,
        dataIndex: "view_date",
        sorter: (a, b) => a.view_date.length - b.view_date.length,
        render: (text, record) => {
          return <>
            {
              record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                ''
                :
                <>
                  <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                  {/*<span className="px-2">|</span>*/}
                  {/*<Moment format="hh:mm A">{record.view_date}</Moment>*/}
                </>
            }
          </>
        },
      },
      {
        title: 'For', width: 80,
        dataIndex: 'property_for',
        sorter: (a, b) => {
          if (a && a.property_for && a.property_for.length && b && b.property_for && b.property_for.length) {
            return a.property_for.length - b.property_for.length;
          } else if (a && a.property_for && a.property_for.length) {
            return -1;
          } else if (b && b.property_for && b.property_for.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Property for
            <Select
              mode="multiple"
              maxTagCount='responsive'
              style={{
                width: '100%',
              }}
              value={this.state.property_for_filter}
              placeholder="Please select"
              onChange={this.handlePropertyFor}


              options={[
                {
                  value: 'sale',
                  label: 'sale',
                },
                {
                  value: 'rent',
                  label: 'rent',
                },
                {
                  value: 'tenanted',
                  label: 'tenanted',
                },
                {
                  value: 'short-term',
                  label: 'short-term',
                },
              ]}
            />


          </div>
        ),

      },


      {
        title: "Vendor Agent", width: 180,
        dataIndex: 'vendor_agent',
        sorter: (a, b) => sortValidator(a, b, a.vendor_agent, b.vendor_agent),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Agent name
            <select value={this.state.vendor_agent_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleVendorAgentValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleVendorAgent} placeholder="filter..." value={this.state.vendor_agent_value} />
            </div>

          </div>
        ),
      },

      {
        title: "Unit no", width: 150,
        dataIndex: "unitno",
        // sorter: (a, b) => a.unitno.length - b.unitno.length,
        sorter: (a, b) => sortValidator(a, b, a.unitno, b.unitno),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Unit no
            <select value={this.state.unitno_first_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." value={this.state.unitno_first_value} />
            </div>

          </div>
        ),
      },


      {
        title: "Client Agent", width: 180,
        dataIndex: 'fullname',
        // sorter: (a, b) => a.fullname.length - b.fullname.length,
        sorter: (a, b) => sortValidator(a, b, a.fullname, b.fullname),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Agent name
            <select value={this.state.agent_value_id} class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleAgentValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" className="form-control" onChange={this.handleAgent} placeholder="filter..." value={this.state.agent_value} />
            </div>

          </div>
        ),
      },
      {
        title: "Price",
        dataIndex: 'price', width: 180,
        render: (text, record) => {
          if (!isNaN(parseInt(text))) {
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          } else {
            return null;
          }
        },
        // sorter: (a, b) => {
        //   if(a && a.price && a.price.length && b && b.price && b.price.length) {
        //     return a.price.length - b.price.length;
        // } else if(a && a.price && a.price.length) {
        //     // That means be has null rechargeType, so a will come first. 
        //     return -1;
        // } else if(b && b.price && b.price.length) {
        //     // That means a has null rechargeType so b will come first.
        //     return 1;
        // }

        // // Both rechargeType has null value so there will be no order change.
        // return 0;
        // },
        sorter: (a, b) => sortValidator(a, b, a.price, b.price),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Price
            <select value={this.state.price_first_value_id} class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select>
            <div className='mt-2'>
              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" value={this.state.price_min} />
                <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" value={this.state.price_max} />
              </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." value={this.state.price_first_value} />}
            </div>
            {this.state.price_active_second_option ? (<div className='mt-2' >
              <center>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                  <label class="form-check-label" for="pricefirst">
                    AND
                  </label>
                  <input class="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                  <label class="form-check-label ml-4" for="pricesecond">
                    OR
                  </label>

                </div>
              </center>
              <select class="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                <input type="text" className="form-control" onChange={this.handlePriceSecond} placeholder="filter..." />
              </div>

            </div>) : null}



          </div>
        ),
      },
      {
        title: "Commission",
        dataIndex: 'comission', width: 180,
        render: (text, record) => {
          if (!isNaN(parseInt(text))) {
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          } else {
            return null;
          }
        }
      },
      {
        title: "Status",
        dataIndex: "status", width: 130,
        // sorter: (a, b) => a.status.length - b.status.length,
        sorter: (a, b) => sortValidator(a, b, a.status, b.status),
        render: (text, record) => {
          var pStatus = 'Offer'
          var color = 'offer'
          switch (record.status) {
            case 'Offer':
              pStatus = 'Offer'
              color = 'offer'
              break;
            case 'Second Viewing':
              pStatus = 'Second Viewing'
              color = 'secondviewing';
              break;
            case 'Not Interested':
              pStatus = 'Not Interested'
              color = 'notinterested';
              break;
            case 'Scheduled':
              pStatus = 'Scheduled'
              color = 'scheduled';
              break;
            case 'Considering':
              pStatus = 'Considering'
              color = 'considering';
              break;


            default:
              pStatus = 'Offer'
              color = 'offer';
              break;
          }
          return (
            <div className={color + " pstat"} key={pStatus}>
              {pStatus}
            </div>
          );
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Status
            <Select
              mode={'multiple'}
              showSearch
              allowClear
              style={{
                width: '100%',
              }}
              value={this.state.status_filter}
              placeholder="Search"
              onChange={this.handleStatusFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'Viewings',
                  label: 'Viewings',
                },
                {
                  value: 'Second Viewing',
                  label: 'Second Viewing',
                },
                {
                  value: 'Not Interested',
                  label: 'Not Interested',
                },
                {
                  value: 'Scheduled',
                  label: 'Scheduled',
                },
                {
                  value: 'Considering',
                  label: 'Considering',
                },
              ]}
            />


          </div>
        ),

      },


    ]



    const handleTableChange = (pagination, filters) => {


      const data = {
        month: this.state.month,
        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,
        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        offer_status_filter: this.state.offer_status_filter,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,
        vendor_agent_value_id: this.state.vendor_agent_value_id,
        vendor_agent_value: this.state.vendor_agent_value,
        price_first_value_id: this.state.price_first_value_id,
        price_and_or_option: this.state.price_and_or_option,
        price_second_value_id: this.state.price_second_value_id,
        price_first_value: this.state.price_first_value,
        price_second_value: this.state.price_second_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,
        // by Farooq
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,
      }

      this.fetchData(pagination.current, pagination.pageSize, data);


    };
    const handlePreviousTableChange = (pagination, filters) => {

      const data = {
        month: this.state.month,
        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,
        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        offer_status_filter: this.state.offer_status_filter,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,
        vendor_agent_value_id: this.state.vendor_agent_value_id,
        vendor_agent_value: this.state.vendor_agent_value,
        price_first_value_id: this.state.price_first_value_id,
        price_and_or_option: this.state.price_and_or_option,
        price_second_value_id: this.state.price_second_value_id,
        price_first_value: this.state.price_first_value,
        price_second_value: this.state.price_second_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,
        // by Farooq
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,

      }

      this.fetchPreviousData(pagination.current, pagination.pageSize, data); // for push

    };

    // MOU REQUEST
    const sellers = [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Mobile', dataIndex: 'mobile' },
      { title: 'Email', dataIndex: 'email' },
      { title: 'Agent', dataIndex: 'agent' },
    ]

    const sellersdata = [
      { name: 'Rohit Batra', mobile: '0505588463', email: '', agent: 'Claire Towle' },
      { name: 'Asha', mobile: '447739408250', email: '', agent: 'George Hughes' },
    ]



    const columns3 = [
      {
        title: 'Property', dataIndex: 'property', fixed: 'left', width: 200, className: "propertywrap",
        render: (text, record) => (
          <>
            <p className='font-bold mb-0'>LP20467</p>
            <p className='font-12'>281 Sun, Arabian Ranches III</p>
          </>
        )
      },
      { title: 'Status', dataIndex: 'moustatus' },
      { title: 'Sales Price', dataIndex: 'salesprice' },
      { title: 'Annual Service Charges Amount', dataIndex: 'annualamount', width: 100 },
      { title: 'Is The Property Vacant?', dataIndex: 'propertyvacant' },
      { title: 'Tenancy Expiry Date', dataIndex: 'tenancyexpirydate' },
      { title: 'Proposed Transfer Date', dataIndex: 'proposedtransferdate' },
      {
        title: 'Seller', dataIndex: 'seller',
        render: (text, record) => (
          <>
            <p className='mb-0'>ZAYN</p>
            <p className='font-12 mb-0'>+97123456789 | email@email.com</p>
            <p className='font-12 mb-0'>Tyrone Burrows</p>
          </>
        )
      },
      { title: 'Seller Documents', dataIndex: 'sellerdocuments' },
      { title: 'Seller Commission', dataIndex: 'sellercommission' },
      { title: 'Seller Conveyancing Fee', dataIndex: 'sellerconveyancingfee' },
      {
        title: 'Buyer', dataIndex: 'buyer',
        render: (text, record) => (
          <>
            <div className=''>
              <p className='mb-0'>ZAYN</p>
              <p className='font-12 mb-0'>+97123456789 | email@email.com</p>
              <p className='font-12 mb-0'>Tyrone Burrows</p>
            </div>
            <div className=''>
              <p className='mb-0'>Nicola</p>
              <p className='font-12 mb-0'>+97123456789 | email@email.com</p>
              <p className='font-12 mb-0'>Kristina Skalicka</p>
            </div>
          </>
        )
      },
      { title: 'Buyer Commission', dataIndex: 'buyercommission' },
      { title: 'Buyer Conveyancing Fee', dataIndex: 'buyerconveyancingfee' },
      { title: 'Buyer Documents', dataIndex: 'buyerdocuments' },
      { title: 'Furnished/Unfurnished', dataIndex: 'furnished' },
      { title: 'Valuation Variance', dataIndex: 'valuationvariance' },
      { title: 'Special Conditions', dataIndex: 'specialconditions', width: 300 },
      { title: 'Created Date', dataIndex: 'createddate' },
      { title: 'Action', dataIndex: 'action' }
    ]
    // const data = [
    //     {property:'LP20598', annualamount:'123456789', propertyvacant: 'Yes', tenancyexpirydate:'',salesprice:'	1800000', proposedtransferdate:'', seller:[{sellername: 'Selma', sellermobile:'0123456789', selleremail:'email@gmail.com', selleragent: 'Alex Eady'}], documents:"", sellercommission:'', sellerconveyancingfee:'',buyer:'', buyercommission:'', buyerconveyancingfee:'', }
    // ]
    const data = [
      { key: 1, property: 'LP20598', moustatus: 'Pending', annualamount: '123,456,789 AED', tenancyexpirydate: 'May 1, 2023', salesprice: '1,234,567 AED', seller: '', buyer: '', valuationvariance: '5%', specialconditions: '', createddate: 'April 26, 2023 | 03:22 PM', action: '' },
      { key: 2, property: 'LP20599', moustatus: 'Pending', annualamount: '123,456,789 AED', tenancyexpirydate: 'May 1, 2023', salesprice: '1,234,567 AED', seller: '', buyer: '', valuationvariance: '5%', specialconditions: '', createddate: 'April 26, 2023 | 03:22 PM', action: '' },
      { key: 3, property: 'LP20598', moustatus: 'Sent', annualamount: '123,456,789 AED', tenancyexpirydate: 'May 1, 2023', salesprice: '1,234,567 AED', seller: '', buyer: '', valuationvariance: '5%', specialconditions: '', createddate: 'April 26, 2023 | 03:22 PM', action: '' },
      { key: 4, property: 'LP20599', moustatus: 'Sent', annualamount: '123,456,789 AED', tenancyexpirydate: 'May 1, 2023', salesprice: '1,234,567 AED', seller: '', buyer: '', valuationvariance: '5%', specialconditions: '', createddate: 'April 26, 2023 | 03:22 PM', action: '' },
    ]



    return (
      <div>
        <ToastContainer />



        <Drawer width={'95vw'} title="Tenancy Contract Request" placement="left" onClose={this.handlecontractcancel} open={this.state.iscontractOpen}>
          <div className="requestwrap">

            <Row span={24} gutter={24}>
              <Col span={12}>
                <Row className='border-top border' gutter={16}>
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Property Details</p>
                  </Col>
                  <Col span={24} className='bg-gray'>
                    <div className='form-group w-50 pr-1'>
                      <label>Search property</label>
                      <input placeholder='Enter Ref no' />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Address <em>*</em></label>
                      {/* unit no, property, sub community, community */}
                      <input value={this.state.address} placeholder='Enter address' onChange={this.handleAddress} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>DEWA/Premise Number</label>
                      <input placeholder='Enter DEWA number' onChange={this.handleDewa} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Location (as per Title Deed) <em>*</em></label>
                      <input placeholder='Enter location' onChange={this.handleLocation} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Plot Number (as per Title Deed)  <em>*</em></label>
                      <input placeholder='Enter number' onChange={this.handlePlotNumber} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Building Name  <em>*</em></label>
                      <input value={this.state.building_name} placeholder='Enter number' onChange={this.handleBuildingName} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Unit Number <em>*</em></label>
                      <input value={this.state.property_number} placeholder='Enter number' onChange={this.handleUnitNumber} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Property Type <em>*</em></label>
                      {/* pull up from database but editable*/}
                      <select value={this.state.property_category_id} onChange={this.handlePropertyType} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                        <option>select</option>
                        {this.state.propertytypes.map((propertytype) => {
                          return <option value={propertytype.value}>{propertytype.label}</option>
                        })}


                      </select>
                      {/* <input placeholder='Enter type'  onChange={this.handlePropertyType} />  */}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>No of Bedrooms <em>*</em></label>
                      {/* pull up from database but editable*/}
                      <input value={this.state.bedrooms} placeholder='Enter number' onChange={this.handleBedroom} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Size in sqm <em>*</em></label>
                      <input value={this.state.area_size} placeholder='Enter size' onChange={this.handleAreaSize} />
                    </div>
                  </Col>
                </Row>
                <Row className='border border-top mt-3' gutter={16}>
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Contract Details</p>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Start date <em>*</em></label>
                      <DatePicker format="YYYY-MM-DD" onChange={this.handleStartDate} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>End date <em>*</em></label>
                      <DatePicker format="YYYY-MM-DD" onChange={this.handleEndDate} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Rental Price <em>*</em></label>
                      <input placeholder='Enter price' onChange={this.handleRentalPrice} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Contract Value</label>
                      <input placeholder='Enter amount' onChange={this.handleContractValue} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Security Deposit Amount <em>*</em></label>
                      <input placeholder='Enter amount' onChange={this.handleSecurityDeposit} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Commission</label>
                      <input value={this.state.contract_commission} placeholder='Enter amount' onChange={this.handleCommission} />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className='form-group'>
                      <label>Mode of Payment <em>*</em></label>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Radio.Group className='d-flex flex-row' onChange={this.handlePaymentType}>
                            <Radio value={1}>Cheque</Radio>
                            <Radio value={2}>Bank Transfer</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={12}>
                          <Radio.Group className='d-flex flex-row align-self-end' onChange={this.handleNumberOfPayment}>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                            <Radio value={6}>6</Radio>
                            <Radio value={12}>12</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Additional clauses to the contract<em>*</em></label>
                      {/* <textarea rows={4} maxLength={6} onChange={this.handleAdditionalContract}   /> */}
                      <Input showCount className='form-input mb-1' onChange={this.handleConditionTermsOne}
                        value={"Tenant agrees to pay Luxury Property LLC " + (this.state.offers_commission ? this.state.offers_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null) + " AED + VAT (" + parseInt((this.state.offers_commission * 0.05)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED) upon signing this agreement"} />
                      <Input showCount className='form-input mb-1' onChange={this.handleConditionTermsTwo} value={"The attached addendum forms an integral part of this contract"} />
                      <Input showCount className='form-input mb-1' onChange={this.handleConditionTermsThree} />
                      <Input showCount className='form-input mb-1' onChange={this.handleConditionTermsFour} />
                      <Input showCount className='form-input' onChange={this.handleConditionTermsFive} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Addditional clauses to the addendum<em>*</em></label>
                      <textarea rows={4} maxLength={6} onChange={this.handleAdditionalAddendum} className='border' />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row className='border border-top' gutter={16}>
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Landlord Details</p>
                  </Col>
                  <Col span={24} className='bg-gray'>
                    <div className='form-group w-50 pr-1'>
                      <label>Search owner</label>
                      <input placeholder='Search owner by name' />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Emirates ID/Passport Number <em>*</em></label>
                      <input placeholder='Enter number' onChange={this.handleLandLoardEmirateIDNumber} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Name (as per passport) <em>*</em></label>
                      <input value={this.state.landloard_name} placeholder='Enter name' onChange={this.handleLandLoardName} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Nationanality <em>*</em></label>
                      <input placeholder='Enter nationality' onChange={this.handleLandLoardNationality} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Mobile number <em>*</em></label>
                      <input value={this.state.landloard_mobile} placeholder='Enter number' onChange={this.handleLandLoardMobile} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Email address <em>*</em></label>
                      <input value={this.state.landloard_email} placeholder='Enter email adress' onChange={this.handleLandLoardEmail} />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16} className="border-top border mt-3">
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Tenant Details</p>
                  </Col>
                  <Col span={24} className='bg-gray'>
                    <div className='form-group w-50 pr-1'>
                      <label>Search Tenant</label>
                      <input placeholder='Search tenant by name' />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Emirates ID/Passport Number <em>*</em></label>
                      <input placeholder='Enter number' onChange={this.handleTenantEmirateIDNumber} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Name (as per passport) <em>*</em></label>
                      <input value={this.state.tenant_name} placeholder='Enter name' onChange={this.handleTenantName} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Mobile Number <em>*</em></label>
                      <input value={this.state.tenant_mobile} placeholder='Enter mobile number' onChange={this.handleTenantMobile} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='form-group'>
                      <label>Email Address <em>*</em></label>
                      <input value={this.state.tenant_email} placeholder='Enter email address' onChange={this.handleTenantEmail} />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16} className="border-top border mt-3">
                  <Col span={24}>
                    <p className='font-bold my-2 fs-6'>Documents</p>
                  </Col>
                  <Col span={12}><div className='form-group'>
                    <label>Title Deed <em>*</em></label>
                    <input type="file" placeholder='Enter email address' onChange={this.handleTitleDeed} />
                  </div>
                  </Col>
                  <Col span={12}><div className='form-group'>
                    <label>Landlord's Passport<em>*</em></label>
                    <input type="file" placeholder='Enter email address' onChange={this.handleLandLoardPassportFile} />
                  </div>
                  </Col>

                  <Col span={12}><div className='form-group'>
                    <label>Landlord's Emirate ID</label>
                    <input type="file" placeholder='Enter email address' onChange={this.handleLandLoardEmirateIDFile} />
                  </div>
                  </Col>
                  <Col span={12}><div className='form-group'>
                    <label>Tenant's Passport<em>*</em></label>
                    <input type="file" placeholder='Enter email address' onChange={this.handleTenantPassportFile} />
                  </div>
                  </Col>
                  <Col span={12}><div className='form-group'>
                    <label>Tenant's Emirate ID</label>
                    <input type="file" placeholder='Enter email address' onChange={this.handleTenantEmirateIDFile} />
                  </div>
                  </Col>
                  <Col span={12}><div className='form-group'>
                    <label>Tenant's Visa</label>
                    <input type="file" placeholder='Enter email address' onChange={this.handleTenantVisa} />
                  </div>
                  </Col>
                  <Col span={12}><div className='form-group'>
                    <label>POA Documents (Passport, POA)</label>
                    <input type="file" placeholder='Enter email address' onChange={this.handlePOA} />
                  </div>
                  </Col>
                  <Col span={12}><div className='form-group'>
                    <label>Cheque Copy</label>
                    <input type="file" placeholder='Enter email address' onChange={this.handleCheque} />
                  </div>
                  </Col>

                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='ml-auto d-flex flex-row mt-5 mb-3'>
                <Button type='primary' className='mr-3' onClick={this.handleSubmitTenancyContract}>Submit Contract</Button>
                <Button type='primary'>Cancel</Button>
              </Col>
            </Row>
          </div>
        </Drawer>


        <Drawer width={1200} title="MOU Request" placement="left" onClose={this.handlemourequestscancel} open={this.state.ismourequestOpen}>
          <div className="">
            <Row className='border-bottom pb-2' gutter={8}>
              <Col>
                <h5 className='font-bold mb-0'>{offer_record.refno}</h5>
                <p className="mb-0 font-12">{offer_record.community_title}, {offer_record.sub_community_title}</p>
                <p className='mb-0 font-12'>{offer_record_price ? offer_record_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ""}</p>


              </Col>

            </Row>
            <Row span={24} gutter={24} className='mt-3'>
              <Col span={12}>
                <div className='form-group'>
                  <label className='font-16'>Sale Price</label>
                  <input placeholder='Enter amount' value={this.state.mou_price} onChange={this.handleMouPrice} />
                </div>
              </Col>
              <Col span={12}>
                <div className='form-group'>
                  <label className='font-16'>Proposed Transfer Date</label>
                  <DatePicker style={{ height: '36px', overflow: 'hidden' }} onChange={this.handleMouTransferDate} />
                </div>
              </Col>
            </Row>
            <Row span={24} gutter={24} className='mt-2 pt-2 border-bottom'>
              <Col span={12}>
                <div className='form-group'>
                  <label className='font-16'>Annual Service Charges Amount</label>
                  <input placeholder='Enter amount' onChange={this.handleMouAnnualAmount} />
                </div>
              </Col>
              <Col span={4}>
                <div className='form-group'>
                  <label className='font-16'>Is the property vacant?</label>
                  <Radio.Group defaultValue={this.state.mou_is_vacant} buttonStyle="solid" className='d-flex flex-row' style={{ height: '36px' }} onChange={this.handleIdVacant}>
                    <Radio.Button value="yes">Yes</Radio.Button>
                    <Radio.Button value="no">No</Radio.Button>
                  </Radio.Group>
                </div>
              </Col>
              {/* If it's NO show Tenance expiry date */}
              <Col span={8}>
                <div className='form-group'>
                  <label className='font-16'>Tenancy expiry date</label>
                  <DatePicker style={{ height: '36px', overflow: 'hidden' }} onChange={this.handleMouExpireDate} />
                </div>
              </Col>
            </Row>

            <Row span={24} gutter={24} className="py-3">
              {/* SELLER - Function to add multiple sellers
                                Seller Name: *
                                Seller Mobile: *
                                Seller Email: *
                                Seller’s Agent: * */}
              <Col span={12}>
                <p className='font-bold text-uppercase'>Seller</p>
                <div className='form-group mb-0'>
                  <label className='font-16'>Search for seller</label>
                  <input placeholder='Search' />
                </div>
                <Button type="link" className='font-12 p-0 text-black float-right'>Add New Seller</Button>
              </Col>
              <Col span={12}>
                {/* BUYER – Function to add multiple Buyers
                                    Buyer Name:*
                                    Buyer Mobile: *
                                    Buyer Email: *
                                    Buyer’s Agent: * */}
                <p className='font-bold text-uppercase'>Buyer</p>
                <div className='form-group mb-0'>
                  <label className='font-16'>Search for Buyer</label>
                  <input placeholder='Search' />
                </div>
                <Button type="link" className='font-12 p-0 text-black float-right'>Add New Buyer</Button>
              </Col>
            </Row>
            <Row span={24} gutter={24} className='my-2'>
              <Col span={12}>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Email</th>
                      <th scope="col">Agent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>

                      <td>{this.state.seller_data.fullname}</td>
                      <td>{this.state.seller_data.mobile}</td>
                      <td>{this.state.seller_data.email}</td>
                      <td>{this.state.seller_data.display_name}</td>
                    </tr>

                  </tbody>
                </table>
                {/* <Table columns={sellers} dataSource={sellersdata} className='w-100 font-12 border' pagination={false}/> */}
              </Col>
              <Col span={12}>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Email</th>
                      <th scope="col">Agent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>

                      <td>{this.state.buyer_data.fullname}</td>
                      <td>{this.state.buyer_data.mobile}</td>
                      <td>{this.state.buyer_data.email}</td>
                      <td>{this.state.buyer_data.display_name}</td>
                    </tr>

                  </tbody>
                </table>
                {/* <Table columns={sellers} dataSource={sellersdata} className='w-100 font-12 border' pagination={false}/> */}
              </Col>
            </Row>
            <Row span={24} gutter={24} className='my-3'>
              <Col span={24}><p className='font-bold mb-0'>Documents needed</p></Col>
              {/* If there is already upload documents under the listing show in here if not they can upload. */}
              {/* Seller documents 
                                Title Deed: *
                                Passport – attachment – Minimum requirement *
                                EID – attachment
                                Visa – Attachment
                            */}
              <Col span={12}>
                <div className='form-group'>
                  <label>Title Deed <em>*</em></label>
                  <input type="text" className="form-control" onChange={this.handleSellerTitleDeed} />
                  <div className="text-danger">
                    {this.state.errors.seller_title_deed}
                  </div>
                </div>
                <div className='form-group'>
                  {/* <label class="input-group-text" for="inputGroupFile01">Upload</label> */}
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerTitleFile} />


                  {/* <Upload onChange={this.handleSellerTitleFile}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>
                <div className='form-group'>
                  <label>Passport <em>*</em><br /><span className='font-10'>Minimum Requirement</span></label>
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerPassport} />
                  <div className="text-danger">
                    {this.state.errors.seller_passport}
                  </div>
                  {/* <Upload onChange={this.handleSellerPassport}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>
                <div className='form-group'>
                  <label>Emirates ID</label>
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerEmirateID} />
                  {/* <Upload onChange={this.handleSellerEmirateID}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>
                <div className='form-group'>
                  <label>Visa</label>
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleSellerVisa} />
                  {/* <Upload onChange={this.handleSellerVisa}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>




              </Col>
              {/* BUYER REquirements
                            Passport – attachment – Minimum requirement*
                            EID – Attachment */}
              <Col span={12}>
                <div className='form-group'>
                  <label>Title Deed <em>*</em></label>
                  <input type="text" className="form-control" onChange={this.handleBuyerTitleDeed} />
                  <div className="text-danger">
                    {this.state.errors.buyer_title_deed}
                  </div>
                </div>
                <div className='form-group'>
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerTitleFile} />
                  {/* <Upload onChange={this.handleBuyerTitleFile}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>
                <div className='form-group'>
                  <label>Passport <em>*</em><br /><span className='font-10'>Minimum Requirement</span></label>
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerPassport} />
                  <div className="text-danger">
                    {this.state.errors.buyer_passport}
                  </div>
                  {/* <Upload onChange={this.handleBuyerPassport}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>
                <div className='form-group'>
                  <label>Emirates ID</label>
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerEmirateID} />
                  {/* <Upload onChange={this.handleBuyerEmirateID}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>
                <div className='form-group'>
                  <label>Visa</label>
                  <input type="file" class="form-control" id="inputGroupFile01" onChange={this.handleBuyerVisa} />
                  {/* <Upload onChange={this.handleBuyerVisa}><Button icon={<UploadOutlined />}>Browse</Button></Upload> */}
                </div>

              </Col>
            </Row>
            <Row span={24} gutter={24} className='my-2'>
              <Col span={12}>
                <div className='form-group'>
                  <label className='font-16'>Seller Commission: <em>*</em></label>
                  <input placeholder="Enter seller's commision" onChange={this.handleSellerCommission} />
                  <div className="text-danger">
                    {this.state.errors.seller_commission}
                  </div>
                </div>
                <div className='form-group'>
                  <label className='font-16'>Seller Conveyancing Fee: <em>*</em></label>
                  <input placeholder="Enter Seller Conveyancing Fee" onChange={this.handleSellerConveyancingFee} />
                  <div className="text-danger">
                    {this.state.errors.seller_conveyancing_fee}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className='form-group'>
                  <label className='font-16'>Buyer Commission: <em>*</em></label>
                  <input placeholder="Enter Buyer's commision" onChange={this.handleBuyerCommission} />
                  <div className="text-danger">
                    {this.state.errors.buyer_commission}
                  </div>
                </div>
                <div className='form-group'>
                  <label className='font-16'>Buyer Conveyancing Fee: <em>*</em></label>
                  <input placeholder="Enter Buyer Conveyancing Fee" onChange={this.handleBuyerConveyancingFee} />
                  <div className="text-danger">
                    {this.state.errors.buyer_conveyancing_fee}
                  </div>
                </div>
              </Col>
            </Row>
            <Row span={24} gutter={24} className='my-2'>
              <Col span={12}>
                <div className='form-group'>
                  <label className='font-16'>Furnished/Unfurnished: <em>*</em></label>
                  <Select style={{ width: 120 }}
                    onChange={this.handleFurnished}
                    options={[
                      { value: 'furnished', label: 'Furnished' },
                      { value: 'unfurnished', label: 'Unfurnished' },
                    ]}
                  />
                  <div className="text-danger">
                    {this.state.errors.furnished_unfurnished}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className='form-group'>
                  <label className='font-16'>Valuation Variance: <em>*</em></label>
                  <Select style={{ width: 120 }}
                    onChange={this.handleValuationVariance}
                    options={[
                      { value: '0', label: '0%' },
                      { value: '5', label: '5%' },
                      { value: '7', label: '7%' },
                      { value: '10', label: '10%' },
                    ]}
                  />
                  <div className="text-danger">
                    {this.state.errors.valuation_variance}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='form-group'>
                  <label>Special Conditions</label>
                  <TextArea
                    placeholder="Enter special conditions"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={this.handleSpecialConditions}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button type="primary" className='ml-auto' onClick={this.handleSubmit}>Submit</Button>
              </Col>
            </Row>
          </div>
        </Drawer>







        <div className='contentwrap p-4' style={{

          minHeight: 380,
        }}>

          <div className='toptitle w-100'>
            <Row span={24} className='w-100'>
              <Col span={12}>
                <h3 className="my-2 ">Offers</h3>
              </Col>
              <Col span={12}>
                <Button className='ml-auto' type="primary" onClick={this.handleClearFilter} icon={<ClearOutlined />}>Reset filter</Button>
              </Col>
            </Row>
          </div>




          <div class=" alloffers " >
            <div className='tabs-months pb-2 w-100'>
              <Tabs >
                <TabList>
                  {this.state.months.length > 0 ? <Tab onClick={this.handleClearMonth}>All</Tab> : null}
                  {this.state.months.map((month) => (
                    <>
                      <Tab onClick={this.handleMonthTabs.bind(this, month.key)}><span>{month.labe}</span></Tab>
                    </>
                  ))}

                </TabList>
              </Tabs>
            </div>
            {/* <Tabs className={'monthlyviews px-3'} >
            <TabList selectedIndex={0}>
                <Tab >January</Tab>
                <Tab>February</Tab>
                <Tab>March</Tab>
                <Tab>April</Tab>
                <Tab>May</Tab>
                <Tab>June</Tab>
                <Tab>July</Tab>
                <Tab>August</Tab>
                <Tab>September</Tab>
                <Tab>October</Tab>
                <Tab>November</Tab>
                <Tab>December</Tab>
                
            </TabList> */}
            {/* <TabPanel className={'px-2'}> */}
            <Row gutter={16} className='px-2 mx-0 mb-3 statwrap'>
              <Col span={4} lg={4} md={8} sm={12} xs={12} className=' p-3 flex-row'>
                <div className='flex-column'>
                  <p className='mb-0 font-12 fontRes-9'>Total Deal Signed</p>
                  <p className='font-bold fs-3 mb-0 fontRes-1'>  {this.state.isTotalLoading ? <Skeleton active /> :
                    <>{deal_signed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>}</p>
                </div>
              </Col>
              <Col span={4} lg={4} md={8} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-9'>Total Accepted Offer</p>
                <p className='font-bold fs-3 mb-0 fontRes-1'>  {this.state.isTotalLoading ? <Skeleton active /> :
                  <>{accepted_offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>}</p>
              </Col>
              <Col span={4} lg={4} md={8} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-9'>Total Rejected Offer</p>
                <p className='font-bold fs-3 mb-0 fontRes-1'>  {this.state.isTotalLoading ? <Skeleton active /> :
                  <>{rejected_offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>}</p>
              </Col>
              <Col span={4} lg={4} md={8} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-9'>Total Sent Offer</p>
                <p className='font-bold fs-3 mb-0 fontRes-1'>  {this.state.isTotalLoading ? <Skeleton active /> :
                  <>{sent_offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>}</p>
              </Col>
              <Col span={4} lg={4} md={8} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-9'>Sales Volume</p>
                <p className='font-bold fs-3 mb-0 fontRes-1'>  {this.state.isTotalLoading ? <Skeleton active /> :
                  <>{total_listings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>}</p>
                {/* total of Price column */}
              </Col>
              <Col span={4} lg={4} md={8} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-9'>Commission Volume</p>
                <p className='font-bold fs-3 mb-0 fontRes-1'>  {this.state.isTotalLoading ? <Skeleton active /> :
                  <>{commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>}</p>
                {/* Total of Commission column */}
              </Col>
            </Row>



            {/* Responsive Filters List by Hassan 8/14/24 */}
            {window.isIpadView ? <>
              <div className='listingfilter-second'>
                <Row span={24}>
                  <Col span={24}>
                    {/* Status */}
                    <div className='d-flex flex-row align-items-end form-group formcheckboxgroup'>
                      <div className="d-flex flex-column align-items-start "> {/* 11-23-2023 */}


                        <Collapse className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                          <Panel header={<p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Filter by</p>} key="2">
                            {/* {!this.state.isViewingTabShow && !this.state.isOfferTabShow?<> */}

                            <>
                              <Popover title="Status" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {offerStatus}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Status
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Ref No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {RefNoProperty}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Ref No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Client Name" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {clientName}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Client Name
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>


                              <Popover title="Offer feedback" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {offerFeedback}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Offer feedback
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Property For" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {propertyFor}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Property For
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Agent name" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {vendorAgent}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Vendor Agent
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Unit No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {unitNo}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Unit No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Agent Name" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {clientAgent}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Client Agent
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Price" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {propertyPrice}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Price
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Status" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {propertyStatus}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Status
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>


                            </>



                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </> : ''
            }




            <div className='tabs-view'>
              <Tabs >
                <TabList>
                  <Tab>Pending Offers</Tab>
                  <Tab>Previous Offers</Tab>
                </TabList>
                <TabPanel>

                  {this.state.isNext ?
                    <div className='table-responsive tablelistwrap upcomingviewings tabscontentwrap'><h5 className="mt-2 font-bold fs-5">
                      Pending Offers</h5>
                      <Table onChange={handleTableChange}
                        loading={this.state.isLoading}
                        rowSelection={window.isIpadView ? '' : rowSelection}
                        className="TableborderNoneMain"
                        pagination={{
                          pageSizeOptions: ['10', '20', '50', '100'],
                          total:
                            this.state?.all_upcomming_offers?.total > 0 &&
                            this.state.all_upcomming_offers.total,
                          pageSize: this.state.all_upcomming_offers.per_page,
                          current: this.state.all_upcomming_offers.current_page,
                          showTotal: (total, range) =>
                            window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        //   style={{ overflowX: "auto" }}
                        columns={window.isIpadView ? columnsResponsiveThis : role == 1 ? columns : columns1}
                        scroll={{ x: window.isIpadView ? 'auto' : 1500, y: 'hidden' }}
                        dataSource={
                          this.state?.all_upcomming_offers?.data
                            ? this.state?.all_upcomming_offers?.data
                            : []
                        }

                        // rowKey={(record) => record}
                        rowKey={(record) => record.id}
                      />
                    </div>
                    :
                    <>
                      <div className='table-responsive tablelistwrap upcomingviewings tabscontentwrap p-3'><p>No Records Found</p></div>
                    </>
                  }

                </TabPanel>

                <TabPanel>

                  {this.state.isPrevious ?
                    <div className='table-responsive tablelistwrap previousviewings tabscontentwrap'>
                      <h5 className="mt-2 font-bold fs-5">Previous Offers</h5>
                      <Table onChange={handlePreviousTableChange}
                        loading={this.state.isPreviousLoading}
                        rowSelection={window.isIpadView ? '' : rowSelection}
                        className="TableborderNoneMain"
                        pagination={{
                          pageSizeOptions: ['10', '20', '50', '100'],
                          total:
                            this.state?.all_previous_offers?.total > 0 &&
                            this.state.all_previous_offers.total,
                          pageSize: this.state.all_previous_offers.per_page,
                          current: this.state.all_previous_offers.current_page,
                          showTotal: (total, range) =>
                            window.isTabView ? `` :`Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        //   style={{ overflowX: "auto" }}
                        columns={window.isIpadView ? columnsResponsiveThis : role == 1 ? columns : columns1}
                        scroll={{ x: window.isIpadView ? 'auto' : 1500, y: 'hidden' }}
                        dataSource={
                          this.state?.all_previous_offers?.data
                            ? this.state?.all_previous_offers?.data
                            : []
                        }

                        // rowKey={(record) => record}
                        rowKey={(record) => record.id}
                      />
                    </div>
                    : <>
                      <div className='table-responsive tablelistwrap upcomingviewings tabscontentwrap p-3'><p>No Records Found</p></div>
                    </>
                  }

                </TabPanel>
              </Tabs></div>

            {/* <Table onChange={handleTableChange}


                loading={this.state.isLoading}
                rowSelection={rowSelection}
                //className="table-striped table-bordered"
                pagination={{
                  pageSizeOptions: ['10', '20', '50', '100'],
                  total:
                    this.state?.all_viewings?.total > 0 &&
                    this.state.all_viewings.total,
                  pageSize: this.state.all_viewings.per_page,
                  current: this.state.all_viewings.current_page,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                scroll={{x:'2000', y:'1500'}}
                columns={role == 1 ? columns : columns1}
                bordered
                dataSource={
                  this.state?.all_viewings?.data
                    ? this.state?.all_viewings?.data
                    : []
                }

                
                rowKey={(record) => record.id}
              /> */}
            {/* </TabPanel>
            <TabPanel>February</TabPanel>
            <TabPanel>March</TabPanel>
            <TabPanel>April</TabPanel>
            <TabPanel>May</TabPanel>
            <TabPanel>June</TabPanel>
            <TabPanel>July</TabPanel>
            <TabPanel>August</TabPanel>
            <TabPanel>September</TabPanel>
            <TabPanel>October</TabPanel>
            <TabPanel>November</TabPanel>
            <TabPanel>December</TabPanel>
                </Tabs> */}
          </div>
        </div>


        {/** ADD MODAL BY FAROOQ */}
        <Modal width={600} title="Offers" open={this.state.offerModal} height={600}
          onOk={this.handleChangeStatus} onCancel={this.handleCancel}
          footer={[
            <>
              <Button key="ok" onClick={this.handleChangeStatus}>Submit </Button>
              {/* <Button key="ok" onClick={this.handleChangeStatus}>Submit</Button> */}
              <Button key="back" onClick={this.handleCancel}>Cancel</Button>
            </>
          ]}
        >
          <div className="container">
            <div className='row w-100 d-flex flex-row'>
              <div className='px-0 pt-1 form-group'>
                <label>Ref No</label>
                <p className="form-control-disabled">{this.state.offers.refno}</p>
              </div>
              {role == 1 ? <div className='px-0 pt-1 form-group'>
                <label>Agent Name</label>
                <p className="form-control-disabled">{this.state.offers_agent}</p>
              </div> : null}

            </div>
            <div className='row w-100 d-flex flex-row'>

              <div className='px-0 pt-1 form-group'>
                <label>Date</label>
                {
                  role == 1 ?
                    <DatePicker value={(offers_date) ? dayjs(offers_date) : ""} format="YYYY-MM-DD" style={{ width: '100%' }} showTime onChange={this.handleOfferingDate} />
                    :
                    <p className="form-control mb-0" style={{ fontSize: "12px" }}>{this.state.offers_date}</p>
                }

              </div>
              <div className='px-0 pt-1  form-group'>
                <label>Offers Status</label>
                <select onChange={this.handleOfferStatus} value={this.state.offers_status} className='form-select'>
                  <option value="1">Offer Sent</option>
                  <option value="2">Offer Accepted</option>
                  <option value="3">Offer Rejected</option>
                  {role == 1 ? <option value="4">Deal Signed</option> : null}

                  {/* <option value="5">MOU Request</option> */}
                </select>
                <div className='mt-2'></div>
                {this.state.showSaleScreenStatus ?
                  <Checkbox checked={this.state.sale_screen_status} onChange={this.handleSaleScreenStatus}>Don’t share in sales screen</Checkbox> : null}

              </div>
              {/* {
              this.state.showPrice ? 
                <div className='form-group'>
                  <label>Price</label>
                  <input onChange={this.handleOfferingPrice} type="text" className="form-control" value={this.state.offers_price} />
                </div> 
                : 
                null
              } */}
              <div className='form-group px-0'>
                <label>Price</label>
                <input onChange={this.handleOfferingPrice} type="text" className="form-control" value={this.state.offers_price} />
              </div>
              <div className='form-group px-0'>
                <label>Commission</label>
                <input onChange={this.handleOfferingCommission} type="text" className="form-control" value={this.state.offers_commission} />
              </div>

              {this.state.showReferral ?
                <>
                  {this.state.vendor_agent_id != this.state.client_agent_id ? <>
                    <div className='form-group px-0'>
                      <label>Split agent</label>
                      <p className="form-control-disabled">{split_agent ? split_agent : ""}</p>
                    </div>
                    <div className='form-group px-0'>
                      <label>Split Commission</label>
                      <input onChange={this.handleSplitCommission} type="text" className="form-control" />
                    </div>
                  </> : null}

                  <div className='form-group px-0'>
                    <label>Referral</label>
                    <Checkbox checked={this.state.ReferralChecked}
                      onChange={this.handleReferral}
                    />

                  </div></> : null}
              {this.state.showReferralAgent ? <><div className='form-group px-0'>
                <label>Referral Agent</label>
                <Select className='form-select p-0 noborder m-0 font-14'

                  showSearch
                  allowClear
                  maxTagCount='responsive'
                  style={{
                    width: '100%',
                  }}

                  placeholder="Select Agent"
                  onChange={this.handleReferralAgent}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }

                  options={this.state.agents}
                />
              </div>
                <div className='form-group px-0'>
                  <label>Commission</label>
                  <input type="text" className="form-control" onChange={this.handleRefrralCommission} />
                </div></> : null}
            </div>
            <div className='row w-100'>

              <div className='w-100 px-0 pt-1 form-group'>
                <label>Offer feedback</label>
                <TextArea value={this.state.offers_note} style={{ width: '100%' }} onChange={this.handleOfferingNote} />
              </div>


            </div>
          </div>
        </Modal>

      </div>
    )
  }
}




export default All_Listings_Leads_Offers

// export default  MyListings;
