import React, {Component} from 'react'
import API from "../../utils/API";
import {toast, ToastContainer} from "react-toastify";
import {connect} from "react-redux";

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pwd: "",
            confirm_pwd: "",
            disable: false
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.changePassword();
    }

    changePassword = () => {
        if (this.validate()) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const token = urlParams.get('token')
            const data = {
                pwd: this.state.pwd,
                token: token
            }
            this.setState({disable: true});
            API.post("/change_password", data)
                .then((response) => {
                    this.setState({disable: false});
                    let success = response.data.success;
                    if (success) {
                        toast.success("Password successfuly updated")
                        if (response?.data)
                            this.props.setCredentials(response.data)
                        setTimeout(() => {
                            this.setState({isLoading: false})
                            this.props.history.push("/dashboard");
                        }, 2000);
                    } else {
                        toast.warning("Something went wrong, please try again")
                    }
                })
        }
    }
    getPassword = (e) => {
        this.setState({pwd: e.target.value})
    }

    getConfirmPassword = (e) => {
        this.setState({confirm_pwd: e.target.value})
    }

    validate() {
        let pwd = this.state.pwd;
        let confirm_pwd = this.state.confirm_pwd;
        let validationerrors = {};
        let isValid = true;


        if (pwd == null || pwd == "") {
            isValid = false;
            validationerrors["pwd"] = "Password is required.";
        } else if (confirm_pwd == null || confirm_pwd == "") {
            isValid = false;
            validationerrors["confirm_pwd"] = "Confirm Password is required.";
        } else if (confirm_pwd != pwd) {
            isValid = false;
            validationerrors["pwd_mismatch"] = "Confirm Password must be same as Password.";
        }

        this.setState({
            validationerrors: validationerrors
        });

        return isValid;
    }

    render() {
        return (
            <div>
                <ToastContainer/>
                <div className='container' style={{marginTop: '50px'}}>
                    <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>

                            <div className="card ">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Enter New Password</h3>
                                    <div className="card-text">
                                        <form>
                                            <div className="form-group">
                                                <input type="password" className="form-control form-control-sm"
                                                       placeholder="Enter new password" name="pwd"
                                                       id="pwd" onChange={this.getPassword}/>
                                                <div
                                                    className="text-danger">{this.state.validationerrors && this.state.validationerrors.pwd}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <input type="password" className="form-control form-control-sm"
                                                       placeholder="Confirm password" name="confirm_pwd"
                                                       id="confirm_pwd" onChange={this.getConfirmPassword}/>
                                                <div
                                                    className="text-danger">{this.state.validationerrors && this.state.validationerrors.confirm_pwd}</div>
                                                <div
                                                    className="text-danger">{this.state.validationerrors && this.state.validationerrors.pwd_mismatch}</div>
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-block"
                                                    onClick={this.onSubmit}
                                                    disabled={this.state.disable}>Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCredentials: (data) => dispatch({type: "SET_LOGIN", payload: data})
    }
}

export default connect(null, mapDispatchToProps)(ResetPassword)