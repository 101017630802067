import React, { Component } from 'react'
import { connect } from 'react-redux';
import 'antd/dist/reset.css';
import { Row, Col, Table, Tabs, Space, Button, Modal, Select, Drawer, Input, Avatar, Divider, DatePicker, Mentions } from 'antd'
import {
    EditOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./leads.css";
import "../Listings/tableResponsive.css";
import Swal from "sweetalert2";
import { store } from '../../store';
import dayjs from "dayjs";
const { Option } = Select;


class LeadsRedirect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            agent_from: "",
            validationerrors: {},
            agent_to: "",
            date_from: "",
            date_to: "",
            redirected_id: "",
            agents: [],
            leads_redirect: [],
        }

    }

    componentDidMount() {

        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
        const page = 1;
        const perPage = 10;
        const data = {

        }
        API.get("/all_agents")
            .then((response) => {
                if (response.data.success) {
                    this.setState({ agents: response.data.agents })
                }
            })
        this.fetchData(page, perPage, data);


    }
    addNewRedirection = () => {
        this.setState({ isNewRedirection: true })
    }
    handleOpenDrawerRedirectionLead = (data) => {
        console.log("data", data)
        this.setState({
            agent_from: data.agent_from,
            agent_to: data.agent_to,
            date_from: data.date_from,
            date_to: data.date_to,
            redirected_id: data.id,
            isUpdateRedirection: true
        })
    }

    deleteRedirection = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                API.put("/delete_new_lead_redirection/" + id)
                    .then((response) => {
                        if (response.data.success) {
                            toast.success("Redirected Lead is successfully deleted")
                            const page = 1;
                            const perPage = 10;
                            const data = {

                            }
                            this.fetchData(page, perPage, data);
                        } else {
                            toast.error("Something went wrong")
                        }
                    }).catch((e) => {
                        toast.error("Something went wrong")
                        console.log("Error is deleting redirection lead", e)
                    })
            }
        })
    }
    onChangeAgentFrom = (e) => {
        this.setState({ agent_from: e })
    }
    onChangeAgentTo = (e) => {
        this.setState({ agent_to: e })
    }
    onChangeDateFrom = (data, dateString) => {
        this.setState({ date_from: dateString })
    }
    onChangeDateTo = (data, dateString) => {
        this.setState({ date_to: dateString })
    }

    validate = () => {
        let agent_from = this.state.agent_from;
        let agent_to = this.state.agent_to;
        let date_from = this.state.date_from;
        let date_to = this.state.date_to;
        let validationerrors = {};
        let isValid = true;

        if (agent_from == null || agent_from == "" || agent_from == 0) {
            isValid = false;
            validationerrors["agent_from"] = "Agent from is required";
        }
        if (agent_to == null || agent_to == "" || agent_to == 0) {
            isValid = false;
            validationerrors["agent_to"] = "Agent to is required";
        }
        if (date_from == null || date_from == "" || date_from == 0) {
            isValid = false;
            validationerrors["date_from"] = "Date from is required";
        }
        if (date_to == null || date_to == "" || date_to == 0) {
            isValid = false;
            validationerrors["date_to"] = "Date to is required";
        }

        this.setState({
            validationerrors: validationerrors
        });

        return isValid;
    }

    addNewRedirectLead = () => {
        if (this.validate()) {
            const data = {
                agent_from: this.state.agent_from,
                agent_to: this.state.agent_to,
                date_from: this.state.date_from,
                date_to: this.state.date_to,
            }

            API.post("/add_new_lead_redirection", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            isNewRedirection: false
                        })
                        this.resetState();
                        toast.success("Lead is successfully redirected")
                        const page = 1;
                        const perPage = 10;
                        const data = {

                        }
                        this.fetchData(page, perPage, data);
                    } else {
                        toast.error("Something went wrong")
                    }
                }).catch((e) => {
                    toast.error("Something went wrong")
                    console.log("Error is redirection lead", e)
                })
        }
    }
    updateRedirectLead = () => {
        if (this.validate()) {
            const data = {
                agent_from: this.state.agent_from,
                agent_to: this.state.agent_to,
                date_from: this.state.date_from,
                date_to: this.state.date_to,
                id: this.state.redirected_id
            }
            API.post("/update_lead_redirection", data)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({
                            isUpdateRedirection: false
                        })
                        this.resetState();
                        toast.success("Lead is successfully updated")
                        const page = 1;
                        const perPage = 10;
                        const data = {

                        }
                        this.fetchData(page, perPage, data);
                    } else {
                        toast.error("Something went wrong")
                    }
                }).catch((e) => {
                    toast.error("Something went wrong")
                    console.log("Error is redirection lead", e)
                })
        }
    }
    handleCancelNewRedirection = () => {
        this.setState({ isNewRedirection: false })
        this.resetState();
    };
    handleCancelUpdateRedirection = () => {
        this.setState({ isUpdateRedirection: false })
        this.resetState();
    };
    resetState = () => {
        this.setState({
            agent_from: "",
            agent_to: "",
            date_from: "",
            date_to: "",
            redirected_id: "",
            validationerrors: {}
        })
    };

    onInputChange = (e) => {
        console.log("onchange", e.target.value)
    }
    fetchData = (page, perPage, data) => {
        this.setState({ isLoading: true })

        API.post(`/leads_redirect?page=${page}&perPage=${perPage}`, data)
            .then(async (response) => {
                if (response.data.success) {
                    var data = await response.data.leads_redirect.data;
                    await response.data.leads_redirect;
                    this.setState({
                        leads_redirect: response.data.leads_redirect,
                        isLoading: false
                    })
                }

            })
    }

    getCurrentTime = (date) => {
        if (date != null && date != "" && date != undefined && date != "0000-00-00 00:00:00") {
            let newDate = new Date(date);
            newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
            return newDate.toISOString();
        }
    }

    render() {

        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let role_name = userdata.auth.user.role_name;
        const user_id = userdata.auth.user.id

        const onSelectChange = (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
            // setSelectedRowKeys(newSelectedRowKeys);
        };

        const rowSelection = {
            // selectedRowKeys,
            onChange: onSelectChange,

        };

        const columns = [

            {
                title: 'Lead from',
                render: (text, record) => (
                    <>
                        {record.agent_from_name === "" || record.agent_from_name === undefined || record.agent_from_name === null ? '-' : record.agent_from_name}
                    </>
                ),
            },
            {
                title: 'Lead to',
                render: (text, record) => (
                    <>
                        {record.agent_to_name === "" || record.agent_to_name === undefined || record.agent_to_name === null ? '-' : record.agent_to_name}
                    </>
                ),
            },
            {
                title: 'Date from',
                render: (text, record) => (
                    <>
                        {record.date_from === "" || record.date_from === undefined || record.date_from === null ? '-' : <Moment format="MMMM DD, YYYY">{record.date_from}</Moment>}
                    </>
                ),
            },
            {
                title: 'Date to',
                render: (text, record) => (
                    <>
                        {record.date_to === "" || record.date_to === undefined || record.date_to === null ? '-' : <Moment format="MMMM DD, YYYY">{record.date_to}</Moment>}
                    </>
                ),
            },
            {
                title: 'Assign date',
                render: (text, record) => (
                    <>
                        {record.added_date === "" || record.added_date === undefined || record.added_date === null ? '-' : <Moment fromNow>
                            {record.added_date}
                        </Moment>}
                    </>
                ),
            },
            {
                title: 'Actions',
                render: (text, record) => (
                    <>
                        <a className="btn btn-secondary btn-sm m-2" style={{ color: 'white', border: 'none', background: 'transparent' }} onClick={this.deleteRedirection.bind(this, record.id)}  ><i className="fa fa-trash fs-6 text-black" ></i></a>
                        <a className='btn btn-text px-0 align-items-center d-flex' onClick={this.handleOpenDrawerRedirectionLead.bind(this, record)}><EditOutlined className='mr-1' /></a>
                    </>
                ),
            },
        ];

        const responsiveColumns = [

            {
                title: 'expired in', dataIndex: 'expires_in',
                render: (text, record) => {

                    return (
                        <div className="TableCard">

                            <div className='row alignCenter headerGrayBgColor borderBottom'>
                                <div className='col-6'>

                                </div>


                            </div>

                            <div className='row alignCenter borderGray pt-2 pb-2'>

                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>

                                            <p className='info-title'>Lead From:</p>
                                            <p className="font-12">{record.agent_from_name === "" || record.agent_from_name === undefined || record.agent_from_name === null ? '-' : record.agent_from_name}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>

                                            <p className='info-title'>Lead To:</p>
                                            <p className="font-12">{record.agent_to_name === "" || record.agent_to_name === undefined || record.agent_to_name === null ? '-' : record.agent_to_name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>

                                            <p className='info-title'>Date From:</p>
                                            <p className="font-12">{record.date_from === "" || record.date_from === undefined || record.date_from === null ? '-' : <Moment format="MMMM DD, YYYY">{record.date_from}</Moment>}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-12 col-sm-6'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>

                                            <p className='info-title'>Date To:</p>
                                            <p className="font-12">{record.date_to === "" || record.date_to === undefined || record.date_to === null ? '-' : <Moment format="MMMM DD, YYYY">{record.date_to}</Moment>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12'>
                                    <div className='BodyFirstRowRight'>
                                        <div className='d-flex gap-1 BodyFirstRowRight'>
                                            <p className='info-title'>Assign Date:</p>
                                            <p className="font-12">{record.added_date === "" || record.added_date === undefined || record.added_date === null ? '-' : <Moment fromNow>
                                                {record.added_date}
                                            </Moment>}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12'>
                                <div className='BodyFirstRowRight'>
                                    <div className='d-flex gap-1 justify-content-center BodyFirstRowRight'>
                                        <div className='d-flex flex-row align-items-baseline'>
                                        <a className="btn btn-secondary btn-sm m-2" style={{ color: 'white', border: 'none', background: 'transparent' }} onClick={this.deleteRedirection.bind(this, record.id)}  ><i className="fa fa-trash fs-6 text-black" ></i></a>
                                        <a className='btn btn-text px-0 align-items-center d-flex' onClick={this.handleOpenDrawerRedirectionLead.bind(this, record)}><EditOutlined className='mr-1' /></a>
                                        </div>
                                    </div>


                                </div>

                            </div>


                        </div>
    
    
                        </div >
                    )
    }
},
        ]


const handleTableChange = (pagination, filters) => {
    const data = {
    }

    this.fetchData(pagination.current, pagination.pageSize, data);

};

return (
    <div>
        <ToastContainer />
        <div className="contentwrap" style={{
            minHeight: 380,
            background: "#fff",
        }}>
            <div className='ml-auto d-flex px-0'>
                <div className='row w-100'>
                    <Space direction="vertical">


                        <Space wrap>

                            <div className="d-flex flex-row justify-content-between my-2 w-100 px-3">
                                <h3 className="mb-0">Leads Redirect</h3>
                                <div className="d-flex flex-row justify-content-between">
                                    <Button className='ml-2 addnewlisting' type='primary' onClick={this.addNewRedirection} icon={<PlusCircleOutlined />}>Add New Redirection</Button>
                                </div>
                            </div>

                            {/* add redirection request */}

                            <Modal width={1000} title="Add New Redirection" open={this.state.isNewRedirection} onOk={this.addNewRedirectLead} onCancel={this.handleCancelNewRedirection}>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Agent from<span style={{ color: '#FF0000' }}>*</span></label>
                                            <Select showSearch value={this.state.agent_from}
                                                onChange={this.onChangeAgentFrom}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                name="agents_assign">

                                                <Option
                                                    value="">Select agent to</Option>
                                                {this.state.agents.map((agent) => {
                                                    return <>
                                                        <Option
                                                            value={agent.value}>{agent.label}</Option>
                                                    </>
                                                })}
                                            </Select>
                                            <div
                                                className="text-danger">{this.state.validationerrors.agent_from}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Agent to<span style={{ color: '#FF0000' }}>*</span></label>
                                            <Select showSearch value={this.state.agent_to}
                                                onChange={this.onChangeAgentTo}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                name="agents_assign">

                                                <Option
                                                    value="">Select agent to</Option>
                                                {this.state.agents.map((agent) => {
                                                    return <>
                                                        <Option
                                                            value={agent.value}>{agent.label}</Option>
                                                    </>
                                                })}
                                            </Select>
                                            <div
                                                className="text-danger">{this.state.validationerrors.agent_to}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Date from<span style={{ color: '#FF0000' }}>*</span></label>
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                style={{ width: '100%' }}
                                                showTime
                                                changeOnBlur={true}
                                                onChange={this.onChangeDateFrom} />
                                            <div
                                                className="text-danger">{this.state.validationerrors.date_from}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Date to<span style={{ color: '#FF0000' }}>*</span></label>
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                style={{ width: '100%' }}
                                                showTime
                                                changeOnBlur={true}
                                                onChange={this.onChangeDateTo} />
                                            <div
                                                className="text-danger">{this.state.validationerrors.date_to}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal>

                            {/* update redirection request */}

                            <Modal width={1000} title="Update Redirection" open={this.state.isUpdateRedirection} onOk={this.updateRedirectLead} onCancel={this.handleCancelUpdateRedirection}>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Agent from<span style={{ color: '#FF0000' }}>*</span></label>
                                            <Select showSearch value={this.state.agent_from}
                                                onChange={this.onChangeAgentFrom}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                name="agents_assign">

                                                <Option
                                                    value="">Select agent to</Option>
                                                {this.state.agents.map((agent) => {
                                                    return <>
                                                        <Option
                                                            value={agent.value}>{agent.label}</Option>
                                                    </>
                                                })}
                                            </Select>
                                            <div
                                                className="text-danger">{this.state.validationerrors.agent_from}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Agent to<span style={{ color: '#FF0000' }}>*</span></label>
                                            <Select showSearch value={this.state.agent_to}
                                                onChange={this.onChangeAgentTo}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                name="agents_assign">

                                                <Option
                                                    value="">Select agent to</Option>
                                                {this.state.agents.map((agent) => {
                                                    return <>
                                                        <Option
                                                            value={agent.value}>{agent.label}</Option>
                                                    </>
                                                })}
                                            </Select>
                                            <div
                                                className="text-danger">{this.state.validationerrors.agent_to}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Date from<span style={{ color: '#FF0000' }}>*</span></label>
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                style={{ width: '100%' }}
                                                showTime
                                                changeOnBlur={true}
                                                value={(this.state.date_from) ? dayjs(this.state.date_from) : ""}
                                                onChange={this.onChangeDateFrom} />
                                            <div
                                                className="text-danger">{this.state.validationerrors.date_from}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='width-100'>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Date to<span style={{ color: '#FF0000' }}>*</span></label>
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                style={{ width: '100%' }}
                                                showTime
                                                changeOnBlur={true}
                                                value={(this.state.date_to) ? dayjs(this.state.date_to) : ""}
                                                onChange={this.onChangeDateTo} />
                                            <div
                                                className="text-danger">{this.state.validationerrors.date_to}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal>

                        </Space>
                    </Space>
                </div>
            </div>


            <div class="table-responsive tablelistwrap px-3" >

                <Table onChange={handleTableChange}
                    rowSelection={window.isIpadView ? '' :rowSelection}
                    loading={this.state.isLoading}
                     className="TableborderNoneMain"
                    pagination={{
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total:
                            this.state?.leads_redirect?.total > 0 &&
                            this.state.leads_redirect.total,
                        pageSize: this.state.leads_redirect.per_page,
                        current: this.state.leads_redirect.current_page,
                        showTotal: (total, range) =>
                             window.isTabView ? `` :`Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                    }}
                    style={{ overflowX: "auto" }}
                    columns={window.isTabView? responsiveColumns: columns}

                    dataSource={
                        this.state?.leads_redirect?.data
                            ? this.state?.leads_redirect?.data
                            : []
                    }
                    rowKey={(record) => record}
                />
                {/* </ReactDragListView.DragColumn> */}

            </div>
        </div>

    </div>

)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNoMatch: (data) => dispatch({ type: "SET_Not_Match", payload: data })
    }
}

export default connect(null, mapDispatchToProps)(LeadsRedirect)