import React from 'react';
import { store } from '../../store';
import Moment from 'react-moment'; 
 
const thStyle = {
    fontFamily: "Anton",
    fontWeight: "normal",
    fontStyle: "normal"
};
   
class DataComponent extends React.Component {
    render() {
      const tenancy_contract = store.getState((state) => state);
      const tenancy_contract_data = tenancy_contract
      const data_object = tenancy_contract_data.tenancy_contract_reducer.tenancy_contract;
      return (
      <div>
  <div className="u-row-container" style={{height:'297mm', width:"210mm", border:"1px solid #151515", margin:"0 auto", backgroundColor: "#FFF",padding:"5px 1.5rem"}}>
    <div className="u-row" style={{}}>
      <table width="100%">
        <tbody><tr style={{backgroundColor: '#FFF', paddingBottom: 5}}>
            <td text-align="left">
              <img src="Government_of_Dubai_logo.png" height="32px" />
              <img src="Dubai-Land-LOGO.png" height="40px" valign="center" style={{float: 'right'}} />
            </td>
          </tr>
          <tr style={{border: '1px solid #4f647e', backgroundColor: '#FFF'}}>
            <td style={{verticalAlign: 'bottom', padding: 5, border: '1px solid #4f647e', width: '100%'}}>
              <div style={{width: '33%', float: 'left', paddingTop: 25, paddingLeft: 10}}>
                <p style={{float: 'left', fontSize: 12, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Date</p>
                <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 150, margin: '0 0 0 2px', lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif'}}>&nbsp;</p>
                <p style={{fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 12, lineHeight: 1, color:'#2d4253'}}>التاريخ</p>
              </div>
              <div style={{width: '62%', float: 'right'}}><img src="tenancycontract-logo.png" height="40px" /></div>
            </td>
          </tr>
        </tbody></table>
      <table style={{marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Owner / Lessor Information</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>معلومات المالك/ المؤجر</th>
          </tr></thead>
        <tbody><tr>
            <td colSpan={12} style={{padding: '10px 15px 0'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Owner's Name</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1, textIndent:'1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.landloard_name}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>اسم المالك</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', letterSpacing: '-0.6px', color:'#2d4253'}}>Lessor's Name</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1,textIndent:'1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.tenant_name}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>اسم المؤجر</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Lessor's Emirates ID</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1,textIndent:'1rem', fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.landloard_emirate_id_number}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>الهوية اللإمارتية للمؤجر</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '18%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>License No.</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '65%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif !important', color:'#000'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>رقم الرخصة</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '23%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>License Authority</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '57%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '17%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>سلطة الترخيص</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '100%', marginTop:'-15px'}}><p style={{fontSize: 8, width: '50%', margin: '0 0 0 -3%', float: 'left', textAlign: 'center'}}><span style={{fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253', marginRight:'10px'}}>Incase of a Company</span><span style={{fontFamily: 'Cairo, sans-serif'}}> في حال كانت شركة</span></p></div>
              <div style={{width: '100%', marginTop:'-15px'}}><p style={{fontSize: 8, width: '48%', margin: '0 0 0 5%', float: 'left', textAlign: 'center'}}><span style={{fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253', marginRight:'15px'}}>Incase of a Company</span><span style={{fontFamily: 'Cairo, sans-serif', color:'#2d4253'}}> في حال كانت شركة</span></p></div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '5px 15px 0'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Lessor's Email</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '75%', float: 'left', lineHeight: 1,textIndent:'1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.landloard_email}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>البريد اللإلكتروني للمؤجر</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '11%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Lessor's Phone</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '78%', float: 'left', lineHeight: 1,textIndent:'1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.landloard_mobile}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '11%', float: 'right', textAlign: 'right', lineHeight: '1.8',color:'#2d4253'}}>رقم هاتف المؤجر</p>
            </td>
          </tr>
        </tbody></table>
      <table style={{marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Tenant Information</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>معلومات المالك/ المؤجر</th>
          </tr></thead>
        <tbody><tr>
            <td colSpan={12} style={{padding: '10px 15px 0'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Tenant's Name</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '82%', float: 'left', lineHeight: 1,textIndent:'1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.tenant_name}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>اسم المستأجر</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '12%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', letterSpacing: '-0.6px', color:'#2d4253'}}>Tenant's Emirates ID</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '73%', float: 'left', lineHeight: 1,textIndent:'1rem', height: "16px",fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.tenant_emirate_id_number}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>الهوية الإماراتية للمستأجر</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '20%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>License No.</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '65%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8',  color:'#2d4253'}}>رقم الرخصة</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '25%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>License Authority</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '60%', float: 'left', lineHeight: 1, height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8',  color:'#2d4253'}}>سلطة الترخيص</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '100%', marginTop:'-15px'}}><p style={{fontSize: 8, width: '50%', margin: '0 0 0 -3%', float: 'left', textAlign: 'center'}}><span style={{fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Incase of a Company</span><span style={{fontFamily: 'Cairo, sans-serif', color:'#2d4253'}}> في حال كانت شركة</span></p></div>
              <div style={{width: '100%', marginTop:'-15px'}}><p style={{fontSize: 8, width: '48%', margin: '0 0 0 5%', float: 'left', textAlign: 'center'}}><span style={{fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Incase of a Company</span><span style={{fontFamily: 'Cairo, sans-serif', color:'#2d4253'}}> في حال كانت شركة</span></p></div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '15px 15px 0'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '10%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Tenant's Email</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '74%', float: 'left', lineHeight: 1,textIndent:'1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.tenant_email}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>البريد الإلكتروني للمستأجر</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '12%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Tenant's Phone</p>
              <p style={{borderBottom: '1px dashed #4f647e', width: '72%', float: 'left', lineHeight: 1,textIndent:'1rem', height: "16px", fontFamily: 'Arial, Helvetica, sans-serif', color:'#000'}}>{data_object.tenant_mobile}</p>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8',color:'#2d4253'}}>رقم هاتف المستأجر</p>
            </td>
          </tr>
        </tbody></table>
      <table style={{marginTop: 5, border: '1px solid #eff2f5', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 3rem'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Property Information</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>معلومات العقار</th>
          </tr></thead>
        <tbody><tr>
            <td colSpan={24} style={{padding: '0 15px 0'}}>
                <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent: 'space-between'}}>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '19%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', height:'16px', margin:0}}>Property Usage</p>
              <div className="form-group" style={{float: 'left', margin: '3px 2%', lineHeight: '1.8', display:'flex', flexDirection:'row', alignItems:'center' }}>
                <input type="radio" id="industrial"disabled name="property_usage" defaultValue="Industrial" style={{float: 'left', width:"10px", height: "16px",marginRight: "9px"}} />
                <label htmlFor="industrial" style={{fontSize: 11, whiteSpace: 'nowrap', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253', margin:0}}>Industrial <span style={{fontFamily:'Cairo, sans-serif', marginLeft:'5px' }}>صناعي</span></label>
              </div>
              <div className="form-group" style={{float: 'left', margin: '3px 2%', lineHeight: '1.8', display:'flex', flexDirection:'row', alignItems:'center' }}>
                <input type="radio" id="commercial" name="property_usage" defaultValue="Commercial" style={{float: 'left', width:"10px", height: "17px",marginRight: "9px"}} disabled />
                <label htmlFor="industrial" style={{fontSize: 11, whiteSpace: 'nowrap', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253', margin:'0'}}>Commercial<span style={{fontFamily:'Cairo, sans-serif', marginLeft:'5px' }}> تجاري</span></label>
              </div>
              <div className="form-group" style={{float: 'left', margin: '3px 2%', lineHeight: '1.8', display:'flex', flexDirection:'row', alignItems:'center' }}>
                <input type="radio" id="residential" name="property_usage" defaultValue="Residential" style={{float: 'left', width:"10px", height: "17px",marginRight: "9px"}} disabled />
                <label htmlFor="industrial" style={{fontSize: 11, whiteSpace: 'nowrap',float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253', margin:0}}>Residential<span style={{fontFamily:'Cairo, sans-serif', marginLeft:'5px' }}> سكني</span></label>
              </div>
              <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253', margin:0}}>استخدام العقار</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Plot No.</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '70%', float: 'left', lineHeight: 1,textIndent:'1rem', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253', height:'16px'}}>{data_object.plot}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>رقم الأرض</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Makani No.</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '71%', float: 'left', lineHeight: 1,textIndent:'1rem', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253', height:'16px'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>رقم مكاني</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '21%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Building Name</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '62%', float: 'left', lineHeight: 1, height: '16px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>اسم المبنى</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '16%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Property No.</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '69%', float: 'left', lineHeight: 1,textIndent:'1rem', height:'16px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>{data_object.property_number}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>رقم العقار</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '21%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Property Type</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '62%', float: 'left', lineHeight: 1,textIndent:'1rem', height:'16px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>نوع الوحدة</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '27%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Property Area (s.m)</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '44%', float: 'left', lineHeight: 1,textIndent:'1rem', height:'16px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>{data_object.area_size}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '29%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>مساحة العقار (متر.مربع)</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Location</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '72%', float: 'left', lineHeight: 1,textIndent:'1rem', height:'16px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>{data_object.location}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '13%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>الموقع</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '27%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Premises No. (DEWA)</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '52%', float: 'left', lineHeight: 1,textIndent:'1rem', height:'16px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>{data_object.dewa}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '20%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>رقم المبنى (ديوا)</p>
              </div>
            </td>
          </tr>
        </tbody></table>
      <table style={{marginTop: 5, border: '1px solid #eff2f5'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Contract Information</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}> معلومات العقد</th>
          </tr></thead>
        <tbody>
          <tr>
            <td colSpan={12} style={{padding: '10px 15px 0'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '24%', float: 'left', lineHeight: '1.8', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Contract Period</p>
                <div style={{width: '65%', float: 'left'}}>
                  <p style={{borderBottom: '1px dashed #4f647e', width: '45%', float: 'left', lineHeight: 1,textIndent:'1rem' , fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>
                  {
                    data_object.start_date == null ||  data_object.start_date  =='' ||  data_object.start_date  == "0000-00-00"|| data_object.start_date == "0000-00-00 00:00:00"? 
                    ''
                    :
                    <>
                    <Moment format="MMMM DD, YYYY" style={{fontSize:'11px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>{ data_object.start_date }</Moment>
                    </>
                    }
                  </p>
                  <p style={{borderBottom: '1px dashed #4f647e', width: '45%', float: 'right', lineHeight: 1,textIndent:'1rem'}}>    {
                    data_object.end_date == null ||  data_object.end_date  =='' ||  data_object.end_date  == "0000-00-00"|| data_object.end_date == "0000-00-00 00:00:00"? 
                    ''
                    :
                    <>
                    <Moment format="MMMM DD, YYYY" style={{fontSize:'11px', fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>{ data_object.end_date }</Moment>
                    </>
                    }</p>
                </div>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '10%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>الموقع</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '24%', float: 'left', lineHeight: '1.8', color:'#2d4253'}}>Contract Value</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '60%', float: 'left', lineHeight: 1, height:'16px'}}>&nbsp;</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>قيمة العقد</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} style={{padding: '0 15px'}}>
              <div style={{width: '100%', marginTop:'-10px'}}><p style={{fontSize: 8, width: '48%', margin: '-5px 0 0 4%', float: 'left', textAlign: 'center', color:'#2d4253'}}>From من</p></div>
              <div style={{width: '100%', marginTop:'-10px'}}><p style={{fontSize: 8, width: '10%', margin: '-5px 0 0 5%', float: 'left', textAlign: 'center', color:'#2d4253'}}>To إلى</p></div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '45%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '22%', float: 'left', lineHeight: '1.8', color:'#2d4253'}}>Annual Rent</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '59%', float: 'left', lineHeight: 1,textIndent:'1rem', color:'#2d4253'}}>{data_object.rental_price? data_object.rental_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':""}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '19%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>الايجار السنوي</p>
              </div>
              <div style={{width: '50%', float: 'right'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '40%', float: 'left', lineHeight: '1.8', color:'#2d4253'}}>Security Deposit Amount</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '44%', float: 'left', lineHeight: 1,textIndent:'1rem', color:'#2d4253'}}>{data_object.security_deposit?data_object.security_deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED':""}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '15%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>مبلغ التأمين</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12} style={{padding: '0 15px'}}>
              <div style={{width: '100%', float: 'left'}}>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', width: '14%', float: 'left', lineHeight: '1.8', color:'#2d4253'}}>Mode of Payment</p>
                <p style={{borderBottom: '1px dashed #4f647e', width: '78%', float: 'left', lineHeight: 1,textIndent:'1rem', color:'#2d4253'}}>{data_object.payment_type ==1?"Cheque":"Bank Transefer"}</p>
                <p style={{fontSize: 11, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif', width: '8%', float: 'right', textAlign: 'right', lineHeight: '1.8', color:'#2d4253'}}>طريقة الدفع</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{marginTop: 5, border: '1px solid #FFF', background: 'url("ejari-poa-background.png") no-repeat', backgroundSize: '30%', backgroundPosition: '50% 0.8rem'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Terms and Conditions</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>الأحكام و الشروط</th>
          </tr></thead>
        <tbody>
          <tr>
            <td colSpan={6}>
              <ol style={{paddingLeft: 15, marginTop: 2, marginBottom: 0}}>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', listStyle:'inside number'}}>The tenant has inspected the premises and agreed to lease the unit on its current condition.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', listStyle:'inside number'}}>Tenant undertakes to use the premises for designated purpose, tenant has no rights to transfer or relinquish the tenancy contract either with or to without counterpart to any without landlord written to third party in whole or in part unless it is legally permitted.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', listStyle:'inside number'}}>The tenant undertakes not to make any amendments, modifications or addendums to the premises subject of the contract without obtaining the landlord written approval. Tenant shall be liable for any damages or failure due to that.</li>
              </ol>
            </td>
            <td colSpan={6}>
              <ol style={{paddingRight: 15, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0}}>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', listStyle:'inside number', color:'#2d4253'}}>عاين المستأجر الوحدة موضوع الايجار ووافق على إستئجار العقار على حالته الحالية.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', listStyle:'inside number', color:'#2d4253'}}>يتعهد المستأجر باستعمال المأجور للغرض المخصص له، و لا يجوز للمستأجر تحويل أو التنازل عن عقد الايجار للغير بمقابل أو دون مقابل دون موافقة المالك خطيا، كما لا يجوز للمستأجر تأجير المأجور أو أي جزء منه من الباطن مالم يسمح بذلك قانونا</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', listStyle:'inside number', color:'#2d4253'}}>يتعهد المستأجر بعدم إجراء أي تعديلات أو إضافات على العقار موضوع العقد دون موافقة المالك الخطية، و يكون المستأجر مسؤولا عن أي أضرار أو نقص أو تلف يلحق بالعقار</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{marginTop: 10, border: '1px solid #FFF'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: 13, paddingLeft: 10, whiteSpace: 'nowrap'}}>Signatures</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>التوقيعات</th>
          </tr></thead>
        <tbody>
          <tr>
            <td colSpan={12}>
              <div style={{marginTop: 5, border: '1px solid #374f6c', width: '50%', float: 'left', display: "flex",alignItems: "center"}}>
                <p style={{width: '50%', float: 'left', fontSize: 11, marginTop: 40, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Tenant's Signature<span style={{fontFamily: 'Cairo, sans-serif', marginLeft:'5px'}}> توقيع المستأجر</span></p>
                <div style={{width: '45%', float: 'right', marginTop: 40, display: "flex",alignItems: "center", marginBottom:'1em', marginLeft:'auto'}}>
                  <p style={{float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Date</p>
                  <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 100, margin: '0 0 0 2px', lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', height:'16px', color:'#000'}}>&nbsp;</p>
                  <p style={{fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 11, lineHeight: 1, fontFamily: 'Cairo, sans-serif', color:'#2d4253'}}>التاريخ</p>
                </div>
              </div>
              <div style={{marginTop: 5, border: '1px solid #374f6c', width: '49%', float: 'right', display: "flex",alignItems: "center"}}>
                <p style={{width: '48%', float: 'left', fontSize: 11, marginTop: 40, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Lessor's Signature<span style={{fontFamily: 'Cairo, sans-serif', marginLeft:'5px', color:'#2d4253'}}> توقيع المؤجر</span></p>
                <div style={{width: '45%', float: 'right', marginTop: 40, display: "flex",alignItems: "center", marginBottom:'1em', marginLeft:'auto'}}>
                  <p style={{float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif', color:'#2d4253'}}>Date</p>
                  <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 100, margin: '0 0 0 2px', lineHeight: 1, color:'#000'}}>&nbsp;</p>
                  <p style={{fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 11, lineHeight: 1, color:'#2d4253'}}>التاريخ</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  {/* 2nd Page */}
    <div className="u-row-container" style={{height:'297mm', width:"210mm", border:"1px solid #151515", margin:"1rem auto", backgroundColor: "#FFF",padding:"5px 1.5rem"}}>
    <div className="u-row" style={{}}>
      <table width="100%" style={{background: 'url("ejari-poa-background-1.png") repeat-y', backgroundSize: '30%', backgroundPosition: '50% 3rem'}}>
        <tbody><tr style={{backgroundColor: '#FFF', paddingBottom: 5}}>
            <td text-align="left">
              <img src="Government_of_Dubai_logo.png" height="38px" />
              <img src="Dubai-Land-LOGO.png" height="45px" valign="center" style={{float: 'right'}} />
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <ol style={{paddingLeft: 5, marginTop: 2, marginBottom: 0, width: '50%', float: 'left'}} start={4}>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>The tenant shall be responsible for payment of all electricity, water, cooling and gas charges resulting of occupying leased unit unless other condition agreed in written.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>The tenant must pay the rent amount in the manner and dates agreed with the landlord.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>The tenant fully undertakes to comply with all the regulations and instructions related to the management of the property and the use of the premises and of common areas such (parking, swimming pools, gymnasium, etc…).</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>Tenancy contract parties declare all mentioned emails addresses and phone numbers are correct, all formal and legal notifications will be sent to those addresses in case of dispute between parties.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>The landlord undertakes to enable the tenant of the full use of the premises including its facilities (swimming pool, gym, parking lot, etc) and do the regular maintenance as intended unless other condition agreed in written, and not to do any act that would detract from the premises benefit.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>By signing this agreement from the first party, the “Landlord” hereby confirms and undertakes that he is the current owner of the property or his legal representative under legal power of attorney duly entitled by the competent authorities.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>Any disagreement or dispute may arise from execution or interpretation of this contract shall be settled by the Rental Dispute Center.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>This contract is subject to all provisions of Law No (26) of 2007 regulating the relation between landlords and tenants in the emirate of Dubai as amended, and as it will be changed or amended from time to time, as long with any related legislations and regulations applied in the emirate of Dubai.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>Any additional condition will not be considered in case it conflicts with law.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>In case of discrepancy occurs between Arabic and non Arabic texts with regards to the interpretation of this agreement or the scope of its application, the Arabic text shall prevail.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>The landlord undertakes to register this tenancy contract on EJARI affiliated to Dubai Land Department and provide with all required documents.</li>
              </ol>
              <ol style={{paddingRight: 5, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '50%', float: 'right'}} start={4}>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>يكون المستأجر مسؤولا عن سداد كافة فواتير الكهرباء و المياه و التبريد و الغاز المترتبة عن اشغاله المأجور مالم يتم الاتفاق على غير ذلك كتابيا</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>يتعهد المستأجر بسداد مبلغ الايجار المتفق عليه في هذا العقد في التواريخ و الطريقة المتفق عليها.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>يلتزم المستأجر التقيد التام بالانظمة و التعليمات المتعلقة باستخدام المأجور و المنافع المشتركة
                  (كمواقف السيارات، أحواض السباحة، النادي الصحي، الخ).</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>يقر أطراف التعاقد بصحة العناوين و أرقام الهواتف المذكورة أعلاه، و تكون تلك العناوين هي المعتمدة رسميا للإخطارات و الأعلانات القضائية في حال نشوء أي نزاع بين أطراف العقد</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>يتعهد المؤجر بتمكين المستأجر من الانتفاع التام بالعقار للغرض المؤجر لأجله و المرافق الخاصة به (حوض سباحة، نادي صحي، مواقف سيارات.... إلخ) كما يكون مسؤولا عن أعمال الصيانة مالم يتم الاتفاق على غير ذلك، و عدم التعرض له في منفعة العقار.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>يعتبر توقيع المؤجر على هذا العقد إقرار منه بأنه المالك الحالي للعقار أو الوكيل القانوني للمالك بموجب وكالة قانونية موثقة وفق الأصول لدى الجهات المختصة.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>أي خلاف أو نزاع قد ينشأ عن تنفيذ أو تفسير هذا العقد يعود البت فيه لمركز فض المنازعات الإيجارية</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>يخضع هذا العقد لكافة أحكام القانون رقم ( 26 ) لسنة 2007 بشأن تنظيم العلاقة بين مؤجري و مستأجري العقارات في إماراة دبي، و تعديلاته و أي تغيير أو تعديل يطرأ عليه من وقت لآخر، كما يخضع للتشريعات و اللوائح الأخرى ذات العلاقة النافذة في دبي.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 10, listStyle:'inside number', color:'#2d4253'}}>لا يعتد بأي شرط تم إضافته إلى هذا العقد في حال تعارضه مع القانون.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 15, listStyle:'inside number', color:'#2d4253'}}>في حال حدوث أي تعارض أو اختلاف في التفسير بين النص العربيو النص الأجنبي يعتمد النص العربي.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number', color:'#2d4253'}}>يتعهد المؤجر بتسجيل عقد الايجار في نظام إيجاري التابع لدائرة الأراضي و الأملاك و توفير كافة المستندات اللازمة لذلك.</li>
              </ol>
            </td>
          </tr>
        </tbody></table>
      <table style={{marginTop: 5, border: '1px solid #FFF'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Know your Rights</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>لمعرفة حقوق الأطراف</th>
          </tr></thead>
        <tbody>
          <tr>
            <td colSpan={12}>
              <ul style={{paddingLeft: 5, marginTop: 2, marginBottom: 0, width: '46%', float: 'left'}}>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>You may visit Rental Dispute Center website through www.dubailand.gov.ae in case of any rental dispute between parties.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>Law No 26 of 2007 regulating relationship between landlords and tenants.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>Law No 33 of 2008 amending law 26 of year 2007.</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>Law No 43 of 2013 determining rent increases for properties.</li>
              </ul>
              <ul style={{paddingRight: 5, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '46%', float: 'right'}}>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>يمكنكم زيارة موقع مركز فض المنازعات الإيجارية من خلال www.dubailand.gov.ae في حال نشوء أي نزاع إيجاري بين الأطراف.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>الإطلاع على قانون رقم 26 لسنة 2007 بشأن تنظيم العلاقة بين المؤجرين والمستأجرين.</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>. الإطلاع على قانون رقم 33 لسنة 2008 الخاص بتعديل بعض أحكام قانون 26 لعام 2007</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside disc'}}>الإطلاع على قانون رقم 43 لسنة 2013 بشأن تحديد زيادة بدل الإيجار.</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{marginTop: 5, border: '1px solid #FFF'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Attachments for Ejari Registration</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>مرفقات التسجيل في إيجاري</th>
          </tr></thead>
        <tbody>
          <tr>
            <td colSpan={12}>
              <ul style={{paddingLeft: 5, marginTop: 2, marginBottom: 0, width: '46%', float: 'left'}}>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number'}}>Original unified tenancy contract</li>
                <li style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number'}}>Original emirates ID of applicant</li>
              </ul>
              <ul style={{paddingRight: 5, textAlign: 'right', direction: 'rtl', marginTop: 2, marginBottom: 0, width: '46%', float: 'right'}}>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number'}}>نسخة أصلية عن عقد الايجار الموحد</li>
                <li style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, listStyle:'inside number'}}>الهوية الإماراتية الأصلية لمقدم الطلب</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{marginTop: 5, border: '1px solid #FFF'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', paddingLeft: 10, whiteSpace: 'nowrap'}}>Additional Terms</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>شروط إضافية</th>
          </tr></thead>
        <tbody style={{background: 'url("ejari-poa-background-1.png") repeat-y', backgroundSize: '30%', backgroundPosition: '50% 0'}}>
          <tr>
            <td colSpan={12}>
              <div style={{marginTop: 10}}>
                <p style={{float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>1</p>
                <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height:'16px'}}>&nbsp;</p>
                <p style={{float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>1</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <p style={{float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>2</p>
              <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height:'16px'}}>&nbsp;</p>
              <p style={{float: 'left', fontSize: 11, margin: 0, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>2</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>3</p>
              <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height:'16px'}}>&nbsp;</p>
              <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>3</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>4</p>
              <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height:'16px'}}>&nbsp;</p>
              <p style={{float: 'left', fontSize: 12, margin: '7px 0', lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0'}}>4</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <p style={{float: 'left', fontSize: 12, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0', margin: '7px 0'}}>5</p>
              <p style={{float: 'left', borderBottom: '1px dashed #4f647e', width: '94.5%', margin: '7px 0 7px 2px', lineHeight: 1, height:'16px'}}>&nbsp;</p>
              <p style={{float: 'left', fontSize: 12, lineHeight: 1, fontFamily: 'Helvetica, sans-serif', borderRadius: '50%', border: '1px solid #DDD', width: 18, height: 18, textAlign: 'center', padding: '3px 0 0', margin: '7px 0'}}>5</p>
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <p style={{fontSize: 8, fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1.8', marginBottom: 5, width: '48%', float: 'left'}}>Note : You may add addendum to this tenancy contract in case you have additional terms while it needs to be signed by all parties.</p>
              <p style={{fontSize: 8, fontFamily: '"Cairo", sans-serif', lineHeight: '1.8', marginBottom: 5, width: '48%', float: 'right', direction: 'rtl'}}>ملاحظة: يمكن إضافة ملحق إلى هذا العقد في حال وجود أي شروط إضافية، على أن يوقع من أطراف التعاقد.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{marginTop: 10, border: '1px solid #FFF'}} width="100%">
        <thead style={{backgroundColor: '#374f6c'}}>
          <tr><th colSpan={6} style={{color: '#FFF', lineHeight: 2, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: 13, paddingLeft: 10, whiteSpace: 'nowrap'}}>Signatures</th>
            <th colSpan={6} style={{color: '#FFF', lineHeight: 2, textAlign: 'right', paddingRight: 10, whiteSpace: 'nowrap', fontFamily: '"Cairo", sans-serif'}}>التوقيعات</th>
          </tr></thead>
        <tbody>
          <tr>
            <td colSpan={12}>
              <div style={{marginTop: 5, border: '1px solid #374f6c', width: '50%', float: 'left',display: "flex",alignItems: "center"}}>
                <p style={{width: '50%', float: 'left', fontSize: 9, marginTop: 50, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif'}}>Tenant's Signature<span style={{fontFamily: '"Cairo", sans-serif'}}> توقيع المستأجر</span></p>
                <div style={{width: '45%', float: 'right', marginTop: 50, marginBottom:'1em', display: "flex",alignItems: "center"}}>
                  <p style={{float: 'left', fontSize: 9, margin: 0, lineHeight: 1, fontFamily: 'Arial, Helvetica, sans-serif'}}>Date</p>
                  <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 120, margin: '0 0 0 2px', lineHeight: 1}}>&nbsp;</p>
                  <p style={{fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 9, lineHeight: 1}}>التاريخ</p>
                </div>
              </div>
              <div style={{marginTop: 5, border: '1px solid #374f6c', width: '49%', float: 'right',display: "flex",alignItems: "center"}}>
                <p style={{width: '48%', float: 'left', fontSize: 9, marginTop: 50, marginLeft: 5, fontFamily: 'Arial, Helvetica, sans-serif'}}>Lessor's Signature<span style={{fontFamily: '"Cairo", sans-serif'}}> توقيع المؤجر</span></p>
                <div style={{width: '49%', float: 'right', marginTop: 50, marginBottom:'1em', display: "flex",alignItems: "center"}}>
                  <p style={{float: 'left', fontSize: 9, margin: 0, lineHeight: 1, fontFamily: 'Aial, Helvetica, sans-serif'}}>Date</p>
                  <p style={{float: 'left', borderBottom: '1px dashed #4f647e', minWidth: 100, margin: '0 0 0 2px', lineHeight: 1}}>&nbsp;</p>
                  <p style={{fontFamily: '"Cairo", sans-serif', float: 'left', margin: 0, fontSize: 9, lineHeight: 1}}>التاريخ</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <div style={{marginTop: 5, border: '1px solid #374f6c', width: '100%', float: 'left', textAlign: 'center', padding:"5px 0px"}}>
                <p style={{float: 'left', fontSize: 9, textAlign: 'center', width: '100%', fontFamily: 'Aial, Helvetica, sans-serif', marginBottom:0}}>Tel: 8004488 Fax: +971 4 222 2251 P.O.Box 1166, Dabai, U.A.E. هاتف: ۸۰۰٤٤۸۸ فاكس: ٩٧١٤٢٢٢٢٢٥١+ ص.ب ۱۱٦٦ ، دبي ، الإمارات العربية المتحدة</p>
                <p style={{fontSize: 9, fontFamily: 'Aial, Helvetica, sans-serif', marginBottom:0}}>Website: www.dubailand.gov.ae :الموقع الإلكتروني  E-mail: support@dubailand.gov.ae    :بريد إلكتروني </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
 
      );
    }
  }
 
  export default DataComponent;