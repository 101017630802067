import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import 'antd/dist/reset.css';
import {Table,Breadcrumb,Badge,Drawer,Button,Space,Checkbox,Select,Row,Col,Input} from 'antd'
import { PlusCircleOutlined,CloseOutlined,EditOutlined,MailOutlined} from '@ant-design/icons';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';


class BroadCastEmail extends Component {
    constructor(props){
        super(props);
        this.state ={
            role_id:0,
            role_name:"",
            role:{},
            permissions:[],
            isLoading:false,
            users:[],
            roles:[],
            open_new_role:false,
            open_edit_role:false,
            rent_ref_nums:[],
            rent_first_ref_no:"",
            rent_second_ref_no:"",
            rent_third_ref_no:"",
            sale_ref_nums:[],
            sale_first_ref_no:"",
            sale_second_ref_no:"",
            sale_third_ref_no:"",
            email:""
            
        }
    }
    componentDidMount() {

        
    }



    onSearchFirstSaleRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ sale_ref_nums: [] })
            return
        }
        API.get("/get_sales_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ sale_ref_nums: response.data.ref_nums })
                }
            })
    }

    handleFirstSaleRefsno = (e) => {
        this.setState({ sale_first_ref_no: e})
    }

    onSearchSecondSaleRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ sale_ref_nums: [] })
            return
        }
        API.get("/get_sales_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ sale_ref_nums: response.data.ref_nums })
                }
            })
    }

    handleSecondSaleRefsno = (e) => {
        this.setState({ sale_second_ref_no: e})
    }

    onSearchThirdSaleRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ sale_ref_nums: [] })
            return
        }
        API.get("/get_sales_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ sale_ref_nums: response.data.ref_nums })
                }
            })
    }

    handleThirdSaleRefsno = (e) => {
        this.setState({ sale_third_ref_no: e})
    }

    onSearchFirstRentRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ rent_ref_nums: [] })
            return
        }
        API.get("/get_rent_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ rent_ref_nums: response.data.ref_nums })
                }
            })
    }

    handleFirstRentRefsno = (e) => {
        this.setState({ rent_first_ref_no: e})
    }

    onSearchSecondRentRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ rent_ref_nums: [] })
            return
        }
        API.get("/get_rent_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ rent_ref_nums: response.data.ref_nums })
                }
            })
    }

    handleSecondRentRefsno = (e) => {
        this.setState({ rent_second_ref_no: e})
    }

    onSearchThirdRentRefNo = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ rent_ref_nums: [] })
            return
        }
        API.get("/get_rent_ref_no/" + e)
            .then((response) => {
                if (response.data.success) {
                    this.setState({ rent_ref_nums: response.data.ref_nums })
                }
            })
    }

    handleThirdRentRefsno = (e) => {
        this.setState({ rent_third_ref_no: e})
    }

    handleEmail = (e) =>{
        this.setState({email:e.target.value})
    }
   
    handleSendEmail = () =>{
        if(
            this.state.email=="" || this.state.rent_first_ref_no=="" || this.state.rent_second_ref_no=="" ||this.state.rent_third_ref_no=="" || this.state.sale_first_ref_no=="" || this.state.sale_second_ref_no =="" || this.state.sale_third_ref_no==""){
            toast.error("Enter data")
            return
        }
        const data = {
            email:this.state.email,
            sale_first_ref_no:this.state.sale_first_ref_no,sale_second_ref_no:this.state.sale_second_ref_no,sale_third_ref_no:this.state.sale_third_ref_no,
            rent_first_ref_no:this.state.rent_first_ref_no,rent_second_ref_no:this.state.rent_second_ref_no,rent_third_ref_no:this.state.rent_third_ref_no
        }
        toast.success("Email sent")
        // this.setState({   rent_ref_nums:[],
        //     rent_first_ref_no:"",
        //     rent_second_ref_no:"",
        //     rent_third_ref_no:"",
        //     sale_ref_nums:[],
        //     sale_first_ref_no:"",
        //     sale_second_ref_no:"",
        //     sale_third_ref_no:"",
        //     email:""})
           
        API.post("/send_broadcast",data)
        .then((res) =>{
            // if(res.data.success){
               
            // }
        })
    }
  render() {

    
    return (
        <div>
            <ToastContainer />
        
            <div  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  


       </div>
       <div style={{
           padding: 24,
           minHeight: 380,
           background: "#fff",
         }}>
         <Row gutter={16}>
            <Col span={12} lg={12} md={12} sm={12} xs={24} className='d-flex flex-column gap-2 pt-2'>
                <h4>Sales listings</h4>
            <label>Listing 1</label>
            <Select
                                    style={{
                                        width: "100%",
                                    }}
                                 
                                    value={this.state.sale_first_ref_no}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleFirstSaleRefsno}
                                    onSearch={this.onSearchFirstSaleRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.sale_ref_nums}
                                />
       <label>Listing 2</label>
            <Select
                                    style={{
                                        width: "100%",
                                    }}
                                 
                                    value={this.state.sale_second_ref_no}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleSecondSaleRefsno}
                                    onSearch={this.onSearchSecondSaleRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.sale_ref_nums}
                                />
 <label>Listing 3</label>
            <Select
                                    style={{
                                        width: "100%",
                                    }}
                                 
                                    value={this.state.sale_third_ref_no}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleThirdSaleRefsno}
                                    onSearch={this.onSearchThirdSaleRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.sale_ref_nums}
                                />
            </Col>
            <Col span={12} lg={12} md={12} sm={12} xs={24} className='d-flex flex-column gap-2 pt-2'>
                <h4>Rental listings</h4>
            <label>Listing 1</label>
            <Select
                                    style={{
                                        width: "100%",
                                    }}
                                 
                                    value={this.state.rent_first_ref_no}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleFirstRentRefsno}
                                    onSearch={this.onSearchFirstRentRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.rent_ref_nums}
                                />
     <label>Listing 2</label>
            <Select
                                    style={{
                                        width: "100%",
                                    }}
                                 
                                    value={this.state.rent_second_ref_no}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleSecondRentRefsno}
                                    onSearch={this.onSearchSecondRentRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.rent_ref_nums}
                                />
      <label>Listing 3</label>
            <Select
                                    style={{
                                        width: "100%",
                                    }}
                                 
                                    value={this.state.rent_third_ref_no}
                                    showSearch
                                    allowClear
                                    placeholder="Select Refs no"
                                    maxTagCount='responsive'
                                    optionFilterProp="children"
                                    onChange={this.handleThirdRentRefsno}
                                    onSearch={this.onSearchThirdRentRefNo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.rent_ref_nums}
                                />
            </Col>
            </Row>
            <Row>
                <Col span={24} className='d-flex flex-column gap-2 pt-2'>
                    <label>Email address</label>
                <Input onChange={this.handleEmail} value={this.state.email} />
                </Col>
            </Row>
            <Row>
                <Col span={24} className='pt-2'>
            <Button type="primary" icon={<MailOutlined />} onClick={this.handleSendEmail}>Send mail</Button>
            </Col>
            </Row>
       </div>
       </div>
    )
  }
}

export default  BroadCastEmail;
