import React, { Component } from "react";

import { Table, Button, Modal, Select, Radio, DatePicker, Input, Typography, Checkbox, Drawer, Row, Col, Timeline, Avatar, Tag, Empty, Carousel, Image, Card, Tooltip } from "antd";
import { LinkOutlined, RiseOutlined, CaretRightOutlined, CaretLeftOutlined, SendOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Tabs } from "react-tabs";
import { toast } from "react-toastify";
// import ReactDragListView from "react-drag-listview";
import "react-tabs/style/react-tabs.css";
import parse from "html-react-parser";
import BootStrapCarousel from 'react-bootstrap/Carousel';
// Local
import API from "../../utils/API";
import { onShowSizeChange, itemRender } from "../paginationfunction";
// CSS
import "antd/dist/reset.css";
import "../../Pages/antdstyle.css";
import "react-toastify/dist/ReactToastify.css";
import { store } from '../../store';
import Moment from 'react-moment';
// import {forEach} from "react-bootstrap/ElementChildren";
import ApiCalendar from "react-google-calendar-api";
import { baseurlImg, liveUrl } from "../../components/BaseUrl";
const { TextArea } = Input;
const { Text } = Typography;
const config = {
  "clientId": '8974307049-e0ug8edud9gp4ahcnah13i5kk8opivnh.apps.googleusercontent.com',
  "apiKey": 'AIzaSyAbmWx3GOaQ_HU-lD4wViBmV4kKNeWdk0Y',
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config)

class Followups extends Component {

  constructor(props) {

    super(props);
    this.state = {
      showNote: true,
      teams: [],
      lead_list_details: {},
      selectedTeam: "",
      listing_id: "",
      record_id: "",
      lead_details: {},
      listing_details: {},
      all: [],
      leadsortedInfo: null,
      leadscolumnKey: "",
      leadsorder: "",
      vendorsortedInfo: null,
      vendorcolumnKey: "",
      vendororder: "",
      sendEvent: false,
      isLeadsFollowupLoading: false,
      leadsfollowups: [],
      isListingsFollowupLoading: false,
      listingsfollowups: [],
      isfollowupOpen: false,
      sListAgents: [],
      new_followup_date: "",
      new_followup_note: "",
      sAgentId: 0,
      add_new_followup: false,
      currentVendor: {},
      sAddFollowUp_DateAndTime: "",
      currentClient: {},
      isNewFollowup: false,
      notes: "",
      notes_client: "",
      property_images: [],

      // Add Followup's state
      sAddFollowUp_Value: 1,
      sAddFollowUp_Vendor: "",
      sAddFollowUp_Client: "",
      sAddFollowUp_DateTime: new Date(),
      sAddFollowUp_Notes: "",

      // Vendors Lists
      sListOfVendors: [],
      sListOfVendorsPagination: {},
      isLoadingVendors: false,

      // Clients Lists
      sListOfClients: [],
      sListOfClientsPagination: {},
      isLoadingClients: false,

      // Button's states
      sToday: true,
      sOverdue: false,
      sFuture: false,
      date_type: 1,

      // Modal states
      modalVisible: false,
      modalClientVisible: false,
      modalLoading: false,
      sSelectUpadateModalValue: 1,
      sSelectClientUpadateModalValue: 1,

      openLeadSnippet: false,
      openListingSnippet: false,

      sortDateOrder: 'ascend',
      showNote: false,

      isUpdateLoading: false

    };
  }

  fHandleOpenUpdateDrawer = () => {
    this.setState({ modalVisible: true })
  }
  fHandleOpenClientUpdateDrawer = () => {

    this.setState({ modalClientVisible: true })
  }

  // All Tables Coloumns represent here....

  columnsVendors = [
    // {
    //   title: "Ref no",
    //   dataIndex: "refno",
    //   render: (text, item) => (
    //     <>
    //       <Link to={'/'} >{item.reference}</Link>
    //     </>
    //   ),
    // },
    {
      title: "Owner", className: 'whitespacenormal',
      render: (text, item) => (
        <>
          <div type="link" onClick={() => this.openVendorNotesSection(text, item)} className="p-0 d-flex flex-column text-left" style={{ height: 'unset', cursor: 'pointer' }}>
            <span>{item.firstname + item.lastname}</span>
            <span>{item.mobile}</span>
          </div>
        </>
      ),
    },
    {
      title: "Property Details",
      className: "propdetails",
      render: (text, item) => (
        <>
          {/* <Link  to={'/'} className="">{ 
          item.development == null || item.project == null  || item.property == null ?
          ""
          :
            item.development + ", " +item.project+ ", " +item.property 

          }</Link> */}
          <p className="font-12 mb-0">
            {item.unit_no == null ? "" : item.unit_no + ", "}
            {item.property == null ? "" : item.property + ", "}
            {item.project == null ? "" : item.project + ", "}
            {item.development == null ? "" : item.development}
          </p>
        </>
      ),
    },
    {
      title: "Date",
      render: (text, item) => (
        <>
          <p className="mb-0 font-12"><Moment format="DD MMMM YYYY">{item.date}</Moment></p>
        </>
      ),
    },
    {
      title: "Note", className: 'whitespacenormal',
      render: (text, item) => (
        <>
          <p className="mb-0  font-12">{item.notes}</p>
        </>
      ),
    },
    {
      title: "",
      render: (text, item) => (
        <>
          <Button
            className="btn btn-link"
            type={"link"}
            onClick={() => this.openVendorNotesSection(text, item)}
          >
            UPDATE
          </Button>
        </>
      ),
    },
  ];

  columnsClients = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, item) => (
        <>
          <Text >{item.firstname + item.lastname}</Text>
        </>
      ),
    },
    {
      title: "Phone",
      render: (text, item) => (
        <>
          <p className="mb-0  font-12">{item.mobile}</p>
        </>
      ),
    },
    {
      title: "Type",
      render: (text, item) => (
        <>
          <p className="mb-0  font-12">{item.client_type == null ? "Buyer" : item.client_type}</p>
        </>
      ),
    },
    {
      title: "Date",
      render: (text, item) => (
        <>
          <p className="mb-0  font-12"><Moment format="DD MMMM YYYY">{item.date}</Moment></p>
        </>
      ),
    },
    {
      title: "Note", width: 200,
      render: (text, item) => (
        <>
          <p className="mb-0  font-12">{item.notes}</p>
        </>
      ),
    },
    {
      title: "",
      render: (text, item) => (
        <>
          <Button
            className="btn btn-link"
            type={"link"}
            // style={{ backgroundColor: "black" }}
            // onClick={this.fHandleOpenUpdateDrawer}
            onClick={() => this.openClientNotesSection(text, item)}
          >
            UPDATE
          </Button>
        </>
      ),
    },
  ];

  //////////////////////// COLOUMNS END ///////////////////////

  componentDidMount() {
    API.get("/all_agents")
      .then((response) => {
        if (response.data.success) {
          this.setState({ sListAgents: response.data.agents });
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err, "something went wrong!");
      });

    API.get("/teams")
      .then((response) => {

        if (response.data.success) {
          this.setState({ teams: response.data.teams })
        }
      }
      )

    // this.fListOf_Clients(1, 10);
    // this.fListOf_Vendors(1, 10);
    const page = 1
    const perPage = 10
    const data = { agent: this.state.sAgentId, date_type: this.state.date_type, selectedTeam: this.state.selectedTeam }
    this.fetchLeadsFollowups(page, perPage, data)
    this.fetchListingsFollowups(page, perPage, data)
  }


  fetchLeadsFollowups = (page, perPage, data) => {
    this.setState({ isLeadsFollowupLoading: true })
    API.post(`/leads_followups?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {


        await response.data.followups;


        this.setState({
          leadsfollowups: response.data.followups,
          isLeadsFollowupLoading: false
        })




      })


  }

  fetchListingsFollowups = (page, perPage, data) => {
    this.setState({ isListingsFollowupLoading: true })
    API.post(`/listings_followups?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {


        await response.data.followups;


        this.setState({
          listingsfollowups: response.data.followups,
          isListingsFollowupLoading: false
        })




      })


  }

  fListOf_Clients = (page, perPage, id) => {
    this.setState({ isLoadingClients: true });
    const userdata = store.getState((state) => state);
    const role = userdata.auth.user.role;
    const user_id = userdata.auth.user.id

    API.get(
      `/followups-client/${role == 1 ?
        id ? id : this.state.sAgentId
        :
        user_id
      }?page=${page}&perPage=${perPage}`
    )
      .then((response) => {
        if (response.data.success) {
          this.setState({
            sListOfClients: response.data.client,
            sListOfClientsPagination: response.data.client,
            isLoadingClients: false,
          });
        } else {
          toast.error(response.message);
          this.setState({
            isLoadingClients: false,
          });
        }
      })
      .catch((err) => {
        toast.error(err, "something went wrong");
        this.setState({
          isLoadingClients: false,
        });
      });
  };

  fListOf_Vendors = (page, perPage, id) => {
    this.setState({ isLoadingVendors: true });
    const userdata = store.getState((state) => state);
    const role = userdata.auth.user.role;
    const user_id = userdata.auth.user.id;
    API.get(
      `/followups-vendor/${id ? id : this.state.sAgentId
      // role ==1 ? 0 : user_id
      }?page=${page}&perPage=${perPage}`
    )
      .then((response) => {
        if (response.data.success) {
          this.setState({
            sListOfVendors: response.data.vendor,
            sListOfVendorsPagination: response.data.vendor,
            isLoadingVendors: false,
          });
        } else {
          toast.error(response.message);
          this.setState({
            isLoadingVendors: false,
          });
        }
      })
      .catch((err) => {
        toast.error(err, "something went wrong");
        this.setState({
          isLoadingVendors: false,
        });
      });
  };

  handleTableChange_Vendors = (xPagination) => {
    this.fListOf_Vendors(xPagination.current, xPagination.pageSize);
  };

  handleTableChange_Clients = (xPagination) => {
    this.fListOf_Clients(xPagination.current, xPagination.pageSize);
  };

  handleFollowupModalOpen = () => {
    this.setState({
      followup_date: "",
      followup_note: "",
      selected_agent: "",
      followup_for: "",
      reminder: "",
      isNewFollowup: true
    });
    if (this.state.sendEvent == false) {
      setTimeout(this.signIn(), 5000)
    }
  };
  createEvent = (viewing_state_data, startingDate, type) => {

    const [date, time] = startingDate.split(' ');
    const [year, month, day] = date.split('-');
    const [hours, minutes] = time.split(':');
    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
    const dateObject = new Date(isoString);
    const startDateInIsoFormat = dateObject.toISOString();

    const starting_date = new Date(startDateInIsoFormat);
    const newStartingDate = new Date(starting_date.getTime() - (4 * 60 * 60 * 1000));
    const startingDateInUtc = newStartingDate.toISOString();
    const endDate = new Date(startingDateInUtc);
    endDate.setHours(endDate.getHours() + 1);
    const endDateInIsoFormat = endDate.toISOString();
    let eventData = {};
    if (type == "viewing") {
      eventData = {
        summary: "Leads Viewing Event",
        description: this.generateEventDescription(viewing_state_data),
        start: {
          dateTime: startingDateInUtc,
          timeZone: "Asia/Dubai",
        },
        end: {
          dateTime: endDateInIsoFormat,
          timeZone: "Asia/Dubai",
        },
        attendees: this.agentEmails(viewing_state_data),
        sendUpdates: "all"
      };
    } else {
      eventData = {
        summary: "Leads Followups Event",
        description: this.generateFollowUpsEventDescription(viewing_state_data),
        start: {
          dateTime: startingDateInUtc,
          timeZone: "Asia/Dubai",
        },
        end: {
          dateTime: endDateInIsoFormat,
          timeZone: "Asia/Dubai",
        },
        // attendees: this.agentEmails(viewing_state_data),
        // sendUpdates: "all"
      };
    }

    try {
      apiCalendar
        .createEvent(eventData)
        .then((response) => {
          this.setState({ sendEvent: true })
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  signIn = () => {

    apiCalendar.handleAuthClick();
  }

  openVendorNotesSection = (text, item) => {
    this.setState({ currentVendor: text })
    this.setState({ sSelectUpadateModalValue: 1 })
    this.setState({ modalVisible: true })
  }

  openClientNotesSection = (text, item) => {

    this.setState({ currentClient: text })
    this.setState({ sSelectClientUpadateModalValue: 1 })
    this.setState({ modalClientVisible: true, showNote: true })
  }

  addNotes = (e) => {
    this.setState({ notes: e.target.value })
  }

  addNotesClient = (e) => {
    this.setState({ notes_client: e.target.value })
  }

  onChangeAddNewFollowup = (e) => {
    this.setState({ add_new_followup: e.target.checked })
  }

  handleNewFollowupDate = (data, dateString) => {
    this.setState({ new_followup_date: dateString })
  }

  handleNewFollowupNote = (e) => {
    this.setState({ new_followup_note: e.target.value })
  }

  validate() {
    let note = this.state.notes;
    let new_followup_date = this.state.new_followup_date;
    let add_new_followup = this.state.add_new_followup;
    let new_followup_note = this.state.new_followup_note;
    let validationerrors = {};
    let isValid = true;


    if (note == null || note == "") {
      isValid = false;
      validationerrors["note"] = "Note is required.";
    }

    if (add_new_followup == true) {
      if (new_followup_date == null || new_followup_date == "") {
        isValid = false;
        validationerrors["date"] = "Date is required.";
      }
      if (new_followup_note == null || new_followup_note == "") {
        isValid = false;
        validationerrors["new_followup_note"] = "New Followup Note is required.";
      }
    }

    this.setState({
      validationerrors: validationerrors
    });

    return isValid;
  }

  addFollowupValidation() {
    let note = this.state.sAddFollowUp_Notes;
    let followup_date = this.state.sAddFollowUp_DateAndTime;
    let vendor = this.state.sAddFollowUp_Vendor;
    let validationerrors = {};
    let isValid = true;


    if (note == null || note == "") {
      isValid = false;
      validationerrors["note"] = "Note is required.";
    }

    if (followup_date == null || followup_date == "") {
      isValid = false;
      validationerrors["date"] = "Date is required.";
    }

    if (vendor == null || vendor == "") {
      isValid = false;
      validationerrors["vendor"] = "Required field.";
    }


    this.setState({
      newFollowupValidationerrors: validationerrors
    });

    return isValid;
  }

  clientValidate() {
    let client_note = this.state.notes_client;
    let validationerrors = {};
    let isValid = true;

    if (client_note == null || client_note == "") {
      isValid = false;
      validationerrors["client_note"] = "Note is required.";
    }

    this.setState({
      validationerrors: validationerrors
    });

    return isValid;
  }

  addVendorNote = () => {
    let data = {
      note_type: (this.state.sSelectUpadateModalValue == 1) ? "followup_p" : "followup_n",
      listing_id: this.state.currentVendor.listing_id,
      agent_id: this.state.currentVendor.user_id,
      note: this.state.notes,
      followup_id: this.state.currentVendor.id,
      page: 1,
      perPage: 10
    }
    if (this.state.add_new_followup == true) {
      data.new_followup = true;
      data.new_followup_data = {
        leads_id: 0,
        listing_id: this.state.currentVendor.listing_id,
        contacts_id: this.state.currentVendor.contacts_id,
        followup_date: this.state.new_followup_date,
        followup_note: this.state.new_followup_note,
        type: 1,
      }
    }

    this.setState({ isLoadingVendors: true });
    API.post("/followups-vendor-notes", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            sListOfVendors: response.data.vendor,
            sListOfVendorsPagination: response.data.vendor,
            isLoadingVendors: false,
          });
        }
      })
  }

  // addNewFollowup = () => {
  //       const data = {
  //     leads_id: 0,
  //     listing_id: this.state.currentVendor.listing_id,
  //     contacts_id: this.state.currentVendor.contacts_id,
  //     followup_date: this.state.new_followup_date,
  //     followup_note: this.state.new_followup_note,
  //     type: 1
  //   }
  //   API.post("/add_followup_details", data)
  //       .then((response) => {
  //         if (response.data.success) {
  //           this.fListOf_Vendors(1, 10);
  //           // this.fetchFollowUpDetails(this.state.record_id)
  //         }
  //
  //
  //       })
  // }

  addClientNote = () => {
    const data = {
      note_key: this.state.sSelectClientUpadateModalValue,
      note_type: (this.state.sSelectClientUpadateModalValue == 1) ? "followup_p" : "followup_n",
      lead_id: this.state.currentClient.lead_id,
      agent_id: this.state.currentClient.user_id,
      note: this.state.notes_client,
      followup_id: this.state.currentClient.id,
      page: 1,
      perPage: 10
    }


    this.setState({ isLoadingClients: true });
    API.post("/followups-client-notes", data)
      .then((response) => {
        if (response.data.success) {

          this.setState({
            sListOfClients: response.data.client,
            sListOfClientsPagination: response.data.client,
            isLoadingClients: false,
          });
        }
      })
  }

  fHandleModalClosed = () => {
    if (this.validate()) {
      this.addVendorNote()
      this.setState({ currentVendor: {} })
      this.setState({ sSelectUpadateModalValue: 1 })
      this.setState({
        isfollowupOpen: false,
        modalVisible: false
      });
    }
  };

  addNewFollowup = () => {
    if (this.addFollowupValidation()) {

      // if (this.validate()) {
      //   this.addVendorNote()
      //   this.setState({sSelectUpadateModalValue: 1})
      this.setState({
        isNewFollowup: false,
      });
      // }
    }
  };

  cancelNewFollowup = () => {
    this.setState({ isNewFollowup: false })
  };

  fHandleModalCancel = () => {
    this.setState({ currentVendor: {} })
    this.setState({ sSelectUpadateModalValue: 1 })
    this.setState({
      isfollowupOpen: false,
      modalVisible: false
    });
  };

  fHandleClientModalClosed = () => {
    this.setState({ showCompleteAddFollowUp: false })
    this.setState({ isUpdateLoading: true })
    if (this.state.sSelectClientUpadateModalValue == 3) {

      this.addClientNote()
      this.setState({ currentClient: {} })
      this.setState({ sSelectClientUpadateModalValue: 1 })
      this.setState({
        isfollowupOpen: false,
        modalClientVisible: false
      });
      this.setState({ isUpdateLoading: false })
    } else {
      if (this.clientValidate()) {
        this.addClientNote()
        this.setState({ currentClient: {} })
        this.setState({ sSelectClientUpadateModalValue: 1 })
        this.setState({
          isfollowupOpen: false,
          modalClientVisible: false
        });

      }
      this.setState({ isUpdateLoading: false })
    }


  };

  fHandleClientModalCancel = () => {
    this.setState({ currentClient: {} })
    this.setState({ isUpdateLoading: false })
    this.setState({ sSelectClientUpadateModalValue: 1 })
    this.setState({
      isfollowupOpen: false,
      modalClientVisible: false
    });
    this.setState({ showCompleteAddFollowUp: false })
  };

  fOnChangeFollowUpValue = (e) => {
    this.setState({ sAddFollowUp_Value: e.target.value });
  };

  onChangeVendor = (e) => {
    this.setState({ sAddFollowUp_Vendor: e })
  }

  onSearchClient = (e) => {

    API.get("/get_clients/" + e)
      .then((response) => {

        if (response.data.success) {
          this.setState({ clientList: response.data.contacts })
        }
      })
  }

  handleFollowupDate = (data, dateString) => {
    this.setState({ sAddFollowUp_DateAndTime: dateString });
  };

  fOnSelectUpdateModalValue = (e) => {
    this.setState({ sSelectUpadateModalValue: e.target.value });
  };

  fOnSelectClientUpdateModalValue = (e) => {
    if (e.target.value == 3) {
      this.setState({ showNote: false })
      this.setState({ showCompleteAddFollowUp: false })
    } else if (e.target.value == 4) {
      this.setState({ showCompleteAddFollowUp: true })
      this.setState({ showNote: false })
    } else {
      this.setState({ showNote: true })
      this.setState({ showCompleteAddFollowUp: false })
    }
    this.setState({ sSelectClientUpadateModalValue: e.target.value });
  };

  fOnChangeAgent = (e) => {
    this.setState({ sAgentId: e });
    // this.fListOf_Clients(1, 10, e);
    // this.fListOf_Vendors(1, 10, e);
    const page = 1
    const perPage = 10
    const data = { agent: e, date_type: this.state.date_type, selectedTeam: this.state.selectedTeam }
    this.fetchLeadsFollowups(page, perPage, data)
    this.fetchListingsFollowups(page, perPage, data)
  };

  fOnClick_OverdueState = () => {
    // if (this.state.sOverdue == false) {
    this.setState({
      sOverdue: true,
      sToday: false,
      sFuture: false,
      date_type: 2
    });

    const page = 1
    const perPage = 10
    const data = { agent: this.state.sAgentId, date_type: 2, selectedTeam: this.state.selectedTeam }
    this.fetchLeadsFollowups(page, perPage, data)
    this.fetchListingsFollowups(page, perPage, data)
    // }
  };

  fOnClick_TodayState = () => {
    // if (this.state.sToday == false) {
    this.setState({
      sToday: true,
      sOverdue: false,
      sFuture: false,
      date_type: 1
    });
    const page = 1
    const perPage = 10
    const data = { agent: this.state.sAgentId, date_type: 1, selectedTeam: this.state.selectedTeam }
    this.fetchLeadsFollowups(page, perPage, data)
    this.fetchListingsFollowups(page, perPage, data)
    // }
  };

  fOnClick_FutureState = () => {
    // if (this.state.sFuture == false) {
    this.setState({
      sFuture: true,
      sToday: false,
      sOverdue: false,
      date_type: 3
    });
    const page = 1
    const perPage = 10
    const data = { agent: this.state.sAgentId, date_type: 3, selectedTeam: this.state.selectedTeam }
    this.fetchLeadsFollowups(page, perPage, data)
    this.fetchListingsFollowups(page, perPage, data)
    // }
  };

  fAddFollowUps = () => {

  };

  showListingSnippet = (listing_id, e) => {
    this.setState({ listing_id: listing_id })


    API.get("/listing_details/" + listing_id)
      .then((response) => {

        if (response.data.success) {
          this.setState({ openListingSnippet: true })
          this.setState({
            listing_details: response.data.listing_details, property_images: response.data.images, notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes, agent_name_details: response.data.listing_details.agent,
            agent_name_details: response.data.listing_details.agent_id, property_for_details: response.data.listing_details.property_for,
            international: response.data.listing_details.international, country: (response.data.listing_details.country) ? response.data.listing_details.country : 198,
            city: (response.data.listing_details.emirate) ? response.data.listing_details.emirate : 1,
            role: response.data.role.role,
            agent_id: response.data.role.id,
            pf_property: parseInt(response.data.listing_details.pf_property),
            pf_project: parseInt(response.data.listing_details.pf_project),
            marketing_agent: (response.data.listing_details.marketing_agent == "" || response.data.listing_details.marketing_agent == "") ? "" : response.data.listing_details.agent_id,
            title_details: response.data.listing_details.title,
            selected_facilities: response.data.selected_amenities,
            lifestyle_details: response.data.life_style_detail,
            curation_details: response.data.curation_details,
            referred_by: (response.data.listing_details.referred_by != "" && response.data.listing_details.referred_by != null && response.data.listing_details.referred_by != undefined) ? response.data.listing_details.referred_by : response.data.listing_details.agent_id,
            referred_to: response.data.listing_details.referred_to,
            is_referred: (response.data.listing_details.referred_to == null || response.data.listing_details.referred_to == "") ? false : true,
            selected_portals_id: response.data.selected_portals_id,
            selected_portals: response.data.selected_portals,
            // isSameValueCheck: this.isCheckSameValue(response.data.selected_portals),
            rera_permit_details: response.data.listing_details.rera_permit,
            rera_permit: response.data.listing_details.rera_permit,
            lat: response.data.listing_details.latitude, lng: response.data.listing_details.longitude,
            bua: response.data.listing_details.bua,
            plot_area: response.data.listing_details.plot_area,
            avail_d: response.data.listing_details.avail_date, property_category: response.data.listing_details.property_category,
            property_type_details: response.data.listing_details.property_category_id, completion_status: (response.data.listing_details.completed == null) ? 'Yes' : response.data.listing_details.completed,
            style_property_details: response.data.listing_details.style_property_details,
            video_link: response.data.listing_details.video_link, livetour_link: response.data.listing_details.livetour_link,
            meta_title: response.data.listing_details.meta_title,
            meta_description: response.data.listing_details.meta_desc,
            meta_keywords: response.data.listing_details.meta_keywords,
            unit_no_details: response.data.listing_details.unitno, lead_gen: response.data.listing_details.leadgen == "Yes" ? true : false,
            just_listed: response.data.listing_details.just_listed == 1 ? true : false,
            community_details: response.data.listing_details.development, subcommunity_details: response.data.listing_details.project,
            property_details: response.data.listing_details.property, bedroom_details: response.data.listing_details.bedrooms, bathroom_details: response.data.listing_details.bathrooms,
            price_details: response.data.listing_details.price, view_details: response.data.listing_details.view,
            vacant_details: response.data.listing_details.vacant_details,
            upgraded_details: response.data.listing_details.upgraded_details,
            furnished_details: response.data.listing_details.furnished,
            maids_room: response.data.listing_details.maids_room,
            // tags_detail:this.state.tags_detail,description_details:this.state.description_details,
            listing_status_details: response.data.listing_details.status,
            emirate_id: response.data.listing_details.emirate_id, form_a: response.data.listing_details.form_a,
            title_deed: response.data.listing_details.title_deed, exclusive: response.data.listing_details.exclusive,
            deed_expire: response.data.listing_details.deed_expire, owner_other_properties: response.data.owner_other_properties,
            document_notes: response.data.document_notes, documents: response.data.documents,
            description_details: response.data.listing_details.description,
            owner_id: response.data.listing_details.owners_id,
            owner_name: response.data.listing_details.owner,
            owner_email: response.data.listing_details.owner_email,
            owner_mobile: response.data.listing_details.owner_mobile, price_application: response.data.listing_details.poa == "Y" ? true : false,
            // Add By Farooq
            all: response.data.all,
            // Farooq
            loader: false
          })

        }
      })

  };
  onCloseListingSnippet = () => {
    this.setState({ openListingSnippet: false })
  };
  showLeadSnippet = (record_id, e) => {


    this.setState({ record_id: record_id })

    API.get("/get_lead_details/" + record_id)
      .then((response) => {



        if (response.data.success) {

          this.setState({ openLeadSnippet: true })
          this.setState({
            lead_details: response.data.lead_details,
            all: response.data.all,
            lead_list_details: response.data.lead_list_details

          });
        }
      })

  };
  onCloseLeadSnippet = () => {
    this.setState({ openLeadSnippet: false })
  };

  isValidHttpUrl = (string) => {
    try {
      const url = new URL(string);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (err) {
      return false;
    }
  };
  getCurrentTime = (date) => {
    if (
      date != null &&
      date != "" &&
      date != undefined &&
      date != "0000-00-00 00:00:00"
    ) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateParts = date.split(" ");
      const iso8601DateString = dateParts[0] + "T" + dateParts[1] + "Z";
      const utcDate = new Date(iso8601DateString);
      const regionalDate = new Date(
        utcDate.toLocaleString("en-US", { timeZone: userTimeZone })
      );
      return regionalDate;
    }
  };
  handleLeadLink = () => {

    window.open("/leads/" + this.state.record_id, "_blank");
  }

  handleListingLink = () => {

    window.open("/all_listings/" + this.state.listing_id, "_blank");
  }

  handleTeams = (e) => {
    this.setState({ selectedTeam: e })
    const page = 1
    const perPage = 10
    const data = { agent: this.state.sAgentId, date_type: this.state.date_type, selectedTeam: e }

    this.fetchLeadsFollowups(page, perPage, data)
    this.fetchListingsFollowups(page, perPage, data)

  }
  isHTML = (content) => {
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    return htmlRegex.test(content);
  }
  handleSplitReq = (string) => {
    if (string && string.includes("from")) {
      var splitarr = string.split("from");
      var splitarrtwo = splitarr[1].split("To");
      return (
        <>
          <div className="d-flex" style={{ marginTop: "10px" }}>
            <p className="font-12">
              <span className="mr-2 font-bold">{splitarr[0]}</span>
              <Tag>{splitarrtwo[0]}</Tag>
              <i class="bi bi-arrow-right mr-2"></i>
              <Tag className="updated">
                {splitarr[0].includes("Move in date") ? (
                  <Moment format="DD MMMM YYYY">{splitarrtwo[1]}</Moment>
                ) : (
                  splitarrtwo[1]
                )}
              </Tag>
            </p>
          </div>
        </>
      );
    }
    if (string.includes("changed To")) {
      var splitarr = string.split("changed To");
      return (
        <div className="d-flex adesc">
          <p className="font-12">
            <span className="mr-0 font-bold">{splitarr[0]}</span>
            <Tag>{splitarr[0]}</Tag>
            <i class="bi bi-arrow-right mr-2 "></i>
            <Tag>{splitarr[1]}</Tag>
          </p>
        </div>
      );
    }
    if (string.includes("Expired")) {
      var fromStatus = string.match(/(?<=from\s+).*?(?=\s+to)/gs);
      var toStatus = string.match(/(?<=to\s+).*?(?=\s+because)/gs);
      var splitarr = string.split("following:");
      if (splitarr && splitarr[1]) {
        return (
          <>
            <div className="d-flex flex-column w-100">
              <div className="adesc">
                <span
                  className="font-bold"
                  style={{ marginBottom: "-15px", display: "flex" }}
                >
                  Reason:
                </span>
                <p className="font-12 mt-0">{parse(splitarr[1])}</p>
              </div>
              <div className="d-flex adesc-stat mt-1">
                <Tag className="pstat">{fromStatus}</Tag>
                <i class="bi bi-arrow-right mr-2 "></i>
                <Tag className="pstat expired">{toStatus}</Tag>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="d-flex flex-column w-100">
              <div className="adesc">
                <p className="font-12 mt-0">{string}</p>
              </div>
              <div className="d-flex adesc-stat mt-1">
                <Tag className="pstat">New Inquiry</Tag>
                <i class="bi bi-arrow-right mr-2 "></i>
                <Tag className="pstat expired">Expired</Tag>
              </div>
            </div>
          </>
        );
      }
    }
  };
  getImageArray = (imageString) => {
    if (imageString == "" || imageString == null) {
      return [];
    } else {
      let images = imageString.split(",");
      return images;
    }
  }
  handleSplitViewing = (string, props) => {
    if (string.includes('Viewing booked')) {
      var splitarr = string.split("booked for");
      var splitarr2 = splitarr[1].split("on")
      var splitarr3 = splitarr2[1].split(" ")
      return (
        <div className='adesc-sched w-100' style={{ display: "flex", alignItems: "center" }}>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col span={24}>
              {props ? <div className="d-flex flex-row mb-2" style={{ gap: '1rem' }}>

                <p><Moment format='hh:mm A' className='font-bold font-14' style={{ whiteSpace: 'nowrap' }}>{splitarr2[1]}</Moment></p>
                <span>|</span>
                <p><Moment format='DD MMMM YYYY' style={{ whiteSpace: 'nowrap' }}>{splitarr3[2]}</Moment></p>
                <span>|</span>
                {props.status == "D" ? <p className='stat publishstat'>Draft</p>
                  : props.status == "UC" ? <p className='stat confirmedowner ml-0'>Confirmed Owner</p>
                    : props.status == "OM" ? <p className='stat offmarket ml-0'>Off-Market</p>
                      : props.status == "CTM" ? <p className='stat comingtomarket ml-0'>Coming to Market</p>
                        : props.status == "Y" ? <p className='stat published ml-0'>Published</p>
                          : props.status == "F" ? <p className='stat feeds ml-0'>Feeds</p>
                            : null
                }
              </div> : null}

            </Col>
            <Col span={12} className="mr-0 mb-0">
              <Carousel showThumbs={false} arrows style={{ width: '130px', height: "68px", overflow: 'hidden' }} effect="fade" {...props}>
                {props && props.property_images && this.getImageArray(props.property_images).map((image) => {
                  return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                })}
              </Carousel>
            </Col>



            <Col span={12}>
              {props ? <div className='lpdetails_text w-100'>

                <div className='mr-0 font-bold'>{splitarr2[0]}</div><div>{props.price && props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</div>
                <p>{props.unitno ? props.unitno + "," : null}{props.property_project ? props.property_project + "," : null}</p>
              </div> : null}
            </Col>
          </Row>



          {/* {props ?
                        <div className='lp_details w-100 m-0 pr-0' style={{border:0}}> */}
          {/* <Carousel arrows style={{width:'130px',height:"68px",  overflow:'hidden'}} effect="fade" {...props}>
                                {this.getImageArray(props.property_images).map((image) => {
                                    return <Image src={baseurlImg + "/" + image} style={{width:'100%'}} />
                                })}
                            </Carousel> */}
          {/* <div className='lpdetails_text w-100'>
                                {props.status=="D"? <p className='stat publishstat'>Draft</p>
                                :props.status=="UC"? <p className='stat confirmedowner'>Confirmed Owner</p>
                                :props.status=="OM"? <p className='stat offmarket'>Off-Market</p>
                                :props.status=="CTM"? <p className='stat comingtomarket'>Coming to Market</p>
                                :props.status=="Y"? <p className='stat published'>Published</p>
                                :props.status=="F"? <p className='stat feeds'>Feeds</p>
                                :null
                                }
                                <span className='mr-0 font-bold'>{splitarr2[0]}</span><i class="bi bi-dot"></i><span>{props.price && props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</span>
                                <p>{props.unitno?props.unitno+",":null}{props.property_project?props.property_project+",":null}</p>
                            </div> */}

          {/* </div>
                    :null} */}
        </div>
      )
    } else {
      return false
    }

  }

  getPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED";
  };

  handleEditViewing(view_id, e) {
    this.setState({ isViewViewingOpen: false });
    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, iseditviewingOpen: true
          })
        }

      })
  }

  handleRequestNewViewing = (data = null) => {
    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let role_name = userdata.auth.user.role_name;
    const user_id = userdata.auth.user.id

    this.setState({ isRequestviewingOpen: true })
    this.setState({ currentRequest: data })
    this.setState({ ref_def_nums: data.refno })
    this.setState({ viewing_ref_no: [data.refno] })
    this.setState({ viewing_agent: user_id })

    if (this.state.sendEvent == false) {
      setTimeout(this.signIn(), 5000)
    }
  }
  handleComplete = (followup_id, type, e) => {


    const data2 = { followup_id: followup_id }


    API.post("/followups_as_complete", data2)
      .then((res) => {
        if (res.data.success) {
          if (type == 1) {
            const page = 1
            const perPage = 10
            const data = { agent: this.state.sAgentId, date_type: this.state.date_type, selectedTeam: this.state.selectedTeam }
            this.fetchLeadsFollowups(page, perPage, data)
          } else {
            const page = 1
            const perPage = 10
            const data = { agent: this.state.sAgentId, date_type: this.state.date_type, selectedTeam: this.state.selectedTeam }
            this.fetchListingsFollowups(page, perPage, data)
          }

        }
      })
  }


  generateTimeOptions = () => {
    const options = [];
    const startTime = 6 * 60; // 6:00 AM
    const endTime = 22 * 60; // 10:00 PM

    for (let time = startTime; time <= endTime; time += 15) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      const formattedHours24 = hours.toString().padStart(2, '0');
      const formattedHours12 = ((hours % 12 === 0 ? 12 : hours % 12)).toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';

      const formattedTime = `${formattedHours24}:${formattedMinutes}`;
      const labelTime = `${formattedHours12}:${formattedMinutes} ${period}`;

      options.push({
        value: formattedTime,
        label: labelTime,
      });
    }

    return options;
  };
  handleFollowupDate = (momentObject, dateString) => {
    const combinedDateTime = dateString + ' ' + this.state.selectedTime;
    this.setState({
      selectedDate: dateString,
      followup_date: combinedDateTime,
    });
    console.log(combinedDateTime)

  }

  handleTimeChange = (value) => {
    const combinedDateTime = this.state.selectedDate + ' ' + value;
    this.setState({
      selectedTime: value,
      followup_date: combinedDateTime, // Set the date again to ensure it's up to date
    });
    console.log(combinedDateTime)
  }

  render() {
    const userdata = store.getState((state) => state);
    const role = userdata.auth.user.role;
    const user_id = userdata.auth.user.id

    let role_profile = userdata.auth.user.profile_photo;

    const price = this.state.lead_details.price


    let { leadsortedInfo, vendorsortedInfo } = this.state;
    leadsortedInfo = leadsortedInfo || {};
    vendorsortedInfo = vendorsortedInfo || {};


    const handleVendorTableChange = (pagination, filters, sorter) => {
      this.setState({
        vendorsortedInfo: sorter,
        vendorcolumnKey: sorter.order != false ? sorter.columnKey : "",
        vendororder: sorter.order != false ? sorter.order : "",
      });
      const data = {
        agent: this.state.sAgentId,
        date_type: this.state.date_type,
        vendorcolumnKey: sorter.order != false ? sorter.columnKey : "",
        vendororder: sorter.order != false ? sorter.order : "",
        selectedTeam: this.state.selectedTeam
      }
      this.fetchListingsFollowups(pagination.current, pagination.pageSize, data)

    }

    const handleClientTableChange = (pagination, filters, sorter) => {
      this.setState({
        leadsortedInfo: sorter,
        leadscolumnKey: sorter.order != false ? sorter.columnKey : "",
        leadsorder: sorter.order != false ? sorter.order : "",
      });
      // const page= 1
      // const perPage = 10
      const data = {
        agent: this.state.sAgentId,
        date_type: this.state.date_type,
        leadscolumnKey: sorter.order != false ? sorter.columnKey : "",
        leadsorder: sorter.order != false ? sorter.order : "",
        selectedTeam: this.state.selectedTeam
      }
      this.fetchLeadsFollowups(pagination.current, pagination.pageSize, data)

    }

    const leadsfollowupscolumns = [
      {
        title: "Image", width: 100,
        render: (text, item) => (
          <>
            <Avatar style={{ borderRadius: '0.75rem', borderRadius: '0.75rem', boxShadow: '0px 0px 5px lightgrey', background: '#FFF' }} size={80} icon={item.picture == null || item.picture == "" ? <i class="bi bi-person-bounding-box" style={{ opacity: '0.3' }}></i> : <img src={this.isValidHttpUrl(item.picture) ? item.picture : `data:image/png;base64,${item.picture}`} style={{ width: '100%', height: '100%', borderRadius: 0 }} />} />
          </>
        )
      },
      {
        title: "Name", width: 180,
        dataIndex: "name",
        render: (text, item) => (
          <>
            <Button onClick={this.showLeadSnippet.bind(this, item.lead_id)} className="folnamebtn">
              <Text className="align-self-start" style={{ cursor: 'pointer', fontFamily: 'Poppins-Bold, sans-serif', fontSize: 14 }}>{item.firstname + " " + item.lastname}</Text>
              <Text className="mb-0  font-12 align-self-start">{item.mobile}</Text>
              <Text className="mb-0  font-12 align-self-start">{item.client_type == null ? "Buyer" : item.client_type}</Text>
            </Button>
          </>
        ),
      },
      //   {
      //     title: "Phone",width:80,
      //     render: (text, item) => (
      //       <>
      //         <p className="mb-0  font-12">{item.mobile}</p>
      //       </>
      //     ),
      //   },
      //   {
      //     title: "Type",width:80,
      //     render: (text, item) => (
      //       <>
      //         <p className="mb-0  font-12">{item.client_type == null? "Buyer" : item.client_type}</p>
      //       </>
      //     ),
      //   },
      {
        title: "Date",
        width: 140,
        key: "lead_date",
        sorter: (a, b) => a.date - b.date,
        sortOrder: leadsortedInfo.columnKey === 'lead_date' && leadsortedInfo.order,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, item) => (
          <>
            <p className="mb-0  font-12"><Moment format="DD MMMM YYYY">{item.date}</Moment></p>
            <p className="mb-0  font-12"><Moment format="hh:mm A">{item.date}</Moment></p>
          </>
        ),
      },
      {
        title: 'Agent',
        render: (text, item) => (
          <div className='agentwrap'>
            <Avatar className='d-flex' size={30} style={{ backgroundColor: "#FFF", marginRight: "0", }}
              icon={<img src={item.profile_photo == null || item.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(item.profile_photo) ? item.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + item.profile_photo} style={{ width: "30px", height: "30px", borderRadius: "50%", }} />} />
            <p className="mb-0 font-12">{item.agent_name}</p>
          </div>
        )
      },
      {
        title: "Note", width: 250, className: 'fnotewrap', style: { whiteSpace: 'unset' },
        render: (text, item) => (
          <>
            <p className="mb-0  font-12">{item.notes}</p>
          </>
        ),
      },
      {
        title: "",
        render: (text, item) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              className="btn btn-primary mt-0 followupupdatebtn"
              type={"link"}
              // style={{ backgroundColor: "black" }}
              // onClick={this.fHandleOpenUpdateDrawer}
              onClick={() => this.openClientNotesSection(text, item)}

            >
              Update
            </Button>
            <Button

              type={"link"}
              // style={{ backgroundColor: "black" }}
              // onClick={this.fHandleOpenUpdateDrawer}
              onClick={() => this.handleComplete(text.id, 1, item)}

            >
              <CloseOutlined />
            </Button>

          </div>
        ),
      },
    ];

    const leadsfollowupscolumnsRes = [
      {
        title: 'cardColumn', className: 'namecolwrap',
        render: (text, item) => {

          return (<>

            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                    <Button
                      className="btn btn-primary mt-0 followupupdatebtn"
                      type={"link"}
                      // style={{ backgroundColor: "black" }}
                      // onClick={this.fHandleOpenUpdateDrawer}
                      onClick={() => this.openClientNotesSection(text, item)}

                    >
                      Update
                    </Button>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    <Button

                      type={"link"}
                      // style={{ backgroundColor: "black" }}
                      // onClick={this.fHandleOpenUpdateDrawer}
                      onClick={() => this.handleComplete(text.id, 1, item)}

                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                </div>
              </div>

              <Link className="btn-text listinginfo" onClick={this.showLeadSnippet.bind(this, item.lead_id)}>
                <div className='row alignCenter borderBottom pt-2 pb-2'>
                  <div className='col-6'>
                    <div className='BodyFirstRowLeft'>
                      <Avatar style={{ borderRadius: '0.75rem', borderRadius: '0.75rem', boxShadow: '0px 0px 5px lightgrey', background: '#FFF' }} size={80} icon={item.picture == null || item.picture == "" ? <i class="bi bi-person-bounding-box" style={{ opacity: '0.3' }}></i> : <img src={this.isValidHttpUrl(item.picture) ? item.picture : `data:image/png;base64,${item.picture}`} style={{ width: '100%', height: '100%', borderRadius: 0 }} />} />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='BodyFirstRowRight'>
                      <p className='mb-0'><Text className="align-self-start" style={{ cursor: 'pointer', fontFamily: 'Poppins-Bold, sans-serif', fontSize: 14 }}>{item.firstname + " " + item.lastname}</Text></p>
                    </div>
                    <div className='BodyFirstRowRight'>
                      <p className='mb-0'><Text className="mb-0  font-12 align-self-start">{item.mobile}</Text></p>
                    </div>
                    <div className='BodyFirstRowRight'>
                      <p className='mb-0'><Text className="mb-0  font-12 align-self-start">{item.client_type == null ? "Buyer" : item.client_type}</Text></p>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='row alignCenter pt-2 pb-2'>
                      <div className='col-6'>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Date:</p>
                          <p><Moment format="DD MMMM YYYY">{item.date}</Moment></p>

                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Time:</p>
                          <p><Moment format="hh:mm A">{item.date}</Moment></p>
                        </div>
                      </div>
                      <div className='col-6'>

                        <div className='d-flex gap-1 alignCenter BodyFirstRowRight'>
                          <Avatar className='d-flex' size={30} style={{ backgroundColor: "#FFF", marginRight: "0", }}
                            icon={<img src={item.profile_photo == null || item.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(item.profile_photo) ? item.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + item.profile_photo} style={{ width: "30px", height: "30px", borderRadius: "50%", }} />} />
                          <div className='agentwrap'>
                            <p className="mb-0 font-12">{item.agent_name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Note:</p>
                          <p>  <p className="mb-0  font-12" style={{ whiteSpace: 'unset' }}>{item.notes}</p></p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

            </div>

          </>)
        }

      }
    ];
    const listingsfollowupscolumns = [
      {
        title: "Image", width: 130,
        render: (text, item) => (
          <>
            {item.property_images && item.property_images.length > 0 ?
              <Carousel arrows style={{ width: '178px', height: '100px', overflow: 'hidden' }} effect="fade" {...props}>
                {item.property_images.split(',').map((image) => {
                  return <Image src={baseurlImg + "/" + image.trim()} style={{ width: '100%', height: '100px' }} />

                })}
              </Carousel>
              :
              <div className='noimageyet' style={{ width: '178px', height: 100 }}><img src={"/../images/noimageyet.png"} className='defimg mx-auto' width="40px" style={{ opacity: '0.6' }} /><span className='font-12'>Image pending</span></div>
            }
          </>
        )
      },
      {
        title: "Name", width: 180,
        dataIndex: "name",
        render: (text, item) => (
          <>
            <Button onClick={this.showListingSnippet.bind(this, item.listing_id)} className="folnamebtn" >
              <Text style={{ cursor: 'pointer', fontSize: 14 }} className="font-bold align-self-start" >{item.firstname + " " + item.lastname}</Text>
              <Text className="mb-0  font-12 align-self-start">{item.mobile}</Text>
              <Text className="mb-0  font-12 align-self-start">{item.client_type == null ? "Seller" : item.client_type}</Text>
            </Button>
          </>
        ),
      },
      //   {
      //     title: "Phone",width:80,
      //     render: (text, item) => (
      //       <>
      //         <p className="mb-0  font-12">{item.mobile}</p>
      //       </>
      //     ),
      //   },
      //   {
      //     title: "Type",width:80,
      //     render: (text, item) => (
      //       <>
      //         <p className="mb-0  font-12">{item.client_type == null? "Buyer" : item.client_type}</p>
      //       </>
      //     ),
      //   },
      {
        title: "Date",
        width: 130,
        key: "vendor_date",
        sorter: (a, b) => a.date - b.date,
        sortOrder: vendorsortedInfo.columnKey === 'vendor_date' && vendorsortedInfo.order,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, item) => (
          <>
            <p className="mb-0  font-12"><Moment format="DD MMMM YYYY">{item.date}</Moment></p>
            <p className="mb-0  font-12"><Moment format="hh:mm A">{item.date}</Moment></p>
          </>
        ),
      },
      {
        title: 'Agent', width: 130,
        render: (text, item) => (
          <div className='agentwrap'>
            <Avatar className='d-flex' size={30} style={{ backgroundColor: "#FFF", marginRight: "0", }}
              icon={<img src={item.profile_photo == null || item.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(item.profile_photo) ? item.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + item.profile_photo} style={{ width: "30px", height: "30px", borderRadius: "50%", }} />} />
            <p className="mb-0 font-12 mt-1">{item.agent_name}</p>
          </div>
        )
      },
      {
        title: "Note", width: 250, className: 'fnotewrap', style: { whiteSpace: 'unset' },
        render: (text, item) => (
          <>
            <p className="mb-0  font-12" style={{ whiteSpace: 'unset' }}>{item.notes}</p>
          </>
        ),
      },
      {
        title: "",
        render: (text, item) => (
          // <>
          //   <Button
          //     className="btn btn-primary mt-0 followupupdatebtn"
          //   //   style={{ backgroundColor: "black" }}
          //     // onClick={this.fHandleOpenUpdateDrawer}
          //     onClick={() => this.openClientNotesSection(text, item)}

          //   > 
          //     Update
          //   </Button>
          // </>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              className="btn btn-primary mt-0 followupupdatebtn"
              type={"link"}
              // style={{ backgroundColor: "black" }}
              // onClick={this.fHandleOpenUpdateDrawer}
              onClick={() => this.openClientNotesSection(text, item)}

            >
              Update
            </Button>
            <Button

              type={"link"}
              // style={{ backgroundColor: "black" }}
              // onClick={this.fHandleOpenUpdateDrawer}
              onClick={() => this.handleComplete(text.id, 2, item)}

            >
              <CloseOutlined />
            </Button>

          </div>
        ),
      },
    ];


    const listingsfollowupscolumnsRes = [
      {
        title: 'cardColumn', className: 'namecolwrap',
        render: (text, item) => {

          return (<>

            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                    <Button
                      className="btn btn-primary mt-0 followupupdatebtn"
                      type={"link"}
                      // style={{ backgroundColor: "black" }}
                      // onClick={this.fHandleOpenUpdateDrawer}
                      onClick={() => this.openClientNotesSection(text, item)}

                    >
                      Update
                    </Button>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    <Button
                      type={"link"}
                      // style={{ backgroundColor: "black" }}
                      // onClick={this.fHandleOpenUpdateDrawer}
                      onClick={() => this.handleComplete(text.id, 2, item)}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                </div>
              </div>

              <Link className="btn-text listinginfo" onClick={this.showListingSnippet.bind(this, item.listing_id)}>
                <div className='row alignCenter borderBottom pt-2 pb-2'>
                  <div className='col-6'>
                    <div className='BodyFirstRowLeft'>

                      {item.property_images && item.property_images.length > 0 ?

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <BootStrapCarousel fade controls={true} indicators={false} style={{ width: '100%', height: 'auto' }}>
                            {item.property_images.split(',').map((image, index) => {
                              return (
                                <BootStrapCarousel.Item key={index} >
                                  {/* <Image src={baseurlImg + "/" + image.trim()} style={{ width: '100%', height: '100px' }} /> */}
                                  <img
                                    className="d-block w-100"
                                    src={image.includes('https://') ? image : `${baseurlImg}/${image}`}
                                    alt={`Slide ${index + 1}`}
                                    style={{ objectFit: 'cover', height: 'auto', maxHeight: '400px' }}
                                  />
                                </BootStrapCarousel.Item>
                              );
                            })}
                          </BootStrapCarousel>
                        </div>

                        :
                        <div className='noimageyet' style={{ width: window.isTabView ? '100%' : '178px', height: 100 }}>
                          <img src={"/../images/noimageyet.png"} className='defimg mx-auto' width="40px" style={{ opacity: '0.6' }} />
                          <span className='font-12'>Image pending</span>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='BodyFirstRowRight'>
                      <p className='mb-0'><Text style={{ cursor: 'pointer', fontSize: 14 }} className="font-bold align-self-start" >{item.firstname + " " + item.lastname}</Text></p>
                    </div>
                    <div className='BodyFirstRowRight'>
                      <p className='mb-0'><Text className="mb-0  font-12 align-self-start">{item.mobile}</Text></p>
                    </div>
                    <div className='BodyFirstRowRight'>
                      <p className='mb-0'><Text className="mb-0  font-12 align-self-start">{item.client_type == null ? "Seller" : item.client_type}</Text></p>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='row alignCenter pt-2 pb-2'>
                      <div className='col-6'>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Date:</p>
                          <p><Moment format="DD MMMM YYYY">{item.date}</Moment></p>

                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Time:</p>
                          <p><Moment format="hh:mm A">{item.date}</Moment></p>
                        </div>
                      </div>
                      <div className='col-6'>

                        <div className='d-flex gap-1 alignCenter BodyFirstRowRight'>
                          <Avatar className='d-flex' size={30} style={{ backgroundColor: "#FFF", marginRight: "0", }}
                            icon={<img src={item.profile_photo == null || item.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(item.profile_photo) ? item.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + item.profile_photo} style={{ width: "30px", height: "30px", borderRadius: "50%", }} />} />
                          <div className='agentwrap'>
                            <p className="mb-0 font-12 mt-1">{item.agent_name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Note:</p>
                          <p>  <p className="mb-0  font-12" style={{ whiteSpace: 'unset' }}>{item.notes}</p></p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

            </div>

          </>)
        }

      }
    ];


    const props = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <CaretRightOutlined />,
      prevArrow: <CaretLeftOutlined />
    };





    return (
      <>
        <Modal
          onCancel={this.cancelNewFollowup}
          onOk={this.addNewFollowup}
          open={this.state.isNewFollowup}
          title="Add Follow Up"
        >
          <div className="d-flex flex-column w-100 mt-4">
            <div className="form-group">
              <Radio.Group
                onChange={this.fOnChangeFollowUpValue}
                value={this.state.sAddFollowUp_Value}
                className="d-flex flex-row"
              >
                <Radio value={1}>Vendor</Radio>
                <Radio value={2}>Client</Radio>
              </Radio.Group>
            </div>

            <div className="form-group">
              {this.state.sAddFollowUp_Value == 1 && <label className="form-label">Search Vendor</label>}
              {this.state.sAddFollowUp_Value == 2 && <label className="form-label">Search Client</label>}
              <Select
                style={{
                  width: "100%",
                }}
                value={this.state.sAddFollowUp_Vendor}
                showSearch
                allowClear
                placeholder="Select Vendor"
                maxTagCount='responsive'
                optionFilterProp="children"
                onChange={this.onChangeVendor}
                onSearch={this.onSearchClient}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.clientList}
              />
              <div
                className="text-danger">{this.state.newFollowupValidationerrors && this.state.newFollowupValidationerrors.vendor}
              </div>
            </div>
            <div className="d-flex flex-row">
              <div class="form-group col-6 pl-0 mb-1">
                <label class="form-label">Follow up for</label>
                <Select style={{ height: '46px', lineHeight: '46px' }}
                  defaultValue='followup'
                  options={[{ value: 'followup', label: 'Follow Up' },
                  { value: 'call', label: 'Call' },
                  { value: 'email', label: 'Email' },
                  { value: 'viewing', label: 'Viewing' },
                  { value: 'offer', label: 'Offers' },
                  { value: 'whatsapp', label: 'WhatsApp' },]} />
              </div>
              <div class="form-group col-6 pr-0 mb-1">
                <label class="form-label">Agent</label>
                <Select style={{ height: '46px', lineHeight: '46px' }}
                  // defaultValue = [assigned agent] 
                  options={[{ value: '1', label: 'Mark Castley' },
                  { value: '2', label: 'Mark Richards' },
                  { value: '3', label: 'Simon Boden' },
                  { value: '4', label: 'Alex Eady' },
                  { value: '5', label: 'Jason Farr' },]} />
              </div>
            </div>
            {/*{this.state.sAddFollowUp_Value == 2 && <div className="form-group">*/}
            {/*  <label className="form-label">Search Client</label>{" "}*/}
            {/*  <input*/}
            {/*      placeholder="Search"*/}
            {/*      className="form-control"*/}
            {/*      value={this.state.sAddFollowUp_Client}*/}
            {/*  />*/}
            {/*</div>}*/}
            <div className="form-group">
              <label className="form-label">Date and Time</label>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                style={{ width: '100%', height: '36px', overflow: 'hidden' }}
                showTime={{ minuteStep: 5 }}
                onChange={this.handleFollowupDate}
              />
              <div
                className="text-danger">{this.state.newFollowupValidationerrors && this.state.newFollowupValidationerrors.date}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Note</label>
              <TextArea
                rows={4}
                onChange={(text) => {
                  this.setState({ sAddFollowUp_Notes: text.target.value });
                }}
              />
              <div
                className="text-danger">{this.state.newFollowupValidationerrors && this.state.newFollowupValidationerrors.note}
              </div>
            </div>
          </div>
        </Modal>
        <div className="contentwrap followups">
          <div
            className="d-flex flex-row justify-content-between py-3"
            style={{ backgroundColor: "#FAFAFA" }}
          >
            <div
              className={window.isIpadView ? "d-flex px-3" : 'd-flex flex-row px-3'}
              style={{
                gap: "0.6rem",
                width: "100%",
                display: "flex",
                alignItems: window.isIpadView ? '' : "center",
                justifyContent: "space-between",
                flexDirection: window.isIpadView ? 'column' : '',
              }}
            >
              {/* if management show agents list */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: window.isIpadView ? "" : "flex-end",
                }}
              >
                <Button
                  onClick={this.fOnClick_TodayState}
                  type={this.state.sToday && "primary"}
                  className="addnewlisting"
                >
                  Today
                </Button>
                <Button
                  onClick={this.fOnClick_OverdueState}
                  type={this.state.sOverdue && "primary"}
                  className="addnewlisting"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Over due
                </Button>
                <Button
                  onClick={this.fOnClick_FutureState}
                  type={this.state.sFuture && "primary"}
                  className="addnewlisting"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Future
                </Button>

              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >

                {role == 1 ?
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end", marginRight: '1rem'
                  }}>


                    <Select showSearch allowClear placeholder="Team" style={{ minWidth: window.isIpadView ? "" : 200 }}
                      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                      onChange={this.handleTeams}
                      options={this.state.teams.map((team, index) => (
                        { value: team.value, label: team.label }
                      ))} />

                  </div> : null}

                {role == 1 ? <Select className="form-control mr-3 p-0"
                  showSearch
                  allowClear
                  // value={role == 1? this.state.sAgentId : user_id}
                  placeholder="Select agent"
                  options={this.state.sListAgents}
                  optionFilterProp="children"
                  style={{
                    minWidth: window.isIpadView ? "" : "25rem",
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={this.fOnChangeAgent}
                  disabled={role == 1 ? false : true}
                /> : null}

                {/* <Button
                  type="primary"
                  className="addnewlisting"
                  icon={<CalendarOutlined />}
                  onClick={this.handleFollowupModalOpen}
                >
                  Add Follow Ups
                </Button> */}
              </div>
            </div>
          </div>
          <div className="row px-3 w-100 mt-3 mb-5">
            {/* <h2 className="font-bold px-4">
              {this.state.sOverdue
                ? "Overdue"
                : this.state.sToday
                ? "Today"
                : this.state.sFuture && "Future"}
            </h2> */}
            <div className='col-12  px-0'>
              <div className="row">
                {/** Vendor's Table */}
                <div className='col-12 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <h4 className="font-bold px-4">Vendors</h4>
                  <div className="table-responsive tablelistwrap pl-3 py-2">
                    {/* <Table
                      onChange={this.handleTableChange_Vendors}
                      // rowSelection={rowSelection}
                      loading={this.state.isLoadingVendors}
                      pagination={{hideOnSinglePage:true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        total:
                          this.state?.sListOfVendorsPagination?.total > 0 &&
                          this.state.sListOfVendorsPagination.total,
                        pageSize: this.state.sListOfVendorsPagination.per_page,
                        current: this.state.sListOfVendorsPagination.current_page,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={this.columnsVendors}
                      dataSource={
                        this.state?.sListOfVendors.data?.overdue
                          ? this.state?.sListOfVendors.data?.overdue
                          : []
                      }
                      rowKey={(record) => record.id}
                    /> */}
                    <Table
                      onChange={handleVendorTableChange}
                      className='tableleads px-2 tableLeadsResponsive' style={{ border: 'none !important' }}

                      loading={this.state.isListingsFollowupLoading ?
                        { indicator: <img tyle={{ width: '80px', height: '60px' }} src='images/opportunityloading.gif' /> } : false
                      }
                      pagination={{
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total:
                          this.state?.listingsfollowups?.total > 0 &&
                          this.state.listingsfollowups.total,
                        pageSize: this.state.listingsfollowups.per_page,
                        current: this.state.listingsfollowups.current_page,
                        showTotal: (total, range) =>
                          window.isMobile576 ? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={window.isTabView ? listingsfollowupscolumnsRes : listingsfollowupscolumns}
                      scroll={{ x: '800' }}
                      dataSource={
                        this.state?.listingsfollowups?.data
                          ? this.state?.listingsfollowups?.data
                          : []
                      }

                    />
                  </div>
                </div>

                {/** Client's Table */}

                <div className='col-12 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <h4 className="font-bold px-4">Clients</h4>
                  <div className="table-responsive tablelistwrap pl-3 py-2">
                    {/* <Table
                      onChange={this.handleTableChange_Clients}
                      // rowSelection={rowSelection}
                      loading={this.state.isLoadingClients}
                      pagination={{hideOnSinglePage:true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        total:
                          this.state?.sListOfClientsPagination?.total > 0 &&
                          this.state.sListOfClientsPagination.total,
                        pageSize: this.state.sListOfClientsPagination.per_page,
                        current: this.state.sListOfClientsPagination.current_page,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={this.columnsClients}
                      dataSource={
                        // this.state?.sListOfClients?.overdue
                        //   ? this.state?.sListOfClients?.overdue
                        //   : []
                        this.state?.sListOfClients.data?.overdue
                        ? this.state?.sListOfClients.data?.overdue
                        : []
                      }
                      rowKey={(record) => record.id}
                    /> */}
                    <Table
                      onChange={handleClientTableChange}
                      className='tableleads px-2 tableLeadsResponsive'
                      //   showHeader={false}
                      loading={this.state.isLeadsFollowupLoading ?
                        { indicator: <img tyle={{ width: '80px', height: '60px' }} src='images/opportunityloading.gif' /> } : false
                      }
                      pagination={{
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total:
                          this.state?.leadsfollowups?.total > 0 &&
                          this.state.leadsfollowups.total,
                        pageSize: this.state.leadsfollowups.per_page,
                        current: this.state.leadsfollowups.current_page,
                        showTotal: (total, range) =>
                          window.isMobile576 ? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={window.isTabView ? leadsfollowupscolumnsRes : leadsfollowupscolumns}
                      scroll={{ x: '1300' }}
                      dataSource={
                        this.state?.leadsfollowups?.data
                          ? this.state?.leadsfollowups?.data
                          : []
                      }

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** Modal For Update Vendor*/}
        <Modal
          onCancel={this.fHandleModalCancel}
          onOk={this.fHandleModalClosed}
          open={this.state.modalVisible}
          title="Updates dd"
        >
          <div className="d-flex flex-column w-100 mt-4">
            <div className="form-group">
              <Radio.Group
                onChange={this.fOnSelectUpdateModalValue}
                value={this.state.sSelectUpadateModalValue}
                className="d-flex flex-row"
              >
                <Radio value={1}>Add Call</Radio>
                <Radio value={2}>Add Note</Radio>
                <Radio value={3}>Mark as Complete</Radio>
                <Radio value={4}>Mark as complete and add another follow up</Radio>
              </Radio.Group>
            </div>
            <div className="form-group">
              <label className="form-label">{
                this.state.sSelectUpadateModalValue == 1 ?
                  "Add Call"
                  :
                  "Add Note"
              }</label>
              <TextArea
                rows={4}
                value={this.state.notes}
                onChange={this.addNotes}
                placeholder={
                  this.state.sSelectUpadateModalValue == 1 ?
                    "Add Call ..."
                    :
                    "Add Note ..."
                }
              />
              <div
                className="text-danger">{this.state.validationerrors && this.state.validationerrors.note}
              </div>
            </div>
            <div className="form-group">
              <Checkbox checked={this.state.add_new_followup} onChange={this.onChangeAddNewFollowup}>
                Add New Followup
              </Checkbox>
            </div>
            {this.state.add_new_followup && <div className="form-group">
              <label className="form-label">Date and Time</label>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                style={{ width: '100%' }}
                showTime
                onChange={this.handleNewFollowupDate} />
              <div
                className="text-danger">{this.state.validationerrors && this.state.validationerrors.date}
              </div>
            </div>}

            {this.state.add_new_followup && <div className="form-group">
              <label className="form-label">New Followup Note</label>
              <TextArea rows={4} onChange={this.handleNewFollowupNote} />
              <div
                className="text-danger">{this.state.validationerrors && this.state.validationerrors.new_followup_note}
              </div>
            </div>}
          </div>
        </Modal>

        {/** Modal For Update Client*/}
        <Modal width={680}
          onCancel={this.fHandleClientModalCancel}
          onOk={this.fHandleClientModalClosed}
          open={this.state.modalClientVisible}
          title={<p className="mb-0"><i className="bi bi-calendar-check mr-1"></i>Update follow up</p>}
        >
          <div className="d-flex flex-column w-100 mt-2">
            <div className="form-group">
              <Radio.Group optionType="button" buttonStyle="solid"
                onChange={this.fOnSelectClientUpdateModalValue}
                value={this.state.sSelectClientUpadateModalValue}
                className="d-flex flex-row"
              >
                <Tooltip title="Add Call">
                  <Radio value={1} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Add Call</Radio>
                </Tooltip>
                <Tooltip title="Add Note">
                  <Radio value={2} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Add Note</Radio>
                </Tooltip>
                <Tooltip title="Mark as Complete">
                  <Radio value={3} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Mark as Complete</Radio>
                </Tooltip>
                <Tooltip title="Mark as complete and add another follow up">
                  <Radio value={4} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Mark as complete and add another follow up</Radio>
                </Tooltip>
              </Radio.Group>
            </div>
            {this.state.showNote ?
              <div className="form-group">
                <label className="form-label">{
                  this.state.sSelectClientUpadateModalValue == 1 ?
                    "Add Call"
                    :
                    "Add Note"
                }</label>
                <TextArea
                  rows={4}
                  value={this.state.notes_client}
                  onChange={this.addNotesClient}
                  placeholder={
                    this.state.sSelectClientUpadateModalValue == 1 ?
                      "Add Call ..."
                      :
                      "Add Note ..."
                  }
                />
                <div
                  className="text-danger">{this.state.validationerrors && this.state.validationerrors.client_note}
                </div>
              </div>
              : null}
            {this.state.showCompleteAddFollowUp ?
              <Row spacing={6}>
                <Col span={24}>
                  <div className="form-group">
                    <label className="form-label">Subject</label>
                    <Input defaultValue={"Follow Up"} />
                  </div>
                </Col>
                {/* <Col span={24}>
                        <div className="form-group">
                            <label className="form-label">Vendor</label>
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                value={this.state.vendor}
                                showSearch
                                allowClear
                                placeholder="Select Vendor"
                                maxTagCount= 'responsive'
                                optionFilterProp="children"
                                onChange={this.onChangeVendor}
                                onSearch={this.onSearchClient}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.state.clientList}
                            />
                        </div>
                    </Col> */}
                <Col span={24}>
                  <div className="form-group">
                    <label className="form-label">Client:</label>
                    <span className='form-control font-14 disabled'>{this.state.owner_name? this.state.owner_name: '---'}</span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="form-group">
                    <label class="form-label">Follow up for</label>
                    <Radio.Group className="followupforgrp justify-content-start"
                      // onChange={this.onChangeFollowupFor}
                      // value={this.state.followup_for}
                      buttonStyle="solid" // This makes the segmented control look like buttons
                    >
                      {/* <Radio.Button value="">Select Follow up For</Radio.Button> */}
                      <Radio.Button value={1}>Follow Up</Radio.Button>
                      <Radio.Button value={2}>Call</Radio.Button>
                      <Radio.Button value={3}>Email</Radio.Button>
                      <Radio.Button value={4}>Viewing</Radio.Button>
                      <Radio.Button value={5}>Offer</Radio.Button>
                      <Radio.Button value={6}>Whatsapp</Radio.Button>
                    </Radio.Group>
                    {/* <div className="text-danger">{this.state.validationerrors.followup_for}</div> */}
                  </div>
                </Col>
                <Col span={12} xs={24}>
                  <div className='form-group pr-1'>
                    <label className="form-label">Date</label>
                    <DatePicker changeOnBlur={true}
                      // format="DD MMMM YYYY"
                      style={{ width: '100%', height: '36px', 'overflow': 'hidden' }}
                      // showTime={{ minuteStep:15}}
                      onChange={this.handleFollowupDate} />
                    {/* <div className="text-danger">{this.state.validationerrors.followup_date}</div> */}
                  </div>
                </Col>
                <Col span={12} xs={24}>
                  <div className='form-group pl-1'>
                    <label className="form-label">Time</label>
                    <Select style={{ width: '100%', height: '36px' }}
                      showSearch
                      placeholder="Select a time"
                      options={this.generateTimeOptions()}
                      onChange={this.handleTimeChange}
                      value={this.state.selectedTime}
                    />
                  </div>
                </Col>

              </Row>
              : null}
          </div>
        </Modal>

        {/* Drawer LISTING/VENDOR : Requirements | Timeline */}
        <Drawer width={800} placement="right" closable={window.isTabView ? true : false} onClose={this.onCloseListingSnippet} open={this.state.openListingSnippet}>
          <div className="d-flex flex-column">
            <div className={`d-flex ${window.isMobileView ? 'flex-column' : 'flex-row'} justify-content-between`}>
              <div className="d-flex flex-column">
                <h5 className="font-bold font-18 mb-1">{this.state.listing_details.owner}</h5>
                <p className="mb-1">{this.state.listing_details.owner_mobile}</p>
                <p className="mb-1">Owner</p>
                <p className="mb-1">{this.state.listing_details.property_title}, {this.state.listing_details.sub_community_title}, {this.state.listing_details.community_title}</p>
                {/* <p className="mb-1">05 November 2023 | 12:54 PM</p> */}
                <p className="mb-1">{this.state.listing_details.unitno} | {this.state.listing_details.refno}</p>

              </div>
              <div className="d-flex flex-column">

                <Button className="mt-0 d-flex flex-row align-items-center" onClick={this.handleListingLink}>Open Listing Details <LinkOutlined /></Button>
                <div className={`agentwrap ${window.isMobileView ? 'd-flex flex-row gap-10 py-2' : ''}`}>
                  <p className="font-semibold mb-0 mt-1">Agent: </p>
                  <div className="d-flex flex-row align-items-center">
                    <Avatar className='d-flex' size={30} style={{ backgroundColor: "#FFF", marginRight: "0", }}
                      icon={<img src={this.state.listing_details.profile_photo == null || this.state.listing_details.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(this.state.listing_details.profile_photo) ? this.state.listing_details.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + this.state.listing_details.profile_photo} style={{ width: "30px", height: "30px", borderRadius: "50%", }} />} />
                    <p className="mb-0 font-12 ml-2">{this.state.listing_details.agent_name}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column bordertop mt-2 pyt-2" style={{ borderTop: '2px solid #151515' }}>
              <Row>
                <Col span={24} className=' pt-1'>
                  <p className='font-bold font-16'>Property Details</p>
                </Col>
                <Col span={24} className='folpropdetails'>
                  <Row>
                    <Col span={12} lg={12} md={12} sm={12} xs={24}>
                      {this.state.property_images.length > 0 ?
                        <Carousel arrows style={{ width: '246px', height: '160px', overflow: 'hidden' }} effect="fade" {...props}>
                          {this.state.property_images.map((image) => {
                            return <Image src={baseurlImg + "/" + image.image} style={{ width: '100%' }} />

                          })}
                        </Carousel>
                        :
                        <div className='noimageyet' style={{ width: '100%' }}><img src={"/../images/noimageyet.png"} className='defimg mx-auto' width="80px" style={{ opacity: '0.6' }} /><span className='font-12'>Image pending</span></div>
                      }
                    </Col>
                    <Col span={12} lg={12} md={12} sm={12} xs={24} className="pl-3">
                      <Button onClick={this.handleListingLink} className="foldetbtn">
                        <h6>{this.state.listing_details.unitno ? this.state.listing_details.unitno : ''} {this.state.listing_details.property_title ? this.state.listing_details.property_title : ''}</h6>
                        <p className="mb-0">{this.state.listing_details.sub_community_title ? this.state.listing_details.sub_community_title : ''} {this.state.listing_details.community_title ? ", " + this.state.listing_details.community_title : ''}</p>
                        <p className="mb-0" style={{ textTransform: 'capitalize' }}>{this.state.listing_details.property_for ? this.state.listing_details.property_for : ''}</p>
                        <p style={{ textTransform: 'capitalize' }}>{this.state.listing_details.price ? "Price: " + this.state.listing_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ''}</p>
                        <p className="font-semibold">{this.state.listing_details.refno ? "Ref no: " + this.state.listing_details.refno : ''}</p>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column bordertop mt-2 pt-2" style={{ borderTop: '2px solid #151515' }}>
              <Row>
                <Col span={24} className='filteractivity pt-1'>
                  <p className='font-bold font-16'>Activity Log</p>
                </Col>
                <Col span={24} className='timelinelist'>
                  <Timeline>
                    {this.state.all.length > 0 ?
                      this.state.all.map((al) => {
                        return (<>
                          {al.note_type == "phone" || al.note_type == "whatsapp" ?
                            <Timeline.Item dot={
                              al.note_type == "phone" ? <i class="bi bi-telephone-fill"></i>
                                : al.note_type == "email" ? <i class="bi bi-envelope-fill"></i>
                                  : al.note_type == "whatsapp" ? <i class="bi bi-whatsapp"></i>
                                    : null
                            } className={"time" + al.note_type}>
                              <div className='d-flex flex-column'>
                                <div className='d-flex flex-row justify-content-between'>
                                  <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                    <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                    <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                      {al.note_type == "phone" ? " logged a call" : null}
                                      {al.note_type == "email" ? " sent an email" : null}
                                      {al.note_type == "whatsapp" ? "" : null}
                                      {al.note_type == "note" ?
                                        al.note && al.note.includes("Criteria") ? " used a custom match" : " added a note"
                                        : null}
                                    </p>
                                  </div>
                                  <div className='align-content-center'>
                                    <p className="atime">
                                      <Moment fromNow>
                                        {/*{this.getCurrentTime(al.time)}*/}
                                        {(al.time)}
                                      </Moment>
                                    </p>
                                  </div>
                                </div>
                                <div className='d-flex adesc'>
                                  {al.note && al.note.includes("Criteria") ?
                                    <>
                                      <p className='font-bold'>Matched using the following criteria:</p>
                                      <p>{al.note.split("<br>").slice(1).map((matchnote) => (
                                        <>
                                          <p>{matchnote}</p>
                                        </>
                                      ))
                                      }</p>
                                    </>
                                    : <>
                                      <p className='font-12'><strong className='mr-1 font-12'>Price:</strong>{al.confirm_price && al.confirm_price > 0 ? al.confirm_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : 0}</p>
                                      <p className='font-12'><strong className='mr-1 font-12'>Available:</strong>{al.isPropertyAvailable}</p>
                                      <p className='font-12'><strong className='mr-1 font-12'>Discuss with vendor:</strong>{al.call_log_discuss}</p>
                                      <p className='font-12'>{al.note}</p>
                                    </>}
                                </div>
                              </div>
                            </Timeline.Item>
                            : al.note_type == "note" ?
                              <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"time" + al.note_type}>
                                <div className='d-flex flex-column'>
                                  <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                      <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                      <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                        {al.note_type == "phone" ? " logged a call" : null}
                                        {al.note_type == "email" ? " sent an email" : null}
                                        {al.note_type == "whatsapp" ? "" : null}
                                        {al.note_type == "note" ?
                                          al.note && al.note.includes("Criteria") ? " used a custom match" : " added a note"
                                          : null}
                                      </p>
                                    </div>
                                    <div className='align-content-center'>
                                      <p className="atime">
                                        <Moment fromNow>
                                          {/*{this.getCurrentTime(al.time)}*/}
                                          {(al.time)}
                                        </Moment>
                                      </p>
                                    </div>
                                  </div>
                                  <div className='d-flex adesc'>
                                    {al.note && al.note.includes("Criteria") ?
                                      <>
                                        <p className='font-bold'>Matched using the following criteria:</p>
                                        <p>{al.note.split("<br>").slice(1).map((matchnote) => (
                                          <>
                                            <p>{matchnote}</p>
                                          </>
                                        ))
                                        }</p>
                                      </>
                                      : <>
                                        <p className='font-12'>{al.note}</p>
                                      </>}
                                  </div>
                                </div>
                              </Timeline.Item>
                              // viewing note
                              : al.note_type == "viewing" ?
                                <Timeline.Item dot={<i class="bi bi-eye-fill"></i>} className={"time" + al.note_type}>
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex flex-row justify-content-between'>
                                      <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                        <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                        <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                          {al.note_type == "viewing" ? " booked a viewing" : null}
                                        </p>
                                      </div>
                                      <div className='align-content-center'>
                                        <p className="atime">
                                          <Moment fromNow>
                                            {/*{this.getCurrentTime(al.time)}*/}
                                            {(al.time)}
                                          </Moment>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="adesc">
                                      <p className='font-12'><strong className='mr-1 font-12'>Date:</strong>{al.view_date ? al.view_date : "-"}</p>
                                      <p className='font-12'><strong className='mr-1 font-12'>Note:</strong>{al.note}</p>
                                    </div>
                                  </div>
                                </Timeline.Item>
                                // viewing note
                                : al.note_type == "offer" ?
                                  <Timeline.Item dot={<i class="bi bi-tag-fill"></i>} className={"time" + al.note_type}>
                                    <div className='d-flex flex-column'>
                                      <div className='d-flex flex-row justify-content-between'>
                                        <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                          <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                          <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                            {al.note && al.note.includes("Viewing status") ? <span> updated the viewing status</span>
                                              : al.note && al.note.includes("Offer status") ? <span> updated the offer status</span>
                                                : null}
                                          </p>
                                        </div>
                                        <div className='align-content-center'>
                                          <p className="atime">
                                            <Moment fromNow>{this.getCurrentTime(al.time)}</Moment>
                                          </p>
                                        </div>
                                      </div>
                                      <div className='d-flex adesc'>
                                        <p className='font-12'>
                                          {al.note}
                                        </p>
                                      </div>
                                      <div className="d-flex adesc-stat mt-1">
                                        {al.note && al.note.includes("Viewing status changed to Offer") ? <>
                                          <Tag className='pstat viewing'>Viewing</Tag>
                                          <i class="bi bi-arrow-right"></i>
                                          <Tag className='pstat offer'>Offer</Tag>
                                        </> : al.note && al.note.includes("Not Interested") ? <>
                                          <Tag className='pstat viewing'>Viewing</Tag>
                                          <i class="bi bi-arrow-right"></i>
                                          <Tag className='pstat cancelled'>Not Interested</Tag>
                                        </> : al.note && al.note.includes("Offer Sent") ? <>
                                          <Tag className='pstat offer'>Offer</Tag>
                                          <i class="bi bi-arrow-right"></i>
                                          <Tag className='pstat offer'>Offer Sent</Tag>
                                        </> : al.note && al.note.includes("Offer Accepted") ? <>
                                          <Tag className='pstat offer'>Offer Sent</Tag>
                                          <i class="bi bi-arrow-right"></i>
                                          <Tag className='pstat secondviewing'>Offer Accepted</Tag>
                                        </> : al.note && al.note.includes("Offer Rejected") ? <>
                                          <Tag className='pstat offer'>Offer</Tag>
                                          <i class="bi bi-arrow-right"></i>
                                          <Tag className='pstat scheduled'>Offer Rejected</Tag>
                                        </> : al.note && al.note.includes("Deal Signed") ? <>
                                          <Tag className='pstat offer'>Offer</Tag>
                                          <i class="bi bi-arrow-right"></i>
                                          <Tag className='pstat deal'>Deal Signed</Tag>
                                        </> : null}
                                      </div>
                                    </div>
                                  </Timeline.Item>
                                  : al.note_type == 'req_view' ?
                                    <Timeline.Item dot={<RiseOutlined />} className={"time" + al.note_type}>
                                      <div className='d-flex flex-column'>
                                        <div className='d-flex flex-row justify-content-between'>
                                          <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                            <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                            <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                              <span> requested to share this property with the following leads.</span></p>
                                          </div>
                                          <div className='align-content-center'>
                                            <p className="atime">
                                              <Moment fromNow>{this.getCurrentTime(al.time)}</Moment>
                                            </p>
                                          </div>
                                        </div>
                                        <div className='d-flex adesc'>
                                          <p className='font-12'>
                                            {al.leads_user.map((lead_id, index) => (
                                              <>
                                                <Tag><a key={index} href={liveUrl + "/leads/" + lead_id} target="_blank">
                                                  {lead_id}
                                                </a></Tag>
                                              </>
                                            ))}
                                          </p>
                                        </div>
                                      </div>
                                    </Timeline.Item>
                                    : al.note_type == 'followup' ?
                                      <Timeline.Item dot={<i class="bi bi-calendar"></i>} className={"time" + al.note_type}>
                                        <div className='d-flex flex-column'>
                                          <div className='d-flex flex-row justify-content-between'>
                                            <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                              <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                              <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                <span> scheduled a follow up</span></p>
                                            </div>
                                            <div className='align-content-center'>
                                              <p className="atime">
                                                <Moment fromNow>{this.getCurrentTime(al.time)}</Moment>
                                              </p>
                                            </div>
                                          </div>
                                          <div className='d-flex adesc'>
                                            <Row className='d-flex flex-row w-100'>
                                              <Col span={6}>
                                                <p className='font-10'>Date / Time :</p>
                                                <p><Moment format={"hh:mm A"} className='font-bold font-16'>{al.time}</Moment></p>
                                                <p><Moment format={"DD MMMM YYYY"}>{al.time}</Moment></p>
                                              </Col>
                                              <Col span={18}>
                                                <p>Note:</p>
                                                <p className='font-12'>{al.note}</p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      </Timeline.Item>
                                      : null}
                        </>)
                      })
                      :
                      <Empty description={<>
                        <p className='font-bold font-16 mb-1'>No Activity Found</p>
                        <p>It seems, no activity happened yet.</p>
                      </>} />
                    }
                  </Timeline>
                </Col>

              </Row>
            </div>
          </div>

        </Drawer>

        {/* Drawer LEADS/CLIENT: Requirements | Timeline */}
        <Drawer width={800} placement="right" onClose={this.onCloseLeadSnippet} closable={window.isTabView ? true : false} open={this.state.openLeadSnippet}>
          <div className="d-flex flex-column">
            <div className={`d-flex ${window.isMobileView ? 'flex-column' : 'flex-row'} justify-content-between`}>
              <div className="d-flex flex-column">
                <h5 className="font-bold font-18 mb-1">{this.state.lead_details.fullname}</h5>
                <p className="mb-1">{this.state.lead_details.mobile}</p>
                <p className="mb-1">{this.state.lead_details.contact_type == 1 ? "Tenant"
                  : this.state.lead_details.contact_type == 2 ? "Buyer"
                    : this.state.lead_details.contact_type == 3 ? "Landlord"
                      : this.state.lead_details.contact_type == 4 ? "Seller"
                        : this.state.lead_details.contact_type == 6 ? "Agent"
                          : this.state.lead_details.contact_type == 7 ? "Other"
                            : this.state.lead_details.contact_type == 8 ? "Portal"
                              : this.state.lead_details.contact_type == 9 ? "Buyer/Tenant"
                                : "Unrecognized"}</p>
                <p className="mb-1">{this.state.lead_details.inquiry_date}</p>
              </div>
              <div className="d-flex flex-row">
                <Button onClick={this.handleLeadLink} className="mt-0 d-flex flex-row align-items-center">Open Leads Details <LinkOutlined /></Button>
              </div>
            </div>
            <div className="d-flex flex-column bordertop mt-2 pt-2" style={{ borderTop: '2px solid #151515' }}>
              <p className="font-bold">Requirements</p>
              <Row>
                <Col span={12} lg={12} md={12} sm={12} xs={24}>
                  <Row className="d-flex flex-column">
                    {this.state.lead_details.pinned == 1 ? <Row className="d-flex flex-row w-100">
                      <Col span={9}><p className="d-flex flex-row"><i class="bi bi-pin-angle-fill mr-1" style={{ color: '#ff0000' }}></i> Pin</p></Col>

                    </Row> : null}

                    <Row className="d-flex flex-row w-100">
                      <Col span={9}><p>Bedrooms:</p></Col>
                      <Col span={15}><p className="font-bold">{this.state.lead_details.bedroom}</p></Col>
                    </Row>
                    <Row className="d-flex flex-row w-100">
                      <Col span={9}><p>Contact Type:</p></Col>
                      <Col span={15}><p className="font-bold">Buyer</p></Col>
                    </Row>
                    <Row className="d-flex flex-row w-100">
                      <Col span={9}><p>Community:</p></Col>
                      <Col span={15}><p className="font-bold">{this.state.lead_details.community}</p></Col>
                    </Row>
                    <Row className="d-flex flex-row w-100">
                      <Col span={9}><p>Sub Community:</p></Col>
                      <Col span={15}><p className="font-bold">{this.state.lead_details.subcommunity}</p></Col>
                    </Row>

                  </Row>
                </Col>
                <Col span={12} lg={12} md={12} sm={12} xs={24}>
                  <Row className="d-flex flex-column">
                    <Row className="d-flex flex-row w-100"><Col span={9}><p>Lead Stage:</p></Col><Col span={15}><p className="font-bold">
                      {this.state.lead_details.agents == 430 && this.state.lead_details.lead_stage == 12 ? <span style={{ width: 'fit-content !important' }} className="recruitment pstat status-styling">Pond</span> : <>
                        {this.state.lead_details.lead_stage == 1 ? <span style={{ width: 'fit-content !important' }} className="uncontacted pstat status-styling">New Inquiry</span> : null}
                        {this.state.lead_details.lead_stage == 2 ? <span style={{ width: 'fit-content !important' }} className="working pstat status-styling">Working</span> : null}
                        {this.state.lead_details.lead_stage == 10 ? <span style={{ width: 'fit-content !important' }} className="noanswer pstat status-styling">No Answer</span> : null}
                        {this.state.lead_details.lead_stage == 11 ? <span style={{ width: 'fit-content !important' }} className="expiry pstat status-styling">Expired</span> : null}
                        {this.state.lead_details.lead_stage == 3 ? <span style={{ width: 'fit-content !important' }} className="hot pstat status-styling">Hot</span> : null}
                        {this.state.lead_details.lead_stage == 4 ? <span style={{ width: 'fit-content !important' }} className="followup pstat status-styling">Follow Up</span> : null}
                        {this.state.lead_details.lead_stage == 5 ? <span style={{ width: 'fit-content !important' }} className="dead pstat status-styling">Dead</span> : null}
                        {this.state.lead_details.lead_stage == 6 ? <span style={{ width: 'fit-content !important' }} className="offer pstat status-styling">Offer</span> : null}
                        {this.state.lead_details.lead_stage == 7 ? <span style={{ width: 'fit-content !important' }} className="dealsigned pstat status-styling">Deal signed</span> : null}
                        {this.state.lead_details.lead_stage == 8 ? <span style={{ width: 'fit-content !important' }} className="viewings pstat status-styling">Viewings</span> : null}
                        {this.state.lead_details.lead_stage == 9 ? <span style={{ width: 'fit-content !important' }} className="recruitment pstat status-styling">Recruitment</span> : null}
                        {this.state.lead_details.lead_stage == 13 ? <span style={{ width: 'fit-content !important' }} className="recruitment pstat status-styling">Offer accepted</span> : null}
                        {this.state.lead_details.lead_stage == 14 ? <span style={{ width: 'fit-content !important' }} className="recruitment pstat status-styling">Offer declined</span> : null}
                      </>}
                    </p></Col></Row>
                    <Row className="d-flex flex-row w-100"><Col span={9}><p>Price (AED):</p></Col><Col span={15}><p className="font-bold">{price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : '-'}</p></Col></Row>
                    <Row className="d-flex flex-row w-100"><Col span={9}><p>Move in Date: </p></Col><Col span={15}><p className="font-bold">
                      {this.state.lead_details.move_in_date != '0000-00-00 00:00:00' ? <Moment format={'DD MMMM YYYY hh:mm A'} className="font-bold">{this.state.lead_details.move_in_date}</Moment>
                        : '-'}
                    </p></Col></Row>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="d-flex flex-column bordertop mt-2 pt-2 followupleadstimeline" style={{ borderTop: '2px solid #151515' }}>
              <Row>
                <Col span={24} className='filteractivity pt-1'>
                  <p className='font-bold font-16'>Activity Log</p>
                </Col>
                <Col span={24} className='timelinelist p-1'>
                  <Row gutter={[0, "5%"]}
                    justify="start"
                    style={{ marginTop: "15px" }} className="timeline-wrap justify-content-start">
                    {this.state.all.map((al, index) => {
                      return (
                        <Col xs={24} sm={12} className={index % 2 === 0 ? window.isMobile576 ? '' : "divider" : ""} style={{ display: index % 2 === 0 ? 'flex' : '', flexDirection: index % 2 === 0 ? 'row-reverse' : '' }}>
                          {window.isMobile576 ? '' : <div className="circle" style={{ marginTop: index % 2 === 0 ? '10px' : '2rem', marginBottom: index % 2 === 0 ? '0' : '-25px', marginLeft: index % 2 === 0 ? '0' : '-6px', marginRight: index % 2 === 0 ? '-6px' : '0' }}></div>}
                          <Card
                            className={index % 2 !== 0 ? "leads-details-section mt-3" : "leads-details-section"}
                            style={{ marginTop: index % 2 === 0 ? window.isMobile576 ? '10px' : '10px' : '', marginRight: index % 2 === 0 ? window.isMobile576 ? '' : '20px' : '', marginLeft: index % 2 !== 0 ? window.isMobile576 ? '' : '20px' : '', width: "100%", height: "fit-content" }}

                          >
                            <div className="tabtimeline">
                              <Row>
                                {/* <Col span={2} className="mr-2">
                          <div className="d-flex flex-column">
                            <Avatar
                              style={{
                                boxShadow: "0px 0px 10px lightgrey",
                                borderRadius: "10%",
                              }}
                              size={50}
                              icon={ <img src={
                                al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                :al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }
                                />} 
                            />
                          </div>
                        </Col> */}
                                <Col span={24}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>
                                      {/* <span
                                className="font-bold"
                                style={{ fontSize: "15px" }}
                              >
                                {al.by_user_name?al.by_user_name:al.note && al.note.includes('archived the chat') ?'':al.note.includes('Expired') ?'Automation':'Opportunity'}
                              </span>{" "}
                              <span
                              className="infovalue atime"
                              style={{ fontSize: "13px", display: "flex" }}
                            >
                              <Moment fromNow>
                              {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                              </Moment>
                            </span> */}
                                      {al.note_type == "note" || al.note_type == "email" ?
                                        <span className={"time" + al.note_type}>
                                          <div className='d-flex flex-column'>
                                            <div className='d-flex flex-row justify-content-between'>
                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                <Avatar
                                                  style={{
                                                    boxShadow: "0px 0px 10px lightgrey",
                                                    borderRadius: "10%",
                                                  }}
                                                  size={50}
                                                  icon={<img src={
                                                    al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                      : al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo}
                                                  />}
                                                />



                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={
                                                                                            al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                                                                            :al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }
                                                                                            />} 
                                                                                            /> */}


                                                <p className="awriter">
                                                  <span className="font-bold">{al.by_user_name ? al.by_user_name : al.note && al.note.includes('archived the chat') ? '' : al.note.includes('Expired') ? 'Automation' : 'Opportunity'}</span>
                                                  <span>
                                                    {al.note && al.note.includes('archived the chat') ? 'A client sent a message in the chat'
                                                      // :al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                      : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                        ' updated a requirement'
                                                        : al.note && al.note.includes('Expired') ? ' changed the status'
                                                          : ' added a note'}
                                                  </span>

                                                  <span
                                                    className="infovalue atime"
                                                    style={{ fontSize: "13px", display: "flex" }}
                                                  >
                                                    {
                                                      al.added_date ? (
                                                        <>
                                                          <div className="tooltip">
                                                            <Moment fromNow>
                                                              {al.added_date
                                                                ? this.getCurrentTime(al.added_date)
                                                                : "-"}
                                                            </Moment>
                                                            <span class="tooltiptext">
                                                              <Moment format="DD MMM YYYY HH:mm:ss">
                                                                {al.added_date
                                                                  ? this.getCurrentTime(al.added_date)
                                                                  : "-"}
                                                              </Moment>
                                                            </span>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        "-"
                                                      )
                                                    }
                                                  </span>
                                                </p>

                                              </div>



                                              {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}



                                            </div>

                                            {al.note && al.note.includes("Call start :") ?
                                              <div className='d-flex adesc'><p className='font-12'>{al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}</p></div>
                                              : al.note && al.note.includes('changed from') || al.note && al.note.includes('changed To') ?
                                                <>{al.note && this.handleSplitReq(al.note)}</>
                                                // :al.note.includes('property on Bayut') ?<span className="">{al.note}</span>
                                                : al.note && al.note.includes('archived the chat') ? <div className='d-flex adesc'>{al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}</div>
                                                  : al.note.includes('Expired') ? <>
                                                    <div className='d-flex flex-column w-100'>
                                                      {/* {(al.note) && this.handleSplitReq(al.note)} */}
                                                      <div className="adesc">
                                                        <span className="font-bold" style={{ display: "flex" }} >
                                                          Reason:
                                                        </span>
                                                        <p className="font-12 mt-0">{parse(al.note.substr(al.note.indexOf(":") + 1))}</p>
                                                      </div>
                                                      <div className="d-flex adesc-stat mt-1">
                                                        <Tag className="pstat">{al.note.match(/(?<=from\s+).*?(?=\s+to)/gs)}</Tag>
                                                        <i class="bi bi-arrow-right mr-2 "></i>
                                                        <Tag className="pstat expired">Expired</Tag>
                                                      </div>
                                                    </div>
                                                  </>
                                                    : <div className='d-flex adesc'>
                                                      <div className='d-flex flex-column'>
                                                        <p className='font-12 mb-1'>
                                                          {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}

                                                        </p>
                                                        {al.lp_details ?
                                                          <div className='lp_details'>

                                                            <Carousel showThumbs={false} arrows style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}>
                                                              {al && al.lp_details && al.lp_details.property_images && this.getImageArray(al.lp_details.property_images).map((image) => {
                                                                return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                              })}
                                                            </Carousel>
                                                            <div className='lpdetails_text w-100'>
                                                              {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                                : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                                  : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                    : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                      : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                        : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                          : null
                                                              }
                                                              <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                              <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                              <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                            </div>
                                                          </div>
                                                          : null}
                                                      </div>
                                                    </div>}


                                            {al.note && al.note.includes("@") && !al.note.includes('archived the chat') ?
                                              <>

                                                <Timeline className='d-flex flex-column mt-2'>
                                                  {al.children && al.children.length > 0 && al.children != [] && al.children.map((child, index) => (
                                                    /* Request Accepted : Book a viewing */
                                                    child && child.note_type == "reply" ?
                                                      <Timeline.Item className={"mt-2 time" + al.note_type}>
                                                        <div className='d-flex flex-column'>
                                                          <div className='d-flex flex-row justify-content-between'>
                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                              {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ child.profile_photo== null || child.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(child.profile_photo)? child.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + child.profile_photo }/>} /> */}
                                                              <Avatar
                                                                style={{
                                                                  boxShadow: "0px 0px 10px lightgrey",
                                                                  borderRadius: "10%",
                                                                }}
                                                                size={50}
                                                                icon={<img src={child.profile_photo == null || child.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(child.profile_photo) ? child.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + child.profile_photo} />}
                                                              />
                                                              <p className="awriter">
                                                                <span className="font-bold">{child.by_user_name}</span>
                                                                <span> replied</span>
                                                                <span
                                                                  className="infovalue atime"
                                                                  style={{ fontSize: "13px", display: "flex" }}
                                                                >
                                                                  {
                                                                    al.added_date ? (
                                                                      <>
                                                                        <div className="tooltip">
                                                                          <Moment fromNow>
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                          <span className="tooltiptext">
                                                                            <Moment format="DD MMM YYYY HH:mm:ss">
                                                                              {al.added_date
                                                                                ? this.getCurrentTime(al.added_date)
                                                                                : "-"}
                                                                            </Moment>
                                                                          </span>
                                                                        </div>
                                                                      </>
                                                                    ) : (
                                                                      "-"
                                                                    )
                                                                  }
                                                                </span>
                                                              </p>
                                                            </div>
                                                            {/* <div className='align-content-center d-flex'>
                                                                                                                   <p className="atime align-self-center">
                                                                                                                       <Moment fromNow>
                                                                                                                           {(child.added_date) ? this.getCurrentTime(child.added_date) : "-"}
                                                                                                                       </Moment>
                                                                                                                   </p>
                                                                                                               </div> */}
                                                          </div>
                                                          <div className="adesc">
                                                            <span>{child.note}</span>
                                                          </div>
                                                        </div>
                                                      </Timeline.Item>
                                                      : null))}
                                                </Timeline>
                                              </>
                                              : null}
                                          </div>
                                        </span>


                                        : al.note_type == "phone" ?
                                          <span className={"time" + al.note_type}>
                                            <div className='d-flex flex-column'>
                                              <div className='d-flex flex-row justify-content-between'>
                                                <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                  {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                  <Avatar
                                                    style={{
                                                      boxShadow: "0px 0px 10px lightgrey",
                                                      borderRadius: "10%",
                                                    }}
                                                    size={50}
                                                    icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                  />
                                                  <p className="awriter">
                                                    <span className="font-bold">{al.by_user_name ? al.by_user_name : 'Opportunity'}</span>
                                                    <span> logged a call</span>
                                                    <span
                                                      className="infovalue atime"
                                                      style={{ fontSize: "13px", display: "flex" }}
                                                    >
                                                      {
                                                        al.added_date ? (
                                                          <>
                                                            <div className="tooltip">
                                                              <Moment fromNow>
                                                                {al.added_date
                                                                  ? this.getCurrentTime(al.added_date)
                                                                  : "-"}
                                                              </Moment>
                                                              <span class="tooltiptext">
                                                                <Moment format="DD MMM YYYY HH:mm:ss">
                                                                  {al.added_date
                                                                    ? this.getCurrentTime(al.added_date)
                                                                    : "-"}
                                                                </Moment>
                                                              </span>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          "-"
                                                        )
                                                      }
                                                    </span>
                                                  </p>
                                                </div>
                                                {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                              </div>
                                              <div className='d-flex adesc'>
                                                <p className='font-12 note-styling'>
                                                  {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}
                                                </p>
                                              </div>
                                            </div>
                                          </span>
                                          : al.note_type == "verify" ?
                                            <span className={"time" + al.note_type}>
                                              <div className='d-flex flex-column'>
                                                <div className='d-flex flex-row justify-content-between'>
                                                  <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                    {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                    <Avatar
                                                      style={{
                                                        boxShadow: "0px 0px 10px lightgrey",
                                                        borderRadius: "10%",
                                                      }}
                                                      size={50}
                                                      icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                    />
                                                    <p className="awriter">
                                                      <span className="font-bold">{al.by_user_name ? al.by_user_name : 'Opportunity'}</span>
                                                      <span> verified this lead</span>
                                                      <span
                                                        className="infovalue atime"
                                                        style={{ fontSize: "13px", display: "flex" }}
                                                      >
                                                        {
                                                          al.added_date ? (
                                                            <>
                                                              <div className="tooltip">
                                                                <Moment fromNow>
                                                                  {al.added_date
                                                                    ? this.getCurrentTime(al.added_date)
                                                                    : "-"}
                                                                </Moment>
                                                                <span class="tooltiptext">
                                                                  <Moment format="DD MMM YYYY HH:mm:ss">
                                                                    {al.added_date
                                                                      ? this.getCurrentTime(al.added_date)
                                                                      : "-"}
                                                                  </Moment>
                                                                </span>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            "-"
                                                          )
                                                        }
                                                      </span>
                                                    </p>
                                                  </div>
                                                  {/* <div className='align-content-center d-flex'>
                                                                                    <p className="atime align-self-center">
                                                                                        <Moment fromNow>
                                                                                            {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                        </Moment>
                                                                                    </p>
                                                                                </div> */}
                                                </div>
                                                {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}
                                              </div>
                                            </span>

                                            : al.note_type == "whatsapp" ?
                                              <span className={this.state.whatsapp_from == 1 ? 'timewhatsapp wa_child' : 'timewhatsapp wa_parent'}>
                                                <div className='d-flex flex-column'>
                                                  <div className='d-flex flex-row justify-content-between'>
                                                    <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                      <Avatar
                                                        style={{
                                                          boxShadow: "0px 0px 10px lightgrey",
                                                          borderRadius: "10%",
                                                        }}
                                                        size={50}
                                                        icon={
                                                          <img src={
                                                            al.note && al.note.includes("Whatsapp - Propertyfinder") ? '/images/PropertyFinder.png'
                                                              : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? '/images/BayutLogo.png'
                                                                : al.note && al.note.includes("Automation sent whatsapp") || al.note && al.note.includes('Client Replied:') ? '/images/Opportunitysquare.png'
                                                                  : al.note && al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo
                                                          } style={{ transform: 'unset' }} />}
                                                      />
                                                      {/* <Avatar className='d-flex mt-1' size={25} icon={
                                                                                                <img src={
                                                                                                    al.note && al.note.includes("Whatsapp - Propertyfinder") ?'/images/PropertyFinder.png'
                                                                                                    :al.note && al.note.includes("Whatsapp - Bayut")|| al.note && al.note.includes('property on Bayut') ?'/images/BayutLogo.png'
                                                                                                    : al.note && al.note.includes("Automation sent whatsapp") || al.note && al.note.includes('Client Replied:') ?'/images/Opportunitysquare.png'
                                                                                                    : al.note && al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo
                                                                                                    } style={{transform:'unset'}}/>} /> */}
                                                      <p className="awriter">
                                                        {al.note && al.note.includes("Whatsapp - Propertyfinder") ? <span className="">You got a new inquiry from <span className='font-bold'>PropertyFinder</span></span>
                                                          : al.note && al.note.includes("Whatsapp - Bayut") || al.note && al.note.includes('property on Bayut') ? <span className="">You got a new inquiry from <span className='font-bold'>Bayut</span></span>
                                                            : al.note && al.note.includes('Client Replied:') ? <span> Client replied to the message</span>
                                                              : <span> Opportunity sent a message</span>}
                                                        <span
                                                          className="infovalue atime"
                                                          style={{ fontSize: "13px", display: "flex" }}
                                                        >
                                                          {
                                                            al.added_date ? (
                                                              <>
                                                                <div className="tooltip">
                                                                  <Moment fromNow>
                                                                    {al.added_date
                                                                      ? this.getCurrentTime(al.added_date)
                                                                      : "-"}
                                                                  </Moment>
                                                                  <span class="tooltiptext">
                                                                    <Moment format="DD MMM YYYY HH:mm:ss">
                                                                      {al.added_date
                                                                        ? this.getCurrentTime(al.added_date)
                                                                        : "-"}
                                                                    </Moment>
                                                                  </span>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              "-"
                                                            )
                                                          }
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                  </div>
                                                </div>
                                                <div className='d-flex adesc'>
                                                  <div className='d-flex flex-column'>
                                                    <p className='font-12 mb-1'>
                                                      {al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}
                                                    </p>
                                                    {al.lp_details ?
                                                      <div className='lp_details note-crousal'>
                                                        <Carousel arrows showThumbs={false} style={{ width: '160px', height: "98px", overflow: 'hidden' }} effect="fade" {...props}>
                                                          {al && al.lp_details && al.lp_details.property_images && this.getImageArray(al.lp_details.property_images).map((image) => {
                                                            return <Image src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                                                          })}
                                                        </Carousel>
                                                        <div className='lpdetails_text w-100'>
                                                          {al.lp_details.status == "D" ? <p className='stat publishstat'>Draft</p>
                                                            : al.lp_details.status == "UC" ? <p className='stat confirmedowner'>Confirmed Owner</p>
                                                              : al.lp_details.status == "OM" ? <p className='stat offmarket'>Off-Market</p>
                                                                : al.lp_details.status == "CTM" ? <p className='stat comingtomarket'>Coming to Market</p>
                                                                  : al.lp_details.status == "Y" ? <p className='stat published'>Published</p>
                                                                    : al.lp_details.status == "F" ? <p className='stat feeds'>Feeds</p>
                                                                      : null
                                                          }
                                                          <p>{al.lp_details.unitno ? al.lp_details.unitno + "," : null}{al.lp_details.property_project ? al.lp_details.property_project + "," : null}</p>
                                                          <p>{al.lp_details.subcommunity ? al.lp_details.subcommunity + "," : null}{al.lp_details.community ? al.lp_details.community + "," : null}</p>
                                                          <p>{al.lp_details.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}</p>
                                                        </div>
                                                      </div>
                                                      : null}
                                                  </div>
                                                </div>
                                              </span>

                                              : al.note_type == "viewing" ?
                                                <span className={"time" + al.note_type}>
                                                  <div className='d-flex flex-column'>
                                                    <div className='d-flex flex-row justify-content-between'>
                                                      <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                        <Avatar
                                                          style={{
                                                            boxShadow: "0px 0px 10px lightgrey",
                                                            borderRadius: "10%",
                                                          }}
                                                          size={50}
                                                          icon={
                                                            <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                        />
                                                        {/* <Avatar className='d-flex mt-1' size={25} icon={ */}
                                                        {/* <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                        <p className="awriter">
                                                          <span className="font-bold">{al.by_user_name ? al.by_user_name : 'Opportunity'}</span>
                                                          <span> scheduled a viewing</span>
                                                          {/* <span className='pstat scheduled'>Scheduled</span> */}
                                                          <span
                                                            className="infovalue atime"
                                                            style={{ fontSize: "13px", display: "flex" }}
                                                          >
                                                            {
                                                              al.added_date ? (
                                                                <>
                                                                  <div className="tooltip">
                                                                    <Moment fromNow>
                                                                      {al.added_date
                                                                        ? this.getCurrentTime(al.added_date)
                                                                        : "-"}
                                                                    </Moment>
                                                                    <span class="tooltiptext">
                                                                      <Moment format="DD MMM YYYY HH:mm:ss">
                                                                        {al.added_date
                                                                          ? this.getCurrentTime(al.added_date)
                                                                          : "-"}
                                                                      </Moment>
                                                                    </span>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                "-"
                                                              )
                                                            }
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}

                                                    </div>
                                                  </div>
                                                  <div className='d-flex adesc w-100'>
                                                    {this.handleSplitViewing(al.note, al.lp_details ? al.lp_details : null)}
                                                  </div>

                                                  {al.property_viewings_feedback && al.property_viewings_feedback.map((vfeedback) => (
                                                    <>
                                                      {al.note.includes(vfeedback.refno) ?
                                                        <div className='d-flex flex-row mt-2'>
                                                          <Avatar
                                                            style={{
                                                              boxShadow: "0px 0px 10px lightgrey",
                                                              borderRadius: "10%",
                                                            }}
                                                            size={50}
                                                            icon={
                                                              <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                          />
                                                          {/* <Avatar className='d-flex mt-1' size={25} icon={
                                                                                            <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                          <div className='messagewrap'>
                                                            <p>{vfeedback.note}</p>
                                                            <div className='d-flex w-100 align-items-center'>
                                                              <span className='mr-1 font-bold'>Status: </span>
                                                              <Tag>Scheduled</Tag>
                                                              <i className="bi bi-arrow-right mr-2 "></i>
                                                              <Tag className={vfeedback.status.replace(/ /g, '').toLowerCase() + ' pstat px-1'}>{vfeedback.status}</Tag>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        : null}
                                                    </>
                                                  ))}
                                                </span>


                                                : al.note_type == "offer" ?
                                                  <span className={"time" + al.note_type}>
                                                    <div className='d-flex flex-column'>
                                                      <div className='d-flex flex-row justify-content-between'>
                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                          <Avatar
                                                            style={{
                                                              boxShadow: "0px 0px 10px lightgrey",
                                                              borderRadius: "10%",
                                                            }}
                                                            size={50}
                                                            icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                          />
                                                          {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?  "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } />} /> */}
                                                          <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                            {al.note && al.note.includes("Viewing status") ? <span> updated the viewing status</span>
                                                              : al.note && al.note.includes("Offer status") ? <span> updated the offer status</span>
                                                                : null}
                                                            <span
                                                              className="infovalue atime"
                                                              style={{ fontSize: "13px", display: "flex" }}
                                                            >
                                                              {
                                                                al.added_date ? (
                                                                  <>
                                                                    <div className="tooltip">
                                                                      <Moment fromNow>
                                                                        {al.added_date
                                                                          ? this.getCurrentTime(al.added_date)
                                                                          : "-"}
                                                                      </Moment>
                                                                      <span class="tooltiptext">
                                                                        <Moment format="DD MMM YYYY HH:mm:ss">
                                                                          {al.added_date
                                                                            ? this.getCurrentTime(al.added_date)
                                                                            : "-"}
                                                                        </Moment>
                                                                      </span>
                                                                    </div>
                                                                  </>
                                                                ) : (
                                                                  "-"
                                                                )
                                                              }
                                                            </span>
                                                          </p>
                                                        </div>
                                                        {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>{this.getCurrentTime(al.added_date)}</Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                      </div>
                                                      <div className='d-flex adesc'>
                                                        <p className='font-12'>
                                                          {al.note}
                                                        </p>
                                                      </div>
                                                      <div className="d-flex adesc-stat mt-1">
                                                        {al.note && al.note.includes("Viewing status changed to Offer") ? <>
                                                          <Tag className='pstat viewing'>Viewing</Tag>
                                                          <i class="bi bi-arrow-right"></i>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                        </> : al.note && al.note.includes("Not Interested") ? <>
                                                          <Tag className='pstat viewing'>Viewing</Tag>
                                                          <i class="bi bi-arrow-right"></i>
                                                          <Tag className='pstat cancelled'>Not Interested</Tag>
                                                        </> : al.note && al.note.includes("Offer Sent") ? <>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                          <i class="bi bi-arrow-right"></i>
                                                          <Tag className='pstat offer'>Offer Sent</Tag>
                                                        </> : al.note && al.note.includes("Offer Accepted") ? <>
                                                          <Tag className='pstat offer'>Offer Sent</Tag>
                                                          <i class="bi bi-arrow-right"></i>
                                                          <Tag className='pstat secondviewing'>Offer Accepted</Tag>
                                                        </> : al.note && al.note.includes("Offer Rejected") ? <>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                          <i class="bi bi-arrow-right"></i>
                                                          <Tag className='pstat scheduled'>Offer Rejected</Tag>
                                                        </> : al.note && al.note.includes("Deal Signed") ? <>
                                                          <Tag className='pstat offer'>Offer</Tag>
                                                          <i class="bi bi-arrow-right"></i>
                                                          <Tag className='pstat deal'>Deal Signed</Tag>
                                                        </> : null}
                                                      </div>
                                                    </div>
                                                  </span>

                                                  : al.note_type == "noanswer" ?
                                                    <span className={"time" + al.note_type}>
                                                      <div className='d-flex flex-column'>
                                                        <div className='d-flex flex-row justify-content-between'>
                                                          <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                            <Avatar
                                                              style={{
                                                                boxShadow: "0px 0px 10px lightgrey",
                                                                borderRadius: "10%",
                                                              }}
                                                              size={50}
                                                              icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                            />
                                                            {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                            <p className="awriter">
                                                              <span className="font-bold">{al.by_user_name ? al.by_user_name : 'Opportunity'}</span>
                                                              <span> called but no answer</span>
                                                              <span
                                                                className="infovalue atime"
                                                                style={{ fontSize: "13px", display: "flex" }}
                                                              >
                                                                {
                                                                  al.added_date ? (
                                                                    <>
                                                                      <div className="tooltip">
                                                                        <Moment fromNow>
                                                                          {al.added_date
                                                                            ? this.getCurrentTime(al.added_date)
                                                                            : "-"}
                                                                        </Moment>
                                                                        <span class="tooltiptext">
                                                                          <Moment format="DD MMM YYYY HH:mm:ss">
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                        </span>
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    "-"
                                                                  )
                                                                }
                                                              </span>
                                                            </p>
                                                          </div>
                                                          {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                        </div>

                                                      </div>
                                                    </span>

                                                    : al.note_type == "req_expire" ?
                                                      <span className={"time" + al.note_type}>
                                                        <div className='d-flex flex-column'>
                                                          <div className='d-flex flex-row justify-content-between'>
                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                              <Avatar
                                                                style={{
                                                                  boxShadow: "0px 0px 10px lightgrey",
                                                                  borderRadius: "10%",
                                                                }}
                                                                size={50}
                                                                icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                              />
                                                              {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                              <p className="awriter">
                                                                <span className="font-bold">{al.by_user_name ? al.by_user_name : 'Opportunity'}</span>
                                                                <span> requested to share his property to your client.</span>
                                                                <span
                                                                  className="infovalue atime"
                                                                  style={{ fontSize: "13px", display: "flex" }}
                                                                >
                                                                  {
                                                                    al.added_date ? (
                                                                      <>
                                                                        <div className="tooltip">
                                                                          <Moment fromNow>
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                          <span class="tooltiptext">
                                                                            <Moment format="DD MMM YYYY HH:mm:ss">
                                                                              {al.added_date
                                                                                ? this.getCurrentTime(al.added_date)
                                                                                : "-"}
                                                                            </Moment>
                                                                          </span>
                                                                        </div>
                                                                      </>
                                                                    ) : (
                                                                      "-"
                                                                    )
                                                                  }
                                                                </span>
                                                              </p>
                                                            </div>
                                                            {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}
                                                          </div>
                                                          <div className='adesc'>
                                                            <a href="" className='font-bold color-text-black'>{al.note}</a>
                                                            <p className="font-semibold">{(al.unitno) ? al.unitno : ""} {(al.community) ? "," + al.community : ""} {(al.subcommunity) ? "," + al.subcommunity : ""} {(al.price) && <i className="bi bi-dot"></i>}<span>{(al.price) ? this.getPrice(al.price) : ""}</span></p>
                                                          </div>
                                                          {al.note_type && al.note_type == 'req_expire' ?
                                                            <Timeline className='d-flex flex-column mt-2'>
                                                              {al.children && al.children.length > 0 && al.children != [] && al.children.map((child) => (
                                                                /* Request Accepted : Book a viewing */
                                                                child && child.note_type == "req_accept" ?
                                                                  <Timeline.Item className={"time" + al.note_type}>
                                                                    <div className='d-flex flex-column'>
                                                                      <div className='d-flex flex-row justify-content-between'>
                                                                        <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                          {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                          <Avatar
                                                                            style={{
                                                                              boxShadow: "0px 0px 10px lightgrey",
                                                                              borderRadius: "10%",
                                                                            }}
                                                                            size={50}
                                                                            icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                          />
                                                                          <p className="awriter">
                                                                            <span className="font-bold">{child.by_user_name}</span>
                                                                            <span> scheduled a viewing with client.</span>
                                                                            <span
                                                                              className="infovalue atime"
                                                                              style={{ fontSize: "13px", display: "flex" }}
                                                                            >
                                                                              {
                                                                                al.added_date ? (
                                                                                  <>
                                                                                    <div className="tooltip">
                                                                                      <Moment fromNow>
                                                                                        {al.added_date
                                                                                          ? this.getCurrentTime(al.added_date)
                                                                                          : "-"}
                                                                                      </Moment>
                                                                                      <span class="tooltiptext">
                                                                                        <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                          {al.added_date
                                                                                            ? this.getCurrentTime(al.added_date)
                                                                                            : "-"}
                                                                                        </Moment>
                                                                                      </span>
                                                                                    </div>
                                                                                  </>
                                                                                ) : (
                                                                                  "-"
                                                                                )
                                                                              }
                                                                            </span>
                                                                          </p>
                                                                        </div>
                                                                        {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow>{(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                      </div>
                                                                      <div className="adesc">
                                                                        <Row>
                                                                          <Col span={24}><span className='font-10'>Date / Time:</span></Col>
                                                                          <Col span={8}>
                                                                            <p className='font-bold fs-6 mb-0'>{this.getTime(child.viewing_date)}</p>
                                                                            <p className="font-12">{this.getDate(child.added_date)}</p>
                                                                          </Col>
                                                                          <Col span={16}>
                                                                            <p>Viewing booked for <span className='font-bold'>{al.note}</span></p>
                                                                            <p className="font-12 mt-2">{al.unitno}, {al.community}, {al.subcommunity} <i className="bi bi-dot"></i><span>{al.price} AED</span></p>
                                                                          </Col>
                                                                        </Row>
                                                                      </div>
                                                                    </div>
                                                                  </Timeline.Item>
                                                                  :/* Share via WhatsApp - Replied */
                                                                  child && child.note_type == "req_wait" ?
                                                                    <Timeline.Item className={" time" + al.note_type}>
                                                                      <div className='d-flex flex-column'>
                                                                        <div className='d-flex flex-row justify-content-between'>
                                                                          <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                            <Avatar
                                                                              style={{
                                                                                boxShadow: "0px 0px 10px lightgrey",
                                                                                borderRadius: "10%",
                                                                              }}
                                                                              size={50}
                                                                              icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                            />
                                                                            {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                            <p className="awriter">
                                                                              <span className="font-bold">{this.state.lead_list_details.owner}</span>
                                                                              <span> replied to the message.</span>
                                                                              <span
                                                                                className="infovalue atime"
                                                                                style={{ fontSize: "13px", display: "flex" }}
                                                                              >
                                                                                {
                                                                                  al.added_date ? (
                                                                                    <>
                                                                                      <div className="tooltip">
                                                                                        <Moment fromNow>
                                                                                          {al.added_date
                                                                                            ? this.getCurrentTime(al.added_date)
                                                                                            : "-"}
                                                                                        </Moment>
                                                                                        <span class="tooltiptext">
                                                                                          <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                            {al.added_date
                                                                                              ? this.getCurrentTime(al.added_date)
                                                                                              : "-"}
                                                                                          </Moment>
                                                                                        </span>
                                                                                      </div>
                                                                                    </>
                                                                                  ) : (
                                                                                    "-"
                                                                                  )
                                                                                }
                                                                              </span>
                                                                            </p>
                                                                          </div>
                                                                          {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                        </div>
                                                                        <div className="adesc">
                                                                          <Row className='align-content-center'>
                                                                            <Col span={16} className='d-flex flex-column'>
                                                                              <span className='font-10'>Best time to view:</span>
                                                                              <div className='d-flex flex-row'>
                                                                                <p className='font-bold mb-0'>{child.note}</p>
                                                                              </div>
                                                                            </Col>
                                                                            <Col span={8}>
                                                                              <Button type='primary' onClick={this.handleRequestNewViewing.bind(this, al)}>Book a viewing</Button>
                                                                            </Col>
                                                                          </Row>
                                                                        </div>
                                                                      </div>
                                                                    </Timeline.Item>
                                                                    :/* Share via WhatsApp - Shared via WhatsApp */
                                                                    child && child.note_type == "req_w" ?
                                                                      <Timeline.Item className={"mt-2 time" + al.note_type}>
                                                                        <div className='d-flex flex-column'>
                                                                          <div className='d-flex flex-row justify-content-between'>
                                                                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                              <Avatar
                                                                                style={{
                                                                                  boxShadow: "0px 0px 10px lightgrey",
                                                                                  borderRadius: "10%",
                                                                                }}
                                                                                size={50}
                                                                                icon={<img src={child.note.includes("System has shared") ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                              />
                                                                              {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ child.note.includes("System has shared")  ?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                              <p className="awriter">
                                                                                <span>
                                                                                  {child.note.includes("System has shared") ?
                                                                                    <><span>Opportunity shared {child.by_user_name} property to the client</span>
                                                                                    </>
                                                                                    : child.note}
                                                                                </span>
                                                                                <span
                                                                                  className="infovalue atime"
                                                                                  style={{ fontSize: "13px", display: "flex" }}
                                                                                >
                                                                                  {
                                                                                    al.added_date ? (
                                                                                      <>
                                                                                        <div className="tooltip">
                                                                                          <Moment fromNow>
                                                                                            {al.added_date
                                                                                              ? this.getCurrentTime(al.added_date)
                                                                                              : "-"}
                                                                                          </Moment>
                                                                                          <span class="tooltiptext">
                                                                                            <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                              {al.added_date
                                                                                                ? this.getCurrentTime(al.added_date)
                                                                                                : "-"}
                                                                                            </Moment>
                                                                                          </span>
                                                                                        </div>
                                                                                      </>
                                                                                    ) : (
                                                                                      "-"
                                                                                    )
                                                                                  }
                                                                                </span>
                                                                              </p>
                                                                            </div>
                                                                            {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                            <Moment fromNow>
                                                                                                                        {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}
                                                                                                                    </Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                          </div>
                                                                        </div>
                                                                      </Timeline.Item>
                                                                      :/* Share via WhatsApp - Shared via WhatsApp */
                                                                      child && child.note_type == "req_decline" ?
                                                                        <Timeline.Item className={"mt-2 time" + al.note_type}>
                                                                          <div className='d-flex flex-column'>
                                                                            <div className='d-flex flex-row justify-content-between'>
                                                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                                <Avatar
                                                                                  style={{
                                                                                    boxShadow: "0px 0px 10px lightgrey",
                                                                                    borderRadius: "10%",
                                                                                  }}
                                                                                  size={50}
                                                                                  icon={<img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />}
                                                                                />
                                                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ <img src={ al.profile_photo== null || al.profile_photo == ""?"/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo }/>} /> */}
                                                                                <p className="awriter">
                                                                                  <span className="font-bold">{child.by_user_name}</span>
                                                                                  <span> declined your request</span>
                                                                                  <span
                                                                                    className="infovalue atime"
                                                                                    style={{ fontSize: "13px", display: "flex" }}
                                                                                  >
                                                                                    {
                                                                                      al.added_date ? (
                                                                                        <>
                                                                                          <div className="tooltip">
                                                                                            <Moment fromNow>
                                                                                              {al.added_date
                                                                                                ? this.getCurrentTime(al.added_date)
                                                                                                : "-"}
                                                                                            </Moment>
                                                                                            <span class="tooltiptext">
                                                                                              <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                                {al.added_date
                                                                                                  ? this.getCurrentTime(al.added_date)
                                                                                                  : "-"}
                                                                                              </Moment>
                                                                                            </span>
                                                                                          </div>
                                                                                        </>
                                                                                      ) : (
                                                                                        "-"
                                                                                      )
                                                                                    }
                                                                                  </span>
                                                                                </p>
                                                                              </div>
                                                                              {/* <div className='align-content-center d-flex'>
                                                                                                            <p className="atime align-self-center">
                                                                                                                <Moment fromNow> {(child.added_date) ? this.getCurrentTime(child.added_date) : ""}</Moment>
                                                                                                            </p>
                                                                                                        </div> */}
                                                                            </div>
                                                                            <div className="adesc">
                                                                              <span>{child.note}</span>
                                                                            </div>
                                                                          </div>
                                                                        </Timeline.Item>
                                                                        : null))}
                                                            </Timeline>
                                                            : null}
                                                        </div>
                                                      </span>

                                                      : al.note_type = "followup" ?
                                                        <span className={"timefollowup"}>
                                                          <div className='d-flex flex-column'>
                                                            <div className='d-flex flex-row justify-content-between'>
                                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                <Avatar
                                                                  style={{
                                                                    boxShadow: "0px 0px 10px lightgrey",
                                                                    borderRadius: "10%",
                                                                  }}
                                                                  size={50}
                                                                  icon={
                                                                    <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                                />
                                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ */}
                                                                {/* <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                                <p className="awriter">
                                                                  <span className="font-bold">{al.by_user_name ? al.by_user_name : 'Opportunity'}  {al.by_status == 2 ? "added a followup" : "added a feedback for viewing"} </span>
                                                                </p>
                                                              </div>
                                                              <div className='align-content-center d-flex'>
                                                                <p className="atime align-self-center">
                                                                  <Moment fromNow>
                                                                    {al.added_date
                                                                      ? this.getCurrentTime(al.added_date)
                                                                      : "-"}
                                                                  </Moment>
                                                                </p>
                                                              </div>

                                                            </div>
                                                          </div>
                                                          <div className='d-flex adesc p-2'>
                                                            <p className='font-12'>
                                                              {al.note}
                                                            </p>
                                                          </div>
                                                        </span>

                                                        :
                                                        <span className={"time" + al.note_type}>
                                                          <div className='d-flex flex-column'>
                                                            <div className='d-flex flex-row justify-content-between'>
                                                              <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.3rem' }}>
                                                                <Avatar
                                                                  style={{
                                                                    boxShadow: "0px 0px 10px lightgrey",
                                                                    borderRadius: "10%",
                                                                  }}
                                                                  size={50}
                                                                  icon={
                                                                    <img src={al.profile_photo == null || al.profile_photo == "" ? "/images/Opportunitysquare.png" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} style={{ transform: 'unset' }} />}
                                                                />
                                                                {/* <Avatar className='d-flex mt-1' size={25} icon={ */}
                                                                {/* <img src={ al.profile_photo== null || al.profile_photo == ""? "/images/Opportunitysquare.png": this.isValidHttpUrl(al.profile_photo)? al.profile_photo:  baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo } style={{transform:'unset'}}/>} /> */}
                                                                <p className="awriter">
                                                                  <span className="font-bold">{al.by_user_name ? al.by_user_name : 'Opportunity'}</span>
                                                                  <span
                                                                    className="infovalue atime"
                                                                    style={{ fontSize: "13px", display: "flex" }}
                                                                  >
                                                                    {
                                                                      al.added_date ? (
                                                                        <>
                                                                          <div className="tooltip">
                                                                            <Moment fromNow>
                                                                              {al.added_date
                                                                                ? this.getCurrentTime(al.added_date)
                                                                                : "-"}
                                                                            </Moment>
                                                                            <span class="tooltiptext">
                                                                              <Moment format="DD MMM YYYY HH:mm:ss">
                                                                                {al.added_date
                                                                                  ? this.getCurrentTime(al.added_date)
                                                                                  : "-"}
                                                                              </Moment>
                                                                            </span>
                                                                          </div>
                                                                        </>
                                                                      ) : (
                                                                        "-"
                                                                      )
                                                                    }
                                                                  </span>
                                                                </p>
                                                              </div>
                                                              {/* <div className='align-content-center d-flex'>
                                                                                        <p className="atime align-self-center">
                                                                                            <Moment fromNow>
                                                                                                {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                                                                                            </Moment>
                                                                                        </p>
                                                                                    </div> */}

                                                            </div>
                                                          </div>
                                                          <div className='d-flex adesc'>
                                                            <p className='font-12'>
                                                              {al.note}
                                                              <span
                                                                className="infovalue atime"
                                                                style={{ fontSize: "13px", display: "flex" }}
                                                              >
                                                                {
                                                                  al.added_date ? (
                                                                    <>
                                                                      <div className="tooltip">
                                                                        <Moment fromNow>
                                                                          {al.added_date
                                                                            ? this.getCurrentTime(al.added_date)
                                                                            : "-"}
                                                                        </Moment>
                                                                        <span class="tooltiptext">
                                                                          <Moment format="DD MMM YYYY HH:mm:ss">
                                                                            {al.added_date
                                                                              ? this.getCurrentTime(al.added_date)
                                                                              : "-"}
                                                                          </Moment>
                                                                        </span>
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    "-"
                                                                  )
                                                                }
                                                              </span>
                                                            </p>
                                                          </div>
                                                        </span>}

                                      {/* <span>
                                updated a requirement
                                </span> */}
                                    </span>
                                    {/* <span
                              className="infovalue atime"
                              style={{ fontSize: "13px", display: "flex" }}
                            >
                              <Moment fromNow>
                              {(al.added_date) ? this.getCurrentTime(al.added_date) : "-"}
                              </Moment>
                            </span> */}
                                  </div>
                                  {/* <div className='d-flex adesc'>
                                                                    <p className='font-12'>
                                                                        { (al.note && al.note != null) && parse(al.note)}
                                                                    </p>
                                                                </div> */}
                                  {/* <p>
                            {this.handleSplitReq(
                              "Move in date has been changed from 0000-00-00 00:00:00 To 2023-09-01"
                            )}
                          </p> */}
                                </Col>
                              </Row>
                            </div>
                          </Card>

                        </Col>
                      )
                    })}
                  </Row>
                </Col>

              </Row>
            </div>
          </div>

        </Drawer>

      </>
    );
  }
}

export default Followups;
