import React, { Component } from 'react'
import { Table, Space, Select ,DatePicker ,Modal,Popover} from 'antd';
import {itemRender,onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './listing.css'
import API from '../../utils/API';
import {liveUrl} from "../../components/BaseUrl";
import { Link } from 'react-router-dom';
import EdiText from "react-editext";
import './Pledge.css'



import Moment from 'react-moment';
import { store } from '../../store';
const Option = Select.Option;


const { RangePicker } = DatePicker;
class Pledge extends Component {
   
    constructor(props){
        super(props)
        this.state={
            visible:false,
            target:"",
            deals:"",
            new_listings:"",
            new_viewings:"",
            feeds_back:"",
            row_id:"",
            pledges:[],
            agents:[],
            isLoading:false,
            selectedAgents:"",
            dateRange:[]
        }
    }
    componentDidMount(){
        var loadScript = function(src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')

        const all_data = store.getState((state) => state);
        const isPledge = all_data.pledge_statistics_reducer.isPledge
        const redux_selectedAgents = all_data.pledge_statistics_reducer.selectedAgents

         
       

        const page = 1;
        const perPage = 10;
        const data = {redux_selectedAgents:redux_selectedAgents,selectedAgents:this.state.selectedAgents,dateRange:this.state.dateRange,isPledge : isPledge===true?1:0}
        this.fetchData(page,perPage,data)

        API.get("/all_agents")
        .then((response) => {
          if (response.data.success) {
            this.setState({ agents: response.data.agents })
          }
        }
      )
    }

    fetchData = (page, perPage,data) => {
        this.setState({ isLoading: true })
        API.post(`/pledges?page=${page}&perPage=${perPage}`,data)
          .then(async (response) => {

            await response.data.pledges;
            this.setState({
              pledges: response.data.pledges,
              isLoading: false
            })
    
          })
      
    
      }

      handleAgent = (e) =>{
        const all_data = store.getState((state) => state);
        const isPledge = all_data.pledge_statistics_reducer.isPledge
        const redux_selectedAgents = all_data.pledge_statistics_reducer.selectedAgents
       
        this.setState({selectedAgents:e})
       const data = {selectedAgents:e,dateRange:this.state.dateRange,isPledge : isPledge===true?1:0,redux_selectedAgents:redux_selectedAgents}
       const page = 1;
       const perPage = 10;
       this.fetchData(page,perPage,data)

      }

      handleRangeDate = (value, dateString) =>{
        const all_data = store.getState((state) => state);
        const isPledge = all_data.pledge_statistics_reducer.isPledge
        const redux_selectedAgents = all_data.pledge_statistics_reducer.selectedAgents
       
       this.setState({dateRange:value==null?[]:dateString})
       const data = {selectedAgents:this.state.selectedAgents,dateRange:value==null?[]:dateString,isPledge:isPledge==true?1:0,redux_selectedAgents:redux_selectedAgents}
       const page = 1;
       const perPage = 10;
        this.fetchData(page,perPage,data)

      }

      handleLead = (e,ev)=>{
        window.open("/leads/"+e, "_blank");
      }

      handleListings = (e,ev)=>{
        window.open("/all_listings/"+e, "_blank");
      }

      handleShowModal = (record,e) =>{
     
      

       API.get("/get_pledge/"+record.id)
       .then((response) =>{
      
        if(response.data.success){
          
          this.setState({row_id:record.id,target:response.data.pledge.target,deals:response.data.pledge.deals,new_viewings:response.data.pledge.viewings,new_listings:response.data.pledge.listings,feeds_back:response.data.pledge.feed_back})
      
        }
        this.setState({visible:true})
       })
      }

      handleOk = () =>{
        const all_data = store.getState((state) => state);
        const isPledge = all_data.pledge_statistics_reducer.isPledge
        const redux_selectedAgents = all_data.pledge_statistics_reducer.selectedAgents
       
        const row_id =this.state.row_id;
        const page = 1;
        const perPage = 10;
        const data = {selectedAgents:this.state.selectedAgents,dateRange:this.state.dateRange,isPledge:isPledge==true?1:0,redux_selectedAgents:redux_selectedAgents}
        const data2 = {target:this.state.target,deals:this.state.deals,
                      listings:this.state.new_listings,viewings:this.state.new_viewings,feed_back:this.state.feeds_back}
                     
                      API.put("pledge/"+row_id,data2)
                      .then((response) =>{
                       
                        if(response.data.success)
                    
                        this.fetchData(page,perPage,data)
                      })

        this.setState({visible:false})
      }

      handleCancel = () =>{
        
        this.setState({visible:false,target:"",deals:"",new_viewings:"",new_listings:"",feeds_back:null})

      }

      handleTarget = (e) => {
    
        this.setState({target:e.target.value})
       }
   
       handleDeal = (e) => {
       
         this.setState({deals:e.target.value})
        }
   
        handleNewListings = (e) => {
       
         this.setState({new_listings:e.target.value})
        }
   
        handleNewViewings = (e) => {
       
         this.setState({new_viewings:e.target.value})
        }

        handleFeedback = (e) => {
          this.setState({feeds_back:e.target.value})
         }

        
   

      // handleSaveTarget = (value) =>{
      //   const data = {row_id:this.state.row_id,target:value}
      //   console.log("data",data);
      // }

      // handleSaveDeal = (value) =>{
      //   const data = {row_id:this.state.row_id,deal:value}
      //   console.log("data",data);
      // }

      // handleSaveNewListing = (value) =>{
      //   const data = {row_id:this.state.row_id,new_listing:value}
      //   console.log("data",data);
      // }

      // handleSaveNewViewing = (value) =>{
      //   const data = {row_id:this.state.row_id,new_viewings:value}
      //   console.log("data",data);
      // }

      handleContent = (r_l,e) =>{
        return  <div>
        <p>Unit no:  {r_l.unitno}</p>
        <p>Ref no: {r_l.refno}</p>
        <p>Community: {r_l.community}</p>
        <p>Subcommunity: {r_l.subcommunity}</p>
        <p>Property: {r_l.property}</p>
      </div>

      }

  render() {
    const authentication = store.getState((state) => state);
    const user = authentication.auth.user
    const role = user.role
    const {feeds_back} = this.state

    const content = (
      <div>
        <p>Content</p>
        <p>Content</p>
      </div>
    );

    const columns = [
        {
        title: 'Target Date',
         dataIndex: 'date_added',
           key: 'date_added',
           render: (text, record) => (
            <Link type="primary" onClick={this.handleShowModal.bind(this,record)}>
            <Moment format="MMMM DD, YYYY" >
            {record.date_added}
              </Moment> 
              </Link>
         ),
          
      },
       {
        title: 'Pledge Date',
         dataIndex: 'pludge_date',
           key: 'pludge_date',
           render: (text, record) => (
            <>
            {record.pludge_date ==null?"": <Moment format="MMMM DD, YYYY" >
            {record.pludge_date}
              </Moment> }
            </>
           
           
            
         ),
          
      },
      
      { title: 'Agent', dataIndex: 'display_name',key: 'display_name'}, 
       { title: 'AED Target', dataIndex: 'target',key: 'aedtarget',
       render: (text,record)=> {
        if(!isNaN(parseInt(text))){
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          }else{
            return null;
          }
       }
      },   
      { title: 'Viewings', 
      dataIndex: 'viewings',
      className:'text-center ',
      render: (text,record)=> (
      
        <div className='plview'>
        {record.viewings_done >= record.viewings && record.viewings_done > 0?
        <><div className='greengroup'>{record.viewings}</div><div className='remainview'>({record.viewings_done})</div></>:<><div className='redgroup'>{record.viewings}</div><div className='remainview'>({record.viewings_done})</div></>
         } 
          
       </div>
  
      )
      }, 
      { title: 'Listings', dataIndex: 'listings',
      render: (text,record)=> (
        <div className='plview'>
        {record.listings_done >= record.listings && record.listings_done > 0?
        <><div className='greengroup'>{record.listings}</div><div className='remainview'>({record.listings_done})</div></>:<><div className='redgroup'>{record.listings}</div><div className='remainview'>({record.listings_done})</div></>
         } 
          
       </div>
      )
      }, 
      { title: 'Deals', dataIndex: 'deals',
      render: (text,record)=> (
        <div className='plview'>
        {record.deals_done >= record.deals && record.deals_done > 0?
        <><div className='greengroup'>{record.deals}</div><div className='remainview'>  ({record.deals_done})</div></>:<><div className='redgroup'>{record.deals}</div><div className='remainview'>({record.deals_done})</div></>
         } 
          
       </div>
      )
      }, 
      { 
        title: 'Hottest Listings',
          dataIndex: 'refno',key: 'refno', 
        render: (text, record) => (
           // console.log("rec",record.pl_listing)
            record.pl_listing.map((r_l)=>(
                <div>
                <Link onClick={this.handleListings.bind(this,r_l.id)}  style={{lineHeight:2}}>  <Popover content={this.handleContent.bind(this,r_l)} title="Details" trigger="hover">
                {r_l.refno}
    </Popover> </Link>
                </div>
            ))
        ),
        
       }, 
        { title: 'Hottest Clients',
        // dataIndex: 'leads_1',key: 'leads_1', 
        render: (text, record) => (
             record.pl_leads.map((r_lead)=>(
               <div>
                 <Link onClick={this.handleLead.bind(this,r_lead.id)} style={{lineHeight:2}}>
                    {r_lead.firstname==null ? "" : r_lead.firstname + " "}{r_lead.lastname}
                </Link>
                </div>
             ))
         ),
        }, 

       

        { title: 'Feedback', dataIndex: 'feed_back',key: 'feed_back', 
    }, { title: 'Action', dataIndex: 'action',key: 'action', className: 'text-center', render: (text, record) => (
                        <div className="ms-auto">
                        <a className="btn btn-link btn-sm p-0"><i  className="fa fa-trash" style={{fontSize:"14px"}}></i></a>
                    </div>
                ),
      }];

      const agent_columns = [
        {
        title: 'Target Date',
         dataIndex: 'date_added',
           key: 'date_added',
           render: (text, record) => (
            <Moment format="MMMM DD, YYYY">
            {record.date_added}
              </Moment> 
         ),
          
      },
      {
        title: 'Pledge Date',
         dataIndex: 'pludge_date',
           key: 'pludge_date',
           render: (text, record) => (
            <>
            {record.pludge_date ==null?"": <Moment format="YYYY-MM-DD" >
            {record.pludge_date}
              </Moment> }
            </>
           
           
            
         ),
          
      },
       { title: 'AED Target', dataIndex: 'target',key: 'aedtarget', render: (text,record)=> {
        if(!isNaN(parseInt(text))){
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          }else{
            return null;
          }
       }
      }, 
      { title: 'Viewings', 
      dataIndex: 'viewings',
      className:'text-center ',
      render: (text,record)=> (
      
             <div className='plview'>
             {record.viewings_done >= record.viewings && record.viewings_done > 0?
             <div className='greengroup'>{record.viewings} ({record.viewings_done})</div>:<div className='redgroup'>{record.viewings} ({record.viewings_done})</div>
              } 
               
            </div>
  
      )
      }, 
      { title: 'Listings', dataIndex: 'listings',
      render: (text,record)=> (
        <div className='plview'>
        {record.listings_done >= record.listings && record.listings_done > 0?
        <div className='greengroup'>{record.listings} ({record.listings_done})</div>:<div className='redgroup'>{record.listings} ({record.listings_done})</div>
         } 
          
       </div>
      )
      }, 
      { title: 'Deals', dataIndex: 'deals',
      render: (text,record)=> (
        <div className='plview'>
        {record.deals_done >= record.deals && record.deals_done > 0?
        <div className='greengroup'>{record.deals} ({record.deals_done})</div>:<div className='redgroup'>{record.deals} ({record.deals_done})</div>
         } 
          
       </div>
      )
      }, 
      { 
        title: 'Hottest Listings',
          dataIndex: 'refno',key: 'refno', 
        render: (text, record) => (
           // console.log("rec",record.pl_listing)
           record.pl_listing.map((r_l)=>(
            <div>
            <Link onClick={this.handleListings.bind(this,r_l.id)}  style={{lineHeight:2}}>
              <Popover content={this.handleContent.bind(this,r_l)} title="Details" trigger="hover">
                {r_l.refno}
    </Popover></Link>
            </div>
        ))
        ),
        
       }, 
        { title: 'Hottest Clients',
        // dataIndex: 'leads_1',key: 'leads_1', 
        render: (text, record) => (
            record.pl_leads.map((r_lead)=>(
                <div>
                  <Link onClick={this.handleLead.bind(this,r_lead.id)} style={{lineHeight:2}}>
                     {r_lead.firstname==null ? "" : r_lead.firstname + " "}{r_lead.lastname}
                 </Link>
                 </div>
              ))
         ),
        }, 

       

        { title: 'Feedback', dataIndex: 'feed_back',key: 'feed_back', width:250
    }];

    const handleTableChange = (pagination,filters) => {
      const all_data = store.getState((state) => state);
      const isPledge = all_data.pledge_statistics_reducer.isPledge
      const redux_selectedAgents = all_data.pledge_statistics_reducer.selectedAgents
     

        const data = {selectedAgents:this.state.selectedAgents,dateRange:this.state.dateRange,isPledge:isPledge==true?1:0,redux_selectedAgents:redux_selectedAgents}

        this.fetchData(pagination.current, pagination.pageSize,data);

    };

    return (
        <>
           <ToastContainer />

            <Modal className='pledge' width={600} visible={this.state.visible} 
           
            okText={"Save"} 
            onCancel={this.handleCancel} 
            onOk={this.handleOk} 
            // footer={null}
             style={{borderRadius:"0.3rem", overflow:'hidden'}}>
                <div className='row title'>
                    <h1 className="fs-6 font-bold text-uppercase mb-0">Pledge</h1>
                   
                </div>
                <div className='row d-flex flex-row w-100' >
                    <div className='form-group col-6'>
                        <label className="font-bold cont-12">AED Target For The Week <em>*</em></label>
                        {/* <EdiText

                       value={this.state.target}
                        type="text"
                        onSave={this.handleSaveTarget}

                        /> */}
                        <input value={this.state.target} className="form-controls font-14" placeholder="Target" onChange={this.handleTarget} />
                    </div>
                    <div className='form-group col-6'>
                        <label className="font-bold font-12">New Deals For This Week <em>*</em></label>
                        {/* <EdiText

                        value={this.state.deals}
                        type="text"
                        onSave={this.handleSaveDeal}

                        /> */}
                        <input value={this.state.deals} className="form-controls font-14" placeholder="Deals" onChange={this.handleDeal} />
                    </div>
                </div>
                <div className='row d-flex flex-row w-100 pt-1'>
                    <div className='form-group col-6'>
                        <label className="font-bold font-12">New Listings For This Week <em>*</em></label>
                        {/* <EdiText

                        value={this.state.new_listings}
                        type="text"
                        onSave={this.handleSaveNewListing}

                        /> */}
                        <input value={this.state.new_listings} className="form-controls font-14" placeholder="Listings" onChange={this.handleNewListings} />
                    </div>
                    <div className='form-group col-6'>
                        <label className="font-bold font-12">New Viewings For This Week <em>*</em></label>
                        {/* <EdiText

                            value={this.state.new_viewings}
                            type="text"
                            onSave={this.handleSaveNewViewing}

                            /> */}
                        <input value={this.state.new_viewings} className="form-controls font-14" placeholder="Viewings" onChange={this.handleNewViewings} />
                    </div>
                </div>

                <div className='row d-flex flex-row w-100 pt-1'>
                    <div className='form-group col-12'>
                        <label className="font-bold font-12">Feedback </label>
                        {/* <EdiText

                        //value={this.state.lead_details.owner}
                        type="text"
                        onSave={this.handleSaveOwnerName}

                        /> */}
                     
                        <input value={feeds_back?feeds_back:""} className="form-controls font-14" placeholder="feedback" onChange={this.handleFeedback} /> 
                        {/* <textarea class="form-control" placeholder="feedback" onChange={this.handleFeedback}  >{this.state.feed_back}</textarea> */}
                    </div>
                 
                </div>
             

            </Modal>
                <div className='contentwrap pledge'>
                    <div className='ml-auto d-flex px-0'>
                        <div className='px-3 w-100'>
                            <Space direction="vertical">
                                <Space wrap>
                                    <div className="d-flex flex-row justify-content-between my-3 w-100 align-items-center">
                                        <h3 className="mb-0 px-2">Pledge</h3>
                                        {/* For Management only */}
                                        <div  className="d-flex flex-row justify-content-between ml-auto mgmtfilter">
                                        <div className='form-group flex-row align-items-center mb-0 mr-2'>
                                                <label className='mr-2 mb-0' style={{whiteSpace:'nowrap'}}>Date:</label>
                                                <RangePicker onChange={this.handleRangeDate} onOk={this.handleRangeDateOk} />
                                                </div>
                                                {role ==1?       <div className='form-group flex-row align-items-center mb-0 ml-2'>
                                                <label className='mr-2 mb-0' style={{whiteSpace:'nowrap'}}>Filter by Brokers:</label>
                                                <Select className='mr-2'
                                                allowClear
                                                showSearch
                                                style={{ minWidth:'200px',
                                                    width: '100%',
                                                }}
                                                value={this.state.community_filter}

                                                placeholder="Select agent"
                                                onChange={this.handleAgent}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={this.state.agents}
                                            />
                                            </div>:null}
                                     
                                        </div>
                                    </div>
                                </Space>
                            </Space>
                        </div>
                    </div>
                </div>
            <div class="table-responsive tablelistwrap px-3 pledge " >
            <Table onChange={handleTableChange}


loading={this.state.isLoading}
//className="table-striped table-bordered"
pagination={{
    pageSizeOptions: ['10', '20','50','100'],
    total:
        this.state?.pledges?.total > 0 &&
        this.state.pledges.total,
    pageSize: this.state.pledges.per_page,
    current: this.state.pledges.current_page,
    showTotal: (total, range) =>
        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
    showSizeChanger: true,
    onShowSizeChange: onShowSizeChange,
    itemRender: itemRender,
}}
style={{ overflowX: "auto" }}
columns={role==1?columns:agent_columns}
dataSource={
    this.state?.pledges?.data
        ? this.state?.pledges?.data
        : []
}

//    rowKey={(record) => record}
// Change By Farooq
rowKey={(record) => record.id}
/>
            </div>
        </>
    )
  }
}

export default  Pledge;
