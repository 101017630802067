import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin, TokenResponse, CodeResponse } from "@react-oauth/google";
import GoogleLoginButton from '../Calender/GoogleLoginButton';
import { oauth_client_key, oauth_redirect_url } from '../../components/BaseUrl';
import { connect } from 'react-redux';
import "./login.css"
import axios  from 'axios';
import { Link } from 'react-router-dom';
import {
  LoginSocialGoogle,
  // LoginSocialAmazon,
  // LoginSocialFacebook,
  // LoginSocialGithub,
  // LoginSocialInstagram,
  // LoginSocialLinkedin,
  // LoginSocialMicrosoft,
  // LoginSocialPinterest,
  // LoginSocialTwitter,
  // LoginSocialApple,
  // IResolveParams,
} from 'reactjs-social-login';
import API from '../../utils/API';

// import {
//   FacebookLoginButton,
//   GoogleLoginButton,
//   GithubLoginButton,
//   AmazonLoginButton,
//   InstagramLoginButton,
//   LinkedInLoginButton,
//   MicrosoftLoginButton,
//   TwitterLoginButton,
//   AppleLoginButton,
// } from 'react-social-login-buttons';

class Login extends Component {

    constructor(props){
        super();
        this.state  = {
          email:'',
          password: '',
          errors:{},
          isLoading:false,
          check_status:false,
          input: {},
          validationerrors: {},
          has_error:false,
          error_message:''
        }
      
    }

    

    handleInput = (e) =>{
      e.preventDefault();
      const name = e.target.name;
      const value = e.target.value;
      let input = this.state.input;
      input[e.target.name] = e.target.value;

      this.setState({[name]:value,input:input})
  }

    buttonRender =() => {
      if(this.state.isLoading){
          return <ClipLoader color={"blue"} loading={true}  size={30} />
      }
      return (
      <button className="login100-form-btn" onClick={this.handeLogin}>
        Login
      </button>
      )
  }

     handeLogin = (e) => {
      e.preventDefault();
      const data = {email:this.state.email,password:this.state.password}


     

        if(this.validate()){
          this.setState({isLoading:true})

          API.post("/login",data)
          .then((res) =>{
            console.log(res)
            this.setState({isLoading:false})
            if(res?.data)
            this.props.setCredentials(res.data) 
          })
          .catch((e) =>{
            this.setState({isLoading:false})
          })
         
        }
       
        
    }

    validate(){
      let input = this.state.input;
      let validationerrors = {};
      let isValid = true;    
  
       
       if (!input["email"]) {
        isValid = false;
        validationerrors["email"] = "Please enter your email Address.";
       }
  

        if (!input["password"]) {
          isValid = false;
          validationerrors["password"] = "Please enter your password .";
        }

 



      // if (typeof input["email"] !== "undefined") {
          
      //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      //   if (!pattern.test(input["email"])) {
      //     isValid = false;
      //     validationerrors["email"] = "Please enter valid email address.";
      //   }
      // }
  
 
  
      this.setState({
          validationerrors: validationerrors
      });
  
      return isValid;
  }

     onLoginStart = (() => {
      alert('login start');
    }, []);

    handleGoogleLogin = (req) =>{

      const user = {
        name:req.data.name,
        email:req.data.email
      }
      const userdata = {
        user:user,
        access_token:req.data.access_token
      }

     
      
      const data = {
        email:req['data']['email'],
        token:req['data']['access_token']
      }
      API.post("/google_login",data)
      .then((response) =>{

        

         if(response.data){
          this.props.setCredentials(response.data)
         }
        // if(response.data.success){
        //   this.setState({has_error:false,error_message:''})

        //   this.props.history.push("/dashboard");
        // }else{
        //   this.setState({has_error:true,error_message:response.data.msg})
         
        // }
      })
    }
    

  render() {
    const REDIRECT_URI =
  'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
    return (
        <div className="limiter">
        <div className="container-login100"  style={{backgroundImage: 'url("images/addressskyviewblur.webp")'}}>
          <div className="wrap-login100">
            <div className="login100-form validate-form text-center">
            <img src="images/LP-NExa-Black-MWebsite.svg" width="300px" />
            <p style={{fontFamily: '"Poppins-Bold", sans-serif',textTransform:'uppercase', fontSize:'2.3rem', marginBottom:0, }}>CRM</p>

            {this.state.has_error?<div className='text-danger mt-3'>{this.state.error_message}</div>:null}
              {/* <span className="login100-form-title p-b-20">
                
              </span> */}
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" name="email" value={this.state.email}  onChange={this.handleInput}/>
                <label for="floatingInput" style={{color:'grey'}}>Email address or mobile(ex: +971xxxxx)</label>
                <div className="text-danger">{this.state.validationerrors.email}</div>
              </div>

              <div class="form-floating">
                <input type="password" class="form-control" id="floatingPassword" placeholder="Password" name="password" value={this.state.password}  onChange={this.handleInput} />
                <label for="floatingPassword" style={{color:'grey'}}>Password</label>
                <div className="text-danger">{this.state.validationerrors.password}</div>
              </div>
             
              <div className="flex-sb-m w-full p-t-3 p-b-32">
                <div className="contact100-form-checkbox">
                  <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                  <label className="label-checkbox100" htmlFor="ckb1">
                    Remember me
                  </label>
                </div>
                <div>
                  <Link to="/forgotpassword" className="txt1">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="container-login100-form-btn">
              {this.buttonRender()}
               
              </div>
              <div className="text-center p-t-46 p-b-20">
                <span className="txt2">
                  OR
                </span>
              </div>
              
              <div id="authorizeButton" className="login100-form-social flex-c-m">
            <GoogleOAuthProvider clientId={oauth_client_key}>
                <GoogleLoginButton />
            </GoogleOAuthProvider>
        </div>
              {/* <div className="login100-form-social flex-c-m"> */}
              {/* <LoginSocialGoogle
                  client_id="133385170276-dqag67k4l2u2gfanidqeskrds890ado8.apps.googleusercontent.com"
                  // onLoginStart={this.onLoginStart}
                  redirect_uri={REDIRECT_URI}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  access_type="offline"
                  onResolve={res =>{
                   this.handleGoogleLogin(res)
                  }}
                  onReject={err => {
                    console.log(err);
                  }}
                >
                <Link className="login100-form-social-item flex-c-m btn-danger m-r-5">
                  <i className="fa fa-google" aria-hidden="true" />
                </Link>
                </LoginSocialGoogle> */}

{/*               
                <a href="#" className="login100-form-social-item flex-c-m bg2 m-r-5">
                <i class="fa fa-linkedin" aria-hidden="true" />

                </a> */}
              {/* </div> */}
            </div>
            <div className="login100-more" style={{backgroundImage: 'url("images/addressskyview.webp")'}}>
            </div>
          </div>
        </div>
      </div>
      
    )
  }
}


const mapDispatchToProps = dispatch =>{
  return {
      setCredentials: (data) => dispatch({type: "SET_LOGIN",payload:data})
  }
}

export default connect(null,mapDispatchToProps)(Login)

