import React, {Component} from 'react'
import 'antd/dist/reset.css';
import {Table, Space, Card, Pagination, Row, Col, Button, Input} from 'antd'
import {itemRender, onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {oldBaseurl} from "../../components/BaseUrl";
import './document.css'
import {EyeOutlined, FileTextOutlined, FileWordOutlined, YoutubeOutlined} from '@ant-design/icons';

let columns = []
const { Meta } = Card;

class DocumentsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            documents: [],
            files: [],
            currentPage: 1,
            perPage: 16,
            totalDocuments: 0,
            searchQuery: '',
            allDocuments: [],
        }
    }

    componentDidMount() {
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')

        this.fetchData(1, this.state.perPage);
    }


    fetchData = (page, perPage) => {
        this.setState({ isLoading: true });
    
        API.get(`/documents?page=${page}&perPage=${perPage}`)
            .then(async (response) => {
                const data = await response.data.documents;
                // console.log('Fetched Data:', data);
                this.setState({
                    documents: data,
                    allDocuments: data.data,
                    totalDocuments: data.total, // Update total documents
                    isLoading: false,
                    currentPage: data.current_page, // Keep track of the current page
                    nextPageUrl: data.next_page_url, // Store next page URL
                     prevPageUrl: data.prev_page_url, // Store previous page URL
                }, () => {
                    // Scroll to the top after updating state
                    window.scrollTo(0, 0);
                });
            })
            .catch(error => {
                console.error('Error fetching documents:', error);
                this.setState({ isLoading: false });
            });
    };

    handlePreviewClick = (url) => {
        if (url.startsWith("https://")) {
          window.open(url, '_blank');  // Open the link in a new tab
        } else {
          window.open(`https://crm2.luxuryproperty.com/${url}`, '_blank');
        }
      };

    renderImage = (document) => {
        const { url, type } = document;
    
        // Check file type and return the appropriate preview
        if (url.includes('.pdf')) {
            return (<>
              <iframe loading="lazy"
                title={document.name}
                src={url.startsWith("https://") ? `${url}#page=1` : `https://crm2.luxuryproperty.com/${url}#page=1`}
                style={{ width: "100%", height: "18rem", overflow:'hidden', cursor:'pointer', }}
                onClick={() => this.handlePreviewClick(url)}
              />
              <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
            </>);
        } else if (url.includes('.docx') || url.includes('docs.google.com')) {
            return (<div className="docxwrap" style={{ width: "100%", height: "18rem", overflow:'hidden', cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center', background:'rgba(21,21,21,0.06)' }}>
                <FileWordOutlined style={{fontSize:'6rem', color:'rgba(21,21,21,0.1)'}}/>
                <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
                </div>
              );
        } else if (url.includes('issuu.com')) {
            return (<div className="docxwrap" style={{ width: "100%", height: "18rem", overflow:'hidden', cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center', background:'rgba(21,21,21,0.06)' }}>
                <FileTextOutlined style={{fontSize:'6rem', color:'rgba(21,21,21,0.1)'}}/>
                <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
                </div>
              );
        } else if (url.includes('.mp4')) {
            return (<div className="docxwrap" style={{ width: "100%", height: "18rem", overflow:'hidden', cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center', background:'rgba(21,21,21,0.06)' }}>
                <YoutubeOutlined style={{fontSize:'6rem', color:'rgba(21,21,21,0.1)'}}/>
                <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
                </div>
              );
        } else {
            return (<>
              <img
                alt={document.name}
                src={url.startsWith("https://") ? `${url}` : `https://crm2.luxuryproperty.com/${url}`}
                style={{ height: "18rem", objectFit: "cover", objectPosition:'top' }}
              />
              <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
              </>
            );
        }
    };

    handleSearch = (searchQuery) => {
        const allDocuments = this.state.allDocuments;   console.log(allDocuments)
    
        if (searchQuery === '') {
            // Reset to show all documents
            this.setState({
                documents: { data: allDocuments},
                searchQuery,
                currentPage: 1,
            });
        } else {
            const filteredDocuments = allDocuments?.filter(document => 
                document.name?.toLowerCase().includes(searchQuery.toLowerCase())
            ) || [];
    
            this.setState(
                {
                    documents: { data: filteredDocuments },  // Assuming `documents` has a `data` array
                    searchQuery,
                    currentPage: 1,
                },
                () => console.log(this.state.documents) // Log after state update
            );
        }
    };
    

    render() {

        return (
            <div className='pb-5'>
                <ToastContainer/>
                <Row gutter={16} className='justify-content-between ' style={{padding:'2rem 3.7rem 1rem'}}>
                    <Col span={12}>
                        <h1>Documents</h1>                  
                    </Col>
                    {/* <Col span={12} style={{display:'flex', justifyContent:'flex-end'}}>
                        <Input className="ml-auto"
                            placeholder="Search documents"
                            value={this.state.searchQuery}
                            onChange={(e) => this.handleSearch(e.target.value)} // Call handleSearch directly
                            style={{ width: '100%', marginRight: '10px', height:46 }}
                        />
                    </Col> */}
                </Row>

                <div className="documentlist-wrap" style={{padding:'1rem 3rem'}}>
                    <Row gutter={[21,'2rem']}>
                        {this.state?.documents?.data?.length > 0 ? (
                        this.state.documents.data.map((document) => (
                            <Col  xs={24} sm={12} md={12} lg={6}>
                            <Card
                            key={document.id}
                            bordered
                            style={{ boxShadow: '0 0 10px rgba(21, 21, 21, 0.16)', margin: '0 1rem 2rem 1rem', display: 'flex', flexDirection: 'column', height: '100%' }} 
                            actions={[
                                <a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a>,
                                // <Button className="py-0 mx-auto" type='link' style={{textDecoration:'none', gap:'0.3rem', margin:0, display:'flex', alignItems:'center', color:'#151515'}}><EditOutlined key="edit"  />Edit</Button>,
                            ]}
                            cover={this.renderImage(document)}
                            >
                            <Meta title={<p className={"font-bold"} style={{ display:'flex', whiteSpace:'wrap', overflow:'visible', flexGrow: 1 }}>{document.name}</p>} />
                            </Card>
                            </Col>
                        ))
                        ) : (
                        <p>No documents available</p>
                        )}

                    </Row>
                    <Row className='pt-5'>
                        <Col span={24} style={{display:'flex', justifyContent:'center'}}>
                            <Pagination style={{gap:'0.6rem'}}
                                current={this.state.currentPage}
                                pageSize={this.state.perPage}
                                total={this.state.totalDocuments}
                                onChange={(page) => this.fetchData(page, this.state.perPage)}
                                showSizeChanger={false} // You can enable this if you want to allow page size changes
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}


export default DocumentsList