import React, {Component} from 'react'
import 'antd/dist/reset.css';
import {Table, Space,Button,Select,Avatar,Input,DatePicker,Modal,Tooltip} from 'antd'
import {itemRender, onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {baseurlImg, oldBaseurl} from "../../components/BaseUrl";
import dayjs from 'dayjs'
import {ClockCircleOutlined} from "@ant-design/icons";
import {Link} from 'react-router-dom'

// import './document.css'

let columns = []

class SharedListings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sharedlistings: [],
            files: [],
            selectedRowKeysTest: [],
            ref_def_nums:[],
            agents:[],
            viewing_agent:[],
            suggestions:[],
            isShareLinkOpen:false,
            unitno_data:[],
            viewing_unit_no:"",
            isSharing:false
        }
    }

    componentDidMount() {
        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')

        const page = 1;
        const perPage = 10;

        this.fetchData(page, perPage);
    }


    fetchData = (page, perPage, data) => {
        this.setState({isLoading: true})

        API.post(`/hash_links?page=${page}&perPage=${perPage}`)
            .then(async (response) => {
                var data = await response.data.hash_links;
                this.setState({
                    sharedlistings: data,
                    isLoading: false
                })
            })

    }

    handleSearchUintNo = (e) => {
        this.setState({ref_nums: []})
        if (!e.target.value || e.target.value == "" || e.target.value == null) {
          this.setState({ unit_nums: [] });
          this.setState({ suggestions: [] ,viewing_unit_no: e.target.value});
          return;
        }
        this.setState({viewing_unit_no: e.target.value})
        const data = {
          id: e.target.value,
          page: 1,
          perPage: 5
        }
        API.post("/get_listing_by_unitno", data).then((response) => {
          this.setState({isListingLoading: false});
          if (response.data.success) {
      
    
      
    
            this.setState({ unit_nums: response.data.unit_nums });
            this.setState({ suggestions: response.data.unit_nums });
          }
        });
      };

      handleRowSelectChange = (rowKey,selectedRowKeysTest) => {
  
        for (let i = 0; i < selectedRowKeysTest.length; i++) {
          if(selectedRowKeysTest[i].agent_id ==430){
            toast.warning("You can't select pond !")
            return
          }
      
          
        }
      
      
        const uniqueAgentIds = [...new Set(selectedRowKeysTest.map(item => item.agent_id))];
      
        const refno_unitno = this.state.unitno_data
      
      
        const refno_data_mergedArray = [...new Set([...refno_unitno, ...selectedRowKeysTest])];
      
      
        
      
        
        const mergedArray = [...new Set([...this.state.viewing_agent, ...uniqueAgentIds])];
        console.log("mergedArray",mergedArray);
      
        this.setState({ viewing_agent:mergedArray });
        console.log("viewing_agent",this.state.viewing_agent);
        const agents = this.state.agents;
        const matchingProfilePictures = agents
        .filter(agent => mergedArray.includes(agent.value))
        .map(agent => agent.profile_picture);
      this.setState({agent_profile_picture: matchingProfilePictures});
      
      
      // this.setState({ refno_data: refno_data_mergedArray});
      

        
          this.setState({ unitno_data: refno_data_mergedArray });
        
        this.setState({ selectedRowKeysTest:rowKey});
      };

      handleViewingOk = () => {
       
    const unitnoData = this.state.unitno_data;
    const viewing_ref_no = unitnoData.map(item => item.refno);
    const data = {
      
        viewing_ref_no: viewing_ref_no
      };
      const page = 1
      const perPage = 10
      this.setState({isSharing:true})
      
      API.post("/addsharelink",data)
      .then((res)=>{
        if(res.data.success){
            this.setState({isShareLinkOpen:false,unitno_data:[],suggestions:[],viewing_unit_no:"",isSharing:false})
            this.fetchData(page,perPage)
        }
      })
     
      }

      handleViewingCancel = () =>{
        this.setState({isShareLinkOpen:false})
      }
    render() {
        const onSelectChange = (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        };

        const rowSelection = {
            onChange: onSelectChange,

        };
        const selectedRowKeysTest = this.state.selectedRowKeysTest;

        const rowSelectionTest = {
     
            selectedRowKeys: selectedRowKeysTest,
            onChange: this.handleRowSelectChange
          };
      

        columns = [
            {
                title: 'Hash ID',
                dataIndex: 'hash_id',
               
            },

            {
                title: 'listings refno',
                dataIndex: 'listings_refno',
             
            },
            {
                title: ' Date',
                dataIndex: 'added_date',
                render: (added_date) => (
                    <Moment fromNow>
                        {added_date}
                    </Moment>
                ),
            },
        ];
        const addViewingStructure = [
            {
              key: (record) => record.refno,
              title: "Property",
              dataIndex: "propertyinfo",
              render: (text, record) => (
                <Link target="_blank" to={"/all_listings/" + record.id}>
                  <div className="info-group w-100 propertyinfo">
                    <div className="d-flex flex-row justify-content-between">
                      <p className="info-title">
                        {(record.unitno) && <span className="font-bold font-12">{record.unitno}</span>} {(record.unitno) && "|"} {record.refno}
                      </p>
                    </div>
                    <p>
                      {record.property?record.property+",":""}
                    {record.sub_community}, {record.community}
                    </p>
                  </div>
                </Link>
              ),
            },
            {
              key: (record) => record.property_for,
              title: "Property for",
              dataIndex: "property_for",
              render: (text, record) => (
                  <div className="info-group w-100 propertyinfo">
                       <div className="d-flex flex-row justify-content-between">
                      <p className="info-title">
                      Property for
                      </p>
                    </div>
                    <p>
                      {record.property_for}
                    </p>
                  </div>
              ),
            },
            {
              key: (record) => record.display_name,
              title: "Agent",
              dataIndex: "display_name",
              render: (text, record) => (
                  <div className="info-group w-100 propertyinfo">
                      <div className="d-flex flex-row justify-content-between">
                      <p className="info-title">
                      Agent
                      </p>
                    </div>
                    <p>
                      {record.display_name}
                    </p>
                  </div>
              ),
            },
          ];
        const handleTableChange = (pagination) => {
            this.fetchData(pagination.current, pagination.pageSize);
        };

        return (
            <div>
                <ToastContainer/>

                <Modal
          width={'70vw'}
          title={<><i class="bi bi-calendar-plus"></i> Add a Viewing</>}
          open={this.state.isShareLinkOpen}
          onOk={this.handleViewingOk}
          onCancel={this.handleViewingCancel}
          confirmLoading={this.state.isSharing}
        >          

          <div className="row w-100">
            <div className="col-md-5 pl-0 pr-3 border-right ">
         
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="form-group">
                    <label className="">Unit no:</label>
                    <Input 
               style={{
                width: "100%",
              }}
                onChange={this.handleSearchUintNo}
                name="unitno" 
                value={this.state.viewing_unit_no}
                placeholder="Select Unit no"
                />

                  </div>
                </div>
             
              </div>
          
            </div>
            <div className="col-md-7">
            <div className="row">
                <div className="col-12">

                <Table
                onChange={this.handleSuggestionsTableChange}
                loading={this.state.isListingLoading}
                rowKey={(record) => record.refno}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 5,
                  pageSize: 5,
                  total:
                    this.state?.suggestions?.total > 0 &&
                    this.state.suggestions.total,
                  current: this.state.suggestions.current_page,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,

                  itemRender: itemRender,
                }}
                showHeader={false}
                columns={addViewingStructure}
                dataSource={
                  this.state?.suggestions?.data ? this.state?.suggestions?.data : []
                }
                rowSelection={rowSelectionTest }
              />

                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Ref no</th>
                        <th scope="col">Unit no</th>
                        <th scope="col">Community</th>
                        <th scope="col">Subcommunity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.refno_data && this.state.refno_data.map((s_data) => (
                        <tr>
                          <th>{s_data.refno}</th>
                          <th>{s_data.unitno}</th>
                          <td>{s_data.community}</td>
                          <td>{s_data.sub_community}</td>
                        </tr>
                      ))}

{this.state.unitno_data && this.state.unitno_data.map((s_data) => (
                        <tr>
                          <th>{s_data.refno}</th>
                          <th>{s_data.unitno}</th>
                          <td>{s_data.community}</td>
                          <td>{s_data.sub_community}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </Modal>
                <div className='contentwrap' style={{

                    minHeight: 380,
                }}>

                    <div className='ml-auto d-flex px-0'>

                        <div className='row px-3 w-100'>
                            <Space direction="vertical">


                                <Space wrap>

                                    <div className="d-flex flex-row justify-content-between my-2 w-100">
                                        <h3 className="mb-0">Shared Lists</h3>
                                        <Button onClick={() =>this.setState({isShareLinkOpen:true})} type="primary">New Link</Button>
                                    </div>

                                </Space>
                            </Space>
                        </div>
                    </div>

                    <div class="table-responsive tablelistwrap px-3">
                        <ReactDragListView.DragColumn onDragEnd={this.onDragEnd}
                                                      nodeSelector="th">

                            <Table onChange={handleTableChange}
                                   loading={this.state.isLoading}
                                //    rowSelection={rowSelection}
                                   pagination={{
                                       pageSizeOptions: ['10', '20', '50', '100'],
                                       total:
                                           this.state?.sharedlistings?.total > 0 &&
                                           this.state.sharedlistings.total,
                                       pageSize: this.state.sharedlistings.per_page,
                                       current: this.state.sharedlistings.current_page,
                                       showTotal: (total, range) =>
                                           `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                       showSizeChanger: true,
                                       onShowSizeChange: onShowSizeChange,
                                       itemRender: itemRender,
                                   }}
                                   style={{overflowX: "auto"}}
                                   columns={columns}
                                   bordered
                                   dataSource={
                                       this.state?.sharedlistings?.data
                                           ? this.state?.sharedlistings?.data
                                           : []
                                   }

                                //    rowKey={(record) => record}
                            />
                        </ReactDragListView.DragColumn>

                    </div>
                </div>
            </div>
        )
    }
}


export default SharedListings