import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumb,Button, Checkbox, Form, Input,InputNumber,Select,Switch,Table } from 'antd';
import { SaveOutlined} from '@ant-design/icons';

import API from '../../utils/API';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

 class AddUser extends Component {

    constructor(props){
        super(props);
        this.state ={
            modules:[],
            permissions:[],
            user_status:false
        }
    }

    componentDidMount(){
        API.get("/permissions")
        .then((response) =>{
            var data = response.data.permissions
            for (let i = 0; i < data.length; i++) {
                data[i].ischecked = false;
            }
            this.setState({permissions:data})


        })
    }

     validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };

      handleUserStatus = (value) =>{
       this.setState({user_status:value})
      }

      onChangePermission = (permission_id,e)=>{
       
        let index = this.state.permissions.findIndex(obj => obj.id ===permission_id);
        var mydata = this.state.permissions;

      

        var i;
        for (i = 0; i < mydata.length; i++) {
           
          if (i == index) {

           
           
            mydata[i].ischecked = !mydata[i].ischecked;
            
          }
        }

        this.setState({permissions:mydata})


      }



       onFinish = (values) => {
      
        const data = {
            form_values:values,
            permissions:this.state.permissions,
        }

       

        API.post("/createUser",data)
        .then((response) =>{
           if(response.data.success){
                 this.props.history.push("/users");
           }
        })
      };



  render() {

   

    const dataSource = [
        {
          key: '1',
          name: 'Mike',
          age: 32,
          address: '10 Downing Street',
        },
        {
          key: '2',
          name: 'John',
          age: 42,
          address: '10 Downing Street',
        },
      ];
      
      const columns = [
        {
          title: 'Name',
          dataIndex: 'module_name',
          key: 'module_name',
        },
        
      ];

    return (
        <div>
        <Breadcrumb
         style={{
           margin: '16px 0',
         }}
       >
        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/users">All Uses</Link></Breadcrumb.Item>
         <Breadcrumb.Item>Add User</Breadcrumb.Item>
         
       </Breadcrumb>
       <div style={{
           padding: 24,
           minHeight: 380,
           background: "#fff",
         }}>
      <Form
    {...layout}
    name="nest-messages"
    onFinish={this.onFinish}
   
    validateMessages={this.validateMessages}
  >
    <Form.Item
      name="name"
      label="Name"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="email"
      label="Email"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          type: 'email',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      label="Confirm Password"
      name="c_password"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          required: true,
          message: 'Please input your confirm password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

   
    <Form.Item label="Team" name="team_id"  style={{
      maxWidth: 600,
    }}>
        <Select  onChange={this.handleTeam}>
          <Select.Option value="1">Sales</Select.Option>
          <Select.Option value="2">Rent</Select.Option>
          <Select.Option value="3">other</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="user_status" label="Active / Inactive" valuePropName="checked"   style={{
        maxWidth: 600,
      }}>
        <Switch  defaultChecked={this.state.user_status} onChange={this.handleUserStatus} />
      </Form.Item>
      {/* <h4>Permissions:</h4>

        <hr/>

      <div className='row'>

        

   


   {this.state.permissions.map((permission,index) =>{
     
   return  (<div className='col-md-3'>
        <Checkbox checked={permission.ischecked} onChange={this.onChangePermission.bind(this,permission.id)}  >
        {permission.name}
       </Checkbox>
      
       
       </div>)
      
       
     })}

 
</div> */}

    <Form.Item
      wrapperCol={{
        ...layout.wrapperCol,
       // offset: 8,
      }}
      style={{
        maxWidth: 600,
        marginTop:'50px'
      }}
    >
      <Button type="primary" htmlType="submit">
      <SaveOutlined /> Save
      </Button>
    </Form.Item>
  </Form>



       </div>
       </div>
    )
  }
}

export default AddUser;
