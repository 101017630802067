import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import 'antd/dist/reset.css';
import { Table, Space, Select, Tag, DatePicker, Modal, Row, Col, Skeleton, Button, Popover, Collapse } from 'antd'
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { liveUrl } from '../../components/BaseUrl';

import { ClearOutlined, UploadOutlined } from '@ant-design/icons';
import './listing.css'
import { store } from '../../store';
import TextArea from 'antd/es/input/TextArea';
import Swal from 'sweetalert2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import dayjs from "dayjs";
import moment from "moment/moment";
import { Excel } from "antd-table-saveas-excel";
const { Panel } = Collapse;
let columns = []




class Deals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deals_listing_array: [],
      deals_listing_id: [],
      isLoading: false,
      isTotalLoading: false,
      all_viewings: [],
      data_source: [],
      communities: [],
      sub_communities: [],
      properties: [],

      total_deals: 0,
      count_deals: 0,
      total_commission: 0,
      total_sales_volume: 0,

      // filters
      community_filter: "",
      sub_community_filter: "",
      property_filter: "",
      viewing_feedback: 0,
      property_for_filter: [],
      // ref no
      refno_first_value_id: 1,
      refno_second_value_id: 1,
      refno_active_second_option: false,
      refno_and_or_option: true,
      refno_first_value: "",
      refno_second_value: "",
      // unit no
      unitno_first_value_id: 1,
      unitno_second_value_id: 1,
      unitno_active_second_option: false,
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_second_value: "",
      // status
      status_filter: [],
      offer_status_filter: [],
      // agent filter
      agent_value_id: 1,
      agent_value: "",

      // client filter
      client_value_id: 1,
      client_value: "",

      // price
      price_first_value_id: 1,
      price_second_value_id: 1,
      price_active_second_option: false,
      price_and_or_option: true,
      price_first_value: "",
      price_second_value: "",
      inrange: false,
      price_min: 0,
      price_max: 0,

      // offers
      offers: {},
      offer_id: "",
      offers_agent: "",
      offers_date: "",
      offers_note: "",
      offers_status: 1,
      offers_price: "",
      offers_commission: "",
      offerModal: false,
      showPrice: false,
      showDate: false,
      role: "",

      month: "",

      months: [{
        key: 1,
        labe: "January"
      },
      {
        key: 2,
        labe: "February"
      },
      {
        key: 3,
        labe: "March"
      },
      {
        key: 4,
        labe: "April"
      },
      {
        key: 5,
        labe: "May"
      },
      {
        key: 6,
        labe: "June"
      },
      {
        key: 7,
        labe: "July"
      },
      {
        key: 8,
        labe: "August"
      },
      {
        key: 9,
        labe: "September"
      },
      {
        key: 10,
        labe: "October"
      },
      {
        key: 11,
        labe: "November"
      },
      {
        key: 12,
        labe: "December"
      },

      ],

    }

    this.textInput = React.createRef();

  }

  componentDidMount() {

    var loadScript = function (src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    }
    loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
    //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')

    const page = 1;
    const perPage = 10;
    const all_data = store.getState((state) => state);
    const deals_listing_array = all_data.stock_area_reducer.deals_listing_array
    this.setState({ deals_listing_array: deals_listing_array })

    const deals_listing_id = all_data.stock_area_reducer.deals_listing_id
    this.setState({ deals_listing_id: deals_listing_id })

    const data = {
      deals_listing_array: deals_listing_array,
      deals_listing_id: deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }




    this.fetchData(page, perPage, data);
    const monthData = { month: this.state.month }
    this.fetchTotal(monthData)

    API.get("/community")
      .then((response) => {
        if (response.data.success) {
          this.setState({ communities: response.data.communities })
        }
      })

  }

  handleDeleteOffer = (offer_id) => {
    if (offer_id == "" || offer_id < 1)
      return;
    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }


    this.fetchData(page, perPage, data);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {

        API.delete("/delete_offer/" + offer_id)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchData(page, perPage, data)
            }

          })
      }
    })
  }


  fetchTotal = (monthData) => {
    this.setState({ isTotalLoading: true })
    API.post("/total_deals", monthData)
      .then(async (response) => {


        if (response.data.success) {
          this.setState({
            isTotalLoading: false,
            total_deals: response.data.total_deals,
            count_deals: response.data.count_deals,
            total_commission: response.data.total_commission,
            total_sales_volume: response.data.total_sales_volume
          })
        }
      })
  }

  fetchData = (page, perPage, data) => {
    this.setState({ isLoading: true })
    //    setTimeout(() => {


    API.post(`/all_listings_leads_deals?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {
        var data = await response.data.all_viewings.data;

        await response.data.all_viewings;
        this.setState({
          all_viewings: response.data.all_viewings,
          isLoading: false
        })

        this.setState({
          role: response.data.role.role,
        })

      })
    // }, 200);

  }

  handleCommunityFilter = (e) => {


    this.setState({ community_filter: e })

    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })

    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: e,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    this.fetchData(page, perPage, data)

  }

  handleSubCommunityFilter = (e) => {

    this.setState({ sub_community_filter: e })
    API.get("/property/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ properties: response.data.properties })
      })
    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: e,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    this.fetchData(page, perPage, data)
  }

  handlePropertyFilter = (e) => {
    this.setState({ property_filter: e })
    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: e,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }
    this.fetchData(page, perPage, data)
  }

  handleRefnoFirstValue_id = (e) => {
    this.setState({ refno_first_value_id: e.target.value })
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: e.target.value,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleRefnoFirst = (e) => {

    const refno_first_value = e.target.value;
    this.setState({ refno_first_value: refno_first_value })

    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleUnitnoFirstValue_id = (e) => {
    this.setState({ unitno_first_value_id: e.target.value })
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: e.target.value,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleUnitnoFirst = (e) => {

    const unitno_first_value = e.target.value;
    this.setState({ unitno_first_value: unitno_first_value })

    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleStatusFilter = (e) => {
    this.setState({ status_filter: e })
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      offer_status_filter: this.state.offer_status_filter,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: e,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);
  }

  handleOfferStatusFilter = (e) => {
    this.setState({ offer_status_filter: e })
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: e,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);
  }



  // handle Client... by Farooq 
  handleClientValue_id = (e) => {

    this.setState({ client_value_id: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: e.target.value,
      client_value: this.state.client_value,

    }
    if (this.state.client_value != '')
      this.fetchData(page, perPage, data);
  }

  handleClient = (e) => {
    this.setState({ client_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: e.target.value,
    }
    this.fetchData(page, perPage, data)
  }
  ///////////////////////////////////////////

  // handle agent ......
  handleAgentValue_id = (e) => {

    this.setState({ agent_value_id: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: e.target.value,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }
    if (this.state.agent_value != '')
      this.fetchData(page, perPage, data);
  }

  handleAgent = (e) => {
    this.setState({ agent_value: e.target.value })

    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: e.target.value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }
    this.fetchData(page, perPage, data)
  }

  handlePriceFirstValue_id = (e) => {
    this.setState({ inrange: false })
    if (e.target.value == 7) {
      this.setState({ inrange: true, price_first_value: 0 })
    } else {
      this.setState({ price_min: 0, price_max: 0 })
    }

    this.setState({ price_first_value_id: e.target.value })
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      deals_listing_id: this.state.deals_listing_id,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      price_first_value_id: e.target.value,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,


    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handlePriceMin = (e) => {
    this.setState({ price_min: e.target.value })


    const data = {
      deals_listing_array: this.state.deals_listing_array,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: e.target.value,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,


    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_max > 0)
      this.fetchData(page, perPage, data);

  }

  handlePriceMax = (e) => {
    this.setState({ price_max: e.target.value })
    const max = e.target.value
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: e.target.value,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,


    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_min > 0 && max > this.state.price_min)
      this.fetchData(page, perPage, data);
  }

  handlePriceFirst = (e) => {
    const price_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { price_first_value: e.target.value } : prevState)
    )



    const data = {
      deals_listing_array: this.state.deals_listing_array,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const page = 1;
    const perPage = 10;
    if (e.target.value > 0) {
      this.fetchData(page, perPage, data);
    } else {
      return
    }

  }




  //////////////////  Handle Offer's Modal .... By Farooq  //////////////////////////

  // Handel Ref View .... By Farooq
  handleViewRefNo = (record, e) => {
    console.log("**** Record *** ", record)
    this.setState({
      offers: record,
      offers_agent: record.fullname,
      offers_date: record.view_date,
      offers_status: record.offer_status,
      offers_note: record.note,
      showPrice: record.status == "Offer" ? true : false,
      // showDate: record.status == "Second Viewing" ? true : false,
      offers_price: record.price,
      offers_commission: record.comission,
      offerModal: true,
      offer_id: record.views_id
    })
  }

  // Handle Change Status
  handleChangeStatus = () => {
    if (this.state.offers_note == "") {
      toast.warning('Feedback is required...')
    }
    else {
      const page = 1
      const perPage = 10

      const data = {
        deals_listing_array: this.state.deals_listing_array,
        month: this.state.month,
        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,
        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        offer_status_filter: this.state.offer_status_filter,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,
        price_first_value_id: this.state.price_first_value_id,
        price_and_or_option: this.state.price_and_or_option,
        price_second_value_id: this.state.price_second_value_id,
        price_first_value: this.state.price_first_value,
        price_second_value: this.state.price_second_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,
        // by Farooq
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,
      }

      const data2 = {
        view_id: this.state.offer_id,
        // viewing_date:this.state.offers_date, 
        offer_status: this.state.offers_status,
        viewing_price: this.state.offers_price,

        commission_price: this.state.offers_commission,
        note: this.state.offers_note,
        offer_date: this.state.offers_date
      }

      API.post("/change_offer_status", data2)
        .then((response) => {
          console.log("****response Offer status **** ", response)
          // if(response.data.success)
          if (response.status == 200)
            this.fetchData(page, perPage, data)
        })
      this.setState({ offerModal: false })
    }
  }

  // Handle Modal close
  handleCancel = () => {
    this.setState({ offerModal: false })
  }

  // Handle Offer Status
  handleOfferStatus = (e) => {
    const status = e.target.value
    console.log("*** check Status 8** ", e.target.value)
    this.setState({ offers_status: e.target.value })
    this.setState({ showPrice: true })
    // if (status == "Offer") {
    //   this.setState({ showPrice: true })
    // } else {
    //   this.setState({ showPrice: false })
    // }

    // if (status == "Second Viewing") {
    //   this.setState({ showDate: true })
    // } else {
    //   this.setState({ showDate: false })
    // }
  }

  // Handle offer Price
  handleOfferingPrice = (e) => {
    this.setState({ offers_price: e.target.value })
  }
  handleOfferingCommission = (e) => {
    this.setState({ offers_commission: e.target.value })
  }

  // Handle offer Note
  handleOfferingNote = (e) => {
    this.setState({ offers_note: e.target.value })
  }

  // Handle offer Date
  handleOfferingDate = (date, dateString) => {
    this.setState({ offers_date: dateString })
  };

  // Handle offer Delete, we will use it when need.
  // handleDeleteOffer = (offer_id,e) =>{
  //   const page = 1;
  //   const perPage = 10;
  //   const data = {
  //     date_range_status:this.state.date_range_status,
  //     date_range:this.state.date_range,
  //     community_filter:this.state.community_filter,
  //     sub_community_filter:this.state.sub_community_filter,
  //     property_filter:this.state.property_filter,
  //     refno_first_value_id:this.state.refno_first_value_id,
  //     refno_and_or_option:this.state.refno_and_or_option,
  //     refno_second_value_id:this.state.refno_second_value_id,
  //     refno_first_value:this.state.refno_first_value,
  //     refno_second_value:this.state.refno_second_value,
  //     unitno_first_value_id:this.state.unitno_first_value_id,
  //     unitno_and_or_option:this.state.unitno_and_or_option,
  //     unitno_second_value_id:this.state.unitno_second_value_id,
  //     unitno_first_value:this.state.unitno_first_value,
  //     unitno_second_value:this.state.unitno_second_value,
  //     status_filter:this.state.status_filter,
  //     agent_value_id: this.state.agent_value_id,
  //     agent_value: this.state.agent_value,
  //     client_value_id: this.state.client_value_id,
  //     client_value: this.state.client_value,
  // }
  //   if(offer_id == "" || offer_id < 1)
  //   return
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {

  //       API.delete("/delete_viewing/"+view_id)
  //       .then((response) =>{

  //           if(response.data.success){
  //               toast.success("Successfully deleted")
  //             this.fetchData(page,perPage,data)
  //           }

  //       })






  //     }
  //   })
  // }

  handleViewingFeedback = (e) => {

    this.setState({ viewing_feedback: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
      deals_listing_array: this.state.deals_listing_array,
      month: this.state.month,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: e.target.value,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    this.fetchData(page, perPage, data);

  }


  handlePropertyFor = (e) => {

    this.setState({ property_for_filter: e })

    const page = 1;
    const perPage = 10;

    const data = {
      deals_listing_array: this.state.deals_listing_array,
      month: this.state.month,
      property_for_filter: e,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    this.fetchData(page, perPage, data);


  }

  handleMonthTabs = (e, event) => {

    this.setState({ month: e })

    let newDate = new Date()
    let month = newDate.getMonth() + 1;

    const page = 1;
    const perPage = 10;

    const data = {
      deals_listing_array: this.state.deals_listing_array,
      month: e,
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    }

    const monthData = { month: e }
    this.fetchTotal(monthData)
    this.fetchData(page, perPage, data)

    // if(e == month){
    //   this.setState({isNext:true,isPrevious:true,date_range_status:"date"})
    //   this.fetchData(page,perPage,data)
    // //  this.fetchPreviousData(page,perPage,data)

    // }else 
    //  if(e > month){
    //   this.setState({isNext:true,isPrevious:false})
    //   this.fetchData(page,perPage,data)
    //   //this.fetchPreviousData(page,perPage,data)

    // }else
    // if(e < month){

    //   this.setState({isNext:false,isPrevious:true})
    //   //this.fetchData(page,perPage,data)
    //  // this.fetchPreviousData(page,perPage,data)

    // }


  }

  handleClearMonth = () => {
    this.setState({
      month: "",
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,
    })
    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: this.state.deals_listing_array,
      month: "",
      property_for_filter: this.state.property_for_filter,
      viewing_feedback: this.state.viewing_feedback,
      community_filter: this.state.community_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,
      status_filter: this.state.status_filter,
      offer_status_filter: this.state.offer_status_filter,
      agent_value_id: this.state.agent_value_id,
      agent_value: this.state.agent_value,
      vendor_agent_value_id: this.state.vendor_agent_value_id,
      vendor_agent_value: this.state.vendor_agent_value,
      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      // by Farooq
      client_value_id: this.state.client_value_id,
      client_value: this.state.client_value,

    }

    const monthData = { month: "" }
    this.fetchTotal(monthData)
    this.fetchData(page, perPage, data)

  }

  handleClearFilter = () => {
    this.props.removeDealReducer()
    this.setState({
      deals_listing_array: [],
      agent_value: "",
      agent_value_id: 1,
      client_value: "",
      client_value_id: 1,
      community_filter: "",
      month: "",
      offer_status_filter: [],
      price_and_or_option: true,
      price_first_value: "",
      price_first_value_id: 1,
      price_max: 0,
      price_min: 0,
      price_second_value: "",
      price_second_value_id: 1,
      property_filter: "",
      property_for_filter: [],
      refno_and_or_option: true,
      refno_first_value: "",
      refno_first_value_id: 1,
      refno_second_value: "",
      refno_second_value_id: 1,
      status_filter: [],
      sub_community_filter: "",
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_first_value_id: 1,
      unitno_second_value: "",
      unitno_second_value_id: 1,
      viewing_feedback: 0,


    })
    const page = 1;
    const perPage = 10;
    const data = {
      deals_listing_array: [],
      agent_value: "",
      agent_value_id: 1,
      client_value: "",
      client_value_id: 1,
      community_filter: "",
      month: "",
      offer_status_filter: [],
      price_and_or_option: true,
      price_first_value: "",
      price_first_value_id: 1,
      price_max: 0,
      price_min: 0,
      price_second_value: "",
      price_second_value_id: 1,
      property_filter: "",
      property_for_filter: [],
      refno_and_or_option: true,
      refno_first_value: "",
      refno_first_value_id: 1,
      refno_second_value: "",
      refno_second_value_id: 1,
      status_filter: [],
      sub_community_filter: "",
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_first_value_id: 1,
      unitno_second_value: "",
      unitno_second_value_id: 1,
      viewing_feedback: 0,

    }

    const monthData = { month: "" }
    this.fetchTotal(monthData)
    this.fetchData(page, perPage, data)
    this.fetchPreviousData(page, perPage, data);

  }




  render() {


    const refNo = (
      <>
        <select class="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
        </div>
        {this.state.refno_active_second_option ? (<div className='mt-2' >
          <center>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
              <label class="form-check-label" for="flexRadioDefault1">
                AND
              </label>
              <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
              <label class="form-check-label ml-4" for="flexRadioDefault1">
                OR
              </label>

            </div>
          </center>
          <select class="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
            <option value="1">Contain</option>
            <option value="2">Not contain</option>
            <option value="3">Equals</option>
            <option value="4">Not equal</option>
            <option value="5">Starts with</option>
            <option value="6">Ends with</option>
          </select>
          <div className='mt-2'>
            <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
          </div>

        </div>) : null}</>
    )

    const clientName = (
      <>
        <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleClientValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleClient} placeholder="filter..." />
        </div></>
    )

    const price = (
      <>
        <select class="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
          <option value="1">Equals</option>
          <option value="2">Not equal</option>
          <option value="3">Less than</option>
          <option value="4">Less than or equals</option>
          <option value="5">Greater than</option>
          <option value="6">Greater than or equals</option>
          <option value="7">In range</option>
        </select>
        <div className='mt-2'>
          {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
            <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
            <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
          </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
        </div>
        {this.state.price_active_second_option ? (<div className='mt-2' >
          <center>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
              <label class="form-check-label" for="pricefirst">
                AND
              </label>
              <input class="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
              <label class="form-check-label ml-4" for="pricesecond">
                OR
              </label>

            </div>
          </center>
          <select class="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
            <option value="1">Equals</option>
            <option value="2">Not equal</option>
            <option value="3">Less than</option>
            <option value="4">Less than or equals</option>
            <option value="5">Greater than</option>
            <option value="6">Greater than or equals</option>
            <option value="7">In range</option>
          </select>
          <div className='mt-2'>
            <input type="text" className="form-control" onChange={this.handlePriceSecond} placeholder="filter..." />
          </div>

        </div>) : null}
      </>
    )

    const propertyFor = (
      <>
        <Select
          mode="multiple"
          maxTagCount='responsive'
          style={{
            width: '100%',
          }}

          placeholder="Please select"
          onChange={this.handlePropertyFor}


          options={[
            {
              value: 'sale',
              label: 'sale',
            },
            {
              value: 'rent',
              label: 'rent',
            },
            {
              value: 'tenanted',
              label: 'tenanted',
            },
            {
              value: 'short-term',
              label: 'short-term',
            },
          ]}
        /></>
    )

    const unitNo = (
      <>
        <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." />
        </div>
      </>
    )

    const agent = (
      <>
        <select class="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleAgentValue_id}>
          <option value="1">Contain</option>
          <option value="2">Not contain</option>
          <option value="3">Equals</option>
          <option value="4">Not equal</option>
          <option value="5">Starts with</option>
          <option value="6">Ends with</option>
        </select>
        <div className='mt-2'>
          <input type="text" className="form-control" onChange={this.handleAgent} placeholder="filter..." />
        </div>
      </>
    )

    const dealFeedback = (
      <>
        <select className='form-select' onChange={this.handleViewingFeedback}>
          <option value="0" selected>All</option>
          <option value="1">Note is empty</option>
          <option value="2">Note is not empty</option>
        </select></>
    )


const propertyForCol1 = (
  <>
   <Select
              mode="multiple"
              maxTagCount='responsive'
              style={{
                width: '100%',
              }}

              placeholder="Please select"
              onChange={this.handlePropertyFor}


              options={[
                {
                  value: 'sale',
                  label: 'sale',
                },
                {
                  value: 'rent',
                  label: 'rent',
                },
                {
                  value: 'tenanted',
                  label: 'tenanted',
                },
                {
                  value: 'short-term',
                  label: 'short-term',
                },
              ]}
            /></>
)

const dealFeedbackCol1 = (<>
 <select className='form-select' onChange={this.handleViewingFeedback}>
              <option value="0" selected>All</option>
              <option value="1">Note is empty</option>
              <option value="2">Note is not empty</option>
            </select>
</>)

    const authentication = store.getState((state) => state);
    const user = authentication.auth.user
    const role = user.role

    const total_deals = this.state.total_deals
    const total_commission = this.state.total_commission
    const total_sales_volume = this.state.total_sales_volume
    const { offers_date } = this.state;

    const val1 = dayjs(offers_date ? offers_date : "");

    const onSelectChange = (newSelectedRowKeys, records) => {
      this.setState({ data_source: records })
      // setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      // selectedRowKeys,
      onChange: onSelectChange,

    };

    // Common function for sort Validator, by farooq
    const sortValidator = (a, b, aVar, bVar) => {
      if (a && aVar && aVar.length && b && bVar && bVar.length) {
        return aVar.length - bVar.length;
      } else if (a && aVar && aVar.length) {
        // That means be has null rechargeType, so a will come first.
        return -1;
      } else if (b && bVar && bVar.length) {
        // That means a has null rechargeType so b will come first.
        return 1;
      }

      // Both rechargeType has null value so there will be no order change.
      return 0;
    },

      columns = [

        {
          title: "Deal Status", fixed: "left", width: 180,
          dataIndex: "offer_status",
          // sorter: (a, b) => a.status.length - b.status.length,
          sorter: (a, b) => sortValidator(a, b, a.offer_status, b.offer_status),
          render: (text, record) => {
            var pStatus = 'Offer'
            var color = 'offer'
            switch (record.offer_status) {
              case 0:
                pStatus = 'Pending'
                color = ''
                break;
              case 1:
                pStatus = 'Offer Sent'
                color = 'offer'
                break;
              case 2:
                pStatus = 'Offer Accepted'
                color = 'secondviewing';
                break;
              case 3:
                pStatus = 'Offer Rejected'
                color = 'scheduled';
                break;
              case 4:
                pStatus = 'Deal Signed'
                color = 'offer';
                break;
              default:
                pStatus = 'Pending'
                color = '';
                break;
            }
            return (
              <div className={color + " pstat"} key={pStatus}>
                {pStatus}
              </div>
            );
          }
        },
        {
          title: 'Ref no', fixed: "left", width: 280,
          dataIndex: 'refno',

          // sorter: (a, b) => a.refno.length - b.refno.length,
          sorter: (a, b) => sortValidator(a, b, a.status.replace("LP", ""), b.status.replace("LP", "")),

          render: (text, record) => (
            <Link onClick={this.handleViewRefNo.bind(this, record)} className="d-flex flex-column">
              <span className="propref">{record.refno}</span>
              <span className="propinfo">
                {record.property_title == null ? "" : record.property_title + ", "}
                {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                {record.community_title == null ? "" : record.community_title}
              </span>
            </Link>
          ),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Ref no
              {refNo}



            </div>
          ),
        },
        {
          title: "Client", fixed: "left", width: 150,
          dataIndex: 'client_name',
          render: (text, record) => (
            <>
              {record.leads_id ? <a href={liveUrl + "/leads/" + record.leads_id} target="_blank">{text}</a> : text}
            </>
          ),
          // sorter: (a, b) => a.client_name.length - b.client_name.length,
          sorter: (a, b) => sortValidator(a, b, a.client_name, b.client_name),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Client name
              {clientName}

            </div>
          ),
        },

        {
          title: "Price", width: 160,
          dataIndex: 'price',
          render: (text, record) => {
            if (!isNaN(parseInt(text))) {
              return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
            } else {
              return null;
            }
          },
          sorter: (a, b) => sortValidator(a, b, a.price, b.price),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Price
              {price}



            </div>
          ),
        },
        {
          title: "Commission",
          dataIndex: 'comission', width: 180,
          render: (text, record) => {
            if (!isNaN(parseInt(text))) {
              return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
            } else {
              return null;
            }
          }
        },
        {
          title: "Date", width: 180,
          dataIndex: "view_date",
          sorter: (a, b) => a.view_date.length - b.view_date.length,
          render: (text, record) => {
            return <>
              {
                record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                  ''
                  :
                  <>
                    <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                    {/*<span className="px-2">|</span>*/}
                    {/*<Moment format="hh:mm A">{record.view_date}</Moment>*/}
                  </>
              }
            </>
          },
        },
        {
          title: 'For', width: 100,
          dataIndex: 'property_for',
          sorter: (a, b) => {
            if (a && a.property_for && a.property_for.length && b && b.property_for && b.property_for.length) {
              return a.property_for.length - b.property_for.length;
            } else if (a && a.property_for && a.property_for.length) {
              return -1;
            } else if (b && b.property_for && b.property_for.length) {
              return 1;
            }
            return 0;
          },
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Property for
              {propertyFor}


            </div>
          ),

        },


        {
          title: "Unit no", width: 130,
          dataIndex: "unitno",
          // sorter: (a, b) => a.unitno.length - b.unitno.length,
          sorter: (a, b) => sortValidator(a, b, a.unitno, b.unitno),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Unit no
              {unitNo}

            </div>
          ),
        },



        {
          title: "Agent", width: 200,
          dataIndex: 'fullname',
          // sorter: (a, b) => a.fullname.length - b.fullname.length,
          sorter: (a, b) => sortValidator(a, b, a.fullname, b.fullname),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Agent name
              {agent}

            </div>
          ),
        },
        {
          title: "Deal Feedback", width: 500,
          dataIndex: 'note',
          className: "noteswrap",
          // sorter: (a, b) => a.note.length - b.note.length,
          sorter: (a, b) => sortValidator(a, b, a.note, b.note),
          filterDropdown: (
            <div style={{
              padding: '8px',
              borderRadius: '6px',
              background: '#fff',
              boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
            }}>
              Deel feedback
              {dealFeedback}



            </div>
          ),
        },


        // {
        //   title: 'Action',
        //   render: (text, record) => (
        //     <div className="ms-auto">
        //     <a className="btn btn-link btn-sm m-2" onClick={this.handleDeleteOffer.bind(this,record.views_id)} style={{fontSize:'16px'}}><i className="fa fa-trash" style={{fontSize:"18px"}}></i></a>
        // </div>
        //     ),
        // }, 

      ]

    const columnsResponsiveThis = [
      {
        title: "Status", key: "status",
        render: (text, record) => {
          var pStatus = 'Offer'
          var color = 'offer'
          switch (record.offer_status) {
            case 0:
              pStatus = 'Pending'
              color = ''
              break;
            case 1:
              pStatus = 'Offer Sent'
              color = 'offer'
              break;
            case 2:
              pStatus = 'Offer Accepted'
              color = 'secondviewing';
              break;
            case 3:
              pStatus = 'Offer Rejected'
              color = 'scheduled';
              break;
            case 4:
              pStatus = 'Deal Signed'
              color = 'offer';
              break;
            default:
              pStatus = 'Pending'
              color = '';
              break;
          }
          return (<>
            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                    <div className={color + " pstat"} key={pStatus}>
                      {pStatus}
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    {
                      record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                        ''
                        :
                        <>
                          <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                        </>
                    }
                  </div>
                </div>
              </div>
              <Link className="btn-text listinginfo" nClick={this.handleViewRefNo.bind(this, record)}>
                <div className='row alignCenter borderGray pt-2 pb-2'>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>
                      <span className="propref">{record.refno}</span>
                      <span className="propinfo">
                        {record.property_title == null ? "" : record.property_title + ", "}
                        {record.sub_community_title == null ? "" : record.sub_community_title + ", "}
                        {record.community_title == null ? "" : record.community_title}
                      </span>
                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Unit No:</p>
                        <p>{record.unitno ? record.unitno : null}</p>
                      </div>

                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>For:</p>
                        <p>{record.property_for ? record.property_for : null}</p>
                      </div>
                    </div>

                  </div>



                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Client:</p>
                        <p>
                          {record.leads_id ? (
                            <a href={`${liveUrl}/leads/${record.leads_id}`} target="_blank" rel="noopener noreferrer">
                              {record.client_name}
                            </a>
                          ) : (
                            record.client_name
                          )}
                        </p>
                      </div>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Agent:</p>
                        <p>{record.fullname ? record.fullname : null}</p>
                      </div>

                    </div>

                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Price:</p>
                        <p>
                          {record.price && !isNaN(parseInt(record.price))
                            ? `${parseInt(record.price).toLocaleString()} AED`
                            : null}
                        </p>
                      </div>

                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Commission:</p>
                        <p>
                          {record.comission && !isNaN(parseInt(record.comission))
                            ? `${parseInt(record.comission).toLocaleString()} AED`
                            : null}
                        </p>
                      </div>
                    </div>

                  </div>

                  <div className='col-12'>
                    <div className='BodyFirstRowRight'>
                      <p className='info-title'>Deal Feedback:</p>
                      <p>{record.note ? record.note : '--'}</p>
                    </div>
                  </div>
                </div>

              </Link>

            </div>
          </>)
        }
      }
    ]


    const columns1 = [
      {
        title: "Date",
        dataIndex: "view_date",
        // sorter: (a, b) => a.view_date.length - b.view_date.length,
        sorter: (a, b) => sortValidator(a, b, a.view_date, b.view_date),
      },

      {
        title: 'Property for',
        dataIndex: 'property_for',
        sorter: (a, b) => {
          if (a && a.property_for && a.property_for.length && b && b.property_for && b.property_for.length) {
            return a.property_for.length - b.property_for.length;
          } else if (a && a.property_for && a.property_for.length) {
            return -1;
          } else if (b && b.property_for && b.property_for.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Property for
           {propertyForCol1}


          </div>
        ),

      },


      {
        title: "Unit no",
        dataIndex: "unitno",
        // sorter: (a, b) => a.unitno.length - b.unitno.length,
        sorter: (a, b) => sortValidator(a, b, a.unitno, b.unitno),

      },

      {
        title: "Price",
        dataIndex: 'price',
        render: (text, record) => {
          if (!isNaN(parseInt(text))) {
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          } else {
            return null;
          }
        },
      },


      {
        title: "Offer Feedback",
        dataIndex: 'note',
        // sorter: (a, b) => a.note.length - b.note.length,
        sorter: (a, b) => sortValidator(a, b, a.note, b.note),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Deel feedback
           {dealFeedbackCol1}



          </div>
        ),

      },




    ]

    const columns1Responsive = [
      {
        title: "Status", key: "status",
        render: (text, record) => {
        
          return (<>
            <div className="TableCard">

              <div className='row alignCenter headerGrayBgColor borderBottom'>
                <div className='col-6'>
                  <div className='HeaderMainLeft'>
                  {record.unitno ? record.unitno : null}
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                    {
                      record.view_date == null || record.view_date == '' || record.view_date == "0000-00-00 00:00:00" ?
                        ''
                        :
                        <>
                          <Moment format="MMMM DD, YYYY">{record.view_date}</Moment>
                        </>
                    }
                  </div>
                </div>
              </div>
              <Link className="btn-text listinginfo" nClick={this.handleViewRefNo.bind(this, record)}>
                <div className='row alignCenter borderGray pt-2 pb-2'>
                  
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowLeft d-flex flex-column'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Property For:</p>
                        <p>
                        {record.property_for ? record.property_for : null}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='BodyFirstRowRight'>
                      <div className='d-flex gap-1 BodyFirstRowRight'>
                        <p className='info-title'>Price:</p>
                        <p>
                          {record.price && !isNaN(parseInt(record.price))
                            ? `${parseInt(record.price).toLocaleString()} AED`
                            : null}
                        </p>
                      </div>

                    </div>

                  </div>

                  <div className='col-12'>
                    <div className='BodyFirstRowRight'>
                      <p className='info-title'>Offer Feedback:</p>
                      <p>{record.note ? record.note : '--'}</p>
                    </div>
                  </div>
                </div>

              </Link>

            </div>
          </>)
        }
      }
    ]


    const columns2 = [
      {
        title: "Agent",
        dataIndex: "fullname",
        key: "fullname",
        render: (text, record) => (
          <>
            {record.fullname ? record.fullname : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      },
      {
        title: "Ref no",
        dataIndex: "refno",
        key: "refno",
        render: (text, record) => (
          <>
            {record.refno ? record.refno : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      },
      {
        title: "Date",
        dataIndex: "view_date",
        key: "view_date",
        render: (text, record) => (
          <>
            {record.view_date ? record.view_date : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      },
      {
        title: "Community",
        dataIndex: "community_title",
        key: "community_title",
        render: (text, record) => (
          <>
            {record.community_title ? record.community_title : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      },
      {
        title: "Sub Community",
        dataIndex: "sub_community_title",
        key: "sub_community_title",
        render: (text, record) => (
          <>
            {record.sub_community_title ? record.sub_community_title : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      },
      {
        title: "Property",
        dataIndex: "property_title",
        key: "property_title",
        render: (text, record) => (
          <>
            {record.property_title ? record.property_title : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      },
      {
        title: "Property For",
        dataIndex: "property_for",
        key: "property_for",
        render: (text, record) => (
          <>
            {record.property_for ? record.property_for == "rent" ? "Rent" : "Sale" : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      }, {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text, record) => {
          if (!isNaN(parseInt(text))) {
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          } else {
            return null;
          }
        },
      }, {
        title: "Commission",
        dataIndex: "comission",
        key: "comission",
        render: (text, record) => {
          if (!isNaN(parseInt(text))) {
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          } else {
            return null;
          }
        },
      },
      {
        title: "Sources",
        dataIndex: "sources",
        key: "sources",
        render: (text, record) => (
          <>
            {record.sources ? record.sources : ""}

            {/* {record.fullname == " " || record.fullname == "" || record.fullname == null ? <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}><SearchOutlined /></Link> : <Link className="" onClick={this.handleOpenDrawer.bind(this, record.parent_lead, false)}>{record.fullname}</Link>} */}
          </>
          // <Link className="" onClick={this.handleOpenDrawer.bind(this, record.id)}>{record.fullname != "" || record.fullname != null ?record.fullname:<SearchOutlined />}</Link>
        ),
      }
    ];

    const handleClick = () => {

      const excel = new Excel();
      excel
        .addSheet("selectedata")
        .addColumns(columns2)
        .addDataSource(this.state.data_source, {
          str2Percent: true
        })
        .saveAs("SelectedData.xlsx");
    };

    const handleClickAll = () => {
      API.get("/export_all_listings_leads_offers")
        .then((res) => {
          if (res.data.success) {
            const excel = new Excel();
            excel
              .addSheet("alldata")
              .addColumns(columns2)
              .addDataSource(res.data.all_upcomming_offers, {
                str2Percent: true
              })
              .saveAs("allData.xlsx");
          }
        })


    };




    const handleTableChange = (pagination, filters) => {


      const data = {
        deals_listing_array: this.state.deals_listing_array,
        month: this.state.month,
        property_for_filter: this.state.property_for_filter,
        viewing_feedback: this.state.viewing_feedback,
        community_filter: this.state.community_filter,
        sub_community_filter: this.state.sub_community_filter,
        property_filter: this.state.property_filter,
        refno_first_value_id: this.state.refno_first_value_id,
        refno_and_or_option: this.state.refno_and_or_option,
        refno_second_value_id: this.state.refno_second_value_id,
        refno_first_value: this.state.refno_first_value,
        refno_second_value: this.state.refno_second_value,
        unitno_first_value_id: this.state.unitno_first_value_id,
        unitno_and_or_option: this.state.unitno_and_or_option,
        unitno_second_value_id: this.state.unitno_second_value_id,
        unitno_first_value: this.state.unitno_first_value,
        unitno_second_value: this.state.unitno_second_value,
        status_filter: this.state.status_filter,
        offer_status_filter: this.state.offer_status_filter,
        agent_value_id: this.state.agent_value_id,
        agent_value: this.state.agent_value,
        price_first_value_id: this.state.price_first_value_id,
        price_and_or_option: this.state.price_and_or_option,
        price_second_value_id: this.state.price_second_value_id,
        price_first_value: this.state.price_first_value,
        price_second_value: this.state.price_second_value,
        price_min: this.state.price_min,
        price_max: this.state.price_max,
        // by Farooq
        client_value_id: this.state.client_value_id,
        client_value: this.state.client_value,
      }

      this.fetchData(pagination.current, pagination.pageSize, data);

    };

    return (
      <div>
        <ToastContainer />







        <div className='contentwrap' style={{

          minHeight: 380,
        }}>

          <div className='ml-auto d-flex px-0'>

            <div className='px-3'>
              <Space direction="vertical">


                <Space wrap>

                  <div className="d-flex flex-row justify-content-between my-2 w-100 px-2">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="col-12 d-flex flex-row toptitle" >
                        <h3 className="my-2 ">Deals</h3>

                      </div>

                      <div className='d-flex flex-row '>

                        <Button className='ml-1' type="primary" onClick={this.handleClearFilter} icon={<ClearOutlined />}>
                          Reset filter
                        </Button></div>
                      {this.state.data_source.length > 0 ? <Button onClick={handleClick} type="primary">Export Selected</Button> : null}
                      {/* <Button onClick={handleClickAll} type="primary">Export all</Button> */}
                    </div>
                    <div className="d-flex flex-row justify-content-between">

                    </div>
                  </div>

                  {/* edit filter */}





                </Space>
              </Space>
            </div>
          </div>




          <div class="table-responsive tablelistwrap px-3 alloffers " >
            <Tabs >
              <TabList>
                {this.state.months.length > 0 ? <Tab onClick={this.handleClearMonth}>All</Tab> : null}
                {this.state.months.map((month) => (
                  <>
                    <Tab><span onClick={this.handleMonthTabs.bind(this, month.key)} className="mr-3">{month.labe}</span></Tab>
                  </>
                ))}

              </TabList>
            </Tabs>

            {/* <Tabs className={'monthlyviews px-3'} >
            <TabList selectedIndex={0}>
                <Tab >January</Tab>
                <Tab>February</Tab>
                <Tab>March</Tab>
                <Tab>April</Tab>
                <Tab>May</Tab>
                <Tab>June</Tab>
                <Tab>July</Tab>
                <Tab>August</Tab>
                <Tab>September</Tab>
                <Tab>October</Tab>
                <Tab>November</Tab>
                <Tab>December</Tab>
            </TabList>
            <TabPanel className={'px-2'}>  */}
            {/*January */}
            <Row gutter={16} className='px-2 mb-3 statwrap'>
              <Col span={6} lg={6} md={12} sm={12} xs={12} className=' p-3 flex-row'>
                <div className='flex-column'>
                  <p className='mb-0 font-12 fontRes-90'>Total Deal Signed</p>

                  <p className='font-bold fs-3 mb-0 fontRes-11'>
                    {this.state.isTotalLoading ? <Skeleton active /> :
                      <>
                        {total_deals > 0 ? total_deals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : 0}
                      </>
                    }
                  </p>
                </div>
              </Col>
              <Col span={6} lg={6} md={12} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-90'>Sales Volume</p>
                <p className='font-bold fs-3 mb-0 fontRes-11'>   {this.state.isTotalLoading ? <Skeleton active /> :
                  <>{total_sales_volume > 0 ? total_sales_volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : 0}</>}</p>
                {/* Price column */}
              </Col>
              <Col span={6} lg={6} md={12} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-90'>Commission Volume</p>
                <p className='font-bold fs-3 mb-0 fontRes-11'>   {this.state.isTotalLoading ? <Skeleton active /> :
                  <>{total_commission > 0 ? total_commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : 0}</>}</p>
                {/* Commission column */}
              </Col>
              <Col span={6} lg={6} md={12} sm={12} xs={12} className=' p-3'>
                <p className='mb-0 font-12 fontRes-90'>Deals</p>
                <p className='font-bold fs-3 mb-0 fontRes-11'>{this.state.isTotalLoading ? <Skeleton active /> :
                  <>{this.state.count_deals > 0 ? this.state.count_deals : 0}</>}</p>
                {/* conversion = Sales volumn / commission volumn */}
              </Col>
            </Row>

            {/* Responsive Filters List by Hassan 8/15/24 */}
            {window.isIpadView ? <>
              <div className='listingfilter-second'>
                <Row span={24}>
                  <Col span={24}>
                    {/* Status */}
                    <div className='d-flex flex-row align-items-end form-group formcheckboxgroup'>
                      <div className="d-flex flex-column align-items-start "> {/* 11-23-2023 */}


                        <Collapse className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                          <Panel header={<p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Filter by</p>} key="2">
                            {/* {!this.state.isViewingTabShow && !this.state.isOfferTabShow?<> */}
                            {role == 1 ?
                            <>
                              <Popover title="Ref No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {refNo}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Ref No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Client Name" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {clientName}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Client Name
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Price" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {price}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Price
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>


                              <Popover title="Property For" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {propertyFor}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Property For
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Unit No" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {unitNo}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Unit No
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Agent Name" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {agent}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Agent Name
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Deal Feedback" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {dealFeedback}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                  Deal Feedback
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                            </> :
                            <>
                            <Popover title="Property for" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {propertyForCol1}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                Property for
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>

                              <Popover title="Deal Feedback" trigger="click" placement="bottomRight"
                                content={<>
                                  <div className='FilterDiv'>
                                    {dealFeedbackCol1}
                                  </div>
                                </>}>
                                <Button type="primary" className='font-12 FilterButtonStyling'>
                                Deal Feedback
                                  <i class="bi bi-chevron-down ml-2"></i>
                                </Button>
                              </Popover>
                            </>
                            }



                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </> : ''
            }


            <ReactDragListView.DragColumn onDragEnd={this.onDragEnd}
              nodeSelector="th">

              <Table onChange={handleTableChange}


                loading={this.state.isLoading}
                rowSelection={window.isIpadView ? '' : rowSelection}
                 className="TableborderNoneMain"
                pagination={{
                  pageSizeOptions: ['10', '20', '50', '100'],
                  total:
                    this.state?.all_viewings?.total > 0 &&
                    this.state.all_viewings.total,
                  pageSize: this.state.all_viewings.per_page,
                  current: this.state.all_viewings.current_page,
                  showTotal: (total, range) =>
                    window.isTabView ? `` :`Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                scroll={{ x: window.isIpadView ? 'auto' :'2000', y: window.isIpadView ? 'auto' :'1500' }}
                columns={role == 1 ? window.isIpadView ? columns1Responsive : columns1 : window.isIpadView ? columns1Responsive : columns1}
                bordered
                dataSource={
                  this.state?.all_viewings?.data
                    ? this.state?.all_viewings?.data
                    : []
                }

                // rowKey={(record) => record}
                // Change By Farooq
                rowKey={(record) => record.views_id}
              />
            </ReactDragListView.DragColumn>
            {/* </TabPanel>
            <TabPanel>February</TabPanel>
            <TabPanel>March</TabPanel>
            <TabPanel>April</TabPanel>
            <TabPanel>May</TabPanel>
            <TabPanel>June</TabPanel>
            <TabPanel>July</TabPanel>
            <TabPanel>August</TabPanel>
            <TabPanel>September</TabPanel>
            <TabPanel>October</TabPanel>
            <TabPanel>November</TabPanel>
            <TabPanel>December</TabPanel>
                </Tabs> */}
          </div>
        </div>


        {/** ADD MODAL BY FAROOQ */}
        <Modal width={600} title="Offers" open={this.state.offerModal}
          onOk={this.handleChangeStatus} onCancel={this.handleCancel} >
          <div className="container">
            <div className='row w-100 d-flex flex-row'>
              <div className='px-0 pt-1 form-group'>
                <label>Ref No</label>
                <p className="form-control-disabled">{this.state.offers.refno}</p>
              </div>
              {role == 1 ? <div className='px-0 pt-1 form-group'>
                <label>Agent Name</label>
                <p className="form-control-disabled">{this.state.offers_agent}</p>
              </div> : null}

            </div>
            <div className='row w-100 d-flex flex-row'>

              <div className='px-0 pt-1 form-group'>
                <label>Date</label>
                {
                  (this.state.role == "Super Admin" || this.state.role == "Admin") ?
                    <DatePicker value={(offers_date) ? dayjs(offers_date) : ""} format="YYYY-MM-DD" style={{ width: '100%' }} showTime onChange={this.handleOfferingDate} />
                    :
                    <p className="form-control mb-0" style={{ fontSize: "12px" }}>{this.state.offers_date}</p>
                }

              </div>
              <div className='px-0 pt-1  form-group'>
                <label>Offers Status</label>
                <select onChange={this.handleOfferStatus} value={this.state.offers_status} className='form-select'>
                  <option value="1">Offer Sent</option>
                  <option value="2">Offer Accepted</option>
                  <option value="3">Offer Rejected</option>
                  <option value="4">Deal Signed</option>
                </select>
              </div>
              {/* {
              this.state.showPrice ? 
                <div className='form-group'>
                  <label>Price</label>
                  <input onChange={this.handleOfferingPrice} type="text" className="form-control" value={this.state.offers_price} />
                </div> 
                : 
                null
              } */}
              <div className='form-group px-0'>
                <label>Price</label>
                <input onChange={this.handleOfferingPrice} type="text" className="form-control" value={this.state.offers_price} />
              </div>
              <div className='form-group px-0'>
                <label>Commission</label>
                <input onChange={this.handleOfferingCommission} type="text" className="form-control" value={this.state.offers_commission} />
              </div>
            </div>
            <div className='row w-100'>

              <div className='w-100 px-0 pt-1 form-group'>
                <label>Offer feedback</label>
                <TextArea value={this.state.offers_note} style={{ width: '100%' }} onChange={this.handleOfferingNote} />
              </div>


            </div>
          </div>
        </Modal>

      </div>
    )
  }
}



const mapDispatchToProps = dispatch => {
  return {
    removeDealReducer: () => dispatch({ type: "REMOVE_DEALS_STOCK_AREA_REDUCER", payload: false })
  }
}

export default connect(null, mapDispatchToProps)(Deals)

