import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import PublicRoute from "./components/protectRoutes/PublicRoute";
import SideBar from "./components/SidebarComponent/SideBar";
import ForgetPassword from "./Pages/Auth/ForgetPassword";

import Login from "./Pages/Auth/Login";
import tenancyContractPdf from "./Pages/Requests/tenancyContractPdf";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import { useMediaQuery } from 'react-responsive';
function App() {
  window.isMobile576 = useMediaQuery({ query: '(max-width: 576px)' });
  window.isMobileView = useMediaQuery({maxWidth: '650px' });
  window.isTabView = useMediaQuery({maxWidth: '768px' });
  window.isIpadView = useMediaQuery({maxWidth: '1024px' });
  useEffect(() => {
      // Function to detect if the user is on a mobile device
      const isMobile = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };
      // Redirect to the mobile site if on a mobile device
    //   if (isMobile()) {
    //     window.location.href = "https://lms.luxuryproperty.com";
    // }
  }, []);
    return (
      <Router>
        <div>
          <Switch>
            <PublicRoute exact path="/forgotpassword" component={ForgetPassword} />
            <PublicRoute exact path="/" component={Login} />
            <Route exact path="/tenancyContractPdf" component={tenancyContractPdf} />
            <PublicRoute exact path="/reset-password" component={ResetPassword} />
            <SideBar />
          </Switch>
        </div>
      </Router>
    );
}
  export default App;