import React, { Component } from 'react'
import { Row, Col, Select, Table, Avatar, Statistic, Button, Modal } from 'antd';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { store } from '../../store';
import "./leads.css";
import "../Listings/tableResponsive.css";
import { baseurlImg } from '../../components/BaseUrl';
import Moment from 'react-moment';


const { Countdown } = Statistic;

class Pending_Leads extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalNewLead: true,
            totalMilliseconds: 7200000,
            //remove this
            profile_photo: 'https://luxuryproperty.s3.ap-south-1.amazonaws.com/uploads/users/images/Oksana.jpg'

        }
    }

    handleNewLeadOpen = e => { this.setState({ modalNewLead: true }) }
    handleNewLeadClose = e => { this.setState({ modalNewLead: false }) }


    isValidHttpUrl = (string) => {
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }
    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState((prevState) => ({
                totalMilliseconds: prevState.totalMilliseconds - 1000,
            }));
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }



    dataSource = [
        { key: '1', name: 'Chris Timoney', community: '', sub_community: '', property: '', price: '', beds: '', property_type: '', property_for: 'sale', created_date: '2023-11-21 08:00:22', },
        { key: '2', name: 'Rhys Benhadj', community: '', sub_community: '', property: '', price: '', beds: '', property_type: '', property_for: 'sale', created_date: '2023-11-21 08:00:22', },
        { key: '3', name: 'George Hughes', community: '', sub_community: '', property: '', price: '', beds: '', property_type: '', property_for: 'sale', created_date: '2023-11-21 08:00:22', },
        { key: '4', name: 'Kyle Guisti', community: 'Jumeirah Islands', sub_community: 'Mediterranean Clusters', property: '', price: '500000', currency: 'AED', beds: '4', property_type: 'villa', property_for: 'sale', created_date: '2023-11-21 08:00:22', },
        { key: '5', name: 'Ellie Chambers', community: 'Jumeirah Islands', sub_community: 'Mediterranean Clusters', property: '', price: '500000', currency: 'AED', beds: '4', property_type: 'villa', property_for: 'sale', created_date: '2023-11-21 08:00:22', },
    ]

    columns = [
        {
            title: 'Name', dataIndex: 'name',
            render: (text, record) => (
                <div className='listinginfowrap'>
                    <div className='info-group'>
                        <div className='agentwrap d-flex flex-row align-items-center'>
                            <Avatar shape="square" style={{ borderRadius: '10px !important' }} size={60} icon={<img style={{ borderRadius: '10px', boxShadow: '1px 1px 10px lightgray', width: "60px", height: "60px", borderRadius: '10px !important' }} src={record.profile_photo == null || record.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(record.profile_photo) ? record.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo} />} />
                            <p className="font-semibold font-14 ">{record.name}</p>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'requirements', dataIndex: 'requirements', width: 400,
            render: (text, record) => (
                <div className='listinginfowrap'>
                    <div className='info-group'>
                        <p className='info-title'>Requirements:</p>
                        <div className='d-flex flex-column align-items-start'>
                            <p>{record.property ? <span className="font-semibold font-14">{record.property + ", "}</span> : ''}
                                {record.sub_community ? <span className="font-semibold font-14">{record.sub_community + ", "}</span> : ''}
                                {record.community ? <span className="font-semibold font-14">{record.community}</span> : '-'}</p>
                            <div className='d-flex flex-row' style={{ gap: '0.6rem' }}>
                                {record.price ? <p className=" font-12">{record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + record.currency}</p> : ''}
                                <i class="bi bi-dot"></i>
                                {record.beds ? <p className="font-12">{record.beds + " beds"}</p> : ''}
                                <i class="bi bi-dot"></i>
                                {record.property_type ? <p className="font-12">{record.property_type}</p> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'created date', dataIndex: 'created_date', width: 130,
            render: (text, record) => (
                <div className='listinginfowrap'>
                    <div className='info-group'>
                        <p className='info-title'>Created date:</p>
                        <div className='d-flex flex-row align-items-center'>
                            {record.created_date ? <Moment fromNow className="font-semibold font-14">{record.created_date}</Moment> : ''}
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'expired in', dataIndex: 'expires_in',
            render: (text, record) => {
                const { totalMilliseconds } = this.state;
                const hoursValue = Math.floor((totalMilliseconds / (1000 * 60 * 60)) % 24);
                const minutesValue = Math.floor((totalMilliseconds / 1000 / 60) % 60);
                const secondsValue = Math.floor((totalMilliseconds / 1000) % 60);

                return (
                    <div className='listinginfowrap' style={{ alignItems: 'center' }}>
                        <div className='info-group' style={{ textAlign: 'center' }}>
                            <p className='info-title' style={{ color: "#095EDD" }}>Expires in:</p>
                            <div className='d-flex flex-row align-items-center'>
                                <Row gutter={16} className='countdownwrap'>
                                    <Col>
                                        <Statistic value={"0" + hoursValue} className='font-bold' />
                                        <span className='lblStatistics'>Hours</span>
                                    </Col>
                                    <Col className='d-flex align-items-center font-18 font-bold' style={{ color: '#095EDD' }}>:</Col>
                                    <Col>
                                        <Statistic value={minutesValue} className='font-bold' />
                                        <span className='lblStatistics'>Minutes</span>
                                    </Col>
                                    <Col className='d-flex align-items-center font-18 font-bold' style={{ color: '#095EDD' }}>:</Col>
                                    <Col>
                                        <Statistic value={secondsValue} className='font-bold' />
                                        <span className='lblStatistics'>seconds</span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'action', dataIndex: 'actions',
            render: (text, record) => (
                <div className='listinginfowrap' style={{ alignSelf: 'center' }}>
                    <div className='info-group'>
                        <div className='d-flex flex-row align-items-center'>
                            <Button className='btn btnAccept  font-14'>Accept</Button>
                            <Button className="btn btn-secondary  font-14" >Decline</Button>
                        </div>
                    </div>
                </div>
            )
        },
        // { title: 'Reassign to', dataIndex: 'reassign_to',  
        //     render: (text,record) => (
        //         <div className='listinginfowrap'  style={{ alignSelf:'center'}}>
        //             <div className='info-group'>
        //                 <div className='d-flex flex-row align-items-center'>
        //                     <Select className="btnSelectReassign"
        //                         style={{ minWidth: '150px'}} placeholder="Reassign to another agent"
        //                         // onChange={handleChange}
        //                         options={[
        //                             { value: '1', label: 'Chris Timoney' },
        //                             { value: '2', label: 'Rhys Benhadj' },
        //                             { value: '3', label: 'George Hughes' },
        //                             { value: '4', label: 'Kyle Guisti' },
        //                             { value: '5', label: 'Ellie Chambers' },
        //                         ]}
        //                         />
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // },
    ]


    responsiveColumns = [

        {
            title: 'expired in', dataIndex: 'expires_in',
            render: (text, record) => {
                const { totalMilliseconds } = this.state;
                const hoursValue = Math.floor((totalMilliseconds / (1000 * 60 * 60)) % 24);
                const minutesValue = Math.floor((totalMilliseconds / 1000 / 60) % 60);
                const secondsValue = Math.floor((totalMilliseconds / 1000) % 60);

                return (
                    <div className="TableCard">

                        <div className='row alignCenter headerGrayBgColor borderBottom'>
                            <div className='col-6'>

                            </div>
                            

                        </div>

                        <div className='row alignCenter borderGray pt-2 pb-2'>

                            <div className='col-12 col-sm-12'>
                                <div className='BodyFirstRowLeft d-flex flex-column'>
                                    <div className='listinginfowrap'>
                                        <div className='info-group'>
                                            <div className='agentwrap d-flex flex-row align-items-center'>
                                                <Avatar shape="square" style={{ borderRadius: '10px !important' }} size={60} icon={<img style={{ borderRadius: '10px', boxShadow: '1px 1px 10px lightgray', width: "60px", height: "60px", borderRadius: '10px !important' }} src={record.profile_photo == null || record.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(record.profile_photo) ? record.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo} />} />
                                                <p className="font-semibold font-14 ">{record.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-12'>
                                <div className='BodyFirstRowLeft d-flex flex-column'>


                                    {/* <span className="propinfo"> */}
                                        <p style={{whiteSpace:'wrap '}}>{record.property ? <span className="font-semibold font-14">{record.property + ", "}</span> : ''}
                                            {record.sub_community ? <span className="font-semibold font-14">{record.sub_community + ", "}</span> : ''}
                                            {record.community ? <span className="font-semibold font-14">{record.community}</span> : '-'}</p>
                                    {/* </span> */}

                                </div>

                            </div>
                            <div className='col-6 col-sm-6'>
                                <div className='BodyFirstRowRight'>
                                    <div className='d-flex gap-1 BodyFirstRowRight'>
                                    
                                    <p className='info-title'>Beds:</p>
                                    {record.beds ? <p className="font-12">{record.beds + " beds"}</p> : '---'}
                                    </div>
                                </div>
                            </div>


                        <div className='col-6 col-sm-6'>
                            <div className='BodyFirstRowLeft d-flex flex-column'>
                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                    <p className='info-title'>Price:</p>
                                    {record.price ? <p className=" font-12">{record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + record.currency}</p> : '---'}

                                </div>


                            </div>

                        </div>
                        <div className='col-12 col-sm-12'>
                            <div className='BodyFirstRowRight'>
                                <div className='d-flex gap-1 BodyFirstRowRight'>
                                    <p className='info-title'>Created Date:</p>
                                    {record.created_date ? <Moment fromNow className="font-semibold font-14">{record.created_date}</Moment> : '---'}
                                </div>
                            </div>

                        </div>

                        <div className='col-12 col-sm-12'>
                            <div className='BodyFirstRowLeft d-flex flex-column'>
                                <div className='d-flex gap-1 alignCenter BodyFirstRowRight'>
                                    <p className='info-title'>Expires In:</p>
                                    <p> <Row gutter={16} className='countdownwrap'>
                                    <Col>
                                        <Statistic value={"0" + hoursValue} className='font-bold' />
                                        <span className='lblStatistics'>Hours</span>
                                    </Col>
                                    <Col className='d-flex align-items-center font-18 font-bold' style={{ color: '#095EDD' }}>:</Col>
                                    <Col>
                                        <Statistic value={minutesValue} className='font-bold' />
                                        <span className='lblStatistics'>Minutes</span>
                                    </Col>
                                    <Col className='d-flex align-items-center font-18 font-bold' style={{ color: '#095EDD' }}>:</Col>
                                    <Col>
                                        <Statistic value={secondsValue} className='font-bold' />
                                        <span className='lblStatistics'>seconds</span>
                                    </Col>
                                </Row></p>
                                </div>
                               

                            </div>

                        </div>
                        <div className='col-12 col-sm-12'>
                            <div className='BodyFirstRowRight'>
                                <div className='d-flex gap-1 justify-content-center BodyFirstRowRight'>
                                <div className='d-flex flex-row align-items-center'>
                            <Button className='btn btnAccept  font-14'>Accept</Button>
                            <Button className="btn btn-secondary  font-14" >Decline</Button>
                        </div>
                                </div>

                                
                            </div>

                        </div>

                       
                    </div>


                    </div >
                )
            }
        },
    ]

    render() {
        return (<>
            <Row className="contentwrap pendingleadswrap" >
                <Col span={24}><h2>Pending Leads</h2></Col>
                {/* Filters */}
                <Col span={24} className="border-bottom">
                    <Row className="d-flex flex-row justify-content-between w-100 mt-2 align-items-center py-2">
                        <Col span={12} lg={12} md={12} sm={12} xs={24} className="d-flex flex-row" style={{ gap: '0.3rem' }}>
                            <p className="font-12 mb-0">Sort by: </p>
                            <p className="font-12 mb-0">Time Remaining</p>
                            <i class="bi bi-sort-down"></i>
                            {/* <i class="bi bi-sort-up"></i> */}
                        </Col>
                        <Col span={12} lg={12} md={12} sm={12} xs={24} className="d-flex flex-row align-items-center" style={{ gap: '0.3rem' }}>
                            <p className="font-12 mb-0">Filter by: </p>
                            <Select showSearch
                                style={{ minWidth: '200px', height: 36, borderRadius: 0 }}
                                // onChange={handleChange}
                                options={[
                                    { value: '1', label: 'Chris Timoney' },
                                    { value: '2', label: 'Rhys Benhadj' },
                                    { value: '3', label: 'George Hughes' },
                                    { value: '4', label: 'Kyle Guisti' },
                                    { value: '5', label: 'Ellie Chambers' },
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table showHeader={false} tableLayout={'fixed'} className="pendingLeadsTable"
                        dataSource={this.dataSource}
                        columns={window.isTabView ? this.responsiveColumns: this.columns}
                        style={{

                        }}

                    />
                </Col>
            </Row>


            {/* IF new lead comes in */}
            <Modal width={400} open={this.state.modalNewLead} onOk={this.handleNewLeadClose} onCancel={this.handleNewLeadClose}
                title={<p className='font-bold mb-0'>You have a new lead!</p>}
                footer={[
                    <Button className='btn btnAccept'>Accept</Button>,
                    <Button className='btn btnReject'>Reject</Button>
                ]}
            >
                <Row>
                    <Col span={24}>
                        <img src="https://www1.luxuryproperty.com//uploads/images/3-bedroom-apartment-for-sale-bel_air-LP38777-13cfd1a0eb015700.jpeg" style={{ width: '100%' }} />
                    </Col>
                    <Col span={24}>
                        <Row className='w-100 justify-content-between mt-2'>
                            <Col span={12} className='d-flex flex-row' style={{ gap: '0.3rem' }}>
                                <p className='mb-0 font-12'>Villa</p>
                                <i class="bi bi-dot"></i>
                                <p className='mb-0 font-12'>Sale</p>
                            </Col>
                            <Col span={12}>
                                <p className='stat published mb-0'>Published</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}><p className='font-bold font-16 mb-0'>LP10096</p></Col>
                    <Col span={24}><p className='mb-0'>3,850,000 AED</p></Col>
                    <Col span={24}><p className='mb-0'><i class="bi bi-geo-alt-fill"></i> A-207, Maeen, The Lakes</p></Col>
                    <Col span={24}>
                        <div className='agentwrap d-flex flex-row align-items-center mt-2'>
                            <Avatar style={{ borderRadius: '10px !important' }} size={40} icon={<img style={{ borderRadius: '10px', boxShadow: '1px 1px 10px lightgray', width: "40px", height: "40px", borderRadius: '10px !important' }} src={this.state.profile_photo == null || this.state.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(this.state.profile_photo) ? this.state.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + this.state.profile_photo} />} />
                            <p className="font-semibold font-14 pl-2 mb-0">Oksana Semiletova</p>
                        </div>
                    </Col>
                </Row>

            </Modal>
        </>)
    }

} export default (Pending_Leads)